import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import _ from "lodash";
// Customizable Area Start
// Customizable Area End
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell } from '@material-ui/core';
import ApprovalRequestController, {
  Props,
  configJSON
} from "./ApprovalRequestController";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from "@material-ui/core/Card";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const logo = require("../../../components/src/volkswagen.svg")
const defaultUser = require("../../../components/src/defaultuser.png")
const downIcon = require('../../../components/src/down.png');
const filterButton = require("../../../components/src/filter.png");

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


const data = [
  {
    id: 1,
    name: "BMW"
  }
]


const styles = {
  root: {

  },
  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  accordianStyle: {
    boxShadow: "none",
    border: "none",
    marginTop: "30px"
  },

  actionCard: {
    backgroundColor: "#e4e8f0",
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: "14px"
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    height: "18vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  title: {
    color: "#282828",
    fontSize: "16px"
  },
  dropDown: {
    fontSize: "16px"
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
    // padding:"0px"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  }
} as any
class ApprovalRequest extends ApprovalRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleClickProjects = (event: any) => {
    console.log(event, "h")
    this.setState({
      anchorE2: event.currentTarget,
    });
  };

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    
    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      //Merge Engine DefaultContainer
      <div style={{ marginLeft: "15px" ,fontFamily : primaryFont }}>

        <Backdrop
          className={classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container spacing={0} >
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/HomePage" style={{ color: secondaryColorToBeChanged, fontSize: "14px" ,fontFamily : SecondaryFont }}>
                Home
              </Link>
              <Link href="/Home/projects" style={{ color: "#737373", fontSize: "14px", textDecoration:"none", cursor:"default" ,fontFamily : SecondaryFont}}>
                Approval Requests
              </Link>
              
            </Breadcrumbs>

            <span> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" ,fontFamily : primaryFont }}>{t("ApprovalRequest.approvalRequest")}

              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">{t("ApprovalRequest.approvalRequest")}</Typography>
                    <p>Here you can see all the approval requests.</p>
                  </React.Fragment>
                }
                placement="right"
              >
                <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                </InfoOutlinedIcon>
              </HtmlTooltip>



            </p></span>








          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>

            {/* <Button
              variant="contained"
              style={{ color: secondaryColorToBeChanged, marginBottom: "10px" , textTransform: 'none' ,fontFamily : SecondaryFont}}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter: event.target })}
            >
              Filters
            </Button> */}

            <ButtonGroup aria-label="outlined primary button group" style={{ marginRight: "10px", marginLeft: "10px" }}>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged ,textTransform: 'none' ,fontFamily : SecondaryFont }}
                className={this.state.grid ? classes.activeButton : classes.button}
                onClick={e => { this.changeToGridView(e) }}

                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={<AppsOutlinedIcon />}
              >
                {this.state.grid &&
                  <>Grid</>
                }
              </Button>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged ,textTransform: 'none' ,fontFamily : SecondaryFont }}
                className={this.state.list ? classes.activeButtonList : classes.buttonGroup}
                onClick={e => { this.changeToListView(e) }}
                endIcon={<ListOutlinedIcon />}

              >
                <span>  {this.state.list &&
                  <>List</>
                }</span>
              </Button>

            </ButtonGroup>

            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              startAdornment={
                <InputAdornment position="start" >
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={this.handleSearchChange}
              value={this.state.searchBrandName}
            />

          </Grid>

          <>
            {this.state.grid &&

(
  <>
    <Grid item xs={12}>
      <div style={{ marginTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px" }}>
          <Typography className={classes.heading} >Brands </Typography>
          <Button
            variant="contained"
            style={{ color: secondaryColorToBeChanged, zIndex:100, textTransform: 'none' }}
            className={classes.button}
            startIcon={
              <img src={filterButton} aria-controls="panel1a-content"
               id="panel1a-header" />
            }
            endIcon={
              <ExpandMoreIcon style={{ color: "black" }} />
            }
            onClick={(event) => this.setState({ anchorElFilter: event.target })}
          >
             {this.state.brandFilterValue == 'brand_name' ? 'Name (A-Z)' : this.state.brandFilterValue == 'create_at' ? 'Created At' : 'Updated At'}
          </Button>

        </div>
          <Grid xs={12}>
            <Grid container spacing={0} alignItems="stretch">
         
              {this.state.brandGroupFlag && this.state.brandsData?.filter((val:any)=> val.attributes.brand_group_id==null).map((item: any) => {
                return (
                  <Grid item xs={3}>
                    <Box m={1}>
                      <Card className={classes.cardStyle} onClick={(e: any) => { this.setState({ selectData: item, selectedBrandId: item.id }, () => { this.getProjectByBrandID() }) }}>
                        <CardContent style={{ paddingBottom: "16px", cursor: "pointer" }}  >
                          <a>
                            <Typography className={classes.title} gutterBottom>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                  {item?.attributes?.logo_blob_signed_id_url ?
                                    <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                    :
                                    <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                  }
                                  <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px" }} >{item.attributes.name}</p> </span>
                             
                              </div>


                            </Typography>

                            {item.attributes.projects?.map((projectName: any) => (
                              <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>
                                {projectName.name}
                              </p>
                            ))
                            }

                          </a>

                        </CardContent>

                        


                        <CardActions className={classes.actionCard} >
                          <p style={{ fontSize: "10px" }}>
                            {" "}
                            {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                              item.id == val.id && (
                                val.DMY + " " + t("ApprovalRequest.lastModified")
                              )
                            ))}
                          </p>


                        </CardActions>

                      </Card>
                    </Box>

                  </Grid>

                )
              })}
              
          { this.state.brandGroupFlag && this.handleFilterArray(this.state.brandsData).length && <Typography className={classes.heading} style={{width:"100%",marginBottom:"20px",marginTop:"10px",marginLeft:"5px"}} >Brands Group</Typography>}

              {this.state.brandGroupFlag &&
              this.handleFilterArray(this.state.brandsData).map((item: any) => {
                return (
                  <Grid item xs={3}>
                    <Box m={1}>
                      <Card className={classes.cardStyle} onClick={(e: any) => { this.setState({ brandGroupFlag: false },() =>{ this.handleBrandGroupView(item.attributes.brand_group_id)})}}>
                        <CardContent style={{ paddingBottom: "16px", cursor: "pointer" }}  >
                          <a>
                            <Typography className={classes.title} gutterBottom>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                  
                                    <Avatar style={{ width: "35px", height: "35px" }} >{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                  
                                  <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "auto" }} >{item.attributes.brand_group_name}</p> </span>
                             
                              </div>


                            </Typography>

                            {this.state.brandsData?.filter((value:any)=>value.attributes.brand_group_id==item.attributes.brand_group_id).map((BrandName: any) => (
                              <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>
                                {BrandName.attributes.name}
                              </p>
                            ))
                            }

                          </a>

                        </CardContent>

                        


                        <CardActions className={classes.actionCard} >
                          <p style={{ fontSize: "10px" }}>
                            {" "}
                            {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                              item.id == val.id && (
                                val.DMY + " " + t("ApprovalRequest.lastModified")
                              )
                            ))}
                          </p>

                        </CardActions>

                      </Card>
                    </Box>

                  </Grid>

                )
              })}

{!this.state.brandGroupFlag && this.state.selectedBrandFromGroup.map((item: any) => {
                return (
                  <Grid item xs={3}>
                    <Box m={1}>
                      <Card className={classes.cardStyle} onClick={(e: any) => { this.setState({ selectData: item, selectedBrandId: item.id }, () => { this.getProjectByBrandID() }) }}>
                        <CardContent style={{ paddingBottom: "16px", cursor: "pointer" }}  >
                          <a>
                            <Typography className={classes.title} gutterBottom>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                  {item?.attributes?.logo_blob_signed_id_url ?
                                    <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                    :
                                    <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                  }
                                  <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px" }} >{item.attributes.name}</p> </span>
                             
                              </div>


                            </Typography>

                            {item.attributes.projects?.map((projectName: any) => (
                              <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>
                                {projectName.name}
                              </p>
                            ))
                            }

                          </a>

                        </CardContent>

                        


                        <CardActions className={classes.actionCard} >
                          <p style={{ fontSize: "10px" }}>
                            {" "}
                            {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                              item.id == val.id && (
                                val.DMY + " " + t("ApprovalRequest.lastModified")
                              )
                            ))}
                          </p>


                        </CardActions>

                      </Card>
                    </Box>

                  </Grid>

                )
              })}

              
            </Grid>
            <Box textAlign="right" style={{ marginTop: "45px", position: "absolute", right: "55px" }}>
              <Pagination
                count={this.state.total_pages}
                variant="outlined"
                color="primary"
                page={this.state.setPage}
                onChange={this.handleChange}
              />
            </Box>
          </Grid>

     
      </div></Grid>
  </>
)

}

{this.state.list &&
(
  <>
    <Grid item xs={12}>
      <div style={{ marginTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px" }}>
          <Typography className={classes.heading} >Brands</Typography>
          <Button
            variant="contained"
            style={{ color: secondaryColorToBeChanged, zIndex:100, textTransform: 'none' }}
            className={classes.button}
            startIcon={
              <img src={filterButton} aria-controls="panel1a-content"
               id="panel1a-header" />
            }
            endIcon={
              <ExpandMoreIcon style={{ color: "black" }} />
            }
            onClick={(event) => this.setState({ anchorElFilter: event.target })}
          >
             {this.state.brandFilterValue == 'brand_name' ? 'Name (A-Z)' : this.state.brandFilterValue == 'create_at' ? 'Created At' : 'Updated At'}
          </Button>

        </div>
        <Grid item xs={12}>
          {
          this.state.brandsData?.map((item: any, index:any) => {
            return (
              <div>
                <div
                  onClick={(e: any) => { this.setState({ selectData: item, selectedBrandId: item.id }, () => { this.getProjectByBrandID() }) }} 
                  style={{ width: "95%", borderRadius: "8px", borderLeft: `6px solid ${secondaryColorToBeChanged}`, borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px", cursor: 'pointer' }}
                >
                  <div style={{ margin: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <div style={{ display: "flex" }}>
                        {item?.attributes?.logo_blob_signed_id_url ?
                          <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                          :
                          <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                        }
                        <p style={{ marginTop: "10px", marginLeft: "20px" }}>{item.attributes.name}</p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className={classes.circle} >
                          <p ><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                        </div>
                        {this.state.brandUpdatedCompleteDate?.map((val: any,i: any) => {
                          if(index == i){
                            return(
                              <p className= {classes.listDate} style={{ fontSize: "12px", marginLeft: "5px" }}>
                                {item.id == val.id && (
                                  val.DMY + " " + t("ApprovalRequest.lastModified")
                                )}
                              </p>
                            )
                          }
                        })}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {item.attributes?.projects.map((project: any) => (
                        <p className = {classes.listProjects} style={{ fontSize: "12px", paddingLeft: "25px" }}>{project.name}</p>
                      ))}
                      {item.attributes && item.attributes.projects && item.attributes.projects.length == 0 &&
                        <p></p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          }
        </Grid>
      </div>
    </Grid>
  </>
)

}
        </>
        </Grid>

        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >
          <Grid container style={{ width: "auto", paddingLeft: "15px" }}>
            <Grid item xs={8}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.brandFilterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{
                  marginLeft: "5px !important",
                  width: "125px"
                }} value="brand_name" control={<Radio />} label="Name (A-Z)" />
                <FormControlLabel value="created_at" control={<Radio />} style={{ width: "112px" }} label="Created At" />
                <FormControlLabel value="updated_at" control={<Radio />} style={{ width: "112px" }} label="Updated At" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Menu>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(ApprovalRequest))
// Customizable Area Start

// Customizable Area End
