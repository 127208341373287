import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  MenuItem,
  Box,
  Select,
  Menu,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const downIcon = require("../../../components/src/down.png");
const defaultUser = require("../../../components/src/defaultuser.png")

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { withTranslation, Trans } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  // inputSerachBox: {
  //   "&::before": {
  //     content: "none",
  //     borderBottom: "none",
  //   },
  //   "&:hover": {
  //     // borderBottom:'none'
  //   },
  //   "&::after": {
  //     borderBottom: "none",
  //   },
  //   border: "1px solid #e8edf4",
  //   background: "#ffffff",
  //   borderBottom: "1px solid #e8edf4",
  //   boxSizing: "border-box",
  //   borderRadius: "8px",
  //   outline: "none",
  //   // underline:"none"
  // },

  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  dialogPaper: {
    width: "600px",
    height: "525px",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  confirmDialogPaper: {
    width: "400px",
    height: "275px",
  },
} as const;
interface Props {
  classes: any;
  t?: any;
  i18n?: any;
  updateSelectionState:any;
}
interface S {
  anchorEl: any;
  brandList: any;
  projectList: any;
  userList: any;
  selectedBrandId: any;
  selectedProjectId: any;
  selectedUserId: any;
  selectedUser: any;
  brandExpanded: any;
  projectExpanded: any;
  rolesList: any;
  selectedBrandName: any;
  selectedProjectName: any;
  setBrandPage: number;
  totalBrandPages: number;
  setUserPage: number;
  totalUserPages: number;
  isSearch: boolean;
  searchValue: string;
  searchData: any;
  totalPage: number;
  nextPage: boolean;
  setPage: number;
  path: string;
  createUserDialogOpen: any;
  roleListBasedOnBrand: any;
  confirmUserDialogOpen: any;
  createdUser: any;
}
interface SS {
  id: any;
}
class Users extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: any;
  getProjectsApiCallId: any;
  getUsersApiCallId: any;
  deleteUserApiCallId: any;
  getRolesApiCallId: any;
  updateRoleApiCallId: any;
  exportListApiCallId: any;
  blockUnblockUserApiCallId: any;
  getUsersByFilterApiCallId: any;
  createUserApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: null,
      brandList: [],
      projectList: [],
      userList: [],
      selectedBrandId: "",
      selectedProjectId: "",
      selectedUserId: "",
      selectedUser: null,
      brandExpanded: "",
      projectExpanded: "",
      rolesList: [],
      selectedBrandName: "",
      selectedProjectName: "",
      setBrandPage: 1,
      totalBrandPages: 0,
      setUserPage: 1,
      totalUserPages: 0,
      isSearch: false,
      searchValue: "",
      searchData: [],
      totalPage: 0,
      nextPage: false,
      setPage: 1,
      path: "",
      createUserDialogOpen: false,
      roleListBasedOnBrand: [],
      confirmUserDialogOpen: false,
      createdUser: null
    };
  }

  async componentDidMount() {
    await this.getBrands();
    await this.getRoles();
var url_string = window.location.href; //window.location.href
var url = new URL(url_string);
var param = url.searchParams.get("redirect");
console.log(param)
if(param !==null){
  this.props.updateSelectionState(param);
}
    
  }

  getBrands = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands?page=${this.state.setBrandPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRoles = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_roles_permissions/roles"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBrandsApiCallId) {
        if (responseJson.brands) {
          if (responseJson.brands.data) {
            if (responseJson.brands.data !== null) {
              let tempArr = [] as any;
              responseJson.brands.data.map((_data: any, idx: any) => {
                tempArr.push({
                  id: _data.id,
                  name: _data.attributes.name,
                });
                if (idx === 0) {
                  this.setState({
                    selectedBrandId: _data.id,
                  });
                  this.getUsersByBrand(_data.id);
                }
              });
              let totalBrandPagesCount = this.state.totalBrandPages;
              this.setState({
                brandList: tempArr,
                totalBrandPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalBrandPagesCount,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getUsersApiCallId) {
        if (responseJson.accounts) {
          if (responseJson.accounts.data) {
            if (responseJson.accounts.data !== null) {
              let totalUserPagesCount = this.state.totalUserPages;
              this.setState({
                userList: responseJson.accounts.data,
                totalUserPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalUserPagesCount,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.deleteUserApiCallId) {
        if (responseJson.message) {
          if (responseJson.message?.toUpperCase() === "Record successfully deleted".toUpperCase()) {
            toast.success(responseJson.message);
            this.setState({
              anchorEl: null,
              selectedUser: null
            }, () => {
              if (this.state.isSearch == false) {
                this.getUsersByBrand(this.state.selectedBrandId);
              } else {
                this.handleSearchClick();
              }
            })
          }
        }
      } else if (apiRequestCallId === this.getRolesApiCallId) {
        this.setState({
          rolesList: responseJson,
        });
      } else if (apiRequestCallId === this.updateRoleApiCallId) {
        console.clear();
        console.log(responseJson);
        if (responseJson.errors) {
          toast.error("Something Went Wrong.");
        } else {
          toast.success("Role updated successfully");
          if (this.state.isSearch == false) {
            this.getUsersByBrand(this.state.selectedBrandId);
          } else {
            this.handleSearchClick();
          }
        }
      } else if (apiRequestCallId === this.exportListApiCallId) {
        console.clear();
        console.log(responseJson);
        console.log(errorReponse);
        console.log(message);
      } else if (apiRequestCallId === this.blockUnblockUserApiCallId) {
        console.log(responseJson);
        toast.success("User suspended/unsuspended successfully.");
        this.setState({ anchorEl: null, selectedUser: null });
        if (this.state.isSearch == false) {
          this.getUsersByBrand(this.state.selectedBrandId); 
        } else {
          this.handleSearchClick();
        }
      } else if (apiRequestCallId === this.getUsersByFilterApiCallId) {
        console.log(responseJson);
        if (responseJson.accounts) {

          this.setState({
            searchData: responseJson.accounts.data,
            // searchData: [
            //   ...this.state.searchData,
            //   ...responseJson.accounts.data,
            // ],
          });
          this.setState({
            nextPage: responseJson.next_page,
            totalPage: responseJson.total_pages,
          });
        }
      } else if (apiRequestCallId === this.createUserApiCallId) {
   
        console.log(responseJson);
        if (responseJson?.errors?.length > 0) {
          if (responseJson?.errors?.[0]?.account) {
            toast.error(responseJson?.errors?.[0]?.account);
          } else if (responseJson?.errors?.[0]?.email) {
            toast.error(responseJson?.errors?.[0]?.email);
          } else if (responseJson?.errors?.[0]?.toUpperCase() == "Record not found".toUpperCase()) {
            toast.error(responseJson?.errors?.[0]);
          } else if (responseJson?.errors?.[0]?.account?.company_name?.[0]?.toUpperCase() == "Company name is incorrect".toUpperCase()) {
            toast.error(responseJson?.errors?.[0]?.account?.company_name?.[0]);
          }
        } else {
          if (responseJson?.data?.id != undefined) {
            this.setState({
              createUserDialogOpen: false,
              confirmUserDialogOpen: true
            }, () => {
              if (this.state.isSearch == false) {
                this.getUsersByBrand(this.state.selectedBrandId);
              } else {
                this.handleSearchClick();
              }
            });
          }
        }
      }
    }
  }

  getProjectsByBrand = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?brand_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUsersByBrand = (id: any) => {
    this.setState({ userList: [] });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUsersApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?brand_id=${id}&page=${this.state.setUserPage}&all_user=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteUser = (e: any) => {
  
    e.stopPropagation();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteUserApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.selectedUserId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleIconClick = (event: any, id: any, selectedUser: any) => {
   
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
      selectedUserId: id,
      selectedUser: selectedUser
    });
  };

  handleIconClose = (event: any) => {
    event.stopPropagation();
    this.setState({
      anchorEl: null
    });
  };

  handleBrandAccordionChange = (id: any, name: any) => {
    if(this.state.brandExpanded == id + "brandsAccordion"){
      this.setState({
        brandExpanded: "",
        selectedBrandId: "",
        selectedBrandName: "",
      });
    }else{
      this.setState({
        brandExpanded: id + "brandsAccordion",
        selectedBrandId: id,
        selectedBrandName: name,
      });
    }
  };
  handleProjectAccordionChange = (id: any, name: any) => {
    this.setState({
      projectExpanded: id + "projectAccordion",
      selectedProjectId: id,
      selectedProjectName: name,
    });
  };

  handlePermissionChange = (event: any, id: any) => {
 
    console.log(event.target.value);
    console.log(id);
    this.updateRoleByUserId(event.target.value, id);
  };

  updateRoleByUserId = (roleId: any, userId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRoleApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let body = {
      account_id: Number(userId),
      role_id: roleId,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_roles_permissions/roles/update_account_role"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  exportList = () => {
    var url = `${currentUrl
      }/bx_block_data_import_export_csv/users_exports?brand_ids=[${this.state.selectedBrandId
      }]&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
    toast.success("List exported successfully.")
  };

  handleBlockUnblockUser = (event: any, userId: any, isBlocked: any) => {
    event.stopPropagation();
    let requestBody = {} as any;
    if (isBlocked) {
      requestBody = {
        is_suspended: false,
      };
    } else {
      requestBody = {
        is_suspended: true,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUnblockUserApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBrandPagination = (event: any, value: number) => {
    this.setState({ setBrandPage: value }, () => {
      this.getBrands();
    });
  };
  handleUserPagination = (event: any, value: number) => {
    this.setState({ setUserPage: value }, () => {
      this.getUsersByBrand(this.state.selectedBrandId);
    });
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  handleSearchClick = () => {
    this.setState({ searchData: [], setPage: 1 });
    this.setState({ isSearch: true });
    let path = `first_name=${this.state.searchValue}`;
    this.setState({ path: path });
    this.userByFilters(path, 1);
    //this.setState({ searchValue: "" });
  };

  fetchMoreData = () => {
    console.clear();
    console.log("Page count", this.state.setPage);
    this.setState({ setPage: this.state.setPage + 1 }, () => {
      this.userByFilters(this.state.path, this.state.setPage);
    });
  };

  userByFilters = (path: any, page: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUsersByFilterApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?${path}&&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleNameFilterClick = () => {
    this.setState({ isSearch: true });
    this.userByFilters("per_page=1000&sort_by=name", 1);
  };

  handleCreateUserDialogOpen = () => {
    this.setState({
      createUserDialogOpen: true,
      anchorEl: null
    });
  }

  handleCreateUserDialogClose = () => {
    this.setState({
      createUserDialogOpen: false,
      selectedUser: null
    });
  };

  createUserHandler = (values: any) => {
  
    console.log(values);
    this.setState({
      createdUser: values
    })
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    //const httpBody = { data: values };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createUserApiCallId = requestMessage.messageId;
    if (this.state.selectedUser == null) {
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/account_creation_by_admin");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    } else {
      values['id'] = parseInt(this.state.selectedUser?.id);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/account_updation_by_admin");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    }

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(values));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCreateUserConfirmDialogClose = () => {
    this.setState({
      confirmUserDialogOpen: false
    });
  };

  render() {
    const { t } = this.props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    return (
      <>
        <Grid style={{ paddingLeft: "10px" }} container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
               <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Typography>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.users")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2}>
                {" "}
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.users")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("AdminConsole.export")}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      disableUnderline={true}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder={t("AdminConsole.searchForUser")}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "89px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "152px",
                        height: "32px",
                      }}
                      onClick={() => {
                        this.handleCreateUserDialogOpen();
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        {t("AdminConsole.createNewUser")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginBottom: "30px" }}>
              <div style={{ position: "absolute", right: "16px" }}>
                <Button
                  style={{ zIndex: 100, textTransform: "none" }}
                  endIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                  onClick={this.handleNameFilterClick}
                >
                  Name
                </Button>
              </div>
            </div>
          </Grid>
          {!this.state.isSearch ? (
            <>
              {this.state.brandList.map((_data: any) => (
                <Grid item xs={12} key={_data.id}>
                  <Accordion
                    style={{ boxShadow: "none", background: "#F5F6FA" }}
                    expanded={
                      this.state.brandExpanded === `${_data.id}brandsAccordion` || userInfo?.role?.data?.attributes?.name === 'client'
                    }
                    onChange={() => {
                      this.handleBrandAccordionChange(_data.id, _data.name);
                    }}
                  >
                    {userInfo?.role?.data?.attributes?.name === 'superadmin'&&
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button
                          variant="text"
                          onClick={() => {
                            this.setState({ setUserPage: 1 }, () => {
                              this.getUsersByBrand(_data.id);
                            });
                          }}
                        >
                          {this.state.brandExpanded ===
                            `${_data.id}brandsAccordion` ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "Work Sans",
                              color: "#0E0E0E",
                            }}
                          >
                            {/* <img src={downIcon} /> {_data.name} */}
                            {_data.name}
                          </Typography>
                        </Button>
                        <Typography
                          style={{ position: "absolute", right: "10px" }}
                        >
                          {/* <div
                        style={{
                          display: "inline-flex",
                          marginTop: "11px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            color: "#0E0E0E",
                            opacity: 1,
                            fontSize: "13px",
                            fontFamily: "Work Sans",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            marginRight: "15px",
                          }}
                        >
                          Name
                        </Typography>
                        <span>
                          <ArrowDownwardIcon
                            style={{
                              width: "11px",
                              height: "11px",
                              marginTop: "4px",
                            }}
                          />
                        </span>
                      </div> */}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    }
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>

                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Work Sans",
                                    color: "#9EABB5",
                                    textTransform: "uppercase",
                                    opacity: 1,
                                    borderBottom: "0px",
                                    width: "32%",
                                    paddingLeft: "50px",
                                  }}
                                >
                                  Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Work Sans",
                                    color: "#9EABB5",
                                    textTransform: "uppercase",
                                    opacity: 1,
                                    borderBottom: "0px",
                                    width: "36%",
                                  }}
                                >
                                  Designation
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Work Sans",
                                    color: "#9EABB5",
                                    textTransform: "uppercase",
                                    opacity: 1,
                                    borderBottom: "0px",
                                    width: "auto",
                                  }}
                                >
                                  Role
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </Grid>
                        {this.state.userList.map((_data: any) => (
                          <Grid item xs={12}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              onClick={(e) => {
                                // (window.location.href = `/AdminConsole/user/${_data.id
                                //   }?brand_name=${this.state.selectedBrandName}`)
                              }
                              }
                            >
                              <Box
                                width="100%"
                                borderRadius="8px"
                                border="solid 1px #e4e8f0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                  style={{
                                    background: "white",
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="15%"
                                  >
                                    {/* <span
                                      style={{
                                        border: "1px solid black",
                                        height: "32px",
                                        width: "32px",
                                        backgroundColor: "#707880",
                                        borderRadius: "17px",
                                      }}
                                    /> */}
                                    <Avatar src={_data.attributes?.photo_blob_signed_id_url == null || _data.attributes?.photo_blob_signed_id_url?.length > 0 ? _data.attributes?.photo_blob_signed_id_url : defaultUser}
                                      style={{
                                        height: "32px",
                                        width: "32px",
                                        borderRadius: "17px",
                                        //border: "1px solid black",
                                      }} />



                                    <Typography
                                      variant="body2"
                                      style={{
                                        marginLeft: "11px",
                                        fontFamily: "Work Sans",
                                        fontSize: "12px",
                                        color: "#1A1A1A",
                                        whiteSpace : "nowrap"
                                      }}
                                    >
                                      {_data.attributes.is_deleted?`Deleted User`:`${_data.attributes.first_name} ${_data.attributes.last_name}`}
                                   
                                    </Typography>
                                  </Box>
                                  <Box width="20%">
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontSize: "11px",
                                        opacity: 1,
                                        fontFamily: "Work Sans",
                                        color: "#707880",
                                      }}
                                    >
                                      {_data.attributes.is_deleted?'': _data.attributes.designation !== null
                                        ? _data.attributes.designation
                                        : "NA"}
                                     
                                    </Typography>
                                  </Box>
                                  <Box>
                                  {_data.attributes.is_deleted?'':
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      label="Age"
                                      value={
                                        _data.attributes.role.data.attributes.id
                                      }
                                      disableUnderline={true}
                                      style={{
                                        border: "1px solid #9EABB593",
                                        width: "152px",
                                        height: "32px",
                                        fontSize: "12px",
                                        paddingLeft: "5px",
                                      }}
                                      onChange={(e) =>
                                        this.handlePermissionChange(e, _data.id)
                                      }
                                    >
                                      {_data.attributes.is_albertbauer_user
                                        ? this.state.rolesList.ab_user_roles.map(
                                          (_roles: any) => (
                                            <MenuItem
                                              value={_roles.id}
                                              key={_roles.id}
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              {_roles.permission}
                                            </MenuItem>
                                          )
                                        )
                                        : this.state.rolesList.client_roles.map(
                                          (_roles: any) => (
                                            <MenuItem
                                              value={_roles.id}
                                              key={_roles.id}
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              {_roles.permission}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>}

                                  </Box>
                                  <Box>
                                  {_data.attributes.is_deleted?'':
                                    <IconButton
                                      onClick={(e) =>
                                        this.handleIconClick(e, _data.id, _data)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>}
                                    
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={9} />
                            <Grid item xs={3}>
                              <Pagination
                                count={this.state.totalUserPages}
                                variant="outlined"
                                color="primary"
                                page={this.state.setUserPage}
                                onChange={this.handleUserPagination}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid
                      item
                      xs={12}
                      style={{ height: "20px", background: "#FFFFFF" }}
                    /> */}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
              {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  {this.state.totalBrandPages > 1 ?
                  <>
                  <Grid item xs={3}>
                    <Pagination
                      count={this.state.totalBrandPages}
                      variant="outlined"
                      color="primary"
                      page={this.state.setBrandPage}
                      onChange={this.handleBrandPagination}
                    />
                  </Grid>
                  </> :""}
                </Grid>
              </Grid>
              }
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      style={{ left: "60px" }}
                      onClick={() =>
                        this.setState({ isSearch: false, searchValue: "" }, () => {
                          if (this.state.isSearch == false) {
                           this.getUsersByBrand(this.state.selectedBrandId); 
                          }
                        })
                      }
                    >
                      Back to Previous Screen
                    </Button>
                  </Grid>
                </Grid>
                <InfiniteScroll
                  dataLength={this.state.searchData.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.nextPage}
                  loader={this.state.totalPage ? "" : <h4>Loading...</h4>}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Designation
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Role
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.searchData.map((_data: any) => {
                        return (
                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}
                              onClick={(e) => {
                                // (window.location.href = `/AdminConsole/user/${_data.id
                                //   }?brand_name=${this.state.selectedBrandName}`)
                              }
                              }
                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={this.props.classes.tableData}
                                    >
                                      {`${_data.attributes.first_name} ${_data.attributes.last_name
                                        }`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.designation}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                {/* <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {
                                    _data.attributes.role.data.attributes
                                      .permission
                                  }
                                </Typography> */}
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Age"
                                  value={
                                    _data.attributes.role.data.attributes.id
                                  }
                                  disableUnderline={true}
                                  style={{
                                    border: "1px solid #9EABB593",
                                    width: "152px",
                                    height: "32px",
                                    fontSize: "12px",
                                    paddingLeft: "5px",
                                  }}
                                  onChange={(e) =>
                                    this.handlePermissionChange(e, _data.id)
                                  }
                                >
                                  {_data.attributes.is_albertbauer_user
                                    ? this.state.rolesList.ab_user_roles.map(
                                      (_roles: any) => (
                                        <MenuItem
                                          value={_roles.id}
                                          key={_roles.id}
                                          onClick={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {_roles.permission}
                                        </MenuItem>
                                      )
                                    )
                                    : this.state.rolesList.client_roles.map(
                                      (_roles: any) => (
                                        <MenuItem
                                          value={_roles.id}
                                          key={_roles.id}
                                          onClick={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {_roles.permission}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(e) =>
                                    this.handleIconClick(e, _data.id, _data)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                      id="simple-menu"
                                      anchorEl={this.state.anchorEl}
                                      open={Boolean(this.state.anchorEl)}
                                      onClose={(e) => this.handleIconClose(e)}
                                    >
                                      <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) => { 
                                          this.deleteUser(e);
                                        }}
                                      >
                                        Delete User
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) => {
                                          this.handleBlockUnblockUser(
                                              e,
                                              _data.id,
                                              _data.attributes.activated
                                            );
                                          }
                                        }
                                      >
                                        {_data.attributes.activated
                                          ? "Suspend"
                                          : "Unsuspend"}
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) => {
                                            // (window.location.href = `/AdminConsole/user/${this.state.selectedUserId
                                            //   }?brand_name=${this.state.selectedBrandName
                                            //   }`)
                                           
                                            console.log(_data);
                                            this.handleCreateUserDialogOpen();
                                          }
                                        }
                                      >
                                        Profile Settings
                                      </MenuItem>
                                    </Menu>
                              </TableCell>
                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </InfiniteScroll>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          onClose={this.handleCreateUserDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.createUserDialogOpen}
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={this.props.classes.dialogTitleRoot}
          >
            <span className={this.props.classes.dialogTitle}>
              {this.state.selectedUser == null ? t("AdminConsole.createNewUser") : "Edit User"}
            </span>
            <IconButton>
              <CloseIcon onClick={this.handleCreateUserDialogClose} />
            </IconButton>
          </DialogTitle>
          <Formik
            initialValues={{
              brand_id: this.state.selectedUser?.attributes?.brand_id || "",
              first_name: this.state.selectedUser?.attributes?.first_name || "",
              last_name: this.state.selectedUser?.attributes?.last_name || "",
              email: this.state.selectedUser?.attributes?.email || "",
              role_name: this.state.selectedUser?.attributes?.role?.data?.attributes?.permission || "",
            }}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              brand_id: Yup.string().required(
                "Brand Name is required"
              ),
              first_name: Yup.string().required(
                "First Name is required"
              ),
              last_name: Yup.string().required(
                "Last Name is required"
              ),
              email: Yup.string().required(
                "Email is required"
              ),
              role_name: Yup.string().required(
                "Role Name is required"
              ),
            })}
            onSubmit={(values: any) => {
            
              console.log(values);
              this.createUserHandler(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          color: "#9EABB5",
                          letterSpacing: 0,
                          marginTop: '8px'
                        }}
                      >
                        {t("AdminConsole.brandName")}
                      </Typography>
                      <Select
                        labelId="brand_id"
                        id="brand_id"
                        name="brand_id"
                        value={values.brand_id}
                        variant="outlined"
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        style={{
                          height: "45px",
                          paddingLeft: "8px",
                          borderRadius: "2px",
                          marginTop: '8px',
                          width: '100%'
                        }}
                        disabled={this.state.selectedUser != null ? true : false}
                      >
                        <MenuItem value=""></MenuItem>
                        {
                          this.state.brandList.map((_data: any) => {
                            return (
                              <MenuItem key={_data.id} value={_data.id}>{_data.name}</MenuItem>
                            );
                          })
                        }
                      </Select>
                      {/* <Field
                        name="brandName"
                        value={values.brandName}
                        as="select"
                        onChange={(e: any) => { 
                        
                          handleChange(e);
                        }}
                        placeholder="Select Brand"
                        style={{
                          border: "1px solid #e8edf4",
                          height: "45px",
                          paddingLeft: "8px",
                          borderRadius: "2px",
                          marginTop: '8px',
                          width: '100%'
                        }}
                      >
                        <option value=""></option>
                        {
                          this.state.brandList.map((_data: any) => {
                            return (
                              <option key={_data.id} value={_data.id}>{_data.name}</option>
                            );
                          })
                        }
                      </Field> */}
                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                      {touched.brand_id && errors.brand_id ? (
                        <div className="error">{errors.brand_id}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              color: "#9EABB5",
                              letterSpacing: 0,
                            }}
                          >
                            {t("EmailAccountRegistrationController.firstName")}
                          </Typography>
                          <Field
                            name="first_name"
                            type="text"
                            //placeholder={t("EmailAccountRegistrationController.firstName")}
                            style={{
                              border: "1px solid #e8edf4",
                              height: "45px",
                              paddingLeft: "8px",
                              borderRadius: "2px",
                              marginTop: '8px',
                              width: '95%'
                            }}
                          />
                          {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                          {touched.first_name && errors.first_name ? (
                            <div className="error">{errors.first_name}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "Work Sans",
                              fontSize: "14px",
                              color: "#9EABB5",
                              letterSpacing: 0,
                            }}
                          >
                            {t("EmailAccountRegistrationController.lastName")}
                          </Typography>
                          <Field
                            name="last_name"
                            type="text"
                            //placeholder={t("EmailAccountRegistrationController.lastName")}
                            style={{
                              border: "1px solid #e8edf4",
                              height: "45px",
                              paddingLeft: "8px",
                              borderRadius: "2px",
                              marginTop: '8px',
                              width: '95%'
                            }}
                          />
                          {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                          {touched.last_name && errors.last_name ? (
                            <div className="error">{errors.last_name}</div>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          color: "#9EABB5",
                          letterSpacing: 0,
                          marginTop: '8px'
                        }}
                      >
                        {t("AdminConsole.email")}
                      </Typography>
                      <Field
                        name="email"
                        type="email"
                        placeholder=''
                        style={{
                          border: "1px solid #e8edf4",
                          height: "45px",
                          paddingLeft: "8px",
                          borderRadius: "2px",
                          marginTop: '8px',
                          width: '97%'
                        }}
                      />
                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                      {touched.email && errors.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          color: "#9EABB5",
                          letterSpacing: 0,
                          marginTop: '8px'
                        }}
                      >
                        Role
                      </Typography>
                      <Select
                        labelId="role_name"
                        id="role_name"
                        name="role_name"
                        value={values.role_name}
                        variant="outlined"
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        style={{
                          height: "45px",
                          paddingLeft: "8px",
                          borderRadius: "2px",
                          marginTop: '8px',
                          width: '100%'
                        }}
                      >
                        <MenuItem value="editor">Editor</MenuItem>
                        <MenuItem value="viewer">Viewer</MenuItem>
                        <MenuItem value="approver">Approver</MenuItem>
                        <MenuItem value="brand_manager">Brand Manager</MenuItem>
                      </Select>
                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                      {touched.role_name && errors.role_name ? (
                        <div className="error">{errors.role_name}</div>
                      ) : null}
                    </Grid>
                  </DialogContent>
                  <DialogActions style={{ marginRight: "20px", paddingBottom: "30px" }}>
                    <Button
                      variant="outlined"
                      style={{
                        border: "1px solid #1E3E59",
                        width: "88px",
                        height: "40px",
                        borderRadius: "2px",
                      }}
                      onClick={this.handleCreateUserDialogClose}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "13px",
                        }}
                      >
                        {t("AdminConsole.cancel")}
                      </Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        border: "1px solid #1E3E59",
                        width: "144px",
                        height: "40px",
                        borderRadius: "2px",
                        background: "#1E3E59",
                        marginLeft: "15px",
                      }}
                      type="submit"
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "13px",
                          color: "#FFFFFF",
                        }}
                      >
                        {this.state.selectedUser == null ? 'Create User' : 'Update User'}
                      </Typography>
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          />
        </Dialog>
        <Dialog
          onClose={this.handleCreateUserConfirmDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.confirmUserDialogOpen}
          classes={{ paper: this.props.classes.confirmDialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={this.props.classes.dialogTitleRoot}
          >
            <span className={this.props.classes.dialogTitle}>
              Confirmation
            </span>
            <IconButton>
              <CloseIcon onClick={this.handleCreateUserConfirmDialogClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop:"35px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#282828",
                  fontSize: "15px",
                  fontFamily: "Work Sans",
                  fontWeight: 500,
                  opacity: 1,
                  marginBottom: '2%',
                  width: '85%',
                  textAlign: 'center'
                }}
              >
                <span>
                  {this.state.selectedUser == null 
                  ? "User insertion is successful." 
                  : "User updation is successful."}
                </span><br />
              
              </Typography>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px' }}>
              <Typography
                variant="body2"
                style={{
                  color: "#282828",
                  fontSize: "16px",
                  fontFamily: "Work Sans",
                  fontWeight: 500,
                  opacity: 1,
                  marginBottom: '2%',
                  width: '85%',
                  textAlign: 'center'
                }}
              >
                <span style={{ padding: '15px', fontWeight: 'bold' }}>{this.state.createdUser?.first_name + " " + this.state.createdUser?.last_name}</span> <br />
                <span>{this.state.createdUser?.email}</span>
              </Typography>
            </div> */}
          </DialogContent>
        </Dialog>

        <Menu
                                      id="simple-menu"
                                      anchorEl={this.state.anchorEl}
                                      open={Boolean(this.state.anchorEl)}
                                      onClose={(e) => this.handleIconClose(e)}
                                    >
                                     <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) => {
                                          // (window.location.href = `/AdminConsole/user/${this.state.selectedUserId
                                          //   }?brand_name=${this.state.selectedBrandName
                                          //   }`)
                                         
                                          // console.log(_data);
                                          this.handleCreateUserDialogOpen();
                                        }
                                        }
                                      >
                                        Profile Settings
                                      </MenuItem>
                                      
                                     { this.state.selectedUser?.attributes?.is_suspended == true  ? <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) =>
                                          this.handleBlockUnblockUser(
                                            e,
                                            this.state.selectedUser.id,
                                            this.state.selectedUser.attributes.is_suspended
                                          )
                                        }
                                      >
                                      
                                       Unsuspend
                                      </MenuItem> :
                                      <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) =>
                                          this.handleBlockUnblockUser(
                                            e,
                                            this.state.selectedUser.id,
                                            this.state.selectedUser.attributes.is_suspended
                                          )
                                        }
                                      >
                                      
                                          Suspend
                                          
                                      </MenuItem>
                                    }

                                      <MenuItem
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "12px",
                                          color: "#0E0E0E",
                                          textTransform: "capitalize",
                                          letterSpacing: 0,
                                        }}
                                        onClick={(e) => this.deleteUser(e)}
                                      >
                                        Delete User
                                      </MenuItem>
                                    </Menu>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Users));
