Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.REACT_APP_API_stage = "https://abordersystem-37196-ruby.b37196.stage.us-east-1.aws.svc.builder.ai";
exports.REACT_APP_API_prod = "https://abordersystem-37196-ruby.b37196.prod.eu-central-1.aws.svc.builder.ai";

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End