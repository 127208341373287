import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Menu,
  MenuItem,
  AppBar,
  Tabs,
  Tab,
  Box,
  IconButton,
  Chip,
  TextField,
  Card,
  CardContent,
  Select,
  Divider,
} from "@material-ui/core";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import { Link, Redirect } from "react-router-dom";
import InputColor from "react-input-color";
const downIcon = require("../../../components/src/down.png");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withTranslation, Trans } from "react-i18next";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
const brandLogo = require("../assets/default-logo.png");

import ReactS3Uploader from "react-s3-uploader";
import * as SparkMD5 from 'spark-md5';

import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Camera from "react-html5-camera-photo";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  AppBarRoot: {
    background: "#FFFFFF",
    color: "#1a76b0",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "#1a76b0",
  },
  resize: {
    fontSize: "15px",
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  tableDataLogo: {
    border: "1px solid white",
    paddingLeft: "44px",
    paddingTop: "18px",
    paddingBottom: "8px",
    backgroundColor: "#707880",
    borderRadius: "26px",
  },
  menu: {
    width: "168px !important",
    height: "128px  !important",
  },
  newTemplateBtn: {
    width: "100%",
    background: "#FFFFFF",
    height: "48px",
    boxShadow: "none",
    border: "3px dotted black",
  },
  templateHeadings: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1a76b0",
  },
  templateHeadingsData: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    fontWeight: 400,
    marginLeft: "10px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  confirmInput: {
    marginTop: "15px",
  },
  companyEmail: {
    marginTop: "15px",
  },
  confirmInputStreet: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    color: "white",
  },
} as any;

interface Props {
  classes: any;
  t?: any;
  i18?: any;
}
interface S {
  tabValue: any;
  brandData: any;
  brandId: any;
  brandBillingDetails: any;
  projectData: any;
  accountTotal: any;
  brandInformationData: any;
  userInfo: any;
  anchorEl: any;
  imgs: any;
  fileToRawBinary: any;
  resp: any;
  colorPlatte:
  {
    name: any,
    selected: any
    primaryColor: any,
    secondaryColor: any
  }[],
  ThemeInfo: any;
  selectedColor: any;
  setOpen: any,
  colorPlatteIndex: any;
  primaryColorIndex: any;
  secondaryColorIndex: any;
  primaryColorMainIndex: any;
  secondaryColorMainIndex: any;
  files: [];
  isTrue: false;
}
interface SS {
  id: any;
}

const defaultPrimaryColor = '#173e59';
const defaultSecondaryColor = "#1a76b0";

class BrandDetails extends BlockComponent<Props, S, SS> {
  getBrandDataApiCallId: any;
  getBrandBillingDetailsApiCallId: any;
  getProjectByBrandApiCallId: any;
  getProjectTotalAmountApiCallId: any;
  getBrandInformationApiCallId: any;
  updateBrandDetailsApiCallId: any;
  userProfileApiCallId: any;
  getSignedURLForFileUploadId: any;
  getSignedURLForFileUploadId2: any;
  fontUploadApiCallId: any;
  getBrandThemeDetailId: any;
  fileInput: any;
  primaryColorPicker: any;
  secondaryColorPicker: any;
  updateCompanyThemeInfoId: any;
  updateCompanyBillingDetailId: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    this.fileInput = React.createRef();
    this.primaryColorPicker = React.createRef();
    this.secondaryColorPicker = React.createRef();
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      tabValue: 0,
      brandData: null,
      brandId: "",
      brandBillingDetails: null,
      projectData: [],
      accountTotal: "",
      brandInformationData: {},
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      anchorEl: null,
      imgs: "",
      fileToRawBinary: {},
      resp: null,
      colorPlatte: [
        {
          name: '',
          selected: false,
          primaryColor: defaultPrimaryColor,
          secondaryColor: defaultSecondaryColor,
        }],
      ThemeInfo: null,
      selectedColor: 0,
      setOpen: false,
      colorPlatteIndex: 0,
      primaryColorMainIndex: 0,
      primaryColorIndex: 0,
      secondaryColorMainIndex: 0,
      secondaryColorIndex: 0,
      isTrue: false,
      files: [],
    };
  }

  async componentDidMount() {
    var str = window.location.href;
    var brandId = str.split("/")[5];
    this.setState({ brandId: brandId });
    this.getBrandData(brandId);
    this.getBrandThemeDetails(brandId);
  }

  getBrandData = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandDataApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getProjectTotalAmountInfo = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectTotalAmountApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details/total_project_price?brand_id=${this.state.brandId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getBrandBillingDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandBillingDetailsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details?brand_id=${this.state.brandId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getProjectByBrand = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectByBrandApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=1&brand_id=${this.state.brandId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getBrandInformation = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandInformationApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations?brand_id=${this.state.brandId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChange = (event: any, value: any) => {
    this.setState({ tabValue: value });
  };

  addCompanyProfile = (values: any, value?: any) => {
   
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    // if(value === 'image'){
    //   const data = new FormData();
    //   const imagedata = values;
    //   const date =
    //   data.append('photo', imagedata);
    // }
    var bodyFormData = new FormData();
    values.companyName ? bodyFormData.append('name', values.companyName) : null;
    values.companyURL ? bodyFormData.append('website_url', values.companyURL) : null;
    values.companyEmail ? bodyFormData.append('email_domain', values.companyEmail) : null;
    values.companySize ? bodyFormData.append('company_size', values.companySize) : null;
    values.phoneNumber ? bodyFormData.append('phone_number', values.phoneNumber) : null;
    
    values.industry ? bodyFormData.append('industry', values.industry) : null;
    values.street ? bodyFormData.append('street', values.street) : null;
    values.postCode ? bodyFormData.append('postcode', values.postCode) : null;
    values.city ? bodyFormData.append('city', values.city) : null;
    values.country ? bodyFormData.append('country', values.country) : null;
    // console.log(bodyFormData,"data")
    const httpBody = {
      data: bodyFormData
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateBrandDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.brandData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = (item?: any) => {
    if (item == 'removeImage') {
      this.uploadImage(item)
    }
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
      imgs: "",
    });
  };

  uploadImage(responseJson: any) {

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };

    const httpBody = {
      "logo_blob_signed_id": responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSignedUrl = (file: any) => {
    console.log("File", file)
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    });
  }

  getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fontFileUpload(responseJson: any) {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };
    // const data = {
    //   // type: "email_account",
    //   attributes: values
    // };

    const httpBody = {
      // "photo_blob_signed_id" : responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
      "font_file_1_blob_signed_id": responseJson.data[0]?.signed_id
    };

    // var datas = new FormData()
    // datas.append("photo_blob_signed_id" , responseJson?.data[0]?.signed_id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fontUploadApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
      // datas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getBrandThemeDetails = (brandId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandThemeDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations?brand_id=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleChangeColorName = (e: any, index: any) => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr[index].name = e?.target?.value
    // console.log("targrt", e?.target?.value);
    this.setState({ colorPlatte: colorPlatteArr }, () => {
      console.log("colorname", this.state.colorPlatte);
    })
  }

  inputFileChanged = (e: any) => {
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
  };

  handleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    this.setState({ setOpen: false })
    console.log("takePhoto", dataUri);
    var type = dataUri.slice(5, 14);
    console.log("type", type);

    // var file = this.dataURLtoFile("dataUri",this.state.userInfo?.first_name);
    this.urltoFile(dataUri, this.state.userInfo?.first_name, type).then((file) => { console.log(file); this.getSignedUrl(file) });
    // console.log("camera file",file);
  };

  urltoFile = (url: any, filename: any, mimeType: any) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  updateCompanyBillingDetails = (values: any) => {
    console.log("billing", values);
    
   
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    var bodyFormDataUpdate = {
      // "brand_id": this.state.brandBillingDetails?.id,
      "billing_email": values.billingEmail,
      'first_name': values.contactFirstName,
      'last_name': values.contactLastName,
      'tax_id': values.taxID,
      'vat_id': values.vATID
    }
  
    var bodyFormDataAdd = {
      "brand_id" : this.state.brandId,
      "billing_email": values.billingEmail,
      'first_name': values.contactFirstName,
      'last_name': values.contactLastName,
      'tax_id': values.taxID,
      'vat_id': values.vATID
    }
    var body;
    this.state.brandBillingDetails?.id ? body = bodyFormDataUpdate : body = bodyFormDataAdd

    console.log("bodyFormData", body);

    const httpBody = {
      data: body,
    };
   console.log("billing body" ,httpBody );
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyBillingDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.brandBillingDetails?.id ?
        `bx_block_categories_sub_categories/brand_billing_details/${this.state.brandBillingDetails.id}`
        :
        `bx_block_categories_sub_categories/brand_billing_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // bodyFormData
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.brandBillingDetails?.id ?
        "PUT"
        :
        "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateThemeInfo = (values: any) => {
  
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    var bodyFormDataUpdate = {
      "primary_font": values.primaryFont,
      "secondary_font": values.secondaryFont,
      "primary_colours": {
        "color_pallete": this.state.colorPlatte,
      },
      "selected_primary_colour": this.state.colorPlatte[this.state.selectedColor].primaryColor,
      "selected_secondary_colour": this.state.colorPlatte[this.state.selectedColor].secondaryColor
    }

    var bodyFormDataAdd = {
      "brand_id": this.state.brandData?.id,
      "primary_font": values.primaryFont,
      "secondary_font": values.secondaryFont,
      "primary_colours": {
        "color_pallete": this.state.colorPlatte,
      },
      "selected_primary_colour": this.state.colorPlatte[this.state.selectedColor].primaryColor,
      "selected_secondary_colour": this.state.colorPlatte[this.state.selectedColor].secondaryColor
    }
    var body;
    this.state.ThemeInfo ? body = bodyFormDataUpdate : body = bodyFormDataAdd

    console.log("bodyFormData", body);

    const httpBody = {
      data: body,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyThemeInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.ThemeInfo ?
        `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo?.id}`
        :
        `bx_block_categories_sub_categories/brand_theme_informations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // bodyFormData
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.ThemeInfo ?
        "PUT"
        :
        "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedColor: parseInt((event.target as HTMLInputElement).value)
    }, () => {
      console.log("selectedColor", this.state.selectedColor, typeof this.state.selectedColor);
      let key = JSON.parse(localStorage.getItem("key") || `{}`)
      key.hex = this.state.colorPlatte[this.state.selectedColor].primaryColor
      localStorage.setItem("key", JSON.stringify(key));
      let secondarykey = JSON.parse(localStorage.getItem("secondarykey") || `{}`)
      secondarykey.hex = this.state.colorPlatte[this.state.selectedColor].secondaryColor
      localStorage.setItem("secondarykey", JSON.stringify(secondarykey));
      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
      for (let i = 0; i < colorPlatteArr.length; i++) {
        if (i == this.state.selectedColor) {
          colorPlatteArr[this.state.selectedColor].selected = true
        }
        else {
          colorPlatteArr[i].selected = false
        }
      }
      this.setState({ colorPlatte: colorPlatteArr }, () => {
        console.log("final color", this.state.colorPlatte);

      })
    })
  }

  triggerColorPicker = (mainColorPlatteIndex: number, index = 0) => {
    this.setState({ colorPlatteIndex: mainColorPlatteIndex });
    this.setState({ primaryColorIndex: index });
    var link : any = document.getElementById(`colorPickerPrimary${mainColorPlatteIndex}`);
    link.click();
    // this.primaryColorPicker.current.click();
  }

  triggerSecondaryColorPicker = (mainColorPlatteIndex: number, index = 0) => {
    this.setState({ colorPlatteIndex: mainColorPlatteIndex });
    this.setState({ secondaryColorIndex: index });
    var link : any = document.getElementById(`colorPickerSecondary${mainColorPlatteIndex}`);
    link.click();
    // this.secondaryColorPicker.current.click();
  }

  primaryColorChange = (e: any) => {
    console.log("color", e?.target?.value);
    const value = e?.target?.value;
    if (value) {
      const colorPlatteIndex = this.state.colorPlatteIndex;

      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;

      colorPlatteArr[colorPlatteIndex].primaryColor = value;

      this.setState(
        {
          colorPlatte: colorPlatteArr
        }, () => {
          console.log("abcs", this.state.colorPlatte);
          let key = JSON.parse(localStorage.getItem("key") || `{}`)
          key.hex = value
          localStorage.setItem("key", JSON.stringify(key));
        }
      );
    };
  }

  secondaryColorChange = (e: any) => {
    console.log("color", e?.target?.value);
    const value = e?.target?.value;
    if (value) {
      const colorPlatteIndex = this.state.colorPlatteIndex;
      const secondaryColorIndex = this.state.secondaryColorIndex;
      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;

      colorPlatteArr[colorPlatteIndex].secondaryColor = value;

      console.log(colorPlatteArr);

      this.setState(
        {
          colorPlatte: colorPlatteArr
        }, () => {
          let key = JSON.parse(localStorage.getItem("secondarykey") || `{}`)
          key.hex = value
          localStorage.setItem("secondarykey", JSON.stringify(key));
        }
      );
    };
  }

  incrementColorPallete = () => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr.push({
      name: "",
      selected: false,
      primaryColor: defaultPrimaryColor,
      secondaryColor: defaultSecondaryColor,
    })
    this.setState(
      {
        colorPlatte: colorPlatteArr
      }
    )
  }

  onChangeFont = (value: any) => {
    console.log("value23", value?.currentTarget?.name, value?.currentTarget?.value);
    if (value?.currentTarget?.name == 'primaryFont') {
      var key = localStorage.getItem("key") ? JSON.parse(localStorage.getItem("key") || `{}`) : {}
      key.font = value?.currentTarget?.value
      localStorage.setItem("key", JSON.stringify(key))
    }
    else if (value?.currentTarget?.name == 'secondaryFont') {

      var key = localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`) : {}
      key.font = value?.currentTarget?.value
      localStorage.setItem("secondarykey", JSON.stringify(key))

    }
  }

  printName(data: any) {
    return data.map((item: any) => {
      return <p style={{ textAlign: "center" }}>{item.name}</p>;
    });
  }

  checkBoxChange = (e: any) => {
    console.log(e, "e");
    this.setState({ isTrue: e.target.checked });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBrandDataApiCallId) {
        if (responseJson.data) {
          if (responseJson.data !== null) {
            this.setState({
              brandData: responseJson.data
            })
            // this.setState({
            //   ...this.state.brandData,
            //   brandData: {
            //     name: responseJson.data.attributes.name,
            //     industry: responseJson.data.attributes.industry,
            //     companySize: responseJson.data.attributes.company_size,
            //     companyUrl: responseJson.data.attributes.website_url,
            //     street: responseJson.data.attributes.street,
            //     postCode: responseJson.data.attributes.postcode,
            //     city: responseJson.data.attributes.city,
            //     country: responseJson.data.attributes.country,
            //     brandLogoUrl:
            //       responseJson.data.attributes.logo_blob_signed_id_url,
            //   },
            // });
          }
        }
      } else if (apiRequestCallId === this.getBrandBillingDetailsApiCallId) {
        if (responseJson.brand_billings.data !== null) {
          if (responseJson.brand_billings.data.length !== 0) {
            
            this.setState({
              brandBillingDetails: {
                id: responseJson.brand_billings.data[0].id,
                billingEmail:responseJson.brand_billings.data[0].attributes.billing_email,
                vat: responseJson.brand_billings.data[0].attributes.vat_id,
                tax: responseJson.brand_billings.data[0].attributes.tax_id,
                contactName: `${responseJson.brand_billings.data[0].attributes.first_name
                  } ${responseJson.brand_billings.data[0].attributes.last_name}`,
                firstName: responseJson.brand_billings.data[0].attributes.first_name,
                lastName: responseJson.brand_billings.data[0].attributes.last_name
              },
            });
          }
        }
      } else if (apiRequestCallId === this.getProjectByBrandApiCallId) {
        if (responseJson.projects) {
          if (
            responseJson.projects.data &&
            responseJson.projects.data !== null
          ) {
            let response = responseJson.projects.data;
            let tempArr = [] as any;
            response.map((_data: any) => {
              tempArr.push({
                projectName: _data.attributes.name,
                assignee: "NA",
                owner:
                  _data.attributes.creator.data !== null
                    ? _data.attributes.creator.data.attributes.first_name
                    : "NA",
                status: _data.attributes.status,
                dueDate:
                  _data.attributes.estimated_end_date !== null
                    ? _data.attributes.estimated_end_date
                    : "NA",
                budget:
                  _data.attributes.price !== null
                    ? _data.attributes.price
                    : "NA",
              });
            });
            this.setState({
              projectData: tempArr,
            });
          }
        }
      } else if (apiRequestCallId === this.getProjectTotalAmountApiCallId) {
        this.setState({
          accountTotal: responseJson.project_total,
        });
      } else if (apiRequestCallId === this.getBrandInformationApiCallId) {
        console.clear();
        console.log(responseJson);
        if (responseJson.brand_themes) {
          if (
            responseJson.brand_themes.data !== null &&
            responseJson.brand_themes.data.length !== 0
          ) {
            this.setState({
              brandInformationData: {
                ...this.state.brandInformationData,

                colorPalleteName:
                  responseJson.brand_themes.data[0].attributes
                    .colour_palette_name,
                primaryColors:
                  responseJson.brand_themes.data[0].attributes
                    .primary_colours !== null
                    ? responseJson.brand_themes.data[0].attributes
                      .primary_colours
                    : "",
                selectedPrimaryColor:
                  responseJson.brand_themes.data[0].attributes
                    .selected_primary_colour,
                secondaryColors:
                  responseJson.brand_themes.data[0].attributes
                    .secondary_colours !== null
                    ? responseJson.brand_themes.data[0].attributes
                      .secondary_colours
                    : "",
                selectedSecondaryColor:
                  responseJson.brand_themes.data[0].attributes
                    .selected_secondary_colour,
                primaryFont:
                  responseJson.brand_themes.data[0].attributes.primary_font !==
                    null
                    ? responseJson.brand_themes.data[0].attributes.primary_font
                    : "",
                secondaryFont:
                  responseJson.brand_themes.data[0].attributes
                    .secondary_font !== null
                    ? responseJson.brand_themes.data[0].attributes
                      .secondary_font
                    : "",
              },
            });
          }
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateBrandDetailsApiCallId) {
      
        this.getBrandData(this.state.brandId);
        toast.success("Saved successfully")
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.userProfileApiCallId) {
        if (message.properties?.RestAPIResponceSuccessMessage?.data) {
          this.getBrandData(this.state.brandId);
          toast.success("Saved successfully")
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          if (apiRequestCallId != null) {
            /// search live
            if (apiRequestCallId && responseJson) {
              console.log("Dtaassss", this.state.fileToRawBinary, responseJson)
              this.setState({ resp: responseJson }, () => {
                this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
              })
              // toast.success("Project create successfully");
            }
          }
        } else if (responseJson.errors) {
          toast.error(errorResponse.errors[0].projects);
        } else if (responseJson.error) {
          toast.error(errorResponse.error);
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId2) {
        if (this.state.tabValue === 2) {
          this.fontFileUpload(this.state.resp)
        }
        else if (this.state.tabValue === 0) {
          this.uploadImage(this.state.resp)
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.fontUploadApiCallId) {
        if (message.properties?.RestAPIResponceSuccessMessage?.data) {
          this.getBrandThemeDetails(this.state.brandId);
          toast.success("Saved successfully")
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandThemeDetailId) {
        console.log('message.properties', message.properties?.RestAPIResponceSuccessMessage?.brand_themes?.data[0]);
        const theme_info = message.properties?.RestAPIResponceSuccessMessage?.brand_themes?.data[0];
        this.setState({ ThemeInfo: theme_info });
        const colorArr = [];
        const secondaryColorArr = [];
        for (const key in this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete) {
          if (Object.prototype.hasOwnProperty.call(this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete, key)) {
            const element = this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete[key];
            if (element.selected == true) {
              this.setState({
                selectedColor: parseInt(key)
              }, () => {
                console.log("fggg", this.state.selectedColor, typeof this.state.selectedColor, typeof key);

              })
            }
            colorArr.push({
              name: element?.name,
              selected: element?.selected,
              primaryColor: element.primaryColor,
              secondaryColor: element.secondaryColor

            });
          }
        }

        if (colorArr.length > 0) this.setState({ colorPlatte: colorArr });

        console.log("this.state.ThemeInfo", this.state.ThemeInfo);
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyThemeInfoId) {
      
        var responseJSON = message.properties?.RestAPIResponceSuccessMessage;
        if (responseJSON.errors) {
          if (responseJSON.errors[0]?.brand_theme_information) {
            toast.error(responseJSON.errors[0]?.brand_theme_information);
          }
        } else {
          this.setState({ ThemeInfo: message.properties?.RestAPIResponceSuccessMessage?.data })
          console.log("this.state.ThemeInfo1", this.state.ThemeInfo);
          toast.success("Saved successfully")
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyBillingDetailId) {
      
        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.errors) {
          if (responseJson.errors[0]?.role) {
            toast.error(responseJson.errors[0]?.role);
          }
        } else {
          toast.success("Saved successfully");
        }
        this.getBrandBillingDetails();
      }
    }
  }

  handleExportClick = () => {
    if (this.state.tabValue == 0) {
      let path = `/bx_block_data_import_export_csv/brand_general_info_exports?brand_id=${this.state.brandId
        }&`;
      this.exportList(path);
    } else if (this.state.tabValue == 1) {
      let path = `/bx_block_data_import_export_csv/brand_billing_detail_exports?brand_id=${this.state.brandId
        }&`;
      this.exportList(path);
    } else {
      let path = `/bx_block_data_import_export_csv/brand_theme_exports?brand_id=${this.state.brandId
        }&`;
      this.exportList(path);
    }
  };

  exportList = (path: any) => {
    var url = `${currentUrl
      }${path}token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  handleBillingTabClick = () => {
    this.getBrandBillingDetails();
    this.getProjectByBrand();
    this.getProjectTotalAmountInfo();
  };

  handleBrandInformationClick = () => {
    this.getBrandInformation();
  };

  render() {
    const { classes, t } = this.props;
    const _this = this;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = defaultPrimaryColor;
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#1a76b0";
    }

    return (
      <>
        <Grid container style={{ paddingLeft: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Dashboard
              </Typography>

              <Link
                to="/AdminConsole/Brands"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Brands
              </Link>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {this.state.brandData?.attributes?.name}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {this.state.brandData?.attributes?.name}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.handleExportClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        Export
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    {/* <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder="search for user"
                    /> */}
                  </Grid>
                  {/* <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AppBar position="static" className={this.props.classes.AppBarRoot}>
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChange}
                classes={{
                  indicator: this.props.classes.indicator,
                }}
              >
                <Tab
                  label={
                    <span
                      style={{
                        color: "#1a76b0",
                        fontFamily: "Work Sans",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      General
                    </span>
                  }
                />
                <Tab
                  label={
                    <span
                      style={{
                        color: "#1a76b0",
                        fontFamily: "Work Sans",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Billing Details
                    </span>
                  }
                  onClick={this.handleBillingTabClick}
                />
                <Tab
                  label={
                    <span
                      style={{
                        color: "#1a76b0",
                        fontFamily: "Work Sans",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Brand Information
                    </span>
                  }
                  onClick={this.handleBrandInformationClick}
                />
              </Tabs>
            </AppBar>

            <Grid container>
              {this.state.tabValue == 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      paddingTop: "20px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <Formik
                          initialValues={{
                            companyName: this.state.brandData?.attributes?.name ? this.state.brandData?.attributes?.name : "",
                            companySize: this.state.brandData?.attributes?.company_size ? this.state.brandData?.attributes?.company_size : "",
                            industry: this.state.brandData?.attributes?.industry ? this.state.brandData?.attributes?.industry : "",
                            companyEmail: this.state.brandData?.attributes?.email_domain ? this.state.brandData?.attributes?.email_domain : '',
                            companyURL: this.state.brandData?.attributes?.website_url ? this.state.brandData?.attributes?.website_url : '',
                            phoneNumber :this.state.brandData?.attributes?.phone_number ? this.state.brandData?.attributes?.phone_number : '',
                            street: this.state.brandData?.attributes?.street ? this.state.brandData?.attributes?.street : '',
                            postCode: this.state.brandData?.attributes?.postcode ? this.state.brandData?.attributes?.postcode : '',
                            city: this.state.brandData?.attributes?.city ? this.state.brandData?.attributes?.city : '',
                            country: this.state.brandData?.attributes?.country ? this.state.brandData?.attributes?.country : '',
                          }}
                          enableReinitialize
                          validateOnBlur={false}
                          validationSchema={Yup.object().shape({
                            companyName: Yup.string()
                              .required("company name is required"),
                            companySize: Yup.string(),
                            industry: Yup.string(),
                            companyEmail: Yup.string()
                              .required("company domain is required"),
                            companyURL: Yup.string()
                              .required("company URL is required"),
                              phoneNumber : Yup.string(),
                            street: Yup.string(),
                            postCode: Yup.string(),
                            city: Yup.string(),
                            country: Yup.string(),
                          })}
                          onSubmit={(values) => {
                            console.log(values);
                            this.addCompanyProfile(values)
                          }}
                          render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            isValid,
                            handleBlur,
                            setFieldTouched,
                            isSubmitting,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <TextField
                                name="companyName"
                                type="text"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                size="small"
                                fullWidth
                                label={"Company Name"}
                                value={values.companyName}
                                className={classes.confirmInput}
                                variant="outlined"
                                helperText={
                                  touched.companyName &&
                                    errors.companyName ? (
                                    <Trans>
                                      CompanyProfile.companyNameRequired
                                    </Trans>

                                  ) : (
                                    ""
                                  )
                                }
                                error={
                                  touched.companyName && errors.companyName
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Grid container>
                                <Grid item xs={6}>
                                  <TextField
                                    name="companySize"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    size="small"
                                    SelectProps={{
                                      native: true,
                                    }}
                                    className={classes.confirmInput}
                                    style={{ width: "95%" }}
                                    label={"Company Size"}
                                    value={values.companySize}
                                    variant="outlined"
                                    helperText={
                                      errors.companySize &&
                                        touched.companySize ? (
                                        <Trans>
                                          CompanyProfile.companyRequired
                                        </Trans>
                                      ) : (
                                        ""
                                      )
                                    }
                                    error={
                                      touched.companySize &&
                                        errors.companySize
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    name="industry"
                                    select
                                    SelectProps={{
                                      native: true,
                                    }}
                                    size="small"
                                    className={classes.confirmInput}
                                    fullWidth
                                    label={"Industry"}
                                    value={values.industry}
                                    variant="outlined"
                                    helperText={
                                      touched.industry && errors.industry ? (
                                        <Trans>
                                          CompanyProfile.industryRequired
                                        </Trans>
                                      ) : (
                                        ""
                                      )
                                    }
                                    error={
                                      touched.industry && errors.industry
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option aria-label="None" value="" />
                                    <option value="Services">Services</option>
                                    <option value="Entertainment / Gastro / Tourism / Culture">Entertainment / Gastro / Tourism / Culture</option>
                                    <option value="Real Estate / Construction / Architecture">Real Estate / Construction / Architecture</option>
                                    <option value="Industry">Industry</option>
                                    <option value="Industrial machinery / mechanical engineering / electronics / medical technology">Industrial machinery / mechanical engineering / electronics / medical technology</option>
                                    <option value="LEH / FMCG / Retail">LEH / FMCG / Retail</option>
                                    <option value="Luxury / Fashion">Luxury / Fashion</option>
                                    <option value="Software / IT / Digital">Software / IT / Digital</option>
                                    <option value="Advertising / Marketing / Agencies / Publishing">Advertising / Marketing / Agencies / Publishing</option>
                                  </TextField>
                                </Grid>
                              </Grid>
                              <TextField
                                id="outlined-multiline-static"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                label={"Company Email Domain"}
                                name="companyEmail"
                                fullWidth
                                value={values.companyEmail}
                                variant="outlined"
                                className={classes.companyEmail}
                                size="small"
                                helperText={
                                  touched.companyEmail && errors.companyEmail ? (
                                    <Trans>
                                      CompanyProfile.companyEmailDomainRequired
                                    </Trans>
                                  ) : (
                                    ""
                                  )
                                }
                                error={
                                  touched.companyEmail && errors.companyEmail
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <TextField
                                id="outlined-multiline-static"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                label={"Company URL"}
                                name="companyURL"
                                fullWidth
                                value={values.companyURL}
                                variant="outlined"
                                className={classes.confirmInputStreet}
                                size="small"
                                helperText={
                                  touched.companyURL && errors.companyURL ? (
                                    <Trans>
                                      CompanyProfile.companyUrlRequired
                                    </Trans>
                                  ) : (
                                    ""
                                  )
                                }
                                error={
                                  touched.companyURL && errors.companyURL
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

<TextField
                                id="outlined-multiline-static"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                label={"Phone Number"}
                                name="phoneNumber"
                                fullWidth
                                value={values.phoneNumber}
                                variant="outlined"
                                className={classes.confirmInputStreet}
                                size="small"
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber ? (
                                    <Trans>
                                      CompanyProfile.phoneNumberRequired
                                    </Trans>
                                  ) : (
                                    ""
                                  )
                                }
                                error={
                                  touched.phoneNumber && errors.phoneNumber
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <p style={{ fontSize: "20px" }}>{t('AdminConsole.address')}</p>
                              <TextField
                                name="street"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                type="text"
                                size="small"
                                className={classes.confirmInput}
                                fullWidth
                                label={"Street"}
                                value={values.street}
                                variant="outlined"
                                helperText={
                                  touched.street && errors.street
                                    ? (
                                      <Trans>
                                        CompanyProfile.streetRequired
                                      </Trans>
                                    )
                                    : ""
                                }
                                error={
                                  touched.street && errors.street
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Grid container>
                                <Grid item xs={6}>
                                  <TextField
                                    name="postCode"
                                    type="text"
                                    size="small"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    className={classes.confirmInput}
                                    style={{ width: "95%" }}
                                    label={"Zip Code"}
                                    value={values.postCode}
                                    variant="outlined"
                                    helperText={
                                      errors.postCode && touched.postCode
                                        ? (
                                          <Trans>
                                            CompanyProfile.postCodeRequied
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.postCode && errors.postCode
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="city"
                                    type="text"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    size="small"
                                    className={classes.confirmInput}
                                    fullWidth
                                    label={"City"}
                                    value={values.city}
                                    variant="outlined"
                                    helperText={
                                      touched.city && errors.city
                                        ? (
                                          <Trans>
                                            CompanyProfile.cityRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.city && errors.city
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                              </Grid>
                              <TextField
                                name="country"
                                type="text"
                                size="small"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                className={classes.confirmInput}
                                fullWidth
                                label={"Country"}
                                value={values.country}
                                variant="outlined"
                                helperText={
                                  touched.country && errors.country
                                    ? (
                                      <Trans>
                                        CompanyProfile.countryRequired
                                      </Trans>
                                    )
                                    : ""
                                }
                                error={
                                  touched.country && errors.country
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {(this.state.userInfo?.role?.data?.attributes?.name === "superadmin" || this.state.userInfo?.role?.data?.attributes?.permission === "editor" || 
                                this.state.userInfo?.role?.data?.attributes?.permission === "approver" || this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                <Button
                                  className={classes.button}
                                  style={{ backgroundColor: colorToBeChanged }}
                                  type="submit"
                                  variant="contained"
                                >
                                  {'SAVE CHANGES'}
                                </Button>
                                :
                                null
                              }
                            </form>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={0}>
                          <Grid item xs={8}>
                            <div style={{ marginLeft: "40%" }}>
                              <Grid container spacing={0}>
                                <Grid item xs={6}>
                                  {/* <img src={userImage} style={{width:"100%"}} /> */}
                                  <img
                                    src={this.state.brandData?.attributes?.logo_blob_signed_id_url ? this.state.brandData?.attributes?.logo_blob_signed_id_url : brandLogo}
                                    alt="img"
                                    style={{ width: "96px", height: "96px", borderRadius: "50%", border: "16px solid #f8f9fd" }}
                                  />
                                </Grid>
                                {(this.state.userInfo?.role?.data?.attributes?.name === "superadmin" || this.state.userInfo?.role?.data?.attributes?.permission === "editor" || 
                                  this.state.userInfo?.role?.data?.attributes?.permission === "approver" || this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                  <Grid item xs={6}>
                                    <ArrowDropDownCircleIcon
                                      style={{
                                        fontSize: "30px",
                                        marginTop: "39%",
                                        marginLeft: "-10px",
                                        color: "#d2daf8",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        this.handleClick(e);
                                      }}
                                    />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={this.state.anchorEl}
                                      keepMounted
                                      open={Boolean(this.state.anchorEl)}
                                      onClose={() => {
                                        this.handleClose();
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) => {
                                          this.handleClose("uploadImage");
                                        }}
                                        style={{ alignItems: "center", color: secondaryColorToBeChanged }}
                                        className={classes.dropDown}
                                      >
                                        <span style={{ marginLeft: "-3px" }}>
                                          <label
                                            htmlFor="upload"
                                            className="label_tags"
                                          >
                                            <ReactS3Uploader
                                              type="file"
                                              id="upload"
                                              name="pic"
                                              getSignedUrl={this.getSignedUrl}
                                              uploadRequestHeaders={{
                                                'x-amz-acl': 'public-read'
                                              }}
                                              s3path="/uploads/"
                                              contentDisposition="auto"
                                              accept="image/jpg, image/jpeg, image/png"
                                              capture={true}
                                              ref={this.fileInput}
                                              onChange={(event: any) =>
                                                this.inputFileChanged(event)
                                              }
                                              style={{
                                                visibility: "hidden",
                                                position: "absolute",
                                              }}
                                            />
                                            <span>
                                              <PublishOutlinedIcon
                                                style={{
                                                  fontSize: "20px",
                                                  marginRight: "10px",
                                                  color: colorToBeChanged,
                                                  marginBottom: "-5px"
                                                }}
                                              />
                                            </span>
                                            <span>{'Upload Image'} </span>
                                          </label>
                                        </span>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) => {
                                          this.handleClose("removeImage");
                                        }}
                                        className={classes.dropDown}
                                        style={{ color: secondaryColorToBeChanged }}
                                      >
                                        <RemoveCircleOutlineIcon
                                          style={{
                                            fontSize: "20px",
                                            marginRight: "10px",
                                            color: colorToBeChanged,
                                          }}
                                        />{" "}
                                        {'Remove Image'}
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          this.setState({ setOpen: true });
                                        }}
                                        className={classes.dropDown}
                                        style={{ color: secondaryColorToBeChanged }}
                                      >
                                        {" "}
                                        <CameraAltOutlinedIcon
                                          style={{
                                            fontSize: "20px",
                                            marginRight: "10px",
                                            color: colorToBeChanged,
                                          }}
                                        />
                                        {'Take Picture'}
                                      </MenuItem>
                                    </Menu>
                                  </Grid>
                                  :
                                  null
                                }
                              </Grid>
                              <p style={{ fontSize: "18px" }}>
                                {'My Avatar'}
                              </p>
                              <p style={{ fontSize: "14px" }}>
                                {" "}
                                {' Your photo should be cool and may use transparency'}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={4} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : this.state.tabValue == 1 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "30px",
                      paddingTop: "20px",
                    }}
                  >
                    <Formik
                      initialValues={{
                        companyName: this.state.brandData?.attributes?.name ? this.state.brandData?.attributes?.name : "",
                        billingEmail: this.state.brandBillingDetails?.billingEmail ? this.state.brandBillingDetails?.billingEmail : "",
                        contactFirstName: this.state.brandBillingDetails?.firstName ? this.state.brandBillingDetails?.firstName : "",
                        contactLastName: this.state.brandBillingDetails?.lastName ? this.state.brandBillingDetails?.lastName : "",
                        vATID: this.state.brandBillingDetails?.vat ? this.state.brandBillingDetails?.vat : "",
                        taxID: this.state.brandBillingDetails?.tax ? this.state.brandBillingDetails?.tax : "",
                      }}
                      enableReinitialize
                      validateOnBlur={false}
                      validationSchema={Yup.object().shape({
                        companyName: Yup.string()
                          .required("Company Name is required"),
                        billingEmail: Yup.string().email('Invalid billing email').required('billing email is required'),
                        contactFirstName: Yup.string().required("contact first name is required"),
                        contactLastName: Yup.string().required("contact last name is required"),
                        vATID: Yup.string(),
                        taxID: Yup.string(),
                      })}
                      onSubmit={(values) => {
                       
                        console.log(values);
                        this.updateCompanyBillingDetails(values)
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        isValid,
                        handleBlur,
                        setFieldTouched,
                        isSubmitting,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                borderTop: "1px solid #F1F1F1",
                                borderBottom: "1px solid #F1F1F1",
                                padding: "20px 0 20px 0",
                              }}
                            >
                              <Grid container direction="row">
                                <Grid item xs={4}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        {t("AdminConsole.organization")}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="companyName"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.companyName && errors.companyName ? (
                                        <div className="error">{errors.companyName}</div>
                                      ) : null}
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        Billing Email
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="billingEmail"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.billingEmail && errors.billingEmail ? (
                                        <div className="error">{errors.billingEmail}</div>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={3}
                                      style={{
                                        padding: "0px",
                                        paddingTop: "7px",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        VAT ID
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="vATID"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.vATID && errors.vATID ? (
                                        <div className="error">{errors.vATID}</div>
                                      ) : null}
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        Tax ID
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="taxID"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.taxID && errors.taxID ? (
                                        <div className="error">{errors.taxID}</div>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={4}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        Contact First Name
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="contactFirstName"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.contactFirstName && errors.contactFirstName ? (
                                        <div className="error">{errors.contactFirstName}</div>
                                      ) : null}
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="body2"
                                        className={classes.templateHeadings}
                                      >
                                        Contact Last Name:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Field
                                        name="contactLastName"
                                        type="text"
                                        style={{ padding: '1%', width: '85%' }}
                                      />
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.contactLastName && errors.contactLastName ? (
                                        <div className="error">{errors.contactLastName}</div>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid container
                                  direction="row"
                                  justify="center"
                                  alignItems="center">
                                  <Grid>
                                    <Button
                                      className={classes.button}
                                      style={{ backgroundColor: colorToBeChanged }}
                                      type="submit"
                                      variant="contained"
                                    >
                                      {'SAVE CHANGES'}
                                    </Button>
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    />
                    <Grid item xs={12} style={{ marginTop: "30px" }}>
                      <TableContainer
                        style={{
                          background: "#F5F6FA",
                          paddingLeft: "20px",
                          paddingRight: "10px",
                        }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "30%",
                                  paddingLeft: "35px",
                                }}
                              >
                                {t("AdminConsole.projectName")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "15%",
                                }}
                              >
                                {t("AdminConsole.assignee")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "15%",
                                }}
                              >
                                {t("AdminConsole.owner")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "15%",
                                }}
                              >
                                {t("AdminConsole.status")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "15%",
                                }}
                              >
                                {t("AdminConsole.dueDate")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Work Sans",
                                  color: "#9EABB5",
                                  textTransform: "uppercase",
                                  opacity: 1,
                                  borderBottom: "0px",
                                  width: "10%",
                                }}
                              >
                                {t("AdminConsole.budget")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {this.state.projectData.map(
                            (_data: any, idx: any) => (
                              <>
                                <TableBody
                                  style={{ background: "white" }}
                                  key={idx}
                                >
                                  <TableCell
                                    style={{
                                      padding: "35px",
                                      border: "0px",
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="body2"
                                          className={classes.brandNameData}
                                        >
                                          {_data.projectName}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell style={{ border: "0px" }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableData}
                                    >
                                      {_data.assignee}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: "0px" }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableData}
                                    >
                                      {_data.owner}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: "0px" }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableData}
                                    >
                                      {_data.status}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: "0px" }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableData}
                                    >
                                      {_data.dueDate}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: "0px" }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableData}
                                    >
                                      {_data.budget}
                                    </Typography>
                                  </TableCell>
                                </TableBody>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ height: "25px" }}
                                  />
                                </Grid>
                              </>
                            )
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        borderTop: "1px solid #F1F1F1",
                        borderBottom: "1px solid #F1F1F1",
                        padding: "20px 0 20px 0",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={9} />
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={8}>
                              <p>{t("AdminConsole.accountTotal")}:</p>
                            </Grid>
                            <Grid item xs={4}>
                              <p>$ {this.state.accountTotal}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <div style={{ paddingBottom: "50px" }}>
                        <Formik
                          initialValues={{
                            primaryFont: this.state.ThemeInfo?.attributes?.primary_font ? this.state.ThemeInfo.attributes.primary_font : "",
                            secondaryFont: this.state.ThemeInfo?.attributes?.secondary_font ? this.state.ThemeInfo.attributes.secondary_font : "",
                          }}
                          validateOnBlur={false}
                          validationSchema={Yup.object().shape({
                            primaryFont: Yup.string(),
                            secondaryFont: Yup.string(),
                          })}
                          onSubmit={(values) => {
                            console.log(values);
                            this.updateThemeInfo(values)
                          }}
                          render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            isValid,
                            handleBlur,
                            setFieldTouched,
                            isSubmitting,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              {/* ================================================================== */}
                              <RadioGroup value={this.state.selectedColor} onChange={(e) => this.handleChangeRadio(e)}>
                                {this.state.colorPlatte?.length > 0 && this.state.colorPlatte.map((mainColorPlatte, mainColorPlatteIndex: number) =>
                                  <div key={mainColorPlatteIndex}>
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "15px"
                                    }}>
                                      <Radio value={mainColorPlatteIndex} color="primary" />
                                      <TextField
                                        name="nameColorPalatte"
                                        type="text"
                                        size="small"
                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                          }
                                        }}
                                        style={{ marginTop: "0px" }}
                                        value={mainColorPlatte.name}
                                        fullWidth
                                        label={'Name Colour Pallet'}
                                        className={classes.confirmInput}
                                        variant="outlined"
                                        onChange={(e) => this.handleChangeColorName(e, mainColorPlatteIndex)}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div style={{ marginTop: "40px", paddingLeft: "34px" }}>
                                      <p style={{ fontSize: "20px" }}>{'Colour'}</p>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 0.6)",
                                        }}
                                      >
                                        {'Add primary Colour'}
                                      </p>
                                      <div>
                                        <Grid container spacing={1}>
                                          <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "center" }}>
                                            <Box
                                              p={2}
                                              style={{
                                                backgroundColor: "#efefef",
                                                paddingLeft: "34px"

                                              }}
                                              onClick={() => this.triggerColorPicker(mainColorPlatteIndex)}
                                            >
                                              {mainColorPlatte?.primaryColor}
                                            </Box>

                                            <Box
                                                  p={2}
                                                  style={{
                                                    backgroundColor: mainColorPlatte?.primaryColor,
                                                    paddingLeft: "34px",
                                                    width: "25%",
                                                    marginLeft: "16px",
                                                    height: "8px",

                                                  }}

                                                >

                                                </Box>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>
                                    <input
                                      style={{
                                        opacity: 0
                                      }}
                                      id={`colorPickerPrimary${mainColorPlatteIndex}`}
                                      ref={this.primaryColorPicker}
                                      type="color"
                                      defaultValue={mainColorPlatte.primaryColor ? mainColorPlatte.primaryColor : defaultPrimaryColor}
                                      onChange={this.primaryColorChange}
                                    />
                                    <div style={{ paddingLeft: "34px" }}>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 0.6)",
                                        }}
                                      >
                                        {'Add Secondary Colour'}
                                      </p>
                                    </div>
                                    <Grid container spacing={1} style={{ paddingLeft: "34px" }}>
                                      <Grid item xs={12} sm={8}  style={{ display: "flex", alignItems: "center" }}>
                                        <Box
                                          p={2}
                                          style={{
                                            backgroundColor: "#efefef",
                                            paddingLeft: "34px"
                                          }}
                                          onClick={() => this.triggerSecondaryColorPicker(mainColorPlatteIndex)}
                                        >
                                          {mainColorPlatte?.secondaryColor}
                                        </Box>
                                        <Box
                                              p={2}
                                              style={{
                                                backgroundColor: mainColorPlatte?.secondaryColor,
                                                paddingLeft: "34px",
                                                width: "25%",
                                                marginLeft: "16px",
                                                height: "8px",

                                              }}

                                            >

                                            </Box>
                                      </Grid>
                                    </Grid>
                                    <div style={{
                                      marginTop: "17px",
                                    }}>
                                      <input
                                        style={{
                                          opacity: 0
                                        }}
                                        id={`colorPickerSecondary${mainColorPlatteIndex}`}
                                        ref={this.secondaryColorPicker}
                                        type="color"
                                        defaultValue={mainColorPlatte.secondaryColor ? mainColorPlatte.secondaryColor : defaultSecondaryColor}
                                        onChange={this.secondaryColorChange}
                                      />
                                    </div>
                                  </div>)
                                }
                              </RadioGroup>
                              {((this.state.userInfo?.role?.data?.attributes?.name === "superadmin" || this.state.userInfo?.role?.data?.attributes?.permission === "editor"
                               || this.state.userInfo?.role?.data?.attributes?.permission === "approver" || this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") && this.state.colorPlatte.length < 5) ?
                                <Button variant="contained" className={classes.button2} style={{
                                  alignContent: "center", backgroundColor: 'white',
                                  color: '#419bf9',
                                  padding: 20, fontSize: 16, fontWeight: 'bold',
                                  borderWidth: 1,
                                  borderColor: '#419bf9',
                                  borderRadius: 10,
                                  borderStyle: 'dashed',
                                  fontVariant: "initial",
                                  textTransform: 'none',
                                  marginTop: "7px",
                                  width: "100%",
                                  marginLeft: '34px'
                                }} color="primary"
                                  onClick={this.incrementColorPallete}
                                > + Add Colour Palette
                                </Button> : null
                              }
                              {/* =============================================================== */}
                              <div style={{
                                marginTop: "40px",
                                borderTop: "2px solid #efefef",
                                marginLeft: '34px'
                              }}>
                                <p style={{ fontSize: "20px" }}> {'Fonts'}</p>
                              </div>
                              <TextField
                                name="primaryFont"
                                type="text"
                                size="small"
                                select
                                style={{ marginLeft: '34px' }}
                                SelectProps={{
                                  native: true,
                                }}
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                value={values.primaryFont}
                                fullWidth
                                label={'Primary Font'}
                                className={classes.confirmInput}
                                variant="outlined"
                                helperText={
                                  touched.primaryFont && errors.primaryFont
                                    ? (
                                      <Trans>
                                        CompanyProfile.primaryFontsRequired
                                      </Trans>
                                    )
                                    : ""
                                }
                                error={
                                  touched.primaryFont && errors.primaryFont
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleChange(e)
                                  this.onChangeFont(e)
                                }}
                                onBlur={handleBlur}
                              >
                                <option aria-label="None" />
                                <option value="Arial">Arial</option>
                                <option value="Times New Roman">Times New Roman</option>
                                <option value="Times">Times</option>
                                <option value="Courier New">Courier New</option>
                                <option value="Courier">Courier</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Palatino">Palatino</option>
                                <option value="Garamond">Garamond</option>
                                <option value="Bookman">Bookman</option>
                                <option value="Tahoma">Tahoma</option>
                                <option value="Trebuchet MS">Trebuchet MS</option>
                                <option value="Arial Black">Arial Black</option>
                                <option value="Impact">Impact</option>
                                <option value="Comic Sans MS">Comic Sans MS</option>
                              </TextField>
                              <TextField
                                name="secondaryFont"
                                type="text"
                                style={{ marginLeft: '34px' }}
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                size="small"
                                value={values.secondaryFont}
                                fullWidth
                                label={'Secondary Font'}
                                className={classes.confirmInput}
                                variant="outlined"
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                helperText={
                                  touched.secondaryFont &&
                                    errors.secondaryFont
                                    ? (
                                      <Trans>
                                        CompanyProfile.secondaryRequired
                                      </Trans>
                                    )
                                    : ""
                                }
                                error={
                                  touched.secondaryFont &&
                                    errors.secondaryFont
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleChange(e)
                                  this.onChangeFont(e)

                                }}
                                onBlur={handleBlur}
                              >
                                <option aria-label="None" />
                                <option value="Arial">Arial</option>
                                <option value="Times New Roman">Times New Roman</option>
                                <option value="Times">Times</option>
                                <option value="Courier New">Courier New</option>
                                <option value="Courier">Courier</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Palatino">Palatino</option>
                                <option value="Garamond">Garamond</option>
                                <option value="Bookman">Bookman</option>
                                <option value="Tahoma">Tahoma</option>
                                <option value="Trebuchet MS">Trebuchet MS</option>
                                <option value="Arial Black">Arial Black</option>
                                <option value="Impact">Impact</option>
                                <option value="Comic Sans MS">Comic Sans MS</option>
                              </TextField>

                              {(this.state.userInfo?.role?.data?.attributes?.name === "superadmin" || this.state.userInfo?.role?.data?.attributes?.permission === "editor" 
                               || this.state.userInfo?.role?.data?.attributes?.permission === "approver" || this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                <>
                                  <div style={{ marginTop: "40px", marginLeft: "34px" }}>
                                    {/* <div className={classes.fileNameDisplay}> */}
                                    {/* {this.state.files.map(item=>{
       return <p>{item}</p>} */}
                                    {this.printName(this.state.files)}
                                    {/* {this.state.files.map(item=>{
   return<p style={{textAlign:'center'}}>{item.name}</p>
 })} */}
                                    {/* </div> */}

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.isTrue}
                                          onChange={this.checkBoxChange}
                                          color="primary"
                                        />
                                      }
                                      label={'I agree that, I have all the right to use the uploaded font'}
                                    />
                                  </div>
                                  <div>
                                    <Button variant="contained" className={classes.button2} style={{
                                      alignContent: "center", backgroundColor: 'white',
                                      color: '#419bf9',
                                      padding: 20, fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: '#419bf9',
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginTop: "7px",
                                      width: "100%",
                                      marginLeft: '34px'
                                    }} color="primary"
                                      onClick={() => this.fileInput.click()}
                                    >
                                      <ReactS3Uploader
                                        type="file"
                                        id="upload"
                                        name="pic"
                                        getSignedUrl={this.getSignedUrl}
                                        uploadRequestHeaders={{
                                          'x-amz-acl': 'public-read'
                                        }}
                                        s3path="/uploads/"
                                        contentDisposition="auto"
                                        accept="**"
                                        capture={true}
                                        inputRef={cmp => this.fileInput = cmp}
                                        onChange={(event: any) =>
                                          this.inputFileChanged(event)
                                        }
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                        }}
                                      />
                                      + Upload your custom font
                                    </Button>
                                  </div>
                                </> : null
                              }
                              {(this.state.userInfo?.role?.data?.attributes?.name === "superadmin" || this.state.userInfo?.role?.data?.attributes?.permission === "editor" 
                                || this.state.userInfo?.role?.data?.attributes?.permission === "approver" || this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                <Button
                                  className={classes.button}
                                  style={{ backgroundColor: colorToBeChanged, marginLeft: '34px' }}
                                  type="submit"
                                  variant="contained"
                                >
                                  {'SAVE CHANGES'}
                                </Button>
                                :
                                null
                              }
                            </form>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </>
                // <>
                //   <Grid item xs={12} style={{ marginTop: "30px" }}>
                //     <Grid container>
                //       <Grid item xs={2} />
                //       <Grid item xs={4}>
                //         <Grid container spacing={2}>
                //           <Grid item xs={12}>
                //             <Typography
                //               variant="body2"
                //               style={{
                //                 fontSize: "14px",
                //                 fontFamily: "Work Sans",
                //                 color: "#1a76b0",
                //               }}
                //             >
                //               Name Color Pallete
                //             </Typography>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Input
                //               id="input-with-icon-adornment"
                //               style={{
                //                 border: "1px solid #A8A8A8",
                //                 background: "#ffffff",
                //                 borderBottom: "1px solid #A8A8A8",
                //                 boxSizing: "border-box",
                //                 width: "300px",
                //                 outline: "none",
                //                 height: "45px",
                //                 borderRadius: "3px",
                //               }}
                //               disableUnderline={true}
                //               value={
                //                 this.state.brandInformationData.colorPalleteName
                //               }
                //             />
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Typography
                //               variant="body2"
                //               style={{
                //                 fontSize: "14px",
                //                 fontFamily: "Work Sans",
                //                 color: "#1a76b0",
                //               }}
                //             >
                //               Add Primary Colours
                //             </Typography>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Grid container direction="row" spacing={3}>
                //               <Grid item>
                //                 <Card
                //                   style={{
                //                     width: "88px",
                //                   }}
                //                 >
                //                   <CardContent
                //                     style={{
                //                       backgroundColor: "#efefef",
                //                       paddingLeft: "10px",
                //                     }}
                //                   >
                //                     <Typography
                //                       variant="body2"
                //                       style={{
                //                         paddingTop: "10px",
                //                         fontSize: "10px",
                //                         fontFamily: "Work Sans",
                //                         lineHeight: 2,
                //                       }}
                //                     >
                //                       #EFEFEF
                //                     </Typography>
                //                   </CardContent>
                //                 </Card>
                //               </Grid>

                //               <Grid item>
                //                 <InputColor
                //                   initialValue="#173e59"
                //                   placement="right"
                //                 />
                //               </Grid>
                //             </Grid>
                //           </Grid>
                //           <Grid item xs={12} style={{ marginTop: "20px" }}>
                //             <Typography
                //               variant="body2"
                //               style={{
                //                 fontSize: "14px",
                //                 fontFamily: "Work Sans",
                //                 color: "#1a76b0",
                //               }}
                //             >
                //               Add Secondary Colours
                //             </Typography>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Grid container direction="row" spacing={3}>
                //               <Grid item>
                //                 <Card
                //                   style={{
                //                     width: "88px",
                //                   }}
                //                 >
                //                   <CardContent
                //                     style={{
                //                       backgroundColor: "#efefef",
                //                       paddingLeft: "10px",
                //                     }}
                //                   >
                //                     <Typography
                //                       variant="body2"
                //                       style={{
                //                         paddingTop: "10px",
                //                         fontSize: "10px",
                //                         fontFamily: "Work Sans",
                //                         lineHeight: 2,
                //                       }}
                //                     >
                //                       #EFEFEF
                //                     </Typography>
                //                   </CardContent>
                //                 </Card>
                //               </Grid>

                //               <Grid item>
                //                 <InputColor
                //                   initialValue="#173e59"
                //                   placement="right"
                //                 />
                //               </Grid>
                //             </Grid>
                //           </Grid>
                //         </Grid>
                //       </Grid>
                //       <Grid item xs={1} />
                //       <Grid item xs={4}>
                //         <Grid container spacing={2}>
                //           <Grid item xs={12}>
                //             <Typography
                //               variant="body2"
                //               style={{
                //                 fontSize: "14px",
                //                 fontFamily: "Work Sans",
                //                 color: "#1a76b0",
                //               }}
                //             >
                //               Primary Font
                //             </Typography>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Select
                //               labelId="demo-simple-select-outlined-label"
                //               id="demo-simple-select-outlined"
                //               label="Age"
                //               disableUnderline={true}
                //               style={{
                //                 border: "1px solid #9EABB593",
                //                 width: "100%",
                //                 height: "45px",
                //                 fontSize: "12px",
                //                 paddingLeft: "5px",
                //               }}
                //             >
                //               <MenuItem value="Active">Active</MenuItem>
                //               <MenuItem value="Inactive">Inactive</MenuItem>
                //             </Select>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Typography
                //               variant="body2"
                //               style={{
                //                 fontSize: "14px",
                //                 fontFamily: "Work Sans",
                //                 color: "#1a76b0",
                //               }}
                //             >
                //               Secondary Font
                //             </Typography>
                //           </Grid>
                //           <Grid item xs={12}>
                //             <Select
                //               labelId="demo-simple-select-outlined-label"
                //               id="demo-simple-select-outlined"
                //               label="Age"
                //               disableUnderline={true}
                //               style={{
                //                 border: "1px solid #9EABB593",
                //                 width: "100%",
                //                 height: "45px",
                //                 fontSize: "12px",
                //                 paddingLeft: "5px",
                //               }}
                //             >
                //               <MenuItem value="Active">Active</MenuItem>
                //               <MenuItem value="Inactive">Inactive</MenuItem>
                //             </Select>
                //           </Grid>
                //           <Grid item xs={12} style={{ marginTop: "20px" }}>
                //             <Divider style={{ width: "100%" }} />
                //           </Grid>
                //         </Grid>
                //       </Grid>
                //       <Grid item xs={1} />
                //     </Grid>
                //   </Grid>
                // </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.setOpen}
          onClose={() => this.setState({ setOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Capture Photo</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Camera
                onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ setOpen: false })} color="primary" autoFocus>
              {t("AdminConsole.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(BrandDetails));
