
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FlashOnTwoTone, FormatListNumberedRtlOutlined } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInterval } from "timers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  grid: boolean;
  list: boolean;
  anchorEl: any;
  anchorE2: any;
  setOpen2: any;
  sharedProjectError: any;
  sharedProject: any;
  percentage: any,
  quotePrice: any;
  isEditing: any;
  next_page: boolean;
  total_pages: number;
  brandsData: any;
  brandUpdatedCompleteDate: any;
  setPage: number;
  brandDeleteId: number;
  selectedBrand: any;
  selectedBrandId: any;
  selectData: any;
  isSearch: boolean;
  tableBrandData: any;
  anchorElFilter: any;
  searchBrandName: string;
  brandFilterValue: string;
  setOpen: boolean;
  accountsData: any;
  permissions: any;
  permissionName: any;
  selectedBrandForProjectCreate: any;
  selectedUsersForProjectCreate: any;
  notify: any
  loading: boolean;
  brandTeamsData: any;
  selectedSortName: any;
  brandGroupData : any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandGroupController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End
  getBrandListingId: any;
  apiCallToBrandDeleteApi: any;
  getProjectByBrandId: any;
  getBrandListingByFiltersId: any;
  projectCreateApiCallId: any;
  callGetAccountListApiId: any;
  getNotifyNotificationId: any;
  getBrandTeamId: any;
  getABUsersId: any;
  getBrandListingByFilters: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      grid: true,
      list: false,
      anchorEl: null,
      anchorE2: null,
      setOpen2: false,
      sharedProjectError: false,
      sharedProject: "",
      percentage: 74,
      quotePrice: 64,
      isEditing: false,
      next_page: false,
      total_pages: 1,
      brandsData: [],
      brandUpdatedCompleteDate: [],
      setPage: 1,
      brandDeleteId: 1,
      selectedBrand: "",
      selectedBrandId: "",
      selectData: {},
      isSearch: false,
      tableBrandData: [],
      anchorElFilter: null,
      searchBrandName: "",
      brandFilterValue: "name0",
      setOpen: false,
      accountsData: {},
      permissions: '',
      permissionName: "",
      selectedBrandForProjectCreate: "",
      selectedUsersForProjectCreate: "",
      notify: null,
      loading: false,
      brandTeamsData: [],
      selectedSortName: 'Name (A-Z)',
      brandGroupData : null

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    // let value : any = localStorage.getItem("onBoardingStep");
    // let valueData : any = localStorage.getItem("toShowOnboarding");
    // if(parseInt(value) == 5){
    //   localStorage.setItem("toShowOnboarding", "true");
    //   if(valueData == "false") window.location.reload();
    // }
    // this.setState({ loading: true }, () => {
    //   this.getBrandListing();
    //   this.loadUserDetailsAndRoles();
    //   this.callGetAccountListApi('');
    // });

    let brandGroup = localStorage.getItem("brandGroupSelected");
    this.getBrandListing(brandGroup)
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  getNotifyNotifications = async () => {
     const header = {
       "Content-Type": "application/json",
       token: localStorage.getItem("token")
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.getNotifyNotificationId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_notifications/alert_notifications/notify`
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       "GET"
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
   }
  handleInputChange = (e?: any) => {

    this.setState({
      quotePrice: e.target.value,

    })
  }
  handleClickAway = () => {
    this.setState({
      isEditing: false
    })
  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }


  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getABUsersId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        this.setState({brandTeamsData: message?.properties?.RestAPIResponceSuccessMessage})        
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandTeamId) {      
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        let teamData = []
        teamData.push([...this.state.brandTeamsData, ...message?.properties?.RestAPIResponceSuccessMessage])
        this.setState({
          brandTeamsData: teamData[0]
        });
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingId) {
  
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson === undefined || !responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
        
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            this.setState({ brandsData: responseJson.brands?.brands?.data,
              brandGroupData : responseJson.brand_group?.data
            }, () => {
              
              this.setState({loading: false});
            })
            // this.getNotifyNotifications()
            // this.setState({ next_page: responseJson.next_page })
            // this.setState({ total_pages: responseJson.total_pages })
            // let DMYString = ""
            // let brandUpdatedDate: any = [];
            // this.state.brandsData?.map((items: any) => {
            //   let modifiedDate: any = items.attributes.updated_at;

            //   var part: any = modifiedDate.split(" ");
            //   DMYString = part[0] + " " + part[1] + " " + part[2];
            //   brandUpdatedDate.push({ id: items.id, DMY: DMYString })
            // })
            // this.setState({ brandUpdatedCompleteDate: brandUpdatedDate })
          }
        }
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getNotifyNotificationId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        if (apiRequestCallId && responseJson) {
          this.setState({ notify: responseJson });
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToBrandDeleteApi) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson === undefined || !responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            toast.success("Brand is deleted Sucessfully");
            //search live
            //recent search
          }
        }
      }
      else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectCreateApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //  this.callGetProjectsListApiType1();
            //this.callGetProjectsListApiTypeForAB();
            toast.success("Project created successfully");
            this.setState({ loading: false });
            this.getBrandListing();
          }
        }
      } else if (responseJson.errors) {
        if (errorResponse.errors[0].name) {
          // toast.error(`Project` + " " + errorResponse.errors[0].name);
          toast.error("Ups! This name already exists.")
        } else {
          toast.error(errorResponse.errors[0].account);
        }
        this.setState({ loading: false });
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
        this.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
        this.setState({ loading: false });
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              accountsData: responseJson.accounts.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          localStorage.setItem("projectsDataSelected", JSON.stringify(responseJson?.projects?.data))
          this.setData(this.state.selectData);
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFiltersId) {
    
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        if (apiRequestCallId && responseJson) {
          this.setState({ tableBrandData: responseJson.projects.data })
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFilters) {
    
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        if (apiRequestCallId && responseJson) {
          this.setState({ brandsData: responseJson?.brands?.data, loading: false })
        } else {
          this.setState({ loading: false });
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End


    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }

    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  progressPercentage = (e?: any, value?: any) => {
    this.setState({
      percentage: value
    })
  }
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false
    })
    // this.getBrandListing();
  }
  handleClose = (item?: any) => {
    this.setState({
      anchorEl: null
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  setData(selectedBrand?: any) {
    this.setState({
      selectedBrandId: selectedBrand.id,
      selectedBrand: selectedBrand,
    });
    localStorage.setItem("selectedBrandID", selectedBrand.id)
    localStorage.setItem("selectedBrand", selectedBrand.id);
    localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0].id : null);
    localStorage.setItem("selectedProject", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0].id : null);
    localStorage.setItem("selectedProjectName", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0]?.name : null);

    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    //this.props.history.push("/Home/Projects")
    //navigator.navigate("/Home/Projectportfolio")
    // if (selectedBrand.attributes.projects.length === 0) {
    //   toast("Please create a project")
    // } else {
      if(localStorage.getItem("projectType") == 'Archive Projects' || localStorage.getItem("projectType") == 'Deleted Projects' ){
        window.location.href = '/Home/ProjectsTypes'
      }
      else {
        window.location.href = '/Home/Projects'
      }
      // this.props.history.push()

    // }

  }

  setData2(selectedBrand?: any) {
    localStorage.setItem("selectedBrandID", selectedBrand?.attributes?.brand_id)
    localStorage.setItem("selectedBrand", selectedBrand?.attributes?.brand_id);
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.brand?.data?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", selectedBrand?.id);
    localStorage.setItem("selectedProject", selectedBrand?.id);
    localStorage.setItem("selectedProjectName", selectedBrand.attributes?.name);
    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    //this.props.history.push("/Home/Projects")
    //navigator.navigate("/Home/Projectportfolio")

    window.location.href = '/Home/Projectportfolio'



  }



  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true
    })
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
    // fetch AB users if the current user is an AB user too
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);    
    if(userInfo.is_albertbauer_user){
      this.getBrandTeams({id: userInfo.brand_id}, true);
    }   
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false
    })
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null
    })
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  }
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true
    })
  }
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };
  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPage: value }, () => { this.getBrandListing() });
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  setProjectData =(selectedProject?: any, brandDetail? : any)=> {
  
     
      console.log(brandDetail, "21ggfg")
      localStorage.setItem("selectedBrandID", selectedProject.brand_id)
      localStorage.setItem("selectedBrand", selectedProject.brand_id);
      localStorage.setItem("selectedProject", selectedProject);
      localStorage.setItem("selectedProjectId", selectedProject.id)
      localStorage.setItem("selectedProjectStatus", selectedProject.status)
      localStorage.setItem("selectedProjectName", selectedProject.name);

      localStorage.setItem("BrandNameIcon", brandDetail?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrandName", brandDetail?.attributes?.name);
    localStorage.setItem("selectedBrandImage", brandDetail?.attributes?.logo_blob_signed_id_url);
      var selectedGrid: any = false
      localStorage.setItem("selectedGridView", selectedGrid)

      const sideNavData = {
        sideNavData:
          [
            {
              name: "photoproduction"
            },
            {
              name: "CGI"
            }


          ]
      }
      let da = { sideNavData: sideNavData, key: "projects" }
      localStorage.setItem("navData", JSON.stringify(da))
      window.location.href = '/Home/Projectportfolio';
    

  }
  getBrandListing = (brandGroupId? : any) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_groups?page=1&per_page=1000&brand_group_id=${brandGroupId}`
      // configJSON.brandListingAPiEndPoint + `&page=${this.state.setPage}&per_page=${20}&sort_by=name&sort_order=asc`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //account  index api
  callGetAccountListApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }


  getProjectByBrandID = () => {
    this.setState({
      loading: true
    });
    const header = {
      "Content-Type": configJSON.callToProjectShowByBrandContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectByBrandId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToProjectShowByBrandApiEndPoint + this.state.selectedBrandId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToProjectShowByBrandMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandTeams =(brand: any, userBelongsToAB?:boolean)=>{    
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // check if the current user belongs to Albert Bauer by cheking the 'is_albertbauer_user' key from LS's userinfo object
    if(userBelongsToAB){
      this.getABUsersId = requestMessage.messageId;
    } else {
      this.getBrandTeamId = requestMessage.messageId;
    }
    var brandID = localStorage.getItem("selectedBrand")
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_sharing_lists?brand_id=${brand?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // getABUsers method to fetch all AB users to show in the 'members' dropdown for brand project other than AB itself

  //project create api
  callCreateProjectApi = (values: any, brands: any, users: any) => {

    this.setState({
      setOpen: false,
      loading: true
    })

    if (users) {
      const selectedAccount = [];
      const selectedTeam = []

      for (let i = 0; i < users.length; i++) {
        if(users[i].entity == "Account"){
          selectedAccount.push(users[i].id)
        }
        else if(users[i].entity == "Team"){
          selectedTeam.push(users[i].id)
        }
        
      }
      var selectedUsers = [];
      selectedUsers = selectedAccount.map((i: any) => Number(i));
      var teams = [];
      teams = selectedTeam.map((i: any) => Number(i));
    }
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = brands.id; 
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const data = {
      name: values.title,
      description: values.projectDescription,
      account_ids: selectedUsers,
      team_ids: teams,
      brand_id: parseInt(brand_id),
    };

    const httpBody = {
      data: data
    };


    const requestCreateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectCreateApiCallId = requestCreateProjectMessage.messageId;
    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectCreateAPiEndPoint
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateProjectMessage.id, requestCreateProjectMessage);



  }

  deleteBrand(id: number) {

    //Call update API
    const header = {
      "Content-Type": configJSON.contentTypeApiDeleteBrand,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToBrandDeleteApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandDeleteApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletionBrandApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchChange = (event: any) => { 
    if (event.target.value !== "") {
      this.setState({ isSearch: false, searchBrandName: event.target.value }, () => {
        if (this.state.brandFilterValue !== "") {
          //this.getProjectsByFilter(`name=${event.target.value}&Sort_by=${this.state.brandFilterValue}`);
          this.getBrandsByFilter(null);
        } else {
          //this.getProjectsByFilter(`name=${event.target.value}`)
          this.getBrandsByFilter(null);
        }
      })
    } else {
      this.setState({ isSearch: false, searchBrandName: "" }, () => {
     
        this.getBrandsByFilter(null)
      });
    }
  }

  getBrandsByFilter = (path: any) => {
    this.setState({ tableBrandData: [] })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFilters = requestMessage.messageId;
    if (path == null) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands/brand_project_listing?page=${1}&per_page=${1000}&brand_name=${this.state.searchBrandName}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands/brand_project_listing?page=${1}&per_page=${1000}&${path}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getProjectsByFilter = (path: any) => {
    this.setState({ tableBrandData: [] })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=${this.state.setPage}&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }

  handleSortByName = () => {
    this.setState({ isSearch: true })
    let path = "" as string;
    if (this.state.searchBrandName !== "") {
      path = `name=${this.state.searchBrandName}&Sort_by=name`;
    } else {
      path = `Sort_by=name`;
    }

    this.getProjectsByFilter(path)
  }

  handleFilterChange = (event: any) => {
    var order = event.target.value.slice(-1)
    var item = event.target.value.substr(0, event.target.value.length - 1)
    let path = "" as string;
    this.setState({ 
      brandFilterValue: event.target.value, 
      isSearch: false, 
      anchorElFilter: null,
      loading: true
    });
    if(this.state.searchBrandName !== "" && order == "0"){
      path = `brand_name=${this.state.searchBrandName}&sort_by=${item}&sort_order=asc`;
    }
    else if(this.state.searchBrandName !== "" && order == "1"){
      path = `brand_name=${this.state.searchBrandName}&sort_by=${item}&sort_order=desc`;
    }
    else if (order == "0") {
      path = `sort_by=${item}&sort_order=asc`
    }
    else if (order == "1") {
      path = `sort_by=${item}&sort_order=desc`
    }
    this.getBrandsByFilter(path);
  }

  handleClickProjects = (event: any) => {
    this.setState({
      anchorE2: event.currentTarget,
    });
  };

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 10) {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      localStorage.setItem("toShowOnboarding", "false");
      window.location.reload();
    } else if(parseInt(value) == 5){
      localStorage.setItem("onBoardingStep", "7")
      this.forceUpdate();
    } else if(parseInt(value) == 8){
      if(this.state.permissions !== "viewer"){
        localStorage.setItem("onBoardingStep", "9")
      }else { localStorage.setItem("onBoardingStep", "10") }
      this.forceUpdate();
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if(parseInt(value) == 7){
      localStorage.setItem("onBoardingStep", "5")
      this.forceUpdate();
    } else if(parseInt(value) == 10){
      if(this.state.permissions !== "viewer"){
        localStorage.setItem("onBoardingStep", "9")
      }else { localStorage.setItem("onBoardingStep", "8") }
      this.forceUpdate();
    } else{
      localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
      this.forceUpdate();
    }
  }

  handleSkipOnboarding = () => {
    localStorage.setItem("onBoardingStep", "11");
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  // Customizable Area Start
  // Customizable Area End

}