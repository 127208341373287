import React from "react";
import Button from "@material-ui/core/Button";
import { forgetBackgroundImage, harvestBlueLogoImage, rightHarvestLogoImage, signUpBackgroundImage, ukLogo, germanLogo } from "./assets";
import { imgPasswordVisible } from "./assets";
import { imgPasswordInVisible } from "./assets";
// Customizable Area Start

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CustomCheckBox from "../../../components/src/CustomCheckBox";
// Customizable Area End
import { Link } from "@material-ui/core";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { withFormik } from "formik";
import Yup from "yup";
import { withTranslation, Trans } from "react-i18next";

const styles = {
  root: {
    backgroundColor: "red",
    overflow: "hidden",
  },
  inputPostion: {
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "65%",
    transform: "translate(-50%, -50%)",
    width: '100%',
    maxWidth: 'fit-content'
  },
  sendMail: {
    textAlign: "end",
  },
  signUpLogo: {
    height: "100%",
    width: "25%",
    position: "absolute",
    top: "0",
    bottom: "0",
  },
  button: {
    backgroundColor: "rgb(23, 62, 89)",
    color: "white",
  },
  PageHeader: {
    fontFamily: "inter",
    color: "#173e59",
    fontSize: "24px",
    marginBottom: "20px",
  },
  emailError: {
    fontSize: "10px",
    color: "red",
  },
  inputHeight: {
    height: "70px",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  signUp: {
    textAlign: "end",
  },
  signUpLink: {
    color: "#173e59",
    cursor: "pointer",
  },
  textError: {
    border: "1px solid red",
  },
  passwordVisible: {
    height: "25px",
  },
  emailVerfication: {
    fontSize: "12px",
  },
  view_image_left: {
    maxHeight: "100vh"
  },
  images1 : {

  },
  grid : {
    display : "block"
  }
} as const;

var langSelected = localStorage.getItem("i18nextLng");

class EmailVerification extends EmailAccountRegistrationController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleLanguage(lang: string) {
    // console.log(this.props.i18n, lang)
    langSelected = lang
    this.props.i18n.changeLanguage(lang);
    this.langaugeChangeHandler(this.props.i18n.language)
    console.log("lang", this.props.i18n)
  }

  render() {
    const { t, classes } = this.props;

    return (
      <div style={{ overflowY: 'hidden', maxHeight: '100vh' }}>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "10px",
            zIndex: 10000,
          }}
        >
          <Button
            onClick={() => this.handleLanguage("en")}
            style={{ color:localStorage.getItem("i18nextLng") == 'en' ? 'white' : "black", backgroundColor: localStorage.getItem("i18nextLng") == 'en' ? '#173e59' : 'transparent' }}
            startIcon={<img src={ukLogo} style={{width: '18px', height: '18px'}}/>}
          >
            English
          </Button>
          <Button
            onClick={() => this.handleLanguage("de")}
            style={{ color: localStorage.getItem("i18nextLng") == 'de' ? 'white' : "black" , backgroundColor: localStorage.getItem("i18nextLng") == 'de' ? '#173e59' : 'transparent' }}
            startIcon={<img src={germanLogo} style={{width: '18px', height: '18px'}}/>}
          >
            German
          </Button>
        </div>
        <Grid container className ={classes.grid}>
          <Grid>
            <img src={forgetBackgroundImage.default} className={classes.view_image_left} />
          </Grid>
          <div className={classes.images1}>
              <img src={harvestBlueLogoImage.default} style={{
                maxWidth: "173px",
                height: "auto",
                position: "absolute",
                left: "100px",
                top: "62px",
                cursor: 'pointer'
              }} data-testid="harvestimg" onClick={() => window.location.href = '/EmailAccountLogin' } />
              <Grid>
                <form className={classes.inputPostion}>
                  <div>
                    <p className={classes.PageHeader}>
                      {" "}
                      {t("EmailVerficationController.emailVerfication")}
                    </p>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container spacing={2} />
                      <p>
                        {" "}
                        <Link color="inherit" className={classes.emailVerfication}>
                          {t("EmailVerficationController.sentMail")}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={6}>
                      <Button
                        className={classes.button}
                        fullWidth
                        type="submit"
                        variant="contained"
                        href="mailto:"
                      >
                        {t("EmailVerficationController.openMail")}
                      </Button>
                      <p className={classes.sendMail}>
                        {t("EmailVerficationController.didNotReciveEmail")}
                        <Link
                          onClick={(e:any)=>this.resendMail2(e)}
                          className={classes.signUpLink}
                        >
                          {t("EmailVerficationController.resend")}
                        </Link>
                      </p>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <img src={rightHarvestLogoImage} style={{
                maxWidth: "298px",
                height: "auto",
                margin: 0,
                position: "absolute",
              bottom: 0,
              right: 0
            }} />


          </div>
        </Grid>

      </div>
    );
  }
  // async componentDidMount(){
  //   this.resendMail();
  // }
}
export default withStyles(styles)(withTranslation()(EmailVerification));
