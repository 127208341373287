// App.js - WEB
import React, { Component } from 'react';
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  Link
} from 'react-router-dom';

import { View } from 'react-native';
// import firebase from 'firebase'
import { connect } from 'react-firebase';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen.web';
import HomePage from '../../components/src/HomePage.web';

import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import GSuiteIntegration from '../../blocks/GSuiteIntegration/src/GSuiteIntegration';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole.web';
import Annotations from '../../blocks/Annotations/src/Annotations';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
// import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Chat from '../../blocks/Chat/src/Chat';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Notifications from '../../blocks/Notifications/src/Notifications';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Translation from '../../blocks/Translation/src/Translation';
import LandingPage from '../../blocks/LandingPage/src/LandingPage';
import HubspotIntegration from '../../blocks/HubspotIntegration/src/HubspotIntegration';
import Download from '../../blocks/Download/src/Download';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import PersonalEmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/PersonalEmailAccountRegistration.web"
import HelpCentre from '../../blocks/HelpCentre/src/HelpCentre';
import Comments from '../../blocks/Comments/src/Comments';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Documentation from '../../blocks/Documentation/src/Documentation';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import UploadMedia from '../../blocks/UploadMedia/src/UploadMedia';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder.web';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import CustomiseInterface from '../../blocks/CustomiseInterface/src/CustomiseInterface';
import ServicespecificSettingsadmin from '../../blocks/ServicespecificSettingsadmin/src/ServicespecificSettingsadmin';
import ImportPhotoshopDocumentspsd from '../../blocks/ImportPhotoshopDocumentspsd/src/ImportPhotoshopDocumentspsd';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import EmailVerfication from '../../blocks/email-account-registration/src/EmailVerfication.web';
import EmailConfirmation from '../../blocks/email-account-registration/src/EmailConfirmation.web';
import Brands from '../../blocks/ProjectPortfolio/src/Brands.web';
import ProjectPortfolio from '../../blocks/ProjectPortfolio/src/ProjectPortfolio.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import MultilevelApproval from '../../blocks/MultilevelApproval/src/MultilevelApproval';
import AccountProfileWeb from '../../components/src/AccountProfile.web';
import CompanyProfile from '../../components/src/CompanyProfile.web';
import { lightTheme } from '../../components/src/theme';
import Trello from '../../components/src/draganddrop/Trello.web';
import indexTrello from '../../components/src/draganddrop/indexTrello';
import ProjectsWeb from '../../blocks/ProjectPortfolio/src/Projects.web';
import ProjectPortfolioWeb from '../../blocks/ProjectPortfolio/src/ProjectPortfolio.web';
import HomePageWeb from '../../components/src/HomePage.web';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import NewTemplates from '../../blocks/AdminConsole/src/NewTemplates.web';
import NewBrand from '../../blocks/AdminConsole/src/NewBrand.web';
const routeMap = {
  GSuiteIntegration: {
    component: GSuiteIntegration,
    path: '/GSuiteIntegration'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  NewTemplates: {
    component: NewTemplates,
    path: '/NewTemplate'
  },
  Annotations: {
    component: Annotations,
    path: '/Annotations'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLogin'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Translation: {
    component: Translation,
    path: '/Translation'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  HubspotIntegration: {
    component: HubspotIntegration,
    path: '/HubspotIntegration'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  // EmailAccountRegistration:{
  //  component:EmailAccountRegistration,
  // path:"/EmailAccountRegistration"},
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  Comments: {
    component: Comments,
    path: '/Comments'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  UploadMedia: {
    component: UploadMedia,
    path: '/UploadMedia'
  },
  // TeamBuilder:{
  //  component:TeamBuilder,
  // path:"/TeamBuilder"},
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/ProjecttaskTracking'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  CustomiseInterface: {
    component: CustomiseInterface,
    path: '/CustomiseInterface'
  },
  ServicespecificSettingsadmin: {
    component: ServicespecificSettingsadmin,
    path: '/ServicespecificSettingsadmin'
  },
  ImportPhotoshopDocumentspsd: {
    component: ImportPhotoshopDocumentspsd,
    path: '/ImportPhotoshopDocumentspsd'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  EmailVerfication: {
    component: EmailVerfication,
    path: '/EmailVerfication'
  },
  EmailConfirmation: {
    component: EmailConfirmation,
    path: '/EmailConfirmation'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: '/MultilevelApproval'
  },

  Home: {
    component: HomeScreen,
    path: '/Home'
    // exact: true
  },

  ProjectsWeb: {
    component: ProjectsWeb,
    path: '/Home/Project',
    exact: true
  },
  Brands: {
    component: Brands,
    path: '/Home/brands',
    exact: true
  },

  AccountProfile: {
    component: AccountProfileWeb,
    path: '/AccountProfile',
    exact: true
  },
  CompanyProfile: {
    component: CompanyProfile,
    path: '/CompanyProfile',
    exact: true
  },
  HomePage: {
    component: HomePageWeb,
    path: 'Home/HomePage',
    exact: true
  },

  //    CGI:{
  // component:ProjectPortfolio,
  //   path:"/Home/Projects/Projectportfolio",
  //   exact:true

  //    },

  Trello: {
    component: Trello,
    path: '/TrelloBoard',
    exact: true
  },
  indexTrello: {
    component: indexTrello,
    path: '/Trello',
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistrationWeb,
    path: '/',
    exact: true
  },
  PersonalEmailAccountRegistrationWeb : {
    component : PersonalEmailAccountRegistrationWeb,
    path: '/customRegistration',
    exact : true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  NewBrand: {
    component: NewBrand,
    path: '/NewBrand'
  }
};

class AppRoot extends Component {
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      //  <EmailAccountLoginBlock/>
      // <EmailAccountRegistration/>
      <div>
        {WebRoutesGenerator({ routeMap })}
        <ToastContainer />
      </div>
    );
  }
}

export default AppRoot;
