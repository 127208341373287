import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import clsx from "clsx";
import DeleteIcon from '@material-ui/icons/Delete';
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import draftToHtml from 'draftjs-to-html';
import { Switch } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactS3Uploader from "react-s3-uploader"
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';

import htmlToDraft from 'html-to-draftjs';
import * as SparkMD5 from 'spark-md5';

import {
  Grid,
  Typography,
  Drawer,
  CssBaseline,
  List,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  Breadcrumbs,
  ListItemSecondaryAction,
  Button,
  Input,
  Card,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Select,
  MenuItem,
  Slider,
  DialogActions,
  InputLabel,
  FormGroup,


} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML, ContentBlock } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateFnsUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MuiListItem from "@material-ui/core/ListItem";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MailIcon from "@material-ui/icons/Mail";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileOutlined from "@material-ui/icons/InsertDriveFileOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation, Trans } from "react-i18next";
import { EditLocationOutlined, FlashOffRounded, FlashOnOutlined, LeakAddTwoTone } from "@material-ui/icons";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AnyRecord } from "dns";
import _ from "lodash";
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'
import Visibility from "@material-ui/icons/Visibility"

const styles = {
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${296}px)`,
    marginLeft: 296,
  },
  drawer: {
    width: 296,
    flexShrink: 0,
    padding: "8px",
  },
  drawerPaper: {
    width: 296,
    background: "#F5F6FA",
  },
  content: {
    flexGrow: 1,
    padding: "30px",
    marginTop: "20px",
  },
  designHarvestLogo: {
    width: "128px",
    height: "64px",
    marginLeft: "55px",
    marginTop: "45px",
    marginBottom: "51px",
  },
  input: {
    fontSize: "32px",
    fontWeight: "bold",
    fontFamily: "Inter",
  },
  cardRoot: {
    width: "820px",

    marginTop: "15px",
  },
  radio: {
    "&$checked": {
      color: "#1a76b0",
    },
  },
  checked: {
    color: "red",
  },
  titleError: {
    width: "55%",
    borderBottom: "2px solid red",
  },
  templateTitle: {
    width: "55%",
    borderBottom: "2px solid #173e59",
  },
  dialogPaper: {
    width: 800,

    maxHeight: "700px",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },

} as any;
interface Props {
  classes: any;
  navigation: any;
  t?: any;
  i18n?: any;
}
interface S {
  pageCountLabel: any;
  selectedDate: any;
  openDialog: any;
  showSecond: any;
  elementArray: any;
  selectedAssetCategoryId: any;
  selectedBriefingId: any;
  selectedAssetTypeId: any;
  templateTitle: any;
  isTemplateTitleValid: any;
  templateTitleErrorMessage: any;
  previewDialogOpen: any;
  selectedConditionFirstLabel: any;
  selectedConditionFirstIndex: any;
  selectedConditionSecondLabel: any;
  selectedConditionSecondIndex: any;
  selectedConditionFirstType: any;
  selectedConditionSecondType: any;
  isConditionSelected: any;
  selectedConditionValue: any;
  templateId: any;
  selectedThenCondition: any;
  counter: any;
  screenType: any;
  loading: boolean;
  templateSpecificID: any;
  elementArrayEditBriefing: any;
  maxValues: any;
  minValues: any;
  countPagination: any;
  selectedConditionData: any;
  selectedConditionId: any;
  fileNameFromBriefing: any;
  selectedFormConditionId: any;
  formCondition: any;
  updateButton: any;
  deleteDialog: boolean;
  selectedDeleteCondition: any;
  addEditDialog: boolean;
  editConditionButton: boolean;
  selectedEditCondition: any;
  setPage: any;
  nextPage: boolean;
  totalPage: any;
  dataLength: any;
  editAddDelete: boolean;
  editorState: any;
  pageSeparatorIndex: any;
  page: any;
  fromStart: any;
  fromEnd: any;
  paginationCount: any;
  editOptionValue: boolean;
  editOptionValueIndex: any;
  templateFrom: string;
  fileToRawBinary: any
  resp: any
  selectedImageId: any
  editorState2: any
  replaceTemp: any
  uploading: any;
  tempElement: any;
  show_instruction: any
  saveTemplatePerID:any
}
interface SS {
  id: any;
}

const ListItem = withStyles({
  root: {
    marginBottom: "10px",
    color: "#1a76b0",
  } as const,
})(MuiListItem);

var logo = require("../assets/image_logo_dark.png");


const CustomSlider = withStyles({
  root: {
    color: "#1a76b0",
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#1a76b0",
    border: "1px solid 1a76b0",
    marginTop: -9,
    marginLeft: -11,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#1a76b0",
  },
})(Slider);
const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        top: "20%!important",
        color: "#1A76B0"
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#1A76B0"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#D2CDD4",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#D2CDD4"
        }
      }
    }
  }
});
class NewTemplates extends BlockComponent<Props, S, SS> {
  createTemplateApiCallId: any;
  deleteFormConditionID: any;
  removeTemplateFormId: any;
  addFormFieldsInUpdateSectionId: any;
  getSpecificTemplateId: any;
  createTemplateFieldsApiCallId: any;
  assignFieldsToTemplateApiCallId: any;
  assignConditionToFieldApiCallId: any;
  getBriefingId: any;
  upDateForm: any;
  upDateFormConditionById: any;
  getTemplateTitleName: any;
  upDateTemplateTitle: any;
  getFormConditionById: any;
  duplicateBriefingFieldAPIID: any;
  temp: any = [];

  getSignedURLForFileUploadId: any
  getSignedURLForFileUploadId2: any
  fileInput: any
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      pageCountLabel: 0,
      countPagination: 10,
      maxValues: "",
      fileNameFromBriefing: "",
      minValues: "",
      templateSpecificID: "",
      selectedDate: new Date("2014-08-18T21:11:54"),
      openDialog: false,
      showSecond: false,
      elementArray: [],
      selectedAssetCategoryId: "",
      selectedBriefingId: '',
      selectedAssetTypeId: "",
      templateTitle: localStorage.getItem("assetTypeName"),
      isTemplateTitleValid: true,
      templateTitleErrorMessage: "",
      previewDialogOpen: false,
      selectedConditionFirstLabel: "",
      selectedConditionFirstIndex: "",
      selectedConditionSecondLabel: "",
      selectedConditionSecondIndex: "",
      selectedConditionFirstType: "",
      selectedConditionSecondType: "",
      isConditionSelected: false,
      selectedConditionValue: "",
      templateId: "",
      selectedThenCondition: "",
      counter: 0,
      screenType: 'TemplateManagement',
      loading: false,
      elementArrayEditBriefing: [],
      selectedConditionData: null,
      selectedConditionId: null,
      selectedFormConditionId: null,
      formCondition: [],
      updateButton: false,
      deleteDialog: false,
      selectedDeleteCondition: null,
      addEditDialog: false,
      editConditionButton: false,
      selectedEditCondition: null,
      totalPage: 0,
      nextPage: false,
      setPage: 1,
      dataLength: 10,
      editAddDelete: false,
      editorState: [EditorState.createEmpty()],
      pageSeparatorIndex: [],
      page: 1,
      fromStart: 0,
      fromEnd: 0,
      paginationCount: "",
      editOptionValue: false,
      editOptionValueIndex: null,
      templateFrom: "",
      fileToRawBinary: null,
      resp: null,
      selectedImageId: null,
      editorState2: [],
      replaceTemp: [],
      uploading: false,
      tempElement: [],
      show_instruction: false,
      saveTemplatePerID:null
    };
  }


  async componentDidMount() {

    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var templateFrom = url.searchParams.get("type");
    if (templateFrom === "templatedetails") {
      this.setState({ templateFrom })
    }



    try {
      let paramsArray = window.location.search.substring(1).split("&");
      if (paramsArray[0].split("=")[0] == "type") {
        this.setState({ templateFrom: paramsArray[1].split("=")[1] });
      }
      if (paramsArray[0].split("=")[0] === 'briefingId') {
        this.setState({
          selectedBriefingId: paramsArray[0].split("=")[1],
          templateSpecificID: paramsArray[0].split("=")[1],
          loading: true,
        })
        if (templateFrom !== "templatedetails") {

          this.getSpecificTemplate(paramsArray[0].split("=")[1]);
        } else {

          this.getBriefing(paramsArray[0].split("=")[1])
          this.getTemplateTitle(paramsArray[0].split("=")[1])
        }
      }
      else {
        this.setState({
          selectedAssetCategoryId: paramsArray[0].split("=")[1],
          selectedAssetTypeId: paramsArray[1].split("=")[1],

        }, () => {
          if (paramsArray?.length >= 3) {
            this.setState({ screenType: paramsArray[2].split("=")[1] })
          }
        });
      }
    } catch (error) {
      console.error();
    }


  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.createTemplateApiCallId) {
        if (responseJson.data) {
          if (responseJson.data !== null) {
            if (responseJson.data.id) {
              if (
                responseJson.data.id !== "" ||
                responseJson.data.id !== null
              ) {
                toast.success("Template created successfully.");
                this.setState({ templateId: responseJson.data.id, saveTemplatePerID :responseJson.data.id,selectedBriefingId:responseJson.data.id,templateSpecificID:responseJson.data.id  });
                this.assignFieldsToTemplate(responseJson.data.id);


              }
            }
          }
        }
      } else if (apiRequestCallId === this.removeTemplateFormId) {
        if (responseJson) {
          this.getBriefing(this.state.templateSpecificID)
          toast.success(responseJson.message)

        }
      }
      else if (apiRequestCallId === this.duplicateBriefingFieldAPIID) {
        if (responseJson) {
          toast.success("Duplicated wigdet created successfully.");
          this.getBriefing(responseJson.data.attributes.template_id)
        }
      }
      else if (apiRequestCallId === this.assignFieldsToTemplateApiCallId) {
        if (responseJson.data) {
          toast.success("Fields added to template successfully.");
          this.getBriefing(this.state.templateId)
          this.addConditionToFields(responseJson.data);
        }
      } else if (apiRequestCallId === this.assignConditionToFieldApiCallId) {
        if (responseJson.data) {
          toast.success("Condition added successfully.");
          this.getFormCondition(this.state.templateSpecificID)
        }
        else if (responseJson.errors) {
          // toast.error("Something wrong!")
        }
      } else if (apiRequestCallId === this.deleteFormConditionID) {
        if (responseJson.message) {
          this.getFormCondition(this.state.templateSpecificID)
          toast.success("Form condition deleted successfully.");
        }
      }
      else if (apiRequestCallId === this.upDateForm) {


      }
      else if (apiRequestCallId === this.upDateFormConditionById) {
        if (responseJson.data) {
          toast.success(`Form condition updated successfully`)
          this.getFormCondition(this.state.templateSpecificID)
          this.addFormFieldsInUpdateSection(responseJson.data.id)
        }
        else if (responseJson.errors) {
          toast.error(responseJson.errors[0].name)
        }
      }
      else if (apiRequestCallId === this.upDateTemplateTitle) {
        if (responseJson.data) {
          toast.success(`Template title  updated successfully`)
          this.addFormFieldsInUpdateSection(responseJson.data.id)
        }
        else if (responseJson.errors) {
          toast.error(`Template title name ` + responseJson.errors[0].name)
        }
      } else if (apiRequestCallId === this.addFormFieldsInUpdateSectionId) {
        this.setState({ replaceTemp: [] })
        // this.getBriefing(this.state.templateSpecificID)
        this.state.templateId ? this.getBriefing(this.state.templateId) : this.getBriefing(this.state.templateSpecificID)

      }
      else if (apiRequestCallId === this.getTemplateTitleName) {

        this.setState({
          templateTitle: responseJson.data.attributes.name
        })

      } else if (apiRequestCallId === this.getSpecificTemplateId) {
        this.setState({ templateSpecificID: responseJson.templates.data[0].id, show_instruction: responseJson.templates.data[0].attributes?.show_instruction }, () => {
          this.getBriefing(this.state.templateSpecificID)
          this.getTemplateTitle(this.state.templateSpecificID)
          // this.getFormCondition(this.state.templateSpecificID)
        })
      }
      else if (apiRequestCallId === this.getFormConditionById) {

        if (this.state.editAddDelete) {
          this.setState({
            formCondition: responseJson.template_form_conditions.data,
            nextPage: responseJson.next_page,
            totalPage: responseJson.total_pages,
            editAddDelete: false
          })
        }
        else {
          this.setState({
            formCondition: [...this.state.formCondition, ...responseJson.template_form_conditions.data],
            nextPage: responseJson.next_page,
            totalPage: responseJson.total_pages
          })
        }
        if (this.state.setPage == "1" && this.state.selectedBriefingId && !this.state.previewDialogOpen) { this.checkFormConditionLength() }
      }
      else if (apiRequestCallId === this.getBriefingId) {





        let tempArr = [] as any;

        responseJson.template_form_fields.data.map((_data: any) => {
          if (_data.attributes.element == "date_time") {
            tempArr.push({
              unId: _data.id,
              type: _data.attributes.element,
              label: _data.attributes.label,
              description: _data.attributes.description,
              elementOptionalValue: _data.attributes.element_optional_values,
              defaultValue: _data.attributes.default_value,
              mandatory: _data.attributes.mandatory,
              detailedValues: _data.attributes.detailed_values,
              date: _data.attributes.date,
              time: _data.attributes.time,
              file: '',
              information: _data.attributes.information,
              tempValue: _data?.attributes?.detailed_values !== null ? _data?.attributes?.detailed_values?.options : [],
              showField: true,
              preview_visibility: _data.attributes.preview_visibility,

              // tempValue:[],
              // condition: {
              //   template_id: _data.attributes.template_id,
              //   condition_label_id: "",
              //   condition_value: "",
              //   is_condition_selected: false,
              //   is_response_to_show: false,
              //   response_label_id: "",
              //   conditionLabel: "",
              //   responseLabel: "",
              //   isConditionAdded: false,
              // },
              condition: [],
              titleEditDisabled: this.state.templateFrom == "templatedetails" ? true : false
            })
          }
          else if (_data.attributes.element == "image_upload") {
            tempArr.push({
              unId: _data.id,
              type: _data.attributes.element,
              label: _data.attributes.label,
              description: _data.attributes.description,
              elementOptionalValue: _data.attributes.element_optional_values,
              defaultValue: _data.attributes.default_value,
              mandatory: _data.attributes.mandatory,
              detailedValues: _data.attributes.detailed_values,
              file: '',
              file_blob_signed_id_url: _data.attributes.file_blob_signed_id_url,
              file_blob_signed_id: _data.attributes.file_blob_signed_id,
              information: _data.attributes.information,
              tempValue: _data?.attributes?.detailed_values !== null ? _data?.attributes?.detailed_values?.options : [],
              showField: true,
              preview_visibility: _data.attributes.preview_visibility,

              // tempValue:[],
              // condition: {
              //   template_id: _data.attributes.template_id,
              //   condition_label_id: "",
              //   condition_value: "",
              //   is_condition_selected: false,
              //   is_response_to_show: false,
              //   response_label_id: "",
              //   conditionLabel: "",
              //   responseLabel: "",
              //   isConditionAdded: false,
              // },
              condition: [],
              titleEditDisabled: this.state.templateFrom == "templatedetails" ? true : false
            })
          }
          else {
            if (_data.attributes.element == "paragraph") {
              if (_data?.attributes?.description?.length) {
                const html = _data?.attributes?.description
                const contentBlock = htmlToDraft(html);
                console.log("hrfd", html, contentBlock);

                if (contentBlock) {
                  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                  const editorState = EditorState.createWithContent(contentState);
                  this.temp[_data.id] = editorState

                }

                this.setState({ editorState2: this.temp }, () => console.log("paragrak", this.state.editorState2))
              }


            }

            tempArr.push({
              unId: _data.id,
              type: _data.attributes.element,
              label: _data.attributes.label,
              description: _data.attributes.description,
              elementOptionalValue: _data.attributes.element_optional_values,
              defaultValue: _data.attributes.default_value,
              mandatory: _data.attributes.mandatory,
              detailedValues: _data.attributes.detailed_values,
              file: '',
              information: _data.attributes.information,
              tempValue: _data?.attributes?.detailed_values !== null ? _data?.attributes?.detailed_values?.options : [],
              showField: true,
              preview_visibility: _data.attributes.preview_visibility,
              // tempValue:[],
              // condition: {
              //   template_id: _data.attributes.template_id,
              //   condition_label_id: "",
              //   condition_value: "",
              //   is_condition_selected: false,
              //   is_response_to_show: false,
              //   response_label_id: "",
              //   conditionLabel: "",
              //   responseLabel: "",
              //   isConditionAdded: false,
              // },
              condition: [],
              titleEditDisabled: this.state.templateFrom == "templatedetails" ? true : false
            })
          }
        })

        tempArr = tempArr.concat(this.state.tempElement)

        this.setState({

          elementArray: tempArr,
          loading: false,
          tempElement: []
        })



      }


      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          if (apiRequestCallId != null) {
            /// search live
            if (apiRequestCallId && responseJson) {

              console.log("Dtaassss", this.state.fileToRawBinary, responseJson)
              this.setState({ resp: responseJson }, () => {
                this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary)
              })
            }
          }
        } else if (responseJson.errors) {
          toast.error(errorResponse.errors[0].projects);
          this.setState({ loading: false });
        } else if (responseJson.error) {
          toast.error(errorResponse.error);
          this.setState({ loading: false });
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
          this.setState({ loading: false });
        }
      }
      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId2) {
        let elementsArray = this.state.elementArray;
        console.log("this.state.selectedImageId", this.state.selectedImageId);

        elementsArray[this.state.selectedImageId].fileName = this.state.fileToRawBinary?.name;
        elementsArray[this.state.selectedImageId].file_blob_signed_id = this.state.resp?.data[0]?.signed_id;
        console.log("elementsArray", elementsArray);

        this.setState({
          elementArray: elementsArray,
          uploading: false
        }, () => {
        });
        toast.success("File uploaded successfully")
      }
    }
  }
  visibilityHandler = (id: any, visibility: any, ID: any) => {
    let elementsArray = this.state.elementArray;
    if (visibility) {
      elementsArray[id].preview_visibility = true;
    }
    else {
      elementsArray[id].preview_visibility = false;
    }

    this.setState({
      elementArray: elementsArray,
    }, () => {
    });
    if (ID) {
      let data = {
        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_value": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        "preview_visibility": this.state.elementArray[id].preview_visibility
      } as any
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  }
  removeTemplateForm = (templateFormID: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeTemplateFormId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_fields/${templateFormID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createTemplate = () => {
    console.log(this.state.elementArray)

    let valueError = false;

    let elementArray = this.state.elementArray;
    elementArray.map((_data: any) => {
      if (_data.type == "multiple_selection" || _data.type == "drop_down_select" || _data.type == "single_selection") {
        if (Object.keys(_data.detailedValues).length == 0) {
          valueError = true;
          toast.error("Please add atleast one value in Single Selection/Multiple Selection/Dropdown selection")
        }
      }
    })

    if (!valueError) {


      if (this.state.templateTitle !== "") {
        if (this.state.selectedBriefingId) {
          this.upDateTemplateTitleForm(this.state.templateSpecificID || this.state.saveTemplatePerID )
        }
        else {
          var body = {} as any;
          body.name = this.state.templateTitle;
          body.asset_category_id = this.state.selectedAssetCategoryId;
          body.asset_type_id = this.state.selectedAssetTypeId;
          body.show_instruction = this.state.show_instruction



          let paramsArray = window.location.search.substring(1).split("&");
          if (paramsArray?.length >= 3 && paramsArray[2].split("=")[1] == 'AssetCategories') {
            body.briefing_empty_form = true;
          }
          if (this.state.templateId) {
            this.upDateTemplateTitleForm(this.state.templateId)
          }
          else {
            var httpBody = {
              data: body,
            };

            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.createTemplateApiCallId = requestMessage.messageId;
            const header = {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            };
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              "bx_block_custom_form/templates"
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              "POST"
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
          }


        }
      } else {
        this.setState({
          isTemplateTitleValid: false,
          templateTitleErrorMessage: "This field is required",
        });
      }
    }

  };

  upDateTemplateTitleForm = (id: any) => {
    var body = {} as any;
    body.name = this.state.templateTitle;
    body.show_instruction = this.state.show_instruction
    // body.asset_category_id = this.state.selectedAssetCategoryId;
    // body.asset_type_id = this.state.selectedAssetTypeId;
    var httpBody = { data: body };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upDateTemplateTitle = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };
  addFormFieldsInUpdateSection = (templateId: any) => {
    var tempArr = [] as any;
    this.state.elementArray.map((_data: any, idx: any) => {

      if (_data?.type == "image_upload") {
        if (this.state.replaceTemp.includes(_data.unId)) {

          this.upDateFormFieldData(_data.unId, {
            label: _data.label,
            description: _data.description,
            element: _data.type,
            default_value: _data.defaultValue,
            element_optional_values: _data.elementOptionalValue,
            detailed_values: _data.detailedValues,
            information: _data.fileName,
            mandatory: _data.mandatory,
            file_blob_signed_id: _data.file_blob_signed_id,
            field_order: idx + 1,
            preview_visibility: _data.preview_visibility
          });
        }
        else if (!_data?.unId) {
          tempArr.push({
            label: _data.label,
            description: _data.description,
            element: _data.type,
            default_value: _data.defaultValue,
            element_optional_values: _data.elementOptionalValue,
            detailed_values: _data.detailedValues,
            information: _data.information,
            mandatory: _data.mandatory,
            file_blob_signed_id: _data.file_blob_signed_id,
            field_order: idx + 1,
            preview_visibility: _data.preview_visibility
          });
        }

      }
      else if (!_data?.unId) {
        if (_data?.type == "page_separator") {
          tempArr.push({
            // label: _data.label,
            description: _data.description,
            element: _data.type,
            default_value: _data.defaultValue,
            element_optional_values: _data.elementOptionalValue,
            detailed_values: _data.detailedValues,
            information: _data.information,
            mandatory: _data.mandatory,
            field_order: idx + 1,
          });
        }
        else {
          tempArr.push({
            label: _data.label,
            description: _data.description,
            element: _data.type,
            default_value: _data.defaultValue,
            element_optional_values: _data.elementOptionalValue,
            detailed_values: _data.detailedValues,
            information: _data.information,
            mandatory: _data.mandatory,
            field_order: idx + 1,
            preview_visibility: _data.preview_visibility
          });

        }


      }
    });

    var httpBody = {
      data: {
        template_id: parseInt(templateId),
        template_form_fields: tempArr,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFormFieldsInUpdateSectionId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_custom_form/template_form_fields"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  assignFieldsToTemplate = (templateId: any) => {
    var tempArr = [] as any;
    this.state.elementArray.map((_data: any, idx: any) => {
      if (_data.type == "date_time") {

        tempArr.push({
          label: _data.label,
          description: _data.description,
          element: _data.type,
          default_value: _data.defaultValue,
          element_optional_values: _data.elementOptionalValue,
          detailed_values: {
            date: _data.date,
            time: _data.time,
          },
          information: _data.information,
          mandatory: _data.mandatory,

          field_order: idx + 1,
          preview_visibility: _data.preview_visibility
        });
      }
      else if (_data.type == "paragraph") {
        tempArr.push({
          label: _data.label,
          description: _data.description,
          element: _data.type,
          default_value: _data.defaultValue,
          element_optional_values: _data.elementOptionalValue,
          detailed_values: _data.detailedValues,
          information: _data.information,
          mandatory: _data.mandatory,
          field_order: idx + 1,
          preview_visibility: _data.preview_visibility
        });
      }
      else if (_data.type == "image_upload") {
        tempArr.push({
          label: _data.label,
          description: _data.description,
          element: _data.type,
          default_value: _data.defaultValue,
          element_optional_values: _data.elementOptionalValue,
          detailed_values: _data.detailedValues,
          information: _data.fileName,
          mandatory: _data.mandatory,
          field_order: idx + 1,
          file_blob_signed_id: _data.file_blob_signed_id,
          preview_visibility: _data.preview_visibility
        });
      }
      else if (_data.type == "page_separator") {
        tempArr.push({
          description: _data.description,
          element: _data.type,
          default_value: _data.defaultValue,
          element_optional_values: _data.elementOptionalValue,
          detailed_values: _data.detailedValues,
          information: _data.information,
          mandatory: _data.mandatory,
          field_order: idx + 1,
          preview_visibility: _data.preview_visibility
        });
      }
      else {

        tempArr.push({

          label: _data.label,
          description: _data.description,
          element: _data.type,
          default_value: _data.defaultValue,
          element_optional_values: _data.elementOptionalValue,
          detailed_values: _data.detailedValues,
          information: _data.information,
          mandatory: _data.mandatory,
          field_order: idx + 1,
          preview_visibility: _data.preview_visibility
        });
      }
    });


    var httpBody = {
      data: {
        template_id: parseInt(templateId),
        template_form_fields: tempArr,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignFieldsToTemplateApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_custom_form/template_form_fields"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addConditionToFields = (data: any) => {
    var tempArr = {} as any;
    let arr = [] as any;


    this.state.elementArray.map((_data: any) => {
      if (_data.type !== "comment_box") {
        _data.condition.map((_condition: any) => {
          data.map((_response: any) => {

            if (_condition.conditionLabel == _response.attributes.label) {

              tempArr.condition_label_id = _response.id;
              if (_condition.responseLabel == _response.attributes.label) {
                tempArr.response_label_id = _response.id;
              }
              tempArr.condition_value =
                _condition.condition_value === ""
                  ? "No"
                  : _condition.condition_value;
              tempArr.is_condition_selected = _condition.is_condition_selected;
              tempArr.is_response_to_show = _condition.is_response_to_show;
            } else if (
              _condition.responseLabel == _response.attributes.label
            ) {
              tempArr.response_label_id = _response.id;
            } else {

            }
            // arr.push(tempArr)
          })
          tempArr.template_id = this.state.templateId;
          this.addConditionToField(tempArr);
          tempArr = {};
        })
      }

      arr = [];
    });

    // this.state.elementArray.map((_data: any, idx: any) => {
    //   if (_data.condition.conditionLabel == data.attributes.label) {
    //     tempArr.condition_label_id = data.id;
    //     tempArr.condition_value =
    //       _data.condition.condition_value === ""
    //         ? "No"
    //         : _data.condition.condition_value;
    //     tempArr.is_condition_selected = _data.condition.is_condition_selected;
    //     tempArr.is_response_to_show = _data.condition.is_response_to_show;
    //   } else if (_data.condition.responseLabel == data.attributes.label) {
    //     tempArr.response_label_id = data.id;
    //   } else {
    //     tempArr.response_label_id = data.id;
    //   }
    //   tempArr.template_id = this.state.templateId;

    //   this.addConditionToField(tempArr);
    // });
  };

  addConditionToField = (data: any) => {
    this.setState({
      editAddDelete: true,
      setPage: 1
    })
    if ("condition_label_id" in data && "response_label_id" in data) {
      var httpBody = { data };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.assignConditionToFieldApiCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_custom_form/template_form_conditions"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  duplicateBriefingField = (BriefingFieldId: any) => {

    const tempBriefingFields = this.state.elementArray.filter((el: any, index: any) => el.unId == null || el.unId == undefined)
    console.log("tempBriefingFields", tempBriefingFields);
    this.setState({ tempElement: tempBriefingFields })

    const body = {
      "data": {
        "template_form_field_id": BriefingFieldId
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.duplicateBriefingFieldAPIID = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_form/template_form_fields/widget_duplication`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteFormCondition = (removeID: any) => {
    this.setState({
      deleteDialog: false,
      editAddDelete: true,
      setPage: 1
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFormConditionID = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_form/template_form_conditions/${removeID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  upDateFormFieldData = (id: any, data: any) => {

    var httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upDateForm = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_fields/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  upDateFormCondition = (data: any) => {

    this.setState({
      editAddDelete: true,
      setPage: 1
    })
    var httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upDateFormConditionById = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_conditions/${this.state.selectedFormConditionId}`
    );
    debugger;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  getSpecificTemplate = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpecificTemplateId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates?briefing_empty_form=true&asset_type_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getBriefing = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBriefingId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_fields?template_id=${id}&per_page=100`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFormCondition = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFormConditionById = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_conditions?template_id=${id || this.state.saveTemplatePerID}&&page=${this.state.setPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTemplateTitle = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateTitleName = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTemplateTitle = (event: any) => {
    this.setState({
      templateTitle: event.target.value,
      isTemplateTitleValid: true,
      templateTitleErrorMessage: "",
    });
  };

  handleDateChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[id].date = event.target.value;
    if (elementsArray[id].time !== undefined) {
      elementsArray[id].defaultValue = elementsArray[id].date + " " + elementsArray[id].time;
      elementsArray[id].detailedValues = {
        date: elementsArray[id].date,
        time: elementsArray[id].time
      }
    }
    else {
      elementsArray[id].defaultValue = elementsArray[id].date;
      elementsArray[id].detailedValues = {
        date: elementsArray[id].date,
      }
    }
    this.setState({
      elementArray: elementsArray,
    }, () => { });

    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": {
          "date": this.state.elementArray[id].detailedValues.date,
          "time": this.state.elementArray[id].detailedValues.time,
        },
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "date": this.state.elementArray[id].date,
        "field_order": id + 1,

      } as any


      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }

  };
  handleTimeChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;

    elementsArray[id].time = event.target.value;
    if (elementsArray[id].date !== undefined) {
      elementsArray[id].defaultValue = elementsArray[id].date + " " + elementsArray[id].time;
      elementsArray[id].detailedValues = {
        date: elementsArray[id].date,
        time: elementsArray[id].time
      }
    }
    else {
      elementsArray[id].defaultValue = elementsArray[id].time;
      elementsArray[id].detailedValues = {
        time: elementsArray[id].time
      }
    }

    this.setState({
      elementArray: elementsArray,
    }, () => { });
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": {
          "date": this.state.elementArray[id].detailedValues.date,
          "time": this.state.elementArray[id].detailedValues.time,
        },
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "date": this.state.elementArray[id].date,
        "time": this.state.elementArray[id].time,
        "field_order": id + 1,

      } as any


      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }

  };

  handleDialogOpen = (fromIndex: any) => {


    let elementsArray = this.state.elementArray;

    // elementsArray[fromIndex].condition.push({
    //   id: this.state.elementArray[fromIndex].condition.length + 1,
    //   template_id: "",
    //   condition_label_id: "",
    //   condition_value: "",
    //   is_condition_selected: false,
    //   is_response_to_show: false,
    //   response_label_id: "",
    //   conditionLabel: "",
    //   responseLabel: "",
    //   isConditionAdded: false,
    // })
    this.setState({
      openDialog: true,
      elementArray: elementsArray,
      selectedConditionId: fromIndex
    });
    if (!this.state.selectedBriefingId) {
      this.setState({

        selectedConditionData: this.state.elementArray[fromIndex].condition.length - 1,

      });
    }
  };
  handleDeleteDialogOpen = (id: any) => {
    this.setState({
      deleteDialog: true,
      selectedDeleteCondition: id,
    })
  }
  handleDeleteDialogCloase = () => {
    this.setState({
      deleteDialog: false,
      selectedDeleteCondition: null
    })
  }

  fetchMoreData = () => {
    if (this.state.selectedBriefingId) {
      this.setState({ setPage: this.state.setPage + 1 }, () => {
        this.getFormCondition(this.state.templateSpecificID);
      });
    }
    else {


      this.setState({
        dataLength: this.state.dataLength + 10,

      })
    }
  };

  removeCondition = (index: any) => {
    let elementsArray = this.state.elementArray[this.state.selectedConditionId].condition.filter(
      (item: any, idx: any) => idx !== index
    )
    let afterFilterArray = this.state.elementArray
    afterFilterArray[this.state.selectedConditionId].condition = elementsArray
    this.setState({
      elementArray: afterFilterArray
    });

  };
  handleUpdateCondition = (data: any, index: any) => {

    this.setState({ addEditDialog: true })
    this.setState({
      selectedEditCondition: index,
      showSecond: true,
      updateButton: true,
      selectedConditionFirstIndex: data.condition_label_id,
      selectedConditionFirstLabel: data.conditionLabel,
      selectedConditionValue: data.condition_value,
      isConditionSelected: data.is_condition_selected,
      selectedThenCondition: data.is_response_to_show,
      selectedConditionSecondLabel: data.responseLabel,
      //selectedConditionFirstType:this.state.elementArray[data.condition_label_id].type
      selectedConditionFirstType: this.state.elementArray[data.condition_label_id].type

    })

  }
  handleAddACondition = () => {
    console.log("see");
    this.setState({
      addEditDialog: true
    })
    let elementsArray = this.state.elementArray;
    if (!this.state.selectedBriefingId) {

      elementsArray[this.state.selectedConditionId].condition.push({
        id: this.state.elementArray[this.state.selectedConditionId].condition.length + 1,
        template_id: "",
        condition_label_id: "",
        condition_value: "",
        is_condition_selected: false,
        is_response_to_show: false,
        response_label_id: "",
        conditionLabel: "",
        responseLabel: "",
        isConditionAdded: false,

      })
      this.setState({

        elementArray: elementsArray,
        selectedConditionData: this.state.elementArray[this.state.selectedConditionId].condition.length - 1,

      });
    }

  }

  checkFormConditionLength = () => {
    if (this.state.formCondition.length > 0) {
      this.handleDialogOpen(this.state.selectedConditionId)
    }
    else {
      this.handleAddEditOpen(this.state.selectedConditionId)
    }
  }

  handleConditionDialog = async (fromIndex: any) => {
    console.log("ff  ff")
    // this.state.selectedBriefingId !=="" ? () => this.handleDialogOpen(fromIndex) : ()=>this.handleAddEditOpen(fromIndex)
    this.setState({ selectedConditionId: fromIndex })
    if (this.state.selectedBriefingId) {
      this.getFormCondition(this.state.templateSpecificID);
    }
    else {
      if (this.state.elementArray[fromIndex].condition.length > 0) {
        this.handleDialogOpen(fromIndex)
      }
      else {
        this.handleAddEditOpen(fromIndex)
      }
    }

  }
  handleAddEditOpen = (fromIndex: any) => {
    this.setState({
      addEditDialog: true
    })
    let elementsArray = this.state.elementArray;
    if (elementsArray[fromIndex]?.condition?.length < 1) {

      elementsArray[fromIndex].condition.push({
        id: this.state.elementArray[fromIndex].condition.length + 1,
        template_id: "",
        condition_label_id: "",
        condition_value: "",
        is_condition_selected: false,
        is_response_to_show: false,
        response_label_id: "",
        conditionLabel: "",
        responseLabel: "",
        isConditionAdded: false,
      })
    }
    this.setState({

      elementArray: elementsArray,
      selectedConditionData: this.state.elementArray[fromIndex].condition.length - 1,
      selectedConditionId: fromIndex
    });

  }
  handleAddEditClose = () => {
    this.setState({
      addEditDialog: false,
      selectedConditionFirstIndex: "",
      selectedConditionFirstLabel: "",
      selectedConditionFirstType: "",
      selectedConditionSecondLabel: "",
      selectedConditionSecondIndex: "",
      selectedConditionSecondType: "",

      updateButton: false,
    });
    if (this.state.selectedBriefingId) {
      this.setState({
        selectedConditionData: null,
        selectedConditionId: null,
      })
    }
  };
  handleDialogClose = () => {
    this.setState({
      openDialog: false,
      selectedConditionFirstIndex: "",
      selectedConditionFirstLabel: "",
      selectedConditionFirstType: "",
      selectedConditionSecondLabel: "",
      selectedConditionSecondIndex: "",
      selectedConditionSecondType: "",
      selectedConditionData: null,
      selectedConditionId: null,
      updateButton: false,
      formCondition: [],
      setPage: 1,
      nextPage: false,
      totalPage: 1,
    });
  };

  handleAddCondition = () => {
    console.log("qwerty1");
    let elementsArray = this.state.elementArray;
    let conditionIndex = elementsArray.findIndex((x: any) => x.label === this.state.selectedConditionFirstLabel);
    let responselIndex = elementsArray.findIndex((x: any) => x.label === this.state.selectedConditionSecondLabel);
    // if (!this.state.selectedBriefingId) {
    // console.log("qwerty6");
    //   if (!this.state.updateButton) {

    //     elementsArray[
    //       this.state.selectedConditionId
    //     ].condition[this.state.selectedConditionData].isConditionAdded = true;
    //   }
    // } else {
    
      let data = {
        condition_label_id: elementsArray[conditionIndex].unId,
        condition_label_value: this.state.selectedConditionFirstLabel,
        condition_value: this.state.selectedConditionValue !== "" ? this.state.selectedConditionValue : "No",
        is_condition_selected: this.state.isConditionSelected,
        is_response_to_show: this.state.selectedThenCondition,
        response_label_id: elementsArray[responselIndex].unId,
        response_label_value: this.state.selectedConditionSecondLabel,
        template_id: this.state.templateSpecificID || this.state.saveTemplatePerID
      }
      if (this.state.updateButton) {
        this.upDateFormCondition(data);
    
      } else {
        this.addConditionToField(data);
   
      }
    // }
    this.setState({
      updateButton: false,
      elementArray: elementsArray,
      selectedConditionFirstIndex: "",
      selectedConditionFirstLabel: "",
      selectedConditionFirstType: "",
      selectedConditionSecondLabel: "",
      selectedConditionSecondIndex: "",
      selectedConditionSecondType: "",
      isConditionSelected: "",
      selectedConditionValue: '',
      selectedThenCondition: '',
      addEditDialog: false,
      openDialog: true,
    });
    if (this.state.selectedBriefingId) {
      this.setState({
        selectedConditionData: null,
        selectedConditionId: null
      })
    }
    else {
      this.setState({
        selectedConditionData: this.state.elementArray[this.state.selectedConditionId].condition.length - 1,
      })
    }

  };

  handleFirstSelect = (event: any) => {

    let elementsArray = this.state.elementArray;
    if (!this.state.selectedBriefingId) {
      if (this.state.updateButton) {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedEditCondition].conditionLabel = event.target.value
        if (elementsArray[event.currentTarget.getAttribute("data-id")].type === "short_text" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "long_text" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "slider" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "date_time") {
          if (elementsArray[event.currentTarget.getAttribute("data-id")].defaultValue !== "") {
            elementsArray[
              this.state.selectedConditionId
            ].condition[this.state.selectedConditionData].condition_value = "yes"

          } else {
            elementsArray[
              this.state.selectedConditionId
            ].condition[this.state.selectedConditionData].condition_value = "no"
          }
        }
      } else {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].conditionLabel = event.target.value;
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].condition_label_id = event.currentTarget.getAttribute("data-id");
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].template_id = this.state.templateSpecificID;
        if (elementsArray[event.currentTarget.getAttribute("data-id")].type === "short_text" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "long_text" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "slider" ||
          elementsArray[event.currentTarget.getAttribute("data-id")].type === "date_time") {
          if (elementsArray[event.currentTarget.getAttribute("data-id")].defaultValue !== "") {
            elementsArray[
              this.state.selectedConditionId
            ].condition[this.state.selectedConditionData].condition_value = "yes"

          }
          else {
            elementsArray[
              this.state.selectedConditionId
            ].condition[this.state.selectedConditionData].condition_value = "no"
          }
        }
      }
    }
    if (elementsArray[event.currentTarget.getAttribute("data-id")].type === "short_text" ||
      elementsArray[event.currentTarget.getAttribute("data-id")].type === "long_text" ||
      elementsArray[event.currentTarget.getAttribute("data-id")].type === "slider" ||
      elementsArray[event.currentTarget.getAttribute("data-id")].type === "date_time") {
      if (elementsArray[event.currentTarget.getAttribute("data-id")].defaultValue !== "") {
        this.setState({ selectedConditionValue: "yes" })
      } else {
        this.setState({ selectedConditionValue: "no" })
      }
    }

    this.setState({
      showSecond: true,
      selectedConditionFirstLabel: event.target.value,
      selectedConditionFirstIndex: event.currentTarget.getAttribute("data-id"),
      selectedConditionFirstType: event.currentTarget.getAttribute("data-type"),
      elementArray: elementsArray,
    });
  };
  handleConditionSelectionChange = (event: any) => {
    let elementsArray = this.state.elementArray;

    if (!this.state.selectedBriefingId) {
      if (this.state.updateButton) {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedEditCondition].is_condition_selected = event.target.value;
      } else {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].is_condition_selected = event.target.value;
      }
    }


    this.setState({
      isConditionSelected: event.target.value,
      elementArray: elementsArray,
    });
    // if(this.state.selectedBriefingId){
    //   let  data = {

    //                "element": this.state.elementArray[id].type,
    //                "label":this.state.elementArray[id].label,
    //                "description":this.state.elementArray[id].description,
    //                "element_optional_values": this.state.elementArray[id].elementOptionalValue,
    //                "default_value":this.state.elementArray[id].defaultValue,
    //               "mandatory":this.state.elementArray[id].mandatory,
    //                "detailed_value":this.state.elementArray[id].detailedValue,
    //                "information":this.state.elementArray[id].information,
    //                "template_id":this.state.elementArray[id].template_id,
    //                   "field_order":id+1,
    //                // file:'',
    //                // tempValue: [],
    //                // condition: {
    //                //   template_id:this.state.elementArray.template_id,
    //                //   condition_label_id:"",
    //                //   condition_value:"",
    //                //   is_condition_selected: false,
    //                //   is_response_to_show: false,
    //                //   response_label_id:"",
    //                //   conditionLabel:"",
    //                //   responseLabel:"",
    //                //   isConditionAdded: false,
    //                // },

    //    } as any
    //   this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    //  }
  };

  handleThenConditionChange = (event: any) => {
    let elementsArray = this.state.elementArray;
    if (!this.state.selectedBriefingId) {
      if (this.state.updateButton) {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedEditCondition].is_response_to_show = event.target.value;
      } else {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].is_response_to_show = event.target.value;
      }
    }

    this.setState({
      elementArray: elementsArray,
      selectedThenCondition: event.target.value,
    });
  };

  handleSecondSelect = (event: any) => {
    let elementsArray = this.state.elementArray;
    if (!this.state.selectedBriefingId) {
      if (this.state.updateButton) {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedEditCondition].responseLabel = event.target.value;
      } else {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].responseLabel = event.target.value;
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].response_label_id = event.currentTarget.getAttribute("data-id");
      }
    }
    this.setState({
      selectedConditionSecondLabel: event.target.value,
      selectedConditionSecondIndex: event.currentTarget.getAttribute("data-id"),
      elementArray: elementsArray,
    });
  };

  handleConditionValueChange = (event: any) => {
    let elementsArray = this.state.elementArray;
    if (!this.state.selectedBriefingId) {
      if (this.state.updateButton) {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedEditCondition].condition_value = event.target.value;
        this.setState({ selectedConditionValue: event.target.value, })
      } else {
        elementsArray[
          this.state.selectedConditionId
        ].condition[this.state.selectedConditionData].condition_value = event.target.value;
        this.setState({ selectedConditionValue: event.target.value, })
      }
    }
    this.setState({
      elementArray: elementsArray,
      selectedConditionValue: event.target.value,
    });
  };

  instructionHandler = (e: any) => {
    console.log("instructionHandler", e.target.checked);
    this.setState({
      show_instruction: e.target.checked
    }, () => {
      if (this.state.show_instruction) {
        let dataExact = { type: "comment_box", label: "Do you have any other instructions", description: "", elementOptionalValue: {}, defaultValue: "" }
        this.setState({
          elementArray: [...this.state.elementArray, dataExact]
        })
      }
      else {
        let data = this.state.elementArray;
        let commentData = data.filter((el: any) => el.label === "Do you have any other instructions");
        if (commentData?.length && commentData[0]?.unId) {
          this.removeTemplateForm(commentData[0]?.unId)
        }
        else if (commentData?.length && !commentData[0]?.unId) {
          let index = data.findIndex((el: any) => el.label === "Do you have any other instructions");
          console.log("index", index);
          data.splice(index, 1)
          console.log("data", data);
        }

        this.setState({
          elementArray: data
        })
      }

    })
  }

  handleListItemClick = (data: any) => {
    if (data.type == "page_separator") {
      this.setState({ pageCountLabel: Math.floor(Math.random() + 1) }, () => {
        data.label = this.state.pageCountLabel
      })

    }
    if (this.state.elementArray.some((item: any) => item.label === "Do you have any other instructions")) {
      this.setState({
        elementArray: [...this.state.elementArray, data],
        // elementArrayEditBriefing: [data]
      })
    }
    else
    // if(this.state.show_instruction)
    {
      // let dataExact = { ...data, ...{ type: "comment_box", label: "Do you have any other instructions", description: "", elementOptionalValue: {}, defaultValue: "" } }
      this.setState({
        elementArray: [...this.state.elementArray, data]
        // elementArrayEditBriefing: [data]
      }
        // , () => {
        //   this.handleListItemClick(data)

        // }
      )
    }
    // else if(!this.state.show_instruction){

    // }
  };


  handleFormConditionClick = (data: any) => {

    this.setState({ addEditDialog: true })
    this.setState({
      showSecond: true,
      updateButton: true,
      selectedConditionFirstIndex: this.state.elementArray.findIndex((field: any) => field.unId == data.attributes.condition_label_id),
      selectedConditionFirstLabel: data.attributes.condition_label_value,
      selectedConditionFirstType: this.state.elementArray.filter((field: any) => field.label === data.attributes.condition_label_value)[0].type,
      selectedConditionValue: data.attributes.condition_value,
      isConditionSelected: data.attributes.is_condition_selected,
      selectedThenCondition: data.attributes.is_response_to_show,
      selectedConditionSecondLabel: data.attributes.response_label_value,
      selectedFormConditionId: data.id
    })

  }
  removeItem = (index: any) => {

    this.setState({
      elementArray: [
        ...this.state.elementArray.filter(
          (item: any, idx: any) => idx !== index
        ),
      ],
    });
  };



  moveItemUpward = (fromIndex: any) => {
    let elementsArray = this.state.elementArray;
    var element = elementsArray[fromIndex];
    elementsArray.splice(fromIndex, 1);
    elementsArray.splice(fromIndex - 1, 0, element);

    this.setState({
      elementArray: elementsArray,
    }, () => {
    });
    if (this.state.templateSpecificID !== "") {
      this.state.elementArray.map((item: any, index: any) => {
        item.fieldOrder = index + 1;
        let data = {
          "field_order": item.fieldOrder
        }

        this.upDateFormFieldData(this.state.elementArray[index].unId, data);
      })
    }
  };
  moveItemDownward = (fromIndex: any) => {

    let elementsArray = this.state.elementArray;
    var element = elementsArray[fromIndex];
    elementsArray.splice(fromIndex, 1);
    elementsArray.splice(fromIndex + 1, 0, element);
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.templateSpecificID !== "") {
      this.state.elementArray.map((item: any, index: any) => {
        item.fieldOrder = index + 1;
        let data = {
          "field_order": item.fieldOrder
        }

        this.upDateFormFieldData(this.state.elementArray[index].unId, data);
      })
    }
  };
  handlePagination = (event: any, id: any) => {

    let elementsArray = this.state.elementArray;
    elementsArray[id].label = event.target.value;
    this.setState({
      elementArray: elementsArray,
    })
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValues,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,

      } as any


      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  }

  handleMandatoryChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[id].mandatory = event.target.checked;
    this.setState({
      elementArray: elementsArray,
    })
    if (this.state.selectedBriefingId) {
      let data = {
        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
      } as any


      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  }
  handleLabelChange = (event: any, id: any) => {

    let elementsArray = this.state.elementArray;
    console.log("element", elementsArray);

    elementsArray[id].label = event.target.value;
    this.setState({
      elementArray: elementsArray,
    })
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any


      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  };
  handleDescriptionChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[id].description = event.target.value;
    this.setState({
      elementArray: elementsArray,
    }, () => {
    });
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_value": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  };
  handleMaxValueChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[id].detailedValues = { "min": this.state.elementArray[id].detailedValues.min, "max": event.target.value };
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValues,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  };

  setSliderDefaultValue = (event: any, item: any, i: any) => {

    const id = this.state.elementArray.findIndex((field: any) => field.unId == item.unId);
    if (this.state.selectedBriefingId) {
      let data = {
        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": event.target.ariaValueNow,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValues,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
      }
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    } else {
      let elementsArray = this.state.elementArray;
      elementsArray[i].defaultValue = event.target.ariaValueNow;
      this.setState({
        elementArray: elementsArray,
      });
    }
  }

  handleMinValueChange = (event: any, id: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[id].detailedValues = { "min": event.target.value, "max": this.state.elementArray[id].detailedValues.max };
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_values": this.state.elementArray[id].detailedValues,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
      } as any
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  };

  handleDefaultValueChange = (event: any, id: any) => {
    console.log(event.target.value)
    let elementsArray = this.state.elementArray;
    elementsArray[id].defaultValue = event.target.value;
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.selectedBriefingId) {
      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_value": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[id].unId, data);
    }
  };

  //   paginationChangeHandler = (event?: any, id: any) =>{

  // console.log("eventPage",event, id );

  //   }

  handleOptionalElementChange = (event: any, idx: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[idx].information = event.target.value;
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.selectedBriefingId) {
      let data = {

        "element": this.state.elementArray[idx].type,
        "label": this.state.elementArray[idx].label,
        "description": this.state.elementArray[idx].description,
        "element_optional_values": this.state.elementArray[idx].elementOptionalValue,
        "default_value": this.state.elementArray[idx].defaultValue,
        "mandatory": this.state.elementArray[idx].mandatory,
        "detailed_value": this.state.elementArray[idx].detailedValue,
        "information": this.state.elementArray[idx].information,
        "template_id": this.state.elementArray[idx].template_id,
        "field_order": idx + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[idx].unId, data);
    }
  };

  onEditorStateChangeParagraph = (id: any, editorState: any, item: any) => {
    let elementsArray = this.state.elementArray;
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    let contentHTML = draftToHtml(contentRaw);

    if (!item?.unId) {
      console.log("first");
      let editorStateTemp = this.state.editorState;
      editorStateTemp[id] = editorState;
      this.setState({
        editorState: editorStateTemp
      })
      let text = contentRaw.blocks[0].text

      // console.log("text",editorStateTemp,editorState);
      elementsArray[id].description = contentHTML;
      this.setState({
        elementArray: elementsArray,
      }, () => {
        // console.log("elementArray",this.state.elementArray);

      });
    }
    else {
      console.log("second");

      let editorStateTemp = this.state.editorState2;
      editorStateTemp[item?.unId] = editorState;
      this.setState({
        editorState2: editorStateTemp
      })
      let text = contentRaw.blocks[0].text

      // console.log("text",editorStateTemp,thiseditorState2);
      elementsArray[id].description = contentHTML;
      this.setState({
        elementArray: elementsArray,
      }, () => {
        // console.log("elementArray",this.state.elementArray);

      });
    }


    if (this.state.selectedBriefingId) {

      let data = {

        "element": this.state.elementArray[id].type,
        "label": this.state.elementArray[id].label,
        "description": this.state.elementArray[id].description,
        "element_optional_values": this.state.elementArray[id].elementOptionalValue,
        "default_value": this.state.elementArray[id].defaultValue,
        "mandatory": this.state.elementArray[id].mandatory,
        "detailed_value": this.state.elementArray[id].detailedValue,
        "information": this.state.elementArray[id].information,
        "template_id": this.state.elementArray[id].template_id,
        "field_order": id + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      if (this.state.elementArray[id].unId) {
        this.upDateFormFieldData(this.state.elementArray[id].unId, data);
      }

    }


  }

  onEditorStateChange = (index: any, editorState: any) => {
    var templateData = this.state.elementArray;
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    let contentHTML = draftToHtml(contentRaw);
    let text = contentRaw.blocks[0].text
    this.setState({
      editorState
    })
    templateData[index].defaultValue = text;
    if (this.state.selectedBriefingId) {
      if (this.state.formCondition && this.state.formCondition.length > 0) {
        let conditionsData = this.state.formCondition.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].unId));
        if (conditionsData.length > 0) {
          templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
        }

        let conditionData = conditionsData.filter((condition: any) => condition.attributes.condition_value == text);

        if (conditionData.length > 0) {
          conditionData.map((condition: any) => {
            let findMatch = templateData.findIndex((data: any) => data.unId == condition.attributes.response_label_id);

            if (condition.attributes.condition_value == text &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== text &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == text &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== text
              && !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == text &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== text &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == text &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== text
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
          })
        }
      }
    }
    else {
      if (this.state.formCondition && this.state.formCondition.length > 0) {
        let conditionsData = this.state.formCondition.filter((condition: any) => condition.condition_label_id == parseInt(index));


        if (conditionsData.length > 0) {
          templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
        }
        let conditionData = conditionsData.filter((condition: any) => condition.condition_value == text);

        if (conditionData.length > 0) {
          conditionData.map((condition: any) => {
            let findMatch = condition.response_label_id


            if (condition.condition_value == text &&
              condition.is_condition_selected && condition.is_response_to_show == "false") {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== text &&
              condition.is_condition_selected && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == text &&
              condition.is_condition_selected == "false" && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== text
              && condition.is_condition_selected == "false" && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == text &&
              condition.is_condition_selected && condition.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== text &&
              condition.is_condition_selected && condition.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == text &&
              condition.is_condition_selected == "false" && condition.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== text
              && condition.is_condition_selected == "false" && condition.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else {
              console.log("notfound")
            }
          })
        }
      }
    }
    this.setState({
      elementArray: templateData
    });
  }

  handlePreviewInputChange = (index: any, event: any) => {
    var templateData = this.state.elementArray;
    // var index = this.state.elementArray.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))

    if (templateData[index].type == "date_time") {
      if (event.target.name == "date") {

        templateData[index].date = event.target.value;
      }
      if (event.target.name == "time") {

        templateData[index].time = event.target.value;

      }
    }
    else if (templateData[index].type == "multiple_selection") {
      if (templateData[index].defaultValue == "") {
        templateData[index].defaultValue = [];

      }
      if (templateData[index].defaultValue && templateData[index].defaultValue.length > 0 &&
        (templateData[index].defaultValue).findIndex((data: any) => data == event.target.value) > -1) {
        var i = templateData[index].defaultValue.findIndex((data: any) => data == event.target.value);
        templateData[index].defaultValue.splice(i, 1);

      } else {
        templateData[index].defaultValue.push(event.target.value);

      }
    } else if (templateData[index].type == "slider") {
      if (event.target.name == "sliderDescription") {
        templateData[index].description = event.target.value
      }
      else if (event.target.name == "min") {
        templateData[index].detailedValues.min = event.target.value
      }
      else if (event.target.name == "max") {
        templateData[index].detailedValues.max = event.target.value
      }
    }
    else {
      templateData[index].defaultValue = event.target.value

    }

    if (this.state.selectedBriefingId) {
      if (this.state.formCondition && this.state.formCondition.length > 0) {
        let conditionsData = this.state.formCondition.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].unId));
        if (conditionsData.length > 0) {
          templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
        }
        let conditionData = conditionsData.filter((condition: any) => condition.attributes.condition_value == event.target.value);
        if (conditionData.length > 0) {
          conditionData.map((condition: any) => {
            let findMatch = templateData.findIndex((data: any) => data.unId == condition.attributes.response_label_id);

            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== event.target.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == event.target.value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== event.target.value
              && !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== event.target.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            if (condition.attributes.condition_value == event.target.value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.attributes.condition_value !== event.target.value
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
          })
        }
      }
    }
    else {
      if (this.state.formCondition && this.state.formCondition.length > 0) {
        let conditionsData = this.state.formCondition.filter((condition: any) => condition.condition_label_id == parseInt(index));


        if (conditionsData.length > 0) {
          templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
        }
        let conditionData = conditionsData.filter((condition: any) => condition.condition_value == event.target.value);

        if (conditionData.length > 0) {
          conditionData.map((condition: any) => {
            let findMatch = condition.response_label_id


            if (condition.condition_value == event.target.value &&
              condition.is_condition_selected && condition.is_response_to_show == "false") {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== event.target.value &&
              condition.is_condition_selected && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == event.target.value &&
              condition.is_condition_selected == "false" && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== event.target.value
              && condition.is_condition_selected == "false" && condition.is_response_to_show == "false") {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == event.target.value &&
              condition.is_condition_selected && condition.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== event.target.value &&
              condition.is_condition_selected && condition.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }

            else if (condition.condition_value == event.target.value &&
              condition.is_condition_selected == "false" && condition.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else if (condition.condition_value !== event.target.value
              && condition.is_condition_selected == "false" && condition.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].type == "file" || templateData[findMatch].type == "image") {
                templateData[findMatch].file = "";
              }
              templateData[findMatch].defaultValue = "";

            }
            else {
              console.log("notfound")
            }
          })
        }
      }
    }

    this.setState({
      elementArray: templateData
    });

  };

  addValues = (event: any, idx: any) => {
    let elementsArray = this.state.elementArray;
    if (this.state.editOptionValue && elementsArray[idx].information !== "") {
      elementsArray[idx].tempValue.splice(this.state.editOptionValueIndex, 0, {
        value: elementsArray[idx].information,
        label: elementsArray[idx].information,
      });
      elementsArray[idx].detailedValues = { "options": elementsArray[idx].tempValue }
      elementsArray[idx].information = "";

      this.setState({
        elementArray: elementsArray,
        editOptionValue: false,
        editOptionValueIndex: null
      });
    }
    else if (elementsArray[idx].information !== "") {
      elementsArray[idx].tempValue.push({
        value: elementsArray[idx].information,
        label: elementsArray[idx].information,
      });
      elementsArray[idx].detailedValues = { "options": elementsArray[idx].tempValue }
      // var counter = Object.keys(elementsArray[idx].elementOptionalValue).length;
      // if (counter === 0) {
      //   counter = counter + 1;

      //   elementsArray[idx].elementOptionalValue[counter] =
      //     elementsArray[idx].information;
      // } else {
      //   counter = counter + 1;
      //   elementsArray[idx].elementOptionalValue[counter] =
      //     elementsArray[idx].information;
      // }
      elementsArray[idx].information = "";

      this.setState({
        elementArray: elementsArray,
      });
    }
    if (this.state.selectedBriefingId) {
      let data = {

        "element": this.state.elementArray[idx].type,
        "label": this.state.elementArray[idx].label,
        "description": this.state.elementArray[idx].description,
        "element_optional_values": this.state.elementArray[idx].elementOptionalValue,
        "default_value": this.state.elementArray[idx].defaultValue,
        "mandatory": this.state.elementArray[idx].mandatory,
        "detailed_values": this.state.elementArray[idx].detailedValues,
        "information": this.state.elementArray[idx].information,
        "template_id": this.state.elementArray[idx].template_id,
        "field_order": idx + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[idx].unId, data);
    }
  };

  handleSingleValueChange = (event: any, idx: any, item: any) => {
    let elementsArray = this.state.elementArray;
    elementsArray[idx].defaultValue = event.target.value;
    this.setState({
      elementArray: elementsArray,
    });
    if (this.state.selectedBriefingId) {
      let data = {

        "element": this.state.elementArray[idx].type,
        "label": this.state.elementArray[idx].label,
        "description": this.state.elementArray[idx].description,
        "element_optional_values": this.state.elementArray[idx].elementOptionalValue,
        "default_value": this.state.elementArray[idx].defaultValue,
        "mandatory": this.state.elementArray[idx].mandatory,
        "detailed_value": this.state.elementArray[idx].detailedValue,
        "information": this.state.elementArray[idx].information,
        "template_id": this.state.elementArray[idx].template_id,
        "field_order": idx + 1,
        // file:'',
        // tempValue: [],
        // condition: {
        //   template_id:this.state.elementArray.template_id,
        //   condition_label_id:"",
        //   condition_value:"",
        //   is_condition_selected: false,
        //   is_response_to_show: false,
        //   response_label_id:"",
        //   conditionLabel:"",
        //   responseLabel:"",
        //   isConditionAdded: false,
        // },

      } as any
      this.upDateFormFieldData(this.state.elementArray[idx].unId, data);
    }
  };
  handleEditOptionValue = (id: any, index: any) => {

    let list = [...this.state.elementArray]

    let filterList = list[id].detailedValues.options.find((item: any, idx: any) => idx == index)
    let updateList = list[id].detailedValues.options.filter((item: any, idx: any) => idx !== index)
    updateList = list[id].tempValue.filter((item: any, idx: any) => idx !== index)

    list[id].tempValue = updateList
    list[id].detailedValues.options = updateList
    list[id].information = filterList.value
    this.setState({
      elementArray: list,
      editOptionValue: true,
      editOptionValueIndex: index,
    })

  }
  handleDeleteOptionValue = (id: any, index: any) => {

    let list = [...this.state.elementArray]

    let updateList = list[id].detailedValues.options.filter((item: any, idx: any) => idx !== index)
    updateList = list[id].tempValue.filter((item: any, idx: any) => idx !== index)
    list[id].tempValue = updateList
    list[id].detailedValues.options = updateList

    this.setState({
      elementArray: list
    })

  }

  handleChangePage = (event: any, value: any) => {
    this.setState({ page: value }, () => {
      if (this.state.page == 1) {
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      } else if (this.state.page == 2) {

        this.setState({ fromStart: this.state.pageSeparatorIndex[0] + 1, fromEnd: this.state.pageSeparatorIndex[1] + 1 })
      } else if (this.state.page == 3) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[1] + 1, fromEnd: this.state.pageSeparatorIndex[2] + 1 })
      } else if (this.state.page == 4) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[2] + 1, fromEnd: this.state.pageSeparatorIndex[3] + 1 })
      } else if (this.state.page == 5) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[3] + 1, fromEnd: this.state.pageSeparatorIndex[4] + 1 })
      } else if (this.state.page == 6) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[4] + 1, fromEnd: this.state.pageSeparatorIndex[5] + 1 })
      } else if (this.state.page == 7) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[5] + 1, fromEnd: this.state.pageSeparatorIndex[6] + 1 })
      } else if (this.state.page == 8) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[6] + 1, fromEnd: this.state.pageSeparatorIndex[7] + 1 })
      } else if (this.state.page == 9) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[7] + 1, fromEnd: this.state.pageSeparatorIndex[8] + 1 })
      } else if (this.state.page == 10) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[8] + 1, fromEnd: this.state.pageSeparatorIndex[9] + 1 })
      }
    });
  };

  handlePreviewDialogOpen = () => {
    let templateData = this.state.elementArray
    this.setState({
      previewDialogOpen: true,
    })
    if (this.state.selectedBriefingId) {
      this.getFormCondition(this.state.templateSpecificID)
    }
    else {
      let formConditions = [] as any
      this.state.elementArray.map((data: any) => {
        data.condition.map((_condition: any) => {
          if (_condition.isConditionAdded == true) {

            formConditions.push(_condition)
          }
        })
      })

      this.setState({
        formCondition: formConditions
      })
    }
    let pagination;
    templateData.map((items: any) => {
      if (items.type == "page_separator") {
        pagination = true
      }
    })

    if (pagination == true) {
      let dataLength = templateData.length;
      // let removed = templateData.shift();
      templateData.push({
        "default_value": "page_separator",
        "type": "page_separator",
        // "label": "",
        "id": "remove_able_for_demo"
      })
      let pageSeparatorIndex: any = []
      var paginationCount = templateData.map((items: any) => items.type == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
      templateData.map((_data: any, index: any) => {
        if (_data.type == "page_separator") {
          pageSeparatorIndex.push(index)
        }
      })
      this.setState({
        paginationCount: paginationCount.true,

      });
      if (paginationCount.true !== undefined) {
        this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {

          this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
        })
      } else {
        this.setState({ fromStart: 0, fromEnd: 100 })
      }
    } else {
      this.setState({ fromStart: 0, fromEnd: 100 })
    }
    templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
    this.setState({
      elementArray: templateData,
    });

  };

  handlePreviewDialogClose = () => {
    this.setState({
      previewDialogOpen: false,
      elementArray: [
        ...this.state.elementArray.filter(
          (item: any,) => item.id !== "remove_able_for_demo"
        ),
      ],
      formCondition: [],
      pageSeparatorIndex: [],
      page: 1,
      fromStart: 0,
      fromEnd: 0,
      paginationCount: "",
    });

  };
  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  getSignedUrl = (file: any) => {
    console.log("File", file)
    this.setState({
      fileToRawBinary: file,
      uploading: true
    })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    });
  }


  getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }



  render() {
    const { t } = this.props;
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 100,
        label: '100',
      },

    ];
    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.uploading}
        // onClick={() => this.setState({ loading: false })}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Drawer
          className={this.props.classes.drawer}
          variant="permanent"
          classes={{
            paper: this.props.classes.drawerPaper,
          }}
          anchor="left"
        >
          <img
            src={logo.default}
            className={this.props.classes.designHarvestLogo}
          />
          <Typography
            variant="body2"
            style={{
              fontFamily: "Inter",
              fontSize: "13px",
              color: "#1a76b0",
              letterSpacing: "0px",
              opacity: 1,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Select the format to build form
          </Typography>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "short_text",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValue: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Short Text" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "short_text",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValue: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "long_text",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValue: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Long Text" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "long_text",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValue: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "email",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValue: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary={t("AdminConsole.email")} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "email",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValue: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "date_time",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValues: {
                    date: "",
                    time: ""
                  },
                  file: "",
                  information: "",
                  date: "",
                  time: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Date / Time" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "date_time",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValues: {
                        date: "",
                        time: ""
                      },
                      file: "",
                      information: "",
                      date: "",
                      time: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "drop_down_select",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Dropdown Selection" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "drop_down_select",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValues: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "single_selection",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Single Selection" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "single_selection",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValues: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "multiple_selection",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true

                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Multiple Selection" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "multiple_selection",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValues: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "image",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValue: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Image Upload" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "image",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValue: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "file",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "",
                  mandatory: "",
                  detailedValue: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="File Upload" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "file",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "",
                      mandatory: "",
                      detailedValue: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "page_separator",
                  // label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValue: "page_separator",
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  // preview_visibility : true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Page Seperator" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "page_separator",
                      // label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValue: "page_separator",
                      mandatory: "",
                      detailedValues: "",
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      // preview_visibility : true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "slider",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValues: {},
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Slider" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "slider",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValues: "",
                      mandatory: "",
                      detailedValues: {},
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "paragraph",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValues: {},
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Paragraph" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "paragraph",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValues: "",
                      mandatory: "",
                      detailedValues: {},
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>


            <ListItem
              button
              onClick={() =>
                this.handleListItemClick({
                  type: "image_upload",
                  label: "",
                  description: "",
                  elementOptionalValue: {},
                  defaultValues: {},
                  mandatory: "",
                  detailedValues: "",
                  file: "",
                  information: "",
                  tempValue: [],
                  condition: [],
                  showField: 'true',
                  titleEditDisabled: false,
                  fileName: "",
                  file_blob_signed_id: null,
                  preview_visibility: true
                })
              }
            >
              <ListItemIcon>
                <MailIcon style={{ color: "#1a76b0" }} />
              </ListItemIcon>
              <ListItemText primary="Simple image" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    this.handleListItemClick({
                      type: "image_upload",
                      label: "",
                      description: "",
                      elementOptionalValue: {},
                      defaultValues: "",
                      mandatory: "",
                      detailedValues: {},
                      file: "",
                      information: "",
                      tempValue: [],
                      condition: [],
                      showField: 'true',
                      titleEditDisabled: false,
                      fileName: "",
                      file_blob_signed_id: null,
                      preview_visibility: true
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon style={{ color: "#1a76b0" }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Drawer>
        <main className={this.props.classes.content}>
          <Grid container>
            <Grid item xs={12}>
              {this.state.templateFrom == "" ?

                <>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      to="/AdminConsole"
                      style={{
                        color: "#1a76b0",
                        fontSize: "12px",
                        textDecoration: "none",
                        textTransform: "uppercase",
                        fontFamily: "Work Sans",
                      }}
                    >
                      Home
                    </Link>
                    {this.state.screenType.toUpperCase() == 'AssetCategories'.toUpperCase() ? (
                      <Link
                        to="/AdminConsole/AssetCategories"
                        style={{
                          color: "#1a76b0",
                          fontSize: "12px",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          fontFamily: "Work Sans",
                        }}
                      >
                        {t("AdminConsole.assetCategories")}
                      </Link>
                    ) : (
                      <Link
                        to="/AdminConsole/AssetCategories"
                        style={{
                          color: "#1a76b0",
                          fontSize: "12px",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          fontFamily: "Work Sans",
                        }}
                      >
                        {t("AdminConsole.assetCategories")}
                      </Link>
                    )
                    }
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "12px",
                        color: "#1a76b0",
                        textTransform: "uppercase",
                        fontFamily: "Work Sans",
                        textDecoration: "none",
                      }}
                    >
                      {this.state.screenType.toUpperCase() == 'AssetCategories'.toUpperCase() ? 'Create New Briefing Form' : this.state.selectedBriefingId ? 'Edit Briefing Form' : 'Create New Briefing Form'}
                    </Typography>
                  </Breadcrumbs>
                </>
                :
                this.state.templateFrom == "templatedetails" ?
                  <>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        to="/AdminConsole"
                        style={{
                          color: "#1a76b0",
                          fontSize: "12px",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          fontFamily: "Work Sans",
                        }}
                      >
                        Home
                      </Link>


                      <Link
                        to="/AdminConsole/TemplateManagement"
                        style={{
                          color: "#1a76b0",
                          fontSize: "12px",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          fontFamily: "Work Sans",
                        }}
                      >
                        TEMPLATE DETAILS
                      </Link>

                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#1a76b0",
                          textTransform: "uppercase",
                          fontFamily: "Work Sans",
                          textDecoration: "none",
                        }}
                      >
                        {this.state.templateFrom == "templatedetails" ? this.state.selectedBriefingId ? "Edit Template" : "Create Template" : ""}
                      </Typography>
                    </Breadcrumbs>
                  </>
                  : ""

              }
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Grid container direction="row">
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#1a76b0",
                      textTransform: "uppercase",
                      opacity: 1,
                    }}
                  >
                    {this.state.templateTitle}
                  </Typography>

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      style={{
                        float: "right",
                        background: "#173e59",
                        border: "1px solid #173e59",
                        width: "190px",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      onClick={this.createTemplate}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        {this.state.screenType.toUpperCase() == 'AssetCategories'.toUpperCase() ? 'Save Briefing Form' : this.state.selectedBriefingId ? 'Update Briefing Form' : t("AdminConsole.saveTemplate")}
                      </Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        float: "right",

                        border: "1px solid #173e59",
                        width: "136px",
                        height: "32px",
                        marginRight: "30px",
                      }}
                      onClick={this.handlePreviewDialogOpen}
                    >
                      <VisibilityIcon
                        style={{
                          color: "#173e59",
                          width: "16px",
                          height: "25px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#173e59",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Preview
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px", position: "relative" }}>
              <Grid container
                justify="center"
                alignItems="center"
              >
                <Input
                  type="text"
                  classes={{
                    input: this.props.classes.input,
                  }}
                  value={this.state.templateTitle}
                  disableUnderline={true}
                  onChange={this.handleTemplateTitle}
                  className={
                    !this.state.isTemplateTitleValid
                      ? this.props.classes.titleError
                      : this.props.classes.templateTitle
                  }
                />

              </Grid>
              <FormControlLabel
                style={{ position: "absolute", right: 0, top: "20px" }}
                control={
                  <Checkbox
                    checked={this.state.show_instruction == null ? false : this.state.show_instruction}
                    onChange={this.instructionHandler}
                  />
                }
                label="Want instruction widget ?"
              />



              {!this.state.isTemplateTitleValid ? (
                <Grid container justify="center" alignItems="center">
                  <span style={{ color: "red", marginTop: "10px" }}>
                    {this.state.templateTitleErrorMessage}
                  </span>
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            {!this.state.loading ? (this.state.elementArray.map((item: any, i: any) => {
              switch (item.type) {

                case "short_text":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "10px",
                                          }}
                                          placeholder="Short Text Label"
                                          disableUnderline
                                          fullWidth
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}

                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />
                                        </IconButton>

                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>

                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "10px",
                                        width: "100%",
                                      }}
                                      placeholder="Short Text Description"
                                      disableUnderline
                                      value={item.description}
                                      onChange={(e) =>
                                        this.handleDescriptionChange(e, i)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      placeholder="Enter Text"
                                      style={{
                                        width: "100%",
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                      }}
                                      disableUnderline
                                      value={item.defaultValue}
                                      onChange={(e) =>
                                        this.handleDefaultValueChange(e, i)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "long_text":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "15px",
                                          }}
                                          placeholder="Long Text Label"
                                          fullWidth
                                          disableUnderline
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          // onClick={() => this.handleConditionDialog(i)}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>
                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Long Text Description"
                                      disableUnderline
                                      value={item.description}
                                      onChange={(e) =>
                                        this.handleDescriptionChange(e, i)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      placeholder="Default Value"
                                      style={{
                                        width: "100%",
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                      }}
                                      disableUnderline
                                      value={item.defaultValue}
                                      onChange={(e) =>
                                        this.handleDefaultValueChange(e, i)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "email":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "15px",
                                          }}
                                          placeholder="Email Label"
                                          fullWidth
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disableUnderline
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>


                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />


                                        </IconButton>

                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          // onClick={() => this.handleConditionDialog(i)}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>
                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Email Description"
                                      disableUnderline
                                      value={item.description}
                                      onChange={(e) =>
                                        this.handleDescriptionChange(e, i)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        width: "100%",
                                        marginLeft: "18px",
                                        fontSize: "12px",
                                      }}
                                      placeholder="Enter Email"
                                      disableUnderline
                                      value={item.defaultValue}
                                      onChange={(e) =>
                                        this.handleDefaultValueChange(e, i)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "date_time":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          width: "100%",
                                          marginLeft: "18px",
                                          fontSize: "12px",
                                        }}
                                        placeholder="Enter Date Time label"
                                        disableUnderline
                                        fullWidth
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px dashed #9EABB5",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Description"
                                    disableUnderline
                                    value={item.description}
                                    onChange={(e) =>
                                      this.handleDescriptionChange(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <TextField
                                        id="date"
                                        type="date"
                                        //  defaultValue={item.detailedValues !== null ? item.detailedValues.date :item.defaultValue}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        style={{
                                          width: "232px",
                                          border: "1px solid #F0F0F0",
                                          padding: "10px",
                                        }}
                                        value={item.detailedValues !== null ? item.detailedValues.date : item.defaultValue}
                                        onChange={(e) => {
                                          this.handleDateChange(e, i);
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      style={{
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <TextField
                                        id="time"
                                        type="time"
                                        defaultValue={item.detailedValues !== null ? item.detailedValues.time : item.defaultValue}
                                        style={{
                                          border: "1px solid #F0F0F0",
                                          width: "232px",
                                          padding: "10px",
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          step: 300, // 5 min
                                        }}
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        value={item.detailedValues !== null ? item.detailedValues.time : item.defaultValue}
                                        onChange={(e) =>
                                          this.handleTimeChange(e, i)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                case "multiple_selection":
                  return (
                    <Grid item xs={12} style={{}}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          width: "100%",
                                          marginLeft: "18px",
                                          fontSize: "12px",
                                        }}
                                        placeholder="Enter Multiple Selection Label"
                                        disableUnderline
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>

                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>

                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px dashed #9EABB5",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      color: "#9EABB5",
                                      fontSize: "12px",
                                      fontFamily: "Inter",
                                      marginLeft: "18px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    multiple answers possible
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                    }}
                                    placeholder="Enter Value"
                                    value={item.information}
                                    disableUnderline
                                    onChange={(e) =>
                                      this.handleOptionalElementChange(e, i)
                                    }
                                  />
                                  <Button onClick={(e) => this.addValues(e, i)}>
                                    Add
                                  </Button>
                                </Grid>
                                {item.tempValue.length !== 0
                                  ? item.detailedValues.options.map((_data: any, index: any) => (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        paddingLeft: "15px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Grid style={{
                                        border: "1px solid #F0F0F0",
                                        width: "750px",
                                        marginLeft: "4px",
                                      }}>
                                        <FormControlLabel
                                          style={{

                                            marginLeft: "4px",
                                          }}
                                          control={
                                            <Checkbox
                                              // checked={false}
                                              name={_data.label}
                                              value={_data.value}
                                            />
                                          }
                                          label={_data.label}
                                        />
                                        <DeleteForeverIcon style={{ cursor: 'pointer', margin: '0px 30px 0px 10px', float: 'right' }} onClick={() => this.handleDeleteOptionValue(i, index)} />
                                        <EditOutlinedIcon style={{ cursor: 'pointer', margin: '0px 10px', float: 'right' }} onClick={() => this.handleEditOptionValue(i, index)} />
                                      </Grid>
                                    </Grid>
                                  ))
                                  : ""}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                case "single_selection":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                        }}
                                        placeholder="Single Selection Label"
                                        fullWidth
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disableUnderline
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>

                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Single Selection Description"
                                    disableUnderline
                                    value={item.description}
                                    onChange={(e) =>
                                      this.handleDescriptionChange(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                    }}
                                    placeholder="Enter Value"
                                    value={item.information}
                                    disableUnderline
                                    onChange={(e) =>
                                      this.handleOptionalElementChange(e, i)
                                    }
                                  />
                                  <Button onClick={(e) => this.addValues(e, i)}>
                                    Add
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <RadioGroup
                                      aria-label="gender"
                                      name="gender1"
                                      onChange={(e) => {
                                        this.handleSingleValueChange(
                                          e,
                                          i,
                                          item
                                        );
                                      }}
                                    >
                                      {item.tempValue.length !== 0
                                        ? item.detailedValues.options.map((_data: any, index: any) => (
                                          <Grid style={{
                                            border: "1px solid #F0F0F0",
                                            width: "750px",
                                            marginTop: "5px",
                                            marginBottom: "5px",
                                            marginLeft: "4px",
                                          }}>
                                            <FormControlLabel
                                              value={_data.value}
                                              control={

                                                <Radio
                                                  disableRipple
                                                  classes={{
                                                    root: this.props.classes
                                                      .radio,
                                                    checked: this.props.classes
                                                      .checked,
                                                  }}
                                                />


                                              }
                                              label={_data.label}
                                              style={{
                                                marginLeft: '5px'
                                              }}
                                            />
                                            <DeleteForeverIcon style={{ cursor: 'pointer', margin: '0px 30px 0px 10px', float: 'right' }} onClick={() => this.handleDeleteOptionValue(i, index)} />
                                            <EditOutlinedIcon style={{ cursor: 'pointer', margin: '0px 10px', float: 'right' }} onClick={() => this.handleEditOptionValue(i, index)} />

                                          </Grid>
                                        ))
                                        : ""}
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                case "image":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                        }}
                                        placeholder="Image Label"
                                        fullWidth
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disableUnderline
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton><IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px dashed #9EABB5",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Description"
                                    disableUnderline
                                    value={item.description}
                                    onChange={(e) =>
                                      this.handleDescriptionChange(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    padding: "0px 15px",
                                  }}
                                >
                                  <input
                                    accept="image/*"
                                    style={{
                                      display: "none",
                                    }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                  />
                                  <label htmlFor="raised-button-file">
                                    <Button
                                      variant="outlined"
                                      style={{
                                        border: "1px dashed #9EABB5",
                                        width: "100%",

                                        height: "72px",
                                      }}
                                    >
                                      Upload file
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                case "file":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                        }}
                                        placeholder="File Label"
                                        fullWidth
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disableUnderline
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>

                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px dashed #9EABB5",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                <Grid item xs={12}>
                                  <Input
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Description"
                                    disableUnderline
                                    value={item.description}
                                    onChange={(e) =>
                                      this.handleDescriptionChange(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    padding: "0px 15px",
                                  }}
                                >
                                  <input
                                    accept="image/*"
                                    style={{
                                      display: "none",
                                    }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                  />
                                  <label htmlFor="raised-button-file">
                                    <Button
                                      variant="outlined"
                                      style={{
                                        border: "1px dashed #9EABB5",
                                        width: "100%",

                                        height: "72px",
                                      }}
                                    >
                                      Upload file
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                case "drop_down_select":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "10px",
                                          }}
                                          placeholder="Dropdown Label"
                                          disableUnderline
                                          fullWidth
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />
                                        </IconButton>

                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          // onClick={() => this.handleConditionDialog(i)}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>
                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Dropdown Description"
                                      disableUnderline
                                      value={item.description}
                                      onChange={(e) =>
                                        this.handleDescriptionChange(e, i)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                      }}
                                      placeholder="Enter Value"
                                      value={item.information}
                                      disableUnderline
                                      onChange={(e) =>
                                        this.handleOptionalElementChange(e, i)
                                      }
                                    />
                                    <Button
                                      onClick={(e) => this.addValues(e, i)}
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      marginRight: "18px",
                                    }}
                                  >
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      label="Age"
                                      style={{
                                        width: "100%",
                                        marginLeft: "18px",
                                        border: "1px solid #9EABB5 ",
                                        marginTop: "15px",
                                      }}
                                      disableUnderline
                                      value={item.defaultValue}
                                      onChange={(e) =>
                                        this.handleDefaultValueChange(e, i)
                                      }
                                    >
                                      {/* <MenuItem> None</MenuItem> */}
                                      {
                                        item.tempValue.length !== 0
                                          ? item.detailedValues.options.map((_data: any, index: any) => (
                                            <>
                                              <MenuItem value={_data.value} style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                {_data.label}
                                                <div>
                                                  <EditOutlinedIcon style={{ cursor: 'pointer', margin: '0px 10px 0 50px', }} onClick={() => this.handleEditOptionValue(i, index)} />
                                                  <DeleteForeverIcon style={{ cursor: 'pointer', margin: '0px 10px', }} onClick={() => this.handleDeleteOptionValue(i, index)} />
                                                </div>
                                              </MenuItem>

                                            </>
                                          ))
                                          :
                                          ""
                                      }
                                    </Select>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "slider":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "10px",
                                          }}
                                          placeholder="Slider Label"
                                          disableUnderline
                                          fullWidth
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          // onClick={() => this.handleConditionDialog(i)}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>

                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>

                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Slider Description"
                                      disableUnderline
                                      value={item.description}
                                      onChange={(e) =>
                                        this.handleDescriptionChange(e, i)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Minimun value"
                                      disableUnderline
                                      value={item?.detailedValues?.min}
                                      // onMouseOver={() => { this.setState({ minValues: item.detailedValues.min ||  this.state.minValues}) }}
                                      onChange={(e) => {
                                        this.setState({ minValues: e.target.value })
                                        this.handleMinValueChange(e, i)
                                      }
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Input
                                      type="text"
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "18px",
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                      placeholder="Maximun value"
                                      disableUnderline
                                      value={item?.detailedValues?.max}
                                      // onMouseOver={() => { this.setState({ maxValues: item.detailedValues.max ||  this.state.maxValues}) }}
                                      onChange={(e) => {
                                        this.setState({ maxValues: e.target.value })
                                        this.handleMaxValueChange(e, i);
                                      }
                                      }
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      marginRight: "30px",
                                    }}
                                  >
                                    <CustomSlider
                                      style={{
                                        width: "100%",
                                        marginLeft: "18px",
                                        marginTop: "18px",


                                      }}
                                      defaultValue={item.defaultValue}
                                      // getAriaValueText={valuetext}
                                      // onChange={(e) =>
                                      //   this.handleDefaultValueChange(e, i)
                                      // }
                                      aria-labelledby="discrete-slider-always"
                                      //step={10}
                                      marks={[
                                        {
                                          value: item?.detailedValues?.min ? parseInt(item?.detailedValues?.min) : 0,
                                          label: item?.detailedValues?.min ? item?.detailedValues?.min : '0',
                                        },
                                        {
                                          value: item?.detailedValues?.max ? parseInt(item?.detailedValues?.max) : 100,
                                          label: item?.detailedValues?.max ? item?.detailedValues?.max : '100',
                                        },

                                      ]}
                                      min={item?.detailedValues?.min ? parseInt(item?.detailedValues?.min) : 0}
                                      max={item?.detailedValues?.max ? parseInt(item?.detailedValues?.max) : 100}
                                      valueLabelDisplay="on"
                                      onChangeCommitted={(e: any) => this.setSliderDefaultValue(e, item, i)}

                                    //ValueLabelComponent={StyledValueLabel}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "page_separator":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      {/* <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "10px",
                                        }}
                                        placeholder="Label"
                                        disableUnderline
                                        fullWidth
                                        value={item.label}
                                        onChange={(e: any) => {
                                          this.handlePagination(e, i)
                                        }
                                        }
                                      /> */}
                                      <Pagination count={10} style={{ marginTop: "20px" }}
                                      // defaultPage={1} page={item?.label ? parseInt(item?.label) : 1}
                                      //  onChange={()=>this.paginationChangeHandler(i)} 
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          this.moveItemDownward(i)
                                        }
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {/* {item.preview_visibility == true ?
                                        <Visibility 
                                        // fontSize="small"
                                        style={{ color: "#9e9e9e" }} 
                                        onClick={()=>this.visibilityHandler(i, false)}
                                        /> :
                                        <VisibilityOffOutlined 
                                        style={{ color: "#9e9e9e" }}
                                        onClick={()=>this.visibilityHandler(i, true)}
                                        />
                                        } */}
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>


                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    // <Grid item xs={12}>
                    //   <Grid
                    //     container
                    //     spacing={0}
                    //     direction="column"
                    //     alignItems="center"
                    //     justify="center"
                    //   >
                    //     <Grid item xs={8}>

                    //       <Pagination count={10} style={{ marginTop: "20px" }} />
                    //     </Grid>
                    //   </Grid>
                    // </Grid>

                  );
                case "paragraph":
                  return (
                    <>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={8}>
                            <Card className={this.props.classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Input
                                          type="text"
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "18px",
                                            marginTop: "10px",
                                          }}
                                          placeholder="Paragraph"
                                          disableUnderline
                                          fullWidth
                                          value={item.label}
                                          onChange={(e) =>
                                            this.handleLabelChange(e, i)
                                          }
                                          disabled={item.titleEditDisabled}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            if (this.state.templateSpecificID !== "" && item.unId) {
                                              this.removeTemplateForm(item.unId)
                                            } else {
                                              this.removeItem(i)
                                            }
                                          }
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}

                                          disabled={i == 0 ? true : false}
                                          onClick={() => this.moveItemUpward(i)}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.moveItemDownward(i)
                                          }
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          onClick={() => this.handleConditionDialog(i)}
                                        >
                                          <DeviceHubIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                          disabled={item.unId ? false : true}
                                          // onClick={() => this.handleConditionDialog(i)}
                                          onClick={() => this.duplicateBriefingField(item.unId)}
                                        >
                                          <FileCopyTwoToneIcon />
                                        </IconButton>
                                        <IconButton
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          {item.preview_visibility == true ?
                                            <Visibility
                                              // fontSize="small"
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, false, item.unId)}
                                            /> :
                                            <VisibilityOffOutlined
                                              style={{ color: "#9e9e9e" }}
                                              onClick={() => this.visibilityHandler(i, true, item.unId)}
                                            />
                                          }
                                        </IconButton>
                                        <IconButton style={{
                                          float: "right",
                                        }}>
                                          <ThemeProvider theme={theme}>
                                            <FormGroup>
                                              <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                            </FormGroup>
                                          </ThemeProvider>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>

                                    <Editor
                                      editorState={item?.unId ? this.state.editorState2[item?.unId] : this.state.editorState[i]}
                                      // toolbarClassName="toolbarClassName"
                                      // wrapperClassName="wrapperClassName"
                                      // editorClassName="editorClassName"
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      placeholder="Add Paragraph"
                                      // defaultEditorState={this.state.editorState2[i]}
                                      stripPastedStyles={true}
                                      onEditorStateChange={(editorState) => this.onEditorStateChangeParagraph(i, editorState, item)}
                                      wrapperStyle={{
                                        height: 510,
                                        border: "1px solid black",

                                        // display: "flex",
                                        // flexDirection: "column-reverse",
                                      }}
                                      editorStyle={{
                                        padding: 10,
                                        height: 445,
                                        overflow: "scroll"
                                      }}
                                      toolbarStyle={{
                                        height: 50,
                                        fontSize: 18,
                                      }}
                                      //onEditorStateChange={this.onEditorStateChange}
                                      toolbar={{
                                        inline: {
                                          // inDropdown: false,
                                          // className: "test",
                                          // component: undefined,
                                          // dropdownClassName: undefined,
                                          options: ["bold", "italic"],
                                          // bold: { className: "test", style: { height:80 } },
                                          // italic: { className: undefined },
                                        },

                                        blockType: {
                                          inDropdown: true,
                                          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                          // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                          // component: undefined,
                                          // dropdownClassName: undefined,
                                        },
                                        fontSize: {
                                          // icon: Icon.,
                                          options: [7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                          // className: "demo-option-custom-medium",
                                          // component: undefined,
                                          // dropdownClassName: undefined,
                                          inDropdown: true,
                                        },
                                        fontFamily: {
                                          options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                          // className: undefined,
                                          // component: undefined,
                                          // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                          inDropdown: true,
                                        },
                                        list: {
                                          // inDropdown: false,
                                          // className: "test",
                                          // component: undefined,
                                          // dropdownClassName: undefined,
                                          options: ["unordered", "ordered"],
                                          // unordered: {
                                          //   className: undefined,
                                          // },
                                          // ordered: {
                                          //   className: undefined,
                                          // },
                                          // indent: { icon: indent, className: undefined },
                                          // outdent: { icon: outdent, className: undefined },
                                        },
                                        link: {
                                          // inDropdown: false,
                                          // className: undefined,
                                          // component: undefined,
                                          // popupClassName: undefined,
                                          // dropdownClassName: undefined,
                                          // showOpenOptionOnHover: true,
                                          // defaultTargetOption: "_self",
                                          options: ["link"],
                                          // link: { icon: link, className: undefined },
                                          // unlink: { icon: unlink, className: undefined },
                                          // linkCallback: undefined,
                                        },

                                        options: [
                                          "inline",
                                          "blockType",
                                          "fontSize",
                                          'fontFamily',
                                          'list',
                                          'textAlign',
                                          // "list",
                                          // "link",
                                          // "emoji",
                                          // "image",
                                        ],


                                        emoji: {
                                          // icon: emoji,
                                          className: undefined,
                                          component: undefined,
                                          popupClassName: undefined,
                                          emojis: [
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "珞",
                                            "樂",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "邏",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "⛷",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "⛹",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "落",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "✊",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "⏰",
                                            "",
                                            "",
                                            "",
                                            "⭐",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "⛄",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "✅",
                                            "❎",
                                            "",
                                          ],
                                        },
                                        image: {
                                          //icon:,
                                          className: undefined,
                                          component: undefined,
                                          popupClassName: undefined,
                                          urlEnabled: true,
                                          uploadEnabled: true,
                                          alignmentEnabled: true,
                                          uploadCallback: undefined,
                                          previewImage: false,
                                          inputAccept:
                                            "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                          alt: {
                                            present: false,
                                            mandatory: false,
                                          },
                                          defaultSize: {
                                            height: "auto",
                                            width: "auto",
                                          },
                                        },
                                      }}
                                    />
                                    {/* <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                        placeholder="Short Text Description"
                                        disableUnderline
                                        value={item.description}
                                        onChange={(e) =>
                                          this.handleDescriptionChange(e, i)
                                        }
                                      /> */}

                                  </Grid>
                                  {/* <Grid item xs={12}> */}
                                  {/* <Input
                                        type="text"
                                        placeholder="Enter Text"
                                        style={{
                                          width: "100%",
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                        }}
                                        disableUnderline
                                        value={item.defaultValue}
                                        onChange={(e) =>
                                          this.handleDefaultValueChange(e, i)
                                        }
                                      /> */}
                                  {/* </Grid> */}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "image_upload":
                  return (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item xs={8}>
                          <Card className={this.props.classes.cardRoot}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                        }}
                                        placeholder="Simple Image Upload"
                                        fullWidth
                                        value={item.label}
                                        onChange={(e) =>
                                          this.handleLabelChange(e, i)
                                        }
                                        disableUnderline
                                        disabled={item.titleEditDisabled}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          if (this.state.templateSpecificID !== "" && item.unId) {
                                            this.removeTemplateForm(item.unId)
                                          } else {
                                            this.removeItem(i)
                                          }
                                        }
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={i == 0 ? true : false}
                                        onClick={() => this.moveItemUpward(i)}
                                      >
                                        <ArrowUpwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.moveItemDownward(i)}
                                      >
                                        <ArrowDownwardIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => this.handleConditionDialog(i)}
                                      >
                                        <DeviceHubIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                        disabled={item.unId ? false : true}
                                        // onClick={() => this.handleConditionDialog(i)}
                                        onClick={() => this.duplicateBriefingField(item.unId)}
                                      >
                                        <FileCopyTwoToneIcon />
                                      </IconButton>

                                      <IconButton
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {item.preview_visibility == true ?
                                          <Visibility
                                            // fontSize="small"
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, false, item.unId)}
                                          /> :
                                          <VisibilityOffOutlined
                                            style={{ color: "#9e9e9e" }}
                                            onClick={() => this.visibilityHandler(i, true, item.unId)}
                                          />
                                        }
                                      </IconButton>
                                      <IconButton style={{
                                        float: "right",
                                      }}>
                                        <ThemeProvider theme={theme}>
                                          <FormGroup>
                                            <FormControlLabel control={<Switch checked={item.mandatory} onChange={(e) => { this.handleMandatoryChange(e, i) }} />} label="" />
                                          </FormGroup>
                                        </ThemeProvider>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px dashed #9EABB5",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                  }}
                                />
                                {/* <Grid item xs={12}>
                                      <Input
                                        type="text"
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "18px",
                                          marginTop: "15px",
                                          width: "100%",
                                        }}
                                        placeholder="Description"
                                        disableUnderline
                                        value={item.description}
                                        onChange={(e) =>
                                           this.handleDescriptionChange(e, i)
                                        }
                                      />
                                    </Grid> */}
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    padding: "0px 15px",
                                  }}
                                  onClick={() => this.setState({ selectedImageId: i })}
                                >
                                  {/* <input
                                        accept="image/*"
                                        style={{
                                          display: "none",
                                        }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                      /> */}

                                  <label htmlFor="raised-button-file">
                                    <div>
                                      {item?.file_blob_signed_id_url ?
                                        <img src={item?.file_blob_signed_id_url} alt="image" style={{
                                          maxWidth: "200%",
                                          maxHeight: "440px",
                                          padding: "15px",
                                          display:"block", marginLeft:"auto", marginRight:"auto"
                                        }} />
                                        :
                                        null}
                                    </div>
                                    <Button
                                      variant="outlined"
                                      style={{
                                        border: "1px dashed #9EABB5",
                                        width: "100%",

                                        height: "72px",
                                      }}
                                      onClick={() => {
                                        if (item?.file_blob_signed_id) {
                                          var tempo = this.state.replaceTemp;
                                          tempo.push(item.unId)
                                          this.setState({ replaceTemp: tempo })
                                        }

                                        this.fileInput.click()
                                      }}
                                    >
                                      <ReactS3Uploader
                                        type="file"
                                        id="upload"
                                        name="pic"
                                        // className={uploaderClassName}
                                        getSignedUrl={this.getSignedUrl}
                                        //accept="image/*"
                                        // onProgress={onProgress}
                                        // onError={onError}
                                        // onFinish={onFinish}
                                        uploadRequestHeaders={{
                                          'x-amz-acl': 'public-read'
                                        }}
                                        s3path="/uploads/"
                                        contentDisposition="auto"
                                        accept="image/**"
                                        capture={true}
                                        //  ref={this.fileInput}
                                        inputRef={cmp => this.fileInput = cmp}
                                        // onChange={(event: any) =>
                                        //   this.inputFileChanged(event)
                                        // }
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          // width: "280px",
                                          // alignContent: "center", backgroundColor: 'white',
                                          // color: secondaryColorToBeChanged,
                                          // width: "280px", padding: 20,
                                          // fontSize: 16, fontWeight: 'bold',
                                          // borderWidth: 1,
                                          // borderColor: secondaryColorToBeChanged,
                                          // borderRadius: 10,
                                          // borderStyle: 'dashed',
                                          // fontVariant: "initial",
                                          // textTransform: 'none',
                                          // marginBottom: "20px"
                                        }}
                                      />

                                      {item?.file_blob_signed_id_url ? "Replace" : "Upload image"}
                                    </Button>
                                  </label>
                                </Grid>

                                {item?.fileName?.length ? <p>{item?.fileName}</p> : null}

                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                default:
                  return <></>;
              }
            })) : <p>Loading</p>}
            {/* //Second Card */}
          </Grid>
        </main>
        {/* Delete Dialog */}
        <Dialog
          onClose={this.handleDeleteDialogCloase}
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.deleteDialog}
        >
          <DialogTitle id="customized-dialog-title" style={{ marginRight: "15px" }}>

            Do you want to delete this condition?
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleDeleteDialogCloase} />
            </span>
          </DialogTitle>


          <DialogActions style={{ justifyContent: "flex-start" }}>

            <Button
              style={{
                backgroundColor: "rgb(23, 62, 89)",
                color: "white",
                marginLeft: "15px",
              }}
              type="submit"
              variant="contained"
              onClick={() => {
                this.deleteFormCondition(this.state.selectedDeleteCondition)

              }}
            >Yes

            </Button>
            <Button

              style={{ backgroundColor: "white", color: "black" }}

              variant="contained"
              onClick={this.handleDeleteDialogCloase}
            >
              No
            </Button>
          </DialogActions>


        </Dialog>

        {/* Condition Dialog Start */}

        <Dialog
          onClose={this.handleAddEditClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.addEditDialog}
          fullWidth={false}
          //  maxWidth={"xs"}
          style={{ width: '595px', alignItems: 'center', justifyContent: "center", margin: "auto" }}


        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ minWidth: "528px", borderBottom: "1px solid #E7E7E7" }}
          >
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#1a76b0",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Edit Condition
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={this.handleAddEditClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent style={{ padding: "25px" }}>
            {this.state.addEditDialog ? <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: "#0E0E0E",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  If
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disableUnderline
                  value={this.state.selectedConditionFirstLabel}
                  onChange={(e) => {
                    this.handleFirstSelect(e);
                  }}
                  style={{
                    width: "100%",
                    border: "1px solid #9EABB5",
                    paddingLeft: "10px",
                    fontSize: "13px",
                    fontFamily: "Inter",
                    height: "40px",
                    marginTop: "10px",
                  }}
                >
                  {this.state.elementArray.length !== 0
                    ? this.state.elementArray.map((_data: any, idx: any) => {
                      if (_data.label !== "Do you have any other instructions") {
                        return (

                          <MenuItem
                            key={idx}
                            style={{
                              fontSize: "13px",
                              fontFamily: "Inter",
                            }}
                            value={_data.label}
                            data-id={idx}
                            data-label={_data.label}
                            data-type={_data.type}
                          >
                            {_data.label}
                          </MenuItem>);
                      }
                    })
                    : ""}
                </Select>
              </Grid>

              {this.state.showSecond ? (
                <Grid item xs={12}>
                  <Grid container direction="row">
                    {/* hide the select box conditionally if the selected field is of text type */}
                    {
                      !(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.selectedConditionFirstType, el))) ?
                        <>
                          <Grid item xs={3}>
                            {
                              this.state.selectedConditionFirstType ===
                                "drop_down_select" ||
                                this.state.selectedConditionFirstType ===
                                "multiple_selection" ||
                                this.state.selectedConditionFirstType ===
                                "single_selection" ? (
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  disableUnderline
                                  value={this.state.selectedConditionValue}
                                  style={{
                                    width: "100%",
                                    border: "1px solid #9EABB5",
                                    paddingLeft: "10px",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    height: "40px",
                                    marginTop: "10px",
                                  }}
                                  onChange={this.handleConditionValueChange}
                                >
                                  {this.state.elementArray[
                                    this.state.selectedConditionFirstIndex
                                  ].tempValue.map((_data: any, idx: any) => (
                                    <MenuItem
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: "Inter",
                                      }}
                                      value={_data.label}
                                      data-id={idx}
                                    >
                                      {_data.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) :
                                (
                                  this.state.elementArray !== 0 ?
                                    this.state.elementArray?.map((item: any, index: any) => (
                                      item.type == "drop_down_select" ?
                                        (
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disableUnderline
                                            value={this.state.selectedConditionValue}
                                            style={{
                                              width: "100%",
                                              border: "1px solid #9EABB5",
                                              paddingLeft: "10px",
                                              fontSize: "13px",
                                              fontFamily: "Inter",
                                              height: "40px",
                                              marginTop: "10px",
                                            }}
                                            onChange={this.handleConditionValueChange}
                                          >
                                            {item.detailedValues.options.map((_data: any, idx: any) => (
                                              <MenuItem
                                                style={{
                                                  fontSize: "13px",
                                                  fontFamily: "Inter",
                                                }}
                                                value={_data.label}
                                                data-id={idx}
                                              >
                                                {_data.label}
                                              </MenuItem>
                                            ))}
                                          </Select>

                                        )
                                        : ""
                                    ))
                                    : ""
                                )
                            }
                          </Grid>
                          <Grid item xs={1} />
                        </>
                        : <></>
                    }
                    <Grid item xs={!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.selectedConditionFirstType, el))) ? 8 : 12}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disableUnderline
                        value={this.state.isConditionSelected}
                        style={{
                          width: "100%",
                          border: "1px solid #9EABB5",
                          paddingLeft: "10px",
                          fontSize: "13px",
                          fontFamily: "Inter",
                          height: "40px",
                          marginTop: "10px",
                        }}
                        onChange={this.handleConditionSelectionChange}
                      >
                        <MenuItem
                          style={{ fontSize: "13px", fontFamily: "Inter" }}
                          value="select"
                        >
                          Please Select
                        </MenuItem>
                        <MenuItem
                          style={{ fontSize: "13px", fontFamily: "Inter" }}
                          value="true"
                        >
                          {_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.selectedConditionFirstType, el)) ? 'Not empty' : 'Selected'}
                        </MenuItem>
                        <MenuItem
                          style={{ fontSize: "13px", fontFamily: "Inter" }}
                          value="false"
                        >
                          {!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.selectedConditionFirstType, el))) ? 'Not selected' : 'Empty'}
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} style={{ height: "100px" }} />
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: "#0E0E0E",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Then
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={3}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disableUnderline
                      value={this.state.selectedThenCondition}
                      style={{
                        width: "100%",
                        border: "1px solid #9EABB5",
                        paddingLeft: "10px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        height: "40px",
                        marginTop: "10px",
                      }}
                      onChange={this.handleThenConditionChange}
                    >
                      <MenuItem
                        style={{ fontSize: "13px", fontFamily: "Inter" }}
                        value="true"
                      >
                        Show
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "13px", fontFamily: "Inter" }}
                        value="false"
                      >
                        Hide
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={8}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disableUnderline
                      value={this.state.selectedConditionSecondLabel}
                      style={{
                        width: "100%",
                        border: "1px solid #9EABB5",
                        paddingLeft: "10px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        height: "40px",
                        marginTop: "10px",
                      }}
                      onChange={this.handleSecondSelect}
                    >
                      {this.state.elementArray.map((_data: any, idx: any) => {
                        if (_data.label !== "Do you have any other instructions") {
                          return (

                            <MenuItem
                              style={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                              }}
                              value={_data.label}
                              data-id={idx}
                            >
                              {_data.label}
                            </MenuItem>

                          );
                        }
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "40px",
                  marginBottom: "15px",
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{
                        width: "184px",
                        height: "40px",
                        background: "#1a76b0",
                      }}
                      onClick={this.handleAddCondition}
                    >
                      {!this.state.updateButton ?
                        <Typography
                          variant="body2"
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            color: "#FFFFFF",
                            fontFamily: "Inter",
                            fontWeight: "bold",
                          }}
                        >
                          Add This Condition
                        </Typography> :
                        <Typography
                          variant="body2"
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            color: "#FFFFFF",
                            fontFamily: "Inter",
                            fontWeight: "bold",
                          }}
                        >
                          Update This Condition
                        </Typography>}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* {this.state.selectedBriefingId ? <Grid
                item
                xs={12}
                style={{
                  marginTop: "20px",
                  marginBottom: "15px",

                }}>
                <Grid container
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "18px",
                        color: "#1a76b0",
                        letterSpacing: "0px",
                        opacity: 1,
                        textAlign: "left",
                        marginBottom: "10px",
                        marginLeft: '60px'
                      }}
                    >
                      Conditions
                    </Typography>
                    <List style={{ maxHeight: '150px', width: '80%', overflow: 'auto', margin: 'auto' }}>
                      {this.state.formCondition.map((data: any, index: any) => {
                        return (
                          <ListItem button style={index % 2 ? { marginBottom: '0', padding: '4px 16px', backgroundColor: 'white' } : { marginBottom: '0', padding: '4px 16px', backgroundColor: '#f3f2f2' }}
                            onClick={() => { this.handleFormConditionClick(data) }}>
                            <ListItemText  >
                              {data.attributes.condition_label_value}
                              <span style={{ fontSize: '16px' }}> {"  >  "} </span>
                              {data.attributes.response_label_value}
                            </ListItemText>

                            
                            <ListItemSecondaryAction onClick={() => { this.handleDeleteDialogOpen(data.id) }}>
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}

                    </List>
                  </Grid>
                </Grid>
              </Grid>
                : ''} */}
            </Grid> : ''}
          </DialogContent>
        </Dialog>

        {/* edit condition dialog */}
        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.openDialog}
          fullWidth={false}
          style={{ width: '595px', alignItems: 'center', justifyContent: "center", margin: "auto" }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ minWidth: "528px", borderBottom: "1px solid #E7E7E7" }}
          >
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#1a76b0",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Add Condition
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={this.handleDialogClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>



          {this.state.selectedBriefingId ? <DialogContent style={{ padding: "25px" }} id="scrollableGrid">
            
            <InfiniteScroll
              dataLength={this.state.formCondition.length}
              next={this.fetchMoreData}
              hasMore={this.state.nextPage}
              loader={this.state.totalPage ? "" : <h4>Loading...</h4>}
              scrollableTarget="scrollableGrid"
            >

              <Grid container>
                  
                <Grid item xs={12}>
                  <Grid container direction="row">

                    {this.state.formCondition.map((data: any, index: any) => {
                      return (
                        <Grid item xs={12} >
                          <Grid container direction="row" style={{ margin: '7px 0px' }}>
                            <Grid item xs={9} spacing={2} style={{ border: '1px solid rgb(156 155 155 / 87%)', borderRadius: '4px', padding: '15px' }}>
                              <Grid>
                                <div style={{ textTransform: 'capitalize', }}>{data.attributes?.condition_label_value} </div>
                              </Grid>
                              <Grid>
                                {/* <span style={{ color: 'rgb(156 155 155 / 87%)' }}>{data.condition_value !== '' ? data.condition_value : 'No'}{" - "}
                                          {data.attributes.is_condition_selected ? ((_.some(['text', 'image', 'file', 'email', 'date', 'time'], (el) => _.includes(this.state.elementArray.filter((field:any) => field.unId ==  data.attributes.condition_label_id).type, el))) ?  "Not Empty" : "Selected") : (!(_.some(['text', 'image', 'file', 'email', 'date', 'time'], (el) => _.includes(this.state.elementArray.filter((field:any) => field.unId ==  data.attributes.condition_label_id).type, el)))) ? "Not Selected" : "Empty"}</span> */}
                                <span style={{ color: 'rgb(156 155 155 / 87%)' }}>
                                  {data.attributes?.condition_value !== 'no' ? (!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.elementArray.filter((field: any) => field.unId == data.attributes?.condition_label_id)[0]?.type, el)))) ? data.attributes?.condition_value + ' - ' : "" : ''}
                                  {/* {data.attributes.is_condition_selected ? "Selected" : 'Not Selected'} */}
                                  {
                                    data.attributes?.is_condition_selected ? ((_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.elementArray.filter((field: any) => field.unId == data.attributes?.condition_label_id)[0]?.type, el))) ? "Not Empty" : "Selected") : (!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider', 'paragraph'], (el) => _.includes(this.state.elementArray.filter((field: any) => field.unId == data.attributes?.condition_label_id)[0]?.type, el)))) ? "Not Selected" : "Empty"
                                  }
                                </span>
                              </Grid>
                              <Grid>
                                <span style={{ color: 'rgb(156 155 155 / 87%)' }}>
                                  {data.attributes?.is_response_to_show ? "Show" : "Hide"}</span>{" - "} <span style={{ textTransform: 'capitalize', }}>{data.attributes?.response_label_value} </span>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs ={1} /> */}
                            <Grid item xs={3}>
                              <div style={{ float: 'right' }}>
                                <IconButton style={{ padding: '15px 6px', color: 'black' }} onClick={() => { this.handleFormConditionClick(data) }} >
                                  <EditOutlinedIcon style={{ fontSize: '2rem' }} />
                                </IconButton>
                                <IconButton style={{ padding: '15px 6px', color: 'black' }} onClick={() => { this.handleDeleteDialogOpen(data.id) }}>
                                  <DeleteForeverIcon
                                    style={{ fontSize: '2rem' }} />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}


                  </Grid>

                </Grid>


                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "40px",
                    marginBottom: "15px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          width: "184px",
                          height: "40px",
                          background: "#1a76b0",
                        }}
                        onClick={() => this.handleAddACondition()}
                      >

                        <Typography
                          variant="body2"
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            color: "#FFFFFF",
                            fontFamily: "Inter",
                            fontWeight: "bold",
                          }}
                        >
                          Add Condition
                        </Typography>

                      </Button>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </InfiniteScroll>
          </DialogContent> : <>
            {this.state.selectedConditionId !== null ?
              <DialogContent style={{ padding: "25px" }} >


                <Grid container>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      {this.state.selectedConditionId !== null && this.state.openDialog ? this.state.elementArray[this.state.selectedConditionId].condition.map((data: any, index: any) => {
                        return (
                          <>
                            {data.isConditionAdded ?
                              <Grid item xs={12} >
                                <Grid container direction="row" style={{ margin: '7px 0px' }}>
                                  <Grid item xs={9} spacing={2} style={{ border: '1px solid rgb(156 155 155 / 87%)', borderRadius: '4px', padding: '15px' }}>
                                    <Grid>
                                      <div style={{ textTransform: 'capitalize', }}>{data?.conditionLabel}</div>
                                    </Grid>
                                    <Grid>
                                      <span style={{ color: 'rgb(156 155 155 / 87%)' }}>{data?.condition_value !== '' && data?.condition_value !== 'no' ? (!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider'], (el) => _.includes(this.state.elementArray[data?.condition_label_id]?.type, el)))) ? data?.condition_value + ' - ' : '' : ''}

                                        {data.is_condition_selected == "true" ? ((_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider'], (el) => _.includes(this.state.elementArray[data?.condition_label_id]?.type, el))) ? "Not Empty" : "Selected") : (!(_.some(['text', 'image', 'file', 'email', 'date', 'time', 'slider'], (el) => _.includes(this.state.elementArray[data?.condition_label_id]?.type, el)))) ? "Not Selected" : "Empty"}</span>
                                    </Grid>
                                    <Grid>
                                      <span style={{ color: 'rgb(156 155 155 / 87%)' }}>
                                        {data?.is_response_to_show == "true" ? "Show" : "Hide"}</span>{" - "} <span style={{ textTransform: 'capitalize', }}> {data?.responseLabel} </span>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1} />
                                  <Grid item xs={2}>

                                    <IconButton style={{ padding: '15px 6px', color: 'black' }} onClick={() => { this.handleUpdateCondition(data, index) }}  >
                                      <EditOutlinedIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                    <IconButton style={{ padding: '15px 6px', color: 'black' }} onClick={() => this.removeCondition(index)}>
                                      <DeleteForeverIcon
                                        style={{ fontSize: '2rem' }} />
                                    </IconButton>

                                  </Grid>
                                </Grid>
                              </Grid>
                              : ""} </>)
                      }) : ''
                      }

                    </Grid>

                  </Grid>


                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "40px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            width: "184px",
                            height: "40px",
                            background: "#1a76b0",
                          }}
                          onClick={() => this.handleAddACondition()}
                        >

                          <Typography
                            variant="body2"
                            style={{
                              textTransform: "uppercase",
                              fontSize: "13px",
                              color: "#FFFFFF",
                              fontFamily: "Inter",
                              fontWeight: "bold",
                            }}
                          >
                            Add Condition
                          </Typography>

                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>

              </DialogContent> : ''
            }</>
          }
        </Dialog>


        {/* Preview Dialog Starts */}
        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.previewDialogOpen}
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <div
          // style={{ width: 800 }}
          >
            <DialogTitle
              style={{
                right: 10,
                borderBottom: "1px solid grey",
                marginTop: "15px",
              }}
              id="form-dialog-title"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }} />
                <IconButton
                  style={{
                    position: "absolute",
                    right: 10,
                  }}
                  onClick={this.handlePreviewDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent >

              <Grid container spacing={2} style={{ overflowX: "hidden" }}>
                {this.state.elementArray.length !== 0 ? (
                  this.state.elementArray.slice(this.state.fromStart, this.state.fromEnd).map((item: any, index: any) => {
                    switch (item.type) {
                      case "short_text":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid item xs={12} style={{
                                fontWeight: "bold",
                              }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  value={item.defaultValue}
                                  onChange={event => this.handlePreviewInputChange(index, event)}
                                  style={{
                                    marginBottom: 10,
                                    color: "primary",
                                  }}
                                />
                              </Grid> : ''}
                          </>
                        );
                      case "email":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid item xs={12} style={{
                                fontWeight: "bold",
                              }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  value={item.defaultValue}
                                  onChange={event => this.handlePreviewInputChange(index, event)}
                                  style={{
                                    marginBottom: 10,
                                    color: "primary",
                                  }}
                                />
                              </Grid> : ""}</>
                        );
                      case "long_text":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid item xs={12} style={{
                                fontWeight: "bold",
                              }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <Editor
                                  editorState={this.state.editorState[index]}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  placeholder="Add Comment"
                                  onEditorStateChange={(editorState) => this.onEditorStateChange(index, editorState)}

                                  wrapperStyle={{
                                    height: 200,
                                    border: "1px solid black",

                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  }}
                                  editorStyle={{
                                    padding: 10,
                                    minHeight: 100,
                                  }}
                                  toolbarStyle={{
                                    height: 50,
                                    fontSize: 18,
                                  }}
                                  //onEditorStateChange={this.onEditorStateChange}
                                  toolbar={{
                                    options: [
                                      "inline",
                                      "list",
                                      "link",
                                      "emoji",
                                      "image",
                                    ],
                                    inline: {
                                      inDropdown: false,
                                      className: "test",
                                      component: undefined,
                                      dropdownClassName: undefined,
                                      options: ["bold", "italic"],
                                      // bold: { className: "test", style: { height:80 } },
                                      // italic: { className: undefined },
                                    },
                                    list: {
                                      inDropdown: false,
                                      className: "test",
                                      component: undefined,
                                      dropdownClassName: undefined,
                                      options: ["unordered", "ordered"],
                                      unordered: {
                                        className: undefined,
                                      },
                                      ordered: {
                                        className: undefined,
                                      },
                                      // indent: { icon: indent, className: undefined },
                                      // outdent: { icon: outdent, className: undefined },
                                    },
                                    link: {
                                      inDropdown: false,
                                      className: undefined,
                                      component: undefined,
                                      popupClassName: undefined,
                                      dropdownClassName: undefined,
                                      showOpenOptionOnHover: true,
                                      defaultTargetOption: "_self",
                                      options: ["link"],
                                      // link: { icon: link, className: undefined },
                                      // unlink: { icon: unlink, className: undefined },
                                      linkCallback: undefined,
                                    },
                                    emoji: {
                                      // icon: emoji,
                                      className: undefined,
                                      component: undefined,
                                      popupClassName: undefined,
                                      emojis: [
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "珞",
                                        "樂",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "邏",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "⛷",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "⛹",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "落",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "✊",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "⏰",
                                        "",
                                        "",
                                        "",
                                        "⭐",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "⛄",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "✅",
                                        "❎",
                                        "",
                                      ],
                                    },
                                    image: {
                                      //icon:,
                                      className: undefined,
                                      component: undefined,
                                      popupClassName: undefined,
                                      urlEnabled: true,
                                      uploadEnabled: true,
                                      alignmentEnabled: true,
                                      uploadCallback: undefined,
                                      previewImage: false,
                                      inputAccept:
                                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                      alt: {
                                        present: false,
                                        mandatory: false,
                                      },
                                      defaultSize: {
                                        height: "auto",
                                        width: "auto",
                                      },
                                    },
                                  }}
                                />
                              </Grid> : ""}</>
                        );
                      case "file":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <FormControl component="fieldset">
                                  {/* <FormLabel component="legend" style={{ fontSize: 12, margin: 10 }}> can we work from an existing layout which you need us to produce other formats from?
                    </FormLabel> */}
                                  <div>
                                    <Button
                                      variant="contained"
                                      style={{
                                        alignContent: "center",
                                        backgroundColor: "white",
                                        color: "#419bf9",
                                        width: 260,
                                        padding: 10,
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        margin: 10,
                                        borderColor: "#419bf9",
                                        borderRadius: 10,
                                        borderStyle: "dashed",
                                        fontVariant: "initial",
                                        textTransform: "none",
                                        marginBottom: "50px",
                                      }}
                                      color="primary"
                                      onChange={event => this.handlePreviewInputChange(index, event)}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </FormControl>
                              </Grid> : ''}
                          </>
                        );
                      case "image":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <FormControl component="fieldset">
                                  {/* <FormLabel component="legend" style={{ fontSize: 12, margin: 10 }}> can we work from an existing layout which you need us to produce other formats from?
                    </FormLabel> */}
                                  <div>
                                    <Button
                                      variant="contained"
                                      style={{
                                        alignContent: "center",
                                        backgroundColor: "white",
                                        color: "#419bf9",
                                        width: 260,
                                        padding: 10,
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        margin: 10,
                                        borderColor: "#419bf9",
                                        borderRadius: 10,
                                        borderStyle: "dashed",
                                        fontVariant: "initial",
                                        textTransform: "none",
                                        marginBottom: "50px",
                                      }}
                                      color="primary"
                                      onChange={event => this.handlePreviewInputChange(index, event)}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </FormControl>
                              </Grid> : ""}</>
                        );
                      case "drop_down_select":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  style={{
                                    minWidth: "100%",
                                  }}
                                >
                                  <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    style={{
                                      marginLeft: 10,
                                      fontFamily: "Roboto, Helvetica, sans-serif",
                                      fontWeight: "inherit",
                                    }}
                                  >
                                    {" "}
                                    {item.label}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={item.default_value}
                                    onChange={event => this.handlePreviewInputChange(index, event)}
                                  >
                                    {/* {item.tempValue.map((value: any) => {
                                  return (
                                    <MenuItem
                                      value={value.value}
                                      style={{
                                        color: "black",
                                        fontSize: 18,
                                      }}
                                    >
                                      {value.value}
                                    </MenuItem>
                                  );
                                })} */}

                                    {
                                      item.tempValue.length !== 0
                                        ? item.detailedValues.options.map((_data: any) => (
                                          <MenuItem value={_data.value}>
                                            {_data.label}
                                          </MenuItem>
                                        ))
                                        :
                                        ""
                                    }
                                  </Select>
                                </FormControl>
                              </Grid> : ""}</>
                        );
                      case "multiple_selection":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <div>
                                  <FormControl component="fieldset">
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      style={{
                                        marginLeft: 10,
                                        fontFamily: "Roboto, Helvetica, sans-serif",
                                        fontWeight: "inherit",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                    <FormGroup
                                      onChange={event => this.handlePreviewInputChange(index, event)}>
                                      {item.tempValue.map(
                                        (item: any, index: any) => (
                                          <Grid
                                            item
                                            xs={12}
                                            style={{
                                              paddingLeft: "15px",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <FormControlLabel
                                              style={{
                                                border: "1px solid #F0F0F0",
                                                width: "750px",
                                                marginLeft: "4px",
                                              }}
                                              control={
                                                <Checkbox
                                                  // checked={false}
                                                  name={item.label}
                                                  value={item.value}
                                                />
                                              }
                                              label={item.label}
                                            />
                                          </Grid>
                                        )
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </div>
                              </Grid> : ""}</>
                        );
                      case "single_selection":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <FormControl component="fieldset">
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    style={{
                                      marginLeft: 10,
                                      fontFamily: "Roboto, Helvetica, sans-serif",
                                      fontWeight: "inherit",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                  <RadioGroup aria-label={item.label}
                                    value={item.defaultValue}
                                    onChange={event => this.handlePreviewInputChange(index, event)}>
                                    {item.tempValue.map((item: any, index: any) => (
                                      <FormControlLabel
                                        key={index}
                                        value={item.value}
                                        control={
                                          <Radio
                                            disableRipple
                                            classes={{
                                              root: this.props.classes.radio,
                                              checked: this.props.classes.checked,
                                            }}
                                          />
                                        }
                                        label={item.value}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid> : ""}</>
                        );
                      case "slider":
                        return (
                          <>
                            {item.showField
                              && item.preview_visibility
                              ?
                              <Grid item container>
                                <Grid item xs={12} style={{
                                  fontWeight: "bold",
                                }}>
                                  {/* <Input
                                type="text"
                                name="sliderDescription"
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "18px",
                                  marginTop: "15px",
                                  width: "100%",
                                  fontWeight:"bold"
                                }}
                                placeholder="Slider Description"
                                disableUnderline
                                value={item.description}
                               
                                onChange={event=> this.handlePreviewInputChange(index,event)}
                              // onChange={(e) =>
                              //   this.handleDescriptionChange(e, i)
                              // }
                              /> */}
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    style={{
                                      marginLeft: 10,
                                      fontFamily: "Roboto, Helvetica, sans-serif",
                                      fontWeight: "inherit",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                  <Input
                                    type="text"
                                    name="min"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Minimun value"
                                    disableUnderline
                                    value={item?.detailedValues?.min}
                                    onChange={event => this.handlePreviewInputChange(index, event)}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <Input
                                    type="text"
                                    name="max"
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "18px",
                                      marginTop: "15px",
                                      width: "100%",
                                    }}
                                    placeholder="Maximun value"
                                    disableUnderline
                                    value={item?.detailedValues?.max}
                                    onChange={event => this.handlePreviewInputChange(index, event)}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    marginRight: "30px",
                                  }}
                                >
                                  <CustomSlider
                                    style={{
                                      width: "100%",
                                      marginLeft: "18px",
                                      marginTop: "18px",
                                    }}
                                    defaultValue={0}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider-always"
                                    //step={10}
                                    marks={[
                                      {
                                        value: item?.detailedValues?.min ? parseInt(item?.detailedValues?.min) : 0,
                                        label: item?.detailedValues?.min ? item?.detailedValues?.min : '0',
                                      },
                                      {
                                        value: item?.detailedValues?.max ? parseInt(item?.detailedValues?.max) : 100,
                                        label: item?.detailedValues?.max ? item?.detailedValues?.max : '100',
                                      },

                                    ]}
                                    min={item?.detailedValues?.min ? parseInt(item?.detailedValues?.min) : 0}
                                    max={item?.detailedValues?.max ? parseInt(item?.detailedValues?.max) : 100}
                                    valueLabelDisplay="on"
                                    onChangeCommitted={(e: any) => this.setSliderDefaultValue(e, item, index)}
                                  //ValueLabelComponent={StyledValueLabel}
                                  />
                                </Grid>
                              </Grid> : ''}</>
                        );
                      case "date_time":
                        return (
                          <>
                            {item.showField && item.preview_visibility ?
                              <Grid item xs={12} style={{
                                fontWeight: "bold",
                              }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    marginLeft: 10,
                                    fontFamily: "Roboto, Helvetica, sans-serif",
                                    fontWeight: "inherit",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="date"
                                      type="date"
                                      defaultValue={item.defaultValue}
                                      onChange={event => this.handlePreviewInputChange(index, event)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      style={{
                                        width: "232px",
                                        border: "1px solid #F0F0F0",
                                        padding: "10px",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="time"
                                      type="time"
                                      defaultValue="07:30"
                                      style={{
                                        border: "1px solid #F0F0F0",
                                        width: "232px",
                                        padding: "10px",
                                      }}
                                      onChange={event => this.handlePreviewInputChange(index, event)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid> : ''}</>
                        );
                      case "page_separator":
                        return (
                          <>
                            {item.showField ?
                              <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                              >
                                <Grid item xs={8}>
                                  <Pagination count={this.state.paginationCount} style={{ marginTop: "20px", marginBottom: "20px" }} defaultPage={1} page={this.state.page} onChange={this.handleChangePage} />
                                </Grid>
                              </Grid> : ""}</>
                        )
                      case "image_upload":
                        if (item.preview_visibility) {
                          return (

                            <Grid item xs={12} >
                              {item?.file_blob_signed_id_url?.length > 0 ?
                                <div>
                                  <img src={item?.file_blob_signed_id_url}
                                    alt="image"
                                    style={{ maxWidth: "100%", maxHeight: "240px" }} />

                                </div>
                                :
                                null}
                            </Grid>

                          )
                        }
                        else {
                          return null;
                        }

                      case "paragraph":
                        if (item.preview_visibility) {
                          return (
                            <Grid item xs={12}>
                              <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                            </Grid>
                          )
                        }
                        else {
                          return null
                        }

                      default:
                        return "";
                    }
                  })
                ) : (
                  <p>Add a field</p>
                )}
              </Grid>
            </DialogContent>
          </div>
        </Dialog>
        {/* Preview Dialog Ends */}
      </div >
    );
  }
}

export default withStyles(styles)(withTranslation()(NewTemplates));
