export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signUpBackgroundImage = require("../assets/signin_signup_bg.png")
export const harvestLogoImage = require('../assets/image-logo.png')
export const harvestLogo2xImage = require('../assets/image-logo@2x.png')
export const harvestLogo3xImage = require('../assets/image-logo@3x.png')
export const forgetBackgroundImage = require("../assets/view-image-left.png") 
export const rightHarvestLogoImage = require('../assets/view-right-image.png')
export const harvestBlueLogoImage = require('../assets/image-logo-blue.png');
export const ukLogo = require("../assets/UK.png");
export const germanLogo = require("../assets/germany.png");
