import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, Redirect } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withTranslation, Trans } from "react-i18next";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  brandNameData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
    marginLeft: "20px",
  },
  tableDataLogo: {
    border: "1px solid white",
    paddingLeft: "44px",
    paddingTop: "18px",
    paddingBottom: "8px",
    backgroundColor: "#1E3E59",
    borderRadius: "26px",
  },
  dialogPaper: {
    width: "528px",
    height: "440px",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  inputRoot: {
    width: "449px",
    height: "40px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
  inputError: {
    width: "449px",
    height: "40px",
    border: "1px solid red",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  dialogOpen: any;
  newBrandRedirect: any;
  messageError: string;
  brandsList: any;
  brandName: any;
  brandEmailDomain: any;
  brandDescription: any;
  isBrandNameValid: any;
  isBrandEmailDomainValid: any;
  isBrandDescriptionValid: any;
  nextPage: boolean;
  totalCount: number;
  totalPages: number;
  setPage: number;
  isSearch: boolean;
  searchValue: any;
  searchData: any;
  path: string;
  selectedBrand: any;
  isBrandDisabled: boolean;
}

interface SS {
  id: any;
}

class Brands extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: any;
  createBrandApiCallId: any;
  getBrandsByFilterApiCallId: any;
  deleteBrandApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: "",
      dialogOpen: false,
      newBrandRedirect: false,
      messageError: "",
      brandsList: [],
      brandName: "",
      brandEmailDomain: "",
      brandDescription: "",
      isBrandNameValid: true,
      isBrandEmailDomainValid: true,
      isBrandDescriptionValid: true,
      nextPage: false,
      totalCount: 0,
      totalPages: 0,
      setPage: 1,
      isSearch: false,
      searchValue: "",
      searchData: [],
      path: "",
      selectedBrand: null,
      isBrandDisabled: false
    };
  }

  async componentDidMount() {
    await this.getBrandsData();
  }

  getBrandsData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands?is_admin_brand_listing=true&page=${
        this.state.setPage
      }&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteBrand = (id: any) => {
    if (id !== "") {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteBrandApiCallId = requestMessage.messageId;
      const header = { token: localStorage.getItem("token") };
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories_sub_categories/brands/${id}`);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBrandsApiCallId) {
        console.clear();
        console.log(responseJson);
        if (responseJson.brands) {
          if (responseJson.brands.data) {
            if (responseJson.brands.data !== null) {
              let tempArr = [] as any;
              responseJson.brands.data.map((_data: any) => {
                tempArr.push({
                  id: _data.id,
                  brandName: _data.attributes.name,
                  users: _data.attributes.total_users,
                  phone:
                    _data.attributes.phone_number !== null
                      ? _data.attributes.phone_number
                      : "NA",
                  email:
                    _data.attributes.billing_email !== null
                      ? _data.attributes.billing_email
                      : "NA",
                  address:
                    _data.attributes.address !== null ||
                    _data.attributes.address !== ""
                      ? _data.attributes.address
                      : "NA",
                });
              });
              let totalBrandPages = this.state.totalPages;
              this.setState({
                brandsList: tempArr,
                nextPage: responseJson.next_page,
                totalCount: responseJson.total_count,
                totalPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalBrandPages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.createBrandApiCallId) {
        if (responseJson) {
          console.log(responseJson);
          if (responseJson.errors) {
            if (responseJson.errors.length !== 0) {
              if (responseJson.errors[0].name) {
                toast.error(`Name ${responseJson.errors[0].name}`);
              }
            }
          } else {
            toast.success("Brand created successfully.");
            this.setState({
              dialogOpen: false,
              brandName: "",
              brandDescription: "",
              brandEmailDomain: "",
            });
            this.getBrandsData();
          }
        }
      } else if (apiRequestCallId === this.getBrandsByFilterApiCallId) {
        console.log(responseJson);
        if (responseJson.brands) {
          this.setState({
            searchData: [...this.state.searchData, ...responseJson.brands.data],
          });
          this.setState({
            nextPage: responseJson.next_page,
            totalPages: responseJson.total_pages,
          });
        }
      } else if (apiRequestCallId === this.deleteBrandApiCallId) {
     
        if (responseJson.message) {
          if (responseJson?.message?.toUpperCase() === "Record successfully deleted".toUpperCase()) {
            toast.success("Record successfully deleted");
            this.setState({
              anchorEl: "",
              selectedBrand: null
            });
            this.getBrandsData();
          } else {
            toast.error("Deletion failed");
          }
        } else {
          toast.error("Deletion failed");
        }
      }
    }
  }

  handleIconClick = (event: any, selectedBrand: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedBrand: selectedBrand
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
      selectedBrand: null
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      brandName: "",
      brandDescription: "",
      brandEmailDomain: "",
    });
  };
  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };
  handleBrandNameChange = (event: any) => {
    this.setState({
      isBrandNameValid: true,
      brandName: event.target.value,
    });
  };
  handleBrandDomainChange = (event: any) => {
    this.setState({
      isBrandEmailDomainValid: true,
      brandEmailDomain: event.target.value,
    });
  };
  handleBrandDescriptionChange = (event: any) => {
    this.setState({
      isBrandDescriptionValid: true,
      brandDescription: event.target.value,
    });
  };

  createNewBrand = () => {
    let networkError = false;
    if (this.state.brandName === "") {
      this.setState({
        isBrandNameValid: false,
      });
      networkError = true;
    }
    if (this.state.brandDescription === "") {
      this.setState({
        isBrandDescriptionValid: false,
      });
      networkError = true;
    }
    if (this.state.brandEmailDomain === "") {
      this.setState({
        isBrandEmailDomainValid: false,
      });
      networkError = true;
    }
    if (
      !networkError &&
      this.state.isBrandNameValid &&
      this.state.isBrandDescriptionValid &&
      this.state.isBrandEmailDomainValid
    ) {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };

      let body = {} as any;
      body.name = this.state.brandName;
      body.email_domain = this.state.brandEmailDomain;
      body.website_url = this.state.brandEmailDomain;
      console.log(body);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createBrandApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  exportList = () => {
    var url = `${
      currentUrl
    }/bx_block_data_import_export_csv/brand_exports?token=${localStorage.getItem(
      "token"
    )}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    this.setState({ setPage: value }, () => {
      this.getBrandsData();
    });
  };

  fetchMoreData = () => {
    console.clear();
    console.log("Page count", this.state.setPage);
    this.setState({ setPage: this.state.setPage + 1 }, () => {
      this.getBrandsByFilter(this.state.path, this.state.setPage);
    });
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  handleSearchClick = () => {
    this.setState({ searchData: [], setPage: 1 });
    this.setState({ isSearch: true });
    let path = `brand_name=${this.state.searchValue}`;
    this.setState({ path: path });
    this.getBrandsByFilter(path, this.state.setPage);
    this.setState({ searchValue: "" });
  };

  getBrandsByFilter = (path: any, page: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsByFilterApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands?is_admin_brand_listing=true&${path}&&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Grid container style={{ paddingLeft: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/AdminConsole"
                style={{
                  color: "#1a76b0",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Link>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.brands")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2}>
                {" "}
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.brands")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("AdminConsole.export")}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder={t("AdminConsole.searchForBrands")}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "152px",
                        height: "32px",
                      }}
                      onClick={() => {
                        this.handleDialogOpen();
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        {t("AdminConsole.createNewBrand")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!this.state.isSearch ? (
            <>
              <Grid item xs={12}>
                <TableContainer style={{ background: "#F5F6FA" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "25%",
                            paddingLeft: "50px",
                          }}
                        >
                          {t("AdminConsole.brandName")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "15%",
                          }}
                        >
                          {t("AdminConsole.users")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "18%",
                          }}
                        >
                           {t("AdminConsole.phone")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "18%",
                          }}
                        >
                          {t("AdminConsole.email")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "15%",
                          }}
                        >
                          {t('AdminConsole.address')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.brandsList.map((_data: any) => (
                      <>
                        <TableBody
                          style={{ background: "white" }}
                          key={_data.id}
                          // onClick={(e) =>
                          //   (window.location.href = `/AdminConsole/brand/${
                          //     _data.id
                          //   }`)
                          // }
                        >
                          <TableCell
                            style={{
                              padding: "35px",
                              border: "0px",
                              borderLeft: "20px solid #1E3E59",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container direction="row">
                                  <Grid
                                    item
                                    xs={2}
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <span
                                      className={
                                        this.props.classes.tableDataLogo
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="body2"
                                      className={
                                        this.props.classes.brandNameData
                                      }
                                    >
                                      {_data.brandName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data.users}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data.phone}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data.email}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data.address}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleIconClick(e, _data);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleIconClose}
                              className={this.props.classes.menu}
                            >
                              <MenuItem
                                style={{
                                  fontFamily: "Work Sans",
                                  fontSize: "12px",
                                  color: "#0E0E0E",
                                  textTransform: "capitalize",
                                  letterSpacing: 0,
                                }}
                                onClick={(e) =>
                                  (window.location.href = `/AdminConsole/brand/${
                                    this.state.selectedBrand?.id
                                  }`)
                                }
                              >
                                {t("AdminConsole.edit")}
                              </MenuItem>
                              <MenuItem
                                style={{
                                  fontFamily: "Work Sans",
                                  fontSize: "12px",
                                  color: "#0E0E0E",
                                  textTransform: "capitalize",
                                  letterSpacing: 0,
                                }}
                                onClick={(e) => this.deleteBrand(this.state.selectedBrand?.id)}
                              >
                                {t("AdminConsole.delete")}
                              </MenuItem>
                              {/* <MenuItem
                                style={{
                                  fontFamily: "Work Sans",
                                  fontSize: "12px",
                                  color: "#0E0E0E",
                                  textTransform: "capitalize",
                                  letterSpacing: 0,
                                }}
                              >
                                {this.state.isBrandDisabled === false ? 'Disable' : 'Enable'}
                              </MenuItem> */}
                            </Menu>
                          </TableCell>
                        </TableBody>
                        <Grid container>
                          <Grid item xs={12} style={{ height: "25px" }} />
                        </Grid>
                      </>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  {this.state.totalPages > 1 ? 
                  <>
                  <Grid item xs={3}>
                    <Pagination
                      count={this.state.totalPages}
                      variant="outlined"
                      color="primary"
                      page={this.state.setPage}
                      onChange={this.handlePaginationChange}
                    />
                  </Grid>
                  </> :"" }
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      style={{ left: "60px" }}
                      onClick={() =>
                        this.setState({ isSearch: false, searchValue: "" })
                      }
                    >
                      Back to Previous Screen
                    </Button>
                  </Grid>
                </Grid>
                <InfiniteScroll
                  dataLength={this.state.searchData.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.nextPage}
                  loader={this.state.totalPages ? "" : <h4>Loading...</h4>}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            {t("AdminConsole.brandName")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.users")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                             {t("AdminConsole.phone")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.email")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t('AdminConsole.address')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.searchData.map((_data: any) => {
                        return (
                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}
                              onClick={(e) =>
                                (window.location.href = `/AdminConsole/brand/${
                                  _data.id
                                }`)
                              }
                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={this.props.classes.tableData}
                                    >
                                      {_data.attributes.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.total_users}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.phone_number !== null
                                    ? _data.attributes.phone_number
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.email !== null
                                    ? _data.attributes.email
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.address !== null
                                    ? _data.attributes.address
                                    : "NA"}
                                </Typography>
                              </TableCell>
                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </InfiniteScroll>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogOpen}
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={this.props.classes.dialogTitleRoot}
          >
            <span className={this.props.classes.dialogTitle}>
              {t("AdminConsole.createNewBrand")}
            </span>
            <IconButton>
              <CloseIcon onClick={this.handleDialogClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  classes={
                    !this.state.isBrandNameValid
                      ? { root: this.props.classes.inputError }
                      : { root: this.props.classes.inputRoot }
                  }
                  disableUnderline={true}
                  placeholder=""
                  value={this.state.brandName}
                  onChange={this.handleBrandNameChange}
                />
                {!this.state.isBrandNameValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Email Domain
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  classes={
                    !this.state.isBrandEmailDomainValid
                      ? { root: this.props.classes.inputError }
                      : { root: this.props.classes.inputRoot }
                  }
                  disableUnderline={true}
                  placeholder="domainname.com"
                  value={this.state.brandEmailDomain}
                  onChange={this.handleBrandDomainChange}
                />
                {!this.state.isBrandEmailDomainValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "40px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  style={
                    !this.state.isBrandDescriptionValid
                      ? {
                          width: "449px",
                          minHeight: "120px",
                          border: "1px solid red",
                          borderRadius: "2px",
                          paddingLeft: "15px",
                        }
                      : {
                          width: "449px",
                          minHeight: "120px",
                          border: "1px solid #9EABB5",
                          borderRadius: "2px",
                          paddingLeft: "15px",
                        }
                  }
                  disableUnderline={true}
                  placeholder=""
                  multiline={true}
                  value={this.state.brandDescription}
                  onChange={this.handleBrandDescriptionChange}
                />
                {!this.state.isBrandDescriptionValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: "44px", paddingBottom: "30px" }}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "88px",
                height: "40px",
                borderRadius: "2px",
              }}
              onClick={this.handleDialogClose}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                }}
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "144px",
                height: "40px",
                borderRadius: "2px",
                background: "#1E3E59",
                marginLeft: "15px",
              }}
              onClick={this.createNewBrand}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                  color: "#FFFFFF",
                }}
              >
                Create Brand
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Brands));
