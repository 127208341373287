import React from "react";
import { IBlock } from "../../framework/src/IBlock";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  Button,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { BlockComponent } from "../../framework/src/BlockComponent";

// Customizable Area Start
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

import { withTranslation, Trans } from "react-i18next";
import "react-html5-camera-photo/build/css/index.css";
import { toast } from "react-toastify";

const designHarvestLogo = require("./design-harvest-logo-icon.png");
const gettingStarted = require("./assets/gettingStarted.png");
const pricingPlans = require("./assets/pricingPlans.png");
const salesQuestions = require("./assets/salesQuestions.png");
const userGuide = require("./assets/userGuide.png");
const instantMessage = require("./assets/instantMessage.png");
const support = require("./assets/support.png");
import SearchIcon from "@material-ui/icons/Search";
import MaximizeIcon from '@material-ui/icons/Maximize';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = {
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    paddingBottom: "30px",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "999",
  }
} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area End
}

// Customizable Area Start
interface S {
  searchKeyword: string;
  categoryList: any;
  selectedCategory: any;
  carouselIndex: any; // holding the current index for the category that has to be rendered at each time on the screen 
  faqList: any;
  expanded: any;
  contactEmail: any;
  categoryInGerman:any;
}

interface SS {
}
declare global {
  interface Window {
    HubSpotConversations: string;
  }
}
declare global {
  var HubSpotConversations: {
    widget: {
      open: () => void;
    };
  };
}
class HelpWeb extends BlockComponent<Props, S, SS> {

  static instance: HelpWeb;
  searchApiCallId: any;
  getCategoriesApiCallId: any;
  getFaqQuestionsAndAnswersApiCallId: any;
  getFaqContactApiCallId: any;

  constructor(props: Props) {

    super(props);
    console.log(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]

    this.state = {
      searchKeyword: "",
      categoryList: [],
      selectedCategory: null,
      carouselIndex: 0,
      faqList: [],
      expanded: 0,
      contactEmail: '',
      categoryInGerman:[]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    HelpWeb.instance = this;
  }

  async componentDidMount() {
    await this.getCategories();
    this.getFaqContact();

  }

  getFaqContact = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFaqContactApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_contacts");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    console.log("receive function");
    console.log(message, "message");
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.searchApiCallId) {
        console.log(responseJson.faq_question_answers);
        if (responseJson?.faq_question_answers?.data) {
          this.setState({
            faqList: responseJson?.faq_question_answers?.data
          });
        } else {
          toast.error("Fetching faq list having issue");
        }
      }
      else if (apiRequestCallId === this.getFaqContactApiCallId) {

        console.log(responseJson?.faq_contacts?.data?.[0]);
        if (responseJson?.faq_contacts?.data?.length > 0) {
          this.setState({

            contactEmail: responseJson?.faq_contacts?.data?.[0]?.attributes.email,
            // contactPhoneNumber: responseJson?.faq_contacts?.data?.[0]?.attributes.phone_number,
            //contactUpdateFlag: 'UPDATE'
          });
        }
      }
      else if (apiRequestCallId === this.getCategoriesApiCallId) {
        if (responseJson.faq_categories) {
          if (responseJson.faq_categories.data) {
            if (responseJson.faq_categories.data.length !== 0) {
              this.setState({
                categoryList: responseJson.faq_categories.data,
              });
              responseJson.faq_categories.data.map((_data: any, idx: any) => {
                if (idx === 0) {
                  this.setState({
                    selectedCategory: _data,
                    expanded: _data.id
                  });
                  this.getFaqQuestionAndAnswers();
                }
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getFaqQuestionsAndAnswersApiCallId) {
        console.log(responseJson.faq_question_answers);
        if (responseJson?.faq_question_answers?.data) {
          this.setState({
            faqList: responseJson?.faq_question_answers?.data
          });
        } else {
          toast.error("Fetching faq list having issue");
        }
      }
    }
  }

  goToHomeScreen = () => {
    this.props.navigation.navigate('HomePage')
  }

  getSearchResults = () => {
    if (this.state.searchKeyword != "") {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.searchApiCallId = requestMessage.messageId;
      const header = { token: localStorage.getItem("token") };
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers?faq_search=" + this.state.searchKeyword);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.getFaqQuestionAndAnswers();
    }
  };

  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoriesApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_categories?per_page=30");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFaqQuestionAndAnswers = () => {
    console.log(this.state.selectedCategory);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFaqQuestionsAndAnswersApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers?faq_category_id=" + this.state.selectedCategory.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSelectedCategory = (category: any) => {
    this.setState({
      selectedCategory: category
    }, () => {
      this.getFaqQuestionAndAnswers();
    })
  }

  handleAccordionChange = (id: any) => {
    if(this.state.expanded == id){
      this.setState({
        expanded: false
      });
    }
    else {
      this.setState({
        expanded: id
      });
    }
    }
    

  prevSlide = () => {
    // find the index of the last image in the array
    const lastIndex = this.state.categoryList.length - 1;
    // check if we need to start over from the last index again
    const resetIndex = this.state.carouselIndex === 0;
    const index = resetIndex ? lastIndex : this.state.carouselIndex - 1;
    // assign the logical index to currentImageIndex that will use in render method
    this.setState({
      carouselIndex: index
    })
  }

  nextSlide = () => {
    // find the index of the last image in the array
    const lastIndex = this.state.categoryList.length - 1;
    // check if we need to start over from the first index
    const resetIndex = this.state.carouselIndex === lastIndex;
    const index = resetIndex ? 0 : this.state.carouselIndex + 1;
    // assign the logical index to currentImageIndex that will use in render method
    this.setState({
      carouselIndex: index
    });
  }
  openHubSpot = () => {
    window.HubSpotConversations.widget.open()
  }
  render() {

    const { t, navigation, classes } = this.props;
    const _this = this;
    let colorToBeChanged: any;

    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
    // get current category index
    const index = this.state.carouselIndex;
    // create a new array with 4 category cards from the source category list
    let firstFourCategories = this.state.categoryList.slice(index, index + 4);
    // check the length of the new array (it’s less than 4 when index is near the end of the array)
    if (firstFourCategories.length < 4) {
      // if the firstFourCategories's length is lower than 4 categories than append missing categories from the beginning of the original array 
      firstFourCategories = firstFourCategories.concat(this.state.categoryList.slice(0, 4 - firstFourCategories.length))
    }

    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <AppBar position="static" className={classes.appBar}>
                  <Toolbar variant="dense">
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                    </IconButton>
                    <Typography variant="h6" color="inherit" />
                  </Toolbar>
                  <Grid container spacing={0} className={classes.nav_main}>
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={designHarvestLogo} />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ color: secondaryColorToBeChanged, cursor: "pointer" }} onClick={this.goToHomeScreen}>
                            {" "}
                            {
                              `${t(
                                "CompanyProfile.backTo"
                              )} Design Harvest`}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AppBar>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: '3% 0%' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography
              variant="body2"
              style={{
                color: "#282828",
                fontSize: "32px",
                fontFamily: "Work Sans",
                fontWeight: 500,
                opacity: 1
              }}
            >
              {t("Help.anyHelp")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Input
              style={{
                width: "395px",
                height: "40px",
                border: "1px solid #1A1A1A",
                borderRadius: "4px",
                opacity: 0.4,
                paddingLeft: '5px'
              }}
              disableUnderline={true}
              type="text"
              placeholder={t("Help.askQuestion")}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              value={this.state.searchKeyword}
              onChange={(event) => this.setState({ searchKeyword: event.target.value })}
            />
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                border: `1px solid ${colorToBeChanged}`,
                width: "134px",
                height: "40px",
                marginLeft: "17px",
              }}
              variant="contained"
              onClick={this.getSearchResults}
            >
              <Typography
                variant="body2"
                style={{
                  color: "#FFFFFF",
                  fontSize: "13px",
                  fontFamily: "Work Sans",
                  opacity: 1,
                  textTransform: "uppercase",
                }}
              >
                {t("Help.search")}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '2%' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography
                variant="body2"
                style={{
                  color: "#737373",
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  fontWeight: 500,
                  opacity: 1
                }}
              >
                {t("Help.chooseCategory")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {this.state.categoryList.length >= 4 &&
                <IconButton aria-label="left" onClick={this.prevSlide}>
                  <ChevronLeftIcon fontSize="large" />
                </IconButton>
              }
              {
                this.state.categoryList.length >= 4 && firstFourCategories.map((_data: any, index: number) => (
                  <>
                    <Grid item xs={2} style={{ padding: '1%' }} key={_data.id}>
                      <Card key={_data.id} style={{ boxShadow: 'none', border: this.state.selectedCategory?.id == _data?.id ? '1px solid #173e59' : 'none' }} onClick={(event) => this.updateSelectedCategory(_data)}>
                        <CardContent style={{ padding: "0px", display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: '140px', backgroundColor: this.state.selectedCategory?.id == _data?.id ? '#ffffff' : '#f4f6fc' }}  >
                          <div style={{ padding: '15px' }}>
                            {/* {
                              _data?.attributes?.name.toUpperCase() === 'Getting Started'.toUpperCase() ? <img src={gettingStarted} /> :
                                _data?.attributes?.name.toUpperCase() === 'Pricing Plans'.toUpperCase() ? <img src={pricingPlans} /> :
                                  _data?.attributes?.name.toUpperCase() === 'Sales Questions'.toUpperCase() ? <img src={salesQuestions} /> :
                                    _data?.attributes?.name.toUpperCase() === 'Userguide'.toUpperCase() ? <img src={userGuide} /> : <SearchIcon />
                            } */}
                           {
                         _data.attributes.file_blob_signed_id_url ? _data.attributes.file_blob_signed_id_url !== "" ? <img src={_data.attributes.file_blob_signed_id_url} style={{height:"20px", width:"20px"}} /> : <SearchIcon /> : <SearchIcon />
                        }
                          </div>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#282828",
                              fontSize: "14px",
                              fontFamily: "Work Sans",
                              fontWeight: 500,
                              opacity: 1
                            }}
                          >
                            {_data?.attributes?.name}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ))
              }
              {this.state.categoryList.length >= 4 &&
                <IconButton aria-label="right" onClick={this.nextSlide}>
                  <ChevronRightIcon fontSize="large" />
                </IconButton>
              }
              {this.state.categoryList.length < 4 && this.state.categoryList.map((_data: any, index: number) => (
                <Grid item xs={2} style={{ padding: '1%' }} key={_data.id}>
                  <Card key={_data.id} style={{ boxShadow: 'none', border: this.state.selectedCategory?.id == _data?.id ? '1px solid #173e59' : 'none' }} onClick={(event) => this.updateSelectedCategory(_data)}>
                    <CardContent style={{ padding: "0px", display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: '140px', backgroundColor: this.state.selectedCategory?.id == _data?.id ? '#ffffff' : '#f4f6fc' }}  >
                      <div style={{ padding: '15px' }}>
                        {/* {
                          _data?.attributes?.name.toUpperCase() === 'Getting Started'.toUpperCase() ? <img src={gettingStarted} /> :
                            _data?.attributes?.name.toUpperCase() === 'Pricing Plans'.toUpperCase() ? <img src={pricingPlans} /> :
                              _data?.attributes?.name.toUpperCase() === 'Sales Questions'.toUpperCase() ? <img src={salesQuestions} /> :
                                _data?.attributes?.name.toUpperCase() === 'Userguide'.toUpperCase() ? <img src={userGuide} /> : <SearchIcon />
                        } */}
                         {
                         _data.attributes.file_blob_signed_id_url ? _data.attributes.file_blob_signed_id_url !== "" ? <img src={_data.attributes.file_blob_signed_id_url} style={{height:"20px", width:"20px"}} /> : <SearchIcon /> : <SearchIcon />
                        }
                      </div>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#282828",
                          fontSize: "14px",
                          fontFamily: "Work Sans",
                          fontWeight: 500,
                          opacity: 1
                        }}
                      >
                        {_data?.attributes?.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '2%' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                  style={{
                    color: "#282828",
                    fontSize: "20px",
                    fontFamily: "Work Sans",
                    fontWeight: 500,
                    opacity: 1,
                    paddingBottom: '2%'
                  }}
                >
                  {this.state.selectedCategory?.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#282828",
                    fontSize: "12px",
                    fontFamily: "Work Sans",
                    fontWeight: 500,
                    opacity: 1,
                    marginBottom: '2%',
                    width: '85%',
                    textAlign: 'center'
                  }}
                >
                  {this.state.selectedCategory?.description}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '2%' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {
                this.state.faqList.length > 0 && this.state.faqList.map((_data: any, index: number) => (
                  <Grid item xs={10} key={_data.id}>
                    <Accordion
                      style={{
                        boxShadow: "none"
                      }}
                      expanded={this.state.expanded == _data.id}
                      onChange={() => this.handleAccordionChange(_data.id)}
                      key={_data.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                        onClick={() => this.handleAccordionChange(_data.id)}
                      >
                        <Typography
                          style={{
                            color: '#282828',
                            fontSize: '16px',
                            fontFamily: "Work Sans",
                            fontWeight: 'bold',
                            opacity: 1
                          }}
                        >
                          {_data?.attributes?.question}
                        </Typography>
                        <span style={{ flex: '1 1 auto' }}></span>
                        {this.state.expanded === _data.id ? (<MaximizeIcon style={{ marginTop: '8px' }}  />) : (<AddIcon />)}
                      </div>
                      <AccordionDetails>
                        <Typography
                          style={{
                            color: '#282828',
                            fontSize: '12px',
                            fontFamily: "Work Sans",
                            fontWeight: 500,
                            opacity: 1
                          }}
                          dangerouslySetInnerHTML={{
                                        __html: _data.attributes.answer
                                        // +
                                        // _data.attributes.faq_question_answer_files?.data.length > 0 ? _data.attributes.faq_question_answer_files?.data[0]?.attributes?.file_blob_signed_id_url : null  

                                      }}
                        >
                          {/* {_data?.attributes?.answer} */}
                         {/* <a href= {_data?.attributes?.faq_question_answer_files?.data?.[0]?.attributes?.file_blob_signed_id_url} target="_blank" >File</a> */}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Grid
                      item
                      xs={12}
                      style={{
                        border: "1px solid #9EABB5",
                        opacity: 0.4,
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '2%' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                  style={{
                    color: "#282828",
                    fontSize: "20px",
                    fontFamily: "Work Sans",
                    fontWeight: 500,
                    opacity: 1,
                    paddingBottom: '2%'
                  }}
                >
                  {t("Help.stillQuestion")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#282828",
                    fontSize: "12px",
                    fontFamily: "Work Sans",
                    fontWeight: 500,
                    opacity: 1,
                    marginBottom: '2%',
                    width: '85%',
                    textAlign: 'center'
                  }}
                >
                  {t("Help.contactUs")}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {
                [{ name: 'Open Instant Message' }, { name: this.state.contactEmail }].map((obj: any, index: number) => (
                  <Grid item xs={2} style={{ padding: '1%' }} key={index}>
                    <Card style={{ boxShadow: 'none', border: 'none' }}>
                      <CardContent style={{ padding: "0px", display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: '140px', backgroundColor: '#f4f6fc' }}  >
                        <div style={{ padding: '15px', cursor: "pointer" }} onClick={() => {
                          this.openHubSpot();
                        }}>
                          {
                            obj.name.toUpperCase() === 'Open Instant Message'.toUpperCase() ? <img src={instantMessage} /> : <img src={support} />
                          }
                        </div>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#282828",
                            fontSize: "14px",
                            fontFamily: "Work Sans",
                            fontWeight: 500,
                            opacity: 1
                          }}
                        >
                          {obj.name === 'Open Instant Message' ? t("Help.instantMsg") : <a style={{color :secondaryColorToBeChanged }} href={`mailto:` + obj.name}>{obj.name}</a>}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </div >
    );
  }
}
// Customizable Area End

// Customizable Area Start

export default withStyles(styles)(withTranslation()(HelpWeb));
