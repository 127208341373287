import React, { Fragment, useState, useRef, useEffect } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfOutlined"
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Pagination from '@material-ui/lab/Pagination';
import '../../../components/src/styles/styles.css'
import Backdrop from "@material-ui/core/Backdrop";
import CalendarTodayOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Select from '@material-ui/core/Select';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GridList from '@material-ui/core/GridList';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import Menu from "@material-ui/core/Menu";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../../components/src/styles/styles.css'
import Box from '@material-ui/core/Box';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Slider from '@material-ui/core/Slider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Chip from '@material-ui/core/Chip';
import List from "@material-ui/core/List";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from '@material-ui/core/CardHeader';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
const deleteLogo = require('../../../components/src/svgFiles/group-9.svg')
// import ReactS3Uploader from "react-s3-uploader"
// import {
//   ReactPictureAnnotation,
//   defaultShapeStyle,
//   DefaultInputSection
// } from "react-picture-annotation";
import Annotation from 'react-image-annotation';
import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell, } from '@material-ui/core'
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation, Trans } from "react-i18next";
// import Divider from '@material-ui/core/Divider';
import { Editor } from 'react-draft-wysiwyg';
// import { convertToRaw, convertFromRaw } from 'draft-js';
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
const duplicateButton = require("../assets/duplicate.png");
// import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import CardMedia from '@material-ui/core/CardMedia';
import { imgFileTemplate } from "./assets";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Divider from '@material-ui/core/Divider';
import { FormatBold, FormatListBulleted, AttachFile, FormatItalic, FormatLineSpacing, InsertEmoticon, Telegram, InsertDriveFileOutlined, TrainRounded, NaturePeopleOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
// import { EditorState, Modifier } from 'draft-js';
import DirectionsIcon from '@material-ui/icons/Directions';
import { equal } from "assert";
//import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MenuIcon from '@material-ui/icons/Menu';
import GetAppIcon from '@material-ui/icons/GetApp';
import '../../../components/src/styles/scrollbar.css';
import '../../../components/src/styles/styles.css';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

const approvedDone = require("../assets/approved_done.png");
const CancelIcon = require("../assets/close_white.png");
const FilterIcon = require("../assets/filter.png");
const LeftIcon = require("../assets/left.png");
const RightIcon = require("../assets/right.png");
const CommentIcon = require("../assets/comment.png");
const DownloadIcon = require("../assets/download.png");
const FullViewIcon = require("../assets/view_full.png");
const ExitFullViewIcon = require("../assets/view_actual.png");
const defaultUser = require("../../../components/src/defaultuser.png")
const projectPropertiesButton = require("../assets/projectProperties.png");


import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/moment";

import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,

  Platform
} from "react-native";
// Customizable Area End

import ProjectPortfolioController, {
  Props,
  configJSON,

} from "./ProjectPortfolioController";
import Input from '@material-ui/core/Input';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardActions from '@material-ui/core/CardActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
// import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles'; createMuiTheme
import { createMuiTheme } from '@material-ui/core';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Icon from "react-native-vector-icons/FontAwesome";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SearchIcon from '@material-ui/icons/Search';
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from '@material-ui/core/InputAdornment';
import _, { parseInt } from "lodash";
import Card from '@material-ui/core/Card';
import { Formik } from "formik";
// popover
import Popover from '@material-ui/core/Popover';
import SendIcon from '@material-ui/icons/Send';

import * as Yup from "yup";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@material-ui/core/TextField";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { classNames } from "react-select/src/utils";
import ReactS3Uploader from "react-s3-uploader";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuList from '@material-ui/core/MenuList';
import WebViewer from "@pdftron/webviewer";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import $ from 'jquery';

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const logo = require("../../../components/src/volkswagen.svg")
const userLogo = require('../../../components/src/view-user-image@3x.png')
const hearBeatLogo = require('../../../components/src/svgFiles/heartbeat.svg')
const downIcon = require('../../../components/src/down.png')
const imageIcon = require("../../../components/src/svgFiles/image.svg")
const userImage = require("../../../components/src/view-user-image.png")
const plusButton = require("../../../components/src/plus-top-bar.png");
const deleteButton = require("../assets/delete.png");
import Tooltip from '@material-ui/core/Tooltip';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ThemeProvider } from "react-native-elements";
import { userInfo } from "os";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PermIdentitySharpIcon from '@material-ui/icons/PermIdentitySharp';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1a76b0",
    }
  }
})

const minRowsValue: any = 5;

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip);
// const [selectedDate, handleDateChange] = useState(new Date());

const PrettoSlider = withStyles({
  root: {
    color: '#1a76b0',
    height: 8,
  },
  // textReadmore:{
  //   position:"absolute",
  //   right:0
  // },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSlider = withStyles({
  root: {
    color: "#1a76b0",
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#1a76b0",
    border: "1px solid 1a76b0",
    marginTop: -9,
    marginLeft: -11,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#1a76b0",
  },
})(Slider);

const top100Films = [
  { title: 'Lars', year: 1994, image: require('../../../components/src/view-user-image.png') },
  { title: 'Abbe', year: 1972, image: require('../../../components/src/view-user-image.png') },
  { title: 'Alexander', year: 1974, image: require('../../../components/src/view-user-image.png') },
  { title: 'Baltasar', year: 2008, image: require('../../../components/src/view-user-image.png') },
  { title: 'Barduwulf', year: 1957, image: require('../../../components/src/view-user-image.png') },
  { title: "Barnim", year: 1993, image: require('../../../components/src/view-user-image.png') },
  { title: 'Pulp Fiction', year: 1994, image: require('../../../components/src/view-user-image.png') },
  { title: 'Frederick', year: 2003, image: require('../../../components/src/view-user-image.png') },
  { title: 'Garit', year: 1966, image: require('../../../components/src/view-user-image.png') },
  { title: 'Godfrey', year: 1999, image: require('../../../components/src/volkswagen.svg') },

];
const approvers = [
  {
    name: "Lars",
    image: userImage
  },
  {
    name: "convi",
    image: "../../../components/src/view-user-image.png"
  }
]
/**
* Moves an item from one list to another list.
*/

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    padding: "24px 13px 30px 28px",
    left: "-55px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);


const styles = {
  name: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    // margin: "10px"
    marginTop: "10px",

    // boxShadow: "none",
    // background:" linearGradient(to bottom, black 50%, white 50%)"
    // background: '-webkit-linear-gradient(top, white 80%, #e4e8f0 20%);'
  },
  filterButton: {

  },
  accordianStyle: {
    boxShadow: "none",
  },
  fileName: {
    fontSize: "14px",
    margin: "8px"
  },
  banners: {
    marginTop: "30px",
    marginLeft: "50px",
    backgroundColor: '#f5f6fa'
  },
  uploadButton: {
    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  labelTextForBriefingFields: {
    width: "auto",
    height: "auto",
    margin: "10px 13px 4px 1px",
    fontFamily: "sansSerif",
    fontSize: "16px",
    // fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineSeight: "normal",
    letterSpacing: "normal",
    color: "#282828",
  },
  labelTextForBriefingFieldsDescription: {
    // width: "484px",
    // height: "19px",
    margin: "0px 0px 15px 0px",
    fontFamily: "'WorkSans',sansSerif",
    fontSize: "10px",
    color: "#282828",
    fontWeight: 400,
    lineHeight: '2',
  },
  TaskViewTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: 'center',
    alignItems: "center",
    color: "#000000",
    padding: "20px 20px",
  },
  // tab: {
  //   minWidth: 10,
  // },
  date: {
    fontSize: "12px",
    marginLeft: "190px"
  },
  progress: {
    display: "flex",
    flexDirection: "row",
    height: '55px'
  },
  fileSize: {
    fontSize: "10px",
    margin: "8px"
  },
  projectDocument: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px",
  },
  selectInput: {
    marginBottom: "20px",
  },
  projectDocuments: {
    display: "flex",
    flexDirection: "row",
    margin: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  members: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginLeft: "20px"
    // position:"relative"
  },
  modalUserName: {
    marginTop: "-10px",
    marginLeft: "13px",
  },
  modalUserEmail: {
    marginTop: "10px",
    marginLeft: "-83px",
    color: "#666666"

  },
  dialogueTitle: {
    height: "30px"
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  confirmInput: {
    marginTop: "25px"
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  listHeader: {
    display: 'flex'
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  checkdecoration: {
    textDecoration: 'line-through'
  },
  circle: {
    background: 'white',
    borderRadius: '50%',
    width: ' 33px',
    height: '33px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circleList: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  countCircle: {
    background: '#ffd4d4',
    borderRadius: '50%',
    marginLeft: "70%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  listAvatar: {
    height: '25px',
    width: "25px",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    color: "black"
  },
  qaFeedback: {
    background: '#c8e7fa',
    borderRadius: '50%',
    marginLeft: "50%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  inProgress: {
    background: '#f6f0ba',
    borderRadius: '50%',
    marginLeft: "55%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  //   ''.MuiAvatar-root':{
  // height:"30px !important",
  // width:"30px !important"
  //   },

  doneCards: {
    background: '#c7f9c5',
    borderRadius: '50%',
    // marginLeft: "70%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: "15px"
  },

  textfieldLabel: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#666666"
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "#737373",
      borderWidth: "1px",
    },
  },

  inputSearchBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    //     avatar:{
    // display:"inline-flex !important"
    //     },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  root: {
    minWidth: 75,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 8,
  },
  dlgpaper: {
    minWidth: "800px"
  },
  templateHover: {
    backgroundColor: "rgb(52, 63, 86)",
  },
  scrollbarSize: {
    '&::-webkit-scrollbar': {
      width: "5px!important"
    },
    '&::-webkit-scrollbar-track': {
      background: "#f1f1f1"
    },
    '&::-webkit-scrollbar-thumb': {
      background: "#888"
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: "#555"
    }
  },
  activitySpacing: {
    // marginLeft:"20px",
    width: "100%",
  },
  activityCardRoot: {
    width: "100%",
    backgroundColor: "#e4e8f0",
    height: "auto",
  },
  allActivityCard: {
    boxShadow: "none",

  },
  tab: {
    "&:p": {
      fontSize: "16px",
      textTransform: "none"
    },
    textTransform: "none"
  },
  activitiesCicle: {
    background: 'white',
    borderRadius: '50%',
    border: "1px solid #e4e8f0",
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  activitiesCalenderRow: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "start",

  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  commentBox: {
    // width: "275px",
    // height: "275px",
    margin: "0px 10px 0px 10px",
    padding: "20px 20px 7px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    marginBottom: "5px",
  },
  commentUserImage: {
    height: "30px",
    width: "30px",
    borderRadius: "50%"
  },
  addCommentBox: {
    margin: "15px 0",
    borderRadius: "4px",
    border: "solid 1px #737373",
    backgroundColor: "#ffffff",
  },
  commentName: {
    margin: "5px 0px 0px 0px",
    fontSize: "12px",
    // fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    color: "#282828",
  },
  commentDate: {
    margin: "0px 17px 12px 0px",
    fontSize: "10px",
    // fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    color: "#282828",
  },
  commentText: {
    margin: "12px 0 0px 0px",
    fontSize: "14px",
    // fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#282828",
    overflowWrap: "break-word"
  },
  commentButton: {
    color: "#1a76b0",
    border: "2px solid #1a76b0",
    marginRight: "10px",
    cursor: "Pointer"
  },
  commentButton1: {
    color: "#1a76b0",
    marginRight: "10px",
    cursor: "Pointer"
  },
  replyBox: {
    width: "100%",
    textAlign: "right"
  },
  commentLine: {
    display: "inline-block",
    border: "1px solid #bfbdbd",
    width: "120px",
    marginBottom: "3px",
  },
  scrollComments: {
    paddingLeft: "15px",
    maxHeight: "100%",
    overflowY: "auto"
  },
  submitReviewButton: {
    // marginLeft: "auto !important",
    height: "46px",
    padding: "15px",
    borderRadius: "8px",
    border: "solid 1px #ffffff",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "#ffffff"
  },
  FinalApprovalButton: {
    height: "46px",
    padding: "15px 17px 15px 18px",
    borderRadius: "8px",
    backgroundColor: "#1a76b0",
    color: "#ffffff",
    marginLeft: "10px"
  },
  dropDownStatus: {
    fontSize: "12px",
    color: "#1a76b0",
    fontFamily: 'Work Sans',

  },
  taskImgsStatus: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: "#fff",
    border: "1px solid #e4e8f0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginRight: "6px",
  },
  taskImgStatus: {
    height: "36px",
    objectFit: "contain",
  },
  SelectDropDown: {
    width: "130px",
    height: "32px",
    margin: "0 16px 12px 9px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#ffffff",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  SelectDropDownVersion: {
    width: "70px",
    height: "32px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#ffffff",
  },
  testing12: {
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
  },
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },

  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    width: "72px",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  highlightText: {
    padding: "5px 5px 5px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
  },
  activeCommentBox: {
    borderStyle: 'solid',
    borderColor: '#00a5e4',
  },
  pdfPageView: {
    backgroundColor: "rgb(0 0 0 / 78%)"
  },
  pdfPageNavigation: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  pdfPageNavigationPrevButton: {
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#1a76b0",
    marginRight: "10px",
    marginLeft: "auto"
  },
  pdfPageNavigationNextButton: {
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#1a76b0",
    marginRight: "10px",
  },
  // annotationDot: {
  //   borderRadius: "50%",
  //   padding: "0px 10px 3px 10px",
  //   fontSize: "30px",
  //   color: "#fff",
  //   textAlign: "center",
  //   background: "#000"
  // },
  annotationNumberInBox: {
    borderRadius: "50%",
    padding: "0px 8px 3px 8px",
    fontSize: "20px",
    color: "#fff",
    textAlign: "center",
    background: "#21ab21"
  },
  cancelIcon: {
    width: "20px",
    paddingLeft: "20px"
  },
  annotation: {
    '& .MuiDialog-paperWidthLg': {
      background: "#f0ffff59"
    }
  },
  annotationDialogTitle: {
    cursor: 'move',
    backgroundColor: "rgb(0 0 0 / 78%)",
    padding: "5px 24px 24px"
  },
  annotationDialogHeader: {
    display: "flex",
    color: "#ffffff",
    justifyContent: "space-between",
    alignItems: "center"
  },
  annotationDialogHeaderFilter: {
    textAlign: "right",
    display: "flex",
    alignItems: "center"
  },
  annotationDialogHeaderFilterMenu: {
    color: '#1a76b0',
    fontSize: 14
  },
  scrollAnnotationFile: {
    height: "90%",
    overflow: "auto",
    padding: "25px",
  },
  colorblack: {
    color: "black"
  },
  scrollAnnotationImage: {
    overflow: "auto",
    height: "100%"
  },
  anootationContainer: {
    backgroundColor: "rgb(0 0 0 / 78%)",
    padding: "8px 5px",
    overflow: "hidden"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  showActivityOrCommentButton: {
    display: "initial",
    color: "rgb(23, 62, 89)",
    cursor: "pointer"
  },
  finalApprovalText: {
    // marginLeft: "auto !important",
    color: "#ffffff",
    marginRight: "20px"
  },
  setAnnotationAssetInFrame: {
    maxHeight: "100%",
    maxWidth: "100%"
  },
  fullScreenEnterAndExisIcon: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
    zIndex: 1
  },
  setAssetInFrame: {
    maxHeight: "100%",
    width: "auto",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%"
  },
  attachmentShowContent: {
    backgroundColor: "rgb(0 0 0 / 78%)",
    padding: 0,
    overflowY: "hidden",
    position: "relative"
  },

  paragraphTemp: {
    "&:span": {
      backgroundColor: "transparent !important",
      border: "1px solid red"
    }
  },
  paragraphTemp1: {},
  createTemplete: {
    width: "100%"
  }
  // popUpMenu :{
  //   zIndex:`1316 !important`
  // }
} as const
const grid = 0;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 10,
  // margin: `0 0 ${grid}px 0`,
  margin: 7,
  border: "1px solid #e4e8f0 ",

  // change background colour if dragging
  background: isDragging ? 'white' : '#f5f6fa',
  borderRadius: '8px',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? '#e4e8f0' : '#f5f6fa',
  padding: grid,
  width: '260px',
  height: "auto",
  marginBottom: "20px"
});

interface PdfAnnotationProps {
  getAllFileTaskAnnotation: any;
  onChangeForPdf: any;
  documentUrl: any;
  classes: any;
}
class PDFTronWebViewer extends React.Component<PdfAnnotationProps>{
  viewer: any;
  constructor(props: PdfAnnotationProps) {
    super(props);
    this.viewer = React.createRef();
  }

  componentDidMount() {
    WebViewer(
      {
        path: '../../lib',
        // initialDoc: 'http://www.usrap.org/sites/default/files/historical/pdf/usRAP_brochure.pdf',
        initialDoc: decodeURIComponent(this.props.documentUrl),
      },
      this.viewer.current,
    ).then((instance) => {
      instance.UI.disableElements(['header']);
      instance.UI.setToolbarGroup('toolbarGroup-Annotate', true);

      const { Annotations, annotationManager, documentViewer } = instance.Core;

      documentViewer.addEventListener('documentLoaded', async () => {

        if (this.props.getAllFileTaskAnnotation && this.props.getAllFileTaskAnnotation.length > 0) {
          this.props.getAllFileTaskAnnotation.forEach((element: any) => {



            const rectangleAnnot = new Annotations.StickyAnnotation();
            //WC
            rectangleAnnot.PageNumber = element.attributes?.page_number ? element.attributes.page_number : 1;
            // values are in page coordinates with (0, 0) in the top left
            //dx
            rectangleAnnot.X = element.attributes.x_coordinate;
            //ex
            rectangleAnnot.Y = element.attributes.y_coordinate;
            //Ws
            // rectangleAnnot.Width = 200;
            //us
            // rectangleAnnot.Height = 150;
            rectangleAnnot.Author = "Test";
            annotationManager.addAnnotation(rectangleAnnot);
            annotationManager.redrawAnnotation(rectangleAnnot);
          });
        }
      });
      annotationManager.addEventListener('annotationChanged', (annotations, action, { imported }) => {
        // 


        //  this.props.onChangeForPdf(annotations);
      });
      annotationManager.addEventListener('annotationSelected', (annotations) => {

        if (this.props.getAllFileTaskAnnotation && this.props.getAllFileTaskAnnotation.length > 0) {
          this.props.getAllFileTaskAnnotation.forEach((element: any, index: any) => {
            if (element.attributes.x_coordinate == annotations[0].dx
              && element.attributes.y_coordinate == annotations[0].ex) {

              // var elements : any = document.getElementsByClassName('activeCommentBox');
              // elements.classList.remove("activeCommentBox");
              var d: any = document.getElementById(`annotation${index}`);
              if (d) {
                // d.classList.add(this.props.classes.activeCommentBox);
                // d.style.borderStyle = 'solid';
                // d.style.borderColor = '#00a5e4';
                d.scrollIntoView();
              }
              // $('#scrollCommentsSection').scrollTo(`#annotation${index}`);
            }
          });
        }
      });
    });
  }

  render() {
    return (
      <div style={{ height: '100vh' }} ref={this.viewer} />
    );
  }
}


class ProjectPortfolio extends ProjectPortfolioController {
  fileInput: any
  fileInput10: any
  fileInput1: any
  fileInput2: any
  fileInput3: any
  fileInput4: any
  screenshotRef: any
  viewer: any;
  fileType: any;
  textArea: any
  constructor(props: Props) {
    super(props);
    // this.state = {
    //   items: getItems(10)
    // };
    this.fileInput = React.createRef();
    this.fileInput1 = React.createRef();
    this.fileInput2 = React.createRef();
    this.fileInput3 = React.createRef();
    this.fileInput4 = React.createRef();
    this.fileInput10 = React.createRef();
    this.screenshotRef = React.createRef();
    this.viewer = React.createRef();
    this.fileType = React.createRef();
    this.textArea = React.createRef();
    // Customizable Area Start
    // Customizable Area End
  }



  inputFileRmoveAndUpload = (e: any) => {
    // 
    //
    // this.setState({fileNameFromBriefing: e.target.files[0].name})
    this.deleteTaskFile(this.state.deleteFileId);
    this.getSignedUrl(e.target.files[0]);
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };

  };

  inputFileChanged = (e: any, optionId?: any) => {
    // 
    //
    // this.setState({fileNameFromBriefing: e.target.files[0].name})
    //this.getSignedUrl(e.target.files[0]);
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
    // this.setState({briefingFieldId : optionId},()=>{
    //   

    // })
    //
  };
  onDragEnd = (result: any) => {
    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      const { destination, source, draggableId, type } = result;
      //
      if ((destination.droppableId === "Approved" && source.droppableId === "Done") || localStorage.getItem("brandGroupSelectedName") == 'Hugo Boss') {
        //moving from one list to another
        const home = this.state.columnsKanban[source.droppableId];
        const foreign = this.state.columnsKanban[destination.droppableId];

        if (foreign?.title == "Production Ready") {
          const newTask = home.tasks[source.index];
          //

          const homeTaskIds = Array.from(home.tasks);
          homeTaskIds.splice(source.index, 1);
          const newHome = {
            ...home,
            tasks: homeTaskIds,
          };

          const foreignTaskIds = Array.from(foreign.tasks);

          foreignTaskIds.splice(destination.index, 0, newTask);


          const newForeign = {
            ...foreign,
            tasks: foreignTaskIds,
          };

          const newState = {
            columnsKanban: {
              ...this.state.columnsKanban,
              [newHome.title]: newHome,
              [newForeign.title]: newForeign,
            },
          };
          this.setState(newState);
          if (foreign.title === "Open") {
            this.callStateTransitionsAPI("open", newTask.id, foreign.title)
          } else if (foreign.title === "Approve") {
            this.callStateTransitionsAPI("approve", newTask.id, foreign.title)
          } else {
            this.callStateTransitionsAPI(`${foreign.action}`, newTask.id, foreign.title)

          }

        }
        else {
          toast.error("Sorry, You are not allowed to change the column order!!");
        }
      }
      else {
        toast.error("Sorry, You are not allowed to change the column order!!");
      }
    } else {
      const { destination, source, draggableId, type } = result;
      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (type === 'column') {
        const newColumnOrder = Array.from(this.state.taskStatusesData);
        const newColumn = newColumnOrder[source.index];
        let id = newColumnOrder[source.index]
        //

        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, newColumn);

        const newState = {
          ...this.state.taskStatusesData,
          taskStatusesData: newColumnOrder,
        };
        this.setState(newState, () => {
          this.onPriorityChange()

        });
        return;
      }

      const home = this.state.columnsKanban[source.droppableId];
      const foreign = this.state.columnsKanban[destination.droppableId];



      const newTask = home.tasks[source.index];
      //

      if (home === foreign) {
        const newTaskIds = Array.from(home.tasks);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, newTask);

        const newHome = {
          ...home,
          tasks: newTaskIds,
        };

        const newState = {
          ...this.state.columnsKanban,
          columns: {
            ...this.state.columnsKanban,
            [newHome.id]: newHome,
          },
        };

        this.setState(newState);
        return;
      } else {

        //moving from one list to another
        const homeTaskIds = Array.from(home.tasks);
        homeTaskIds.splice(source.index, 1);
        const newHome = {
          ...home,
          tasks: homeTaskIds,
        };

        const foreignTaskIds = Array.from(foreign.tasks);

        foreignTaskIds.splice(destination.index, 0, newTask);


        const newForeign = {
          ...foreign,
          tasks: foreignTaskIds,
        };

        const newState = {
          columnsKanban: {
            ...this.state.columnsKanban,
            [newHome.title]: newHome,
            [newForeign.title]: newForeign,
          },
        };
        this.setState(newState);
        if (foreign.title === "Open") {
          this.callStateTransitionsAPI("open", newTask.id, foreign.title)
        } else if (foreign.title === "Approve") {
          this.callStateTransitionsAPI("approve", newTask.id, foreign.title)
        } else {
          this.callStateTransitionsAPI(`${foreign.action}`, newTask.id, foreign.title)
        }


      }

    }
  };

  openCommentReplyBox = (id: any) => {
    this.setState({
      openReplyBox: true,
      replyCommentData: "",
      addReplyAnnotationId: id
    });
  }

  closeCommentReplyBox = () => {
    this.setState({
      openReplyBox: false,
      replyCommentData: "",
      addReplyAnnotationId: "",
      replyEditorState: EditorState.createEmpty(),
      convertedReplyData: {}

    });
  }

  onMouseOver = (id: any) => (e: any) => {
    //
    //
    this.setState({
      activeAnnotations: [
        ...this.state.activeAnnotations,
        id
      ]
    });
  }

  onMouseOut = (id: any) => (e: any) => {
    const index = this.state.activeAnnotations.indexOf(id)

    this.setState({
      activeAnnotations: [
        ...this.state.activeAnnotations.slice(0, index),
        ...this.state.activeAnnotations.slice(index + 1)
      ]
    });
  }

  activeAnnotationComparator = (a: any, b: any) => {
    //
    //
    return a.data.id === b
  }

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 10 || parseInt(value) == 12) {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      localStorage.setItem("toShowOnboarding", "false");
      window.location.reload();
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
    this.forceUpdate();
  }

  handleSkipOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) <= 10) {
      localStorage.setItem("onBoardingStep", "11");
    } else if (parseInt(value) == 12) {
      localStorage.setItem("onBoardingStep", "13");
    }
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
    this.hideAnnotationAndTextLayer();
    this.loadJqueryForAnnotation();
  };

  onClickCancelPdfAnnotation = () => {
    $(`#Annot${this.state.annotationNumber - 1}`).removeAttr('style');
    $(`#Annot${this.state.annotationNumber - 1}`).css({ "display": "none" });
    $(`#Annot${this.state.annotationNumber}`).remove();
    this.setState({
      annotationCompleteDataPdf: EditorState.createEmpty(),
      showAddAnnotation: false,
      convertedReplyData: {},
      annotationNumber: this.state.annotationNumber - 1
    });
  }
  createRamdomCode = () => {
    var url_code = "";
    var useCharacters = "1234567890";
    for (var i = 0; i < 1; i++) {
      url_code += useCharacters.charAt(Math.floor(Math.random() * useCharacters.length));
    }
    return url_code;
  }
  formatDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({ openLink: false })

  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // const test = {
    //   test: this.test
    // }

    //
    //
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any
    let finalTaskFiles = this.state.fileTaskName.filter((item: any) => item?.attributes?.source_file == false && item?.attributes?.final_file == true);

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const userDetails: any = localStorage.getItem("userInfo")

    // const date = new Date();
    // const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
    var dueDate = this.formatDate()

    return (
      //Merge Engine DefaultContainer
      <div style={{ marginLeft: "20px", fontFamily: primaryFont }}>
        <Backdrop
          className={classes.backdrop}
          open={this.state.loadingIndicator}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={0} >
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/Homepage" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                {t("HomeScreen.home")}
              </Link>
              {(this.state.permissionName !== "client" && this.state.permissions !== "viewer") && (this.state.permissionName !== "client" && this.state.permissions !== "approver") && (this.state.permissionName !== "client" && this.state.permissions !== "brand_manager") && (
                <Link href="/Home/Brands" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                  {t("AdminConsole.brands")}


                </Link>
              )
              }
              <Link href="/Home/Projects" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                {t("HomeScreen.project")}
              </Link>
              {/* <Link href="/Home/Projectportfolio" style={{ color: secondaryColorToBeChanged, fontSize: "14px" }}>
                {this.state.selectedProjectName}
              </Link> */}
              <p style={{ color: "#737373", fontSize: "14px", fontFamily: primaryFont }}>
                {this.state.selectedProjectName}
              </p>


            </Breadcrumbs>

            <span> <p style={{ fontSize: "32px", margin: "0px 0px 0px 10px", fontFamily: primaryFont }}> {this.state.selectedProjectName}

              {/* <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">{this.state.selectedProjectName}</Typography>
                    <p>{this.state.projectDesc}</p>
                  </React.Fragment>
                }
                placement="right"
              > */}
              <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373", cursor: 'pointer' }}
                onClick={(e) => {
                  // 
                  // const item: any = localStorage.getItem("selectedProjectId");
                  // this.callShowProjectsApi(item)
                  this.handleClickProjectPropertiesIcon(e);
                }}
              >
              </InfoOutlinedIcon>
              {/* </HtmlTooltip> */}

              {/* <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} 
              
              
              >

              </InfoOutlinedIcon> */}




            </p></span>
            {/* {window.location.href.includes("Projectportfolio") &&
              <span style={{ marginLeft: "10px" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" }}>{this.state.projectNameSelectedShow} <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} onClick={this.handleClickOpenModal}></InfoOutlinedIcon></p></span>


            }
            {
              window.location.href.includes("CGI") &&
              (
                <span style={{ marginLeft: "10px" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" }}>CGI <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} onClick={this.handleClickOpenModal}></InfoOutlinedIcon></p></span>

              )
            }

            {window.location.href.includes("Photoproduction") &&
              (
                <span style={{ marginLeft: "10px" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" }}>Photo Production <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} onClick={this.handleClickOpenModal}></InfoOutlinedIcon></p></span>

              )

            } */}







          </Grid>
          <Grid item xs={8} style={{ textAlign: "right", marginTop: "13px", padding: "5px" }}>

            <Button
              variant="contained"
              style={{ color: secondaryColorToBeChanged, marginBottom: "10px", textTransform: "none", fontFamily: primaryFont }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event: any) => this.setState({ anchorElFilter: event.target })}
            >
              Filters {this.state.selectedUsersForFilter.length + this.state.selectedAssetTypeForFilter.length + this.state.selectedCategoryForFilter.length + this.state.selectedStatusForFilter.length + (this.state.filterDueDate ? 1 : 0) > 0 ? `(${this.state.selectedUsersForFilter.length + this.state.selectedAssetTypeForFilter.length + this.state.selectedCategoryForFilter.length + this.state.selectedStatusForFilter.length + (this.state.filterDueDate ? 1 : 0)})` : null}
            </Button>

            <ButtonGroup aria-label="outlined primary button group" style={{ marginRight: "10px", marginLeft: "10px" }}>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                className={this.state.gridProjectPortfolio ? classes.activeButton : classes.button}
                onClick={e => {
                  var selectedGrid: any = true
                  localStorage.setItem("selectedGridView", selectedGrid)
                  this.changeToGridView(e)
                  this.setState({ isSearch: false, searchProject: "" })
                }}

                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={<AppsOutlinedIcon />}
              >
                {this.state.gridProjectPortfolio &&
                  <>{t("HomeScreen.grid")}</>
                }
              </Button>
              <Button
                variant="contained"

                className={this.state.listProjectPortfolio ? classes.activeButtonList : classes.buttonGroup}
                onClick={e => {
                  var selectedGrid: any = false
                  localStorage.setItem("selectedGridView", selectedGrid)
                  this.changeToListView(e)
                  this.setState({ isSearch: false, searchProject: "" })
                }}
                endIcon={<ListOutlinedIcon />}

              >
                <span style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: primaryFont }}>  {this.state.listProjectPortfolio &&
                  <>{t("HomeScreen.list")}</>
                }</span>
              </Button>



            </ButtonGroup>


            {/* <SearchBar
      dataSource={state.dataSource}
      onChange={(value) => setState({dataSource: [ value, value+value, value+value+value]})}
      onRequestSearch={() => }
      style={{
        margin: '0 auto',
        maxWidth: 800
      }}
    /> */}
            <Input
              id="input-with-icon-adornment"
              className={classes.inputSearchBox}
              startAdornment={
                <InputAdornment position="start" >
                  <SearchIcon />
                </InputAdornment>
              }
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
            />

            <span onClick={() => {
              this.setState({ openProjectProperties: true, value: 2 })


            }}>
              <AvatarGroup max={2} className={classes.avatar} style={{ display: "inline-flex", marginLeft: "10px" }} >
                {this.state.selectedProjectsAccountsData.length > 0 && this.state.selectedProjectsAccountsData.map((item: any) => (
                  <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={item.attributes?.photo_blob_signed_id_url} />
                ))}
                {/* <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300" />
                <Avatar alt="Travis Howard" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300" />
                <Avatar alt="Cindy Baker" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300 " />
                <Avatar alt="Trevor Henderson" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300 " /> */}

              </AvatarGroup>
            </span>

            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "12" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    {/* <img src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "13px", width: "13px", borderRadius: "50%" }} /> */}
                    <span className={classes.popupTitle} style={{ color: secondaryColorToBeChanged }}>{t("ApprovalRequest.activity")}</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.accountTextOne")}<br />
                    {t("onBoarding.accountTextTwo")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                      color={colorToBeChanged}
                    >
                      SKIP
                    </Button>
                    {/* <Button
                                      className={classes.popupButton}
                                      onClick={this.handlePrevOnboarding}
                                    >
                                      PREV
                                    </Button> */}
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >
              <Button aria-describedby={id}
                color="primary"
                variant="contained"
                className={classes.button + ' ' + (localStorage.getItem("onBoardingStep") == "12" && classes.highlightText)}
                size="medium"
                style={localStorage.getItem("onBoardingStep") == "12" ? { zIndex: 9999, marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', color: colorToBeChanged, marginBottom: "18px", textTransform: "none", fontFamily: primaryFont }
                  : { marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', color: secondaryColorToBeChanged, marginBottom: "18px", textTransform: "none", fontFamily: primaryFont }}
                startIcon={<img src={hearBeatLogo} style={{ width: "20px", height: "24px" }} />}
                onClick={this.handleClickMenu}
              >
                {t("ApprovalRequest.activity")}
              </Button>
            </HtmlTooltip>





            {/* Modal Dialogues */}
            <Popover
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            // style={{ backgroundColor: "#000000ad" }}
            >
              <List style={{ width: '100%', maxWidth: '36ch', padding: '8% 4%', margin: '10px -11px' }}
                subheader={
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <ListSubheader component="div" id="nested-list-subheader"
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: '0.15px',
                        color: '#282828',
                        fontFamily: primaryFont
                      }}
                    >
                      {t("ApprovalRequest.activity")}
                    </ListSubheader>
                    <IconButton aria-label="delete" style={{ marginRight: '22px' }} onClick={this.handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                }
              >
                {this.state.taskActivityData?.map((item: any) => {
                  return (
                    // <Card key={item.id} >
                    //   <CardContent>
                    //     <Typography >{item.attributes.description}</Typography>
                    //     <Typography >{item.attributes.activity}</Typography>
                    //   </CardContent>
                    // </Card>
                    <ListItem
                      alignItems="flex-start"
                      onClick={e => { this.handleClickOpenTaskModal2(item) }}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="body2" style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#282828',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: '0.1px',
                            margin: '10px 0px'
                          }}>
                            <p style={{ margin: "0px", fontFamily: primaryFont }} dangerouslySetInnerHTML={{ __html: item?.attributes?.description }} />

                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              style={{ display: 'inline' }}
                              color="textPrimary"

                            >
                              {item.attributes.updated_at}
                            </Typography>

                          </React.Fragment>
                        }
                      />
                    </ListItem>

                  )
                })}
              </List>
            </Popover>


            {/* <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen1}
        >
          <DialogTitle id="customized-dialog-title">
            Activity List
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>
          {this.state.taskActivityData.map((item:any) => {
                              return( 
                                <Card key={item.id} >
                                  <CardContent>
                                    <Typography >{item.attributes.description}</Typography>
                                    <Typography >{item.attributes.activity}</Typography>
                                  </CardContent>
                                </Card>
                              )})}
        </Dialog> */}







          </Grid>
          <Grid className={classes.createTemplete}>

            {this.state.creteTempleteButton && this.state.permissionName == 'superadmin' && <Button aria-describedby={id}

              variant="contained"
              size="medium"
              style={{

                backgroundColor: colorToBeChanged,
                color: "white",
                fontFamily: primaryFont,
                float: "right",
                marginRight: "15px"
              }}
              onClick={this.openCreateTempleteModel}
            >
              {/* {t("ApprovalRequest.activity")} */}
              Create Template
            </Button>}
          </Grid>
        </Grid>
        {!this.state.isSearch ?
          <>
            <div>
              {this.state.gridProjectPortfolio &&
                <div>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable
                      droppableId="all-columns"
                      direction="horizontal"
                      type="column"
                    >
                      {(provided, snapshot) => (
                        <div style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-around',
                        }}
                        >
                          <Grid container spacing={10} style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                              <GridList >
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{

                                    flexGrow: 1,
                                    // padding: 20,
                                    display: "flex",
                                    height: "100%",
                                    alignItems: "strech",
                                    overflowY: "hidden",
                                    // backgroundColor: "#e2e2e2",
                                    // backgroundColor: "red",
                                  }}>

                                  {this.state.taskStatusesData.map((item: any, index: any) => (
                                    <Draggable key={item.id} draggableId={item.attributes.status} index={index}>
                                      {(provided, snapshot) => (

                                        <Grid xs={3}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,

                                          )}
                                        // backgroundColor: "#e2e2e2",
                                        >
                                          {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: '0px !important' }}> */}

                                          {/* </IconButton>  */}

                                          {/* <button 
                                     data-my-value={item.id} 
                                    //  style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                    //  className={classes.dropDownStatus}
                                     onClick={(e:any)=>{
                                     let x=this.state.taskStatusesData.filter((d:any,i:any)=>{
                                       if(d.id==e.target.dataset.myValue){

                                       }else{
                                          return d
                                       }
                                     })
                                       this.setState({taskStatusesData: x})
                                     }} >
                                      <span className={classes.taskImgsStatus} >
                                      <img className={classes.taskImgStatus} src={deleteButton} /> </span>
                                       Delete Status
                                      </button> */}


                                          <div
                                            style={{
                                              width: "100%",
                                              paddingBottom: "5px",
                                              marginLeft: "0px",
                                              borderTopLeftRadius: "8px",
                                              borderTopRightRadius: "8px",
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}>
                                            <p style={{ width: "80%" }}><span style={{ marginLeft: "10px" }}>{item.attributes.label_name?.length ? item.attributes.label_name : item.attributes.status}</span></p>
                                            <div className={classes.doneCards}>
                                              {/* {mainItem.cardsData.length} */}
                                              {this.state.columnsKanban[item.attributes.status]?.tasks?.length}
                                            </div>
                                            <div>
                                              {!['1', "2", "3"].includes(item.id) && item.attributes.status != 'Done' && (this.state.permissionName == "superadmin" || this.state.permissions == 'editor' || (this.state.permissionName == "albertbauer" && this.state.permissions == 'brand_manager')) && <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: "15px" }}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(e: any) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    selectedStatus: item
                                                  }, () => console.log("selectedStatus", this.state.selectedStatus));
                                                  this.handleClickStatusMenu(e);
                                                }}
                                              />}
                                            </div>
                                          </div>
                                          <GridList className={classes.scrollbarSize} style={{ overflowY: "auto", overflowX: "hidden", height: "700px", alignItems: "strech", flexGrow: 1 }}>
                                            <div
                                            // backgroundColor: "#e2e2e2",
                                            >
                                              {index == 0 ?

                                                <Button variant="contained" className={classes.button} style={{
                                                  alignContent: "center", backgroundColor: 'white',
                                                  color: colorToBeChanged,
                                                  width: "262px", padding: 20, fontSize: 16, fontWeight: 'bold',
                                                  borderWidth: 1,
                                                  borderColor: colorToBeChanged,
                                                  borderRadius: 10,
                                                  borderStyle: 'dashed',
                                                  fontVariant: "initial",
                                                  textTransform: 'none',
                                                  marginTop: "20px",
                                                  marginBottom: "8px"
                                                }} color="primary" onClick={this.handleClickCreateNewTaskModel}>
                                                  + {t("ApprovalRequest.createNewTask")}
                                                </Button>
                                                : undefined}
                                              <Droppable droppableId={item.attributes.status}>
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.columnsKanban[item.attributes.status]?.tasks?.map((task: any, index: any) => (
                                                      <Draggable
                                                        isDragDisabled={item.attributes.status === "Unstarted"}
                                                        key={task.id}
                                                        draggableId={task.id}
                                                        index={index}>

                                                        {(provided, snapshot) => (

                                                          <div>
                                                            <div
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={provided.innerRef}
                                                            >

                                                              <Card
                                                                onClick={e => { this.handleClickOpenTaskModal(task) }}
                                                                className={classes.name} style={{ cursor: 'pointer' }}>
                                                                {/* {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager') &&
                                                                  <CardHeader
                                                                    action={
                                                                      <IconButton aria-label="settings" style={{ color: "black", marginRight: '10px' }}>
                                                                        <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                                                          aria-haspopup="true"
                                                                          onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            // this.handleClickMenu(e);
                                                                            this.setState({modelForDelete: e.currentTarget, deleteTaskId : item.id })
                                                                          }}
                                                                        />
                                                                      </IconButton>
                                                                    }
                                                                    style={{height: '20px'}}
                                                                  />
                                                                } */}
                                                                <CardContent>
                                                                  {this.state.notify?.task_ids?.includes(parseInt(task.id)) ? <div style={{
                                                                    height: 8,
                                                                    width: 8,
                                                                    backgroundColor: "red",
                                                                    borderRadius: "50%",
                                                                    marginTop: "5px",
                                                                    marginLeft: "5px"
                                                                  }}></div> : null}
                                                                  <div>

                                                                    <Typography className={classes.title} color="textSecondary" gutterBottom component="div">
                                                                      {/* <span> <img src={logo} alt="bmw" style={{ height: "30px", width: "40px" }} /></span>
                        <span>
                <p>{item.content}</p> 
                        </span> */}
                                                                      {/* <span><img src={logo} alt="bmw" style={{ height: "30px", width: "40px" }} />  {item.content} </span> */}
                                                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                                                        <span> <p style={{ fontSize: "10px", color: "black", width: "150px" }}>{task?.attributes?.order_number}</p></span>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                          {task?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url?.length > 0 ?
                                                                            <Avatar className={classes.listAvatar}
                                                                              style={{
                                                                                fontSize: "12px",
                                                                                backgroundColor: "#f5f6fa70",
                                                                                height: '20px', width: "20px",
                                                                                padding: 10,
                                                                                border: "1px solid #e4e8f0 ",
                                                                                //  marginTop: "5px" 
                                                                              }} src={task?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url}>

                                                                            </Avatar>
                                                                            :
                                                                            <Avatar className={classes.listAvatar} style={{ fontSize: "12px", backgroundColor: "#f5f6fa70", height: '20px', width: "20px", padding: 10, border: "1px solid #e4e8f0 ", marginTop: "5px" }} src={defaultUser}>

                                                                              {/* {task?.attributes?.assignee?.data?.attributes?.first_name.match(/\b(\w)/g).join('')} */}
                                                                            </Avatar>
                                                                          }

                                                                          {((localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'viewer' || localStorage.getItem("permissions") == 'brand_manager')) &&
                                                                            <IconButton aria-label="settings" style={{ color: "black" }}

                                                                              onClick={(e: any) => {

                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  !task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: task.attributes.briefing.data.id }) : null
                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: "Exists" }) : null
                                                                                e.stopPropagation();
                                                                                // this.handleClickMenu(e);
                                                                                localStorage.getItem("permissions") == task?.attributes?.requestor?.data?.attributes?.role?.data?.attributes?.permission ?
                                                                                  this.setState({ permissionFlagViewer: true }) : this.setState({ permissionFlagViewer: false })
                                                                                this.setState({
                                                                                  modelForDelete: e.currentTarget, deleteTaskId: task.id,
                                                                                  currentTask: task
                                                                                })
                                                                              }}
                                                                            >
                                                                              <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: '12px' }} aria-controls="simple-menu"
                                                                                aria-haspopup="true"
                                                                              />
                                                                            </IconButton>
                                                                          }

                                                                          {(localStorage.getItem("permissions") == task?.attributes?.requestor?.data?.attributes?.role?.data?.attributes?.permission) &&

                                                                            <IconButton aria-label="settings" style={{ color: "black" }}

                                                                              onClick={(e: any) => {
                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  !task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: task.attributes.briefing.data.id }) : null
                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: "Exists" }) : null
                                                                                e.stopPropagation();
                                                                                // this.handleClickMenu(e);
                                                                                this.setState({ modelForDelete: e.currentTarget, deleteTaskId: task.id, currentTask: task })
                                                                              }}
                                                                            >
                                                                              <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: '12px' }} aria-controls="simple-menu"
                                                                                aria-haspopup="true"

                                                                              />
                                                                            </IconButton>
                                                                          }
                                                                          {this.state.permissionName == 'superadmin' &&
                                                                            <IconButton aria-label="settings" style={{ color: "black" }}

                                                                              onClick={(e: any) => {
                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  !task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: task.attributes.briefing.data.id }) : null
                                                                                task.attributes.ultimate_state != "New" &&
                                                                                  task.attributes.template_created ? this.setState({ selectedTaskFromDraggable: "Exists" }) : null
                                                                                e.stopPropagation();
                                                                                // this.handleClickMenu(e);
                                                                                this.setState({ modelForDelete: e.currentTarget, deleteTaskId: task.id, currentTask: task })
                                                                              }}
                                                                            >
                                                                              <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: '12px' }} aria-controls="simple-menu"
                                                                                aria-haspopup="true"

                                                                              />
                                                                            </IconButton>
                                                                          }
                                                                          {/* <Avatar className={classes.listAvatar} style={{ fontSize: "12px", backgroundColor: "#f5f6fa70", height: '20px', width: "20px", padding: 10, border: "1px solid #e4e8f0 ", marginTop: "5px" }}>{localStorage.getItem('BrandNameIcon')}</Avatar> */}
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span style={{ color: "black", fontSize: "16px", marginBottom: "10px" }}>
                                                                          <strong style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                                                            {task?.attributes?.title} </strong>
                                                                        </span>
                                                                        {/* <span style={{ marginTop: "10px" }}>
                              <img src={userLogo} style={{ height: "63px", width: "63px" }} />

                            </span> */}
                                                                        <span style={{
                                                                          color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(224,255,255)", borderRadius: "20px", marginBottom: "5px", width: "max-content", padding: "4px 10px",
                                                                        }}>
                                                                          <strong style={{ maxWidth: "207px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                                                            {task?.attributes?.asset_category_name}</strong>
                                                                        </span>
                                                                        <span style={{
                                                                          color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(175,238,238)", borderRadius: "20px", width: "max-content", padding: "4px 10px",
                                                                        }}>
                                                                          <strong style={{ maxWidth: "207px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                                                            {task?.attributes?.asset_type_name} </strong>
                                                                        </span>
                                                                      </div>
                                                                    </Typography>
                                                                  </div>
                                                                  <div >

                                                                  </div>
                                                                </CardContent>


                                                                <CardActions style={{ backgroundColor: "#e4e8f0", padding: 12 }}>
                                                                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                                                    <div className={classes.circle} >
                                                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "16px" }} /></p>
                                                                    </div>
                                                                    {task?.attributes?.estimated_end_date && task?.attributes?.estimated_end_date !== "" ?
                                                                      <p style={{ fontSize: "9px", marginLeft: "5px" }} >{(task?.attributes?.estimated_end_date).split("-").reverse().join("-")} </p>
                                                                      :
                                                                      <p style={{ fontSize: "9px", marginLeft: "5px" }} >Add DueDate</p>
                                                                    }

                                                                    <div className={classes.circle} style={{ marginLeft: '14px' }}>
                                                                      <p><ChatOutlinedIcon style={{ fontSize: "16px" }} /></p>
                                                                    </div>
                                                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>{task?.attributes?.total_comments_count} </p>

                                                                    <div className={classes.circle} style={{ marginLeft: '14px' }}>
                                                                      <p><AttachFileOutlinedIcon style={{ fontSize: "16px" }} /></p>
                                                                    </div>
                                                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>{task?.attributes?.task_files_count} </p>
                                                                  </div>
                                                                </CardActions>


                                                              </Card>

                                                            </div>


                                                          </div>
                                                        )}
                                                      </Draggable>


                                                    ))}
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Droppable>

                                            </div>
                                          </GridList>

                                        </Grid>

                                      )}


                                    </Draggable>
                                  ))}

                                  {provided.placeholder}

                                  {(this.state.permissions === "editor" || (this.state.permissionName == 'albertbauer' && this.state.permissions === "brand_manager") || this.state.permissionName === "superadmin") ?
                                    (<Grid item xs={3}>
                                      <Button variant="contained" className={classes.button} style={{
                                        alignContent: "center", backgroundColor: 'white',
                                        color: colorToBeChanged, width: "300px", padding: 20, fontSize: 16, fontWeight: 'bold',
                                        borderWidth: 1,
                                        borderColor: colorToBeChanged,
                                        borderRadius: 10,
                                        borderStyle: 'dashed',
                                        fontVariant: "initial",
                                        textTransform: 'none',
                                        marginBottom: "50px"
                                      }} color="primary" onClick={this.handleClickCreateNewStatusModel}>
                                        + {t("ApprovalRequest.createNewStatus")}
                                      </Button>
                                    </Grid>
                                    ) : undefined}
                                </div>
                              </GridList>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                </div>}






              {this.state.listProjectPortfolio &&
                <div>
                  {/* {(this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
                    || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") ? (

                    <>

                      <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded expanded={this.state.draftsArrowFlag === true} onChange={(e) => this.setState(preState => ({ draftsArrowFlag: !preState.draftsArrowFlag }))}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.listHeader}><span>Drafts {' '} </span></Typography>
                          {this.state.draftsArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>
                            {this.state.setDraftQuote.length ? this.state.setDraftQuote.map((item: any, index: any) => {
                              return (
                                <div onClick={e => { this.handleClickOpenTaskModal(item) }} style={{ width: "99.5%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={4}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p>{item?.attributes?.project_name}</p>
                                          <p style={{ marginLeft: "30px" }}>{item?.attributes?.brand_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px", paddingLeft: "25px" }}>{item?.attributes?.title}</p>
                                        </div>
                                      </Grid>

                                      <Grid item xs={4} style={{ display: "flex" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          <div className={classes.circleList} >
                                            <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }} >{item?.attributes?.updated_at} </p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }}>
                                            <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_comments_count}</p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }} >
                                            <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>
                                          </div>
                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_files_count}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          {item?.attributes?.image_url != null ?
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"

                                                src={item.attributes?.image_url} />
                                            </AvatarGroup>
                                            :
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"
                                                src={defaultUser} />
                                            </AvatarGroup>
                                          }

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>


                                </div>
                              )
                            }) :
                              <p style={{ marginLeft: "15px", textAlign: "center" }}>No Drafts</p>}
                            <Button variant="contained" className={classes.button} style={{
                              alignContent: "center", backgroundColor: 'white',
                              color: '#419bf9', width: "100%", padding: 20, fontSize: 16, fontWeight: 'bold',
                              borderWidth: 1,
                              borderColor: '#419bf9',
                              borderRadius: 10,
                              borderStyle: 'dashed',
                              fontVariant: "initial",
                              textTransform: 'none',
                              marginBottom: "50px",
                              marginTop: "17px"
                            }} color="primary" onClick={this.handleClickCreateNewTaskModel}>
                              + {t("ApprovalRequest.createNewTask")}
                            </Button>
                          </Grid>



                        </AccordionDetails>

                      </Accordion>

                      <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded expanded={this.state.waitingArrowFlag === true} onChange={(e) => this.setState(preState => ({ waitingArrowFlag: !preState.waitingArrowFlag }))}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.listHeader}><span>Waiting for Quote</span> </Typography>
                          {this.state.waitingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>
                            {this.state.setPendingQuote.length ? this.state.setPendingQuote.map((item: any, index: any) => {
                              return (
                                <div onClick={e => { this.handleClickOpenTaskModal(item) }} style={{ width: "99.5%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={4}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p>{item?.attributes?.project_name}</p>
                                          <p style={{ marginLeft: "30px" }}>{item?.attributes?.brand_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px", paddingLeft: "25px" }}>{item?.attributes?.title}</p>
                                        </div>
                                      </Grid>

                                      <Grid item xs={4} style={{ display: "flex" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          <div className={classes.circleList} >
                                            <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }} >{item?.attributes?.updated_at} </p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }}>
                                            <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_comments_count}</p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }} >
                                            <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>
                                          </div>
                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_files_count}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          {item?.attributes?.image_url != null ?
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"

                                                src={item.attributes?.image_url} />
                                            </AvatarGroup>
                                            :
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"
                                                src={defaultUser} />
                                            </AvatarGroup>
                                          }

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              )
                            }) :
                              <p style={{ marginLeft: "15px", textAlign: "center" }}>No Waiting for Quote</p>}

                          </Grid>



                        </AccordionDetails>

                      </Accordion>

                      <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded expanded={this.state.onGoingArrowFlag === true} onChange={(e) => this.setState(preState => ({ onGoingArrowFlag: !preState.onGoingArrowFlag }))}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.listHeader}><span>Approved</span> </Typography>
                          {this.state.onGoingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>
                            {this.state.setApprovedQuote.length ? this.state.setApprovedQuote.map((item: any, index: any) => {
                              return (
                                <div onClick={e => { this.handleClickOpenTaskModal(item) }} style={{ width: "99.5%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={4}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p>{item?.attributes?.project_name}</p>
                                          <p style={{ marginLeft: "30px" }}>{item?.attributes?.brand_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px", paddingLeft: "25px" }}>{item?.attributes?.title}</p>
                                        </div>
                                      </Grid>

                                      <Grid item xs={4} style={{ display: "flex" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          <div className={classes.circleList} >
                                            <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }} >{item?.attributes?.updated_at} </p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }}>
                                            <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                          </div>

                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_comments_count}</p>
                                          <div className={classes.circleList} style={{ marginLeft: "12px" }} >
                                            <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>
                                          </div>
                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_files_count}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          {item?.attributes?.image_url != null ?
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"

                                                src={item.attributes?.image_url} />
                                            </AvatarGroup>
                                            :
                                            <AvatarGroup max={1}
                                            >
                                              <Avatar alt="Remy Sharp"
                                                src={defaultUser} />
                                            </AvatarGroup>
                                          }

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>


                                </div>
                              )
                            }) :
                              <p style={{ marginLeft: "15px", textAlign: "center" }}>No Approved Quote</p>}

                          </Grid>



                        </AccordionDetails>

                      </Accordion>


                    </> */}

                  {/* ) : ( */}

                  <div>
                    {this.state.taskStatusesData.map((item: any, i: any) => (

                      <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            let find1 = this.state.onGoingArrowFlag.find((d: any) => d.id === i)
                            if (find1) {
                              let filter1 = this.state.onGoingArrowFlag.filter((d: any) => d.id !== i)
                              this.setState({ onGoingArrowFlag: [...filter1] })
                            } else {
                              this.setState({ onGoingArrowFlag: [...this.state.onGoingArrowFlag, { id: i }] })
                            }
                          }}
                        >
                          <Typography className={classes.listHeader}><span>{item.attributes.status}</span>  <span> <Avatar style={{ backgroundColor: "#ffd4d4" }} className={classes.listAvatar}>{this.state.columnsKanban[item.attributes.status]?.tasks?.length}</Avatar></span></Typography>
                          {this.state.onGoingArrowFlag.find((d: any) => d.id === i) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingRight: '0px' }}>
                          <Grid item xs={12}>
                            {this.state.columnsKanban[item.attributes.status]?.tasks?.map((item: any, index: any) => (
                              <>
                                <div>
                                  {i === 0 && index == 0 ?
                                    <Button variant="contained" className={classes.button} style={{
                                      alignContent: "center", backgroundColor: 'white',
                                      color: colorToBeChanged, width: "100%", padding: 20, fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: colorToBeChanged,
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginTop: "17px"
                                    }} color="primary" onClick={this.handleClickCreateNewTaskModel}>
                                      + {t("ApprovalRequest.createNewTask")}
                                    </Button>
                                    : undefined}
                                </div>
                                <div onClick={e => { this.handleClickOpenTaskModal(item) }} style={{ width: "100%", borderRadius: "8px", borderLeft: `6px solid ${secondaryColorToBeChanged}`, borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px", cursor: 'pointer', height: '135px' }}>
                                  <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: this.state.notify?.task_ids?.includes(parseInt(item.id)) ? 'space-between' : 'flex-end', width: '100%' }}>
                                      {this.state.notify?.task_ids?.includes(parseInt(item.id)) ? <div style={{
                                        height: 8,
                                        width: 8,
                                        backgroundColor: "red",
                                        borderRadius: "50%",
                                        marginTop: "5px",
                                        marginLeft: "5px"
                                      }}></div> : null}
                                      {((localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager') && (item?.attributes?.ultimate_state == 'New' || item?.attributes?.ultimate_state == 'Pending Quote Approval' || item?.attributes?.ultimate_state == "Quote Requested")) ?
                                        <IconButton aria-label="settings" style={{ color: "black", right: '12px', top: '14px' }}
                                          onClick={(e: any) => {

                                            e.stopPropagation();
                                            // this.handleClickMenu(e);
                                            this.setState({ modelForDelete: e.currentTarget, deleteTaskId: item.id })
                                          }}

                                        >
                                          <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                            aria-haspopup="true"

                                          />
                                        </IconButton>
                                        :
                                        null
                                      }
                                      {this.state.permissionName === "superadmin" ?
                                        <IconButton aria-label="settings" style={{ color: "black", right: '12px', top: '14px' }}
                                          onClick={(e: any) => {

                                            e.stopPropagation();
                                            // this.handleClickMenu(e);
                                            this.setState({ modelForDelete: e.currentTarget, deleteTaskId: item.id })
                                          }}

                                        >
                                          <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                            aria-haspopup="true"

                                          />
                                        </IconButton>
                                        :
                                        null
                                      }
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', paddingLeft: '3%', height: '30px' }}>
                                      <p>{item?.attributes?.project_name}</p>
                                      <p style={{ marginLeft: "30px", background: '#F4F6FC 0% 0% no-repeat padding-box', padding: '6px', borderRadius: '8px' }}>{item?.attributes?.brand_name}</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', paddingLeft: '3%' }}>
                                      <p style={{ fontSize: "12px", width: "500px", wordBreak: "break-all" }}>{item?.attributes?.title}</p>
                                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', right: '25%' }}>
                                        <div className={classes.circleList} >
                                          <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                        </div>
                                        <p style={{ fontSize: "12px", marginLeft: "5px", width: '125px' }} >{item?.attributes?.updated_at} </p>
                                        <div className={classes.circleList} style={{ marginLeft: "12px" }}>
                                          <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                        </div>
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_comments_count}</p>
                                        <div className={classes.circleList} style={{ marginLeft: "12px" }} >
                                          <span> <AttachFileOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                        </div>
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_files_count}</p>

                                        {item?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url?.length > 0 ?
                                          <AvatarGroup max={1}
                                          >
                                            <Avatar alt="Remy Sharp"
                                              style={{ position: 'absolute', right: '-70%' }}
                                              // src={item.attributes?.first_account.data[0]?.attributes?.photo_blob_signed_id_url} 
                                              src={item?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url}
                                            />
                                          </AvatarGroup>
                                          :
                                          <AvatarGroup max={1}
                                          >
                                            <Avatar alt="Remy Sharp"
                                              style={{ position: 'absolute', right: '-70%' }}
                                              src={defaultUser} />
                                          </AvatarGroup>
                                          // null
                                        }

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  {/* )} */}
                </div>
              }
            </div>
          </>
          :
          <>
            <Grid container direction="row">
              <Grid item xs={9} />
              <Grid item xs={3}>
                <Button onClick={() => this.setState({ isSearch: false, taskData: [], searchValue: "" })} variant="text" style={{ left: "60px" }}>Back to Previous Screen</Button>
              </Grid>
            </Grid>
            {this.state.taskData.length !== 0 ?
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                          paddingLeft: "50px",
                        }}
                      >
                        Task Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Brand Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Project Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        End Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Updated At
                      </TableCell>


                    </TableRow>
                  </TableHead>

                  {this.state.taskData.map((_data: any) => {

                    return (
                      <>
                        <TableBody
                          style={{
                            background: "#E4E8F0",
                            cursor: 'pointer'
                          }}

                          onClick={e => this.handleClickOpenTaskModal(_data)}
                        >
                          <TableCell
                            style={{
                              padding: "35px",
                              border: "0px",

                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.title}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.brand_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.project_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.created_at}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.estimated_end_date}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.ultimate_state}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={
                                classes.tableData
                              }
                            >
                              {_data.attributes.updated_at}
                            </Typography>
                          </TableCell>



                        </TableBody>

                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              height: "25px",
                            }}
                          />
                        </Grid>
                      </>
                    )


                  })}







                </Table>
              </TableContainer>

              : <p>No Data Found</p>}

          </>


        }
        {/* Share Link  model */}

        <Dialog
          onClose={this.handleCloseModal1}
          aria-labelledby="customized-dialog-title"
          open={this.state.openLink}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px', color: secondaryColorToBeChanged }}>
            {/* {t("projects.newProjectTitle")} */}
            Shareable Link
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal1} />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* <p> {this.state.selectedAsset?.attributes?.file_blob_signed_id_url} </p> */}
            <textarea
              ref={(textarea) => this.textArea = textarea}
              value={this.state.shareableLink}
              style={{ height: "125px", width: "750px" }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="button"
              variant="contained"
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy to Clipboard
            </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseModal1}
            >
              {t("projects.cancel")}
            </Button>
          </DialogActions>

        </Dialog>


        {/* Briefing model part 2*/}
        {this.state.isCurrentTaskSelected && this.state.currentTask ? (

          <Dialog

            open={this.state.setOpen2}
            onClose={this.handleCloseTaskModal}
            id="taskviewPopup"
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ fontFamily: primaryFont }}

          >
            <div style={{ fontFamily: primaryFont }}>
              <div className={classes.TaskViewTitle}>
                <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
                <p style={{ fontSize: "14px" }}>Project: {this.state.currentTask?.attributes?.project_name}</p>
                <span>
                  <CloseIcon onClick={this.handleCloseTaskModal} />
                </span>
              </div>

              <Divider />
              <Backdrop
                className={classes.backdrop}
                open={this.state.loading}
              // onClick={() => this.setState({ loading: false })}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <DialogContent style={{ overflowY: "initial" }}>
                <DialogContentText id="alert-dialog-description">
                  <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                    <Grid item xs={8} style={{ backgroundColor: "#f5f6fa" }} >
                      <div style={{ backgroundColor: "#f5f6fa", paddingRight: '20px' }}>
                        <div style={{ margin: "20px", }}>
                          <p style={{ color: "#173e59", fontSize: "20px" }}>{this.state.currentTask?.attributes?.title}</p>
                          {/* <p style={{ fontSize: "14px", color: "#282828" }}>{this.state.currentTask?.attributes?.title}</p> */}

                          <Menu
                            id="long-menu"
                            anchorEl={this.state.setSourceFileAnchorEl}
                            keepMounted
                            open={Boolean(this.state.setSourceFileAnchorEl)}
                            onClose={this.closeSourceFileMenu}
                            PaperProps={{
                              style: {
                                // maxHeight: ITEM_HEIGHT * 4.5,
                                width: '',
                              },
                            }}
                          >

                            <MenuItem>
                              <GetAppIcon
                                style={{
                                  fontSize: "20px",
                                  marginRight: "10px",
                                  color: "black",
                                }}
                              />{" "}
                              <span onClick={() => { this.taskFileDownloadSourceFile(this.state.deleteFileId) }}>Download</span>
                            </MenuItem>
                            {(!(this.state.permissionName == "client" && this.state.currentTask.attributes.ultimate_state == "Done")) ? <> <MenuItem>
                              <RemoveCircleOutlineIcon
                                style={{
                                  fontSize: "20px",
                                  marginRight: "10px",
                                  color: "black",
                                }}
                              />{" "}
                              <span onClick={() => this.setState({ deleteSourceFileDialog: true })}>Delete</span>
                            </MenuItem>
                              <MenuItem>
                                <PublishOutlinedIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                    marginBottom: "-5px"
                                  }}
                                />
                                <span onClick={() => {
                                  this.setState({ replaceFlag: 1 }, () => {


                                    localStorage.setItem("task", "2")
                                    this.getMediaAssets()
                                    this.handleUploadOpenDialog()
                                  })

                                  // this.fileInput4.click()
                                }}>

                                  {/* <ReactS3Uploader
                                    type="file"
                                    id="upload"
                                    name="pic"
                                    getSignedUrl={this.removeImageAndGetSignedUrl}
                                    uploadRequestHeaders={{
                                      'x-amz-acl': 'public-read'
                                    }}
                                    s3path="/uploads/"
                                    contentDisposition="auto"
                                    accept="**"
                                    capture={true}
                                    inputRef={cmp => this.fileInput4 = cmp}
                                    onChange={(event: any) =>
                                      this.inputFileRmoveAndUpload(event)
                                    }
                                    style={{
                                      visibility: "hidden",
                                      position: "absolute",
                                      alignContent: "center", backgroundColor: 'white',
                                      color: '#419bf9',
                                      width: "280px", padding: 20,
                                      fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: '#419bf9',
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginBottom: "20px"
                                    }}
                                  /> */}
                                  <span>Replace</span></span>
                              </MenuItem></> : null}
                          </Menu>


                          <Dialog
                            open={this.state.deleteFinalFileDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
                              <span>
                                <CloseIcon onClick={() => { this.setState({ deleteFinalFileDialog: false, setAnchorEl: null }) }} />
                              </span>
                            </DialogTitle>
                            <Backdrop
                              className={classes.backdrop}
                              open={this.state.loading}
                            ></Backdrop>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <p style={{ fontSize: "18px" }}>
                                  {t("projects.deletePara")} <br />
                                </p>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ display: "unset" }}>
                              <Grid container>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => this.deleteTaskFile(this.state.deleteFileId)}

                                  >
                                    {t("projects.yes")}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => { this.setState({ deleteFinalFileDialog: false, setAnchorEl: null }) }}
                                  >
                                    {t("projects.close")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogActions>
                          </Dialog>

                          <Dialog
                            open={this.state.deleteFinalInvoiceDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
                              <span>
                                <CloseIcon onClick={() => { this.setState({ deleteFinalInvoiceDialog: false, menuflaginvoice: null }) }} />
                              </span>
                            </DialogTitle>
                            <Backdrop
                              className={classes.backdrop}
                              open={this.state.loading}
                            ></Backdrop>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <p style={{ fontSize: "18px" }}>
                                  {t("projects.deletePara")} <br />
                                </p>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ display: "unset" }}>
                              <Grid container>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => this.deleteTaskInvoice(this.state.invoiceId)}

                                  >
                                    {t("projects.yes")}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => { this.setState({ deleteFinalInvoiceDialog: false, menuflaginvoice: null }) }}
                                  >
                                    {t("projects.close")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogActions>
                          </Dialog>


                          <Dialog
                            open={this.state.deleteSourceFileDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
                              <span>
                                <CloseIcon onClick={() => { this.setState({ deleteSourceFileDialog: false, setSourceFileAnchorEl: false }) }} />
                              </span>
                            </DialogTitle>
                            <Backdrop
                              className={classes.backdrop}
                              open={this.state.loading}
                            ></Backdrop>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <p style={{ fontSize: "18px" }}>
                                  {t("projects.deletePara")} <br />
                                </p>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ display: "unset" }}>
                              <Grid container>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => this.deleteTaskFile(this.state.deleteFileId)}
                                  >
                                    {t("projects.yes")}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                                  <Button
                                    style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => { this.setState({ deleteSourceFileDialog: false, setSourceFileAnchorEl: false }) }}
                                  >
                                    {t("projects.close")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogActions>
                          </Dialog>




                          <Menu
                            id="long-menu"
                            anchorEl={this.state.setAnchorEl}
                            keepMounted
                            open={Boolean(this.state.setAnchorEl)}
                            // className= {classes.popUpMenu}
                            // style={{
                            //   zIndex :"1316 !important"
                            // }}
                            onClose={this.closeMenu}
                            PaperProps={{
                              style: {
                                // maxHeight: ITEM_HEIGHT * 4.5,
                                width: '',

                              },
                            }}
                          >

                            {((this.state.permissionName === "albertbauer" || this.state.permissionName === "client") && (this.state.permissions === "editor" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager") ||
                              this.state.permissionName === "superadmin") ? <>
                              {/* {(this.state.permissionName == 'client') ?
                                <MenuItem>
                                  <CloudDownloadIcon
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "10px",
                                      color: "black",
                                    }}
                                  />{" "}
                                  <span onClick={() => {
                                    // this.setState({ deleteFinalFileDialog: true })
                                    this.downloadloadLatestVersionImage()

                                  }}> Download new version</span>
                                </MenuItem> :
                                null

                              } */}
                              {!(this.state.permissionName == 'client') && <MenuItem>
                                <RemoveCircleOutlineIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "}
                                <span onClick={() => {
                                  this.setState({ deleteFinalFileDialog: true })

                                }}> Delete</span>
                              </MenuItem>}
                              {!(this.state.permissionName == 'client') && !this.state.uploadVersionShow?.id && <MenuItem>
                                <PublishOutlinedIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                    marginBottom: "-5px"
                                  }}
                                />
                                <span onClick={() => {
                                  localStorage.setItem("task", "1")
                                  //  this.fileInput2.click()
                                  this.setState({
                                    // uploadVersion : item?.id
                                  },
                                    () => {
                                      this.getMediaAssets()
                                      this.handleUploadOpenDialog()
                                    })

                                }}>

                                  {/* <ReactS3Uploader
                                  type="file"
                                  id="upload"
                                  name="pic"
                                  getSignedUrl={this.getSignedUrl}
                                  uploadRequestHeaders={{
                                    'x-amz-acl': 'public-read'
                                  }}
                                  s3path="/uploads/"
                                  contentDisposition="auto"
                                  accept="**"
                                  capture={true}
                                  inputRef={cmp => this.fileInput2 = cmp}
                                  // onChange={(event: any) =>
                                  //   this.inputFileChanged(event)
                                  // }
                                  style={{
                                    visibility: "hidden",
                                    position: "absolute",
                                    alignContent: "center", backgroundColor: 'white',
                                    color: '#419bf9',
                                    width: "280px", padding: 20,
                                    fontSize: 16, fontWeight: 'bold',
                                    borderWidth: 1,
                                    borderColor: '#419bf9',
                                    borderRadius: 10,
                                    borderStyle: 'dashed',
                                    fontVariant: "initial",
                                    textTransform: 'none',
                                    marginBottom: "20px"
                                  }}
                                /> */}
                                  <p>Upload New Version</p></span>
                              </MenuItem>}
                              <MenuItem>
                                <CloudDownloadIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "}
                                <span onClick={() => {
                                  this.download()

                                }}> Download </span>
                              </MenuItem>
                              <MenuItem>
                                {/* <CloudDownloadIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "} */}
                                <span onClick={() => {
                                  this.setState({
                                    openLink: true
                                  })

                                }}>  Get Shareable Link </span>
                              </MenuItem>
                            </> : <>
                              <MenuItem>
                                <CloudDownloadIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "}
                                <span onClick={() => {
                                  this.download()

                                }}> Download </span>
                              </MenuItem>
                              <MenuItem>
                                {/* <CloudDownloadIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "} */}
                                <span onClick={() => {
                                  this.setState({
                                    openLink: true
                                  })

                                }}>  Get Shareable Link </span>
                              </MenuItem>
                            </>}



                          </Menu>

                          {/* Popover for Invoices */}
                          <Menu
                            id="long-menu"
                            anchorEl={this.state.menuflaginvoice}
                            keepMounted
                            open={Boolean(this.state.menuflaginvoice)}
                            // className= {classes.popUpMenu}
                            // style={{
                            //   zIndex :"1316 !important"
                            // }}
                            onClose={this.closeMenuInvoice}
                            PaperProps={{
                              style: {
                                // maxHeight: ITEM_HEIGHT * 4.5,
                                width: '',

                              },
                            }}
                          >
                            {(this.state.permissionName == 'client' || this.state.permissionName == 'albertbauer' || this.state.permissionName == 'superadmin') ?
                              <MenuItem>
                                <CloudDownloadIcon
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "black",
                                  }}
                                />{" "}
                                <span onClick={() => {
                                  // this.setState({ deleteFinalFileDialog: true })
                                  this.downloadInvoiceFile()

                                }}> Download Invoice </span>
                              </MenuItem> :
                              null

                            }
                            {(this.state.permissionName != 'client') && <MenuItem>
                              <RemoveCircleOutlineIcon
                                style={{
                                  fontSize: "20px",
                                  marginRight: "10px",
                                  color: "black",
                                }}
                              />{" "}
                              <span onClick={() => {
                                this.setState({ deleteFinalInvoiceDialog: true })

                              }}> Delete</span>
                            </MenuItem>}

                          </Menu>

                          <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }} >Blockers</p></b>

                          {/* checkbox */}
                          <div> {this.state.blockersData?.length > 0 && this.state.blockersData.map((values: any, index: any) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox color="primary" name="is_completed"
                                    // onChange={this.handleChangeBlockerSelection(value,values)}
                                    checked={values.attributes?.is_completed}
                                    value={values.attributes?.is_completed}
                                    onChange={(e) => { this.handleChangeBlockerSelection(values, e.target.checked) }}
                                  />}
                                // className={values.attributes?.is_completed 
                                //   && 
                                //   classes.checkdecoration}

                                label={values.attributes?.description}
                              // label="Secondary"
                              />

                            </>
                          ))}


                          </div>
                          {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                            <div style={{ position: "relative" }}>
                              <span style={{ position: "absolute", top: "-4px", cursor: "pointer" }}><img onClick={this.handleClickCreateNewTaskBlockerModel} src={plusButton} /></span>
                              <span><p style={{ color: "#282828", fontSize: "14px", paddingLeft: "32px" }} >Blocker reason (e.g. task # or @user</p> </span>
                            </div>
                          ) : undefined

                          }

                          <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Subtasks</p></b>

                          <div>
                            {this.state.subTasksData?.length > 0 && this.state.subTasksData.map((values: any, index: any) => (
                              <>
                                <FormControlLabel
                                  control={<Checkbox color="primary" name="is_completed"
                                    // onChange={this.handleChangeBlockerSelection(value,values)}
                                    checked={values.attributes?.is_completed}
                                    value={values.attributes?.is_completed}
                                    onChange={(e) => { this.callUpdateSubTaskApi(values, e.target.checked) }}
                                  />}
                                  label={values.attributes?.title}
                                // label="Secondary"
                                />
                                {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") ||
                                  this.state.permissionName === "superadmin") && <IconButton aria-label="delete" onClick={() => this.handleDeleteSubTask(values.id)}>
                                    <DeleteIcon />
                                  </IconButton>}
                                <br />
                              </>
                            ))}

                          </div>

                          {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") ||
                            this.state.permissionName === "superadmin") ? (
                            <div style={{ position: "relative" }}>
                              <span style={{ position: "absolute", top: "-4px", cursor: "pointer" }}><img src={plusButton} onClick={this.handleClickCreateNewSubTakModel} /></span>
                              <span>
                                <p style={{ color: "#282828", fontSize: "14px", paddingLeft: "32px", marginBottom: "30px", }} >Add new subtask</p> </span>
                            </div>) : undefined}
                          <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Attachments</p></b>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                              if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                                return (
                                  <React.Fragment>
                                    <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                      {/* <a href={item.attributes.file_blob_signed_id_url} target="_blank"> */}
                                      <span
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type == 'pdf') {
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                          else {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true })
                                          }

                                        }}>
                                        <span>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px', cursor: "pointer" }}
                                              height="15"
                                              width="20"
                                              image={item?.attributes?.file_type == "video" ? imgFileTemplate : item.attributes.file_blob_signed_id_url && item.attributes.file_type !== "pdf" ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              title={item.attributes.name}
                                              className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                            />
                                          </Card>
                                        </span>
                                      </span>
                                      <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                        {item.attributes.name}
                                      </Typography>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            }
                            )}
                          </div>
                          <div style={{ height: "45px", width: '60px', border: `1px dotted ${colorToBeChanged}`, borderRadius: "8px", marginBottom: "33px", cursor: 'pointer' }}
                            onClick={() => {
                              localStorage.setItem("task", "0")
                              // this.fileInput1.click()

                              this.getMediaAssets()
                              this.handleUploadOpenDialog()
                            }}
                          >
                            {/* <ReactS3Uploader
                              type="file"
                              id="upload"
                              name="pic"
                              getSignedUrl={this.getSignedUrl}
                              uploadRequestHeaders={{
                                'x-amz-acl': 'public-read'
                              }}
                              s3path="/uploads/"
                              contentDisposition="auto"
                              accept="**"
                              capture={true}
                              inputRef={cmp => this.fileInput1 = cmp}
                              onChange={(event: any) =>
                                this.inputFileChanged(event)
                              }
                              style={{
                                visibility: "hidden",
                                position: "absolute",
                                alignContent: "center", backgroundColor: 'white',
                                color: '#419bf9',
                                width: "280px", padding: 20,
                                fontSize: 16, fontWeight: 'bold',
                                borderWidth: 1,
                                borderColor: '#419bf9',
                                borderRadius: 10,
                                borderStyle: 'dashed',
                                fontVariant: "initial",
                                textTransform: 'none',
                                marginBottom: "20px"
                              }}
                            /> */}
                            <p style={{ color: colorToBeChanged, marginLeft: "42%" }}>+</p>
                          </div>
                        </div>

                        {this.state.currentTaskBriefingData?.length > 0 &&
                          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>BRIEFING SUMMARY:</p></b>
                          </div>}

                        {this.state.currentTaskBriefingData?.length > 0 && this.state.currentTaskBriefingData.slice(0).map((briefedValues: any, index: any) => (
                          <div style={{ marginTop: "-10px", marginLeft: "20px" }}>
                            {briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == "file" && briefedValues.showField ?
                              (
                                <>
                                  <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                    <>
                                      {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                        briefedValues.attributes.briefed_value_files?.data?.length > 0 &&
                                        briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                          return (
                                            <Fragment>
                                              <p style={{ fontSize: '14px', marginTop: "0px" }}>{file.attributes.value}</p>
                                              {/* <a href={file.attributes.file_blob_signed_id_url} target="_blank" style={{ marginRight: "10px", marginBottom: "30px" }}> */}
                                              {/* <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}> */}
                                              <span onClick={(e) => { this.setState({ fileId: file?.id, file_blob_signed_id_url: file?.attributes?.file_blob_signed_id_url, fileImage: file?.attributes?.name, setOpenImageDialog: true }) }}>
                                                <Card style={{ width: '73px', }}>
                                                  <CardMedia
                                                    component="img"
                                                    alt="template"
                                                    style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                    height="15"
                                                    width="20"
                                                    image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                  />
                                                </Card>
                                              </span>
                                              {/* </a> */}
                                            </Fragment>
                                          )
                                        })
                                      }
                                    </>
                                  </div>
                                </>
                              )
                              :
                              (briefedValues.attributes.preview_visibility && (briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image") && briefedValues.showField) ?
                                (
                                  <>
                                    <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                      <>
                                        {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                          briefedValues.attributes.briefed_value_files?.data?.length > 0 &&
                                          briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                            return (
                                              <Fragment>
                                                <span onClick={(e) => { this.setState({ fileId: file?.id, file_blob_signed_id_url: file?.attributes?.file_blob_signed_id_url, fileImage: "Uploaded Image", setOpenImageDialog: true }) }}>
                                                  <Card style={{ width: '73px', marginRight: "10px", marginBottom: "30px" }}>
                                                    <CardMedia
                                                      component="img"
                                                      alt="template"
                                                      style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                      height="15"
                                                      width="20"
                                                      image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                      // title={item.attributes.name}
                                                      className={classes.templateHover}
                                                    />
                                                  </Card>
                                                </span>
                                              </Fragment>
                                            )
                                          })
                                        }
                                      </>
                                    </div>
                                  </>
                                )
                                :
                                (briefedValues.attributes.preview_visibility && (briefedValues.attributes.value_type == "short_text" ||
                                  briefedValues.attributes.value_type == "email" ||
                                  briefedValues.attributes.value_type == "multiple_selection" ||
                                  briefedValues.attributes.value_type == "single_selection" ||
                                  briefedValues.attributes.value_type == "drop_down_select" ||
                                  briefedValues.attributes.value_type == "slider" ||
                                  briefedValues.attributes.value_type == "comment_box"
                                ) && briefedValues.showField) ?
                                  (
                                    <>
                                      {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                        <Fragment>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                          <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                        </Fragment>
                                      }
                                    </>
                                  )
                                  :
                                  (briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == 'long_text' && briefedValues.showField && (briefedValues?.attributes?.value?.length > 8)) ?
                                    (
                                      <div className={classes.paragraphTemp1}>
                                        <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                        <p dangerouslySetInnerHTML={{ __html: briefedValues?.attributes?.value }}></p>
                                      </div>

                                    ) :
                                    (briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == "date_time" && briefedValues.showField) ?
                                      (
                                        <>
                                          {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                            <Fragment>
                                              <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                              <p style={{ fontSize: '14px', marginTop: "0px" }}>
                                                {this.handledateformat(briefedValues.attributes.value)}
                                              </p>
                                            </Fragment>
                                          }
                                        </>
                                      )
                                      :
                                      (briefedValues.attributes.preview_visibility && (briefedValues.attributes.value_type == "short_text" ||
                                        briefedValues.attributes.value_type == "email" ||
                                        briefedValues.attributes.value_type == "multiple_selection" ||
                                        briefedValues.attributes.value_type == "single_selection" ||
                                        briefedValues.attributes.value_type == "drop_down_select" ||
                                        briefedValues.attributes.value_type == "comment_box"
                                      ) && briefedValues.showField) ?
                                        (
                                          <>
                                            <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                            <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                          </>
                                        )
                                        :
                                        (briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == "page_separator" && briefedValues.showField) ?
                                          "" :
                                          (briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == "image_upload" && briefedValues.showField) ?
                                            <div>
                                              {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                                briefedValues.attributes.briefed_value_files?.data[0]?.attributes?.file_blob_signed_id_url?.length > 0 ?
                                                <img src={briefedValues.attributes.briefed_value_files?.data[0]?.attributes?.file_blob_signed_id_url}
                                                  alt="image"
                                                  style={{ maxWidth: "100%", maxHeight: "240px", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                                                : null
                                              }

                                            </div> :
                                            (briefedValues.attributes.preview_visibility && briefedValues.attributes.value_type == "paragraph" && briefedValues.showField) ?
                                              <div className={classes.paragraphTemp1}>
                                                <p dangerouslySetInnerHTML={{ __html: briefedValues?.attributes?.description }}></p>
                                              </div>
                                              :
                                              null
                            }
                          </div>
                        ))}

                        {/* <div style={{ marginTop: "40px", marginLeft: "20px" }}>
                      <p style={{ fontSize: "16px", marginBottom: "0px" }}>Do you have any other instructions?</p>
                      <p style={{ fontSize: '14px', marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue elit ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue eli et libero.)
          </p>
                    </div> */}
                        <Divider style={{ backgroundColor: "#737373" }} />

                        <Accordion className={classes.accordianStyle} style={{ background: "#f5f6fa" }} defaultExpanded>
                          <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => (this.setState({ onGoingArrowFlag: !this.state.onGoingArrowFlag }))}
                          >
                            <Typography
                              className={classes.heading}
                            >
                              Activity
                            </Typography>
                            {this.state.onGoingArrowFlag === true ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: "9px 0px 16px", background: "#f5f6fa" }}>
                            <Grid item xs={12}>

                              {/* <Paper component="form" className={classes.root} style={{height:'200px',border:'1px solid black',margin:15}} > */}


                              <Editor
                                editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                placeholder={this.state.placeholder}
                                onFocus={() => this.setState({ placeholder: '' })}
                                onBlur={() => this.setState({ placeholder: 'Add Comment' })}
                                //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                                wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                                editorStyle={{ padding: 10, minHeight: 100, background: "#ffffff", }}
                                toolbarStyle={{ height: 50, fontSize: 18, marginBottom: "0px", background: "#f4f6fc" }}
                                onEditorStateChange={this.onEditorStateChange}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: this.state.selectedProjectsMentionsAccountsData2.length > 0 ? this.state.selectedProjectsMentionsAccountsData2 : "No Suggestion"
                                  //  [
                                  //   { text: 'APPLE', value: 'apple', url: 'apple' },
                                  //   { text: 'BANANA', value: 'banana', url: 'banana' },
                                  //   { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                  //   { text: 'DURIAN', value: 'durian', url: 'durian' },
                                  //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                  //   { text: 'FIG', value: 'fig', url: 'fig' },
                                  //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                  //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                  // ],
                                }}
                                hashtag={{}}

                                toolbarCustomButtons={[
                                  <Button
                                    size="small"
                                    onClick={(e) => {
                                      this.state.convertedCommentData?.blocks?.length > 0 ?
                                        this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                    }}
                                    style={{ color: 'white', backgroundColor: colorToBeChanged, position: 'absolute', right: '70px', padding: "5px 15px 5px 15px" }}
                                    // startIcon={<SendIcon />}
                                    type="submit"
                                    endIcon={<SendIcon />}
                                  >
                                    Send
                                  </Button>
                                  // <button
                                  //   style={{
                                  //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "293px",
                                  //   }}
                                  //   type="submit"

                                  //   onClick={(e) => {
                                  //     this.state.convertedCommentData?.blocks?.length > 0 ?
                                  //       this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                  //   }}
                                  // >
                                  //   <SendIcon />

                                  // </button>

                                ]}
                                toolbar={{
                                  options: ["inline", "link"],
                                  inline: {
                                    inDropdown: false,
                                    className: "test",
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ["bold", "italic"],
                                    // bold: { className: "test", style: { height:80 } },
                                    // italic: { className: undefined },
                                  },
                                  list: {
                                    inDropdown: false,
                                    className: "test",
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    // options: ['unordered', 'ordered'],
                                    unordered: { className: undefined },
                                    ordered: { className: undefined },
                                    // indent: { icon: indent, className: undefined },
                                    // outdent: { icon: outdent, className: undefined },
                                  },
                                  link: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    dropdownClassName: undefined,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link'],
                                    //  link: { icon: link, className: undefined },
                                    //  unlink: { icon: unlink, className: undefined },
                                    linkCallback: undefined
                                  },
                                  // emoji: {
                                  //   className: undefined,
                                  //   component: undefined,
                                  //   popupClassName: undefined,
                                  //   emojis: [
                                  //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                  //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                                  //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                  //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                  //     '✅', '❎', '',
                                  //   ],
                                  // },

                                  // image: {
                                  //   //icon:,
                                  //   urlEnabled: false,
                                  //   uploadEnabled: true,
                                  //   uploadCallback: this.getSignedUrlTaskFileComment,
                                  //   previewImage: true,
                                  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                  // },

                                }}
                              />

                              {/* <div style={{ textAlign: "right" }}>
                                {this.state.showActivity ? 
                                  <span onClick={this.changeActivityViewState}>
                                    <b><Typography className={classes.showActivityOrCommentButton} >Show Comments</Typography></b>
                                  </span>
                                :
                                  <span onClick={this.changeActivityViewState}>
                                    <b><Typography className={classes.showActivityOrCommentButton} >Show Activity Log</Typography></b>
                                  </span>
                                }
                              </div> */}

                              {!this.state.showActivity ?
                                <Fragment>
                                  {this.state.taskActivityData?.map((item: any) => {
                                    if (item?.attributes?.comment) {
                                      return (<>
                                        <div>
                                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                            <Avatar src={item?.attributes?.task_comment?.data?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                            <div style={{ marginLeft: "15px" }}>
                                              <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>  {item?.attributes?.account?.data?.attributes?.is_deleted ? "Deleted User" : `${item.attributes?.account?.data?.attributes?.first_name}`} </p>
                                              <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.updated_at}</p>
                                            </div>
                                          </div>

                                          {item?.attributes?.task_comment?.data?.attributes?.file_blob_signed_id_url !== null && (
                                            <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                              <span onClick={(e) => { this.setState({ fileId: item?.attributes?.task_comment?.data?.id, file_blob_signed_id_url: item?.attributes?.task_comment?.data?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.task_comment?.data?.attributes?.name }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}>
                                                <Card style={{ width: '73px' }}>
                                                  <CardMedia
                                                    component="img"
                                                    alt="template"
                                                    style={{ width: 25, height: 40, padding: '15px 24px' }}
                                                    height="15"
                                                    width="20"
                                                    image={imgFileTemplate}
                                                    title={item.attributes.name}
                                                    className={(this.state.fileTaskName?.id == item?.attributes?.task_comment?.data?.id) ? classes.templateHover : "white"}
                                                  />
                                                </Card>
                                              </span>
                                              <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                                {item.attributes.task_comment.data.attributes.name}
                                              </Typography>
                                            </Grid>
                                          )}
                                          <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                            <p style={{ margin: "0px" }} dangerouslySetInnerHTML={{ __html: item.attributes.task_comment.data?.attributes?.description }} />
                                            {localStorage.getItem("userId") == item.attributes.task_comment.data.attributes?.account_id ? <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }} onClick={() => { this.openSpecificTaskComment(item.attributes.task_comment.data.id) }}>Edit</p> : null}
                                          </div>
                                        </div>
                                      </>
                                      )
                                    } else {
                                      return (

                                        <ListItem
                                          alignItems="flex-start"
                                          style={{
                                            cursor: 'pointer'
                                          }}
                                        >
                                          <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={
                                              <Typography variant="body2" style={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: '#282828',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: '0.1px',
                                                marginBottom: '10px'
                                              }}>
                                                {item?.attributes?.account?.data?.attributes?.is_deleted ? "Deleted User" : `${item?.attributes?.account?.data?.attributes?.first_name}  ${item?.attributes?.account?.data?.attributes?.last_name}`}
                                              </Typography>
                                            }
                                            secondary={
                                              <React.Fragment>
                                                <Typography variant="body2" style={{
                                                  fontSize: '14px',
                                                  color: '#282828',
                                                  fontStretch: 'normal',
                                                  fontStyle: 'normal',
                                                  lineHeight: 'normal',
                                                  letterSpacing: '0.1px',
                                                  margin: '10px 0px'
                                                }}>
                                                  <p style={{ margin: "0px", fontFamily: primaryFont }} dangerouslySetInnerHTML={{ __html: item?.attributes?.description }} />
                                                </Typography>
                                                {item.attributes.updated_at}
                                              </React.Fragment>
                                            }
                                          />
                                        </ListItem>

                                      )
                                    }
                                  })}
                                  {this.state.totalActivityPageCount > 1 && this.state.totalActivityPageCount > this.state.currentPage &&
                                    <Button variant="contained" className={classes.button} style={{
                                      alignContent: "center", backgroundColor: 'white',
                                      color: '#419bf9', width: "100%", padding: 5, fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: '#419bf9',
                                      borderRadius: 10,
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginLeft: "15px"
                                    }} color="primary" onClick={this.getTaskActivityListPerPageApi}>
                                      View more
                                    </Button>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {/* {this.state.getTaskCommentsData.map((item: any) => {
                                return (<>
                                  <div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                      <Avatar src={item.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                      <div style={{ marginLeft: "15px" }}>
                                        <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>{item.attributes?.account?.data?.attributes?.first_name}</p>
                                        <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.updated_at}</p>
                                      </div>
                                    </div>

                                    {item.attributes?.file_blob_signed_id_url !== null && (
                                      <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                        <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 25, height: 40, padding: '15px 24px' }}
                                              height="15"
                                              width="20"
                                              image={imgFileTemplate}
                                              title={item.attributes.name}
                                              className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                            />
                                          </Card>
                                        </span>
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid>
                                    )}
                                    <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                      <p style={{ margin: "0px" }} dangerouslySetInnerHTML={{ __html: item.attributes?.description }} />
                                     { localStorage.getItem("userId") == item.attributes?.account_id ? <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }} onClick={() => { this.openSpecificTaskComment(item.id) }}>Edit</p> : null }
                                    </div>
                                  </div>
                                </>
                                )
                              })} */}
                                </Fragment>
                              }

                            </Grid>
                          </AccordionDetails>
                        </Accordion>


                      </div>

                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ marginLeft: "20px", marginTop: "20px" }}>

                        {(this.state.currentTask.attributes?.quotation?.data?.attributes?.price && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" &&
                          this.state.currentTask.attributes?.quotation?.data?.attributes?.status === "Pending Quote Approval" &&
                          ((this.state.permissions === "approver" && this.state.permissionName === "client") || (this.state.permissions === "brand_manager" && this.state.permissionName === "client"))
                          && (localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined)
                          ? (
                            <>
                              <p style={{ fontSize: "16px" }}></p>
                              <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                              <Button

                                style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                                type="submit"
                                variant="contained"
                                fullWidth
                                // onClick={(e) => {
                                //   this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                                // }}
                                onClick={(e) => {

                                  (window.location.href = `/Home/ProjectList?projectId=${this.state.currentTask?.attributes?.project_id}&brandId=${this.state.currentTask?.attributes?.brand_id}&taskId=${this.state.currentTask.id}&approveQuote=1`)
                                }}
                              >
                                Waiting For Approval
                                {/* {t("projects.selfApprove")} */}
                              </Button>
                            </>
                          ) : (this.state.currentTask.attributes?.quotation?.data?.attributes?.price && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" &&
                            this.state.currentTask.attributes?.quotation?.data?.attributes?.status === "Pending Quote Approval")
                            // && this.state.brandDetailData?.brand_group_id == null
                            && (localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined)
                            ? (
                              <>
                                <Button
                                  style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px", boxShadow: "none" }}
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                >
                                  Pending Quote Approval

                                </Button>
                              </>
                            ) : (this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions === "approver" || this.state.permissionName === "client")
                              //  && 
                              // this.state.brandDetailData?.brand_group_id == null 
                              && (localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined)
                              ? (
                                <>
                                  <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                  <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                                  <Button

                                    style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={(e) => {
                                      var currentTask = this.state.currentTask;
                                      currentTask.attributes.quotation.data.attributes.status = "Quote Approved"
                                      this.setState({
                                        currentTask: currentTask
                                      })
                                      this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                                    }}
                                  >
                                    {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" ? (
                                      <span>{t("projects.selfApprove")}</span>
                                    ) : (
                                      <span>Self Approve</span>
                                    )}
                                  </Button>
                                </>
                              ) : undefined))}

                        {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" &&
                          this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Pending Quote Approval" &&
                          (this.state.permissionName === "superadmin" || (this.state.permissionName === "albertbauer" && this.state.permissions === "brand_manager") || this.state.permissionName === "client" || this.state.permissions === "viewer" || this.state.permissions === "editor")
                          //  && this.state.brandDetailData?.brand_group_id == null 
                          && (localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined)
                          ? (
                            <div>
                              {(this.state.currentTask.attributes?.quote_requested === false) ? (
                                <>
                                  {this.state.currentTask.attributes.price_auto_generated !== null && (
                                    <>
                                      <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                      <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.price_auto_generated}</p>
                                    </>
                                  )}
                                  {this.state.currentTask?.attributes?.briefing?.data?.id &&
                                    <Button
                                      style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px", boxShadow: "none" }}
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.attributes?.briefing?.data?.id) }}
                                    >
                                      {t("projects.requestQuote")}
                                    </Button>
                                  }
                                  {/* <Divider style={{ marginBottom: "20px" }} /> */}
                                </>
                              ) : (
                                <>
                                  {this.state.currentTask.attributes.price_auto_generated !== null &&
                                    // this.state.brandDetailData?.brand_group_id == null &&
                                    (localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined) &&
                                    (
                                      <>
                                        <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                        <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                                      </>
                                    )}
                                  {/* <Button
                                disabled
                                style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.id) }}
                              >
                                Quote Requested
                              </Button> */}
                                  {/* <Divider style={{ marginBottom: "20px" }} /> */}
                                </>
                              )


                              }

                            </div>
                          ) : (
                            <>
                            </>
                            // <div>
                            //   {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                            //     <>
                            //       <p>Approved quote - €{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>

                            //       <Divider style={{ marginBottom: "20px" }} />
                            //     </>
                            //   ) : undefined}
                            // </div>


                          )}
                        {this.state.currentTask.attributes?.ultimate_state === "New" && localStorage.getItem("brandGroupSelectedName") != null &&

                          <Button
                            style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px", boxShadow: "none" }}
                            onClick={() => {
                              this.setState({ submitProduction: true })
                              this.submitProductionReady()
                            }}
                            type="button"
                            variant="contained"
                          > Submit to Production Ready
                            {/* {t("projects.saveChanges")} */}
                          </Button>

                        }
                        {this.state.currentTask.attributes?.ultimate_state === "New" && this.state.currentTask.attributes?.briefing?.data &&
                          <Button
                            style={{
                              backgroundColor: "white",
                              color: colorToBeChanged,
                              marginBottom: "20px",
                              border: `1px solid ${colorToBeChanged}`,
                              boxShadow: "none"
                            }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              this.handleClickEditBriefing(this.state.currentTask.attributes.briefing);
                            }}
                          >
                            Edit Briefing
                          </Button>
                        }
                        <Divider style={{ marginBottom: "20px" }} />

                        {this.state.currentTask.attributes.ultimate_state == 'Quote Requested' &&
                          <Button
                            style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              if (this.state.permissionName === "superadmin" || (this.state.permissionName === "albertbauer" && this.state.permissions === "brand_manager") ||
                                (this.state.permissionName === "albertbauer" && this.state.permissions === "editor")) {
                                (window.location.href = `/Home/ProjectList?projectId=${this.state.currentTask?.attributes?.project_id}&brandId=${this.state.currentTask?.attributes?.brand_id}&taskId=${this.state.currentTask.id}&sendQuote=1`)
                              }
                            }}
                          >
                            {this.state.currentTask.attributes.ultimate_state}
                          </Button>
                        }

                        <TextField
                          id="Category Selected"
                          disabled
                          name="taskType"
                          select
                          size="small"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          fullWidth
                          // label={t("TaskView.taskType")}
                          label="Asset Catgeory"
                          // defaultValue={this.state.category}
                          value={this.state.currentTask?.attributes?.asset_category_name}
                          className={classes.selectInput}
                          // onChange={handleChange}
                          SelectProps={{
                            native: true,
                          }}

                          variant="outlined"
                          style={{ marginTop: '10px' }}
                        >
                          {/* {currencies.map((option) => (
                           <option key={option.value} value={option.value}>
                           {option.label}
                           </option>
                           ))} */}
                          {/* <option aria-label="None" value="" /> */}

                          <option>{this.state.currentTask?.attributes?.asset_category_name}</option>

                        </TextField>

                        <TextField
                          id="taskType"
                          disabled
                          name="taskType"
                          select
                          size="small"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          fullWidth
                          // label={t("TaskView.taskType")}
                          label="Asset Type"
                          value={this.state.currentTask?.attributes?.asset_type_name}
                          className={classes.selectInput}
                          // onChange={handleChange}
                          SelectProps={{
                            native: true,
                          }}

                          variant="outlined"
                          style={{ marginTop: '10px' }}
                        >
                          {/* {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))} */}
                          {/* <option aria-label="None" value="" /> */}
                          <option>{this.state.currentTask?.attributes?.asset_type_name}</option>

                        </TextField>
                        {(this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissionName == "client") ? (
                          <TextField
                            name="status"
                            disabled
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            select
                            // onChange={(e) => {
                            //   //
                            //   var action: string = ""
                            //   this.state.taskStatusesData.map((item: any) => {
                            //     if (item.attributes.status === e.target.value) {
                            //       action = item.attributes.action
                            //       //console.log("action", action)


                            //       this.callStateTransitionsAPI(action, this.state.currentTask.id)

                            //     }
                            //   })
                            // }}
                            onChange={(e) => {
                              //
                              var action: string = ""
                              this.state.taskStatusesData.map((item: any) => {
                                if (item.attributes.label_name) {
                                  if (item.attributes.label_name === e.target.value) {
                                    action = item.attributes.action
                                    //

                                    this.setState({ taskStatus: e.target.value })
                                    this.callStateTransitionsAPI(action, this.state.currentTask.id, item.attributes.label_name)

                                  }
                                }
                                else {
                                  if (item.attributes.status === e.target.value) {
                                    action = item.attributes.action
                                    //

                                    this.setState({ taskStatus: e.target.value })
                                    this.callStateTransitionsAPI(action, this.state.currentTask.id, item.attributes.status)

                                  }
                                }

                              })

                            }}
                            size="small"
                            fullWidth
                            label={t("TaskView.status")}
                            // value={this.state.currentTask?.attributes?.ultimate_state}
                            value={this.state.taskStatus?.length > 0 ? this.state.taskStatus : null}
                            // value=""
                            className={classes.selectInput}
                            variant="outlined"
                          > <MenuItem aria-label="None" value="started" />
                            <MenuItem key="New" value="New"> New</MenuItem>
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem key="Quote Requested" value="Quote Requested"> Quote Requested</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>}
                            {this.state.taskStatusesData?.map((option: any) => {
                              if (option.attributes.status !== "Draft" && option.attributes.status !== "Quote Pending" && option.attributes.status !== "Approved") {
                                return (<MenuItem key={option.attributes.status} value={option.attributes?.status} > {option?.attributes?.label_name?.length ? option.attributes?.label_name : option.attributes?.status} </MenuItem>)
                              }
                            })}


                          </TextField>

                        ) : (
                          <TextField
                            name="status"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            select
                            // value={this.state.taskStatus}
                            onChange={(e) => {
                              //
                              var action: string = ""
                              this.state.taskStatusesData.map((item: any) => {
                                if (item.attributes.status) {
                                  if (item.attributes.status === e.target.value) {
                                    action = item.attributes.action
                                    //

                                    this.setState({ taskStatus: e.target.value })
                                    this.callStateTransitionsAPI(action, this.state.currentTask.id, item.attributes.label_name)

                                  }
                                }
                                else {
                                  if (item.attributes.status === e.target.value) {
                                    action = item.attributes.action
                                    //

                                    this.setState({ taskStatus: e.target.value })
                                    this.callStateTransitionsAPI(action, this.state.currentTask.id, item.attributes.status)

                                  }
                                }

                              })

                            }}
                            size="small"
                            fullWidth
                            label={t("TaskView.status")}
                            value={this.state.taskStatus?.length > 0 ? this.state.taskStatus : null}
                            className={classes.selectInput}
                            variant="outlined"
                            disabled={this.state.currentTask.attributes.ultimate_state == "New" ||
                              this.state.currentTask.attributes.ultimate_state == "Quote Requested" ||
                              this.state.currentTask.attributes.ultimate_state == "Briefing Completed" ||
                              this.state.currentTask.attributes.ultimate_state == "Pending Quote Approval" ? true : false}


                          > <MenuItem disabled aria-label="None" value="started" />
                            <MenuItem disabled key="New" value="New"> New</MenuItem>
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem disabled key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem disabled key="Quote Requested" value="Quote Requested"> Quote Requested</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem disabled key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>}
                            {localStorage.getItem("brandGroupSelectedName") != "Hugo Boss" && <MenuItem disabled key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>}
                            {/* <MenuItem key="Open" value="Open">Open</MenuItem> */}
                            {this.state.taskStatusesData?.map((option: any) => {
                              if (option.attributes.status !== "Draft" && option.attributes.status !== "Quote Pending" && option.attributes.status !== "Quote Approved") {
                                return (<MenuItem key={option.attributes.status} value={option.attributes?.status} > {option.attributes?.label_name?.length ? option.attributes?.label_name : option.attributes?.status} </MenuItem>)
                              }
                            })}


                          </TextField>

                        )
                        }
                        {!(this.state.permissionName == "albertbauer" || this.state.permissionName == "superadmin")
                          && (this.state.currentTask.attributes.ultimate_state !== "New" ||
                            this.state.currentTask.attributes.ultimate_state !== "Briefing Completed") ?
                          <TextField
                            id="date"
                            name="Due Date"
                            label="Due Date"
                            type="text"
                            disabled
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            //defaultValue={Date.now()}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            size="small"
                            fullWidth
                            // label={t("TaskView.status")}
                            // label="Due Date"

                            variant="outlined"
                            className={classes.selectInput}
                            SelectProps={{
                              native: true,
                            }}
                            value={this.state.currentTask.attributes.estimated_end_date}
                          // onChange={(e) => {
                          //   this.handleDateChange(e);
                          // }}
                          />
                          :
                          <TextField
                            // focused
                            id="date"
                            name="Due Date"
                            type="date"

                            InputProps={{ classes: { root: classes.inputRoot }, inputProps: { min: dueDate } }}

                            //defaultValue={Date.now()}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              },
                              shrink: true
                            }}
                            size="small"
                            fullWidth

                            label="Due Date"
                            variant="outlined"
                            className={classes.selectInput}
                            SelectProps={{
                              native: true,
                            }}
                            value={this.state.currentTask.attributes.estimated_end_date}
                            onChange={(e) => {
                              this.handleDateChange(e);
                            }}
                          />
                        }
                        <TextField
                          id="Requestor"
                          name="Requestor"
                          disabled
                          size="small"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          fullWidth
                          label="Requestor"
                          value={this.state.currentTask?.attributes?.requestor?.data?.attributes?.is_deleted ? "Deleted User" : this.state.currentTask?.attributes?.requestor?.data?.attributes?.first_name}
                          className={classes.selectInput}
                          // onChange={handleChange}
                          SelectProps={{
                            native: true,
                          }}

                          variant="outlined"
                        ></TextField>
                        {/* <Autocomplete
                               id="disabled"
                               disabled
                               className={classes.selectInput}
                              options={this.state.accountsData}
                              getOptionLabel={(option:any) => option.attributes.first_name}
                              onChange={(e,value)=>{
                                
                                var currentTask = this.state.currentTask
                                currentTask.attributes.requestor.data = value
                                this.setState({
                                  currentTask:currentTask,
                                  // sharedProjectError:value.length>0?false:true
                                  
                                },()=>{
                                  
                                })
                              }}
                              style={{ width: 280 }}
                              renderInput={(params) => <TextField {...params} label="Requestor" variant="outlined" />}
                            /> */}

                        {/* {this.state.permissions === 'editor' ? */}

                        {this.state.permissionName === "albertbauer" ?
                          <div style={{ display: "flex" }}>
                            <TextField
                              id="Reviewer"
                              name="Reviewer"
                              // type="text"
                              disabled
                              size="small"
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                                // shrink: true
                              }}
                              fullWidth
                              label="Reviewer"
                              value={this.state.currentTask?.attributes?.reviewer?.data?.attributes?.is_deleted ? 'Deleted User' : this.state.taskReviewerName}
                              className={classes.selectInput}
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            ></TextField>
                            <span
                              onClick={() => { this.setState({ userInfoPopup2: true }) }}
                              style={{ marginTop: "5%", height: "100%", cursor: "pointer" }}
                            >
                              <PermIdentitySharpIcon />
                            </span>
                          </div>
                          :
                          <Autocomplete
                            id="fixed-tags-demo"
                            className={classes.selectInput}
                            options={this.state.accountsData}
                            getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                            inputValue={this.state.currentTask?.attributes?.reviewer?.data?.attributes?.is_deleted ? 'Deleted User' : this.state.taskReviewerName}
                            onInputChange={(event, newInputValue) => {
                              this.setState({
                                taskReviewerName: newInputValue
                              })
                            }}
                            value={this.state.currentTask?.attributes?.reviewer?.data || ""}
                            onChange={(e, value) => {
                              this.handleReviewerChange(e, value);
                            }}
                            style={{ width: 280 }}
                            renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                          />
                        }








                        {this.state.permissionName == "client" ?
                          <div style={{ display: "flex" }}>
                            <TextField
                              id="Assignee"
                              name="Assignee"
                              disabled
                              size="small"
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                                // shrink: true
                              }}
                              fullWidth
                              label="Assignee"
                              value={this.state.currentTask?.attributes?.assignee?.data?.attributes?.is_deleted ? 'Deleted User' : this.state.taskAssigneeName}
                              className={classes.selectInput}
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            ></TextField>
                            <span
                              onClick={() => { this.setState({ userInfoPopup: true }) }}
                              style={{ marginTop: "5%", height: "100%", cursor: "pointer" }}
                            >
                              <PermIdentitySharpIcon />
                            </span>
                          </div>
                          :
                          <Autocomplete
                            id="fixed-tags-demo"
                            className={classes.selectInput}
                            options={this.state.assigneeAccountsData}
                            getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                            inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.is_deleted ? 'Deleted User' : this.state.taskAssigneeName}
                            onInputChange={(event, newInputValue) => {
                              this.setState({
                                taskAssigneeName: newInputValue
                              })
                            }}
                            value={this.state.currentTask?.attributes?.assignee?.data || ""}
                            onChange={(e, value) => {
                              this.handleAssigneeChange(e, value);
                            }}
                            style={{ width: 280 }}
                            renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                          />
                        }


                        <div style={{ display: "flex" }}>
                          <TextField
                            id="Assignee"
                            name="job_number"
                            disabled={!((this.state.permissionName == "albertbauer" && this.state.permissions == 'editor') || this.state.permissionName == 'superadmin')}
                            // size="small"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}

                            fullWidth
                            label="Job Number"
                            value={this.state.job_number}
                            onChange={this.handleJobNumberChange}

                            variant="outlined"
                          ></TextField>
                        </div>

                        <div style={{ display: "flex", marginTop: "5%" }}>
                          <TextField
                            id="Assignee"
                            name="sixomc_number"
                            disabled={!((this.state.permissionName == "albertbauer" && this.state.permissions == 'editor') || this.state.permissionName == 'superadmin')}
                            // size="small"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}

                            fullWidth
                            label="Sixomc Number"
                            value={this.state.sixomc_number}
                            onChange={this.handleSixomcNumberChange}

                            variant="outlined"
                          ></TextField>
                        </div>





                        {/* : undefined} */}
                        <p style={{ fontSize: "16px", height: '0px' }}>Task Progress</p>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className={classes.progress}>
                            <span> <p style={{ fontSize: "25px" }}>{this.state.currentTask.attributes.progress} %</p> </span>
                            <span style={{ marginTop: "8%", marginLeft: "10px" }}>   <p style={{ fontSize: "14px" }}>completed</p></span>
                          </div>
                          <div>
                            {this.state.percentage &&
                              <Button
                                style={{
                                  backgroundColor: "white",
                                  color: colorToBeChanged,
                                  marginTop: "25px",
                                  border: `1px solid ${colorToBeChanged}`,
                                  boxShadow: "none"
                                }}
                                disabled={this.state.percentage ? false : true}
                                variant="contained"
                                onClick={() => { this.updateTaskProgress() }}
                              >
                                Save
                              </Button>
                            }
                          </div>
                        </div>
                        {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager") || this.state.permissionName == 'superadmin') ? (
                          <div style={{ marginBottom: "10px", marginTop: '1%' }}>
                            <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />
                          </div>
                        ) : (
                          <div style={{ marginBottom: "10px", marginTop: '1%' }}>
                            <PrettoSlider disabled valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />
                          </div>
                        )}

                        <Divider />
                        <div style={{ margin: "20px" }}>
                          <div style={{ marginBottom: "20px" }}>
                            <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >Created</p></b>
                            <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.created_at}</p>
                          </div>
                          <div style={{ marginBottom: "20px" }}>
                            <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >Updated</p></b>
                            <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.updated_at}</p>
                          </div>
                          <div>
                            <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >Task ID</p></b>
                            <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes?.order_number}</p>
                          </div>
                          {this.state.currentTask.attributes.ultimate_state == "Quote Approved" && (
                            <>
                              <Divider />
                              <div>
                                <p style={{ fontSize: "16px", marginBottom: "0px" }} >Approved Quote</p>
                                <p style={{ fontSize: "25px", marginTop: "0px" }} >€{this.state.currentTask.attributes.quotation.data && this.state.currentTask.attributes.quotation.data.attributes.price ? this.convertData(this.state.currentTask.attributes.quotation.data.attributes.price)
                                  : this.state.currentTask.attributes && this.state.currentTask.attributes.price_auto_generated ? this.state.currentTask.attributes.price_auto_generated : ''}</p>
                              </div>
                              <Divider />
                            </>
                          )}

                          <>
                            <div>
                              {this.state.permissionName == 'client' ? <b><p style={{ fontSize: "16px" }} >Invoices</p></b> : <b><p style={{ fontSize: "16px" }} > Upload Invoices</p></b>}
                            </div>
                            <div>
                              <div>
                                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                  {this.state.fileInvoiceTaskName && this.state.fileInvoiceTaskName?.map((item: any) => {
                                    this.fileType = item?.attributes?.file_type == null ? item.attributes.name.split('.').pop() : item.attributes.file_type
                                    return (
                                      <React.Fragment>
                                        <Grid item xs={12} style={{ display: "flex", marginRight: "40px", marginBottom: "15px" }}>
                                          <Card style={{ width: '73px', cursor: "pointer" }} onClick={() => {
                                            // this.setState({ deleteFileId: item?.id, uploadVersion: item?.id, finalFileDown: item?.id ,uploadVersionShow : item?.attributes?.approver?.data  ? item?.attributes?.approver?.data : null }, () => console.log("uploadVersion", this.state.uploadVersion)
                                            // )
                                            this.setState({ invoiceId: item?.id })
                                          }}>
                                            {((this.state.permissionName === "albertbauer" || this.state.permissionName === "client") && (this.state.permissions === "editor" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager") ||
                                              this.state.permissionName === "superadmin") && (
                                                <>
                                                  <ExpandMoreIcon style={{ marginLeft: "35px", cursor: "pointer" }}
                                                    onClick={this.openMenuFilePopUpInvoice}
                                                  />




                                                </>
                                              )}


                                            <span
                                              onClick={(e) => {

                                                this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)


                                              }}
                                            >
                                              {/* <a href={item?.attributes?.file_blob_signed_id_url} target="_blank"> */}
                                              <div style={{ position: "relative" }}>
                                                <CardMedia
                                                  component="img"
                                                  alt="template"
                                                  style={{ width: 50, height: 60, padding: '5px 5px' }}
                                                  height="15"
                                                  width="20"
                                                  image={imgFileTemplate}
                                                  title={item.attributes.name}
                                                  className={"white"}
                                                />
                                                {/* {item?.attributes?.approved_at &&  <img width="50%" style={{position:"absolute",top:"11px",left:"15px"}} src={approvedDone} />} */}
                                              </div>
                                              {/* </a> */}
                                            </span>

                                          </Card>
                                          <Typography color="textSecondary" style={{ marginLeft: "14px", marginTop: "44px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>
                                            {this.convertWords(item.attributes.name)}
                                          </Typography>
                                        </Grid>
                                      </React.Fragment>
                                    )

                                  }
                                  )}
                                </div>
                                {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "brand_manager") ||
                                  this.state.permissionName === "superadmin") && (
                                    <>
                                      <div style={{ height: "45px", width: '60px', border: `1px dotted ${colorToBeChanged}`, borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => {
                                          localStorage.setItem("task", "invoice")
                                          // this.setState({ uploadVersion: null }, () => {
                                          this.getMediaAssets()
                                          this.handleUploadOpenDialog()
                                          // })

                                        }}
                                      >
                                        <p style={{ color: colorToBeChanged, marginLeft: "50%" }}>+</p>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </>
                          <>
                            <div>
                              <b><p style={{ fontSize: "16px" }} >Final Files for Sign off</p></b>
                            </div>
                            <div>
                              <div>
                                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                  {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                    if (item?.attributes?.source_file == false && item?.attributes?.final_file == true) {
                                      return (
                                        <React.Fragment>
                                          <Grid item xs={12} style={{ display: "flex", marginRight: "40px", marginBottom: "15px" }}>
                                            <Card style={{ width: '73px', cursor: "pointer" }} onClick={() => {
                                              this.setState({ deleteFileId: item?.id, uploadVersion: item?.id, shareableLink: item?.attributes?.file_blob_signed_id_url, finalFileDown: item?.id, uploadVersionShow: item?.attributes?.approver?.data ? item?.attributes?.approver?.data : null }, () => console.log("uploadVersion", this.state.uploadVersion)
                                              )
                                            }}>

                                              <ExpandMoreIcon style={{ marginLeft: "35px", cursor: "pointer" }} onClick={this.openMenuFilePopUp} />


                                              {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, fileType: item?.attributes?.file_type, loading: true }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                              <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, fileType: item?.attributes?.file_type, fileData: item?.attributes, loading: true }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}>
                                                <div style={{ position: "relative" }}>
                                                  <CardMedia
                                                    component="img"
                                                    alt="template"
                                                    style={{ width: 50, height: 60, padding: '5px 5px' }}
                                                    height="15"
                                                    width="20"
                                                    image={item?.attributes?.file_type == "video" ? imgFileTemplate : item.attributes.file_blob_signed_id_url && item.attributes.file_type !== "pdf" ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                    title={item.attributes.name}
                                                    className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                                  />
                                                  {item?.attributes?.status == 'Approved' && <img width="50%" style={{ position: "absolute", top: "11px", left: "15px" }} src={approvedDone} />}
                                                </div>
                                              </span>
                                              {/* </CardActionArea> */}
                                            </Card>
                                            <Typography color="textSecondary" style={{ marginLeft: "14px", marginTop: "44px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>
                                              {this.convertWords(item.attributes.name)}
                                            </Typography>
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    }
                                  }
                                  )}
                                </div>
                                {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager") ||
                                  this.state.permissionName === "superadmin") && (
                                    <>
                                      <div style={{ height: "45px", width: '60px', border: `1px dotted ${colorToBeChanged}`, borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => {
                                          localStorage.setItem("task", "1")
                                          // this.setState({flag :  1} , ()=> this.fileInput2.click() )
                                          //this.fileInput2.click()
                                          this.setState({ uploadVersion: null }, () => {
                                            this.getMediaAssets()
                                            this.handleUploadOpenDialog()
                                          })

                                        }}
                                      >
                                        {/* <ReactS3Uploader
                                          type="file"
                                          id="upload"
                                          name="pic"
                                          // className={uploaderClassName}
                                          getSignedUrl={this.getSignedUrl}
                                          //accept="image/*"
                                          // onProgress={onProgress}
                                          // onError={onError}
                                          // onFinish={onFinish}
                                          uploadRequestHeaders={{
                                            'x-amz-acl': 'public-read'
                                          }}
                                          s3path="/uploads/"
                                          contentDisposition="auto"
                                          accept="**"
                                          // multiple
                                          capture={true}
                                          //  ref={this.fileInput}
                                          inputRef={cmp => this.fileInput2 = cmp}
                                          // onChange={(event: any) =>
                                          //   this.inputFileChanged(event)
                                          // }
                                          style={{
                                            visibility: "hidden",
                                            position: "absolute",
                                            // width: "280px",
                                            alignContent: "center", backgroundColor: 'white',
                                            color: '#419bf9',
                                            width: "280px", padding: 20,
                                            fontSize: 16, fontWeight: 'bold',
                                            borderWidth: 1,
                                            borderColor: '#419bf9',
                                            borderRadius: 10,
                                            borderStyle: 'dashed',
                                            fontVariant: "initial",
                                            textTransform: 'none',
                                            marginBottom: "20px"
                                          }}
                                        /> */}
                                        <p style={{ color: colorToBeChanged, marginLeft: "50%" }}>+</p>

                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>

                            {((this.state.permissionName == "client" && this.state.currentTask.attributes.ultimate_state == "Done")
                              || this.state.permissionName == "albertbauer" || this.state.permissionName === "superadmin") && (
                                <>
                                  <div>
                                    <b><p style={{ fontSize: "16px" }}>Source Files</p></b>
                                  </div>
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                      if (item?.attributes?.source_file == true && item?.attributes?.final_file == false) {
                                        return (
                                          <React.Fragment>
                                            <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                              <Card style={{ width: '73px', cursor: "pointer" }} onClick={() => { this.setState({ deleteFileId: item?.id }) }}>
                                                {(this.state.permissionName == "client" && this.state.currentTask.attributes.ultimate_state == "Done") ? (
                                                  <>
                                                    {this.state.currentTask.attributes.ultimate_state == "Done" && (
                                                      <>
                                                        <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }} onClick={this.openSourceFileMenuFilePopUp} />
                                                        {/* <Menu
                                                          id="long-menu"
                                                          anchorEl={this.state.setSourceFileAnchorEl}
                                                          keepMounted
                                                          open={Boolean(this.state.setSourceFileAnchorEl)}
                                                          onClose={this.closeSourceFileMenu}
                                                          PaperProps={{
                                                            style: {
                                                              // maxHeight: ITEM_HEIGHT * 4.5,
                                                              width: '',
                                                            },
                                                          }}
                                                        >

                                                          <MenuItem>
                                                            <GetAppIcon
                                                              style={{
                                                                fontSize: "20px",
                                                                marginRight: "10px",
                                                                color: "black",
                                                              }}
                                                            />{" "}
                                                            <span onClick={() => { this.taskFileDownloadSourceFile(item.id) }}>Download</span>
                                                          </MenuItem>
                                                        </Menu> */}
                                                      </>
                                                    )}
                                                  </>
                                                )
                                                  :
                                                  (
                                                    <>
                                                      <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }} onClick={this.openSourceFileMenuFilePopUp} />

                                                    </>
                                                  )
                                                }

                                                {/* <a href={item.attributes.file_blob_signed_id_url} target="_blank"> */}
                                                <span onClick={(e) => {

                                                  if (item?.attributes?.file_type == 'pdf') {
                                                    this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                                  }
                                                  else {
                                                    this.setState({
                                                      fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, fileType: this.fileType, fileData: item?.attributes,
                                                      // setOpenImageDialog : this.fileType == 'pdf' ? false : true 
                                                    })
                                                  }
                                                }}
                                                >
                                                  <CardMedia
                                                    component="img"
                                                    alt="template"
                                                    style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                    height="15"
                                                    width="20"
                                                    image={item?.attributes?.file_type == "video" ? imgFileTemplate : item.attributes.file_blob_signed_id_url && item.attributes.file_type !== "pdf" ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                    title={item.attributes.name}
                                                    className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                                  />
                                                </span>
                                                {/* </a> */}
                                                {/* </CardActionArea> */}
                                              </Card>
                                              <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                                {item.attributes.name}
                                              </Typography>
                                            </Grid>
                                            {/* <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                        <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px' }}
                                              height="15"
                                              width="20"
                                              image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              title={item.attributes.name}
                                              className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                            />
                                          </Card>
                                        </span>
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid> */}
                                          </React.Fragment>
                                        )
                                      }
                                    }
                                    )}

                                  </div>

                                  {(this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager") ||
                                    this.state.permissionName === "superadmin") && (
                                      <>
                                        <div style={{ height: "45px", width: '60px', border: `1px dotted ${colorToBeChanged}`, borderRadius: "8px", cursor: "pointer" }}
                                          onClick={() => {
                                            localStorage.setItem("task", "2")
                                            this.getMediaAssets()
                                            this.handleUploadOpenDialog()
                                            // this.fileInput3.click()
                                          }}
                                        >
                                          {/* <ReactS3Uploader
                                            type="file"
                                            id="upload"
                                            name="pic"
                                            // className={uploaderClassName}
                                            // getSignedUrl={this.getSignedUrl}
                                            //accept="image/*"
                                            // onProgress={onProgress}
                                            // onError={onError}
                                            // onFinish={onFinish}
                                            uploadRequestHeaders={{
                                              'x-amz-acl': 'public-read'
                                            }}
                                            s3path="/uploads/"
                                            contentDisposition="auto"
                                            accept="**"
                                            // multiple
                                            capture={true}
                                            //  ref={this.fileInput}
                                            inputRef={cmp => this.fileInput3 = cmp}
                                            // onChange={(event: any) =>
                                            //   this.inputFileChanged(event)
                                            // }
                                            style={{
                                              visibility: "hidden",
                                              position: "absolute",
                                              // width: "280px",
                                              alignContent: "center", backgroundColor: 'white',
                                              color: '#419bf9',
                                              width: "280px", padding: 20,
                                              fontSize: 16, fontWeight: 'bold',
                                              borderWidth: 1,
                                              borderColor: '#419bf9',
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "20px"
                                            }}
                                          /> */}
                                          <p style={{ color: colorToBeChanged, marginLeft: "50%" }}>+</p>

                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                          </>



                        </div>

                      </div>

                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>

              <DialogActions>

              </DialogActions>
            </div>
          </Dialog>

        ) : undefined}

        <div>
          <Dialog
            open={this.state.setOpenImageDialog}
            maxWidth="lg"
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move', backgroundColor: "rgb(0 0 0 / 78%)", padding: "5px 24px 24px" }} id="draggable-dialog-title">
              <Typography style={{ display: "flex", color: "#ffffff", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>
                  {this.state.fileImage}
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    {!this.state.flagForPreviewImage ? this.state.currentTask?.attributes?.brand_name : null} {!this.state.flagForPreviewImage ? "/" : null} {!this.state.flagForPreviewImage ? this.state.currentTask?.attributes?.project_name : null}
                  </Typography>
                </div>
                <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                  <Typography onClick={() => this.setState({ setOpenImageDialog: false, flagForPreviewImage: false })}>
                    <img src={CancelIcon} />
                  </Typography>
                </div>
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.attachmentShowContent}>
              {this.state.fileImage && this.state.fileImage.includes(".mp4") ?
                <video className={classes.setAssetInFrame} controls autoPlay style={{ left: "35%" }}>
                  <source src={this.state.file_blob_signed_id_url} type="video/mp4" />
                  <source src={this.state.file_blob_signed_id_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
                :
                <img src={this.state.file_blob_signed_id_url} className={classes.setAssetInFrame} />
              }
            </DialogContent>
          </Dialog>
        </div>

        {/* Annotation  */}
        <div >
          <Dialog
            open={this.state.setOpenAnnotaion}
            maxWidth="lg"
            aria-labelledby="draggable-dialog-title"
            className={classes.annotation}
            ref={this.screenshotRef}
          >
            <DialogTitle className={classes.annotationDialogTitle} id="draggable-dialog-title">
              <Typography className={classes.annotationDialogHeader}>
                <Typography>
                  {this.state.fileImage}
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    {this.state.currentTask?.attributes?.brand_name} / {this.state.currentTask?.attributes?.project_name}
                  </Typography>
                </div>
                <div className={classes.annotationDialogHeaderFilter}>
                  <Typography>
                    <img src={FilterIcon} onClick={this.handleTaskFilterOpen} style={{ cursor: 'cus', marginTop: '10px' }} />
                    <Menu
                      id="task_filter"
                      anchorEl={this.state.anchorElTaskFilter}
                      open={Boolean(this.state.anchorElTaskFilter)}
                      onClose={() => {
                        this.handleTaskFilterClose();
                      }}
                    >
                      <MenuItem
                        value="1"
                        selected={this.state.filterType == "1" ? true : false}
                        className={classes.annotationDialogHeaderFilterMenu}
                        onClick={() => { this.onClickApplyFilters("createdbyme") }}
                      >
                        Created By Me
                      </MenuItem>
                      <MenuItem
                        value="2"
                        selected={this.state.filterType == "2" ? true : false}
                        className={classes.annotationDialogHeaderFilterMenu}
                        onClick={() => { this.onClickApplyFilters("resolved") }}
                      >
                        Resolved
                      </MenuItem><MenuItem
                        value="3"
                        selected={this.state.filterType == "3" ? true : false}
                        className={classes.annotationDialogHeaderFilterMenu}
                        onClick={() => { this.onClickApplyFilters("tagged") }}
                      >
                        Tagged
                      </MenuItem>
                      <MenuItem
                        value="4"
                        selected={this.state.filterType == "4" ? true : false}
                        className={classes.annotationDialogHeaderFilterMenu}
                        onClick={() => { this.onClickApplyFilters("mostrecent") }}
                      >
                        Most Recent
                      </MenuItem>
                      <MenuItem
                        value="5"
                        selected={this.state.filterType == "5" ? true : false}
                        className={classes.annotationDialogHeaderFilterMenu}
                        onClick={() => { this.onClickApplyFilters("active") }}
                      >
                        Active
                      </MenuItem>
                    </Menu>
                  </Typography>
                  <Typography>
                    <Select
                      className={classes.SelectDropDownVersion}
                      value={this.state.fileVersionNumber}
                    >
                      {this.state.allVesrsionDetails && this.state.allVesrsionDetails.length > 0 &&
                        this.state.allVesrsionDetails.map((version: any, id: any) =>
                        (
                          <MenuItem
                            value={`V ${id + 1}`}
                            onClick={() => { this.onClickChangeVesrion(version.id) }}
                            className={classes.annotationDialogHeaderFilterMenu}
                          >
                            V {id + 1}
                          </MenuItem>
                        )
                        )}
                      <MenuItem
                        value={`V ${this.state.allVesrsionDetails.length + 1}`}
                        onClick={this.onClickChangeToLatestVesrion}
                        className={classes.annotationDialogHeaderFilterMenu}
                      >
                        V {this.state.allVesrsionDetails.length + 1}
                      </MenuItem>
                    </Select>
                  </Typography>
                  <Typography onClick={() => { this.taskFileDownload() }}>
                    <img src={DownloadIcon} style={{ marginTop: '10px' }} />
                  </Typography>

                  {(this.state.fileData?.status == "Approved") ||
                    (this.state.permissionName == "client" && this.state.permissions == "viewer" && this?.state?.currentTask?.attributes?.status == "Feedback2") ||
                    (this.state.permissionName == "albertbauer" && this.state.permissions == "viewer") ||
                    (this.state.fileData?.status == "Inintial_approval" && this?.state?.currentTask?.attributes?.status == "Feedback1") ?
                    <Typography onClick={this.handleCloseAnnotationModal}>
                      <img src={CancelIcon} className={classes.cancelIcon} />
                    </Typography>
                    :
                    <Typography onClick={this.handleCloseAnnotationModal2}>
                      <img src={CancelIcon} className={classes.cancelIcon} />
                    </Typography>
                  }
                </div>
              </Typography>
            </DialogTitle>
            <Backdrop
              className={classes.backdrop}
              open={this.state.loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent className={classes.annotationContainer} style={{ background: '#000000c9' }} >
              <DialogContentText id="alert-dialog-description" style={{ height: "100%", marginBottom: '0px' }}>
                <Grid container spacing={0} style={{ minWidth: "600px", height: "86%" }}>
                  <Grid item xs={8}
                    style={
                      this.state.latestVesrionDetails?.attributes?.file_type !== "video"
                        && this.state.latestVesrionDetails?.attributes?.file_type !== "pdf"
                        ? { position: "relative", height: "100%" }
                        : { position: "relative", height: "89%", width: "500px" }}
                  >
                    {
                      this.state.latestVesrionDetails?.attributes?.file_type !== "video" &&
                      this.state.latestVesrionDetails?.attributes?.file_type !== "pdf" &&
                      <Typography
                        onClick={() => this.changeBetweenFullAndNoramlView()}
                        className={classes.fullScreenEnterAndExisIcon}
                      >
                        {this.state.fullScreenImage ?
                          <img src={ExitFullViewIcon} />
                          :
                          <img src={FullViewIcon} />
                        }
                      </Typography>
                    }
                    {this.state.fileType && this.state.fileType == "pdf" ?
                      <div
                        // style={{ height: "100%" }} 
                        style={this.state.fullScreenImage ? { height: "96%", width: "100%", position: "relative" } : { position: "relative", height: '95%' }}

                      >
                        <div className={classes.scrollAnnotationFile} id="scrollAnnotationFileSection">
                          <Document
                            className="annotation-pdf-id-document"
                            file={this.state.file_blob_signed_id_url}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            loading={<div style={{ color: "white" }}
                            >Please wait!!!<br />Loading pdf file</div>}
                          >

                            <Page pageNumber={this.state.pageNumber} width={this.state.fullScreenImage ? 800 : 2000} />
                          </Document>

                        </div>
                        <div className="typography_wrapper" style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "relative",
                          bottom: "unset",
                          right: "unset",
                          margin: "10px 0px",
                          cursor: "pointer"
                        }} >
                          <Typography
                            onClick={() => this.changeBetweenFullAndNoramlView()}
                          //className={classes.fullScreenEnterAndExisIcon}
                          >
                            {!this.state.fullScreenImage ?
                              <img src={ExitFullViewIcon} />
                              :
                              <img src={FullViewIcon} />
                            }
                          </Typography>
                        </div>
                        <nav className={classes.pdfPageNavigation}>
                          <p>
                            Page {this.state.pageNumber} of {this.state.numPages}
                          </p>
                          <button
                            className={classes.pdfPageNavigationPrevButton}
                            style={this.state.pageNumber > 1 ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                            disabled={this.state.pageNumber > 1 ? false : true}
                            onClick={this.goToPrevPage}
                          >
                            Prev Page
                          </button>
                          <button
                            className={classes.pdfPageNavigationNextButton}
                            style={this.state.numPages && this.state.numPages > this.state.pageNumber ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                            disabled={this.state.numPages && this.state.numPages > this.state.pageNumber ? false : true}
                            onClick={this.goToNextPage}
                          >
                            Next Page
                          </button>
                        </nav>
                      </div>

                      : this.state.fileType && this.state.fileType == "video" ?
                        <div className={classes.scrollAnnotationImage} id="scrollAnnotationFileSection">
                          <div className="react-pdf__Page">
                            <video controls className={classes.setAssetInFrame} style={{ left: "35%" }}>
                              <source src={this.state.file_blob_signed_id_url} type="video/mp4" />
                              <source src={this.state.file_blob_signed_id_url} type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                        :
                        <div className={classes.scrollAnnotationImage} ref={this.imgRef} id="scrollAnnotationFileSection">
                          <div className="react-pdf__Page" style={this.state.fullScreenImage ? { height: "100%", width: "100%", position: "relative" } : { position: "relative" }}>
                            <img

                              src={this.state.file_blob_signed_id_url}
                              alt='Task Annotation'
                              id="annotation-image-id"


                              className={this.state.fullScreenImage && classes.setAnnotationAssetInFrame}
                            />
                          </div>
                        </div>
                    }

                  </Grid>

                  <Grid item xs={4} className={classes.scrollComments} id="scrollCommentsSection">
                    {(this.state.showAddAnnotation || this.state.fileType == "video") &&
                      <div className={classes.commentBox}>
                        <Grid container>
                          <Grid item xs={2}>
                            <img
                              src={JSON.parse(userDetails).photo_blob_signed_id_url ? JSON.parse(userDetails).photo_blob_signed_id_url : defaultUser}
                              className={classes.commentUserImage}
                            />
                          </Grid>
                          <Grid>
                            <span>
                              {JSON.parse(userDetails).first_name + ' ' + JSON.parse(userDetails).last_name}
                            </span>
                            <p className={classes.commentDate}>
                              {Date().split(":")[0] + ':' + Date().split(":")[1]}
                            </p>
                          </Grid>
                        </Grid>

                        <Editor
                          editorState={this.state.annotationCompleteDataPdf}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder={this.state.placeholder}
                          onFocus={() => this.setState({ placeholder: '' })}
                          onBlur={() => this.setState({ placeholder: 'Add Comment' })}
                          //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                          wrapperStyle={{ border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                          editorStyle={{ padding: 10, minHeight: 100, background: 'white' }}
                          toolbarStyle={{ fontSize: 18 }}
                          onEditorStateChange={(e) => {
                            this.setState({
                              annotationCompleteDataPdf: e,
                              convertedReplyData: convertToRaw(this.state.annotationCompleteDataPdf.getCurrentContent())
                            });
                          }}
                          mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: this.state.selectedProjectsMentionsAccountsData2
                          }}
                          hashtag={{
                            separator: ' ',
                            trigger: '#',
                          }}
                          toolbar={{
                            options: []
                          }}
                        />

                        <div className={classes.replyBox}>
                          <Button
                            onClick={() => {
                              if (this.state.convertedReplyData?.blocks?.length > 0) this.onSubmitOnPdf();
                              else toast.error("Comment is required")
                            }}
                            variant="outlined"
                            className={classes.commentButton}
                          >
                            Comment
                          </Button>
                          <Button
                            onClick={this.onClickCancelPdfAnnotation}
                            variant="outlined"
                            className={classes.commentButton}
                          >
                            Cancel
                          </Button>
                        </div>

                      </div>
                    }
                    {this.state.getAllFileTaskAnnotation && this.state.getAllFileTaskAnnotation.length > 0 &&
                      this.state.getAllFileTaskAnnotation.map((annotation: any, index: any) => {
                        return (
                          <div
                            className={classes.commentBox}
                            style={{ border: '1px solid black' }}
                            id={"annotation" + index}
                            onMouseOver={this.onMouseOver(parseInt(annotation.attributes.task_file_annotation_comments.data.length > 0 && annotation.attributes.task_file_annotation_comments.data[0].id))}
                            onMouseOut={this.onMouseOut(parseInt(annotation.attributes.task_file_annotation_comments.data.length > 0 && annotation.attributes.task_file_annotation_comments.data[0].id))}
                          >
                            <div>
                              <Grid container>
                                <Grid item xs={2}>
                                  <img
                                    src={annotation?.attributes?.creator?.data?.attributes?.photo_blob_signed_id_url
                                      ? annotation?.attributes?.creator?.data?.attributes?.photo_blob_signed_id_url : defaultUser}
                                    className={classes.commentUserImage}
                                  />
                                </Grid>
                                <Grid item xs={9}>
                                  <p className={classes.commentName}>
                                    {annotation.attributes.creator.data.attributes.is_deleted ? 'Deleted User' : `${annotation.attributes.creator.data.attributes.first_name}  ${annotation.attributes.creator.data.attributes.last_name}`}

                                  </p>
                                  <p className={classes.commentDate}>
                                    {annotation.attributes.created_at}
                                  </p>
                                </Grid>
                                <Grid item xs={1}>
                                  {this.state.fileType != "video" && <span className={classes.annotationNumberInBox}>{index + 1}</span>}
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={2} />
                                <Grid item xs={10}>
                                  <p className={classes.commentText} dangerouslySetInnerHTML={{ __html: annotation.attributes.task_file_annotation_comments.data.length > 0 && annotation.attributes.task_file_annotation_comments.data[0].attributes.comment }}></p>
                                  {/* <p className={classes.commentText}>{annotation.attributes.task_file_annotation_comments.data.length > 0 && annotation.attributes.task_file_annotation_comments.data[0].attributes.comment}</p> */}
                                </Grid>
                              </Grid>
                              {annotation.attributes.is_resolved == false ?
                                <div className={classes.replyBox}>
                                  <span
                                    onClick={() => this.openCommentReplyBox(annotation.id)}
                                    className={classes.commentButton1}
                                  >
                                    Reply
                                  </span>
                                  <span
                                    onClick={() => this.resolveComment(annotation.id)}
                                    className={classes.commentButton1}
                                  >
                                    Resolve
                                  </span>
                                </div>
                                :
                                <div className={classes.replyBox}>
                                  <span
                                    style={{ color: colorToBeChanged, marginRight: "10px" }}
                                  >
                                    Resolved
                                  </span>
                                </div>
                              }
                            </div>
                            {this.state.openReplyBox && annotation.id == this.state.addReplyAnnotationId &&
                              <Fragment>
                                <hr />
                                <Grid container>
                                  <Grid item xs={2}>
                                    <img
                                      src={JSON.parse(userDetails).photo_blob_signed_id_url ? JSON.parse(userDetails).photo_blob_signed_id_url : defaultUser}
                                      className={classes.commentUserImage}
                                    />
                                  </Grid>
                                  <Grid>
                                    <span>
                                      {JSON.parse(userDetails).first_name + ' ' + JSON.parse(userDetails).last_name}
                                    </span>
                                  </Grid>
                                </Grid>


                                <Editor
                                  editorState={this.state.replyEditorState}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  placeholder="Add Comment"
                                  //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                                  wrapperStyle={{ border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                                  editorStyle={{ padding: 10, minHeight: 100, background: 'white' }}
                                  toolbarStyle={{ fontSize: 18 }}
                                  onEditorStateChange={(e) => this.onReplyTextChange(e)}
                                  mention={{
                                    separator: ' ',
                                    trigger: '@',
                                    suggestions: this.state.selectedProjectsMentionsAccountsData2
                                    //  [
                                    //   { text: 'APPLE', value: 'apple', url: 'apple' },
                                    //   { text: 'BANANA', value: 'banana', url: 'banana' },
                                    //   { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                    //   { text: 'DURIAN', value: 'durian', url: 'durian' },
                                    //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                    //   { text: 'FIG', value: 'fig', url: 'fig' },
                                    //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                    //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                    // ],
                                  }}
                                  hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                  }}
                                  toolbar={{
                                    options: []
                                  }}
                                />

                                <div className={classes.replyBox}>
                                  <Button
                                    onClick={() => this.onSavingCommentReplyBox(annotation.id)}
                                    variant="outlined"
                                    className={classes.commentButton}
                                    disabled={this.state.convertedReplyData && this.state.convertedReplyData?.blocks?.length > 0
                                      && this.state.convertedReplyData?.blocks[0].text !== "" ? false : true}
                                  >
                                    Reply
                                  </Button>
                                  <Button
                                    onClick={this.closeCommentReplyBox}
                                    variant="outlined"
                                    className={classes.commentButton}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Fragment>
                            }
                            {annotation.attributes.task_file_annotation_comments.data.length > 1 &&
                              <Fragment>
                                <p style={{ marginLeft: "10px" }}>
                                  {parseInt(annotation.attributes.task_file_annotation_comments.data.length) - 1} reply
                                  <span className={classes.commentLine}></span>
                                </p>
                                {annotation.attributes.task_file_annotation_comments &&
                                  annotation.attributes.task_file_annotation_comments.data.map((comment: any, id: any) => {
                                    if (id > 0) {
                                      return (
                                        <div style={{ paddingLeft: "15px" }}>
                                          <Grid container>
                                            <Grid item xs={2}>
                                              <img
                                                src={comment.attributes.creator.data.attributes.photo_blob_signed_id_url}
                                                style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                                              />
                                            </Grid>
                                            <Grid>
                                              <p className={classes.commentName}>
                                                {comment.attributes.creator.data.attributes.first_name + ' ' + comment.attributes.creator.data.attributes.last_name}
                                              </p>
                                              <p className={classes.commentDate}>{comment.attributes.created_at}</p>
                                              <p className={classes.commentText} dangerouslySetInnerHTML={{ __html: comment.attributes.comment }}></p>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      )
                                    }
                                  })}
                              </Fragment>
                            }
                          </div>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "rgb(0 0 0 / 78%)", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {this.state.fileTaskName.findIndex((element: any) =>
                  element.id == this.state.latestVesrionDetails?.id) > this.state.fileTaskName.findIndex((item: any) => item?.attributes?.source_file == false && item?.attributes?.final_file == true)
                  &&
                  <Typography onClick={this.onClickPrevImage}>
                    <img src={LeftIcon} />
                  </Typography>
                }
                {finalTaskFiles && finalTaskFiles.length > 0 &&
                  this.state.latestVesrionDetails?.id !== finalTaskFiles[finalTaskFiles.length - 1].id &&
                  <Typography onClick={this.onClickNextImage}>
                    <img src={RightIcon} />
                  </Typography>
                }
              </div>
              {/* {this.state.fileType == "video" &&
                <h2 style={{ color: "white" }}>
                  Annotation is not allowed in video
                </h2>
              } */}
              {(this.state.fileData?.status == "Approved") || (this.state.fileData?.status == "Inintial_approval" && this?.state?.currentTask?.attributes?.status == "Feedback1") ?
                <Fragment>
                  <p className={classes.finalApprovalText}>
                    {(this.state.fileData?.status == "Inintial_approval" && this?.state?.currentTask?.attributes?.status == "Feedback1") ? 'Approval 1 was done ' :
                      (this.state.fileData?.status == "Approved" && this?.state?.currentTask?.attributes?.status == "Feedback2") ? 'Approval 2 was done ' : 'Approved '}  at {this.state.fileData?.approved_at} by  {this.state.fileData?.approver?.data?.attributes?.is_deleted ? 'Deleted User' : `${this.state.fileData?.approver?.data?.attributes?.first_name} ${this.state.fileData?.approver?.data?.attributes?.last_name}`}.
                  </p>
                </Fragment>
                :
                <Fragment>
                  {(this.state.permissionName == "client" && this.state.permissions == "viewer" && this?.state?.currentTask?.attributes?.status == "Feedback2") ||
                    (this.state.permissionName == "albertbauer" && this.state.permissions == "viewer") ?
                    null
                    :
                    <div>
                      {this.state.fileData?.reviews_submitted == false ?
                        <Button
                          className={classes.submitReviewButton}
                          onClick={() => {
                            this.setState({
                              submitReviewPopUp: true
                            });
                          }}
                        >
                          Submit Comments
                        </Button>
                        : this.state.fileData?.reviewed_at &&
                        <p className={classes.finalApprovalText}>
                          {this?.state?.currentTask?.attributes?.status == "Feedback1" ? 'Submitted for review 1 ' :
                            this?.state?.currentTask?.attributes?.status == "Feedback2" ? 'Submitted for review 2 ' : 'Submitted for review '} at {this.state.fileData?.reviewed_at} by   {this.state.fileData?.reviewer?.data?.attributes?.is_deleted ? 'Deleted User' : `${this.state.fileData?.reviewer?.data?.attributes?.first_name} ${this.state.fileData?.reviewer?.data?.attributes?.last_name}`} .
                        </p>
                      }

                      <Button
                        className={classes.FinalApprovalButton}
                        onClick={() => this.onClickFinalApproval(this.state.fileId)}
                      >
                        Approve Asset
                      </Button>
                      <Button
                        className={classes.FinalApprovalButton}
                        style={{ display: 'none' }}
                        onClick={() => this.screenshot(this.screenshotRef.current, 'test1')}
                      >
                        Test screenshot
                      </Button>
                    </div>
                  }
                </Fragment>
              }
            </DialogActions>
          </Dialog>
        </div>

        <div>

          <Dialog
            open={this.state.finalFileOpen}
            onClose={this.handleCloseFinalFile}
            // fullWidth
            maxWidth="md"
            aria-labelledby="draggable-dialog-title"
            aria-describedby="alert-dialog-description"

          >
            <DialogActions style={{ display: "flex" }}>

              {(this.state.permissionName == "client" && this.state.permissions == "viewer" && this?.state?.currentTask?.attributes?.status == "Feedback2") ||
                (this.state.permissionName == "albertbauer" && this.state.permissions == "viewer") ?
                <Button variant="contained" onClick={this.handleCloseAnnotationModal3} autoFocus>
                  close without action
                </Button> :

                <>

                  {(this.state.fileData?.status == "Inintial_approval" && this?.state?.currentTask?.attributes?.status == "Feedback1") ? '' :
                    <>
                      {(this.state.fileData?.reviews_submitted == false) &&
                        <Button
                          variant="contained"
                          style={{ margin: "2px" }}
                          onClick={() => {
                            this.setState({
                              submitReviewPopUp: true,
                              finalFileOpen: false,
                            });
                          }}
                        >
                          Submit Comments
                        </Button>
                      }
                    </>
                  }

                  <Button variant="contained" onClick={this.handleCloseAnnotationModal3} autoFocus>
                    close without action
                  </Button>
                  {(this.state.fileData?.status == "Approved") || (this.state.fileData?.status == "Inintial_approval" && this?.state?.currentTask?.attributes?.status == "Feedback1") ? '' :
                    <Button className={classes.FinalApprovalButton}
                      onClick={() => {
                        this.onClickFinalApproval(this.state.fileId)
                        this.setState({ finalFileOpen: false })
                      }
                      } autoFocus>
                      Approve Asset
                    </Button>}
                </>}
            </DialogActions>
          </Dialog>
        </div>

        {/* TaskModal */}


        <div >
          <Dialog maxWidth={false} open={this.state.openTaskCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>{t("ApprovalRequest.createNewTask")}</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewTaskModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  title: "",
                  category: "",
                  assetType: "",

                }}
                // enableReinitialize
                validateOnBlur={false}
                // validationSchema={Yup.object().shape({
                //   title: Yup.string().required(
                //     "Task title is required"
                //   ),
                //   //   category: Yup.string()
                //   //     .required("category is required"),
                //   //   assetType: Yup.string()
                //   //     .required("assetType is required"),


                // })}
                onSubmit={(values) => {
                  this.callCreateTaskApi(values, this.state.category, this.state.assetType,)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12} style={{ display: "flex" }}>
                            <TextField id="outlined-basic" style={{
                              marginBottom: 10,
                              marginTop: 3,
                              color: 'primary',
                              background: "#fff"
                            }} fullWidth
                              label={t("projects.taskTitle")}
                              className={classes.confirmInput}
                              name="title"
                              value={values.title}
                              // defaultValue={"Task 00" +this.createRamdomCode()}                          
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Enter Task Title" variant="outlined"
                              // helperText={
                              //   touched.title &&
                              //     errors.title ? (<>
                              //       Title is required
                              //     </>
                              //   ) : (
                              //     ""
                              //   )
                              // }
                              // error={
                              //   touched.title && errors.title
                              //     ? true
                              //     : false
                              // }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                            {/* <BorderColorOutlinedIcon style={{
                                padding: "15px",
                                cursor: "pointer"
                              }}
                              onClick={()=> {                               
                                setFieldValue("title", "Task " + this.createRamdomCode() )                              
                              }}
                              /> */}
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined" style={{
                              marginLeft: 0,
                              minWidth: "100%",
                              maxWidth: "100%",
                              background: 'white'
                            }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16, background: "#fff" }}>{t("projects.category")}</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.category}
                                onChange={this.handleChangeCategory}
                                label={t("projects.category")}
                              >
                                {this.state.assetCategoriesData.length > 0 && this.state.assetCategoriesData.map((item: any) => (
                                  <MenuItem value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                    this.setCategory(item)
                                  }} >{item.attributes.name}</MenuItem>
                                ))}
                              </Select>
                              {!this.state.category && this.state.startBriefingOpen1 &&
                                <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select asset category</Typography>
                              }
                            </FormControl>
                          </Grid>




                          <Grid item xs={6}>

                            <FormControl variant="outlined" className={classes.formControl} style={{
                              marginRight: 0,
                              minWidth: "100%",
                              background: 'white'
                            }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}>{t("ApprovalRequest.assetType")}</InputLabel>
                              <Select labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.assetType}
                                onChange={this.handleChangeAssetType}
                                label={t("ApprovalRequest.assetType")}
                              >

                                {this.state.category && this.state.assetTypesData && this.state.assetTypesData.map((item: any) => (
                                  <MenuItem value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                    this.setAssetType(item)
                                  }} >{item?.attributes?.name}</MenuItem>

                                ))}
                              </Select>
                              {!this.state.assetType && this.state.startBriefingOpen2 &&
                                <Typography style={{ color: "red", paddingLeft: "10px" }} >Please select asset type</Typography>
                              }
                            </FormControl>
                          </Grid>
                          <span style={{ height: '16px', margin: ' 37px 102px 22px 0', fontSize: '16px', marginLeft: '10px', color: '#666666' }}>{t("projects.selectTemplete")}</span>

                          <Grid item xs={12}
                            onClick={this.handleUnselectTemplateInTask}
                          >
                            {this.state.templatesData.length > 0 ? (
                              <>
                                <GridList style={{ backgroundColor: "#e2e2e2", overflowY: "auto", }}>
                                  <div style={{
                                    flexGrow: 1,
                                    padding: 20,
                                    // backgroundColor: "#e2e2e2",
                                  }}>
                                    <Grid item xs={12} container style={{ flexDirection: 'column', }} spacing={1}>
                                      {this.state.templatesData && this.state.templatesData.map((item: any) => (
                                        <React.Fragment>

                                          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', flexBasis: '100%' }}>
                                            <span
                                            //  onClick={(e) => { this.handleClickTemplateSelection(e, item) }}
                                            >
                                              <Card style={{ width: '40px', padding: '10px', textAlign: 'center' }}
                                                onClick={(e) => { this.handleClickTemplateSelection(e, item) }}
                                              >
                                                {/* <CardActionArea onClick = {(item) => {this.handleClickTemplateSelection(item)}} 
>*/}
                                                <CardMedia
                                                  component="img"
                                                  alt="template"
                                                  style={{ width: 25, height: 40, margin: '0 auto' }}
                                                  height="15"
                                                  width="20"
                                                  image={imgFileTemplate}
                                                  title="Contemplative Reptile"
                                                  className={this.state.selectedTaskTemplate?.id === item?.id ? classes.templateHover : "white"}
                                                />
                                                {/* </CardActionArea> */}
                                              </Card>
                                            </span>
                                            <Typography color="textSecondary" style={{ marginTop: 5, marginLeft: "10px", width: 'auto' }}>
                                              {item.attributes.template.data.attributes.name}
                                            </Typography>
                                          </Grid>
                                        </React.Fragment>
                                      ))}

                                    </Grid>
                                  </div>
                                </GridList>
                              </>
                            ) : undefined}
                          </Grid>

                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}

                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button variant="contained" type="submit" style={{ backgroundColor: colorToBeChanged, cursor: "pointer", color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: primaryFont, padding: 15, fontWeight: 'initial' }}
                              onClick={() => {
                                this.handleClickStartBriefing()
                                // disabled={values.title && values.title !== "" ? false : true}
                                this.setState({ startBriefingOpen1: true, startBriefingOpen2: true })
                              }
                              }
                            // disabled={values.title && values.title !== "" ? false : true}
                            >
                              {t("projects.StartBriefing")}
                            </Button>
                            <p style={{ position: "absolute", right: 20, cursor: "pointer" }}
                              onClick={() => window.open('/HelpCentre', '_blank')}
                            //onClick={() => { window.location.href = "/HelpCentre" }}
                            >
                              {t("projects.interestedIn")} <span style={{ color: secondaryColorToBeChanged }}>{t("projects.readMore")}</span>
                            </p>
                          </div>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>

        {/*Add briefing model*/}
        <div>
          <Dialog maxWidth={false} open={this.state.openAddBriefingModel} onClose={() => {

            this.handleClose()

          }} aria-labelledby="form-dialog-title">

            <div style={{ width: 800, background: "#f5f6fa" }} id="scrollUpOnPageChange">
              <DialogTitle style={{
                right: 10,
                borderBottom: '1px solid grey', background: "#ffffff"
              }} id="form-dialog-title">
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: "space-between" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: "16px", marginLeft: "15px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
                    <InsertDriveFileOutlined style={{ marginLeft: 15, fontSize: "16px" }} />
                  </div>
                  <div>
                    <p>{this.state.currentTask?.attributes?.title}</p>
                  </div>
                  <IconButton style={{

                    // position: 'absolute',
                    right: 10,

                  }} onClick={this.handleDeleteTask}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <Backdrop
                className={classes.backdrop}
                open={this.state.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <DialogContent style={{
                backgroundColor: "#f5f6fa",
                overflow: "hidden"
              }}>
                <Grid  >

                  {/* <Grid item xs={12} style={{ margin: 20 }}>
                    <TextField focused id="outlined-basic" className={classes.textfieldLabel} style={{ marginBottom: 10, color: "#666666", background: "#fff" }}
                      fullWidth label="Task Title" value={this.state.currentTask?.attributes?.title} variant="outlined" />
                  </Grid> */}

                  {this.state.templateFormFieldsData
                    .slice(this.state.fromStart, this.state.fromEnd).map((option: any, index: any) => (
                      (option.showField && (option.attributes.element === "text" || option.attributes.element === "short_text" || option.attributes.element === "email" ||
                        option.attributes.element === "full_name" || option.attributes.element === "phone")) ? (
                        <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                          <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                          </Typography>
                          {option.attributes.description && option.attributes.description !== "" &&
                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                              {option.attributes.description}
                            </Typography>
                          }
                          <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                            {index == 0 ?
                              <TextField autoFocus id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChange(option.id, event)} value={option.attributes.default_value} variant="outlined" />
                              :
                              <TextField id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChange(option.id, event)} value={option.attributes.default_value} variant="outlined" />
                            }
                          </Grid>
                        </Grid>
                      ) : (option.showField && option.attributes.element === "long_text") ?
                        (
                          <Grid item xs={12} style={{ margin: 20 }}>
                            <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                            </Typography>
                            {option.attributes.description && option.attributes.description !== "" &&
                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                {option.attributes.description}
                              </Typography>
                            }
                            {!this.state.longTextEditorContent[option.id] && option.attributes.default_value ? this.handlePrefilledValueLongText(option.id, option.attributes.default_value) : null}
                            <Editor
                              editorState={this.state.longTextEditorContent[option.id]}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              onEditorStateChange={(editorState) => this.onEditorStateChange2(editorState, option.id)}
                              stripPastedStyles={true}
                              wrapperStyle={{
                                height: 400,
                                border: "1px solid black",

                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                              editorStyle={{
                                padding: 10,
                                minHeight: 100,
                                background: 'white'
                              }}
                              toolbarStyle={{
                                height: 50,
                                fontSize: 18,
                              }}
                              //onEditorStateChange={this.onEditorStateChange}
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  'list',
                                  'textAlign',
                                ],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic", "underline"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                blockType: {
                                  inDropdown: true,
                                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                },
                                fontSize: {
                                  // icon: Icon.,
                                  options: [7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                  // className: "demo-option-custom-medium",
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                  inDropdown: true,
                                },
                                fontFamily: {
                                  options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                  // className: undefined,
                                  // component: undefined,
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  inDropdown: true,
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["unordered", "ordered"],
                                  unordered: {
                                    className: undefined,
                                  },
                                  ordered: {
                                    className: undefined,
                                  },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                              }}
                            />
                            {/* {option.attributes.description && option.attributes.description !== "" &&
                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                {option.attributes.description}
                              </Typography>
                            }
                            <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                              <TextareaAutosize id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff",
                                width: "99%",
                                borderRadius: "5px",
                              }}
                                autoFocus={index == 0 ? true : false} aria-label="minimum height" rowsMin={minRowsValue} onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} />
                            </Grid> */}

                          </Grid>
                        )
                        : (option.showField && option.attributes.element === "paragraph") ?
                          (
                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                              {/* <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                            </Typography> */}
                              {option.attributes.description && option.attributes.description !== "" &&
                                <div className={classes.paragraphTemp1}>
                                  <Typography gutterBottom
                                    // style={styles.labelTextForBriefingFieldsDescription} 

                                    dangerouslySetInnerHTML={{ __html: option.attributes.description }}>
                                    {/* {option.attributes.description} */}
                                  </Typography>
                                </div>

                              }
                              {/* <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                              <TextareaAutosize id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff",
                                width: "99%",
                                borderRadius: "5px",
                              }}
                                autoFocus={index == 0 ? true : false} aria-label="minimum height" rowsMin={minRowsValue} onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} />
                            </Grid> */}
                            </Grid>
                          ) :
                          (option.showField && option.attributes.element === "image_upload") ?
                            (
                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                {option.attributes.file_blob_signed_id_url && option.attributes.file_blob_signed_id_url !== "" &&
                                  <div>
                                    <img src={option.attributes.file_blob_signed_id_url} alt="image upload" style={{
                                      maxWidth: "200%",
                                      maxHeight: "400px",
                                      display: "block", marginLeft: "auto", marginRight: "auto"
                                    }}
                                    />
                                  </div>}
                                {
                                  option.attributes.template_form_field_files.data.length > 0 &&
                                  <div>
                                    <img src={option.attributes.template_form_field_files.data[0].attributes.file_blob_signed_id_url} alt="image upload" style={{
                                      maxWidth: "200%",
                                      maxHeight: "400px",
                                      display: "block", marginLeft: "auto", marginRight: "auto"
                                    }}
                                    />
                                  </div>
                                }
                              </Grid>
                            )
                            :

                            (option.showField && option.attributes.element === "single_selection") ? (
                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                  {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                </Typography>
                                {option.attributes.description && option.attributes.description !== "" &&
                                  <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                    {option.attributes.description}
                                  </Typography>
                                }
                                <FormControl style={{ marginLeft: 0, }} component="fieldset">
                                  {/* <FormLabel component="legend">{option.attributes.label}</FormLabel> */}
                                  <RadioGroup
                                    style={{ flexDirection: "row" }}
                                    aria-label={option.attributes.label}
                                    name={option.attributes.label}
                                    value={option.attributes.default_value}
                                    onChange={event => this.handleInputChange(option.id, event)}
                                  // onChange={handleChange}
                                  >
                                    {option.attributes.detailed_values.options.map((item: any, index: any) => (
                                      <FormControlLabel
                                        key={index}
                                        value={item.value}
                                        control={<Radio style={{ color: "#1a76b0" }} />}
                                        label={item.label}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>

                              </Grid>
                            ) :
                              (option.showField && option.attributes.element === "date_time") ? (<Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography
                                  variant="h3"
                                  gutterBottom
                                  style={styles.labelTextForBriefingFields}
                                >
                                  {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="date"
                                      type="date"
                                      name="date"
                                      defaultValue={option.attributes.default_value && option.attributes.default_value.includes("T")
                                        && (option.attributes.default_value).indexOf("T") > -1
                                        ? (option.attributes.default_value).split("T")[0]
                                        : option.attributes.default_value && option.attributes.default_value
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      style={{
                                        width: "232px",
                                        border: "1px solid #F0F0F0",
                                        background: 'white',
                                        padding: "10px",
                                      }}
                                      autoFocus={index == 0 ? true : false}
                                      onChange={event => this.handleInputChange(option.id, event)} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="time"
                                      type="time"
                                      name="time"
                                      disabled={option.attributes.default_value == "" ? true : false}
                                      defaultValue={option.attributes.default_value && option.attributes.default_value.includes("T")
                                        && (option.attributes.default_value).indexOf("T")
                                        && (option.attributes.default_value).split("T")[1]
                                      }
                                      style={{
                                        border: "1px solid #F0F0F0",
                                        width: "232px",
                                        background: 'white',
                                        padding: "10px",
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      onChange={event => this.handleInputChange(option.id, event)} />
                                  </Grid>
                                </Grid>
                              </Grid>) :
                                (option.showField && option.attributes.element === "multiple_selection") ? (
                                  <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                    <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                      {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                    </Typography>
                                    {option.attributes.description && option.attributes.description !== "" &&
                                      <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                        {option.attributes.description}
                                      </Typography>
                                    }
                                    <div className={classes.root}>

                                      <FormControl component="fieldset" className={classes.formControl} >
                                        {/* <FormHelperText style={{ fontSize: 16, margin: 5 }}>Choose from standard sizes:</FormHelperText> */}
                                        {/* <FormLabel component="legend" style={{ marginLeft: 3, fontWeight: 'bold', fontSize: 16, color: 'black', marginBottom: 5 }}>{option.attributes.label}</FormLabel> */}
                                        <FormGroup onChange={event => {
                                          this.handleInputChange(option.id, event)
                                        }} style={{ flexDirection: "row" }}>

                                          {option.attributes.detailed_values.options.map((item: any, index: any) => (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                paddingLeft: "15px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <FormControlLabel
                                                key={index}
                                                style={{
                                                  border: "1px solid #F0F0F0",
                                                  width: "750px",
                                                  marginLeft: "4px",
                                                }}
                                                control={
                                                  <Checkbox
                                                    checked={(option.attributes.default_value && option.attributes.default_value.length > 0 &&
                                                      (option.attributes.default_value).findIndex((data: any) => data == item.value) > -1) ? true : false}
                                                    name={item.label}
                                                    value={item.value}
                                                  />
                                                }
                                                label={item.label}
                                              />
                                            </Grid>
                                            // <FormControlLabel
                                            //   key={index}
                                            //   value={item.value}
                                            //   control={<Radio style={{ color: "#1a76b0" }} />}
                                            //   label={item.label}
                                            // />
                                          ))}
                                        </FormGroup>

                                      </FormControl>
                                    </div>
                                  </Grid>

                                ) :
                                  (option.showField && option.attributes.element === "drop_down_select") ? (
                                    <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                      <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                        {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                      </Typography>
                                      {option.attributes.description && option.attributes.description !== "" &&
                                        <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                          {option.attributes.description}
                                        </Typography>
                                      }
                                      <FormControl variant="outlined" style={{
                                        marginLeft: 0,
                                        minWidth: "100%",
                                        background: 'white'
                                      }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}> {option.attributes.label}</InputLabel> */}
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={option.attributes.default_value}
                                          onChange={event => this.handleInputChange(option.id, event)}
                                        // label="Category"
                                        >
                                          {option.attributes.detailed_values.options.map((values: any, index: any) => (
                                            <MenuItem value={values.value} style={{ color: 'black', fontSize: 18 }}  >{values.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  ) :
                                    (option.showField && (option.attributes.element === "file")) ? (
                                      <>
                                        {!option.attributes.template_form_field_files.data.length && <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>
                                          {option.attributes.description && option.attributes.description !== "" &&
                                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                              {option.attributes.description}
                                            </Typography>
                                          }
                                          {/* <FormControl component="fieldset"> */}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged,
                                              width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "3")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog(this.state.briefingFieldId)
                                                })
                                              }
                                              }
                                            >
                                              Upload
                                            </Button>
                                            {this.state.briefingFiles[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingFiles[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}
                                            {this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                            {/* </FormControl> */}
                                          </div>
                                        </Grid>}
                                        {option.attributes.template_form_field_files.data.length > 0 && <>

                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>


                                          {option.attributes.template_form_field_files && option.attributes.template_form_field_files?.data &&
                                            option.attributes.template_form_field_files?.data?.length > 0 &&
                                            option.attributes.template_form_field_files?.data.map((file: any) => {
                                              return (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                  </Typography>
                                                  {file.attributes.description && file.attributes.description !== "" &&
                                                    <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                      {file.attributes.description}
                                                    </Typography>
                                                  }
                                                  <span>
                                                    <Card style={{ width: '73px' }}>
                                                      <CardMedia
                                                        component="img"
                                                        alt="template"
                                                        style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                        height="15"
                                                        width="20"
                                                        image={file.attributes.file_blob_signed_id_url}
                                                        className={classes.templateHover}
                                                      />
                                                    </Card>
                                                    <Button variant="contained" className={classes.button} style={{
                                                      alignContent: "center", backgroundColor: '#ffffff',
                                                      color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                      borderColor: colorToBeChanged,
                                                      borderRadius: 10,
                                                      borderStyle: 'dashed',
                                                      fontVariant: "initial",
                                                      textTransform: 'none',
                                                      marginBottom: "50px"
                                                    }} color="primary"
                                                      startIcon={<CloudUploadIcon />}
                                                      onClick={(event) => this.clearFileImage(option.id, event, file.id)}>
                                                      Clear
                                                    </Button>
                                                  </span>

                                                </Grid>
                                              )
                                            })}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "3")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog(this.state.briefingFieldId)
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingFiles[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingFiles[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </>}
                                      </>) :
                                      (option.showField && (option.attributes.element === "image")) ? (<>
                                        {!option.attributes.template_form_field_files.data.length && <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                          {console.log(option, "currrentoption")}
                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>
                                          {option.attributes.description && option.attributes.description !== "" &&
                                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                              {option.attributes.description}
                                            </Typography>
                                          }
                                          {/* <FormControl component="fieldset"> */}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </Grid>}


                                        {option.attributes.template_form_field_files.data.length > 0 && <>

                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>


                                          {option.attributes.template_form_field_files && option.attributes.template_form_field_files?.data &&
                                            option.attributes.template_form_field_files?.data?.length > 0 &&
                                            option.attributes.template_form_field_files?.data.map((file: any) => {
                                              return (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                  </Typography>
                                                  {file.attributes.description && file.attributes.description !== "" &&
                                                    <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                      {file.attributes.description}
                                                    </Typography>
                                                  }
                                                  <span>
                                                    <Card style={{ width: '73px' }}>
                                                      <CardMedia
                                                        component="img"
                                                        alt="template"
                                                        style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                        height="15"
                                                        width="20"
                                                        image={file.attributes.file_blob_signed_id_url}
                                                        className={classes.templateHover}
                                                      />
                                                    </Card>
                                                    <Button variant="contained" className={classes.button} style={{
                                                      alignContent: "center", backgroundColor: '#ffffff',
                                                      color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                      borderColor: colorToBeChanged,
                                                      borderRadius: 10,
                                                      borderStyle: 'dashed',
                                                      fontVariant: "initial",
                                                      textTransform: 'none',
                                                      marginBottom: "50px"
                                                    }} color="primary"
                                                      startIcon={<CloudUploadIcon />}
                                                      onClick={(event) => this.clearFileImage(option.id, event, file.id)}>
                                                      Clear
                                                    </Button>
                                                  </span>

                                                </Grid>
                                              )
                                            })}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </>}

                                      </>) :
                                        (option.showField && option.attributes.element === "slider") ? (

                                          <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                            <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                            </Typography>
                                            {option.attributes.description && option.attributes.description !== "" &&
                                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                {option.attributes.description}
                                              </Typography>
                                            }
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Due Date"
                                        format="MMM D YYYY"
                                        autoOk={true}
                                        inputVariant="outlined"
                                        value={option.attributes.default_value}
                                        onChange={event => this.handleInputChange(index, event)}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />
                                    </MuiPickersUtilsProvider> */}
                                            <CustomSlider
                                              style={{
                                                // width: "100%",
                                                // marginLeft: "18px",
                                                // marginTop: "18px",
                                              }}
                                              defaultValue={option.attributes.default_value ? option.attributes.default_value : 0}
                                              // getAriaValueText={valuetext}
                                              aria-labelledby="discrete-slider-always"
                                              //onChange={( event: any, values: any) => {  }}
                                              //step={10}
                                              marks={[
                                                {
                                                  value: option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0,
                                                  label: option.attributes.detailed_values?.min ? option.attributes.detailed_values?.min : '0',
                                                },
                                                {
                                                  value: option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100,
                                                  label: option.attributes.detailed_values?.max ? option.attributes.detailed_values?.max : '100',
                                                },

                                              ]}
                                              min={option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0}
                                              max={option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100}
                                              valueLabelDisplay="on"
                                              onChange={(event: any, value: any) => {
                                                // this.handleSliderChange(index, event, value);
                                                var templateData = this.state.templateFormFieldsData;
                                                var indexValue = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(option.id))
                                                templateData[indexValue].attributes.default_value = value
                                                templateData[indexValue].value = value;
                                                if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
                                                  let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
                                                  // if (conditionData.length > 0) {
                                                  //   templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
                                                  // }
                                                  if (conditionData.length > 0) {
                                                    conditionData.map((condition: any) => {
                                                      let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);

                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                    })
                                                  }
                                                }
                                                this.setState({
                                                  templateFormFieldsData: templateData
                                                });
                                                // 
                                              }}
                                            //ValueLabelComponent={StyledValueLabel}
                                            />

                                          </Grid>
                                        ) :
                                          (option.showField && option.attributes.element === "address") ? (
                                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                              <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                {option.attributes.label}
                                              </Typography>
                                              {option.attributes.description && option.attributes.description !== "" &&
                                                <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                  {option.attributes.description}
                                                </Typography>
                                              }
                                              <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                {index == 0 ?
                                                  <TextField autoFocus id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} variant="outlined" />
                                                  :
                                                  <TextField id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} variant="outlined" />
                                                }
                                              </Grid>
                                            </Grid>
                                          ) :
                                            (option.showField && option.attributes.element === "page_separator") ? (
                                              <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                              >
                                                <Grid item xs={8}>
                                                  <Pagination count={this.state.paginationCount} style={{ marginTop: "20px" }} defaultPage={1} page={this.state.page} onChange={this.handleChangePage} />
                                                </Grid>
                                              </Grid>
                                            ) :
                                              (option.showField && option.attributes.element === "comment_box") ? (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {option.attributes.label}
                                                  </Typography>
                                                  <TextareaAutosize aria-label="minimum height" rowsMax={10} rowsMin={10} style={{ width: "100%", fontSize: "16px", padding: "18px 14px", boxSizing: "border-box", fontFamily: "Arial" }} onChange={event => this.handleInputChange(option.id, event)} />
                                                </Grid>
                                              )

                                                :
                                                undefined



                    ))}
                </Grid>

              </DialogContent>
              <DialogActions>
              {this.state.paginationCount  && this.state.paginationCount == this.state.page ?
              <Grid container item style={{ margin: '10px 20px', fontFamily: primaryFont }}>
              {/* {
                this.state.openAddRequestQuoteModel ?
                  <Button variant="contained" className={classes.button}
                    onClick={this.handleClickSubmitBriefing}
                    style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                    SUBMIT BRIEFING
                      </Button>
                  : */}

              {/* <Button variant="contained" className={classes.button}
                onClick={this.handleClickSubmitBriefing}
                style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                SUBMIT BRIEFING
              </Button> */}
              <Button
                style={{
                  backgroundColor: colorToBeChanged,
                  color: "white",
                  marginLeft: "15px",
                }}
                type="submit"
                variant="contained"
                onClick={() => this.handleClickSubmitBriefing(false)}
              >SAVE
                {/* {t("projects.saveChanges")} */}
              </Button>
              {/* {this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" ) ? ( */}

              {(localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined) ? <Button
                style={{
                  backgroundColor: 'white',
                  color: colorToBeChanged,
                  marginLeft: "15px",
                }}
                onClick={this.createBriefingAndGenerateQuote}
                type="submit"
                variant="contained"
              > REQUEST QUOTE
                {/* {t("projects.saveChanges")} */}
              </Button> :
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: colorToBeChanged,
                    marginLeft: "15px",
                  }}
                  onClick={() => {
                    this.setState({ submitProduction: true })
                    this.createBriefingAndGenerateQuote()
                  }}
                  type="button"
                  variant="contained"
                > Submit to Production Ready
                  {/* {t("projects.saveChanges")} */}
                </Button>

              }
              {/* ) : undefined} */}

            </Grid>
              :<></>}
                

              </DialogActions>
              <div style={{ margin: '5px 30px', marginBottom: 15, color: 'grey', fontSize: 16, }}>*Please fill out the mandatory fields to request a quote.If assistance needed,get in contact with one of our colleagues via help chat.</div>
            </div>

          </Dialog>

        </div>

        {/*Edit briefing model*/}
        <div>
          <Dialog maxWidth={false} open={this.state.openEditBriefingModel} onClose={this.handleCloseEditBriefing} aria-labelledby="form-dialog-title">

            <div style={{ width: 800, background: "#f5f6fa" }}>
              <DialogTitle style={{
                right: 10,
                borderBottom: '1px solid grey', background: "#ffffff"
              }} id="form-dialog-title">
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: "space-between" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: "16px", marginLeft: "15px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
                    <InsertDriveFileOutlined style={{ marginLeft: 15, fontSize: "16px" }} />
                  </div>
                  <div>
                    <p>{this.state.currentTask?.attributes?.title}</p>
                  </div>
                  <IconButton style={{right: 10}} onClick={this.state.taskDuplicateFlag ? this.handleCloseEditBriefingAndDeleteTask : this.handleCloseEditBriefing}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent style={{
                backgroundColor: "#f5f6fa",
                overflow: "hidden"
              }}>
                <Grid  >

                  {/* <Grid item xs={12} style={{ margin: 20 }}>
                    <TextField focused id="outlined-basic" className={classes.textfieldLabel} style={{ marginBottom: 10, color: "#666666", background: "#fff" }}
                      fullWidth label="Task Title" value={this.state.currentTask?.attributes?.title} variant="outlined" />
                  </Grid> */}

                  {this.state.templateFormFieldsData
                    .slice(this.state.fromStart, this.state.fromEnd).map((option: any, index: any) => (
                      (option.showField && (option.attributes.value_type === "text" || option.attributes.value_type === "short_text" || option.attributes.value_type === "email" ||
                        option.attributes.value_type === "full_name" || option.attributes.value_type === "phone")) ? (
                        <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                          <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                            {option.attributes.label}
                          </Typography>
                          {option.attributes.description && option.attributes.description !== "" &&
                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                              {option.attributes.description}
                            </Typography>
                          }
                          <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>

                            {index == 0 ?
                              <TextField autoFocus id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChangeEditBriefing(option.id, event)} placeholder={option.attributes.description} value={option.attributes.value} variant="outlined" />
                              :
                              <TextField id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChangeEditBriefing(option.id, event)} placeholder={option.attributes.description} value={option.attributes.value} variant="outlined" />
                            }
                          </Grid>
                        </Grid>
                      ) : (option.showField && option.attributes.value_type === "long_text") ?
                        (
                          <Grid item xs={12} style={{ margin: 20 }}>
                            <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                              {option.attributes.label}
                            </Typography>
                            {option.attributes.description && option.attributes.description !== "" &&
                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                {option.attributes.description}
                              </Typography>
                            }
                            {/* <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                              <TextareaAutosize id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff",
                                width: "99%",
                                borderRadius: "5px",
                              }}
                                autoFocus={index == 0 ? true : false} rowsMin={minRowsValue} onChange={event => this.handleInputChangeEditBriefing(option.id, event)} placeholder={option.attributes.description} value={option.attributes.value} />
                            </Grid> */}
                            <Editor
                              editorState={this.state.longTextEditorContent[option.id]}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              onEditorStateChange={(editorState) => this.onEditorStateChange2(editorState, option.id)}
                              stripPastedStyles={true}
                              wrapperStyle={{
                                height: 400,
                                border: "1px solid black",

                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                              editorStyle={{
                                padding: 10,
                                minHeight: 100,
                                background: 'white'
                              }}
                              toolbarStyle={{
                                height: 50,
                                fontSize: 18,
                              }}
                              //onEditorStateChange={this.onEditorStateChange}
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  'list',
                                  'textAlign',
                                ],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic", "underline"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                blockType: {
                                  inDropdown: true,
                                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                },
                                fontSize: {
                                  // icon: Icon.,
                                  options: [7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                  // className: "demo-option-custom-medium",
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                  inDropdown: true,
                                },
                                fontFamily: {
                                  options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                  // className: undefined,
                                  // component: undefined,
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  inDropdown: true,
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["unordered", "ordered"],
                                  unordered: {
                                    className: undefined,
                                  },
                                  ordered: {
                                    className: undefined,
                                  },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                              }}
                            />
                          </Grid>
                        ) :
                        (option.showField && option.attributes.value_type === "paragraph") ?
                          (
                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                              {/* <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                          </Typography> */}
                              {option.attributes.description && option.attributes.description !== "" &&
                                <div className={classes.paragraphTemp1}>
                                  <Typography gutterBottom
                                    // style={styles.labelTextForBriefingFieldsDescription} 

                                    dangerouslySetInnerHTML={{ __html: option.attributes.description }}>
                                    {/* {option.attributes.description} */}
                                  </Typography>
                                </div>

                              }
                              {/* <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                            <TextareaAutosize id="outlined-basic" style={{
                              marginBottom: 10,
                              color: 'primary',
                              background: "#fff",
                              width: "99%",
                              borderRadius: "5px",
                            }}
                              autoFocus={index == 0 ? true : false} aria-label="minimum height" rowsMin={minRowsValue} onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} />
                          </Grid> */}
                            </Grid>
                          ) :

                          (option.showField && option.attributes.value_type == "image_upload") ? (
                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                              {option.attributes?.briefed_value_files?.data?.map((_item: any) => {

                                return <div>
                                  <img src={_item?.attributes?.file_blob_signed_id_url}
                                    alt="image"
                                    style={{ maxWidth: "100%", maxHeight: "240px", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                                </div>
                              })}

                            </Grid>) :
                            (option.showField && option.attributes.value_type === "single_selection") ? (
                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                  {option.attributes.label}
                                </Typography>
                                {option.attributes.description && option.attributes.description !== "" &&
                                  <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                    {option.attributes.description}
                                  </Typography>
                                }
                                <FormControl style={{ marginLeft: 0, }} component="fieldset">
                                  {/* <FormLabel component="legend">{option.attributes.label}</FormLabel> */}
                                  <RadioGroup
                                    style={{ flexDirection: "row" }}
                                    aria-label={option.attributes.label}
                                    name={option.attributes.label}
                                    value={option.attributes.value}
                                    onChange={event => this.handleInputChangeEditBriefing(option.id, event)}
                                  // onChange={handleChange}
                                  >
                                    {option.attributes.detailed_values.options.map((item: any, index: any) => (

                                      <FormControlLabel
                                        key={index}
                                        value={item.value}
                                        control={<Radio style={{ color: "#1a76b0" }} />}
                                        label={item.label}
                                      />

                                    ))}
                                  </RadioGroup>
                                </FormControl>

                              </Grid>
                            ) :
                              (option.showField && option.attributes.value_type === "date_time") ? (<Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography
                                  variant="h3"
                                  gutterBottom
                                  style={styles.labelTextForBriefingFields}
                                >
                                  {option.attributes.label}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="date"
                                      type="date"
                                      name="date"
                                      defaultValue={option.attributes.value && option.attributes.value.includes("T")
                                        && (option.attributes.value).indexOf("T")
                                        ? (option.attributes.value).split("T")[0]
                                        : option.attributes.value && option.attributes.value
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      style={{
                                        width: "232px",
                                        border: "1px solid #F0F0F0",
                                        padding: "10px",
                                      }}
                                      autoFocus={index == 0 ? true : false}
                                      onChange={event => this.handleInputChangeEditBriefing(option.id, event)} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="time"
                                      type="time"
                                      name="time"
                                      disabled={option.attributes.value == "" ? true : false}
                                      defaultValue={option.attributes.value && option.attributes.value.includes("T")
                                        && (option.attributes.value).indexOf("T")
                                        && (option.attributes.value).split("T")[1]
                                      }
                                      style={{
                                        border: "1px solid #F0F0F0",
                                        width: "232px",
                                        padding: "10px",
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      onChange={event => this.handleInputChangeEditBriefing(option.id, event)} />
                                  </Grid>
                                </Grid>
                              </Grid>) :
                                (option.showField && option.attributes.value_type === "multiple_selection") ? (
                                  <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                    <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                      {option.attributes.label}
                                    </Typography>
                                    {option.attributes.description && option.attributes.description !== "" &&
                                      <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                        {option.attributes.description}
                                      </Typography>
                                    }
                                    <div className={classes.root}>

                                      <FormControl component="fieldset" className={classes.formControl} >
                                        {/* <FormHelperText style={{ fontSize: 16, margin: 5 }}>Choose from standard sizes:</FormHelperText> */}
                                        {/* <FormLabel component="legend" style={{ marginLeft: 3, fontWeight: 'bold', fontSize: 16, color: 'black', marginBottom: 5 }}>{option.attributes.label}</FormLabel> */}
                                        <FormGroup onChange={event => this.handleInputChangeEditBriefing(option.id, event)} style={{ flexDirection: "row" }}>

                                          {option.attributes.detailed_values.options.map((item: any, index: any) => (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                paddingLeft: "15px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <FormControlLabel
                                                key={index}
                                                style={{
                                                  border: "1px solid #F0F0F0",
                                                  width: "750px",
                                                  marginLeft: "4px",
                                                }}
                                                control={
                                                  <Checkbox
                                                    checked={(option.attributes.value && option.attributes.value.length > 0 &&
                                                      (option.attributes.value).findIndex((data: any) => data == item.value) > -1) ? true : false}
                                                    name={item.label}
                                                    value={item.value}
                                                  />
                                                }
                                                label={item.label}
                                              />
                                            </Grid>
                                            // <FormControlLabel
                                            //   key={index}
                                            //   value={item.value}
                                            //   control={<Radio style={{ color: "#1a76b0" }} />}
                                            //   label={item.label}
                                            // />
                                          ))}
                                        </FormGroup>

                                      </FormControl>
                                    </div>
                                  </Grid>

                                ) :
                                  (option.showField && option.attributes.value_type === "drop_down_select") ? (
                                    <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                      <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                        {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                      </Typography>
                                      {option.attributes.description && option.attributes.description !== "" &&
                                        <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                          {option.attributes.description}
                                        </Typography>
                                      }
                                      <FormControl variant="outlined" style={{
                                        marginLeft: 0,
                                        minWidth: "100%",
                                        background: 'white'
                                      }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}> {option.attributes.label}</InputLabel> */}
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={option.attributes.value}
                                          onChange={event => this.handleInputChangeEditBriefing(option.id, event)}
                                        // label="Category"
                                        >
                                          {option.attributes.detailed_values.options.map((values: any, index: any) => (
                                            <MenuItem value={values.value} style={{ color: 'black', fontSize: 18 }}  >{values.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  ) :
                                    (option.showField && (option.attributes.value_type === "file"))
                                      ?
                                      <>
                                        {option.attributes.briefed_value_files && option.attributes.briefed_value_files.data &&
                                          option.attributes.briefed_value_files.data.length > 0 ?
                                          option.attributes.briefed_value_files.data.map((file: any) => {
                                            return (
                                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                  {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                </Typography>
                                                {file.attributes.description && file.attributes.description !== "" &&
                                                  <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                    {file.attributes.description}
                                                  </Typography>
                                                }
                                                <span>
                                                  <Card style={{ width: '73px' }}>
                                                    <CardMedia
                                                      component="img"
                                                      alt="template"
                                                      style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                      height="15"
                                                      width="20"
                                                      image={file.attributes.file_blob_signed_id_url}
                                                      className={classes.templateHover}
                                                    />
                                                  </Card>
                                                  <Button variant="contained" className={classes.button} style={{
                                                    alignContent: "center", backgroundColor: '#ffffff',
                                                    color: colorToBeChanged,
                                                    width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                    borderColor: colorToBeChanged,
                                                    borderRadius: 10,
                                                    borderStyle: 'dashed',
                                                    fontVariant: "initial",
                                                    textTransform: 'none',
                                                    marginBottom: "50px"
                                                  }} color="primary"
                                                    startIcon={<CloudUploadIcon />}
                                                    onClick={(event) => this.clearOldFile(option.id, event, file.id)}>
                                                    Clear
                                                  </Button>
                                                </span>
                                              </Grid>
                                            )
                                          })
                                          :
                                          (option.showField) &&
                                          (<Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                            <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                              {option.attributes.label}
                                            </Typography>
                                            {option.attributes.description && option.attributes.description !== "" &&
                                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                {option.attributes.description}
                                              </Typography>
                                            }
                                            {/* <FormControl component="fieldset"> */}
                                            <div style={{ marginLeft: 0 }}>
                                              <Button variant="contained" className={classes.button} style={{
                                                alignContent: "center", backgroundColor: '#ffffff',
                                                color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                borderColor: colorToBeChanged,
                                                borderRadius: 10,
                                                borderStyle: 'dashed',
                                                fontVariant: "initial",
                                                textTransform: 'none',
                                                marginBottom: "50px"
                                              }} color="primary"
                                                startIcon={<CloudUploadIcon />}
                                                // onClick={() =>
                                                //  this.fileInput.click()}
                                                onClick={() => {
                                                  localStorage.setItem("task", "3")
                                                  this.setState({ fileOptionId: option?.id })
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()

                                                }
                                                }
                                              >
                                                Upload
                                              </Button>
                                              
                                              {this.state.briefingFiles[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingFiles[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                                return (
                                                  <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                                )
                                              }) : null}

                                              {this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                                return (
                                                  <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                                )
                                              }) : null}
                                            </div>
                                            {/* </FormControl> */}
                                          </Grid>
                                          )}

                                        {option.attributes.briefed_value_files.data.length > 0 && <div style={{ marginLeft: 0 }}>
                                          <Button variant="contained" className={classes.button} style={{
                                            alignContent: "center", backgroundColor: '#ffffff',
                                            color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                            borderColor: colorToBeChanged,
                                            borderRadius: 10,
                                            borderStyle: 'dashed',
                                            fontVariant: "initial",
                                            textTransform: 'none',
                                            marginBottom: "50px"
                                          }} color="primary"
                                            startIcon={<CloudUploadIcon />}
                                            onClick={() => {
                                              localStorage.setItem("task", "3")
                                              this.setState({ fileOptionId: option?.id })
                                              this.getMediaAssets()
                                              this.handleUploadOpenDialog()
                                            }
                                            }
                                          >
                                            Upload
                                          </Button>
                                          
                                          {this.state.briefingFiles[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingFiles[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                            return (
                                              <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                            )
                                          }) : null}

                                          {this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                            return (
                                              <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                            )
                                          }) : null}
                                        </div>}

                                      </> :
                                      (option.showField && (option.attributes.value_type === "image"))
                                        ?
                                        <>
                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>
                                          {option.attributes.briefed_value_files && option.attributes.briefed_value_files?.data &&
                                            option.attributes.briefed_value_files?.data?.length > 0 ?
                                            option.attributes.briefed_value_files?.data.map((file: any) => {
                                              return (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                  </Typography>
                                                  {file.attributes.description && file.attributes.description !== "" &&
                                                    <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                      {file.attributes.description}
                                                    </Typography>
                                                  }
                                                  <span>
                                                    <Card style={{ width: '73px' }}>
                                                      <CardMedia
                                                        component="img"
                                                        alt="template"
                                                        style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                        height="15"
                                                        width="20"
                                                        image={file.attributes.file_blob_signed_id_url}
                                                        className={classes.templateHover}
                                                      />
                                                    </Card>
                                                    <Button variant="contained" className={classes.button} style={{
                                                      alignContent: "center", backgroundColor: '#ffffff',
                                                      color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                      borderColor: colorToBeChanged,
                                                      borderRadius: 10,
                                                      borderStyle: 'dashed',
                                                      fontVariant: "initial",
                                                      textTransform: 'none',
                                                      marginBottom: "50px"
                                                    }} color="primary"
                                                      startIcon={<CloudUploadIcon />}
                                                      onClick={(event) => this.clearOldImage(option.id, event, file.id)}>
                                                      Clear
                                                    </Button>
                                                  </span>
                                                </Grid>
                                              )
                                            })
                                            : (option.showField) &&
                                            (<Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                              {option.attributes.description && option.attributes.description !== "" &&
                                                <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                  {option.attributes.description}
                                                </Typography>
                                              }
                                              {/* <FormControl component="fieldset"> */}
                                              <div style={{ marginLeft: 0 }}>
                                                <Button variant="contained" className={classes.button} style={{
                                                  alignContent: "center", backgroundColor: '#ffffff',
                                                  color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                  borderColor: colorToBeChanged,
                                                  borderRadius: 10,
                                                  borderStyle: 'dashed',
                                                  fontVariant: "initial",
                                                  textTransform: 'none',
                                                  marginBottom: "50px"
                                                }} color="primary"
                                                  startIcon={<CloudUploadIcon />}
                                                  // onClick={() => this.fileInput10.click()}

                                                  onClick={() => {
                                                    localStorage.setItem("task", "4")
                                                    this.setState({ fileOptionId: option?.id })
                                                    this.getMediaAssets()
                                                    this.handleUploadOpenDialog()

                                                  }
                                                  }
                                                >
                                                  Upload
                                                </Button>
                                                {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                                  return (
                                                    <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                                  )
                                                }) : null}

                                                {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                                  return (
                                                    <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                                  )
                                                }) : null}
                                              </div>
                                            </Grid>
                                            )}

                                          {option.attributes.briefed_value_files?.data?.length > 0 && <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id })
                                                this.getMediaAssets()
                                                this.handleUploadOpenDialog()
                                              }
                                              }
                                            >
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>}
                                        </> :
                                        (option.showField && option.attributes.value_type === "slider") ? (

                                          <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                            <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                            </Typography>
                                            {option.attributes.description && option.attributes.description !== "" &&
                                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                {option.attributes.description}
                                              </Typography>
                                            }
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Due Date"
                                        format="MMM D YYYY"
                                        autoOk={true}
                                        inputVariant="outlined"
                                        value={option.attributes.default_value}
                                        onChange={event => this.handleInputChange(index, event)}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />
                                    </MuiPickersUtilsProvider> */}
                                            <CustomSlider
                                              style={{
                                                width: "100%",
                                                // marginLeft: "18px",
                                                // marginRight: "18px",
                                                // marginTop: "18px",
                                              }}
                                              defaultValue={option.attributes.value ? option.attributes.value : 0}
                                              // getAriaValueText={valuetext}
                                              aria-labelledby="discrete-slider-always"
                                              //step={10}
                                              marks={[
                                                {
                                                  value: option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0,
                                                  label: option.attributes.detailed_values?.min ? option.attributes.detailed_values?.min : '0',
                                                },
                                                {
                                                  value: option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100,
                                                  label: option.attributes.detailed_values?.max ? option.attributes.detailed_values?.max : '100',
                                                },

                                              ]}
                                              min={option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0}
                                              max={option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100}
                                              valueLabelDisplay="on"
                                              onChange={(event: any, value: any) => {
                                                var templateData = this.state.templateFormFieldsData;
                                                var indexValue = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(option.id))
                                                templateData[indexValue].attributes.value = value
                                                templateData[indexValue].value = value;
                                                if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
                                                  let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
                                                  if (conditionData.length > 0) {
                                                    conditionData.map((condition: any) => {
                                                      let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.value = "";
                                                        templateData[findMatch].value = "";
                                                      }
                                                    })
                                                  }
                                                }
                                                this.setState({
                                                  templateFormFieldsData: templateData
                                                });
                                              }}
                                            //ValueLabelComponent={StyledValueLabel}
                                            />

                                          </Grid>
                                        ) :
                                          (option.showField && option.attributes.value_type === "address") ? (
                                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                              <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                              </Typography>
                                              {option.attributes.description && option.attributes.description !== "" &&
                                                <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                  {option.attributes.description}
                                                </Typography>
                                              }
                                              <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                {index == 0 ?
                                                  <TextField autoFocus id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChangeEditBriefing(option.id, event)} placeholder={option.attributes.description} value={option.attributes.value} variant="outlined" />
                                                  :
                                                  <TextField id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChangeEditBriefing(option.id, event)} placeholder={option.attributes.description} value={option.attributes.value} variant="outlined" />
                                                }
                                              </Grid>
                                            </Grid>
                                          ) :
                                            (option.showField && option.attributes.value_type === "page_separator") ? (
                                              <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                              >
                                                <Grid item xs={8}>
                                                  <Pagination count={this.state.paginationCount} style={{ marginTop: "20px" }} defaultPage={1} page={this.state.page} onChange={this.handleChangePage} />
                                                </Grid>
                                              </Grid>
                                            )
                                              :
                                              (option.showField && option.attributes.value_type === "comment_box") ? (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {option.attributes.label}
                                                  </Typography>
                                                  <TextareaAutosize aria-label="minimum height" rowsMin={10} rowsMax={10} value={option.attributes.value} style={{ width: "100%" }} onChange={event => this.handleInputChangeEditBriefing(option.id, event)} />
                                                </Grid>
                                              ) :
                                                undefined

                    ))}
                </Grid>

              </DialogContent>
              <DialogActions>

                {/* {this.state.paginationCount  && this.state.paginationCount == this.state.page ? */}
                <Grid container item style={{ margin: '10px 20px' }}>
                  {/* {
                    this.state.openAddRequestQuoteModel ?
                      <Button variant="contained" className={classes.button}
                        onClick={this.handleClickSubmitBriefing}
                        style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                        SUBMIT BRIEFING
                          </Button>
                      : */}

                  {/* <Button variant="contained" className={classes.button}
                    onClick={this.handleClickSubmitBriefing}
                    style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                    SUBMIT BRIEFING
                  </Button> */}
                  <Button
                    style={{
                      backgroundColor: colorToBeChanged,
                      color: "white",
                      marginLeft: "15px",
                    }}
                    type="submit"
                    variant="contained"
                    onClick={() => this.state.taskDuplicateFlag ? this.handleClickSaveDuplicateTask() : this.updateBriefingAndGenerateQuote("save")}
                  >SAVEee
                    {/* {t("projects.saveChanges")} */}
                  </Button>
                  {/* {this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" ) ? ( */}

                  {(localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined) && <Button
                    style={{
                      backgroundColor: 'white',
                      color: colorToBeChanged,
                      marginLeft: "15px",
                    }}
                    onClick={() => this.state.taskDuplicateFlag ? this.setState({ isComingFromBriefingForm: true }, () => {
                      this.handleClickSaveDuplicateTask()
                    }) : this.updateBriefingAndGenerateQuote("request_quote")}
                    type="submit"
                    variant="contained"
                  > REQUEST QUOTE
                    {/* {t("projects.saveChanges")} */}
                  </Button>}

                  {!this.state.taskDuplicateFlag && (localStorage.getItem("brandGroupSelectedName") == "Hugo Boss") && <Button
                    style={{
                      backgroundColor: 'white',
                      color: colorToBeChanged,
                      marginLeft: "15px",
                    }}
                    onClick={() => {
                      this.setState({ submitProduction: true })
                      this.updateBriefingAndGenerateQuote()
                    }}
                    type="submit"
                    variant="contained"
                  > Submit to Production Ready
                    {/* {t("projects.saveChanges")} */}
                  </Button>}
                </Grid>
              </DialogActions>
              <div style={{ margin: '5px 30px', marginBottom: 15, color: 'grey', fontSize: 16, }}>*Please fill out the mandatory fields to request a quote.If assistance needed,get in contact with one of our colleagues via help chat.</div>
            </div>

          </Dialog>

        </div>

        {/* create status popup */}

        <div >
          <Dialog maxWidth={false} open={this.state.openStatusCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800, fontFamily: primaryFont }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>{t("ApprovalRequest.createNewStatus")}</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewStatusModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  status: "",
                  project_id: "",

                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  status: Yup.string().required(
                    "column name is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateStatusApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" style={{
                              marginBottom: 10,
                              marginTop: 3,
                              color: 'primary'
                            }} fullWidth label="status"
                              className={classes.confirmInput}
                              name="status"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="column name" variant="outlined"
                              helperText={
                                touched.status &&
                                  errors.status ? (<>
                                    column name is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.status && errors.status
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>
                          {/* <Grid item xs={12}>
                          <TextField id="outlined-basic" style={{
                            marginBottom: 10,
                            marginTop: 3,
                            color: 'primary'
                          }} fullWidth label="priority"
                            className={classes.confirmInput}
                            name="priority"
                            InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                            InputProps={{ style: { borderColor: 'black' } }}
                            placeholder="Enter Priority" variant="outlined"
                            helperText={
                              touched.priority &&
                                errors.priority ? (<>
                                  priority is required
                              </>
                              ) : (
                                ""
                              )
                            }
                            error={
                              touched.priority && errors.priority
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur} />
                        </Grid>
                        */}

                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >

                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE COLUMN
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>
        {/* edit status popup */}

        <div >
          <Dialog maxWidth={false} open={this.state.editStatusDialog} onClose={() => this.setState({ editStatusDialog: false })} aria-labelledby="form-dialog-title">
            <div style={{ width: 800, fontFamily: primaryFont }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Edit Status</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={() => this.setState({ editStatusDialog: false })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  status: this.state.selectedStatus?.attributes?.label_name?.length > 0 ? this.state.selectedStatus?.attributes?.label_name : this.state.selectedStatus?.attributes?.status,
                  project_id: "",

                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  status: Yup.string().required(
                    "status is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callEditStatusApi(values)
                  // this.callCreateStatusApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" style={{
                              marginBottom: 10,
                              marginTop: 3,
                              color: 'primary'
                            }} fullWidth label="status"
                              className={classes.confirmInput}
                              name="status"
                              defaultValue={this.state.selectedStatus?.attributes?.label_name?.length > 0 ? this.state.selectedStatus?.attributes?.label_name : this.state.selectedStatus?.attributes?.status}
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="status" variant="outlined"
                              helperText={
                                touched.status &&
                                  errors.status ? (<>
                                    status is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.status && errors.status
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>
                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >

                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE STATUS
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>

        {/* end create status popup */}
        {/* Create subtask popup */}
        <div >
          <Dialog maxWidth={false} open={this.state.openSubTaskCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800, fontFamily: primaryFont }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Subtasks</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewSubTakModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  title: "",



                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  title: Yup.string().required(
                    "title is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateSubTaskApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>


                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primory'
                              }} fullWidth label="Title"
                              className={classes.confirmInput}
                              name="title"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Enter Title" variant="outlined"
                              helperText={
                                touched.title &&
                                  errors.title ? (<>
                                    Title is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.title && errors.title
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>


                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE SUB TASK
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>


        {/* end sub task  popup */}
        {/* create task blocker popup */}
        <div >
          <Dialog maxWidth={false} open={this.state.openTaskBlockerCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800, fontFamily: primaryFont }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Blocker</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewTaskBlockerModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  description: "",


                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required(
                    "description is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateTaskBlockerApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primary'
                              }} fullWidth label="Description"
                              className={classes.confirmInput}
                              name="description"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="description" variant="outlined"
                              helperText={
                                touched.description &&
                                  errors.description ? (<>
                                    description is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>



                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <Button variant="contained" type="submit" style={{
                            backgroundColor: colorToBeChanged,
                            color: "white",
                            marginLeft: "15px",
                          }}>
                            SAVE BLOCKER
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>

        {/* Project properties popup */}
        <Dialog onClose={this.handleCloseModalProject} aria-labelledby="customized-dialog-title" open={this.state.openProjectProperties} >
          <div style={{ width: "600px", height: "570px", padding: "40px", fontFamily: primaryFont }}>
            <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px", padding: "0px 0px", marginBottom: "40px", }}>
              {/* {t("HomePage.photoProduction")} */}
              <div style={{ position: "relative" }}>
                {/* {t("individualCompanyHomeScreen.photoProduction")} */}


                <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }} >
                  <CloseIcon onClick={this.handleCloseModalProject} />

                </span>
              </div>


            </DialogTitle>


            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              scrollButtons="off"
              indicatorColor="primary"
              aria-label="full width tabs example"
              textColor="primary"
              centered
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                label={t("individualCompanyHomeScreen.projectOverview")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />
              <Tab
                label={t("individualCompanyHomeScreen.projectDocuments")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />
              <Tab
                label={t("individualCompanyHomeScreen.members")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />

            </Tabs>


            {this.state.value === 0 &&
              (

                <Formik
                  initialValues={{
                    // email: "",
                    // animal: "",
                    // companyName: "",
                    projectTitle: this.state.selectedProjectData?.attributes?.name,
                    projectDescription: this.state.selectedProjectData?.attributes?.description,
                    //shareTheProjectWithOthers:this.state.sharedProject,
                    //   includeAPersonalMessageWithYourInvitation: ''

                  }}

                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    // projectDescription: Yup.string()
                    //   .required("projectDescription is required"),


                    // includeAPersonalMessageWithYourInvitation: Yup.string()
                    //   .required("Include A Personal MessageWith Your Invitation is required"),

                  })}
                  onSubmit={(values) => {
                    //
                    this.callUpdateProjectPropertiesApi(values, this.state.sharedProject);
                    // this.setState({
                    //   sharedProjectError: this.state.sharedProject ? true : false
                    // }, () => {
                    //   
                    // })
                    // this.goToConfirmationAfterPasswordChange(values);
                    // this.props.nextStep(values);
                  }}

                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    isValid,
                    setFieldValue,
                    handleBlur,
                    setFieldTouched,
                    isSubmitting,
                    handleSubmit,
                  }) => {
                    //



                    return (

                      <form onSubmit={handleSubmit}>
                        <DialogContent >
                          <TextField
                            name="projectTitle"
                            type="text"
                            size="small"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            fullWidth
                            // label={t("projects.projectDescription")}
                            label="Project Title"
                            value={values.projectTitle}
                            className={classes.confirmInput}
                            variant="outlined"

                            onChange={handleChange("projectTitle")}
                            onBlur={handleBlur}
                          />


                          {/* <Autocomplete
                            inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.first_name}
                            id="fixed-tags-demo"
                            className={classes.selectInput}
                            options={this.state.projectPropertiesAccountsData}
                            getOptionLabel={(option: any) => option?.attributes?.first_name ? option?.attributes?.first_name : ''}
                            onChange={(e, value) => {
                              this.callGetProjectPropertiesAccountListApi(value)
                            }}
                            style={{ minWidth: "550px", marginTop: "40px" }}
                            renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                          /> */}
                          <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            options={this.state.brandTeamsData}
                            style={{ minWidth: "550px" }}
                            getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}

                            onInputChange={(e, value) => {
                              this.callGetProjectPropertiesAccountListApi(value)

                            }}
                            renderOption={(option: any) => (
                              option?.photo_blob_signed_id_url?.length >= 0 ?
                                <React.Fragment>
                                  <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                                    <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                                    <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                                  }</span>
                                  {option.first_name} {option.last_name}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {option?.name}
                                </React.Fragment>
                            )}
                            // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                            onChange={(e, value) => {
                              //
                              this.setState({
                                sharedProject: value,
                                // sharedProjectError: value.length > 0 ? false : true

                              }, () => {
                                //
                              })
                              // this.callGetProjectPropertiesAccountListApi(value)
                            }}

                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                option?.photo_blob_signed_id_url?.length >= 0 ?
                                  <Chip
                                    className={classes.projectChips}
                                    label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                      <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                      <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                                    </Typography>}
                                    {...getTagProps({ index })}

                                    style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                  />
                                  :
                                  <Chip
                                    className={classes.projectChips}
                                    label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                      {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                      <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                                    </Typography>}
                                    {...getTagProps({ index })}

                                    style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                  />
                              ))
                            }
                            // renderTags={(value, getTagProps) =>
                            //   value.map((option, index) => (
                            //     <Chip
                            //       className={classes.projectChips}
                            //       label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                            //         <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.attributes?.photo_blob_signed_id_url} /> </span>
                            //         <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.attributes?.first_name}</span>
                            //       </Typography>}
                            //       {...getTagProps({ index })}

                            //       style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            //     />
                            //   ))
                            // }

                            renderInput={(params): any => (
                              <TextField
                                {...params}
                                label={t("teams.addMembers")}
                                name="addMembers"
                                placeholder={t("teams.addMembersPlaceholder")}

                                // helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                                // error={
                                //   this.state.sharedProjectError
                                // }

                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                className={classes.confirmInput}
                                variant="outlined"

                              />
                            )}
                          />




                          <TextField
                            name="projectDescription"

                            multiline
                            rows={5}
                            // InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            style={{ marginTop: "40px", }}
                            size="small"
                            className={classes.confirmInput}
                            fullWidth
                            // label={t("projects.Includeapersonalmessagewithyourinvitation")}
                            label="Project Description"
                            placeholder={t("teams.descriptionsPlaceholder")}
                            value={values.projectDescription}
                            variant="outlined"

                            onChange={handleChange("projectDescription")}
                            onBlur={handleBlur}
                          />





                        </DialogContent>
                        <DialogActions style={{ justifyContent: "flex-start" }}>
                          {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                          <Button
                            style={{
                              backgroundColor: colorToBeChanged,
                              color: "white",
                              marginLeft: "15px",
                              width: "100px",
                              height: "48px",
                              marginTop: "15px"
                            }}
                            type="submit"
                            variant="contained"
                          // onClick={()=>
                          //   {this.handleUpdateDuplicateProjectProperties(values.projectTitle,values.projectDescription)}}
                          >Update
                            {/* {t("projects.done")} */}
                          </Button>
                          {/* <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button> */}
                        </DialogActions>
                      </form>


                    )
                  }}
                />

              )
            }
            {
              this.state.value === 1 &&
              (
                <div style={{ marginBottom: "2rem", minWidth: "600px" }} >
                  <div className={classes.projectDocuments} >
                    <p style={{ fontSize: "12px" }}>Document Details</p>
                    <p style={{ marginLeft: "60%", fontSize: "12px" }}>Date Details</p>

                  </div>
                  {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.map((media: any) => (
                    <div className={classes.projectDocument} style={{}} >
                      <span style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                        <img src={imageIcon} style={{ height: "14px", width: "18px", padding: "15px" }} />
                        <div>
                          <Link
                            //  href= "/Home/MediaAssets/:brandId/:mediaAssetId/:AssetId"
                            href={`/Home/MediaAssets/${this.state.projectMediaAssetsDetail[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.projectMediaAssetsDetail[0]?.attributes?.name + '/' + media?.attributes?.name + '/' + media.id}
                            // href = {`/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                            underline="none"
                          >

                            <p className={classes.fileName} style={{ color: secondaryColorToBeChanged }}>{media.attributes?.name}</p>
                          </Link>
                          <p className={classes.fileSize}>{media.attributes?.size}</p>
                        </div>
                      </span>
                      <span>
                        <p className={classes.date}>{media.attributes?.updated_at},Uploaded by {media.attributes?.creator?.data?.attributes?.first_name} {media.attributes?.creator?.data?.attributes?.last_name}</p>
                      </span>
                    </div >
                  ))}

                  {/* {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.length > 0 ? <Button
                    className={classes.button}
                    style={{
                      backgroundColor: "#173e59",
                      color: "white",
                      bottom: "-300px",
                      right: "-390px"
                    }}
                    type="submit"
                    variant="contained"
                    onClick={this.handleCloseModal}
                    onClick={(e: any) => (window.location.href = `/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                  >
                    Go to Assets folder
                  </Button> :
                    null
                  } */}

                </div>

              )
            }
            {
              this.state.value === 2 &&
              (
                <div style={{ minWidth: "600px", padding: "15px" }}>


                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ fontSize: "12px", }}>
                      Members Details
                    </p>
                    <p style={{ position: "absolute", right: "150px", fontSize: "12px", width: '108px' }}>
                      Role
                    </p>

                  </div>

                  {this.state.selectedProjectsAccountsData && this.state.selectedProjectsAccountsData.map((account: any) => (
                    <div className={classes.members} style={{ marginLeft: "0px" }} >
                      <Avatar alt="Remy Sharp" src={account?.attributes?.photo_blob_signed_id_url} />
                      {account?.attributes?.is_deleted ?
                        <div style={{ marginLeft: "10px" }}>
                          <span >
                            <p style={{ fontSize: "14px", textAlign: 'left', margin: "12px 0px" }}>Deleted User</p>
                          </span>
                        </div>
                        : <>
                          <div style={{ marginLeft: "10px" }}>
                            <span >
                              <p style={{ fontSize: "14px", textAlign: 'left', margin: "0" }}>{account?.attributes?.first_name}</p>
                            </span>
                            <span >
                              <p style={{ fontSize: "10px" }}> {account?.attributes?.email}</p>
                            </span>
                          </div>
                          <span style={{ fontSize: "12px", position: "absolute", right: "150px", width: '108px', textTransform: 'uppercase' }}>
                            <p> {account?.attributes?.role?.data?.attributes?.permission}</p>
                          </span>
                          {!((this.state.permissionName == "client" && this.state.permissions === "viewer") || (this.state.permissionName == "albertbauer" && this.state.permissions === "viewer"))
                            //  || this.state.permissions === "editor" ||
                            //   this.state.permissions === "approver" || this.state.permissions === "brand_manager" || this.state.permissions === "superadmin" 
                            ?
                            <>
                              <IconButton aria-label="Delete" style={{ position: "absolute", right: "50px" }} onClick={(e) => {
                                this.callDeleteAccountProjectsApi(account.id, this.state.selectedProjectData.id)
                              }}>
                                <CloseIcon />
                              </IconButton>
                            </>
                            : ""}
                        </>}



                    </div>
                  ))}

                </div>
              )
            }
          </div>
        </Dialog>
        {/* end Task Blocker popup */}
        {/* next screen */}
        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();
          }}
          style={{
            marginTop: "175px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)", fontFamily: primaryFont
          }}
        >
          <Grid container style={{ width: '550px' }} spacing={2}>
            <Grid item xs={12} style={{ padding: '0px' }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ padding: '0px 16px', height: '55px' }}
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      color: "#9EABB5",
                    }}
                  >
                    {this.state.selectedUsersForFilter.length + this.state.selectedAssetTypeForFilter.length + this.state.selectedCategoryForFilter.length + this.state.selectedStatusForFilter.length + (this.state.filterDueDate ? 1 : 0)} {" "} {t("ApprovalRequest.filterSelected")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: colorToBeChanged,
                      border: `1px solid ${colorToBeChanged}`,
                      width: "134px",
                      height: "35px",
                      marginLeft: "17px",
                    }}
                    onClick={() => this.filterTasksBasedOnUserAndStatus()}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "13px",
                        fontFamily: "Work Sans",
                        opacity: 1,
                        textTransform: "uppercase",
                      }}
                    >
                      {t("ApprovalRequest.applyFilter")}
                    </Typography>
                  </Button>
                  <span style={{ marginLeft: '15px' }}></span>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: colorToBeChanged,
                      border: `1px solid ${colorToBeChanged}`,
                      width: "134px",
                      height: "35px",
                      marginLeft: "17px",
                    }}
                    onClick={() => {
                      this.setState({
                        selectedUsersForFilter: [],
                        selectedStatusForFilter: [],
                        selectedAssetTypeForFilter: [],
                        selectedCategoryForFilter: [],
                        filterDueDate: '',
                        filterTaskOnAssetTypes: []
                      }, () => {
                        this.componentDidMount2()
                        this.handleFilterClose();
                      })
                    }}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "13px",
                        fontFamily: "Work Sans",
                        opacity: 1,
                        textTransform: "uppercase",
                      }}
                    >
                      {t("ApprovalRequest.clearAll")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid #9EABB5",
                  opacity: 0.4,
                }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <MenuList style={{ backgroundColor: '#f8f9fa', padding: '0px' }}>
                  <MenuItem style={{ borderBottom: "1px solid #ecedf0", padding: '0px 16px', height: '45px' }} onClick={() => this.handleFilterClick('name')}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="inherit">Name</Typography>
                      <Typography variant="inherit"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'rebeccapurple',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.selectedUsersForFilter.length}
                      </Typography>
                    </div>
                  </MenuItem>

                  <MenuItem style={{ borderBottom: "1px solid #ecedf0", padding: '0px 16px', height: '45px' }} onClick={() => this.handleFilterClick('asset_category')}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="inherit">Asset Category</Typography>
                      <Typography variant="inherit"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'rebeccapurple',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.selectedCategoryForFilter.length}
                      </Typography>
                    </div>
                  </MenuItem>

                  <MenuItem style={{ borderBottom: "1px solid #ecedf0", padding: '0px 16px', height: '45px' }} onClick={() => this.handleFilterClick('asset_type')}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="inherit">Asset Type</Typography>
                      <Typography variant="inherit"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'rebeccapurple',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.selectedAssetTypeForFilter.length}
                      </Typography>
                    </div>
                  </MenuItem>

                  <MenuItem style={{ borderBottom: "1px solid #ecedf0", padding: '0px 16px', height: '45px' }} onClick={() => this.handleFilterClick('date')}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="inherit">Date</Typography>
                      <Typography variant="inherit"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'rebeccapurple',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.filterDueDate ? "1" : null}
                      </Typography>
                    </div>
                  </MenuItem>
                  {/* <MenuItem style={{ borderBottom: "1px solid #ecedf0", padding: '0px 16px', height: '45px' }} onClick={() => this.handleFilterClick('status')}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="inherit">Status</Typography>
                      <Typography variant="inherit"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'rebeccapurple',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.selectedStatusForFilter.length}
                      </Typography>
                    </div>
                  </MenuItem> */}
                </MenuList>
              </Grid>
              <Grid item xs={8} style={{ width: '100%', height: '250px', overflow: 'hidden' }}>
                {this.state.selectedFilter === 'name' && (
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '94%', maxHeight: '250px', overflowY: 'scroll' }}>
                    <Grid
                      container
                      direction="row"
                    // justify="center"
                    // alignItems="center"
                    >
                      <Input
                        style={{
                          width: "210px",
                          height: "35px",
                          border: "1px solid #1A1A1A",
                          borderRadius: "4px",
                          opacity: 0.4,
                          paddingLeft: '5px'
                        }}
                        disableUnderline={true}
                        type="text"
                        placeholder={t("Help.askQuestion")}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        value={this.state.searchKeyword}
                        onChange={(event) => this.setState({ searchKeyword: event.target.value?.toLowerCase() })}
                      />
                      <Button
                        style={{
                          backgroundColor: colorToBeChanged,
                          border: `1px solid ${colorToBeChanged}`,
                          width: "95px",
                          height: "35px",
                          marginLeft: "17px",
                        }}
                        variant="contained"
                        onClick={() => {
                          if (this.state.searchKeyword == "") {
                            this.setState({
                              filteredUserAccountsData: this.state.filteredUserAccountsDataTemp
                            })
                          } else {
                            this.setState({
                              filteredUserAccountsData: this.state.filteredUserAccountsDataTemp.filter((obj: any) => obj?.attributes?.first_name?.toLowerCase() == this.state.searchKeyword)
                            })
                          }
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            color: "#FFFFFF",
                            fontSize: "13px",
                            fontFamily: "Work Sans",
                            opacity: 1,
                            textTransform: "uppercase",
                          }}
                        >
                          {t("Help.search")}
                        </Typography>
                      </Button>
                    </Grid>
                    <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                      <FormGroup>
                        {
                          this.state.filteredUserAccountsData.map((_data: any, key: any) => {
                            return this.state.selectedUsersForFilter.find((item: any) => item == _data?.id) == undefined ? (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('name', _data?.id)} name="name" value={_data?.attributes?.first_name} />}
                                label={_data?.attributes?.first_name + " " + _data?.attributes?.last_name}
                                key={key}
                              />
                            ) : (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('name', _data?.id)} name="name" value={_data?.attributes?.first_name} />}
                                label={_data?.attributes?.first_name + " " + _data?.attributes?.last_name}
                                key={key}
                                checked={true}
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                {this.state.selectedFilter === 'asset_type' && (
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '94%', maxHeight: '250px', overflowY: 'scroll' }}>

                    <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                      <FormGroup>
                        {
                          this.state.filterTaskOnAssetTypes.map((_data: any, key: any) => {
                            return this.state.selectedAssetTypeForFilter.find((item: any) => item == _data?.id) == undefined ? (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('asset_type', _data?.id)} name="name" value={_data?.id} />}
                                label={_data?.attributes?.name}
                                key={key}
                              />
                            ) : (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('asset_type', _data?.id)} name="name" value={_data?.id} />}
                                label={_data?.attributes?.name}
                                key={key}
                                checked={true}
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                {this.state.selectedFilter === 'asset_category' && (
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '94%', maxHeight: '250px', overflowY: 'scroll' }}>

                    <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                      <FormGroup>
                        {
                          this.state.assetCategoriesData.map((_data: any, key: any) => {
                            return this.state.selectedCategoryForFilter.find((item: any) => item == _data?.id) == undefined ? (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('asset_category', _data?.id)} name="name" value={_data?.id} />}
                                label={_data?.attributes?.name}
                                key={key}
                              />
                            ) : (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('asset_category', _data?.id)} name="name" value={_data?.id} />}
                                label={_data?.attributes?.name}
                                key={key}
                                checked={true}
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                {/* {this.state.selectedFilter === 'status' && (
                  <div style={{ display: 'flex', margin: '10px' }}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {
                          this.state.allTaskStatus.map((_data: any, key: any) => {
                            return this.state.selectedStatusForFilter.find((item: any) => item == _data?.attributes?.status) == undefined ? (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('status', _data?.attributes?.status)} name="status" value={_data?.attributes?.status} />}
                                label={_data?.attributes?.status}
                                key={key}
                              />
                            ) : (
                              <FormControlLabel
                                control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('status', _data?.attributes?.status)} name="status" value={_data?.attributes?.status} />}
                                label={_data?.attributes?.status}
                                key={key}
                                checked={true}
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </FormControl>
                  </div>
                )} */}
                {this.state.selectedFilter === 'date' && (
                  <div style={{ margin: '10px' }}>
                    <Grid container>
                      <Grid item xs={4}>
                        <p>Due Date :</p>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          id="date"
                          name="Start Date"
                          type="date"
                          value={this.state.filterDueDate}
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          fullWidth
                          variant="outlined"
                          className={classes.selectInput}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) => {
                            this.handlefilterDueDate(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.filterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name" control={<Radio />} label="Name" />
                <FormControlLabel value="create_at" control={<Radio />} label="Created At" />
                <FormControlLabel value="updated_at" control={<Radio />} label="Updated At" />
                <FormControlLabel value="estimated_end_date" control={<Radio />} label="Estimated End Date" />
                <FormControlLabel value="ultimate_state" control={<Radio />} label="Status" />
                <FormControlLabel value="price" control={<Radio />} label="Price" />
              </RadioGroup>
            </Grid>
          </Grid> */}
        </Menu>
        {/* upload Dialog */}
        <Dialog
          onClose={this.handleUploadCloseDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.uploadDialog}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            Upload File
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleUploadCloseDialog} />
            </span>
            {/* <IconButton onClick={this.handleUploadCloseDialog} >
                  <CloseIcon />
                </IconButton> */}
            <Box display="flex" alignItems="center">
              {/* <Box flexGrow={1}> */}
              {/* <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#1a76b0",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Upload File
                </Typography> */}
              {/* </Box> */}
              {/* <Box>

                <IconButton onClick={this.handleUploadCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </Box> */}
            </Box>
            <Tabs
              value={this.state.dialogTabValue}
              onChange={this.handleUploadTabChange}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label="Media Asset Libraries" value="MediaAssest" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
              <Tab label="Upload" value="Upload" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
            </Tabs>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent dividers>
            <Grid>
              {this.state.dialogTabValue === "MediaAssest" ? (
                <AccordionDetails>
                  <Box width="100%" display="flex" flexDirection="column">
                    {/* <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                       border="solid 1px #e4e8f0"
                    > */}

                    {this.state.mediaState > 0 ?
                      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px", marginBottom: "10px" }}>

                        {


                          <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 0 })}>
                            <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                              Brands
                            </Link>
                          </Typography>

                        }
                        {
                          this.state.mediaState >= 1 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 1 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.brandSelected}
                              </Link>
                            </Typography>
                            : null
                        }

                        {
                          this.state.mediaState >= 2 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 2 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>

                                {this.state.mediaAssetSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }
                        {
                          this.state.mediaState >= 3 ?
                            <Typography style={{ fontSize: "12px" }}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.folderSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }



                      </Breadcrumbs>
                      :
                      null

                    }

                    <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                    // border="solid 1px #e4e8f0"
                    >
                      {this.state.mediaState == 0 && this.state.assetData.length > 0 && this.state.assetData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>
                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                // this.setState({mediaState : 1}, ()=> this.loadBrandMediaFolderandFiles(item?.id))
                                this.setState({ brandSelected: item?.attributes?.name }, () => this.loadBrandMediaFolderandFiles(item?.id))
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"



                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 1 && this.state.brandMediaAssets.length > 0 && this.state.brandMediaAssets.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {


                                this.setState({ mediaAssetSelected: item?.attributes?.name }, () => {
                                  this.getfilesAndFolder(item?.id)

                                })
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"


                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 2 && this.state.projectFoldersAssetsData.length > 0 && this.state.projectFoldersAssetsData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                this.setState({ folderSelected: item?.attributes?.name }, () => {
                                  this.getMediaFolderAssetFiles(item?.id)
                                })
                              }
                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"

                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}



                      {this.state.mediaState == 2 && this.state.projectFilesAssetsData.length > 0 && this.state.projectFilesAssetsData.map((item: any, index: any) => {
                        if (localStorage.getItem("task") == 'invoice' && item?.attributes?.file_type == 'pdf') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }

                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {/* <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> */}
                                        <PictureAsPdfRounded />
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                        else if (localStorage.getItem("task") != 'invoice') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}

                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {item?.attributes?.file_type != 'pdf' ? <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> :
                                          <PictureAsPdfRounded />
                                        }
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }

                      })}




                      {this.state.mediaState == 3 && this.state.mediaFolderAssetdFiles.length > 0 && this.state.mediaFolderAssetdFiles.map((item: any, index: any) => {
                        if (localStorage.getItem("task") == 'invoice' && item?.attributes?.file_type == 'pdf') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {/* <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> */}
                                        <PictureAsPdfRounded />
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                        else if (localStorage.getItem("task") != 'invoice') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {item?.attributes?.file_type != 'pdf' ? <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> :
                                          <PictureAsPdfRounded />
                                        }
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                      })}


                    </Box>
                  </Box>

                </AccordionDetails>
              ) : this.state.dialogTabValue === "Upload" ? (
                <Grid style={{ display: "flex", justifyContent: "center" }}>




                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    style={{
                      alignContent: "center",
                      backgroundColor: "white",
                      color: secondaryColorToBeChanged,
                      width: "280px",
                      padding: 20,
                      fontSize: 16,
                      fontWeight: "bold",
                      borderWidth: 1,
                      borderColor: secondaryColorToBeChanged,
                      borderRadius: 10,
                      borderStyle: "dashed",
                      fontVariant: "initial",
                      textTransform: "none",
                      marginBottom: "20px",
                      height: "70px",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    color="primary"
                    onClick={() => {

                      localStorage.getItem("task") == '3' ?
                        this.fileInput2.click()
                        :
                        localStorage.getItem("task") == '4' ?
                          this.setState({ flagForImage: true }, () => {
                            this.fileInput2.click()
                          })
                          :
                          localStorage.getItem("task") == '2' && this.state.replaceFlag == 1 ?
                            this.fileInput2.click()
                            :
                            this.fileInput2.click()
                    }
                    }
                  // type ="file"
                  >

                    + Upload
                  </Button>


                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContent>
          {this.state.dialogTabValue === "MediaAssest" ?
            <DialogActions
              style={{
                justifyContent: "flex-start",
                marginLeft: "25px",
                padding: "12px",
              }}
            >
              <Button variant="contained"
                // color= {secondaryColorToBeChanged} 
                style={{
                  background: localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? secondaryColorToBeChanged : null : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? secondaryColorToBeChanged : null : this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  //  background : this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  // color : this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                  color: localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? "white" : "darkgrey" : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? "white" : "darkgrey" : this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                }}
                onClick={() => {
                  if (localStorage.getItem("task") == '3' || localStorage.getItem("task") == '4') {

                    if (localStorage.getItem("task") == '3') {
                      this.setState({
                        // fileName  : this.state.uploadingFilesName[this.state.uploadingFilesName.length -1] ,
                        flagForMediaUpload1: true, uploadDialog: false
                      })
                      // this.uploadBriefingFiles()
                    }
                    else if (localStorage.getItem("task") == '4') {
                      this.setState({
                        // fileNameImage : this.state.uploadingFilesName[this.state.uploadingFilesName.length -1] ,
                        flagForMediaUpload2: true, uploadDialog: false
                      })

                    }


                  }
                  else if (localStorage.getItem("task") == 'invoice') {
                    this.uploadInvoiceFromMediaAsset()
                  }
                  else {
                    this.uploadFiles()
                  }
                }}
                disabled={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? false : true : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? false : true : this.state.uploadingFilesId.length > 0 ? false : true}
              >
                Insert
              </Button>
              <Button variant="contained" onClick={this.handleUploadCloseDialog}>Cancel</Button>
            </DialogActions>
            :
            null
          }

        </Dialog>
        <Dialog
          open={this.state.openTaskCommentPopUp}
          onClose={this.cancelSpecificTaskComment}
          aria-labelledby="customized-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {"Task Comment"}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.cancelSpecificTaskComment} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Editor
                editorState={this.state.editComment}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                placeholder={this.state.placeholder}
                onFocus={() => this.setState({ placeholder: '' })}
                onBlur={() => this.setState({ placeholder: 'Add Comment' })}
                wrapperStyle={{ height: 200, width: 500, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                editorStyle={{ padding: 10, minHeight: 100, background: 'white' }}
                toolbarStyle={{ height: 50, fontSize: 18, background: "#f5f6fa", marginBottom: "0px", }}
                onEditorStateChange={this.onEditorStateCommentTaskSpecific}
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: this.state.selectedProjectsMentionsAccountsData2
                }}
                hashtag={{
                  separator: ' ',
                  trigger: '#',
                }}
                toolbarCustomButtons={[
                  <Button
                    size="small"
                    onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                    style={{ color: 'white', backgroundColor: colorToBeChanged, position: 'absolute', right: '70px', padding: "5px 15px 5px 15px" }}
                    // startIcon={<SendIcon />}
                    type="submit"
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                  // <button
                  //   style={{
                  //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "200px",
                  //   }}
                  //   type="submit"
                  //   onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                  // ><SendIcon />

                  // </button>

                ]}
                toolbar={{
                  options: ["inline", "link"],
                  inline: {
                    inDropdown: false,
                    className: "test",
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic"],
                  },
                  list: {
                    inDropdown: false,
                    className: "test",
                    component: undefined,
                    dropdownClassName: undefined,
                    // options: ['unordered', 'ordered'],
                    unordered: { className: undefined },
                    ordered: { className: undefined },
                  },
                  link: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    dropdownClassName: undefined,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_self',
                    options: ['link'],
                    linkCallback: undefined
                  },
                  // emoji: {
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   emojis: [
                  //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                  //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '✅', '❎', '',
                  //   ],
                  // },

                  image: {
                    //icon:,
                    urlEnabled: false,
                    uploadEnabled: true,
                    uploadCallback: this.getSignedUrlTaskFileComment,
                    previewImage: false,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  },
                }}
              />
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.submitReviewPopUp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ submitReviewPopUp: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                Please be aware that your feedback will now be submitted. <br />
                If you plan to add more feedback or collect feedback from colleagues, simply close this window.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                  onClick={() => { this.onClickSubmitReview(this.state.fileId) }}
                // onClick={() => { this.setState({ submitReviewPopUp : false }) }}
                >
                  Yes, please submit review
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ submitReviewPopUp: false }) }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.errorBillingDetailsPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Add Billing Details</span>
            <CloseIcon
              style={{
                cursor: "pointer",
                position: 'absolute',
                right: 10,
              }}
              onClick={() => this.setState({ errorBillingDetailsPopup: false, loading: false })}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                You are about to request your first quote but you have not entered the necessary billing details to do so.<br />
                Please navigate to Company Profile or inform your brand admin to do so.
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.userInfoPopup}
          aria-labelledby="responsive-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="responsive-dialog-title" style={{
            textAlign: "center",
            color: secondaryColorToBeChanged
          }}>{"Member Info"}</DialogTitle>
          <DialogContent style={{ minWidth: "400px" }}>
            <DialogContentText>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>First Name</p>
                {this.state.userInfo && this.state.userInfo.data && this.state.userInfo.data.attributes ?

                  <p>{this.state.userInfo?.data?.attributes?.first_name}</p> :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Last Name</p>
                {this.state.userInfo && this.state.userInfo.data && this.state.userInfo.data.attributes ?
                  <p>{this.state.userInfo?.data?.attributes?.last_name}</p>

                  :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Email</p>
                {this.state.userInfo && this.state.userInfo.data && this.state.userInfo.data.attributes ?
                  <p>{this.state.userInfo?.data?.attributes?.email}</p> :

                  <p>No Data</p>

                }
              </div>


              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Designation</p>
                {this.state.userInfo && this.state.userInfo.data && this.state.userInfo.data.attributes ?
                  <p>{this.state.userInfo?.data?.attributes?.designation}</p> :

                  <p>No Data</p>

                }
              </div>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // autoFocus
              style={{
                color: "white",
                backgroundColor: secondaryColorToBeChanged
              }}
              onClick={() => { this.setState({ userInfoPopup: false }) }}>
              close
            </Button>

          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.userInfoPopup2}
          aria-labelledby="responsive-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="responsive-dialog-title" style={{
            textAlign: "center",
            color: secondaryColorToBeChanged
          }}>{"Member Info"}</DialogTitle>
          <DialogContent style={{ minWidth: "400px" }}>
            <DialogContentText>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>First Name</p>
                {this.state.currentTask?.attributes?.reviewer?.data?.attributes?.first_name ?

                  <p>{this.state.currentTask?.attributes?.reviewer?.data?.attributes?.first_name}</p> :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Last Name</p>
                {this.state.currentTask?.attributes?.reviewer?.data?.attributes?.last_name ?
                  <p>{this.state.currentTask?.attributes?.reviewer?.data?.attributes?.last_name}</p>

                  :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Email</p>
                {this.state.currentTask?.attributes?.reviewer?.data?.attributes?.email ?
                  <p>{this.state.currentTask?.attributes?.reviewer?.data?.attributes?.email}</p> :

                  <p>No Data</p>

                }
              </div>


              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Designation</p>
                {this.state.currentTask?.attributes?.reviewer?.data?.attributes?.designation ?
                  <p>{this.state.currentTask?.attributes?.reviewer?.data?.attributes?.designation}</p> :

                  <p>No Data</p>

                }
              </div>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // autoFocus
              style={{
                color: "white",
                backgroundColor: secondaryColorToBeChanged
              }}
              onClick={() => { this.setState({ userInfoPopup2: false }) }}>
              close
            </Button>

          </DialogActions>
        </Dialog>





        <Menu

          id="simple-menu"
          anchorEl={this.state.modelForDelete}
          // keepMounted
          open={Boolean(this.state.modelForDelete)}
          onClose={() => {
            // this.handleClose();
            this.setState({ modelForDelete: false, selectedTaskFromDraggable: null })
          }}
        >

          {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager' || (localStorage.getItem("permissions") == 'viewer' && this.state.permissionFlagViewer)) ? <MenuItem
            onClick={(e) => {
              // this.handleClose("delete");
              this.setState({ deleteDialog: true })
              // this.deleteTask()
            }}
            style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
            className={classes.dropDown}
          >
            <div className={classes.circle}>
              <img src={deleteLogo} />
            </div>
            {t("AdminConsole.delete")}
            {/* Delete */}
          </MenuItem> : null
          }


          {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager' || localStorage.getItem("permissions") == 'viewer') && this.state.selectedTaskFromDraggable ? <MenuItem
            onClick={(e) => {
              this.state.selectedTaskFromDraggable != "Exists" ? this.setState({ flagCreateTemplate: true }) : null
            }}
            style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
            className={classes.dropDown}
          >
            <div className={classes.circle}>
              <NoteAddIcon />
            </div>
            {this.state.selectedTaskFromDraggable == "Exists" ? "Template Already Exists" : "Create new briefing with same inputs"}
          </MenuItem> : null
          }

          {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager' || localStorage.getItem("permissions") == 'viewer') ? <MenuItem
            onClick={(e) => {
              this.setState({ taskDuplicateFlag: true })
            }}
            style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
            className={classes.dropDown}
          >
            <div className={classes.circle}>
              <img src={duplicateButton} />
            </div>
            Duplicate
          </MenuItem> : null
          }
        </Menu>

        <Dialog
          open={this.state.taskDuplicateFlag}
          onClose={() => this.setState({ taskDuplicateFlag: false })}
          aria-labelledby="duplicateTemplate"
        >
          <Formik
            initialValues={{
              name: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "template name is required"
              ),
            })}
            onSubmit={(values) => {
              this.setState({ duplicateTaskName: values.name })
              this.callCreateDuplicateTaskApi(this.state.currentTask);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {
              return (
                <div style={{ width: 800 }}>
                  <form onSubmit={handleSubmit}>
                    <DialogTitle id="duplicateTemplate">Duplicate Task</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        autoFocus
                        id="name"
                        label="Task Name"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        helperText={
                          touched.name &&
                            errors.name ? (<>
                              Task name is required
                            </>
                          ) : (
                            ""
                          )
                        }
                        error={
                          touched.name && errors.name
                            ? true
                            : false
                        }

                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => this.setState({ taskDuplicateFlag: false, modelForDelete: false })} color="secondary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Duplicate Task
                      </Button>
                    </DialogActions>
                  </form>
                </div>
              )
            }}
          />

        </Dialog>

        <Dialog
          open={this.state.flagCreateTemplate}
          onClose={() => this.setState({ flagCreateTemplate: false })}
          aria-labelledby="createTemplate"
        >
          <Formik
            initialValues={{
              name: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "template name is required"
              ),
            })}
            onSubmit={(values) => {
              this.setState({ TemplateName: values.name }, () => {
                this.createTemplateTask()
              })
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {
              return (
                <div style={{ width: 800 }}>
                  <form onSubmit={handleSubmit}>
                    <DialogTitle id="createTemplate">Create Template</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="outlined"
                        autoFocus
                        id="name"
                        label="Template Name"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        helperText={
                          touched.name &&
                            errors.name ? (<>
                              Template name is required
                            </>
                          ) : (
                            ""
                          )
                        }
                        error={
                          touched.name && errors.name
                            ? true
                            : false
                        }

                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => this.setState({ flagCreateTemplate: false, modelForDelete: false })} color="secondary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Create new Template
                      </Button>
                    </DialogActions>
                  </form>
                </div>
              )
            }}
          />

          {/* <div style={{ width: 800, fontFamily: primaryFont }}>
        <form onSubmit={(e:any)=>{
          e.preventDefault()
          this.createTemplateTask()
          }}>
        <DialogTitle id="createTemplate">Create Template</DialogTitle>
        <DialogContent>
          <TextField
          variant="outlined"
            autoFocus
            id="name"
            label="Template Name"
            type="text"
            fullWidth
            onChange={(e:any)=>this.setState({TemplateName:e.target.value})}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({flagCreateTemplate:false})} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
          Create new Template
          </Button>
        </DialogActions>
        </form>
        </div> */}


        </Dialog>

        <Dialog
          open={this.state.deleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteDialog: false, modelForDelete: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                  // onClick={() => { this.onClickSubmitReview(this.state.fileId) }}
                  // onClick={() => { this.setState({ submitReviewPopUp : false }) }}
                  onClick={() => this.deleteTask()}
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteDialog: false, modelForDelete: false }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>



        <ReactS3Uploader
          type="file"
          id="upload"
          name="pic"
          // className={uploaderClassName}
          getSignedUrl={localStorage.getItem("task") == '3' ? this.getSignedUrlInBriefing : localStorage.getItem("task") == '4' ? this.getSignedUrlImageInBriefing : (localStorage.getItem("task") == '2' && this.state.replaceFlag == 1) ? this.removeImageAndGetSignedUrl : this.getSignedUrl}
          //accept="image/*"
          // onProgress={onProgress}
          // onError={onError}
          // onFinish={onFinish}
          uploadRequestHeaders={{
            'x-amz-acl': 'public-read'
          }}
          multiple
          s3path="/uploads/"
          contentDisposition="auto"
          accept={localStorage.getItem("task") == "invoice" ? "application/pdf" : "**"}
          // multiple
          capture={true}
          //  ref={this.fileInput}
          inputRef={cmp => this.fileInput2 = cmp}
          onChange={(event: any) =>
            this.inputFileChanged(event)
          }
          style={{
            visibility: "hidden",
            position: "absolute",
            // width: "280px",
            alignContent: "center",
            backgroundColor: "white",
            color: secondaryColorToBeChanged,
            width: "280px",
            padding: 20,
            fontSize: 16,
            fontWeight: "bold",
            borderWidth: 1,
            borderColor: secondaryColorToBeChanged,
            borderRadius: 10,
            borderStyle: "dashed",
            fontVariant: "initial",
            textTransform: "none",
            marginBottom: "20px",
          }}
        />

        <Dialog
          open={this.state.deleteStatusDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteStatusDialog: false, modelForDelete: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                  // onClick={() => { this.onClickSubmitReview(this.state.fileId) }}
                  // onClick={() => { this.setState({ submitReviewPopUp : false }) }}
                  // onClick={() => this.deleteTask()}
                  onClick={() => this.deleteStatus()}
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteStatusDialog: false, modelForDelete: false }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>


        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorE4}
          keepMounted
          open={Boolean(this.state.anchorE4)}
          onClose={() => {
            this.handleCloseStatus();
          }}
        >
          <MenuItem
            // data-my-value={item.id}
            onClick={(e: any) => {
              this.setState({ editStatusDialog: true, anchorE4: null })
            }}
            style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            className={classes.dropDownStatus}
          >
            <span className={classes.taskImgsStatus} >
              <img className={classes.taskImgStatus} src={projectPropertiesButton} /> </span>
            Rename
          </MenuItem>

          {this.state.columnsKanban[this.state.selectedStatus?.attributes.status]?.tasks?.length == 0 && <MenuItem
            // data-my-value={item.id}
            onClick={(e: any) => {
              this.setState({ deleteStatusDialog: true, anchorE4: null })
            }}
            style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            className={classes.dropDownStatus}
          >
            <span className={classes.taskImgsStatus} >
              <img className={classes.taskImgStatus} src={deleteButton} /> </span>
            Delete
          </MenuItem>}
        </Menu>


        <div >
          <Dialog maxWidth={false} open={this.state.createTempeletePopUp} onClose={this.closeCreateTempleteModel} aria-labelledby="form-dialog-title">
            <div style={{ width: 800, fontFamily: primaryFont }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>
                  {/* {t("ApprovalRequest.createNewStatus")} */}
                  Create New Template
                </span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.closeCreateTempleteModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  name: "",
                  project_id: "",

                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(
                    "template name is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.createProjectTemplete(values)
                  // this.callCreateStatusApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" style={{
                              marginBottom: 10,
                              marginTop: 3,
                              color: 'primary'
                            }} fullWidth
                              label="Template Name"
                              className={classes.confirmInput}
                              name="name"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Template Name" variant="outlined"
                              helperText={
                                touched.name &&
                                  errors.name ? (<>
                                    Template name is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.name && errors.name
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>
                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >

                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            Create Template
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>

          <Dialog
            onClose={() => this.setState({ openPdf: false })}
            aria-labelledby="customized-dialog-title"
            open={this.state.openPdf}
          >
            <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
              {this.state.openPdfURL?.name}
              <span style={{ position: "absolute", right: "10px" }}>
                <CloseIcon onClick={() => this.setState({ openPdf: false, pdfPageNumber: 1 })} />
              </span>
            </DialogTitle>
            <DialogContent>
              <div>
                <Document
                  file={this.state.openPdfURL?.url}
                  onLoadSuccess={this.onDocumentPdfLoadSuccess}
                >
                  <Page pageNumber={this.state.pdfPageNumber} />
                </Document>
                {/* <p>Page {this.state.pageNumber} of {this.state.numPages}</p> */}
                <nav className={classes.pdfPageNavigation}>
                  <p>
                    Page {this.state.pdfPageNumber} of {this.state.numPdfPages}
                  </p>
                  {this.state.numPdfPages > 1 && <button
                    className={classes.pdfPageNavigationPrevButton}
                    style={this.state.pdfPageNumber > 1 ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                    disabled={this.state.pdfPageNumber > 1 ? false : true}
                    onClick={this.goToPrevPdfPage}
                  >
                    Prev Page
                  </button>}
                  {this.state.numPdfPages > 1 && <button
                    className={classes.pdfPageNavigationNextButton}
                    style={this.state.numPdfPages && this.state.numPdfPages > this.state.pdfPageNumber ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                    disabled={this.state.numPdfPages && this.state.numPdfPages > this.state.pdfPageNumber ? false : true}
                    onClick={this.goToNextPdfPage}
                  >
                    Next Page
                  </button>}
                </nav>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div >
    );
  }

}

export default withStyles(styles)(withTranslation()(ProjectPortfolio))
