import React, { Fragment } from "react";
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  HashRouter,
  withRouter
} from "react-router-dom";
import { Link } from '@material-ui/core';
const defaultUser = require("./defaultuser.png")
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Input from '@material-ui/core/Input';
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfOutlined"
import htmlToDraft from 'html-to-draftjs';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML, } from 'draft-js';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import GridList from '@material-ui/core/GridList';
import Slider from '@material-ui/core/Slider';
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import ForumIcon from '@material-ui/icons/Forum';
import Pagination from '@material-ui/lab/Pagination';
import InfiniteScroll from "react-infinite-scroll-component";
const activeProjects = require("./activeProjects.png");
const deleteProjects = require("./deleteProjects.png");
const archiveProjects = require("./archiveProjects.png");
import Divider from '@material-ui/core/Divider';
import { FormatBold, FormatListBulleted, AttachFile, FormatItalic, FormatLineSpacing, InsertEmoticon, Telegram, InsertDriveFileOutlined } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import Svg from "../../components/src/svgComponents/Svg";
import { BlockComponent } from "../../framework/src/BlockComponent";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import HomePage from "./HomePage.web";
import AccountProfileWeb from "./AccountProfile.web";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
// import Slider from '@material-ui/core/Slider';
import * as SparkMD5 from 'spark-md5';
// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CardMedia from '@material-ui/core/CardMedia';
import Backdrop from "@material-ui/core/Backdrop";
export const configJSON = require("./config");
import './styles/scrollbar.css'
// custom imports
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Icon from "react-native-vector-icons/FontAwesome";
import Box from "@material-ui/core/Box";
import { SearchBar } from "react-native-elements";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MuiListItem from "@material-ui/core/ListItem";
import { withTranslation, Trans } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import FormGroup from '@material-ui/core/FormGroup';
import ProjectsWeb from "../../blocks/ProjectPortfolio/src/Projects.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ProjectPortfolioWeb from "../../blocks/ProjectPortfolio/src/ProjectPortfolio.web";
import ProjectsTypes from "../../blocks/ProjectPortfolio/src/ProjectsTypes.web";
import TeamBuilderWeb from "../../blocks/TeamBuilder/src/TeamBuilder.web";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import MultilevelApprovalRequestWeb from "../../blocks/MultilevelApproval/src/MultilevelApprovalRequest.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import BrandAssets from "../../blocks/categoriessubcategories/src/BrandAssets";
import MediaAssets from "../../blocks/categoriessubcategories/src/MediaAssets";
import BrandGroup from "../../blocks/categoriessubcategories/src/BrandGroup"
import MultilevelApprovalWeb from "../../blocks/MultilevelApproval/src/MultilevelApproval.web";
import ApprovalRequestWeb from "../../blocks/ProjectPortfolio/src/ApprovalRequest.web";
import Brands from '../../blocks/ProjectPortfolio/src/Brands.web';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import HubSpotChat from "../../blocks/HubspotIntegration/src/useHubSpotChat"
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ReactS3Uploader from "react-s3-uploader";
import Loader from "./Loader";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FolderMediaAssets from "../../blocks/categoriessubcategories/src/FolderMediaAssets";
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Archive } from "@material-ui/icons";
const duplicateButton = require("../src/assets/duplicate.png");
// import MenuIcon from '@material-ui/icons/Menu';
export const imgFileTemplate = require("./ic_file.png");
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();
const CancelIcon = require("./assets/close_white.png");
const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;
const minRowsValue: any = 5;
const CustomSlider = withStyles({
  root: {
    color: "#1a76b0",
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#1a76b0",
    border: "1px solid 1a76b0",
    marginTop: -9,
    marginLeft: -11,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#1a76b0",
  },
})(Slider);
const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});
const logo = require("./image_logo_dark.png");
const plusButton = require("./plus-top-bar.png");
const notificationIcon = require("./notification.png");
const notificationWithoutDotIcon = require("./notificationWithoutDot.png")
const retakeIcon = require("./notification.png");
const searchIcon = require("./search.png");
const userImage = require("./defaultuser.png");
const projectLogo = require("../../components/src/svgFiles/projects.svg");
const mediaAssetLogo = require("./media-assets-library.png");
const quoteRequestLogo = require("./quote-requests-1.png");
const teamsLogo = require("./teams.png");
const favouriteStartedLogo = require("./favourite-started.png");
const recentLogo = require("./recent.png");
const downArrow = require("./down.png");
const BMWImage = require("./volkswagen.svg");
//const designHarvestLogo = require("../../components/src/design-harvest-logo-icon.png");
const designHarvestLogo = require("./design-harvest-logo-icon.png");
const croppedImageLogo = require("../../components/src/logo-cropped.png");
const imageIcon = require("../../components/src/svgFiles/image.svg");
const companyAccountIcon = require("../src/assets/Fill 1@2x.png");
const logoutIcon = require("../src/assets/Group 6@2x.png");
const notificationPreferenceIcon = require("../src/assets/Group 8@2x.png");
const helpIcon = require("../src/assets/Group 12@2x.png");
const myAccountIcon = require("../src/assets/Group 14@2x.png");
const leftViewBg = require("./view-bg.png");
const notificationPreferenceSettings = require("./assets/notification_popup.png");
import { Editor } from 'react-draft-wysiwyg';
import FilterListIcon from '@material-ui/icons/FilterList';
import SendIcon from '@material-ui/icons/Send';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isNull } from "lodash";
import BrandGroupWeb from "../../blocks/ProjectPortfolio/src/BrandGroup.web";
import SearchResults from "./SearchResults.web";
//import { userInfo } from "node:os";

const ListItem = withStyles({
  root: {
    "&$selected": {
      color: "white!important",
      backgroundColor: "#1a76b0",
      borderRadius: "10px",
    },
  } as const,
  selected: {},
})(MuiListItem);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    padding: "24px 13px 30px 28px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);

const styles = {
  logos: {
    position: "fixed",
    bottom: "40px",
    left: "20px",
  },
  root: {
    flexGrow: 1,
    position: "relative",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  Icons: {
    color: "#1a76b0",
  },
  fileName: {
    fontSize: "14px",
    marginTop: "-12px",
    marginLeft: "17px",
  },
  date: {
    fontSize: "12px",
    marginLeft: "190px",
  },
  fileSize: {
    fontSize: "10px",
    marginLeft: "-84px",
    marginTop: "10px",
  },
  projectDocument: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px",
    marginTop: "20px",
  },
  projectDocuments: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center",
  },

  active: {
    //  "& text1":{
    // color:"white"
    //  },
    "& Icons": {
      color: "#1a76b0",
    },

    "& p": {
      // backgroundColor: "red",
      color: "#ffffff",
      textDecorationLine: "none",
    },
    "& img": {
      color: "#ffffff",
    },

    backgroundColor: "#1a76b0",
    borderRadius: "10px",
  },

  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  confirmInput: {
    marginTop: "15px",
  },
  sideNav: {
    backgroundColor: "#f5f6fa",
    boxShadow: "#f5f6fa",
    borderRight: " 1px solid #e4e8f0",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    padding: "10px",
    width: "100%",
    zIndex: "999",
    "& a": {
      color: "#1a76b0 !important",
    },
  },
  designHarvest: {
    marginTop: "30px",
  },
  Board: {
    marginTop: "30px",
  },
  PersonalBoard: {
    // marginTop: "10px",
  },
  designHarvestText: {
    Color: "#173e59",
    fontSize: "15px",
  },
  recentBoard: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  recent1: {
    marginTop: "5px",

    width: "218px",
    borderRadius: "20px",
  },

  started: {
    marginTop: "20px",
    width: "218px",
    borderRadius: "20px",
  },
  recent: {
    paddingTop: "5px",
    marginBottom: "5px",
    width: "218px",
    borderRadius: "20px",
  },
  mediaAssetLogo: {
    marginTop: "20px",

    width: "218px",
    borderRadius: "20px",
  },
  teamsLogoimage: {
    marginTop: "20px",

    width: "218px",
    borderRadius: "20px",
  },
  approvalRequestImage: {
    marginTop: "20px",

    width: "218px",
    borderRadius: "20px",
  },

  text: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  text1: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  favouriteText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  recentText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  Mediatext: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  teamsText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  approvalText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  foottext: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "11px",
    marginLeft: "3px"
  },

  footer: {
    position: "fixed",
    bottom: "33px",
    marginLeft: "15px",
  },
  footerBackImage: {
    position: "absolute",
    top: "-97px",
    right: "-133px",
    height: "198px",
    opacity: "0.15"
  },
  foot: {
    position: "absolute",
    // marginTop: "30px",
    bottom: "10px",
    display: "flex",

    // marginLeft: "40px",
  },
  dropDown: {
    fontSize: "12px",
    color: "#1a76b0",
    fontFamily: 'Work Sans',
  },
  footerText: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  header: {
    marginTop: "40px",
  },
  designHarvestLogo: {
    height: "80px",
    width: "155px",
  },
  routingPages: {
    backgroundColor: "#ffff",
    position: "relative",
    left: "16.6%",
  },
  navArea: {
    marginBottom: "60px",
  },
  plusButtonIcon: {
    textAlign: "end",
  },
  taskImgs: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: "#fff",
    border: "1px solid #e4e8f0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginRight: "6px",
  },
  taskImg: {
    height: "36px",
    objectFit: "contain",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "99",
  } as const,
  // root1: {

  //   backgroundColor: 'red'
  // },
  listItemText: {
    fontSize: "0.8em", //Insert your required size
    // color:"#5b6b96",
    // color: secon
    fontFamily: localStorage.getItem("key") ? JSON.parse(localStorage.getItem("key") || `{}`).font : ""
  },
  listItemTextApproval: {
    // fontSize: "11px!important"
    fontFamily: localStorage.getItem("key") ? JSON.parse(localStorage.getItem("key") || `{}`).font : ""
  },
  Icon: {
    color: "blue",
  },
  iconActive: {
    color: "white",
  },
  fadeIcon: {
    color: "#1a76b0",
  },
  imageFooter: {
    marginLeft: "16px",
    position: "fixed",
    bottom: "11%",
  },
  MenuListItems: {
    // zIndex:"1"
  },
  firstCard: {
    height: "220px",
    borderRadius: "8px",
    marginBottom: "10px"
  },
  leftViewBg: {
    position: "absolute",
    right: "0",
    bottom: "0",
    zIndex: "-1"
  },
  root1: {
    "&:selected": {
      backgroundColor: "red",
      color: "white",
    },
  },
  selected: {},
  notificationCardRoot: {
    backgroundColor: "#e4e8f0",
    //height: "auto",
    // height: "640px",
    // overflow: "overlay"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  //  "MuiListItemText-primary" : {
  //   fontFamily : localStorage.getItem("key") ? JSON.parse(localStorage.getItem("key") || `{}`).font : "Work Sans"
  //  },
  allNotificationCard: {
    boxShadow: "none",
  },
  notificationCardHover: {
    '&:hover': {
      backgroundColor: "#fff",
      boxShadow: "0 3px 3px rgba(0,0,0,0.2)"
    }
  },
  notificationCalenderRow: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "start",
  },
  notificationCicle: {
    background: 'white',
    borderRadius: '50%',
    border: "1px solid #e4e8f0",
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  templateHover: {
    backgroundColor: "rgb(52, 63, 86)",
  },
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },
  inputSearchBox: {
    width: "200px",
    marginTop: '4px',
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    //     avatar:{
    // display:"inline-flex !important"
    //     },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    minWidth: "72px",
    width: "auto",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  activeButton: {
    color: "#ffffff",
    height: "45px",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  highlightText: {
    padding: "5px 5px 5px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
    zIndex: "9999"
  },
  TaskViewTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: 'center',
    alignItems: "center",
    color: "#000000",
    padding: "20px 20px",
  },
  selectInput: {
    marginBottom: "20px",
  },
  progress: {
    display: "flex",
    flexDirection: "row"
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
  backdrop2: {
    zIndex: 9998,
    color: "#fff",
  },
  labelTextForBriefingFields: {
    width: "484px",
    height: "19px",
    margin: "10px 13px 4px 1px",
    fontFamily: "'WorkSans',sansSerif",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineSeight: "normal",
    letterSpacing: "normal",
    color: "#282828",
  },
  labelTextForBriefingFieldsDescription: {
    width: "484px",
    height: "auto",
    margin: "0px 0px 40px 0px",
    fontFamily: "'WorkSans',sansSerif",
    fontSize: "10px",
    color: "#282828",
    fontWeight: 400,
    lineHeight: '2',
  },
} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any

  // Customizable Area Start
  // Customizable Area End
}
const PrettoSlider = withStyles({
  root: {
    color: '#1a76b0',
    height: 8,
  },
  // textReadmore:{
  //   position:"absolute",
  //   right:0
  // },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
// Customizable Area Start
interface S {
  selectSeacrh: any;
  startBriefingOpen: boolean;
  searchValue: any;
  filterValue: any;
  isSearch: boolean;
  taskData: any;
  brandTeamsData: any;
  selectedIndex: number;
  anchorEl: null;
  anchorE2: null;
  setAnchorEl: null;
  activeStatus: any;
  sideNavStatus: any;
  setOpen: any;
  value: any;
  sharedProject: any;
  sharedProjectError: any;
  currentLink: any;
  setNotification: boolean;
  notificationData: [];
  task_notification_email_frequency: any;
  mute_notifications: any;
  tagged_email_notifications: any;
  task_email_notifications: any;
  account_notification_id: any;
  userDetails: any;
  permissions: any;
  permissionName: any;
  projectDataDrop: any;
  alertNotificationsData: any;
  setAlertNotification: boolean;
  page: any;
  projectSelectedDataByBrandId: any;
  projectSelectedDataByBrandId2: any;
  total_pages: any;
  setPage: any;
  per_page: any;
  isNextFunc: any;
  anchorE3: any;
  projectSortByType: any;
  anchorE4: any;
  defaultSortOrder: any;
  teamBased: any;
  toDo: any;
  approvalNotification: any;
  anchorE5: any;
  selectedBrandForProjectCreate: any;
  selectedUsersForProjectCreate: any;
  brandsData: any;
  accountsData: any;
  openTaskCreateModel: any,
  editorState: any,
  currentTask: any,
  presignedData: any;
  templateFormFieldsData: any,
  openAddBriefingModel: boolean,
  openStartBriefingModel: boolean,
  isComingFromBriefingForm: boolean;
  setOpen2: any,
  category: string,
  selectedTaskTemplate: any,
  isLabel: any,
  SelectedCategory: any,
  fileName: any,
  fileToRawBinary: any,
  templatesData: any,
  assetType: string,
  convertedCommentData: any,
  selectedProjectsMentionsAccountsData2: any,
  assetCategoriesData: any,
  assetTypesData: any,
  loading: any,
  otherloading: any,
  templateBasedOnSelectedAssetType: any,
  brandSelected: any,
  projectsOfStatusDraftData: any,
  projectSelectedTask: any,
  onBoardingShow: any;
  isCurrentTaskSelected: any,
  fromStart: any;
  fromEnd: any;
  paginationCount: any;
  pageSeparatorIndex: any;
  setOpenAnnotaion: any;
  blockersData: any,
  allowAnnotation: any;
  subTasksData: any,
  fileTaskName: any,
  currentTaskBriefingData: any,
  onGoingArrowFlag: any;
  fileId: any,
  file_blob_signed_id_url: string,
  getTaskCommentsData: any,
  fileImage: any;
  taskStatusesData: any
  dueDate: any,
  percentage: any;
  columnOrderKanban: any,
  columnsKanban: any,
  dynamicMessages: any,
  dynamicStatus: any,
  templateFormConditionsData: any,
  fileNameImage: any;
  presignedDataImage: any;
  resp: any,
  projectStatus: any
  brandDetail: any
  uploadDialog: any
  mediaState: any
  dialogTabValue: any
  assetData: any
  brandMediaAssets: any
  mediaFolderAssetdFiles: any
  projectFilesAssetsData: any
  projectFoldersAssetsData: any
  uploadingFilesId: any
  uploadingFilesName: any
  fileOptionId: any
  inviteDialog: any
  inviteEmail: string
  inviteEmailError: any;
  inviteUserName: any;
  selectedOptions: any;
  longTextEditorContent: any;
  briefingFieldId: any
  briefingFiles: any
  briefingMediaFiles: any
  briefingImages: any
  briefingMediaImages: any
  submitProduction: any
  flagForMediaUpload1: any
  flagForMediaUpload2: any
  flagForImage: any
  mediaAssetSelected: any
  folderSelected: any
  openPdfURL: any
  openPdf: any
  pdfPageNumber: any
  numPdfPages: any
  setOpenImageDialog: any
  flagForPreviewImage: any
  // uploadingFilesId: any
  uploadingFilesId1: any
  uploadingFilesId2: any
  signedIdAndURLs: any;
  signedIdAndURLs2: any;
  signedIdAndURLs3: any;
  countForFile: any
  countUpload: any
  countUpload2: any
  fileData1: any
  fileData2: any
  loading2: any
}
const initialData = {
  tasks: {
    'task-1': { id: 'task-1', content: 'Take out the garbage' },
    'task-2': { id: 'task-2', content: 'Watch my favorite show' },
    'task-3': { id: 'task-3', content: 'Charge my phone' },
    'task-4': { id: 'task-4', content: 'Cook dinner' },
  },
  columns: {
    'UnStarted': {
      id: 'UnStarted',
      title: 'UnStarted',
      // taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
      tasks: [],

    },
    'Open': {
      id: 'Open',
      title: 'Open',
      // taskIds: [],
      tasks: []
    },
  },
  // Facilitate reordering of the columns
  columnOrder: [{
    id: 'UnStarted',
    title: 'UnStarted',
    messageId: '',

  },
  {
    id: 'Open',
    title: 'Open',
    messageId: '',

  },],

};
interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  fileInput: any
  fileInput1: any
  fileInput2: any
  fileInput3: any
  fileInput10: any

  static instance: HomeScreen;
  showNotificationAPICall: string = ""
  apiSendNoticationCallId: string = ""
  showProjectsName: any;
  showAlertNotificationsAPICall: any;
  getProjectByBrandId: any;
  getApproveProjectByBrandId: any;
  projectCreateApiCallId: any
  callGetAccountListApiId: any
  getBrandListingId: any
  projectApiCallId: any
  callCategoryprojectApiCallId: any
  getProjectByBrandIdUsingFilter: any;
  getProjectByBrandIdUsingFilter2: any;
  callCreateTaskCommentListApiID: any;
  getBrandTeamId: any;
  callCreateBriefingApiId: any;
  getSignedURLForFileUploadId: any;
  projectTaskCreateAPICallId: any;
  getSignedURLForFileUploadTaskFileID: any;
  getSignedUrlInBriefingFileID: any;
  getTemplateFormFieldsAPIId: any;
  projectIndexApiCallIdType1: any;
  getTasksByFilterApiCallId: any;
  getBrandsByFilterApiCallId: any;

  callUpdateTaskQuotationApiID: any;
  callStateTransitionsAPIId: any;
  updateTaskAPIId: any;
  callUpdateSubTaskApiID: any;
  statusUpdateToRequestQuoteId: any
  callUpdateTaskBlockerApiID: any;


  callShowTaskApiId: any;
  subtaskAPiEndPointID: any;
  callGetStatusListApiId: any;
  //callShowTaskApiId:any;
  blockersAPiEndPointID: any


  callCreatetaskTemplateApiId: any;
  taskTemplateAPiEndPointID: any;

  getTemplateFormConditionsAPIId: any


  getSignedUrlInBriefingImageID: any;
  bulkTaskCommentUploadingID: any


  getSignedURLForFileUploadId2: Array<any> = [];
  callToBulkFileUpload: any
  getTaskFileId: any


  getBrandDetailId: any

  getAssetCategoryApiCallId: any
  getMediaAssetsApiCallId: any


  getMediaFolderAssetFilesApiCallId: any
  getAssetCategoryFileApiCallId: any
  getAssetCategoryFolderApiCallId: any

  uploadFilesAPIId: any
  uploadBriefingFilesAPIId: any
  inviteUserApiCallId: any;
  submitProductionApiCallId: any
  array: Array<any> = []
  array2: Array<any> = []
  array3: Array<any> = []
  imgRef: React.RefObject<any>;
  arratMD5: Array<any> = []
  arrat2MD5: Array<any> = []
  temp: any = [];
  constructor(props: Props) {
    super(props);
    this.fileInput = React.createRef();
    this.fileInput1 = React.createRef();
    this.fileInput2 = React.createRef();
    this.fileInput3 = React.createRef();
    this.fileInput10 = React.createRef();

    HomeScreen.instance = this;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

    ]
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      selectSeacrh: "task",
      startBriefingOpen: false,
      searchValue: "",
      isSearch: false,
      filterValue: "",
      taskData: [],
      brandTeamsData: [],
      selectedIndex: 7,
      allowAnnotation: false,
      blockersData: [],
      presignedData: [],
      subTasksData: [],
      fileTaskName: [],
      taskStatusesData: [],
      onGoingArrowFlag: true,
      fileId: 1,
      fileImage: '',
      columnOrderKanban: initialData.columnOrder,
      columnsKanban: {},
      dynamicMessages: {},
      getTaskCommentsData: [],
      dynamicStatus: {},
      dueDate: null,
      file_blob_signed_id_url: '',
      currentTaskBriefingData: [],
      anchorEl: null,
      anchorE2: null,
      setAnchorEl: null,
      activeStatus: "",
      sideNavStatus: false,
      setOpen: false,
      percentage: 74,
      value: 0,
      sharedProject: "",
      sharedProjectError: false,
      currentLink: props,
      setNotification: false,
      notificationData: [],
      task_notification_email_frequency: '',
      mute_notifications: '',
      tagged_email_notifications: '',
      task_email_notifications: '',
      account_notification_id: '',
      userDetails: '',
      permissions: '',
      permissionName: "",
      projectDataDrop: [],
      alertNotificationsData: [],
      setAlertNotification: false,
      page: 1,
      projectSelectedDataByBrandId: [],
      projectSelectedDataByBrandId2: [],
      total_pages: 1,
      setPage: 1,
      per_page: 10,
      isNextFunc: false,
      anchorE3: null,
      projectSortByType: 'name',
      anchorE4: null,
      defaultSortOrder: true,
      teamBased: [],
      toDo: [],
      approvalNotification: [],
      anchorE5: null,
      selectedBrandForProjectCreate: "",
      selectedUsersForProjectCreate: "",
      brandsData: [],
      accountsData: [],
      openTaskCreateModel: false,
      editorState: EditorState.createEmpty(),
      currentTask: {},
      templateFormFieldsData: [],
      openAddBriefingModel: false,
      openStartBriefingModel: false,
      isComingFromBriefingForm: false,
      setOpen2: false,
      category: '',
      selectedTaskTemplate: null,
      isLabel: '',
      SelectedCategory: {},
      fileName: '',
      fileToRawBinary: {},
      templatesData: [],
      assetType: '',
      convertedCommentData: {},
      selectedProjectsMentionsAccountsData2: [],
      assetCategoriesData: [],
      assetTypesData: [],
      loading: false,
      otherloading: false,
      templateBasedOnSelectedAssetType: {},
      brandSelected: null,
      projectsOfStatusDraftData: [],
      projectSelectedTask: null,
      isCurrentTaskSelected: false,
      fromStart: 0,
      fromEnd: 0,
      paginationCount: "",
      pageSeparatorIndex: [],
      setOpenAnnotaion: false,
      templateFormConditionsData: {},
      fileNameImage: '',
      presignedDataImage: [],

      onBoardingShow: true,
      resp: null,
      projectStatus: 0,
      brandDetail: null,
      uploadDialog: false,
      mediaState: 0,
      dialogTabValue: "MediaAssest",
      assetData: [],
      brandMediaAssets: [],
      mediaFolderAssetdFiles: [],
      projectFilesAssetsData: [],
      projectFoldersAssetsData: [],
      uploadingFilesId: [],
      uploadingFilesName: [],
      fileOptionId: null,
      inviteDialog: false,
      inviteEmail: "",
      inviteEmailError: false,
      inviteUserName: "",
      selectedOptions: [],
      longTextEditorContent: [EditorState.createEmpty()],
      briefingFieldId: null,
      briefingFiles: [{}],
      briefingMediaFiles: [{}],
      briefingImages: [{}],
      briefingMediaImages: [{}],
      submitProduction: false,
      flagForMediaUpload1: null,
      flagForMediaUpload2: null,
      flagForImage: false,
      mediaAssetSelected: null,
      folderSelected: null,
      openPdfURL: null,
      openPdf: false,
      pdfPageNumber: 1,
      numPdfPages: null,
      setOpenImageDialog: false,
      flagForPreviewImage: false,
      // uploadingFilesId: [],
      uploadingFilesId1: [],
      uploadingFilesId2: [],
      signedIdAndURLs: [],
      signedIdAndURLs2: [],
      signedIdAndURLs3: [],
      countForFile: 0,
      countUpload: 0,
      countUpload2: 0,
      fileData1: null,
      fileData2: null,
      loading2: false

    };
  }
  handleUploadTabChange = (event: any, value: any) => {
    this.setState({ dialogTabValue: value });
  };
  defaultSortOrderHandleChange = (event: any) => {
    this.setState((preState) => ({
      defaultSortOrder: !preState.defaultSortOrder
    }));
  }
  setAssetType(templateBasedOnSelectedAssetType?: any) {
    this.setState({
      templateBasedOnSelectedAssetType: templateBasedOnSelectedAssetType,
    });

    this.callGetTemplateListApi(templateBasedOnSelectedAssetType.id)


  }
  createRamdomCode = () => {
    var url_code = "";
    var useCharacters = "1234567890";
    for (var i = 0; i < 1; i++) {
      url_code += useCharacters.charAt(Math.floor(Math.random() * useCharacters.length));
    }
    return url_code;
  }
  uploadBriefingFiles = () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody;

    httpBody = {
      data: {
        "template_form_field_id": parseInt(this.state.fileOptionId),
        "media_asset_file_ids": this.state.uploadingFilesId,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadBriefingFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_fields/template_file_clone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadFiles = () => {
    this.setState({ loading: true })

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody;

    if (localStorage.getItem("task") == "2") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": false,
          "source_file": true,
        }
      };
    }
    else if (localStorage.getItem("task") == "1") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": true,
          "source_file": false,
        }
      };
    }
    else if (localStorage.getItem("task") == "0") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": false,
          "source_file": false,
        }
      };
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks/task_file_clone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkBoxChange = (assetIds: any) => {
    console.log("assetIds", assetIds);

    const assetId = parseInt(assetIds?.id);
    if (localStorage.getItem("task") == '3') {
      if (this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(assetId)) {
        var temp = this.state.briefingMediaFiles;
        // var tempFile = this.state.uploadingFilesName1;

        const index = temp[0]?.[this.state.fileOptionId].uploadedFileId.indexOf(assetId);
        if (index > -1) {
          temp[0]?.[this.state.fileOptionId].uploadedFileId.splice(index, 1);
          temp[0]?.[this.state.fileOptionId].uploadedFileName.splice(index, 1);
        }
        this.setState({
          briefingMediaFiles: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaFiles)
        )
      }
      // if (this.state.uploadingFilesId1.includes(assetId)) {
      //   var temp = this.state.uploadingFilesId1;
      //   var tempFile = this.state.uploadingFilesName1;

      //   const index = temp.indexOf(assetId);
      //   if (index > -1) {
      //     temp.splice(index, 1);
      //     tempFile.splice(index, 1);
      //   }
      //   this.setState({
      //     uploadingFilesId1: temp,
      //     uploadingFilesName1: tempFile,
      //     fileName: tempFile
      //   }, () => console.log("filename", this.state.fileName, this.state.uploadingFilesId1)
      //   )

      // }
      else {
        var temp = this.state.briefingMediaFiles;
        // var tempFile = this.state.uploadingFilesName1;
        if (!temp[0]?.[this.state.fileOptionId]) {
          temp[0][this.state.fileOptionId] = {
            uploadedFileId: [],
            uploadedFileName: []
          }
        }
        temp[0]?.[this.state.fileOptionId].uploadedFileId.push(assetId)
        temp[0]?.[this.state.fileOptionId].uploadedFileName.push(assetIds?.attributes?.name)
        // tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          briefingMediaFiles: temp,
          //  uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaFiles))
      }

    }
    else if (localStorage.getItem("task") == '4') {
      if (this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(assetId)) {
        var temp = this.state.briefingMediaImages;
        // var tempFile = this.state.uploadingFilesName1;

        const index = temp[0]?.[this.state.fileOptionId].uploadedFileId.indexOf(assetId);
        if (index > -1) {
          temp[0]?.[this.state.fileOptionId].uploadedFileId.splice(index, 1);
          temp[0]?.[this.state.fileOptionId].uploadedFileName.splice(index, 1);
        }
        this.setState({
          briefingMediaImages: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaImages))
      }
      // if (this.state.uploadingFilesId1.includes(assetId)) {
      //   var temp = this.state.uploadingFilesId1;
      //   var tempFile = this.state.uploadingFilesName1;

      //   const index = temp.indexOf(assetId);
      //   if (index > -1) {
      //     temp.splice(index, 1);
      //     tempFile.splice(index, 1);
      //   }
      //   this.setState({
      //     uploadingFilesId1: temp,
      //     uploadingFilesName1: tempFile,
      //     fileName: tempFile
      //   }, () => console.log("filename", this.state.fileName, this.state.uploadingFilesId1)
      //   )

      // }
      else {
        var temp = this.state.briefingMediaImages;
        if (!temp[0]?.[this.state.fileOptionId]) {
          temp[0][this.state.fileOptionId] = {
            uploadedFileId: [],
            uploadedFileName: []
          }
        }
        temp[0]?.[this.state.fileOptionId].uploadedFileId.push(assetId)
        temp[0]?.[this.state.fileOptionId].uploadedFileName.push(assetIds?.attributes?.name)
        // tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          briefingMediaImages: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaImages))
      }

    }
    else {
      if (this.state.uploadingFilesId.includes(assetId)) {
        var temp = this.state.uploadingFilesId;
        var tempFile = this.state.uploadingFilesName;
        const index = temp.indexOf(assetId);
        if (index > -1) {
          temp.splice(index, 1);
          tempFile.splice(index, 1);
        }
        this.setState({
          uploadingFilesId: temp,
          // uploadingFilesId2 : temp , 
          uploadingFilesName: tempFile
        }, () => console.log("uploadingFilesIda", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))

      }
      else {
        var temp = this.state.uploadingFilesId;
        var tempFile = this.state.uploadingFilesName;
        temp.push(assetId)
        tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          uploadingFilesId: temp,
          // uploadingFilesId2 : temp,
          uploadingFilesName: tempFile
        }, () => console.log("uploadingFilesIdb", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))
      }
      console.log("uploadingFilesId", this.state.uploadingFilesId);
    }




  }


  getMediaFolderAssetFiles(assetId: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaFolderAssetFilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=40&media_asset_folder_id=${assetId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getMediaAssetFiles(assetId: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      // `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=3&media_asset_id=${assetId}`

      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=40&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getMediaAsset(assetId: any) {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // `bx_block_categories_sub_categories/media_assets?page=1&brand_id=${assetId}&per_page=50`

      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=50&media_asset_id=${assetId}`

      // `bx_block_categories_sub_categories/media_asset_folders?page=${1}&per_page=${10}&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  loadBrandMediaFolderandFiles = (brandId: any) => {


    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaAssetsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/media_assets?page=1&brand_id=${brandId}`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMediaAssets = () => {
    console.log("++++")

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/brands/brand_media_asset_listing?page=1&per_page=150`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getBrandTeams = (brand_id?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandTeamId = requestMessage.messageId;
    const userDetails = JSON.parse(localStorage.getItem('userInfo') || '{}')
    var brandID;
    if (brand_id) {
      brandID = brand_id
    }
    else {
      brandID = userDetails?.brand_id
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_sharing_lists?brand_id=${brandID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //task Blocker index api
  callGetTaskBlockerListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockersAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }


  handleUploadOpenDialog = (optionId?: any) => {
    this.setState({
      uploadDialog: true,
      briefingFieldId: optionId
    });
  };
  handleUploadCloseDialog = () => {
    this.setState({
      uploadingFilesId: [],
      uploadingFilesId1: [],
      uploadingFilesId2: [],
      uploadingFilesName: [],
      // uploadedImages : []
      uploadDialog: false,
      mediaState: 0
    });
  };



  //status index api
  callGetStatusListApi() {

    const userDetails = localStorage.getItem("userInfo")
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetStatusListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusAPiEndPoint + `?project_id=${selectedProjectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task template index api
  callGetTaskTemplateListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.taskTemplateAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskTemplateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskTemplateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task template create api
  callCreateTaskTemplateApi(taskId?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      brand_template_id: this.state.selectedTaskTemplate?.id,
      task_id: taskId
    };



    const httpBody = {
      data: attrs
    };

    const requestCreateTaskTemplateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreatetaskTemplateApiId = requestCreateTaskTemplateMessage.messageId;
    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskTemplateCreateAPiEndPoint
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskTemplateCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskTemplateMessage.id, requestCreateTaskTemplateMessage);
  }

  //template index api
  callGetTemplateListApi(asset_type_id?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_custom_form/brand_templates" + `?brand_id=${brand_id}&&status=Active&&asset_type_id=${asset_type_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task  quotation update api
  callUpdateTaskQuotationApi(taskQuotationId?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      action: "approve_quote"
    };

    const httpBody = {
      data: data
    };

    const requestUpdateTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskQuotationApiID = requestUpdateTaskQuotationMessage.messageId;
    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskQuotationUpdateAPiEndPoint + `/${taskQuotationId}`
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskQuotationUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskQuotationMessage.id, requestUpdateTaskQuotationMessage);



  }

  upDateNotifications(value?: any) {
    // debugger;
    //console.log(value, "state")
    //console.log(this.state.task_email_notifications, "value2")
    const userId = localStorage.getItem("userId")
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };


    const data = {
      "tagged_email_notifications": this.state.tagged_email_notifications,
      "task_email_notifications": this.state.task_email_notifications,
      "mute_notifications": this.state.mute_notifications,
      "task_notification_email_frequency": this.state.task_notification_email_frequency
    };

    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendNoticationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notification_settings/account_notifications_settings/${this.state.account_notification_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  setNotications(e?: any) {
    // debugger;
    //console.log(e.target.name, "name")
    if (e.target.name == "task_email_notifications") {
      this.setState({
        task_email_notifications: !this.state.task_email_notifications
      }, () => {
        //console.log(this.state.task_email_notifications, "emailNotification")
        this.upDateNotifications(this.state.task_email_notifications)
      });
    }
    if (e.target.name == "task_notification_email_frequency") {
      this.setState({
        task_notification_email_frequency: e.target.value
      }, () => {
        //console.log(this.state.task_notification_email_frequency, "task_notification_email_frequency")
        this.upDateNotifications(this.state.task_notification_email_frequency)

      });
    }
    if (e.target.name == "tagged_email_notifications") {
      this.setState({
        tagged_email_notifications: !this.state.tagged_email_notifications
      }, () => {
        //console.log(this.state.tagged_email_notifications, "tagged_email_notifications")
        this.upDateNotifications(this.state.tagged_email_notifications)

      });
    }
    if (e.target.name == "mute_notifications") {
      this.setState({
        mute_notifications: !this.state.mute_notifications
      }, () => {
        //console.log(this.state.mute_notifications, "mute_notifications")
        this.upDateNotifications(this.state.mute_notifications)

      });
    }
    //console.log(this.state.task_email_notifications, this.state.task_notification_email_frequency, this.state.tagged_email_notifications, this.state.mute_notifications, "overallStates");
  }
  handleChangePage = (event: any, value: any) => {
    this.setState({ page: value }, () => {
      if (this.state.page == 1) {
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      } else if (this.state.page == 2) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[0] + 1, fromEnd: this.state.pageSeparatorIndex[1] + 1 })
      } else if (this.state.page == 3) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[1] + 1, fromEnd: this.state.pageSeparatorIndex[2] + 1 })
      } else if (this.state.page == 4) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[2] + 1, fromEnd: this.state.pageSeparatorIndex[3] + 1 })
      } else if (this.state.page == 5) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[3] + 1, fromEnd: this.state.pageSeparatorIndex[4] + 1 })
      } else if (this.state.page == 6) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[4] + 1, fromEnd: this.state.pageSeparatorIndex[5] + 1 })
      } else if (this.state.page == 7) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[5] + 1, fromEnd: this.state.pageSeparatorIndex[6] + 1 })
      } else if (this.state.page == 8) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[6] + 1, fromEnd: this.state.pageSeparatorIndex[7] + 1 })
      } else if (this.state.page == 9) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[7] + 1, fromEnd: this.state.pageSeparatorIndex[8] + 1 })
      } else if (this.state.page == 10) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[8] + 1, fromEnd: this.state.pageSeparatorIndex[9] + 1 })
      }
    });
  };
  getBasedNotifications(value?: any) {

    let teamBased = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "team"

    })
    //console.log(teamBased, "teamBased")
    this.setState({
      teamBased: teamBased
    })
    let approval = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "approval"
    })
    this.setState({
      approvalNotification: approval
    })
    let toDo = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "todo"
    })
    this.setState({
      toDo: toDo
    })
  }

  onEditorStateChange = (editorState?: any) => {

    //console.log("editorState", editorState);

    this.setState({
      editorState: editorState
    });
    this.setState({
      convertedCommentData: convertToRaw(this.state.editorState.getCurrentContent())
    });
    //console.log("convertedData", this.state.convertedCommentData);
  }

  async signedIdHandler() {
    if (this.state.flagForImage == true) {
      if (this.state.signedIdAndURLs2.length > 0) {
        await Promise.all(this.state.signedIdAndURLs2.map(async (signed: any, index: any) => {
          await this.bulkUploading(signed?.data[0], this.array2[index]).catch(e => console.log(e));
          if (this.state.signedIdAndURLs2.length == this.state.countForFile) {
            console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
            this.setState({ countForFile: 0 })
          }
        })
        )
      }
    }
    else if (this.state.signedIdAndURLs.length > 0) {
      if (this.state.signedIdAndURLs.length > 0) {

        await Promise.all(this.array.map(async (file: any, index: any) => {
          await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index2: any) => {
            if (this.arratMD5[index] == signed?.data[0].headers["Content-MD5"]) {
              await this.bulkUploading(signed?.data[0], this.array[index]).catch(e => console.log(e));
              if (this.state.signedIdAndURLs.length == this.state.countForFile) {
                // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
                this.setState({ countForFile: 0 })
                this.arratMD5 = []

              }
            }
          }))
        }))




        // await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index: any) => {
        //   await this.bulkUploading(signed?.data[0], this.array[index]).catch(e => console.log(e));
        //   if (this.state.signedIdAndURLs.length == this.state.countForFile) {
        //     // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
        //     this.setState({countForFile : 0})
        //   }
        // })
        // )
      }
    }
    else if (this.state.signedIdAndURLs3.length > 0) {
      if (this.state.signedIdAndURLs3.length > 0) {

        await Promise.all(this.array3.map(async (file: any, index: any) => {
          await Promise.all(this.state.signedIdAndURLs3.map(async (signed: any, index2: any) => {
            if (this.arrat2MD5[index] == signed?.data[0].headers["Content-MD5"]) {
              await this.bulkUploading(signed?.data[0], this.array3[index]).catch(e => console.log(e));
              if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
                // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
                this.setState({ countForFile: 0, })
                this.arrat2MD5 = []
              }
            }
          }))
        }))


        // await Promise.all(this.state.signedIdAndURLs3.map(async (signed: any, index: any) => {
        //   await this.bulkUploading(signed?.data[0], this.array3[index]).catch(e => console.log(e));
        //   if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
        //     // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
        //     this.setState({countForFile : 0})
        //   }
        // })
        // )
      }
    }

  }

  getSignedUrl = (file: any) => {
    //console.log("File", file)
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    });
  }
  async getSignedURLForFileUpload(file: any, fileSize: any, checkSum: any, fileName: any, contentType: any) {
    this.setState({ loading: true });
    this.closeMenu();
    let header = new Headers()
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : ""
    header.append("Content-Type", "application/json")
    header.append("token", `${token}`)
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );

    fetch(`${currentUrl}` + "/bx_block_upload_media/s3_uploads/get_presigned_url", {
      method: 'POST',
      headers: header,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      body: JSON.stringify(httpBody)
    })
      .then(response => response.json())
      .then(async (data) => {

        var blob = new Blob([file], { type: 'text/plain' });
        let headers: string[] = Object.values(data?.data[0].headers);
        const header = {
          "Content-Type": headers[0],
          "Content-MD5": headers[1],
        };
        let head = new Headers();
        head.append("Content-Type", headers[0])
        head.append("Content-MD5", headers[1])
        await fetch(data?.data[0]?.url, {
          headers: header,
          method: "PUT",
          body: blob
        })

        if (this.state.flagForImage == true && localStorage.getItem("task") == '4') {

          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs2: [...prevState.signedIdAndURLs2, data],
              fileNameImage: this.array2
            }
          }, async () => {
            if (this.state.signedIdAndURLs2.length == this.state.countForFile) {
              this.signedIdHandler()
            }
          })

          // this.setState({ signedIdAndURLs2: [...this.state.signedIdAndURLs2, responseJson], fileNameImage: this.array2 }, () => {
          //   console.log("Dtaassss", this.state.signedIdAndURLs2);
          //   if (this.state.signedIdAndURLs2.length == this.array2.length) {
          //     this.signedIdHandler()
          //   }
          // })
        }
        else if (this.state.flagForImage == false && localStorage.getItem("task") == '3') {

          // this.setState({ signedIdAndURLs: [...this.state.signedIdAndURLs, responseJson], fileName: this.array }, () => {
          //   console.log("Dtaassss", this.state.signedIdAndURLs);
          //   if (this.state.signedIdAndURLs.length == this.array.length) {
          //     this.signedIdHandler()
          //   }
          // })

          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs: [...prevState.signedIdAndURLs, data],
              fileName: this.array
            }
          }, async () => {
            if (this.state.signedIdAndURLs.length == this.state.countForFile) {
              this.signedIdHandler()
            }
          })
        }

        else {
          // console.log("right");

          this.setState({ resp: data }, () => {
            this.bulkUploading(data.data[0], this.state.fileToRawBinary);
          })
        }
      }
      )
      .catch(err => console.log(err))




    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.getSignedURLForFileUploadId.push(requestMessage.messageId);
    // // this.getSignedURLForFileUploadId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.callToPreSignedURLApiEndPoint
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.callToPreSignedURLMethodType
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  //templatye form Condition show api
  callTemplateFormConditionsApi(templateID: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTemplateFormConditionsContentType,
      token: localStorage.getItem("token")
    };

    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTemplateFormConditionsAPIId = requestShowTaskMessage.messageId;

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTemplateFormConditionsEndPoint + `${templateID}`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTemplateFormConditionsMethodType
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);

  }

  callCreateTaskCommentListApi(convertedCommentData?: any) {
    var comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    let ArrayObjects: any = [];
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,
      file_blob_signed_id: this.state.presignedData?.signed_id

    }



    const httpBody = {
      data: data
    };

    const requestCreateTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateTaskCommentListApiID = requestCreateTaskCommentMessage.messageId;
    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_comments/task_comments"
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateTaskCommentMessage.id, requestCreateTaskCommentMessage);

  }

  callGetSubTaskListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subtaskAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task show api
  callShowTaskApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskApiId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskShowAPiEndPoint + `${taskId}`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);



  }
  progressPercentage = (e?: any, value?: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.progress = value
    this.setState({
      percentage: value,
      currentTask: currentTask
    })
    this.callUpdateTaskApi(currentTask);

  }
  //state transition create api
  callStateTransitionsAPI(action?: any, taskId?: any) {


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const attrs = {
      "action": action
    };

    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callStateTransitionsAPIId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.state_transitionsAPiEndPoint + `${taskId}`
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.state_transitionsAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  handleAssigneeChange = (event: any, value: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.assigned_to = value.id,
      this.setState({
        currentTask: currentTask,
      });
    this.callUpdateTaskApi(currentTask);


  };

  handleReviewerChange = (event: any, value: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.reviewer_id = value.id,
      this.setState({
        currentTask: currentTask,
      });
    this.callUpdateTaskApi(currentTask);


  };

  pdfView(name: any, url: any) {
    this.setState({ openPdfURL: { "name": name, "url": url } }, () => {
      this.setState({ openPdf: true })
    })
  }

  goToPrevPdfPage = () => {
    this.setState({
      pdfPageNumber: this.state.pdfPageNumber - 1
    });
  }

  goToNextPdfPage = () => {
    this.setState({
      pdfPageNumber: this.state.pdfPageNumber + 1
    });
  }

  onDocumentPdfLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPdfPages: numPages
    })
  }

  callUpdateTaskApi(currenttask: any) {
    //console.log("currenttask", currenttask, this.state.currentTask)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTemplateFormConditionsContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: currenttask.attributes,
    };
    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskAPIId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskUpdateAPiEndPoint + `${currenttask.id}`
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);



  }
  //task  subtask update api
  callUpdateSubTaskApi(subTaskId?: any, value?: any) {
    //console.log(value);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };

    const requestUpdateSubTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSubTaskApiID = requestUpdateSubTaskMessage.messageId;
    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskUpdateAPiEndPoint + `/${subTaskId.id}`
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateSubTaskMessage.id, requestUpdateSubTaskMessage);



  }
  bulkTaskCommentUploading = async (data: any, file: any) => {

    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bulkTaskCommentUploadingID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //briefing create api
  callCreateBriefingApi(values: any) {
    this.setState({ loading2: true })
      ;
    //console.log("From Create Brieifing", values)

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const data = values;

    const httpBody = {
      data: data
    };

    const requestCreateBriefingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateBriefingApiId = requestCreateBriefingMessage.messageId;
    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/task_briefings"
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateBriefingMessage.id, requestCreateBriefingMessage);


  }

  createBriefingAndGenerateQuote = (submit?: any) => {
    var briefingValues: any = {};
    var briefed_values_attributes: any = [];
    var formFieldsData = this.state.templateFormFieldsData
    if (formFieldsData.length > 0
      && formFieldsData[formFieldsData.length - 1].attributes.element == "page_separator"
      && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
      formFieldsData.pop();
    }
    formFieldsData.map((item: any) => {
      var objectValues: any = {}
      if (item.attributes.element == "multiple_selection") {
        objectValues.value = (item.value).toString()
      }
      else if (item.attributes.element == "long_text") {
        objectValues.value = item.attributes.value
      }
      else {
        objectValues.value = item.value
      }
      objectValues.label = item.attributes.label,
        // objectValues.value = item.value,
        objectValues.value_type = item.attributes.element,
        objectValues.description = item.attributes.description,
        objectValues.preview_visibility = item.attributes.element == "comment_box" ? true : item.attributes.preview_visibility,
        objectValues.optional_values = item.attributes.element_optional_values,
        objectValues.mandatory = item.attributes.mandatory,
        objectValues.detailed_values = item.attributes.detailed_values,
        objectValues.information = item.attributes.information,
        objectValues.field_order = item.attributes.field_order;
      //console.log("Briefing Values Object", objectValues)
      if (item.attributes.element == "file") {


        if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId1,
          objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {
          // objectValues.briefed_value_files_attributes = this.array?.length > 0 && this.array.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs && this.state.signedIdAndURLs[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
          // objectValues.file_type = this.state.fileData1?.type;

        }


        // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
        // objectValues.file_type = this.state.fileData1?.type;
      } else if (item.attributes.element == "image") {
        if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId2,
          objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {

          // objectValues.briefed_value_files_attributes = this.array2?.length > 0 && this.array2.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs2 && this.state.signedIdAndURLs2[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs2[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
          // objectValues.file_type = this.state.fileData2?.type;
        }
        // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
        // objectValues.file_type = this.state.fileData2?.type;
      }
      else if (item.attributes.element == "image_upload") {
        let name = item.attributes.information
        let extType = name.substring(name.lastIndexOf('.') + 1);
        objectValues.briefed_value_files_attributes = [{
          "name": name || "simple image",
          "file_type": `image/${extType || "jpeg"}`,
          "file_blob_signed_id": item.attributes.file_blob_signed_id
        }]
      }
      // objectValues.file_blob_signed_id= this.state.presignedData?.signed_id
      briefed_values_attributes.push(objectValues)
    })
    briefingValues.task_id = this.state.currentTask.id,
      briefingValues.briefed_values_attributes = briefed_values_attributes;
    this.setState({ isComingFromBriefingForm: true });
    this.callCreateBriefingApi(briefingValues);

    this.setState({
      openAddBriefingModel: false,
      openStartBriefingModel: true,
      // setOpen2: true,
      templateFormFieldsData: [],
      editorState: EditorState.createEmpty(),
    });
  }

  handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ category: event.target.value as string });
    this.setState({ startBriefingOpen: false })
  };


  handleClickTemplateSelection = (event: any, value: any) => {
    //console.log("handleClickTemplateSelection", value)
    this.setState({
      selectedTaskTemplate: value
    })
  }


  handleInputChange = (indexValue: any, event: any) => {
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    if (templateData[index].attributes.element == "date_time") {
      if (event.target.name == "date") {
        if (templateData[index].attributes.default_value && (templateData[index].attributes.default_value).indexOf("T") > -1) {
          templateData[index].attributes.default_value = (templateData[index].attributes.default_value).replace(templateData[index].attributes.default_value.split("T")[0], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[0], event.target.value);
        } else {
          templateData[index].attributes.default_value = event.target.value;
          templateData[index].value = event.target.value;
        }
      }
      if (event.target.name == "time") {
        if (templateData[index].attributes.default_value && (templateData[index].attributes.default_value).indexOf("T") > -1) {
          templateData[index].attributes.default_value = (templateData[index].attributes.default_value).replace(templateData[index].attributes.default_value.split("T")[1], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[1], event.target.value);
        } else {
          templateData[index].attributes.default_value = templateData[index].attributes.default_value + "T" + event.target.value;
          templateData[index].value = templateData[index].value + "T" + event.target.value;
        }
      }

    } else if (templateData[index].attributes.element == "multiple_selection") {
      if (templateData[index].attributes.default_value == "") {
        templateData[index].attributes.default_value = [];
        templateData[index].value = [];
      }
      if (templateData[index].attributes.default_value && templateData[index].attributes.default_value.length > 0 &&
        (templateData[index].attributes.default_value).findIndex((data: any) => data == event.target.value) > -1) {
        var i = templateData[index].attributes.default_value.findIndex((data: any) => data == event.target.value);
        templateData[index].attributes.default_value.splice(i, 1);
        templateData[index].value.splice(i, 1);
      } else {
        templateData[index].attributes.default_value.push(event.target.value);
        templateData[index].value.push(event.target.value);
      }
    } else {
      templateData[index].attributes.default_value = event.target.value
      templateData[index].value = event.target.value
    }
    // templateData[index].attributes.default_value = event.target.value
    // templateData[index].value = event.target.value
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
      console.log("conditionData", conditionData)
      // if (conditionsData.length > 0) {
      //   templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
      // }
      if (templateData[index].attributes.element == "single_selection" ||
        templateData[index].attributes.element == "multiple_selection" ||
        templateData[index].attributes.element == "drop_down_select"
      ) {
        // conditionData = conditionData.filter((condition: any) => condition.attributes.condition_value == event.target.value);
      }
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (templateData[index].attributes.element == "single_selection" ||
            templateData[index].attributes.element == "drop_down_select" ||
            templateData[index].attributes.element == "Long text"
          ) {
            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != event.target.value
              && condition.attributes.is_condition_selected == false && condition.attributes.is_response_to_show == false) {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {

              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != event.target.value
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }
          } else if (templateData[index].attributes.element == "multiple_selection") {

            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '') && condition.attributes.is_condition_selected == false && condition.attributes.is_response_to_show == false) {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {

              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '') && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }
          }
          else if (templateData[index].attributes.element == "text" ||
            templateData[index].attributes.element == "long_text" ||
            templateData[index].attributes.element == "short_text" ||
            templateData[index].attributes.element == "email" ||
            templateData[index].attributes.element == "full_name" ||
            templateData[index].attributes.element == "phone" ||
            templateData[index].attributes.element == "date_time"
          ) {
            if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }
          }
        })
      }
    }
    // templateData[index].briefed_value_files_attributes = []
    this.setState({
      templateFormFieldsData: templateData
    }, () => {
      //console.log("Values from handle Input", templateData, templateData[index])
    });

  };

  clearFileImage = (indexValue: any, event: any, fileId: any) => {
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    let temp = templateData[index].attributes.template_form_field_files?.data.filter((file: any, i: any) => file.id != fileId);
    templateData[index].attributes.template_form_field_files.data = temp;
    this.setState({
      templateFormFieldsData: templateData
    })
  }

  handleClickBriefingTemplateform = (Briefingdata: any, Briefingconditions: any) => {
    let templateData = Briefingdata
    let templateFormConditions = Briefingconditions
    console.log("🚀 ~ file: ProjectPortfolioController.tsx ~ line 4361 ~ loadUserDetailsAndRoles ~ templateData", templateData, templateFormConditions)

    templateData.map((_data: any, index: any) => {
      if (_data.attributes.element == "multiple_selection") {
        if (typeof (_data.attributes.default_value) == "string") {
          if (_data.attributes.default_value !== null && _data.attributes.default_value !== "") {
            _data.attributes.default_value = _data.attributes.default_value.split(",");
            _data.value = _data.attributes.default_value;
            _data.showField = true;
          }
          else {
            _data.attributes.default_value = [];
            _data.value = [];
            _data.showField = true;
          }
        }
      } else {
        _data.value = _data.attributes.default_value;
        _data.showField = true;
      }
      if (_data.attributes.element == "long_text") {
        if (_data?.attributes?.value?.length) {
          const html = _data?.attributes?.value
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.temp[_data.id] = editorState
          }

          this.setState({ longTextEditorContent: this.temp }, () => console.log("paragrak", this.state.longTextEditorContent))
        }
      }
    });


    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          // let applyOn = templateData.filter((data : any) => data.id == condition.attributes.response_label_id);
          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);


          //console.log("testttt2",applyFrom,applyOnIndex)

          //Condition's for Selection type widgets 
          if (!(
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "long_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider" ||
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )) {

            if (condition.attributes.condition_value == applyFrom[0].attributes.default_value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }

            if (condition.attributes.condition_value != applyFrom[0].attributes.default_value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }
            if (condition.attributes.condition_value == applyFrom[0].attributes.default_value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
            if (condition.attributes.condition_value != applyFrom[0].attributes.default_value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (applyFrom[0].attributes.default_value && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (!applyFrom[0].attributes.default_value && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            console.log("xcv", templateData[applyOnIndex])
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (applyFrom[0].attributes.default_value && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (!applyFrom[0].attributes.default_value && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
            console.log("yak", condition.attributes.is_response_to_show);
          }

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //condition for long_text
          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && ((applyFrom[0].attributes.default_value.length > 8) && condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && (!(applyFrom[0].attributes.default_value.length > 8) && !condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && ((applyFrom[0].attributes.default_value.length > 8) && condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && (!(applyFrom[0].attributes.default_value.length > 8) && !condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }


        })
      }
    }
    this.setState({
      templateFormFieldsData: templateData,
    });
  };

  handleClickStartBriefing = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddBriefingModel: true,
      isLabel: '',
      startBriefingOpen: false,
      loading: true
    });
  };

  handleClickStopBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickChangeBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickSaveBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleChangeAssetType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ assetType: event.target.value as string });
    this.setState({ startBriefingOpen: false })

  };

  statusUpdateToRequestQuote = (taskId: any) => {
    const header = {
      "Content-Type": configJSON.briefingUpdateStatusApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: {
        action: "submit_briefing",
        request_quote: true
      }
    };

    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statusUpdateToRequestQuoteId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingUpdateStatusAPiEndPoint + '/' + taskId
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefingUpdateStatusAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);
  }

  //use the code below project index api
  handleChangeBrand(brandId: any) {
    // debugger;
    this.setState({ startBriefingOpen: false })
    const userDetails = localStorage.getItem("userInfo")
    //var brand_id = localStorage.getItem("selectedBrandID");
    var brand_id;
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/projects" + `?per_page=${100}&brand_id=${brandId ? brandId : brand_id}&sort_by=name&sort_order=asc`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  handleCloseTaskModal = () => {
    //this.getNotifyNotifications()
    this.setState({
      setOpen2: false,
      isCurrentTaskSelected: false,
      setOpenAnnotaion: false,
      allowAnnotation: false
    })
  }

  //asset type index api
  callGetAssetTypeListApi(asset_category_id?: any) {
    const userDetails = localStorage.getItem("userInfo")

    console.log(userDetails, "userDetalssssssssssssssssssssssss")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "bx_block_categories_sub_categories/asset_types" + `?asset_category_id=${asset_category_id}`
      this.state.permissionName == 'client' ?
        `bx_block_categories_sub_categories/asset_types?asset_category_id=${asset_category_id}&brand_id=${localStorage.getItem("selectedBrand")}&per_page=5000`
        :
        `bx_block_categories_sub_categories/asset_types?asset_category_id=${asset_category_id}&brand_id=${this.state.brandSelected}&per_page=5000`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  inputFileChanged = (e: any) => {
    // console.log(this.fileInput.current.files[0]);
    //console.log("File1", e.target.files[0])
    this.getSignedUrl(e.target.files[0]);
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
    //console.log("File2", value)
  };

  setCategory(SelectedCategory?: any) {
    this.setState({
      SelectedCategory: SelectedCategory,
    });
    this.callGetAssetTypeListApi(SelectedCategory.id);


    //console.log("Hello", SelectedCategory)

  }

  handleClickCreateNewTaskModel = (e?: any) => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    this.handleChangeBrand(brand_id);
    this.getBrandListing();
    this.setState({
      openTaskCreateModel: true,
    });
    // this.callGetAssetCategoryListApi();

    // this.callGetAssetTypeListApi();  
    // this.setAssetType();

  };


  onEditorStateChange2 = (editorState: any, id: any) => {
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(id))
    let elementsArray = this.state.templateFormFieldsData;
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    let contentHTML = draftToHtml(contentRaw);

    let editorStateTemp = this.state.longTextEditorContent;
    editorStateTemp[id] = editorState;
    this.setState({
      longTextEditorContent: editorStateTemp
    })
    let text = contentRaw.blocks[0].text
    let dummyevent = {
      target: {
        value: text
      }
    }
    this.handleInputChange(id, dummyevent)
    console.log("text", this.state.longTextEditorContent);
    elementsArray[index].attributes.value = contentHTML;
    this.setState({
      templateFormFieldsData: elementsArray,
    }, () => {
      // console.log("elementArray",this.state.elementArray)
      console.log("templateFormFieldsDataData2", this.state.templateFormFieldsData)

    });

  }

  getSignedUrlInBriefing = async (file: any) => {
    //console.log("File", file)
    const temp = [];
    this.array.push(file)
    temp.push(file.name)

    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    this.setState({ fileName: temp }, () => console.log("filename", this.state.fileName))
    this.setState({ fileToRawBinary: file, fileData1: file })
    const md5 = await this.computeChecksumMd5(file);
    this.arratMD5.push(md5)
    await this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedUrlInBriefingFile(file.size, md5, file.name, file.type)
    // });
    var templateData = this.state.templateFormFieldsData;
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(this.state.fileOptionId));
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = false;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }

          if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = true;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }
        })
      }
    }
  }
  getSignedUrlImageInBriefing = async (file: any) => {
    this.array2.push(file)
    //console.log("File", file)
    const temp = [];
    temp.push(file.name)
    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    this.setState({ fileNameImage: temp }, () => console.log("filename", this.state.fileNameImage))
    this.setState({ fileToRawBinary: file, fileData2: file })
    const md5 = await this.computeChecksumMd5(file);
    await this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedUrlInBriefingImageFile(file.size, md5, file.name, file.type)
    // });
    var templateData = this.state.templateFormFieldsData;
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(this.state.fileOptionId));
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = false;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }

          if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = true;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }
        })
      }
    }
  }
  handleDeleteTask = () => {
    debugger;
    this.setState({
      loading: false,
      openAddBriefingModel: false

    });
    // const header = {
    //   token: localStorage.getItem("token")
    // };

    // const requestShowTaskMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );

    // this.deleteTaskApiId = requestShowTaskMessage.messageId;
    // requestShowTaskMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.taskDeleteAPiEndPoint + this.state.currentTask.id
    // );

    // requestShowTaskMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestShowTaskMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.taskDeleteAPiMethod
    // );

    // runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);
  }

  getSignedUrlInBriefingImageFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": configJSON.callToPreSignedURLContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedUrlInBriefingImageID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToPreSignedURLApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToPreSignedURLMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  computeChecksumMd5(file: File): Promise<string> {

    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  //fie upload API api
  getSignedUrlInBriefingFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedUrlInBriefingFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  handleDateChange = (event: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.estimated_end_date = event.target.value,
      this.setState({
        dueDate: event.target.value as string,
        currentTask: currentTask,
      });
    this.callUpdateTaskApi(currentTask);


  };

  handleClickSubmitBriefing = (fromTaskClose: any) => {
    if (this.state.templateFormFieldsData.some((field: any) => (field.attributes.mandatory === true && (field.attributes.element !== 'image' && field.attributes.element !== 'file') && field.value.toString() == ''))) {
      toast.error("Fill the required fields marked as *")
    }
    else {
      var briefingValues: any = {}
      var briefed_values_attributes: any = []
      var formFieldsData = this.state.templateFormFieldsData
      console.log("formFieldsData", formFieldsData);

      if (formFieldsData.length > 0
        && formFieldsData[formFieldsData.length - 1].attributes.element == "page_separator"
        && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
        formFieldsData.pop();
      }
      formFieldsData.map((item: any, index: any) => {
        if (item?.attributes?.preview_visibility || !item?.attributes?.preview_visibility) {
          // console.log(item)
          let file_blob_signed_id = this.state.presignedData?.signed_id
          var objectValues: any = {}
          if (item.attributes.element == "multiple_selection") {
            objectValues.value = (item.value).toString()
            objectValues.briefed_value_files_attributes = []
          }
          else if (item.attributes.element == "long_text") {
            objectValues.value = item.attributes.value
          }
          else {
            objectValues.value = item.value
            objectValues.briefed_value_files_attributes = []
          }
          objectValues.label = item.attributes.label,
            // objectValues.value = item.value,
            objectValues.value_type = item.attributes.element,
            objectValues.description = item.attributes.description,
            objectValues.optional_values = item.attributes.element_optional_values,
            objectValues.mandatory = item.attributes.mandatory,
            objectValues.preview_visibility = item.attributes.element == "comment_box" ? true : item.attributes.preview_visibility,
            objectValues.detailed_values = item.attributes.detailed_values,
            objectValues.information = item.attributes.information,
            objectValues.field_order = item.attributes.field_order;
          objectValues.briefed_value_files_attributes = []
          //  console.log("Briefing Values Object", objectValues)
          if (item.attributes.element == "file") {
            if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
              // console.log("objectValues.media_asset_file_ids", this.state.uploadingFilesId1);

              // objectValues.media_asset_file_ids = this.state.uploadingFilesId1,
              objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;

              objectValues.briefed_value_files_attributes = []
            }
            else {
              // console.log("this.state.signedIdAndURLs212", this.state.signedIdAndURLs);

              // objectValues.briefed_value_files_attributes = this.array?.length > 0 && this.array.map((file: any, index: any) => {
              //   return {
              //     "name": file?.name,
              //     "file_type": file?.type,
              //     "file_blob_signed_id": this.state.signedIdAndURLs && this.state.signedIdAndURLs[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs[index]?.data[0]?.signed_id : ''
              //   }
              // })
              objectValues.briefed_value_files_attributes = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })

              // [


              //   {
              //     "name" : this.state.fileData1?.name,
              //     "file_type": this.state.fileData1?.type,
              //     "file_blob_signed_id": this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
              //   }
              // ]
              // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
              // objectValues.file_type = this.state.fileData1?.type;

            }

          } else if (item.attributes.element == "image") {
            if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
              // objectValues.media_asset_file_ids = this.state.uploadingFilesId2,
              objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;
              objectValues.briefed_value_files_attributes = []
            }
            else {

              // objectValues.briefed_value_files_attributes = this.array2?.length > 0 && this.array2.map((file: any, index: any) => {
              //   return {
              //     "name": file?.name,
              //     "file_type": file?.type,
              //     "file_blob_signed_id": this.state.signedIdAndURLs2 && this.state.signedIdAndURLs2[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs2[index]?.data[0]?.signed_id : ''
              //   }
              // })
              objectValues.briefed_value_files_attributes = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })
              // [
              //   {
              //     "name" : this.state.fileData2?.name,
              //     "file_type": this.state.fileData2?.type,
              //     "file_blob_signed_id": this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
              //   }
              // ]
              // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
              // objectValues.file_type = this.state.fileData2?.type;
            }

          }
          else if (item.attributes.element == "image_upload") {
            let name = item.attributes.information
            let extType = name.substring(name.lastIndexOf('.') + 1);
            objectValues.briefed_value_files_attributes = [{
              "name": name || "simple image",
              "file_type": `image/${extType || "jpeg"}`,
              "file_blob_signed_id": item.attributes.file_blob_signed_id
            }]
          }

          briefed_values_attributes.push(objectValues)
          console.log("Briefing Array", briefed_values_attributes)
        }

      }
      )

      briefingValues.task_id = this.state.currentTask.id,
        briefingValues.briefed_values_attributes = briefed_values_attributes;
      //console.log("briefingValues", briefingValues)
      this.callCreateBriefingApi(briefingValues);
      if (fromTaskClose == true) {
        this.setState({
          openAddBriefingModel: false,
          openStartBriefingModel: false,
          setOpen2: false,
          templateFormFieldsData: [],
          editorState: EditorState.createEmpty(),
          longTextEditorContent: [EditorState.createEmpty()]
        });
      } else {
        this.setState({
          openAddBriefingModel: false,
          openStartBriefingModel: true,
          // setOpen2: true,
          templateFormFieldsData: [],
          editorState: EditorState.createEmpty(),
          longTextEditorContent: [EditorState.createEmpty()]
        });
      }
    }
  };

  callGetAssetCategoryListApi() {

    const userDetails = localStorage.getItem("selectedBrand")

    // const projectDetailsId = localStorage.getItem("projectInfoId")

    // var id = JSON.parse(userDetails|{})

    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCategoryprojectApiCallId = requestMessage.messageId;
    if (this.state.permissionName == "client") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_categories?page=1&per_page=2000&without_asset_type=true&brand_id=${userDetails}`
        // "bx_block_categories_sub_categories/asset_categories"+'?page=1&per_page=2000&without_asset_type=true'
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_categories?page=1&per_page=2000&without_asset_type=true&brand_id=${this.state.brandSelected}`
        // "bx_block_categories_sub_categories/asset_categories"+'?page=1&per_page=2000&without_asset_type=true'
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  callCreateProjectApi = (values: any, users: any, brand_id?: any) => {
    this.setState({
      setOpen: false
    })

    if (users) {
      const selectedAccount = [];
      const selectedTeam = []

      for (let i = 0; i < users.length; i++) {
        if (users[i].entity == "Account") {
          selectedAccount.push(users[i].id)
        }
        else if (users[i].entity == "Team") {
          selectedTeam.push(users[i].id)
        }

      }
      var selectedUsers = [];
      selectedUsers = selectedAccount.map((i: any) => Number(i));
      var teams = [];
      teams = selectedTeam.map((i: any) => Number(i));
    }

    const userDetails = localStorage.getItem("userInfo")
    var brandId = brand_id;
    if (userDetails && brand_id == null) {
      brandId = JSON.parse(userDetails).brand_id

    }

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const data = {
      name: values.title,
      description: values.projectDescription,
      account_ids: selectedUsers,
      team_ids: teams,
      brand_id: brandId,
    };



    const httpBody = {
      data: data
    };


    const requestCreateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectCreateApiCallId = requestCreateProjectMessage.messageId;
    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/projects"
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateProjectMessage.id, requestCreateProjectMessage);



  }

  async getfilesAndFolder(id: any) {
    this.setState({ loading: true })
    await this.getMediaAsset(id)
    await this.getMediaAssetFiles(id)
    // this.setState({loading : false})
  }


  //cretae new task
  callCreateTaskApi(values: any, catID: any, assetTypeID: any, projectID: any) {
    const userDetails = localStorage.getItem("userInfo")
    // const selectedProjectId = localStorage.getItem("selectedProjectId")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };

    const attrs = {
      "project_id": projectID,
      "title": values.title,
      "description": values.title,
      "asset_type_id": Number(assetTypeID),
      "asset_category_id": Number(catID)
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };

    const requestCreateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTaskCreateAPICallId = requestCreateTaskMessage.messageId;
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks"
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateTaskMessage.id, requestCreateTaskMessage);


  }
  //fie upload API api
  getSignedURLForFileUploadTaskFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  getSignedUrlTaskFileComment = (file: any) => {
    //debugger;
    //console.log("File", file)
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      //debugger;
      this.getSignedURLForFileUploadTaskFile(file.size, md5, file.name, file.type)
    });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: URL.createObjectURL(file) } });
      }
    );
  }
  //account  index api
  callGetAccountListApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/accounts" + `?page=${1}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/accounts" + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  handleChangeBlockerSelection = (task?: any, value?: any) => {

    // this.setState({
    //   is_completedBlocker: event.target.checked as boolean,
    //   taskBlockerId: event.target.value

    // });
    //console.log("locker checkbox", task, value);
    this.callUpdateTaskBlockerApi(task.id, value);
    //console.log("locker", task.id, value);

  };
  //task  blocker update api
  callUpdateTaskBlockerApi(taskBlockerId?: any, value?: any) {
    //console.log();

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };
    //console.log("value", value)
    const requestUpdateTaskBlockerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskBlockerApiID = requestUpdateTaskBlockerMessage.messageId;
    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersUpdateAPiEndPoint + `/${taskBlockerId}`
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskBlockerMessage.id, requestUpdateTaskBlockerMessage);



  }


  callGetOngoingTaskListApi(projectID?: any, status?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id

      }
    } else {
      //console.log("localStorage.getItem", localStorage.getItem("selectedBrandID"))
      var brand_id: any = localStorage.getItem("selectedBrandID");

    }
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    status.messageId = requestMessage.messageId
    var dynamicMessages = this.state.dynamicMessages;
    dynamicMessages[status.id] = status
    this.setState({
      dynamicMessages: dynamicMessages

    })
    if (status.id == "Draft") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=draft&brand_id=${brand_id}&project_id=${projectID}`
      );
    } else if (status.id == "Quote Pending") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=quote_requested_or_pending&brand_id=${brand_id}&project_id=${projectID}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`

      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  getApproveProjectByBrandID = () => {
    //console.log("selectedBrand')", localStorage.getItem('selectedBrand'))

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApproveProjectByBrandId = requestMessage.messageId;

    const userDetails = JSON.parse(localStorage.getItem('userInfo') || '{}')
    var brandID = userDetails?.brand_id;



    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=${this.state.setPage}&sort_by=${this.state.projectSortByType}&sort_order=${(this.state.projectSortByType === 'name') ? 'asc' : 'desc'}&per_page=10&brand_id=${localStorage.getItem('selectedBrand')}&quote_requested_or_pending=true`
      // &quote_requested_or_pending=true
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProjectByBrandID = () => {
    //console.log("selectedBrand')", localStorage.getItem('selectedBrand'))
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectByBrandId = requestMessage.messageId;
    const userDetails = JSON.parse(localStorage.getItem('userInfo') || '{}')
    var brandID;
    brandID = userDetails?.brand_id

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=${this.state.setPage}&sort_by=${this.state.projectSortByType}&sort_order=${(this.state.projectSortByType === 'name') ? 'asc' : 'desc'}&per_page=10&brand_id=${localStorage.getItem('selectedBrand')}`
      // &quote_requested_or_pending=true
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  fetchMoreData = () => {
    ;
    //console.log("Page count", this.state.setPage)
    this.setState({ setPage: this.state.setPage + 1 }, () => { this.getProjectByBrandID() });
  }
  fetchMoreData2 = () => {
    ;
    //console.log("Page count", this.state.setPage)
    this.setState({ setPage: this.state.setPage + 1 }, () => { this.getApproveProjectByBrandID() });
  }
  //templatye form fields show api
  callTemplateFormFieldsApi(templateID: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTemplateFormFieldsAPIId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_custom_form/template_form_fields?template_id=" + `${templateID}&per_page=100`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);

  }

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2.push(requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  taskFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": configJSON.taskBulkUploadContentType,
      token: localStorage.getItem("token")
    };
    var data;
    if (localStorage.getItem("task") == "2") {
      data = {
        task_id: parseInt(this.state.currentTask.id),
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: false,
              source_file: true,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "1") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: true,
              source_file: false,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "0") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: false,
              source_file: false,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskBulkUploadContentTypeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskBulkUploadContentTypeMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  submitProductionReady = () => {

    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json",

    };
    var httpBody = {
      "data": {
        "task_id": this.state.currentTask.id
      }
    }


    const requestShowTaskFileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitProductionApiCallId = requestShowTaskFileMessage.messageId;
    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks/move_to_prod_ready_status"
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestShowTaskFileMessage.id, requestShowTaskFileMessage);



  }

  getListTaskFileIndex = () => {

    const header = {
      "Content-Type": configJSON.getTaskFileGetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileGetApiEndPoint + this.state.currentTask.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async receive(from: string, message: Message) {
    //console.log("receive function");
    //console.log(message, "message");

    /*if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiSendNoticationCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //console.log(responseJson);
      debugger;
      if (responseJson?.data) {
        this.setNotificatioPreferences([responseJson.data]);
      } else {
        if (responseJson?.errors?.[0]?.task_notification_email_frequency) {
          //toast.error(responseJson?.errors?.[0]?.task_notification_email_frequency);
        } else if (responseJson?.errors?.[0]?.project_notification) {
          //toast.error(responseJson?.errors?.[0]?.project_notification);
        }
        this.setNotificatioPreferences([]);
      }
    }*/
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.bulkTaskCommentUploadingID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
      toast.success("Files Uploaded successfully!")

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.submitProductionApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ submitProduction: false, loading: false })
        this.setState({ isComingFromBriefingForm: false });
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadBriefingFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          // this.getListTaskFileIndex()
          this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
          toast.success("Files Uploaded successfully!")

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.getListTaskFileIndex()
          this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
          toast.success("Files Uploaded successfully!")

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getTasksByFilterApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {


          // console.log("totall..........", responseJson.projects);
          // alert("ruko...")

          if (responseJson.tasks) {
            if (responseJson.tasks.data) {
              localStorage.setItem("mydata", JSON.stringify(responseJson.tasks.data))
              localStorage.setItem("searchtype", "task")
              window.location.href = `/Home/SearchResults`

            }
          }
          if (responseJson.projects) {
            localStorage.setItem("mydata", JSON.stringify(responseJson.projects.data))
            localStorage.setItem("searchtype", "project")
            // this.props.navigation.navigate("/Home/SearchResults")
            window.location.href = `/Home/SearchResults`
          }

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandsByFilterApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          // console.log("brands......", responseJson.brands);


          if (responseJson.brands) {
            if (responseJson.brands.data) {
              if (responseJson.brands.data.length === 0) {
                localStorage.removeItem("myBrandData")
                localStorage.removeItem("mydata")
                window.location.href = `/Home/SearchResults`

              } else {
                localStorage.setItem("myBrandData", JSON.stringify(responseJson.brands.data))
                localStorage.setItem("searchtype", "brands")
                window.location.href = `/Home/SearchResults`
              }
            }
          }

        } else {


          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.callCategoryprojectApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log("respnsssss", responseJson);

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFolderApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data) {
        this.setState({
          projectFoldersAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,


        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFileApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          projectFilesAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,

          mediaState: 2,
          loading: false
        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaFolderAssetFilesApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          mediaFolderAssetdFiles: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          mediaState: 3,
          loading: false

        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaAssetsApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
        //let totalPagesCount = this.state.totalPages;
        this.setState({
          brandMediaAssets: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
          //count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
          //totalPages:message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : totalPagesCount,
          mediaState: 1
        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryApiCallId) {

      if (message?.properties?.RestAPIResponceSuccessMessage?.brands?.data) {

        this.setState({
          assetData: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data,

        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandDetailId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        this.setState({

          brandDetail: message?.properties?.RestAPIResponceSuccessMessage?.data
        }
          , () => {
            console.log("brandDetails", this.state.brandDetail)
          }
        )

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandTeamId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {

        // let teamData = []
        // teamData.push(...message?.properties?.RestAPIResponceSuccessMessage?.teams.data)
        // let arr = []
        // arr.push(...teamData)
        // console.log(arr)
        this.setState({

          brandTeamsData: message?.properties?.RestAPIResponceSuccessMessage
        }
          , () => {
            console.log(this.state.brandTeamsData, "teamsDara")
          }
        )

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ fileTaskName: responseJson?.task_files?.data })
            // 
            //console.log("File Data Bulk Upload", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }


    if (this.getSignedURLForFileUploadId2.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      if (localStorage.getItem("task") == "2") {
        this.setState({ loading: false, countForFile: 0 })
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "1") {
        this.setState({ loading: false, countForFile: 0 })
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "0" && this.array3.length > 0) {
        // this.taskFileUploadBulk(this.state.signedIdAndURLs3);
        this.setState(prevState => { return { ...prevState, countUpload2: prevState.countUpload2 + 1 } }, async () => {
          if (this.state.countUpload2 == this.state.signedIdAndURLs3?.length) {
            this.setState({ loading: false, countUpload2: 0, countForFile: 0 })
            // this.taskFileUploadBulk(this.state.resp);
            this.taskFileUploadBulk(this.state.signedIdAndURLs3);
          }
        })

      }
      else if (this.array.length > 0 || this.array2.length > 0) {
        this.setState({ countUpload: this.state.countUpload + 1 }, () => {
          if (this.state.flagForImage == true) {
            if (this.state.countUpload == this.state.signedIdAndURLs2.length) {
              this.setState({
                loading: false, countUpload: 0, uploadDialog: false, flagForImage: false, countForFile: 0, briefingImages: [
                  {
                    ...this.state.briefingImages[0],
                    [this.state.fileOptionId]: {
                      signedId: this.state.signedIdAndURLs2,
                      fileName: this.array2
                    }
                  }
                ]
              }, () => {
                console.clear();
                console.log("briefingImages", this.state.fileOptionId, this.state.briefingImages);
                this.setState({ signedIdAndURLs2: [] })
                this.array2 = []

              })

              toast.success("files uploaded successfully")
            }
          }
          else {
            if (this.state.countUpload == this.state.signedIdAndURLs.length) {
              this.setState({
                loading: false, countUpload: 0, uploadDialog: false, countForFile: 0, briefingFiles: [
                  {
                    ...this.state.briefingFiles[0],
                    [this.state.fileOptionId]: {
                      signedId: this.state.signedIdAndURLs,
                      fileName: this.array
                    }
                  }
                ]
              },
                () => {
                  console.clear();
                  console.log("briefingFiles", this.state.fileOptionId, this.state.briefingFiles);
                  this.setState({ signedIdAndURLs: [] })
                  this.array = []
                })
              toast.success("files uploaded successfully")
            }
          }


        })

      }



    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToBulkFileUpload) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("file uploaded successfully")
            this.setState({
              fileTaskName: responseJson?.data, setAnchorEl: null
              // , setSourceFileAnchorEl: null
            })
            this.getListTaskFileIndex()
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            this.setState({ resp: responseJson }, () => {
              this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateBriefingApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // if (this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
            //   || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") {
            // this.getApprovedQuote()
            // this.getOpenQuote()
            // this.getPendingQuote()
            // this.callGetAccountListApi('');

            // } else {
            if (this.state.isComingFromBriefingForm == true) {
              if (this.state.submitProduction) {
                this.submitProductionReady()
              }
              else {
                // this.statusUpdateToRequestQuote(responseJson.data.attributes.task_id);
                this.statusUpdateToRequestQuote(responseJson.data.id);
                toast.success("Requested quote successfully");
              }

            } else {
              // this.callGetStatusListApi();
              // this.callShowTaskApi(responseJson.data.attributes.task_id);
              toast.success("Briefing saved successfully");
            }
            this.setState({
              templateFormFieldsData: [],
              templateFormConditionsData: [],
              uploadingFilesId1: [],
              uploadingFilesId2: [],
              flagForMediaUpload2: null,
              flagForMediaUpload1: null,
              signedIdAndURLs: [],
              signedIdAndURLs2: [],
              flagForImage: false,
              fileNameImage: [],
              fileName: [],
              briefingImages: [{}],
              briefingFiles: [{}],
              briefingMediaFiles: [{}],
              briefingMediaImages: [{}],
              loading2: false

            });
            this.array = []
            this.array2 = []
            this.arratMD5 = []

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedUrlInBriefingImageID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ presignedDataImage: responseJson.data[0] }, () => {
              //console.log("Briefied Value images", this.state.presignedDataImage)
            })
            // localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            this.bulkTaskCommentUploading(responseJson.data[0], this.state.fileToRawBinary);
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTemplateFormConditionsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.callGetProjectsListApiType1();
            // toast.success("Task created successfully");
            //console.log("afterthat 2", responseJson)
            let templateFormConditions = responseJson?.template_form_conditions?.data;
            let templateData = this.state.templateFormFieldsData;
            if (templateFormConditions && templateFormConditions.length > 0) {
              let conditionData = templateFormConditions;
              if (conditionData.length > 0) {
                conditionData.map((condition: any) => {
                  let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);

                  if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                    templateData[applyOnIndex].showField = true;
                  }

                  if (!condition?.attributes?.is_condition_selected && !condition?.attributes?.is_response_to_show) {
                    templateData[applyOnIndex].showField = false;
                  }
                })
              }
            }
            this.setState({
              templateFormConditionsData: responseJson?.template_form_conditions?.data,
              templateFormFieldsData: templateData
            }, () => {
              this.state.selectedTaskTemplate ? this.handleClickBriefingTemplateform(this.state.templateFormFieldsData, this.state.templateFormConditionsData) : null
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.taskTemplateAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callTemplateFormFieldsApi(responseJson.data.id);
            setTimeout(() => {
              this.callTemplateFormConditionsApi(responseJson.data.id);
            }, 3000);
            //console.log("afterthat 3", responseJson)
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreatetaskTemplateApiId) {
      // Customizabledebugger;
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetTaskTemplateListApi(responseJson.data.attributes.task_id);

            // toast.success("Template created successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.blockersAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            blockersData: responseJson.task_blockers?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetStatusListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            var columnOrder: any = this.state.columnOrderKanban
            var dynamicStatus: any = []
            var columns: any = this.state.columnsKanban
            var dataTest: any = [
              // {
              //   id: "1",
              //   attributes: {
              //     status: "UnStarted",
              //     action: "unstarted"

              //   }
              // },
              // {
              //   id: "2",
              //   attributes: {
              //     status: "Open",
              //     action: "open"
              //   }
              // },
              {
                id: "1",
                attributes: {
                  status: "Draft",
                  action: "draft"
                }
              },
              {
                id: "2",
                attributes: {
                  status: "Quote Pending",
                  action: "quotepending"
                }
              },
              {
                id: "3",
                attributes: {
                  status: "Quote Approved",
                  action: "approve_quote"
                }
              },
              {
                id: "4",
                attributes: {
                  status: "Inprogress",
                  action: "inprogress_action"
                }
              },
              ...responseJson.statuses.data,
            ]

            this.setState({
              taskStatusesData: dataTest,
            }, () => {
              dataTest.map((item: any) => {

                dynamicStatus.push({ "id": item.attributes.status, "action": item.attributes.action, "title": item.attributes.status, "messageId": '' })
                columns[item.attributes.status] = {
                  id: item.id,
                  title: item.attributes.status,
                  action: item.attributes.action,
                  tasks: [],
                  messageId: ''
                }
              })
            })
            this.setState(function (state, props) {
              return {
                dynamicStatus: columns,
                columnOrderKanban: dynamicStatus,
                columnsKanban: columns,
                dynamicMessages: columns,
              };
            });
            //console.log("alltogether", this.state.taskStatusesData, this.state.dynamicStatus, this.state.columnOrderKanban, this.state.columnsKanban, this.state.dynamicMessages)
            const selectedProjectId: any = localStorage.getItem("selectedProjectId")
            if (selectedProjectId !== null) {
              // this.callGetUnstartedTaskListApi(selectedProjectId);
              this.callGetAccountListApi('');
              // this.callGetOpenTaskListApi(selectedProjectId);
              dynamicStatus.map((column: any) => {
                this.callGetOngoingTaskListApi(selectedProjectId, column)
              })

            }

          }

        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.subtaskAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.setState({
            subTasksData: responseJson.sub_tasks.data,

          })
        }
      }
      else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(responseJson.data.id);
            this.callGetTaskBlockerListApi(responseJson.data.id);
            this.setState({
              isCurrentTaskSelected: true,
              currentTask: responseJson.data,
              currentTaskBriefingData: responseJson.data.attributes.briefing.data.attributes.briefed_values.data

            }, () => {
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.callGetTaskBlockerListApi(this.state.currentTask.id)
            toast.success("Blocker updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.statusUpdateToRequestQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.callGetStatusListApi();
          this.callShowTaskApi(responseJson.data.attributes.task_id);
          this.setState({ isComingFromBriefingForm: false });
        } else {
          //console.log("else")
        }
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(this.state.currentTask.id)
            toast.success("Sub task updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateTaskAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Task Data updated successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callStateTransitionsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("State changed successfully");
            this.callGetStatusListApi();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].status);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskQuotationApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Quote Approved successfully");
            // if (this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
            //   || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") {
            // this.getApprovedQuote()
            // this.getOpenQuote()
            // this.getPendingQuote()

            // } else {

            this.callGetStatusListApi();
            // }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              accountsData: responseJson.accounts.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectTaskCreateAPICallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.clear();
            console.log("checkkkkkkkk.....", responseJson);
            //this.callGetTaskTemplateListApi(responseJson.data.id);
            // this.callGetSubTaskListApi(responseJson.data.id);
            // this.callGetTaskBlockerListApi(responseJson.data.id);
            //console.log(responseJson);
            toast.success("Task created successfully");
            this.setState({ loading: false })
            this.handleClickStartBriefing();
            if (this.state.selectedTaskTemplate != null) {
              this.callCreateTaskTemplateApi(responseJson?.data?.id);
            } else {
              this.callGetTaskTemplateListApi(responseJson?.data?.id);
            }

            this.setState({
              isCurrentTaskSelected: true,
              currentTask: responseJson.data,
            }, () => {
            })
          }
        }
      } else if (responseJson.errors) {
        // toast.error(errorResponse.errors[0].title);
        this.setState({
          openTaskCreateModel: true,
          openAddBriefingModel: false,
          isLabel: '',
          loading: false
        });
      } else if (responseJson.error) {
        // toast.error(errorResponse.error);
        this.setState({
          openTaskCreateModel: true,
          openAddBriefingModel: false,
          isLabel: '',
          loading: false
        });
      } else {
        // this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType1) {
      // debugger;
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        this.setState(prevState => ({
          projectsOfStatusDraftData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
          //total_pages: message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : prevState.total_pages,
          //next_page: message?.properties?.RestAPIResponceSuccessMessage?.next_page ? message?.properties?.RestAPIResponceSuccessMessage?.next_page : prevState.next_page
        }));
      }
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.asset_categories?.data) {

      this.setState({
        assetCategoriesData: message?.properties?.RestAPIResponceSuccessMessage?.asset_categories?.data,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.asset_types?.data) {

      this.setState({
        assetTypesData: message?.properties?.RestAPIResponceSuccessMessage?.asset_types?.data,
      }, () => {
      })
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.brand_templates?.data) {

      this.setState({
        templatesData: message?.properties?.RestAPIResponceSuccessMessage?.brand_templates?.data,
      }, () => {
      })
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTemplateFormFieldsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // let dataLength = responseJson.template_form_fields.data.length;
            console.log("response", responseJson.template_form_fields.data);

            let removed = responseJson.template_form_fields.data.shift();
            console.log("removed", removed);
            let dataLength = responseJson.template_form_fields.data.length;
            if (responseJson.template_form_fields?.data[dataLength - 1]?.attributes?.element == "page_separator") {
              responseJson.template_form_fields.data.splice((dataLength - 1), 0, removed);
              console.log("response2", responseJson.template_form_fields.data);

              // responseJson.template_form_fields.data.unshift(removed);
            } else {
              responseJson.template_form_fields.data.unshift(removed);
              console.log("response3", responseJson.template_form_fields.data);

              responseJson.template_form_fields.data.push(
                {
                  "attributes":
                  {
                    "default_value": "page_separator",
                    "element": "page_separator",
                    "label": ""
                  },
                  "id": "1",
                  "type": "template_form_field"
                }
              )
            }
            console.log("response4", responseJson.template_form_fields.data);

            var paginationCount = responseJson.template_form_fields.data.map((items: any) => items.attributes.element == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
            // console.clear();
            //console.log("Duplicate Values", paginationCount.true)
            let pageSeparatorIndex: any = []
            responseJson.template_form_fields.data.map((item: any, index: any) => {
              if (item.attributes.element == "page_separator") {
                //console.log("Index page", index)
                // console.log("", index)
                pageSeparatorIndex.push(index)
              }
            })
            // if(pageSeparatorIndex.length > 0){
            //   if(pageSeparatorIndex[pageSeparatorIndex.length - 1] < (responseJson.template_form_fields.data.length - 1)){
            //     paginationCount.true = paginationCount.true + 1;
            //   }
            // }
            this.setState({ paginationCount: paginationCount.true })
            if (paginationCount.true !== undefined) {
              this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {
                //console.log("Page Separator Array", this.state.pageSeparatorIndex)
                this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
              })
            } else {
              this.setState({ fromStart: 0, fromEnd: 100 })
            }

            //console.log("---###################---------------------------------------------")
            //console.log(responseJson)

            let tempArr = [] as any;

            responseJson.template_form_fields.data.map((_data: any) => {
              tempArr.push({
                attributes: _data.attributes,
                id: _data.id,
                showField: true
              })
            })
            tempArr.map((_data: any) => {
              if (_data.attributes.element == "multiple_selection") {
                if (_data.attributes.default_value !== null && _data.attributes.default_value !== "") {
                  _data.attributes.default_value = _data.attributes.default_value.split(",")
                  _data.value = _data.attributes.default_value;
                } else if (_data.attributes.element == "page_separator") {

                } else {
                  _data.attributes.default_value = [];
                  _data.value = [];
                }
              } else {
                if (_data.attributes.default_value !== null) {
                  _data.value = _data.attributes.default_value
                } else {
                  _data.value = ""
                }
              }
            })
            this.setState({
              templateFormFieldsData: tempArr,
              loading: false
            }, () => {
              let elementForm = this.state.templateFormFieldsData
              // let removed = elementForm.shift()
              // elementForm.push(removed)
              console.log("From State", elementForm)
              this.setState({ templateFormFieldsData: elementForm })
              console.log("Data from the template", this.state.templateFormFieldsData)
            });
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson === undefined || !responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            //console.log("responseJsonforbrands", responseJson)
            this.setState({ brandsData: responseJson.brands?.data })
            // this.setState({ next_page: responseJson.next_page })
            // this.setState({ total_pages: responseJson.total_pages })
            // console.log("Responses of brands", this.state.brandsData);
            // let DMYString = ""
            // let brandUpdatedDate: any = [];
            // this.state.brandsData?.map((items: any) => {
            //   let modifiedDate: any = items.attributes.updated_at;
            //   console.log("Data Date0", modifiedDate)

            //   var part: any = modifiedDate.split(" ");
            //   console.log("Data Date1", part)

            // let dataDate: any = new Date(modifiedDate)
            // console.log("Data Date",dataDate)
            // let dd = dataDate.getDate();
            // let mm = dataDate.getMonth();
            // let yy = dataDate.getFullYear();
            // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            // DMYString = part[0] + " " + part[1] + " " + part[2];
            // brandUpdatedDate.push({ id: items.id, DMY: DMYString })
            // })
            // this.setState({ brandUpdatedCompleteDate: brandUpdatedDate })
            // console.log("Dateee", this.state.brandUpdatedCompleteDate)
            //search live
            //recent search
          }
        }
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectCreateApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Project created successfully");
            this.setState({ anchorE5: false })

            //  this.callGetProjectsListApiType1();
            //this.callGetProjectsListApiTypeForAB();
            var str = window.location.href;
            console.log("str", str.substring(str.lastIndexOf("/")) + 1);

            if (str.substring(str.lastIndexOf("/") + 1) == "Brands") {
              window.location.href = '/Home/Brands'
            }
            else if (str.substring(str.lastIndexOf("/") + 1) == 'Projects') {
              window.location.href = '/Home/Projects'
            }

            // this.getBrandListing();
          }
        }
      } else if (responseJson.errors) {
        if (errorResponse.errors[0].name) {
          toast.error(`Project` + " " + errorResponse.errors[0].name);
        } else {
          toast.error(errorResponse.errors[0].account);
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.properties?.RestAPIResponceSuccessMessage?.account_notifications) {
      // debugger;
      this.setState({
        notificationData: message.properties?.RestAPIResponceSuccessMessage?.account_notifications?.data,
      }, () => {
        //console.log(this.state.notificationData, "notificationData")
        this.setNotificatioPreferences(this.state.notificationData)
      })
    }
    if (message.properties?.RestAPIResponceSuccessMessage?.alert_notifications) {
      this.setState({
        alertNotificationsData: message.properties?.RestAPIResponceSuccessMessage?.alert_notifications?.data,
      }, () => {
        //console.log(this.state.alertNotificationsData, "alertNotificationsData")
        this.getBasedNotifications(this.state.alertNotificationsData);
      })
    }
    if (message.properties?.RestAPIResponceSuccessMessage?.data != null) {
      var messages = message.properties?.RestAPIResponceSuccessMessage?.data

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.showProjectsName) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ projectDataDrop: responseJson.projects.data })
          //console.log("response.project", responseJson)
        }
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ projectSelectedDataByBrandId: [...this.state.projectSelectedDataByBrandId, ...responseJson.projects.data] }, () => {
            this.setState({
              projectSelectedDataByBrandId: Array.from(new Map(this.state.projectSelectedDataByBrandId.map((e: any) => [e.id, e])).values())
            })
          })
          this.setState({ isNextFunc: true });
          this.setState(prevState => ({
            total_pages: responseJson.total_pages
              ? responseJson.total_pages
              : prevState.total_pages
          }))
          //console.log("response.project from approval request", responseJson)
        }
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getApproveProjectByBrandId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ projectSelectedDataByBrandId2: [...this.state.projectSelectedDataByBrandId2, ...responseJson.projects.data] }, () => {
            this.setState({
              projectSelectedDataByBrandId2: Array.from(new Map(this.state.projectSelectedDataByBrandId2.map((e: any) => [e.id, e])).values())
            })
          })
          this.setState({ isNextFunc: true });
          this.setState(prevState => ({
            total_pages: responseJson.total_pages
              ? responseJson.total_pages
              : prevState.total_pages
          }))
          //console.log("response.project from approval request", responseJson)
        }
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandIdUsingFilter) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ projectSelectedDataByBrandId: responseJson.projects.data })
          this.setState({ isNextFunc: true });
          this.setState(prevState => ({
            total_pages: responseJson.total_pages
              ? responseJson.total_pages
              : prevState.total_pages
          }))
          //console.log("response.project from approval request", responseJson)
        }
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandIdUsingFilter2) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ projectSelectedDataByBrandId2: responseJson.projects.data })
          this.setState({ isNextFunc: true });
          this.setState(prevState => ({
            total_pages: responseJson.total_pages
              ? responseJson.total_pages
              : prevState.total_pages
          }))
          //console.log("response.project from approval request", responseJson)
        }
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.inviteUserApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson)
      if (responseJson.message) {
        toast.success(responseJson.message)
        this.handleCloseInviteDialog();
      } else {
        toast.error("Something went wrong.")
      }
    }

  }
  setNotificatioPreferences(value?: any) {

    if (value && value.length > 0) {
      let data = value.forEach((item: any) => {
        this.setState({
          task_notification_email_frequency: item.attributes.task_notification_email_frequency,
          mute_notifications: item.attributes.mute_notifications,
          tagged_email_notifications: item.attributes.tagged_email_notifications,
          task_email_notifications: item.attributes.task_email_notifications,
          account_notification_id: item.id
        })
      })
    } else {
      this.DefaultAccountNotificationSettings();
    }
  }
  handleNotificationOpen = () => {
    this.setState({
      setNotification: true
    })
  }

  handleRetakeOnboarding = () => {
    localStorage.setItem("onBoardingStep", "1");
    localStorage.setItem("toShowOnboarding", "true");
    window.location.replace("/Home/HomePage");
  }

  handleCloseNotification = () => {
    this.setState({
      setNotification: false
    })
  }
  handleAlertNotificationOpen = () => {
    this.setState({
      setAlertNotification: true
    })
  }

  handleCloseAlertNotification = () => {
    this.setState({
      setAlertNotification: false
    })
  }

  openMenuProject = (event: any) => {
    //console.log("EVent Details", event)
    this.setState({ setAnchorEl: event.currentTarget });
  }
  closeMenu = () => {
    this.setState({ setAnchorEl: null });
  }
  closeMenuProject = (item?: any) => {
    this.setState({ setAnchorEl: null }, () => {
      localStorage.setItem("selectedBrandName", item?.attributes?.brand?.data?.attributes?.name);
      localStorage.setItem("BrandNameIcon", item?.attributes?.brand?.data?.attributes?.name?.match(/\b(\w)/g).join(''));
      localStorage.setItem("selectedProject", item.id);
      const sideNavData = {
        sideNavData:
          [
            {
              name: "photoproduction"
            },
            {
              name: "CGI"
            }


          ]
      }
      let da = { sideNavData: sideNavData, key: "projects" }
      localStorage.setItem("navData", JSON.stringify(da))
      window.location.href = '/Home/Projectportfolio';
    });
  };
  DefaultAccountNotificationSettings() {

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };


    const data = {
      "tagged_email_notifications": true,
      "task_email_notifications": true,
      "mute_notifications": false,
      "task_notification_email_frequency": "immediately"
    };

    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendNoticationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notification_settings/account_notifications_settings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  closeMenuProjectActive = (item?: any) => {
    this.setState({ setAnchorEl: null }, () => {

      const sideNavData = {
        sideNavData:
          [
            {
              name: "photoproduction"
            },
            {
              name: "CGI"
            }


          ]
      }
      let da = { sideNavData: sideNavData, key: "projects" }
      localStorage.setItem("navData", JSON.stringify(da))
      window.location.href = '/Home/Projects';
    });
  };

  AccountNotificationSettings() {
    var userId = localStorage.getItem("userId")
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showNotificationAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notification_settings/account_notifications_settings?account_id=${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTasksByFilter = (path: any) => {
    // var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    // var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    console.clear()


    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksByFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "bx_block_project_portfolio/tasks" + `?brand_id=${brand_id}&project_id=${project_id}${path}`
      `bx_block_project_portfolio/${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandsByFilter = (path: any) => {
    // var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    // var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");


    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsByFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "bx_block_project_portfolio/tasks" + `?brand_id=${brand_id}&project_id=${project_id}${path}`
      `bx_block_categories_sub_categories/${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  AlertNotifications() {
    var userId = localStorage.getItem("userId")
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showAlertNotificationsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/alert_notifications?type=team&target_user_id=${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getProjectNames = () => {
    const header = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showProjectsName = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  goToAccountProfile = () => {
    this.props.navigation.navigate('AccountProfile')
  }

  goToHelp = () => {
    this.props.navigation.navigate('HelpCentre')
  }

  goToCompanyProfile = () => {
    this.props.navigation.navigate('CompanyProfile')
  }
  goToAdminConsole = () => {
    window.location.href = "/AdminConsole/Users"
  }
  handleClickOpenModal = () => {
    this.getBrandTeams();
    this.getBrandListing();
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleListItemClick = (event: any, index: any, str?: any) => {
    this.setState({
      selectedIndex: index,
      activeStatus: str,
    });
  };
  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = (item?: any) => {
    //console.log(item, "acount");
    this.setState({
      anchorEl: null,
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "logout") {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  };
  handleClickProjects = (event: any) => {
    //console.log(event, "h");
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  logOut = () => {
    //console.log("item2");
    let language: any = localStorage.getItem("i18nextLng");
    localStorage.clear();
    localStorage.setItem("i18nextLng", language);
    this.props.navigation.navigate("EmailAccountLoginBlock");


  }

  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null,
    });
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    //console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPage: value }, () => { this.getProjectByBrandID() });
  };


  async componentDidMount() {

    let searchItem: any = localStorage.getItem("searchSelectValue")
    if (searchItem) (
      this.setState({ selectSeacrh: searchItem })
    )

    let value: any = localStorage.getItem("onBoardingStep");
    let valueData: any = localStorage.getItem("toShowOnboarding");
    if ((parseInt(value) == 11 && window.location.href.includes("Projectportfolio")) ||
      (parseInt(value) == 14 && window.location.href.includes("Teams"))) {
      localStorage.setItem("toShowOnboarding", "true");
      if (valueData == "false") window.location.reload();
    }
    const location = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    // if(location == "Projectportfolio"){
    this.getProjectByBrandID();
    // }
    // if(location == "ProjectList"){
    this.getApproveProjectByBrandID();
    // }
    this.AccountNotificationSettings()

    //console.log(location, "ll");
    if (location == "Projectportfolio") {
      this.setState({
        sideNavStatus: true,
      });
    } else {
      this.setState({
        sideNavStatus: false,
      });
    }
    this.loadUserDetailsAndRoles();
  }

  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }

  getBrandListing = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/brands/brand_project_listing" + `?page=1&per_page=50`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  approvalProjectFilterHandleClick = (event: any) => {
    this.setState({ anchorE4: event.currentTarget });
  };

  approvalProjectFilterHandleClose = () => {
    this.setState({ anchorE4: null });
  };

  getApprovalProjectByBrandIDAndFilter = (flag: any) => {
    this.approvalProjectFilterHandleClose();
    if (this.state.projectSortByType === flag) {
      this.getApproveProjectByBrandID()
    } else {
      this.setState({ projectSortByType: flag }, () => {
        const header = { "Content-Type": "application/json", token: localStorage.getItem("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProjectByBrandIdUsingFilter2 = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_project_portfolio/projects?page=1&per_page=10&&sort_by=${flag}&sort_order=${(this.state.projectSortByType === 'name') ? 'asc' : 'desc'}&brand_id=${localStorage.getItem('selectedBrand')}&quote_requested_or_pending=true`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
      });
    }
  }

  projectFilterHandleClick = (event: any) => {
    this.setState({ anchorE3: event.currentTarget });
  };

  projectFilterHandleClose = () => {
    this.setState({ anchorE3: null });
  };

  getProjectByBrandIDAndFilter = (flag: any) => {
    this.projectFilterHandleClose();
    if (this.state.projectSortByType === flag) {
      this.getProjectByBrandID();
    } else {
      this.setState({ projectSortByType: flag }, () => {
        const header = { "Content-Type": "application/json", token: localStorage.getItem("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProjectByBrandIdUsingFilter = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_project_portfolio/projects?page=1&&per_page=10&&sort_by=${flag}&&sort_order=${(this.state.projectSortByType === 'name') ? 'asc' : 'desc'}&&brand_id=${localStorage.getItem('selectedBrand')}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
      });
    }
  }
  handleStartOnboarding = () => {
    this.setState({
      onBoardingShow: false
    })
    localStorage.setItem("onBoardingStep", "2")
  }

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 14) {
      localStorage.setItem("onBoardingStep", "20")
      localStorage.setItem("toShowOnboarding", "false");
      window.location.reload();
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handleSkipWholeOnboarding = () => {
    localStorage.setItem("onBoardingStep", "20")
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
    this.forceUpdate();
  }

  handleSkipOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 2 || parseInt(value) == 3) localStorage.setItem("onBoardingStep", "5");
    if (parseInt(value) == 11) localStorage.setItem("onBoardingStep", "13");
    if (parseInt(value) == 14) localStorage.setItem("onBoardingStep", "20");
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  openInviteDialog = () => {
    this.setState({
      inviteDialog: true
    }, () => {
      let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
      this.setState({
        inviteUserName: `${userInfo.first_name} ${userInfo.last_name}`
      })
    })
  }



  handleCloseInviteDialog = () => {
    this.setState({ inviteDialog: false, selectedOptions: [] })
  }

  handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      this.setState({ searchValue: event.target.value, isSearch: true })

      if (this.state.filterValue !== "") {
        this.getTasksByFilter(`&title=${event.target.value}&Sort_by=${this.state.filterValue}`);
      } else {
        if (this.state.selectSeacrh == "task") {

          this.getTasksByFilter(`tasks?title=${event.target.value}`);

        }
        if (this.state.selectSeacrh == "project") {
          this.getTasksByFilter(`projects?page=1&name=${event.target.value}`);
        }
        if (this.state.selectSeacrh == "brand") {
          this.getBrandsByFilter(`brands/brand_project_listing?page=1&per_page=1000&brand_name=${event.target.value}`);
        }
      }

    } else {
      this.setState({ searchValue: "", isSearch: false })
    }
  }


  handleInviteEmailChange = (event: any) => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(event.target.value.toLowerCase())) {
      this.setState({
        inviteEmail: event.target.value,
        inviteEmailError: false
      })
    } else {
      this.setState({
        inviteEmail: event.target.value,
        inviteEmailError: true
      })
    }
  }

  inviteUser = () => {

    if (this.state.selectedOptions.length !== 0) {
      const httpBody = {
        data: {
          emails: this.state.selectedOptions
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.inviteUserApiCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/accounts/invite_user"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  render() {
    // this.loadUserDetailsAndRoles()
    const { t, navigation, classes } = this.props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    //console.log(userInfo, "userInfo",);
    const _this = this;
    // const queryString = window.location.search
    let navBarData = []
    let status = ""
    let routingData = [{
      id: 1,
      name: "Project List"

    },
    ]
    //console.log(routingData[0].name, "index")

    if (window.location.href.includes("Projectportfolio") || window.location.href.includes("Photoproduction") || window.location.href.includes("CGI") || window.location.href.includes("Approve") || window.location.href.includes(`${routingData[0].name.replace(/\s/g, "")}`)) {
      const sideNavigate = localStorage.getItem("navData")
      if (sideNavigate) {
        navBarData = JSON.parse(sideNavigate).sideNavData
        status = JSON.parse(sideNavigate).key
        //console.log(status, "status")
      }
    }

    const location = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    if (location == "Projectportfolio") {
      //console.log("hello");
    }
    //console.log(this.props, "his");
    //console.log("hred", this.state.sideNavStatus);

    // if(this.props.history){

    // }
    let colorToBeChanged: any;
    let primaryFont: any;
    let SecondaryFont: any

    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    //console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
    //console.log(navBarData, "nv")

    // let projectDatas: any = JSON.parse(localStorage.getItem('projectsDataSelected') || "[]")


    //  const { closeHandler } = < HubSpotChat po(168444) />;

    const ITEM_HEIGHT = 48;
    const brandImage: any = localStorage.getItem('selectedBrandImage');
    const brandLogo: any = localStorage.getItem("brandLogo")
    return (
      <div className={classes.root} style={{ fontFamily: primaryFont }}>
        <Loader loading={this.state.otherloading} />
        <Backdrop
          className={classes.backdrop2}
          open={this.state.loading2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <BrowserRouter>
          {/* <Backdrop
                className={classes.backdrop}
                open={this.state.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop> */}
          <Grid container spacing={0}>
            <Grid item xs={2} className={classes.sideNav}>
              <div className={classes.root1}>
                <Grid container spacing={0}>
                  <Grid item xs={1} />
                  <Grid item xs={11} />
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={2} />
                  <Grid item xs={10} className={classes.designHarvest} onClick={() => window.location.href = "/Home/HomePage"}>
                    <img src={brandLogo != null && brandLogo?.length > 0 ? brandLogo.split("?")[0] : logo.default} style={{ objectFit: "scale-down" }} className={classes.designHarvestLogo} />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={1} />
                  <Grid item xs={11} className={classes.Board}>
                  </Grid>
                </Grid>



                {!status ?
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={1} />
                      <Grid item xs={11} className={classes.PersonalBoard}>
                        <p className={classes.recentBoard}>
                          {t("HomeScreen.persnolBoards")}
                        </p>
                      </Grid>
                    </Grid>
                    {(this.state.permissionName === 'superadmin') || (this.state.permissionName === "albertbauer" && this.state.permissions === "editor") || (this.state.permissionName === "albertbauer" && this.state.permissions === "viewer") || (this.state.permissionName === "albertbauer" && this.state.permissions === "brand_manager") ? (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <NavLink
                          to={(localStorage.getItem("projectType") == null || localStorage.getItem("projectType") == "Active Projects") ? "/Home/Brands" : "/Home/ProjectsTypes"}
                          style={{ textDecoration: "none", flex: 1, fontFamily: primaryFont, color: colorToBeChanged }}
                        >
                          <ListItem
                            button
                            selected={this.state.selectedIndex === 2}
                            onClick={(event) => {
                              if (window.location.href.match("navigate")) {
                                window.location.href = "/Home/Brands"
                              }

                              this.setState({ projectStatus: 1 }, () => this.handleListItemClick(event, 2))
                            }}
                          >
                            <ListItemIcon>
                              <Svg
                                color={
                                  this.state.selectedIndex === 2
                                    ? "white"
                                    : colorToBeChanged
                                }
                                keyword="projectSVg"
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={localStorage.getItem("projectType") == "Deleted Projects" ? t("HomeScreen.deleteProject") : localStorage.getItem("projectType") == "Archive Projects" ? t("HomeScreen.archiveProject") : t("HomeScreen.activeProject")}
                              style={{
                                color: this.state.selectedIndex === 2
                                  ? "white"
                                  : colorToBeChanged,
                                fontFamily: primaryFont
                              }}
                            />
                            <Button
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={this.openMenuProject}
                              style={{
                                padding: "10px", marginRight: "-15px", marginBottom: "-10px", marginTop: "-10px"
                              }}
                            >
                              <ExpandMoreIcon />
                            </Button>
                          </ListItem>
                        </NavLink>

                        <Menu
                          id="long-menu"
                          anchorEl={this.state.setAnchorEl}
                          //keepMounted
                          open={Boolean(this.state.setAnchorEl)}
                          onClose={this.closeMenu}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: 'auto',
                            },
                          }}
                        >
                          <MenuItem onClick={() => {
                            localStorage.setItem("projectType", "Active Projects")

                            window.location.href = "/Home/Brands";
                          }}
                          >
                            <span className={classes.taskImgs} style={{ color: colorToBeChanged }}>
                              <img className={classes.taskImg} src={activeProjects} /> </span>
                            {t("HomeScreen.activeProject")}
                          </MenuItem>
                          <MenuItem onClick={() => {
                            localStorage.setItem("projectType", "Archive Projects")
                            // window.location.href = "/Home/ProjectsTypes";
                            window.location.href = "/Home/Brands";
                          }}>
                            <span className={classes.taskImgs} style={{ color: colorToBeChanged }} >
                              <img className={classes.taskImg} src={archiveProjects} /> </span>{t("HomeScreen.archiveProject")}
                          </MenuItem>
                          <MenuItem onClick={() => {
                            localStorage.setItem("projectType", "Deleted Projects")
                            window.location.href = "/Home/Brands";
                          }}>
                            <span className={classes.taskImgs} style={{ color: colorToBeChanged }} >
                              <img className={classes.taskImg} src={deleteProjects} /> </span> {t("HomeScreen.deleteProject")}
                          </MenuItem>
                        </Menu>
                      </div>
                    ) :
                      (this.state.permissionName === "client" && this.state.permissions === "viewer") || (this.state.permissionName === "client" && this.state.permissions === "approver") || (this.state.permissionName === "client" && this.state.permissions === "brand_manager") ?
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <NavLink
                            to={(localStorage.getItem("projectType") == null || localStorage.getItem("projectType") == "Active Projects") ? "/Home/Projects" : "/Home/ProjectsTypes"}
                            style={{ textDecoration: "none", flex: 1, fontFamily: primaryFont, color: colorToBeChanged }}
                          >
                            <ListItem
                              button
                              selected={this.state.selectedIndex === 2}
                              onClick={(event) => {
                                localStorage.removeItem("projectType")
                                this.handleListItemClick(event, 2)
                              }}
                            >
                              <ListItemIcon>
                                <Svg
                                  color={
                                    this.state.selectedIndex === 2
                                      ? "white"
                                      : colorToBeChanged
                                  }
                                  keyword="projectSVg"
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={localStorage.getItem("projectType") == "Deleted Projects" ? t("HomeScreen.deleteProject") : localStorage.getItem("projectType") == "Archive Projects" ? t("HomeScreen.archiveProject") : t("HomeScreen.activeProject")}
                                style={{
                                  color: this.state.selectedIndex === 2
                                    ? "white"
                                    : colorToBeChanged, fontFamily: primaryFont
                                }}
                              />
                              <Button
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.openMenuProject}
                                style={{
                                  padding: "10px", marginRight: "-15px", marginBottom: "-10px", marginTop: "-10px"
                                }}
                              >
                                <ExpandMoreIcon />
                              </Button>
                            </ListItem>
                          </NavLink>
                          <Menu
                            id="long-menu"
                            anchorEl={this.state.setAnchorEl}
                            //keepMounted
                            open={Boolean(this.state.setAnchorEl)}
                            onClose={this.closeMenu}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 'auto',
                              },
                            }}
                          >
                            <MenuItem onClick={() => {

                              localStorage.setItem("projectType", "Active Projects")
                              window.location.href = "/Home/Projects";
                            }}>
                              <span className={classes.taskImgs} >
                                <img className={classes.taskImg} src={activeProjects} /> </span>
                              {/* {t("HomeScreen.activeProject")} */}
                            </MenuItem>
                            <MenuItem onClick={() => {
                              localStorage.setItem("projectType", "Archive Projects")
                              window.location.href = "/Home/ProjectsTypes";
                            }}>
                              <span className={classes.taskImgs} >
                                <img className={classes.taskImg} src={archiveProjects} /> </span>{t("HomeScreen.archiveProject")}
                            </MenuItem>
                            <MenuItem onClick={() => {
                              localStorage.setItem("projectType", "Deleted Projects")
                              window.location.href = "/Home/ProjectsTypes";
                            }}>
                              <span className={classes.taskImgs} >
                                <img className={classes.taskImg} src={deleteProjects} /> </span> {t("HomeScreen.deleteProject")}
                            </MenuItem>
                          </Menu>
                        </div>
                        :
                        (
                          <NavLink
                            to="/Home/Projects"
                            style={{ textDecoration: "none", fontFamily: primaryFont, color: colorToBeChanged }}
                          >
                            <ListItem
                              button
                              selected={this.state.selectedIndex === 2}
                              onClick={(event) => this.handleListItemClick(event, 2)}
                            >
                              <ListItemIcon>
                                <Svg
                                  color={
                                    this.state.selectedIndex === 2
                                      ? "white"
                                      : colorToBeChanged
                                  }
                                  keyword="projectSVg"
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={localStorage.getItem("projectType") == "Deleted Projects" ? t("HomeScreen.deleteProject") : localStorage.getItem("projectType") == "Archive Projects" ? t("HomeScreen.archiveProject") : t("HomeScreen.activeProject")}
                                style={{
                                  color: this.state.selectedIndex === 2
                                    ? "white"
                                    : colorToBeChanged, fontFamily: primaryFont
                                }}
                              />
                            </ListItem>
                          </NavLink>
                        )}



                    <NavLink
                      to={(this.state.permissionName == "albertbauer" || this.state.permissionName == "superadmin") ? "/Home/MediaAssets" : "/Home/MediaAssets/" + localStorage.getItem("selectedBrand")}
                      style={{ textDecoration: "none", fontFamily: primaryFont, color: colorToBeChanged }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 3}
                        onClick={(event) => this.handleListItemClick(event, 3)}
                      >
                        <ListItemIcon>
                          <Svg
                            color={
                              this.state.selectedIndex === 3
                                ? "white"
                                : colorToBeChanged
                            }
                            keyword="MediaAssetLibrarySVg"
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={t("HomeScreen.mediaAssets")}
                          style={{
                            color: this.state.selectedIndex === 3
                              ? "white"
                              : colorToBeChanged, fontFamily: primaryFont
                          }}
                        />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/Home/Teams"
                      style={{ textDecoration: "none", fontFamily: primaryFont, color: colorToBeChanged }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 4}
                        onClick={(event) => this.handleListItemClick(event, 4)}
                      >
                        <ListItemIcon>
                          <Svg
                            color={
                              this.state.selectedIndex === 4
                                ? "white"
                                : colorToBeChanged
                            }
                            keyword="teamsSVg"
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={t("HomeScreen.team")}
                          style={{
                            color: this.state.selectedIndex === 4
                              ? "white"
                              : colorToBeChanged, fontFamily: primaryFont
                          }}
                        />
                      </ListItem>
                    </NavLink>

                    {(this.state.permissionName === "superadmin" || ((this.state.permissionName === "albertbauer") && (this.state.permissions === "approver" || this.state.permissions === "editor" || this.state.permissions === "brand_manager"))) ? (
                      <NavLink
                        to="/Home/ApprovalRequest"
                        style={{ textDecoration: "none", fontFamily: primaryFont, color: colorToBeChanged }}
                      >
                        <ListItem
                          button
                          selected={this.state.selectedIndex === 5}
                          onClick={(event) => this.handleListItemClick(event, 5)}
                        >
                          <ListItemIcon>
                            <Svg color={this.state.selectedIndex === 5 ? "white" : colorToBeChanged} keyword="approvalRequestsSVg" />


                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={t("HomeScreen.approvalRequest")}
                            style={{
                              color: this.state.selectedIndex === 5
                                ? "white"
                                : colorToBeChanged, fontFamily: primaryFont
                            }}
                          />
                        </ListItem>
                      </NavLink>
                    ) : (this.state.permissionName === "client" && this.state.permissions === "approver") || (this.state.permissionName === "client" && this.state.permissions === "brand_manager") ? (
                      <NavLink
                        to="/Home/ProjectList"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem
                          button
                          selected={this.state.selectedIndex === 5}
                          onClick={(event) => {
                            localStorage.setItem('clientCounterVal', '1');
                            localStorage.setItem("selectedProjectId", this.state.projectSelectedDataByBrandId2?.projects?.data?.length > 0 ? this.state.projectSelectedDataByBrandId2.projects.data[0].attributes.id : null);
                            this.handleListItemClick(event, 5);
                          }}
                        >
                          <ListItemIcon>
                            <Svg color={this.state.selectedIndex === 5 ? "white" : colorToBeChanged} keyword="approvalRequestsSVg" />


                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={t("HomeScreen.approvalRequest")}
                            style={{
                              color: this.state.selectedIndex === 5
                                ? "white"
                                : colorToBeChanged, fontFamily: primaryFont
                            }}
                          />
                        </ListItem>
                      </NavLink>
                    )
                      : ""}
                  </> :
                  status === 'projects' ?
                    <>

                      <Grid container spacing={0}>
                        <Grid item xs={1} />
                        <Grid item xs={11} className={classes.PersonalBoard}>
                          <HtmlTooltip
                            arrow
                            placement="left"
                            open={localStorage.getItem("onBoardingStep") == "11" ? true : false}
                            title={
                              <React.Fragment>
                                <div>
                                  <img src={notificationIcon} className={classes.popupIcon} />
                                  <span className={classes.popupTitle}>Name Sorting</span>
                                  <span className={classes.popupCancelIcon}>
                                    <CloseIcon onClick={this.handleSkipOnboarding} />
                                  </span>
                                </div>
                                <div className={classes.popupText}>
                                  {t("onBoarding.notificationText")}
                                </div>
                                <div>
                                  <Button
                                    className={classes.popupSkipButton}
                                    onClick={this.handleSkipOnboarding}
                                    color={colorToBeChanged}
                                  >
                                    SKIP
                                  </Button>
                                  {/* <Button
                                    className={classes.popupButton}
                                    onClick={this.handlePrevOnboarding}
                                    >
                                    PREV
                                    </Button> */}
                                  <Button
                                    className={classes.popupButton}
                                    onClick={this.handleNextOnboarding}
                                    style={{ background: colorToBeChanged }}
                                  >
                                    NEXT
                                  </Button>
                                </div>
                              </React.Fragment>
                            }
                          >
                            <p className={classes.recentBoard + ' ' + localStorage.getItem("onBoardingStep") == "11" && classes.highlightText} style={{ display: "flex", alignItems: "center" }}>
                              <div>
                                {
                                  (localStorage.getItem('selectedBrandImage') == undefined || localStorage.getItem('selectedBrandImage') == null || localStorage.getItem('selectedBrandImage') == "") ?
                                    <Avatar style={{ height: "30px", width: "30px" }}>{localStorage.getItem('BrandNameIcon')}</Avatar> :
                                    <Avatar style={{ height: "30px", width: "30px" }} src={brandImage}></Avatar>
                                }
                              </div>
                              <div style={{ marginLeft: "16px", width: '115px' }}>
                                {localStorage.getItem('selectedBrandName')}
                                {/* {t("individualCompanyHomeScreen.companyName")} */}
                              </div>
                              <div style={{ marginTop: '4%' }}>
                                <IconButton aria-label="delete" className={classes.margin} size="small" onClick={this.projectFilterHandleClick}>
                                  <FilterListIcon fontSize="inherit" />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.anchorE3}
                                  keepMounted
                                  open={Boolean(this.state.anchorE3)}
                                  onClose={this.projectFilterHandleClose}
                                >
                                  {/* <MenuItem>
                                  <Checkbox
                                    color="primary"
                                    style={{ paddingTop: "14px", paddingLeft: '0px' }}
                                    name="sort_order"
                                    inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.defaultSortOrder} onChange={(e) => { this.defaultSortOrderHandleChange(e) }} /> {this.state.defaultSortOrder === false ? 'Decending' : 'Ascending'}
                                </MenuItem> */}
                                  <MenuItem onClick={() => this.getProjectByBrandIDAndFilter('name')}>Name</MenuItem>
                                  <MenuItem onClick={() => this.getProjectByBrandIDAndFilter('created_at')}>Recently Created</MenuItem>
                                  <MenuItem onClick={() => this.getProjectByBrandIDAndFilter('updated_at')}>Recently Modified</MenuItem>
                                </Menu>
                              </div>
                              {/* <span
                              style={{
                                marginTop: "0px",
                                position: "absolute",
                                right: "10px",
                              }}
                            >
                              <img src={plusButton} />
                            </span> */}
                            </p>
                          </HtmlTooltip>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <hr
                              style={{
                                height: "1px",
                                border: "none",
                                backgroundColor: "#737373",
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="addScrollBar" id="scrollableDiv">
                        <InfiniteScroll
                          dataLength={this.state.projectSelectedDataByBrandId.length}
                          next={this.fetchMoreData}
                          hasMore={this.state.isNextFunc}
                          loader={this.state.total_pages ? "" : <h4>Loading...</h4>}
                          scrollableTarget="scrollableDiv"
                        >
                          {
                            //console.log(this.state.projectSelectedDataByBrandId)
                          }
                          <div>
                            {this.state.projectSelectedDataByBrandId.length > 0 ?
                              this.state.projectSelectedDataByBrandId?.map((dataProject: any) => (
                                <>
                                  <ListItem
                                    button
                                    selected={dataProject.id === localStorage.getItem('selectedProjectId')}
                                    onClick={() => {
                                      localStorage.setItem('selectedProject', dataProject.id);
                                      localStorage.setItem("selectedProjectId", dataProject.id);
                                      localStorage.setItem("selectedProjectStatus", dataProject.attributes.status)
                                      localStorage.setItem("selectedProjectName", dataProject.attributes.name);
                                      window.location.href = '/Home/Projectportfolio'
                                    }}
                                    key={dataProject.id}
                                  >
                                    <ListItemIcon>
                                      <Svg
                                        color={
                                          (this.state.selectedIndex === 2) || (dataProject.id === localStorage.getItem('selectedProjectId'))
                                            ? "white"
                                            : colorToBeChanged
                                        }
                                        keyword="folderSvg"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      classes={{ primary: classes.listItemText }}
                                      style={{
                                        color: (dataProject.id === localStorage.getItem('selectedProjectId')) ? 'white' : secondaryColorToBeChanged
                                      }}
                                    >
                                      {dataProject?.attributes?.name}
                                    </ListItemText>
                                  </ListItem>
                                </>
                              ))
                              :
                              <ListItem
                                button
                              >
                                <ListItemIcon>
                                  <Svg color={this.state.selectedIndex === 2 ? "white" : colorToBeChanged} keyword="folderSvg" />
                                </ListItemIcon>
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                  style={{
                                    color: secondaryColorToBeChanged
                                  }}
                                >
                                  No Project found
                                </ListItemText>

                              </ListItem>
                            }
                          </div>
                        </InfiniteScroll>
                        {/* <Pagination count={this.state.total_pages} page={this.state.setPage} onChange={this.handlePage} /> */}
                      </div>
                    </>
                    : <>

                      <Grid container spacing={0}>
                        <Grid item xs={1} />
                        <Grid item xs={11} className={classes.PersonalBoard}>
                          <p className={classes.recentBoard} style={{ display: "flex", alignItems: "center" }}>
                            <div>
                              {
                                (localStorage.getItem('selectedBrandImage') == undefined || localStorage.getItem('selectedBrandImage') == null || localStorage.getItem('selectedBrandImage') == "") ?
                                  <Avatar style={{ height: "30px", width: "30px" }}>{localStorage.getItem('BrandNameIcon')}</Avatar> :
                                  <Avatar style={{ height: "30px", width: "30px" }} src={brandImage}></Avatar>
                              }
                            </div>
                            <div style={{ marginLeft: "16px", width: '115px' }}>
                              {localStorage.getItem('selectedBrandName')}
                            </div>
                            <div style={{ marginTop: '4%' }}>
                              <IconButton aria-label="filter" className={classes.margin} size="small" onClick={this.approvalProjectFilterHandleClick}>
                                <FilterListIcon fontSize="inherit" />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorE4}
                                keepMounted
                                open={Boolean(this.state.anchorE4)}
                                onClose={this.approvalProjectFilterHandleClose}
                              >
                                {/* <MenuItem>
                                  <Checkbox
                                    color="primary"
                                    style={{ paddingTop: "14px", paddingLeft: '0px' }}
                                    name="sort_order"
                                    inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.defaultSortOrder} onChange={(e) => { this.defaultSortOrderHandleChange(e) }} /> {this.state.defaultSortOrder === false ? 'Decending' : 'Ascending'}
                                </MenuItem> */}
                                <MenuItem onClick={() => this.getApprovalProjectByBrandIDAndFilter('name')}>Name</MenuItem>
                                <MenuItem onClick={() => this.getApprovalProjectByBrandIDAndFilter('created_at')}>Recently Created</MenuItem>
                                <MenuItem onClick={() => this.getApprovalProjectByBrandIDAndFilter('updated_at')}>Recently Modified</MenuItem>
                              </Menu>
                            </div>
                          </p>
                          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <hr style={{ height: "1px", border: "none", backgroundColor: "#737373" }}></hr>
                          </div>
                        </Grid>
                      </Grid>

                      {routingData.map((item) => {
                        return (
                          <>
                            <div className="addScrollBar" id="scrollableDiv">
                              <InfiniteScroll
                                dataLength={this.state.projectSelectedDataByBrandId2.length}
                                next={this.fetchMoreData2}
                                hasMore={this.state.isNextFunc}
                                loader={this.state.total_pages ? "" : <h4>Loading...</h4>}
                                scrollableTarget="scrollableDiv"
                              >
                                <div>
                                  {
                                    this.state.projectSelectedDataByBrandId2.length > 0 ?
                                      this.state.projectSelectedDataByBrandId2?.map((dataProject: any) => (
                                        <>
                                          <ListItem
                                            button
                                            selected={localStorage.getItem('selectedProjectId') == dataProject.id}
                                            onClick={(event: any) => {
                                              this.handleListItemClick(event, dataProject.id);
                                              localStorage.setItem('selectedProjectId', dataProject.id);
                                              localStorage.setItem('selectedProject', dataProject.id);
                                            }}
                                            key={dataProject.id}
                                          >
                                            <ListItemIcon>
                                              <Svg color={dataProject.id === localStorage.getItem('selectedProjectId') ? "white" : colorToBeChanged} keyword="folderSvg" />
                                            </ListItemIcon>
                                            <ListItemText
                                              classes={{ primary: classes.listItemTextApproval }}
                                              primary={dataProject.attributes.name}
                                              style={{
                                                color: (dataProject.id === localStorage.getItem('selectedProjectId')) ? 'white' : secondaryColorToBeChanged
                                              }}

                                            >
                                              {dataProject.name}
                                            </ListItemText>

                                          </ListItem>
                                        </>
                                      ))
                                      :
                                      <ListItem
                                        button
                                      >
                                        <ListItemIcon>
                                          <Svg color={this.state.selectedIndex === item.id ? "white" : colorToBeChanged} keyword="folderSvg" />
                                        </ListItemIcon>
                                        <ListItemText
                                          classes={{ primary: classes.listItemText }}
                                          style={{
                                            color: secondaryColorToBeChanged
                                          }}
                                        // primary={dataProject.name}

                                        >
                                          No Project found
                                        </ListItemText>

                                      </ListItem>

                                  }
                                </div>
                              </InfiniteScroll>
                              {/* <Pagination count={this.state.total_pages} page={this.state.setPage} onChange={this.handlePage}/> */}
                              {/* </NavLink> */}
                            </div>

                          </>
                        )
                      })


                      }
                    </>
                }




                {/* 
              {this.state.sideNavStatus && 
              <>

  </>
  }
            */}
                <div className={classes.logos}>
                  <img src={designHarvestLogo} />
                  <p className={classes.footerText} style={{ fontFamily: primaryFont }}>© {new Date().getFullYear()} DESIGN HARVEST</p>
                  <p
                    className={classes.foottext}
                    style={{ cursor: "pointer", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                    onClick={() => {
                      window.location.replace('/terms-and-conditions');
                    }}
                  >
                    {" "}
                    {t("AccountProfile.termOfService")}
                  </p>
                  <p
                    className={classes.foottext}
                    style={{ cursor: "pointer", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                    onClick={() => {
                      window.location.replace('/privacy-policy');
                    }}
                  > {t("AccountProfile.privacy")}</p>

                  <p className={classes.foottext} onClick={this.logOut} style={{ textDecoration: "none", cursor: "pointer", fontFamily: primaryFont, color: secondaryColorToBeChanged }}> {t("AccountProfile.logout")}</p>


                </div>
                <img src={leftViewBg} className={classes.leftViewBg} />
                {/* <div> */}
                {/* <Grid container spacing={0}>                    <Grid item xs={12} className={classes.imageFooter}>
                      <span>
                        <img src={designHarvestLogo} />
                      </span>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={10} className={classes.footer}>
                      <img src={croppedImageLogo} className={classes.footerBackImage} />
                      <p className={classes.footerText}>© 2020 DESIGN HARVEST</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}
                    style={{ justifyContent: "center" }}
                  >
                    <Grid item xs={1} />
                    <Grid item xs={10} className={classes.foot}>
                      <p className={classes.foottext}>
                        {t("HomeScreen.termOfService")}
                      </p>
                      <p className={classes.foottext}>
                        {t("HomeScreen.privacy")}
                      </p>
                      <Link onClick={this.logOut} style={{ textDecoration: "none", cursor: "pointer" }}>
                        <p className={classes.foottext}>
                          {t("HomeScreen.logout")}
                        </p>
                      </Link>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>

                </div> */}

              </div>
            </Grid>
            <Grid item xs={10} className={classes.routingPages}>
              <Grid container spacing={0} className={classes.navArea}>
                <Grid item xs={12}>
                  <AppBar position="static" className={classes.appBar}>
                    <Toolbar variant="dense">
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                      >
                        <div className={classes.plusButtonIcon} />
                      </IconButton>

                      <Typography variant="h6" color="inherit" />
                    </Toolbar>

                    <Grid container spacing={0} className={classes.nav_main}>
                      <Grid item xs={2} />
                      <Grid item xs={4}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "20px",
                          }}
                        >
                          {window.location.pathname == "/Home/HomePage" ? <></> :
                            <>
                              <span style={{ marginTop: "8px", marginLeft: "20px" }}>
                                <HomeOutlinedIcon
                                  style={{ color: colorToBeChanged }}
                                  onClick={() => window.location.href = "/Home/HomePage"}
                                />
                              </span>
                              <p style={{ color: "black", fontSize: "14px", cursor: 'pointer' }} onClick={() => window.location.href = "/Home/HomePage"}>
                                {" "}
                                {t("HomeScreen.home")}
                              </p>
                            </>
                          }
                        </div>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={2}>
                        <Input
                          id="input-with-icon-adornment"
                          className={classes.inputSearchBox}
                          endAdornment={
                            <InputAdornment position="end" >
                              <SearchIcon />
                            </InputAdornment>
                          }
                          startAdornment={

                            <Select
                              id="demo-simple-select"
                              value={this.state.selectSeacrh}
                              onChange={(e) => {
                                this.setState({ selectSeacrh: e.target.value })
                                localStorage.setItem("searchSelectValue", e.target.value as string)
                              }}

                            >
                              {this.state.permissionName !== "client" &&
                                <MenuItem value={"brand"}>Brands</MenuItem>
                              }
                              <MenuItem value={"project"}>Projects</MenuItem>
                              <MenuItem value={"task"}>Task</MenuItem>

                            </Select>

                          }
                          placeholder="      search"
                          value={this.state.searchValue}
                          onChange={this.handleSearchChange}

                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container spacing={0}>
                          <Grid
                            item
                            xs={2}
                            style={{ display: "flex", alignItems: "center" }}
                          >

                            {/* <img src={plusButton} /> */}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}
                          >
                            <img src={plusButton} onClick={(e) => this.setState({ anchorE5: e.currentTarget })} />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}
                          //onClick={this.handleAlertNotificationOpen}
                          >
                            <HtmlTooltip
                              arrow
                              placement="left"
                              open={localStorage.getItem("onBoardingStep") == "2" ? true : false}
                              title={
                                <React.Fragment>
                                  <div>
                                    <img src={notificationIcon} className={classes.popupIcon} />
                                    <span className={classes.popupTitle}>{t("onBoarding.notification")}</span>
                                    <span className={classes.popupCancelIcon}>
                                      <CloseIcon onClick={this.handleSkipOnboarding} />
                                    </span>
                                  </div>
                                  <div className={classes.popupText}>
                                    {t("onBoarding.notificationText")}
                                  </div>
                                  <div>
                                    <Button
                                      className={classes.popupSkipButton}
                                      onClick={this.handleSkipOnboarding}
                                      color={colorToBeChanged}
                                    >
                                      SKIP
                                    </Button>
                                    {/* <Button
                                    className={classes.popupButton}
                                    onClick={this.handlePrevOnboarding}
                                    >
                                    PREV
                                    </Button> */}
                                    <Button
                                      className={classes.popupButton}
                                      onClick={this.handleNextOnboarding}
                                      style={{ background: colorToBeChanged }}
                                    >
                                      NEXT
                                    </Button>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <img onClick={this.handleAlertNotificationOpen} className={localStorage.getItem("onBoardingStep") == "2" && classes.highlightText} src={this.state.alertNotificationsData?.length > 0 ? notificationIcon : notificationWithoutDotIcon} />
                            </HtmlTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          // onClick={(e) => { this.handleClick(e); }}
                          >
                            {localStorage.getItem("onBoardingStep") == "3" ?
                              <HtmlTooltip
                                arrow
                                placement="left"
                                open={localStorage.getItem("onBoardingStep") == "3" ? true : false}
                                title={
                                  <React.Fragment>
                                    <div>
                                      <img src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "13px", width: "13px", borderRadius: "50%" }} />
                                      <span className={classes.popupTitle}>{t("onBoarding.myAccount")}</span>
                                      <span className={classes.popupCancelIcon}>
                                        <CloseIcon onClick={this.handleSkipOnboarding} />
                                      </span>
                                    </div>
                                    <div className={classes.popupText}>
                                      {t("onBoarding.accountTextOne")}<br />
                                      {t("onBoarding.accountTextTwo")}
                                    </div>
                                    <div>
                                      <Button
                                        className={classes.popupSkipButton}
                                        onClick={this.handleSkipOnboarding}
                                        color={colorToBeChanged}
                                      >
                                        SKIP
                                      </Button>
                                      <Button
                                        className={classes.popupButton}
                                        onClick={this.handlePrevOnboarding}
                                        style={{ background: colorToBeChanged }}
                                      >
                                        PREV
                                      </Button>
                                      <Button
                                        className={classes.popupButton}
                                        onClick={this.handleNextOnboarding}
                                        style={{ background: colorToBeChanged }}
                                      >
                                        NEXT
                                      </Button>
                                    </div>
                                  </React.Fragment>
                                }
                              >
                                <img className={localStorage.getItem("onBoardingStep") == "3" && classes.highlightText} src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "63px", width: "63px", borderRadius: "50%" }} />
                              </HtmlTooltip>
                              : localStorage.getItem("onBoardingStep") == "14" ?
                                <HtmlTooltip
                                  arrow
                                  placement="left"
                                  open={localStorage.getItem("onBoardingStep") == "14" ? true : false}
                                  title={
                                    <React.Fragment>
                                      <div>
                                        <img src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "13px", width: "13px", borderRadius: "50%" }} />
                                        <span className={classes.popupTitle}>Retake Tour</span>
                                        <span className={classes.popupCancelIcon}>
                                          <CloseIcon onClick={this.handleSkipOnboarding} />
                                        </span>
                                      </div>
                                      <div className={classes.popupText}>
                                        If you need to revisit this tour, please go to "My Profile / Retake Tour".
                                      </div>
                                      <div>
                                        {/* <Button
                                        className={classes.popupSkipButton}
                                        onClick={this.handleSkipOnboarding}
                                      >
                                        SKIP
                                      </Button> */}
                                        <Button
                                          className={classes.popupButton}
                                          onClick={this.handleRetakeOnboarding}
                                          style={{ background: colorToBeChanged }}
                                        >
                                          Retake Tour
                                        </Button>
                                        <Button
                                          className={classes.popupButton}
                                          onClick={this.handleNextOnboarding}
                                          style={{ background: colorToBeChanged }}
                                        >
                                          Finish
                                        </Button>
                                      </div>
                                    </React.Fragment>
                                  }
                                >
                                  <img className={localStorage.getItem("onBoardingStep") == "3" && classes.highlightText} src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "39px", width: "42px", borderRadius: "23%", objectFit: "scale-down" }} />
                                </HtmlTooltip>
                                :
                                <img className={localStorage.getItem("onBoardingStep") == "3" && classes.highlightText} src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "39px", width: "42px", borderRadius: "23%", objectFit: "scale-down" }} />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            <Grid container spacing={0} style={{ cursor: "pointer" }} onClick={(e) => {
                              this.handleClick(e);
                            }}>
                              <Grid item xs={12}>
                                <p
                                  style={{
                                    color: "black",
                                    fontSize: "11px",
                                    margin: "0",
                                    fontFamily: primaryFont
                                  }}
                                >
                                  {userInfo.first_name + " " + userInfo.last_name}
                                </p>
                              </Grid>
                              <Grid style={{ cursor: "pointer" }} item xs={12}>
                                <p
                                  style={{
                                    color: "black",
                                    fontSize: "10px",
                                    margin: "0",
                                    fontFamily: primaryFont
                                  }}

                                >
                                  {/* {t("HomeScreen.accountManeger")} */}
                                  {userInfo.designation != null ? userInfo.designation : null}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={downArrow}
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e) => {
                                this.handleClick(e);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <Menu
                              id="simple-menu"
                              style={{
                                marginTop: "50px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

                              }}
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => {
                                this.handleClose();
                              }}

                            >
                              <Grid container spacing={2} style={{
                                width: "228px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",
                                border: "solid 1px #e4e8f0",
                                backgroundColor: "#ffffff"
                              }}>
                                <Grid style={{ textAlign: "center", paddingTop: "32px", paddingBottom: "32px" }} item xs={12}><img style={{ borderRadius: "50%", height: "52px", width: "53px", objectFit: "scale-down" }} src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage}></img>
                                  <Typography variant="body2" style={{
                                    paddingTop: "10px",
                                    // fontFamily: "Work Sans",
                                    fontFamily: primaryFont,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "0.1px",
                                    textAlign: "center",
                                    color: "#282828"
                                  }}>{userInfo.first_name + " " + userInfo.last_name}</Typography>
                                  <Typography variant="body2" style={{
                                    paddingTop: "2px",
                                    //fontFamily: "Work Sans",
                                    fontFamily: primaryFont,
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "0.09px",
                                    textAlign: "center",
                                    color: "#282828"
                                  }}>
                                    {/* {t("HomeScreen.accountManeger")} */}
                                    {userInfo.designation != null ? userInfo.designation : null}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ borderTop: "solid 0.5px #282828", opacity: "0.25" }}>
                                </Grid>
                                <Grid item style={{ paddingBottom: "20px" }} xs={12}>
                                  <Grid container spacing={1}>

                                    <Grid item style={{}} xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.goToAccountProfile} >
                                          <span style={{
                                            border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                          }}  >
                                            <img src={myAccountIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px",


                                            }} /> </span>{t("HomeScreen.myAccount")}</MenuItem></Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.goToHelp}><span style={{
                                          border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                        }}  >
                                            <img src={helpIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px"

                                            }} /> </span> {t("HomeScreen.help")}</MenuItem></Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item style={{}} xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.handleNotificationOpen}><span style={{
                                          border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                        }}  >
                                            <img src={notificationIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px"

                                            }} /> </span> {t("HomeScreen.notificationPreferences")}</MenuItem></Grid>

                                      </Grid>
                                    </Grid>
                                    <Grid item style={{}} xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.handleRetakeOnboarding}><span style={{
                                          border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                        }}  >
                                            <img src={retakeIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px"

                                            }} /> </span> {t("HomeScreen.retakeTour")}</MenuItem></Grid>

                                      </Grid>
                                    </Grid>
                                    <Grid item style={{}} xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.openInviteDialog}>
                                          <span style={{
                                            border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                          }}  >
                                            <img src={logoutIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px"

                                            }} /> </span>Invite</MenuItem></Grid></Grid></Grid>
                                    <Grid item style={{}} xs={12}>
                                      {
                                        userInfo?.role?.data?.attributes?.name === 'superadmin' ||
                                          (userInfo?.role?.data?.attributes?.name === 'client' && userInfo?.role?.data?.attributes?.permission === 'brand_manager')
                                          ?
                                          <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                            <Grid item xs={12} ><MenuItem style={{
                                              // fontFamily: "Work Sans", 
                                              fontFamily: primaryFont,
                                              fontSize: "12px",
                                              fontWeight: 500,
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "0.09px",
                                              color: secondaryColorToBeChanged,
                                            }} onClick={this.goToAdminConsole} ><span style={{
                                              border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                            }}  >
                                                <img src={companyAccountIcon} style={{
                                                  height: "12px",
                                                  width: "12px",
                                                  padding: "4px"

                                                }} /> </span>Admin Console</MenuItem></Grid></Grid>
                                          :
                                          null
                                      }
                                      {userInfo?.role?.data?.attributes?.permission === "brand_manager" && userInfo?.role?.data?.attributes?.name !== 'superadmin' ?

                                        <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                          <Grid item xs={12} ><MenuItem style={{
                                            // fontFamily: "Work Sans",

                                            fontFamily: primaryFont,
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.09px",
                                            color: secondaryColorToBeChanged,
                                          }} onClick={this.goToCompanyProfile} ><span style={{
                                            border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                          }}  >
                                              <img src={companyAccountIcon} style={{
                                                height: "12px",
                                                width: "12px",
                                                padding: "4px"

                                              }} /> </span>{t("HomeScreen.companyProfile")}</MenuItem></Grid></Grid>

                                        :
                                        null
                                      }




                                    </Grid>

                                    <Grid item style={{}} xs={12}>
                                      <Grid container direction="row" alignItems="center" wrap="nowrap" >
                                        <Grid item xs={12} ><MenuItem style={{
                                          // fontFamily: "Work Sans",
                                          fontFamily: primaryFont,
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.09px",
                                          color: secondaryColorToBeChanged,
                                        }} onClick={this.logOut}>
                                          <span style={{
                                            border: "1px solid #e4e8f0", borderRadius: "10px", textAlign: "center", height: "20px", width: "20px", marginRight: "8px"
                                          }}  >
                                            <img src={logoutIcon} style={{
                                              height: "12px",
                                              width: "12px",
                                              padding: "4px"

                                            }} /> </span>{t("HomeScreen.logout")}</MenuItem></Grid></Grid></Grid>
                                  </Grid>

                                </Grid>
                              </Grid>


                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AppBar>
                </Grid>
              </Grid>
              <Switch>
                <Route exact path="/Home/Projects" component={ProjectsWeb} />
                <Route exact path="/Home/SearchResults" component={SearchResults} />
                <Route
                  exact
                  path="/Home/ProjectPortfolio"
                  component={ProjectPortfolioWeb}
                />
                <Route
                  exact
                  path="/Home/ProjectsTypes"
                  component={ProjectsTypes}
                />
                <Route exact path="/Home/CGI" component={ProjectPortfolioWeb} />
                <Route
                  exact
                  path="/Home/Photoproduction"
                  component={ProjectPortfolioWeb}
                />
                <Route exact path="/Home/Photoproduction"
                  component={ProjectPortfolioWeb}
                />
                <Route exact path="/Home/HomePage" component={HomePage} />
                <Route
                  exact
                  path="/Home/MediaAssets"
                  component={Categoriessubcategories}
                />
                <Route
                  exact
                  path="/Home/MediaAssetsbrandGroup"
                  component={BrandGroup} />
                <Route
                  exact
                  path="/Home/MediaAssets/:brandId"
                  component={BrandAssets}
                />
                <Route
                  exact
                  path="/Home/MediaAssets/:brandId/:mediaAssetId/:AssetId/:FolderAssetsId"
                  component={FolderMediaAssets}
                />
                <Route
                  exact
                  path="/Home/MediaAssets/:brandId/:mediaAssetId/:AssetId"
                  component={MediaAssets}
                />
                <Route exact path="/Home/Teams" component={TeamBuilder} />
                <Route exact path={`/Home/${routingData[0].name.replace(/\s/g, "")}`} component={MultilevelApprovalRequestWeb} />
                <Route exact path="/Home/ApprovalRequest" component={ApprovalRequestWeb} />
                <Route exact path="/Home/Brands/BrandGroup" component={BrandGroupWeb} />
                <Route exact path="/Home/Brands" component={Brands} />

              </Switch>
            </Grid>
          </Grid>
        </BrowserRouter>

        {/* Notification Dialog Start */}
        <Dialog
          open={this.state.setNotification}
          onClose={this.handleCloseNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ borderRadius: "12px" }}
        >
          <DialogTitle style={{
            // fontFamily: "Work Sans !important",
            fontFamily: primaryFont,
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828"
          }} id="alert-dialog-title" >{t("NotificationSettings.notificationDialogTitle")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseNotification} />
            </span>
          </DialogTitle>
          <DialogContent style={{ maxWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container style={{ marginLeft: "9px" }}>
                <Grid item xs={2} style={{ marginBottom: "20px", paddingLeft: "5px" }}>
                  <img src={notificationPreferenceSettings} />
                </Grid>
                <Grid item xs={10} >
                  <Grid container spacing={0} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.12px",
                        color: "#282828",
                        fontFamily: primaryFont,
                      }} >{t("NotificationSettings.notifications")}</Typography></Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px", marginTop: "7px" }}><Typography variant="body2" style={{
                      // fontFamily: "Work Sans",
                      fontFamily: primaryFont,
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }} >{t("NotificationSettings.notificationsTitle")}</Typography></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Checkbox
                        color="primary"
                        style={{ paddingTop: "14px" }}
                        name="task_email_notifications"
                        //disabled={this.state.mute_notifications}
                        inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.task_email_notifications} onChange={(e) => { this.setNotications(e) }} />
                      <Typography
                        variant={"body2"}
                        style={{
                          display: "inline",
                          // fontFamily: "Work Sans",
                          fontFamily: primaryFont,
                          fontSize: "14px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          color: "#282828"
                        }}
                      >{t("NotificationSettings.sendEmailNotification")}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "42px" }}>
                      <FormControl style={{ minWidth: 200 }} variant="outlined" >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="task_notification_email_frequency"
                          onChange={(e) => { this.setNotications(e) }}
                          defaultValue={this.state.task_notification_email_frequency}
                          //disabled={this.state.mute_notifications == true || this.state.task_email_notifications !== true ? true : false}
                          disabled={this.state.task_email_notifications !== true ? true : false}
                          style={{ height: 40 }}
                        >
                          <MenuItem style={{
                            // fontFamily: "Work Sans",
                            fontFamily: primaryFont,
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="immediately">
                            immediately
                          </MenuItem>
                          <MenuItem style={{
                            // fontFamily: "Work Sans",
                            fontFamily: primaryFont,
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="once per day">
                            once per day
                          </MenuItem>

                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                  <Checkbox
                    color="primary"
                    style={{ paddingTop: "14px" }}
                    inputProps={{ 'aria-label': 'primary checkbox' }} value={this.state.tagged_email_notifications} checked={this.state.tagged_email_notifications}
                    name="tagged_email_notifications"
                    //disabled={this.state.mute_notifications}
                    // value={age}
                    //onChange={handleChange}
                    onChange={(e) => { this.setNotications(e) }}
                  />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      // fontFamily: "Work Sans",
                      fontFamily: primaryFont,
                      fontSize: "14px ",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >{t("NotificationSettings.sendEmailCommentsNotification")}</Typography>
                </Grid>
                {/* <Grid item xs={12}>
                  <Checkbox
                    color="primary"
                    style={{ paddingTop: "14px" }}
                    name="mute_notifications"
                    onChange={(e) => { this.setNotications(e) }}
                    disabled={this.state.task_email_notifications == true || this.state.tagged_email_notifications == true ? true : false}
                    inputProps={{ 'aria-label': 'primary checkbox' }} value={this.state.mute_notifications} checked={this.state.mute_notifications} />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >{t("NotificationSettings.muteNotification")}</Typography>
                </Grid> */}
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* <HubSpotChat portalId = "6141202" /> */}

        {/* alert Notification Dialog Start */}
        <Dialog
          open={this.state.setAlertNotification}
          onClose={this.handleCloseAlertNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{
            // fontFamily: "Work Sans !important",
            fontFamily: primaryFont,
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828"
          }} id="alert-dialog-title" >{"Notifications"}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseAlertNotification} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Typography
              // className="addScrollBarHome" 
              style={{ width: "100%" }}>
              <Card className={classes.notificationCardRoot}>
                <CardContent>
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    classes={{ indicator: classes.indicator }}
                    // indicatorColor="primary"
                    aria-label="scrollable force tabs example"
                    textColor="primary"
                    centered
                  >
                    <Tab
                      label={t("HomeScreen.HomePage.all")}
                      className={classes.tab}
                      style={{ fontFamily: SecondaryFont, color: secondaryColorToBeChanged }}
                    />
                    <Tab
                      label={t("HomeScreen.HomePage.teamBased")}
                      className={classes.tab}
                      style={{ fontFamily: SecondaryFont, color: secondaryColorToBeChanged }}
                    />
                    <Tab
                      label={t("HomeScreen.HomePage.toDos")}
                      className={classes.tab}
                      style={{ fontFamily: SecondaryFont, color: secondaryColorToBeChanged }}
                    />
                    <Tab
                      label={t("HomeScreen.HomePage.approvals")}
                      className={classes.tab}
                      style={{ fontFamily: SecondaryFont, color: secondaryColorToBeChanged }}
                    />
                  </Tabs>
                  <Card className={classes.allNotificationCard}>
                    <CardContent style={{ backgroundColor: "#e4e8f0" }}>
                      {this.state.value === 0 && (
                        <>
                          {this.state.alertNotificationsData ? this.state.alertNotificationsData.map((item: any, index: any) => {
                            return (
                              <>
                                <div key={index} style={{
                                  marginBottom: "20px",
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "8px"
                                }}>
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                    <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />

                                    {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px", fontFamily: primaryFont }}>
                                      {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                      <br />
                                      {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                    </span>}
                                    <p style={{ margin: 0 }}>
                                      {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                            />
                                          </IconButton> */}
                                    </p>
                                  </div>
                                  <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px", marginBottom: "10px", textAlign: "center", maxWidth: "100px", fontFamily: primaryFont }} >
                                    {item?.attributes?.target_user_entity}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                    <p style={{ fontSize: "14px", margin: "0", fontFamily: primaryFont }}>
                                      {item.attributes.acted_user_message}
                                    </p>
                                  </div>
                                  <div className={classes.notificationCalenderRow}>
                                    <div className={classes.notificationCicle}  >
                                      <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />
                                    </div>
                                    <p style={{ fontSize: "10px", fontFamily: primaryFont }}>{item.attributes.updated_at}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })
                            :
                            <>
                              <p>No Data Found</p>
                            </>
                          }
                        </>
                      )}
                      {this.state.value === 1 &&
                        <>
                          {this.state.teamBased != null ? this.state.teamBased.map((item: any, index: any) => {
                            return (
                              <>
                                <div key={index} style={{
                                  marginBottom: "20px",
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "8px"
                                }}>
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                    <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                    {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                      {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                      <br />
                                      {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                    </span>}

                                    <p style={{ margin: 0 }}>
                                      {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                            />
                                          </IconButton> */}
                                    </p>
                                  </div>
                                  <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px", marginBottom: "10px", textAlign: "center", maxWidth: "100px" }} >
                                    {item?.attributes?.target_user_entity}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                    <p style={{ fontSize: "14px", margin: "0" }}>
                                      {item.attributes.acted_user_message}
                                    </p>
                                  </div>
                                  <div className={classes.notificationCalenderRow}>
                                    <div className={classes.notificationCicle}  >
                                      <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />
                                    </div>
                                    <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })
                            :
                            <>
                              <p>No Team Notifications</p>
                            </>
                          }
                        </>
                      }
                      {this.state.value === 2 &&
                        <>
                          {this.state.toDo != null ? this.state.toDo.map((item: any, index: any) => {
                            return (
                              <>
                                <div key={index} style={{
                                  marginBottom: "20px",
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "8px"
                                }}>
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                    <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                    {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                      {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                      <br />
                                      {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                    </span>}

                                    <p style={{ margin: 0 }}>
                                      {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                            />
                                          </IconButton> */}
                                    </p>
                                  </div>
                                  <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px", marginBottom: "10px", textAlign: "center", maxWidth: "100px" }} >
                                    {item?.attributes?.target_user_entity}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                    <p style={{ fontSize: "14px", margin: "0" }}>
                                      {item.attributes.acted_user_message}
                                    </p>
                                  </div>
                                  <div className={classes.notificationCalenderRow}>
                                    <div className={classes.notificationCicle}  >
                                      <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />
                                    </div>
                                    <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })
                            :
                            <>
                              <p>No Team Notifications</p>
                            </>
                          }
                        </>
                      }
                      {this.state.value === 3 &&
                        <>
                          {this.state.approvalNotification != null ? this.state.approvalNotification.map((item: any, index: any) => {
                            return (
                              <>
                                <div key={index} style={{
                                  marginBottom: "20px",
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "8px"
                                }}>
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                    <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                    {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                      {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                      <br />
                                      {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                    </span>}

                                    <p style={{ margin: 0 }}>
                                      {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                            />
                                          </IconButton> */}
                                    </p>
                                  </div>
                                  <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px", marginBottom: "10px", textAlign: "center", maxWidth: "100px" }} >
                                    {item?.attributes?.target_user_entity}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                    <p style={{ fontSize: "14px", margin: "0" }}>
                                      {item.attributes.acted_user_message}
                                    </p>
                                  </div>
                                  <div className={classes.notificationCalenderRow}>
                                    <div className={classes.notificationCicle}  >
                                      <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />
                                    </div>
                                    <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })
                            :
                            <>
                              <p>No Team Notifications</p>
                            </>
                          }
                        </>
                      }
                    </CardContent>
                    {/* <CardActions /> */}
                  </Card>
                </CardContent>
                <CardActions />
              </Card>
            </Typography>

            {/* <DialogContentText id="alert-dialog-description">

              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Accordion className={classes.accordion} >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >

                    </AccordionSummary>
                    <AccordionDetails>
                      {this.state.alertNotificationsData.length > 0 ?

                        <Typography>

                          <Card className={classes.cardRoot}>
                            <CardContent style={{ padding: "11px" }}>
                              {
                                this.state.alertNotificationsData.map((item: any) => {
                                  return (

                                    <>

                                      <Card className={classes.firstCard}>

                                        <CardContent>
                                          <div>

                                            <Typography className={classes.title} style={{ marginBottom: "10px" }} color="textSecondary" gutterBottom>

                                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <span> <p style={{ fontSize: "10px", color: "black" }}>   {item.attributes.frequency}</p></span>


                                              </div>
                                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                <p style={{ color: "black" }}>{item.attributes.target_user_message}</p>

                                              </div>
                                            </Typography>
                                          </div>
                                          <div >

                                          </div>
                                        </CardContent>


                                        <CardActions style={{ backgroundColor: "#e4e8f0" }}>
                                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "-3%" }}>


                                            <p style={{ fontSize: "12px", marginLeft: "5px" }} >{item.attributes?.updated_at} </p>
                                            <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                              <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                            </div>


                                          </div>
                                        </CardActions>





                                        <CardActions />


                                      </Card>


                                    </>
                                  )
                                })
                              }

                            </CardContent>
                            <CardActions />
                          </Card>

                        </Typography>


                        : null

                      }


                    </AccordionDetails>
                  </Accordion>




                </Grid>
              </Grid>




            </DialogContentText> */}
          </DialogContent>

        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          style={{ zIndex: 9999 }}
          open={this.state.onBoardingShow && localStorage.getItem("onBoardingStep") == "1"}
        >
          <DialogTitle id="customized-dialog-title" className={classes.step1}>
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleSkipWholeOnboarding} />
            </span>
          </DialogTitle>
          <DialogContent>
            <div>
              <b style={{ fontSize: "20px" }}>{t("onBoarding.designHarvest")}</b><br />
              <span style={{ fontSize: "19px" }}>{t("onBoarding.introductionToTheWorkspace")}</span>
              <br /><br /><br />
              <Button
                variant="contained"
                className={classes.activeButtonList}
                onClick={e => {
                  localStorage.setItem("onBoardingStep", "5")
                  window.location.href = "/Home/Brands";
                }}
              >
                <span style={{ textTransform: "none" }}>
                  <b>{t("onBoarding.projects")}</b>
                </span>
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: "15px" }}
                className={classes.activeButtonList}
                onClick={() => {
                  this.setState({
                    onBoardingShow: false
                  });
                  localStorage.setItem("onBoardingStep", "2");
                }}
              >
                <span style={{ textTransform: "none" }}>
                  <img src={notificationIcon} />
                  <b style={{ paddingLeft: "10px" }}>
                    {t("onBoarding.notification")}</b>
                </span>
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: "15px" }}
                className={classes.activeButtonList}
                onClick={() => {
                  this.setState({
                    onBoardingShow: false
                  });
                  localStorage.setItem("onBoardingStep", "4");
                }}
              >
                <span style={{ textTransform: "none" }}>
                  <b>
                    {t("onBoarding.quickAccess")}
                  </b>
                </span>
              </Button>
              <br />
              <br />
              <Button
                variant="contained"
                className={classes.activeButtonList}
                onClick={() => {
                  this.setState({
                    onBoardingShow: false
                  });
                  localStorage.setItem("onBoardingStep", "3");
                }}
              >
                <span style={{ textTransform: "none" }}>
                  <b>{t("onBoarding.myAccount")}</b>
                </span>
              </Button>
              <br /><br />
              <Button
                className={classes.activeButton}
                onClick={this.handleNextOnboarding}
                style={{
                  background: colorToBeChanged
                }}
              >
                {t("onBoarding.createTour")}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {/* <ForumIcon onClick={closeHandler} /> */}


        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorE5}
          keepMounted
          open={Boolean(this.state.anchorE5)}
          onClose={() => {
            this.setState({ anchorE5: null })
          }}
        >
          {this.state.permissions !== "viewer" &&
            <MenuItem
              // onClick={(e) => {
              //   this.handleClickInviteTeamMember(
              //     e
              //   );
              // }}
              onClick={() => {

                this.setState({ setOpen: true, anchorE5: null }, () => {
                  this.getBrandTeams();
                  this.getBrandListing();
                })
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={duplicateButton} /> </span> {t("onBoarding.createProject")}
            </MenuItem>
          }

          <MenuItem
            onClick={(e) => {
              this.handleClickCreateNewTaskModel(e)
              this.setState({ anchorE5: null })
            }}

            style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            className={classes.dropDown}
          >
            <span className={classes.taskImgs} >
              <img className={classes.taskImg} src={duplicateButton} /> </span> {t("ApprovalRequest.createNewTask")}
          </MenuItem>
        </Menu>


        {/* create project dialog */}


        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
        >
          <DialogTitle id="customized-dialog-title">
            {/* {t("projects.newProjectTitle")} */}
            {t("projects.newProject")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",
              projectDescription: "",
              title: "",
              //shareTheProjectWithOthers:this.state.sharedProject,
              // includeAPersonalMessageWithYourInvitation: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              // projectDescription: Yup.string().required(
              //   "projectDescription is required"
              // ),
              // title: Yup.string().required(
              //   "title is required"
              // ),

              // includeAPersonalMessageWithYourInvitation: Yup.string().required(
              //   "Include A Personal MessageWith Your Invitation is required"
              // ),
            })}
            enableReinitialize
            onSubmit={(values) => {
              console.log("sharing project ", values, this.state.sharedProject, this.state.brandSelected);

              //console.log("pro", this.state, "pro");
              this.callCreateProjectApi(values, this.state.sharedProject, this.state.brandSelected);
              // this.setState(
              //   {
              //     sharedProjectError: this.state.sharedProject ? false : true,
              //   },
              //   () => {
              //     console.log(this.state.sharedProjectError, "error");
              //   }
              // );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    {userInfo?.role?.data?.attributes?.name == "superadmin" || userInfo?.role?.data?.attributes?.name == "albertbauer" ?
                      <>
                        <Grid item xs={12}>
                          <FormControl variant="outlined" style={{
                            marginLeft: 0,
                            minWidth: "100%",
                          }}
                            fullWidth
                          >

                            <InputLabel id="demo-simple-select-outlined-label"
                            // style={{ color: 'gray', fontSize: 16, background: "#fff" }}

                            > {t("teams.selectBrand")}</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.state.brandSelected}
                              onChange={(e) => {
                                // this.setCategory(item)
                                this.setState({ brandSelected: e.target.value }, () => this.getBrandTeams(this.state.brandSelected))
                              }}
                              label={t("teams.selectBrand")}
                            >

                              {this.state.brandsData.length > 0 && this.state.brandsData.map((item: any, index: any) => (

                                <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                  // this.setCategory(item)
                                  this.handleChangeBrand(item.id)
                                }} >{item.attributes.name}</MenuItem>
                              ))}
                            </Select>

                          </FormControl>
                        </Grid>
                      </> : null}


                    <TextField
                      name="title"
                      type="text"
                      size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      fullWidth
                      // label={t("projects.projectDescription")}
                      label={t("projects.newProjectTitle")}
                      value={values.title}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.title &&
                          errors.title ? (
                          // <Trans>CompanyProfile.companyNameRequired</Trans>
                          <Trans>Project title is required</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.title && errors.title
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />


                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.brandTeamsData}
                      style={{ minWidth: "550px" }}

                      getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}
                      renderOption={(option: any) => (
                        option?.photo_blob_signed_id_url?.length >= 0 ?
                          <React.Fragment>
                            <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                            }</span>
                            {option.first_name} {option.last_name}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {option?.name}
                          </React.Fragment>
                      )}
                      // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                      onChange={(e, value) => {
                        //console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          // sharedProjectError: value.length > 0 ? false : true

                        }, () => {
                          //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          option?.photo_blob_signed_id_url?.length >= 0 ?
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                            :
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}

                          // helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                          // error={
                          //   this.state.sharedProjectError
                          // }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />

                    <TextField
                      name="projectDescription"
                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      size="small"
                      className={classes.confirmInput}
                      fullWidth
                      label={t("teams.descriptions")}
                      placeholder={t("teams.descriptionsPlaceholder")}
                      // label={t(
                      //   "projects.ProjectDescription"
                      // )}
                      value={values.projectDescription}
                      variant="outlined"

                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start", marginBottom: "40px" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                        marginTop: "11px"
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.createProject")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black", marginLeft: "36px" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>


        {/* create new task */}
        <div >
          <Dialog maxWidth={false} open={this.state.openTaskCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 890 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>{t("ApprovalRequest.createNewTask")}</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }}
                  onClick={() => this.setState({
                    openTaskCreateModel: false,
                    brandSelected: "",
                    projectSelectedTask: "",
                    category: "",
                    assetType: "",
                    startBriefingOpen: false
                  })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  // title: "Task 00" + this.createRamdomCode(),

                  title: "",
                  category: "",
                  assetType: "",

                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  // title: Yup.string().required(
                  //   "Task title is required"
                  // ),
                })}
                onSubmit={(values) => {

                  if (!this.state.projectSelectedTask || !this.state.category || !this.state.assetType) {
                    this.setState({ startBriefingOpen: true })
                    return
                  }
                  console.log(values);
                  this.handleClickStartBriefing()
                  this.setState({ startBriefingOpen: true })

                  values.title = values.title || values.category + values.assetType
                  // alert(values.title)
                  this.callCreateTaskApi(values, this.state.category, this.state.assetType, this.state.projectSelectedTask)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <Form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>
                          {userInfo?.role?.data?.attributes?.name == "superadmin" || userInfo?.role?.data?.attributes?.name == "albertbauer" ?
                            <>
                              <Grid item xs={6}>
                                <FormControl variant="outlined" style={{
                                  marginLeft: 0,
                                  minWidth: "100%",
                                  maxWidth: "100%",
                                }}>
                                  <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'black', fontSize: 16, background: "#fff" }}>{t("teams.selectBrand")}</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.brandSelected}
                                    onChange={(e) => {
                                      //  this.setCategory(item)
                                      // this.callGetAssetCategoryListApi();
                                      // this.state.category
                                      this.setState({ brandSelected: e.target.value, category: "" },
                                        () => {


                                          this.callGetAssetCategoryListApi()
                                        }
                                      )
                                    }}
                                    label={t("teams.selectBrand")}
                                  >

                                    {this.state.brandsData.length > 0 && this.state.brandsData.map((item: any, index: any) => (

                                      <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                        // this.setCategory(item)


                                        localStorage.setItem('userInfoId', item.id)
                                        this.handleChangeBrand(item.id)
                                        if (item?.attributes?.brand_group_name == 'Hugo Boss') {
                                          localStorage.setItem("brandGroupSelectedName", item?.attributes?.brand_group_name);
                                        }
                                        else {
                                          localStorage.removeItem("brandGroupSelectedName")
                                        }
                                      }} >{item.attributes.name}</MenuItem>
                                    ))}
                                  </Select>
                                  {!this.state.brandSelected && this.state.startBriefingOpen &&
                                    <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select Brand</Typography>
                                  }
                                </FormControl>
                              </Grid>


                              <Grid item xs={6}>
                                <FormControl variant="outlined" style={{
                                  marginLeft: 0,
                                  minWidth: "100%",
                                }}>
                                  <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16, background: "#fff" }}>{t("projects.project")}</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.projectSelectedTask}
                                    onChange={(e) => this.setState({ projectSelectedTask: e.target.value, startBriefingOpen: false })}
                                    label={t("projects.project")}
                                  >

                                    {this.state.projectsOfStatusDraftData.length > 0 && this.state.projectsOfStatusDraftData.map((item: any, index: any) => (

                                      <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                        // this.setCategory(item)
                                        console.log("projectis..........");

                                      }} >{item.attributes.name}</MenuItem>
                                    ))}
                                  </Select>
                                  {!this.state.projectSelectedTask && this.state.startBriefingOpen &&
                                    <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select Project</Typography>
                                  }
                                </FormControl>
                              </Grid>


                            </> :

                            <Grid item xs={12}>
                              <FormControl variant="outlined" style={{
                                marginLeft: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                              }}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16, background: "#fff" }}>{t("projects.project")}</InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={this.state.projectSelectedTask}
                                  onChange={(e) => this.setState({ projectSelectedTask: e.target.value, category: "", startBriefingOpen: false },
                                    () => {
                                      this.callGetAssetCategoryListApi()
                                    }
                                  )}
                                  label={t("projects.project")}
                                >

                                  {this.state.projectsOfStatusDraftData.length > 0 && this.state.projectsOfStatusDraftData.map((item: any, index: any) => (

                                    <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                      // this.setCategory(item)
                                      // console.clear()
                                      // console.log("project....idss......",item.id);
                                      localStorage.setItem('projectInfoId', item.id)
                                    }} >{item.attributes.name}</MenuItem>
                                  ))}
                                </Select>
                                {!this.state.projectSelectedTask && this.state.startBriefingOpen &&
                                  <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select Project</Typography>
                                }
                              </FormControl>
                            </Grid>

                          }



                          <Grid item xs={12} style={{ display: "flex" }}>
                            <TextField id="outlined-basic" style={{
                              marginBottom: 10,
                              marginTop: 3,
                              color: 'primary',
                              background: "#fff"
                            }}
                              fullWidth
                              value={values.title}
                              // defaultValue={"Task 00 " +this.createRamdomCode()}
                              label={t("projects.taskTitle")}
                              className={classes.confirmInput}
                              name="title"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              // placeholder="`Enter Task Title" 
                              variant="outlined"
                              // helperText={
                              //   touched.title &&
                              //     errors.title ? (<>
                              //       Title is required
                              //     </>
                              //   ) : (
                              //     ""
                              //   )
                              // }
                              // error={
                              //   touched.title && errors.title
                              //     ? true
                              //     : false
                              // }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                            {/* <BorderColorOutlinedIcon style={{
                                padding: "15px",
                                cursor: "pointer"
                              }}
                              onClick={()=> {  
                                console.log("value", values);
                                                             
                                setFieldValue("title", "Task " + this.createRamdomCode() )  
                                console.log("value2", values);                            
                              }}
                              /> */}
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined" style={{
                              marginLeft: 0,
                              minWidth: "100%",
                              maxWidth: '100%'
                            }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16, background: "#fff" }}>{t("projects.category")}</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.category}
                                onChange={this.handleChangeCategory}
                                label={t("projects.category")}
                              >

                                {this.state.assetCategoriesData.length > 0 && this.state.assetCategoriesData.map((item: any) => (

                                  <MenuItem value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                    this.setCategory(item)
                                  }} >{item.attributes.name}</MenuItem>
                                ))}
                              </Select>
                              {!this.state.category && this.state.startBriefingOpen &&
                                <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select Category</Typography>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>

                            <FormControl variant="outlined" className={classes.formControl} style={{
                              marginRight: 0,
                              minWidth: "100%",
                            }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}>{t("ApprovalRequest.assetType")}</InputLabel>
                              <Select labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.assetType}
                                onChange={this.handleChangeAssetType}
                                label={t("ApprovalRequest.assetType")}
                              >

                                {this.state.assetTypesData && this.state.assetTypesData.map((item: any, index: any) => (
                                  <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }} onClick={(e) => {
                                    this.setAssetType(item)
                                  }} >{item?.attributes?.name}</MenuItem>

                                ))}
                              </Select>

                              {!this.state.assetType && this.state.startBriefingOpen &&
                                <Typography style={{ color: "red", paddingLeft: "10px" }}>Please select Asset Type</Typography>
                              }
                            </FormControl>
                          </Grid>
                          <span style={{ height: '16px', margin: ' 37px 102px 22px 0', fontSize: '16px', marginLeft: '10px', color: '#666666' }}>{t("projects.selectTemplete")}</span>

                          <Grid item xs={12}>
                            {this.state.templatesData.length > 0 ? (
                              <>
                                <GridList style={{ flexDirection: 'column', }}>
                                  <div style={{
                                    flexGrow: 1,
                                    padding: 20,
                                    // backgroundColor: "#e2e2e2",
                                  }}>
                                    <Grid item xs={12} container style={{ margin: 50, }} spacing={1}>
                                      {this.state.templatesData && this.state.templatesData.map((item: any, index: any) => (
                                        <React.Fragment key={index}>

                                          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', flexBasis: '100%' }}>
                                            <span onClick={(e) => { this.handleClickTemplateSelection(e, item) }}>
                                              <Card style={{ width: '40px', padding: '10px', textAlign: 'center' }}>
                                                {/* <CardActionArea onClick = {(item) => {this.handleClickTemplateSelection(item)}} 
>*/}
                                                <CardMedia
                                                  component="img"
                                                  alt="template"
                                                  style={{ width: 25, height: 40, margin: '0 auto' }}
                                                  height="15"
                                                  width="20"
                                                  image={imgFileTemplate}
                                                  title="Contemplative Reptile"
                                                  className={this.state.selectedTaskTemplate?.id === item?.id ? classes.templateHover : "white"}
                                                />
                                                {/* </CardActionArea> */}
                                              </Card>
                                            </span>
                                            <Typography color="textSecondary" style={{ marginTop: 5, marginLeft: "10px", width: 'auto' }}>
                                              {item.attributes.template.data.attributes.name}
                                            </Typography>
                                          </Grid>
                                        </React.Fragment>
                                      ))}

                                    </Grid>
                                  </div>
                                </GridList>
                              </>
                            ) : undefined}
                          </Grid>

                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button variant="contained" type="submit" style={{ backgroundColor: colorToBeChanged, color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: SecondaryFont, padding: 15, fontWeight: 'initial' }}
                            // onClick={this.handleClickStartBriefing}>

                            >
                              {t("projects.StartBriefing")}
                            </Button>
                            <p style={{ position: "absolute", right: 20, cursor: "pointer" }} onClick={() => { window.location.href = "/HelpCentre" }}>
                              {t("projects.interestedIn")} <span style={{ color: secondaryColorToBeChanged }}>{t("projects.readMore")}</span>
                            </p>
                          </div>
                        </Grid>


                      </DialogActions>

                    </Form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>


        {/* Briefing model part 2*/}
        {this.state.isCurrentTaskSelected && this.state.currentTask ? (

          <Dialog

            open={this.state.setOpen2}
            onClose={this.handleCloseTaskModal}
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.testing12}

          >
            {/* <DialogTitle id="alert-dialog-title" className={classes.dialogueTitle}> */}
            <div className={classes.TaskViewTitle}>
              <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
              <p style={{ fontSize: "14px" }}>Project: {this.state.currentTask?.attributes?.project_name}</p>
              <span>
                <CloseIcon onClick={this.handleCloseTaskModal} />
              </span>
            </div>

            {/* </DialogTitle> */}
            <Divider />
            <Backdrop
              className={classes.backdrop}
              open={this.state.loading}
              onClick={() => this.setState({ loading: false })}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent style={{ overflowY: "initial" }}>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                  <Grid item xs={8} style={{ backgroundColor: "#f5f6fa" }} >
                    <div style={{ backgroundColor: "#f5f6fa" }}>
                      <div style={{ margin: "20px", }}>
                        <p style={{ color: "#173e59", fontSize: "20px" }}>{this.state.currentTask?.attributes?.title}</p>
                        {/* <p style={{ fontSize: "14px", color: "#282828" }}>{this.state.currentTask?.attributes?.title}</p> */}

                        <p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }} >Blockers</p>

                        {/* checkbox */}
                        <div> {this.state.blockersData?.length > 0 && this.state.blockersData.map((values: any, index: any) => (
                          <>
                            <FormControlLabel key={index}
                              control={
                                <Checkbox color="primary" name="is_completed"
                                  // onChange={this.handleChangeBlockerSelection(value,values)}
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.handleChangeBlockerSelection(values, e.target.checked) }}
                                />}
                              // className={values.attributes?.is_completed 
                              //   && 
                              //   classes.checkdecoration}

                              label={values.attributes?.description}
                            // label="Secondary"
                            />

                          </>
                        ))}


                        </div>
                        {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}>
                              <img
                                //  onClick={this.handleClickCreateNewTaskBlockerModel} 
                                src={plusButton} />

                            </span>
                            <span><p style={{ color: "#282828", fontSize: "14px", paddingLeft: "32px" }} >Blocker reason (e.g. task # or @user</p> </span>
                          </div>
                        ) : undefined

                        }

                        <p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Subtasks</p>

                        <div>
                          {this.state.subTasksData?.length > 0 && this.state.subTasksData.map((values: any, index: any) => (
                            <>
                              <FormControlLabel key={index}
                                control={<Checkbox color="primary" name="is_completed"
                                  // onChange={this.handleChangeBlockerSelection(value,values)}
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.callUpdateSubTaskApi(values, e.target.checked) }}
                                />}
                                label={values.attributes?.title}
                              // label="Secondary"
                              />

                            </>
                          ))}

                        </div>

                        {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton}
                            // onClick={this.handleClickCreateNewSubTakModel}
                            /></span>
                            <span>
                              <p style={{ color: "#282828", fontSize: "14px", paddingLeft: "32px", marginBottom: "30px" }} >Add new subtask</p> </span>
                          </div>) : undefined}
                        <p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Attachment</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => {
                            if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                              return (
                                <React.Fragment key={index}>
                                  <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                    {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                    <span onClick={(e) => {
                                      this.setState({
                                        fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name
                                        // , setOpenImageDialog: true
                                      })
                                    }}>
                                      <Card style={{ width: '73px' }}>
                                        <CardMedia
                                          component="img"
                                          alt="template"
                                          style={{ width: 50, height: 60, padding: '5px 10px' }}
                                          height="15"
                                          width="20"
                                          image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                          title={item.attributes.name}
                                          className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                        />
                                      </Card>
                                    </span>
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              )
                            }
                          }
                          )}
                        </div>
                        <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px", marginBottom: "33px" }}
                          onClick={() => {
                            localStorage.setItem("task", "0")
                            this.fileInput1.click()
                          }}
                        >
                          {/* <ReactS3Uploader
                            type="file"
                            id="upload"
                            name="pic"
                            getSignedUrl={this.getSignedUrl}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read'
                            }}
                            s3path="/uploads/"
                            contentDisposition="auto"
                            accept="**"
                            capture={true}
                            inputRef={cmp => this.fileInput1 = cmp}
                            onChange={(event: any) =>
                              this.inputFileChanged(event)
                            }
                            style={{
                              visibility: "hidden",
                              position: "absolute",
                              alignContent: "center", backgroundColor: 'white',
                              color: '#419bf9',
                              width: "280px", padding: 20,
                              fontSize: 16, fontWeight: 'bold',
                              borderWidth: 1,
                              borderColor: '#419bf9',
                              borderRadius: 10,
                              borderStyle: 'dashed',
                              fontVariant: "initial",
                              textTransform: 'none',
                              marginBottom: "20px"
                            }}
                          /> */}
                          <p style={{ color: " #1a76b0", marginLeft: "42%" }}>+</p>

                        </div>






                      </div>

                      {/* <div className={classes.banners}>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Headline</p>
              <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque.</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Subline</p>
              <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iacul elit et libero.</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Target URL</p>
              <p style={{ marginTop: "0px" }}>www.exampledomain.com</p>
            </div>
          </div> */}
                      {this.state.currentTaskBriefingData?.length > 0 && this.state.currentTaskBriefingData.slice(0).map((briefedValues: any, index: any) => (
                        <div style={{ marginTop: "40px", marginLeft: "20px" }}>

                          {briefedValues.attributes.value_type == "slider" ?
                            (
                              <>
                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                {/* <CustomSlider
                        style={{
                          // width: "100%",
                          marginLeft: "-10px",
                          marginRight: "18px",

                          // marginTop: "18px",
                        }}
                        disabled
                        defaultValue={briefedValues.attributes.value ? briefedValues.attributes.value : 0}
                        // getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-always"
                        //step={10}
                        marks={[
                          {
                            value: briefedValues.attributes.detailed_values?.min ? parseInt(briefedValues.attributes.detailed_values?.min) : 0,
                            label: briefedValues.attributes.detailed_values?.min ? briefedValues.attributes.detailed_values?.min : '0',
                          },
                          {
                            value: briefedValues.attributes.detailed_values?.max ? parseInt(briefedValues.attributes.detailed_values?.max) : 100,
                            label: briefedValues.attributes.detailed_values?.max ? briefedValues.attributes.detailed_values?.max : '100',
                          },

                        ]}
                        min={briefedValues.attributes.detailed_values?.min ? parseInt(briefedValues.attributes.detailed_values?.min) : 0}
                        max={briefedValues.attributes.detailed_values?.max ? parseInt(briefedValues.attributes.detailed_values?.max) : 100}
                        valueLabelDisplay="on"
                      //ValueLabelComponent={StyledValueLabel}
                      /> */}
                              </>)
                            :
                            briefedValues.attributes.value_type == "date_time" ?
                              (
                                <>
                                  <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                  <p style={{ fontSize: '14px', marginTop: "0px" }}>{(briefedValues.attributes.value).replace("T", ", ")}</p>
                                </>
                              )
                              :
                              briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image" ?
                                (
                                  <>
                                    {briefedValues.attributes.file_blob_signed_id_url &&
                                      <Fragment>
                                        <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                        <span onClick={(e) => {
                                          this.setState({
                                            fileId: briefedValues?.id, file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, fileImage: "Uploaded Image"
                                            // , setOpenImageDialog: true
                                          })
                                        }}>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px' }}
                                              height="15"
                                              width="20"
                                              image={briefedValues.attributes.file_blob_signed_id_url ? briefedValues.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              // title={item.attributes.name}
                                              className={classes.templateHover}
                                            />
                                          </Card>
                                        </span>
                                      </Fragment>
                                    }
                                  </>
                                )
                                :
                                (briefedValues.attributes.value_type == "short_text" ||
                                  briefedValues.attributes.value_type == "long_text" ||
                                  briefedValues.attributes.value_type == "email" ||
                                  briefedValues.attributes.value_type == "multiple_selection" ||
                                  briefedValues.attributes.value_type == "single_selection" ||
                                  briefedValues.attributes.value_type == "drop_down_select" ||
                                  briefedValues.attributes.value_type == "slider"
                                ) ?
                                  (
                                    <>
                                      {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                        <Fragment>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                          <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                        </Fragment>
                                      }
                                    </>
                                  )
                                  :
                                  briefedValues.attributes.value_type == "page_separator" ?
                                    "" :
                                    null
                          }
                        </div>
                      ))}

                      {/* <div style={{ marginTop: "40px", marginLeft: "20px" }}>
            <p style={{ fontSize: "16px", marginBottom: "0px" }}>Do you have any other instructions?</p>
            <p style={{ fontSize: '14px', marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue elit ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue eli et libero.)
</p>
          </div> */}
                      <Divider style={{ backgroundColor: "#737373" }} />

                      <Accordion className={classes.accordianStyle} style={{ background: "#f5f6fa" }} defaultExpanded>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => (this.setState({ onGoingArrowFlag: !this.state.onGoingArrowFlag }))}
                        >
                          <Typography className={classes.heading} >Activity
                            {/* <img src={downIcon} /> */}
                          </Typography>{this.state.onGoingArrowFlag === true ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "9px 0px 16px", background: "#f5f6fa" }}>
                          <Grid item xs={12}>

                            {/* <Paper component="form" className={classes.root} style={{height:'200px',border:'1px solid black',margin:15}} > */}


                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                              wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                              editorStyle={{ padding: 10, minHeight: 100, background: "#ffffff", }}
                              toolbarStyle={{ height: 50, fontSize: 18, marginBottom: "0px", background: "#f4f6fc" }}
                              onEditorStateChange={this.onEditorStateChange}
                              mention={{
                                separator: ' ',
                                trigger: '@',
                                suggestions: this.state.selectedProjectsMentionsAccountsData2
                                //  [
                                //   { text: 'APPLE', value: 'apple', url: 'apple' },
                                //   { text: 'BANANA', value: 'banana', url: 'banana' },
                                //   { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                //   { text: 'DURIAN', value: 'durian', url: 'durian' },
                                //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                //   { text: 'FIG', value: 'fig', url: 'fig' },
                                //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                // ],
                              }}
                              hashtag={{
                                separator: ' ',
                                trigger: '#',
                              }}

                              toolbarCustomButtons={[

                                <button
                                  style={{
                                    transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "293px",
                                  }}
                                  type="submit"

                                  onClick={(e) => {
                                    this.state.convertedCommentData?.blocks?.length > 0 ?
                                      this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                  }}
                                >
                                  <SendIcon />
                                  {/* {t("projects.saveChanges")} */}
                                </button>

                              ]}
                              toolbar={{
                                options: ["inline", "link", "image"],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  // options: ['unordered', 'ordered'],
                                  unordered: { className: undefined },
                                  ordered: { className: undefined },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                                link: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  dropdownClassName: undefined,
                                  showOpenOptionOnHover: true,
                                  defaultTargetOption: '_self',
                                  options: ['link'],
                                  // link: { icon: link, className: undefined },
                                  // unlink: { icon: unlink, className: undefined },
                                  linkCallback: undefined
                                },
                                // emoji: {
                                //   className: undefined,
                                //   component: undefined,
                                //   popupClassName: undefined,
                                //   emojis: [
                                //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                                //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '✅', '❎', '',
                                //   ],
                                // },

                                image: {
                                  //icon:,
                                  urlEnabled: false,
                                  uploadEnabled: true,
                                  uploadCallback: this.getSignedUrlTaskFileComment,
                                  previewImage: true,
                                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                },

                              }}
                            />


                            {this.state.getTaskCommentsData.map((item: any, index: any) => {
                              return (<>
                                <div key={index}>
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                    <Avatar src={item.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                    <div style={{ marginLeft: "15px" }}>
                                      <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>{item.attributes?.account?.data?.attributes?.first_name}</p>
                                      <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.account?.data?.attributes?.created_at}</p>
                                    </div>
                                  </div>

                                  {item.attributes?.file_blob_signed_id_url !== null && (
                                    <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                      <span onClick={(e) => {
                                        this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name }
                                          // , () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }
                                        )
                                      }}>
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 25, height: 40, padding: '15px 24px' }}
                                            height="15"
                                            width="20"
                                            image={imgFileTemplate}
                                            title={item.attributes.name}
                                            className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                          />
                                        </Card>
                                      </span>
                                      <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                        {item.attributes.name}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                    <p style={{ margin: "0px" }} dangerouslySetInnerHTML={{ __html: item.attributes?.description }} />
                                    <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }}
                                    //  onClick={() => { this.openSpecificTaskComment(item.id) }}
                                    >Edit</p>
                                  </div>
                                </div>
                              </>
                              )
                            })}

                          </Grid>
                        </AccordionDetails>
                      </Accordion>


                    </div>

                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ marginLeft: "20px", marginTop: "20px" }}>

                      {(this.state.currentTask.attributes?.quotation?.data?.attributes?.price && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" &&
                        this.state.currentTask.attributes?.quotation?.data?.attributes?.status === "Pending Quote Approval" &&
                        ((this.state.permissions === "approver" && this.state.permissionName === "client" || (this.state.permissions == "editor"))) ? (
                        <>
                          <p style={{ fontSize: "16px" }}></p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={(e) => {
                              this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >

                            {t("projects.selfApprove")}
                          </Button>
                        </>
                      ) : (this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions === "approver" || this.state.permissionName === "client") ? (
                        <>
                          <p style={{ fontSize: "16px" }}>Auto Generated</p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={(e) => {
                              var currentTask = this.state.currentTask;
                              currentTask.attributes.quotation.data.attributes.status = "Quote Approved"
                              this.setState({
                                currentTask: currentTask
                              })
                              this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >
                            {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" ? (
                              <span>{t("projects.selfApprove")}</span>
                            ) : (
                              <span>Self Approve</span>
                            )}
                          </Button>
                        </>
                      ) : undefined))}

                      {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" &&
                        this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Pending Quote Approval" &&
                        (this.state.permissionName === "client" || this.state.permissions === "viewer" || this.state.permissions === "editor") ? (
                        <div>
                          {(this.state.currentTask.attributes?.quote_requested === false) ? (
                            <>
                              {this.state.currentTask.attributes.price_auto_generated !== null && (
                                <>
                                  <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                  <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.price_auto_generated}</p>
                                </>
                              )}
                              {this.state.currentTask?.attributes?.briefing?.data?.id &&
                                <Button
                                  style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.attributes?.briefing?.data?.id) }}
                                >
                                  {t("projects.requestQuote")}
                                </Button>
                              }
                              {/* <Divider style={{ marginBottom: "20px" }} /> */}
                            </>
                          ) : (
                            <>
                              {this.state.currentTask.attributes.price_auto_generated !== null && (
                                <>
                                  <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                  <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                                </>
                              )}
                              {/* <Button
                                disabled
                                style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.id) }}
                              >
                                Quote Requested
                              </Button> */}
                              {/* <Divider style={{ marginBottom: "20px" }} /> */}
                            </>
                          )}

                        </div>
                      ) : (
                        <>
                        </>
                        // <div>
                        //   {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        //     <>
                        //       <p>Approved quote - €{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>

                        //       <Divider style={{ marginBottom: "20px" }} />
                        //     </>
                        //   ) : undefined}
                        // </div>


                      )}
                      {this.state.currentTask.attributes?.status === "New" && this.state.currentTask.attributes?.briefing?.data &&
                        <Button
                          style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                          type="submit"
                          fullWidth
                          variant="contained"
                        // onClick={() => {
                        //   this.handleClickEditBriefing(this.state.currentTask.attributes.briefing);
                        // }}
                        >
                          Edit Briefing
                        </Button>
                      }
                      <Divider style={{ marginBottom: "20px" }} />

                      <TextField
                        id="taskType"
                        disabled
                        name="taskType"
                        select
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        // label={t("TaskView.taskType")}
                        label="Asset Type"
                        value={this.state.currentTask.attributes.asset_type_name}
                        className={classes.selectInput}
                        // onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      >
                        {/* {currencies.map((option) => (
  <option key={option.value} value={option.value}>
    {option.label}
  </option>
))} */}
                        {/* <option aria-label="None" value="" /> */}
                        <option>{this.state.currentTask.attributes.asset_type_name}</option>

                      </TextField>
                      {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <TextField
                          name="status"
                          disabled
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)


                                this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state}
                          className={classes.selectInput}
                          variant="outlined"


                        > <MenuItem aria-label="None" value="started" />
                          <MenuItem key="New" value="New"> New</MenuItem>
                          <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>
                          <MenuItem key="Quote Requested" value="Quote Requested"> Quote Requested</MenuItem>
                          <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>
                          <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          {this.state.taskStatusesData?.map((option: any) => {
                            if (option.attributes.status !== "Draft" && option.attributes.status !== "Quote Pending" && option.attributes.status !== "Approved") {
                              return (<MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>)
                            }
                          })}


                        </TextField>

                      ) : (
                        <TextField
                          name="status"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)


                                this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state || ''}
                          className={classes.selectInput}
                          variant="outlined"
                          disabled={this.state.currentTask.attributes.ultimate_state == "New" ||
                            this.state.currentTask.attributes.ultimate_state == "Quote Requested" ||
                            this.state.currentTask.attributes.ultimate_state == "Briefing Completed" ||
                            this.state.currentTask.attributes.ultimate_state == "Pending Quote Approval" ? true : false}


                        > <MenuItem disabled aria-label="None" value="started" />
                          <MenuItem disabled key="New" value="New"> New</MenuItem>
                          <MenuItem disabled key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>
                          <MenuItem disabled key="Quote Requested" value="Quote Requested"> Quote Requested</MenuItem>
                          <MenuItem disabled key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>
                          <MenuItem disabled key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          {/* <MenuItem key="Open" value="Open">Open</MenuItem> */}
                          {this.state.taskStatusesData?.map((option: any) => {
                            if (option.attributes.status !== "Draft" && option.attributes.status !== "Quote Pending" && option.attributes.status !== "Quote Approved") {
                              return (<MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>)
                            }
                          })}


                        </TextField>

                      )
                      }
                      {(((this.state.permissions === "approver" && this.state.permissionName === "client") ||
                        (this.state.permissions === "viewer" && this.state.permissionName === "client"))
                        && (this.state.currentTask.attributes.ultimate_state !== "New" &&
                          this.state.currentTask.attributes.ultimate_state !== "Briefing Completed")) ?
                        <TextField
                          id="date"
                          name="Due Date"
                          type="text"
                          disabled
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          //defaultValue={Date.now()}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          size="small"
                          fullWidth
                          // label={t("TaskView.status")}
                          label="Due Date"

                          variant="outlined"
                          className={classes.selectInput}
                          SelectProps={{
                            native: true,
                          }}
                          value={this.state.currentTask.attributes.estimated_end_date}
                          onChange={(e) => {
                            this.handleDateChange(e);
                          }}
                        />
                        :
                        <TextField
                          focused
                          id="date"
                          name="Due Date"
                          type="date"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          //defaultValue={Date.now()}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          size="small"
                          fullWidth
                          // label={t("TaskView.status")}
                          label="Due Date"

                          variant="outlined"
                          className={classes.selectInput}
                          SelectProps={{
                            native: true,
                          }}
                          value={this.state.currentTask.attributes.estimated_end_date}
                          onChange={(e) => {
                            this.handleDateChange(e);
                          }}
                        />
                      }
                      <TextField
                        id="Requestor"
                        name="Requestor"
                        disabled
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        label="Requestor"
                        value={this.state.currentTask?.attributes?.requestor?.data?.attributes?.first_name}
                        className={classes.selectInput}
                        // onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      ></TextField>
                      {/* <Autocomplete
                     id="disabled"
                     disabled
                     className={classes.selectInput}
                    options={this.state.accountsData}
                    getOptionLabel={(option:any) => option.attributes.first_name}
                    onChange={(e,value)=>{
                      console.log(e,value);
                      var currentTask = this.state.currentTask
                      currentTask.attributes.requestor.data = value
                      this.setState({
                        currentTask:currentTask,
                        // sharedProjectError:value.length>0?false:true
                        
                      },()=>{
                        console.log(this.state.currentTask,)
                      })
                    }}
                    style={{ width: 280 }}
                    renderInput={(params) => <TextField {...params} label="Requestor" variant="outlined" />}
                  /> */}
                      <Autocomplete
                        id="fixed-tags-demo"
                        className={classes.selectInput}
                        options={this.state.accountsData}
                        inputValue={this.state.currentTask?.attributes?.reviewer?.data?.attributes?.first_name}
                        getOptionLabel={(option: any) => option.attributes.first_name}
                        onChange={(e, value) => {
                          this.handleReviewerChange(e, value);
                        }}
                        style={{ width: 280 }}
                        renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                      />
                      {/* {this.state.permissions === 'editor' ? */}

                      <Autocomplete
                        inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.first_name}
                        id="fixed-tags-demo"
                        className={classes.selectInput}
                        options={this.state.accountsData}
                        disabled={this.state.permissions == 'viewer' || this.state.permissions == 'approver' ? true : false}
                        getOptionLabel={(option: any) => option.attributes.first_name}
                        onChange={(e, value) => {
                          this.handleAssigneeChange(e, value);
                        }}
                        style={{ width: 280 }}
                        renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                      />
                      {/* : undefined} */}
                      <p style={{ fontSize: "16px" }}>Task Progress</p>
                      <div className={classes.progress}>
                        <span> <p style={{ fontSize: "25px" }}>{this.state.currentTask.attributes.progress} %</p> </span>

                        <span style={{ marginTop: "10px", marginLeft: "10px" }}>   <p style={{ fontSize: "10px" }}>completed</p></span>
                      </div>
                      {(this.state.permissions === "editor") ? (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      ) : (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider disabled valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      )}

                      <Divider />
                      <div style={{ margin: "20px" }}>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Created</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.created_at}</p>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Updated</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.updated_at}</p>
                        </div>
                        <div>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Task ID</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes?.order_number}</p>
                        </div>
                        {this.state.currentTask.attributes.ultimate_state == "Quote Approved" && (
                          <>
                            <Divider />
                            <div>
                              <p style={{ fontSize: "16px", marginBottom: "0px" }} >Approved Quote</p>
                              <p style={{ fontSize: "25px", marginTop: "0px" }} >€{this.state.currentTask.attributes.quotation.data && this.state.currentTask.attributes.quotation.data.attributes.price ? this.state.currentTask.attributes.quotation.data.attributes.price
                                : this.state.currentTask.attributes && this.state.currentTask.attributes.price_auto_generated ? this.state.currentTask.attributes.price_auto_generated : ''}</p>
                            </div>
                            <Divider />
                          </>
                        )}
                        <>
                          <div>
                            <p style={{ fontSize: "16px" }} >Final Files for Sign off</p>
                          </div>
                          <div>
                            <div>
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                  if (item?.attributes?.source_file == false && item?.attributes?.final_file == true) {
                                    return (
                                      <React.Fragment>
                                        <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                          <Card style={{ width: '73px' }}
                                          //  onClick={() => { this.setState({ deleteFileId: item?.id }) }}
                                          >
                                            {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") && (
                                              <>
                                                <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }}
                                                // onClick={this.openMenuFilePopUp}
                                                />
                                                {/* <Menu
                                                    id="long-menu"
                                                    anchorEl={this.state.setAnchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.setAnchorEl)}
                                                    onClose={this.closeMenu}
                                                    PaperProps={{
                                                      style: {
                                                         maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '',
                                                      },
                                                    }}
                                                  >

                                                    <MenuItem>
                                                      <RemoveCircleOutlineIcon
                                                        style={{
                                                          fontSize: "20px",
                                                          marginRight: "10px",
                                                          color: "black",
                                                        }}
                                                      />{" "}
                                                      <span onClick={() => { this.deleteTaskFile(this.state.deleteFileId) }}> Delete</span>
                                                    </MenuItem>
                                                    <MenuItem>
                                                      <PublishOutlinedIcon
                                                        style={{
                                                          fontSize: "20px",
                                                          marginRight: "10px",
                                                          color: "black",
                                                          marginBottom: "-5px"
                                                        }}
                                                      />
                                                      <span onClick={() => {
                                                        localStorage.setItem("task", "1")
                                                        this.fileInput2.click()
                                                      }}>

                                                        <ReactS3Uploader
                                                          type="file"
                                                          id="upload"
                                                          name="pic"
                                                          getSignedUrl={this.getSignedUrl}
                                                          uploadRequestHeaders={{
                                                            'x-amz-acl': 'public-read'
                                                          }}
                                                          s3path="/uploads/"
                                                          contentDisposition="auto"
                                                          accept="**"
                                                          capture={true}
                                                          inputRef={cmp => this.fileInput2 = cmp}
                                                          onChange={(event: any) =>
                                                            this.inputFileChanged(event)
                                                          }
                                                          style={{
                                                            visibility: "hidden",
                                                            position: "absolute",
                                                            alignContent: "center", backgroundColor: 'white',
                                                            color: '#419bf9',
                                                            width: "280px", padding: 20,
                                                            fontSize: 16, fontWeight: 'bold',
                                                            borderWidth: 1,
                                                            borderColor: '#419bf9',
                                                            borderRadius: 10,
                                                            borderStyle: 'dashed',
                                                            fontVariant: "initial",
                                                            textTransform: 'none',
                                                            marginBottom: "20px"
                                                          }}
                                                        />
                                                        <p>Upload New Version</p></span>
                                                    </MenuItem>
                                                  </Menu> */}
                                              </>
                                            )}

                                            <span onClick={(e) => {
                                              this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, loading: true }
                                                // () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }
                                              )
                                            }}>
                                              <CardMedia
                                                component="img"
                                                alt="template"
                                                style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                height="15"
                                                width="20"
                                                image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                title={item.attributes.name}
                                                className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                              />
                                            </span>
                                            {/* </CardActionArea> */}
                                          </Card>
                                          <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                            {item.attributes.name}
                                          </Typography>
                                        </Grid>
                                      </React.Fragment>
                                    )
                                  }
                                }
                                )}
                              </div>
                              {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") && (
                                <>
                                  <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                    onClick={() => {
                                      localStorage.setItem("task", "1")
                                      // this.setState({flag :  1} , ()=> this.fileInput2.click() )
                                      this.fileInput2.click()
                                    }}
                                  >
                                    {/* <ReactS3Uploader
                                      type="file"
                                      id="upload"
                                      name="pic"
                                      // className={uploaderClassName}
                                      getSignedUrl={localStorage.getItem("task") == '3' ? this.getSignedUrlInBriefing : localStorage.getItem("task") == '4' ? this.getSignedUrlImageInBriefing : this.getSignedUrl}
                                      //accept="image/*"
                                      // onProgress={onProgress}
                                      // onError={onError}
                                      // onFinish={onFinish}
                                      uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read'
                                      }}
                                      s3path="/uploads/"
                                      contentDisposition="auto"
                                      accept="**"
                                      // multiple
                                      capture={true}
                                      //  ref={this.fileInput}
                                      inputRef={cmp => this.fileInput2 = cmp}
                                      onChange={(event: any) =>
                                        this.inputFileChanged(event)
                                      }
                                      style={{
                                        visibility: "hidden",
                                        position: "absolute",
                                        // width: "280px",
                                        alignContent: "center", backgroundColor: 'white',
                                        color: '#419bf9',
                                        width: "280px", padding: 20,
                                        fontSize: 16, fontWeight: 'bold',
                                        borderWidth: 1,
                                        borderColor: '#419bf9',
                                        borderRadius: 10,
                                        borderStyle: 'dashed',
                                        fontVariant: "initial",
                                        textTransform: 'none',
                                        marginBottom: "20px"
                                      }}
                                    /> */}
                                    <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          {((this.state.permissionName == "client" && this.state.currentTask.attributes.ultimate_state == "Done")
                            || this.state.permissionName == "albertbauer") && (
                              <>
                                <div>
                                  <p style={{ fontSize: "16px" }}>Source Files</p>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                    if (item?.attributes?.source_file == true && item?.attributes?.final_file == false) {
                                      return (
                                        <React.Fragment>
                                          <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                            <Card style={{ width: '73px' }}
                                            // onClick={() => { this.setState({ deleteFileId: item?.id }) }}
                                            >
                                              {(this.state.permissionName == "client" && this.state.currentTask.attributes.ultimate_state == "Done") ? (
                                                <>
                                                  {this.state.currentTask.attributes.ultimate_state == "Done" && (
                                                    <>
                                                      <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }}
                                                      // onClick={this.openSourceFileMenuFilePopUp}
                                                      />
                                                      {/* <Menu
                                                          id="long-menu"
                                                          anchorEl={this.state.setSourceFileAnchorEl}
                                                          keepMounted
                                                          open={Boolean(this.state.setSourceFileAnchorEl)}
                                                          onClose={this.closeSourceFileMenu}
                                                          PaperProps={{
                                                            style: {
                                                               maxHeight: ITEM_HEIGHT * 4.5,
                                                              width: '',
                                                            },
                                                          }}
                                                        >

                                                          <MenuItem>
                                                            <GetAppIcon
                                                              style={{
                                                                fontSize: "20px",
                                                                marginRight: "10px",
                                                                color: "black",
                                                              }}
                                                            />{" "}
                                                            <span onClick={() => { this.taskFileDownloadSourceFile(item.id) }}>Download</span>
                                                          </MenuItem>
                                                        </Menu> */}
                                                    </>
                                                  )}
                                                </>
                                              )
                                                :
                                                (
                                                  <>
                                                    <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }}
                                                    // onClick={this.openSourceFileMenuFilePopUp}
                                                    />
                                                    {/* <Menu
                                                        id="long-menu"
                                                        anchorEl={this.state.setSourceFileAnchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.setSourceFileAnchorEl)}
                                                        onClose={this.closeSourceFileMenu}
                                                        PaperProps={{
                                                          style: {
                                                             maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '',
                                                          },
                                                        }}
                                                      >

                                                        <MenuItem>
                                                          <GetAppIcon
                                                            style={{
                                                              fontSize: "20px",
                                                              marginRight: "10px",
                                                              color: "black",
                                                            }}
                                                          />{" "}
                                                          <span onClick={() => { this.taskFileDownloadSourceFile(item.id) }}>Download</span>
                                                        </MenuItem>
                                                        <MenuItem>
                                                          <RemoveCircleOutlineIcon
                                                            style={{
                                                              fontSize: "20px",
                                                              marginRight: "10px",
                                                              color: "black",
                                                            }}
                                                          />{" "}
                                                          <span onClick={() => { this.deleteTaskFile(this.state.deleteFileId) }}>Delete</span>
                                                        </MenuItem>
                                                        <MenuItem>
                                                          <PublishOutlinedIcon
                                                            style={{
                                                              fontSize: "20px",
                                                              marginRight: "10px",
                                                              color: "black",
                                                              marginBottom: "-5px"
                                                            }}
                                                          />
                                                          <span onClick={() => {
                                                            localStorage.setItem("task", "2")
                                                            this.fileInput4.click()
                                                          }}>

                                                            <ReactS3Uploader
                                                              type="file"
                                                              id="upload"
                                                              name="pic"
                                                              getSignedUrl={this.removeImageAndGetSignedUrl}
                                                              uploadRequestHeaders={{
                                                                'x-amz-acl': 'public-read'
                                                              }}
                                                              s3path="/uploads/"
                                                              contentDisposition="auto"
                                                              accept="**"
                                                              capture={true}
                                                              inputRef={cmp => this.fileInput4 = cmp}
                                                              onChange={(event: any) =>
                                                                this.inputFileRmoveAndUpload(event)
                                                              }
                                                              style={{
                                                                visibility: "hidden",
                                                                position: "absolute",
                                                                alignContent: "center", backgroundColor: 'white',
                                                                color: '#419bf9',
                                                                width: "280px", padding: 20,
                                                                fontSize: 16, fontWeight: 'bold',
                                                                borderWidth: 1,
                                                                borderColor: '#419bf9',
                                                                borderRadius: 10,
                                                                borderStyle: 'dashed',
                                                                fontVariant: "initial",
                                                                textTransform: 'none',
                                                                marginBottom: "20px"
                                                              }}
                                                            />
                                                            <span>Replace</span></span>
                                                        </MenuItem>
                                                      </Menu> */}
                                                  </>
                                                )
                                              }

                                              <a href={item.attributes.file_blob_signed_id_url} target="_blank">
                                                <span>
                                                  <CardMedia
                                                    component="img"
                                                    alt="template"
                                                    style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                    height="15"
                                                    width="20"
                                                    image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                    title={item.attributes.name}
                                                    className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                                  />
                                                </span>
                                              </a>
                                              {/* </CardActionArea> */}
                                            </Card>
                                            <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                              {item.attributes.name}
                                            </Typography>
                                          </Grid>
                                          {/* <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                        <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px' }}
                                              height="15"
                                              width="20"
                                              image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              title={item.attributes.name}
                                              className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                            />
                                          </Card>
                                        </span>
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid> */}
                                        </React.Fragment>
                                      )
                                    }
                                  }
                                  )}

                                </div>

                                {this.state.permissionName === "albertbauer" && (this.state.permissions === "editor" || this.state.permissions === "viewer") && (
                                  <>
                                    <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                      onClick={() => {
                                        localStorage.setItem("task", "2")

                                        this.fileInput3.click()
                                      }}
                                    >
                                      {/* <ReactS3Uploader
                                        type="file"
                                        id="upload"
                                        name="pic"
                                        // className={uploaderClassName}
                                        getSignedUrl={this.getSignedUrl}
                                        //accept="image/*"
                                        // onProgress={onProgress}
                                        // onError={onError}
                                        // onFinish={onFinish}
                                        uploadRequestHeaders={{
                                          'x-amz-acl': 'public-read'
                                        }}
                                        s3path="/uploads/"
                                        contentDisposition="auto"
                                        accept="**"
                                        // multiple
                                        capture={true}
                                        //  ref={this.fileInput}
                                        inputRef={cmp => this.fileInput3 = cmp}
                                        onChange={(event: any) =>
                                          this.inputFileChanged(event)
                                        }
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          // width: "280px",
                                          alignContent: "center", backgroundColor: 'white',
                                          color: '#419bf9',
                                          width: "280px", padding: 20,
                                          fontSize: 16, fontWeight: 'bold',
                                          borderWidth: 1,
                                          borderColor: '#419bf9',
                                          borderRadius: 10,
                                          borderStyle: 'dashed',
                                          fontVariant: "initial",
                                          textTransform: 'none',
                                          marginBottom: "20px"
                                        }}
                                      /> */}
                                      <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                    </div>
                                  </>
                                )}
                              </>
                            )}
                        </>



                      </div>

                    </div>

                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>

            <DialogActions>

            </DialogActions>
          </Dialog>

        ) : undefined}



        {/*Add briefing model*/}
        <div>
          <Dialog maxWidth={false} open={this.state.openAddBriefingModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">

            <div style={{ width: 800, background: "#f5f6fa" }}>
              <DialogTitle style={{
                right: 10,
                borderBottom: '1px solid grey', background: "#ffffff"
              }} id="form-dialog-title">
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: "space-between" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: "16px", marginLeft: "15px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
                    <InsertDriveFileOutlined style={{ marginLeft: 15, fontSize: "16px" }} />
                  </div>
                  <div>
                    <p>{this.state.currentTask?.attributes?.title}</p>
                  </div>
                  <IconButton style={{

                    // position: 'absolute',
                    right: 10,

                  }} onClick={this.handleDeleteTask}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <Backdrop
                className={classes.backdrop}
                open={this.state.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <DialogContent style={{
                backgroundColor: "#f5f6fa",
                overflow: "hidden"
              }}>
                <Grid  >

                  {/* <Grid item xs={12} style={{ margin: 20 }}>
                    <TextField focused id="outlined-basic" className={classes.textfieldLabel} style={{ marginBottom: 10, color: "#666666", background: "#fff" }}
                      fullWidth label="Task Title" value={this.state.currentTask?.attributes?.title} variant="outlined" />
                  </Grid> */}

                  {this.state.templateFormFieldsData
                    .slice(this.state.fromStart, this.state.fromEnd).map((option: any, index: any) => (
                      (option.showField && (option.attributes.element === "text" || option.attributes.element === "short_text" || option.attributes.element === "email" ||
                        option.attributes.element === "full_name" || option.attributes.element === "phone")) ? (
                        <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                          <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                          </Typography>
                          {option.attributes.description && option.attributes.description !== "" &&
                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                              {option.attributes.description}
                            </Typography>
                          }
                          <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                            {index == 0 ?
                              <TextField autoFocus id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChange(option.id, event)} value={option.attributes.default_value} variant="outlined" />
                              :
                              <TextField id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff"
                              }}
                                fullWidth onChange={event => this.handleInputChange(option.id, event)} value={option.attributes.default_value} variant="outlined" />
                            }
                          </Grid>
                        </Grid>
                      ) : (option.showField && option.attributes.element === "long_text") ?
                        (
                          <Grid item xs={12} style={{ margin: 20 }}>
                            <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                            </Typography>
                            {option.attributes.description && option.attributes.description !== "" &&
                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                {option.attributes.description}
                              </Typography>
                            }
                            <Editor
                              editorState={this.state.longTextEditorContent[option.id]}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              onEditorStateChange={(editorState) => this.onEditorStateChange2(editorState, option.id)}
                              stripPastedStyles={true}
                              wrapperStyle={{
                                height: 400,
                                border: "1px solid black",

                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                              editorStyle={{
                                padding: 10,
                                minHeight: 100,
                              }}
                              toolbarStyle={{
                                height: 50,
                                fontSize: 18,
                              }}
                              //onEditorStateChange={this.onEditorStateChange}
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  'list',
                                  'textAlign',
                                ],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic", "underline"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                blockType: {
                                  inDropdown: true,
                                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                },
                                fontSize: {
                                  // icon: Icon.,
                                  options: [7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                  // className: "demo-option-custom-medium",
                                  // component: undefined,
                                  // dropdownClassName: undefined,
                                  inDropdown: true,
                                },
                                fontFamily: {
                                  options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                  // className: undefined,
                                  // component: undefined,
                                  // className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' ,
                                  inDropdown: true,
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["unordered", "ordered"],
                                  unordered: {
                                    className: undefined,
                                  },
                                  ordered: {
                                    className: undefined,
                                  },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                              }}
                            />
                            {/* {option.attributes.description && option.attributes.description !== "" &&
                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                {option.attributes.description}
                              </Typography>
                            }
                            <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                              <TextareaAutosize id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff",
                                width: "99%",
                                borderRadius: "5px",
                              }}
                                autoFocus={index == 0 ? true : false} aria-label="minimum height" rowsMin={minRowsValue} onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} />
                            </Grid> */}

                          </Grid>
                        )
                        : (option.showField && option.attributes.element === "paragraph") ?
                          (
                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                              {/* <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                            </Typography> */}
                              {option.attributes.description && option.attributes.description !== "" &&
                                <div className={classes.paragraphTemp1}>
                                  <Typography gutterBottom
                                    // style={styles.labelTextForBriefingFieldsDescription} 

                                    dangerouslySetInnerHTML={{ __html: option.attributes.description }}>
                                    {/* {option.attributes.description} */}
                                  </Typography>
                                </div>

                              }
                              {/* <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                              <TextareaAutosize id="outlined-basic" style={{
                                marginBottom: 10,
                                color: 'primary',
                                background: "#fff",
                                width: "99%",
                                borderRadius: "5px",
                              }}
                                autoFocus={index == 0 ? true : false} aria-label="minimum height" rowsMin={minRowsValue} onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} />
                            </Grid> */}
                            </Grid>
                          ) :
                          (option.showField && option.attributes.element === "image_upload") ?
                            (
                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                               
                                {option.attributes.file_blob_signed_id_url && option.attributes.file_blob_signed_id_url !== "" &&
                                  <div>
                                    <img src={option.attributes.file_blob_signed_id_url} alt="image upload" style={{ maxWidth: "100%", maxHeight: "240px" }} />
                                  </div>
                                }
                                {
                                  option.attributes.template_form_field_files.data.length > 0 &&
                                  <div>
                                    <img src={option.attributes.template_form_field_files.data[0].attributes.file_blob_signed_id_url} alt="image upload" style={{
                                      maxWidth: "200%",
                                      maxHeight: "400px",
                                      display: "block", marginLeft: "auto", marginRight: "auto"
                                    }}
                                    />
                                  </div>
                                }
                                
                              </Grid>
                            )
                            :

                            (option.showField && option.attributes.element === "single_selection") ? (
                              <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                  {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                </Typography>
                                {option.attributes.description && option.attributes.description !== "" &&
                                  <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                    {option.attributes.description}
                                  </Typography>
                                }
                                <FormControl style={{ marginLeft: 0, }} component="fieldset">
                                  {/* <FormLabel component="legend">{option.attributes.label}</FormLabel> */}
                                  <RadioGroup
                                    style={{ flexDirection: "row" }}
                                    aria-label={option.attributes.label}
                                    name={option.attributes.label}
                                    value={option.attributes.default_value}
                                    onChange={event => this.handleInputChange(option.id, event)}
                                  // onChange={handleChange}
                                  >
                                    {option.attributes.detailed_values.options.map((item: any, index: any) => (

                                      <FormControlLabel
                                        key={index}
                                        value={item.value}
                                        control={<Radio style={{ color: "#1a76b0" }} />}
                                        label={item.label}
                                      />

                                    ))}
                                  </RadioGroup>
                                </FormControl>

                              </Grid>
                            ) :
                              (option.showField && option.attributes.element === "date_time") ? (<Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                <Typography
                                  variant="h3"
                                  gutterBottom
                                  style={styles.labelTextForBriefingFields}
                                >
                                  {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="date"
                                      type="date"
                                      name="date"
                                      defaultValue={option.attributes.default_value && option.attributes.default_value.includes("T")
                                        && (option.attributes.default_value).indexOf("T") > -1
                                        ? (option.attributes.default_value).split("T")[0]
                                        : option.attributes.default_value && option.attributes.default_value
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      style={{
                                        width: "232px",
                                        border: "1px solid #F0F0F0",
                                        padding: "10px",
                                      }}
                                      autoFocus={index == 0 ? true : false}
                                      onChange={event => this.handleInputChange(option.id, event)} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="time"
                                      type="time"
                                      name="time"
                                      disabled={option.attributes.default_value == "" ? true : false}
                                      defaultValue={option.attributes.default_value && option.attributes.default_value.includes("T")
                                        && (option.attributes.default_value).indexOf("T")
                                        && (option.attributes.default_value).split("T")[1]
                                      }
                                      style={{
                                        border: "1px solid #F0F0F0",
                                        width: "232px",
                                        padding: "10px",
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      onChange={event => this.handleInputChange(option.id, event)} />
                                  </Grid>
                                </Grid>
                              </Grid>) :
                                (option.showField && option.attributes.element === "multiple_selection") ? (
                                  <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                    <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                      {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                    </Typography>
                                    {option.attributes.description && option.attributes.description !== "" &&
                                      <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                        {option.attributes.description}
                                      </Typography>
                                    }
                                    <div className={classes.root}>

                                      <FormControl component="fieldset" className={classes.formControl} >
                                        {/* <FormHelperText style={{ fontSize: 16, margin: 5 }}>Choose from standard sizes:</FormHelperText> */}
                                        {/* <FormLabel component="legend" style={{ marginLeft: 3, fontWeight: 'bold', fontSize: 16, color: 'black', marginBottom: 5 }}>{option.attributes.label}</FormLabel> */}
                                        <FormGroup onChange={event => this.handleInputChange(option.id, event)} style={{ flexDirection: "row" }}>

                                          {option.attributes.detailed_values.options.map((item: any, index: any) => (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                paddingLeft: "15px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <FormControlLabel
                                                key={index}
                                                style={{
                                                  border: "1px solid #F0F0F0",
                                                  width: "750px",
                                                  marginLeft: "4px",
                                                }}
                                                control={
                                                  <Checkbox
                                                    checked={(option.attributes.default_value && option.attributes.default_value.length > 0 &&
                                                      (option.attributes.default_value).findIndex((data: any) => data == item.value) > -1) ? true : false}
                                                    name={item.label}
                                                    value={item.value}
                                                  />
                                                }
                                                label={item.label}
                                              />
                                            </Grid>
                                            // <FormControlLabel
                                            //   key={index}
                                            //   value={item.value}
                                            //   control={<Radio style={{ color: "#1a76b0" }} />}
                                            //   label={item.label}
                                            // />
                                          ))}
                                        </FormGroup>

                                      </FormControl>
                                    </div>
                                  </Grid>

                                ) :
                                  (option.showField && option.attributes.element === "drop_down_select") ? (
                                    <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                      <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                        {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                      </Typography>
                                      {option.attributes.description && option.attributes.description !== "" &&
                                        <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                          {option.attributes.description}
                                        </Typography>
                                      }
                                      <FormControl variant="outlined" style={{
                                        marginLeft: 0,
                                        minWidth: "100%",
                                      }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}> {option.attributes.label}</InputLabel> */}
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={option.attributes.default_value}
                                          onChange={event => this.handleInputChange(option.id, event)}
                                        // label="Category"
                                        >
                                          {option.attributes.detailed_values.options.map((values: any, index: any) => (
                                            <MenuItem value={values.value} style={{ color: 'black', fontSize: 18 }}  >{values.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  ) :
                                    (option.showField && (option.attributes.element === "file")) ? (
                                      <>
                                        {!option.attributes.template_form_field_files.data.length && <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>
                                          {option.attributes.description && option.attributes.description !== "" &&
                                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                              {option.attributes.description}
                                            </Typography>
                                          }
                                          {/* <FormControl component="fieldset"> */}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged,
                                              width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "3")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog(this.state.briefingFieldId)
                                                })
                                              }
                                              }
                                            >
                                              Upload
                                            </Button>
                                            {this.state.briefingFiles[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingFiles[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}
                                            {this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaFiles[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                            {/* </FormControl> */}
                                          </div>
                                        </Grid>}
                                        {option.attributes.template_form_field_files.data.length > 0 && <>

                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>


                                          {option.attributes.template_form_field_files && option.attributes.template_form_field_files?.data &&
                                            option.attributes.template_form_field_files?.data?.length > 0 &&
                                            option.attributes.template_form_field_files?.data.map((file: any) => {
                                              return (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                  </Typography>
                                                  {file.attributes.description && file.attributes.description !== "" &&
                                                    <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                      {file.attributes.description}
                                                    </Typography>
                                                  }
                                                  <span>
                                                    <Card style={{ width: '73px' }}>
                                                      <CardMedia
                                                        component="img"
                                                        alt="template"
                                                        style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                        height="15"
                                                        width="20"
                                                        image={file.attributes.file_blob_signed_id_url}
                                                        className={classes.templateHover}
                                                      />
                                                    </Card>
                                                    <Button variant="contained" className={classes.button} style={{
                                                      alignContent: "center", backgroundColor: '#ffffff',
                                                      color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                      borderColor: colorToBeChanged,
                                                      borderRadius: 10,
                                                      borderStyle: 'dashed',
                                                      fontVariant: "initial",
                                                      textTransform: 'none',
                                                      marginBottom: "50px"
                                                    }} color="primary"
                                                      startIcon={<CloudUploadIcon />}
                                                      onClick={(event) => this.clearFileImage(option.id, event, file.id)}>
                                                      Clear
                                                    </Button>
                                                  </span>

                                                </Grid>
                                              )
                                            })}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </>}
                                      </>)  :
                                      (option.showField && (option.attributes.element === "image")) ? 
                                      (<>
                                        {!option.attributes.template_form_field_files.data.length && <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                          {console.log(option, "currrentoption")}
                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>
                                          {option.attributes.description && option.attributes.description !== "" &&
                                            <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                              {option.attributes.description}
                                            </Typography>
                                          }
                                          {/* <FormControl component="fieldset"> */}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </Grid>}


                                        {option.attributes.template_form_field_files.data.length > 0 && <>

                                          <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                            {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                          </Typography>


                                          {option.attributes.template_form_field_files && option.attributes.template_form_field_files?.data &&
                                            option.attributes.template_form_field_files?.data?.length > 0 &&
                                            option.attributes.template_form_field_files?.data.map((file: any) => {
                                              return (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {file.attributes.label} {file.attributes.mandatory ? "*" : ''}
                                                  </Typography>
                                                  {file.attributes.description && file.attributes.description !== "" &&
                                                    <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                      {file.attributes.description}
                                                    </Typography>
                                                  }
                                                  <span>
                                                    <Card style={{ width: '73px' }}>
                                                      <CardMedia
                                                        component="img"
                                                        alt="template"
                                                        style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                        height="15"
                                                        width="20"
                                                        image={file.attributes.file_blob_signed_id_url}
                                                        className={classes.templateHover}
                                                      />
                                                    </Card>
                                                    <Button variant="contained" className={classes.button} style={{
                                                      alignContent: "center", backgroundColor: '#ffffff',
                                                      color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                                      borderColor: colorToBeChanged,
                                                      borderRadius: 10,
                                                      borderStyle: 'dashed',
                                                      fontVariant: "initial",
                                                      textTransform: 'none',
                                                      marginBottom: "50px"
                                                    }} color="primary"
                                                      startIcon={<CloudUploadIcon />}
                                                      onClick={(event) => this.clearFileImage(option.id, event, file.id)}>
                                                      Clear
                                                    </Button>
                                                  </span>

                                                </Grid>
                                              )
                                            })}
                                          <div style={{ marginLeft: 0 }}>
                                            <Button variant="contained" className={classes.button} style={{
                                              alignContent: "center", backgroundColor: '#ffffff',
                                              color: colorToBeChanged, width: 260, padding: 10, fontSize: 14, fontWeight: 'bold', margin: 10,
                                              borderColor: colorToBeChanged,
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "50px"
                                            }} color="primary"
                                              startIcon={<CloudUploadIcon />}
                                              onClick={() => {
                                                localStorage.setItem("task", "4")
                                                this.setState({ fileOptionId: option?.id, briefingFieldId: option?.id }, () => {
                                                  console.log("fileOptionId", this.state.briefingFieldId);
                                                  this.getMediaAssets()
                                                  this.handleUploadOpenDialog()
                                                })
                                              }
                                              }>
                                              Upload
                                            </Button>
                                            {this.state.briefingImages[0]?.[option?.id]?.fileName?.length > 0 ? this.state.briefingImages[0]?.[option?.id]?.fileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file.name}</span>
                                              )
                                            }) : null}

                                            {this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.length > 0 ? this.state.briefingMediaImages[0]?.[option?.id]?.uploadedFileName?.map((file: any, index: any) => {
                                              return (
                                                <span key={index} style={{ marginRight: "10px" }}> {index + 1}) {file}</span>
                                              )
                                            }) : null}
                                          </div>
                                        </>}

                                      </>)
                                       :
                                        (option.showField && option.attributes.element === "slider") ? (

                                          <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                            <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                              {option.attributes.label} {option.attributes.mandatory ? "*" : ''}
                                            </Typography>
                                            {option.attributes.description && option.attributes.description !== "" &&
                                              <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                {option.attributes.description}
                                              </Typography>
                                            }
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Due Date"
                                        format="MMM D YYYY"
                                        autoOk={true}
                                        inputVariant="outlined"
                                        value={option.attributes.default_value}
                                        onChange={event => this.handleInputChange(index, event)}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />
                                    </MuiPickersUtilsProvider> */}
                                            <CustomSlider
                                              style={{
                                                // width: "100%",
                                                // marginLeft: "18px",
                                                // marginTop: "18px",
                                              }}
                                              defaultValue={option.attributes.default_value ? option.attributes.default_value : 0}
                                              // getAriaValueText={valuetext}
                                              aria-labelledby="discrete-slider-always"
                                              //onChange={( event: any, values: any) => {  }}
                                              //step={10}
                                              marks={[
                                                {
                                                  value: option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0,
                                                  label: option.attributes.detailed_values?.min ? option.attributes.detailed_values?.min : '0',
                                                },
                                                {
                                                  value: option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100,
                                                  label: option.attributes.detailed_values?.max ? option.attributes.detailed_values?.max : '100',
                                                },

                                              ]}
                                              min={option.attributes.detailed_values?.min ? parseInt(option.attributes.detailed_values?.min) : 0}
                                              max={option.attributes.detailed_values?.max ? parseInt(option.attributes.detailed_values?.max) : 100}
                                              valueLabelDisplay="on"
                                              onChange={(event: any, value: any) => {
                                                // this.handleSliderChange(index, event, value);
                                                var templateData = this.state.templateFormFieldsData;
                                                var indexValue = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(option.id))
                                                templateData[indexValue].attributes.default_value = value
                                                templateData[indexValue].value = value;
                                                if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
                                                  let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
                                                  // if (conditionData.length > 0) {
                                                  //   templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
                                                  // }
                                                  if (conditionData.length > 0) {
                                                    conditionData.map((condition: any) => {
                                                      let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) !== parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = false;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }

                                                      if (parseInt(value) == parseInt(templateData[indexValue].attributes.detailed_values.min) &&
                                                        !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                                                        templateData[findMatch].showField = true;
                                                        if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                                                          templateData[findMatch].attributes.file_blob_signed_id_url = "";
                                                        }
                                                        templateData[findMatch].attributes.default_value = "";
                                                        templateData[findMatch].value = "";
                                                      }
                                                    })
                                                  }
                                                }
                                                this.setState({
                                                  templateFormFieldsData: templateData
                                                });
                                                // 
                                              }}
                                            //ValueLabelComponent={StyledValueLabel}
                                            />

                                          </Grid>
                                        ) :
                                          (option.showField && option.attributes.element === "address") ? (
                                            <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                              <Typography variant="h6" gutterBottom style={styles.labelTextForBriefingFields}>
                                                {option.attributes.label}
                                              </Typography>
                                              {option.attributes.description && option.attributes.description !== "" &&
                                                <Typography gutterBottom style={styles.labelTextForBriefingFieldsDescription}>
                                                  {option.attributes.description}
                                                </Typography>
                                              }
                                              <Grid item xs={12} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                {index == 0 ?
                                                  <TextField autoFocus id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} variant="outlined" />
                                                  :
                                                  <TextField id="outlined-basic" style={{
                                                    marginBottom: 10,
                                                    color: 'primary',
                                                    background: "#fff"
                                                  }}
                                                    fullWidth onChange={event => this.handleInputChange(option.id, event)} placeholder={option.attributes.description} value={option.attributes.default_value} variant="outlined" />
                                                }
                                              </Grid>
                                            </Grid>
                                          ) :
                                            (option.showField && option.attributes.element === "page_separator") ? (
                                              <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                              >
                                                <Grid item xs={8}>
                                                  <Pagination count={this.state.paginationCount} style={{ marginTop: "20px" }} defaultPage={1} page={this.state.page} onChange={this.handleChangePage} />
                                                </Grid>
                                              </Grid>
                                            ) :
                                              (option.showField && option.attributes.element === "comment_box") ? (
                                                <Grid item xs={12} style={{ margin: 20, fontWeight: 'bold' }}>
                                                  <Typography variant="h3" gutterBottom style={styles.labelTextForBriefingFields}>
                                                    {option.attributes.label}
                                                  </Typography>
                                                  <TextareaAutosize aria-label="minimum height" rowsMax={10} rowsMin={10} style={{ width: "100%", fontSize: "16px", padding: "18px 14px", boxSizing: "border-box", fontFamily: "Arial" }} onChange={event => this.handleInputChange(option.id, event)} />
                                                </Grid>
                                              )
                                                :
                                                undefined
                    ))}
                </Grid>

              </DialogContent>
              <DialogActions>

                <Grid container item style={{ margin: '10px 20px', fontFamily: primaryFont }}>
                  {/* {
                    this.state.openAddRequestQuoteModel ?
                      <Button variant="contained" className={classes.button}
                        onClick={this.handleClickSubmitBriefing}
                        style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                        SUBMIT BRIEFING
                          </Button>
                      : */}

                  {/* <Button variant="contained" className={classes.button}
                    onClick={this.handleClickSubmitBriefing}
                    style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 20, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                    SUBMIT BRIEFING
                  </Button> */}
                  <Button
                    style={{
                      backgroundColor: colorToBeChanged,
                      color: "white",
                      marginLeft: "15px",
                    }}
                    type="submit"
                    variant="contained"
                    onClick={() => this.handleClickSubmitBriefing(false)}
                  >SAVE
                    {/* {t("projects.saveChanges")} */}
                  </Button>
                  {/* {this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" ) ? ( */}

                  {(localStorage.getItem("brandGroupSelectedName") == null || localStorage.getItem("brandGroupSelectedName") == undefined) ? <Button
                    style={{
                      backgroundColor: 'white',
                      color: colorToBeChanged,
                      marginLeft: "15px",
                    }}
                    onClick={this.createBriefingAndGenerateQuote}
                    type="submit"
                    variant="contained"
                  > REQUEST QUOTE
                    {/* {t("projects.saveChanges")} */}
                  </Button> :
                    <Button
                      style={{
                        backgroundColor: 'white',
                        color: colorToBeChanged,
                        marginLeft: "15px",
                      }}
                      onClick={() => {
                        this.setState({ submitProduction: true })
                        this.createBriefingAndGenerateQuote()
                      }}
                      type="button"
                      variant="contained"
                    > Submit to Production Ready
                      {/* {t("projects.saveChanges")} */}
                    </Button>

                  }
                  {/* ) : undefined} */}

                </Grid>

              </DialogActions>
              <div style={{ margin: '5px 30px', marginBottom: 15, color: 'grey', fontSize: 16, }}>*Please fill out the mandatory fields to request a quote.If assistance needed,get in contact with one of our colleagues via help chat.</div>
            </div>

          </Dialog>

        </div>


        {/* upload Dialog */}
        <Dialog
          onClose={this.handleUploadCloseDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.uploadDialog}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            Upload File
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleUploadCloseDialog} />
            </span>
            {/* <IconButton onClick={this.handleUploadCloseDialog} >
                  <CloseIcon />
                </IconButton> */}
            <Box display="flex" alignItems="center">
              {/* <Box flexGrow={1}> */}
              {/* <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#1a76b0",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Upload File
                </Typography> */}
              {/* </Box> */}
              {/* <Box>
                <IconButton onClick={this.handleUploadCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </Box> */}
            </Box>
            <Tabs
              value={this.state.dialogTabValue}
              onChange={this.handleUploadTabChange}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label="Media Asset Libraries" value="MediaAssest" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
              <Tab label="Upload" value="Upload" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
            </Tabs>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent dividers>
            <Grid>
              {this.state.dialogTabValue === "MediaAssest" ? (
                <AccordionDetails>
                  <Box width="100%" display="flex" flexDirection="column">
                    {/* <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                       border="solid 1px #e4e8f0"
                    > */}

                    {this.state.mediaState > 0 ?
                      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px", marginBottom: "10px" }}>

                        {


                          <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 0 })}>
                            <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                              Brands
                            </Link>
                          </Typography>

                        }
                        {
                          this.state.mediaState >= 1 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 1 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.brandSelected}
                              </Link>
                            </Typography>
                            : null
                        }

                        {
                          this.state.mediaState >= 2 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 2 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>

                                {this.state.mediaAssetSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }
                        {
                          this.state.mediaState >= 3 ?
                            <Typography style={{ fontSize: "12px" }}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.folderSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }



                      </Breadcrumbs>
                      :
                      null

                    }

                    <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                    // border="solid 1px #e4e8f0"
                    >
                      {this.state.mediaState == 0 && this.state.assetData.length > 0 && this.state.assetData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>
                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                // this.setState({mediaState : 1}, ()=> this.loadBrandMediaFolderandFiles(item?.id))
                                this.setState({ brandSelected: item?.attributes?.name }, () => this.loadBrandMediaFolderandFiles(item?.id))
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"



                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 1 && this.state.brandMediaAssets.length > 0 && this.state.brandMediaAssets.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {


                                this.setState({ mediaAssetSelected: item?.attributes?.name }, () => {
                                  this.getfilesAndFolder(item?.id)

                                })
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"


                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 2 && this.state.projectFoldersAssetsData.length > 0 && this.state.projectFoldersAssetsData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 100%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              // borderRadius="8px"
                              borderBottom="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                this.setState({ folderSelected: item?.attributes?.name }, () => {
                                  this.getMediaFolderAssetFiles(item?.id)
                                })
                              }
                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"

                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}



                      {this.state.mediaState == 2 && this.state.projectFilesAssetsData.length > 0 && this.state.projectFilesAssetsData.map((item: any, index: any) => {
                        if (localStorage.getItem("task") == 'invoice' && item?.attributes?.file_type == 'pdf') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }

                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {/* <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> */}
                                        <PictureAsPdfRounded />
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                        else if (localStorage.getItem("task") != 'invoice') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}

                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {item?.attributes?.file_type != 'pdf' ? <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> :
                                          <PictureAsPdfRounded />
                                        }
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }

                      })}




                      {this.state.mediaState == 3 && this.state.mediaFolderAssetdFiles.length > 0 && this.state.mediaFolderAssetdFiles.map((item: any, index: any) => {
                        if (localStorage.getItem("task") == 'invoice' && item?.attributes?.file_type == 'pdf') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {/* <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> */}
                                        <PictureAsPdfRounded />
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                        else if (localStorage.getItem("task") != 'invoice') {
                          return (
                            <div style={{
                              flex: '0 0 100%'
                            }}>

                              <Box
                                width="calc(100% - 20px)"
                                // borderRadius="8px"
                                borderBottom="1px solid rgb(195 195 195)"
                                margin="0 20px 20px 0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => {
                                      // localStorage.setItem("grid", "0")
                                      //  this.setState({mediaState : 3}, ()=> {
                                      //    this.loadProjectMediaFolderandFiles(item?.id)
                                      //   this.getMediaAsset(item?.id)
                                      //   this.getMediaAssetFiles(item?.id)
                                      //   })

                                    }

                                    }

                                  >
                                    <Link
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      {" "}
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                          if (item?.attributes?.file_type != 'pdf') {
                                            this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true, flagForPreviewImage: true })
                                          }
                                          else {
                                            // var anchor = document.createElement("a");
                                            // anchor.setAttribute("href", item?.attributes?.file_blob_signed_id_url); 
                                            // anchor.setAttribute("target", "_blank")
                                            // anchor.click();
                                            this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                          }
                                        }}

                                      >
                                        {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                        <Checkbox
                                          checked={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(parseInt(item?.id)) : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId?.includes(parseInt(item?.id)) : this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                          onClick={e => e.stopPropagation()}
                                          onChange={(e) => {
                                            // e.stopPropagation()
                                            this.checkBoxChange(item)
                                          }}
                                          color="primary"
                                        // sx={{
                                        //   color: secondaryColorToBeChanged,
                                        //   '&.Mui-checked': {
                                        //     color: secondaryColorToBeChanged,
                                        //   },
                                        // }}
                                        />
                                        {item?.attributes?.file_type != 'pdf' ? <img src={item?.attributes?.file_blob_signed_id_url}
                                          style={{ width: "40px", height: "40px", marginBottom: "8px" }}
                                        /> :
                                          <PictureAsPdfRounded />
                                        }
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            marginBottom: "5px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </Typography>

                                      </Box>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }
                      })}


                    </Box>
                  </Box>

                </AccordionDetails>
              ) : this.state.dialogTabValue === "Upload" ? (
                <Grid style={{ display: "flex", justifyContent: "center" }}>




                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    style={{
                      alignContent: "center",
                      backgroundColor: "white",
                      color: secondaryColorToBeChanged,
                      width: "280px",
                      padding: 20,
                      fontSize: 16,
                      fontWeight: "bold",
                      borderWidth: 1,
                      borderColor: secondaryColorToBeChanged,
                      borderRadius: 10,
                      borderStyle: "dashed",
                      fontVariant: "initial",
                      textTransform: "none",
                      marginBottom: "20px",
                      height: "70px",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    color="primary"
                    onClick={() => {

                      localStorage.getItem("task") == '3' ?
                        this.fileInput2.click()
                        :
                        localStorage.getItem("task") == '4' ?
                          this.setState({ flagForImage: true }, () => {
                            this.fileInput2.click()
                          })

                          :

                          this.fileInput2.click()
                    }
                    }
                  // type ="file"
                  >

                    + Upload
                  </Button>


                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContent>
          {this.state.dialogTabValue === "MediaAssest" ?
            <DialogActions
              style={{
                justifyContent: "flex-start",
                marginLeft: "25px",
                padding: "12px",
              }}
            >
              <Button variant="contained"
                // color= {secondaryColorToBeChanged} 
                style={{
                  background: localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? secondaryColorToBeChanged : null : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? secondaryColorToBeChanged : null : this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  //  background : this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  // color : this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                  color: localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? "white" : "darkgrey" : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? "white" : "darkgrey" : this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                }}
                onClick={() => {
                  if (localStorage.getItem("task") == '3' || localStorage.getItem("task") == '4') {

                    if (localStorage.getItem("task") == '3') {
                      this.setState({
                        // fileName  : this.state.uploadingFilesName[this.state.uploadingFilesName.length -1] ,
                        flagForMediaUpload1: true, uploadDialog: false
                      })
                      // this.uploadBriefingFiles()
                    }
                    else if (localStorage.getItem("task") == '4') {
                      this.setState({
                        // fileNameImage : this.state.uploadingFilesName[this.state.uploadingFilesName.length -1] ,
                        flagForMediaUpload2: true, uploadDialog: false
                      })

                    }


                  }

                  else {
                    this.uploadFiles()
                  }
                }}
                disabled={localStorage.getItem("task") == '3' ? this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? false : true : localStorage.getItem("task") == '4' ? this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.length > 0 ? false : true : this.state.uploadingFilesId.length > 0 ? false : true}
              >
                Insert
              </Button>
              <Button variant="contained" onClick={this.handleUploadCloseDialog}>Cancel</Button>
            </DialogActions>
            :
            null
          }

        </Dialog>

        {/* Invite Dialog */}

        <Dialog
          onClose={this.handleCloseInviteDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.inviteDialog}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            Invite
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleCloseInviteDialog} />
            </span>
          </DialogTitle>
          <DialogContent dividers >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <TextField variant="outlined" error={this.state.inviteEmailError} label="Enter Email" fullWidth value={this.state.inviteEmail} onChange={this.handleInviteEmailChange} /> */}
                <ReactMultiEmail
                  placeholder="Enter Email Address"
                  emails={this.state.selectedOptions}
                  onChange={(_emails: string[]) => {

                    this.setState({ selectedOptions: _emails })
                  }}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                  ) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button variant="contained" disabled={this.state.inviteEmailError} disableElevation color="primary" onClick={this.inviteUser}>
                  Invite
                </Button>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>
        <ReactS3Uploader
          type="file"
          id="upload"
          name="pic"
          // className={uploaderClassName}
          getSignedUrl={localStorage.getItem("task") == '3' ? this.getSignedUrlInBriefing : localStorage.getItem("task") == '4' ? this.getSignedUrlImageInBriefing : this.getSignedUrl}
          //accept="image/*"
          // onProgress={onProgress}
          // onError={onError}
          // onFinish={onFinish}
          uploadRequestHeaders={{
            'x-amz-acl': 'public-read'
          }}
          multiple
          s3path="/uploads/"
          contentDisposition="auto"
          accept={localStorage.getItem("task") == "invoice" ? "application/pdf" : "**"}
          // multiple
          capture={true}
          //  ref={this.fileInput}
          inputRef={cmp => this.fileInput2 = cmp}
          onChange={(event: any) =>
            this.inputFileChanged(event)
          }
          style={{
            visibility: "hidden",
            position: "absolute",
            // width: "280px",
            alignContent: "center",
            backgroundColor: "white",
            color: secondaryColorToBeChanged,
            width: "280px",
            padding: 20,
            fontSize: 16,
            fontWeight: "bold",
            borderWidth: 1,
            borderColor: secondaryColorToBeChanged,
            borderRadius: 10,
            borderStyle: "dashed",
            fontVariant: "initial",
            textTransform: "none",
            marginBottom: "20px",
          }}
        />

        <div>
          <Dialog
            open={this.state.setOpenImageDialog}
            maxWidth="lg"
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move', backgroundColor: "rgb(0 0 0 / 78%)", padding: "5px 24px 24px" }} id="draggable-dialog-title">
              <Typography style={{ display: "flex", color: "#ffffff", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>
                  {this.state.fileImage}
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    {!this.state.flagForPreviewImage ? this.state.currentTask?.attributes?.brand_name : null} {!this.state.flagForPreviewImage ? "/" : null} {!this.state.flagForPreviewImage ? this.state.currentTask?.attributes?.project_name : null}
                  </Typography>
                </div>
                <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                  <Typography onClick={() => this.setState({ setOpenImageDialog: false, flagForPreviewImage: false })}>
                    <img src={CancelIcon} />
                  </Typography>
                </div>
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.attachmentShowContent}>
              {this.state.fileImage && this.state.fileImage.includes(".mp4") ?
                <video className={classes.setAssetInFrame} controls autoPlay style={{ left: "35%" }}>
                  <source src={this.state.file_blob_signed_id_url} type="video/mp4" />
                  <source src={this.state.file_blob_signed_id_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
                :
                <img src={this.state.file_blob_signed_id_url} className={classes.setAssetInFrame} />
              }
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
// export default withStyles(styles)(withTranslation()(withRouter(HomeScreen)));
export default withStyles(styles)(withTranslation()(HomeScreen));

