import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import '.././../../components/src/styles/styles.css'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Slider from '@material-ui/core/Slider';

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';

// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
// Customizable Area End
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import MultilevelApprovalController, {
  Props,
  configJSON
} from "./MultilevelApprovalController";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Card } from "react-native-elements";
import '../../../components/src/styles/styles.css'
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const logo = require("../../../components/src/volkswagen.svg")
const downIcon = require('../../../components/src/down.png')

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


const data = [
  {
    id: 1,
    name: "BMW"
  },
  {
    id: 2,
    name: "BMW"
  },
  {
    id: 3,
    name: "BMW"
  },
  {
    id: 4,
    name: "BMW"
  },
  {
    id: 5,
    name: "BMW"
  },
  {
    id: 6,
    name: "BMW"
  },
  {
    id: 7,
    name: "BMW"
  },
  {
    id: 8,
    name: "BMW"
  },
  {
    id: 9,
    name: "BMW"
  },

]


const styles = {
  root: {

  },

  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    //     avatar:{
    // display:"inline-flex !important"
    //     },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  accordianStyle: {
    boxShadow: "none",
    border: "none",
    marginTop: "30px"
  },

  actionCard: {
    backgroundColor: "#e4e8f0",
    display: 'flex',
    flexDirection: 'row'
  },
  //   avatar:{
  // marginLeft:"40px"
  //   },
  heading: {
    fontSize: "14px"
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px"
  },


  title: {
    color: "#282828",
    fontSize: "16px"
  },
  dropDown: {
    fontSize: "16px"

  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
    // padding:"0px"
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
} as any
class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  handleClickProjects = (event: any) => {
    console.log(event, "h")
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
  
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      //Merge Engine DefaultContainer
      <div style={{ marginLeft: "15px" }}>


        <Grid container spacing={0} >
        <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
            onClick={() => this.setState({ loading: false })}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/HomePage" style={{ color: secondaryColorToBeChanged, fontSize: "14px" , fontFamily : SecondaryFont }}>
                Home
              </Link>
              <Link href="/Home/projects" style={{ color: '#737373', fontSize: "14px" ,fontFamily : SecondaryFont }}>
                Approval Requests
              </Link>


            </Breadcrumbs>

            <span style={{ marginLeft: "10px" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" }}>Approval Requests

              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Approval Request</Typography>
                    <p>Lorem ipsum dolor sit amet, adi pisci ng elit. Morbi dictum enim ut mattis. Nullam mauris risus, fringilla tincidunt.</p>
                  </React.Fragment>
                }
                placement="right"
              >
                <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                </InfoOutlinedIcon>
              </HtmlTooltip>
            </p></span>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>

            <Button
              variant="contained"
              style={{ color: secondaryColorToBeChanged, marginBottom: "10px" ,fontFamily : SecondaryFont }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}

            >
              Filters
            </Button>

            <ButtonGroup aria-label="outlined primary button group" style={{ marginRight: "10px", marginLeft: "10px" }}>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged ,fontFamily : SecondaryFont }}
                className={this.state.grid ? classes.activeButton : classes.button}
                onClick={e => { this.changeToGridView(e) }}

                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={<AppsOutlinedIcon />}
              >
                {this.state.grid &&
                  <>Grid</>
                }
              </Button>
              <Button
                variant="contained"

                className={this.state.list ? classes.activeButtonList : classes.buttonGroup}
                onClick={e => { this.changeToListView(e) }}
                endIcon={<ListOutlinedIcon />}

              >
                <span style={{ color: "#1a76b0" }}>  {this.state.list &&
                  <>List</>
                }</span>
              </Button>

            </ButtonGroup>


            {/* <SearchBar
      dataSource={state.dataSource}
      onChange={(value) => setState({dataSource: [ value, value+value, value+value+value]})}
      onRequestSearch={() => console.log('onRequestSearch')}
      style={{
        margin: '0 auto',
        maxWidth: 800
      }}
    /> */}
            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              startAdornment={
                <InputAdornment position="start" >
                  <SearchIcon />
                </InputAdornment>
              }
            />


            <Button
              variant="contained"
              className={classes.button}
              size="medium"
              style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: colorToBeChanged, color: "white", marginBottom: "10px" }}
            //   onClick={this.handleClickOpenModal}
            >
              + Create Project
            </Button>

          </Grid>
          {this.state.grid &&

            (
              <>

                <Accordion className={classes.accordianStyle} defaultExpanded>
                  <AccordionSummary

                    aria-controls="panel1a-content"
                    id="panel1a-header"

                  >
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                      <Typography className={classes.heading} >Brands <img src={downIcon} /></Typography>
                      <Typography style={{ position: "absolute", right: "20px" }}>
                        <div >
                          <Button
                            variant="contained"
                            style={{ color: secondaryColorToBeChanged ,fontFamily : SecondaryFont}}
                            className={classes.button}
                            startIcon={<ImportExportIcon style={{ color: "black" }} />}

                          >
                            Name
                          </Button>

                        </div>

                      </Typography>
                    </div>


                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid xs={12}>
                      <Grid container spacing={0}>

                        {data.map((item) => {
                          return (
                            <Grid item xs={3}>


                              <Card >
                                {/* <a href="/Home/Projectportfolio" style={{ textDecoration: "none" }}> */}
                                {/* <NavLink to="/Home/Projectportfolio" > */}
                                {/* onClick={this.navigateToProjectPortfolio} */}


                                <CardContent style={{ paddingBottom: "16px" }}  >

                                  <a >
                                    <Typography className={classes.title} gutterBottom>
                                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}> <img src={logo} style={{ width: "30px", height: "30px" }} onClick={(e) => {
                                          this.handleClickProjects(e);
                                        }} />

                                          <Menu
                                            id="simple-menu1"
                                            anchorEl={this.state.anchorE2}
                                            keepMounted
                                            open={Boolean(this.state.anchorE2)}
                                            onClose={() => {
                                              this.handleCloseProjects();
                                            }}
                                            className={classes.MenuListItems}

                                          >
                                            <MenuItem
                                              onClick={(e) => {
                                                this.handleCloseProjects("inviteTeamMembers");
                                              }}
                                              className={classes.dropDown}
                                            >
                                              <div className={classes.dropDowncircle} >
                                                <img src={inviteLogo} />
                                              </div>

                                              <p style={{ fontSize: "12px", marginLeft: "10px", color: secondaryColorToBeChanged }}>Invite Team Members</p>
                                            </MenuItem>

                                            <MenuItem
                                              onClick={(e) => {
                                                this.handleCloseProjects("duplicate");
                                              }}
                                              className={classes.dropDown}
                                            >
                                              <div className={classes.dropDowncircle} >
                                                <img src={duplicateLogo} />

                                              </div>
                                              <p style={{ fontSize: "12px", marginLeft: "10px", color: secondaryColorToBeChanged }}>Duplicate</p>

                                            </MenuItem>

                                            <MenuItem
                                              onClick={() => {
                                                this.handleCloseProjects("exportAsCSV");
                                              }}
                                              className={classes.dropDown}
                                            >
                                              <div className={classes.dropDowncircle} >

                                                <img src={csvExportLogo} />
                                              </div>
                                              <p style={{ fontSize: "12px", marginLeft: "10px", color: secondaryColorToBeChanged }}>Export as .CSV</p>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                this.handleCloseProjects("archive");
                                              }}
                                              className={classes.dropDown}
                                            >
                                              <div className={classes.dropDowncircle} >

                                                <img src={archieveLogo} />
                                              </div>
                                              <p style={{ fontSize: "12px", marginLeft: "10px", color: secondaryColorToBeChanged }}>Archive </p>

                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                this.handleCloseProjects("notificationSettings");
                                              }}
                                              className={classes.dropDown}
                                            >
                                              <div className={classes.dropDowncircle} >

                                                <img src={notificationLogo} />
                                              </div>
                                              <p style={{ fontSize: "12px", marginLeft: "10px", color: secondaryColorToBeChanged }}> Notification Settings</p>

                                            </MenuItem>
                                          </Menu>


                                          <p style={{ marginTop: "6px", marginLeft: "7px" }} onClick={this.setData}>BMW</p> </span>
                                        <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                          <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={(e: any) => { this.handleClickMenu(e) }}
                                          />


                                        </IconButton>

                                        <Menu
                                          id="simple-menu"
                                          anchorEl={this.state.anchorEl}
                                          keepMounted
                                          open={Boolean(this.state.anchorEl)}
                                          onClose={() => {
                                            this.handleClose("projectProperties");
                                          }}
                                        >

                                          <MenuItem
                                            onClick={() => {
                                              this.handleClose();
                                            }}
                                            className={classes.dropDown}
                                            style={{ fontSize: "12px" }}
                                          >
                                            Project Properties
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) => {
                                              this.handleClose("InviteTeamMembers");
                                            }}
                                            className={classes.dropDown}
                                            style={{ fontSize: "12px" }}
                                          >
                                            Invite Team Members
                                          </MenuItem>

                                          <MenuItem
                                            onClick={(e) => {
                                              this.handleClose("duplicate");
                                            }}
                                            style={{ fontSize: "12px" }}
                                            className={classes.dropDown}
                                          >
                                            Duplicate
                                          </MenuItem>


                                          <MenuItem
                                            onClick={() => {
                                              this.handleClose();
                                            }}
                                            style={{ fontSize: "12px" }}
                                            className={classes.dropDown}
                                          >
                                            Delete Project
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              this.handleClose();
                                            }}
                                            className={classes.dropDown}
                                            style={{ fontSize: "12px" }}
                                          >
                                            Archive
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              this.handleClose();
                                            }}
                                            className={classes.dropDown}
                                            style={{ fontSize: "12px" }}
                                          >
                                            Request Quote
                                          </MenuItem>
                                        </Menu>

                                      </div>


                                    </Typography>

                                    <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Annual Event</p>
                                    <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p>
                                  </a>

                                </CardContent>

                                {/* </NavLink> */}
                                {/* </a> */}



                                <CardActions className={classes.actionCard} >
                                  <p style={{ fontSize: "10px" }}> 29 Sep 2020 last modified</p>
                                  {/* {item.id} */}
                                  <AvatarGroup max={2} className={classes.avatar} style={{ position: "absolute", right: "10px" }} >
                                    <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300" />
                                    <Avatar alt="Travis Howard" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300" />
                                    <Avatar alt="Cindy Baker" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300 " />
                                    <Avatar alt="Trevor Henderson" style={{ height: "30px", width: "30px" }} src="https://picsum.photos/id/237/200/300 " />

                                  </AvatarGroup>



                                </CardActions>

                              </Card>

                            </Grid>





                          )
                        })

                        }
                      </Grid>

                    </Grid>

                  </AccordionDetails>
                </Accordion>

              </>
            )

          }

          {this.state.list &&
            (
              <>

                <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.listHeader}>Drafts <img src={downIcon} aria-controls="panel1a-content"
                      id="panel1a-header" /></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>

                      {

                        data.map((item) => {
                          return (
                            <div>



                              <div style={{ width: "95%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" }}>
                                <div style={{ margin: "20px" }}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p>Magazine Ad _ Nov_001</p>
                                    <p style={{ marginLeft: "30px" }}>Photo Production</p>

                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p style={{ fontSize: "12px" }}>Lorem Ipsum dolor sit amet, consectetur adipsicing elite. Got it? Neither
                                      did we. In the publishing and design industries</p>


                                    <div className={classes.circle} >
                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }} >29 Sep 2020 </p>
                                    <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                      <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                    <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                      <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>

                                    </div>
                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                    {/* <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p> */}

                                  </div>
                                </div>
                              </div>



                            </div>
                          )
                        })
                      }

                    </Grid>



                  </AccordionDetails>

                </Accordion>

                <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.listHeader}>Drafts <img src={downIcon} aria-controls="panel1a-content"
                      id="panel1a-header" /></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>

                      {

                        data.map((item) => {
                          return (
                            <div>



                              <div style={{ width: "95%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" }}>
                                <div style={{ margin: "20px" }}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p>Magazine Ad _ Nov_001</p>
                                    <p style={{ marginLeft: "30px" }}>Photo Production</p>

                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p style={{ fontSize: "12px" }}>Lorem Ipsum dolor sit amet, consectetur adipsicing elite. Got it? Neither
                                      did we. In the publishing and design industries</p>


                                    <div className={classes.circle} >
                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }} >29 Sep 2020 </p>
                                    <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                      <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                    <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                      <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>

                                    </div>
                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                    {/* <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p> */}

                                  </div>
                                </div>
                              </div>



                            </div>
                          )
                        })
                      }

                    </Grid>



                  </AccordionDetails>

                </Accordion>


              </>
            )

          }


        </Grid>



      </div>
      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(MultilevelApproval))
// Customizable Area Start

// Customizable Area End
