
import React from "react";
// Customizable Area Start
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import InputLabel from '@material-ui/core/InputLabel';
// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import ProjectPortfolioController, {
  Props,
  configJSON,
} from "./ProjectPortfolioController";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";

import { withTranslation, Trans } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Link from "@material-ui/core/Link";
import { Link as DomLink } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText } from '@material-ui/core'
const defaultUser = require("../../../components/src/defaultuser.png")
import _ from "lodash";
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  HashRouter,
} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import ReactS3Uploader from "react-s3-uploader"
// import Board from 'react-trello'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Annotations from "../../Annotations/src/Annotations.web"

// or
// import { InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
const logo = require("../../../components/src/volkswagen.svg")
const hearBeatLogo = require('../../../components/src/svgFiles/heartbeat.svg')
const downIcon = require('../../../components/src/down.png')
const imageIcon = require("../../../components/src/svgFiles/image.svg")
const userImage = require("../../../components/src/view-user-image.png")
const plusButton = require("../../../components/src/plus-top-bar.png");
const filterButton = require("../../../components/src/filter.png");

const inviteTeamMembersButton = require("../assets/inviteTeamMembers.png");
const projectPropertiesButton = require("../assets/projectProperties.png");
const deleteProjectButton = require("../assets/deleteProject.png");
// const duplicateButton = require("../assets/duplicate.png");
// const archiveButton = require("../assets/archive.png");
const requestQuoteButton = require("../assets/requestQuote.png");
const inviteButton = require("../assets/invite.png");
const deleteButton = require("../assets/delete.png");
const duplicateButton = require("../assets/duplicate.png");
const archiveButton = require("../assets/archive.png");

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    padding: "24px 13px 30px 28px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);



// const inviteLogo = require('../../../components/src/svgFiles/group-6.svg')
// const duplicateLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
// const csvExportLogo = require("../../../components/src/svgFiles/group-8.svg");
// const archieveLogo = require("../../../components/src/svgFiles/group-14.svg")
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg");
const csvExportLogo = require("../../../components/src/svgFiles/group-6.svg");
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");

const notificationPreferenceSettings = require("../../../components/src/assets/notification_popup.png");

const top100Films = [
  { title: 'Sudheer', year: 1994, image: require('../../../components/src/volkswagen.svg'), id: 18 },
  { title: '9np6x9uwj5d', year: 1972, image: require('../../../components/src/volkswagen.svg'), id: 7 },

];
const styles = {
  root: {
    marginLeft: "45px",
  },
  button: {
    marginLeft: "43px",
    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center",
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
  },
  buttonGroup1: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  inputSerachBox: {
    "&::before": {
      content: "none",
      borderBottom: "none",
    },
    "&:hover": {
      // borderBottom:'none'
    },
    "&::after": {
      borderBottom: "none",
    },
    border: "1px solid #e8edf4",
    background: "#ffffff",
    borderBottom: "1px solid #e8edf4",
    boxSizing: "border-box",
    borderRadius: "8px",
    outline: "none",
    // underline:"none"
  },

  accordianStyle: {
    boxShadow: "none",
  },
  actionCard: {
    backgroundColor: "#e4e8f0",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  avatar: {
    '&:MuiAvatar-root': {
      width: '30px !important',
      height: "30px !important"
    }
  },
  heading: {
    fontSize: "14px",
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "10px",
    marginTop: "10px",
    marginRight: "9px",
    minHeight: "250px",
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
  },

  title: {
    color: "#282828",
    fontSize: "16px",
    '&:hover': {
      textDecoration: "underline !important"
    }
  },
  dropDown: {
    fontSize: "12px",
    color: "#1a76b0",
    fontFamily: 'Work Sans',
  },
  confirmInput: {
    marginTop: "25px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  projectDocument: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px",
  },
  projectDocuments: {
    display: "flex",
    flexDirection: "row",
    margin: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  members: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginLeft: "20px"
    // position:"relative"
  },
  modalUserName: {
    marginTop: "-10px",
    marginLeft: "13px",
  },
  modalUserEmail: {
    marginTop: "10px",
    marginLeft: "-83px",
    color: "#666666"

  },
  date: {
    fontSize: "12px",
    width: '200px',
    marginLeft: "190px"
  },
  fileName: {
    fontSize: "14px",
    margin: "8px"
  },

  fileSize: {
    fontSize: "10px",
    margin: "8px"
  },

  taskImgs: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: "#fff",
    border: "1px solid #e4e8f0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginRight: "6px",
  },
  taskImg: {
    height: "36px",
    objectFit: "contain",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },

  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    width: "72px",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  highlightText: {
    padding: "5px 5px 5px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
    zIndex: "9999"
  },
  projectCards: {

  },
  tab: {
    "&:p": {
      fontSize: "16px",
      textTransform: "none"
    },
    textTransform: "none"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  deleteProjectDialog: {
    MaxWidth: "600px !important",
    width: "100% !important"
  }

} as any;

interface FilmOptionType {
  title: string;
  year: number;
}
const selectedBrandName = localStorage.getItem("selectedBrandName");

class Projects extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  handleClickProjects = (event: any) => {
    //console.log(event, "h");
    this.setState({
      anchorE2: event.currentTarget,
    });
  };

  // Customizable Area Start
  // Customizable Area End
  handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleCloseModalTeam = () => {
    this.setState({
      inviteTeamMembersDialog: false,
    });
  };

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 9) {
      localStorage.setItem("onBoardingStep", "11")
      localStorage.setItem("toShowOnboarding", "false");
      window.location.reload();
    } else if (parseInt(value) == 5) {
      localStorage.setItem("onBoardingStep", "7")
      this.forceUpdate();
    } else if (parseInt(value) == 8) {
      if (this.state.permissions !== "viewer") {
        localStorage.setItem("onBoardingStep", "9")
        this.forceUpdate();
      } else {
        localStorage.setItem("onBoardingStep", "11")
        localStorage.setItem("toShowOnboarding", "false");
        window.location.reload();
      }
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 7) {
      localStorage.setItem("onBoardingStep", "5")
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
    }
    this.forceUpdate();
  }

  handleSkipOnboarding = () => {
    localStorage.setItem("onBoardingStep", "11");
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  render() {
    const { t, classes } = this.props;
    //console.log(this.props, "prop");

    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      <div className={classes.root} style={{ fontFamily: primaryFont }}>
        <Backdrop
          className={classes.backdrop}
          open={this.state.loadingIndicator}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                href="/Home/Homepage"
                // onClick={this.handleClick}
                style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}
              >
                {t("HomeScreen.home")}
              </Link>
              {
                (this.state.permissionName !== "client" && this.state.permissions !== "viewer") && (this.state.permissionName !== "client" && this.state.permissions !== "approver") && (this.state.permissionName !== "client" && this.state.permissions !== "brand_manager") && (
                  <Typography style={{ fontSize: "12px" }}>
                    <Link href="/Home/Brands" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                      {t("AdminConsole.brands")}
                    </Link> / {selectedBrandName}
                  </Typography>
                )
              }
              {
                (this.state.permissionName == "client" && this.state.permissions == "approver") && (
                  <Typography style={{ fontSize: "12px" }}>
                    <Link href="#" style={{ color: "#737373", fontSize: "14px", textDecoration: "none", cursor: "default", fontFamily: SecondaryFont }}>
                      {t("HomeScreen.activeProject")}
                    </Link>
                  </Typography>
                )
              }

            </Breadcrumbs>
            <span> <p style={{ fontSize: "32px", margin: "0px 0px 0px 8px", fontFamily: SecondaryFont }}>{t("HomeScreen.project")}

              <HtmlTooltip
                arrow
                placement="right"
                open={localStorage.getItem("onBoardingStep") == "5" && this.state.permissionName === "client" ? true : false}
                title={
                  <React.Fragment>
                    <div>
                      <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} />
                      <span className={classes.popupTitle}>Information</span>
                      <span className={classes.popupCancelIcon}>
                        <CloseIcon onClick={this.handleSkipOnboarding} />
                      </span>
                    </div>
                    <div className={classes.popupText}>
                      {t("onBoarding.informationText")}
                    </div>
                    <div>
                      <Button
                        className={classes.popupSkipButton}
                        onClick={this.handleSkipOnboarding}
                      >
                        SKIP
                      </Button>

                      <Button
                        className={classes.popupButton}
                        onClick={this.handleNextOnboarding}
                      >
                        NEXT
                      </Button>
                    </div>
                  </React.Fragment>
                }
              >
                <InfoOutlinedIcon
                  style={{ height: "20px", width: "20px", color: "#737373" }}
                  className={localStorage.getItem("onBoardingStep") == "5" && this.state.permissionName === "client" && classes.highlightText}>

                </InfoOutlinedIcon>
              </HtmlTooltip>



            </p></span>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right", marginTop: "16px", padding: "5px" }}>
            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "6" && this.state.permissionName === "client" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <ImportExportIcon style={{ color: "black" }} />
                    <span className={classes.popupTitle}>Filters</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.filterText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >
              <span></span>
              {/* <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, marginBottom: "10px", textTransform: "none" }}
                className={classes.button + ' ' + (localStorage.getItem("onBoardingStep") == "6" && this.state.permissionName === "client" && classes.highlightText)}
                startIcon={<ImportExportIcon style={{ color: "black" }} />}
                onClick={(event) => this.setState({ anchorElFilter1: event.target })}
              >
                Filters
              </Button> */}
            </HtmlTooltip>

            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "7" && this.state.permissionName === "client" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <AppsOutlinedIcon />
                    <span className={classes.popupTitle}>{t("HomeScreen.list")}</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.gridText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >
              <ButtonGroup
                aria-label="outlined primary button group"
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={localStorage.getItem("onBoardingStep") == "7" && this.state.permissionName === "client" && classes.highlightText}
              >
                <Button
                  variant="contained"
                  style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: primaryFont }}
                  className={
                    this.state.grid ? classes.activeButton : classes.button
                  }
                  onClick={(e) => {
                    this.changeToGridView(e);
                  }}
                  // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                  startIcon={
                    <AppsOutlinedIcon
                      style={{ color: secondaryColorToBeChanged }}
                    />
                  }
                >
                  {this.state.grid && <>{t("HomeScreen.grid")}</>}
                </Button>
                <Button
                  variant="contained"
                  style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: primaryFont }}
                  className={
                    this.state.list
                      ? classes.activeButtonList
                      : classes.buttonGroup
                  }
                  onClick={(e) => {
                    this.changeToListView(e);
                  }}
                  endIcon={
                    <ListOutlinedIcon
                      style={{ color: secondaryColorToBeChanged }}
                    />
                  }
                >
                  {this.state.list && (
                    <>
                      <span>{t("HomeScreen.list")}</span>
                    </>
                  )}
                </Button>
              </ButtonGroup>
            </HtmlTooltip>

            {/* <SearchBar
      dataSource={state.dataSource}
      onChange={(value) => setState({dataSource: [ value, value+value, value+value+value]})}
      onRequestSearch={() => console.log('onRequestSearch')}
      style={{
        margin: '0 auto',
        maxWidth: 800
      }}
    /> */}
            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "8" && this.state.permissionName === "client" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <SearchIcon />
                    <span className={classes.popupTitle}>Search</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.searchText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >

              <Input
                id="input-with-icon-adornment"
                className={classes.inputSerachBox + ' ' + (localStorage.getItem("onBoardingStep") == "8" && this.state.permissionName === "client" && classes.highlightText)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={this.handleSearchChange2}
                value={this.state.searchProject}
                placeholder="search"
              />
            </HtmlTooltip>
            {this.state.permissions !== "viewer" && (
              <HtmlTooltip
                arrow
                placement="left"
                open={localStorage.getItem("onBoardingStep") == "9" && this.state.permissionName === "client" ? true : false}
                title={
                  <React.Fragment>
                    <div>
                      <AppsOutlinedIcon />
                      <span className={classes.popupTitle}>{t("onBoarding.createProject")}</span>
                      <span className={classes.popupCancelIcon}>
                        <CloseIcon onClick={this.handleSkipOnboarding} />
                      </span>
                    </div>
                    <div className={classes.popupText}>
                      {t("onBoarding.createProjectText")}
                    </div>
                    <div>
                      <Button
                        className={classes.popupSkipButton}
                        onClick={this.handleSkipOnboarding}
                      >
                        SKIP
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handlePrevOnboarding}
                      >
                        PREV
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handleNextOnboarding}
                      >
                        NEXT
                      </Button>
                    </div>
                  </React.Fragment>
                }
              >
                <Button
                  variant="contained"
                  className={classes.button + ' ' + (localStorage.getItem("onBoardingStep") == "9" && this.state.permissionName === "client" && classes.highlightText)}
                  size="medium"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    backgroundColor: colorToBeChanged,
                    color: "white",
                    marginBottom: "10px",
                  }}
                  onClick={this.handleClickOpenModal}
                >
                  {t("projects.createProject")}
                </Button>
              </HtmlTooltip>
            )}
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.handleClose("projectProperties");
            }}
          >

            <MenuItem
              // data-my-value={project}
              onClick={(e) => {

                this.handleClickProjectProperties(e);
              }}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
              className={classes.dropDown}

            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={projectPropertiesButton} /></span> {t("ApprovalRequest.projectProperties")}
            </MenuItem>
            {/* {(this.state.permissions == "viewer" || this.state.permissions == "approver" || (this.state.permissions === "brand_manager" && this.state.permissionName === "client")) && ( */}
            {/* <> */}
            <MenuItem
              onClick={(e) => {
                this.handleClickInviteTeamMember(
                  e
                );
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={inviteButton} /> </span> {t("ApprovalRequest.inviteTeamMember")}
            </MenuItem>

            <MenuItem
              onClick={(e) => {
                this.handleOpenDuplicateDialog(e);
              }}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
              className={classes.dropDown}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={duplicateButton} /> </span> {t("ApprovalRequest.duplicate")}
            </MenuItem>

            <MenuItem
              onClick={(e) => {
                this.setState({ deleteProjectDialog: true })
              }}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
              className={classes.dropDown}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={deleteButton} /> </span>{t("ApprovalRequest.deleteProject")}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                this.handleProjectArchiveApi(e);
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={archiveButton} /> </span>{t("ApprovalRequest.archive")}
            </MenuItem>
            {/* </> */}

            <MenuItem
              onClick={(e) => {
                this.handleNotificationOpen(e);
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={archiveButton} /> </span>{t("HomeScreen.notificationPreferences")}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                // this.callCreateRequestQuotationForProjectApi(e);
                this.handleOpenRequestQuoteDialog(e);
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              <span className={classes.taskImgs} >
                <img className={classes.taskImg} src={requestQuoteButton} /> </span> {t("projects.requestQuote")}
            </MenuItem>

          </Menu>
          {!this.state.isSearch ?
            <>
              <>
                <Grid item xs={12}>
                  <div style={{ textAlign: "right", marginRight: "20px", marginBottom: "-35px" }}>
                    <Button
                      variant="contained"
                      style={{ color: secondaryColorToBeChanged, textTransform: 'none', fontFamily: SecondaryFont }}
                      className={classes.button}
                      startIcon={
                        <img src={filterButton} aria-controls="panel1a-content"
                          id="panel1a-header" />
                      }
                      endIcon={
                        <ExpandMoreIcon style={{ color: "black" }} />
                      }
                      onClick={(event) => this.setState({ anchorElFilter1: event.target })}
                    >
                      {
                        this.state.projectFilterValue == 'name0' ? 'Name (A-Z)' : this.state.projectFilterValue == 'name1' ? 'Name (Z-A)' :
                          this.state.projectFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.projectFilterValue == 'created_at1' ? 'Created At' :
                            this.state.projectFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                      }
                    </Button>
                  </div>
                </Grid>
                {((this.state.permissionName === "superadmin" || this.state.permissions === "viewer" || this.state.permissions === "approver" || this.state.permissions === "brand_manager" || (this.state.permissionName === "albertbauer" && this.state.permissions === "viewer") || (this.state.permissionName === "albertbauer" && this.state.permissions === "editor")) && (
                  this.state.grid && (
                    <>
                      <Grid item xs={12}>
                        <div style={{ marginTop: "10px" }}>
                          <Grid item xs={12} style={{ marginTop: "50px" }} >
                            <Grid container spacing={0}>

                              {this.state.tableProjectData.length ? this.state.tableProjectData.map((project: any) => (
                                <Grid item xs={3} key={project?.id} >
                                  <Card className={classes.cardStyle} style={{ cursor: 'pointer',position:'relative' }} onClick={(e) => { this.setData(project) }}>
                                    {this.state.notify?.project_ids?.includes(parseInt(project.id)) ? <div style={{
                                      height: 8,
                                      width: 8,
                                      backgroundColor: "red",
                                      borderRadius: "50%",
                                      position:'absolute',
                                      top:'5px',
                                      left:'5px'

                                    }}></div> : <div></div>}
                                    <>
                                      <CardHeader
                                        title={
                                          <Typography className={classes.title} gutterBottom component="div" style={{
                                            // height: '50px',
                                            lineBreak: 'anywhere', marginTop: "-10px"
                                          }}>
                                            <span style={{ fontSize: "16px", overflow: 'hidden', textOverflow: 'ellipsis', width: "230px", whiteSpace: "nowrap", display: "block" }} onClick={(e) => { this.setData(project) }}>
                                              {project.attributes?.name}
                                            </span>
                                          </Typography>
                                        }
                                        action={
                                          <IconButton aria-label="settings" style={{ color: "black", marginTop: '0px !important' }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: '0px !important' }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                  selectedProjectData: project
                                                });
                                                this.handleClickMenu(e);
                                              }}
                                            />
                                          </IconButton>
                                        }
                                      >
                                      </CardHeader>
                                      <CardContent style={{
                                        paddingBottom: "16px",
                                        height: "120px"
                                      }} onClick={(e) => {
                                        this.setState({ clickEventCheck: "Whole Card" })
                                        if (this.state.clickEventCheck == "Task Open") {
                                          return;
                                        } else if (this.state.clickEventCheck == "Task Create") {
                                          return;
                                        } else if (this.state.clickEventCheck == "Whole Card") {
                                          this.setData(project)
                                        }
                                      }}>
                                        {project.attributes?.first_tasks?.data.length > 0 ? project.attributes?.first_tasks?.data.map((task: any, index: any) => (
                                          <p key={index}
                                            style={{
                                              fontSize: "12px",
                                              color: secondaryColorToBeChanged,
                                              overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                            }}
                                            onClick={(e) => {
                                              this.setState({ clickEventCheck: "Task Open" }, () => {
                                                this.openTaskProjectLevel(project, task);
                                              })
                                            }}
                                          >
                                            {task.attributes?.title}
                                          </p>
                                        )) : <p
                                          style={{
                                            fontSize: "12px",
                                            color: secondaryColorToBeChanged,
                                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                          }}
                                          onClick={(e) => {
                                            this.setState({ clickEventCheck: "Task Create" }, () => {
                                              this.openTaskProjectLevel(project, null);
                                            })
                                          }}
                                        >Create Task</p>
                                        }



                                      </CardContent>
                                      <CardActions className={classes.actionCard}>
                                        <p style={{ fontSize: "11px" }}>
                                          {" "}
                                          {project.attributes?.updated_at}
                                          <span style={{ display: "block", fontSize: "10px", marginTop: "6px" }} >{t("ApprovalRequest.lastModified")}</span>
                                        </p>

                                        {(project?.attributes?.first_accounts?.data?.length > 0) &&
                                          <AvatarGroup max={3} className={classes.avatar} onClick={(event: any) => {
                                            event.stopPropagation();
                                            this.setState({
                                              selectedProjectData: project
                                            }, () => {
                                              this.handleClickProjectPropertiesEventMembers(event)
                                            })
                                          }}
                                          >
                                            {project?.attributes?.first_accounts?.data.map((account: any, index: any) => (
                                              <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={account.attributes?.photo_blob_signed_id_url} key={index} />
                                            ))}
                                            {_.times(project?.attributes?.total_accounts - project?.attributes?.first_accounts?.data?.length, () => (
                                              <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        }

                                      </CardActions>
                                    </>

                                  </Card>
                                </Grid>
                              )) :

                                (this.state.loadingIndicator == false && <p style={{ marginLeft: "15px", textAlign: "center" }}>No projects are live yet.</p>)
                              }



                            </Grid>
                          </Grid>
                          {/* </AccordionDetails> */}
                          {/* </Accordion> */}
                        </div>
                      </Grid>
                      {/* 
                      <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ position: "absolute", right: "25px", marginTop: "14px" }}>
                            <Button
                              variant="contained"
                              style={{ color: secondaryColorToBeChanged, zIndex: 100, textTransform: 'none' }}
                              className={classes.button}
                              startIcon={
                                <img src={filterButton} aria-controls="panel1a-content"
                                  id="panel1a-header" />
                              }
                              endIcon={
                                <ExpandMoreIcon style={{ color: "black" }} />
                              }
                            >
                              Name
                            </Button>
                          </div>
                          <Accordion className={classes.accordianStyle} defaultExpanded expanded={this.state.waitingArrowFlag === true} onChange={(e) => this.setState(preState => ({ waitingArrowFlag: !preState.waitingArrowFlag }))}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.listHeader}>Waiting for Quote {'  '}</Typography>
                              {this.state.waitingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>

                                  {this.state.projectsOfStatusWaitingForQuoteData.length ? this.state.projectsOfStatusWaitingForQuoteData.map((project: any) => (
                                    <Grid item xs={3}>
                                      <Card className={classes.cardStyle}>
                                        <div style={{
                                          height: 8,
                                          width: 8,
                                          backgroundColor: "red",
                                          borderRadius: "50%",
                                          marginTop: "5px",
                                          marginLeft: "5px"
                                        }}></div>
                                        <CardContent style={{ paddingBottom: "16px", height: "160px" }}  >


                                          <Typography className={classes.title} gutterBottom>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                {project?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url ?
                                                  <img src={project?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                  :
                                                  <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{project?.attributes?.brand?.data?.attributes?.name.match(/\b(\w)/g).join('')}</Avatar>
                                                }
                                                <p onClick={(e) => { this.setData(project) }} style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px" }} >{project.attributes?.name}</p> </span>
                                              <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                                  aria-haspopup="true"
                                                  onClick={(e) => {
                                                    this.setState({
                                                      selectedProjectData: project
                                                    });
                                                    this.handleClickMenu(e);
                                                  }}
                                                />


                                              </IconButton>

                                              <Menu
                                                id="simple-menu"
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={() => {
                                                  this.handleClose("projectProperties");
                                                }}
                                              >

                                                <MenuItem
                                                   data-my-value={project}
                                                  onClick={(e) => {

                                                    this.handleClickProjectProperties(e);
                                                  }}
                                                  style={{ fontSize: "12px" }}
                                                  className={classes.dropDown}
                                                >
                                                  <span className={classes.taskImgs} >
                                                    <img className={classes.taskImg} src={projectPropertiesButton} /></span> Project Properties
                                                </MenuItem>
                                                {(this.state.permissions == "viewer" || this.state.permissions == "approver" || (this.state.permissions === "brand_manager" && this.state.permissionName === "client")) && (
                                                <>
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.handleClickInviteTeamMember(
                                                        e
                                                      );
                                                    }}
                                                    className={classes.dropDown}
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={inviteButton} /> </span> Invite Team Members
                                                  </MenuItem>

                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.handleOpenDuplicateDialog(e);
                                                    }}
                                                    style={{ fontSize: "12px" }}
                                                    className={classes.dropDown}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={duplicateButton} /> </span> Duplicate
                                                  </MenuItem>

                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.callDeleteProjectsApi(e);
                                                    }}
                                                    style={{ fontSize: "12px" }}
                                                    className={classes.dropDown}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={deleteButton} /> </span>Delete Project
                                                  </MenuItem>
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.handleProjectArchiveApi(e);
                                                    }}
                                                    className={classes.dropDown}
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={archiveButton} /> </span>Archive
                                                  </MenuItem>
                                                </>

                                                <MenuItem
                                                  onClick={(e) => {
                                                    this.handleNotificationOpen(e);
                                                  }}
                                                  className={classes.dropDown}
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  <span className={classes.taskImgs} >
                                                    <img className={classes.taskImg} src={archiveButton} /> </span>Notification Preferences
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    this.callCreateRequestQuotationForProjectApi(e);
                                                  }}
                                                  className={classes.dropDown}
                                                >
                                                  <span className={classes.taskImgs} >
                                                    <img className={classes.taskImg} src={requestQuoteButton} /> </span> Request Quote
                                                </MenuItem>

                                              </Menu>

                                            </div>

                                          </Typography>
                                          {project.attributes?.first_tasks?.data.length > 0 ? project.attributes?.first_tasks?.data.map((task: any) => (
                                            <p onClick={(e) => { this.setData(project) }}
                                              style={{
                                                fontSize: "12px",
                                                color: secondaryColorToBeChanged,
                                                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                              }}
                                            >
                                              {task.attributes?.title}
                                            </p>
                                          )) : <p
                                            style={{
                                              fontSize: "12px",
                                              color: secondaryColorToBeChanged,
                                              overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                            }}
                                          >Create Task</p>
                                          }



                                        </CardContent>

                                        <CardActions className={classes.actionCard}>
                                          <p style={{ fontSize: "11px" }}>
                                            {" "}
                                            {project.attributes?.updated_at}
                                          </p>
                                          {project?.attributes?.first_accounts?.data.map((account: any) => (
                                            <AvatarGroup max={3} className={classes.avatar}>

                                              <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={account.attributes?.photo_blob_signed_id_url} />

                                            </AvatarGroup>
                                          ))}
                                        </CardActions>
                                      </Card>
                                    </Grid>
                                  )) :
                                    <p style={{ marginLeft: "15px", textAlign: "center" }}>No Waiting for Quote</p>}



                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </Grid>

                     */}

                      {/*                     
                    <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ position: "absolute", right: "25px", marginTop: "14px" }}>
                            <Button
                              variant="contained"
                              style={{ color: secondaryColorToBeChanged, zIndex: 100, textTransform: 'none' }}
                              className={classes.button}
                              startIcon={
                                <img src={filterButton} aria-controls="panel1a-content"
                                  id="panel1a-header" />
                              }
                              endIcon={
                                <ExpandMoreIcon style={{ color: "black" }} />
                              }
                            >
                              Name
                            </Button>
                          </div>
                          <Accordion className={classes.accordianStyle} defaultExpanded expanded={this.state.onGoingArrowFlag === true} onChange={(e) => this.setState(preState => ({ onGoingArrowFlag: !preState.onGoingArrowFlag }))}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.listHeader}>On going Projects {' '}</Typography>
                              {this.state.onGoingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>

                                  {this.state.projectsOfStatusOnGoingData.length ? this.state.projectsOfStatusOnGoingData.map((project: any) => (
                                    <Grid item xs={3}>
                                      <Card className={classes.cardStyle}>
                                        <div style={{
                                          height: 8,
                                          width: 8,
                                          backgroundColor: "red",
                                          borderRadius: "50%",
                                          marginTop: "5px",
                                          marginLeft: "5px"
                                        }}></div>
                                        <CardContent style={{ paddingBottom: "16px", height: "160px" }}  >


                                          <Typography className={classes.title} gutterBottom>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                      {/* <DomLink
                                 to={"/Home/Projectportfolio"}
                                //to={'/Home/Projectportfolio/'+ project?.attributes?.name }

                                style={{
                                  textDecoration: "none",
                                  color: secondaryColorToBeChanged,
                                }}
                              > */}
                      {/* <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                {project?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url ?
                                                  <img src={project?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                  :
                                                  <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{project?.attributes?.brand?.data?.attributes?.name.match(/\b(\w)/g).join('')}</Avatar>
                                                }
                                                <p onClick={(e) => { this.setData(project) }} style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px" }}
                                                >{project.attributes?.name}</p> </span> */}
                      {/* </DomLink> */}
                      {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                                  aria-haspopup="true"
                                                  onClick={(e) => {
                                                    this.setState({
                                                      selectedProjectData: project
                                                    });
                                                    this.handleClickMenu(e);
                                                  }}
                                                />


                                              </IconButton>

                                              <Menu
                                                id="simple-menu"
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={() => {
                                                  this.handleClose("projectProperties");
                                                }}
                                              >

                                                <MenuItem */}
                      {/* data-my-value={project} */}
                      {/* onClick={(e) => { */}

                      {/* this.handleClickProjectProperties(e);
                                                   }}
                                                   style={{ fontSize: "12px" }}
                                                  className={classes.dropDown}
                                                 >
                                                   <span className={classes.taskImgs} >
                                                     <img className={classes.taskImg} src={projectPropertiesButton} /></span> Project Properties
                                                 </MenuItem> */}
                      {/* {(this.state.permissions == "viewer" || this.state.permissions == "approver" || (this.state.permissions === "brand_manager" && this.state.permissionName === "client")) && ( */}
                      {/* <>
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.handleClickInviteTeamMember(
                                                        e
                                                      );
                                                    }}
                                                    className={classes.dropDown}
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={inviteButton} /> </span> Invite Team Members
                                                  </MenuItem>

                                                  <MenuItem
                                                 
                                                 
                                                  onClick={(e)=>{this.handleOpenDuplicateDialog(e)}}
                                                    style={{ fontSize: "12px" }}
                                                    className={classes.dropDown}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={duplicateButton} /> </span> Duplicate
                                                  </MenuItem>

                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.callDeleteProjectsApi(e);
                                                    }}
                                                    style={{ fontSize: "12px" }}
                                                    className={classes.dropDown}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={deleteButton} /> </span>Delete Project
                                                  </MenuItem>
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      this.handleProjectArchiveApi(e);
                                                    }}
                                                    className={classes.dropDown}
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    <span className={classes.taskImgs} >
                                                      <img className={classes.taskImg} src={archiveButton} /> </span>Archive
                                                  </MenuItem>
                                                </>

                                                <MenuItem
                                                  onClick={(e) => {
                                                    this.handleNotificationOpen(e);
                                                  }}
                                                  className={classes.dropDown}
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  <span className={classes.taskImgs} >
                                                    <img className={classes.taskImg} src={archiveButton} /> </span>Notification Preferences
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    this.callCreateRequestQuotationForProjectApi(e);
                                                  }}
                                                  className={classes.dropDown}
                                                >
                                                  <span className={classes.taskImgs} >
                                                    <img className={classes.taskImg} src={requestQuoteButton} /> </span> Request Quote
                                                </MenuItem>

                                              </Menu>


                                            </div>

                                          </Typography>
                                          {project.attributes?.first_tasks?.data.length > 0 ? project.attributes?.first_tasks?.data.map((task: any) => (
                                            <p onClick={(e) => { this.setData(project) }}
                                              style={{
                                                fontSize: "12px",
                                                color: secondaryColorToBeChanged,
                                                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                              }}
                                            >
                                              {task.attributes?.title}
                                            </p>
                                          )) : <p
                                            style={{
                                              fontSize: "12px",
                                              color: secondaryColorToBeChanged,
                                              overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px"
                                            }}
                                          >Create Task</p>
                                          }



                                        </CardContent> */}
                      {/* </NavLink> */}
                      {/* </a> */}

                      {/* <CardActions className={classes.actionCard}>
                                          <p style={{ fontSize: "11px" }}>
                                            {" "}
                                            {project.attributes?.updated_at}
                                          </p> */}

                      {/* <AvatarGroup
                                max={2}
                                className={classes.avatar}
                                style={{ marginLeft: "40%" }}
                              >
                                {project.attributes?.first_accounts?.data.map((account:any)=>(
                                  <Avatar
                                  alt="Remy Sharp"a
                                  style={{ height: "30px", width: "30px" }}
                                  src={account.attributes?.photo}
                                />
                                ))}
                             
                              </AvatarGroup> */}


                      {/* <AvatarGroup max={3} className={classes.avatar}>
                                            {project?.attributes?.first_accounts?.data.map((account: any) => (
                                              <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={account.attributes?.photo_blob_signed_id_url} />
                                            ))}
                                          </AvatarGroup>
                                        </CardActions>
                                      </Card>
                                    </Grid>
                                  )) :
                                    <p style={{ marginLeft: "15px", textAlign: "center" }}>No On going Projects</p>}



                                </Grid> */}
                      {/* <Box textAlign="right" style={{ marginTop: "30px", position: "absolute", right: "55px" }}>
                            <Pagination count={this.state.total_pages} variant="outlined" color="primary" page={this.state.setPageType3} onChange={this.handleChangeOngoingProjectList} />
                          </Box> */}
                      {/* </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </Grid> */}

                    </>
                  )))}
              </>
              {this.state.list && (
                <>
                  <Grid item xs={12}>
                    {this.state.tableProjectData.length ? this.state.tableProjectData.map((project: any, index: any) => {
                      return (
                        <div key={index}
                          style={{
                            width: "97%",
                            borderRadius: "8px",
                            borderLeft: `6px solid ${secondaryColorToBeChanged}`,
                            borderRight: "1px solid #e4e8f0",
                            borderTop: "1px solid #e4e8f0",
                            borderBottom: "1px solid #e4e8f0",
                            marginTop: "55px",
                            cursor: 'pointer'
                          }}
                        >
                          <div style={{ margin: "20px" }}>
                            <Grid item xs={12} style={{ display: "flex" }}>
                              <Grid item xs={6} style={{ display: "flex" }}
                                onClick={(e) => { this.setData(project) }}
                              >
                                {this.state.notify?.project_ids?.includes(parseInt(project.id)) ? <div style={{
                                  height: 8,
                                  width: 8,
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  marginTop: "20px",
                                  marginLeft: "5px"
                                }}></div> : <div style={{
                                  height: 8
                                }}></div>}
                                <p style={{ marginLeft: "10px" }}>
                                  {project.attributes?.name}
                                </p>
                              </Grid>
                              <Grid item xs={5} style={{ display: "flex" }}
                                onClick={(e) => { this.setData(project) }}
                              >
                                <div className={classes.circle}>
                                  <p>
                                    <CalendarTodayOutlinedIcon
                                      style={{ fontSize: "12px" }}
                                    />
                                  </p>
                                </div>

                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                  {project.attributes?.updated_at} {t("ApprovalRequest.lastModified")}
                                </p>
                              </Grid>
                              <Grid item xs={1} style={{ display: "flex" }}>
                                <IconButton aria-label="settings" style={{ color: "black", marginTop: '0px !important' }}>
                                  <MoreVertIcon style={{ color: "black", fontSize: "16px", marginTop: '0px !important' }} aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      this.setState({
                                        selectedProjectData: project
                                      });
                                      this.handleClickMenu(e);
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                              onClick={(e) => { this.setData(project) }}
                            >
                              {project.attributes?.first_tasks?.data && project.attributes?.first_tasks?.data.map((task: any, index: any) => (
                                <p key={index} style={{ fontSize: "12px", paddingLeft: "25px" }} onClick={(e) => {

                                  this.setData(project)
                                }}>
                                  {task.attributes.title}
                                </p>
                              ))}
                              {project.attributes?.first_tasks?.data && project.attributes?.first_tasks?.data.length <= 0 &&
                                <p></p>}
                            </div>
                          </div>
                        </div>
                      )
                    })
                      :
                      <p style={{ marginLeft: "15px", textAlign: "center" }}>No projects are live yet.</p>

                    }

                  </Grid>
                  {/* </AccordionDetails> */}

                  {/* </Accordion> */}

                  {/* <Accordion
                    style={{ width: "100%", marginTop: "30px" }}
                    className={classes.accordianStyle}
                    defaultExpanded expanded={this.state.waitingArrowFlag === true} onChange={(e) => this.setState(preState => ({ waitingArrowFlag: !preState.waitingArrowFlag }))}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.listHeader}> Waiting for Quote{" "} </Typography>
                      {this.state.waitingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        {this.state.projectsOfStatusWaitingForQuoteData.length ? this.state.projectsOfStatusWaitingForQuoteData.map((project: any) => (

                          <div onClick={(e) => { this.setData(project) }}
                            style={{
                              width: "95%",
                              borderRadius: "8px",
                              borderLeft: "6px solid #1a76b0",
                              borderRight: "1px solid #e4e8f0",
                              borderTop: "1px solid #e4e8f0",
                              borderBottom: "1px solid #e4e8f0",
                              marginTop: "20px",
                            }}
                          >

                            <div style={{ margin: "20px" }}>
                              <div style={{
                                height: 8,
                                width: 8,
                                backgroundColor: "red",
                                borderRadius: "50%",
                                marginTop: "5px",
                                marginLeft: "5px"
                              }}></div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <p>{project.attributes?.name}</p>
                                <p style={{ marginLeft: "30px" }}>{project.attributes?.brand?.data?.attributes?.name}</p>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {project.attributes?.first_tasks?.data && project.attributes?.first_tasks?.data.map((task: any) => (
                                  <p style={{ fontSize: "12px", paddingLeft: "25px" }} onClick={(e) => {
                                    this.setData(project)
                                  }}>
                                    {task.attributes.title}
                                  </p>
                                ))}
                                <div className={classes.circle}>
                                  <p>
                                    <CalendarTodayOutlinedIcon
                                      style={{ fontSize: "12px" }}
                                    />
                                  </p>
                                </div>

                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                  {project.attributes?.updated_at}
                                </p>
                                <div
                                  className={classes.circle}
                                  style={{ marginLeft: "12px" }}
                                >
                                  <span>
                                    <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                                  </span>
                                </div>

                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
      3
    </p>
                                <div
                                  className={classes.circle}
                                  style={{ marginLeft: "12px" }}
                                >
                                  <span>
                                    {" "}
                                    <AttachFileOutlinedIcon
                                      style={{ fontSize: "13px" }}
                                    />
                                  </span>
                                </div>
                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
      3
    </p>
                                <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p>
                              </div>
                            </div>

                          </div>))
                          :
                          <p style={{ marginLeft: "15px", textAlign: "center" }}>No Waiting for Quote</p>}

                      </Grid>
                    </AccordionDetails>

                  </Accordion>

                  
                   */}

                  {/* <Accordion
                    style={{ width: "100%", marginTop: "30px" }}
                    className={classes.accordianStyle}
                    defaultExpanded expanded={this.state.onGoingArrowFlag === true} onChange={(e) => this.setState(preState => ({ onGoingArrowFlag: !preState.onGoingArrowFlag }))}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.listHeader}>
                        On going Projects{" "}
                      </Typography>
                      {this.state.onGoingArrowFlag === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        {this.state.projectsOfStatusOnGoingData.length ? this.state.projectsOfStatusOnGoingData.map((project: any) => (

                          <div onClick={(e) => { this.setData(project) }}
                            style={{
                              width: "95%",
                              borderRadius: "8px",
                              borderLeft: "6px solid #1a76b0",
                              borderRight: "1px solid #e4e8f0",
                              borderTop: "1px solid #e4e8f0",
                              borderBottom: "1px solid #e4e8f0",
                              marginTop: "20px",
                            }}
                          >

                            <div style={{ margin: "20px" }}>
                              <div style={{
                                height: 8,
                                width: 8,
                                backgroundColor: "red",
                                borderRadius: "50%",
                                marginTop: "5px",
                                marginLeft: "5px"
                              }}></div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <p>{project.attributes?.name}</p>
                                <p style={{ marginLeft: "30px" }}>{project.attributes?.brand?.data?.attributes?.name}</p>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {project.attributes?.first_tasks?.data && project.attributes?.first_tasks?.data.map((task: any) => (
                                  <p style={{ fontSize: "12px", paddingLeft: "25px" }} onClick={(e) => {
                                    this.setData(project)
                                  }}>
                                    {task.attributes.title}
                                  </p>
                                ))}
                                <div className={classes.circle}>
                                  <p>
                                    <CalendarTodayOutlinedIcon
                                      style={{ fontSize: "12px" }}
                                    />
                                  </p>
                                </div>

                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                  {project.attributes?.updated_at}
                                </p>
                                <div
                                  className={classes.circle}
                                  style={{ marginLeft: "12px" }}
                                >
                                  <span>
                                    <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                                  </span>
                                </div>

                                <p style={{ fontSize: "12px", marginLeft: "5px" }}>
      3
    </p>
                                <div
                                  className={classes.circle}
                                  style={{ marginLeft: "12px" }}
                                >
                                  <span>
                                    {" "}
                                    <AttachFileOutlinedIcon
                                      style={{ fontSize: "13px" }}
                                    />
                                  </span>
                                </div>
                                { <p style={{ fontSize: "12px", marginLeft: "5px" }}>
      3
    </p> 
                                <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p>
                              </div>
                            </div>

                          </div>)) : <p style={{ marginLeft: "15px", textAlign: "center" }}>No On going Projects</p>}

                      </Grid>
                    </AccordionDetails>

                  </Accordion> */}

                </>
              )}
            </> :
            <>
              {this.state.tableProjectData.length !== 0 ?
                <>
                  <Grid container direction="row">
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                      <Button onClick={() => this.setState({ isSearch: false, searchProject: "", filterValue: "" })} variant="text" style={{ left: "60px" }}>Back to Previous Screen</Button>
                    </Grid>
                  </Grid>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            Project Name
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Creator
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Project Manager
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Due Date
                          </TableCell>


                        </TableRow>
                      </TableHead>

                      {this.state.tableProjectData.map((_data: any) => {
                        return (


                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}


                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",

                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={
                                        classes.tableData
                                      }
                                      onClick={(e: any) => {
                                        this.setState({ selectData: _data, selectedBrandId: _data.id }, () => {
                                          // this.getProjectByBrandID()
                                          //console.log("projects ", this.state.selectData);

                                          this.setData2(this.state.selectData)

                                        })
                                      }}
                                    >
                                      {_data.attributes.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.creator.data !== null ? `${_data.attributes.creator.data.attributes.first_name} ${_data.attributes.creator.data.attributes.last_name}` : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.project_manager.data !== null
                                    ? `${_data.attributes.project_manager.data
                                      .attributes.first_name
                                    } ${_data.attributes.project_manager.data
                                      .attributes.last_name
                                    }`
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.status}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.estimated_end_date !== null
                                    ? _data.attributes.estimated_end_date
                                    : "NA"}
                                </Typography>
                              </TableCell>



                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        )
                      })}

                    </Table>
                  </TableContainer>
                </> : <p>No data Found</p>}
            </>
          }
        </Grid>

        {/* Modal Dialogues */}
        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {/* {t("projects.newProjectTitle")} */}
            {t("projects.newProject")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",
              projectDescription: "",
              templete: '',
              title: "",
              //shareTheProjectWithOthers:this.state.sharedProject,
              // includeAPersonalMessageWithYourInvitation: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              // projectDescription: Yup.string().required(
              //   "projectDescription is required"
              // ),
              title: Yup.string().required(
                "title is required"
              ),

              // includeAPersonalMessageWithYourInvitation: Yup.string().required(
              //   "Include A Personal MessageWith Your Invitation is required"
              // ),
            })}
            onSubmit={(values) => {
              console.log("sharing project ", values, this.state.sharedProject);

              //console.log("pro", this.state, "pro");
              this.callCreateProjectApi(values, this.state.sharedProject);
              this.setState(
                {
                  sharedProjectError: this.state.sharedProject ? false : true,
                },
                () => {
                  //console.log(this.state.sharedProjectError, "error");
                }
              );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                  <DialogContent dividers>
                    <TextField
                      name="title"
                      type="text"
                      size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      fullWidth
                      // label={t("projects.projectDescription")}
                      label={t("projects.newProjectTitle")}
                      value={values.title}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.title &&
                          errors.title ? (
                          // <Trans>CompanyProfile.companyNameRequired</Trans>
                          <Trans>Project title is required</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.title && errors.title
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />


                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.brandTeamsData}
                      style={{ minWidth: "550px" }}

                      getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}
                      renderOption={(option: any) => (
                        option?.photo_blob_signed_id_url?.length >= 0 ?
                          <React.Fragment>
                            <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                            }</span>
                            {option.first_name} {option.last_name}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {option?.name}
                          </React.Fragment>
                      )}
                      // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                      onChange={(e, value) => {
                        //console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          sharedProjectError: value.length > 0 ? false : true

                        }, () => {
                          //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          option?.photo_blob_signed_id_url?.length >= 0 ?
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                            :
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}

                          helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />

                    {this.state.permissionName != 'client' && <Grid item xs={12} style={{
                      marginTop: "15px"
                    }}>
                      <FormControl variant="outlined" style={{
                        marginLeft: 0,
                        minWidth: "100%",
                      }}>
                        <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'black', fontSize: 16, background: "#fff" }}>Template</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.tempeleteChoosen}
                          fullWidth
                          onChange={(e) => {
                            // this.setCategory(item)
                            this.setState({ tempeleteChoosen: e.target.value })
                          }}
                          label="Template"
                          name="template"
                        >

                          {this.state.tempeleteListing.length > 0 && this.state.tempeleteListing.map((item: any, index: any) => (

                            <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }}
                            //  onClick={(e) => {
                            //   // this.setCategory(item)
                            //   this.handleChangeBrand(item.id)
                            // }} 
                            >{item.attributes.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>}
                    <TextField
                      name="projectDescription"
                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      size="small"
                      className={classes.confirmInput}
                      fullWidth
                      label={t("projects.projectDescription")}
                      // label={t(
                      //   "projects.ProjectDescription"
                      // )}
                      value={values.projectDescription}
                      variant="outlined"

                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.createProject")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>
        {/* invite team mem dialog */}

        <Dialog onClose={this.handleCloseModalProject} aria-labelledby="customized-dialog-title" open={this.state.openProjectProperties} >
          <div style={{ width: "600px", height: "570px", padding: "40px", fontFamily: primaryFont }}>
            <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px", padding: "0px 0px", marginBottom: "40px", }}>
              {/* {t("HomePage.photoProduction")} */}
              <div style={{ position: "relative" }}>
                {/* {t("individualCompanyHomeScreen.photoProduction")} */}


                <span style={{ position: "absolute", right: "10px" }} >
                  <CloseIcon onClick={this.handleCloseModalProject} />

                </span>
              </div>


            </DialogTitle>


            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              scrollButtons="off"
              indicatorColor="primary"
              aria-label="full width tabs example"
              textColor="primary"
              centered
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                label={t("individualCompanyHomeScreen.projectOverview")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />
              <Tab
                label={t("individualCompanyHomeScreen.projectDocuments")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />
              <Tab
                label={t("individualCompanyHomeScreen.members")}
                className={classes.tab}
                style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
              />

            </Tabs>


            {this.state.value === 0 &&
              (

                <Formik
                  initialValues={{
                    // email: "",
                    // animal: "",
                    // companyName: "",
                    projectTitle: this.state.selectedProjectData?.attributes?.name,
                    projectDescription: this.state.selectedProjectData?.attributes?.description,

                    //shareTheProjectWithOthers:this.state.sharedProject,
                    //   includeAPersonalMessageWithYourInvitation: ''

                  }}

                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    // projectDescription: Yup.string()
                    //   .required("projectDescription is required"),


                    // includeAPersonalMessageWithYourInvitation: Yup.string()
                    //   .required("Include A Personal MessageWith Your Invitation is required"),

                  })}
                  onSubmit={(values) => {
                    //console.log(values);
                    this.callUpdateProjectPropertiesApi(values, this.state.sharedProject)
                    // callUpdateProjectPropertiesApi
                    //console.log(this.state.sharedProject, "pro")

                    // this.setState({
                    //   sharedProjectError: this.state.sharedProject ? true : false
                    // }, () => {
                    //   console.log(this.state.sharedProjectError, "error")
                    // })
                    // this.goToConfirmationAfterPasswordChange(values);
                    // this.props.nextStep(values);

                  }}

                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    isValid,
                    setFieldValue,
                    handleBlur,
                    setFieldTouched,
                    isSubmitting,
                    handleSubmit,
                  }) => {
                    //console.log(values, "shdfsdiuf", this.state.sharedProject);

                    return (

                      <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                        <DialogContent>
                          <TextField

                            name="Project Title"
                            type="text"
                            size="small"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            fullWidth

                            label={t("projects.projectTitle")}
                            // label="Project Title"
                            value={values.projectTitle}
                            className={classes.confirmInput}
                            variant="outlined"

                            onChange={handleChange("projectTitle")}
                            onBlur={handleBlur}
                          />


                          <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            options={this.state.brandTeamsData}
                            style={{ minWidth: "550px" }}
                            getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}

                            onInputChange={(e, value) => {
                              this.callGetProjectPropertiesAccountListApi(value)

                            }}
                            renderOption={(option: any) => (
                              option?.photo_blob_signed_id_url?.length >= 0 ?
                                <React.Fragment>
                                  <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                                    <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                                    <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                                  }</span>
                                  {option.first_name} {option.last_name}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {option?.name}
                                </React.Fragment>
                            )}
                            // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                            onChange={(e, value) => {
                              //console.log(e, value);
                              this.setState({
                                sharedProject: value,
                                // sharedProjectError: value.length > 0 ? false : true

                              }, () => {
                                //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                              })
                              // this.callGetProjectPropertiesAccountListApi(value)
                            }}

                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                option?.photo_blob_signed_id_url?.length >= 0 ?
                                  <Chip
                                    className={classes.projectChips}
                                    label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                      <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                      <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                                    </Typography>}
                                    {...getTagProps({ index })}

                                    style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                  />
                                  :
                                  <Chip
                                    className={classes.projectChips}
                                    label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                      {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                      <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                                    </Typography>}
                                    {...getTagProps({ index })}

                                    style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                  />
                              ))
                            }
                            // renderTags={(value, getTagProps) =>
                            //   value.map((option, index) => (
                            //     <Chip
                            //       className={classes.projectChips}
                            //       label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                            //         <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.attributes?.photo_blob_signed_id_url} /> </span>
                            //         <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.attributes?.first_name}</span>
                            //       </Typography>}
                            //       {...getTagProps({ index })}

                            //       style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            //     />
                            //   ))
                            // }

                            renderInput={(params): any => (
                              <TextField
                                {...params}
                                label={t("teams.addMembers")}
                                name="addMembers"
                                placeholder={t("teams.addMembersPlaceholder")}

                                // helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                                // error={
                                //   this.state.sharedProjectError
                                // }

                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                className={classes.confirmInput}
                                variant="outlined"

                              />
                            )}
                          />
                          {/* <Autocomplete
                          multiple
                          id="fixed-tags-demo"
                          options={top100Films}
                          style={{ minWidth: "550px" }}
                          getOptionLabel={(option: any) => option.title}
                          defaultValue={[top100Films[0]]}
                          onChange={(e, value) => {
                            console.log(e, value);
                            this.setState({
                              sharedProject: value,
                              sharedProjectError: value.length > 0 ? false : true

                            }, () => {
                              console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                            })
                          }}
                          renderTags={(value: any, getTagProps: any) =>
                            value.map((option: any, index: any) => (
                              <Chip
                                className={classes.projectChips}
                                label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                  <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option.image} /> </span>
                                  <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option.title}</span>
                                </Typography>}
                                {...getTagProps({ index })}

                                style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                              />
                            ))
                          }

                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Share the project with others"
                              name="shareTheProjectWithOthers"


                              helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                              error={
                                this.state.sharedProjectError
                              }

                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              className={classes.confirmInput}
                              variant="outlined"

                            />
                          )}
                        /> */}
                          {/* <TextField
        id="outlined-multiline-static"
        label={t("projects.shareTheProjectWithOthers")}
        name="shareTheProjectWithOthers"
        fullWidth
        InputProps={{ classes: { root: classes.inputRoot } }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused
          }
        }}
        value={values.shareTheProjectWithOthers}
        variant="outlined"
        className={classes.confirmInput}
        helperText={
          touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
            ? (
              <Trans>
                CompanyProfile.billingEmailRequired
              </Trans>
            )
            : ""
        }
        error={
          touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
            ? true
            : false
        }
        onChange={handleChange}
        onBlur={handleBlur}
      /> */}


                          <TextField
                            name="Project Description"
                            multiline
                            rows={5}
                            // InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            size="small"
                            className={classes.confirmInput}
                            fullWidth
                            placeholder={t("teams.descriptionsPlaceholder")}
                            // label={t("projects.Includeapersonalmessagewithyourinvitation")}
                            label={t("projects.projectDescription")}
                            value={values.projectDescription}
                            variant="outlined"

                            onChange={handleChange("projectDescription")}
                            onBlur={handleBlur}
                            style={{ marginTop: "40px", }}
                          />





                        </DialogContent>
                        <DialogActions style={{ justifyContent: "flex-start" }}>
                          {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
Save changes
</Button> */}
                          {/* <Button

                          style={{ backgroundColor: colorToBeChanged, color: "white", marginLeft: "15px" }}
                          type="submit"
                          variant="contained"
                        >
                          {t("projects.createProject")}
                        </Button>
                        <Button
                          className={classes.button}
                          style={{ backgroundColor: "white", color: "black" }}
                          type="submit"
                          variant="contained"
                        >
                          {t("projects.cancel")}
                        </Button> */}

                          <Button
                            style={{
                              backgroundColor: colorToBeChanged,
                              color: "white",
                              marginLeft: "15px",
                              width: "100px",
                              height: "48px",
                              marginTop: "15px"
                            }}
                            // style={{ backgroundColor: colorToBeChanged, color: "white" }}
                            type="submit"
                            // onClick={() => { this.handleUpdateDuplicateProjectProperties(values.projectTitle, values.projectDescription) }}
                            variant="contained"
                            fullWidth
                          >
                            Update
                          </Button>

                        </DialogActions>
                      </form>


                    )
                  }}
                />

              )
            }
            {
              this.state.value === 1 &&
              (
                <div style={{ marginBottom: "2rem", minWidth: "600px", fontFamily: primaryFont }} >
                  <div className={classes.projectDocuments} >
                    <p style={{ fontSize: "12px" }}>{t("projects.documentDetail")}</p>
                    <p style={{ marginLeft: "60%", fontSize: "12px" }}>{t("projects.dateDetail")}</p>

                  </div>
                  {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.map((media: any) => (
                    <div className={classes.projectDocument} style={{}} >
                      <span style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                        <img src={imageIcon} style={{ height: "14px", width: "18px", padding: "15px 15px 15px 0" }} />
                        <div>
                          <Link
                            //  href= "/Home/MediaAssets/:brandId/:mediaAssetId/:AssetId"
                            href={`/Home/MediaAssets/${this.state.projectMediaAssetsDetail[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.projectMediaAssetsDetail[0]?.attributes?.name + '/' + media?.attributes?.name + '/' + media.id}
                            // href = {`/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                            underline="none"
                          >
                            <p className={classes.fileName} style={{ color: secondaryColorToBeChanged }}>{media.attributes?.name}</p>
                          </Link>
                          <p className={classes.fileSize}>{media.attributes?.size}</p>
                        </div>
                      </span>
                      <span>
                        <p className={classes.date}>{media.attributes?.updated_at},{t("projects.uploadedBy")} {media.attributes?.creator?.data?.attributes?.first_name} {media.attributes?.creator?.data?.attributes?.last_name}</p>
                      </span>
                    </div >
                  ))}

                  {/* {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.length > 0 ? <Button
                    className={classes.button}
                    style={{
                      backgroundColor: "#173e59",
                      color: "white",
                      bottom: "-300px",
                      right: "-390px"
                    }}
                    type="submit"
                    variant="contained"
                    onClick={this.handleCloseModal}
                    onClick={(e: any) => (window.location.href = `/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                  >
                    Go to Assets folder
                  </Button> :
                    null
                  } */}

                </div>

              )
            }
            {
              this.state.value === 2 &&
              (
                <div style={{ minWidth: "600px", padding: "15px", fontFamily: primaryFont }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ fontSize: "12px", }}>
                      {t("projects.memberDetail")}
                    </p>
                    <p style={{ position: "absolute", right: "150px", fontSize: "12px", width: '108px' }}>
                      Role
                    </p>

                  </div>
                  {this.state.selectedProjectsAccountsData && this.state.selectedProjectsAccountsData.map((account: any) => (
                    <>
                      <div className={classes.members} style={{ marginLeft: "0px" }} >
                        <Avatar alt="Remy Sharp" src={account?.attributes?.photo_blob_signed_id_url} />
                        <div style={{ marginLeft: "10px" }} >
                          <span>
                            <p style={{ fontSize: "14px", textAlign: 'left', margin: "0" }}>{account?.attributes?.first_name}</p>
                          </span>
                          <span>
                            <p style={{ fontSize: "10px" }} > {account?.attributes?.email}</p>
                          </span>
                        </div>
                        <span style={{ fontSize: "12px", position: "absolute", right: "150px", width: '108px', textTransform: 'uppercase' }}>
                          <p> {account?.attributes?.role?.data?.attributes?.permission}</p>
                        </span>

                        {!((this.state.permissionName == "client" && this.state.permissions === "viewer") || (this.state.permissionName == "albertbauer" && this.state.permissions === "viewer"))

                          // || this.state.permissions === "editor" ||
                          //   this.state.permissions === "approver" || this.state.permissions === "brand_manager" || this.state.permissions === "superadmin"

                          ?
                          <>
                            <IconButton aria-label="Delete" style={{ position: "absolute", right: "50px" }} onClick={(e) => {
                              this.callDeleteAccountProjectsApi(account.id, this.state.selectedProjectData.id)
                            }}>
                              <CloseIcon />
                            </IconButton>
                          </>
                          : ""}
                      </div>
                    </>
                  ))}
                  {/* <div className={classes.members} >
                  <Avatar alt="Remy Sharp" src={userImage} />
                  <span className={classes.modalUserName}>
                    <p style={{ fontSize: "14px" }}>Myrtle Swift</p>
                  </span>
                  <span className={classes.modalUserEmail}>
                    <p style={{ fontSize: "10px" }}> Juvenal.Kovacek@gmail.com</p>

                  </span>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "10px" }}
                    type="submit"
                    variant="contained"
                  >
                    {t("projects.view")}
                  </Button>
                </div>
                <div className={classes.members} >
                  <Avatar alt="Remy Sharp" src={userImage} />
                  <span className={classes.modalUserName}>
                    <p style={{ fontSize: "14px" }}>Myrtle Swift</p>
                  </span>
                  <span className={classes.modalUserEmail}>
                    <p style={{ fontSize: "10px" }}> Juvenal.Kovacek@gmail.com</p>

                  </span>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "10px" }}
                    type="submit"
                    variant="contained"
                  >
                    {t("projects.edit")}
                  </Button>
                </div>
                <div className={classes.members} >
                  <Avatar alt="Remy Sharp" src={userImage} />
                  <span className={classes.modalUserName}>
                    <p style={{ fontSize: "14px" }}>Myrtle Swift</p>
                  </span>
                  <span className={classes.modalUserEmail}>
                    <p style={{ fontSize: "10px" }}> Juvenal.Kovacek@gmail.com</p>

                  </span>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "10px" }}
                    type="submit"
                    variant="contained"
                  >
                    {t("projects.view")}
                  </Button>
                </div>
                <div className={classes.members} >
                  <Avatar alt="Remy Sharp" src={userImage} />
                  <span className={classes.modalUserName}>
                    <p style={{ fontSize: "14px" }}>Myrtle Swift</p>
                  </span>
                  <span className={classes.modalUserEmail}>
                    <p style={{ fontSize: "10px" }}> Juvenal.Kovacek@gmail.com</p>

                  </span>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "10px" }}
                    type="submit"
                    variant="contained"
                  >
                    {t("projects.view")}
                  </Button>
                </div>
                <div className={classes.members} style={{ marginBottom: "40px" }}>
                  <Avatar alt="Remy Sharp" src={userImage} />
                  <span className={classes.modalUserName}>
                    <p style={{ fontSize: "14px" }}>Myrtle Swift</p>
                  </span>
                  <span className={classes.modalUserEmail}>
                    <p style={{ fontSize: "10px" }}> Juvenal.Kovacek@gmail.com</p>

                  </span>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "10px" }}
                    type="submit"
                    variant="contained"
                  >
                    {t("projects.edit")}
                  </Button>
                </div> */}
                </div>
              )
            }
          </div>
        </Dialog>


        <Dialog
          onClose={this.handleCloseModalTeam}
          aria-labelledby="customized-dialog-title"
          open={this.state.inviteTeamMembersDialog}
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {t("ApprovalRequest.inviteTeamMember")}
            {/* Invite Team Members */}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModalTeam} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{


            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({

            })}
            onSubmit={(values) => {
              //console.log(values);

              //console.log("pro", this.state, "pro");
              // this.callUpdateProjectsApi(values,this.state.sharedProject);
              this.callUpdateProjectPropertiesApi(values, this.state.sharedProject)
              // this.setState(
              //   {
              //     sharedProjectError: this.state.sharedProject ? false : true,
              //   },
              //   () => {
              //     console.log(this.state.sharedProjectError, "error");
              //   }
              // );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont, marginBottom: "15px" }}>
                  <DialogContent dividers>

                    {/* <ReactS3Uploader
                      // className={uploaderClassName}
                      getSignedUrl={this.getSignedUrl}
                      accept="image/*"
                      // onProgress={onProgress}
                      // onError={onError}
                      // onFinish={onFinish}
                      uploadRequestHeaders={{
                        'x-amz-acl': 'public-read'
                      }}
                      s3path="/uploads/"
                      contentDisposition="auto"
                    /> */}

                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.brandTeamsData}
                      style={{ minWidth: "550px" }}

                      getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}
                      renderOption={(option: any) => (
                        option?.photo_blob_signed_id_url?.length >= 0 ?
                          <React.Fragment>
                            <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                            }</span>
                            {option.first_name} {option.last_name}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {option?.name}
                          </React.Fragment>
                      )}
                      // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                      onChange={(e, value) => {
                        //console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          // sharedProjectError: value.length > 0 ? false : true

                        }, () => {
                          //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          option?.photo_blob_signed_id_url?.length >= 0 ?
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                            :
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}

                          // helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                          // error={
                          //   this.state.sharedProjectError
                          // }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />

                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                      }}
                      type="submit"
                      variant="contained"
                    >Invite
                      {/* {t("projects.inviteTeamMembers")} */}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      // type="submit"
                      variant="contained"
                      onClick={this.handleCloseModalTeam}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>

        {/* Notification Dialog Start */}
        <Dialog
          open={this.state.setNotification}
          onClose={this.handleCloseNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ borderRadius: "12px" }}
        >
          <DialogTitle style={{
            fontFamily: "Work Sans !important",
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828"
          }} id="alert-dialog-title" > {t("HomeScreen.notificationPreferences")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseNotification} />
            </span>
          </DialogTitle>
          <DialogContent style={{ maxWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container style={{ marginLeft: "9px" }}>
                <Grid item xs={2} style={{ marginBottom: "20px", paddingLeft: "5px" }}>
                  <img src={notificationPreferenceSettings} />
                </Grid>
                <Grid item xs={10} >
                  <Grid container spacing={0} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.12px",
                        color: "#282828"
                      }} >{t("NotificationSettings.notifications")}</Typography></Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px", marginTop: "7px" }}><Typography variant="body2" style={{
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }} >{t("NotificationSettings.notificationsTitle")}</Typography></Grid>

                  </Grid>

                </Grid>
              </Grid>
              <Grid container spacing={0}>

                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Checkbox
                        color="primary"
                        style={{ paddingTop: "14px" }}
                        name="task_email_notifications"
                        disabled={this.state.mute_notifications}
                        inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.task_email_notifications} onChange={(e) => { this.setNotications(e) }} />
                      <Typography
                        variant={"body2"}
                        style={{
                          display: "inline",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          color: "#282828"
                        }}
                      >{t("NotificationSettings.sendEmailNotification")}</Typography>

                    </Grid>

                    <Grid item xs={12} style={{ marginLeft: "42px" }}>
                      <FormControl style={{ minWidth: 200 }} variant="outlined" >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="task_notification_email_frequency"
                          onChange={(e) => { this.setNotications(e) }}
                          value={this.state.task_notification_email_frequency}
                          disabled={this.state.mute_notifications == true || this.state.task_email_notifications !== true ? true : false}
                          style={{ height: 40 }}

                        >
                          <option style={{
                            fontFamily: "Work Sans",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="immediately">
                            immediately
                          </option>
                          <option style={{
                            fontFamily: "Work Sans",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="once per day">
                            once per day
                          </option>

                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                  <Checkbox
                    color="primary"
                    style={{ paddingTop: "14px" }}
                    inputProps={{ 'aria-label': 'primary checkbox' }} value={this.state.tagged_email_notifications} checked={this.state.tagged_email_notifications}
                    name="tagged_email_notifications"
                    disabled={this.state.mute_notifications}
                    // value={age}
                    //onChange={handleChange}
                    onChange={(e) => { this.setNotications(e) }}
                  />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px ",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >{t("NotificationSettings.sendEmailCommentsNotification")}</Typography>
                </Grid>


                <Grid item xs={12}>
                  <Checkbox
                    color="primary"
                    style={{ paddingTop: "14px" }}
                    name="mute_notifications"
                    onChange={(e) => { this.setNotications(e) }}
                    disabled={this.state.task_email_notifications == true || this.state.tagged_email_notifications == true ? true : false}
                    inputProps={{ 'aria-label': 'primary checkbox' }} value={this.state.mute_notifications} checked={this.state.mute_notifications} />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >{t("NotificationSettings.muteNotification")}</Typography>
                </Grid>
              </Grid>




            </DialogContentText>
          </DialogContent>

        </Dialog>
        {/* Dupilcate Dialog */}
        <Dialog
          onClose={this.handleCloseDuplicateDialog}
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.setShowDuplicateDialog}
        >
          <DialogTitle id="customized-dialog-title" style={{ marginRight: "15px" }}>

            {t("ApprovalRequest.duplicateParagraph")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseDuplicateDialog} />
            </span>
          </DialogTitle>


          <DialogActions style={{ justifyContent: "flex-start" }}>

            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="submit"
              variant="contained"
              onClick={(e) => {
                this.callCreateProjectDuplicationApi(e)

              }}
            >Yes

            </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}

              variant="contained"
              onClick={this.handleCloseDuplicateDialog}
            >
              No
            </Button>
          </DialogActions>


        </Dialog>



        {/* Request Quote Dialog */}
        <Dialog
          onClose={this.handleCloseRequestQuoteDialog}
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.setShowRequestQuoteDialog}
        // style={{width: "55%"}}
        >
          <DialogTitle id="customized-dialog-title" style={{ marginRight: "15px", padding: "14px" }}>
            {t("ApprovalRequest.requestQuoteParagraph")}
            {/* Requesting quote will make all the drafted task briefings to be submitted and is send for quote. Are you sure? */}
            <span style={{ position: "absolute", right: "10px", top: "8px" }}>
              <CloseIcon onClick={this.handleCloseRequestQuoteDialog} />
            </span>
          </DialogTitle>


          <DialogActions style={{ justifyContent: "flex-start" }}>

            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="submit"
              variant="contained"
              onClick={(e) => {
                this.callCreateRequestQuotationForProjectApi(e);
                this.handleCloseRequestQuoteDialog()
              }}
            >Yes

            </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}

              variant="contained"
              onClick={this.handleCloseRequestQuoteDialog}
            >
              No
            </Button>
          </DialogActions>


        </Dialog>
        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter1}
          // keepMounted
          open={Boolean(this.state.anchorElFilter1)}
          onClose={() => {
            this.handleFilterClose2();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >
          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="projectFilter" value={this.state.projectFilterValue} onChange={this.handleFilterChange2} >
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name0" control={<Radio color="primary" />} label="Name (A-Z)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name1" control={<Radio color="primary" />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio color="primary" />} label="Created At" />
                {/* <FormControlLabel value="created_at0" control={<Radio color="primary" />} label="Created At (oldest)" /> */}
                <FormControlLabel value="updated_at1" control={<Radio color="primary" />} label="Updated At" />
                {/* <FormControlLabel value="updated_at0" control={<Radio color="primary" />} label="Updated At (oldest)" /> */}
              </RadioGroup>
            </Grid>
          </Grid>
        </Menu>

        {/* <Dialog
        open={this.state.setOpen}
        onClose={this.handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseModal} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleCloseModal} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}

        <Dialog
          open={this.state.deleteProjectDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.deleteProjectDialog}
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteProjectDialog: false, anchorEl: null }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                  // onClick={() => { this.onClickSubmitReview(this.state.fileId) }}
                  // onClick={() => { this.setState({ submitReviewPopUp : false }) }}
                  onClick={(e) => this.callDeleteProjectsApi(e)}
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteProjectDialog: false, anchorEl: null }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>


      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(Projects));
// Customizable Area Start

// Customizable Area End