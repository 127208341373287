import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Breadcrumbs,
  AppBar,
  Tab,
  Tabs,
  Input,
  InputAdornment,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  Select,
  Menu,
  MenuItem,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useParams } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation, Trans } from "react-i18next";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  projectName: any;
  brandName: any;
  taskCount: any;
  creator: any;
  email: any;
  status: any;
  projectManager: any;
  projectManagerEmail: any;
  dueDate: any;
  projectId: any;
  taskData: any;
  totalPrice: any;
  setTaskPage: number;
  totalTaskPages: number;
  anchorEl: any;
  selectedTask: any;
  accountsData: any;
  brandId: any;
  sortDirectionTask: any;
  sortDirectionAssignee: any;
  sortDirectionOwner: any;
  sortDirectionStatus: any;
  sortDirectionPrice: any;
  sortDirectionDate: any;
  sortValue: any
}

interface SS {
  id: any;
}

const styles = {
  templateHeadings: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1a76b0",
  },
  templateHeadingsData: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    fontWeight: 400,
    marginLeft: "10px",
    wordWrap: "break-word"
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  brandNameData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
    marginLeft: "20px",
  },
  chipLabel: {
    marginBottom: "5px",
  },
  dialogPaper: {
    width: "528px",
    height: "auto",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  inputRoot: {
    width: "100%",
    height: "32px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

class ProjectDetails extends BlockComponent<Props, S, SS> {
  getProjectApiCallId: any;
  getTasksApiCallId: any;
  getTotalPriceApiCallId: any;
  createTaskBriefingApiCallId: any;
  getRolesApiCallId: any;
  getUserAccountsApiCallId: any;
  updateProjectManagerApiCallId: any;
  callGetAccountListApiId: any;

  getSortTasksApiCallId: any

  taskDeleteID: any
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      projectName: "",
      brandName: "",
      taskCount: "",
      creator: "",
      email: "",
      status: "",
      projectManager: "",
      projectManagerEmail: "",
      dueDate: "",
      projectId: "",
      taskData: [],
      totalPrice: "",
      setTaskPage: 1,
      totalTaskPages: 0,
      anchorEl: null,
      selectedTask: null,
      accountsData: [],
      brandId: 0,
      sortDirectionTask: "asc",
      sortDirectionAssignee: "asc",
      sortDirectionOwner: "asc",
      sortDirectionStatus: "asc",
      sortDirectionPrice: "asc",
      sortDirectionDate: "asc",
      sortValue: ""
    };
  }

  async componentDidMount() {
    var str = window.location.href;
    var projectId = str.split("/")[5];
    this.setState({ projectId: projectId });
    await this.getProjectData(projectId);
    await this.getTasks(projectId);
    await this.getTotalPrice(projectId);
  }

  exportList = () => {
    var url = `${currentUrl
      }/bx_block_data_import_export_csv/task_exports?project_id=${this.state.projectId
      }&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  getProjectData = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTotalPrice = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalPriceApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sortTableHead = (value: any, direction: any) => {
    console.log("sortingg", value, direction);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSortTasksApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks?project_id=${this.state.projectId}&page=${this.state.setTaskPage}&sort_by=${value}&sort_order=${direction}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTasks = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks?project_id=${id}&page=${this.state.setTaskPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getProjectApiCallId) {
        if (responseJson.data) {
          if (responseJson.data.attributes) {

            this.getRoles();
            const userDetails: any = localStorage.getItem("userInfo")

            //this.callGetAccountListApi('');
            this.setState({
              projectName: responseJson.data.attributes.name,
              brandId: responseJson?.data?.attributes?.brand?.data?.id,
              brandName:
                responseJson.data.attributes.brand.data.attributes.name,
              taskCount: "",
              creator:
                responseJson.data.attributes.creator.data !== null
                  ? `${responseJson.data.attributes.creator.data.attributes
                    .first_name
                  } ${responseJson.data.attributes.creator.data.attributes
                    .last_name
                  }`
                  : "NA",
              email:
                responseJson.data.attributes.creator.data !== null
                  ? responseJson.data.attributes.creator.data.attributes.email
                  : "NA",

              status: responseJson.data.attributes.status,
              projectManager:
                responseJson.data.attributes.project_manager.data !== null &&
                userDetails?.role?.data?.attributes?.name === 'superadmin'
                  ? responseJson?.data?.attributes?.project_manager_id
                  : responseJson.data.attributes.project_manager.data !== null
                    ? responseJson.data.attributes.project_manager.data.attributes.first_name
                     + " " +  responseJson.data.attributes.project_manager.data.attributes.last_name
                    : "NA",
              projectManagerEmail:
                responseJson.data.attributes.project_manager.data !== null
                  ? responseJson.data.attributes.project_manager.data.attributes
                    .email
                  : "NA",
              dueDate:
                responseJson.data.attributes.estimated_end_date !== null
                  ? responseJson.data.attributes.estimated_end_date
                  : "NA",
            });
          }
        }
      } else if (apiRequestCallId === this.getTasksApiCallId) {
        console.log(responseJson);
        if (responseJson.tasks) {
          if (responseJson.tasks.data) {
            if (responseJson.tasks.data !== null) {
              let response = responseJson.tasks.data;
              let tempArr = [] as any;
              response.map((_data: any) => {
                tempArr.push({
                  id: _data.id,
                  taskName: _data.attributes.title,
                  assignee:
                    _data.attributes.assignee.data != null
                      ? `${_data.attributes.assignee.data.attributes.first_name
                      } ${_data.attributes.assignee.data.attributes.last_name
                      }`
                      : "NA",
                  owner: _data.attributes.requestor.data != null
                  ? `${_data.attributes.requestor.data.attributes.first_name
                  } ${_data.attributes.requestor.data.attributes.last_name
                  }`
                  : "NA",
                  status: _data.attributes.ultimate_state,
                  templateCreated: _data.attributes.template_created,
                  updated_at:
                    _data.attributes.updated_at !== null
                      ? _data.attributes.updated_at
                      : "NA",
                  price:
                    _data.attributes.quotation.data !== null
                      ? _data.attributes.quotation.data.attributes.price
                      : _data.attributes.price_auto_generated !== null
                        ? _data.attributes.price_auto_generated
                        : "NA",
                  briefing: _data.attributes.briefing.data,
                });
              });
              let totalTaskPages = this.state.totalTaskPages;
              this.setState({
                taskData: tempArr,
                taskCount: responseJson.total_count,
                totalTaskPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalTaskPages,
              });
            }
          }
        }
      }
      else if (apiRequestCallId === this.getSortTasksApiCallId) {
        console.log(responseJson);
        if (responseJson.tasks) {
          if (responseJson.tasks.data) {
            if (responseJson.tasks.data !== null) {
              let response = responseJson.tasks.data;
              let tempArr1 = [] as any;
              response.map((_data: any) => {
                tempArr1.push({
                  id: _data.id,
                  taskName: _data.attributes.title,
                  assignee:
                    _data.attributes.assignee.data != null
                      ? `${_data.attributes.assignee.data.attributes.first_name
                      } ${_data.attributes.assignee.data.attributes.last_name
                      }`
                      : "NA",
                      owner: _data.attributes.requestor.data != null
                      ? `${_data.attributes.requestor.data.attributes.first_name
                      } ${_data.attributes.requestor.data.attributes.last_name
                      }`
                      : "NA",
                  status: _data.attributes.ultimate_state,
                  templateCreated: _data.attributes.template_created,
                  updated_at:
                    _data.attributes.updated_at !== null
                      ? _data.attributes.updated_at
                      : "NA",
                  price:
                    _data.attributes.quotation.data !== null
                      ? _data.attributes.quotation.data.attributes.price
                      : _data.attributes.price_auto_generated !== null
                        ? _data.attributes.price_auto_generated
                        : "NA",
                  briefing: _data.attributes.briefing.data,
                });
              });
              let totalTaskPages = this.state.totalTaskPages;
              this.setState({
                taskData: tempArr1,
                taskCount: responseJson.total_count,
                totalTaskPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalTaskPages,
              }, () => console.log("tsadkdd", this.state.taskData));
            }
          }
        }
      }
      if (apiRequestCallId === this.taskDeleteID) {
        if (message?.properties?.RestAPIResponceSuccessMessage) {
          toast.success("Task Deleted Successfully")
          var str = window.location.href;
          var projectId = str.split("/")[5];
          this.setState({ projectId: projectId });
          this.getProjectData(projectId);
          this.getTasks(projectId);
          this.getTotalPrice(projectId);
          this.handleIconClose()
        }
  
      }

      else if (apiRequestCallId === this.getTotalPriceApiCallId) {
        console.log(responseJson);
        if (responseJson.data) {
          this.setState({
            totalPrice:
              responseJson.data.attributes.price !== null
                ? responseJson.data.attributes.price
                : "0",
          });
        }
      } 
      
      else if (apiRequestCallId === this.createTaskBriefingApiCallId) {

        console.log(responseJson);
        if (responseJson?.data) {

          toast.success("Template created successfully");
        } else if (responseJson?.errors?.[0].task_briefing) {
          toast.error(responseJson?.errors?.[0].task_briefing);
        }else if(responseJson?.errors[0].name){
          toast.error(responseJson?.errors[0].name);
        }
      } 
      else if (apiRequestCallId === this.getRolesApiCallId) {

        console.log(responseJson);
        if (responseJson?.ab_user_roles?.length > 0) {
          var roleArr = [];
          for (var i = 0; i < responseJson?.ab_user_roles.length; ++i) {
            roleArr.push(responseJson?.ab_user_roles[i].id);
          }
          this.getUserAccounts(roleArr.join());
        }
      } else if (apiRequestCallId === this.getUserAccountsApiCallId) {

        console.log(responseJson);
        if (responseJson?.accounts?.data?.length > 0) {
          this.setState({
            accountsData: responseJson?.accounts?.data
          })
        }
      } else if (apiRequestCallId === this.updateProjectManagerApiCallId) {

        console.log(responseJson);
        if (responseJson?.errors?.length > 0) {
          toast.error(responseJson?.errors?.[0]?.project_manager)
        } else {
          toast.success("Project Manager updated successfully");
        }
      } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {

        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          if (apiRequestCallId != null) {
            /// search live

            if (apiRequestCallId && responseJson) {
              this.setState({
                accountsData: responseJson?.accounts?.data
              })
            }
          }
        } else if (responseJson.errors) {
          toast.error(errorResponse.errors[0]);
        } else if (responseJson.error) {
          toast.error(errorResponse.error);
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
  }

  handleTaskPagination = (event: any, value: number) => {
    this.setState({ setTaskPage: value }, () => {
      this.getTasks(this.state.projectId);
    });
  };

  handleIconClick = (event: any, selectedTask: any) => {
    // if (selectedTask?.status?.toUpperCase() != 'New'.toUpperCase()) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedTask: selectedTask
      });
    // }
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
      selectedTask: null
    });
  };

  createTemplateTask = (task: any) => {

    console.log(this.state.selectedTask);
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const httpBody = { task_briefing_id: parseInt(this.state.selectedTask?.briefing?.id) };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createTaskBriefingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_project_portfolio/task_briefings/create_briefing_template");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRoles = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_roles_permissions/roles`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteTask = () => {
    const userDetails = localStorage.getItem("userInfo")
    const header = {
      token: localStorage.getItem("token")
    };
    const requestShowTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.taskDeleteID = requestShowTaskQuotationMessage.messageId;
    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks/" + this.state.selectedTask?.id
    );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestShowTaskQuotationMessage.id, requestShowTaskQuotationMessage);

  }

  getUserAccounts = (roles: string) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    //&brand_id=${this.state.brandId}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?page=${1}&per_page=${50}&role_ids=${roles}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //account  index api
  callGetAccountListApi(nameofUser: any) {

    const userDetails: any = localStorage.getItem("userInfo")
    var brand_id: any = JSON.parse(userDetails).brand_id;

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;

    if (nameofUser === '') {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts?page=${1}&per_page=${20}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts?page=${1}&per_page=${21}`
          //first_name=&last_name&email&username&brand_id`
        );
      }
    } else {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts?page=${1}&&user=${nameofUser}&per_page=${22}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts?page=${1}&&user=${nameofUser}&per_page=${23}`
          //first_name=&last_name&email&username&brand_id`
        );
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleProjectManagerChange = (event: any) => {

    console.log(event.target.value);
    this.setState({
      projectManager: event.target.value
    }, () => {
      this.updateProjectManager();
    })
  }

  updateProjectManager = () => {

    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = { "project_manager_id": parseInt(this.state.projectManager) };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProjectManagerApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_project_portfolio/projects/" + parseInt(this.state.projectId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  render() {
    const { classes, t } = this.props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    return (
      <>
        <Grid
          container
          style={{ paddingLeft: "10px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Dashboard
              </Typography>

              <Link
                to="/AdminConsole/Projects"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Projects
              </Link>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {this.state.projectName}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {this.state.projectName}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        Export
                      </Typography>
                    </Button>
                  </Grid>
                  {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
                    <Fragment>
                      <Grid item style={{ marginRight: "20px" }}>
                        <Input
                          id="input-with-icon-adornment"
                          style={{
                            border: "1px solid #e8edf4",
                            background: "#ffffff",
                            borderBottom: "1px solid #e8edf4",
                            boxSizing: "border-box",
                            borderRadius: "8px",
                            outline: "none",
                          }}
                          disableUnderline={true}
                          startAdornment={
                            <InputAdornment
                              position="start"
                              style={{ marginLeft: "8px" }}
                            >
                              <SearchIcon
                                style={{
                                  height: "35px",
                                  width: "20px",
                                  opacity: 0.4,
                                }}
                              />
                            </InputAdornment>
                          }
                          placeholder="search for user"
                        />
                      </Grid>
                      <Grid item style={{ marginRight: "20px" }}>
                        <Button
                          style={{
                            backgroundColor: "#173e59",
                            border: "1px solid #173e59",
                            width: "88px",
                            height: "32px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "Work Sans",
                              letterSpacing: 0,
                              color: "#FFFFFF",
                              textTransform: "uppercase",
                              opacity: 1,
                            }}
                          >
                            Search
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "20px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  borderTop: "1px solid #F1F1F1",
                  borderBottom: "1px solid #F1F1F1",
                  padding: "20px 0 20px 0",
                }}
              >
                <Grid container direction="row">
                  <Grid item xs={1}>
                    <FolderIcon
                      style={{
                        color: "#1a76b0",
                        height: "60px",
                        width: "60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.projectName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.brand")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.brandName}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.tasks")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.taskCount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                      >
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.creator")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.creator}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.email")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.status")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Chip
                          style={{
                            height: "23px",
                            width: "80px",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #1a76b0",
                            marginLeft: "10px",
                          }}
                          classes={{
                            label: classes.chipLabel,
                          }}
                          label={
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontSize: "10px",
                              }}
                            >
                              {this.state.status}
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.projectManager")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {userInfo?.role?.data?.attributes?.name === 'superadmin' ?
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Age"
                            value={this.state.projectManager}
                            disableUnderline={true}
                            style={{
                              backgroundColor: "#FFFFFF",
                              border: "1px solid #1a76b0",
                              width: "152px",
                              height: "32px",
                              fontSize: "12px",
                              paddingLeft: "5px",
                            }}
                            onChange={(e) => this.handleProjectManagerChange(e)}
                          >
                            <MenuItem value="NA">NA</MenuItem>
                            {
                              this.state.accountsData.map((item: any, index: any) => {
                                return (
                                  <MenuItem value={item?.id}>{item?.attributes?.first_name}</MenuItem>
                                );
                              })
                            }
                          </Select>
                        :
                          <Typography
                            variant="body2"
                            className={classes.templateHeadingsData}
                          >
                            {" "}
                            {this.state.projectManager}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.email")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.projectManagerEmail}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.dueDate")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.dueDate}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <TableContainer
                style={{
                  background: "#F5F6FA",
                  paddingLeft: "20px",
                  paddingRight: "10px",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "30%",
                          paddingLeft: "50px",
                        }}

                      >
                        {/* <TableSortLabel
                         active={true}
                         direction="asc"
                         onClick={()=> console.log("click on sort")}
                        
                        > */}
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionTask}
                          onClick={() => {
                            this.setState({ sortValue: "title" }, () => {
                              if (this.state.sortDirectionTask == "asc") {
                                this.setState({ sortDirectionTask: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionTask))
                              }
                              else {
                                this.setState({ sortDirectionTask: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionTask))
                              }
                            })
                          }}

                        >
                          {t("AdminConsole.taskName")}

                        </TableSortLabel>

                        {/* </TableSortLabel> */}

                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "15%",
                        }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionAssignee}
                          onClick={() => {
                            this.setState({ sortValue: "assignee" }, () => {
                              if (this.state.sortDirectionAssignee == "asc") {
                                this.setState({ sortDirectionAssignee: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionAssignee))
                              }
                              else {
                                this.setState({ sortDirectionAssignee: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionAssignee))
                              }
                            })
                          }}

                        >
                          {t("AdminConsole.assignee")}
                        </TableSortLabel>

                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "15%",
                        }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionOwner}
                          onClick={() => {
                            this.setState({ sortValue: "requested_by" }, () => {
                              if (this.state.sortDirectionOwner == "asc") {
                                this.setState({ sortDirectionOwner: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionOwner))
                              }
                              else {
                                this.setState({ sortDirectionOwner: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionOwner))
                              }
                            })
                          }}
                        >
                          {t("AdminConsole.owner")}

                        </TableSortLabel>

                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "15%",
                        }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionStatus}
                          onClick={() => {
                            this.setState({ sortValue: "ultimate_state" }, () => {
                              if (this.state.sortDirectionStatus == "asc") {
                                this.setState({ sortDirectionStatus: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionStatus))
                              }
                              else {
                                this.setState({ sortDirectionStatus: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionStatus))
                              }
                            })
                          }}

                        >
                          {t("AdminConsole.status")}
                        </TableSortLabel>

                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "15%",
                        }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionDate}
                          onClick={() => {
                            this.setState({ sortValue: "updated_at" }, () => {
                              if (this.state.sortDirectionDate == "asc") {
                                this.setState({ sortDirectionDate: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionDate))
                              }
                              else {
                                this.setState({ sortDirectionDate: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionDate))
                              }
                            })
                          }}

                        >
                          Updated Date
                        </TableSortLabel>


                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "10%",
                        }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.state.sortDirectionPrice}
                          onClick={() => {
                            this.setState({ sortValue: "price" }, () => {
                              if (this.state.sortDirectionPrice == "asc") {
                                this.setState({ sortDirectionPrice: "desc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionPrice))
                              }
                              else {
                                this.setState({ sortDirectionPrice: "asc" }, () => this.sortTableHead(this.state.sortValue, this.state.sortDirectionPrice))
                              }
                            })
                          }}
                        >
                          {t("AdminConsole.price")}
                        </TableSortLabel>

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ background: "white" }}>
                    {this.state.taskData.map((_data: any, idx: any) => {
                      return (
                        <React.Fragment key={_data.id}>
                          <TableRow>
                            <TableCell
                              style={{
                                padding: "35px",
                                border: "0px",
                                cursor : "pointer"
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    className={classes.brandNameData}
                                  >
                                    {_data.taskName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={classes.tableData}
                              >
                                {_data.assignee}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={classes.tableData}
                              >
                                {_data.owner}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={classes.tableData}
                              >
                                {_data.status}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={classes.tableData}
                              >
                                {_data.updated_at}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={classes.tableData}
                              >
                                $ {_data.price}
                              </Typography>
                            </TableCell>
                            {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
                              <TableCell style={{ border: "0px" }}>
                                <IconButton
                                  onClick={(e) =>
                                    this.handleIconClick(e, _data)
                                  }>
                                  <MoreVertIcon />
                                </IconButton>
                              </TableCell>
                            }
                          </TableRow>
                          <Grid container>
                            <Grid item xs={12} style={{
                              background: "#F5F6FA",
                              height: "25px"
                            }} />
                          </Grid>
                        </React.Fragment>
                      )
                    }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleIconClose}
            >
              {this.state.selectedTask?.status?.toUpperCase() != 'New'.toUpperCase() ?  
              
              (this.state.selectedTask?.templateCreated == undefined || this.state.selectedTask?.templateCreated == false) ?
                (
                  <MenuItem
                    style={{
                      fontFamily: "Work Sans",
                      fontSize: "12px",
                      color: "#0E0E0E",
                      textTransform: "capitalize",
                      letterSpacing: 0,
                    }}
                    onClick={this.createTemplateTask}
                  >
                    Create Template
                  </MenuItem>
                )
                :
                <MenuItem
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "12px",
                    color: "#0E0E0E",
                    textTransform: "capitalize",
                    letterSpacing: 0,
                  }}
                >
                  Template Created
                </MenuItem>

                :
                null
              }
              <MenuItem
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "12px",
                    color: "#0E0E0E",
                    textTransform: "capitalize",
                    letterSpacing: 0,
                  }}
                  onClick = {this.deleteTask}
                >
                 Delete
                </MenuItem>
            </Menu>
            <Grid
              item
              xs={12}
              style={{
                borderTop: "1px solid #F1F1F1",
                borderBottom: "1px solid #F1F1F1",
                padding: "20px 0 20px 0",
              }}
            >
              <Grid container>
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Grid container>
                    <Grid item xs={10}>
                      <p>{t("projectTotal.projectTotal")}:</p>
                    </Grid>
                    <Grid item xs={2}>
                      <p>$ {this.state.totalPrice}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Pagination
                    count={this.state.totalTaskPages}
                    variant="outlined"
                    color="primary"
                    page={this.state.setTaskPage}
                    onChange={this.handleTaskPagination}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(ProjectDetails));
