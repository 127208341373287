import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropzoneArea } from "material-ui-dropzone";
import InputColor from "react-input-color";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
// import HomeScreen from './HomeScreen'
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from "../../../blocks/alert/src/AlertBlock";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../../blocks/adapters/src/AlertPageWebAdapter";

import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../../blocks/adapters/src/OtpToNewPasswordAdapter";
import OnboardingAdapter from "../../../blocks/adapters/src/OnboardingAdapter";
// card imports

import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { red } from "@material-ui/core/colors";
import Toolbar from "@material-ui/core/Toolbar";
import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import LanguageOptions from "../../../blocks/LanguageOptions/src/LanguageOptions";
import { withTranslation, Trans } from "react-i18next";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});
const designHarvestLogo = require("../assets/design-harvest-logo-icon.png");
const brandLogo = require("../assets/volkswagen.svg");

const styles = {
  root: {
    flexGrow: 1,
  },
  dropZone: {
    minHeight: "0px",
    height: "70px",
    "& dropzoneIcon": {
      display: "none",
    },
  },
  MuiDropzoneAreaText: {
    height: "30px !important",
  },
  sideNav: {
    height: "100vh",
    backgroundColor: "#f5f6fa",
    zIndex: "9999",
    position: "fixed",
    width: "100%",
  },
  content: {
    backgroundColor: "#ffff",
    position: "relative",
    left: "20%",
  },
  topLogo: {
    marginTop: "40px",
    marginLeft: "40px",
  },
  userName: {
    color: "#282828",
    fontSize: "22px",
  },
  userDesignation: {
    color: "#737373",
    fontSize: "14px",
    margin: "0",
  },
  logos: {
    position: "fixed",
    bottom: "40px",
    left: "20px",
  },
  footerText: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  foottext: {
    color: "#5b6b96",
    // fontFamily:"WorkSans-Regular",
    fontSize: "10px",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "999",
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    paddingBottom: "30px",
  },
  username: {
    fontSize: "32px",
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "40px",
  },
  gridSpacing: {
    marginLeft: "40px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  confirmInputStreet: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  companyEmail: {
    marginTop: "15px",

  },
  selectInput: {
    marginBottom: "20px",
  },
  selectInputLanguage: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    // backgroundColor:"#173e59",
    // backgroundColor:dynamicColor,
    // backgroundColor:`${dynamicColor}`,
    color: "white",
  },
  chips: {
    backgroundColor: "#f4f6fc",
  },
  fileNameDisplay: {
    height: "30px",
    backgroundColor: "#f4f6fc",
    width: "auto",
  },

  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  tab: {
    textTransform: "none"
  }
} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  isTrue: false;
  files: [];
  dynamicColor: any;
  t?: any;
  i18?: any;
  // Customizable Area End
}

// Customizable Area Start
interface S {
  value: number;
  color: any;
  secondaryColor: any;
  isTrue: false;
  files: [];
  dynamicColor: "";
  messageError: any,
  companyDetail: any;
  userInfo: any;
  billingInfo : any;
}

interface SS { }

class NewBrand extends BlockComponent<Props, S, SS> {
  static instance: NewBrand;
  updateCompanyApiCallId: any;
  addCompanyApiCallId:any;
  getCompanyDetailId: any;
  getCompanyBillingDetailId : any;
  updateCompanyBillingDetailId : any ;
  constructor(props: Props) {
    super(props);
    console.log(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      value: 0,
      color: {},
      secondaryColor: {},
      isTrue: false,
      messageError: "",
      files: [],
      dynamicColor: "",
      companyDetail: null,
      billingInfo : null,
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
    };

    NewBrand.instance = this;
  }

  _setColor = (value: any) => {
    if (!value) {
      return {
        color: "red",
      };
    } else {
      return {
        color: value.hex,
      };
    }
  };
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  colorChange = (color: any) => {
    console.log(color, "color");
    console.log(color.hex);
    this.setState(
      {
        color: color,
      },
      () => {
        if (!localStorage.getItem("key")) {
          console.log("empty");
        }
        localStorage.removeItem("key");
        if (color) {
          // localStorage.setItem("primaryColor",JSON.parse(color))
          localStorage.setItem("key", JSON.stringify(color));
        }
      }
    );
  };
  goToHomeScreen = () => {
    this.props.navigation.navigate('AdminConsole')
  }

  async componentDidMount() {
    this.getComapanyDetails();
    this.getBillingDetails();


  }

  //  componentDidUpdate=()=>{
  //    this.getComapanyDetails();
  //   }

  getBillingDetails = ()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyBillingDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details?brand_id=${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  inputFileChanged = (e: any) => {
    // console.log(this.fileInput.current.files[0]);
    
     let value = {
       photo: URL.createObjectURL(e.target.files[0]),
     };
    //  this.setState(
    //    {
    //      imgs: URL.createObjectURL(e.target.files[0]),
    //    },
    //    () => {
    //      // this.addAccountProfile(value, "image");
    //      console.log("imgs", (e.target));
         
    //    }
    //  );
 
     // this.addAccountProfile(this.state.imgs,"image")
   };

  getComapanyDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  colorChangeSeondary = (color: any) => {
    this.setState({
      secondaryColor: color,
    });
    localStorage.removeItem("secondarykey");

    if (color) {
      // localStorage.setItem("secondaryColor",JSON.parse(color))
      localStorage.setItem("secondarykey", JSON.stringify(color));
    }
  };
  fileChange = (files: any) => {
    this.setState({
      files: files,
    });
  };
  checkBoxChange = (e: any) => {
    console.log(e, "e");
    this.setState({ isTrue: e.target.checked });
  };
  printName(data: any) {
    return data.map((item: any) => {
      return <p style={{ textAlign: "center" }}>{item.name}</p>;
    });
  }

  async receive(from: string, message: Message) {
    console.log("receive function");
    console.log(message, "message");

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getCompanyDetailId) {
      this.setState({ companyDetail: message.properties?.RestAPIResponceSuccessMessage?.data?.attributes })
      console.log("this.state.companyInfo", this.state.companyDetail);

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyApiCallId) {
      this.setState({ companyDetail: message.properties?.RestAPIResponceSuccessMessage?.data?.attributes })
      console.log("this.state.companyInfo1", this.state.companyDetail);
    }
    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getCompanyBillingDetailId){
      this.setState({billingInfo : message.properties?.RestAPIResponceSuccessMessage?.brand_billings?.data[0]})
      console.log("this.state.billingInfo", this.state.billingInfo);
    }
    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyBillingDetailId){
      this.setState({billingInfo : message.properties?.RestAPIResponceSuccessMessage?.data})
      console.log("this.state.billingInfo1", this.state.billingInfo);
    }
    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.addCompanyApiCallId){
      console.clear();
      console.log('---------------------$$$$$$$$$$$$$$$$$$$$$$$$$$$$------------------')
      console.log(message)
    }



    if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
      var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
      errorMessage.forEach((element: any) => {
        this.setState({
          messageError: element.name
        })

      });
      console.log(this.state.messageError, "error")
      toast.error(this.state.messageError)

    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            // category list



            //search live


            //recent search

          }
        }
      }
    }


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }



  }

  updateCompanyBillingDetails = (values: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    // if(value === 'image'){
    //   const data = new FormData();
    //   const imagedata = values;
    //   const date =
    //   data.append('photo', imagedata);
    // }
    // var bodyFormData = new FormData();
    if(values.companyName){
      this.addCompanyProfile(values);
    }
    var bodyFormData ={
      "billing_email": values.billingEmail,
      'first_name':  values.contactFirstName,
      'last_name' : values.contactLastName,
      'tax_id' : values.taxID,
      'vat_id': values.vATID

    }
    // values.billingEmail ? bodyFormData.append('billing_email', values.billingEmail) : null;
    // values.contactFirstName ? bodyFormData.append('first_name', values.contactFirstName) : null;
    // values.contactLastName ? bodyFormData.append('last_name', values.contactLastName) : null;
    // values.taxID ? bodyFormData.append('tax_id', values.taxID) : null;
    // values.vATID ? bodyFormData.append('vat_id', values.vATID) : null;
    // values.street ? bodyFormData.append('street', values.street) : null;
    // values.postCode ? bodyFormData.append('postcode', values.postCode) : null;
    // values.city ? bodyFormData.append('city', values.city) : null;
    // values.country ? bodyFormData.append('country', values.country) : null;
    // console.log(bodyFormData,"data")
    const httpBody = {
      data: bodyFormData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyBillingDetailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details/${this.state.billingInfo.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // bodyFormData
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addCompanyProfile = (values: any, value?: any) => {
    const header = {
      "Content-Type":"application/json",
      token: localStorage.getItem("token")
    };

    let body = {} as any;
    body.name = values.companyName;
    body.website_url = values.companyURL;
    body.email_domain = values.companyEmail;
    body.company_size = values.companySize;
    body.industry = values.industry;
    body.street = values.street;
    body.postcode = values.postCode;
    body.city = values.city;
    body.country = values.country;

    console.log(body)

   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCompanyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  logoHandleChange = (event: any, value: any) => {
    // console.log(event.target.value);
    
    // setValue(newValue);
    console.log(value);
    // this.setState({
    //   value: value,
    // });
    // this.statvalue=value
  };
  logoHandleClick = (event: any) => {
    // this.setState({
    //   anchorEl: event.currentTarget,
    // });
  };

  logoHandleClose = (item?: any) => {
    console.log(item, "acount");  
    // this.setState({
    //   anchorEl: null,
    //   imgs: "",
    // });
  };
  ;pgpHandleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    console.log("takePhoto");
  };



  render() {
    const { t, navigation, classes } = this.props;
    const _this = this;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#1a76b0";
    }
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={2} className={classes.sideNav}>
            <div className={classes.topLogo}>
              <img src={brandLogo} />
              <p className={classes.userName}>{this.state.companyDetail?.name ? this.state.companyDetail.name : ""}</p>
              <p className={classes.userDesignation}>
                {t("CompanyProfile.agency")}
              </p>
            </div>

            <div className={classes.logos}>
              <img src={designHarvestLogo} />
              <p className={classes.footerText}>© 2020 design harvest</p>
              <p 
                className={classes.foottext}
                style={{ cursor: "pointer" }} 
                onClick={() => {
                  window.location.replace('/terms-and-conditions');
                }}
              >
                {t("CompanyProfile.termOfService")}
              </p>
              <p 
                className={classes.foottext}
                style={{ cursor: "pointer" }} 
                onClick={() => {
                  window.location.replace('/privacy-policy');
                }}
              >
                {t("CompanyProfile.privacy")}
              </p>
              <p className={classes.foottext}>{t("CompanyProfile.logout")}</p>
            </div>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <AppBar position="static" className={classes.appBar}>
                  <Toolbar variant="dense">
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                    >
                      <div className={classes.plusButtonIcon} />
                    </IconButton>

                    <Typography variant="h6" color="inherit" />
                  </Toolbar>

                  <Grid container spacing={0} className={classes.nav_main}>
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={designHarvestLogo} />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ color: secondaryColorToBeChanged, cursor: "pointer" }} onClick={this.goToHomeScreen}>{`${t(
                            "CompanyProfile.backTo"
                          )} Admin Console`}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AppBar>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={10} className={classes.content}>
                {/* <Grid container spacing={0}>
<Grid xs={10}>

</Grid>
<Grid xs={2}>

</Grid>
              </Grid> */}

                <p className={classes.username}>
                  {t("CompanyProfile.companyProfile")}
                </p>

                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  aria-label="scrollable force tabs example"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={t("CompanyProfile.general")}
                    className={classes.tab}
                  />
                  <Tab
                    label={t("CompanyProfile.billingDetails")}
                    className={classes.tab}
                  />
                  <Tab
                    label={t("CompanyProfile.brandInformation")}
                    className={classes.tab}
                  />
                </Tabs>
                <div className={classes.gridSpacing}>
                  {this.state.value === 0 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Formik
                            initialValues={{
                              // email: "",
                              // animal: "",
                              companyName: this.state.companyDetail?.name ? this.state.companyDetail.name : "",
                              companySize: this.state.companyDetail?.company_size ? this.state.companyDetail.company_size : "",
                              industry: this.state.companyDetail?.industry ? this.state.companyDetail.industry : "",
                              companyEmail: this.state.companyDetail?.email_domain ? this.state.companyDetail.email_domain : '',
                              companyURL: this.state.companyDetail?.website_url ? this.state.companyDetail.website_url : '',
                              street: this.state.companyDetail?.street ? this.state.companyDetail.street : '',
                              postCode: this.state.companyDetail?.postcode ? this.state.companyDetail.postcode : '',
                              city: this.state.companyDetail?.city ? this.state.companyDetail.city : '',
                              country: this.state.companyDetail?.country ? this.state.companyDetail.country : '',
                            }}
                            enableReinitialize
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                              companyName: Yup.string()
                                .required("company name is required"),
                              companySize: Yup.string(),
                              industry: Yup.string(),
                              companyEmail: Yup.string()
                                .required("company domain is required"),
                              companyURL: Yup.string()
                                .required("company URL is required"),
                              street: Yup.string(),
                              postCode: Yup.string(),
                              city: Yup.string(),
                              country: Yup.string(),
                            })}
                            onSubmit={(values) => {
                              this.addCompanyProfile(values)
                            }}
                            render={({
                              values,
                              errors, 
                              touched,
                              handleChange,
                              isValid,
                              handleBlur,
                              setFieldTouched,
                              isSubmitting,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <TextField
                                  name="companyName"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  fullWidth
                                  label={t("CompanyProfile.companyName")}
                                  value={values.companyName}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  helperText={
                                    touched.companyName &&
                                      errors.companyName ? (
                                      <Trans>
                                        CompanyProfile.companyRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyName && errors.companyName
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="companySize"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      select
                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t("CompanyProfile.companySize")}
                                      value={values.companySize}
                                      variant="outlined"
                                      helperText={
                                        errors.companySize &&
                                          touched.companySize ? (
                                          <Trans>
                                            CompanyProfile.companyRequired
                                          </Trans>
                                        ) : (
                                          ""
                                        )
                                      }
                                      error={
                                        touched.companySize &&
                                          errors.companySize
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option aria-label="None" value="" />
                                      <option value="100">100</option>
                                      <option value="200">200</option>
                                    </TextField>
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      name="industry"
                                      select
                                      SelectProps={{
                                        native: true,
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      label={t("CompanyProfile.industry")}
                                      value={values.industry}
                                      variant="outlined"
                                      helperText={
                                        touched.industry && errors.industry ? (
                                          <Trans>
                                            CompanyProfile.industryRequired
                                          </Trans>
                                        ) : (
                                          ""
                                        )
                                      }
                                      error={
                                        touched.industry && errors.industry
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option aria-label="None" value="" />
                                      {/* <option value="luxurygoods">Luxury goods</option>
                                      <option value="goods">goods</option> */}
                                      <option value="Services">Services</option>
                                      <option value="Entertainment / Gastro / Tourism / Culture">Entertainment / Gastro / Tourism / Culture</option>
                                      <option value = "Real Estate / Construction / Architecture">Real Estate / Construction / Architecture</option>
                                      <option value= "Industry">Industry</option>
                                      <option value="Industrial machinery / mechanical engineering / electronics / medical technology">Industrial machinery / mechanical engineering / electronics / medical technology</option>
                                      <option value = "LEH / FMCG / Retail">LEH / FMCG / Retail</option>
                                      <option value= "Luxury / Fashion">Luxury / Fashion</option>
                                      <option value="Software / IT / Digital">Software / IT / Digital</option>
                                      <option value = "Advertising / Marketing / Agencies / Publishing">Advertising / Marketing / Agencies / Publishing</option>
                                    </TextField>
                                  </Grid>
                                </Grid>
                                <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label="Company Email Domain"
                                  name="companyEmail"
                                  fullWidth
                                  value={values.companyEmail}
                                  variant="outlined"
                                  className={classes.companyEmail}
                                  size="small"
                                  helperText={
                                    touched.companyEmail && errors.companyEmail ? (
                                      <Trans>
                                        CompanyProfile.companyUrlRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyEmail && errors.companyEmail
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label={t("CompanyProfile.companyUrl")}
                                  name="companyURL"
                                  fullWidth
                                  value={values.companyURL}
                                  variant="outlined"
                                  className={classes.confirmInputStreet}
                                  size="small"
                                  helperText={
                                    touched.companyURL && errors.companyURL ? (
                                      <Trans>
                                        CompanyProfile.companyUrlRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyURL && errors.companyURL
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {/* <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label="Company Email"
                                  name="companyURL"
                                  fullWidth
                                  value={values.companyURL}
                                  variant="outlined"
                                  className={classes.confirmInputStreet}
                                  size="small"
                                  helperText={
                                    touched.companyURL && errors.companyURL ? (
                                      <Trans>
                                        CompanyProfile.companyUrlRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyURL && errors.companyURL
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                /> */}

                                <p style={{ fontSize: "20px" }}>   {t("CompanyProfile.address")}</p>
                                <TextField
                                  name="street"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  type="text"
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.street")}
                                  value={values.street}
                                  variant="outlined"
                                  helperText={
                                    touched.street && errors.street
                                      ? (
                                        <Trans>
                                          CompanyProfile.streetRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.street && errors.street
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="postCode"
                                      type="text"
                                      size="small"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t("CompanyProfile.postCode")}
                                      value={values.postCode}
                                      variant="outlined"
                                      helperText={
                                        errors.postCode && touched.postCode
                                          ? (
                                            <Trans>
                                              CompanyProfile.postCodeRequied
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.postCode && errors.postCode
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      name="city"
                                      type="text"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      label={t("CompanyProfile.city")}
                                      value={values.city}
                                      variant="outlined"
                                      helperText={
                                        touched.city && errors.city
                                          ? (
                                            <Trans>
                                              CompanyProfile.cityRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.city && errors.city
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                </Grid>

                                <TextField
                                  name="country"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.country")}
                                  value={values.country}
                                  variant="outlined"
                                  helperText={
                                    touched.country && errors.country
                                      ? (
                                        <Trans>
                                          CompanyProfile.countryRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.country && errors.country
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                {(this.state.userInfo?.role?.data?.attributes?.name === "client" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") || (this.state.userInfo?.role?.data?.attributes?.name === "albertbauer" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") ?
                                  null
                                  :
                                  <Button
                                    className={classes.button}
                                    style={{ backgroundColor: colorToBeChanged }}
                                    type="submit"
                                    variant="contained"

                                  >
                                    {t("CompanyProfile.saveChanges")}
                                  </Button>

                                }
                              </form>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ marginLeft: "40%" }}>
                            <img src={brandLogo} />
                            <p style={{ fontSize: "18px" }}>{t("CompanyProfile.myAvatar")}</p>
                            <p style={{ fontSize: "14px" }}>
                              {t("CompanyProfile.myAvatarPargraph")}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {this.state.value === 1 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Formik
                            
                            initialValues={{
                              // email: "",
                              // animal: "",
                              companyName: this.state.companyDetail?.name ? this.state.companyDetail.name : "",
                              billingEmail: this.state.billingInfo?.attributes?.billing_email ? this.state.billingInfo?.attributes.billing_email : "",
                              contactFirstName:this.state.billingInfo?.attributes?.first_name ? this.state.billingInfo?.attributes.first_name : "",
                              contactLastName: this.state.billingInfo?.attributes?.last_name ? this.state.billingInfo?.attributes.last_name : "",
                              vATID: this.state.billingInfo?.attributes?.vat_id ? this.state.billingInfo?.attributes.vat_id : "",
                              taxID: this.state.billingInfo?.attributes?.tax_id ? this.state.billingInfo?.attributes.tax_id : "",
                            }}
                            enableReinitialize
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                              companyName: Yup.string()
                                .required("Company Name is required"),
                              billingEmail: Yup.string().email('Invalid billing email').required('billing email is required'),

                              contactFirstName: Yup.string().required("contact first name is required"),
                              contactLastName: Yup.string().required("contact last name is required"),
                              vATID: Yup.string(),
                              taxID: Yup.string(),
                            })}
                            onSubmit={(values) => {
                              console.log(values);
                              // this.goToConfirmationAfterPasswordChange(values);
                              // this.props.nextStep(values);
                              this.updateCompanyBillingDetails(values)
                            }}
                            render={({
                              values,
                              errors,
                              touched,
                              handleChange,
                              isValid,
                              handleBlur,
                              setFieldTouched,
                              isSubmitting,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <TextField
                                  name="companyName"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  fullWidth
                                  label={t("CompanyProfile.companyName")}
                                  value={values.companyName}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  helperText={
                                    touched.companyName && errors.companyName
                                      ? (
                                        <Trans>
                                          CompanyProfile.companyNameRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.companyName && errors.companyName
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  label={t("CompanyProfile.billingEmail")}
                                  name="billingEmail"
                                  fullWidth
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  value={values.billingEmail}
                                  variant="outlined"
                                  className={classes.confirmInput}
                                  helperText={
                                    touched.billingEmail && errors.billingEmail
                                      ? (
                                        <Trans>
                                          {/* CompanyProfile.billingEmailRequired */}
                                          {errors.billingEmail}
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.billingEmail && errors.billingEmail
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="contactFirstName"
                                      type="text"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t(
                                        "CompanyProfile.contactFirstName"
                                      )}
                                      value={values.contactFirstName}
                                      variant="outlined"
                                      helperText={
                                        errors.contactFirstName &&
                                          touched.contactFirstName
                                          ? (
                                            <Trans>
                                              CompanyProfile.contactFirstNameRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.contactFirstName &&
                                          errors.contactFirstName
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      name="contactLastName"
                                      type="text"
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      label={t(
                                        "CompanyProfile.contactLastName"
                                      )}
                                      value={values.contactLastName}
                                      variant="outlined"
                                      helperText={
                                        touched.contactLastName &&
                                          errors.contactLastName
                                          ? (
                                            <Trans>
                                              CompanyProfile.contactLastNameRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.contactLastName &&
                                          errors.contactLastName
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                </Grid>

                                <TextField
                                  name="vATID"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.vatId")}
                                  value={values.vATID}
                                  variant="outlined"
                                  helperText={
                                    touched.vATID && errors.vATID
                                      ? (
                                        <Trans>
                                          CompanyProfile.vatIdRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.vATID && errors.vATID ? true : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  name="taxID"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.taxId")}
                                  value={values.taxID}
                                  variant="outlined"
                                  helperText={
                                    touched.taxID && errors.taxID
                                      ? (
                                        <Trans>
                                          CompanyProfile.taxIdRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.taxID && errors.taxID ? true : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                {(this.state.userInfo?.role?.data?.attributes?.name === "client" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") || (this.state.userInfo?.role?.data?.attributes?.name === "albertbauer" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") ?
                                  null
                                  :
                                  <Button
                                    className={classes.button}
                                    style={{ backgroundColor: colorToBeChanged }}
                                    type="submit"
                                    variant="contained"

                                  >
                                    {t("CompanyProfile.saveChanges")}
                                  </Button>

                                }
                              </form>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>
                    </>
                  )}
                  {this.state.value === 2 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <div>
                            <Formik
                              initialValues={{
                                // email: "",
                                // animal: "",
                                nameColorPalatte: "",
                                primaryFont: "",
                                secondaryFont: "",
                              }}
                              validateOnBlur={false}
                              validationSchema={Yup.object().shape({
                                nameColorPalatte: Yup.string()
                                  .required("Name Colour Palatte is required"),
                                primaryFont: Yup.string(),

                                secondaryFont: Yup.string(),
                              })}
                              onSubmit={(values) => {
                                console.log(values);
                                // this.goToConfirmationAfterPasswordChange(values);
                                // this.props.nextStep(values);
                              }}
                              render={({
                                values,
                                errors,
                                touched,
                                handleChange,
                                isValid,
                                handleBlur,
                                setFieldTouched,
                                isSubmitting,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <TextField
                                    name="nameColorPalatte"
                                    type="text"
                                    size="small"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    value={values.nameColorPalatte}
                                    fullWidth
                                    label={t(
                                      "CompanyProfile.nameOfColorPalatte"
                                    )}
                                    className={classes.confirmInput}
                                    variant="outlined"
                                    helperText={
                                      touched.nameColorPalatte &&
                                        errors.nameColorPalatte
                                        ? (
                                          <Trans>
                                            CompanyProfile.nameOfColorPalatteRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.nameColorPalatte &&
                                        errors.nameColorPalatte
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <div style={{ marginTop: "40px" }}>
                                    <p style={{ fontSize: "20px" }}>{t("CompanyProfile.colors")}</p>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      {t("CompanyProfile.addPrimaryColor")}
                                    </p>
                                    <div>
                                      <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4}>
                                          <Box
                                            p={2}
                                            style={{
                                              backgroundColor: "#efefef",
                                            }}
                                          >
                                            {/* <div
        style={{
          width: 50,
          height: 50,
          marginTop: 20,
          backgroundColor:this.state.color && this.state.color.rgba
        }}
      /> */}
                                            {this.state.color.hex}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "50%" }}>
                                    <InputColor
                                      initialValue="#173e59"
                                      // onChange={this.state.color}
                                      // color={ this.state.color }
                                      onChange={this.colorChange}
                                      placement="right"
                                    />
                                    <p style={{ color: colorToBeChanged }}>Choose color to change primary colors</p>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      {t("CompanyProfile.addSecondaryColor")}
                                    </p>
                                  </div>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                      <Box
                                        p={2}
                                        style={{ backgroundColor: "#efefef" }}
                                      >
                                        {/* <div
        style={{
          width: 50,
          height: 50,
          marginTop: 20,
          backgroundColor:this.state.color && this.state.color.rgba
        }}
      /> */}
                                        {this.state.secondaryColor.hex}
                                      </Box>
                                    </Grid>
                                  </Grid>

                                  <div style={{ marginLeft: "50%" }}>
                                    {/* <div style={{height:"40px",width:"50px",borderRadius:"8px",backgroundColor:"",border:"2px dotted #1a76b0"}}  >

     </div> */}

                                    <InputColor
                                      initialValue="#1a76b0"
                                      // onChange={this.state.color}
                                      // color={ this.state.color }
                                      onChange={this.colorChangeSeondary}
                                      placement="right"

                                    />
                                    <p style={{ color: secondaryColorToBeChanged }}>Choose color to change secondary colors</p>
                                  </div>
                                  <div>
                                    <p style={{ fontSize: "20px" }}> {t("CompanyProfile.fonts")}</p>
                                  </div>

                                  <TextField
                                    name="primaryFont"
                                    type="text"
                                    size="small"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    value={values.primaryFont}
                                    fullWidth
                                    label={t("CompanyProfile.primaryFonts")}
                                    className={classes.confirmInput}
                                    variant="outlined"
                                    helperText={
                                      touched.primaryFont && errors.primaryFont
                                        ? (
                                          <Trans>
                                            CompanyProfile.primaryFontsRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.primaryFont && errors.primaryFont
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <TextField
                                    name="secondaryFont"
                                    type="text"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    size="small"
                                    value={values.secondaryFont}
                                    fullWidth
                                    label={t("CompanyProfile.secondaryFonts")}
                                    className={classes.confirmInput}
                                    variant="outlined"
                                    helperText={
                                      touched.secondaryFont &&
                                        errors.secondaryFont
                                        ? (
                                          <Trans>
                                            CompanyProfile.secondaryRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.secondaryFont &&
                                        errors.secondaryFont
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />

                                  <div style={{ marginTop: "40px" }}>
                                    <div className={classes.fileNameDisplay}>
                                      {/* {this.state.files.map(item=>{
      return <p>{item}</p>} */}
                                      {this.printName(this.state.files)}
                                      {/* {this.state.files.map(item=>{
  return<p style={{textAlign:'center'}}>{item.name}</p>
})} */}
                                    </div>

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.isTrue}
                                          onChange={this.checkBoxChange}
                                        />
                                      }
                                      label={t("CompanyProfile.agreeCheck")}
                                    />
                                  </div>

                                  <div>
                                    <DropzoneArea
                                      dropzoneText={"Upload your custom font"}
                                      dropzoneClass={classes.dropZone}
                                      onChange={this.fileChange}
                                    />
                                  </div>

                                  {(this.state.userInfo?.role?.data?.attributes?.name === "client" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") || (this.state.userInfo?.role?.data?.attributes?.name === "albertbauer" && this.state.userInfo?.role?.data?.attributes?.permission === "viewer") ?
                                    null
                                    :
                                    <Button
                                      className={classes.button}
                                      style={{ backgroundColor: colorToBeChanged }}
                                      type="submit"
                                      variant="contained"

                                    >
                                      {t("CompanyProfile.saveChanges")}
                                    </Button>

                                  }
                                </form>
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>

              <Grid xs={2} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start

export default withStyles(styles)(withTranslation()(NewBrand));
