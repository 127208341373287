import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Breadcrumbs,
  AppBar,
  Tab,
  Tabs,
  Input,
  InputAdornment,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Select,
  Menu,
  MenuItem,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useParams } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "@material-ui/core/Avatar";
const defaultUser = require("../../../components/src/defaultuser.png");
import { withTranslation, Trans } from "react-i18next";

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  userId: any;
  name: any;
  image : any;
  designation: any;
  organization: any;
  project: any;
  permission: any;
  permissionId: any;
  phone: any;
  email: any;
  isAbUser: any;
  roles: any;
  teams: any;
  anchorEl: any;
  dialogOpen: any;
  isBlocked: boolean;
  setTeamPage: number;
  totalTeamPages: number;
}

interface SS {
  id: any;
}

const styles = {
  templateHeadings: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1a76b0",
  },
  templateHeadingsData: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    fontWeight: 400,
    marginLeft: "10px",
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  brandNameData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
    marginLeft: "20px",
  },
  chipLabel: {
    marginBottom: "5px",
  },
  dialogPaper: {
    width: "369px",
    height: "auto",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
    textTransform: "uppercase",
  },
  inputRoot: {
    width: "100%",
    height: "32px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

class UserDetails extends BlockComponent<Props, S, SS> {
  getUsersDataApiCallId: any;
  getTeamsApiCallId: any;
  getRolesApiCallId: any;
  updateRoleApiCallId: any;
  blockUnblockUserApiCallId: any;
  deleteTeamApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      userId: "",
      name: "",
      image : null ,
      designation: "",
      organization: "",
      project: "",
      permission: "",
      permissionId: "",
      phone: "",
      email: "",
      isAbUser: false,
      roles: { ab_user_roles: [], client_roles: [] },
      teams: [],
      anchorEl: null,
      dialogOpen: false,
      isBlocked: false,
      totalTeamPages: 0,
      setTeamPage: 1,
    };
  }

  async componentDidMount() {
    let paramsArray = window.location.search.substring(1).split("&");
    this.setState({
      organization: paramsArray[0].split("=")[1].replace(/%20/g, " "),
    });
    var str = window.location.href;
    var userId = str.split("/")[5].split("?")[0];
    this.setState({ userId: userId });
    await this.getUsersData(userId);
    await this.getRoles();
    await this.getTeams(userId);
  }

  getUsersData = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUsersDataApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getRoles = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_roles_permissions/roles"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTeams = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeamsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_team_builder/teams?account_id=${id}&page=${
        this.state.setTeamPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getUsersDataApiCallId) {
        if (responseJson.data) {
          if (responseJson.data !== null) {
            this.setState({
              name: `${responseJson.data.attributes.first_name} ${
                responseJson.data.attributes.last_name
              }`,
              image: responseJson.data.attributes.photo_blob_signed_id_url,
              designation:
                responseJson.data.attributes.designation !== null
                  ? responseJson.data.attributes.designation
                  : "NA",

              permission:
                responseJson.data.attributes.role.data !== null
                  ? responseJson.data.attributes.role.data.attributes.permission
                  : "NA",
              permissionId:
                responseJson.data.attributes.role.data !== null
                  ? responseJson.data.attributes.role.data.attributes.id
                  : "NA",
              phone:
                responseJson.data.attributes.full_phone_number !== null
                  ? responseJson.data.attributes.full_phone_number
                  : "NA",
              email: responseJson.data.attributes.email,
              isAbUser: responseJson.data.attributes.is_albertbauer_user,
              isBlocked: responseJson.data.attributes.is_blocked,
            });
          }
        }
      } else if (apiRequestCallId === this.getRolesApiCallId) {
        this.setState({
          roles: responseJson,
        });
      } else if (apiRequestCallId === this.getTeamsApiCallId) {
        console.clear();
        console.log(responseJson);
        if (responseJson.teams) {
          if (responseJson.teams.data && responseJson.teams.data !== null) {
            let response = responseJson.teams.data;
            let tempArr = [] as any;
            response.map((_data: any, idx: any) => {
              tempArr.push({ id: _data.id, name: _data.attributes.name });
            });
            let totalTeamPages = this.state.totalTeamPages;
            this.setState({
              teams: tempArr,
              totalTeamPages: responseJson.total_pages
                ? responseJson.total_pages
                : totalTeamPages,
            });
          }
        }
      } else if (apiRequestCallId === this.updateRoleApiCallId) {
        if (responseJson.errors) {
          console.log("error");
        } else {
          toast.success("Role updated successfully");
          this.getUsersData(this.state.userId);
        }
      } else if (apiRequestCallId === this.blockUnblockUserApiCallId) {
        console.log(responseJson);
        this.setState({
          isBlocked: responseJson?.data?.attributes?.is_blocked
        })
        if (responseJson?.data?.attributes?.is_blocked) {
          toast.success("User blocked successfully");
        } else {
          toast.success("User un-blocked successfully");
        }
      } else if (apiRequestCallId === this.deleteTeamApiCallId) {
        if (responseJson) {
          toast.success("Team deleted successfully");
          this.getTeams(this.state.userId);
        }
      }
    }
  }

  handleIconClose = () => {
    this.setState({ anchorEl: null });
  };
  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handlePermissionChange = (event: any) => {
    console.log(event.target.value);

    this.updateRoleByUserId(event.target.value);
  };

  updateRoleByUserId = (roleId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRoleApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let body = {
      account_id: Number(this.state.userId),
      role_id: roleId,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_roles_permissions/roles/update_account_role"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBlockUnblockUser = () => {
    console.log(this.state);
    let status = false as any;
    if (this.state.isBlocked) {
      status = false;
    } else {
      status = true;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUnblockUserApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}`
    );

    const httpBody = { is_blocked: status };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTeamDelete = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTeamApiCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_team_builder/teams/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTeamPagination = (event: any, value: number) => {
    this.setState({ setTeamPage: value }, () => {
      this.getTeams(this.state.userId);
    });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <Grid
          container
          style={{ paddingLeft: "10px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Dashboard
              </Typography>

              <Link
                to="/AdminConsole/Users"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.users")}
              </Link>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {this.state.name}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.userDetails")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }} />
                  <Grid item style={{ marginRight: "20px" }} />
                  <Grid item style={{ marginRight: "20px" }}>
                    <IconButton
                      onClick={(e) => this.setState({ anchorEl: e.target })}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              borderTop: "1px solid #F1F1F1",
              height: "20px",
              marginLeft: "18px",
              marginRight: "18px",
            }}
          />
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "20px",
            }}
          >
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12} style={{ paddingLeft: "100px" }}>
                    {/* <FolderIcon
                      style={{
                        color: "#71787F",
                        height: "110px",
                        width: "100px",
                      }}
                    /> */}
                    <Avatar 
                    src= {this.state.image == null || this.state.image.length > 0  ? this.state.image : defaultUser} 
                    style = {{
                        height: "110px",
                        width: "100px",
                        marginBottom:" 18px"                      
                    }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "12px",
                        fontFamily: "Work Sans",
                        color: "#1a76b0",
                        letterSpacing: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      {this.state.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "12px",
                        fontFamily: "Work Sans",
                        color: "#707880",
                        letterSpacing: "0px",
                        paddingLeft: "25px",
                        marginTop: "10px",
                      }}
                    >
                      {this.state.designation}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "40px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {t("AdminConsole.organization")}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1A1A1A",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {this.state.organization}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          Project
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1A1A1A",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {this.state.project}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                            paddingTop: "8px",
                          }}
                        >
                          {t("AdminConsole.permission")}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Age"
                          disableUnderline={true}
                          value={this.state.permissionId}
                          style={{
                            border: "1px solid #9EABB593",
                            width: "152px",
                            height: "32px",
                            fontSize: "12px",
                            paddingLeft: "5px",
                          }}
                          onChange={(e) => this.handlePermissionChange(e)}
                        >
                          {this.state.isAbUser
                            ? this.state.roles.ab_user_roles.map(
                                (_item: any, idx: any) => (
                                  <MenuItem value={_item.id}>
                                    {_item.permission}
                                  </MenuItem>
                                )
                              )
                            : this.state.roles.client_roles.map(
                                (_item: any, idx: any) => (
                                  <MenuItem value={_item.id}>
                                    {_item.permission}
                                  </MenuItem>
                                )
                              )}
                        </Select>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          borderTop: "1px solid #F1F1F1",
                          height: "20px",
                          margin: "20px 0 20px 0",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "14px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {t("AdminConsole.details")}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {t("AdminConsole.phone")}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1A1A1A",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {this.state.phone}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {t("AdminConsole.email")}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1A1A1A",
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          {this.state.email}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          borderTop: "1px solid #F1F1F1",
                          height: "20px",
                          margin: "20px 0 20px 0",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#1a76b0",
                            fontSize: "14px",
                            fontFamily: "Work Sans",
                            letterSpacing: "0px",
                            opacity: 1,
                          }}
                        >
                          Teams
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {this.state.teams.map((_data: any) => (
                          <Chip
                            label={_data.name}
                            onDelete={(e) => this.handleTeamDelete(_data.id)}
                          />
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={9} />
                          <Grid item xs={3}>
                            <Pagination
                              count={this.state.totalTeamPages}
                              variant="outlined"
                              color="primary"
                              page={this.state.setTeamPage}
                              onChange={this.handleTeamPagination}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleIconClose}
          className={this.props.classes.menu}
        >
          {/* <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={(e) => this.setState({ dialogOpen: true })}
          >
            Reset Password
          </MenuItem> */}
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleBlockUnblockUser}
          >
            {this.state.isBlocked ? "Unblock" : t("AdminConsole.block")}
          </MenuItem>
        </Menu>

        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogOpen}
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={this.props.classes.dialogTitleRoot}
          >
            <span className={this.props.classes.dialogTitle}>
              Reset Password
            </span>
            <IconButton>
              <CloseIcon onClick={this.handleDialogClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    letterSpacing: "0px",
                  }}
                >
                  Reset password successful
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    letterSpacing: "0px",
                  }}
                >
                  {this.state.name}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "15px",
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    letterSpacing: "0px",
                  }}
                >
                  {this.state.email}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ minHeight: "40px" }} />
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    letterSpacing: "0px",
                  }}
                >
                  Password
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ minHeight: "20px" }} />
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "Work Sans",
                      color: "#1a76b0",
                      letterSpacing: "0px",
                    }}
                  >
                    Password
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginLeft: "20px",
                      fontSize: "12px",
                      fontFamily: "Work Sans",
                      color: "black",
                      letterSpacing: "0px",
                    }}
                  >
                    Hide
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ minHeight: "40px" }} />
              <Grid item xs={12} style={{ marginBottom: "30px" }}>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "30px",
                    border: "1px solid #1a76b0",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "15px",
                      fontFamily: "Work Sans",
                      color: "#1a76b0",
                      letterSpacing: "0px",
                      padding: "10px 0 10px 0",
                    }}
                  >
                    Share via email
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(UserDetails));
