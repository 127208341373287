import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any
  t?: any;
  i18n?: any
  history?: any

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailError: boolean;
  passwordError: boolean;
  emailErrorStatus: boolean;
  showPassword: boolean;
  open: boolean;
  loader: any;
  messageError: any,
  language : any,
  ThemeInfo : any

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  accountActivationmailId: string = "";
  accountActivation: string = "";
  accountActivation2: string = "";
  getBrandThemeDetailId: string = "";
  getBrandDetailId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  emailError = false;
  showPassword = false;
  emailErrorStatus = false;
  passwordError = false;

  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]

    this.state = {

      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailError: false,
      showPassword: false,
      passwordError: false,
      emailErrorStatus: false,
      open: false,
      loader: false,
      messageError: "",
      language : localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : 'en',
      ThemeInfo : null
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  handleCloseLoader = () => {
    this.setState({
      open: false
    })
  };
  handleToggle = (e: any) => {
    this.setState({
      open: !this.state.open
    })
  };

  // emailConfirmation() {


  //   var pageURL = window.location.href;
  //   var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('=') + 1);
  //   var str = lastURLSegment
  //   window.location.toString().includes("franky")
  //   if (window.location.toString().includes('?')) {
  //     const header = {
  //       "Content-Type": configJSON.loginApiContentType,
  //       "token": lastURLSegment
  //     };



  //     const requestMessage = new Message(
  //       getName(MessageEnum.RestAPIRequestMessage)
  //     );

  //     this.accountActivation2 = requestMessage.messageId;
  //     var emailVerification = configJSON.activateAPiEndPoint + '?' + 'token=' + lastURLSegment
  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIResponceEndPointMessage),
  //       emailVerification
  //     );

  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIRequestHeaderMessage),
  //       JSON.stringify(header)
  //     );
  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIRequestMethodMessage),
  //       configJSON.activateAPiMethod
  //     );




  //     runEngine.sendMessage(requestMessage.id, requestMessage);
  //   }




  // }

  activeAccount() {

  }
  async componentDidMount() {
    // localStorage.removeItem("token")
    // localStorage.removeItem("userInfo")
    if (localStorage.getItem("token")) {
      if(localStorage.getItem("permissions") == "superadmin"){
        window.location.assign('/AdminConsole')
      }else{
        window.location.assign("/Home/HomePage");
      }
    }
    // this.callGetValidationApi();
    // this.emailConfirmation();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin()
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn()
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword()
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    }
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    }
  };
  // email handle change
  txtInputEmail(e: any) {

    const name = e.target.name
    const text = e.target.value
    if (e.target.name === "email") {
      if (e.target.value === "") {

        this.setState({
          emailError: true
        })
        this.setState({
          emailErrorStatus: false
        })
      }

      if (e.target.value.length > 0) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {


          this.setState({
            emailError: false
          })
          this.setState({
            emailErrorStatus: true
          })


        }
      }
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (pattern.test(e.target.value) && e.target.value !== "") {
        this.setState({
          emailError: false,
          emailErrorStatus: false
        })

      }
      this.setState({
        email: text
      })
    }
    if (e.target.name === "password") {
      if (e.target.value === "") {
        this.setState({
          passwordError: true
        })
      }
      else {
        this.setState({
          passwordError: false
        })
      }
      this.setState({
        password: text
      })
    }



  }
  passwordVisibility = (e?: any) => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClose = () => {
    alert("hello")
  };

  langaugeChangeHandler = (lang : string)=>{
    this.setState({language : lang})
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
   
    this.setState({
      open: false,
      loader: false
    })
    console.log(message.properties?.RestAPIResponceSuccessMessage?.meta?.token, "success")

    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandDetailId) {
      console.log("brand_sjdjsahas" ,message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes );
      
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        if(message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.logo_blob_signed_id_url?.length > 0) {
          localStorage.setItem("brandLogo" ,message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.logo_blob_signed_id_url)
          localStorage.setItem("brandGroupSelected",message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.brand_group_id)   

        }
        if(message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.brand_group?.data?.attributes?.name == "Hugo Boss"){
          localStorage.setItem("brandGroupSelectedName",message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.brand_group?.data?.attributes?.name)
        }
        this.themeInfo(message?.properties?.RestAPIResponceSuccessMessage?.data?.id)
      }
     }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiEmailLoginCallId) {
      if (message.properties?.RestAPIResponceSuccessMessage?.account?.data != null) {
        console.log(message.properties?.RestAPIResponceSuccessMessage?.account?.data, "userInfo")
        localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.account?.data?.attributes))
        //@ts-ignore
        localStorage.setItem("lastLoginDate", new Date().getDate())
        localStorage.setItem("hubSpotIdentificationToken", message.properties?.RestAPIResponceSuccessMessage?.account?.data?.attributes?.hubspot_token)
        localStorage.setItem("userId", message.properties?.RestAPIResponceSuccessMessage?.account?.data?.id)
        localStorage.setItem("token", message.properties?.RestAPIResponceSuccessMessage?.meta?.token)
        
        if(parseInt(message.properties?.RestAPIResponceSuccessMessage?.account?.data?.attributes?.sign_in_count) <= 1){
          localStorage.setItem("onBoardingStep", "1");
          localStorage.setItem("toShowOnboarding", "true");
        }else{
          localStorage.setItem("onBoardingStep", "20");
          localStorage.setItem("toShowOnboarding", "false");
        }
        let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
        let role = userInfo?.role?.data?.attributes?.name;
        let lang = userInfo?.language;
        
       

        if (role == 'superadmin') {
          if(lang && lang?.length > 0){
            if(lang == "German") {
              lang = "de"
            }
            else {
              lang = "en"
            }
            this.props.i18n.changeLanguage(lang);
            this.langaugeChangeHandler(this.props.i18n.language)
          }
          else {
            lang = "en"
            this.props.i18n.changeLanguage(lang);
            this.langaugeChangeHandler(this.props.i18n.language)
    
          }
          toast.success(this.props?.t("EmailAccountLoginController.signInSuccess"));
          this.props.navigation.navigate("AdminConsole")
        }
        else {
          await this.getBrandDetail(userInfo?.brand_id)
          localStorage.setItem("selectedBrand",userInfo?.brand_id)
          if(lang && lang?.length > 0){
            if(lang == "German") {
              lang = "de"
            }
            else {
              lang = "en"
            }
            this.props.i18n.changeLanguage(lang);
            this.langaugeChangeHandler(this.props.i18n.language)
          }
          else {
            lang = "en"
            this.props.i18n.changeLanguage(lang);
            this.langaugeChangeHandler(this.props.i18n.language)

          }

        }

      }

      if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
        var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
        let error = Object.keys(errorMessage[0])
        let errorMsg = errorMessage[0][error[0]]
        console.log("errorMsg",errorMsg);
        
        if (errorMsg == 'Login Failed') {
          toast.error(this.props?.t("EmailAccountLoginController.signInError"))
        }
        else if (errorMsg == 'Account is not found') {
          // localStorage.setItem("userEmail", this.state.email)
          // this.emailConfirmation();   
          // toast.error(errorMsg)
          toast.error(this.props?.t("EmailAccountLoginController.createYourAccount"))
          this.props.navigation.navigate("EmailAccountRegistration")
        }
        else if (errorMsg == 'Account not activated') {
          // localStorage.setItem("userEmail", this.state.email)
          // this.emailConfirmation();  
          // this.resendMail()
        //  toast.error("User is Inactive.")
        toast.error(this.props?.t("EmailAccountLoginController.suspendedAccount"))
        }
        // console.log(error, "error", errorMessage[0][error[0]])
        //  errorMessage.forEach((element:any) => {
        //    this.setState({
        //     messageError:element.error
        //    })


        //  });
        // console.log(this.state.messageError, "error")
        // toast.error(errorMsg)


      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandThemeDetailId) {
      this.setState({ ThemeInfo: message.properties?.RestAPIResponceSuccessMessage?.brand_themes?.data[0] }, () => {
        console.log("this.state.ThemeInfo", this.state.ThemeInfo);
        var primary = {
          "hex": this.state.ThemeInfo?.attributes?.selected_primary_colour ? this.state.ThemeInfo.attributes.selected_primary_colour : "#173e59",
          "font": this.state.ThemeInfo?.attributes?.primary_font ? this.state.ThemeInfo.attributes.primary_font : ""
        }
        var secondary = {
          "hex": this.state.ThemeInfo?.attributes?.selected_secondary_colour ? this.state.ThemeInfo.attributes.selected_secondary_colour : "#1a76b0",
          "font": this.state.ThemeInfo?.attributes?.secondary_font ? this.state.ThemeInfo.attributes.secondary_font : ""
        }
        localStorage.setItem("key", JSON.stringify(primary))
        localStorage.setItem("secondarykey", JSON.stringify(secondary))
        toast.success(this.props?.t("EmailAccountLoginController.signInSuccess"))
        this.goToHomePage();
      })

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.accountActivation2) {
      toast.success("we have sent a mail For activate account")
      this.props.navigation.navigate('EmailVerfication')
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.accountActivation) {
      toast.success("Account activated Successfully")
      this.props.navigation.navigate('EmailAccountLoginBlock')
    }

    //     if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
    //       const userName = message.getData(getName(MessageEnum.LoginUserName));

    //       const password = message.getData(getName(MessageEnum.LoginPassword));

    //       const countryCode = message.getData(
    //         getName(MessageEnum.LoginCountryCode)
    //       );

    //       if (!countryCode && userName && password) {
    //         this.setState({
    //           email: userName,
    //           password: password,
    //           checkedRememberMe: true
    //         });

    //         //@ts-ignore
    //         this.txtInputEmailProps.value = userName;

    //         //@ts-ignore
    //         this.txtInputPasswordProps.value = password;

    //         this.CustomCheckBoxProps.isChecked = true;
    //       }
    //     } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //       const apiRequestCallId = message.getData(
    //         getName(MessageEnum.RestAPIResponceDataMessage)
    //       );

    //       var responseJson = message.getData(
    //         getName(MessageEnum.RestAPIResponceSuccessMessage)
    //       );





    //       var errorReponse = message.getData(
    //         getName(MessageEnum.RestAPIResponceErrorMessage)
    //       );

    //       if (apiRequestCallId != null) {
    //         if (
    //           apiRequestCallId === this.validationApiCallId &&
    //           responseJson !== undefined
    //         ) {
    //           var arrayholder = responseJson.data;

    //           if (arrayholder && arrayholder.length !== 0) {
    //             let regexData = arrayholder[0];

    //             if (regexData && regexData.email_validation_regexp) {
    //               this.emailReg = new RegExp(regexData.email_validation_regexp);
    //             }
    //           }
    //         }

    //         if (apiRequestCallId === this.apiEmailLoginCallId) {
    //           if (responseJson && responseJson.meta && responseJson.meta.token) {
    //             runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    //             this.saveLoggedInUserData(responseJson);
    //             this.sendLoginSuccessMessage();
    //             if(this.state.email === 'super_admin@albertbauer.com'){
    //               // this.navigateToSetPassword();
    //             }
    //             else{
    //               // this.openInfoPage()
    // // console.log(this.props,"pops")
    //               // this.props.history.push("home");
    //             }

    //           } else {
    //             //Check Error Response
    //             this.parseApiErrorResponse(responseJson);
    //             this.sendLoginFailMessage();
    //           }

    //           this.parseApiCatchErrorResponse(errorReponse);
    //         }
    //       }
    //     }
    // Customizable Area End
  }

  // resendMail() {
  //   // e.preventDefault()

  //   const header = {
  //     "Content-Type": configJSON.contentTypeApiAddDetail,

  //   };

  //   const attrs = {

  //     email: localStorage.getItem("userEmail"),

  //   };
  //   const data = {
  //     type: "email_account",
  //     attributes: attrs
  //   };

  //   const httpBody = {
  //     data: data,
  //     language: localStorage.getItem("i18nextLng") == 'en' ? 'English' : 'German'
  //   };

  //   const requestMessage1 = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.accountActivationmailId = requestMessage1.messageId;
  //   console.log(this.accountActivationmailId,"accountActivationmailId");

  //   requestMessage1.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.activateAPiEndPoint
  //   );

  //   requestMessage1.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );
  //   requestMessage1.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );
  //   requestMessage1.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.activateAPiMethod
  //   );
  //   runEngine.sendMessage(requestMessage1.id, requestMessage1);
  // }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  getBrandDetail = async (brandId : any) => {
    const header = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandDetailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  themeInfo(brandId: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandThemeDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations?brand_id=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }
  goToHomePage() {
    this.props.navigation.navigate('HomePage')
    window.location.replace('/Home/HomePage');
  }
  // openInfoPage() {
  //   const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

  //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

  //   this.send(msg);
  // }

  navigateToSetPassword() {
    const msg: Message = new Message(getName(MessageEnum.NavigationNewPasswordMessage));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);


    // const msg: Message = new Message(
    //   getName(MessageEnum.NavigationForgotPasswordMessage)
    // );
    // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
  }
  onSubmit = () => {
    console.log("va")
  }
  goToForgotPassword = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    // msg.addData("Data_key","DataKey")

    // msg.addData("Data_key",{
    //   name:"Yashwanth",
    //   age:23

    // })
    this.send(msg);

  }

  goToEmailAccountRegistration = () => {

    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigateEmailSignUpMessage), "email");
    // msg.addData("Data_key",{
    //   name:"Yashwanth",
    //   age:23

    // })

    this.send(msg);
  }
  resendMail = () => {
    // e.preventDefault()
    console.log("enial", this.state.email);
    const header = {
      "Content-Type": configJSON.validationApiContentType,

    };


    const attrs = {

      email: localStorage.getItem("userEmail")

    };
    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      language: this.state.language == 'en' ? 'English' : 'German'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountActivation2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateEmailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );




    runEngine.sendMessage(requestMessage.id, requestMessage);





  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  doEmailLogIn(e?: any): boolean {
    // e.preventDefault();

    this.setState({
      loader: true
    })
    // this.props.navigation.navigate("SocialMediaAccountRegistrationScreen")

    if (this.state.email === '') {
      // this.emailError = true
      this.setState({
        emailError: true
      })
    }

    if (this.state.email.length > 0) {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) {
        // this.emailError=false
        // this.emailErrorStatus= true

        this.setState({
          emailError: false
        })
        this.setState({
          emailErrorStatus: true
        })


      }
    }



    if (this.state.password === '') {
      this.setState({
        passwordError: true
      })
    }
    else {

      // if (
      //   this.state.email === null ||
      //   this.state.email.length === 0 ||
      //   !this.emailReg.test(this.state.email)
      // ) {

      //   this.showAlert("Error", configJSON.errorEmailNotValid);
      //   return false;
      // }

      // if (this.state.password === null || this.state.password.length === 0) {
      //   this.showAlert("Error", configJSON.errorPasswordNotValid);
      //   return false;
      // }

      const header = {
        "Content-Type": configJSON.loginApiContentType
      };

      const attrs = {
        email: this.state.email,
        password: this.state.password
      };

      const data = {
        type: "email_account",
        attributes: attrs
      };

      const httpBody = {
        data: data
      };

      this.setState({
        open: !this.state.open
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


    }
    return true;
  }

  callGetValidationApi() {

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}
