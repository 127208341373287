import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Menu,
  MenuItem,
  AppBar,
  Tabs,
  Tab,
  Box,
  IconButton,
} from "@material-ui/core";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import { Link, Redirect } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const downIcon = require("../../../components/src/down.png");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import TabScrollButton from '@material-ui/core/TabScrollButton';

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withTranslation, Trans } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  AppBarRoot: {
    background: "#FFFFFF",
    color: "#1a76b0",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "#1a76b0",
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  tableDataLogo: {
    border: "1px solid white",
    paddingLeft: "44px",
    paddingTop: "18px",
    paddingBottom: "8px",
    backgroundColor: "#707880",
    borderRadius: "26px",
  },
  menu: {
    width: "168px !important",
    height: "128px  !important",
  },
  newTemplateBtn: {
    width: "100%",
    background: "#FFFFFF",
    height: "48px",
    boxShadow: "none",
    border: "3px dotted black",
  },
} as any;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  tabValue: any;
  shouldRedirectToNewTemplate: any;
  assetCategoriesList: any;
  assetTypesList: any;
  templatesList: any;
  expanded: any;
  selectedAssetCategoryId: any;
  selectedAssetTypeId: any;
  isSearch: boolean;
  searchData: any;
  searchValue: any;
  showButton : boolean;
  totalPages: number;
  nextPage: boolean;
  setPage: number;
  path: string;
  nextTab:boolean;
  setTab:number;
  totalTab:number;
  selectedTemplateRow: any;
   assetTotalPages: any;
      assetNextPage: any;
      assetSetPage: any;
      assetTotalCount:any;
      totalCount:any;
}
interface SS {
  id: any;
}

class TemplateManagement extends BlockComponent<Props, S, SS> {
  getAssetCategoriesApiCallId: any;
  getAssetTypeApiCallId: any;
  getTemplatesApiCallId: any;
  getTemplatesByFilterApiCallId: any;
  deleteAssetTypeApiCallId: any;
  getBriefingId:any;
  deleteTemplateApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      tabValue: 0,
      anchorEl: null,
      shouldRedirectToNewTemplate: false,
      assetCategoriesList: [],
      assetTypesList: [],
      templatesList: [],
      expanded: "",
      selectedAssetCategoryId: "",
      selectedAssetTypeId: "",
      isSearch: false,
      searchValue: "",
      searchData: [],
      showButton:false,
      totalPages: 0,
      nextPage: false,
      setPage: 1,
      path: "",
      nextTab:false,
      setTab:1,
      totalTab:0,
      selectedTemplateRow: null,
      assetTotalPages: 0,
      assetTotalCount:0,
      assetNextPage: false,
      assetSetPage: 1,
      totalCount:0
    };
  }

  async componentDidMount() {
    await this.getAssetCategories(1);
  }

  getAssetCategories = (page: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoriesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_categories?page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAssetTypes = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_types?asset_category_id=${id}&page=${this.state.assetSetPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChange = (event: any, value: any) => {
    this.setState({ tabValue: value });
  };
  handleIconClick = (event: any, selectedRow: any) => {
    this.setState({
      anchorEl: event.target,
      selectedTemplateRow: selectedRow
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleAssetTypeDelete = (data: any) => {
    console.log(this.state);
    console.log(data);
    if (data?.id !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAssetTypeApiCallId = requestMessage.messageId;
      const header = {
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_types/${
          data?.id
        }`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAssetCategoriesApiCallId) {
        if (responseJson.asset_categories) {
          if (responseJson.asset_categories.data) {
            if (responseJson.asset_categories.data !== null) {
              this.setState({
                assetCategoriesList:[...this.state.assetCategoriesList , ...responseJson.asset_categories.data],
                nextTab:responseJson.next_page,
                totalTab:responseJson.asset_categories.total_pages,
              });
              
              var response = responseJson.asset_categories.data;
              console.log(this.state.assetCategoriesList)
             { this.state.setTab === 1 ? response.map((_data: any, idx: any) => {
                if (idx === 0) {
                  this.setState({
                    selectedAssetCategoryId: _data.id,
                  });
                  this.getAssetTypes(_data.id);
                }
              }):''}
              if(responseJson.next_page){
                this.getAssetCategories(this.state.setTab+1);
                this.setState({setTab: this.state.setTab+1})
                // console.clear();
                console.log("______");
              }
            }
          } 
        }
       
      } else if (apiRequestCallId === this.getAssetTypeApiCallId) {
        if (responseJson.asset_types) {
          if (responseJson.asset_types.data) {
            if (responseJson.asset_types.data !== null) {
              let totalBrandPages = this.state.assetTotalPages;
              this.setState({
                assetTypesList: responseJson.asset_types.data,
                assetNextPage: responseJson.next_page,
                assetTotalCount: responseJson.total_count,
                assetTotalPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalBrandPages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getTemplatesApiCallId) {
        if (responseJson.templates) {
          if (responseJson.templates.data) {
            if (responseJson.templates.data !== null) {
              let totalTemplatePages = this.state.totalPages;
              this.setState({
                templatesList: responseJson.templates.data,
                nextPage: responseJson.next_page,
                totalCount: responseJson.total_count,
                totalPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalTemplatePages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getTemplatesByFilterApiCallId) {
        if (responseJson.templates) {
        
          this.setState({
            searchData: [
              ...this.state.searchData,
              ...responseJson.templates.data,
            ],
          });
          this.setState({
            nextPage: responseJson.next_page,
            totalPages: responseJson.total_pages,
          });
        }
      } else if (apiRequestCallId === this.deleteAssetTypeApiCallId) {
        if (responseJson.message) {
          if (responseJson.message === "deleted") {
            toast.success("Deleted Asset Type successfully");
            this.getAssetCategories(1);
          }
        }
      }else if(apiRequestCallId === this.getBriefingId){
        if(responseJson.templates.data){
          this.setState({showButton : true});
        }
      
      console.log(responseJson.templates.data)
      } else if (apiRequestCallId === this.deleteTemplateApiCallId) {
        if (responseJson.message) {
          if (responseJson?.message?.toUpperCase() === "Record successfully deleted".toUpperCase()) {
            toast.success(responseJson?.message);
            this.setState({
              anchorEl: ""
            });
            this.getTemplates(this.state.selectedAssetTypeId);
          } else {
            toast.error("Template deletion failed");
          }
        } else {
          toast.error("Template deletion failed");
        }
      }
    }
  }


  handleAssetPaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    this.setState({ assetSetPage: value }, () => {
      this.getAssetTypes(this.state.selectedAssetCategoryId);
    });
  };
  handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    this.setState({ setPage: value }, () => {
      this.getTemplates(this.state.selectedAssetTypeId);
    });
  };


  handleTabClick = (id: any) => {
    this.setState({
      selectedAssetCategoryId: id,
      selectedAssetTypeId: "",
    });
    this.getAssetTypes(id);
  };

  getTemplates = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplatesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates?asset_type_id=${id}&briefing_empty_form=false&page=${this.state.setPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBriefing = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBriefingId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates?asset_type_id=${id}&briefing_empty_form=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAccordionChange = (id: any) => {
    if(this.state.expanded == id + "assetTypeAccordion"){
      this.setState({
        expanded: "",
        selectedAssetTypeId: "",
      });
    }else{
      this.setState({
        expanded: id + "assetTypeAccordion",
        selectedAssetTypeId: id,
      });
    }
  };

  exportList = () => {
    var url = `${
      currentUrl
    }/bx_block_data_import_export_csv/templates_exports?asset_category_id=${
      this.state.selectedAssetCategoryId
    }&asset_type_ids=[${
      this.state.selectedAssetTypeId
    }]&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  handleSearchClick = () => {
    this.setState({ searchData: [] , setPage:1 });
    this.setState({ isSearch: true });
    let path = `name=${this.state.searchValue}`;
    this.setState({ path: path });
    this.getTemplatesByFilter(path ,1);
    this.setState({ searchValue: "" });
  };

  fetchMoreData = () => {
    console.clear();
    console.log("Page count", this.state.setPage);
    this.setState({ setPage: this.state.setPage + 1 }, () => {
      this.getTemplatesByFilter(this.state.path , this.state.setPage);
    });
  };

 

  getTemplatesByFilter = (path: any , page:number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplatesByFilterApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates?${path}&&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTemplateDelete = (id: any) => {
    console.log(this.state.selectedTemplateRow)
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTemplateApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_form/templates/${this.state.selectedTemplateRow?.id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  render() {
    const { t } = this.props;
    return (
      <>
        <Grid container style={{ paddingLeft: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Typography>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.templateManagement")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.templateManagement")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("AdminConsole.export")}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder={t("AdminConsole.searchForTemplate")}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!this.state.isSearch ? (
             <>
            <Grid item xs={12}>
               
                <AppBar
                  position="static"
                  className={this.props.classes.AppBarRoot}
                > 
              
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                   
                    classes={{
                      indicator: this.props.classes.indicator,
                    }}
                    //  ScrollButtonComponent={()=>
                    //    <TabScrollButton direction="right" orientation="horizontal"  >
                       
                    //    {/* <ArrowForwardIosIcon onClick={this.fetchMoreTab} /> */}
                    //    </TabScrollButton>
                      
                    
                      
                    
                    //  }
                    
                   
                  >
                    {this.state.assetCategoriesList.map((_data: any) => (
                      <Tab
                        label={
                          <span
                            style={{
                              color: "#1a76b0",
                              fontFamily: "Work Sans",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            {_data.attributes.name}
                          </span>
                        }
                        onClick={(e) => this.handleTabClick(_data.id)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                  

                <Grid container>
                  {this.state.assetTypesList.length !== 0
                    ? this.state.assetTypesList.map((_data: any) => (
                        <Grid item xs={12}>
                          <Accordion
                            style={{
                              boxShadow: "none",
                              background: "#F5F6FA",
                            }}
                            expanded={
                              this.state.expanded ===
                              `${_data.id}assetTypeAccordion`
                            }
                            onChange={() =>
                              this.handleAccordionChange(_data.id)
                            }
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                             
                             
                            >
                              <Grid container>
                              
                              <Grid item xs={12}>
                              <Button
                                onClick={() => this.getTemplates(_data.id)}
                                
                                variant="text"
                              >
                                {this.state.expanded ===
                                `${_data.id}assetTypeAccordion` ? (
                                  <ExpandMoreIcon />
                                ) : (
                                  <ChevronRightIcon />
                                )}
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "13px",
                                    fontFamily: "Work Sans",
                                    color: "#0E0E0E",
                                  }}
                                  onClick={() => this.getBriefing(_data.id)}
                                >
                                  {/* <img src={downIcon} /> {_data.attributes.name} */}
                                  {_data.attributes.name}
                                </Typography>
                              </Button>
                            
                              
                              {/* <Button
                                onClick={() => {
                                 
                                  console.log(this.state);
                                  console.log(_data);
                                  window.location.href = `/NewTemplate?assetCategory=${
                                    this.state.selectedAssetCategoryId
                                  }&assetType=${
                                    _data?.id
                                  }`;
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Work Sans",
                                    textTransform: "uppercase",
                                    letterSpacing: "0px",
                                    opacity: 1,
                                    color: "#1a76b0",
                                  }}
                                  variant="body2"
                                >
                                  Edit Briefing Form
                                </Typography>
                              </Button>
                              <Button
                                onClick={() => this.handleAssetTypeDelete(_data)}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Work Sans",
                                    textTransform: "uppercase",
                                    letterSpacing: "0px",
                                    opacity: 1,
                                    color: "#1a76b0",
                                  }}
                                  variant="body2"
                                >
                                  Delete Asset Type
                                </Typography>
                              </Button> */}
                              </Grid>
                            </Grid>
                            
                            
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item xs={12}>
                               
                                  <TableContainer>
                                    <Table aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Work Sans",
                                              color: "#9EABB5",
                                              textTransform: "uppercase",
                                              opacity: 1,
                                              borderBottom: "0px",
                                              width: "35%",
                                              paddingLeft: "50px",
                                            }}
                                          >
                                            {t("AdminConsole.templateName")}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Work Sans",
                                              color: "#9EABB5",
                                              textTransform: "uppercase",
                                              opacity: 1,
                                              borderBottom: "0px",
                                              width: "12%",
                                            }}
                                          >
                                            {t("AdminConsole.price")}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Work Sans",
                                              color: "#9EABB5",
                                              textTransform: "uppercase",
                                              opacity: 1,
                                              borderBottom: "0px",
                                              width: "15%",
                                            }}
                                          >
                                            {t("AdminConsole.createdBy")}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Work Sans",
                                              color: "#9EABB5",
                                              textTransform: "uppercase",
                                              opacity: 1,
                                              borderBottom: "0px",
                                              width: "20%",
                                            }}
                                          >
                                            {t("AdminConsole.lastModifiedBy")}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Work Sans",
                                              color: "#9EABB5",
                                              textTransform: "uppercase",
                                              opacity: 1,
                                              borderBottom: "0px",
                                              width: "15%",
                                            }}
                                          >
                                            {t("AdminConsole.status")}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      {this.state.templatesList.map(
                                        (_templateData: any) => (
                                          <>
                                            <TableBody
                                              style={{
                                                background: "white",
                                              }}
                                            >
                                              <TableCell
                                                style={{
                                                  padding: "35px",
                                                  border: "0px",
                                                }}
                                              >
                                                <Grid container>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      variant="body2"
                                                      style={{
                                                        paddingLeft: "17px",
                                                        fontFamily: "Work Sans",
                                                        fontSize: "12px",
                                                        color: "#1A1A1A",
                                                        opacity: "100%",
                                                        textTransform:
                                                          "capitalize",
                                                        letterSpacing: 0,
                                                      }}
                                                    >
                                                      {_templateData.attributes.name}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell
                                                style={{ border: "0px" }}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  className={
                                                    this.props.classes.tableData
                                                  }
                                                >
                                                  {_templateData.attributes.price !==
                                                  null
                                                    ? _templateData.attributes.price
                                                    : "0"}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                style={{ border: "0px" }}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  className={
                                                    this.props.classes.tableData
                                                  }
                                                >
                                                  {_templateData.attributes.creator
                                                    ? _templateData.attributes.creator
                                                        .data
                                                      ? _templateData.attributes.creator
                                                          .data !== null
                                                        ? `${
                                                          _templateData.attributes
                                                              .creator.data
                                                              .attributes
                                                              .first_name
                                                          } ${
                                                            _templateData.attributes
                                                              .creator.data
                                                              .attributes
                                                              .last_name
                                                          }`
                                                        : "NA"
                                                      : "NA"
                                                    : "NA"}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                style={{ border: "0px" }}
                                              >
                                                <Grid container spacing={1}>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      variant="body2"
                                                      style={{
                                                        fontFamily: "Work Sans",
                                                        fontSize: "12px",
                                                        color: "#1A1A1A",
                                                        opacity: "100%",
                                                        textTransform:
                                                          "uppercase",
                                                        letterSpacing: 0,
                                                      }}
                                                    >
                                                      {_templateData.attributes.modifier
                                                        ? _templateData.attributes
                                                            .modifier.data
                                                          ? _templateData.attributes
                                                              .modifier.data !==
                                                            null
                                                            ? `${
                                                              _templateData.attributes
                                                                  .modifier.data
                                                                  .attributes
                                                                  .first_name
                                                              } ${
                                                                _templateData.attributes
                                                                  .modifier.data
                                                                  .attributes
                                                                  .last_name
                                                              }`
                                                            : "NA"
                                                          : "NA"
                                                        : "NA"}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      variant="body2"
                                                      style={{
                                                        fontFamily: "Work Sans",
                                                        fontSize: "11px",
                                                        color: "#9EABB5",
                                                        opacity: "100%",
                                                        letterSpacing: 0,
                                                      }}
                                                    >
                                                      {_templateData.attributes.modifier
                                                        ? _templateData.attributes
                                                            .modifier.data
                                                          ? _templateData.attributes
                                                              .modifier.data !==
                                                            null
                                                            ? _templateData.attributes
                                                                .modifier.data
                                                                .attributes
                                                                .updated_at
                                                            : "NA"
                                                          : "NA"
                                                        : "NA"}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell
                                                style={{ border: "0px" }}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  className={
                                                    this.props.classes.tableData
                                                  }
                                                >
                                                  {_templateData.attributes.status}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                style={{ border: "0px" }}
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleIconClick(e, _templateData);
                                                  }}
                                                >
                                                  <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                  id="simple-menu"
                                                  anchorEl={this.state.anchorEl}
                                                  open={Boolean(
                                                    this.state.anchorEl
                                                  )}
                                                  onClose={(e) =>
                                                    this.handleIconClose()
                                                  }
                                                >
                                                  <MenuItem
                                                    style={{
                                                      fontFamily: "Work Sans",
                                                      fontSize: "12px",
                                                      color: "#0E0E0E",
                                                      textTransform:
                                                        "capitalize",
                                                      letterSpacing: 0,
                                                    }}
                                                    onClick={(e) =>
                                                      (window.location.href =
                                                        "/AdminConsole/template/" +
                                                        _templateData.id)
                                                    }
                                                  >
                                                    Edit
                                                  </MenuItem>
                                                  {/* <MenuItem
                                                    style={{
                                                      fontFamily: "Work Sans",
                                                      fontSize: "12px",
                                                      color: "#0E0E0E",
                                                      textTransform:
                                                        "capitalize",
                                                      letterSpacing: 0,
                                                    }}
                                                    onClick={(e) =>
                                                      (window.location.href =
                                                        "/NewTemplate?briefingId=" +
                                                        this.state.selectedAssetTypeId)
                                                    }
                                                  >
                                                    Edit Template
                                                  </MenuItem> */}
                                                  <MenuItem
                                                    style={{
                                                      fontFamily: "Work Sans",
                                                      fontSize: "12px",
                                                      color: "#0E0E0E",
                                                      textTransform:
                                                        "capitalize",
                                                      letterSpacing: 0,
                                                    }}
                                                    onClick={(e) => this.handleTemplateDelete(_templateData.id)}
                                                  >
                                                    {t("AdminConsole.delete")}
                                                  </MenuItem>
                                                </Menu>
                                              </TableCell>
                                            </TableBody>
                                            <Grid container>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ height: "25px" }}
                                              />
                                            </Grid>
                                          </>
                                        )
                                      )}
                                    </Table>
                                  </TableContainer>
                               
                                </Grid>
                                <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  {this.state.totalPages > 1 ? <>
                  <Grid item xs={3}>
                    <Pagination
                      count={this.state.totalPages}
                      variant="outlined"
                      color="primary"
                      page={this.state.setPage}
                      onChange={this.handlePaginationChange}
                    />
                  </Grid>
                  </>:""}
                </Grid>
              </Grid>
                                {/* <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Button
                                    onClick={() => {
                                      window.location.href = `/NewTemplate?assetCategory=${
                                        this.state.selectedAssetCategoryId
                                      }&assetType=${
                                        this.state.selectedAssetTypeId
                                      }`;
                                    }}
                                    className={
                                      this.props.classes.newTemplateBtn
                                    }
                                    variant="contained"
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justify="center"
                                    >
                                      <Grid item>
                                        <AddIcon
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                          }}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="body2"
                                          style={{
                                            paddingTop: "4px",
                                            paddingLeft: "9px",
                                            fontSize: "13px",
                                            color: "#0E0E0E",
                                            textTransform: "uppercase",
                                            fontFamily: "Work Sans",
                                          }}
                                        >
                                          New Template
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Button>
                                </Grid> */}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Pagination
                      count={this.state.assetTotalPages}
                      variant="outlined"
                      color="primary"
                      page={this.state.assetSetPage}
                      onChange={this.handleAssetPaginationChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      style={{ left: "60px" }}
                      onClick={() =>
                        this.setState({ isSearch: false, searchValue: "" })
                      }
                    >
                      Back to Previous Screen
                    </Button>
                  </Grid>
                </Grid>
                <InfiniteScroll
                  dataLength={this.state.searchData.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.nextPage}
                  loader={this.state.totalPages ? "" : <h4>Loading...</h4>}
                >
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "auto",
                            paddingLeft: "50px",
                          }}
                        >
                          {t("AdminConsole.templateName")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "auto",
                          }}
                        >
                          {t("AdminConsole.price")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "auto",
                          }}
                        >
                          {t("AdminConsole.createdBy")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "auto",
                          }}
                        >
                          {t("AdminConsole.lastModifiedBy")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "auto",
                          }}
                        >
                          {t("AdminConsole.status")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.searchData.map((_data: any) => {
                      return (
                        <>
                          <TableBody
                            style={{
                              background: "#E4E8F0",
                            }}
                            onClick={(e) =>
                              (window.location.href =
                                "/AdminConsole/template/" + _data.id)
                            }
                          >
                            <TableCell
                              style={{
                                padding: "35px",
                                border: "0px",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    className={this.props.classes.tableData}
                                  >
                                    {_data.attributes.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "0px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >
                                {_data.attributes.price !== null
                                  ? _data.attributes.price
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "0px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >
                                {_data.attributes.creator.data !== null
                                  ? `${
                                      _data.attributes.creator.data.attributes
                                        .first_name
                                    } ${
                                      _data.attributes.creator.data.attributes
                                        .last_name
                                    }`
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "0px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >
                                {_data.attributes.modifier.data !== null
                                  ? `${
                                      _data.attributes.modifier.data.attributes
                                        .first_name
                                    } ${
                                      _data.attributes.modifier.data.attributes
                                        .last_name
                                    }`
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "0px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >
                                {_data.attributes.status}
                              </Typography>
                            </TableCell>
                          </TableBody>

                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                height: "25px",
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </Table>
                </TableContainer>
                </InfiniteScroll>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(TemplateManagement));
