import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React from 'react'
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditorState, convertToRaw, ContentState, convertFromHTML, } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import html2canvas from "html2canvas";
import draftToHtml from 'draftjs-to-html';
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import Moment from 'react-moment';
import moment from "moment";
import * as SparkMD5 from 'spark-md5';
import BulkUploading from '../../BulkUploading/src/BulkUploadingController';
import { FormatListNumberedRtlTwoTone } from "@material-ui/icons";
import { array } from "yup";
import { flattenDepth, parseInt, truncate } from "lodash";
export const configJSON = require("./config");
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import $ from 'jquery';
import { Console, log } from "console";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any

  // id2List:any
  // id2List:{ droppable: string; droppable2: string; }
  // Customizable Area Start
  // Customizable Area End
}

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const data = [
  {
    id: "car",
    content: "car"
  },
  {
    id: "elelphant",
    content: "elelphant"
  },
  {
    id: "ball",
    content: "ball"
  },
  {
    id: "cat",
    content: "cat"
  },
  {
    id: "dog",
    content: "dog"
  },

]

const itemsData = [
  {
    id: "BMW",
    content: "BMW"
  },
  {
    id: "Volkswagon",
    content: "Volkswagon"
  },
  {
    id: "Mercedes",
    content: "Mercedes"
  },
  {
    id: "Suzuki",
    content: "Suzuki"
  },
  {
    id: "Hundai",
    content: "Hundai"
  },


]



const getItems = (count: any, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`
  }));
interface FilmOptionType {
  title: string;
  year: number;
}
interface S {
  // Customizable Area Start
  permissionFlagViewer: boolean;
  duplicateTaskName: any;
  taskDuplicateFlag: boolean;
  startBriefingOpen1: any;
  startBriefingOpen2: any;
  finalFileOpen: any;
  clickEventCheck: any;
  pageSeparatorIndex: any;
  deleteFileId: any;
  setAnchorEl: any;
  setSourceFileAnchorEl: any;
  specificTaskComment: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  items: any;
  selected: any;
  approval: any;
  todo: any;
  anchorEl: any;
  anchorE4: any;
  setAnchorE4: any;
  grid: boolean,
  list: boolean,
  gridProjectPortfolio: boolean,
  listProjectPortfolio: boolean,
  setOpen: boolean,
  sharedProject: any;
  sharedProjectError: any;
  value: any;
  setOpen1: any;
  setOpen2: any;
  setOpenAnnotaion: any;
  CGI: any,
  anchorE2: any;
  percentage: any;
  openTaskCreateModel: boolean,
  openStatusCreateModel: boolean,
  openAddBriefingModel: boolean,
  openTaskStartBrief: boolean,
  openStartBriefingModel: boolean,
  openAddRequestQuoteModel: boolean,
  category: string,
  accountId: string,
  assetType: string,
  selectedDate: any,
  openLink: boolean,
  dueDate: any,
  isLabel: any,
  projectsOfStatusDraftData: any,
  projectsForAB: any,
  projectsOfStatusWaitingForQuoteData: any,
  projectsOfStatusApprovedData: any,
  projectsOfStatusOnGoingData: any,
  unstartedTasksData: any,
  openTasksData: any,
  onGoingTasksData: any,
  qaTasksData: any,
  doneTasksData: any,
  isCurrentTaskSelected: any,
  currentTask: any,
  currentTaskBriefingData: any,
  accountsData: any,
  taskTemplateData: any,
  taskStatusesData: any,
  deleteTaskStatusData: any,
  statusesData: any,
  taskFileAnnotationsData: any,
  projectNotificationsData: any,
  projectShowData: any,
  taskShowData: any,
  taskStatusData: any,
  taskFileData: any,
  taskFileListData: any,
  selectedProjectId: any,
  selectedProjectStatus: any,
  selectedProjectName: any,
  assetCategoriesData: any,
  assetTypesData: any,
  templatesData: any,
  selectedTaskTemplate: any,
  templateBasedOnSelectedAssetType: any,
  next_page: boolean,
  total_pages: number,
  page: number,
  setPageType2: number,
  setPageType3: number,
  selectedProjectData: any,
  SelectedCategory: any,
  templateFormFieldsData: any,
  templateFormConditionsData: any,
  projectNameSelectedShow: any,
  projectDescriptionSelectedShow: any,
  fileToRawBinary: any,
  permissions: any,
  permissionName: any,
  dynamicStatus: any,
  columnOrderKanban: any,
  columnsKanban: any,
  dynamicMessages: any,
  tasksKanban: any,
  editorState: any,
  editComment: any,
  initialValue: any,
  convertedCommentData: any,
  convertedCommentData2: any,
  uploadedImages: any
  openSubTaskCreateModel: boolean,
  openTaskBlockerCreateModel: boolean,
  blockersData: any,
  subTasksData: any,
  taskActivityData: any,
  fileName: any,
  fileTaskName: any,
  fileId: any,
  file_blob_signed_id_url: string,
  selectedId: string,
  getAllFileTaskAnnotation: any,
  inviteTeamMembersDialog: boolean,
  openProjectProperties: boolean,
  openProjectPropertiesID: any,
  inviteteammembersId: any,
  is_completedBlocker: boolean,
  taskBlockerId: any,
  annotationCompleteData: any,
  setAnnotation: any,
  commentId: any,
  annotationSpecificId: any,
  setApprovedQuote: any,
  approvedQuoteActualDate: any,
  setDraftQuote: any,
  openQuoteActualDate: any,
  setPendingQuote: any,
  pendingQuoteActualDate: any,
  selectedProjectsAccountsData: any,
  selectedProjectsMediaAssetData: any,
  isSearch: boolean;
  searchValue: string;
  anchorElFilter: any;
  anchorElFilter1: any;
  filterValue: any;
  taskData: any;
  resp: any,
  flag: any,
  selectedUsersForProjectPortPropertiesOverViewUpdate: any,
  projectPropertiesAccountsData: any,
  annotation: any;
  annotations: any;
  geomentry: any;
  getAllTaskCommentsData: any,
  getTaskCommentsData: any,
  selectedProjectsMentionsAccountsData: any,
  selectedProjectsMentionsAccountsData2: any,
  draftsArrowFlag: any;
  waitingArrowFlag: any;
  onGoingArrowFlag: any;
  openTaskCommentPopUp: any;
  openReplyBox: any;
  replyCommentData: any;
  activeAnnotations: any;
  addReplyAnnotationId: any;
  replyEditorState: any;
  convertedReplyData: any;
  showResolved: any;
  shareableLink: string;
  // allowAnnotation: any;
  filterType: any;
  version: any;
  anchorElTaskFilter: any;
  allVesrsionDetails: any;
  fileImage: any;
  latestVesrionDetails: any;
  projectDesc: any;
  searchProject: any;
  tableProjectData: any;
  selectData: any;
  selectedBrandId: any
  presignedData: any;
  loading: any;
  setNotification: boolean;
  task_email_notifications: any;
  task_notification_email_frequency: any;
  tagged_email_notifications: any;
  mute_notifications: any;
  account_notification_id: any;
  setShowDuplicateDialog: boolean;
  setDuplicateData: boolean;
  isComingFromBriefingForm: boolean;
  openEditBriefingModel: boolean;
  projectFilterValue: any

  projectNotificationExists: boolean;
  setOpenImageDialog: boolean;
  notify: any;
  selectedFilter: any;
  allTaskStatus: any;
  selectedUsersForFilter: any;
  selectedStatusForFilter: any;
  selectedCategoryForFilter: any;
  selectedAssetTypeForFilter: any;
  paginationCount: any;
  setPage: any;
  fromStart: any;
  fromEnd: any;
  fileNameImage: any;
  presignedDataImage: any;
  assigneeAccountsData: any;
  loadingIndicator: boolean;
  availableRoles: any;
  filteredUserAccountsData: any;
  filteredUserAccountsDataTemp: any;
  searchKeyword: any;
  brandTeamsData: any
  navigationScreenType: any;
  taskAssigneeName: any;
  taskReviewerName: any;
  showAddAnnotation: boolean;
  fileType: any;
  annotationCompleteDataPdf: any;
  setShowRequestQuoteDialog: any;
  modelForDelete: any;
  deleteTaskId: any;
  numPages: any;
  pageNumber: any;
  annotationNumber: any;
  mediaAssetId: any;
  projectMediaAssetsDetail: any;
  fileVersionNumber: any;
  fileVersionNumber2: any;

  uploadDialog: boolean;
  dialogTabValue: any;
  selectedBrand: any;
  assetData: any;
  brandMediaAssets: any
  mediaState: any
  projectFoldersAssetsData: any
  projectFilesAssetsData: any
  mediaFolderAssetdFiles: any
  showActivity: any,
  totalActivityPageCount: any,
  currentPage: any,
  uploadingFilesId: any
  uploadingFilesId1: any
  uploadingFilesId2: any
  uploadingFilesName: any
  uploadingFilesName1: any
  uploadingFilesName2: any
  fileOptionId: any
  fileData: any;
  fileData1: any;
  fileData2: any;
  flagForMediaUpload1: any
  flagForMediaUpload2: any
  placeholder: string;
  submitReviewPopUp: any;
  brandBillingDetails: any;
  errorBillingDetailsPopup: boolean;
  replaceFlag: any;
  filterDueDate: any;
  userInfoPopup: any;
  userInfo: any;
  signedIdAndURLs: any;
  signedIdAndURLs2: any;
  signedIdAndURLs3: any;
  countUpload: any
  countUpload2: any
  flagForImage: any
  brandSelected: any
  mediaAssetSelected: any
  folderSelected: any;
  fullScreenImage: boolean;
  taskStatus: any
  uploadVersion: any;
  latestVersionFlag: any
  deleteDialog: any
  deleteProjectDialog: any
  deleteFinalFileDialog: any
  deleteSourceFileDialog: any
  countForFile: any
  userInfoPopup2: any
  isResolved: any
  finalFileDown: any
  briefingFiles: any
  briefingImages: any
  briefingFieldId: any
  briefingMediaFiles: any
  briefingMediaImages: any
  selectedStatus: any
  deleteStatusDialog: any
  editStatusDialog: any
  brandDetailData: any
  flagForPreviewImage: any
  creteTempleteButton: any
  createTempeletePopUp: any
  tempeleteListing: any
  tempeleteChoosen: any
  longTextEditorContent: any
  filterTaskOnAssetTypes: any
  job_number: any
  sixomc_number: any
  uploadVersionShow: any
  fileInvoiceTaskName: any
  menuflaginvoice: any
  deleteFinalInvoiceDialog: any
  invoiceId: any
  submitProduction: any
  finalFilesIdFromUrl: any
  openPdf: any
  openPdfURL: any;
  numPdfPages: any;
  pdfPageNumber: any;
  createTaskBriefingApiCallId: any;
  selectedTaskFromDraggable: any
  flagCreateTemplate: boolean;
  TemplateName: any;
  // Customizable Area End
}

const initialData = {
  tasks: {
    'task-1': { id: 'task-1', content: 'Take out the garbage' },
    'task-2': { id: 'task-2', content: 'Watch my favorite show' },
    'task-3': { id: 'task-3', content: 'Charge my phone' },
    'task-4': { id: 'task-4', content: 'Cook dinner' },
  },
  columns: {
    'UnStarted': {
      id: 'UnStarted',
      title: 'UnStarted',
      // taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
      tasks: [],

    },
    'Open': {
      id: 'Open',
      title: 'Open',
      // taskIds: [],
      tasks: []
    },
  },
  // Facilitate reordering of the columns
  columnOrder: [{
    id: 'UnStarted',
    title: 'UnStarted',
    messageId: '',

  },
  {
    id: 'Open',
    title: 'Open',
    messageId: '',

  },],

};

interface SS {
  id: any;
  items: any;
  selected: any;
  todo: any;

}

var allowDeselectionTemplate: any = false;

var importedData: number = 0;

var loadAnnotationJqueryCount: number = 1;
var timer: any;

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSignedUrlInBriefingImageID: any;
  bulkTaskCommentUploadingID: any;
  getSignedUrlInBriefingFileID: any;
  getSignedURLForFileUploadTaskFileID: any;
  callUpdateTaskCommentID: any;
  deleteTaskFileFromTheTaskViewID: any;
  projectIndexApiCallIdType1: any;
  projectIndexApiCallIdType121: any
  projectIndexApiCallIdTypeForAB: any;
  projectIndexApiCallIdType2: any;
  projectIndexApiCallIdType3: any;
  projectIndexApiCallIdType4: any;
  projectIndexApiCallIdTypeMount: any;
  projectCreateApiCallId: any;
  openTaskListApiID: any;
  unstartedTaskListApiID: any;
  onGoingTaskListApiID: any;
  qaTaskListApiID: any;
  doneTaskListApiID: any;
  projectApiCallId: any;
  projectTaskCreateAPICallId: any;
  projectDuplicateTaskCreateAPICallId: any;
  getSignedURLForFileUploadId: Array<any> = [];
  getTemplateFormFieldsAPIId: any;
  getTemplateFormConditionsAPIId: any;
  taskTemplateAPiEndPointID: any;
  callCreateBriefingApiId: any;
  callGetStatusListApiId: any;
  callGetAccountListApiId: any;
  callShowTaskApiId: any;
  subtaskAPiEndPointID: any;
  blockersAPiEndPointID: any;
  callCreateStatusApiId: any;
  taskActivityLogAPiEndPointID: any;
  callToBulkFileUpload: any;
  callToInvoiceBulkFileUpload: any
  getTaskFileId: any;
  getInvoiceTaskFileId: any;
  getTaskFileSingleId: any;
  createTaskFileAnnotationId: any;
  getAllAnnotationTaskFileID: any;
  getAllCommentsAnnotationTaskFileID: any;
  getSpecificCommentsAnnotationTaskFileID: any;
  deleteSpecificCommentsAnnotationTaskFileID: any;
  deleteSpecificAnnotationTaskFileID: any;
  callCreateRequestQuotationApiId: any;
  callCreatetaskTemplateApiId: any;
  callCreateSubTaskApiID: any;
  callCreateTaskBlockerApiID: any
  callUpdateTaskQuotationApiID: any;
  callUpdateTaskBlockerApiID: any;
  callStateTransitionsAPIId: any;
  createAnnotationCommentId: any;
  projectShowApiCallId: any;
  projectTeamUpdateCallId: any;
  projectArchiveApi: any;
  projectDeleteApi: any;
  projectApiDuplicateCallId: any;
  getApprovedQuoteId: any;
  getOpenQuoteId: any;
  getPendingQuoteId: any;
  callShowAccountforProjectApiId: any;
  callShowMediaforProjectApiId: any;
  getTasksByFilterApiCallId: any;
  getSignedURLForFileUploadId2: Array<any> = [];
  getSignedURLForFileUploadId3: Array<any> = [];
  updateTaskAPIId: any;
  callUpdateSubTaskApiID: any;
  callGetProjectPropertiesAccountListApiId: any;
  callUpdateProjectPropertiesApiId: any;
  callGetTaskCommentListApiId: any;
  getTaskFileListID: any;
  callShowTaskCommentApiId: any;
  showTaskCommentSpecificId: any;
  callShowAccountforProjectMentionsApiId: any;
  callGetProjectsActiveListApiId: any;
  callGetProjectsAchiveListApiId: any;
  callGetProjectsDeleteListApiId: any;
  callCreateTaskCommentListApiID: any;
  callCreateRequestQuotationForProjectApiId: any;
  callDeleteAccountProjectsApiId: any;
  resolveAnnotationCommentId: any;
  getFilterAnnotationTaskFileID: any;
  finalApprovalId: any;
  getAllVersionOfAssetId: any;
  getBrandListingByFiltersId: any;
  projectDescId: any;
  apiSendNoticationCallId: any;
  apiUpdateDuplicateProjectProperties: any;
  statusUpdateToRequestQuoteId: any;
  callUpdateBriefingApiId: any;
  getProjectNotificationsApiCallId: string;
  userProfileApiCallId: string;
  getNotifyNotificationId: string
  getTaskDetailId: string
  updateAlertID: string;
  getStatusByProjectIdApiCallId: any;
  getRolesApiCallId: any;
  getUserAccountsApiCallId: any;
  getFilteredUserAccountsApiCallId: any;
  apiUpdatePriorityColumn: any;
  getAssetCategoryApiCallId: any;
  getMediaAssetsApiCallId: any
  taskDeleteID: any

  getBrandTeamId: any;
  deleteTaskApiId: any;

  getAssetCategoryFolderApiCallId: any
  getAssetCategoryFileApiCallId: any

  mediaFolderAPIId: any



  getMediaFolderAssetFilesApiCallId: any

  uploadFilesAPIId: any
  callShowTaskActivityApiId: any;
  getMoreTaskActivityApiId: any;

  uploadBriefingFilesAPIId: any;
  submitReviewId: any;
  getCompanyBillingDetailId: any;

  deleteBriefingFIleAPICallID: any

  deleteStatusApiCallId: any
  callEditStatusApiId: any
  deleteSubTaskApiID: any

  getBrandDetailId: any
  callCreateTempleteApiId: any
  callTempleteListingApiId: any

  deleteTaskInvoiceFromTheTaskViewID: any
  uploadInvoiceFilesAPIId: any

  submitProductionApiCallId: any
  array: Array<any> = []
  array2: Array<any> = []
  array3: Array<any> = []
  imgRef: React.RefObject<any>;
  arratMD5: Array<any> = []
  arrat2MD5: Array<any> = []
  temp: any = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.imgRef = React.createRef()


    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      permissionFlagViewer: false,
      duplicateTaskName: "",
      taskDuplicateFlag: false,
      TemplateName: "",
      flagCreateTemplate: false,
      startBriefingOpen1: false,
      startBriefingOpen2: false,
      finalFileOpen: false,
      clickEventCheck: "",
      presignedDataImage: [],
      fileNameImage: [],
      pageSeparatorIndex: [],
      page: 1,
      fromStart: 0,
      fromEnd: 0,
      paginationCount: "",
      loading: false,
      presignedData: [],
      deleteFileId: "",
      shareableLink: '',
      setAnchorEl: null,
      setSourceFileAnchorEl: null,
      specificTaskComment: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      items: data,
      todo: itemsData,
      sharedProject: [],
      sharedProjectError: false,
      selected: getItems(5, 10),
      approval: getItems(5),
      percentage: null,
      anchorEl: null,
      anchorE4: null,
      setAnchorE4: null,
      openLink: false,
      grid: true,
      list: false,
      gridProjectPortfolio: false,
      listProjectPortfolio: true,
      setOpen: false,
      value: 0,
      setOpen1: false,
      setOpen2: false,
      setOpenAnnotaion: false,
      CGI: false,
      anchorE2: null,
      openTaskCreateModel: false,
      openStatusCreateModel: false,
      openSubTaskCreateModel: false,
      openTaskBlockerCreateModel: false,
      openAddBriefingModel: false,
      openTaskStartBrief: false,
      openStartBriefingModel: false,
      openAddRequestQuoteModel: false,
      category: '',
      assetType: '',
      isLabel: '',
      accountId: '',
      selectedDate: moment().format('MMM D YYYY'),
      dueDate: null,
      projectsOfStatusDraftData: [],
      projectsForAB: [],
      projectsOfStatusWaitingForQuoteData: [],
      projectsOfStatusApprovedData: [],
      projectsOfStatusOnGoingData: [],
      unstartedTasksData: [],
      openTasksData: [],
      onGoingTasksData: {},
      qaTasksData: [],
      doneTasksData: [],
      isCurrentTaskSelected: false,
      currentTask: {},
      currentTaskBriefingData: [],
      accountsData: [],
      taskTemplateData: {},
      taskStatusesData: [],
      deleteTaskStatusData: false,
      statusesData: {},
      taskFileAnnotationsData: [],
      projectNotificationsData: [],
      projectShowData: {},
      taskShowData: {},
      taskStatusData: {},
      taskFileData: {},
      taskFileListData: [],
      selectedProjectId: '',
      assetCategoriesData: [],
      assetTypesData: [],
      templatesData: [],
      selectedTaskTemplate: null,
      templateBasedOnSelectedAssetType: {},
      next_page: false,
      total_pages: 1,
      setPage: 1,
      setPageType2: 1,
      setPageType3: 1,
      selectedProjectData: {},
      SelectedCategory: {},
      templateFormFieldsData: [],
      templateFormConditionsData: {},
      projectNameSelectedShow: "",
      projectDescriptionSelectedShow: "",
      fileToRawBinary: {},
      permissions: '',
      permissionName: "",
      dynamicStatus: {},
      columnOrderKanban: initialData.columnOrder,
      columnsKanban: {},
      dynamicMessages: {},
      tasksKanban: initialData.tasks,
      editorState: EditorState.createEmpty(),
      editComment: EditorState.createEmpty(),
      initialValue: EditorState.createEmpty(),
      longTextEditorContent: [EditorState.createEmpty()],
      convertedCommentData: {},
      convertedCommentData2: {},
      uploadedImages: [],
      blockersData: [],
      subTasksData: [],
      taskActivityData: [],
      fileName: [],
      fileTaskName: [],
      fileId: 1,
      file_blob_signed_id_url: "",
      selectedId: "",
      getAllFileTaskAnnotation: [],
      inviteTeamMembersDialog: false,
      openProjectProperties: false,
      openProjectPropertiesID: "",
      inviteteammembersId: "",
      is_completedBlocker: true,
      taskBlockerId: '',
      annotationCompleteData: {},
      setAnnotation: [],
      commentId: "",
      annotationSpecificId: "",
      selectedProjectStatus: "",
      selectedProjectName: "",
      setApprovedQuote: [],
      approvedQuoteActualDate: [],
      setDraftQuote: [],
      openQuoteActualDate: [],
      setPendingQuote: [],
      pendingQuoteActualDate: [],
      selectedProjectsAccountsData: [],
      selectedProjectsMediaAssetData: [],
      isSearch: false,
      searchValue: "",
      anchorElFilter: null,
      anchorElFilter1: null,
      filterValue: "",
      taskData: [],
      resp: null,
      flag: null,
      selectedUsersForProjectPortPropertiesOverViewUpdate: "",
      projectPropertiesAccountsData: [],
      getAllTaskCommentsData: [],
      annotation: {},
      annotations: [],
      geomentry: {},
      getTaskCommentsData: [],
      selectedProjectsMentionsAccountsData: [],
      selectedProjectsMentionsAccountsData2: [],
      draftsArrowFlag: true,
      waitingArrowFlag: true,
      onGoingArrowFlag: [],
      openTaskCommentPopUp: false,
      openReplyBox: false,
      replyCommentData: "",
      activeAnnotations: [],
      addReplyAnnotationId: "",
      replyEditorState: EditorState.createEmpty(),
      convertedReplyData: {},
      showResolved: false,
      // allowAnnotation: false,
      filterType: '5',
      version: '',
      anchorElTaskFilter: null,
      allVesrsionDetails: [],
      fileImage: "",
      latestVesrionDetails: null,
      projectDesc: '',
      searchProject: '',
      tableProjectData: [],
      selectData: null,
      selectedBrandId: null,
      setNotification: false,
      task_email_notifications: '',
      task_notification_email_frequency: '',
      tagged_email_notifications: '',
      mute_notifications: '',
      account_notification_id: '',
      setShowDuplicateDialog: false,
      setDuplicateData: false,
      isComingFromBriefingForm: false,
      openEditBriefingModel: false,
      projectFilterValue: 'name0',

      projectNotificationExists: false,
      setOpenImageDialog: false,
      notify: null,
      selectedFilter: 'name',
      allTaskStatus: [],
      selectedUsersForFilter: [],
      selectedStatusForFilter: [],
      selectedCategoryForFilter: [],
      selectedAssetTypeForFilter: [],
      assigneeAccountsData: [],
      loadingIndicator: false,
      availableRoles: null,
      filteredUserAccountsData: [],
      filteredUserAccountsDataTemp: [],
      searchKeyword: "",
      brandTeamsData: [],
      navigationScreenType: 'projectPortfolio',
      taskAssigneeName: "",
      taskReviewerName: "",
      showAddAnnotation: false,
      fileType: "",
      annotationCompleteDataPdf: EditorState.createEmpty(),
      setShowRequestQuoteDialog: false,
      modelForDelete: false,
      deleteTaskId: null,
      numPages: null,
      pageNumber: 1,
      annotationNumber: 1,
      mediaAssetId: null,
      projectMediaAssetsDetail: null,
      fileVersionNumber: "V 1",
      fileVersionNumber2: "1",
      uploadDialog: false,
      dialogTabValue: "MediaAssest",
      selectedBrand: null,
      assetData: [],
      brandMediaAssets: [],
      mediaState: 0,
      projectFoldersAssetsData: [],
      projectFilesAssetsData: [],
      mediaFolderAssetdFiles: [],
      uploadingFilesId: [],
      uploadingFilesId1: [],
      uploadingFilesId2: [],
      showActivity: false,
      totalActivityPageCount: 1,
      currentPage: 1,
      fileOptionId: null,
      uploadingFilesName: [],
      uploadingFilesName1: [],
      uploadingFilesName2: [],
      fileData: {},
      fileData1: null,
      fileData2: null,
      flagForMediaUpload1: null,
      flagForMediaUpload2: null,
      // submitReviewPopUp: false,
      replaceFlag: 0,
      placeholder: "Add Comment",
      submitReviewPopUp: false,
      brandBillingDetails: {},
      errorBillingDetailsPopup: false,
      filterDueDate: '',
      userInfoPopup: false,
      userInfo: {},
      signedIdAndURLs: [],
      signedIdAndURLs2: [],
      signedIdAndURLs3: [],
      countUpload: 0,
      countUpload2: 0,
      flagForImage: false,
      brandSelected: null,
      mediaAssetSelected: null,
      folderSelected: null,
      fullScreenImage: true,
      taskStatus: null,
      uploadVersion: null,
      latestVersionFlag: false,
      deleteDialog: false,
      deleteProjectDialog: false,
      deleteFinalFileDialog: false,
      deleteSourceFileDialog: false,
      countForFile: 0,
      userInfoPopup2: false,
      isResolved: false,
      finalFileDown: null,
      briefingFiles: [{}],
      briefingImages: [{}],
      briefingFieldId: null,
      briefingMediaFiles: [{}],
      briefingMediaImages: [{}],
      selectedStatus: null,
      deleteStatusDialog: false,
      editStatusDialog: false,
      brandDetailData: null,
      flagForPreviewImage: false,
      creteTempleteButton: null,
      createTempeletePopUp: false,
      tempeleteListing: [],
      tempeleteChoosen: null,
      filterTaskOnAssetTypes: [],
      sixomc_number: null,
      job_number: null,
      uploadVersionShow: null,
      fileInvoiceTaskName: [],
      menuflaginvoice: false,
      deleteFinalInvoiceDialog: false,
      invoiceId: '',
      submitProduction: null,
      finalFilesIdFromUrl: null,
      openPdf: false,
      openPdfURL: null,
      numPdfPages: null,
      pdfPageNumber: 1,
      createTaskBriefingApiCallId: null,
      selectedTaskFromDraggable: null
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  openCreateTempleteModel = (e: any) => {
    this.setState({ createTempeletePopUp: true })
  }
  closeCreateTempleteModel = () => {
    this.setState({ createTempeletePopUp: false })
  }
  handleNotificationOpen = (event: any) => {
    this.setState({
      setNotification: true
    }, () => {

      this.getNotificationBasedonUserIdAndProjectId(this.state.selectedProjectData);
    })
  }

  handleCloseNotification = () => {
    this.setState({
      setNotification: false,
      projectNotificationExists: false
    })
  }
  checkBoxChange = (assetIds: any) => {
    console.log("assetIds", assetIds);

    const assetId = parseInt(assetIds?.id);
    if (localStorage.getItem("task") == '3') {
      if (this.state.briefingMediaFiles[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(assetId)) {
        var temp = this.state.briefingMediaFiles;
        // var tempFile = this.state.uploadingFilesName1;

        const index = temp[0]?.[this.state.fileOptionId].uploadedFileId.indexOf(assetId);
        if (index > -1) {
          temp[0]?.[this.state.fileOptionId].uploadedFileId.splice(index, 1);
          temp[0]?.[this.state.fileOptionId].uploadedFileName.splice(index, 1);
        }
        this.setState({
          briefingMediaFiles: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaFiles)
        )
      }
      // if (this.state.uploadingFilesId1.includes(assetId)) {
      //   var temp = this.state.uploadingFilesId1;
      //   var tempFile = this.state.uploadingFilesName1;

      //   const index = temp.indexOf(assetId);
      //   if (index > -1) {
      //     temp.splice(index, 1);
      //     tempFile.splice(index, 1);
      //   }
      //   this.setState({
      //     uploadingFilesId1: temp,
      //     uploadingFilesName1: tempFile,
      //     fileName: tempFile
      //   }, () => console.log("filename", this.state.fileName, this.state.uploadingFilesId1)
      //   )

      // }
      else {
        var temp = this.state.briefingMediaFiles;
        var tempFile = this.state.uploadingFilesName1;
        if (!temp[0]?.[this.state.fileOptionId]) {
          temp[0][this.state.fileOptionId] = {
            uploadedFileId: [],
            uploadedFileName: []
          }
        }
        temp[0]?.[this.state.fileOptionId].uploadedFileId.push(assetId)
        temp[0]?.[this.state.fileOptionId].uploadedFileName.push(assetIds?.attributes?.name)
        // tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          briefingMediaFiles: temp,
          //  uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaFiles))
      }

    }
    else if (localStorage.getItem("task") == '4') {
      if (this.state.briefingMediaImages[0]?.[this.state.fileOptionId]?.uploadedFileId.includes(assetId)) {
        var temp = this.state.briefingMediaImages;
        // var tempFile = this.state.uploadingFilesName1;

        const index = temp[0]?.[this.state.fileOptionId].uploadedFileId.indexOf(assetId);
        if (index > -1) {
          temp[0]?.[this.state.fileOptionId].uploadedFileId.splice(index, 1);
          temp[0]?.[this.state.fileOptionId].uploadedFileName.splice(index, 1);
        }
        this.setState({
          briefingMediaImages: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaImages))
      }
      // if (this.state.uploadingFilesId1.includes(assetId)) {
      //   var temp = this.state.uploadingFilesId1;
      //   var tempFile = this.state.uploadingFilesName1;

      //   const index = temp.indexOf(assetId);
      //   if (index > -1) {
      //     temp.splice(index, 1);
      //     tempFile.splice(index, 1);
      //   }
      //   this.setState({
      //     uploadingFilesId1: temp,
      //     uploadingFilesName1: tempFile,
      //     fileName: tempFile
      //   }, () => console.log("filename", this.state.fileName, this.state.uploadingFilesId1)
      //   )

      // }
      else {
        var temp = this.state.briefingMediaImages;
        if (!temp[0]?.[this.state.fileOptionId]) {
          temp[0][this.state.fileOptionId] = {
            uploadedFileId: [],
            uploadedFileName: []
          }
        }
        temp[0]?.[this.state.fileOptionId].uploadedFileId.push(assetId)
        temp[0]?.[this.state.fileOptionId].uploadedFileName.push(assetIds?.attributes?.name)
        // tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          briefingMediaImages: temp,
          // uploadingFilesName1: tempFile,
          // fileName: tempFile
        }, () => console.log("briefingMediaFiles", this.state.briefingMediaImages))
      }

    }
    else {
      if (this.state.uploadingFilesId.includes(assetId)) {
        var temp = this.state.uploadingFilesId;
        var tempFile = this.state.uploadingFilesName;
        const index = temp.indexOf(assetId);
        if (index > -1) {
          temp.splice(index, 1);
          tempFile.splice(index, 1);
        }
        this.setState({
          uploadingFilesId: temp,
          // uploadingFilesId2 : temp , 
          uploadingFilesName: tempFile
        }, () => console.log("uploadingFilesIda", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))

      }
      else {
        var temp = this.state.uploadingFilesId;
        var tempFile = this.state.uploadingFilesName;
        temp.push(assetId)
        tempFile.push({ name: assetIds?.attributes?.name })
        this.setState({
          uploadingFilesId: temp,
          // uploadingFilesId2 : temp,
          uploadingFilesName: tempFile
        }, () => console.log("uploadingFilesIdb", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))
      }
      console.log("uploadingFilesId", this.state.uploadingFilesId);
    }




  }
  uploadBriefingFiles = () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody;

    httpBody = {
      data: {
        "template_form_field_id": parseInt(this.state.fileOptionId),
        // "media_asset_file_ids": this.state.uploadingFilesId,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadBriefingFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/template_form_fields/template_file_clone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadFiles = () => {
    this.setState({ loading: true })
    if (this.state.replaceFlag == 1) {
      this.deleteTaskFile(this.state.deleteFileId)
    }
    console.log("uploadversion", this.state.uploadVersion);

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody;

    if (localStorage.getItem("task") == "2") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": false,
          "source_file": true,
        }
      };
    }
    else if (localStorage.getItem("task") == "1" && this.state.uploadVersion != null) {
      httpBody = {
        data: {
          // "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          // "final_file": false,
          // "source_file": true,
        }
      };

    }
    else if (localStorage.getItem("task") == "1" && this.state.uploadVersion == null) {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": true,
          "source_file": false,
        }
      };
    }
    else if (localStorage.getItem("task") == "0") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": false,
          "source_file": false,
        }
      };
    }




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.uploadVersion != null ?
        `bx_block_upload_media/task_files/${this.state.uploadVersion}`
        : `bx_block_project_portfolio/tasks/task_file_clone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.uploadVersion != null ? "PUT" : "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadInvoiceFromMediaAsset = () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody = {
      data: {
        "task_id": parseInt(this.state.currentTask.id),
        "media_asset_file_ids": this.state.uploadingFilesId,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadInvoiceFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/invoices/clone_media_asset_files"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUploadOpenDialog = (optionId?: any) => {
    this.setState({
      uploadDialog: true,
      briefingFieldId: optionId
    });
  };
  handleUploadCloseDialog = () => {
    this.setState({
      uploadingFilesId: [],
      uploadingFilesId1: [],
      uploadingFilesId2: [],
      uploadingFilesName: [],
      // uploadedImages : []
      uploadDialog: false,
      mediaState: 0
    });
  };
  handleUploadTabChange = (event: any, value: any) => {
    this.setState({ dialogTabValue: value });
  };

  getNotificationBasedonUserIdAndProjectId = (_data: any) => {

    const header = { token: localStorage.getItem("token") };
    const userId = localStorage.getItem("userId");
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectNotificationsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_notification_settings/project_notifications?account_id=${userId}&project_id=${_data.id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setNotications(e?: any) {
    //console.log(e.target.name, "name")
    if (e.target.name == "task_email_notifications") {
      this.setState({
        task_email_notifications: !this.state.task_email_notifications
      }, () => {
        //console.log(this.state.task_email_notifications, "emailNotification")
        //this.upDateNotifications(this.state.task_email_notifications)
      });
    }
    if (e.target.name == "task_notification_email_frequency") {
      this.setState({
        task_notification_email_frequency: e.target.value
      }, () => {
        //console.log(this.state.task_notification_email_frequency, "task_notification_email_frequency")
        this.upDateNotifications(this.state.task_notification_email_frequency)
      });
    }
    if (e.target.name == "tagged_email_notifications") {
      this.setState({
        tagged_email_notifications: !this.state.tagged_email_notifications
      }, () => {
        //console.log(this.state.tagged_email_notifications, "tagged_email_notifications")
        this.upDateNotifications(this.state.tagged_email_notifications)
      });
    }
    if (e.target.name == "mute_notifications") {
      this.setState({
        mute_notifications: !this.state.mute_notifications
      }, () => {
        //console.log(this.state.mute_notifications, "mute_notifications")
        this.upDateNotifications(this.state.mute_notifications)
      });
    }
    //console.log(this.state.task_email_notifications, this.state.task_notification_email_frequency, this.state.tagged_email_notifications, this.state.mute_notifications, "overallStates");
  }

  upDateNotifications(value?: any) {

    //console.log(value, "state");
    //console.log(this.state.task_email_notifications, "value2");
    const header = { "Content-Type": "application/json", "token": localStorage.getItem("token") };
    const data = {
      "project_id": this.state.selectedProjectData?.id,
      "tagged_email_notifications": this.state.tagged_email_notifications,
      "task_email_notifications": this.state.task_email_notifications,
      "mute_notifications": this.state.mute_notifications,
      "task_notification_email_frequency": this.state.task_notification_email_frequency
    };

    const httpBody = { data: data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendNoticationCallId = requestMessage.messageId;

    if (this.state.projectNotificationExists === false) {

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_notification_settings/project_notifications`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

    } else {

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_notification_settings/project_notifications/${this.state.account_notification_id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  setNotificatioPreferences(value?: any) {
    //console.log('valuevalue', value);
    if (value && value.length > 0) {
      let data = value.forEach((item: any) => {
        this.setState({
          task_notification_email_frequency: item.attributes.task_notification_email_frequency,
          mute_notifications: item.attributes.mute_notifications,
          tagged_email_notifications: item.attributes.tagged_email_notifications,
          task_email_notifications: item.attributes.task_email_notifications,
          account_notification_id: item.id
        })
      })
    } else {
      this.DefaultAccountNotificationSettings();
    }
  }

  DefaultAccountNotificationSettings() {

    this.setState({
      task_notification_email_frequency: "",
      mute_notifications: false,
      tagged_email_notifications: false,
      task_email_notifications: false,
      account_notification_id: ''
    })

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const data = {
      "tagged_email_notifications": true,
      "task_email_notifications": true,
      "mute_notifications": false,
      "task_notification_email_frequency": "immediately"
    };

    // const httpBody = {
    //   data: data
    // };


    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );

    // this.apiSendNoticationCallId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   `bx_block_notification_settings/account_notifications_settings`
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   "POST"
    // );

    // runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  callUpdateDuplicateProjectProperties(id: any, data: any) {
    // debugger;
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateDuplicateProjectProperties = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  onPriorityChange = () => {

    this.state.taskStatusesData.map((data: any, index: any) => {
      if (data.attributes?.priority && data.attributes.status !== "Done") {

        this.callUpdatePriorityColumn(data.id, index, data.attributes.status)
      }
    })


  }

  // update priorty api

  callUpdatePriorityColumn(id: any, priority: any, status: any) {

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var project_id = localStorage.getItem("selectedProjectId");
    const data = {
      "status": status,
      "priority": priority + 1
    }
    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdatePriorityColumn = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customised_order_status/task_statuses/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProjectDesc = () => {
    // debugger;
    var selectedProjectId = JSON.parse(localStorage.getItem('selectedProjectId') || '{}')
    const header = {
      // "Content-Type": configJSON.createAnnotationCommentContentType,
      token: localStorage.getItem("token")
    };
    // const httpBody = {
    //   data: {
    //     is_resolved: true,
    //   }
    // };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectDescId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects/` + selectedProjectId
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseLoader = () => {
    this.setState({
      loadingIndicator: false
    })
  };

  getRoles = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_roles_permissions/roles`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserAccounts = () => {
    const brand_id: any = localStorage.getItem("selectedBrandID");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    //&brand_id=${brand_id}&role_id=${roles}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?page=${1}&per_page=${50}&ab_users=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //  componentDidUpdate() {
  //    this.getNotifyNotifications()
  //   }
  async componentDidUpdate() {
    const selectedProjectName = localStorage.getItem("selectedProjectName")
    const item: any = localStorage.getItem("selectedProjectId")
    if (selectedProjectName !== this.state.selectedProjectName) {
      this.setState({
        selectedProjectName: selectedProjectName,
      }, () => {
        this.callShowProjectsApi(item)
      })
    }
  }

  templeteListingApi = () => {
    const brand_id: any = localStorage.getItem("selectedBrandID");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callTempleteListingApiId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    //&brand_id=${brand_id}&role_id=${roles}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_templates?page=1&per_page=1000`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async componentDidMount() {
    const item: any = localStorage.getItem("selectedProjectId")
    const assetId = localStorage.getItem("selectedBrand")
    let s = localStorage.getItem("searchtype")
    let d = localStorage.getItem("retry")
    if (d == "yes") {
      // alert("ye gogogo")
      localStorage.removeItem("retry")
      window.location.reload()
    }
    console.log("**********", s)

    if (s === "project") {
      this.setState({ setOpen2: false })
    }




    this.setState({ selectedBrand: assetId }, () => {
      console.log("brandAssettsCon");

    })
    this.getBrandDetail(assetId)
    this.templeteListingApi()
    let userDetailsBeforeParseTemp: any = localStorage.getItem("userInfo");
    let userDetailsTemp = JSON.parse(userDetailsBeforeParseTemp);
    let permissionNameTemp = userDetailsTemp?.role?.data?.attributes?.name
    let value: any = localStorage.getItem("onBoardingStep");
    let valueData: any = localStorage.getItem("toShowOnboarding");
    if (parseInt(value) == 5 && permissionNameTemp == "client" && !(window.location.href).includes("Home/Projectportfolio")) {
      localStorage.setItem("toShowOnboarding", "true");
      if (valueData == "false") window.location.reload();
    }
    var selectedProjectId = localStorage.getItem("selectedProjectId")
    const userDetails = localStorage.getItem("userInfo")

    if (window.location.href.indexOf("Projects") > -1) {
      this.getBrandDetail(assetId)
      this.loadUserDetailsAndRoles();
    } else {
      this.setState({ selectedProjectId: item }, () => {
        this.getBrandDetail(assetId)
        this.callShowProjectsApi(item)
      })
      this.getNotifyNotifications()
      this.getBrandDetail(assetId)
      this.loadUserDetailsAndRoles();
      this.getAllStatusByProjectId();
      this.callGetAccountListApi('')
      this.getBrandTeams()
      this.callGetProjectPropertiesAccountListApi('')
      this.callShowAccountforProjectMentionsApi('')
      this.callGetProjectsActiveListApi();
      this.getRoles();
      this.getUserAccounts();
      this.getBillingDetails();
    }

    var str = window.location.href;
    var arr = str.split("?")[1];
    if (arr != undefined && arr.length > 1) {
      var subArr = arr.split("&");

      console.log("URL ", subArr)
      if (subArr[0] == "navigate=task_view") {
        this.setState({
          navigationScreenType: 'TaskScreen',
          selectedProjectId: subArr[2]?.split("=")[1],
          grid: true,
          list: false,
          gridProjectPortfolio: true,
          listProjectPortfolio: false
        }, () => {
          localStorage.setItem("selectedProjectId", subArr[2]?.split("=")[1]);
          localStorage.setItem("selectedBrandID", subArr[3]?.split("=")[1])
          localStorage.setItem("selectedBrand", subArr[3]?.split("=")[1])
          if (subArr[4]?.split("=")[0] == 'HugoBoss' && subArr[4]?.split("=")[1] == '1') {
            localStorage.setItem("brandGroupSelectedName", "Hugo Boss")
            this.getAllStatusByProjectId();
            if (subArr[5]?.split("=")[0] == 'finalFileId') {
              this.setState({ finalFilesIdFromUrl: subArr[5]?.split("=")[1] })
            }
          }
          else if (subArr[4]?.split("=")[0] == 'finalFileId') {
            this.setState({ finalFilesIdFromUrl: subArr[4]?.split("=")[1] })
            localStorage.removeItem("brandGroupSelectedName")
          }
          else {
            localStorage.removeItem("brandGroupSelectedName")
          }

          localStorage.setItem("selectedGridView", "true")
          // this.callShowProjectsApi(this.state.selectedProjectId);
          this.callShowProjectsApi(subArr[2]?.split("=")[1]);
          this.callShowTaskApi(subArr[1]?.split("=")[1]);
        })
      } else if (subArr[0] == 'navigate=projects') {
        console.log("navigate=projects", subArr[1]?.split("=")[1]);
        localStorage.setItem("selectedProjectId", subArr[1]?.split("=")[1]);

        this.setState({
          navigationScreenType: 'projectsScreen'
        }, () => {
          if (subArr[2]?.split("=")[1] != "newTask") {
            this.callShowTaskApi(subArr[2]?.split("=")[1]);
          } else {
            this.handleClickCreateNewTaskModel();
          }
        })
      } else if (subArr[0] == "navigate=project_view") {
        console.log("Project View", subArr[1]?.split("=")[1])
        localStorage.setItem("selectedProjectId", subArr[1]?.split("=")[1]);

        this.setState({ navigationScreenType: "projectScreen" }, () => {
          this.callShowProjectsApi(subArr[1]?.split("=")[1]);
          this.getNotifyNotifications()

          this.loadUserDetailsAndRoles();
          this.getAllStatusByProjectId();

          // localStorage.removeItem("token")
          // localStorage.removeItem("userInfo")
          //all index api's


          // this.callGetProjectsListApiTypeForAB();
          // this.callGetAccountProjectsListApi();
          this.callGetAccountListApi('')
          this.getBrandTeams()
          //  this.callGetTaskTemplateListApi();
          this.callGetProjectPropertiesAccountListApi('')
          this.callShowAccountforProjectMentionsApi('')
          this.callGetProjectsActiveListApi();
          this.callGetProjectsAchiveListApi();

          this.getRoles();
          this.getUserAccounts();
          this.getBillingDetails();
        })
      }
    }
    if (window.location.href.indexOf("Projects") <= -1) {
      this.getProjectDesc();
    }
    // const assetId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    localStorage.setItem("selectedGridView", "true")
  }
  componentDidMount2 = async () => {

    const item: any = localStorage.getItem("selectedProjectId")
    const brand: any = localStorage.getItem("selectedBrand")
    this.setState({ selectedProjectId: item }, () => {
      this.callShowProjectsApi(item);
    })
    this.getBrandDetail(brand)
    let userDetailsBeforeParseTemp: any = localStorage.getItem("userInfo");
    let userDetailsTemp = JSON.parse(userDetailsBeforeParseTemp);
    let permissionNameTemp = userDetailsTemp?.role?.data?.attributes?.name
    let value: any = localStorage.getItem("onBoardingStep");
    let valueData: any = localStorage.getItem("toShowOnboarding");
    if (parseInt(value) == 5 && permissionNameTemp == "client" && !(window.location.href).includes("Home/Projectportfolio")) {
      localStorage.setItem("toShowOnboarding", "true");
      if (valueData == "false") window.location.reload();
    }
    var selectedProjectId = localStorage.getItem("selectedProjectId")
    const userDetails = localStorage.getItem("userInfo")
    //console.log(userDetails)

    var selectedProjectStatus = localStorage.getItem("selectedProjectStatus")

    this.setState({
      loadingIndicator: true
    }, () => {
    })
    this.getNotifyNotifications()

    this.loadUserDetailsAndRoles();
    this.getAllStatusByProjectId();

    // localStorage.removeItem("token")
    // localStorage.removeItem("userInfo")
    //all index api's


    // this.callGetProjectsListApiTypeForAB();
    // this.callGetAccountProjectsListApi();
    this.callGetAccountListApi('')
    this.getBrandTeams()
    //  this.callGetTaskTemplateListApi();
    this.callGetProjectPropertiesAccountListApi('')
    this.callShowAccountforProjectMentionsApi('')
    this.callGetProjectsActiveListApi();
    this.callGetProjectsAchiveListApi();

    this.getRoles();
    this.getUserAccounts();
    // this.callGetProjectsDeleteListApi();s
    if (selectedProjectId) {
      // this.callShowAccountforProjectApi(selectedProjectId)

    }
    if (this.state.grid) {
      localStorage.setItem("selectedGridView", "true")
    }
    else {
      localStorage.setItem("selectedGridView", "false")
    }
    //  this.callGetTaskFileAnnotationListApi();
    //  this.callGetProjectExportListApi();
    //  this.callGetProjectNotificationSettingsListApi();
    // this.callGetAssetTypeListApi();
    //this.callGetAssetCategoryListApi();
    // this.callGetAssetTypeListApi();
    // this.callGetTemplateListApi(); 

    //all show related api's

    // this.callShowProjectsApi();
    // this.callShowTaskApi();
    // this.callShowBriefingApi();
    // this.callShowBriefedValuesApi();
    // this.callShowTaskQuotationApi();//data is null
    // this.callShowStatusApi();
    // this.callShowTaskCommentApi();
    // this.callShowTaskFileApi();
    // this.callShowTaskFileAnnotationApi();
    // this.callShowTaskFileAnnotationCommentsApi();
    // this.callShowProjectNotificationSettingsApi();
    // this.callShowAccountApi();

    //all list related api's
    //  this.callListTaskFilesApi();

    //all update related api's
    // this.callUpdateProjectsApi();
    // this.callUpdateTaskApi();
    // this.callUpdateBriefedValuesApi();
    // this.callUpdateTaskQuotationApi();
    // this.callUpdateStatusApi();
    // this.callUpdateTaskCommentApi();
    // this.callUpdateTaskFileAnnotationApi();
    // this.callUpdateTaskFileAnnotationCommentsApi();
    // this.callUpdateAccountApi();
    // this.callUpdateProjectNotificationSettingsApi();

    //all delete related api's
    // this.callDeleteProjectsApi();
    // this.callDeleteTaskApi();
    // this.callDeleteBriefingApi();
    // this.callDeleteStatusApi();
    // this.callDeleteTaskCommentApi();
    // this.callDeleteTaskFileAnnotationApi();
    // this.callDeleteTaskFileAnnotationCommentsApi();
    //this.callDeleteAccountApi();

    // /Home/Projectportfolio?navigate=project_view&projectId=285)
    // this.callShowProjectsApi(this.state.selectedProjectId);
    // var str = window.location.href;
    // var arr = str.split("?")[1];
    // if (arr != undefined && arr.length > 1) {
    //   var subArr = arr.split("&");
    //   console.clear();
    //   console.log("URL ", subArr)
    //   if (subArr[0] == "navigate=task_view") {
    //     this.setState({
    //       navigationScreenType: 'TaskScreen',
    //       selectedProjectId: subArr[2]?.split("=")[1],
    //       grid: true,
    //       list: false,
    //       gridProjectPortfolio: false,
    //       listProjectPortfolio: true
    //     }, () => {
    //       localStorage.setItem("selectedProjectId", subArr[2]?.split("=")[1]);
    //       this.callShowProjectsApi(this.state.selectedProjectId);
    //       this.callShowTaskApi(subArr[1]?.split("=")[1]);
    //     })
    //   } else if (subArr[0] == 'navigate=projects') {
    //     console.log("navigate=projects", subArr[1]?.split("=")[1]);
    //     this.setState({
    //       navigationScreenType: 'projectsScreen'
    //     }, () => {
    //       if (subArr[1]?.split("=")[1] != "newTask") {
    //         this.callShowTaskApi(subArr[1]?.split("=")[1]);
    //       } else {
    //         this.handleClickCreateNewTaskModel();
    //       }
    //     })
    //   } else if (subArr[0] == "navigate=project_view") {
    //     console.log("Project View", subArr[1]?.split("=")[1])
    //     this.setState({ navigationScreenType: "projectScreen" }, () => {
    //       this.callShowProjectsApi(subArr[1]?.split("=")[1]);
    //     })
    //   }
    // }

    this.getProjectDesc();

  }
  getBrandDetail = async (brand_id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandDetailId = requestMessage.messageId;
    var brandID;
    if (brand_id) {
      brandID = brand_id
    }
    else {
      const userDetails = localStorage.getItem("selectedBrandID")
      brandID = userDetails
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${brandID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBrandTeams = (brand_id?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandTeamId = requestMessage.messageId;
    var brandID;
    if (brand_id) {
      brandID = brand_id
    }
    else {
      const userDetails = localStorage.getItem("selectedBrandID")
      brandID = userDetails
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_sharing_lists?brand_id=${brandID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getMediaAssets = () => {
    console.log("++++")
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/brands/brand_media_asset_listing?page=1&per_page=150`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getNotifyNotifications = async () => {
    // console.log("taskID ", taskID);
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotifyNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/alert_notifications/notify`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
    // this.callGetTaskActivityListApi();
    //console.log(this.state.taskActivityData);

  };
  handleClickStatusMenu = (event: any) => {
    this.setState({
      anchorE4: event.currentTarget,
    })
  }

  handleCloseDuplicateDialog = () => {
    this.setState({
      setShowDuplicateDialog: false,
    });
  };
  handleCloseRequestQuoteDialog = () => {
    this.setState({
      setShowRequestQuoteDialog: false,
    });
  };

  handleOpenDuplicateDialog = (e: any) => {
    this.setState({
      setShowDuplicateDialog: true,
    });
  };

  handleOpenRequestQuoteDialog = (e: any) => {
    this.setState({
      setShowRequestQuoteDialog: true,
    })
  }
  pdfView(name: any, url: any) {
    this.setState({ openPdfURL: { "name": name, "url": url } }, () => {
      this.setState({ openPdf: true })
    })
  }

  goToPrevPdfPage = () => {
    this.setState({
      pdfPageNumber: this.state.pdfPageNumber - 1
    });
  }

  goToNextPdfPage = () => {
    this.setState({
      pdfPageNumber: this.state.pdfPageNumber + 1
    });
  }

  onDocumentPdfLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPdfPages: numPages
    })
  }
  handleUpdateDuplicateProjectProperties = (title: any, description: any) => {

    let data = {
      name: title,
      description: description,

    }
    localStorage.setItem("selectedProjectName", title)
    // console.clear()
    //console.log("+++++")
    //console.log(data)
    const item: any = this.state.selectedProjectData
    // const item: any = localStorage.getItem("selectedProjectId")
    this.callUpdateDuplicateProjectProperties(item.id, data)

    this.handleCloseModalProject()
  }

  async screenshot(element: any, imageFileName: any) {
    const canvas = await html2canvas(element, { allowTaint: true, useCORS: true });
    const image = canvas.toDataURL("image/png", 1.0);
    console.log(image);
  }

  download() {
    var url = `${currentUrl
      }/bx_block_download/task_file_downloads?task_file_id=${this.state.finalFileDown}&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    //console.log("Url", url)
    anchor.setAttribute("href", url);
    // anchor.setAttribute("download", url);
    anchor.click();
  }

  handleCloseModal1 = () => {
    this.setState({
      openLink: false,
    });
  };


  async getBase64FromUrl(url: any) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }


  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  // computeChecksumMd5Hash = (file: File) =>{

  // };

  removeImageAndGetSignedUrl = (file: any) => {
    console.log("File", file)
    this.setState({ fileName: file.name });
    this.setState({ fileToRawBinary: file, replaceFlag: 0 });
    this.deleteTaskFile(this.state.deleteFileId)
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    });
  }
  getSignedUrl = async (file: any) => {
    console.log("File", file)
    this.array3.push(file)
    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    const md5 = await this.computeChecksumMd5(file);
    this.arrat2MD5.push(md5)
    console.log('computeChecksumMd5', md5);

    await this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    // });
  }
  getSignedUrlTaskFileComment = (file: any) => {
    //console.log("File", file)
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUploadTaskFile(file.size, md5, file.name, file.type)
    });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: URL.createObjectURL(file) } });
      }
    );
  }
  getSignedUrlInBriefing = async (file: any) => {
    //console.log("File", file)
    const temp = [];
    this.array.push(file)
    temp.push(file.name)

    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    this.setState({ fileName: temp }, () => console.log("filename", this.state.fileName))
    this.setState({ fileToRawBinary: file, fileData1: file })
    const md5 = await this.computeChecksumMd5(file);
    this.arratMD5.push(md5)
    await this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedUrlInBriefingFile(file.size, md5, file.name, file.type)
    // });
    var templateData = this.state.templateFormFieldsData;
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(this.state.fileOptionId));
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = false;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }

          if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = true;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }
        })
      }
    }
  }
  getSignedUrlImageInBriefing = async (file: any) => {
    this.array2.push(file)
    //console.log("File", file)
    const temp = [];
    temp.push(file.name)
    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    this.setState({ fileNameImage: temp }, () => console.log("filename", this.state.fileNameImage))
    this.setState({ fileToRawBinary: file, fileData2: file })
    const md5 = await this.computeChecksumMd5(file);
    await this.getSignedURLForFileUpload(file, file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedUrlInBriefingImageFile(file.size, md5, file.name, file.type)
    // });
    var templateData = this.state.templateFormFieldsData;
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(this.state.fileOptionId));
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = false;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }

          if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
            templateData[findMatch].showField = true;
            if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
              templateData[findMatch].attributes.file_blob_signed_id_url = "";
            }
            templateData[findMatch].attributes.default_value = "";
            templateData[findMatch].value = "";
          }
        })
      }
    }
  }
  uploadImageCallBack(file?: any) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages })

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );
  }
  onEditorStateChange = (editorState?: any, index?: any) => {

    //console.log("editorState", editorState);

    this.setState({
      editorState: editorState
    });
    this.setState({
      convertedCommentData: convertToRaw(this.state.editorState.getCurrentContent())
    });
    //console.log("convertedData", this.state.convertedCommentData);
  }
  onEditorStateChange2 = (editorState: any, id: any) => {
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(id))
    let elementsArray = this.state.templateFormFieldsData;
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    let contentHTML = draftToHtml(contentRaw);

    let editorStateTemp = this.state.longTextEditorContent;
    editorStateTemp[id] = editorState;
    this.setState({
      longTextEditorContent: editorStateTemp
    })
    let text = contentRaw.blocks[0].text
    let dummyevent = {
      target: {
        value: text
      }
    }
    this.state.openEditBriefingModel ? this.handleInputChangeEditBriefing(id, dummyevent) : this.handleInputChange(id, dummyevent)


    console.log("text", this.state.longTextEditorContent);
    elementsArray[index].attributes.value = contentHTML;
    this.setState({
      templateFormFieldsData: elementsArray,
    }, () => {
      // console.log("elementArray",this.state.elementArray)
      console.log("templateFormFieldsDataData2", this.state.templateFormFieldsData)

    });
  }


  onEditorStateCommentTaskSpecific = (editorState?: any) => {
    let contentState = convertFromHTML('<p>Hello</p>');
    const content = ContentState.createFromBlockArray(
      contentState.contentBlocks,
      contentState.entityMap
    );
    this.setState({
      initialValue: EditorState.createWithContent(content),
      editComment: editorState
    });
    this.setState({
      convertedCommentData2: convertToRaw(this.state.editComment.getCurrentContent())
    });
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null
    })
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  }
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    this.setState({
      value: value,
    }, () => {
      console.log("Value change ", this.state.value)
    });
    // this.statvalue=value
  };
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false,
      gridProjectPortfolio: true,
      listProjectPortfolio: false,

    })

  }

  handleClickOpenModal = () => {
    this.setState({
      setOpen1: true
    })

  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")
    const gridvalue = localStorage.getItem("selectedGridView");
    //console.log("gridvalue", gridvalue);
    if (gridvalue) {
      this.setState({
        gridProjectPortfolio: true,
        listProjectPortfolio: false
      })
    } else {
      this.setState({
        gridProjectPortfolio: false,
        listProjectPortfolio: true
      })
    }
    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      //console.log("brandID", userDetails)

      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      const permissions = userDetails?.role?.data?.attributes?.permission
      const permissionName = userDetails?.role?.data?.attributes?.name
      const selectedProjectStatus = localStorage.getItem("selectedProjectStatus")
      const selectedProjectName = localStorage.getItem("selectedProjectName")
      const selectedProjectId = localStorage.getItem("selectedProjectId")
      if (selectedProjectId) {
      }

      this.setState({
        selectedProjectName: selectedProjectName,
        selectedProjectStatus: selectedProjectStatus,
        permissions: permissions,
        permissionName: permissionName
      })

      if (permissionName !== "albertbauer" && (permissions === "approver" || permissions === "viewer" || permissions === "brand_manager")) {
        //console.log("brandID", userDetails)
        localStorage.setItem("selectedBrandID", userDetails?.brand_id)
        localStorage.setItem("selectedBrand", userDetails?.brand_id);
      }

      // if (selectedProjectStatus === "New" || selectedProjectStatus === "Quote Approved"
      //   || selectedProjectStatus === "Briefing Completed" || selectedProjectStatus === "Pending Quote Approval") {

      // this.callShowAccountforProjectApi(selectedProjectId)

      // this.callShowAccountforProjectApi(selectedProjectId)
      // this.getApprovedQuote()
      // this.getOpenQuote()
      // this.getPendingQuote()
      // this.callGetAccountListApi('');

      // } else {
      //this.callShowAccountforProjectApi(selectedProjectId)

      if (window.location.href.indexOf("Projects") > -1) {
        this.callGetProjectsListApiType1();
      } else {
        this.callGetStatusListApi();
        this.callGetProjectsListApiType1();
        this.callGetProjectsListApiType2();
        this.callGetProjectsListApiType3();
        this.callGetProjectsListApiType4();
        this.callGetAssetCategoryListApi();
        this.callGetProjectsListApiTypeMounting();
        this.callGetTaskActivityListApi();
        this.handleLoadTaskData();
      }
    }

  }
  updateAlert = (taskId: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        "task_id": parseInt(taskId)

      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAlertID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks/hide_alert_notifications"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClickOpenTaskModal = (task?: any) => {

    this.updateAlert(task.id)
    // console.log("task", task);
    this.callGetSubTaskListApi(task.id),
      this.callGetTaskBlockerListApi(task.id),
      this.callShowTaskCommentApi(task.id)
    this.getTaskActivityListApi(task.id)
    console.log("taskHandleSubmit", task);

    var taskStatus = this.state.taskStatusesData.filter((el: any) => el.attributes.status == task?.attributes?.ultimate_state);
    if (taskStatus?.length == 0) {
      taskStatus = this.state.taskStatusesData.filter((el: any) => el.attributes.status2 == task?.attributes?.ultimate_state);
    }
    console.log("taskStatus", taskStatus);

    this.setState({
      setOpen2: true,
      //  openAddBriefingModel: false,
      templateFormConditionsData: [],
      isCurrentTaskSelected: true,
      currentTask: task,
      taskStatus: taskStatus.length > 0 ? taskStatus[0]?.attributes?.status2?.length > 0 ? taskStatus[0]?.attributes?.status2 : task?.attributes?.ultimate_state : null,
      job_number: task?.attributes?.job_number, sixomc_number: task?.attributes?.sixomc_number,
      currentTaskBriefingData: task.attributes?.briefing?.data?.attributes?.briefed_values?.data,
      taskAssigneeName: task?.attributes?.assignee?.data == null ? "" : task?.attributes?.assignee?.data?.attributes?.first_name,
      taskReviewerName: task?.attributes?.reviewer?.data == null ? "" : task?.attributes?.reviewer?.data?.attributes?.first_name,
      userInfo: task?.attributes?.assignee?.data == null ? {} : task?.attributes?.assignee,
      loading: false
    }, () => {
      this.getListTaskFileIndex();
      this.getListInvoiceTaskFileIndex()
      this.handleClickBriefing(this.state.currentTask.attributes.briefing)
      //console.log("Current Task", this.state.currentTask) 
    })

  }

  handleClickOpenTaskModal2 = async (task: any) => {
    //console.log("task", task);
    this.callGetSubTaskListApi(task?.attributes?.task_id),
      this.callGetTaskBlockerListApi(task?.attributes?.task_id),
      this.callShowTaskCommentApi(task?.attributes?.task_id)
    this.getTaskActivityListApi(task?.attributes?.task_id)
    this.taskDetails(task?.attributes?.task_id)

  }
  taskDetails = (taskID: any) => {
    //console.log("taskID ", taskID);
    const header = {
      "Content-Type": configJSON.getAllVesrionsOfAssetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskDetailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks/${taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllVesrionsOfAssetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  openSpecificTaskComment = (commentId: any) => {
    this.setState({ openTaskCommentPopUp: true, commentId: commentId }, () => { this.showTaskCommentSpecific(commentId) })
  }
  cancelSpecificTaskComment = () => {
    this.setState({ openTaskCommentPopUp: false })
  }
  openModalAnnotate = () => {
    this.setState({ setOpenAnnotaion: true, latestVersionFlag: true, filterType: '5' });
  }

  handleCloseFinalFile = () => {
    this.setState({ finalFileOpen: false })
  }

  handleCloseAnnotationModal2 = () => {
    (this.state.fullScreenImage); {
      this.setState({ fullScreenImage: true });
      this.resetAnnotationInPdf();
      this.loadJqueryForAnnotation();
    }
    this.setState({ finalFileOpen: true })
  }

  handleCloseAnnotationModal3 = () => {
    this.setState({ finalFileOpen: false })
    this.cancelAddAnnotationForPdf();
    // this.getTaskActivityListApi(this.state.currentTask.id);
    importedData = 0;
    loadAnnotationJqueryCount = 1;
    (this.state.fullScreenImage); {
      this.setState({ fullScreenImage: true });
      this.resetAnnotationInPdf();
      this.loadJqueryForAnnotation();
    }
    this.setState({
      setOpenAnnotaion: false,
      finalFileOpen: false,
      // allowAnnotation: false,
      fileType: '',
      getAllFileTaskAnnotation: [],
      fileVersionNumber: "V 1",
      fileVersionNumber2: "1",
      fileData: {}
    })
    $(document).off('click', '.react-pdf__Page');
  }

  // handleCloseAnnotationModal4 = () => {
  //   this.setState({finalFileOpen1: false})
  //   this.cancelAddAnnotationForPdf();
  //   // this.getTaskActivityListApi(this.state.currentTask.id);
  //   importedData = 0;
  //   loadAnnotationJqueryCount = 1;
  //   this.setState({
  //     setOpenAnnotaion: false,
  //     finalFileOpen: false,
  //     // allowAnnotation: false,
  //     fileType: '',
  //     getAllFileTaskAnnotation: [],
  //     fileVersionNumber: "V 1",
  //     fileData: {}
  //   })
  //   $(document).off('click', '.react-pdf__Page');
  // }


  handleCloseAnnotationModal = () => {
    this.cancelAddAnnotationForPdf();
    this.getTaskActivityListApi(this.state.currentTask.id);
    importedData = 0;
    loadAnnotationJqueryCount = 1;
    (this.state.fullScreenImage); {
      this.setState({ fullScreenImage: true });
      this.resetAnnotationInPdf();
      this.loadJqueryForAnnotation();
    }
    this.setState({
      setOpenAnnotaion: false,
      // allowAnnotation: false,
      fileType: '',
      getAllFileTaskAnnotation: [],
      fileVersionNumber: "V 1",
      fileVersionNumber2: "1",
      fileData: {}
    })
    $(document).off('click', '.react-pdf__Page');
  }
  convertData = (d: any) => {
    let flot = String(d).split('.')
    let kk = flot[0].split('')
    let value = String(d).split('')
    if (flot.length > 1) {
      if (kk.length === 4) {
        kk.splice(1, 0, ",")
      } else if (kk.length === 5) {
        kk.splice(2, 0, ",")
      } else if (kk.length === 6) {
        kk.splice(1, 0, ",")
        kk.splice(4, 0, ",")
      } else if (kk.length === 7) {
        kk.splice(2, 0, ",")
        kk.splice(5, 0, ",")
      } else if (kk.length === 8) {
        kk.splice(1, 0, ",")
        kk.splice(4, 0, ",")
        kk.splice(7, 0, ",")
      }
    } else {
      if (value.length === 4) {
        value.splice(1, 0, ",")
      } else if (value.length === 5) {
        value.splice(2, 0, ",")
      } else if (value.length === 6) {
        value.splice(1, 0, ",")
        value.splice(4, 0, ",")
      } else if (value.length === 7) {
        value.splice(2, 0, ",")
        value.splice(5, 0, ",")
      } else if (value.length === 8) {
        value.splice(1, 0, ",")
        value.splice(4, 0, ",")
        value.splice(7, 0, ",")
      }
    }
    if (flot.length > 1) {
      return kk.join('') + "." + flot[1]
    } else {
      return value.join('')
    }
  }

  convertWords = (d: any) => {
    let data = d.split('.')
    if (d.length > 10) {
      let word = d.substring(0, 10) + "..." + data[data.length - 1]
      return word
    } else {
      return d
    }
  }

  convertTitle = (d: any) => {
    if (d?.length > 22) {
      let tword = d.substring(0, 22) + "..."
      return tword
    } else {
      return d
    }
  }

  convertCategory = (d: any) => {
    if (d?.length > 33) {
      let tword = d.substring(0, 33) + "..."
      return tword
    } else {
      return d
    }
  }

  getAllVersionOfAsset = () => {
    const header = {
      "Content-Type": configJSON.getAllVesrionsOfAssetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllVersionOfAssetId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllVesrionsOfAssetEndPoint + this.state.fileId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllVesrionsOfAssetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickChangeVesrion = (id: any) => {
    var index = this.state.allVesrsionDetails.findIndex((element: any) => element.id == id);
    loadAnnotationJqueryCount = 1;
    if (index >= 0) {
      let data = {
        ...this.state.allVesrsionDetails[index]?.attributes,
        "reviews_submitted": true,
        "status": true
      };
      this.cancelAddAnnotationForPdf();
      this.resetAnnotationInPdf();
      $(document).off('click', '.react-pdf__Page');
      this.setState({
        latestVersionFlag: false,
        fileId: this.state.allVesrsionDetails[index].id,
        file_blob_signed_id_url: this.state.allVesrsionDetails[index]?.attributes?.file_blob_signed_id_url,
        fileVersionNumber: `V ${index + 1}`,
        fileVersionNumber2: index + 1,
        fileImage: this.state.allVesrsionDetails[index]?.attributes?.name,
        fileType: this.state.allVesrsionDetails[index]?.attributes?.file_type,
        fileData: data,
        getAllFileTaskAnnotation: this.state.allVesrsionDetails[index]?.attributes?.task_file_annotations?.data,
      }, () => {
        console.log("filedo", this.state.fileId);

        let annotationData = this.state.getAllFileTaskAnnotation?.map((values: any) => ({
          geometry: { type: "RECTANGLE", x: parseFloat(values?.attributes?.x_coordinate), y: parseFloat(values?.attributes?.y_coordinate), width: parseFloat(values?.attributes?.width), height: parseFloat(values?.attributes?.height) },
          data: { text: values?.attributes?.task_file_annotation_comments?.data[0]?.attributes?.comment, id: parseInt(values?.attributes?.task_file_annotation_comments?.data[0]?.id) }
        }))
        console.log(annotationData)
        this.setState({
          annotations: annotationData,
          loading: false
        }, () => {

          if (this.state.fileType !== "pdf" && this.state.fileType !== "video" && loadAnnotationJqueryCount == 1) {
            this.loadJqueryForAnnotation();
            loadAnnotationJqueryCount = 2;
          }
        })
      })
    }
  }

  onClickChangeToLatestVesrion = () => {
    console.log("run!")
    this.resetAnnotationInPdf();
    this.cancelAddAnnotationForPdf();
    loadAnnotationJqueryCount = 1;
    $(document).off('click', '.react-pdf__Page');
    this.setState({
      latestVersionFlag: true,
      fileId: this.state.latestVesrionDetails?.id,
      file_blob_signed_id_url: this.state.latestVesrionDetails?.attributes?.file_blob_signed_id_url,
      fileVersionNumber: `V ${this.state.allVesrsionDetails.length + 1}`,
      fileVersionNumber2: this.state.allVesrsionDetails.length + 1,
      fileImage: this.state.latestVesrionDetails?.attributes?.name,
      fileType: this.state.latestVesrionDetails?.attributes?.file_type,
      fileData: this.state.latestVesrionDetails?.attributes
    },
      () => {
        // this.taskFileShow(this.state.fileId), 
        this.getAllAnnotationTaskFile();
      })
  }

  onClickNextImage = () => {
    var files = this.state.fileTaskName.filter((item: any) => item?.attributes?.source_file == false && item?.attributes?.final_file == true);
    var index = files.findIndex((element: any) => element.id == this.state.fileId);
    this.cancelAddAnnotationForPdf();
    $(document).off('click', '.react-pdf__Page');
    this.resetAnnotationInPdf();
    importedData = 0;
    loadAnnotationJqueryCount = 1;
    if (index >= 0 && files.length - 1 > index) {
      this.setState({
        fileId: files[index + 1].id,
        file_blob_signed_id_url: files[index + 1]?.attributes?.file_blob_signed_id_url,
        fileImage: files[index + 1]?.attributes?.name,
        fileType: files[index + 1]?.attributes?.file_type,
        fileVersionNumber: "V 1",
        fileVersionNumber2: "1",
        fileData: files[index + 1]?.attributes,
        getAllFileTaskAnnotation: []
      },
        () => {
          this.taskFileShow(this.state.fileId)
        })
    }
  }

  onClickPrevImage = () => {
    var files = this.state.fileTaskName.filter((item: any) => item?.attributes?.source_file == false && item?.attributes?.final_file == true);
    var index = files.findIndex((element: any) => element.id == this.state.fileId);
    this.cancelAddAnnotationForPdf();
    $(document).off('click', '.react-pdf__Page');
    this.resetAnnotationInPdf();
    importedData = 0;
    loadAnnotationJqueryCount = 1;
    if (index > 0) {
      this.setState({
        fileId: files[index - 1].id,
        file_blob_signed_id_url: files[index - 1]?.attributes?.file_blob_signed_id_url,
        fileImage: files[index - 1]?.attributes?.name,
        fileType: files[index - 1]?.attributes?.file_type,
        fileVersionNumber: "V 1",
        fileVersionNumber2: "1",
        fileData: files[index - 1]?.attributes,
        getAllFileTaskAnnotation: []
      },
        () => {
          this.taskFileShow(this.state.fileId)
        })
    }
  }
  // onSelect = (selectedId: any) => { console.log("Selected", selectedId); this.setState({ selectedId: selectedId, commentId: selectedId }) };
  // onChange = (data: any) => {

  //   this.setState({
  //     setAnnotation: this.state.getAllFileTaskAnnotation?.map((values: any) => (
  //       {
  //         id: values.id,
  //         comment: values.attributes.comment,
  //         mark: { x: parseFloat(values.attributes.task_file_annotation.data.attributes.x_coordinate), y: parseFloat(values.attributes.task_file_annotation.data.attributes.y_coordinate), width: parseFloat(values.attributes.task_file_annotation.data.attributes.width), height: parseFloat(values.attributes.task_file_annotation.data.attributes.height) },
  //         type: "RECT"
  //       }
  //     ))
  //   }, () => { console.log("Heloo"); });

  //   this.setState({ setAnnotation: Object.assign(this.state.setAnnotation, data) }, () => { console.log("Data reset", this.state.setAnnotation) })
  //   console.log("onChange", data);
  //   this.setState({ annotationCompleteData: data[data.length - 1] });
  //   data.filter((x: any) => x.id === this.state.selectedId).map((x: any) => { console.log("Values", x); this.createTaskFileAnnotation(x.mark) });
  // };
  //   data: {text: "hi22", id: 0.3105566268890725}
  // geometry: {type: "RECTANGLE", x: 51.35449062859482, y: 7.7018445419544515, width: 15.333776325732074, height: 11.81172118575925}

  onChange = (annotation: any) => {
    //console.log("OnChange", annotation);
    //console.log("Data from the anno and comments", this.state.getAllFileTaskAnnotation)
    let annotationData = this.state.getAllFileTaskAnnotation?.map((values: any) => ({
      geometry: { type: "RECTANGLE", x: parseFloat(values?.attributes?.x_coordinate), y: parseFloat(values?.attributes?.y_coordinate), width: parseFloat(values?.attributes?.width), height: parseFloat(values?.attributes?.height) },
      data: { text: values?.attributes?.task_file_annotation_comments?.data[0]?.attributes?.comment, id: parseInt(values?.attributes?.task_file_annotation_comments?.data[0]?.id) }
    }))
    //console.log("Extact Data from annotation", annotationData)
    this.setState({
      annotations: annotationData
    }, () => {
      //console.log("data Annotation2", this.state.setAnnotation) 
    })
    this.setState({ annotation }, () => {
      //console.log("OnChange2", this.state.annotation); 
      //console.log("GEO", this.state.annotation.geometry); 
      this.setState({ geomentry: this.state.annotation.geometry, annotationCompleteData: this.state.annotation?.data?.text })
    })
  }
  onChangeForPdf = (x: any, y: any) => {

    console.log(x, y)
    let fileType: any = this.state.fileType

    try {
      let clientHeight: any
      let clientWidth: any
      let xPercentage: number = 0
      let yPercentage: number = 0
      let imgDimes: any

      console.log(this.state.fileType)
      if (fileType == "pdf") {
        let data = document.getElementsByClassName("react-pdf__Page__canvas")
        imgDimes = data[0]
      } else {
        imgDimes = document.getElementById("annotation-image-id");
      }
      console.log(imgDimes)
      if (imgDimes !== null) {
        clientWidth = imgDimes.clientWidth
        clientHeight = imgDimes.clientHeight
        xPercentage = (x / clientHeight) * 100;
        yPercentage = (y / clientWidth) * 100;

      }

      // let clientWidth = document.getElementById("annotation-image-id").clientWidth;
      // let xPercentage = (x/clientHeight)*100;
      // let yPercentage = (y/clientWidth)*100;
      // parseInt(xPercentage),
      //   "y": parseInt(yPercentage),
      console.log(clientWidth, clientHeight)
      console.log(xPercentage, yPercentage)
      let annotationData = {
        "type": "STICKY",
        "x": xPercentage,
        "y": yPercentage,
        "pageNumber": this.state.pageNumber,
        "width": "0",
        "height": "0"
      };
      this.setState({
        geomentry: annotationData,
        showAddAnnotation: true,
        annotationNumber: this.state.annotationNumber + 1
      });
    } catch (err: any) {
      console.log(err)
    }
  }
  cancelAddAnnotationForPdf = () => {
    this.setState({
      annotationCompleteDataPdf: EditorState.createEmpty(),
      showAddAnnotation: false,
      convertedReplyData: {},
      numPages: null,
      pageNumber: 1,
      annotationNumber: 1
    })
  }
  onSubmit = (annotation: any) => {
    const { geometry, data } = annotation

    this.setState({
      loading: true,
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      })
    }, () => {
      this.createTaskFileAnnotation(this.state.geomentry)
    })
  }
  onSubmitOnPdf = () => {
    if (this.state.convertedReplyData?.blocks?.length > 0) {
      this.setState({
        loading: true
      });
      this.createTaskFileAnnotation(this.state.geomentry)
    }
  }
  // onClickAllowAnnotation = () => {
  //   this.setState({
  //     allowAnnotation: true
  //   })
  // }
  onReplyTextChange = (replyEditorState: any) => {
    //console.log("editorState", replyEditorState);

    this.setState({
      replyEditorState: replyEditorState
    });
    this.setState({
      convertedReplyData: convertToRaw(this.state.replyEditorState.getCurrentContent())
    });
  }
  onSavingCommentReplyBox = (commentId: any) => {
    this.setState({
      loading: true
    })
    if (this.state.convertedReplyData && this.state.convertedReplyData !== {}) {
      var comment = draftToHtml(convertToRaw(this.state.replyEditorState.getCurrentContent()))
      let ArrayObjects: any = [];
      Object.values(this.state.convertedReplyData.entityMap).forEach((val: any) => {
        let data: any = {
          [val.data.text]: parseInt(val.data.url)
        }
        ArrayObjects.push(data);
      });
      let referencesData: any = Object.assign({}, ...ArrayObjects);
      const header = {
        "Content-Type": configJSON.createAnnotationCommentContentType,
        token: localStorage.getItem("token")
      };
      const httpBody = {
        data: {
          task_file_annotation_id: parseInt(commentId),
          comment: comment,
          references: referencesData,
          // description: comment,
          // file_blob_signed_id: this.state.presignedData?.signed_id,
          textual_description: this.state.editorState.getCurrentContent().getPlainText(),
          version_number: this.state.fileVersionNumber2
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAnnotationCommentId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createAnnotationCommentApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createAnnotationCommentMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({
        replyCommentData: "",
        openReplyBox: false
      });
    }
  }
  resolveComment = (commentId: any) => {
    this.setState({
      loading: true,
      isResolved: true,
    })
    const header = {
      "Content-Type": configJSON.createAnnotationCommentContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        is_resolved: true,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resolveAnnotationCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationUpdateAPiEndPoint + '/' + parseInt(commentId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMediaFolders = (mediaID: any) => {
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mediaFolderAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=30&media_asset_id=${mediaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onClickApplyFilters = (filterType: any) => {
    this.resetAnnotationInPdf();
    const header = {
      "Content-Type": configJSON.getTaskFileAnnotationContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterAnnotationTaskFileID = requestMessage.messageId;
    if (filterType == "createdbyme") {
      this.setState({ filterType: '1' });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId) + '&user_id=' + localStorage.getItem("userId")
      );
    } else if (filterType == "resolved") {
      this.setState({ filterType: '2' });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId) + '&is_resolved=true'
      );
    } else if (filterType == "tagged") {
      this.setState({ filterType: '3' });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId) + '&account_id=' + localStorage.getItem("userId")
      );
    } else if (filterType == "mostrecent") {
      this.setState({ filterType: '4' });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId) + '&recent=true'
      );
    } else if (filterType == "active") {
      this.setState({ filterType: '5' });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId) + '&active=true'
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileAnnotationMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onClickFinalApproval = (fileId?: any) => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.taskFileUpdateApiContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        action: "approve",
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.finalApprovalId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_upload_media/task_files/' + `${fileId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleTaskFilterOpen = (event: any) => {
    this.setState({
      anchorElTaskFilter: event.currentTarget
    });
  }
  handleTaskFilterClose = () => {
    this.setState({
      anchorElTaskFilter: null
    });
  }

  onClick = (annotation: any) => {
    //console.log("Clickeddddddd", annotation)
  }
  // renderContent = (annotation: any) => {
  //   const { geometry } = annotation
  //   return (
  //     <div
  //       key={annotation.data.id}
  //       style={{
  //         background: 'black',
  //         color: 'white',
  //         padding: 10,
  //         position: 'absolute',
  //         fontSize: 12,
  //         left: `${geometry.x}%`,
  //         top: `${geometry.y + geometry.height}%`
  //       }}
  //     >
  //       <div>Custom Content</div>
  //       {annotation.data && annotation.data.text}
  //     </div>
  //   )
  // }
  handleCloseTaskModal = () => {
    this.getNotifyNotifications();
    this.getAllStatusByProjectId();
    this.callGetStatusListApi();



    this.setState({
      setOpen2: false,
      isCurrentTaskSelected: false,
      setOpenAnnotaion: false,
      // allowAnnotation: false,
      taskAssigneeName: "",
      taskReviewerName: "",
      showActivity: false,
      taskActivityData: [],
      currentPage: 1,
      totalActivityPageCount: 1,
      currentTaskBriefingData: []
    }, () => {
      if (this.state.navigationScreenType == 'projectsScreen') {
        // this.callShowProjectsApi(this.state.selectedProjectId);
      } else if (this.state.navigationScreenType == "TaskScreen") {
        // this.callShowProjectsApi(this.state.selectedProjectId);
      }
    })
  }
  handleCloseBriefingModel = () => {
    this.setState({
      openStartBriefingModel: false,
      openAddBriefingModel: false
    })
  }
  handleClickTemplateSelection = (event: any, value: any) => {
    console.log("handleClickTemplateSelection", value)
    this.setState({
      selectedTaskTemplate: value
    })


    allowDeselectionTemplate = false;
  }

  handleUnselectTemplateInTask = () => {
    console.log("this.state.selectedTaskTemplate", this.state.selectedTaskTemplate)
    if (this.state.selectedTaskTemplate && this.state.selectedTaskTemplate !== null && allowDeselectionTemplate == true) {
      this.setState({
        selectedTaskTemplate: null
      });
    }
    allowDeselectionTemplate = true;
  }

  handleCloseModal = () => {
    this.setState({
      setOpen1: false
    })
  }
  handleCloseModalTeam = () => {
    this.setState({
      inviteTeamMembersDialog: false
    })
  }
  handleCloseStatus = () => {
    this.setState({
      anchorE4: null
    })
  }
  handleCloseModalProject = () => {
    this.setState({
      openProjectProperties: false,

    });
  }

  handleLoadTaskData = () => {

    this.callGetAssetCategoryListApi();
    // this.callGetAssetTypeListApi();

  }
  // setProjectData(selectedProject?:any) {

  //   localStorage.setItem("selectedProject",selectedProject[0])
  //   console.log("selectedProject",selectedProject[0])
  // }
  setBrandData(selectedBrand?: any) {
    // this.setState({
    //   selectedBrand: selectedBrand.id,
    // });
    // localStorage.setItem("selectedProject",selectedProject);
    // localStorage.setItem("selectedProjectId",selectedProject.id)
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.brand?.data?.attributes?.name);
    localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.brand?.data?.attributes?.name?.match(/\b(\w)/g).join(''));

    this.callGetAssetCategoryListApi();
    this.callGetAssetTypeListApi();
    this.callGetTaskActivityListApi();


  }


  setData(selectedProject?: any) {
    if (this.state.navigationScreenType == 'TaskScreen') {
      this.setState({
        selectedProjectId: selectedProject.id,
      });
      //console.log(selectedProject, "werwer")
      localStorage.setItem("selectedProject", selectedProject);
      localStorage.setItem("selectedProjectId", selectedProject.id)
      localStorage.setItem("selectedProjectStatus", selectedProject.attributes.status)
      localStorage.setItem("selectedBrandName", selectedProject?.attributes?.brand?.data?.attributes?.name);
      localStorage.setItem("BrandNameIcon", selectedProject?.attributes?.brand?.data?.attributes?.name?.match(/\b(\w)/g).join(''));
      localStorage.setItem("selectedProjectName", selectedProject.attributes.name);
      var selectedGrid: any = false
      localStorage.setItem("selectedGridView", selectedGrid)
      this.callGetAssetCategoryListApi();
      this.callGetAssetTypeListApi();
      this.callGetTaskActivityListApi();
      this.callGetProjectsListApiType1()
      const sideNavData = {
        sideNavData:
          [
            {
              name: "photoproduction"
            },
            {
              name: "CGI"
            }


          ]
      }
      let da = { sideNavData: sideNavData, key: "projects" }
      localStorage.setItem("navData", JSON.stringify(da))
    } else {
      this.setState({
        selectedProjectId: selectedProject.id,
      });
      //console.log(selectedProject, "werwer")
      localStorage.setItem("selectedProject", selectedProject);
      localStorage.setItem("selectedProjectId", selectedProject.id)
      localStorage.setItem("selectedProjectStatus", selectedProject.attributes.status)
      localStorage.setItem("selectedBrandName", selectedProject?.attributes?.brand?.data?.attributes?.name);
      localStorage.setItem("BrandNameIcon", selectedProject?.attributes?.brand?.data?.attributes?.name?.match(/\b(\w)/g).join(''));
      localStorage.setItem("selectedProjectName", selectedProject.attributes.name);
      var selectedGrid: any = false
      localStorage.setItem("selectedGridView", selectedGrid)
      this.callGetAssetCategoryListApi();
      this.callGetAssetTypeListApi();
      this.callGetTaskActivityListApi();
      // this.callShowAccountforProjectApi();
      // if(this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus ==="Quote Approved" 
      // || this.state.selectedProjectStatus ==="Briefing Completed"){

      // this.callShowAccountforProjectApi(this.state.selectedProjectId)

      //   this.getApprovedQuote()
      // this.getOpenQuote()
      //   this.getPendingQuote()
      //   // this.callGetAccountListApi('');

      // }else{

      //   this.callGetStatusListApi();
      // }

      const sideNavData = {
        sideNavData:
          [
            {
              name: "photoproduction"
            },
            {
              name: "CGI"
            }


          ]
      }
      let da = { sideNavData: sideNavData, key: "projects" }
      localStorage.setItem("navData", JSON.stringify(da))
      // this.props.history.push("/Home/Projectportfolio")
      // navigator.navigate("/Home/Projectportfolio")

      window.location.href = '/Home/Projectportfolio';
    }

  }

  openTaskProjectLevel = (selectedProject?: any, task?: any) => {
    this.setState({
      selectedProjectId: selectedProject.id,
    });
    //console.log(selectedProject, "werwer")
    localStorage.setItem("selectedProject", selectedProject);
    localStorage.setItem("selectedProjectId", selectedProject.id)
    localStorage.setItem("selectedProjectStatus", selectedProject.attributes.status)
    localStorage.setItem("selectedBrandName", selectedProject?.attributes?.brand?.data?.attributes?.name);
    localStorage.setItem("BrandNameIcon", selectedProject?.attributes?.brand?.data?.attributes?.name?.match(/\b(\w)/g).join(''));
    localStorage.setItem("selectedProjectName", selectedProject.attributes.name);
    var selectedGrid: any = false
    localStorage.setItem("selectedGridView", selectedGrid)
    this.callGetAssetCategoryListApi();
    this.callGetAssetTypeListApi();
    this.callGetTaskActivityListApi();
    const sideNavData = {
      sideNavData:
        [
          {
            name: "photoproduction"
          },
          {
            name: "CGI"
          }
        ]
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    window.location.href = window.location.href = `/Home/Projectportfolio?navigate=projects&projectId=${selectedProject.id}&taskId=${task != null ? task?.id : 'newTask'}`
  }

  setCategory(SelectedCategory?: any) {
    this.setState({
      SelectedCategory: SelectedCategory,
    });
    this.callGetAssetTypeListApi(SelectedCategory.id);


    //console.log("Hello", SelectedCategory)

  }
  setAssetType(templateBasedOnSelectedAssetType?: any) {
    this.setState({
      templateBasedOnSelectedAssetType: templateBasedOnSelectedAssetType,
    });

    this.callGetTemplateListApi(templateBasedOnSelectedAssetType.id)


  }
  progressPercentage = (e?: any, value?: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.progress = value
    this.setState({
      percentage: value,
      currentTask: currentTask
    })
  }
  updateTaskProgress = () => {
    var currentTask = this.state.currentTask
    this.callUpdateTaskApi(currentTask);
    this.setState({
      percentage: null
    });
  }
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true,
      gridProjectPortfolio: false,
      listProjectPortfolio: true,
    })
  }
  navigateToProjects(e?: any) {

    this.props.navigation.navigate("home/projectportfolio");

  }
  handleClickInviteTeamMember = (item?: any) => {
    //console.log("true");
    this.setState({
      // anchorEl: null,
      inviteTeamMembersDialog: true,
      inviteteammembersId: item
    });

  }
  handleClickProjectProperties = (event: any) => {
    //console.log("menu", this.state.selectedProjectData);
    const item: any = this.state.selectedProjectData
    //console.log("truetrue", item.id);

    this.setState({
      // anchorEl: null,
      openProjectProperties: true,
      openProjectPropertiesID: item.id,
      anchorEl: false,
      value: 0

    }, () => {
      this.callShowProjectsApi(item.id)
      this.callShowAccountforProjectApi(item.id)
      this.callShowMediaforProjectApi(item.id)
    });
  }
  handleClickProjectPropertiesEventMembers = (event: any) => {
    //console.log("menu", this.state.selectedProjectData);
    const item: any = this.state.selectedProjectData
    //console.log("truetrue", item.id);

    this.setState({
      // anchorEl: null,
      openProjectProperties: true,
      openProjectPropertiesID: item.id,
      anchorEl: false,
      value: 2

    }, () => {
      this.callShowProjectsApi(item.id)
      this.callShowAccountforProjectApi(item.id)
      this.callShowMediaforProjectApi(item.id)
    });
  }
  handleClickProjectPropertiesIcon = (event: any) => {
    //console.log("menu", localStorage.getItem("selectedProjectId"));

    const item: any = localStorage.getItem("selectedProjectId")
    const brand: any = localStorage.getItem("selectedBrand")
    this.callShowProjectsApi(item)
    //console.log("truetrue", item);

    this.setState({
      // anchorEl: null,
      openProjectProperties: true,
      openProjectPropertiesID: item,
      anchorEl: false

    }, () => {
      // this.getBrandTeams(brand)
      this.callShowAccountforProjectApi(item)
      this.callShowMediaforProjectApi(item)
    });
  }

  // handleStatusDelete = (id) => {
  //   console.log("nsjsjkkk", id)
  //   let newtaskStatusesData = this.state.taskStatusesData.filter( item => item.id !== id)
  //   this.setState(newtaskStatusesData)
  // }

  // handleStatusDelete = () = {
  //   this.setState({setAnchorE4(null)})
  // }

  openMenuFilePopUp = (event: any) => {
    // console.log("EVent Details", event)
    this.setState({ setAnchorEl: event.currentTarget });
  }
  closeMenu = () => {
    this.setState({ setAnchorEl: null });
  }

  openMenuFilePopUpInvoice = (event: any) => {
    this.setState({ menuflaginvoice: event.currentTarget });
  }
  closeMenuInvoice = () => {
    this.setState({ menuflaginvoice: null });
  }

  openSourceFileMenuFilePopUp = (event: any) => {
    // console.log("EVent Details", event)
    this.setState({ setSourceFileAnchorEl: event.currentTarget });
  }
  closeSourceFileMenu = () => {
    this.setState({ setSourceFileAnchorEl: null });
  }

  handleClose = (item?: any) => {
    this.setState({
      anchorEl: null,
      editorState: EditorState.createEmpty()
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleClickCreateNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: true,
      selectedTaskTemplate: null,
      assetType: '',
      category: '',
      templatesData: [],
      presignedDataImage: [],
      presignedData: [],
      fileName: "",
      fileNameImage: "",
      fileToRawBinary: {},
      fileTaskName: [],
      getTaskCommentsData: [],
      taskAssigneeName: "",
      taskReviewerName: "",
      taskActivityData: []
    });
    this.callGetAssetCategoryListApi();
    // this.callGetAssetTypeListApi();  
    // this.setAssetType();

  };
  handleClickCreateNewStatusModel = (e?: any) => {
    this.setState({
      openStatusCreateModel: true,
    });
    // this.callGetAssetCategoryListApi();
    // this.callGetAssetTypeListApi();  
    // this.setAssetType();

  };
  handleClickCreateNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: true,
    });

  };
  handleClickCreateNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: true,
    });

  };
  handleClickCancelNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: false,
    });

  };
  handleClickCancelNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: false,
    });

  };

  handleClickCancelNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      category: '',
      assetType: '',
      templatesData: [],
      startBriefingOpen1: false,
      startBriefingOpen2: false
    });
  };

  handleClickCancelNewStatusModel = (e?: any) => {
    this.setState({
      openStatusCreateModel: false,
    });
  };

  handleClickStartBriefing = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddBriefingModel: true,
      isLabel: '',
      loading: true,
      startBriefingOpen1: false,
      startBriefingOpen2: false
    });
  };

  handleClickStopBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickChangeBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickSaveBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  deleteTaskFile = (deleteID: any) => {
    this.setState({ setAnchorEl: null, setSourceFileAnchorEl: null, replaceFlag: 0 }, () => {
      //console.log("Icon from the setAnchor", deleteID); 
      this.deleteTaskFileFromTheTaskView(deleteID)
    })
  }
  deleteTaskInvoice = (deleteID: any) => {
    // console.log(deleteID,"Invoiceid");
    this.deleteTaskInvoiceFromTheTaskView(deleteID)

  }

  downloadloadLatestVersionImage = () => {
    console.log("final fils", this.state.finalFileDown);

    this.setState({
      loading: true
    });
    var url = `${currentUrl
      }/bx_block_download/task_file_downloads?task_file_id=${this.state.finalFileDown}&token=${localStorage.getItem("token")}`

    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.click();
    this.setState({
      loading: false,
      setAnchorEl: null
    });
  }
  downloadInvoiceFile = () => {
    this.setState({
      loading: true
    });
    var url = `${currentUrl
      }/bx_block_download/task_file_downloads?invoice_id=${this.state.invoiceId}&token=${localStorage.getItem("token")}`

    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.click();
    this.setState({
      loading: false,
      menuflaginvoice: null
    });
  }

  deleteTask = () => {
    console.log("delte task id", this.state.deleteTaskId);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskQuotationId:this.state.taskQuotationId
      // email: this.state.email,
      // password: this.state.password
    };



    const requestShowTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.taskDeleteID = requestShowTaskQuotationMessage.messageId;
    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskDeleteAPiEndPoint + this.state.deleteTaskId
    );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskDeleteAPiMethod
    );

    runEngine.sendMessage(requestShowTaskQuotationMessage.id, requestShowTaskQuotationMessage);




  }
  handleChangeBlockerSelection = (task?: any, value?: any) => {

    // this.setState({
    //   is_completedBlocker: event.target.checked as boolean,
    //   taskBlockerId: event.target.value

    // });
    //console.log("locker checkbox", task, value);
    this.callUpdateTaskBlockerApi(task.id, value);
    //console.log("locker", task.id, value);

  };
  handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ category: event.target.value as string });
    this.setState({ startBriefingOpen1: false })

  };
  // handleDateChange=(index?:any,event?:any) {
  //   this.setState({ 
  //     dueDate: event.target.value });
  // }
  //used in breifing part 2 where task data can be updated
  handleDateChange = (event: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.estimated_end_date = event.target.value,
      this.setState({
        dueDate: event.target.value as string,
        currentTask: currentTask,
      });
    this.callUpdateTaskApi(currentTask);
  };

  handleAssigneeChange = (event: any, value: any) => {
    if (value != null) {
      var currentTask = this.state.currentTask
      currentTask.attributes.assigned_to = value?.id,
        currentTask.attributes.assignee.data = value,
        this.setState({
          currentTask: currentTask,
          taskAssigneeName: value.attributes.first_name || ""
        });
      this.callUpdateTaskApi(currentTask);
    }
  };
  handleJobNumberChange = (event: any) => {
    if (event.target.value != null) {
      console.log("job_nmum", event.target.value);
      var currentTask = this.state.currentTask
      currentTask.attributes.job_number = event.target.value,
        currentTask.attributes.job_number = event.target.value,
        this.setState({
          currentTask: currentTask,
          job_number: event.target.value || ""
        });

      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        this.callUpdateTaskApi(currentTask);
      }, 2000)
    }
  }

  handleSixomcNumberChange = (event: any) => {
    if (event.target.value != null) {
      console.log("sixomc_nmum", event.target.value);
      var currentTask = this.state.currentTask
      currentTask.attributes.sixomc_number = event.target.value,
        currentTask.attributes.sixomc_number = event.target.value,
        this.setState({
          currentTask: currentTask,
          sixomc_number: event.target.value || ""
        });

      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        this.callUpdateTaskApi(currentTask);
      }, 2000)
    }
  }

  handleReviewerChange = (event: any, value: any) => {

    if (value != null) {
      var currentTask = this.state.currentTask
      currentTask.attributes.reviewer_id = value?.id,
        currentTask.attributes.reviewer.data = value,
        this.setState({
          currentTask: currentTask,
          taskReviewerName: value?.attributes?.first_name || ""
        });
      this.callUpdateTaskApi(currentTask);
    }
  };
  handleChangeUsers = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ accountId: event.target.value as string });

  };

  handleChangeAssetType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ assetType: event.target.value as string });
    this.setState({ startBriefingOpen2: false })

  };

  handleisLayout = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ isLabel: event.target.value as string });
  };

  handleInputChange = (indexValue: any, event: any) => {
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    if (templateData[index].attributes.element == "date_time") {
      if (event.target.name == "date") {
        if (templateData[index].attributes.default_value && (templateData[index].attributes.default_value).indexOf("T") > -1) {
          templateData[index].attributes.default_value = (templateData[index].attributes.default_value).replace(templateData[index].attributes.default_value.split("T")[0], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[0], event.target.value);
        } else {
          templateData[index].attributes.default_value = event.target.value;
          templateData[index].value = event.target.value;
        }
      }
      if (event.target.name == "time") {
        if (templateData[index].attributes.default_value && (templateData[index].attributes.default_value).indexOf("T") > -1) {
          templateData[index].attributes.default_value = (templateData[index].attributes.default_value).replace(templateData[index].attributes.default_value.split("T")[1], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[1], event.target.value);
        } else {
          templateData[index].attributes.default_value = templateData[index].attributes.default_value + "T" + event.target.value;
          templateData[index].value = templateData[index].value + "T" + event.target.value;
        }
      }

    } else if (templateData[index].attributes.element == "multiple_selection") {
      if (templateData[index].attributes.default_value == "") {
        templateData[index].attributes.default_value = [];
        templateData[index].value = [];
      }
      if (templateData[index].attributes.default_value && templateData[index].attributes.default_value.length > 0 &&
        (templateData[index].attributes.default_value).findIndex((data: any) => data == event.target.value) > -1) {
        var i = templateData[index].attributes.default_value.findIndex((data: any) => data == event.target.value);
        templateData[index].attributes.default_value.splice(i, 1);
        templateData[index].value.splice(i, 1);
      } else {
        templateData[index].attributes.default_value.push(event.target.value);
        templateData[index].value.push(event.target.value);
      }
    } else {
      templateData[index].attributes.default_value = event.target.value
      templateData[index].value = event.target.value
    }
    // templateData[index].attributes.default_value = event.target.value
    // templateData[index].value = event.target.value
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
      console.log("conditionData", conditionData)
      // if (conditionsData.length > 0) {
      //   templateData = templateData.map((data: any) => ({ ...data, "showField": true }));
      // }
      if (templateData[index].attributes.element == "single_selection" ||
        templateData[index].attributes.element == "multiple_selection" ||
        templateData[index].attributes.element == "drop_down_select"
      ) {
        // conditionData = conditionData.filter((condition: any) => condition.attributes.condition_value == event.target.value);
      }
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          // console.log(event.target.value,"event.target.value");
          if (templateData[index].attributes.element == "single_selection" ||
            templateData[index].attributes.element == "drop_down_select" ||
            templateData[index].attributes.element == "Long text"
          ) {
            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != event.target.value
              && condition.attributes.is_condition_selected == false && condition.attributes.is_response_to_show == false) {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {

              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != event.target.value
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }
          }
          else if (templateData[index].attributes.element == "multiple_selection") {
            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '')
              && condition.attributes.is_condition_selected == false && condition.attributes.is_response_to_show == false) {

              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {

              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '')
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              templateData[findMatch].attributes.default_value = "";
              templateData[findMatch].value = "";
            }
          }
          else if (templateData[index].attributes.element == "text" ||
            templateData[index].attributes.element == "long_text" ||
            templateData[index].attributes.element == "short_text" ||
            templateData[index].attributes.element == "email" ||
            templateData[index].attributes.element == "full_name" ||
            templateData[index].attributes.element == "phone" ||
            templateData[index].attributes.element == "date_time"
          ) {
            if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                templateData[findMatch].attributes.default_value = "";
                templateData[findMatch].value = "";
              }
            }
          }

        })
      }
    }
    // templateData[index].briefed_value_files_attributes = []
    this.setState({
      templateFormFieldsData: templateData
    }, () => {
      //console.log("Values from handle Input", templateData, templateData[index])
    });

  };

  handleSliderChange = (index: any, event: any, values: any) => {
    //console.log("Values from sliderss before", this.state.templateFormFieldsData)
    //console.log("Sliderss", values)
    var templateData = this.state.templateFormFieldsData;
    if (templateData[index].attributes.element == "slider") {
      templateData[index].attributes.detailed_values = { min: templateData[index].attributes.detailed_values.min, max: values.toString() }
    }
    this.setState({
      templateFormFieldsData: templateData
    }, () => {
      //console.log("Values from sliderss sliderss", this.state.templateFormFieldsData)
    });
  }
  handledateformat = (date: any) => {
    let a = date.replace("T", ", ").split(",")
    a[1] ? a = a[0].split("-").reverse().join("-") + a[1] : a = a[0].split("-").reverse().join("-")
    return a
  }

  createTemplateTask = () => {
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const httpBody = { task_briefing_id: parseInt(this.state.selectedTaskFromDraggable), name: this.state.TemplateName };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.setState({ createTaskBriefingApiCallId: requestMessage.messageId })
    // this.state.createTaskBriefingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_project_portfolio/task_briefings/create_briefing_template");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  callCreateDuplicateTaskApi(selectedTask: any) {
    console.log(selectedTask, "selectedTask")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const attrs = {
      "project_id": `${selectedTask.attributes.project_id}`,
      "title": this.state.duplicateTaskName,
      "description": this.state.duplicateTaskName,
      "asset_type_id": Number(selectedTask.attributes.asset_type_id),
      "asset_category_id": Number(selectedTask.attributes.asset_category_id)
    };
    const httpBody = {
      data: attrs
    };
    const requestCreateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectDuplicateTaskCreateAPICallId = requestCreateTaskMessage.messageId;
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateAPiEndPoint
    );
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCreateAPiMethod
    );
    runEngine.sendMessage(requestCreateTaskMessage.id, requestCreateTaskMessage);
  }


  handleClickDuplicateTask = (selectedTask: any) => {

    let briefing = selectedTask.attributes.briefing
    let templateData = briefing.data.attributes.briefed_values.data;

    let templateFormConditions = briefing.data.attributes.briefed_conditions?.data;
    let pageSeparatorIndex: any = []
    templateData.push(
      {
        "attributes":
        {
          "default_value": "page_separator",
          "element": "page_separator",
          "label": "",
          "value_type": "page_separator"
        },
        "id": "1",
        "type": "template_form_field"
      }
    )
    var paginationCount = templateData.map((items: any) => items.attributes.value_type == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
    this.setState({
      templateFormConditionsData: templateFormConditions
    });
    templateData.map((_data: any, index: any) => {
      if (_data.attributes.value_type == "multiple_selection") {
        if (typeof (_data.attributes.value) == "string") {
          if (_data.attributes.value !== null && _data.attributes.value !== "") {
            _data.attributes.value = _data.attributes.value.split(",");
            _data.value = _data.attributes.value;
            _data.showField = true;
          }
          else {
            _data.attributes.value = [];
            _data.value = [];
            _data.showField = true;
          }
        }
      } else {
        _data.value = _data.attributes.value;
        _data.showField = true;
      }
      if (_data.attributes.value_type == "page_separator") {
        pageSeparatorIndex.push(index)
      }
      if (_data.attributes.value_type == "long_text") {
        if (_data?.attributes?.value?.length) {
          const html = _data?.attributes?.value
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.temp[_data.id] = editorState
          }

          this.setState({ longTextEditorContent: this.temp }, () => console.log("paragrak", this.state.longTextEditorContent))
        }
      }
    });
    this.setState({ paginationCount: paginationCount.true, page: 1 })
    if (paginationCount.true !== undefined) {
      this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {
        //console.log("testdata",this.state.pageSeparatorIndex)
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      })
    } else {
      this.setState({ fromStart: 0, fromEnd: 100 })
    }


    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          // let applyOn = templateData.filter((data : any) => data.id == condition.attributes.response_label_id);
          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);


          //console.log("testttt2",applyFrom,applyOnIndex)

          //Condition's for Selection type widgets 
          if (!(
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "long_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider" ||
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )) {

            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }

            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }
            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            console.log("xcv", templateData[applyOnIndex])
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
            console.log("yak", condition.attributes.is_response_to_show);
          }

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //condition for long_text
          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }


        })
      }
    }

    this.setState({
      // setOpen2: false,
      isLabel: '',
      openEditBriefingModel: true,
      templateFormFieldsData: templateData,
      isCurrentTaskSelected: true,
      presignedData: [],
      fileName: "",
      fileToRawBinary: {},
      presignedDataImage: [],
      fileNameImage: ""
    });
  }

  handleClickSaveDuplicateTask = () => {

    if (this.state.templateFormFieldsData.some((field: any) => (field.attributes.mandatory === true && (field.attributes.value_type !== 'image' && field.attributes.value_type !== 'file') && field.value.toString() == ''))) {
      toast.error("Fill the required fields marked as *")
    }
    else {
      var briefingValues: any = {}
      var briefed_values_attributes: any = []
      var formFieldsData = this.state.templateFormFieldsData
      console.log("formFieldsData", formFieldsData);

      if (formFieldsData.length > 0
        && formFieldsData[formFieldsData.length - 1].attributes.value_type == "page_separator"
        && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
        formFieldsData.pop();
      }

      formFieldsData.map((item: any, index: any) => {
        if (item?.attributes?.preview_visibility || !item?.attributes?.preview_visibility) {
          let file_blob_signed_id = this.state.presignedData?.signed_id
          var objectValues: any = {}
          if (item.attributes.value_type == "multiple_selection") {
            objectValues.value = (item.value).toString()
            objectValues.briefed_value_files_attributes = []
          }
          else if (item.attributes.value_type == "long_text") {
            objectValues.value = item.attributes.value
          }
          else {
            objectValues.value = item.value
            objectValues.briefed_value_files_attributes = []
          }
          objectValues.label = item.attributes.label,
            // objectValues.value = item.value
            objectValues.value_type = item.attributes.value_type,
            objectValues.preview_visibility = item.attributes.element == "comment_box" ? true : item.attributes.preview_visibility,
            objectValues.description = item.attributes.description,
            objectValues.optional_values = item.attributes.element_optional_values || {},
            objectValues.mandatory = item.attributes.mandatory,
            objectValues.detailed_values = item.attributes.detailed_values,
            objectValues.information = item.attributes.information,
            objectValues.field_order = item.attributes.field_order;
          objectValues.briefed_value_files_attributes = []

          if (item.attributes.value_type == "file") {
            let temparray2 = []
            let temparray = []
            if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
              objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;
              objectValues.briefed_value_files_attributes = []
            }
            if (this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0) {
              temparray = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })
            }
            if (item.attributes.briefed_value_files.data.length > 0) {
              for (let index = 0; index < item.attributes.briefed_value_files.data.length; index++) {
                let imageObj = {
                  "duplicated": true,
                  "file_type": item.attributes.briefed_value_files.data[index].attributes.file_type,
                  "name": item.attributes.briefed_value_files.data[index].attributes.name,
                  "task_briefing_id": item.attributes.briefed_value_files.data[index].attributes.task_briefing_id,
                }
                temparray2.push(imageObj)
              }
            }
            objectValues.briefed_value_files_attributes = [...temparray, ...temparray2]

          }
          else if (item.attributes.value_type == "image") {
            let temparray2 = []
            let temparray = []
            if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
              objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;
              objectValues.briefed_value_files_attributes = []
            }
            if (this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0) {
              temparray = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })
            }
            if (item.attributes.briefed_value_files.data.length > 0) {
              for (let index = 0; index < item.attributes.briefed_value_files.data.length; index++) {
                let imageObj = {
                  "duplicated": true,
                  "file_type": item.attributes.briefed_value_files.data[index].attributes.file_type,
                  "name": item.attributes.briefed_value_files.data[index].attributes.name,
                  "task_briefing_id": item.attributes.briefed_value_files.data[index].attributes.task_briefing_id,
                }
                temparray2.push(imageObj)
              }
            }
            objectValues.briefed_value_files_attributes = [...temparray, ...temparray2]
          }
          else if (item.attributes.value_type == "image_upload") {
            objectValues.briefed_value_files_attributes = [
              {
                "duplicated": true,
                "file_type": item.attributes.briefed_value_files.data[0].attributes.file_type,
                "name": item.attributes.briefed_value_files.data[0].attributes.name,
                "task_briefing_id": item.attributes.briefed_value_files.data[0].attributes.task_briefing_id,
              }
            ]

          }

          briefed_values_attributes.push(objectValues)
          console.log("Briefing Array", briefed_values_attributes)
        }
      }
      )

      briefingValues.task_id = this.state.currentTask.id,
        briefingValues.briefed_values_attributes = briefed_values_attributes;
      this.callCreateBriefingApi(briefingValues);

      this.setState({
        loadingIndicator: true,
        modelForDelete: false,
        taskDuplicateFlag: false,
        duplicateTaskName: "",
        openEditBriefingModel: false,
        openAddBriefingModel: false,
        openStartBriefingModel: false,
        setOpen2: false,
        templateFormFieldsData: [],
        editorState: EditorState.createEmpty(),
        longTextEditorContent: [EditorState.createEmpty()]
      });

    }
  };

  handleClickEditBriefing = (briefing: any) => {
    let templateData = briefing.data.attributes.briefed_values.data;

    let templateFormConditions = briefing.data.attributes.briefed_conditions?.data;
    let pageSeparatorIndex: any = []
    templateData.push(
      {
        "attributes":
        {
          "default_value": "page_separator",
          "element": "page_separator",
          "label": "",
          "value_type": "page_separator"
        },
        "id": "1",
        "type": "template_form_field"
      }
    )
    var paginationCount = templateData.map((items: any) => items.attributes.value_type == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
    this.setState({
      templateFormConditionsData: templateFormConditions
    });
    templateData.map((_data: any, index: any) => {
      if (_data.attributes.value_type == "multiple_selection") {
        if (typeof (_data.attributes.value) == "string") {
          if (_data.attributes.value !== null && _data.attributes.value !== "") {
            _data.attributes.value = _data.attributes.value.split(",");
            _data.value = _data.attributes.value;
            _data.showField = true;
          }
          else {
            _data.attributes.value = [];
            _data.value = [];
            _data.showField = true;
          }
        }
      } else {
        _data.value = _data.attributes.value;
        _data.showField = true;
      }
      if (_data.attributes.value_type == "page_separator") {
        pageSeparatorIndex.push(index)
      }
      if (_data.attributes.value_type == "long_text") {
        if (_data?.attributes?.value?.length) {
          const html = _data?.attributes?.value
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.temp[_data.id] = editorState
          }

          this.setState({ longTextEditorContent: this.temp }, () => console.log("paragrak", this.state.longTextEditorContent))
        }
      }
    });
    this.setState({ paginationCount: paginationCount.true, page: 1 })
    if (paginationCount.true !== undefined) {
      this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {
        //console.log("testdata",this.state.pageSeparatorIndex)
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      })
    } else {
      this.setState({ fromStart: 0, fromEnd: 100 })
    }


    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          // let applyOn = templateData.filter((data : any) => data.id == condition.attributes.response_label_id);
          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);


          //console.log("testttt2",applyFrom,applyOnIndex)

          //Condition's for Selection type widgets 
          if (!(
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "long_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider" ||
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )) {

            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }

            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }
            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            console.log("xcv", templateData[applyOnIndex])
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
            console.log("yak", condition.attributes.is_response_to_show);
          }

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //condition for long_text
          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }


        })
      }
    }
    //console.log("testttt",templateData,briefing.data.attributes.briefed_conditions?.data)
    this.setState({
      // setOpen2: false,
      isLabel: '',
      openEditBriefingModel: true,
      templateFormFieldsData: templateData,
      isCurrentTaskSelected: true,
      presignedData: [],
      fileName: "",
      fileToRawBinary: {},
      presignedDataImage: [],
      fileNameImage: ""
    });
  };

  handlePrefilledValueLongText = (id: any, text: any) => {
    let initialvalue = EditorState.createWithContent(ContentState.createFromText(text.toString().replace(/(<([^>]+)>)/ig, '')))

    let editorStateTemp = this.state.longTextEditorContent;
    editorStateTemp[id] = initialvalue;

    this.setState({
      longTextEditorContent: editorStateTemp
    })

    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(id))

    let elementsArray = this.state.templateFormFieldsData;
    const contentRaw = convertToRaw(initialvalue.getCurrentContent());
    let contentHTML = draftToHtml(contentRaw);

    elementsArray[index].attributes.value = contentHTML;
    this.setState({
      templateFormFieldsData: elementsArray,
    });

  }

  handleClickBriefing = (briefing: any) => {
    if (!briefing.data) {
      toast.error("Form not Exists for this Task")
      return
    }
    let templateData = briefing.data.attributes.briefed_values.data;
    let templateFormConditions = briefing.data.attributes.briefed_conditions?.data;
    console.log("🚀 ~ file: ProjectPortfolioController.tsx ~ line 4361 ~ loadUserDetailsAndRoles ~ templateData", templateData, templateFormConditions)
    let pageSeparatorIndex: any = []
    // templateData.push(
    //   {
    //     "attributes":
    //     {
    //       "default_value": "page_separator",
    //       "element": "page_separator",
    //       "label": "",
    //       "value_type": "page_separator"
    //     },
    //     "id": "1",
    //     "type": "template_form_field"
    //   }
    // )
    var paginationCount = templateData.map((items: any) => items.attributes.value_type == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
    this.setState({
      templateFormConditionsData: templateFormConditions
    });
    templateData.map((_data: any, index: any) => {

      if (_data.attributes.value_type == "comment_box" && !_data.attributes.preview_visibility) {
        _data.attributes.preview_visibility = true
      }

      if (_data.attributes.value_type == "multiple_selection") {
        if (typeof (_data.attributes.value) == "string") {
          if (_data.attributes.value !== null && _data.attributes.value !== "") {
            _data.attributes.value = _data.attributes.value.split(",");
            _data.value = _data.attributes.value;
            _data.showField = true;
          }
          else {
            _data.attributes.value = [];
            _data.value = [];
            _data.showField = true;
          }
        }
      } else {
        _data.value = _data.attributes.value;
        _data.showField = true;
      }
      if (_data.attributes.value_type == "page_separator") {
        pageSeparatorIndex.push(index)
      }
      if (_data.attributes.value_type == "long_text") {
        if (_data?.attributes?.value?.length) {
          const html = _data?.attributes?.value
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.temp[_data.id] = editorState
          }

          this.setState({ longTextEditorContent: this.temp }, () => console.log("paragrak", this.state.longTextEditorContent))
        }
      }
    });
    this.setState({ paginationCount: paginationCount.true, page: 1 })
    if (paginationCount.true !== undefined) {
      this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {
        //console.log("testdata",this.state.pageSeparatorIndex)
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      })
    } else {
      this.setState({ fromStart: 0, fromEnd: 100 })
    }
    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          // let applyOn = templateData.filter((data : any) => data.id == condition.attributes.response_label_id);
          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);



          //Condition's for Selection type widgets 
          if (!(
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "long_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider" ||
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )) {

            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              console.log("hack1");
              templateData[applyOnIndex].showField = false;
            }

            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              console.log("hack2", condition);
              templateData[applyOnIndex].showField = false;
            }
            if (condition.attributes.condition_value == applyFrom[0].attributes.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
            if (condition.attributes.condition_value != applyFrom[0].attributes.value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
          }
          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (applyFrom[0].attributes.value && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "email" ||
            applyFrom[0].attributes.value_type == "short_text" ||
            applyFrom[0].attributes.value_type == "full_name" ||
            applyFrom[0].attributes.value_type == "phone" ||
            applyFrom[0].attributes.value_type == "text" ||
            applyFrom[0].attributes.value_type == "date_time" ||
            applyFrom[0].attributes.value_type == "slider"
          )
            && (!applyFrom[0].attributes.value && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //condition for long_text
          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && ((applyFrom[0].attributes.value.length > 8) && condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.value_type == "long_text"
          )
            && (!(applyFrom[0].attributes.value.length > 8) && !condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
        })
      }
    }
    //console.log("testttt",templateData,briefing.data.attributes.briefed_conditions?.data)
    // this.setState({
    //   // setOpen2: false,
    //   isLabel: '',
    //   openEditBriefingModel: true,
    //   templateFormFieldsData: templateData,
    //   isCurrentTaskSelected: true,
    //   presignedData: [],
    //   fileName: "",
    //   fileToRawBinary: {},
    //   presignedDataImage: [],
    //   fileNameImage: ""
    // });
  };

  handleClickBriefingTemplateform = (Briefingdata: any, Briefingconditions: any) => {
    let templateData = Briefingdata
    let templateFormConditions = Briefingconditions
    console.log("🚀 ~ file: ProjectPortfolioController.tsx ~ line 4361 ~ loadUserDetailsAndRoles ~ templateData", templateData, templateFormConditions)

    templateData.map((_data: any, index: any) => {
      if (_data.attributes.element == "multiple_selection") {
        if (typeof (_data.attributes.default_value) == "string") {
          if (_data.attributes.default_value !== null && _data.attributes.default_value !== "") {
            _data.attributes.default_value = _data.attributes.default_value.split(",");
            _data.value = _data.attributes.default_value;
            _data.showField = true;
          }
          else {
            _data.attributes.default_value = [];
            _data.value = [];
            _data.showField = true;
          }
        }
      } else {
        _data.value = _data.attributes.default_value;
        _data.showField = true;
      }
      if (_data.attributes.element == "long_text") {
        if (_data?.attributes?.value?.length) {
          const html = _data?.attributes?.value
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.temp[_data.id] = editorState
          }

          this.setState({ longTextEditorContent: this.temp }, () => console.log("paragrak", this.state.longTextEditorContent))
        }
      }
    });


    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          // let applyOn = templateData.filter((data : any) => data.id == condition.attributes.response_label_id);
          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);


          //console.log("testttt2",applyFrom,applyOnIndex)

          //Condition's for Selection type widgets 
          if (!(
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "long_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider" ||
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )) {

            if (condition.attributes.condition_value == applyFrom[0].attributes.default_value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }

            if (condition.attributes.condition_value != applyFrom[0].attributes.default_value &&
              !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = false;
            }
            if (condition.attributes.condition_value == applyFrom[0].attributes.default_value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
            if (condition.attributes.condition_value != applyFrom[0].attributes.default_value &&
              !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[applyOnIndex].showField = true;
            }
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (applyFrom[0].attributes.default_value && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (!applyFrom[0].attributes.default_value && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            console.log("xcv", templateData[applyOnIndex])
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (applyFrom[0].attributes.default_value && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.element == "email" ||
            applyFrom[0].attributes.element == "short_text" ||
            applyFrom[0].attributes.element == "full_name" ||
            applyFrom[0].attributes.element == "phone" ||
            applyFrom[0].attributes.element == "text" ||
            applyFrom[0].attributes.element == "date_time" ||
            applyFrom[0].attributes.element == "slider"
          )
            && (!applyFrom[0].attributes.default_value && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
            console.log("yak", condition.attributes.is_response_to_show);
          }

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.element == "image" ||
            applyFrom[0].attributes.element == "file"
          )
            && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          //condition for long_text
          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && ((applyFrom[0].attributes.default_value.length > 8) && condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && (!(applyFrom[0].attributes.default_value.length > 8) && !condition.attributes.is_condition_selected)
            && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && ((applyFrom[0].attributes.default_value.length > 8) && condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }

          if ((
            applyFrom[0].attributes.element == "long_text"
          )
            && (!(applyFrom[0].attributes.default_value.length > 8) && !condition.attributes.is_condition_selected)
            && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }


        })
      }
    }
    //console.log("testttt",templateData,briefing.data.attributes.briefed_conditions?.data)
    this.setState({
      templateFormFieldsData: templateData,
    });
  };

  handleCloseEditBriefing = () => {
    this.state.currentTask.attributes.briefing.data.attributes.briefed_values.data?.pop()
    this.setState({
      openEditBriefingModel: false,
      templateFormFieldsData: [],
      pageSeparatorIndex: [],
      page: 1,
      fromStart: 0,
      fromEnd: 0,
      currentTask: this.state.currentTask
    });
  }

  handleCloseEditBriefingAndDeleteTask = () => {
    this.handleDeleteTask()
    this.setState({
      taskDuplicateFlag: false,
      duplicateTaskName: "",
      openEditBriefingModel: false,
      openAddBriefingModel: false,
      openStartBriefingModel: false,
      setOpen2: false,
      pageSeparatorIndex: [],
      page: 1,
      fromStart: 0,
      fromEnd: 0,
      templateFormFieldsData: [],
      editorState: EditorState.createEmpty(),
      longTextEditorContent: [EditorState.createEmpty()]
    })
  }

  removeFileFromBriefing = (fileId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteBriefingFIleAPICallID = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/briefed_value_files/${fileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleApplyFileCondition = (briefing: any) => {
    let templateData = briefing.data.attributes.briefed_values.data;
    let templateFormConditions = briefing.data.attributes.briefed_conditions?.data;

    if (templateFormConditions && templateFormConditions.length > 0) {
      let conditionData = templateFormConditions;
      if (conditionData.length > 0) {

        conditionData.map((condition: any) => {
          let applyFrom = templateData.filter((data: any) => parseInt(data.id) == condition.attributes.condition_label_id);

          let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);

          //Conditions for Files types
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          ) && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          ) && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = true;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          ) && (applyFrom[0].attributes.briefed_value_files.data.length && condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
          if ((
            applyFrom[0].attributes.value_type == "image" ||
            applyFrom[0].attributes.value_type == "file"
          ) && (!applyFrom[0].attributes.briefed_value_files.data.length && !condition.attributes.is_condition_selected) && !condition.attributes.is_response_to_show) {
            templateData[applyOnIndex].showField = false;
          }
        })
        this.setState({
          templateFormFieldsData: templateData
        })
      }
    }

  };

  clearFileImage = (indexValue: any, event: any, fileId: any) => {
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    let temp = templateData[index].attributes.template_form_field_files?.data.filter((file: any, i: any) => file.id != fileId);
    templateData[index].attributes.template_form_field_files.data = temp;
    this.setState({
      templateFormFieldsData: templateData
    })
  }


  clearOldImage = (indexValue: any, event: any, fileId: any) => {
    this.state.taskDuplicateFlag ? null : this.removeFileFromBriefing(fileId)
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    let temp = templateData[index].attributes.briefed_value_files?.data.filter((file: any, i: any) => file.id != fileId);
    templateData[index].attributes.briefed_value_files.data = temp;
    templateData[index].value = "";
    this.setState({
      presignedDataImage: [],
      fileNameImage: "",
      fileToRawBinary: {},
      templateFormFieldsData: templateData
    }, () => {
      this.handleApplyFileCondition(this.state.currentTask.attributes.briefing)
    })
  }

  clearOldFile = (indexValue: any, event: any, fileId: any) => {
    this.state.taskDuplicateFlag ? null : this.removeFileFromBriefing(fileId)
    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    let temp = templateData[index].attributes.briefed_value_files?.data.filter((file: any, i: any) => file.id != fileId);
    templateData[index].attributes.briefed_value_files.data = temp;
    templateData[index].value = "";
    this.setState({
      presignedData: [],
      fileName: "",
      fileToRawBinary: {},
      templateFormFieldsData: templateData
    }, () => {
      this.handleApplyFileCondition(this.state.currentTask.attributes.briefing)
    });
  }

  handleInputChangeEditBriefing = (indexValue: any, event: any) => {

    var templateData = this.state.templateFormFieldsData;
    var index = this.state.templateFormFieldsData.findIndex((field: any) => parseInt(field.id) == parseInt(indexValue))
    if (templateData[index].attributes.value_type == "date_time") {
      if (event.target.name == "date") {
        if (templateData[index].attributes.value && (templateData[index].attributes.value).indexOf("T") > -1) {
          templateData[index].attributes.value = (templateData[index].attributes.value).replace(templateData[index].attributes.value.split("T")[0], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[0], event.target.value);
          //console.log("testtttt",templateData[index])
        } else {
          templateData[index].attributes.value = event.target.value;
          templateData[index].value = event.target.value;
        }
      }
      if (event.target.name == "time") {
        if (templateData[index].attributes.value && (templateData[index].attributes.value).indexOf("T") > -1) {
          templateData[index].attributes.value = (templateData[index].attributes.value).replace(templateData[index].attributes.value.split("T")[1], event.target.value);
          templateData[index].value = (templateData[index].value).replace(templateData[index].value.split("T")[1], event.target.value);
        } else {
          templateData[index].attributes.value = templateData[index].attributes.value + "T" + event.target.value;
          templateData[index].value = templateData[index].value + "T" + event.target.value;
        }
      }
    } else if (templateData[index].attributes.value_type == "multiple_selection") {
      if (templateData[index].attributes.value == "") {
        templateData[index].attributes.value = [];
        templateData[index].value = [];
      }
      if (templateData[index].attributes.value && templateData[index].attributes.value.length > 0 &&
        (templateData[index].attributes.value).findIndex((data: any) => data == event.target.value) > -1) {
        var i = templateData[index].attributes.value.findIndex((data: any) => data == event.target.value);
        templateData[index].attributes.value.splice(i, 1);
      } else {
        templateData[index].attributes.value.push(event.target.value);
      }
    }
    else {
      templateData[index].attributes.value = event.target.value
      templateData[index].value = event.target.value
    }
    if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
      let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[index].id));
      if (templateData[index].attributes.value_type == "single_selection" ||
        templateData[index].attributes.value_type == "multiple_selection" ||
        templateData[index].attributes.value_type == "drop_down_select"
      ) {
        // conditionData = conditionData.filter((condition: any) => condition.attributes.condition_value == event.target.value);
      }
      if (conditionData.length > 0) {
        conditionData.map((condition: any) => {
          let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
          if (templateData[index].attributes.value_type == "single_selection" ||
            templateData[index].attributes.value_type == "drop_down_select"
          ) {
            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              // templateData[findMatch].attributes.value = "";
              // templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value !== event.target.value
              && !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }

            if (condition.attributes.condition_value == event.target.value &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }
            if (condition.attributes.condition_value !== event.target.value
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }
          } else if (templateData[index].attributes.value_type == "multiple_selection") {

            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
              // templateData[findMatch].attributes.value = "";
              // templateData[findMatch].value = "";
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '')
              && !condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = false;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }

            if (condition.attributes.condition_value == (event.target.checked ? event.target.value : '') &&
              condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }

            if (condition.attributes.condition_value != (event.target.checked ? event.target.value : '')
              && !condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              templateData[findMatch].showField = true;
              if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                templateData[findMatch].attributes.file_blob_signed_id_url = "";
              }
            }
          }
          else if (templateData[index].attributes.value_type == "text" ||
            templateData[index].attributes.value_type == "long_text" ||
            templateData[index].attributes.value_type == "short_text" ||
            templateData[index].attributes.value_type == "email" ||
            templateData[index].attributes.value_type == "full_name" ||
            templateData[index].attributes.value_type == "phone" ||
            templateData[index].attributes.value_type == "date_time"
          ) {
            if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                // templateData[findMatch].attributes.value = "";
                // templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = false;
                if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                // templateData[findMatch].attributes.value = "";
                // templateData[findMatch].value = "";
              }
            }

            if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value !== "" && event.target.value !== null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                // templateData[findMatch].attributes.value = "";
                // templateData[findMatch].value = "";
              }
            }

            if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
              if (event.target.value == "" || event.target.value == null) {
                templateData[findMatch].showField = true;
                if (templateData[findMatch].attributes.value_type == "file" || templateData[findMatch].attributes.value_type == "image") {
                  templateData[findMatch].attributes.file_blob_signed_id_url = "";
                }
                // templateData[findMatch].attributes.value = "";
                // templateData[findMatch].value = "";
              }
            }
          }
        })
      }
    }
    this.setState({
      templateFormFieldsData: templateData
    });

  };




  handleClickSubmitBriefing = (fromTaskClose: any) => {

    if (this.state.templateFormFieldsData.some((field: any) => (field.attributes.mandatory === true && (field.attributes.element !== 'image' && field.attributes.element !== 'file') && field.value.toString() == ''))) {
      toast.error("Fill the required fields marked as *")
    }
    else {
      var briefingValues: any = {}
      var briefed_values_attributes: any = []
      var formFieldsData = this.state.templateFormFieldsData
      console.log("formFieldsData", formFieldsData);

      if (formFieldsData.length > 0
        && formFieldsData[formFieldsData.length - 1].attributes.element == "page_separator"
        && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
        formFieldsData.pop();
      }
      formFieldsData.map((item: any, index: any) => {
        if (item?.attributes?.preview_visibility || !item?.attributes?.preview_visibility) {
          // console.log(item)
          let file_blob_signed_id = this.state.presignedData?.signed_id
          var objectValues: any = {}
          if (item.attributes.element == "multiple_selection") {
            objectValues.value = (item.value).toString()
            objectValues.briefed_value_files_attributes = []
          }
          else if (item.attributes.element == "long_text") {
            objectValues.value = item.attributes.value
          }
          else {
            objectValues.value = item.value
            objectValues.briefed_value_files_attributes = []
          }
          objectValues.label = item.attributes.label,
            // objectValues.value = item.value
            objectValues.value_type = item.attributes.element,
            objectValues.preview_visibility = item.attributes.element == "comment_box" ? true : item.attributes.preview_visibility,
            objectValues.description = item.attributes.description,
            objectValues.optional_values = item.attributes.element_optional_values,
            objectValues.mandatory = item.attributes.mandatory,
            objectValues.detailed_values = item.attributes.detailed_values,
            objectValues.information = item.attributes.information,
            objectValues.field_order = item.attributes.field_order;
          objectValues.briefed_value_files_attributes = []
          //  console.log("Briefing Values Object", objectValues)
          if (item.attributes.element == "file") {
            let temparray2 = []
            let temparray = []
            if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
              objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;
              objectValues.briefed_value_files_attributes = []
            }
            if (this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0) {
              temparray = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })
            }
            if (item.attributes.template_form_field_files.data.length > 0) {
              for (let index = 0; index < item.attributes.template_form_field_files.data.length; index++) {
                let imageObj = {
                  "duplicated": true,
                  "file_type": item.attributes.template_form_field_files.data[index].attributes.file_type,
                  "name": item.attributes.template_form_field_files.data[index].attributes.name,
                  "template_form_field_id": item.attributes.template_form_field_files.data[index].attributes.template_form_field_id
                }
                temparray2.push(imageObj)
              }
            }
            objectValues.briefed_value_files_attributes = [...temparray, ...temparray2]
            console.log("🚀 ~ file: ProjectPortfolioController.tsx ~ line 5957 ~ formFieldsData.map ~ temparray", temparray, temparray2, this.state.briefingFiles[0]?.[item?.id]?.fileName)

            // if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
            //   objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;
            //   objectValues.briefed_value_files_attributes = []
            // }
            // else {
            //   objectValues.briefed_value_files_attributes = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            //     return {
            //       "name": file?.name,
            //       "file_type": file?.type,
            //       "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            //     }
            //   })
            // }

          }
          else if (item.attributes.element == "image") {
            let temparray2 = []
            let temparray = []
            if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
              objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;
              objectValues.briefed_value_files_attributes = []
            }
            if (this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0) {
              temparray = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
                return {
                  "name": file?.name,
                  "file_type": file?.type,
                  "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
                }
              })
            }

            if (item.attributes.template_form_field_files.data.length > 0) {
              for (let index = 0; index < item.attributes.template_form_field_files.data.length; index++) {
                let name = item.attributes.template_form_field_files.data[index].attributes.name
                let extType = name.substring(name.lastIndexOf('.') + 1);
                let imageObj = {
                  "duplicated": true,
                  "file_type": item.attributes.template_form_field_files.data[index].attributes.file_type || `image/${extType}`,
                  "name": item.attributes.template_form_field_files.data[index].attributes.name,
                  "template_form_field_id": item.attributes.template_form_field_files.data[index].attributes.template_form_field_id
                }
                temparray2.push(imageObj)
              }
            }
            objectValues.briefed_value_files_attributes = [...temparray, ...temparray2]
            // if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
            //   objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;
            //   objectValues.briefed_value_files_attributes = []
            // }
            // else {
            //   objectValues.briefed_value_files_attributes = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            //     return {
            //       "name": file?.name,
            //       "file_type": file?.type,
            //       "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            //     }
            //   })
            // }


          }
          else if (item.attributes.element == "image_upload") {
            if (!item.attributes.template_form_field_files.data.length) {
              let name = item.attributes.information
              let extType = name.substring(name.lastIndexOf('.') + 1);
              objectValues.briefed_value_files_attributes = [{
                "name": name || "simple image",
                "file_type": `image/${extType || "jpeg"}`,
                "file_blob_signed_id": item.attributes.file_blob_signed_id
              }]
            } else {
              let name = item.attributes.template_form_field_files.data[0].attributes.name
              let extType = name.substring(name.lastIndexOf('.') + 1);
              objectValues.briefed_value_files_attributes = [
                {
                  "duplicated": true,
                  "file_type": item.attributes.template_form_field_files.data[0].attributes.file_type || `image/${extType || "jpeg"}`,
                  "name": item.attributes.template_form_field_files.data[0].attributes.name,
                  "template_form_field_id": item.attributes.template_form_field_files.data[0].attributes.template_form_field_id,
                }
              ]

            }

          }

          briefed_values_attributes.push(objectValues)
          console.log("Briefing Array", briefed_values_attributes)
        }

      }
      )

      briefingValues.task_id = this.state.currentTask.id,
        briefingValues.briefed_values_attributes = briefed_values_attributes;

      //console.log("briefingValues", briefingValues)
      this.callCreateBriefingApi(briefingValues);
      if (fromTaskClose == true) {
        this.setState({
          openAddBriefingModel: false,
          openStartBriefingModel: false,
          setOpen2: false,
          templateFormFieldsData: [],
          editorState: EditorState.createEmpty(),
          longTextEditorContent: [EditorState.createEmpty()]
        });
      } else {
        this.setState({
          openAddBriefingModel: false,
          openStartBriefingModel: true,
          setOpen2: true,
          templateFormFieldsData: [],
          editorState: EditorState.createEmpty(),
          longTextEditorContent: [EditorState.createEmpty()]
        });
      }
    }
  };

  handleDeleteTask = () => {
    this.setState({
      loading: true,
      page: 1,
      longTextEditorContent: [EditorState.createEmpty()]
    });
    const header = {
      token: localStorage.getItem("token")
    };

    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTaskApiId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskDeleteAPiEndPoint + this.state.currentTask.id
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskDeleteAPiMethod
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);
  }

  handleDeleteSubTask = (id: any) => {
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSubTaskApiID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_project_portfolio/sub_tasks/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  createBriefingAndGenerateQuote = (submit?: any) => {
    var briefingValues: any = {};
    var briefed_values_attributes: any = [];
    var formFieldsData = this.state.templateFormFieldsData
    if (formFieldsData.length > 0
      && formFieldsData[formFieldsData.length - 1].attributes.element == "page_separator"
      && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
      formFieldsData.pop();
    }
    formFieldsData.map((item: any) => {
      var objectValues: any = {}
      if (item.attributes.element == "multiple_selection") {
        objectValues.value = (item.value).toString()
      }
      else if (item.attributes.element == "long_text") {
        objectValues.value = item.attributes.value
      }
      else {
        objectValues.value = item.value
      }
      objectValues.label = item.attributes.label,
        // objectValues.value = item.value,
        objectValues.value_type = item.attributes.element,
        objectValues.preview_visibility = item.attributes.element == "comment_box" ? true : item.attributes.preview_visibility,
        objectValues.description = item.attributes.description,
        objectValues.optional_values = item.attributes.element_optional_values,
        objectValues.mandatory = item.attributes.mandatory,
        objectValues.detailed_values = item.attributes.detailed_values,
        objectValues.information = item.attributes.information,
        objectValues.field_order = item.attributes.field_order;
      //console.log("Briefing Values Object", objectValues)
      if (item.attributes.element == "file") {


        if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId1,
          objectValues.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {
          // objectValues.briefed_value_files_attributes = this.array?.length > 0 && this.array.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs && this.state.signedIdAndURLs[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
          // objectValues.file_type = this.state.fileData1?.type;

        }


        // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
        // objectValues.file_type = this.state.fileData1?.type;
      } else if (item.attributes.element == "image") {
        if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId2,
          objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {

          // objectValues.briefed_value_files_attributes = this.array2?.length > 0 && this.array2.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs2 && this.state.signedIdAndURLs2[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs2[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
          // objectValues.file_type = this.state.fileData2?.type;
        }
        // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
        // objectValues.file_type = this.state.fileData2?.type;
      }
      else if (item.attributes.element == "image_upload") {

        let name = item.attributes.information
        let extType = name.substring(name.lastIndexOf('.') + 1);
        objectValues.briefed_value_files_attributes = [{
          "name": name || "simple image",
          "file_type": `image/${extType || "jpeg"}`,
          "file_blob_signed_id": item.attributes.file_blob_signed_id
        }]
      }
      // objectValues.file_blob_signed_id= this.state.presignedData?.signed_id
      briefed_values_attributes.push(objectValues)
    })
    briefingValues.task_id = this.state.currentTask.id,
      briefingValues.briefed_values_attributes = briefed_values_attributes;
    this.setState({ isComingFromBriefingForm: true });
    this.callCreateBriefingApi(briefingValues);

    this.setState({
      openAddBriefingModel: false,
      openStartBriefingModel: true,
      setOpen2: true,
      templateFormFieldsData: [],
      editorState: EditorState.createEmpty(),
    });
  }

  updateBriefingAndGenerateQuote = (type?: any) => {
    var briefingValues: any = {}
    var briefed_values_attributes: any = []
    var formFieldsData = this.state.templateFormFieldsData
    if (formFieldsData.length > 0
      && formFieldsData[formFieldsData.length - 1].attributes.element == "page_separator"
      && formFieldsData[formFieldsData.length - 1].attributes.label == "") {
      formFieldsData.pop();
    }
    formFieldsData.map((item: any) => {
      //console.log(item)
      var objectValues: any = {}
      if (item.attributes.value_type == "multiple_selection") {
        objectValues.value = (item.attributes.value).toString()
      }
      else if (item.attributes.value_type == "long_text") {
        objectValues.value = item.attributes.value
      }
      else {
        objectValues.value = item.value
      }
      objectValues.id = item.id,
        objectValues.label = item.attributes.label,
        // objectValues.value = item.value,
        objectValues.value_type = item.attributes.value_type,
        objectValues.description = item.attributes.description;
      //console.log("Briefing Values Object", objectValues)
      // if (item.attributes.value_type == "image") {
      //   objectValues.file_blob_signed_id = this.state.presignedData?.signed_id
      // }
      if (item.attributes.value_type == "file") {
        // objectValues.file_blob_signed_id = item.att
        if (this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId1,
          item.media_asset_file_ids = this.state.briefingMediaFiles[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {
          // objectValues.briefed_value_files_attributes = this.array?.length > 0 && this.array.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs && this.state.signedIdAndURLs[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingFiles[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingFiles[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingFiles[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''
          // objectValues.file_type = this.state.fileData1?.type;

        }
        // ributes.file_blob_signed_id && item.attributes.file_blob_signed_id !== "" ? objectValues.file_blob_signed_id : this.state.presignedData && this.state.presignedData.signed_id ? this.state.presignedData.signed_id : ''

      } else if (item.attributes.value_type == "image") {
        if (this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId?.length) {
          // objectValues.media_asset_file_ids = this.state.uploadingFilesId2,
          objectValues.media_asset_file_ids = this.state.briefingMediaImages[0]?.[item?.id]?.uploadedFileId;

          objectValues.briefed_value_files_attributes = []
        }
        else {

          // objectValues.briefed_value_files_attributes = this.array2?.length > 0 && this.array2.map((file: any, index: any) => {
          //   return {
          //     "name": file?.name,
          //     "file_type": file?.type,
          //     "file_blob_signed_id": this.state.signedIdAndURLs2 && this.state.signedIdAndURLs2[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs2[index]?.data[0]?.signed_id : ''
          //   }
          // })
          objectValues.briefed_value_files_attributes = this.state.briefingImages[0]?.[item?.id]?.fileName?.length > 0 && this.state.briefingImages[0]?.[item?.id]?.fileName.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_type": file?.type,
              "file_blob_signed_id": this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id ? this.state.briefingImages[0]?.[item?.id]?.signedId[index]?.data[0]?.signed_id : ''
            }
          })
          // objectValues.file_blob_signed_id = this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
          // objectValues.file_type = this.state.fileData2?.type;
        }
        // objectValues.file_blob_signed_id = item.attributes.file_blob_signed_id && item.attributes.file_blob_signed_id !== "" ? objectValues.file_blob_signed_id : this.state.presignedDataImage && this.state.presignedDataImage.signed_id ? this.state.presignedDataImage.signed_id : ''
      }
      // objectValues.file_blob_signed_id= this.state.presignedData?.signed_id
      briefed_values_attributes.push(objectValues)
    })
    briefingValues.briefed_values = briefed_values_attributes;
    if (type == "request_quote") {
      this.setState({ isComingFromBriefingForm: true });
    }
    this.callUpdateBriefingApi(briefingValues);
    this.setState({
      openEditBriefingModel: false,
      templateFormFieldsData: [],
      editorState: EditorState.createEmpty(),
      longTextEditorContent: [EditorState.createEmpty()],
      briefingFiles: [],
      briefingImages: [],
      briefingMediaImages: [],
      briefingMediaFiles: []
    });
  }

  statusUpdateToRequestQuote = (taskId: any) => {
    if ((this.state.brandBillingDetails && this.state.brandBillingDetails?.attributes &&
      this.state.brandBillingDetails?.attributes?.billing_email !== "" && this.state.brandBillingDetails?.attributes?.first_name !== "" &&
      this.state.brandBillingDetails?.attributes?.last_name !== "") || this.state.brandDetailData?.brand_group_id != null) {
      const header = {
        "Content-Type": configJSON.briefingUpdateStatusApiContentType,
        token: localStorage.getItem("token")
      };

      const httpBody = {
        data: {
          action: "submit_briefing",
          request_quote: true
        }
      };

      const requestUpdateTaskMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.statusUpdateToRequestQuoteId = requestUpdateTaskMessage.messageId;
      requestUpdateTaskMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.briefingUpdateStatusAPiEndPoint + '/' + taskId
      );

      requestUpdateTaskMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestUpdateTaskMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestUpdateTaskMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.briefingUpdateStatusAPiMethod
      );

      runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);
    }
    else {
      this.setState({
        errorBillingDetailsPopup: true
      }, async () => {
        this.callGetProjectsListApiTypeMounting()
        await this.componentDidMount2()
      })
    }
  }

  handleClickStartRequestQuote = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddRequestQuoteModel: true,
    });
  };

  handleClickStopRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickChangeRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickSaveRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.projectDuplicateTaskCreateAPICallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson.data) {
        this.callGetTaskTemplateListApi(responseJson?.data?.id);
        let temp = this.state.currentTask

        temp.attributes.title = responseJson.data.attributes.title
        temp.attributes.description = responseJson.data.attributes.description
        temp.id = responseJson.data.id
        temp.attributes.order_number = responseJson.data.attributes.order_number

        this.setState({ currentTask: temp }, () => {
          this.handleClickDuplicateTask(this.state.currentTask)
        })
        toast.success("Task created successfully");
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteSubTaskApiID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.message) {
        toast.success(responseJson?.message);
        this.callGetSubTaskListApi(this.state.subTasksData[0].attributes?.task_id)
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.state.createTaskBriefingApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.data) {
        toast.success("Template created successfully");
        this.setState({ flagCreateTemplate: false, TemplateName: "", modelForDelete: false, selectedTaskFromDraggable: null })
        this.callGetProjectsListApiTypeMounting()
        await this.componentDidMount2()
      } else if (responseJson?.errors?.[0].task_briefing) {
        toast.error(responseJson?.errors?.[0].task_briefing);
      } else if (responseJson?.errors[0].name) {
        toast.error(responseJson?.errors[0].name);
      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandDetailId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ brandDetailData: responseJson?.data?.attributes }, () => {
          console.log("brandDetailData", this.state.brandDetailData);

        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.submitProductionApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ submitProduction: false, loading: false })
        this.callGetStatusListApi();
        this.callShowTaskApi(this.state.currentTask?.id);
        this.setState({ isComingFromBriefingForm: false });
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteTaskInvoiceFromTheTaskViewID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        console.log("respose", responseJson);

        this.setState({ deleteFinalInvoiceDialog: false, menuflaginvoice: false })
        this.getListInvoiceTaskFileIndex();
        toast.success("Invoice file is deleted successfully")
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadInvoiceFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        console.log("respose", responseJson);

        this.setState({ uploadingFilesId: [], uploadDialog: false })
        this.getListInvoiceTaskFileIndex();
        toast.success("Invoice file is uploaded successfully")
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.callTempleteListingApiId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({
          tempeleteListing: responseJson.project_templates.data
        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.callCreateTempleteApiId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        // this.setState({brandDetailData : responseJson?.data?.attributes },()=>{
        //   console.log("brandDetailData", this.state.brandDetailData);

        // })
        toast.success("Templete Created Successfully")
        this.closeCreateTempleteModel()
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.bulkTaskCommentUploadingID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
      toast.success("Files Uploaded successfully!")

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteBriefingFIleAPICallID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadBriefingFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        toast.success("File deleted successfully")
      }
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          // this.getListTaskFileIndex()
          var templateData = this.state.templateFormFieldsData;
          if (this.state.templateFormConditionsData && this.state.templateFormConditionsData.length > 0) {
            let conditionData = this.state.templateFormConditionsData.filter((condition: any) => condition.attributes.condition_label_id == parseInt(templateData[this.state.fileOptionId].id));
            if (conditionData.length > 0) {
              conditionData.map((condition: any) => {
                let findMatch = templateData.findIndex((data: any) => data.id == condition.attributes.response_label_id);
                if (condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                  templateData[findMatch].showField = false;
                  if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                    templateData[findMatch].attributes.file_blob_signed_id_url = "";
                  }
                  templateData[findMatch].attributes.default_value = "";
                  templateData[findMatch].value = "";
                }

                if (condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                  templateData[findMatch].showField = true;
                  if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                    templateData[findMatch].attributes.file_blob_signed_id_url = "";
                  }
                  templateData[findMatch].attributes.default_value = "";
                  templateData[findMatch].value = "";
                }

                // if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                //   templateData[findMatch].showField = true;
                //   if (templateData[findMatch].attributes.element == "file" || templateData[findMatch].attributes.element == "image") {
                //     templateData[findMatch].attributes.file_blob_signed_id_url = "";
                //   }
                //   templateData[findMatch].attributes.default_value = "";
                //   templateData[findMatch].value = "";
                // }//test

              })
            }
          }
          this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
          toast.success("Files Uploaded successfully!")

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.getListTaskFileIndex()
          this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false, uploadVersion: null })
          toast.success("Files Uploaded successfully!")

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaFolderAssetFilesApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          mediaFolderAssetdFiles: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          mediaState: 3,
          loading: false

        })

      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFileApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          projectFilesAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,

          mediaState: 2,
          loading: false
        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteStatusApiCallId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({
            deleteStatusDialog: false,
            selectedStatus: null

          })
          toast.success("Status Deleted Successfully")
          this.callGetStatusListApi()

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.callEditStatusApiId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({
            editStatusDialog: false,
            selectedStatus: null

          })
          toast.success("Status update Successfully")
          this.callGetStatusListApi()

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFolderApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data) {
        this.setState({
          projectFoldersAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,


        })

      }
    }

    // runEngine.debugLog("Message Recived",message);
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.mediaFolderAPIId) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({
            selectedProjectsMediaAssetData: responseJson.media_assets_folders.data,

          })

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.taskDeleteID) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        toast.success("Task Deleted Successfully")
        this.setState({ modelForDelete: false, deleteDialog: false })
        this.callGetProjectsListApiTypeMounting()
        await this.componentDidMount2()


        // if (this.state.gridProjectPortfolio == true) {


        //   this.filterTasksBasedOnUserAndStatus();
        // } else {


        //   this.changeToListView();
        // }
        if (localStorage.getItem("selectedGridView") == "false") {
          this.changeToListView();
        }
        else {
          this.changeToGridView()
        }
        //  this.callGetUnstartedTaskListApi(this.state.selectedProjectId)
        //  this.callGetQATaskListApi(this.state.selectedProjectId)
        //  this.callGetDoneTaskListApi(this.state.selectedProjectId)
        //  this.callGetUnstartedTaskListApi(this.state.selectedProjectId);
        //  this.callGetOpenTaskListApi(this.state.selectedProjectId);
        //  this.callGetQATaskListApi(this.state.selectedProjectId);
        //  this.callGetOngoingTaskListApi(this.state.selectedProjectId);


      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandTeamId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {

        // let teamData = []
        // teamData.push(...message?.properties?.RestAPIResponceSuccessMessage?.teams.data)
        // let arr = []
        // arr.push(...teamData)
        // console.log(arr)
        this.setState({

          brandTeamsData: message?.properties?.RestAPIResponceSuccessMessage
        }
          , () => {
            console.log(this.state.brandTeamsData, "teamsDara2")
          }
        )



      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryApiCallId) {
      console.log('run++++')
      if (message?.properties?.RestAPIResponceSuccessMessage?.brands?.data) {

        this.setState({
          assetData: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data,
          loading: false

        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaAssetsApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
        //let totalPagesCount = this.state.totalPages;
        this.setState({
          brandMediaAssets: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
          loading: false,
          //count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
          //totalPages:message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : totalPagesCount,
          mediaState: 1
        })

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskDetailId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          // this.setState({ projectDesc: responseJson?.data?.attributes?.description })

          this.setState({
            //  openAddBriefingModel: false,
            isCurrentTaskSelected: true,
            currentTask: responseJson?.data,
            currentTaskBriefingData: responseJson?.data?.attributes?.briefing?.data?.attributes?.briefed_values?.data,
            setOpen2: true,

          }, () => {
            let elementForm = this.state.currentTaskBriefingData
            let removed = elementForm.shift()
            elementForm.push(removed)
            console.log("From State", elementForm)
            this.setState({ currentTaskBriefingData: elementForm })
            this.getListTaskFileIndex();
            this.getListInvoiceTaskFileIndex()
            //console.log("Current Task", this.state.currentTask) 
          })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateAlertID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          //this.setState({ projectDesc: responseJson?.data?.attributes?.description })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectDescId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({ projectDesc: responseJson?.data?.attributes?.description })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getNotifyNotificationId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({ notify: responseJson }, () => {
            //console.log("notify", this.state.notify);

          })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFiltersId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        // debugger;
        if (apiRequestCallId && responseJson) {
          // console.clear();
          //console.log(responseJson)
          this.setState({ tableProjectData: responseJson.projects.data, loadingIndicator: false })


        } else {
          //console.log("else")
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getOpenQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          //console.log("Open Quote", responseJson);
          this.setState({ setDraftQuote: responseJson.tasks?.data })
          // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let OpenUpdatedDate: any = [];
          this.state.setDraftQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            OpenUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ openQuoteActualDate: OpenUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getPendingQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          //console.log("Pending Quote", responseJson);
          this.setState({ setPendingQuote: responseJson.tasks?.data })
          // // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let pendingUpdatedDate: any = [];
          this.state.setPendingQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            pendingUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ pendingQuoteActualDate: pendingUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getApprovedQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          this.setState({ setApprovedQuote: responseJson.tasks?.data })
          //console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let ApprovedUpdatedDate: any = [];
          this.state.setApprovedQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            ApprovedUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ approvedQuoteActualDate: ApprovedUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }
    if (this.getSignedURLForFileUploadId.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {



            localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            // this.setState({ resp: responseJson }, () => {
            //   this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
            // })
            if (this.state.flagForImage == true && localStorage.getItem("task") == '4') {

              this.setState(prevState => {
                return {
                  ...prevState,
                  signedIdAndURLs2: [...prevState.signedIdAndURLs2, responseJson],
                  fileNameImage: this.array2
                }
              }, async () => {
                if (this.state.signedIdAndURLs2.length == this.state.countForFile) {
                  this.signedIdHandler()
                }
              })

              // this.setState({ signedIdAndURLs2: [...this.state.signedIdAndURLs2, responseJson], fileNameImage: this.array2 }, () => {
              //   console.log("Dtaassss", this.state.signedIdAndURLs2);
              //   if (this.state.signedIdAndURLs2.length == this.array2.length) {
              //     this.signedIdHandler()
              //   }
              // })
            }
            else if (this.state.flagForImage == false && localStorage.getItem("task") == '3') {

              // this.setState({ signedIdAndURLs: [...this.state.signedIdAndURLs, responseJson], fileName: this.array }, () => {
              //   console.log("Dtaassss", this.state.signedIdAndURLs);
              //   if (this.state.signedIdAndURLs.length == this.array.length) {
              //     this.signedIdHandler()
              //   }
              // })

              this.setState(prevState => {
                return {
                  ...prevState,
                  signedIdAndURLs: [...prevState.signedIdAndURLs, responseJson],
                  fileName: this.array
                }
              }, async () => {
                if (this.state.signedIdAndURLs.length == this.state.countForFile) {
                  this.signedIdHandler()
                }
              })
            }

            else if (localStorage.getItem("task") == '0') {

              this.setState(prevState => {
                return {
                  ...prevState,
                  signedIdAndURLs3: [...prevState.signedIdAndURLs3, responseJson],
                  fileName: this.array3
                }
              }, async () => {
                if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
                  this.signedIdHandler()
                }
              })


              // this.setState({ signedIdAndURLs3: [...this.state.signedIdAndURLs3, responseJson], fileName: this.array3 }, () => {
              //   console.log("Dtaassss", this.state.signedIdAndURLs3, this.array3.length);
              //   if (this.state.signedIdAndURLs3.length == this.array3.length) {
              //     this.signedIdHandler()
              //   }
              // })
            }
            else {
              // console.log("right");

              this.setState({ resp: responseJson }, () => {
                this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
              })
            }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("Dtaassss", this.state.fileToRawBinary, responseJson)
            this.setState({ presignedData: responseJson.data[0] }, () => {
              this.bulkTaskCommentUploading(responseJson.data[0], this.state.fileToRawBinary);
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedUrlInBriefingImageID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ presignedDataImage: responseJson.data[0] }, () => {
              //console.log("Briefied Value images", this.state.presignedDataImage)
            })
            // localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            this.bulkTaskCommentUploading(responseJson.data[0], this.state.fileToRawBinary);
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedUrlInBriefingFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ presignedData: responseJson.data[0] }, () => {
              //console.log("Briefied Value images", this.state.presignedData)
            })
            // localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            this.bulkTaskCommentUploading(responseJson.data[0], this.state.fileToRawBinary);
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (this.getSignedURLForFileUploadId2.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      if (localStorage.getItem("task") == "2") {
        this.setState({ loading: false, countForFile: 0 })
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "1") {
        this.setState({ loading: false, countForFile: 0 })
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "0" && this.array3.length > 0) {
        // this.taskFileUploadBulk(this.state.signedIdAndURLs3);
        this.setState(prevState => { return { ...prevState, countUpload2: prevState.countUpload2 + 1 } }, async () => {
          if (this.state.countUpload2 == this.state.signedIdAndURLs3?.length) {
            this.setState({ loading: false, countUpload2: 0, countForFile: 0 })
            // this.taskFileUploadBulk(this.state.resp);
            this.taskFileUploadBulk(this.state.signedIdAndURLs3);
          }
        })

      }
      else if (this.array.length > 0 || this.array2.length > 0) {
        this.setState({ countUpload: this.state.countUpload + 1 }, () => {
          if (this.state.flagForImage == true) {
            if (this.state.countUpload == this.state.signedIdAndURLs2.length) {
              this.setState({
                loading: false, countUpload: 0, uploadDialog: false, flagForImage: false, countForFile: 0, briefingImages: [
                  {
                    ...this.state.briefingImages[0],
                    [this.state.fileOptionId]: {
                      signedId: this.state.signedIdAndURLs2,
                      fileName: this.array2
                    }
                  }
                ]
              }, () => {
                console.clear();
                console.log("briefingImages", this.state.fileOptionId, this.state.briefingImages);
                this.setState({ signedIdAndURLs2: [] })
                this.array2 = []

              })

              toast.success("files uploaded successfully")
            }
          }
          else {
            if (this.state.countUpload == this.state.signedIdAndURLs.length) {
              this.setState({
                loading: false, countUpload: 0, uploadDialog: false, countForFile: 0, briefingFiles: [
                  {
                    ...this.state.briefingFiles[0],
                    [this.state.fileOptionId]: {
                      signedId: this.state.signedIdAndURLs,
                      fileName: this.array
                    }
                  }
                ]
              },
                () => {
                  console.clear();
                  console.log("briefingFiles", this.state.fileOptionId, this.state.briefingFiles);
                  this.setState({ signedIdAndURLs: [] })
                  this.array = []
                })
              toast.success("files uploaded successfully")
            }
          }


        })

      }



    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectArchiveApi) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project archived successfully");
            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callDeleteAccountProjectsApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson)
            toast.success("Member Removed successfully");
            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();
            const item: any = localStorage.getItem("selectedProjectId")
            this.callShowAccountforProjectApi(item)
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectDeleteApi) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ deleteProjectDialog: false })
            toast.success("project deleted successfully");
            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].role);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectApiDuplicateCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project duplicated successfully");

            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectTeamUpdateCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project team members successfully invited");
            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();
            this.callShowAccountforProjectMentionsApi('')
            this.setState({
              inviteTeamMembersDialog: false,
              anchorEl: null,
              editorState: EditorState.createEmpty(),
              openProjectProperties: false
            });
          }
        }
      } else if (responseJson.errors) {
        toast.error(`Account ` + errorResponse.errors[0].account_ids);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateProjectPropertiesApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project updated successfully");

            this.callGetProjectsListApiType1();
            this.callGetProjectsListApiType2();
            this.callGetProjectsListApiType3();
            this.callGetProjectsListApiType4();
            this.callShowAccountforProjectApi(this.state.selectedProjectData.id)
            this.callShowProjectsApi(this.state.selectedProjectData.id)
            const selectedProjectId = localStorage.getItem("selectedProjectId")
            if (selectedProjectId) {
              this.callShowAccountforProjectApi(selectedProjectId)
            }

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].role);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToInvoiceBulkFileUpload) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (localStorage.getItem("task") == 'invoice') {
            // this.setState({ countUpload: this.state.countUpload + 1 }, () => {
            // if (this.state.countUpload == this.state.signedIdAndURLs3.length) {
            this.setState({ loading: false, countUpload: 0, uploadDialog: false, signedIdAndURLs3: [] })
            toast.success("files uploaded successfully")
            this.getListInvoiceTaskFileIndex()
            if (this.state.currentTask?.id) {
              this.getTaskActivityListApi(this.state.currentTask.id);
            }
            this.array3 = []
            // }
            // })
          }

        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToBulkFileUpload) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (localStorage.getItem("task") == '0') {
            // this.setState({ countUpload: this.state.countUpload + 1 }, () => {
            // if (this.state.countUpload == this.state.signedIdAndURLs3.length) {
            this.setState({ loading: false, countUpload: 0, uploadDialog: false, signedIdAndURLs3: [] })
            toast.success("files uploaded successfully")
            this.getListTaskFileIndex()
            if (this.state.currentTask?.id) {
              this.getTaskActivityListApi(this.state.currentTask.id);
            }
            this.array3 = []
            // }
            // })
          }
          else if (apiRequestCallId && responseJson) {
            this.setState({ uploadDialog: false, countForFile: 0 })
            toast.success("file uploaded successfully")
            this.setState({
              // fileTaskName: responseJson?.data,
              setAnchorEl: null,
              setSourceFileAnchorEl: null,
              uploadVersion: null
            })
            this.array3 = []
            this.getListTaskFileIndex()
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteTaskFileFromTheTaskViewID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ deleteFinalFileDialog: false, deleteSourceFileDialog: false })
            toast.success("Task file deleted successfully")
            this.getListTaskFileIndex()
            this.setState({
              loading: false
            })
          }
        }
      } else {
        this.setState({
          loading: false
        })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectShowApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("projectProject", responseJson)
            this.setState({
              selectedProjectData: message?.properties?.RestAPIResponceSuccessMessage?.data,

            }, () => {
              if (this.state.navigationScreenType == 'projectScreen' || this.state.navigationScreenType == 'TaskScreen') {
                this.setData(this.state.selectedProjectData);
              }
            })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getInvoiceTaskFileId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {


            this.setState({ fileInvoiceTaskName: responseJson?.invoices?.data, loading: false })
            // console.clear()
            console.log("File invoice", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.log("some file121");

            this.setState({ fileTaskName: responseJson?.task_files?.data, loading: false }, () => {
              if (this.state.finalFilesIdFromUrl) {
                const finalFile = this.state.fileTaskName.filter((ele: any) => ele?.id == this.state.finalFilesIdFromUrl)
                if (finalFile?.length) {
                  this.setState({
                    fileId: finalFile[0]?.id,
                    file_blob_signed_id_url: finalFile[0]?.attributes?.file_blob_signed_id_url,
                    fileImage: finalFile[0]?.attributes?.name,
                    fileType: finalFile[0]?.attributes?.file_type,
                    fileData: finalFile[0]?.attributes, loading: true
                  },
                    () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); })
                }

              }
            })
            // console.clear()
            //console.log("File Data Bulk Upload", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileSingleId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("File Data Bulk Upload", responseJson)
            this.setState({
              latestVesrionDetails: responseJson.data
            }, () => {
              this.getAllAnnotationTaskFile();
              this.getAllVersionOfAsset();
            });
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createTaskFileAnnotationId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("File Annotation", responseJson)
            this.createAnnotationComments(responseJson?.data[0]?.id);
          }
        }
      } else {
        this.setState({
          loading: false
        })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            if (this.state.isResolved) {
              this.resetAnnotationInPdf();
            }
            console.log("++++++++++++++++")
            let data = responseJson.task_file_annotations.data;
            if (this.state.filterType == "5" && data.length > 0) {
              data = data.filter((annotation: any) => annotation.attributes.is_resolved !== true)
            }
            this.setState({
              getAllFileTaskAnnotation: data
            }, () => {
              let annotationData = this.state.getAllFileTaskAnnotation?.map((values: any) => ({
                geometry: { type: "RECTANGLE", x: parseFloat(values?.attributes?.x_coordinate), y: parseFloat(values?.attributes?.y_coordinate), width: parseFloat(values?.attributes?.width), height: parseFloat(values?.attributes?.height) },
                data: { text: values?.attributes?.task_file_annotation_comments?.data[0]?.attributes?.comment, id: parseInt(values?.attributes?.task_file_annotation_comments?.data[0]?.id) }
              }))
              //console.log("Extact Data from annotation", annotationData)
              this.setState({
                annotations: annotationData,
                loading: false
              }, () => {
                if (this.state.fileType !== "pdf" && this.state.fileType !== "video" && (loadAnnotationJqueryCount == 1 || this.state.isResolved)) {
                  console.log("this is called loadJqueryForAnnotation")
                  this.loadJqueryForAnnotation();
                  loadAnnotationJqueryCount = 2;
                }
              })
            })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType1) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        // debugger;
        this.setState(prevState => ({
          projectsOfStatusDraftData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
          tableProjectData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
          total_pages: message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : prevState.total_pages,
          next_page: message?.properties?.RestAPIResponceSuccessMessage?.next_page ? message?.properties?.RestAPIResponceSuccessMessage?.next_page : prevState.next_page,
          loadingIndicator: false
        }));
        this.handleCloseLoader();
        this.getBrandTeams();
        this.getNotifyNotifications();
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType121) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        // debugger;
        this.setState(prevState => ({
          tableProjectData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
          loadingIndicator: false
          // total_pages: message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : prevState.total_pages,
          // next_page: message?.properties?.RestAPIResponceSuccessMessage?.next_page ? message?.properties?.RestAPIResponceSuccessMessage?.next_page : prevState.next_page
        }));
        this.getNotifyNotifications()
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdTypeForAB) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        var selectedProject: any = message?.properties?.RestAPIResponceSuccessMessage?.projects?.data;

        //this.setProjectData(selectedProject);
        this.setState({
          projectsForAB: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
        }, () => {
        })
        this.setState({ next_page: message?.properties?.RestAPIResponceSuccessMessage?.next_page })
        this.setState({ total_pages: message?.properties?.RestAPIResponceSuccessMessage?.total_pages })

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType2) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        this.setState({
          projectsOfStatusWaitingForQuoteData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
        }, () => {
        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType4) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {
        this.setState({
          projectsOfStatusApprovedData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
          projectsOfStatusOnGoingData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
        }, () => {
        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectCreateApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetProjectsListApiType1();
            // this.callGetProjectsListApiTypeForAB();
            this.setState({ tempeleteChoosen: null })
            toast.success("Project created successfully");
          }
        }
      } else if (responseJson.errors?.length) {
        // toast.error(errorResponse.errors[0].name);
        toast.error("Ups! This name already exists.")
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.unstartedTaskListApiID) {
      //console.log("messagemessage", message)
      if (message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data) {
        var columns = this.state.columnsKanban
        columns["UnStarted"].tasks = message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data
        this.setState({
          columnsKanban: columns,
          unstartedTasksData: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
          projectNameSelectedShow: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data[0]?.attributes?.project_name,
          projectDescriptionSelectedShow: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data[0]?.attributes?.project_desciption,
        }, () => {
        })
        //console.log("unstartedTasksData", this.state.unstartedTasksData);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.openTaskListApiID) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data) {

        var columns = this.state.columnsKanban
        columns["Open"].tasks = message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data
        this.setState({
          columnsKanban: columns,
          openTasksData: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
        }, () => {
        })
        //console.log("vopenTasksData", this.state.openTasksData);
      }
    }
    this.state.columnOrderKanban?.map((item: any) => {
      //console.log(this.state.columnOrderKanban)
      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === item.messageId) {
        //console.log("columnsKanbanviewwwwww", this.state.columnsKanban, message?.properties?.RestAPIResponceSuccessMessage)

        if (message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data) {

          var columns = this.state.columnsKanban
          columns[item.id].tasks = message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data
          this.setState({
            columnsKanban: columns,
            openTasksData: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
          }, () => {
          })
          //console.log("columnsKanban", this.state.columnsKanban)

        }
      }


    })

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiUpdateDuplicateProjectProperties) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.error) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {

          if (apiRequestCallId && responseJson) {

            this.callGetProjectsListApiType1()
            // toast.success("Updated successfully ");
            // console.clear()
            //console.log(responseJson)

          }
        }
      } else {
        toast.error(errorResponse.error);
      }
    }



    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectTaskCreateAPICallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            //this.callGetTaskTemplateListApi(responseJson.data.id);
            // this.callGetSubTaskListApi(responseJson.data.id);
            // this.callGetTaskBlockerListApi(responseJson.data.id);
            //console.log(responseJson);
            toast.success("Task created successfully");
            this.handleClickStartBriefing();
            if (this.state.selectedTaskTemplate != null) {
              this.callCreateTaskTemplateApi(responseJson?.data?.id);
              // this.callGetTaskTemplateListApi(responseJson?.data?.id);
            } else {
              this.callGetTaskTemplateListApi(responseJson?.data?.id);
              // this.callCreateTaskTemplateApi(responseJson?.data?.id);
            }
            this.setState({
              isCurrentTaskSelected: true,
              currentTask: responseJson.data,
            }, () => {
            })
          }
        }
      } else if (responseJson.errors) {
        // toast.error(errorResponse.errors[0].title);
        // {if(!this.state.category && !this.state.assetType)
        //   toast.error("No Asset category or Type was selected")}
        this.setState({
          openTaskCreateModel: true,
          openAddBriefingModel: false,
          isLabel: '',
          loading: false
        });
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
        this.setState({
          openTaskCreateModel: false,
          openAddBriefingModel: false,
          isLabel: '',
          loading: false
        })
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
        this.setState({
          openTaskCreateModel: false,
          openAddBriefingModel: true,
          isLabel: '',
          loading: false
        })
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTemplateFormFieldsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // let dataLength = responseJson.template_form_fields.data.length;
            console.log("response", responseJson.template_form_fields.data);

            let removed = responseJson.template_form_fields.data.shift();
            console.log("removed", removed);
            let dataLength = responseJson.template_form_fields.data.length;
            if (responseJson.template_form_fields?.data[dataLength - 1]?.attributes?.element == "page_separator") {
              responseJson.template_form_fields.data.splice((dataLength - 1), 0, removed);
              console.log("response2", responseJson.template_form_fields.data);

              // responseJson.template_form_fields.data.unshift(removed);
            } else {
              responseJson.template_form_fields.data.unshift(removed);
              console.log("response3", responseJson.template_form_fields.data);

              responseJson.template_form_fields.data.push(
                {
                  "attributes":
                  {
                    "default_value": "page_separator",
                    "element": "page_separator",
                    "label": ""
                  },
                  "id": "1",
                  "type": "template_form_field"
                }
              )
            }
            console.log("response4", responseJson.template_form_fields.data);

            var paginationCount = responseJson.template_form_fields.data.map((items: any) => items.attributes.element == "page_separator").reduce((cnt: any, cur: any) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
            let pageSeparatorIndex: any = []
            responseJson.template_form_fields.data.map((item: any, index: any) => {
              if (item.attributes.element == "page_separator") {
                pageSeparatorIndex.push(index)
              }
            })
            // if(pageSeparatorIndex.length > 0){
            //   if(pageSeparatorIndex[pageSeparatorIndex.length - 1] < (responseJson.template_form_fields.data.length - 1)){
            //     paginationCount.true = paginationCount.true + 1;
            //   }
            // }
            this.setState({ paginationCount: paginationCount.true })
            if (paginationCount.true !== undefined) {
              this.setState({ pageSeparatorIndex: pageSeparatorIndex }, () => {
                //console.log("Page Separator Array", this.state.pageSeparatorIndex)
                this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
              })
            } else {
              this.setState({ fromStart: 0, fromEnd: 100 })
            }


            let tempArr = [] as any;

            responseJson.template_form_fields.data.map((_data: any) => {
              tempArr.push({
                attributes: _data.attributes,
                id: _data.id,
                showField: true
              })
            })
            tempArr.map((_data: any) => {
              if (_data.attributes.element == "multiple_selection") {
                if (_data.attributes.default_value !== null && _data.attributes.default_value !== "") {
                  _data.attributes.default_value = _data.attributes.default_value.split(",")
                  _data.value = _data.attributes.default_value;
                } else if (_data.attributes.element == "page_separator") {

                } else {
                  _data.attributes.default_value = [];
                  _data.value = [];
                }
              } else {
                if (_data.attributes.default_value !== null) {
                  _data.value = _data.attributes.default_value
                } else {
                  _data.value = ""
                }
              }
            })
            this.setState({
              templateFormFieldsData: tempArr,
              loading: false
            }, () => {
              let elementForm = this.state.templateFormFieldsData
              // let removed = elementForm.shift()
              // elementForm.push(removed)
              console.log("From State", elementForm)
              this.setState({ templateFormFieldsData: elementForm })
              console.log("Data from the template", this.state.templateFormFieldsData)
            });
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTemplateFormConditionsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.callGetProjectsListApiType1();
            // toast.success("Task created successfully");
            //console.log("afterthat 2", responseJson)
            let templateFormConditions = responseJson?.template_form_conditions?.data;
            let templateData = this.state.templateFormFieldsData;
            if (templateFormConditions && templateFormConditions.length > 0) {
              let conditionData = templateFormConditions;
              if (conditionData.length > 0) {
                conditionData.map((condition: any) => {
                  let applyOnIndex = templateData.findIndex((data: any) => parseInt(data.id) == condition.attributes.response_label_id);

                  if (!condition.attributes.is_condition_selected && condition.attributes.is_response_to_show) {
                    templateData[applyOnIndex].showField = true;
                  }

                  if (!condition.attributes.is_condition_selected && !condition.attributes.is_response_to_show) {
                    templateData[applyOnIndex].showField = false;
                  }

                })
              }
            }
            this.setState({
              templateFormConditionsData: responseJson?.template_form_conditions?.data,
              templateFormFieldsData: templateData
            }, () => {
              this.state.selectedTaskTemplate ? this.handleClickBriefingTemplateform(this.state.templateFormFieldsData, this.state.templateFormConditionsData) : null
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.taskTemplateAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (this.state.taskDuplicateFlag) {
          return;
        }
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callTemplateFormFieldsApi(responseJson.data.id);
            setTimeout(() =>
              this.callTemplateFormConditionsApi(responseJson.data.id),
              3000);
            // 
            //console.log("afterthat 3", responseJson)

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateBriefingApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // if (this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
            //   || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") {
            // this.getApprovedQuote()
            // this.getOpenQuote()
            // this.getPendingQuote()
            // this.callGetAccountListApi('');

            // } else {
            if (this.state.isComingFromBriefingForm == true) {
              if (this.state.submitProduction) {
                this.submitProductionReady()
              }
              else {
                // this.statusUpdateToRequestQuote(responseJson.data.attributes.task_id);
                this.statusUpdateToRequestQuote(responseJson.data.id);
                toast.success("Requested quote successfully");
              }

            } else {
              this.callGetStatusListApi();
              this.callShowTaskApi(responseJson.data.attributes.task_id);
              toast.success("Briefing saved successfully");
            }
            this.setState({
              templateFormFieldsData: [],
              templateFormConditionsData: [],
              uploadingFilesId1: [],
              uploadingFilesId2: [],
              flagForMediaUpload2: null,
              flagForMediaUpload1: null,
              signedIdAndURLs: [],
              signedIdAndURLs2: [],
              flagForImage: false,
              fileNameImage: [],
              fileName: [],
              briefingImages: [{}],
              briefingFiles: [{}],
              briefingMediaFiles: [{}],
              briefingMediaImages: [{}],
              page: 1,
              loadingIndicator: false
            });
            this.array = []
            this.array2 = []
            this.arratMD5 = []

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateBriefingApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            if (this.state.submitProduction) {
              this.submitProductionReady()
            }
            if (this.state.isComingFromBriefingForm == true) {


              this.statusUpdateToRequestQuote(this.state.currentTask?.attributes?.briefing?.data?.id);
              toast.success("Requested quote successfully");

              // this.statusUpdateToRequestQuote(responseJson.data.attributes.task_id);

            } else {
              this.callGetStatusListApi();
              this.callShowTaskApi(this.state.currentTask?.id)
              toast.success("Briefing saved successfully");
            }

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(responseJson?.data?.id);
            this.callGetTaskBlockerListApi(responseJson?.data?.id);
            this.setState({
              isCurrentTaskSelected: true,
              currentTask: responseJson?.data,
              currentTaskBriefingData: responseJson?.data?.attributes?.briefing?.data?.attributes?.briefed_values?.data,
              loading: false
            }, () => {
              //kk
              let s = localStorage.getItem("searchtype")

              if (s === "project") {
                return
              }
              if ((this.state.navigationScreenType == "TaskScreen") || (this.state.navigationScreenType == 'projectsScreen')) {
                this.handleClickOpenTaskModal(this.state.currentTask);
              }
              else {
                this.handleClickOpenTaskModal(responseJson?.data);
              }
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdTypeMount) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.callTemplateFormFieldsApi(responseJson.data.id);
            // this.callTemplateFormConditionsApi(responseJson.data.id);
            localStorage.setItem("projectsDataSelected", JSON.stringify(responseJson?.projects?.data))
          }
        }
      } else if (responseJson?.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileListID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.callTemplateFormFieldsApi(responseJson.data.id);
            // this.callTemplateFormConditionsApi(responseJson.data.id);
            // localStorage.setItem("projectsDataSelected", JSON.stringify(responseJson.projects.data))
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetStatusListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            var columnOrder: any = this.state.columnOrderKanban
            var dynamicStatus: any = []
            var columns: any = this.state.columnsKanban
            console.log("this.state.brandDetailData?.brand_group_id", this.state.brandDetailData);
            const brandGroup = localStorage.getItem("brandGroupSelectedName")
            var dataTest: any = brandGroup == null || brandGroup == undefined ? [
              {
                id: "1",
                attributes: {
                  status: "Draft",
                  action: "draft",
                  status2: "New"
                }
              },
              {
                id: "2",
                attributes: {
                  status: "Quote Pending",
                  action: "quotepending",
                  status2: "Quote Requested"
                }
              },
              {
                id: "3",
                attributes: {
                  status: "Quote Approved",
                  action: "approve_quote",
                  status2: "Quote Approved"
                }
              },
              // {
              //   id: "4",
              //   attributes: {
              //     status: "Inprogress",
              //     action: "inprogress_action",
              //     label_name : "In Progress"
              //   }
              // },
              ...responseJson.statuses.data,
            ] :
              [
                {
                  id: "1",
                  attributes: {
                    status: "Draft",
                    action: "draft",
                    status2: "New"
                  }
                },
                // {
                //   id: "2",
                //   attributes: {
                //     status: "Quote Pending",
                //     action: "quotepending"
                //   }
                // },
                // {
                //   id: "3",
                //   attributes: {
                //     status: "Quote Approved",
                //     action: "approve_quote"
                //   }
                // },
                // {
                //   id: "4",
                //   attributes: {
                //     status: "Inprogress",
                //     action: "inprogress_action",
                //     label_name : "In Progress"
                //   }
                // },
                ...responseJson.statuses.data,
              ]

            this.setState({
              taskStatusesData: dataTest,
              // creteTempleteButton : responseJson.statuses.data.length > 0
            }, () => {
              let filtersStatus = responseJson.statuses.data.filter((st: any) => st.attributes.status != 'Done' && st.attributes.status != 'Feedback')

              this.setState({
                creteTempleteButton: filtersStatus.length > 0
              }, () => console.log("creteTempleteButton", filtersStatus)
              )

              dataTest.map((item: any) => {

                dynamicStatus.push({ "id": item.attributes.status, "action": item.attributes.action, "title": item.attributes.status, "messageId": '' })
                columns[item.attributes.status] = {
                  id: item.id,
                  title: item.attributes.status,
                  action: item.attributes.action,
                  tasks: [],
                  messageId: ''
                }
              })
            })
            this.setState(function (state, props) {
              return {
                dynamicStatus: columns,
                columnOrderKanban: dynamicStatus,
                columnsKanban: columns,
                dynamicMessages: columns,
              };
            });
            //console.log("alltogether", this.state.taskStatusesData, this.state.dynamicStatus, this.state.columnOrderKanban, this.state.columnsKanban, this.state.dynamicMessages)
            const selectedProjectId: any = localStorage.getItem("selectedProjectId")
            if (selectedProjectId !== null) {
              // this.callGetUnstartedTaskListApi(selectedProjectId);
              this.callGetAccountListApi('');
              // this.callGetOpenTaskListApi(selectedProjectId);
              dynamicStatus.map((column: any) => {
                this.callGetOngoingTaskListApi(selectedProjectId, column)
              })

            }

          }

        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              accountsData: responseJson?.accounts?.data.filter((item: any) => item?.attributes?.role?.data?.attributes?.name?.toUpperCase() == "client".toUpperCase()),
              //assigneeAccountsData: responseJson?.accounts?.data.filter((item: any) => item?.attributes?.role?.data?.attributes?.name?.toUpperCase() == "albertbauer".toUpperCase()),
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    // if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiUpdatePriorityColumn) {
    //   var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    //   var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    //   if (!responseJson.errors) {
    //     const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    //     if (apiRequestCallId != null) {
    //       /// search live
    //       if (apiRequestCallId && responseJson) {
    //         this.getAllStatusByProjectId()
    //       }
    //     }
    //   } 
    // }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectPropertiesAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              projectPropertiesAccountsData: responseJson?.accounts?.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowAccountforProjectApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);
            var selectedProjectsMentionsAccountsData: any = this.state.selectedProjectsMentionsAccountsData.length > 0 ? this.state.selectedProjectsMentionsAccountsData : [];
            responseJson.accounts?.accounts?.data.map((item: any) => {
              //console.log(item)
              var objectValues: any = {}
              objectValues.text = item.attributes.first_name,
                objectValues.value = item.attributes.first_name,
                objectValues.url = item.id,

                selectedProjectsMentionsAccountsData.push(objectValues)
            }
            )
            this.setState({
              selectedProjectsMentionsAccountsData: selectedProjectsMentionsAccountsData,

            })
            this.setState({
              selectedProjectsAccountsData: responseJson.accounts?.accounts?.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowAccountforProjectMentionsApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // console.clear()
            //console.log("Accounts OF uSer", responseJson.accounts?.data);
            var selectedProjectsMentionsAccountsData2: any = this.state.selectedProjectsMentionsAccountsData2.length > 0 ? this.state.selectedProjectsMentionsAccountsData2 : [];


            responseJson?.accounts?.accounts?.data?.map((item: any) => {
              //console.log(selectedProjectsMentionsAccountsData2)
              var objectValues: any = {}
              objectValues.text = item.attributes.first_name,
                objectValues.value = item.attributes.first_name,
                objectValues.url = item.id,
                selectedProjectsMentionsAccountsData2.push(objectValues)
            }
            )
            this.setState({
              selectedProjectsMentionsAccountsData2: selectedProjectsMentionsAccountsData2,

            }, () => {

              console.log("selectedProjectsMention HI", this.state.selectedProjectsMentionsAccountsData2)
            })
            //console.log(this.state.selectedProjectsMentionsAccountsData2, selectedProjectsMentionsAccountsData2)
          }
        }
      } else if (responseJson.error) {
        toast.error(errorResponse?.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowMediaforProjectApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            this.setState({ mediaAssetId: responseJson.media_assets.data[0]?.id }, () => this.getMediaFolders(this.state.mediaAssetId))
            this.setState({
              projectMediaAssetsDetail: responseJson.media_assets.data,
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateStatusApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetStatusListApi()
            toast.success("New Status created successfully");
            this.setState({
              openStatusCreateModel: false,
            });
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].status);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callStateTransitionsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("State changed successfully");
            this.getTaskActivityListApi(this.state.currentTask.id);
            // this.handleClickOpenTaskModal(this.state.currentTask?.id)
            this.callGetStatusListApi();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].status);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createAnnotationCommentId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              annotationCompleteDataPdf: EditorState.createEmpty(),
              showAddAnnotation: false,
              convertedReplyData: {},
              replyEditorState: EditorState.createEmpty()
            });
            this.getAllAnnotationTaskFile();
            //console.log("Response Fromn the comment annotate", responseJson)
          }
        }
      } else {
        this.setState({
          loading: false,
          annotationCompleteDataPdf: EditorState.createEmpty(),
          showAddAnnotation: false,
          convertedReplyData: {}
        })
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllCommentsAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // this.setState({ getAllFileTaskAnnotation: responseJson.task_file_annotation_comments.data })
            //console.log("Response From the comment annotate function", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetTaskCommentListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("Response From the task  comment  function", responseJson)
            // this.setState({ getAllTaskCommentsData: responseJson.task_file_annotation_comments.data })

          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskCommentApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("Response From the task comment  function", responseJson)
            this.setState({ getTaskCommentsData: responseJson?.task_comments?.data, loading: false })

          }
          //console.log(this.state.getTaskCommentsData);
        }
      } else {
        this.setState({ loading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.showTaskCommentSpecificId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ specificTaskComment: responseJson?.data?.attributes.description }, () => {
              const html = this.state.specificTaskComment
              const contentBlock = htmlToDraft(html);
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editComment: editorState })
              }
            });
            //console.log("Dtaa from the response", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskCommentID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // console.clear();
            toast.success("Task Comment Updated Successfully");
            this.cancelSpecificTaskComment();
            this.callShowTaskCommentApi(this.state.currentTask.id);
            this.getTaskActivityListApi(this.state.currentTask.id)
            //console.log("Comment Updated", responseJson)

          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSpecificCommentsAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("get Specific Annotation Comment", responseJson)
            this.setState({ annotationSpecificId: responseJson?.data?.attributes?.task_file_annotation?.data?.id }, () => { this.deleteSpecificAnnotationComment() })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteSpecificCommentsAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.deleteSpecificAnnotationCoordinates();
            this.getAllCommentsAnnotationTaskFile();
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteSpecificAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.getAllCommentsAnnotationTaskFile();
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.resolveAnnotationCommentId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.getAllAnnotationTaskFile();
            //console.log("Response Fromn the comment annotate", responseJson)
          }
        }
      } else {
        this.setState({
          loading: false
        })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getFilterAnnotationTaskFileID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            let data = responseJson.task_file_annotations.data;
            if (this.state.filterType == "5" && data.length > 0) {
              data = data.filter((annotation: any) => annotation.attributes.is_resolved !== true)
            }
            this.setState({
              loading: false,
              getAllFileTaskAnnotation: data,
              anchorElTaskFilter: null
            }, () => {

              this.addPdfAnnotation(data);

            })
            console.log("Called Here addPdfAnnotation")
          }
        }
      } else {
        this.setState({
          loading: false
        })
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.finalApprovalId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              loading: false
            });
            this.handleCloseTaskModal();
            this.handleCloseAnnotationModal();
            this.getListTaskFileIndex();
          }
        }
      } else {
        this.setState({
          loading: false
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.submitReviewId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              loading: false
            });
            //inprogress_action
            // this.callStateTransitionsAPI("inprogress_action", this.state.currentTask?.id)
            this.handleCloseTaskModal();
            this.handleCloseAnnotationModal();
            this.getListTaskFileIndex();
          }
        }
      } else {
        this.setState({
          loading: false
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllVersionOfAssetId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              allVesrsionDetails: responseJson.task_files.data,
              fileVersionNumber: `V ${responseJson.task_files.data.length + 1}`,
              fileVersionNumber2: responseJson.task_files.data.length + 1,
            })
            //console.log("File Data Bulk version", responseJson.task_files.data)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.statusUpdateToRequestQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.callGetStatusListApi();
          this.callShowTaskApi(responseJson.data.attributes.task_id);
          this.setState({ isComingFromBriefingForm: false });
        } else {
          //console.log("else")
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getCompanyBillingDetailId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          console.log("responseJson.brand_billings", responseJson.brand_billings.data)
          if (responseJson?.brand_billings?.data && responseJson.brand_billings.data.length > 0) {
            this.setState({ brandBillingDetails: responseJson.brand_billings.data[0] });
          }
        } else {
          //console.log("else")
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteTaskApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          if (apiRequestCallId && responseJson) {
            this.setState({
              loading: false,
              openAddBriefingModel: false,
              openStartBriefingModel: false,
              setOpen2: false,
              templateFormFieldsData: [],
              editorState: EditorState.createEmpty(),
              briefingImages: [{}],
              briefingFiles: [{}],
              briefingMediaFiles: [{}],
              briefingMediaImages: [{}]
            });
            toast.success("Task deleted successfully!!");
          }
        }
      } else {
        toast.error(errorResponse.errors[0]);
        this.setState({
          loading: false
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes && message?.properties?.RestAPIResponceSuccessMessage?.data?.type === 'template') {

      this.setState({
        taskTemplateData: message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes && message?.properties?.RestAPIResponceSuccessMessage?.data?.type === 'project') {

      this.setState({
        projectShowData: message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes,
      }, () => {
      })

    }

    if (message?.properties?.RestAPIResponceSuccessMessage?.task_file_annotations?.data) {

      this.setState({
        taskFileAnnotationsData: message?.properties?.RestAPIResponceSuccessMessage?.task_file_annotations?.data,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.project_notifications?.data) {

      this.setState({
        projectNotificationsData: message?.properties?.RestAPIResponceSuccessMessage?.project_notifications?.data,
      }, () => {
      })

    }

    if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes && message?.properties?.RestAPIResponceSuccessMessage?.data?.type === 'task') {

      this.setState({
        taskShowData: message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes && message?.properties?.RestAPIResponceSuccessMessage?.data?.type === 'task_status') {

      this.setState({
        taskStatusData: message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes && message?.properties?.RestAPIResponceSuccessMessage?.data?.type === 'task_file') {

      this.setState({
        taskFileData: message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.task_files?.data) {

      this.setState({
        taskFileListData: message?.properties?.RestAPIResponceSuccessMessage?.task_files?.data,
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.asset_categories?.data) {

      this.setState({
        assetCategoriesData: message?.properties?.RestAPIResponceSuccessMessage?.asset_categories?.data,
      }, () => {
      })

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.asset_types?.data) {

      if (this.state.selectedCategoryForFilter?.length > 0) {
        this.setState(prevState => ({ filterTaskOnAssetTypes: [...prevState.filterTaskOnAssetTypes, ...message?.properties?.RestAPIResponceSuccessMessage?.asset_types?.data] }));
        console.log("selectedCategoryForFilter", this.state.selectedCategoryForFilter, this.state.filterTaskOnAssetTypes);

      }
      else {
        this.setState({
          assetTypesData: message?.properties?.RestAPIResponceSuccessMessage?.asset_types?.data,
        }, () => {
        })
      }

    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.brand_templates?.data) {

      this.setState({
        templatesData: message?.properties?.RestAPIResponceSuccessMessage?.brand_templates?.data,
      }, () => {
      })
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.subtaskAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.setState({
            subTasksData: responseJson.sub_tasks.data,

          })
        }
      }
      else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.callGetSubTaskListApi(this.state.currentTask.id),
            toast.success("Subtask created  successfully");
          this.setState({
            subTasksData: responseJson.sub_tasks?.data,
          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.callGetTaskBlockerListApi(this.state.currentTask.id)
          toast.success("Blocker created  successfully");
          this.setState({
            blockersData: responseJson.task_blockers?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.blockersAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            blockersData: responseJson.task_blockers?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.taskActivityLogAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            taskActivityData: responseJson.task_activities?.data,

          })

        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskActivityApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            taskActivityData: responseJson.task_activities?.data,
            totalActivityPageCount: responseJson.total_pages
          })

        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getMoreTaskActivityApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            taskActivityData: this.state.taskActivityData.concat(responseJson.task_activities?.data),
          })

        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateRequestQuotationApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);
            toast.success("Requested quotation sent successfully");
            this.callGetStatusListApi();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateRequestQuotationForProjectApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ anchorEl: false })
            toast.success("Requested quotation sent successfully");
          }
        }
      } else if (responseJson.errors) {
        this.setState({ anchorEl: false })
        toast.error(errorResponse.errors[0].messages);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectsActiveListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);

          }
        }
      } else if (responseJson?.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectsDeleteListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson?.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);

          }
        }
      } else if (responseJson?.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson?.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskCommentListApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("rreess", responseJson)
            toast.success("Commented successfully");
            this.setState(
              {
                convertedCommentData: {},
                editorState: EditorState.createEmpty(),
                loading: false
              }, () => {
                this.callShowTaskCommentApi(this.state.currentTask.id);
                this.getTaskActivityListApi(this.state.currentTask.id)

              })
            //console.log("this.state.editorState", this.state.editorState);
          }
        }
      } else if (responseJson.errors) {
        this.setState({ loading: false });
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        this.setState({ loading: false });
        toast.error(errorResponse.error);
      } else {
        this.setState({ loading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectsAchiveListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreatetaskTemplateApiId) {
      // Customizable
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetTaskTemplateListApi(responseJson.data.attributes.task_id);
            // toast.success("Template created successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateTaskAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Task Data updated successfully");
            this.setState({ loading: false });
            this.getTaskActivityListApi(this.state.currentTask.id);
            this.callGetStatusListApi();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
        this.setState({ loading: false });
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
        this.setState({ loading: false });
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
        this.setState({ loading: false });
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskQuotationApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Quote Approved successfully");
            // if (this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
            //   || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") {
            // this.getApprovedQuote()
            // this.getOpenQuote()
            // this.getPendingQuote()

            // } else {

            this.callGetStatusListApi();
            // }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetTaskBlockerListApi(this.state.currentTask.id)
            toast.success("Blocker updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(this.state.currentTask.id)
            toast.success("Sub task updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getStatusByProjectIdApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //console.log(responseJson);
      if (responseJson?.statuses?.data) {
        this.setState({
          allTaskStatus: responseJson?.statuses?.data
        })
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTasksByFilterApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //console.log(responseJson)
      if (responseJson.tasks) {
        if (responseJson.tasks.data) {
          this.setState({ taskData: responseJson.tasks.data })
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectNotificationsApiCallId) {

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //console.log(responseJson);
      if (responseJson?.project_notifications?.data?.length > 0) {
        this.setState({
          projectNotificationExists: true
        }, () => {
          this.setNotificatioPreferences(responseJson?.project_notifications?.data);
        })
        //console.log(responseJson?.project_notifications?.data);
      } else {
        this.setNotificatioPreferences([]);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiSendNoticationCallId) {

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //console.log(responseJson);
      if (responseJson?.data) {
        this.setNotificatioPreferences([responseJson.data]);
      } else {
        if (responseJson?.errors?.[0]?.task_notification_email_frequency) {
          //toast.error(responseJson?.errors?.[0]?.task_notification_email_frequency);
        } else if (responseJson?.errors?.[0]?.project_notification) {
          //toast.error(responseJson?.errors?.[0]?.project_notification);
        }
        this.setNotificatioPreferences([]);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getRolesApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({
        availableRoles: responseJson
      }, () => {
        var roleArr = [];
        if (responseJson?.ab_user_roles?.length > 0) {
          for (var i = 0; i < responseJson?.ab_user_roles.length; ++i) {
            roleArr.push(responseJson?.ab_user_roles[i].id);
          }
        }
        if (responseJson?.client_roles?.length > 0) {
          for (var i = 0; i < responseJson?.client_roles.length; ++i) {
            roleArr.push(responseJson?.client_roles[i].id);
          }
        }
        this.getFilteredUserAccounts(roleArr.join());
      })
      console.log(responseJson);
      if (responseJson?.ab_user_roles?.length > 0) {
        var roleArr = [];
        for (var i = 0; i < responseJson?.ab_user_roles.length; ++i) {
          roleArr.push(responseJson?.ab_user_roles[i].id);
        }
        //this.getUserAccounts(roleArr.join());
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getUserAccountsApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson);
      if (responseJson?.accounts?.data?.length > 0) {
        this.setState({
          assigneeAccountsData: responseJson?.accounts?.data
        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getFilteredUserAccountsApiCallId) {
      // debugger;
      // responseJson?.accounts?.accounts?.data
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson);
      // if (responseJson?.accounts?.data?.length > 0) {
      //   this.setState({
      //     filteredUserAccountsData: responseJson?.accounts?.data,
      //     filteredUserAccountsDataTemp: responseJson?.accounts?.data
      //   })
      // }

      if (responseJson?.accounts?.accounts?.data?.length > 0) {
        this.setState({
          filteredUserAccountsData: responseJson?.accounts?.accounts?.data?.filter((user: any) => user.attributes?.role?.data?.attributes?.name != "superadmin"),
          filteredUserAccountsDataTemp: responseJson?.accounts?.accounts?.data?.filter((user: any) => user.attributes?.role?.data?.attributes?.name != "superadmin")
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  handleChangePage = (event: any, value: any) => {
    var el = document.getElementById("scrollUpOnPageChange");
    el?.scrollIntoView();
    this.setState({ page: value }, () => {
      if (this.state.page == 1) {
        this.setState({ fromStart: 0, fromEnd: this.state.pageSeparatorIndex[0] + 1 })
      } else if (this.state.page == 2) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[0] + 1, fromEnd: this.state.pageSeparatorIndex[1] + 1 })
      } else if (this.state.page == 3) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[1] + 1, fromEnd: this.state.pageSeparatorIndex[2] + 1 })
      } else if (this.state.page == 4) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[2] + 1, fromEnd: this.state.pageSeparatorIndex[3] + 1 })
      } else if (this.state.page == 5) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[3] + 1, fromEnd: this.state.pageSeparatorIndex[4] + 1 })
      } else if (this.state.page == 6) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[4] + 1, fromEnd: this.state.pageSeparatorIndex[5] + 1 })
      } else if (this.state.page == 7) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[5] + 1, fromEnd: this.state.pageSeparatorIndex[6] + 1 })
      } else if (this.state.page == 8) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[6] + 1, fromEnd: this.state.pageSeparatorIndex[7] + 1 })
      } else if (this.state.page == 9) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[7] + 1, fromEnd: this.state.pageSeparatorIndex[8] + 1 })
      } else if (this.state.page == 10) {
        this.setState({ fromStart: this.state.pageSeparatorIndex[8] + 1, fromEnd: this.state.pageSeparatorIndex[9] + 1 })
      }
    });
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  uploadImageTaskComment = (file: any) => {
    //console.log("File data", file)
  }

  //fie upload API api
  getSignedUrlInBriefingFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": configJSON.callToPreSignedURLContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedUrlInBriefingFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToPreSignedURLApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToPreSignedURLMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  getSignedUrlInBriefingImageFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": configJSON.callToPreSignedURLContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedUrlInBriefingImageID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToPreSignedURLApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToPreSignedURLMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  //fie upload API api
  getSignedURLForFileUploadTaskFile(fileSize: any, checkSum: any, fileName: any, contentType: any) {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  async signedIdHandler() {
    if (this.state.flagForImage == true) {
      if (this.state.signedIdAndURLs2.length > 0) {
        await Promise.all(this.state.signedIdAndURLs2.map(async (signed: any, index: any) => {
          await this.bulkUploading(signed?.data[0], this.array2[index]).catch(e => console.log(e));
          if (this.state.signedIdAndURLs2.length == this.state.countForFile) {
            console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
            this.setState({ countForFile: 0 })
          }
        })
        )
      }
    }
    else if (this.state.signedIdAndURLs.length > 0) {
      if (this.state.signedIdAndURLs.length > 0) {

        await Promise.all(this.array.map(async (file: any, index: any) => {
          await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index2: any) => {
            if (this.arratMD5[index] == signed?.data[0].headers["Content-MD5"]) {
              await this.bulkUploading(signed?.data[0], this.array[index]).catch(e => console.log(e));
              if (this.state.signedIdAndURLs.length == this.state.countForFile) {
                // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
                this.setState({ countForFile: 0 })
                this.arratMD5 = []

              }
            }
          }))
        }))




        // await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index: any) => {
        //   await this.bulkUploading(signed?.data[0], this.array[index]).catch(e => console.log(e));
        //   if (this.state.signedIdAndURLs.length == this.state.countForFile) {
        //     // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
        //     this.setState({countForFile : 0})
        //   }
        // })
        // )
      }
    }
    else if (this.state.signedIdAndURLs3.length > 0) {
      if (this.state.signedIdAndURLs3.length > 0) {

        await Promise.all(this.array3.map(async (file: any, index: any) => {
          await Promise.all(this.state.signedIdAndURLs3.map(async (signed: any, index2: any) => {
            if (this.arrat2MD5[index] == signed?.data[0].headers["Content-MD5"]) {
              await this.bulkUploading(signed?.data[0], this.array3[index]).catch(e => console.log(e));
              if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
                // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
                this.setState({ countForFile: 0, })
                this.arrat2MD5 = []
              }
            }
          }))
        }))


        // await Promise.all(this.state.signedIdAndURLs3.map(async (signed: any, index: any) => {
        //   await this.bulkUploading(signed?.data[0], this.array3[index]).catch(e => console.log(e));
        //   if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
        //     // console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
        //     this.setState({countForFile : 0})
        //   }
        // })
        // )
      }
    }

  }




  //fie upload API api
  async getSignedURLForFileUpload(file: any, fileSize: any, checkSum: any, fileName: any, contentType: any) {
    this.setState({ loading: true });
    this.closeMenu();
    let header = new Headers()
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : ""
    header.append("Content-Type", "application/json")
    header.append("token", `${token}`)
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );

    fetch(`${currentUrl}` + "/bx_block_upload_media/s3_uploads/get_presigned_url", {
      method: 'POST',
      headers: header,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      body: JSON.stringify(httpBody)
    })
      .then(response => response.json())
      .then(async (data) => {

        var blob = new Blob([file], { type: 'text/plain' });
        let headers: string[] = Object.values(data?.data[0].headers);
        const header = {
          "Content-Type": headers[0],
          "Content-MD5": headers[1],
        };
        let head = new Headers();
        head.append("Content-Type", headers[0])
        head.append("Content-MD5", headers[1])
        await fetch(data?.data[0]?.url, {
          headers: header,
          method: "PUT",
          body: blob
        })

        if (this.state.flagForImage == true && localStorage.getItem("task") == '4') {

          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs2: [...prevState.signedIdAndURLs2, data],
              fileNameImage: this.array2
            }
          }, async () => {
            if (this.state.signedIdAndURLs2.length == this.state.countForFile) {
              this.signedIdHandler()
            }
          })

          // this.setState({ signedIdAndURLs2: [...this.state.signedIdAndURLs2, responseJson], fileNameImage: this.array2 }, () => {
          //   console.log("Dtaassss", this.state.signedIdAndURLs2);
          //   if (this.state.signedIdAndURLs2.length == this.array2.length) {
          //     this.signedIdHandler()
          //   }
          // })
        }
        else if (this.state.flagForImage == false && localStorage.getItem("task") == '3') {

          // this.setState({ signedIdAndURLs: [...this.state.signedIdAndURLs, responseJson], fileName: this.array }, () => {
          //   console.log("Dtaassss", this.state.signedIdAndURLs);
          //   if (this.state.signedIdAndURLs.length == this.array.length) {
          //     this.signedIdHandler()
          //   }
          // })

          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs: [...prevState.signedIdAndURLs, data],
              fileName: this.array
            }
          }, async () => {
            if (this.state.signedIdAndURLs.length == this.state.countForFile) {
              this.signedIdHandler()
            }
          })
        }

        else if (localStorage.getItem("task") == '0') {



          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs3: [...prevState.signedIdAndURLs3, data],
              fileName: this.array3
            }
          }, async () => {
            if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
              this.taskFileUploadBulk(this.state.signedIdAndURLs3)
              // this.signedIdHandler()
            }
          })


          // this.setState({ signedIdAndURLs3: [...this.state.signedIdAndURLs3, responseJson], fileName: this.array3 }, () => {
          //   console.log("Dtaassss", this.state.signedIdAndURLs3, this.array3.length);
          //   if (this.state.signedIdAndURLs3.length == this.array3.length) {
          //     this.signedIdHandler()
          //   }
          // })
        }
        else if (localStorage.getItem("task") == 'invoice') {
          this.setState(prevState => {
            return {
              ...prevState,
              signedIdAndURLs3: [...prevState.signedIdAndURLs3, data],
              fileName: this.array3
            }
          }, async () => {
            if (this.state.signedIdAndURLs3.length == this.state.countForFile) {
              this.taskInvoiceFileUploadBulk(this.state.signedIdAndURLs3)
              // this.signedIdHandler()
            }
          })
        }
        else {
          // console.log("right");

          this.setState({ resp: data }, () => {
            this.bulkUploading(data.data[0], this.state.fileToRawBinary);
          })
        }
      }
      )
      .catch(err => console.log(err))




    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.getSignedURLForFileUploadId.push(requestMessage.messageId);
    // // this.getSignedURLForFileUploadId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.callToPreSignedURLApiEndPoint
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.callToPreSignedURLMethodType
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);


  }



  taskInvoiceFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": configJSON.taskBulkUploadContentType,
      token: localStorage.getItem("token")
    };
    var data;

    if (localStorage.getItem("task") == "invoice") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          invoices_attributes: this.array3?.length > 0 && this.array3.map((file: any, index: any) => {
            return {
              "name": file?.name,
              "file_blob_signed_id": this.state.signedIdAndURLs3 && this.state.signedIdAndURLs3[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs3[index]?.data[0]?.signed_id : ''
            }
          })

        }
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToInvoiceBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/invoices`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }




  taskFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": configJSON.taskBulkUploadContentType,
      token: localStorage.getItem("token")
    };
    var data;
    if (localStorage.getItem("task") == "2") {
      data = {
        task_id: parseInt(this.state.currentTask.id),
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              // status: this.state.currentTask.attributes.status,
              final_file: false,
              source_file: true,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "1" && this.state.uploadVersion != null) {
      data = {
        data: {
          file_blob_signed_id: responseJson.data[0]?.signed_id,
          file_type: this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("image") ? "image"
            : this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("pdf") ? "pdf"
              : this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("video") ? "video"
                : "image"
        }
      };
    }
    else if (localStorage.getItem("task") == "1" && this.state.uploadVersion == null) {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              // status: this.state.currentTask.attributes.status,
              final_file: true,
              source_file: false,
              file_blob_signed_id: responseJson.data[0]?.signed_id,
              file_type: this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("image") ? "image"
                : this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("pdf") ? "pdf"
                  : this.state.fileToRawBinary?.type && this.state.fileToRawBinary.type.includes("video") ? "video"
                    : "image"
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "0") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          // task_files_attributes: [
          //   {
          //     name: this.state.fileToRawBinary?.name,
          //     // status: this.state.currentTask.attributes.status,
          //     final_file: false,
          //     source_file: false,
          //     file_blob_signed_id: responseJson.data[0]?.signed_id
          //   }
          // ],
          task_files_attributes: this.array3?.length > 0 && this.array3.map((file: any, index: any) => {
            return {
              "name": file?.name,
              // "file_type": file?.type,
              "final_file": false,
              "source_file": false,
              "file_blob_signed_id": this.state.signedIdAndURLs3 && this.state.signedIdAndURLs3[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs3[index]?.data[0]?.signed_id : ''
            }
          })

        }
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.uploadVersion != null ?
        `bx_block_upload_media/task_files/${this.state.uploadVersion}`
        : configJSON.taskBulkUploadContentTypeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.uploadVersion != null ? "PUT"
        :
        configJSON.taskBulkUploadContentTypeMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Get Approved Quote
  getApprovedQuote = () => {
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApprovedQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteApprovedApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Open/New Quote
  getOpenQuote = () => {
    //console.log("get open draft api");
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    const header = {
      "Content-Type": configJSON.getQuoteOpenContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOpenQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteOpenApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteOpenApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Pending Quote
  getPendingQuote = () => {
    //console.log("pending quote")
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    const header = {
      "Content-Type": configJSON.getQuotePendingContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuotePendingApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuotePendingApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getListInvoiceTaskFileIndex = () => {

    const header = {
      "Content-Type": configJSON.getTaskFileGetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceTaskFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/invoices" + "?page=1&per_page=1000&task_id=" + this.state.currentTask.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getListTaskFileIndex = () => {

    const header = {
      "Content-Type": configJSON.getTaskFileGetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileGetApiEndPoint + this.state.currentTask.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  taskFileShow = (taskfileId: any) => {
    const header = {
      "Content-Type": configJSON.getTaskFileSingleContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileSingleId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileSingleApiEndPoint + taskfileId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileSingleMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTaskFileList = (taskID: any) => {
    const header = {
      "Content-Type": configJSON.getTaskFileSingleContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileSingleApiEndPoint + taskID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileSingleMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  createTaskFileAnnotation = (markDetails: any) => {
    const header = {
      "Content-Type": configJSON.taskFileAnnotationContentType,
      token: localStorage.getItem("token")
    };
    let httpBody: any;
    if (this.state.fileType == "pdf") {
      httpBody = {
        task_file_id: parseInt(this.state.fileId),
        data: [{
          data: {
            height: markDetails.height,
            width: markDetails.width,
            x_coordinate: markDetails.x,
            y_coordinate: markDetails.y,
            is_resolved: false,
            page_number: markDetails.pageNumber
          }
        }]
      };
    }
    else {
      httpBody = {
        task_file_id: parseInt(this.state.fileId),
        data: [{
          data: {
            height: markDetails.height,
            width: markDetails.width,
            x_coordinate: markDetails.x,
            y_coordinate: markDetails.y,
            is_resolved: false,
          }
        }]
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTaskFileAnnotationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createAnnotationComments = (annotationId: any) => {
    const header = {
      "Content-Type": configJSON.createAnnotationCommentContentType,
      token: localStorage.getItem("token")
    };
    let httpBody: any;
    var comment = draftToHtml(convertToRaw(this.state.annotationCompleteDataPdf.getCurrentContent()))
    let ArrayObjects: any = [];
    Object.values(this.state.convertedReplyData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    httpBody = {
      data: {
        task_file_annotation_id: parseInt(annotationId),
        comment: comment,
        references: referencesData,
        // description: comment,
        // file_blob_signed_id: this.state.presignedData?.signed_id,
        textual_description: this.state.editorState.getCurrentContent().getPlainText(),
        version_number: this.state.fileVersionNumber2
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAnnotationCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAnnotationCommentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAnnotationCommentMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getAllAnnotationTaskFile = () => {
    const header = {
      "Content-Type": configJSON.getTaskFileAnnotationContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAnnotationTaskFileID = requestMessage.messageId;
    if (parseInt(this.state.fileId) !== parseInt(this.state.latestVesrionDetails?.id)) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskVersionFileAnnotationApiEndPoint + parseInt(this.state.fileId)
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTaskFileAnnotationApiEndPoint + parseInt(this.state.fileId)
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileAnnotationMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Download task file
  taskFileDownload = () => {
    this.setState({
      loading: true
    });
    console.log("filedownload", this.state.fileId);
    var url = this.state.latestVersionFlag ?
      `${currentUrl
      }/bx_block_download/task_file_downloads?task_file_id=${this.state.fileId
      }&token=${localStorage.getItem("token")}`
      : `${currentUrl
      }/bx_block_download/task_file_downloads?task_file_version_id=${this.state.fileId
      }&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.click();
    this.setState({
      loading: false
    });
  }

  taskFileDownloadSourceFile = (id: any) => {
    this.setState({
      loading: true
    })
    var url = `${currentUrl
      }/bx_block_download/task_file_downloads?task_file_id=${id
      }&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    //console.log("Url", url)
    anchor.setAttribute("href", url);
    // anchor.setAttribute("download", "file.csv");
    anchor.click();
    this.setState({ setSourceFileAnchorEl: null, loading: false })
  }

  getAllCommentsAnnotationTaskFile = () => {
    const header = {
      "Content-Type": configJSON.getAllCommentsContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCommentsAnnotationTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCommentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllCommentMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteTaskFileFromTheTaskView = (deleteID: any) => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.taskFileDeleteApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskFileFromTheTaskViewID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileDeleteAPiEndPoint + deleteID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileDeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTaskInvoiceFromTheTaskView = (deleteID: any) => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.taskFileDeleteApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskInvoiceFromTheTaskViewID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/invoices/" + deleteID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileDeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSpecificAnnotationComment = () => {
    // console.clear();
    //console.log("Comment ID", this.state.commentId);
    const header = {
      "Content-Type": configJSON.getSpecificAnnotationCommentContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpecificCommentsAnnotationTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificAnnotationCommentApiEndPoint + this.state.commentId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSpecificAnnotationCommentMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteSpecificAnnotationComment = () => {
    const header = {
      "Content-Type": configJSON.deleteAnnotationCommentContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSpecificCommentsAnnotationTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAnnotationCommentApiEndPoint + parseInt(this.state.commentId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAnnotationCommentMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteSpecificAnnotationCoordinates = () => {
    const header = {
      "Content-Type": configJSON.deleteAnnotationContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSpecificAnnotationTaskFileID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAnnotationApiEndPoint + parseInt(this.state.annotationSpecificId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAnnotationMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  //use the code below project index api
  callGetProjectsListApiType1() {
    this.setState({ loadingIndicator: true });
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPage}&per_page=${100}&brand_id=${brand_id}&sort_by=name&sort_order=asc`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  callGetProjectsListApiTypeForAB() {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdTypeForAB = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?brand_id=${brand_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  callGetProjectsListApiTypeMounting() {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdTypeMount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?status_type=draft&brand_id=${brand_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  handleChangeDraftProjectList = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPage: value }, () => { this.callGetProjectsListApiType1() });
  };
  handleChangeWaitingForQuoteProjectList = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPageType2: value }, () => { this.callGetProjectsListApiType2() });
  };
  handleChangeOngoingProjectList = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPageType3: value }, () => { this.callGetProjectsListApiType4() });
  };

  callGetProjectsListApiType2() {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPageType2}&per_page=${100}&brand_id=${brand_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  callGetProjectsListApiType3() {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType3 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPageType3}&per_page=${100}&brand_id=${brand_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  async getfilesAndFolder(id: any) {
    this.setState({ loading: true })
    await this.getMediaAsset(id)
    await this.getMediaAssetFiles(id)
    // this.setState({loading : false})
  }

  callGetProjectsListApiType4() {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType4 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPageType3}&per_page=${100}&brand_id=${brand_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  deleteStatus = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customised_order_status/task_statuses/${this.state.selectedStatus?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //project create api
  callCreateProjectApi = (values: any, users: any, e?: any) => {
    this.setState({
      setOpen: false
    })
    if (users) {
      const selectedAccount = [];
      const selectedTeam = []

      for (let i = 0; i < users.length; i++) {
        if (users[i].entity == "Account") {
          selectedAccount.push(users[i].id)
        }
        else if (users[i].entity == "Team") {
          selectedTeam.push(users[i].id)
        }

      }
      var selectedUsers = [];
      selectedUsers = selectedAccount.map((i: any) => Number(i));
      var teams = [];
      teams = selectedTeam.map((i: any) => Number(i));
    }
    const userDetails = localStorage.getItem("userInfo")
    // if (userDetails) {
    //   var brand_id = JSON.parse(userDetails).brand_id

    // }
    var brand_id = localStorage.getItem("selectedBrand")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const data = {
      name: values.title,
      description: values.projectDescription,
      account_ids: selectedUsers,
      team_ids: teams,
      brand_id: brand_id,
      project_template_id: this.state.tempeleteChoosen
    };



    const httpBody = {
      data: data
    };


    const requestCreateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectCreateApiCallId = requestCreateProjectMessage.messageId;
    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectCreateAPiEndPoint
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateProjectMessage.id, requestCreateProjectMessage);



  }


  //project show api
  callShowProjectsApi(projectID: any) {

    // debugger;

    const userDetails = localStorage.getItem("userInfo")

    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectShowApiCallId = requestShowProjectMessage.messageId;
    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectShowAPiEndPoint + `${projectID}`
    );

    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowProjectMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectShowAPiMethod
    );

    runEngine.sendMessage(requestShowProjectMessage.id, requestShowProjectMessage);



  }

  //project update api
  callUpdateProjectsApi(users?: any) {
    const selectedProjectData: any = this.state.selectedProjectData
    const userDetails = localStorage.getItem("userInfo")
    // if (value) {
    //   const selected = [];
    //   for (let i = 0; i < value.length; i++) {
    //     selected.push(value[i].id)
    //   }
    //   var selectedUsers = [];
    //   selectedUsers = selected.map((i: any) => Number(i));
    // }

    // if (userDetails) {
    //   var brand_id = JSON.parse(userDetails).brand_id

    // }


    if (users) {
      const selectedAccount = [];
      const selectedTeam = []

      for (let i = 0; i < users.length; i++) {
        if (users[i].entity == "Account") {
          selectedAccount.push(users[i].id)
        }
        else if (users[i].entity == "Team") {
          selectedTeam.push(users[i].id)
        }

      }
      var selectedUsers = [];
      selectedUsers = selectedAccount.map((i: any) => Number(i));
      var teams = [];
      teams = selectedTeam.map((i: any) => Number(i));
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectId:this.state.projectId
      // email: this.state.email,
      // password: this.state.password
      "account_ids": selectedUsers,
      "project_id": selectedProjectData.id,
      "team_ids": teams,
      "message": "You have been invited to view project"
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTeamUpdateCallId = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //configJSON.projectUpdateAPiEndPoint + `/${selectedProjectData.id}`
      `bx_block_project_portfolio/account_projects`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      //configJSON.projectUpdateAPiMethod
      "POST"
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);



  }
  //project update api
  callUpdateProjectPropertiesApi(values?: any, users?: any) {
    console.log("project overview", values, users);
    if (users?.length > 0) {
      this.callUpdateProjectsApi(users)
    }

    // this.setState({
    //   openProjectProperties: false,
    // });


    const selectedProjectData: any = this.state.selectedProjectData
    const userDetails = localStorage.getItem("userInfo")
    // if (users) {
    //   const selectedAccount = [];
    //   const selectedTeam = []

    //   for (let i = 0; i < users.length; i++) {
    //     if (users[i].entity == "Account") {
    //       selectedAccount.push(users[i].id)
    //     }
    //     else if (users[i].entity == "Team") {
    //       selectedTeam.push(users[i].id)
    //     }

    //   }
    //   var selectedUsers = [];
    //   selectedUsers = selectedAccount.map((i: any) => Number(i));
    //   var teams = [];
    //   teams = selectedTeam.map((i: any) => Number(i));
    // }
    //console.log("selectedUsers", selectedUsers);
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectId:this.state.projectId,
      // email: this.state.email,
      // password: this.state.password
      // account_ids:projectTitle selectedUsers,
      // team_ids: teams,
      "name": values?.projectTitle,
      "description": values?.projectDescription,

    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateProjectPropertiesApiId = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateAPiEndPoint + `/${selectedProjectData.id}`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);



  }
  //project update api
  handleProjectArchiveApi(projectId?: any) {
    this.setState({ anchorEl: false })
    const selectedProjectData: any = this.state.selectedProjectData

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectId:this.state.projectId
      // email: this.state.email,
      // password: this.state.password
      "is_archived": true
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectArchiveApi = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateAPiEndPoint + `/${selectedProjectData.id}`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);



  }

  //project delete api
  callDeleteProjectsApi(projectId?: any) {
    this.setState({ anchorEl: false })
    const selectedProjectData: any = this.state.selectedProjectData

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).role
      var permission = JSON.parse(userDetails).role.data.attributes.permission;

    }
    if (permission === "viewer") {

      toast.error("You have no rights")
    }
    else {

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")

      };


      const requestDeleteProjectMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.projectDeleteApi = requestDeleteProjectMessage.messageId;
      requestDeleteProjectMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.projectDeleteAPiEndPoint + `/${selectedProjectData.id}`
      );

      requestDeleteProjectMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      // requestDeleteProjectMessage.addData(
      //   getName(MessageEnum.RestAPIRequestBodyMessage),
      //   JSON.stringify(httpBody)
      // );

      requestDeleteProjectMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.projectDeleteAPiMethod
      );

      runEngine.sendMessage(requestDeleteProjectMessage.id, requestDeleteProjectMessage);


    }
  }
  //account project index api
  callGetAccountProjectsListApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountProjectAPiEndPoint + `?project_id=${1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountProjectAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //account project create api
  callCreateAccountProjectsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateAccountProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateAccountProjectMessage.messageId;
    requestCreateAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountProjectCreateAPiEndPoint
    );

    requestCreateAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountProjectCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateAccountProjectMessage.id, requestCreateAccountProjectMessage);



  }

  //account project delete api
  callDeleteAccountProjectsApi(accountID?: any, selectedProjectIdForDelete?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    // const attrs = {
    //   // email: this.state.email,
    //   // password: this.state.password
    // };

    const data = {
      "project_id": selectedProjectIdForDelete,
      "account_ids": [accountID]
    };

    const httpBody = {
      data: data
    };

    const requestDeleteAccountProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callDeleteAccountProjectsApiId = requestDeleteAccountProjectMessage.messageId;
    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountProjectDeleteAPiEndPoint
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountProjectDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteAccountProjectMessage.id, requestDeleteAccountProjectMessage);



  }
  //task index api

  callGetUnstartedTaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    //console.log("brandhID", userDetails)

    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      //console.log("brandhID", userDetails)

      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id
        //console.log("brandhID", userDetails)

      }
    } else {
      var brand_id: any = localStorage.getItem("selectedBrandID");
      //console.log("brandhID", userDetails)

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unstartedTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=unstarted&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  callGetOpenTaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id

      }
    } else {
      var brand_id: any = localStorage.getItem("selectedBrandID");

    }


    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=Open&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  callGetOngoingTaskListApi(projectID?: any, status?: any) {
    const userDetails = localStorage.getItem("userInfo")
    let dueDate = '';
    if (this.state.filterDueDate !== '') {
      dueDate = this.state.filterDueDate.split("-").reverse().join("/")
    }
    console.log("dueDatedueDate", dueDate)
    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id

      }
    } else {
      //console.log("localStorage.getItem", localStorage.getItem("selectedBrandID"))
      var brand_id: any = localStorage.getItem("selectedBrandID");

    }
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    status.messageId = requestMessage.messageId
    var dynamicMessages = this.state.dynamicMessages;
    dynamicMessages[status.id] = status
    this.setState({
      dynamicMessages: dynamicMessages

    })
    if (status.id == "Draft" && dueDate !== "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=draft&brand_id=${brand_id}&project_id=${projectID}&estimated_end_date=${dueDate}`
      );
    } else if (status.id == "Draft") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=draft&brand_id=${brand_id}&project_id=${projectID}`
      );
    } else if (status.id == "Quote Pending" && dueDate !== "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=quote_requested_or_pending&brand_id=${brand_id}&project_id=${projectID}&estimated_end_date=${dueDate}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`

      );
    } else if (status.id == "Quote Pending") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=quote_requested_or_pending&brand_id=${brand_id}&project_id=${projectID}`

        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`

      );
    } else if (dueDate !== "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}&estimated_end_date=${dueDate}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`

      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  callGetQATaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.qaTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=on_going&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  callGetDoneTaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doneTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=approved&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task create api
  callCreateTaskApi(values: any, catID: any, assetTypeID: any) {
    const userDetails = localStorage.getItem("userInfo")
    const selectedProjectId = localStorage.getItem("selectedProjectId")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "project_id": selectedProjectId,
      "title": values.title,
      "description": values.title,
      "asset_type_id": Number(assetTypeID),
      "asset_category_id": Number(catID)
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };

    const requestCreateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTaskCreateAPICallId = requestCreateTaskMessage.messageId;
    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateAPiEndPoint
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskMessage.id, requestCreateTaskMessage);


  }
  //templatye form fields show api
  callTemplateFormFieldsApi(templateID: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTemplateFormFieldsContentType,
      token: localStorage.getItem("token")
    };

    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTemplateFormFieldsAPIId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTemplateFormFieldsEndPoint + `${templateID}&per_page=100`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);

  }

  //templatye form Condition show api
  callTemplateFormConditionsApi(templateID: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTemplateFormConditionsContentType,
      token: localStorage.getItem("token")
    };

    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTemplateFormConditionsAPIId = requestShowTaskMessage.messageId;

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTemplateFormConditionsEndPoint + `${templateID}`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTemplateFormConditionsMethodType
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);

  }

  //task show api
  callShowTaskApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskApiId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskShowAPiEndPoint + `${taskId}`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);



  }

  //task update api
  callUpdateTaskApi(currenttask: any) {
    this.setState({ loading: true })
    //console.log("currenttask", currenttask, this.state.currentTask)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTemplateFormConditionsContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: currenttask.attributes,
    };
    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskAPIId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskUpdateAPiEndPoint + `${currenttask.id}`
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);



  }

  //task delete api
  callDeleteTaskApi() {
    debugger;
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };;

    const attrs = {
      //taskId:this.state.taskId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteTaskMessage.messageId;
    requestDeleteTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskDeleteAPiEndPoint + '/1'
    );

    requestDeleteTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteTaskMessage.id, requestDeleteTaskMessage);



  }

  //task template index api
  callGetTaskTemplateListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.taskTemplateAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskTemplateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskTemplateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task template create api
  callCreateTaskTemplateApi(taskId?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      brand_template_id: this.state.selectedTaskTemplate?.id,
      task_id: taskId
    };



    const httpBody = {
      data: attrs
    };

    const requestCreateTaskTemplateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreatetaskTemplateApiId = requestCreateTaskTemplateMessage.messageId;
    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskTemplateCreateAPiEndPoint
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskTemplateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskTemplateCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskTemplateMessage.id, requestCreateTaskTemplateMessage);
  }

  //briefing create api
  callCreateBriefingApi(values: any) {
    this.setState({ loading: true })
    //console.log("From Create Brieifing", values)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const data = values;

    const httpBody = {
      data: data
    };

    const requestCreateBriefingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateBriefingApiId = requestCreateBriefingMessage.messageId;
    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingCreateAPiEndPoint
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefingCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateBriefingMessage.id, requestCreateBriefingMessage);



  }

  //briefing create api
  callUpdateBriefingApi(values: any) {
    this.setState({ loading: true })
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.briefingUpdateApiContentType,
      token: localStorage.getItem("token")
    };
    let data = values;
    data.task_id = this.state.currentTask?.id

    const httpBody = {
      data: data
    };

    const requestCreateBriefingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateBriefingApiId = requestCreateBriefingMessage.messageId;
    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingUpdateAPiEndPoint
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefingCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateBriefingMessage.id, requestCreateBriefingMessage);



  }

  //briefing show api
  callShowBriefingApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //briefingId:this.state.briefingId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowBriefingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowBriefingMessage.messageId;
    requestShowBriefingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingShowAPiEndPoint + `/2`
    );

    requestShowBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowBriefingMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefingShowAPiMethod
    );

    runEngine.sendMessage(requestShowBriefingMessage.id, requestShowBriefingMessage);



  }

  //briefing delete api
  callDeleteBriefingApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //briefingId:this.state.briefingId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteBriefingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteBriefingMessage.messageId;
    requestDeleteBriefingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingDeleteAPiEndPoint + `/1`
    );

    requestDeleteBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteBriefingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefedValuesUpdateAPiMethod
    );

    runEngine.sendMessage(requestDeleteBriefingMessage.id, requestDeleteBriefingMessage);



  }

  //Briefed Values update api
  callUpdateBriefedValuesApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //briefedValueId:this.state.briefedValueId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestUpdateBriefedValuesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateBriefedValuesMessage.messageId;
    requestUpdateBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefedValuesUpdateAPiEndPoint + `/3`
    );

    requestUpdateBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestUpdateBriefedValuesMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestUpdateBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefedValuesUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateBriefedValuesMessage.id, requestUpdateBriefedValuesMessage);



  }

  //Briefed Values show api
  callShowBriefedValuesApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //briefedValueId:this.state.briefedValueId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowBriefedValuesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowBriefedValuesMessage.messageId;
    requestShowBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefedValuesShowAPiEndPoint + `/4`
    );

    requestShowBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowBriefedValuesMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowBriefedValuesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefedValuesShowAPiMethod
    );

    runEngine.sendMessage(requestShowBriefedValuesMessage.id, requestShowBriefedValuesMessage);



  }

  //task  quotation update api
  callUpdateTaskQuotationApi(taskQuotationId?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      action: "approve_quote"
    };

    const httpBody = {
      data: data
    };

    const requestUpdateTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskQuotationApiID = requestUpdateTaskQuotationMessage.messageId;
    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskQuotationUpdateAPiEndPoint + `/${taskQuotationId}`
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskQuotationUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskQuotationMessage.id, requestUpdateTaskQuotationMessage);



  }

  //task  quotation create api
  callCreateTaskQuotationApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateTaskQuotationMessage.messageId;
    requestCreateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskQuotationCreateAPiEndPoint
    );

    requestCreateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskQuotationCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskQuotationMessage.id, requestCreateTaskQuotationMessage);



  }

  //task  quotation show api
  callShowTaskQuotationApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskQuotationId:this.state.taskQuotationId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowTaskQuotationMessage.messageId;
    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskQuotationShowAPiEndPoint + `/4`
    );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowTaskQuotationMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskQuotationShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskQuotationMessage.id, requestShowTaskQuotationMessage);



  }

  //status index api
  async callGetStatusListApi() {

    const userDetails = localStorage.getItem("userInfo")
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")
    const brandId: any = localStorage.getItem("selectedBrand")
    await this.getBrandDetail(brandId)
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetStatusListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusAPiEndPoint + `?project_id=${selectedProjectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //status edit api
  callEditStatusApi(values?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
      // "project_id": Number(selectedProjectId),
      "status": values.status,
      "priority": this.state.selectedStatus?.attributes?.priority,

    };

    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callEditStatusApiId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customised_order_status/task_statuses/${this.state.selectedStatus?.id}`
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  //create project templete
  createProjectTemplete(values: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "name": values.name,
      "project_id": selectedProjectId,

    };

    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateTempleteApiId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_templates`
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);

  }

  //status create api
  callCreateStatusApi(values?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
      "project_id": Number(selectedProjectId),
      "status": values.status,
      "priority": Number(this.state.columnOrderKanban.length),

    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateStatusApiId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }
  //status show api
  callShowStatusApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //statusId:this.state.statusId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowStatusMessage.messageId;
    requestShowStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusShowAPiEndPoint + `/1`
    );

    requestShowStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowStatusMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusShowAPiMethod
    );

    runEngine.sendMessage(requestShowStatusMessage.id, requestShowStatusMessage);



  }

  //status update api
  callUpdateStatusApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //statusId:this.state.statusId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestUpdateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateStatusMessage.messageId;
    requestUpdateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusUpdateAPiEndPoint + `/1`
    );

    requestUpdateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestUpdateStatusMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestUpdateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateStatusMessage.id, requestUpdateStatusMessage);



  }

  //status delete api
  callDeleteStatusApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //statusId:this.state.statusId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteStatusMessage.messageId;
    requestDeleteStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statusDeleteAPiEndPoint + `/1`
    );

    requestDeleteStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.statusDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteStatusMessage.id, requestDeleteStatusMessage);



  }


  //task comment index api
  callGetTaskCommentListApi(taskId?: any) {
    //console.log(taskId);

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    // const attrs = {
    //   //taskCommentId:this.state.taskCommentId
    //   // email: this.state.email,
    //   // password: this.state.password
    // };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetTaskCommentListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentAPiEndPoint + `/${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task comment create api
  callCreateTaskCommentListApi(convertedCommentData?: any) {
    console.log("convertedCommentData", convertedCommentData);


    console.log(this.state.editorState.getCurrentContent().getPlainText())
    if (this.state.editorState.getCurrentContent().getPlainText() == "" || this.state.editorState.getCurrentContent().getPlainText()?.trim() == "") {
      toast.error("Comment is required");
      return;
    }

    this.setState({
      loading: true
    });
    //console.log(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log(this.state.editorState.getCurrentContent().getPlainText());
    var comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

    let ArrayObjects: any = [];
    const keys = Object.keys(convertedCommentData.entityMap);


    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      if (val?.type == 'LINK') {

      }
      else {
        let data: any = {
          [val.data.text]: parseInt(val.data.url)
        }
        ArrayObjects.push(data);
      }
    });


    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,
      // file_blob_signed_id: this.state.presignedData?.signed_id,
      textual_description: this.state.editorState.getCurrentContent().getPlainText()
    }



    const httpBody = {
      data: data
    };

    const requestCreateTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateTaskCommentListApiID = requestCreateTaskCommentMessage.messageId;
    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentCreateAPiEndPoint
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskCommentMessage.id, requestCreateTaskCommentMessage);

  }

  //sub task  show api
  callShowTaskCommentApi(taskId: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskCommentApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentShowAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentShowAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  showTaskCommentSpecific(commentID: any) {
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTaskCommentSpecificId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentSpecificCommentAPiEndPoint + commentID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentSpecificCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task comment update api
  callUpdateTaskCommentApi(convertedCommentData: any) {

    var comment = draftToHtml(convertToRaw(this.state.editComment.getCurrentContent()))
    let ArrayObjects: any = [];
    // Object.values(convertedCommentData.entityMap).forEach((val: any) => {
    //   let data: any = {
    //     [val.data.text]: parseInt(val.data.url)
    //   }
    //   ArrayObjects.push(data);
    // });
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      if (val?.type == 'LINK') {

      }
      else {
        let data: any = {
          [val.data.text]: parseInt(val.data.url)
        }
        ArrayObjects.push(data);
      }
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.taskCommentUpdateApiContentType,
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,

    }
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callUpdateTaskCommentID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentUpdateAPiEndPoint + `${this.state.commentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  //task comment delete api
  callDeleteTaskCommentApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.taskCommentID
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteTaskCommentMessage.messageId;
    requestDeleteTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentDeleteAPiEndPoint + `/1?token={{ab_order_system_token}}`
    );

    requestDeleteTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteTaskCommentMessage.id, requestDeleteTaskCommentMessage);



  }

  //task file upload-create api
  callCreateTaskFileUploadApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateTaskFileUploadMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateTaskFileUploadMessage.messageId;
    requestCreateTaskFileUploadMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileUploadCreateAPiEndPoint
    );

    requestCreateTaskFileUploadMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskFileUploadMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskFileUploadMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileUploadCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskFileUploadMessage.id, requestCreateTaskFileUploadMessage);



  }

  //task file list api
  callListTaskFilesApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //taskFileId:this.state.taskFileId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestListTaskFileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestListTaskFileMessage.messageId;
    requestListTaskFileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileListAPiEndPoint + `?page=${1}&per_page=${3}&task_id=${25}&final_file=${true}&source_file=${false}`
    );

    requestListTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestListTaskFileMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestListTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileListAPiMethod
    );

    runEngine.sendMessage(requestListTaskFileMessage.id, requestListTaskFileMessage);



  }
  submitProductionReady = () => {

    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json",

    };
    var httpBody = {
      "data": {
        "task_id": this.state.currentTask.id
      }
    }


    const requestShowTaskFileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitProductionApiCallId = requestShowTaskFileMessage.messageId;
    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks/move_to_prod_ready_status"
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestShowTaskFileMessage.id, requestShowTaskFileMessage);



  }

  //task file show api
  callShowTaskFileApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //taskFileId:this.state.taskFileId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowTaskFileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowTaskFileMessage.messageId;
    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileShowAPiEndPoint + '/1'
    );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowTaskFileMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowTaskFileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskFileMessage.id, requestShowTaskFileMessage);



  }

  //task file bulk uploads-create api
  callCreateTaskFileBulkUploadsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateTaskFileBulkUploadsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateTaskFileBulkUploadsMessage.messageId;
    requestCreateTaskFileBulkUploadsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileBulkUploadsCreateAPiEndPoint
    );

    requestCreateTaskFileBulkUploadsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskFileBulkUploadsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskFileBulkUploadsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileBulkUploadsCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskFileBulkUploadsMessage.id, requestCreateTaskFileBulkUploadsMessage);



  }

  //task file annotation index api
  callGetTaskFileAnnotationListApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //taskFileAnnotationId:this.state.taskFileAnnotationId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationAPiEndPoint + `?task_file_id=${57}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task file annotation create api
  callCreateTaskFileAnnotationListApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateTaskFileAnnotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateTaskFileAnnotationMessage.messageId;
    requestCreateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCreateAPiEndPoint
    );

    requestCreateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskFileAnnotationMessage.id, requestCreateTaskFileAnnotationMessage);



  }

  //task file annotation show api
  callShowTaskFileAnnotationApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskFileAnnotationId:this.state.taskFileAnnotationId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowTaskFileAnnotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowTaskFileAnnotationMessage.messageId;
    requestShowTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationShowAPiEndPoint + `/1`
    );

    requestShowTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowTaskFileAnnotationMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskFileAnnotationMessage.id, requestShowTaskFileAnnotationMessage);



  }

  //task file annotation update api
  callUpdateTaskFileAnnotationApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskFileAnnotationId:this.state.taskFileAnnotationId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestUpdateTaskFileAnnotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateTaskFileAnnotationMessage.messageId;
    requestUpdateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationUpdateAPiEndPoint + `/1`
    );

    requestUpdateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestUpdateTaskFileAnnotationMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestUpdateTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskFileAnnotationMessage.id, requestUpdateTaskFileAnnotationMessage);



  }

  //task file annotation delete api
  callDeleteTaskFileAnnotationApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskFileAnnotationId:this.state.taskFileAnnotationId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteTaskFileAnnotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteTaskFileAnnotationMessage.messageId;
    requestDeleteTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationDeleteAPiEndPoint + `/1`
    );

    requestDeleteTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteTaskFileAnnotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteTaskFileAnnotationMessage.id, requestDeleteTaskFileAnnotationMessage);



  }

  //task file annotation comments index api
  callGetTaskFileAnnotationCommentsListApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCommentsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCommentsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2.push(requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  bulkTaskCommentUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bulkTaskCommentUploadingID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //task file annotation comments create api
  callCreateTaskFileAnnotationCommentsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateTaskFileAnnotationCommentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateTaskFileAnnotationCommentsMessage.messageId;
    requestCreateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCommentsCreateAPiEndPoint
    );

    requestCreateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCommentsCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskFileAnnotationCommentsMessage.id, requestCreateTaskFileAnnotationCommentsMessage);



  }

  //task file annotation comments show api
  callShowTaskFileAnnotationCommentsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //taskFileAnnotationCommentId:this.state.taskFileAnnotationCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowTaskFileAnnotationCommentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowTaskFileAnnotationCommentsMessage.messageId;
    requestShowTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCommentsShowAPiEndPoint + `/1`
    );

    requestShowTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowTaskFileAnnotationCommentsMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCommentsShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskFileAnnotationCommentsMessage.id, requestShowTaskFileAnnotationCommentsMessage);



  }

  //task file annotation comments update api
  callUpdateTaskFileAnnotationCommentsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //taskFileAnnotationCommentId:this.state.taskFileAnnotationCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestUpdateTaskFileAnnotationCommentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateTaskFileAnnotationCommentsMessage.messageId;
    requestUpdateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCommentsUpdateAPiEndPoint + `/1`
    );

    requestUpdateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestUpdateTaskFileAnnotationCommentsMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestUpdateTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCommentsUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskFileAnnotationCommentsMessage.id, requestUpdateTaskFileAnnotationCommentsMessage);



  }

  //task file annotation comments delete api
  callDeleteTaskFileAnnotationCommentsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskFileAnnotationCommentId:this.state.taskFileAnnotationCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestDeleteTaskFileAnnotationCommentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteTaskFileAnnotationCommentsMessage.messageId;
    requestDeleteTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileAnnotationCommentsDeleteAPiEndPoint + `/1`
    );

    requestDeleteTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteTaskFileAnnotationCommentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileAnnotationCommentsDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteTaskFileAnnotationCommentsMessage.id, requestDeleteTaskFileAnnotationCommentsMessage);



  }

  //project duplication create api
  callCreateProjectDuplicationApi(projectId: any) {
    this.setState({ setShowDuplicateDialog: false, anchorEl: false });
    const selectedProjectData: any = this.state.selectedProjectData

    //console.log("callCreateProjectDuplicationApi")
    const header = {
      "Content-Type": configJSON.validationApiContentType,

      token: localStorage.getItem("token")

    };
    const attrs = {
      // email: this.state.email,
      // password: this.state.password
      project_id: selectedProjectData.id
    };



    const httpBody = {
      data: attrs
    };

    const requestCreateProjectDuplicationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiDuplicateCallId = requestCreateProjectDuplicationMessage.messageId;
    requestCreateProjectDuplicationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDuplicationCreateAPiEndPoint
    );

    requestCreateProjectDuplicationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectDuplicationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectDuplicationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectDuplicationCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateProjectDuplicationMessage.id, requestCreateProjectDuplicationMessage);



  }

  //project exports index api
  callGetProjectExportListApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //projectExportsId:this.state.projectExportsId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectExportsAPiEndPoint + `?brand_id=${brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectExportsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //project notification settings index api
  callGetProjectNotificationSettingsListApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectNotificationSettingsAPiEndPoint + `?account_id=${93}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectNotificationSettingsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }



  getMediaFolderAssetFiles(assetId: any) {
    this.setState({ loading: true })
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaFolderAssetFilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1000&media_asset_folder_id=${assetId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  async getMediaAssetFiles(assetId: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      // `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=3&media_asset_id=${assetId}`

      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1000&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  async getMediaAsset(assetId: any) {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // `bx_block_categories_sub_categories/media_assets?page=1&brand_id=${assetId}&per_page=50`

      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=1000&media_asset_id=${assetId}`

      // `bx_block_categories_sub_categories/media_asset_folders?page=${1}&per_page=${10}&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  loadBrandMediaFolderandFiles = (brandId: any) => {
    this.setState({ loading: true })

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaAssetsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/media_assets?page=1&per_page=1000&brand_id=${brandId}`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //project notification settings create api
  callCreateProjectNotificationSettingsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestCreateProjectNotificationSettingsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestCreateProjectNotificationSettingsMessage.messageId;
    requestCreateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectNotificationSettingsCreateAPiEndPoint
    );

    requestCreateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectNotificationSetttingsCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateProjectNotificationSettingsMessage.id, requestCreateProjectNotificationSettingsMessage);



  }

  //project notification settings update api
  callUpdateProjectNotificationSettingsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectNotificationSettingsId:this.state.projectNotificationSettingsId
      // email: this.state.email,
      // password: this.state.password
    };

    const data = {
      // type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestUpdateProjectNotificationSettingsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateProjectNotificationSettingsMessage.messageId;
    requestUpdateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectNotificationSettingsUpdateAPiEndPoint + `/1`
    );

    requestUpdateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectNotificationSetttingsUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateProjectNotificationSettingsMessage.id, requestUpdateProjectNotificationSettingsMessage);



  }

  //project notification settings show api
  callShowProjectNotificationSettingsApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //projectNotificationSettingsId:this.state.projectNotificationSettingsId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestShowProjectNotificationSettingsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestShowProjectNotificationSettingsMessage.messageId;
    requestShowProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectNotificationSettingsShowAPiEndPoint + `/1`
    );

    requestShowProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowProjectNotificationSettingsMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowProjectNotificationSettingsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectNotificationSetttingsShowAPiMethod
    );

    runEngine.sendMessage(requestShowProjectNotificationSettingsMessage.id, requestShowProjectNotificationSettingsMessage);



  }

  //account  index api
  callGetAccountListApi(nameofUser: any) {
    const brand_id: any = localStorage.getItem("selectedBrandID");
    // if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer")) {

    //   if (userDetails) {
    //     var brand_id: any = JSON.parse(userDetails).brand_id
    //     console.log("brandhID",userDetails)

    //   }
    // } 
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
    //   //first_name=&last_name&email&username&brand_id`
    // );
    if (nameofUser === '') {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountAPiEndPoint + `?page=${1}&per_page=${5000}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountAPiEndPoint + `?page=${1}&per_page=${5000}`
          //first_name=&last_name&email&username&brand_id`
        );
      }

    } else {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${22}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${23}`
          //first_name=&last_name&email&username&brand_id`
        );
      }

    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //account  index api
  callGetProjectPropertiesAccountListApi(nameofUser: any) {
    //console.log("nameofUser");

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetProjectPropertiesAccountListApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //account  index api
  callShowAccountforProjectMentionsApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowAccountforProjectMentionsApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        // configJSON.accountAPiEndPoint + `?per_page=${100}&brand_id=${localStorage.getItem("selectedBrandID")}&project_id=${localStorage.getItem("selectedProjectId")}`
        `/bx_block_project_portfolio/account_projects/users_with_access?project_id=${localStorage.getItem("selectedProjectId")}&per_page=${1000}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&user=${nameofUser}&per_page=${1000}&brand_id=${localStorage.getItem("selectedBrandID")}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //account show api
  callShowAccountforProjectApi(projectId: any) {

    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowAccountforProjectApiId = requestShowAccountMessage.messageId;
    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountShowAPiEndPoint + `=${projectId}&per_page=28`
    );

    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountShowAPiMethod
    );

    runEngine.sendMessage(requestShowAccountMessage.id, requestShowAccountMessage);



  }



  //account update api
  callUpdateAccountApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskId:this.state.taskId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestUpdateAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateAccountMessage.messageId;
    requestUpdateAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountUpdateAPiEndPoint + '/2'
    );

    requestUpdateAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestUpdateAccountMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestUpdateAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateAccountMessage.id, requestUpdateAccountMessage);



  }

  //account delete api
  callDeleteAccountApi() {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };;

    const attrs = {
      //taskId:this.state.taskId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestDeleteAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestDeleteAccountMessage.messageId;
    requestDeleteAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDeleteAPiEndPoint + '/30'
    );

    requestDeleteAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestDeleteAccountMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestDeleteAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteAccountMessage.id, requestDeleteAccountMessage);



  }

  //account show api
  callShowMediaforProjectApi(projectId: any) {

    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowMediaforProjectApiId = requestShowAccountMessage.messageId;
    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "bx_block_categories_sub_categories/media_asset_folders/" + `${projectId}`
      "bx_block_categories_sub_categories/media_assets?page=1&per_page=50&media_type=BxBlockProjectPortfolio::Project&media_id=" + `${projectId}`
    );

    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountShowAPiMethod
    );

    runEngine.sendMessage(requestShowAccountMessage.id, requestShowAccountMessage);



  }

  //asset category index api
  callGetAssetCategoryListApi() {

    const userDetails = localStorage.getItem("userInfo")

    // if (userDetails) {
    //   var brand_id = JSON.parse(userDetails).brand_id


    // }
    var brandId = localStorage.getItem("selectedBrandID")
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assetCategoryAPiEndPoint + `?page=1&per_page=2000&without_asset_type=true&brand_id=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assetCategoryAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //asset type index api
  callGetAssetTypeListApi(asset_category_id?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assetTypeAPiEndPoint + `?per_page=${5000}&asset_category_id=${asset_category_id}&brand_id=${localStorage.getItem("selectedBrandID")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assetTypeAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //template index api
  callGetTemplateListApi(asset_type_id?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {

      token: localStorage.getItem("token")

    };

    const attrs = {
      //taskCommentId:this.state.taskCommentId
      // email: this.state.email,
      // password: this.state.password
    };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs
    // };

    // const httpBody = {
    //   data: data
    // };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.templateAPiEndPoint + `?brand_id=${brand_id}&&status=Active&&asset_type_id=${asset_type_id}&per_page=5000`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.templateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task blocker create api
  callCreateTaskBlockerApi(values?: any) {
    this.setState({
      openTaskBlockerCreateModel: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
      "task_id": this.state.currentTask.id,

      "description": values.description,

    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callCreateTaskBlockerApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }
  //task Blocker index api
  callGetTaskBlockerListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockersAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //sub task create api
  callCreateSubTaskApi(values?: any) {
    this.setState({
      openSubTaskCreateModel: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTask.id,
      "title": values.title,
    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateSubTaskApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  //task  blocker update api
  callUpdateTaskBlockerApi(taskBlockerId?: any, value?: any) {
    //console.log();

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };
    //console.log("value", value)
    const requestUpdateTaskBlockerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskBlockerApiID = requestUpdateTaskBlockerMessage.messageId;
    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersUpdateAPiEndPoint + `/${taskBlockerId}`
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskBlockerMessage.id, requestUpdateTaskBlockerMessage);



  }

  //task  subtask update api
  callUpdateSubTaskApi(subTaskId?: any, value?: any) {
    //console.log(value);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };

    const requestUpdateSubTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSubTaskApiID = requestUpdateSubTaskMessage.messageId;
    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskUpdateAPiEndPoint + `/${subTaskId.id}`
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateSubTaskMessage.id, requestUpdateSubTaskMessage);



  }

  //state transition create api
  callStateTransitionsAPI(action?: any, taskId?: any, label_name?: any) {


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const attrs = {
      "action": action,
      "label_name": label_name
    };

    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callStateTransitionsAPIId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.state_transitionsAPiEndPoint + `${taskId}`
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.state_transitionsAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  //sub task  index api
  callGetSubTaskListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subtaskAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskAPiEndPoint + `?task_id=${taskId}&per_page=5000`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  getTaskActivityListPerPageApi = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    }, () => {
      const userDetails = localStorage.getItem("userInfo")
      if (userDetails) {
        var brand_id = JSON.parse(userDetails).brand_id

      }
      const header = {

        token: localStorage.getItem("token")

      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getMoreTaskActivityApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskActivityLogAPiEndPoint + `?task_id=${this.state.currentTask.id}&page=${this.state.currentPage}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.taskActivityLogAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  getTaskActivityListApi(taskId: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskActivityApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskActivityLogAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskActivityLogAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //task index api

  callGetTaskActivityListApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const projectID: any = localStorage.getItem("selectedProjectId");
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.taskActivityLogAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskActivityLogAPiEndPoint + `?project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskActivityLogAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  callCreateRequestQuotationApi(taskId: any) {

    //console.log(taskId);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const attrs = {
      "task_id": this.state.currentTask.id
    };
    //console.log("request quote", attrs);
    const httpBody = {
      data: attrs
    };

    const requestCreateRequestQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateRequestQuotationApiId = requestCreateRequestQuotationMessage.messageId;
    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requestQuotationCreateAPiEndPoint
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestQuotationCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateRequestQuotationMessage.id, requestCreateRequestQuotationMessage);



  }

  callCreateRequestQuotationForProjectApi(projectId: any) {
    const selectedProjectData: any = this.state.selectedProjectData

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const attrs = {
      "project_id": Number(selectedProjectData.id)
    };
    //console.log("request quote", attrs);
    const httpBody = {
      data: attrs
    };

    const requestCreateRequestQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateRequestQuotationForProjectApiId = requestCreateRequestQuotationMessage.messageId;
    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requestQuotationForProjectCreateAPiEndPoint
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateRequestQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestQuotationForProjectCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateRequestQuotationMessage.id, requestCreateRequestQuotationMessage);



  }

  //projects active get api
  callGetProjectsActiveListApi() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callGetProjectsActiveListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectActiveAPiEndPoint + `page=${1}&per_page=${2}&is_active=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectActiveAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //projects active get api
  callGetProjectsDeleteListApi() {
    // debugger;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callGetProjectsDeleteListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDeleteAPiEndPoint + `page=${1}&per_page=${2}&is_delete=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectDeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //projects Achive get api
  callGetProjectsAchiveListApi() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callGetProjectsAchiveListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAchiveAPiEndPoint + `page=${1}&per_page=${2}&is_achive=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAchiveAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSearchChange2 = (event: any) => {
    //console.log(event.target.value)


    if (event.target.value !== "") {
      this.setState({ isSearch: false, searchProject: event.target.value })
      if (this.state.filterValue !== "") {
        this.getProjectsByFilter(`name=${event.target.value}&Sort_by=${this.state.filterValue}`)
      } else {
        this.getProjectsByFilter(`name=${event.target.value}`)
      }
    } else {
      this.setState({ isSearch: false, searchProject: "" })
    }
  }
  setData2(selectedBrand?: any) {

    //console.log("selectedBrand",selectedBrand);
    //   this.setState({
    //    selectedBrandId: selectedBrand.id,
    //    selectedBrand: selectedBrand,
    //  });
    localStorage.setItem("selectedBrandID", selectedBrand?.attributes?.brand_id)
    localStorage.setItem("selectedBrand", selectedBrand?.attributes?.brand_id);
    // localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.brand?.data?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", selectedBrand?.id);
    localStorage.setItem("selectedProject", selectedBrand?.id);
    localStorage.setItem("selectedProjectName", selectedBrand.attributes?.name);


    // window.location.href = '/Home/Projectportfolio'
    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    //this.props.history.push("/Home/Projects")
    //navigator.navigate("/Home/Projectportfolio")

    window.location.href = '/Home/Projectportfolio'



  }
  getProjectsByFilter = (path: any) => {
    this.setState({ loadingIndicator: true });
    this.setState({ tableProjectData: [] })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=${this.state.setPage}&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      this.setState({ searchValue: event.target.value, isSearch: true })

      if (this.state.filterValue !== "") {
        this.getTasksByFilter(`&title=${event.target.value}&Sort_by=${this.state.filterValue}`);
      } else {
        this.getTasksByFilter(`&title=${event.target.value}`);
      }

    } else {
      this.setState({ searchValue: "", isSearch: false })
    }
  }


  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null,
      searchKeyword: "",
      filteredUserAccountsData: this.state.filteredUserAccountsDataTemp
    })
  }
  handleFilterClose2 = () => {
    this.setState({ anchorElFilter1: null })
  }

  handleFilterChange = (event: any) => {
    this.setState({ filterValue: event.target.value, isSearch: true, taskData: [] })
    if (this.state.searchValue !== "") {
      this.getTasksByFilter(`&title=${this.state.searchValue}&Sort_by=${event.target.value}`);
    } else {
      this.getTasksByFilter(`&Sort_by=${event.target.value}`);
    }
  }
  handleFilterChange2 = (event: any) => {
    var order = event.target.value.slice(-1)
    var item = event.target.value.substr(0, event.target.value.length - 1)
    this.setState({
      // isSearch: true,
      projectFilterValue: event.target.value,
      anchorElFilter1: null,
      loadingIndicator: true,
      // , isSearch: true
    })
    let path = "" as string;
    if (order == "0") {
      path = `sort_by=${item}&sort_order=asc`
    }
    else if (order == "1") {
      path = `sort_by=${item}&sort_order=desc`
    }
    this.callGetProjectsListApiType121(path)
  }

  callGetProjectsListApiType121(path: any) {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType121 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPage}&per_page=${100}&brand_id=${brand_id}` + `&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  getTasksByFilter = (path: any) => {
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksByFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks" + `?brand_id=${brand_id}&project_id=${project_id}${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleTitleChange = (event: any) => {
    //console.log(event.target.value)
    let titleObject = this.state.currentTask;
    titleObject.attributes.title = event.target.value;
    this.setState({ currentTask: titleObject })
  }

  handleFilterClick = (flag: any) => {
    //console.log(flag);
    this.setState({
      selectedFilter: flag
    }, () => {
    })
  }

  handleSelectedFilterValueChange = (flag: any, data: any) => {
    //console.log(data);
    if (flag == 'name') {
      var obj = this.state.selectedUsersForFilter.find((item: any) => item == data);
      if (obj === undefined) {
        this.setState((preState) => ({
          selectedUsersForFilter: [...preState.selectedUsersForFilter, data]
        }))
      } else {
        var objArr = this.state.selectedUsersForFilter.filter((item: any) => item !== data);
        this.setState((preState) => ({
          selectedUsersForFilter: objArr
        }))
      }
    }
    else if (flag == 'asset_category') {
      var obj = this.state.selectedCategoryForFilter.find((item: any) => item == data);
      if (obj === undefined) {
        this.setState((preState) => ({
          selectedCategoryForFilter: [...preState.selectedCategoryForFilter, data]
        }), () => {
          this.callGetAssetTypeListApi(data)
        })
      }

      else {
        var objArr = this.state.selectedCategoryForFilter.filter((item: any) => item !== data);
        this.setState((preState) => ({
          selectedCategoryForFilter: objArr
        }), () => {
          const temp = this.state.filterTaskOnAssetTypes.filter((_data: any) => _data.attributes.asset_category_id != data)
          this.setState({
            filterTaskOnAssetTypes: temp
          })
        })
      }
    }
    else if (flag == 'asset_type') {
      var obj = this.state.selectedAssetTypeForFilter.find((item: any) => item == data);
      if (obj === undefined) {
        this.setState((preState) => ({
          selectedAssetTypeForFilter: [...preState.selectedAssetTypeForFilter, data]
        }))
      }

      else {
        var objArr = this.state.selectedAssetTypeForFilter.filter((item: any) => item !== data);
        this.setState((preState) => ({
          selectedAssetTypeForFilter: objArr
        }))
      }
    }

    else if (flag == 'status') {
      var obj = this.state.selectedStatusForFilter.find((item: any) => item == data);
      if (obj === undefined) {
        this.setState((preState) => ({
          selectedStatusForFilter: [...preState.selectedStatusForFilter, data]
        }))
      } else {
        var objArr = this.state.selectedStatusForFilter.filter((item: any) => item !== data);
        this.setState((preState) => ({
          selectedStatusForFilter: objArr
        }))
      }
    }
  }

  getFilteredUserAccounts = (roles: any) => {
    // debugger;
    const brand_id: any = localStorage.getItem("selectedBrandID");
    const project_id: any = localStorage.getItem("selectedProjectId");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredUserAccountsApiCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/account_projects?page=${1}&per_page=${2000}&project_id=${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllStatusByProjectId = () => {
    const header = { token: localStorage.getItem("token") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStatusByProjectIdApiCallId = requestMessage.messageId;
    var project_id = localStorage.getItem("selectedProjectId");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_customised_order_status/task_statuses?project_id=${project_id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  filterTasksBasedOnUserAndStatus = () => {
    var dynamicStatus: any = []
    var columns: any = this.state.columnsKanban
    const selectedProjectId: any = localStorage.getItem("selectedProjectId");
    this.state.taskStatusesData.map((item: any) => {
      dynamicStatus.push({ "id": item.attributes.status, "action": item.attributes.action, "title": item.attributes.status, "messageId": '' })
      columns[item.attributes.status] = {
        id: item.id,
        title: item.attributes.status,
        action: item.attributes.action,
        tasks: [],
        messageId: ''
      }
    })
    this.setState(function (state, props) {
      return {
        dynamicStatus: columns,
        columnOrderKanban: dynamicStatus,
        columnsKanban: columns,
        dynamicMessages: columns,
      };
    });

    dynamicStatus.map((column: any) => {
      if (this.state.selectedUsersForFilter?.length > 0 || this.state.selectedCategoryForFilter?.length > 0 || this.state.selectedAssetTypeForFilter?.length > 0 || this.state.filterDueDate) {
        console.log(column);
        this.callGetOngoingTaskFilterListApi(selectedProjectId, column, this.state.selectedUsersForFilter.join(), this.state.selectedCategoryForFilter.join(), this.state.selectedAssetTypeForFilter.join());
      } else {
        this.callGetOngoingTaskListApi(selectedProjectId, column);
      }
    })
  }

  callGetOngoingTaskFilterListApi = (projectID?: any, status?: any, memberIds?: any, categoryId?: any, assetTypeIds?: any) => {

    const userDetails = localStorage.getItem("userInfo");
    let dueDate = '';
    if (this.state.filterDueDate !== '') {
      dueDate = this.state.filterDueDate.split("-").reverse().join("/")
    }

    if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer" || this.state.permissions === "brand_manager")) {
      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id
      }
    } else {
      var brand_id: any = localStorage.getItem("selectedBrandID");
    }

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    status.messageId = requestMessage.messageId
    var dynamicMessages = this.state.dynamicMessages;
    dynamicMessages[status.id] = status
    this.setState({
      dynamicMessages: dynamicMessages
    })
    if (status.id == "Draft" && dueDate !== '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=draft&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}&estimated_end_date=${dueDate}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
      );
    } else if (status.id == "Draft") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=draft&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
      );
    } else if (status.id == "Quote Pending" && dueDate !== '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=quote_requested_or_pending&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}&estimated_end_date=${dueDate}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`
      );
    } else if (status.id == "Quote Pending") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=quote_requested_or_pending&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`
      );
    } else if (dueDate !== '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}&estimated_end_date=${dueDate}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
        // configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&status_type=waiting_for_quote&brand_id=${brand_id}&project_id=${projectID}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskAPiEndPoint + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}${memberIds?.length > 0 ? ("&member_ids=" + memberIds) : ''}${categoryId?.length > 0 ? ("&asset_category_ids=" + categoryId) : ''}${assetTypeIds?.length > 0 ? ("&asst_type_ids=" + assetTypeIds) : ''}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideAnnotationAndTextLayer = () => {
    setTimeout(function () {
      var a = $(".react-pdf__Page__textContent");
      a.css({ "display": "none" });
      var b = $(".react-pdf__Page__annotations");
      b.css({ "display": "none" });
    }, 3000);
  }

  goToPrevPage = () => {
    if (this.state.getAllFileTaskAnnotation && this.state.getAllFileTaskAnnotation.length > 0) {
      this.state.getAllFileTaskAnnotation.map((annotation: any, index: any) => {
        $(`#Annot${index + 1}`).css("display", "");
        if (annotation.attributes.page_number !== (this.state.pageNumber - 1)) {
          $(`#Annot${index + 1}`).css("display", "none");
        }
      });
    }
    this.hideAnnotationAndTextLayer();
    this.setState({
      pageNumber: this.state.pageNumber - 1
    });
  }

  goToNextPage = () => {
    if (this.state.getAllFileTaskAnnotation && this.state.getAllFileTaskAnnotation.length > 0) {
      this.state.getAllFileTaskAnnotation.map((annotation: any, index: any) => {
        $(`#Annot${index + 1}`).css("display", "");
        if (annotation.attributes.page_number !== (this.state.pageNumber + 1)) {
          $(`#Annot${index + 1}`).css("display", "none");
        }
      });
    }
    this.hideAnnotationAndTextLayer();
    this.setState({
      pageNumber: this.state.pageNumber + 1
    });
  }

  addPdfAnnotation = (annotationData: any) => {
    // debugger;
    const self = this
    let clientHeight: any
    let clientWidth: any
    let imgDimes: any


    if (this.state.fileType == "pdf") {
      let data = document.getElementsByClassName("react-pdf__Page__canvas")
      imgDimes = data[0]
    } else {
      imgDimes = document.getElementById("annotation-image-id");
    }
    if (imgDimes !== null) {
      clientWidth = imgDimes.clientWidth
      clientHeight = imgDimes.clientHeight

    }

    if (annotationData && annotationData.length > 0) {
      this.setState({
        annotationNumber: annotationData.length + 1
      });
      annotationData.map((annotation: any, index: any) => {
        let xPercentage: number = 0
        let yPercentage: number = 0
        xPercentage = (annotation.attributes.x_coordinate * clientHeight) / 100;
        yPercentage = (annotation.attributes.y_coordinate * clientWidth) / 100;
        $(`<span 
            id="Annot${index + 1}" 
          >`
        ).appendTo('.react-pdf__Page');
        $(`<span
            style="border-radius: 50%;
              padding: 0px 10px 3px 10px;
              font-size: 30px;
              color: #fff;
              text-align: center;
              background: #000;
            "
          >
            ${index + 1}
          </span>`
        ).appendTo(`#Annot${index + 1}`);
        if (this.state.fileType == "pdf") {
          if (annotation.attributes.page_number == this.state.pageNumber) {
            $(`#Annot${index + 1}`).css({
              "position": "absolute",
              "top": self.state.fullScreenImage ? xPercentage - 18 : xPercentage,
              "left": self.state.fullScreenImage ? yPercentage - 18 : yPercentage,
              "z-index": "99"
            });
          } else {
            $(`#Annot${index + 1}`).css({
              "position": "absolute",
              "top": self.state.fullScreenImage ? xPercentage - 18 : xPercentage,
              "left": self.state.fullScreenImage ? yPercentage - 18 : yPercentage,
              "z-index": "99",
              "display": "none"
            });
          }
        } else {
          $(`#Annot${index + 1}`).css({
            "position": "absolute",
            "top": self.state.fullScreenImage ? xPercentage - 18 : xPercentage,
            "left": self.state.fullScreenImage ? yPercentage - 18 : yPercentage,
            "z-index": "99"
          });
        }
      });
    }
    $(`<span 
        id="Annot${annotationData.length + 1}" 
        style="display: none"
      >`
    ).appendTo('.react-pdf__Page');
    $(`<span
        style="border-radius: 50%;
          padding: 0px 10px 3px 10px;
          font-size: 30px;
          color: #fff;
          text-align: center;
          background: #000;
        "
      >
          ${annotationData.length + 1}
      </span>`
    ).appendTo(`#Annot${annotationData.length + 1}`);
    this.setState({ loading: false });
  }

  resetAnnotationInPdf = () => {
    if (this.state.getAllFileTaskAnnotation && this.state.getAllFileTaskAnnotation.length > 0) {
      this.state.getAllFileTaskAnnotation.map((annotation: any, index: any) => {
        $(`#Annot${index + 1}`).remove();
      })
    }
    if (this.state.showAddAnnotation) {
      $(`#Annot${this.state.annotationNumber - 1}`).remove();
      $(`#Annot${this.state.annotationNumber}`).remove();
    } else {
      $(`#Annot${this.state.annotationNumber}`).remove();
    }
    this.setState({
      loading: true,
      // annotationCompleteDataPdf: EditorState.createEmpty(),
      showAddAnnotation: false,
      convertedReplyData: {},
      annotationNumber: 1,
      pageNumber: this.state.pageNumber,
      getAllFileTaskAnnotation: this.state.getAllFileTaskAnnotation
    })
  }
  loadJqueryForAnnotation = () => {
    console.log('++++')
    this.setState({ loading: true, isResolved: false }, () => {
      console.log(this.state.loading)
    })
    console.log(this.state.fileType)
    const type: any = this.state.fileType
    let self = this;
    setTimeout(function () {
      self.addPdfAnnotation(self.state.getAllFileTaskAnnotation);
    }, 3000);
    if ((this.state.fileData?.reviews_submitted == true && this.state.permissionName == "client") || this.state.fileType == "video" ||
      (this.state.fileData?.status == "Approved" && this.state.permissionName == "client")) {
    } else {
      $(document).on('click', '.react-pdf__Page', function (e: any) {
        let clientHeight: any
        let clientWidth: any
        let imgDimes: any


        if (type == "pdf") {
          let data = document.getElementsByClassName("react-pdf__Page__canvas")
          imgDimes = data[0]
        } else {
          imgDimes = document.getElementById("annotation-image-id");
        }
        if (imgDimes !== null) {
          clientWidth = imgDimes.clientWidth
          clientHeight = imgDimes.clientHeight
        }
        if ((e.originalEvent.offsetY <= clientHeight && e.originalEvent.offsetX <= clientWidth) && e.target.tagName != 'SPAN') {
          console.log(e.target.tagName)
          if (self.state.filterType !== '2') {
            if (self.state.showAddAnnotation) {

              let xPercentage: number = 0
              let yPercentage: number = 0


              xPercentage = ((e.originalEvent.offsetY - 18) / clientHeight) * 100;
              yPercentage = ((e.originalEvent.offsetX - 18) / clientWidth) * 100;

              $(`#Annot${self.state.annotationNumber - 1}`).css({
                "position": "absolute",
                "top": e.originalEvent.offsetY - 18,
                "left": e.originalEvent.offsetX - 18,
                "z-index": "99"
              });
              let annotationData = {
                "type": "STICKY",
                "x": xPercentage,
                "y": yPercentage,
                "pageNumber": self.state.pageNumber,
                "width": "0",
                "height": "0"
              };
              self.setState({
                geomentry: annotationData
              });
              // self.onChangeForPdf(e.originalEvent.offsetY , e.originalEvent.offsetX);

            } else {
              $(`#Annot${self.state.annotationNumber}`).removeAttr('style');
              $(`#Annot${self.state.annotationNumber}`).css({
                "position": "absolute",
                "top": e.originalEvent.offsetY - 18,
                "left": e.originalEvent.offsetX - 18,
                "z-index": "99"
              });
              $(`<span 
                id="Annot${self.state.annotationNumber + 1}" 
                style="display: none"
              >`
              ).appendTo('.react-pdf__Page');
              $(`<span 
                style="border-radius: 50%;
                  padding: 0px 10px 3px 10px;
                  font-size: 30px;
                  color: #fff;
                  text-align: center;
                  background: #000;
                "
              >
                ${self.state.annotationNumber + 1}
              </span>`
              ).appendTo(`#Annot${self.state.annotationNumber + 1}`);
              self.onChangeForPdf(e.originalEvent.offsetY - 18, e.originalEvent.offsetX - 18);
              var d: any = document.getElementById(`scrollCommentsSection`);
              if (d) {
                d.scroll({ top: 0, behavior: 'auto' });
              }
            }
          }
        }
      });
    }
  }

  changeActivityViewState = () => {
    this.setState({ showActivity: !this.state.showActivity })
  }

  onClickSubmitReview = (fileId?: any) => {
    this.setState({
      loading: true,
      submitReviewPopUp: false
    })
    const header = {
      "Content-Type": configJSON.taskFileUpdateApiContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        reviews_submitted: true,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_upload_media/task_files/' + `${fileId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBillingDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const brandId = localStorage.getItem("selectedBrand");
    this.getCompanyBillingDetailId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details?brand_id=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlefilterDueDate = (event: any) => {
    console.log("filterDueDate", event.target.value)
    this.setState({
      filterDueDate: event.target.value as string,
    });
  };

  changeBetweenFullAndNoramlView = () => {

    // if (this.state.fullScreenImage) {
    //   this.setState({ fullScreenImage: false });
    //   this.resetAnnotationInPdf();
    //   this.loadJqueryForAnnotation();
    //    $(document).off('click', '.react-pdf__Page');
    //   this.setState({ fullScreenImage: true, loading: false });
    // } else {
    //   // this.cancelAddAnnotationForPdf();
    //   this.setState({loading:true})
    //    this.resetAnnotationInPdf();
    //   this.loadJqueryForAnnotation();
    //   $(document).off('click', '.react-pdf__Page');
    //   this.setState({ fullScreenImage: true, loading: false });
    // }

    if (this.state.fullScreenImage) {
      this.setState({ fullScreenImage: false });
      this.resetAnnotationInPdf();
      this.loadJqueryForAnnotation();
    } else {
      //this.cancelAddAnnotationForPdf();
      this.resetAnnotationInPdf();
      this.loadJqueryForAnnotation();
      // $(document).off('click', '.react-pdf__Page');
      this.setState({ fullScreenImage: true });
    }

  }
}
  // Customizable Area End