
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropzoneArea } from "material-ui-dropzone";
import InputColor from "react-input-color";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
// import HomeScreen from './HomeScreen'
import { BlockComponent } from "../../../framework/src/BlockComponent";

// card imports

import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { red } from "@material-ui/core/colors";
import Toolbar from "@material-ui/core/Toolbar";
import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import LanguageOptions from "../../../blocks/LanguageOptions/src/LanguageOptions";
import { withTranslation, Trans } from "react-i18next";
import { createGlobalStyle } from 'styled-components'

// Import main Board component
import { Board } from './board'

//Assembler generated adapters start


//Assembler generated adapters end


// Customizable Area End


// const downIcon = require("./down.png");
const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});
// const userImage = require("./view-user-image@3x.png");
// const designHarvestLogo = require("./design-harvest-logo-icon.png");
// const brandLogo = require("./volkswagen.svg");

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #4bcffa;
  }
`

const styles = {
  root: {
    flexGrow: 1,
  },
  dropZone: {
    minHeight: "0px",
    height: "70px",
    "& dropzoneIcon": {
      display: "none",
    },
  },
  MuiDropzoneAreaText: {
    height: "30px !important",
  },
  sideNav: {
    height: "100vh",
    backgroundColor: "#f5f6fa",
    zIndex: "9999",
    position: "fixed",
    width: "100%",
  },
  content: {
    backgroundColor: "#ffff",
    position: "relative",
    left: "20%",
  },
  topLogo: {
    marginTop: "40px",
    marginLeft: "40px",
  },
  userName: {
    color: "#282828",
    fontSize: "22px",
  },
  userDesignation: {
    color: "#737373",
    fontSize: "14px",
    margin: "0",
  },
  logos: {
    position: "fixed",
    bottom: "40px",
    left: "20px",
  },
  footerText: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  foottext: {
    color: "#5b6b96",
    // fontFamily:"WorkSans-Regular",
    fontSize: "10px",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "999",
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    paddingBottom: "30px",
  },
  username: {
    fontSize: "32px",
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "40px",
  },
  gridSpacing: {
    marginLeft: "40px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  confirmInputStreet: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  selectInput: {
    marginBottom: "20px",
  },
  selectInputLanguage: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    // backgroundColor:"#173e59",
    // backgroundColor:dynamicColor,
    // backgroundColor:`${dynamicColor}`,
    color: "white",
  },
  chips: {
    backgroundColor: "#f4f6fc",
  },
  fileNameDisplay: {
    height: "30px",
    backgroundColor: "#f4f6fc",
    width: "auto",
  },
} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  isTrue: false;
  files: [];
  dynamicColor: any;
  t?: any;
  i18?: any;
  // Customizable Area End
}

// Customizable Area Start
interface S {
  value: number;
 
}

interface SS {}

class Trello extends BlockComponent<Props, S, SS> {
  static instance: Trello;

  constructor(props: Props) {
    super(props);
    console.log(props);
    this.state = {
      value: 0,
      
    };

    Trello.instance = this;
  }

  
  render() {
    const { t, navigation, classes } = this.props;
    const _this = this;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#1a76b0";
    }
    return (
     <div>
         <Board/>
         <GlobalStyle/>
     </div>
   );
  }
}
// Customizable Area End

// Customizable Area Start

export default Trello;
