import React from "react";
// Customizable Area Start
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import Pagination from '@material-ui/lab/Pagination';
const defaultUser = require("../../../components/src/defaultuser.png")
// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import BrandAssetsController, {
  Props,
  configJSON,
} from "./BrandAssetsController";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { withTranslation, Trans } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import _ from "lodash";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchIcon from "@material-ui/icons/Search";
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfOutlined"
import CircularProgress from '@material-ui/core/CircularProgress';

import '../../../components/src/styles/styles.css'
// import Board from 'react-trello'
import Svg from "../../../components/src/svgComponents/Svg";
// or
// import { InputAdornment } from '@material-ui/core';
import Input from "@material-ui/core/Input";
import { Divider } from "react-native-elements";
import Backdrop from "@material-ui/core/Backdrop";
const downIcon = require("../../../components/src/down.png");
const logo = require("../../../components/src/volkswagen.svg");
// const inviteLogo = require('../../../components/src/svgFiles/group-6.svg')
// const duplicateLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
// const csvExportLogo = require("../../../components/src/svgFiles/group-8.svg");
// const archieveLogo = require("../../../components/src/svgFiles/group-14.svg")
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg");
const csvExportLogo = require("../../../components/src/svgFiles/group-6.svg");
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const filterButton = require("../../../components/src/filter.png");

const top100Films = [
  {
    title: "The Shawshank Redemption",
    year: 1994,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  {
    title: "Fight Club",
    year: 1999,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
  { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "The Lord of the Rings: The Two Towers", year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const styles = {
  root: {
    marginLeft: "40px",
  },
  button: {
    marginLeft: "43px",
    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center",
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
  },
  buttonGroup1: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  inputSerachBox: {
    "&::before": {
      content: "none",
      borderBottom: "none",
    },
    "&:hover": {
      // borderBottom:'none'
    },
    "&::after": {
      borderBottom: "none",
    },
    border: "1px solid #e8edf4",
    background: "#ffffff",
    borderBottom: "1px solid #e8edf4",
    boxSizing: "border-box",
    borderRadius: "8px",
    outline: "none",
    // underline:"none"
  },

  accordianStyle: {
    boxShadow: "none",
  },
  actionCard: {
    backgroundColor: "#e4e8f0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    minHeight: "45px",
    justifyContent: "space-between"
  },
  //   avatar:{
  // marginLeft:"40px"
  //   },
  heading: {
    fontSize: "14px",
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "10px",
    borderBottom: "0",
    height: "100%"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
  },

  title: {
    color: "#282828",
    fontSize: "16px",
  },
  dropDown: {
    fontSize: "16px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  folderDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "20px"
  },
  folderHeaders: {
    fontSize: "16px"
  },
  noOfFiles: {
    fontSize: "16px",
    marginLeft: "20px"
  },
  tab: {
    "&:p": {
      fontSize: "16px",
      textTransform: "none"
    },
    textTransform: "none"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
} as any;

interface FilmOptionType {
  title: string;
  year: number;
}

class BrandAssets extends BrandAssetsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  textArea: any

  handleClickProjects = (event: any) => {
    console.log(event, "h");
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  handleCloseModal1 = () => {
    this.setState({
      openLink: false,
    });
  };
  // Customizable Area Start
  // Customizable Area End
  handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({ openLink: false })

  }
  navigateToProjectPortfolio = () => {
    console.log(this.props, "prop");
    // window.location.href='/Home/Projectportfolio'
    this.props.history.push("/Home/Projectportfolio");
  };

  handleNotificationOpen = () => {
    this.setState({
      setNotification: true
    })
  }

  handleCloseNotification = () => {
    this.setState({
      setNotification: false
    })
  }
  handleCloseFolderProperties = () => {
    this.setState({
      setFolder: false
    })
  }



  render() {
    const { t, classes, match } = this.props;
    console.log(this.props, "prop");

    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    const brandId: string = match.params.brandId || "N/A";

    return (
      <div className={classes.root} style={{ fontFamily: primaryFont }}>
        <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
            // onClick={() => this.setState({ loading: false })}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/Home/HomePage"
                component={RouterLink}
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily: SecondaryFont,
                  fontSize: "14px",
                  textDecoration: "none",
                }}

              >
                Home
              </Link>
              { (this.state.permissionName == "albertbauer" || this.state.permissionName == 'superadmin') &&
              <Link
                to="/home/MediaAssets"
                component={RouterLink}
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily: SecondaryFont,
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                Media Asset Library
              </Link> 
              }

{ (this.state.permissionName == "albertbauer" || this.state.permissionName == 'superadmin')  && localStorage.getItem("brandGroupSelected") &&
              <Link
                to="/Home/MediaAssetsbrandGroup"
                component={RouterLink}
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily: SecondaryFont,
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                Brand Group
              </Link> 
              }

              <Typography style={{ fontSize: "12px", fontFamily: primaryFont }}>
                {this.state.brandDetail?.attributes?.name}
              </Typography>
            </Breadcrumbs>

            <span>
              <p style={{ fontSize: "32px", margin: "0px 0px 0px 10px", fontFamily: primaryFont }}>
                {this.state.brandDetail?.attributes?.name}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Media Asset Library</Typography>
                      <p>Here you can see the media asset library</p>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                  </InfoOutlinedIcon>
                </HtmlTooltip>
              </p>
            </span>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              style={{
                color: secondaryColorToBeChanged,
                fontFamily : SecondaryFont,
                marginBottom: "10px",
                textTransform: "none"
              }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter: event.target })}
            >
              Filters
            </Button> */}

            <ButtonGroup
              aria-label="outlined primary button group"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                className={
                  this.state.grid ? classes.activeButton : classes.button
                }
                onClick={(e) => {
                  this.changeToGridView(e);
                }}
                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={
                  <AppsOutlinedIcon
                    style={{ color: secondaryColorToBeChanged }}
                  />
                }
              >
                {this.state.grid && <>Grid</>}
              </Button>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                className={
                  this.state.list
                    ? classes.activeButtonList
                    : classes.buttonGroup
                }
                onClick={(e) => {
                  this.changeToListView(e);
                }}
                endIcon={
                  <ListOutlinedIcon
                    style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }}
                  />
                }
              >
                {this.state.list && (
                  <>
                    <span> List</span>
                  </>
                )}
              </Button>
            </ButtonGroup>

            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              onChange={(e) => {
                this.setState({ search: e.target.value }, () => {
                  console.log("search ,", this.state.search);
                  this.searchAssets()
                })
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              hidden={true}
              variant="contained"
              className={classes.button}
              size="medium"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: colorToBeChanged,
                color: "white",
                marginBottom: "10px",
                fontFamily: SecondaryFont
              }}
              onClick={this.handleClickOpenModal}
            >
              Create Folder
            </Button>
          </Grid>

          {this.state.grid && (
            <Grid item xs={12}>
              <div style={{ marginTop: "10px" }}>
                {this.state.search.length == 0 ?
                  <div>
                    <Button
                      variant="contained"
                      style={{
                        color: secondaryColorToBeChanged, float: "right", marginRight: "17px", marginBottom: "20px",
                        zIndex: 1, textTransform: "none", left: "-35px",
                        top: "-3px", fontFamily: SecondaryFont,
                      }}
                      className={classes.button}
                      startIcon={
                        <img src={filterButton} aria-controls="panel1a-content"
                          id="panel1a-header" />
                      }
                      endIcon={
                        <ExpandMoreIcon style={{ color: "black" }} />
                      }
                      onClick={(event) => this.setState({ anchorElFilter: event.target })}
                    >
                      {
                        this.state.brandFilterValue == 'name0' ? 'Name (A-Z)' : this.state.brandFilterValue == 'name1' ? 'Name (Z-A)' :
                          this.state.brandFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.brandFilterValue == 'created_at1' ? 'Created At' :
                            this.state.brandFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                      }
                    </Button>
                    {/* <Button
                      variant="contained"
                      style={{
                        color: secondaryColorToBeChanged, float: "right", marginRight: "17px",
                        zIndex: 1, textTransform: "none", left: "-35px",
                        top: "-3px" ,  fontFamily : SecondaryFont,
                      }}
                      className={classes.button}
                      startIcon={
                        <img src={filterButton} aria-controls="panel1a-content"
                          id="panel1a-header" />
                      }
                      endIcon={
                        <ExpandMoreIcon style={{ color: "black" }} />
                      }
                      onClick={() => {
                        if (this.state.sortOrder == "asc") {
                          this.setState({ sortOrder: 'desc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                        }
                        else if (this.state.sortOrder == "desc") {
                          this.setState({ sortOrder: 'asc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                        }

                      }
                      }
                    >
                      Name
                          </Button> */}
                  </div>
                  :
                  null

                }
                {
                  this.state.search.length == 0 ?
                    <Accordion className={classes.accordianStyle} defaultExpanded>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          width: "100%",
                          display: " block !important",
                          padding: "0px 20px"
                        }}
                      >
                        <div style={{
                          display: "flex", flexDirection: "row", width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}>
                          <Typography className={classes.heading}>
                            Assets <img src={downIcon} />
                          </Typography>
                          <Typography
                            style={{ position: "absolute", right: "-1385px" }}
                          >
                            {/* <div>
                          <Button
                            variant="contained"
                            style={{ color: secondaryColorToBeChanged }}
                            className={classes.button}
                            startIcon={
                              <ImportExportIcon style={{ color: "black" }} />
                            }
                            // onClick={()=> this.searchAssets()}
                            onClick={()=>{
                              if(this.state.sortOrder == "asc"){
                                this.setState({sortOrder : 'desc'},()=> this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`) )
                              }
                              else if(this.state.sortOrder == "desc"){
                                this.setState({sortOrder : 'asc'},()=> this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`) )
                              }
                              
                            }
                            }
                          >
                            Name
                          </Button>
                        </div> */}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12}>

                          <Grid container spacing={0}>


                            <>
                              {
                                this.state.assetData.map((item: any, index: any) =>

                                  <>

                                    <Grid item xs={3} style={{ marginBottom: "20px" }} >



                                      <Card className={classes.cardStyle}
                                      style={{
                                        cursor : "pointer"
                                      }}
                                      onClick = {()=> this.props.history.push(`/Home/MediaAssets/${item?.attributes?.brand?.data?.attributes?.name}` + '/' + item.attributes.name + '/' + item.id)}
                                      
                                      >
                                        <CardContent style={{ minHeight: "165px", maxHeight: "165px", overflow: "auto" }}>
                                          <Typography
                                            className={classes.title}
                                            gutterBottom
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                              onClick={() => localStorage.setItem("grid", "1")}
                                            >
                                              {/* <Link
                                                //  to="/Home/MediaAssets/bmw/others"
                                                to={`/Home/MediaAssets/${item?.attributes?.brand?.data?.attributes?.name}` + '/' + item.attributes.name + '/' + item.id}
                                                component={RouterLink}
                                                style={{
                                                  color: secondaryColorToBeChanged,
                                                  textDecoration: "none",
                                                }}
                                              > */}
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <FolderOpenIcon
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                  />{" "}
                                                  <p
                                                    style={{
                                                      marginTop: "6px",
                                                      marginLeft: "7px",
                                                    }}
                                                  >
                                                    {item.attributes.name}
                                                  </p>
                                                </span>
                                              {/* </Link> */}
                                              <IconButton
                                                aria-label="settings"
                                                style={{
                                                  color: "black",
                                                  marginTop: "-17px",
                                                }}
                                                onClick={(e: any) => {
                                                  e.stopPropagation();
                                                  e.persist()
                                                  this.setState({ selectedFolder: item,anchorEl : e.currentTarget }, () => console.log("awfcc", this.state.selectedFolder))
                                                  // this.handleClickMenu(e);
                                                }}
                                              >
                                                <MoreVertIcon
                                                  style={{
                                                    color: "black",
                                                    fontSize: "16px",
                                                  }}
                                                  aria-controls="simple-menu"
                                                  aria-haspopup="true"

                                                />
                                              </IconButton>



                                            </div>
                                          </Typography>
                                          <Grid container spacing={0}>
                                            {_.times(item?.attributes?.number_of_files && item?.attributes?.number_of_files > 6 ? 6 : item?.attributes?.number_of_files, () => (
                                              <Grid item xs={4}>
                                                <div key={item.id}
                                                  style={{
                                                    background: "#F9F9FC 0% 0% no-repeat padding-box",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    margin: "10px",
                                                    padding: "5px",
                                                    borderRadius: "4px"
                                                  }}>
                                                  <FileCopyOutlinedIcon />
                                                </div>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>
                                              </Grid>
                                            ))}
                                            {/* 
                                            {item?.internalFiles?.map((element: any) => {
                                              return (
                                                <Grid item xs={4}>


                                                  <div key={item.id}
                                                    style={{
                                                      backgroundColor: "#f9f9fc",

                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      margin: "10px",
                                                      padding: "5px",
                                                      borderRadius: "4px"
                                                    }}>

                                                    <FileCopyOutlinedIcon />
                                                  </div>
                                                  <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{element.attributes.name}</span>
                                                </Grid>

                                              )
                                            })


                                            } */}



                                          </Grid>
                                          <Box display="flex" flexWrap="wrap">
                                            {/* {Array.from(Array(5).keys()).map((key) => (
                                      <Box
                                        key={key}
                                        bgcolor="#f9f9fc"
                                        height="47px"
                                        width="68px"
                                        m="5px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius="4px"
                                      >
                                        <FileCopyOutlinedIcon />
                                      </Box>
                                    ))} */}
                                          </Box>
                                          {/* <Box display="flex" flexWrap="wrap">
                                            {
                                              this.state.files.map((item: any, index: any) => {
                                                return (
                                                  <Box
                                                    key={item.id}
                                                    bgcolor="#f9f9fc"
                                                    height="47px"
                                                    width="68px"
                                                    m="5px"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    borderRadius="4px"
                                                  >
                                                    <div >
                                                      <FileCopyOutlinedIcon />
                                                      <p>  {item.attributes.name}</p>
                                                    </div>


                                                  </Box>
                                                )
                                              })
                                            }

                                          </Box> */}
                                        </CardContent>
                                        <CardActions className={classes.actionCard} style={{height:"20px"}}>
                                          <p style={{ fontSize: "10px", display: "inline-block" }}>
                                            {item.attributes.updated_at}
                                            <br/>
                                            <span>Last Modified</span>
                                          </p>
                                          {item?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                            <AvatarGroup max={3}
                                              // className={classes.avatar}
                                              style={{ marginTop: "10px"}}
                                            >
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                              {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                                <Avatar alt="Remy Sharp"
                                                  //  style={{ height: "30px", width: "30px" }}
                                                  src={defaultUser} />
                                              ))}
                                            </AvatarGroup>
                                            :
                                            (
                                              <>
                                                <AvatarGroup max={3}
                                                  //  className={classes.avatar}
                                                  style={{ marginTop: "10px", marginLeft: "131px" }}
                                                >
                                                  {_.times(item.attributes.media_asset_accounts_count, () => (
                                                    <Avatar alt="Remy Sharp"
                                                      //  style={{ height: "30px", width: "30px" }}
                                                      src={defaultUser} />
                                                  ))}
                                                </AvatarGroup>
                                              </>

                                            )
                                          }
                                          {/* <AvatarGroup
                                        max={4}
                                        className={classes.avatar}
                                        style={{ marginLeft: "30%" }}
                                      >
                                        {this.state.accountImages.map((item: any, index: any) => {
                                          return (
                                            <Avatar
                                              alt="Trevor Henderson"
                                              style={{ height: "30px", width: "30px" }}
                                              src={item.attributes.photo}
                                            />
                                          )
                                        })

                                        }



                                      </AvatarGroup> */}
                                        </CardActions>
                                      </Card>
                                    </Grid>


                                  </>

                                )
                              }
                            </>


                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    :
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        {this.state.search.length > 0 && this.state.searchedData.length > 0 && this.state.searchedData.map((item: any, index: any) => {
                          if (item?.attributes?.media_asset_type != 'media_asset_folder') {
                            return (
                              <Grid item xs={3} style={{ marginBottom: "20px" }}>
                                <Card className={classes.cardStyle} >
                                  <CardContent style={{ minHeight: "165px" }}>
                                    <Typography
                                      className={classes.title}
                                      gutterBottom
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                        onClick={() => localStorage.setItem("grid", "1")}
                                      >
                                        {
                                          item?.attributes?.media_asset_type == 'media_asset' ?
                                            <Link
                                              //  to="/Home/MediaAssets/bmw/others"
                                              // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                              to={`/Home/MediaAssets/${item?.attributes?.brand_name}` + '/' + item.attributes?.name + '/' + item.attributes?.media_id}
                                              component={RouterLink}
                                              style={{
                                                color: secondaryColorToBeChanged,
                                                textDecoration: "none",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <FolderOpenIcon
                                                  style={{
                                                    width: "30px",
                                                    height: "30px",
                                                  }}
                                                  aria-controls="simple-menu"
                                                  aria-haspopup="true"
                                                />{" "}
                                                <p
                                                  style={{
                                                    marginTop: "6px",
                                                    marginLeft: "7px",
                                                  }}
                                                >
                                                  {item?.attributes?.name}
                                                </p>
                                              </span>
                                            </Link>
                                            :

                                            item?.attributes?.media_asset_type == 'media_asset_folder' ?
                                              <Link
                                                //  to="/Home/MediaAssets/bmw/others"
                                                // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                                to={`/Home/MediaAssets` + '/' + item?.attributes?.brand_name + '/' + item?.attributes?.asset?.data?.attributes?.media_asset_id + '/' + item.attributes.name + '/' + item.attributes.media_id}
                                                style={{
                                                  color: secondaryColorToBeChanged,
                                                  textDecoration: "none",
                                                }}
                                                component={RouterLink}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <FolderOpenIcon
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                  />{" "}
                                                  <p
                                                    style={{
                                                      marginTop: "6px",
                                                      marginLeft: "7px",
                                                    }}
                                                  >
                                                    {item?.attributes?.name}
                                                  </p>
                                                </span>
                                              </Link>
                                              :
                                              //   <Link
                                              //   //  to="/Home/MediaAssets/bmw/others"
                                              //   // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                              //   to="/Home/MediaAssets/bmw/others"
                                              //   style={{
                                              //     color: secondaryColorToBeChanged,
                                              //     textDecoration: "none",
                                              //   }}
                                              //   component={RouterLink}
                                              // >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                {/* <FolderOpenIcon
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                      }}
                                                      aria-controls="simple-menu"
                                                      aria-haspopup="true"
                                                    />{" "} */}
                                                <p
                                                  style={{
                                                    marginTop: "6px",
                                                    marginLeft: "7px",
                                                  }}
                                                >
                                                  {item?.attributes?.name}
                                                </p>
                                              </span>

                                        }


                                        {/* <Link
                                                    //  to="/Home/MediaAssets/bmw/others"
                                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                
                                                    style={{
                                                      color: secondaryColorToBeChanged,
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                      }}
                                                    >
                                                      <FolderOpenIcon
                                                        style={{
                                                          width: "30px",
                                                          height: "30px",
                                                        }}
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                      />{" "}
                                                      <p
                                                        style={{
                                                          marginTop: "6px",
                                                          marginLeft: "7px",
                                                        }}
                                                      >
                                                        {item?.attributes?.name}
                                                      </p>
                                                    </span>
                                                  </Link> */}
                                        {item?.attributes?.media_asset_type == 'media_asset_file' ? <IconButton
                                          aria-label="settings"
                                          style={{
                                            color: "black",
                                            marginTop: "-17px",
                                          }}
                                          onClick={(e: any) => {
                                            this.setState({ selectedAsset: item, fileFlag: true })
                                            this.handleClickMenu(e);
                                          }}
                                        >
                                          <MoreVertIcon
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                            }}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"

                                          />
                                        </IconButton> : null}
                                      </div>
                                    </Typography>
                                    {
                                      item?.attributes?.media_asset_type == 'media_asset_file' ?
                                        <Box display="flex" flexWrap="wrap">
                                          {/* {Array.from(Array(5).keys()).map((key) => (
                                               <Box
                                                 key={key}
                                                 bgcolor="#f9f9fc"
                                                 height="47px"
                                                 width="68px"
                                                 m="5px"
                                                 display="flex"
                                                 justifyContent="center"
                                                 alignItems="center"
                                                 borderRadius="4px"
                                               >
                                                 <FileCopyOutlinedIcon />
                                               </Box>
                                             ))} */}
                                          {
                                            (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                              <img src={item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url} style={{ height: "114px", width: "125px" }}
                                                onClick={() => this.imageView(item.attributes.name, item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url)} /> :
                                              (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                                < PictureAsPdfRounded style={{ height: "114px", width: "125px" }} /> :
                                                <FileCopyOutlinedIcon style={{ height: "114px", width: "125px" }} />

                                          }

                                        </Box>
                                        :
                                        <Box display="flex" flexWrap="wrap">

                                        </Box>
                                    }

                                  </CardContent>
                                  <CardActions className={classes.actionCard}>
                                    <p style={{ fontSize: "10px" }}>
                                      {" "}
                                      {item?.attributes?.updated_at} last modified
                                    </p>
                                    {item?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{ marginTop: "10px", marginLeft: "20px" }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                            //  className={classes.avatar}
                                            style={{ marginTop: "10px", marginLeft: "131px" }}
                                          >
                                            {_.times(item.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    }
                                  </CardActions>
                                </Card>
                              </Grid>




                            )
                          }




                        }


                        )
                        }
                      </Grid>
                    </Grid>


                }

              </div>
            </Grid>
          )}
          {this.state.list && (
            <div style={{
              width: "100%",
              marginTop: "20px",
              marginLeft: "10px"
            }}>
              {this.state.search.length == 0 ?
                <div>
                  <Button
                    variant="contained"
                    style={{
                      color: secondaryColorToBeChanged, float: "right", marginRight: "17px",marginBottom: "20px",
                      zIndex: 1, textTransform: "none", left: "-35px",
                      top: "-3px", fontFamily: SecondaryFont,
                    }}
                    className={classes.button}
                    startIcon={
                      <img src={filterButton} aria-controls="panel1a-content"
                        id="panel1a-header" />
                    }
                    endIcon={
                      <ExpandMoreIcon style={{ color: "black" }} />
                    }
                    onClick={(event) => this.setState({ anchorElFilter: event.target })}
                  >
                    {
                      this.state.brandFilterValue == 'name0' ? 'Name (A-Z)' : this.state.brandFilterValue == 'name1' ? 'Name (Z-A)' :
                        this.state.brandFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.brandFilterValue == 'created_at1' ? 'Created At' :
                          this.state.brandFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                    }
                  </Button>
                </div>
                :
                null

              }
              <>
                {
                  this.state.search.length == 0 ?
                    <Accordion
                      style={{
                        width: "100%",
                        //  marginTop: "30px"
                      }}
                      className={classes.accordianStyle}
                      defaultExpanded
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.listHeader}>
                          Assets{" "}
                          <img
                            src={downIcon}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          />
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>

                        {this.state.assetData != null ? this.state.assetData.map((item: any, index: any) => {
                          return (

                            <>
                              <div>
                                {/* <Link

                                  to={`/Home/MediaAssets/${item?.attributes?.brand?.data?.attributes?.name}` + '/' + item.attributes.name + '/' + item.id}
                                  style={{
                                    color: secondaryColorToBeChanged,
                                    textDecoration: "none",
                                  }}
                                  component={RouterLink}
                                > */}
                                  <Box
                                    width="100%"
                                    borderRadius="8px"
                                    border="solid 1px #e4e8f0"
                                    style={{
                                      cursor : "pointer"
                                    }}
                                    onClick = {()=> this.props.history.push(`/Home/MediaAssets/${item?.attributes?.brand?.data?.attributes?.name}` + '/' + item.attributes.name + '/' + item.id)}
                                  >
                                    <Box
                                      height="71.5px"
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      pl="30px"
                                      pr="30px"
                                    // onClick={()=>this.setState({selectedFolder : item})}
                                    >
                                      <Box width="60%"
                                        onClick={() => localStorage.setItem("grid", "0")}
                                      >


                                        {" "}
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                        >
                                          <FolderOpenIcon
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                          />{" "}
                                          <Typography
                                            style={{
                                              marginLeft: "12px",
                                              fontSize: "16px !important",
                                              fontWeight: 500,
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "0.12px",
                                              color: "#282828",
                                            }}
                                          >
                                            {item?.attributes?.name}
                                          </Typography>

                                        </Box>
                                      {/* </Link> */}
                                    </Box>

                                    <Box>
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "12px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.updated_at}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "12px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.size}
                                      </Typography>
                                    </Box>
                                    <Box width="10%"
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="flex-end">
                                      {item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                        <AvatarGroup max={3}
                                          // className={classes.avatar}
                                          style={{
                                            marginTop: "10px",

                                            //  float : "right"

                                          }}
                                        >
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                          {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                            <Avatar alt="Remy Sharp"
                                              //  style={{ height: "30px", width: "30px" }}
                                              src={defaultUser} />
                                          ))}
                                        </AvatarGroup>
                                        :
                                        (
                                          <>
                                            <AvatarGroup max={3}
                                              //  className={classes.avatar}
                                              style={{ marginTop: "10px" }}
                                            >
                                              {_.times(item.attributes.media_asset_accounts_count, () => (
                                                <Avatar alt="Remy Sharp"
                                                  //  style={{ height: "30px", width: "30px" }}
                                                  src={defaultUser} />
                                              ))}
                                            </AvatarGroup>
                                          </>

                                        )
                                      }
                                      {/* <AvatarGroup
                                  max={4}
                                  className={classes.avatar}
                                // style={{ marginLeft: "30%" }}
                                >
                                  <Avatar
                                    alt="Remy Sharp"
                                    style={{ height: "30px", width: "30px" }}
                                    src="https://picsum.photos/id/237/200/300"
                                  />
                                  <Avatar
                                    alt="Travis Howard"
                                    style={{ height: "30px", width: "30px" }}
                                    src="https://picsum.photos/id/237/200/300"
                                  />
                                  <Avatar
                                    alt="Cindy Baker"
                                    style={{ height: "30px", width: "30px" }}
                                    src="https://picsum.photos/id/237/200/300 "
                                  />
                                  <Avatar
                                    alt="Trevor Henderson"
                                    style={{ height: "30px", width: "30px" }}
                                    src="https://picsum.photos/id/237/200/300 "
                                  />
                                </AvatarGroup> */}
                                    </Box>
                                    <Box>
                                      <IconButton
                                        aria-label="settings"
                                        style={{
                                          color: "black",
                                        }}
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                           e.persist()
                                          this.setState({ selectedFolder: item , anchorEl : e.currentTarget }, () => console.log("awfcc", this.state.selectedFolder))
                                          // this.handleClickMenu(e);
                                        }}
                                      >
                                        <MoreVertIcon
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"

                                        />
                                      </IconButton>
                                      

                                    </Box>
                                  </Box>






                                </Box>
                               {/* </Link> */}
                              </div>

                            </>
                          )
                        })
                          :
                          <>
                            No Data Found
                          </>
                        }



                      </AccordionDetails>
                    </Accordion>

                    :

                    <Box width="100%" display="flex" flexDirection="column">
                      {/* <Box
           width="100%"
           borderRadius="8px"
           border="solid 1px #e4e8f0"
         > */}

                      {this.state.search.length != 0 && this.state.searchedData.map((item: any, index: any) => {
                        if (item?.attributes?.media_asset_type != 'media_asset_folder') {
                          return (
                            <div>
                              <Box
                                width="100%"
                                borderRadius="8px"
                                border="solid 1px #e4e8f0"
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="40%"

                                  >
                                    {/* <ImageOutlined
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    />{" "} */}

                                    {
                                      item?.attributes?.media_asset_type == 'media_asset' ?


                                        <FolderOpenIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                        />

                                        :
                                        (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                          item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                          item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                          //         <ImageOutlined
                                          //   style={{
                                          //     width: "30px",
                                          //     height: "30px",
                                          //   }}
                                          //   aria-controls="simple-menu"
                                          //   aria-haspopup="true"
                                          // />
                                          <Svg
                                            color={

                                              colorToBeChanged
                                            }
                                            keyword="MediaAssetLibrarySVg"
                                          />
                                          :
                                          (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                            <PictureAsPdfRounded style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                              aria-controls="simple-menu"
                                              aria-haspopup="true" />
                                            :
                                            item?.attributes?.media_asset_type == 'media_asset_folder' ?


                                              <FolderOpenIcon
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                }}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                              />

                                              :
                                              <FileCopyOutlinedIcon style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true" />

                                    }

                                    {
                                      item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg' ?
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                          onClick={() => this.imageView(item.attributes.name, item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url)}
                                        >
                                          {item.attributes.name}
                                        </Typography>
                                        :
                                        item?.attributes?.media_asset_type == 'media_asset' ?
                                          <Link
                                            to={`/Home/MediaAssets/${item?.attributes?.brand_name}` + '/' + item.attributes?.name + '/' + item.attributes?.media_id}
                                            //to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                            style={{
                                              color: secondaryColorToBeChanged,
                                              textDecoration: "none",
                                            }}
                                            component={RouterLink}
                                          >
                                            <Typography
                                              style={{
                                                marginLeft: "12px",
                                                fontSize: "16px !important",
                                                fontWeight: 500,
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                                letterSpacing: "0.12px",
                                                color: "#282828",
                                              }}
                                            // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                            >
                                              {item.attributes.name}
                                            </Typography>
                                          </Link>
                                          :
                                          item?.attributes?.media_asset_type == 'media_asset_folder' ?
                                            <Link
                                              // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                              to={`/Home/MediaAssets` + '/' + item?.attributes?.brand_name + '/' + item?.attributes?.asset?.data?.attributes?.media_asset_id + '/' + item.attributes.name + '/' + item.attributes.media_id}
                                              style={{
                                                color: secondaryColorToBeChanged,
                                                textDecoration: "none",
                                              }}
                                              component={RouterLink}
                                            >
                                              <Typography
                                                style={{
                                                  marginLeft: "12px",
                                                  fontSize: "16px !important",
                                                  fontWeight: 500,
                                                  fontStretch: "normal",
                                                  fontStyle: "normal",
                                                  lineHeight: "normal",
                                                  letterSpacing: "0.12px",
                                                  color: "#282828",
                                                }}
                                              // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                              >
                                                {item.attributes.name}
                                              </Typography>
                                            </Link>
                                            :
                                            <Typography
                                              style={{
                                                marginLeft: "12px",
                                                fontSize: "16px !important",
                                                fontWeight: 500,
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                                letterSpacing: "0.12px",
                                                color: "#282828",
                                              }}
                                            // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                            >
                                              {item.attributes.name}
                                            </Typography>

                                    }
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        marginLeft: "12px",
                                        fontSize: "12px !important",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "0.12px",
                                        color: "#282828",
                                      }}
                                    >
                                      {/* March 25, 2019 6:21 PM, Modified by Matea */}
                                      {item.attributes.updated_at}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {/* <Typography
                      style={{
                        marginLeft: "12px",
                        fontSize: "12px !important",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.12px",
                        color: "#282828",
                      }}
                    >
                      {item.attributes.size}
                    </Typography> */}
                                  </Box>
                                  <Box>

                                  </Box>
                                  <Box>
                                    {item?.attributes?.media_asset_type == 'media_asset_file' ?
                                      <IconButton
                                        aria-label="settings"
                                        style={{
                                          color: "black",
                                        }}
                                        onClick={(e: any) => {
                                          this.setState({ selectedAsset: item, fileFlag: true })
                                          this.handleClickMenu(e);
                                        }}
                                      >
                                        <MoreVertIcon
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"

                                        />
                                      </IconButton> : null}

                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )
                        }



                      })}



                    </Box>
                }


              </>
            </div>)}


          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9} />
              <Grid item xs={3}>
                <Pagination
                  count={this.state.count}
                  variant="outlined"
                  color="primary"
                  page={this.state.setPage}
                  onChange={this.handlePagination}
                />
              </Grid>
            </Grid>
          </Grid>




        </Grid>
        {/* <div style={{ position: "absolute", right: "5%" }}>
          <Pagination count={this.state.count} variant="outlined" shape="rounded" onChange={e => this.handlePageChange(e)} />

        </div> */}







        {/* Modal Dialogues */}
        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
            {/* {t("projects.newProjectTitle")} */}
            Create New Folder
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: "",

              }}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .matches(/^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gmi, "folder name should not be start and end with specila charecters")
                  .required(
                    "folder name is required"
                  ),


              })}
              onSubmit={(values) => {
                console.log(values);
                this.createFolder(values)
                // this.goToConfirmationAfterPasswordChange(values);
                // this.props.nextStep(values);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                isValid,
                setFieldValue,
                handleBlur,
                setFieldTouched,
                isSubmitting,
                handleSubmit,
              }) => {


                return (
                  <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                      <TextField
                        name="name"
                        type="text"
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        fullWidth
                        label="Name"
                        value={values.name}
                        className={classes.confirmInput}
                        variant="outlined"
                        helperText={
                          values.name.length == 0 && touched.name ? (
                            "folder name is required"
                          ) :
                            touched.name &&
                              errors.name ? (
                              "folder name should not be start and end with special charecters"

                            )
                              : (
                                <>
                                </>
                                // "folder name is required"
                              )
                        }
                        error={
                          touched.name && errors.name
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />


                    </DialogContent>
                    <DialogActions style={{ justifyContent: "flex-start" }}>
                      {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                      <Button
                        style={{
                          backgroundColor: colorToBeChanged,
                          color: "white",
                          marginLeft: "15px",
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Create Folder
                      </Button>
                      <Button
                        className={classes.button}
                        style={{ backgroundColor: "white", color: "black" }}
                        onClick={this.handleCloseModal}
                        // type="submit"
                        variant="contained"
                      >
                        {t("projects.cancel")}
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            />
          </DialogContent>
        </Dialog>

        {/* Notification Dialog Start */}
        <Dialog
          open={this.state.setNotification}
          onClose={this.handleCloseNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle style={{
            fontFamily: "Work Sans !important",
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828"
          }} id="alert-dialog-title" >{"Notification Settings"}


            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseNotification} />
            </span>

          </DialogTitle>
          <DialogContent style={{ maxWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container style={{ marginLeft: "9px" }}>
                <Grid item xs={2}>
                  <Avatar
                    alt="Remy Sharp"
                    style={{ height: "50px", width: "50px" }}
                    src="https://picsum.photos/id/237/200/300"
                  />
                </Grid>

                <Grid item xs={10} >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{

                        fontSize: "16px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.12px",
                        color: "#282828"
                      }} >Notifications</Typography></Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}><Typography variant="body2" style={{
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }} >Customize how you want to be notified on changes in the web pages</Typography></Grid>

                  </Grid>

                </Grid>
              </Grid>
              <Grid container spacing={0}>
                {/* <Grid item xs={12}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }} />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >Enable sounds for incoming notification</Typography>
                </Grid> */}


                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }} />

                      <Typography
                        variant={"body2"}
                        style={{
                          whiteSpace: "normal",
                          display: "inline",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          color: "#282828"
                        }}
                      >Send email for task notification</Typography>

                    </Grid>

                    <Grid item xs={12} style={{ marginLeft: "42px" }}>
                      <FormControl style={{ minWidth: 200 }} variant="outlined" >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          //onChange={handleChange}
                          defaultValue="ime"

                          style={{ height: 40 }}

                        >
                          <MenuItem style={{
                            fontFamily: "Work Sans",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="ime">
                            Immediately
                          </MenuItem>
                          <MenuItem style={{
                            fontFamily: "Work Sans",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#282828"
                          }} value="ime">
                            later
                          </MenuItem>

                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }} />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px ",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >Send email for comments and mentions</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }} />
                  <Typography
                    variant={"body2"}
                    style={{
                      whiteSpace: "normal",
                      display: "inline",
                      fontFamily: "Work Sans",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.1px",
                      color: "#282828"
                    }}
                  >Mute notification in this project</Typography>
                </Grid>
              </Grid>




            </DialogContentText>
          </DialogContent>

        </Dialog>

        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >

          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.brandFilterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name0" control={<Radio />} label="Name (A-Z)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name1" control={<Radio />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio />} label="Created At" />
                {/* <FormControlLabel value="created_at0" control={<Radio />} label="Created At (oldest)" /> */}
                <FormControlLabel value="updated_at1" control={<Radio />} label="Updated At" />
                {/* <FormControlLabel value="updated_at0" control={<Radio />} label="Updated At (oldest)" /> */}

              </RadioGroup>
            </Grid>



          </Grid>
        </Menu>


        {/* folder properties */}

        <Dialog onClose={this.handleCloseFolderProperties} aria-labelledby="customized-dialog-title" open={this.state.setFolder} >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px", fontFamily: primaryFont }}>
            {/* {t("HomePage.photoProduction")} */}
            <div style={{ position: "relative", color: secondaryColorToBeChanged }}>
              {/* {t("teams.overviewTeam")} */}
              Folder Properties
              <span style={{ position: "absolute", right: "10px" }} >
                <CloseIcon onClick={this.handleCloseFolderProperties} />

              </span>
            </div>


          </DialogTitle>


          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="fullWidth"
            scrollButtons="off"
            indicatorColor="primary"
            aria-label="full width tabs example"
            textColor="primary"
            centered
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              label={t("teams.overview")}
              // label="Folder Properties"
              className={classes.tab}
              style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
            />

            <Tab
              // label={t("individualCompanyHomeScreen.members")}
              label=""
              className={classes.tab}
              style={{ fontFamily: primaryFont }}
            />

          </Tabs>


          {this.state.value === 0 &&
            (
              <>
                <DialogContent style={{ minWidth: "700px", fontFamily: primaryFont }}>
                  <DialogContentText id="alert-dialog-description">

                    <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Folder name :
                      </li>
                      <span className={classes.noOfFiles} >{this.state.selectedFolder?.attributes?.name}</span>
                    </div>

                    <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Number of files :
                      </li>
                      <span className={classes.noOfFiles} >{this.state.selectedFolder?.attributes?.number_of_files}</span>
                    </div>

                    <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Number of Folders :
                      </li>
                      <span className={classes.noOfFiles}>{this.state.selectedFolder?.attributes?.number_of_folders}</span>
                    </div>
                    <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Size:
                      </li>
                      <span className={classes.noOfFiles}>{this.state.selectedFolder?.attributes?.size}</span>
                    </div>


                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "white", color: "black" }}
                    type="button"
                    variant="contained"
                    onClick={this.handleCloseFolderProperties}
                  >
                    close
                  </Button>
                </DialogActions>
              </>
            )
          }

        </Dialog>
        <Dialog
          onClose={() => this.setState({ openImage: false })}
          aria-labelledby="customized-dialog-title"
          open={this.state.openImage}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px', fontFamily: primaryFont }}>

            {this.state.openImageURL?.name}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={() => this.setState({ openImage: false })} />
            </span>
          </DialogTitle>
          <DialogContent>

            <img src={this.state.openImageURL?.url} style={{ maxHeight: "75vh", maxWidth: "100%" }} />
          </DialogContent>


        </Dialog>


        {/* <Dialog
          open={this.state.setFolder}
          onClose={this.handleCloseFolderProperties}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle style={{
            fontFamily: "Work Sans !important",
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828",
            marginLeft: "20px"

          }} id="alert-dialog-title">{"Folder Properties"}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseFolderProperties} />
            </span></DialogTitle>
          <Divider /> 
          <DialogContent style={{ minWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">
              
            <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Folder name : 
                </li>
                <span className={classes.noOfFiles} >{this.state.selectedFolder?.attributes?.name}</span>
              </div>

              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of files : 
                </li>
                <span className={classes.noOfFiles} >{this.state.selectedFolder?.attributes?.number_of_files}</span>
              </div>

              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of Folders :
                </li>
                <span className={classes.noOfFiles}>{this.state.selectedFolder?.attributes?.number_of_folders}</span>
              </div>
              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Size:
                </li>
                <span className={classes.noOfFiles}>{this.state.selectedFolder?.attributes?.size}</span>
              </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseFolderProperties}
            >
              close
                    </Button>
          </DialogActions>

        </Dialog>  */}



        {/*Notification Dialog End*/}
        {/* <Dialog
        open={this.state.setOpen}
        onClose={this.handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseModal} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleCloseModal} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}

        {/* <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose("projectProperties");
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleClose("assetsProperties");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px", fontFamily: primaryFont }}
          >
            Asset Properties
          </MenuItem>
        
          {this.state.fileFlag &&
            <MenuItem
              onClick={() => {
                this.handleClose("sharableLink");
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", fontFamily: primaryFont }}
            >
              Get Shareable Link
            </MenuItem>
          }
          {this.state.fileFlag && <MenuItem
            onClick={() => {
              this.handleClose("download");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px", fontFamily: primaryFont }}
          >
            Download
          </MenuItem>
          }
        </Menu> */}
        <Dialog
          onClose={this.handleCloseModal1}
          aria-labelledby="customized-dialog-title"
          open={this.state.openLink}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px', fontFamily: primaryFont }}>
            {/* {t("projects.newProjectTitle")} */}
            Shareable Link
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal1} />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* <p> {this.state.selectedAsset?.attributes?.file_blob_signed_id_url} </p> */}
            <textarea
              ref={(textarea) => this.textArea = textarea}
              value={this.state.selectedAsset?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url.split("?")[0]}
              style={{ height: "125px", width: "750px" }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="button"
              variant="contained"
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy to Clipboard
            </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseModal1}
            >
              {t("projects.cancel")}
            </Button>
          </DialogActions>

        </Dialog>


        <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={() => {
                                          // this.handleClose("projectProperties");
                                          this.setState({ anchorEl: false })
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            this.handleClose("folderProperties");
                                          }}
                                          className={classes.dropDown}
                                          style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                        >
                                          Folder Properties
                                        </MenuItem>

                                        {this.state.permissionName =='superadmin'  && <MenuItem
                                          onClick={() => {
                                            this.handleClose("delete");
                                          }}
                                          className={classes.dropDown}
                                          style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                        >
                                          Delete
                                        </MenuItem>}
                                        {/* <MenuItem
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                    className={classes.dropDown}
                                    style={{ fontSize: "12px" }}
                                  >
                                    Invite Team Members
                          </MenuItem>{" "}
                                  <MenuItem
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                    className={classes.dropDown}
                                    style={{ fontSize: "12px" }}
                                  >
                                    Duplicate
                          </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                    className={classes.dropDown}
                                    style={{ fontSize: "12px" }}
                                  >
                                    Export as .CSV
                          </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                    className={classes.dropDown}
                                    style={{ fontSize: "12px" }}
                                  >
                                    Archive
                          </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      this.handleClose();
                                    }}
                                    className={classes.dropDown}
                                    style={{ fontSize: "12px" }}
                                  >
                                    Notification Settings
                          </MenuItem> */}
                                      </Menu>

                                      <Dialog
          open={this.state.deleteMediaDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className= {classes.deleteProjectDialog}
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteMediaDialog: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
              {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                   onClick={(e) => this.deleteAsset()}
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteMediaDialog: false }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(BrandAssets));
// Customizable Area Start

// Customizable Area End
