import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";

const downIcon = require("../../../components/src/down.png");

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { withTranslation, Trans } from "react-i18next";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  tableDataLogo: {
    border: "1px solid white",
    paddingLeft: "44px",
    paddingTop: "18px",
    paddingBottom: "8px",
    backgroundColor: "#1E3E59",
    borderRadius: "26px",
  },
  menu: {
    width: "168px !important",
    height: "128px  !important",
  },
} as any;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  brandsList: any;
  projectsList: any;
  expanded: any;
  selectedBrandId: any;
  totalBrandPages: number;
  setBrandPage: number;
  totalProjectPages: number;
  setProjectPage: number;
  isSearch: boolean;
  searchValue: any;
  searchData: any;
  totalPage: number;
  nextPage: boolean;
  setPage: number;
  path: string;
}

interface SS {
  id: any;
}

class Projects extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: any;
  getProjectsByBrandApiCallId: any;
  getProjectsByFilterApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: null,
      brandsList: [],
      projectsList: [],
      expanded: "",
      selectedBrandId: "",
      totalBrandPages: 0,
      setBrandPage: 1,
      totalProjectPages: 0,
      setProjectPage: 1,
      isSearch: false,
      searchValue: "",
      searchData: [],
      totalPage: 0,
      nextPage: false,
      setPage: 1,
      path: "",
    };
  }

  async componentDidMount() {
    await this.getBrands();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBrandsApiCallId) {
        if (responseJson.brands) {
          if (responseJson.brands.data) {
            if (responseJson.brands.data !== null) {
              let tempArr = [] as any;
              responseJson.brands.data.map((_data: any, idx: any) => {
                tempArr.push({
                  id: _data.id,
                  name: _data.attributes.name,
                });
                if (idx === 0) {
                  this.setState({
                    selectedBrandId: _data.id,
                  });
                  this.getProjectsByBrand(_data.id);
                }
              });
              let totalBrandPages = this.state.totalBrandPages;
              this.setState({
                brandsList: tempArr,
                totalBrandPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalBrandPages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getProjectsByBrandApiCallId) {
        console.clear();
        console.log(responseJson);
        if (responseJson.projects) {
          if (responseJson.projects.data) {
            if (responseJson.projects.data !== null) {
              let tempArr = [] as any;
              responseJson.projects.data.map((_data: any) => {
                tempArr.push({
                  id: _data.id,
                  projectName: _data.attributes.name,
                  creator: _data.attributes.creator.data
                    ? _data.attributes.creator.data !== null
                      ? `${
                          _data.attributes.creator.data.attributes.first_name
                        } ${_data.attributes.creator.data.attributes.last_name}`
                      : "NA"
                    : "NA",
                  projectManager: _data.attributes.project_manager
                    ? _data.attributes.project_manager.data
                      ? _data.attributes.project_manager.data !== null
                        ? _data.attributes.project_manager.data.attributes
                            .first_name
                        : "NA"
                      : "NA"
                    : "NA",
                  status: _data.attributes.status,
                  updated_at:
                    _data.attributes.updated_at !== null
                      ? _data.attributes.updated_at
                      : "NA",
                });
              });
              let totalProjectPages = this.state.totalProjectPages;
              this.setState({
                projectsList: tempArr,
                totalProjectPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalProjectPages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.getProjectsByFilterApiCallId) {
        console.clear();
        console.log("---------");
        console.log(responseJson);
        if (responseJson.projects) {
          this.setState({
            searchData: responseJson.projects.data
            // searchData: [
            //   ...this.state.searchData,
            //   ...responseJson.projects.data,
            // ],
          });
          this.setState({
            nextPage: responseJson.next_page,
            totalPage: responseJson.total_pages,
          });
        }
      }
    }
  }

  getBrands = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands?page=${
        this.state.setBrandPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectsByBrand = (id: any) => {
    this.setState({ projectsList: [] });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsByBrandApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?brand_id=${id}&page=${
        this.state.setProjectPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchMoreData = () => {
    console.clear();
    console.log("Page count", this.state.setPage);
    this.setState({ setPage: this.state.setPage + 1 }, () => {
      this.getProjectsByFilter(this.state.path, this.state.setPage);
    });
  };

  handleIconClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleAccordionChange = (id: any) => {
    if(this.state.expanded == id + "brandsAccordion"){
      this.setState({
        expanded: "",
        selectedBrandId: "",
      });
    }else{
      this.setState({
        expanded: id + "brandsAccordion",
        selectedBrandId: id,
      });
    }
  };

  exportList = () => {
    var url = `${
      currentUrl
    }/bx_block_data_import_export_csv/project_exports?brand_id=${
      this.state.selectedBrandId
    }&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  handleBrandPagination = (event: any, value: number) => {
    console.log(value);
    this.setState(
      {
        setBrandPage: value,
      },
      () => {
        this.getBrands();
      }
    );
  };
  handleProjectPagination = (event: any, value: number) => {
    console.log(value);
    this.setState(
      {
        setProjectPage: value,
      },
      () => {
        this.getProjectsByBrand(this.state.selectedBrandId);
      }
    );
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  handleSearchClick = () => {
    this.setState({ searchData: [], setPage: 1 });
    this.setState({ isSearch: true });
    let path = `name=${this.state.searchValue}`;
    this.setState({ path: path });
    this.getProjectsByFilter(path, 1);
    this.setState({ searchValue: "" });
  };

  getProjectsByFilter = (path: any, page: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsByFilterApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStatusClick = () => {
    let path = "";
    if (this.state.searchValue !== "") {
      path = `name=${this.state.searchValue}&per_page=1000&sort_by=status`;
    } else {
      path = `per_page=1000&sort_by=status`;
    }

    this.setState({ isSearch: true });
    this.getProjectsByFilter(path, 1);
  };

  render() {
    const { t } = this.props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    return (
      <>
        <Grid container style={{ paddingLeft: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/AdminConsole"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Link>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.projects")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2}>
                {" "}
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.projects")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #9EABB5",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("AdminConsole.export")}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder={t("AdminConsole.searchForProjects")}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginBottom: "30px" }}>
              <div style={{ position: "absolute", right: "16px" }}>
                <Button
                  style={{ zIndex: 100, textTransform: "none" }}
                  endIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                  onClick={this.handleStatusClick}
                >
                  {t("AdminConsole.status")}
                </Button>
              </div>
            </div>
          </Grid>
          {!this.state.isSearch ? (
            <>
              {this.state.brandsList.map((_data: any) => {
                return (
                  <>
                    <Grid item xs={12} key={_data.id}>
                      <Accordion
                        style={{
                          boxShadow: "none",
                          background: "#F5F6FA",
                        }}
                        expanded={
                          this.state.expanded === `${_data.id}brandsAccordion` || userInfo?.role?.data?.attributes?.name == 'client'
                        }
                        onChange={() => this.handleAccordionChange(_data.id)}
                      >
                        {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Button
                              variant="text"
                              onClick={() => {
                                this.setState({ setProjectPage: 1 }, () => {
                                  this.getProjectsByBrand(_data.id);
                                });
                              }}
                            >
                              {this.state.expanded ===
                              `${_data.id}brandsAccordion` ? (
                                <ExpandMoreIcon />
                              ) : (
                                <ChevronRightIcon />
                              )}
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Work Sans",
                                  color: "#0E0E0E",
                                }}
                              >
                                {/* <img src={downIcon} /> {_data.name} */}
                                {_data.name}
                              </Typography>
                            </Button>
                            {/* <Typography
                          style={{
                            position: "absolute",
                            right: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "11px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                color: "#0E0E0E",
                                opacity: 1,
                                fontSize: "13px",
                                fontFamily: "Work Sans",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                marginRight: "15px",
                              }}
                            >
                              {t("AdminConsole.status")}
                            </Typography>
                            <span>
                              <ArrowDownwardIcon
                                style={{
                                  width: "11px",
                                  height: "11px",
                                  marginTop: "4px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography> */}
                          </div>
                        </AccordionSummary>
                        }
                        <AccordionDetails>
                          <Grid container>
                            <Grid item xs={12}>
                              <TableContainer>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "Work Sans",
                                          color: "#9EABB5",
                                          textTransform: "uppercase",
                                          opacity: 1,
                                          borderBottom: "0px",
                                          width: "35%",
                                          paddingLeft: "50px",
                                        }}
                                      >
                                        {t("AdminConsole.projectName")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "Work Sans",
                                          color: "#9EABB5",
                                          textTransform: "uppercase",
                                          opacity: 1,
                                          borderBottom: "0px",
                                          width: "12%",
                                        }}
                                      >
                                        {t("AdminConsole.creator")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "Work Sans",
                                          color: "#9EABB5",
                                          textTransform: "uppercase",
                                          opacity: 1,
                                          borderBottom: "0px",
                                          width: "15%",
                                        }}
                                      >
                                        {t("AdminConsole.projectManager")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "Work Sans",
                                          color: "#9EABB5",
                                          textTransform: "uppercase",
                                          opacity: 1,
                                          borderBottom: "0px",
                                          width: "15%",
                                        }}
                                      >
                                        {t("AdminConsole.status")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "Work Sans",
                                          color: "#9EABB5",
                                          textTransform: "uppercase",
                                          opacity: 1,
                                          borderBottom: "0px",
                                          width: "15%",
                                        }}
                                      >
                                        Updated Date
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {this.state.projectsList.map((_data: any) => {
                                    return (
                                      <>
                                        <TableBody
                                          style={{
                                            background: "white",
                                            cursor : "pointer"
                                          }}
                                          onClick={(e) =>
                                            (window.location.href =
                                              "/AdminConsole/project/" +
                                              _data.id)
                                          }
                                        >
                                          <TableCell
                                            style={{
                                              padding: "35px",
                                              border: "0px",
                                              borderLeft: "20px solid #1E3E59",
                                            }}
                                          >
                                            <Grid container>
                                              <Grid item xs={12}>
                                                <Typography
                                                  variant="body2"
                                                  className={
                                                    this.props.classes.tableData
                                                  }
                                                >
                                                  {_data.projectName}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "0px",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              className={
                                                this.props.classes.tableData
                                              }
                                            >
                                              {_data.creator}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "0px",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              className={
                                                this.props.classes.tableData
                                              }
                                            >
                                              {_data.projectManager}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "0px",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              style={{
                                                fontFamily: "Work Sans",
                                                fontSize: "8px",
                                                color: "#707880",
                                                opacity: "100%",
                                                textTransform: "uppercase",
                                                letterSpacing: 0,
                                                border: "1px solid #707070",
                                                marginRight: "90px",
                                                padding: "10px 30px 10px 30px",
                                                paddingLeft: "34px",
                                                borderRadius: "34px",
                                              }}
                                            >
                                              {_data.status}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "0px",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              className={
                                                this.props.classes.tableData
                                              }
                                            >
                                              {_data.updated_at}
                                            </Typography>
                                          </TableCell>
                                          {/* <TableCell
                                      style={{
                                        border: "0px",
                                      }}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          this.handleIconClick(e);
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </TableCell> */}
                                        </TableBody>
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{
                                              height: "25px",
                                            }}
                                          />
                                        </Grid>
                                      </>
                                    );
                                  })}
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={9} />
                                <Grid item xs={3}>
                                  <Pagination
                                    count={this.state.totalProjectPages}
                                    variant="outlined"
                                    color="primary"
                                    page={this.state.setProjectPage}
                                    onChange={this.handleProjectPagination}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                );
              })}
              {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  {this.state.totalBrandPages > 1 ?
                  <>
                  <Grid item xs={3}>
                    <Pagination
                      count={this.state.totalBrandPages}
                      variant="outlined"
                      color="primary"
                      page={this.state.setBrandPage}
                      onChange={this.handleBrandPagination}
                    />
                  </Grid>
                  </> :""}
                </Grid>
              </Grid>
              }
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      style={{ left: "60px" }}
                      onClick={() =>
                        this.setState({ isSearch: false, searchValue: "" })
                      }
                    >
                      Back to Previous Screen
                    </Button>
                  </Grid>
                </Grid>
                <InfiniteScroll
                  dataLength={this.state.searchData.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.nextPage}
                  loader={this.state.totalPage ? "" : <h4>Loading...</h4>}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            {t("AdminConsole.projectName")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.creator")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.projectManager")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.status")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.dueDate")}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {this.state.searchData.map((_data: any) => {
                        return (
                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}
                              onClick={(e) =>
                                (window.location.href =
                                  "/AdminConsole/project/" + _data.id)
                              }
                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={this.props.classes.tableData}
                                    >
                                      {_data.attributes.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.creator.data !== null
                                    ? `${
                                        _data.attributes.creator.data.attributes
                                          .first_name
                                      } ${
                                        _data.attributes.creator.data.attributes
                                          .last_name
                                      }`
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.project_manager.data !==
                                  null
                                    ? `${
                                        _data.attributes.project_manager.data
                                          .attributes.first_name
                                      } ${
                                        _data.attributes.project_manager.data
                                          .attributes.last_name
                                      }`
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.status}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.estimated_end_date !== null
                                    ? _data.attributes.estimated_end_date
                                    : "NA"}
                                </Typography>
                              </TableCell>
                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </InfiniteScroll>
              </Grid>
            </>
          )}
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleIconClose}
            className={this.props.classes.menu}
          >
            <MenuItem
              style={{
                fontFamily: "Work Sans",
                fontSize: "12px",
                color: "#0E0E0E",
                textTransform: "capitalize",
                letterSpacing: 0,
              }}
            >
              {t("AdminConsole.dueDate")}
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Work Sans",
                fontSize: "12px",
                color: "#0E0E0E",
                textTransform: "capitalize",
                letterSpacing: 0,
              }}
            >
              Name
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Work Sans",
                fontSize: "12px",
                color: "#0E0E0E",
                textTransform: "capitalize",
                letterSpacing: 0,
              }}
            >
              {t("AdminConsole.status")}
            </MenuItem>
          </Menu>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Projects));
