import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { string } from "yup";
import { loadavg } from "os";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any;

  // id2List:any
  // id2List:{ droppable: string; droppable2: string; }
  // Customizable Area Start
  // Customizable Area End
}
// export interface id2List{ droppable: string; droppable2: string; };
// const getItems = (count:any) =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));
// const foo =
//     'lorem ipsum dolor sit amet consectutrtpoyu sklhfdlkfh dklfh lkdhflhdflkh dlkfhdlkhflkd fldhflh';
// const getItems = (count:any, offset = 0) =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         id: `item-${k + offset}`,
//         content: `item ${k + offset}${k + offset >= 10 ? foo : ''}`
//     }));

const data = [
  {
    id: "car",
    content: "car",
  },
  {
    id: "elelphant",
    content: "elelphant",
  },
  {
    id: "ball",
    content: "ball",
  },
  {
    id: "cat",
    content: "cat",
  },
  {
    id: "dog",
    content: "dog",
  },
];

const itemsData = [
  {
    id: "BMW",
    content: "BMW",
  },
  {
    id: "Volkswagon",
    content: "Volkswagon",
  },
  {
    id: "Mercedes",
    content: "Mercedes",
  },
  {
    id: "Suzuki",
    content: "Suzuki",
  },
  {
    id: "Hundai",
    content: "Hundai",
  },
];

const getItems = (count: any, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));
interface FilmOptionType {
  title: string;
  year: number;
}
interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  items: any;
  selected: any;
  approval: any;
  todo: any;
  anchorEl: any;
  grid: boolean;
  list: boolean;
  setOpen: boolean;
  sharedProject: any;
  sharedProjectError: any;
  value: any;
  setOpen1: any;
  setOpen2: any;
  CGI: any;
  anchorE2: any;
  percentage: any;
  openTaskCreateModel: boolean;
  openAddBriefingModel: boolean;
  openAddRequestQuoteModel: boolean;
  category: string;
  assetType: string;
  assetData:any;
  imageData:any
  search : any
  anchorElFilter: any
  brandFilterValue: any
  searchBrandName: any
  isSearch: any
  sortOrder: any
  loading: any
  searchedData : any
  openImageURL: any
  openImage: any
  selectedAsset : any
  fileFlag: boolean
  openLink : boolean,
  setFolder : boolean,
  loading2 : boolean,
  brandGroupData : any
  permissions: any
  permissionName : any


  // Customizable Area End
}
const initialBoardData = {
  items: {
    "item-1": { id: "item-1", content: "Content of item 1." },
    "item-2": { id: "item-2", content: "Content of item 2." },
    "item-3": { id: "item-3", content: "Content of item 3." },
    "item-4": { id: "item-4", content: "Content of item 4." },
    "item-5": { id: "item-5", content: "Content of item 5." },
    "item-6": { id: "item-6", content: "Content of item 6." },
    "item-7": { id: "item-7", content: "Content of item 7." },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Column 1",
      itemsIds: [
        "item-1",
        "item-2",
        "item-3",
        "item-4",
        "item-5",
        "item-6",
        "item-7",
      ],
    },
    "column-2": {
      id: "column-2",
      title: "Column 2",
      itemsIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "Column 3",
      itemsIds: [],
    },
    "column-4": {
      id: "column-4",
      title: "Column 4",
      itemsIds: [],
    },
  },
  columnsOrder: ["column-1", "column-2", "column-3", "column-4"],
};
interface SS {
  id: any;
  items: any;
  selected: any;
  todo: any;
}
const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

export default class BrandGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssetCategoryApiCallId :string = ""
  getAssetCategorySearchApiCallId:string = ""
  getMediaAssetAccountApiCallId:String = ""
  getBrandListingByFiltersId :String = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      items: data,
      todo: itemsData,
      sharedProject: "",
      sharedProjectError: false,
      selected: getItems(5, 10),
      approval: getItems(5),
      percentage: 74,
      anchorEl: null,
      grid: true,
      list: false,
      setOpen: false,
      value: 0,
      setOpen1: false,
      setOpen2: false,
      CGI: false,
      anchorE2: null,
      openTaskCreateModel: false,
      openAddBriefingModel: false,
      openAddRequestQuoteModel: false,
      category: "",
      assetType: "",
      assetData:[],
      imageData:[],
      search: "",
      anchorElFilter: null,
      brandFilterValue: 'name0',
      searchBrandName: null,
      isSearch: null,
      sortOrder : "desc",
      loading : false,
      searchedData : [],
      openImageURL : null,
      openImage : false,
      selectedAsset : null,
      fileFlag : false,
      openLink : false,
      setFolder : false,
      loading2 : false,
      brandGroupData : [],
      permissionName : null,
      permissions : null

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleClickMenu = (event: any) => {
    console.log("clickedvvv");
    
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleCloseModal1 = () => {
    this.setState({
      openLink: false,
    });
  };
  
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null,
    });
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }
  handleFilterChange = (event: any) => {
    this.setState({ loading: true })
    console.log(event.target.value)
    var order = event.target.value.slice(-1)
    var item  = event.target.value.substr(0,event.target.value.length -1)
    this.setState({ brandFilterValue:event.target.value, isSearch: true, anchorElFilter: null })
    let path = "" as string;
    if(order == "0"){
      path = `sort_by=${item}&sort_order=asc`
    }
    else if(order == "1"){
      path = `sort_by=${item}&sort_order=desc`
    }
    
    // if (this.state.searchBrandName !== "") {
    //   path = `brand_name=${this.state.searchBrandName}&Sort_by=${event.target.value}`;
    // } else {
    //   path = `Sort_by=${event.target.value}`
    // }
    this.getBrandsByFilters(path)
  }
  getBrandsByFilters = (path: any, brandGroupId? :any) => {
    this.setState({loading : true})
    // this.setState({tableBrandData:[]})
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_groups?page=1&per_page=2000&brand_group_id=${brandGroupId}` + `&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false,
    });
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen1: true,
    });
  };
  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true,
    });
  };
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen1: false,
    });
  };
  setData() {
    console.log("hello");
    const sideNavData = {
      sideNavData: [
        {
          name: "photoproduction",
        },
        {
          name: "CGI",
        },
      ],
    };
    localStorage.setItem("navData", JSON.stringify(sideNavData));
    // this.props.history.push("/Home/Projectportfolio")
    // navigator.navigate("/Home/Projectportfolio")
    window.location.href = "/Home/Projectportfolio";
  }
  imageView(name: any, url: any) {
    this.setState({ openImageURL: { "name": name, "url": url } }, () => {
      this.setState({ openImage: true })
    })
  }



  progressPercentage = (e?: any, value?: any) => {
    console.log(e, value, "value");
    this.setState({
      percentage: value,
    });
  };
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true,
    });
  }
  navigateToProjects(e?: any) {
    this.props.navigation.navigate("home/projectportfolio");
  }
  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
    });
    // if (item == "inviteTeamMember") {
    //   console.log("true");
    //   this.setState({
    //     // anchorEl: null,
    //     inviteTeamMembersDialog: true,
    //     //inviteteammembersId:item
    //   });
    // }
    if (item == "assetsProperties") {
      this.setState({
        // anchorEl: null,
        setFolder: true,
        //inviteteammembersId:item
      });
    }
    if (item == 'download') {
      console.log("this.selectedAsset", this.state.selectedAsset);

      this.download(this.state.selectedAsset)
    }
    if (item == 'sharableLink') {
      this.setState({ openLink: true })
    }

    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };

  download(e: any) {
    // console.log("file for ", e);
    // fetch(e?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url, {
    //   method: "GET",
    //   // headers: {}
    // })
    //   .then(response => {
    //     response.arrayBuffer().then(function (buffer) {
    //       const url = window.URL.createObjectURL(new Blob([buffer]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", e?.attributes?.name); //or any other extension
    //       document.body.appendChild(link);
    //       link.click();
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    var url = `${currentUrl
    }/bx_block_categories_sub_categories/media_asset_files/file_downloads?media_asset_file_id=${e?.id}&token=${localStorage.getItem("token")}`;
  var anchor = document.createElement("a");
  //console.log("Url", url)
  anchor.setAttribute("href", url);
  // anchor.setAttribute("download", url);
  anchor.click();
  }


  handleClickCreateNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: true,
    });
  };

  handleClickCancelNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
    });
  };

  handleClickStartBriefing = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddBriefingModel: true,
    });
  };

  handleClickStopBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickChangeBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickSaveBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };

  handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ category: event.target.value as string });
  };

  handleChangeAssetType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ assetType: event.target.value as string });
  };

  handleClickStartRequestQuote = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddRequestQuoteModel: true,
    });
  };

  handleClickStopRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickChangeRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickSaveRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handlePageChange = ()=>{

  }
getAssetCategory =()=>{
  const userDetails = localStorage.getItem("userInfo")
  if(userDetails){
    var userId = JSON.parse(userDetails).brand_id
    console.log(userId,"userId")

  }
  const header = {
    
    token: localStorage.getItem("token")
     
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAssetCategoryApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    // configJSON.AssetCategoryAPIEndPoint
  
    // `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
    `bx_block_categories_sub_categories/brands/brand_media_asset_listing?per_page=50`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;

}
getImages(data?: any){
  console.log(data, "gg")
  data.forEach(async (element: any) => {
    var value;
    value = element.id
    this.getMediaAssetImages(value)


  })
}

searchAssets(){
  this.setState({loading2 : true})
  const userDetails = localStorage.getItem("userInfo")
  if(userDetails){
    var userId = JSON.parse(userDetails).brand_id
    console.log(userId,"userId")

  }
  const header = {
    
    token: localStorage.getItem("token")
     
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAssetCategorySearchApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    // configJSON.AssetCategoryAPIEndPoint
    // `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
   // `bx_block_categories_sub_categories/brands/brand_media_asset_listing?per_page=50&brand_name=${this.state.search}`
  `bx_block_categories_sub_categories/media_assets/search_media_assets?page=1&per_page=500&name=${this.state.search}`
   );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

getMediaAssetImages(value?:any){
  const header = {

    token: localStorage.getItem("token")

  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getMediaAssetAccountApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),

    // `bx_block_categories_sub_categories/media_asset_accounts?type=brand&id=${this.state.mediaAssetID}`
   `bx_block_categories_sub_categories/media_asset_accounts?type=media_asset&id=${value}`
  // `bx_block_categories_sub_categories/brands/brand_media_asset_listing`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
console.log(message,"me")
if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryApiCallId){
 if(message?.properties?.RestAPIResponceSuccessMessage?.brands?.data){
   this.setState({assetData :  message?.properties?.RestAPIResponceSuccessMessage?.brands?.data})
   console.log("assetData1", this.state.assetData);
   
 }
}
if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandListingByFiltersId){
    var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if(responseJson){
    this.setState({
        assetData : responseJson.brands?.brands?.data,
        brandGroupData : responseJson.brand_group?.data
    })
    console.log("assetData1", this.state.assetData);
    this.setState({ loading: false })
  }
 }
if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategorySearchApiCallId){
  if(message?.properties?.RestAPIResponceSuccessMessage?.data){
    this.setState({searchedData :  message?.properties?.RestAPIResponceSuccessMessage?.data,
    loading2 : false
  })
    console.log("assetData123", this.state.searchedData);
    //console.log("searching data" , message?.properties?.RestAPIResponceSuccessMessage?.data );
    
    
  }
}
// if (message?.properties?.RestAPIResponceSuccessMessage?.accounts?.data) {
//   this.setState({
//     imageData:[ ...this.state.imageData,message?.properties?.RestAPIResponceSuccessMessage?.accounts?.data]
   


//   }, async () => {
   
//     await this.state.assetData.map((item:any,id:any)=>{
//       let len=this.state.imageData.length
//       if(len)
//                     item.internalFiles=this.state.imageData[id]
//                     return item;
//     })
//     console.log(this.state.assetData,"data")
    
//     // this.setMediaAssetID(this.state.assetData)
//   })
 
// }
// if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
//   this.setState({
//     assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
   


//   }, () => {
//     console.log(this.state.assetData)
//     this.getImages(this.state.assetData)
//     // this.setMediaAssetID(this.state.assetData)
//   })
 
// }



    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }
async componentDidMount(){
  this.loadUserDetailsAndRoles()
  // this.getAssetCategory()
  let brandGroup = localStorage.getItem("brandGroupSelected");
  console.log("brandGroupbrandGroup",brandGroup);
  
  this.getBrandsByFilters("sort_by=name&sort_order=asc" , brandGroup )
}


  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area End
}
