import React, { Component } from 'react'
interface Props{
   
    color:any;
    keyword:any;
}
export class Svg extends Component<Props> {
   
    render() {
        
        let {keyword,color} = this.props
        console.log("color",this.props)
        return (
            <>
       
           { keyword=== "projectSVg"&&

          <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="18" viewBox="0 0 20 18">
    <defs>
        <path id="uguh3mvfta" d="M0.5 0L19.5 0 19.5 18 0.5 18z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-26 -235) translate(-1) translate(27 235)">
                    <mask id="ypfmb3zv6b" fill="#fff">
                        <use href="#uguh3mvfta"/>
                    </mask>
                    <path fill="#1A76B0" stroke={color} stroke-width=".5" d="M10 11.913L2.513 9 7.6 7.02l2.297.894c.066.026.138.026.204 0l2.297-.893L17.487 9 10 11.913zm7.487 1.62L10 16.446l-7.487-2.913L7.6 11.554l2.297.894c.066.025.138.025.204 0l2.297-.894 5.088 1.98zM2.513 4.467L10 1.554l7.487 2.913L10 7.379 2.513 4.467zM18.5 9c0-.11-.068-.207-.173-.248l-5.19-2.019 5.19-2.019c.141-.054.21-.21.153-.346-.027-.068-.083-.122-.153-.149l-8.225-3.2c-.066-.025-.138-.025-.204 0l-8.225 3.2c-.141.055-.21.21-.153.347.027.067.083.121.153.148l5.19 2.02-5.19 2.018c-.141.055-.21.21-.153.347.027.068.083.122.153.149l5.19 2.019-5.19 2.019c-.141.054-.21.21-.153.346.027.068.083.122.153.149l8.225 3.2c.066.025.138.025.204 0l8.225-3.2c.141-.055.21-.21.153-.347-.027-.067-.083-.121-.153-.148l-5.19-2.02 5.19-2.018c.105-.04.173-.139.173-.248h0z" mask="url(#ypfmb3zv6b)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
 } 

{keyword=== "MediaAssetLibrarySVg"&&

<svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13">
    <g fill="none" fill-rule="evenodd">
        <g fill="#1A76B0" stroke={color} stroke-width=".5">
            <g>
                <g>
                    <path d="M13.152 0l.178.001h.33c1.894.005 2.136.018 2.185.048.048.026.1.083.118.122.02.041.031 1.863.035 4.055l.001.635v2.275l-.001.635c-.004 2.191-.016 4.013-.035 4.055-.017.039-.07.095-.118.121-.05.031-.305.044-2.342.05h-.928l-.211.001-.936.001h-6.06l-.296-.001h-.567c-2.753-.006-4.27-.016-4.306-.029-.044-.013-.105-.07-.14-.126l-.01-.018-.006-.014c-.028-.077-.039-.299-.042-1.608v-3.61-.625l.001-.319.003-1.908.001-.449C.012.432.02.197.061.135L.068.127h0C.106.084.159.04.195.027.218.017 2.233.01 5.059.005h.556l.87-.002h.598l.305-.001L8.006 0h.366zm-2.587 5.092c-.013 0-1.123 1.098-2.475 2.439-1.743 1.733-2.484 2.448-2.55 2.465-.048.009-.132.009-.185-.004-.06-.017-.464-.396-1.272-1.198h0L2.898 7.618.668 9.831v1.502h14.659V9.805l-2.374-2.356c-1.308-1.298-2.384-2.357-2.388-2.357zm4.762-4.42H.669v8.196l1-.993c.549-.54 1.032-1.001 1.067-1.019.04-.022.118-.035.184-.035.11 0 .158.04 1.308 1.18.653.65 1.207 1.18 1.22 1.18.017 0 1.136-1.097 2.497-2.447h0l2.462-2.443h.307l2.304 2.286 2.304 2.287V4.766h.005V.672zM4.394 2.282c.574-.126 1.191.121 1.532.608.328.466.363 1.051.095 1.57-.098.19-.375.48-.56.59-.205.12-.436.187-.689.198-.467.02-.87-.144-1.177-.475-.161-.173-.245-.314-.323-.535-.052-.15-.06-.214-.069-.43-.008-.207-.003-.279.032-.414.144-.567.585-.99 1.16-1.111zm.508.492c-.23-.05-.424-.025-.64.087-.236.114-.414.31-.51.551-.052.128-.068.4-.035.551.076.367.402.688.777.766.614.125 1.202-.342 1.205-.958.003-.21-.024-.318-.112-.484-.137-.26-.394-.454-.685-.513z" transform="translate(-28 -288) translate(-1) translate(29 288)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
}

{keyword=== "MediaAssetTypePDF"&&
<svg width="17" height="13">    
    <path fill={color} d="M 15 0.2 H 4.7 c -1.9 0 -1.9 -0.1 -1.8 1.4 v 8 c -0.1 1.2 0 1.4 1.8 1.4 h 10.3 c 1.8 0 1.8 -0.1 1.8 -1.4 V 1.5 c -0.1 -1.4 0 -1.4 -1.8 -1.4 z m 1 10.1 H 3.7 V 0.8 h 12.4 v 9.3 z M 1.1 2.9 H 0.3 v 9.4 c -0.1 0.5 0 0.5 1.3 0.5 h 12 v -0.5 H 0.8 V 2.9 z m 10.3 4 V 4.9 c 0 -0.4 -0.4 -0.6 -0.9 -0.6 h -1.8 v 3.3 h 1.8 c 0.5 0 0.9 -0.4 0.9 -0.6 z m -1.8 -2 h 0.9 v 2 h -0.9 V 4.9 z m 3.4 1.4 h 0.9 v -0.6 h -0.9 V 4.9 h 0.9 V 4.1 h -1.8 v 3.3 h 0.9 z m -6.9 0 h 0.9 c 0.5 0 0.9 -0.4 0.9 -0.6 V 4.9 c 0 -0.4 -0.4 -0.6 -0.9 -0.6 H 5.6 v 3.3 h 0.9 v -1.4 z m 0 -1.4 h 0.9 v 0.6 h -0.9 V 4.9 z"></path>
</svg>
}

{keyword === "MediaAssetFile" &&
<svg width="17" height="13">
    <path fill={color} d="M 11.7 0.1 H 3.6 c -0.7 0 -1.3 0.5 -1.3 1.2 v 8.2 h 1.3 V 1.3 h 8.1 V 0.1 z m -0.7 2.3 H 6.3 c -0.7 0 -1.3 0.5 -1.3 1.2 L 5 11.8 c 0 0.6 0.6 1.2 1.3 1.2 H 13.7 c 0.7 0 1.3 -0.5 1.3 -1.2 V 6 l -4 -3.5 z M 6.3 11.8 V 3.6 h 4 v 2.9 h 3.4 v 5.3 H 6.3 z"></path>
</svg>
} 

{keyword=== "teamsSVg" &&
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
    <defs>
        <path id="5qdvqn4pja" d="M0 0.5L22 0.5 22 13.5 0 13.5z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-25 -339) translate(-1) translate(26 339)">
                    <mask id="wn9rtvnccb" fill="#fff">
                        <use href="#5qdvqn4pja"/>
                    </mask>
                    <path fill="#1A76B0" stroke={color} stroke-width=".5" d="M15.986 9.68c-.217-.804-.633-1.523-1.199-2.118.586-.487 1.346-.78 2.177-.788h.047c1.73.011 3.154 1.284 3.332 2.906h-4.357zm-.47 1.516c0 .78-.662 1.417-1.473 1.417H7.91c-.81 0-1.472-.636-1.472-1.417v-.259c0-2.365 1.963-4.3 4.405-4.369.043.004.09.004.134.004.044 0 .091 0 .135-.004 2.441.069 4.404 2.004 4.404 4.369v.259zM5.965 9.68H1.641c.178-1.622 1.603-2.895 3.332-2.906h.048c.823.004 1.575.297 2.156.773-.57.594-.993 1.326-1.21 2.133h0zm-2.62-5.104c0-.876.74-1.588 1.65-1.588.911 0 1.651.712 1.651 1.588 0 .865-.724 1.57-1.619 1.589h-.063c-.894-.016-1.618-.72-1.618-1.589h0zm5.367-.792c0-1.2 1.013-2.175 2.26-2.175 1.246 0 2.259.975 2.259 2.175 0 1.158-.946 2.106-2.13 2.171h-.26c-1.184-.065-2.13-1.013-2.13-2.17h0zm6.624.792c0-.876.74-1.588 1.65-1.588.91 0 1.65.712 1.65 1.588 0 .865-.724 1.57-1.618 1.589h-.063c-.899-.016-1.619-.72-1.619-1.589h0zm2.96 1.802c.586-.396.974-1.055.974-1.798 0-1.215-1.021-2.197-2.284-2.197-1.262 0-2.283.982-2.283 2.197 0 .743.384 1.402.973 1.798-.502.168-.961.427-1.349.762-.534-.438-1.163-.773-1.852-.972.835-.487 1.397-1.375 1.397-2.384C13.873 2.245 12.579 1 10.98 1 9.381 1 8.087 2.25 8.087 3.784c0 1.01.558 1.897 1.397 2.384-.68.199-1.302.53-1.832.96-.388-.327-.839-.582-1.333-.746.585-.396.973-1.055.973-1.798 0-1.215-1.02-2.198-2.283-2.198s-2.284.983-2.284 2.198c0 .743.384 1.402.974 1.798C2.128 6.904 1 8.339 1 10.027v.251c0 .008.008.015.016.015h4.84c-.028.21-.044.427-.044.644v.259c0 1.12.942 2.026 2.105 2.026h6.134c1.163 0 2.105-.906 2.105-2.026v-.259c0-.217-.016-.434-.043-.644h4.871c.008 0 .016-.007.016-.015v-.251c-.008-1.691-1.132-3.127-2.703-3.65h0z" mask="url(#wn9rtvnccb)"/>
                </g>
            </g>
        </g>
    </g>
</svg>

}
{keyword=== "approvalRequestsSVg"&& 
<svg   width="16" height="16" viewBox="0 0 16 16">
    <defs>
        <path id="ddxwaql7fa" d="M0 0L16 0 16 16 0 16z"/>
        <path id="gsrnlvo3yc" d="M0 16L16 16 16 0 0 0z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g transform="translate(-28 -392) translate(-1) translate(29 392) matrix(-1 0 0 1 16 0)">
                        <g>
                            <mask id="5tif5o30db" fill="#fff">
                                <use href="#ddxwaql7fa"/>
                            </mask>
                            <path fill={color} d="M1.361.89H14.64c.269 0 .472.202.472.47v13.28c0 .27-.203.47-.472.47H1.361c-.268 0-.472-.2-.472-.47V1.36c0-.268.204-.47.472-.47zm0-.89C.615 0 .001.615.001 1.36v13.28C0 15.387.614 16 1.36 16H14.64c.745 0 1.361-.614 1.361-1.36V1.36C16 .616 15.384 0 14.64 0H1.36z" mask="url(#5tif5o30db)"/>
                        </g>
                        <mask id="65lvtm2mud" fill="#fff">
                            <use  href="#gsrnlvo3yc"/>
                        </mask>
                        <path fill={color} d="M7.857 5h4.286V3H7.857v2zM7.43 2C7.192 2 7 2.224 7 2.5v3c0 .276.192.5.429.5h5.142c.237 0 .429-.224.429-.5v-3c0-.276-.192-.5-.429-.5H7.43zM7.857 10h4.286V8H7.857v2zM7.43 7C7.192 7 7 7.224 7 7.5v3c0 .276.192.5.429.5h5.142c.237 0 .429-.224.429-.5v-3c0-.276-.192-.5-.429-.5H7.43zM3 4L6 4 6 3 3 3zM3 9L6 9 6 8 3 8zM2 13L13 13 13 12 2 12z" mask="url(#65lvtm2mud)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

}
{keyword=== "recentSvg"&& 
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
    <g fill="none" fill-rule="evenodd">
        <g fill="#1A76B0" stroke={color} stroke-width=".5">
            <g>
                <g>
                    <path d="M8 0c4.416.005 7.995 3.583 8 7.997.003 4.417-3.576 8-7.995 8.003C3.587 16.003.003 12.424 0 8.008c-.001-1.902.675-3.74 1.907-5.188H.891c-.26 0-.47-.211-.47-.47 0-.26.21-.471.47-.471l2.152-.029h.135c.117.034.216.111.276.217.01.018.018.037.025.056v.11l.21 2.142c.057.255-.105.508-.36.565l-.035.006h0-.047c-.241 0-.443-.184-.467-.423l-.113-1.145c-2.55 2.945-2.23 7.4.716 9.949 2.946 2.55 7.402 2.229 9.953-.716 2.55-2.945 2.23-7.4-.717-9.95C11.336 1.551 9.697.941 8 .941c-.26 0-.47-.21-.47-.47S7.74 0 8 0zm0 2.936c.26 0 .47.21.47.47h0v4.306l2.874 1.49c.152.081.247.238.25.41.004.26-.205.476-.466.48-.075 0-.15-.019-.217-.053h0L7.752 8.402H7.73l-.04-.028-.035-.028-.031-.032-.032-.034-.025-.038c-.006-.014-.013-.028-.022-.04V8.16h0l-.016-.044v-.044c.007-.016 0-.025 0-.05h0V3.406c0-.26.21-.47.471-.47z" transform="translate(-27 -233) translate(-1) translate(29 233)"/>
                </g>
            </g>
        </g>
    </g>
</svg>

}
{keyword=== "favouriteSvg"&& 
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
  <g fill="none" fill-rule="evenodd">
      <g fill="#1A76B0" stroke={color} stroke-width=".5">
          <g>
              <path d="M14.997 6.568l-2.888 2.93c-.279.28-.407.687-.342 1.085l.682 4.15c.021.134-.065.26-.193.282-.05.008-.102 0-.147-.025l-3.567-1.953c-.34-.187-.748-.187-1.089 0L3.886 14.99c-.114.063-.256.018-.317-.101-.025-.05-.033-.107-.023-.163l.682-4.15c.065-.397-.06-.802-.337-1.082l-2.888-2.93C.91 6.47.906 6.315.996 6.218c.036-.039.084-.064.135-.072l3.982-.603c.382-.058.713-.308.883-.67l1.79-3.762c.057-.12.198-.17.314-.11.045.024.082.063.105.11l1.785 3.77c.17.362.5.614.883.672l3.981.603c.128.019.217.143.198.276-.007.054-.031.104-.069.141l.014-.005zm0-1.382l-3.991-.61c-.076-.012-.142-.062-.176-.134L9.046.669C8.846.257 8.439 0 7.996 0c-.443-.002-.849.257-1.049.67L5.162 4.448c-.033.072-.1.122-.175.134l-3.982.603c-.64.096-1.085.715-.993 1.382.036.263.154.507.335.694l2.888 2.93c.056.056.08.137.068.217l-.694 4.151c-.108.665.32 1.296.958 1.41.253.044.513.001.74-.123l3.57-1.953c.068-.038.15-.038.218 0l3.57 1.953c.565.327 1.278.113 1.59-.477.132-.247.177-.533.128-.81l-.702-4.14c-.013-.08.013-.16.068-.216l2.897-2.942c.463-.47.473-1.243.022-1.726-.18-.193-.416-.318-.67-.357v.007z" transform="translate(-28 -281) translate(-1) translate(29 282)"/>
          </g>
      </g>
  </g>
</svg>

}

{keyword === "folderSvg"&&

<svg height="17"      viewBox="0  -57 512.00003 512" width="16" xmlns="http://www.w3.org/2000/svg">
<g fill="none" fill-rule="evenodd">
    <g fill={color} stroke={color} stroke-width=".5">
    <g>
    <path d="m506.039062 180.492188c-7.773437-12.554688-21.523437-20.046876-36.78125-20.046876h-38.085937v-69.863281c0-16.863281-14.226563-30.578125-31.710937-30.578125h-186.441407c-.273437 0-.457031-.078125-.53125-.125l-33.367187-46.667968c-5.917969-8.273438-15.675782-13.210938-26.105469-13.210938h-121.304687c-17.488282 0-31.710938 13.71875-31.710938 30.578125v335.148437c0 17.257813 14.570312 31.300782 32.484375 31.300782h377.363281c5.878906 0 10.957032-3.394532 13.414063-8.320313l.011719.003907 84.5625-169.839844c6.132812-12.308594 5.457031-26.65625-1.796876-38.378906zm-474.328124-150.492188h121.304687c.898437 0 1.507813.394531 1.699219.664062l33.417968 46.734376c5.640626 7.890624 14.945313 12.605468 24.886719 12.605468h186.441407c1.046874 0 1.578124.492188 1.710937.667969v69.773437h-271.472656c-16.835938 0-32.148438 9.488282-39.011719 24.175782l-60.6875 129.871094v-283.824219c.132812-.175781.664062-.667969 1.710938-.667969zm449.269531 175.5-80.421875 161.523438h-361.992188l79.300782-169.699219c1.921874-4.113281 6.679687-6.878907 11.832031-6.878907h339.558593c4.792969 0 9.007813 2.183594 11.273438 5.839844 1.273438 2.058594 2.382812 5.328125.449219 9.214844zm0 0"/>
</g>
    </g>
    </g>

</svg>
}
            </>
        )
    }
}

export default Svg
