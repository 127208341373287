import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Breadcrumbs,
  List,
  ListItemText,
  Button,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  Input,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import MuiListItem from "@material-ui/core/ListItem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation, Trans } from "react-i18next";

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  anchorEl2: any;
  showNewCategory: any;
  assetCategoriesList: any;
  messageError: any;
  newCategoryName: string;
  assetTypesList: any;
  selectedAssetCategory: any;
  showNewType: any;
  newTypeName: any;
  selectedAssetCategoryId: any;
  selectedAssestTypeId: any;
  openDialog: any;
  brandsList: any;
  isBrandSelected: any;
  taggedBrandList: any;
  selectedToEdit: string;
  openEditDialog: boolean;
  selectedName: string;
  editButton: boolean;
  setassestCategoriesPage:any;
  nextassetCategoriesPage:boolean;
  totalassetCategoriesPage:any;
  setassestTypePage:any;
  nextassetTypePage:boolean;
  totalassetTypePage:any;
  editDeleteAssestCategoriesButton:boolean;
  editDeleteAssestTypeButton:boolean;
  duplicateFlag : any
  tagBrandList : any
  openCopyModel : any
  selectedAssetsForCopy : any
  copyAssetCategories : any
}
interface SS {
  id: any;
}

const styles = {
  inputRoot: {
    width: "449px",
    height: "40px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#1a76b0",
      color: "white",
      opacity: 1,
    },
    "&:hover": {
      backgroundColor: "#1a76b0",
      color: "white",
    },
    borderRadius: "4px",
    background: "white",
    height: "56px",
    color: "#5b6b96",
  } as const,
  selected: {},
})(MuiListItem);

class AssetCategories extends BlockComponent<Props, S, SS> {
  getAssetCategoriesApiCallId: any;
  getCopyAssetCategoriesApiCallId : any
  copyAssetTypeApiCallId : any
  createAssetCategoryApiCallId: any;
  getAssetTypesApiCallId: any;
  createAssetTypeApiCallId: any;
  deleteAssetCategoryApiCallId: any;
  deleteAssetTypeApiCallId: any;
  getBrandsListApiCallId: any;
  tagBrandsApiCallId: any;
  getBrandsWithAssetTypeApiCallId: any;
  removeBrandFromAssetTypeApiCallId: any;
  editAssetCategoryApiCallId: any;
  editAssetTypeApiCallId: any;
  getBriefingId: any;
  duplicateAssetTypeApiCallId : any
  duplicateAssetType2ApiCallId : any

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      showNewCategory: false,
      assetCategoriesList: [],
      messageError: "",
      newCategoryName: "",
      assetTypesList: [],
      selectedAssetCategory: "",
      showNewType: false,
      newTypeName: "",
      selectedAssetCategoryId: "",
      selectedAssestTypeId: "",
      openDialog: false,
      brandsList: [],
      taggedBrandList: [],
      isBrandSelected: false,
      selectedToEdit: "",
      openEditDialog: false,
      selectedName: "",
      editButton: false,
      setassestCategoriesPage:1,
      nextassetCategoriesPage:false,
      totalassetCategoriesPage:1,
      setassestTypePage:1,
      nextassetTypePage:false,
      totalassetTypePage:1,
      editDeleteAssestCategoriesButton:false,
      editDeleteAssestTypeButton:false,
      duplicateFlag : false,
      tagBrandList : [],
      openCopyModel : null,
      copyAssetCategories : [],
      selectedAssetsForCopy : []
    };
  }

  async componentDidMount() {
    await this.getAssetCategories();
  }

  getAssetCategories = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoriesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_categories?page=${this.state.setassestCategoriesPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getCopyAssetCategories = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCopyAssetCategoriesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_categories?page=1&per_page=5000`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAssetTypes = (id: any, name: any) => {
    this.setState({
      selectedAssetCategory: name,
      selectedAssetCategoryId: id,
      
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetTypesApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_types?asset_category_id=${id}&&page=${this.state.setassestTypePage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createAssetCategory = () => {
    this.setState({
      editDeleteAssestCategoriesButton:true,
      setassestCategoriesPage:1
    })
    const data = {
      name: this.state.newCategoryName,
    };
    console.log(this.state.newCategoryName);
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAssetCategoryApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/asset_categories"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBrandsWithAssetType = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsWithAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_asset_types?page=1&per_page=1000&asset_type_id=${this.state.selectedAssestTypeId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAssetCategoriesApiCallId) {
        if (responseJson.asset_categories) {
          if(this.state.editDeleteAssestCategoriesButton){
            this.setState({
              assetCategoriesList:responseJson.asset_categories.data,
              nextassetCategoriesPage:responseJson.next_page,
              totalassetCategoriesPage:responseJson.total_pages,
              editDeleteAssestCategoriesButton:false
            });
          }
          else
          {this.setState({
            assetCategoriesList:[...this.state.assetCategoriesList, ...responseJson.asset_categories.data],
            nextassetCategoriesPage:responseJson.next_page,
            totalassetCategoriesPage:responseJson.total_pages
          });}
        }
      }
      else if( apiRequestCallId === this.copyAssetTypeApiCallId) {
          if(responseJson) {
            this.setState({
              selectedAssetsForCopy : [],
              openCopyModel : null,
            })
            toast.success("Asset Type Copied Successfully!")
          }
      }

      else if (apiRequestCallId === this.getCopyAssetCategoriesApiCallId) {
        if (responseJson.asset_categories) {       
            this.setState({
              copyAssetCategories:responseJson.asset_categories.data.filter((el : any)=> el.id != this.state.selectedAssetCategoryId)
            });
        }
      }
      
      else if (apiRequestCallId === this.createAssetCategoryApiCallId) {
        this.setState({
          newCategoryName: "",
          showNewCategory: false,
        });
        this.getAssetCategories();
      } else if (apiRequestCallId === this.getAssetTypesApiCallId) {
        if (responseJson.asset_types) {
          if(this.state.editDeleteAssestTypeButton || this.state.duplicateFlag)
          {this.setState({
            assetTypesList:responseJson.asset_types.data,
            nextassetTypePage:responseJson.next_page,
            totalassetTypePage:responseJson.total_pages,
            editDeleteAssestTypeButton:false,
            duplicateFlag : false
          });}
          else
          {this.setState({
            assetTypesList:[...this.state.assetTypesList, ...responseJson.asset_types.data],
            nextassetTypePage:responseJson.next_page,
            totalassetTypePage:responseJson.total_pages
          });}
        }
      } else if (apiRequestCallId === this.createAssetTypeApiCallId) {
        this.setState({
          newTypeName: "",
          showNewType: false,
        });
        this.getAssetTypes(
          this.state.selectedAssetCategoryId,
          this.state.selectedAssetCategory
        );
      } else if (apiRequestCallId === this.deleteAssetCategoryApiCallId) {
        if (responseJson.message) {
          
            toast.success("Record successfully deleted");
            this.setState({
              anchorEl: "",
              selectedAssetCategoryId: "",
            });
            this.setState({assetTypesList : []})
            this.getAssetCategories();
          
        }
      } else if (apiRequestCallId === this.getBrandsListApiCallId) {
        if (responseJson.brands.data) {
          if (responseJson.brands.data !== null) {
            var tempArr = [] as any;
            
            if (this.state.taggedBrandList.length !== 0) {
              responseJson.brands.data.map((obj: any) => {
                var i = this.state.taggedBrandList.findIndex(
                  (x: any) => x.id == obj.id
                );
                if (i <= -1) {
                  tempArr.push({
                    id: obj.id,
                    name: obj.attributes.name,
                    checked: false,
                    isTagged: false,
                  });
                } else {
                  tempArr.push({
                    id: obj.id,
                    name: obj.attributes.name,
                    checked: true,
                    isTagged: true,
                  });
                }
              });
            } else {
              responseJson.brands.data.map((_data: any) => {
                tempArr.push({
                  id: _data.id,
                  name: _data.attributes.name,
                  checked: false,
                  isTagged: false,
                });
              });
            }

            this.setState({
              brandsList: tempArr,
            });
          }
        }
      } else if (apiRequestCallId === this.deleteAssetTypeApiCallId) {
        if (responseJson.message) {
          if (responseJson.message === "deleted") {
            this.setState({
              anchorEl2: "",
              selectedAssestTypeId: "",
            });
          }
          this.getAssetTypes(
            this.state.selectedAssetCategoryId,
            this.state.selectedAssetCategory
          );
        }

      } 
      else if(apiRequestCallId ===this.duplicateAssetTypeApiCallId){
       
        
        if (responseJson) {      
          if (responseJson) {
            this.getAssetCategories();
            this.setState({   
              anchorEl2: "",
              selectedAssestTypeId: "",
            });
          }
         
          this.getAssetTypes(
            this.state.selectedAssetCategoryId,
            this.state.selectedAssetCategory
          );
        }

      }
      else if(apiRequestCallId === this.duplicateAssetType2ApiCallId){
        if (responseJson) {      
          if (responseJson) {
            // this.();
            this.setState({   
              anchorEl2: "",
              selectedAssestTypeId: "",
            });
          }
         
          this.getAssetTypes(
            this.state.selectedAssetCategoryId,
            this.state.selectedAssetCategory
          );
        }
      }
      else if (apiRequestCallId === this.tagBrandsApiCallId) {
        toast.success("Brands tagged successfully.");
        this.setState({tagBrandList:[]})
        this.handleCloseTagBrandDialog()
        this.handleSecondIconClose()
      } else if (apiRequestCallId === this.getBrandsWithAssetTypeApiCallId) {
        if (responseJson.brand_asset_types) {
          if (responseJson.brand_asset_types.data !== null) {
            var tempArr = [] as any;
            var tempArr2 = [] as any;
            responseJson.brand_asset_types.data.map((_data: any) => {
              if (_data.attributes.brand) {
                if (_data.attributes.brand !== null) {
                  tempArr2.push(_data.attributes.brand_id.toString())
                  tempArr.push({
                    id: _data.attributes.brand_id.toString(),
                    name: _data.attributes
                      ? _data.attributes.brand
                        ? _data.attributes.brand.data
                          ? _data.attributes.brand.data.attributes
                            ? _data.attributes.brand.data.attributes.name
                            : ""
                          : ""
                        : ""
                      : "",
                    checked: true,
                  });
                }
              }
            });

            this.setState(
              {
                taggedBrandList: tempArr,
                tagBrandList: tempArr2
              },
              () => {
               
              }
            );
          }
        }
      } else if (apiRequestCallId === this.removeBrandFromAssetTypeApiCallId) {
        console.log(responseJson);
        this.getBrandsWithAssetType();
      } else if (apiRequestCallId === this.editAssetCategoryApiCallId) {
        toast.success("Asset Category updated successfully");
        this.getAssetCategories();
        this.setState({
          selectedName: "",
          selectedToEdit: "",
          openEditDialog: false,
          anchorEl: null,
        });
      }
      else if (apiRequestCallId === this.getBriefingId) {
        if (responseJson.templates.data.length !== 0) {
          this.setState({ editButton: true });
        }
        else {
          this.setState({ editButton: false });
        }
        console.log(responseJson.templates.data)
      } else if (apiRequestCallId === this.editAssetTypeApiCallId) {
        toast.success("Asset Type updated successfully.");
        this.getAssetTypes(
          this.state.selectedAssetCategoryId,
          this.state.selectedAssetCategory
        );
        this.setState({
          selectedName: "",
          selectedToEdit: "",
          openEditDialog: false,
          anchorEl2: null,
        });
      }
    }
  }
  removeAllBrandFromAssetType = (id: any) => {
    

   

    const httpBody = {
      data: {
        asset_type_id: this.state.selectedAssestTypeId,
        brand_ids: id,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBrandFromAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/brand_asset_types/delete_brand_asset_types"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  removeBrandFromAssetType = (id: any) => {
    let selectedBrand = [] as any;

    selectedBrand.push(id.toString());

    const httpBody = {
      data: {
        asset_type_id: this.state.selectedAssestTypeId,
        brand_ids: selectedBrand,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBrandFromAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/brand_asset_types/delete_brand_asset_types"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBriefing = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBriefingId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates?asset_type_id=${id}&briefing_empty_form=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleIconClick = (event: any, id: any, name: any, type: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedAssetCategoryId: id,
      selectedName: name,
      selectedToEdit: type,
    });
  };
  handleSecondIconClick = (event: any, id: any, name: any, type: any) => {
    this.setState({
      anchorEl2: event.currentTarget,
      selectedAssestTypeId: id,
      selectedName: name,
      selectedToEdit: type,
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleSecondIconClose = () => {
    this.setState({
      anchorEl2: null,
    });
  };

  handleNewCategoryOpen = () => {
    this.setState({
      showNewCategory: true,
    });
  };
  handleNewCategoryClose = () => {
    this.setState({
      showNewCategory: false,
      newCategoryName: "",
    });
  };

  handleNewCategoryChange = (event: any) => {
    this.setState({
      newCategoryName: event.target.value,
    });
  };

  handleNewTypeOpen = () => {
    this.setState({
      showNewType: true,
    });
  };
  handleNewTypeClose = () => {
    this.setState({
      showNewType: false,
    });
  };
  handleNewTypeChange = (event: any) => {
    this.setState({
      newTypeName: event.target.value,
    });
  };

  createAssetType = () => {
    this.setState({
      editDeleteAssestTypeButton:true,
      setassestTypePage:1
    })
    const data = {
      name: this.state.newTypeName,
      asset_category_id: this.state.selectedAssetCategoryId,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/asset_types"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAssetCategoryDelete = () => {
    console.log("Clicked");
    this.setState({
      editDeleteAssestCategoriesButton:true,
      setassestCategoriesPage:1
    })
    if (this.state.selectedAssetCategoryId !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAssetCategoryApiCallId = requestMessage.messageId;
      const header = {
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_categories/${this.state.selectedAssetCategoryId
        }`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleCloseTagBrandDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleOpenTagBrand = () => {
    this.getBrandsList();
    this.getBrandsWithAssetType();
    this.setState({
      openDialog: true,
    });
   
  };

  getBrandsList = () => {
    if (this.state.selectedAssestTypeId !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getBrandsListApiCallId = requestMessage.messageId;
      const header = {
        // "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands?per_page=3000&page=1`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  handleAssetTypeDuplicate = ()=>{
    // this.setState({
    //   editDeleteAssestTypeButton:true,
    //   setassestTypePage:1
    // })
console.log("this.state.selectedAssetCategoryId",this.state.selectedAssetCategoryId);

    const httpBody = {
      data: {
        "asset_category_id": parseInt(this.state.selectedAssetCategoryId)
      },
    };
    if (this.state.selectedAssetCategoryId !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.duplicateAssetTypeApiCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),    
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_categories/asset_category_duplication`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  handleAssetType2Duplicate = ()=>{
    this.setState({
      duplicateFlag : true
    })
console.log("this.state.selectedAssetCategoryId",this.state.selectedAssestTypeId);


    const httpBody = {
      data: {
        "asset_type_id": parseInt(this.state.selectedAssestTypeId)
      },
    };
    if (this.state.selectedAssestTypeId !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.duplicateAssetType2ApiCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),    
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_types/asset_type_duplication`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleAssetTypeDelete = () => {
    this.setState({
      editDeleteAssestTypeButton:true,
      setassestTypePage:1
    })
    if (this.state.selectedAssestTypeId !== "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAssetTypeApiCallId = requestMessage.messageId;
      const header = {
        token: localStorage.getItem("token"),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/asset_types/${this.state.selectedAssestTypeId
        }`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  handleCopyAssetTypeCheckbox = (event:any, id : any)=>{
    var temp = this.state.selectedAssetsForCopy;
    if(this.state.selectedAssetsForCopy.includes(id)){
      const index = temp.indexOf(id);
      if (index > -1) {
        temp.splice(index, 1); 
        this.setState({selectedAssetsForCopy : temp})
      }
    }
    else {
      temp.push(id);
      this.setState({selectedAssetsForCopy : temp})
    }
    console.log("selcted Category", this.state.selectedAssetsForCopy);
    
  }

  handleBrandCheckbox = (event: any, id: any) => {
    var temp = this.state.tagBrandList;
    if(this.state.tagBrandList.includes(id)) {
     
      const index = temp.indexOf(id);
      if (index > -1) {
        temp.splice(index, 1); 
        this.setState({tagBrandList : temp})
      }
    }
    else {
      temp.push(id);
      this.setState({tagBrandList : temp})
    }
console.log("tagBrandList", this.state.tagBrandList);

    if (!event.target.checked) {
       this.removeBrandFromAssetType(id);
    } else {
      var brandListArray = this.state.brandsList;
      brandListArray.map((_data: any) => {
        if (_data.id === id) {
          _data.checked = event.target.checked;
        }
      });
      this.setState({
        brandsList: brandListArray,
      });
    }
  };
  handleBrandCheckboxSelectAll = (event: any) => {
    let brandListArray = this.state.brandsList;
    let brandListArray2 = this.state.brandsList.map((brand : any)=> brand.id);
    console.log("event.target.checked",event.target.checked);
    
    if(event.target.checked){

      this.setState({tagBrandList : [...brandListArray2]},()=>console.log("tagBrandList21", this.state.tagBrandList))
    }
    else {
      let brandListArray2 = this.state.brandsList.map((brand : any)=> brand.id);
      this.removeAllBrandFromAssetType(brandListArray2);
      this.setState({tagBrandList : []},()=>console.log("tagBrandList21", this.state.tagBrandList))

    }
    
    
    brandListArray.map((_data: any) => {
      _data.checked = event.target.checked;
    });
    if (event.target.checked) {
      this.setState({
        isBrandSelected: true,
      });
    } else {
      this.setState({
        isBrandSelected: false,
      });
    }
    this.setState({
      brandsList: brandListArray,
    });
  };

  copyAssetType = () => {
    const httpBody = {
      data: {
        asset_type_id: this.state.selectedAssestTypeId,
        asset_category_ids: this.state.selectedAssetsForCopy
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.copyAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/asset_types/copy_asset_type"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  tagBrandWithAssetType = () => {
    var selectedBrands = [] as any;
    this.state.brandsList.map((_data: any) => {
      if (_data.checked && !_data.isTagged) {
        selectedBrands.push(_data.id);
      }
    });
    const httpBody = {
      data: {
        asset_type_id: this.state.selectedAssestTypeId,
        brand_id: this.state.tagBrandList,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tagBrandsApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories_sub_categories/brand_asset_types"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseEditDialog = () => {
    this.setState({
      openEditDialog: false,
    });
  };
  handleEditName = (event: any) => {
    console.log(event.target.value);
    this.setState({ selectedName: event.target.value });
  };
  handleEditClick = () => {
    this.setState({
      openEditDialog: true,
    });
  };

  editAssetCategory = () => {
    this.setState({
      editDeleteAssestCategoriesButton:true,
      setassestCategoriesPage:1
    })
    const httpBody = {
      data: {
        name: this.state.selectedName,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editAssetCategoryApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_categories/${this.state.selectedAssetCategoryId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  editAssetType = () => {
    this.setState({
      editDeleteAssestTypeButton:true,
      setassestTypePage:1
    })
    const httpBody = {
      data: {
        name: this.state.selectedName,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editAssetTypeApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/asset_types/${this.state.selectedAssestTypeId
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleEditButtonClick = () => {
    if (this.state.selectedToEdit === "Asset Category") {
      this.editAssetCategory();
    } else {
      this.editAssetType();
    }
  };

  fetchMoreassetCategoriesList=()=>{
    this.setState({ setassestCategoriesPage: this.state.setassestCategoriesPage + 1 }, () => {
      this.getAssetCategories();
    })
  }
  fetchMoreassetTypeList=()=>{
    this.setState({ setassestTypePage: this.state.setassestTypePage + 1 }, () => {
      this.getAssetTypes(
        this.state.selectedAssetCategoryId,
        this.state.selectedAssetCategory
      );
    })
  }

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <Grid style={{ paddingLeft: "10px" }} container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/AdminConsole"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Link>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.assetCategories")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Typography
              variant="body2"
              style={{
                fontFamily: "Work Sans",
                color: "#1a76b0",
                textTransform: "uppercase",
                opacity: 1,
                fontSize: "20px",
              }}
            >
              {t("AdminConsole.assetCategories")}
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <Typography
              variant="body2"
              style={{
                fontSize: "13px",
                fontFamily: "Work Sans",
                color: "#1a76b0",
                textTransform: "uppercase",
                paddingLeft: "10px",
              }}
            >
              {t("AdminConsole.categories")}
            </Typography>
            <Grid container direction="row" >
              <Grid item xs={3} style={{height:'500px',overflowY:'auto'}} id="scrollableGrid">
            <InfiniteScroll
                          dataLength={this.state.assetCategoriesList.length}
                          next={this.fetchMoreassetCategoriesList}
                          hasMore={this.state.nextassetCategoriesPage}
                          loader={this.state.totalassetCategoriesPage ? "" : <h4>Loading...</h4>}
                          scrollableTarget="scrollableGrid"
                        >
                <Grid container>
                  {this.state.assetCategoriesList.map((_data: any) => (
                    <Grid item xs={12}>
                      <List>
                        <ListItem
                          button
                          style={{ width: "100%" }}
                          onClick={() =>
                            {this.getAssetTypes(_data.id, _data.attributes.name)
                            this.setState({setassestTypePage:1,
                            assetTypesList:[]})}
                          }
                          selected={
                            this.state.selectedAssetCategory ===
                              _data.attributes.name
                              ? true
                              : false
                          }
                        >
                          <ListItemText primary={_data.attributes.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              onClick={(e) =>
                                this.handleIconClick(
                                  e,
                                  _data.id,
                                  _data.attributes.name,
                                  "Asset Category"
                                )
                              }
                            >
                              <MoreVertIcon style={{}} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    {!this.state.showNewCategory ? (
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "48px",
                            marginTop: "16px",
                            border: "1px dashed #1A1A1A",
                          }}
                          onClick={this.handleNewCategoryOpen}
                        >
                          <AddIcon
                            style={{
                              width: "14px",
                              height: "14px",
                              marginRight: "8px",
                            }}
                          />
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "Work Sans",
                              textTransform: "capitalize",
                              fontSize: "13px",
                            }}
                          >
                            {t("AdminConsole.newCategory")}
                          </Typography>
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Input
                              type="text"
                              placeholder={t("AdminConsole.newCategoryName")}
                              disableUnderline={true}
                              onChange={this.handleNewCategoryChange}
                              value={this.state.newCategoryName}
                            />
                          </CardContent>
                          <CardActions
                            disableSpacing
                            style={{ float: "right" }}
                          >
                            <Button
                              size="small"
                              onClick={this.handleNewCategoryClose}
                            >
                              {t("AdminConsole.cancel")}
                            </Button>
                            <Button
                              size="small"
                              onClick={this.createAssetCategory}
                            >
                              {t("AdminConsole.save")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                </InfiniteScroll>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <ArrowForwardIosIcon style={{ marginTop: "18px" }} />
              </Grid>
              <Grid item xs={3} style={{height:'500px',overflowY:'auto'}} id="scrollableAssestTypeGrid">
              <InfiniteScroll
                          dataLength={this.state.assetTypesList.length}
                          next={this.fetchMoreassetTypeList}
                          hasMore={this.state.nextassetTypePage}
                          loader={this.state.totalassetTypePage ? "" : <h4>Loading...</h4>}
                          scrollableTarget="scrollableAssestTypeGrid"
                        >
                <Grid container>
                  {this.state.assetTypesList.map((_data: any) => (
                    <Grid item xs={12}>
                      <List>
                        <ListItem button style={{ width: "100%" }} onMouseEnter={() => { 
                          // this.getBriefing(_data.id); 
                          localStorage.setItem("assetTypeName", _data.attributes.name) }} 
                          onClick={() => {
                          if (this.state.editButton == true) {
                            window.location.href = `/NewTemplate?briefingId=${_data.id}`;
                          }
                        }}>
                          <ListItemText primary={_data.attributes.name}
                          />
                          <ListItemSecondaryAction
                            onClick={() => { this.getBriefing(_data.id); localStorage.setItem("assetTypeName", _data.attributes.name) }}
                          >
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              onClick={(e) =>
                                this.handleSecondIconClick(
                                  e,
                                  _data.id,
                                  _data.attributes.name,
                                  "Asset Type"
                                )

                              }
                            >
                              <MoreVertIcon style={{}} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Grid>
                  ))}
                  {this.state.selectedAssetCategoryId !== "" ? (
                    <Grid item xs={12}>
                      {!this.state.showNewType ? (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            style={{
                              width: "100%",
                              height: "48px",
                              marginTop: "16px",
                              border: "1px dashed #1A1A1A",
                            }}
                            onClick={this.handleNewTypeOpen}
                          >
                            <AddIcon
                              style={{
                                width: "14px",
                                height: "14px",
                                marginRight: "8px",
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{
                                fontFamily: "Work Sans",
                                textTransform: "capitalize",
                                fontSize: "13px",
                              }}
                            >
                              {t("AdminConsole.newAssetType")}
                            </Typography>
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Card>
                            <CardContent>
                              <Input
                                type="text"
                                placeholder={t("AdminConsole.newAssetTypeName")}
                                disableUnderline={true}
                                onChange={this.handleNewTypeChange}
                                value={this.state.newTypeName}
                              />
                            </CardContent>
                            <CardActions
                              disableSpacing
                              style={{ float: "right" }}
                            >
                              <Button
                                size="small"
                                onClick={this.handleNewTypeClose}
                              >
                                {t("AdminConsole.cancel")}
                              </Button>
                              <Button
                                size="small"
                                onClick={this.createAssetType}
                              >
                                {t("AdminConsole.save")}
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
            </InfiniteScroll>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleIconClose}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleEditClick}
          >
            {t("AdminConsole.edit")}
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleAssetTypeDuplicate}
          >
            {t("AdminConsole.duplicateAssetCategory")}
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleAssetCategoryDelete}
          >
            {t("AdminConsole.delete")}
          </MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl2}
          open={Boolean(this.state.anchorEl2)}
          onClose={this.handleSecondIconClose}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleEditClick}
          >
            {t("AdminConsole.editLabel")}
          </MenuItem>
         
         
          {!this.state.editButton ? <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={() => {
              window.location.href = `/NewTemplate?assetCategory=${this.state.selectedAssetCategoryId
                }&assetType=${this.state.selectedAssestTypeId
                }&screenType=${'AssetCategories'
                }`;
                this.setState({setassestCategoriesPage:1,
                setassestTypePage:1})
            }}
          >
            {t("AdminConsole.createBriefingForm")}
          </MenuItem> :
            <MenuItem
              style={{
                fontFamily: "Work Sans",
                fontSize: "12px",
                color: "#0E0E0E",
                textTransform: "capitalize",
                letterSpacing: 0,
              }}
              onClick={() => {
               window.location.href = `/NewTemplate?briefingId=${this.state.selectedAssestTypeId}`;
                  this.setState({setassestCategoriesPage:1,
                    setassestTypePage:1})
              }}
            >
              {t("AdminConsole.editBriefingForm")}
            </MenuItem>}
            <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleAssetType2Duplicate}
          >
            {t("AdminConsole.duplicateAssetType")}
          </MenuItem>
            <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleOpenTagBrand}
          >
            {t("AdminConsole.tagBrands")}
          </MenuItem>

          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={()=> {
              this.getCopyAssetCategories()
              this.setState({openCopyModel: true})
            }}
          >
            {/* {t("AdminConsole.tagBrands")} */}
            Copy Asset Type
          </MenuItem>
          {/* <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleAssetTypeDuplicate}
          >
            {t("AdminConsole.duplicateAssetType")}
          </MenuItem> */}
            <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleAssetTypeDelete}
          >
            {t("AdminConsole.deleteAssetType")}
          </MenuItem>
        </Menu>
        {/* Tag Brands Dialog  starts*/}
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseTagBrandDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              fontFamily: "Work Sans !important",
              fontSize: "14px",
              fontWeight: 500,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0px",
              color: "#1a76b0",
              opacity: 1,
              textTransform: "uppercase",
            }}
            id="alert-dialog-title"
          >
            {t("AdminConsole.brands")}
          </DialogTitle>
          <DialogContent
            style={{
              maxWidth: "700px",
              width: "369px",
              height: "450px",
              maxHeight: "500px",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        onChange={(e) => this.handleBrandCheckboxSelectAll(e)}
                        checked = { this.state.tagBrandList.length> 0 && this.state.tagBrandList.length == this.state.brandsList.length}

                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#1a76b0",
                          fontFamily: "Inter",
                          opacity: 1,
                          letterSpacing: "0px",
                          marginLeft: "10px",
                        }}
                        variant="body2"
                      >
                        Select All
                      </Typography>
                    }
                  />
                </Grid>

                {this.state.brandsList.map((_data: any) => {
                  return (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={_data.id + "checkbox"}
                            name="checkedB"
                            color="primary"
                            checked={this.state.tagBrandList.includes(_data.id)}
                            onChange={(e) =>
                              this.handleBrandCheckbox(e, _data.id)
                            }
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "#1a76b0",
                              fontFamily: "Inter",
                              opacity: 1,
                              letterSpacing: "0px",
                              marginLeft: "10px",
                            }}
                            variant="body2"
                          >
                            {_data.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button color="primary" onClick={this.handleCloseTagBrandDialog}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button onClick={this.tagBrandWithAssetType}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {t("AdminConsole.save")}
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        {/* Tag brands dialog ends */}


                 {/* copy asset Dialog  starts*/}
        <Dialog
          open={this.state.openCopyModel}
          onClose={()=> this.setState({openCopyModel : false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              maxWidth: "700px",
              width: "369px",
              height: "450px",
              maxHeight: "500px",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        onChange={(e) => this.handleBrandCheckboxSelectAll(e)}
                        checked = { this.state.tagBrandList.length> 0 && this.state.tagBrandList.length == this.state.brandsList.length}

                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#1a76b0",
                          fontFamily: "Inter",
                          opacity: 1,
                          letterSpacing: "0px",
                          marginLeft: "10px",
                        }}
                        variant="body2"
                      >
                        Select All
                      </Typography>
                    }
                  />
                </Grid> */}

                {this.state.copyAssetCategories.map((_data: any) => {
                  return (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={_data.id + "checkbox"}
                            name="checkedB"
                            color="primary"
                            checked={this.state.selectedAssetsForCopy.includes(_data.id)}
                            onChange={(e) =>
                              this.handleCopyAssetTypeCheckbox(e, _data.id)
                            }
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "#1a76b0",
                              fontFamily: "Inter",
                              opacity: 1,
                              letterSpacing: "0px",
                              marginLeft: "10px",
                            }}
                            variant="body2"
                          >
                            {_data.attributes.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button color="primary" onClick={()=> this.setState({openCopyModel : false , selectedAssetsForCopy: []})}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button onClick={this.copyAssetType}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {/* {t("AdminConsole.save")} */}
                Paste Asset Type
                
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        {/* copy asset dialog ends */}

        {/* Edit dialog starts */}
        <Dialog
          open={this.state.openEditDialog}
          onClose={this.handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              fontFamily: "Work Sans !important",
              fontSize: "12px",
              fontWeight: 500,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0px",
              color: "#1a76b0",
              opacity: 1,
              textTransform: "uppercase",
            }}
            id="alert-dialog-title"
          >
            {`Edit ${this.state.selectedToEdit}`}
          </DialogTitle>
          <DialogContent
            style={{
              width: "450px",
              height: "55px",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Input
                    id="input-with-icon-adornment"
                    classes={{ root: classes.inputRoot }}
                    disableUnderline={true}
                    placeholder=""
                    value={this.state.selectedName}
                    onChange={this.handleEditName}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button color="primary" onClick={this.handleCloseEditDialog}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
                onClick={this.handleEditButtonClick}
              >
                Save
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit dialog ends */}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(AssetCategories));
