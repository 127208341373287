import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormatListNumberedRtlOutlined } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInterval } from "timers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  selectedBrandFromGroup:any;
  brandGroupFlag:boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  grid: boolean;
  list: boolean;
  anchorEl: any;
  anchorE2: any;
  setOpen2: any;
  sharedProjectError: any;
  sharedProject: any;
  percentage: any,
  next_page: boolean;
  total_pages: number;
  brandsData: any;
  brandUpdatedCompleteDate: any;
  setPage: number;
  brandDeleteId: number;
  selectedBrandId: any;
  setApprovedQuote: any;
  setDraftQuote: any;
  setPendingQuote: any;
  approvedQuoteActualDate: any;
  openQuoteActualDate: any;
  pendingQuoteActualDate: any;
  permissions:any;
  selectData:any;
  draftsArrowFlag: any;
  waitingArrowFlag: any;
  onGoingArrowFlag: any;
  anchorElFilter: any;
  brandFilterValue: string;
  isSearch: boolean;
  searchBrandName: string;
  tableBrandData: any;
  userInfo: any;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApprovalRequestController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End
  getBrandListingId: any;
  apiCallToBrandDeleteApi: any;
  getApprovedQuotesId: any;
  getOpenQuotesId: any;
  getPendingQuotesId: any;
  getProjectByBrandId: any;
  getBrandListingByFilters: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      selectedBrandFromGroup:[],
      brandGroupFlag:true,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      grid: true,
      list: false,
      anchorEl: null,
      anchorE2: null,
      setOpen2: false,
      sharedProjectError: false,
      sharedProject: "",
      percentage: 74,
      next_page: false,
      total_pages: 1,
      brandsData: [],
      brandUpdatedCompleteDate: [],
      setPage: 1,
      brandDeleteId: 1,
      selectedBrandId: "",
      setApprovedQuote: [],
      setDraftQuote: [],
      setPendingQuote: [],
      approvedQuoteActualDate: [],
      openQuoteActualDate: [],
      pendingQuoteActualDate: [],
      permissions:"",
      selectData:{},
      draftsArrowFlag: true,
      waitingArrowFlag: true,
      onGoingArrowFlag: true,
      anchorElFilter: null,
      brandFilterValue: "created_at",
      isSearch: false,
      searchBrandName: "",
      tableBrandData: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      loading: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }

  handleFilterChange = (event: any) => {
    this.setState({ brandFilterValue: event.target.value, isSearch: false, anchorElFilter: null })
    let path = "" as string;
    if (this.state.searchBrandName !== "") {
      path = `brand_name=${this.state.searchBrandName}&sort_by=${event.target.value}`;
    } else {
      path = `sort_by=${event.target.value}`
    }
    this.getBrandsByFilter(path);
  }

  handleSearchChange = (event: any) => {   
    if (event.target.value !== "") {
      this.setState({ isSearch: false, searchBrandName: event.target.value }, () => {
        if (this.state.brandFilterValue !== "") {
          this.getBrandsByFilter(null);
        } else {
          this.getBrandsByFilter(null);
        }
      })
    } else {
      this.setState({ isSearch: false, searchBrandName: "" }, () => {
        this.getBrandsByFilter(null)
      });
    }
  }

  handleBrandGroupView=(SelectGroupID:any)=>{
   let array =  this.state.brandsData?.filter((value:any)=>value.attributes.brand_group_id==SelectGroupID)
    this.setState({selectedBrandFromGroup:array})
  }

  getBrandsByFilter = (path: any) => {
    this.setState({ tableBrandData: [] })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFilters = requestMessage.messageId;
    if (path == null) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands/brand_project_listing?page=${1}&per_page=${1000}&brand_name=${this.state.searchBrandName}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_categories_sub_categories/brands/brand_project_listing?page=${1}&per_page=${1000}&${path}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingId) {
      // debugger;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            // if (this.state.userInfo?.role?.data?.attributes?.name == 'client' && responseJson.brands?.data?.length == 1) {
            //   this.setState(prevState => ({ 
            //     brandsData: responseJson.brands?.data,
            //     total_pages: responseJson.total_pages ? responseJson.total_pages : prevState.total_pages,
            //     selectData: responseJson.brands?.data[0], 
            //     selectedBrandId: responseJson.brands?.data[0].id
            //   }), ()=> {
            //     this.getProjectByBrandID();
            //     this.transformBrandData();
            //   })
            // } else {
              this.setState(prevState => ({ 
                brandsData: responseJson.brands?.data,
                total_pages: responseJson.total_pages ? responseJson.total_pages : prevState.total_pages,
                loading: false
              }), ()=> {
                this.transformBrandData();
              })
            // }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].brand);
        //  console.log("Fetch Data Brand",errorResponse.errors[0].brand)
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToBrandDeleteApi) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && responseJson.message) {
        if (apiRequestCallId && responseJson) {
          // category list
          toast.success("Brand is deleted Sucessfully");
          this.setState({ setPage: 1 }, () => { this.getBrandListing() })
          //search live
          //recent search
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].brand);
        // console.log("Error delete1", errorReponse);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getApprovedQuotesId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          this.setState({ setApprovedQuote: responseJson.tasks?.data })
          // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let ApprovedUpdatedDate: any = [];
          this.state.setApprovedQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            ApprovedUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ approvedQuoteActualDate: ApprovedUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getOpenQuotesId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          // console.log("Open Quote", responseJson);
          this.setState({ setDraftQuote: responseJson.tasks?.data })
          // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let OpenUpdatedDate: any = [];
          this.state.setDraftQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            OpenUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ openQuoteActualDate: OpenUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getPendingQuotesId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list
          // console.log("Pending Quote", responseJson);
          this.setState({ setPendingQuote: responseJson.tasks?.data })
          // // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let pendingUpdatedDate: any = [];
          this.state.setPendingQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            pendingUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ pendingQuoteActualDate: pendingUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandId) {
      // debugger;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
    // localStorage.setItem("projectsDataSelected", JSON.stringify(responseJson?.projects?.data))
    this.setData(this.state.selectData, responseJson);
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFilters) {
      
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        if (apiRequestCallId && responseJson) {
          console.clear();
          console.log(responseJson)
          this.setState({ brandsData: responseJson?.brands?.data })
        } else {
          console.log("else")
        }
      }
    }

  }

  transformBrandData() {
    let DMYString = ""
    let brandUpdatedDate: any = [];
    this.state.brandsData?.map((items: any) => {
      let modifiedDate: any = items.attributes.updated_at;

      var part: any = modifiedDate.split(" ");
     
      DMYString = part[0] + " " + part[1] + " " + part[2];
      brandUpdatedDate.push({ id: items.id, DMY: DMYString })
    })
    this.setState({ brandUpdatedCompleteDate: brandUpdatedDate })
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  progressPercentage = (e?: any, value?: any) => {
    console.log(e, value, "value")
    this.setState({
      percentage: value
    })
  }
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false
    })
    this.getBrandListing();
  }
  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleFilterArray = (array:any)=>{
    let a = array.filter((val:any)=>val.attributes.brand_group_id!=null)
    let unique = [...new Map(a.map((item:any) =>
      [item.attributes.brand_group_id, item])).values()]
    return unique
  }
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  setData(selectedBrand?: any, responseJson?: any) {
    this.setState({
      selectedBrandId: selectedBrand.id,
    });
    localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrand", selectedBrand.id);
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", responseJson?.projects?.data?.length > 0 ? responseJson.projects.data[0].id : null);
    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "approvalRequests" }
    localStorage.setItem("navData", JSON.stringify(da))
    // this.props.history.push("/Home/Projectportfolio")
    // navigator.navigate("/Home/Projectportfolio")
    window.location.href = '/Home/ProjectList'


  }

  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true
    })
  }
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false
    })
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null
    })
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  }
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true
    })
  }
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  async componentDidMount() {
    this.getBrandListing();
    this.setState({permissions: localStorage.getItem('permissions')})
  }

  getBrandListing = () => {
    this.setState({
      loading: true
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandListingAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPage: value }, () => { this.getBrandListing() });
  };

  deleteBrand(id: number) {

    //Call update API
    const header = {
      "Content-Type": configJSON.contentTypeApiDeleteBrand,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToBrandDeleteApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandDeleteApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletionBrandApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Get Approved Quote
  getApprovedQuote = () => {
    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApprovedQuotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteApprovedApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Open/New Quote
  getOpenQuote = () => {
    const header = {
      "Content-Type": configJSON.getQuoteOpenContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOpenQuotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteOpenApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteOpenApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Pending Quote
  getPendingQuote = () => {
    const header = {
      "Content-Type": configJSON.getQuotePendingContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingQuotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuotePendingApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuotePendingApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProjectByBrandID = () => {
   
    const header = {
      "Content-Type": configJSON.callToProjectShowByBrandContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectByBrandId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToProjectShowByBrandApiEndPoint+ this.state.selectedBrandId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToProjectShowByBrandMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
