
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const filterButton = require("../../../components/src/filter.png");
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
const defaultUser = require("../../../components/src/defaultuser.png")
import _ from "lodash";
// import '.././../../components/src/styles/styles.css'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
// Customizable Area Start
// Customizable Area End
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText } from '@material-ui/core'
import ProjectsTypesController, {
  Props,
  configJSON
} from "./ProjectsTypesController";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from "@material-ui/core/Card";


import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import '../assets/styles.css'
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const logo = require("../../../components/src/volkswagen.svg")
const downIcon = require('../../../components/src/down.png');

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


const data = [
  {
    id: 1,
    name: "BMW"
  },
  {
    id: 2,
    name: "BMW"
  },
  {
    id: 3,
    name: "BMW"
  },
  {
    id: 4,
    name: "BMW"
  }

]


const styles = {
  root: {

  },
  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  accordianStyle: {
    boxShadow: "none",
    border: "none",
    marginTop: "30px"
  },

  actionCard: {
    backgroundColor: "#e4e8f0",
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: "14px"
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    height: "18vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // cursor: "pointer"
  },
  title: {
    color: "#282828",
    fontSize: "16px"
  },
  dropDown: {
    fontSize: "16px"
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
    // padding:"0px"
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  accordionSummaryRoot: {
    position: "inherit !important"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
} as any
class ProjectsTypes extends ProjectsTypesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleClickProjects = (event: any) => {
    console.log(event, "h")
    this.setState({
      anchorE2: event.currentTarget,
    });
  };

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
   

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      //Merge Engine DefaultContainer
      <div style={{ marginLeft: "15px" , fontFamily : primaryFont }}>
        <Backdrop
          className={classes.backdrop}
          open={this.state.loading}
          onClick={(e) => this.handleCloseLoader()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={0} >
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/HomePage" style={{ color: secondaryColorToBeChanged, fontSize: "14px" ,fontFamily : SecondaryFont }}>
                Home
              </Link>
             { this.state.permissionName == "albertbauer"  && <Link href="/Home/Brands" style={{ color: secondaryColorToBeChanged, fontSize: "14px" ,fontFamily : SecondaryFont }}>
                Brand
              </Link>}
             {localStorage.getItem("brandGroupSelected") && this.state.permissionName == "albertbauer"  &&  <Link href="/Home/Brands/BrandGroup" style={{ color: secondaryColorToBeChanged, fontSize: "14px" ,fontFamily : SecondaryFont }}>
                Brand Group
              </Link>}
              <Link href="#" style={{ color: "#737373", fontSize: "14px", textDecoration:"none", cursor:"default"  ,fontFamily : SecondaryFont}}>
                {
                  localStorage.getItem("projectType") == "Archive Projects" ? "Archived Projects" : "Deleted Projects"
                }
              </Link>


            </Breadcrumbs>

            <span style={{ marginLeft: "10px" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" ,fontFamily : SecondaryFont }}>Projects

              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Projects</Typography>
                    <p>Projects grouped brands.</p>
                  </React.Fragment>
                }
                placement="right"
              >
                <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                </InfoOutlinedIcon>
              </HtmlTooltip>



            </p></span>









          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>

            <Button
              variant="contained"
              style={{ color: secondaryColorToBeChanged, marginBottom: "10px", textTransform: "none"  ,fontFamily : SecondaryFont}}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter: event.target })}

            >
              Filters
            </Button>

            <ButtonGroup aria-label="outlined primary button group" style={{ marginRight: "10px", marginLeft: "10px" }}>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none" ,fontFamily : SecondaryFont }}
                className={this.state.grid ? classes.activeButton : classes.button}
                onClick={e => { this.changeToGridView(e) }}

                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={<AppsOutlinedIcon />}
              >
                {this.state.grid &&
                  <>Grid</>
                }
              </Button>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none"  ,fontFamily : SecondaryFont}}
                className={this.state.list ? classes.activeButtonList : classes.buttonGroup}
                onClick={e => { this.changeToListView(e) }}
                endIcon={<ListOutlinedIcon />}

              >
                <span>  {this.state.list &&
                  <>List</>
                }</span>
              </Button>

            </ButtonGroup>


            {/* <SearchBar
      dataSource={state.dataSource}
      onChange={(value) => setState({dataSource: [ value, value+value, value+value+value]})}
      onRequestSearch={() => console.log('onRequestSearch')}
      style={{
        margin: '0 auto',
        maxWidth: 800
      }}
    /> */}
            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              startAdornment={
                <InputAdornment position="start" >
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={this.handleSearchChange}
              value={this.state.searchBrandName}
            />

            {/* {((this.state.permissionName === 'albertbauer' && this.state.permissions === "editor") || (this.state.permissionName === "albertbauer" && this.state.permissions === "viewer")) && ( */}
            <Button
              variant="contained"
              className={classes.button}
              size="medium"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: colorToBeChanged,
                color: "white",
                marginBottom: "10px",
              }}
              onClick={this.handleClickOpenModal}
            >
              Create Project
            </Button>



          </Grid>
          {!this.state.isSearch ?
            <>
              {this.state.grid &&

                (
                  <>
                    <Grid item xs={12}>
                      <div style={{ marginTop: "10px" }}>
                        <div style={{
                          position: "absolute", right: "9px",
                          marginTop: "-40px"
                        }}>
                          <Button
                            variant="contained"
                            style={{ color: secondaryColorToBeChanged, textTransform: 'none' ,fontFamily : SecondaryFont }}
                            className={classes.button}
                            startIcon={
                              <img src={filterButton} aria-controls="panel1a-content"
                                id="panel1a-header" />
                            }
                            endIcon={
                              <ExpandMoreIcon style={{ color: "black" }} />
                            }
                            onClick={(event) => this.setState({ anchorElFilter: event.target })}
                          >
                            Name
                          </Button>
                        </div>
                        {/* <Accordion className={classes.accordianStyle} defaultExpanded > */}
                        <AccordionSummary

                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          classes={{ root: classes.accordionSummaryRoot }}

                        >
                          <div style={{ display: 'flex', flexDirection: "row" }}>
                            <Typography className={classes.heading} >
                              {localStorage.getItem("projectType") == 'Archive Projects' ? 'Archived Projects' : localStorage.getItem("projectType") }
                              {/* <img src={downIcon} />  */}
                            </Typography>

                            {/* <Typography style={{ position: "absolute", right: "20px" }}>
                              <div >
                                <Button
                                  variant="contained"
                                  style={{ color: secondaryColorToBeChanged , textTransform : "none"  }}
                                  className={classes.button}
                                  startIcon={<ImportExportIcon style={{ color: "black" }} />}
                                  onClick={this.handleSortByName}

                                >
                                  Name
                                </Button>

                              </div>

                            </Typography> */}
                          </div>


                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid xs={12}>
                            <Grid container spacing={0} alignItems="stretch">
                              {this.state.projectDataFromDropDown?.map((item: any) => {
                                return (
                                  <Grid item xs={3}>
                                    <Box m={1}>
                                      <Card className={classes.cardStyle}
                                      // onClick={(e: any) => { this.setState({ selectData: item, selectedBrandId: item.id }, () => { this.getProjectByBrandID() }) }}
                                      >
                                        <CardContent style={{ paddingBottom: "16px" ,fontFamily : primaryFont }}  >
                                          <a>
                                            <Typography className={classes.title} gutterBottom>
                                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                  {item?.attributes?.logo_blob_signed_id_url ?
                                                    <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                    :
                                                    <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                  }
                                                  <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "145px" }} >{item.attributes.name}</p> </span>
                                                <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                  {localStorage.getItem("projectType") == "Deleted Projects" ? ""
                                                    : (
                                                      <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="listing_branding"
                                                        aria-haspopup="true"
                                                        //onClick={(e: any) => { this.handleClickMenu(e); this.setState({ brandDeleteId: parseInt(item.id) }) }}
                                                        onClick={(e: any) => this.handleClickMenu3(e, item.id)}
                                                      />
                                                    )}

                                                </IconButton>


                                              </div>


                                            </Typography>

                                            {item.attributes.projects?.map((projectName: any) => (
                                              <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>
                                                {projectName.name}
                                              </p>
                                            ))
                                            }

                                            {/* <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p> */}
                                          </a>

                                        </CardContent>

                                        {/* </NavLink> */}
                                        {/* </a> */}


                                        <CardActions className={classes.actionCard} >
                                          <p style={{ fontSize: "10px" }}>
                                            {item.attributes.updated_at.split(" ")[0]} {item.attributes.updated_at.split(" ")[1]} {item.attributes.updated_at.split(" ")[2]} last modified
                                          </p>

                                          {(item?.attributes?.first_accounts?.data?.length > 0) &&
                                            <AvatarGroup max={3} className={classes.avatar}>
                                              {item?.attributes?.first_accounts?.data.map((account: any) => (
                                                <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={account.attributes?.photo_blob_signed_id_url} />
                                              ))}
                                              {_.times(item?.attributes?.first_accounts_count, () => (
                                                <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={defaultUser} />
                                              ))}
                                            </AvatarGroup>
                                          }
                                          {/* {(item?.attributes?.first_accounts?.data[0]?.attributes?.photo_blob_signed_id_url) ?
                                            <AvatarGroup max={3} className={classes.avatar}>
                                              <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                              {_.times(item.attributes.account_projects_count, () => (
                                                <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={defaultUser} />
                                              ))}
                                            </AvatarGroup>
                                            :
                                            (
                                              <AvatarGroup max={3} className={classes.avatar}>
                                                {_.times(item.attributes.account_projects_count, () => (
                                                  <Avatar alt="Remy Sharp" style={{ height: "30px", width: "30px" }} src={defaultUser} />
                                                ))}
                                              </AvatarGroup>
                                            )
                                          } */}


                                        </CardActions>

                                      </Card>
                                    </Box>

                                  </Grid>





                                )
                              })

                              }
                              {
                                this.state.projectDataFromDropDown?.length == 0 ? <div style={{marginTop : "15px" }}> No Project Found!</div>
                                  : null
                              }
                              {/* <Box textAlign="right" style={{ marginTop: "45px", position: "absolute", right: "55px" }}>
                          <Pagination
                            count={this.state.total_pages}
                            variant="outlined"
                            color="primary"
                            page={this.state.setPage}
                            onChange={this.handleChange}
                          />
                        </Box> */}
                            </Grid>
                           {this.state.projectDataFromDropDown?.length != 0 && <Grid item xs={12}>
                              <Grid container direction="row">
                                <Grid item xs={9} />
                                <Grid item xs={3}>
                                  <Pagination
                                    count={this.state.total_pages}
                                    variant="outlined"
                                    color="primary"
                                    page={this.state.setPage}
                                    onChange={this.handleChange}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>}


                            <Grid container spacing={0} alignItems="stretch">
                              <Grid item xs={12}>

                              </Grid>
                            </Grid>

                          </Grid>




                        </AccordionDetails>
                        {/* </Accordion> */}
                      </div></Grid>
                  </>
                )

              }

              {this.state.list &&
                (
                  <>

                    <Accordion style={{ width: "100%", marginTop: "30px"  }} className={classes.accordianStyle} defaultExpanded>
                      <AccordionSummary

                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.listHeader}>Drafts <img src={downIcon} aria-controls="panel1a-content"
                          id="panel1a-header" /></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12}>

                          {

                            this.state.projectDataFromDropDown?.map((item: any) => {
                              return (
                                <div>



                                  <div style={{ width: "95%", borderRadius: "8px", borderLeft: "6px solid #1a76b0", borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px" ,fontFamily : primaryFont }}>
                                    <div style={{ margin: "20px" }}>
                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                        <p onClick={(e) => { this.setData(item) }}>{item.attributes.name}</p>
                                        {/* <p style={{ marginLeft: "30px" }}>Photo Production</p> */}

                                      </div>
                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                        {item.attributes?.projects?.map((project: any) => (
                                          <p style={{ fontSize: "12px", paddingLeft: "25px" }}>{project.name}</p>
                                        ))}

                                        <div className={classes.circle} >
                                          <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                        </div>
                                        {/* {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                            {item.id == val.id && (
                                              val.DMY + " " + "last modified"
                                            )}
                                          </p>
                                        ))} */}
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                          {item.attributes.updated_at.split(" ")[0]} {item.attributes.updated_at.split(" ")[1]} {item.attributes.updated_at.split(" ")[2]} last modified
                                        </p>
                                        <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                          <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                        </div>

                                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                        <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                          <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>

                                        </div>
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>3</p>
                                        {/* <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p> */}

                                      </div>
                                    </div>
                                  </div>



                                </div>
                              )
                            })
                          }
                          {
                                this.state.projectDataFromDropDown?.length == 0 ? <div style={{marginTop : "15px" }}> No Project Found!</div> : null
                              }

                        </Grid>



                      </AccordionDetails>

                    </Accordion>


                  </>
                )

              }
            </> :
            <>
              {/* table starts */}

              {this.state.tableBrandData.length !== 0 ?
                <>
                  <Grid container direction="row">
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                      <Button onClick={() => this.setState({ isSearch: false, searchBrandName: "", brandFilterValue: "" })} variant="text" style={{ left: "60px" }}>Back to Previous Screen</Button>
                    </Grid>
                  </Grid>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            Project Name
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Creator
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Project Manager
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            Due Date
                          </TableCell>


                        </TableRow>
                      </TableHead>

                      {this.state.tableBrandData.map((_data: any) => {
                        return (


                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}


                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",

                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={
                                        classes.tableData
                                      }
                                      onClick={(e: any) => {
                                        this.setState({ selectData: _data, selectedBrandId: _data.id }, () => {
                                          // this.getProjectByBrandID()
                                          console.log("projects ", this.state.selectData);

                                          this.setData2(this.state.selectData)

                                        })
                                      }}
                                    >
                                      {_data.attributes.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.creator.data !== null ? `${_data.attributes.creator.data.attributes.first_name} ${_data.attributes.creator.data.attributes.last_name}` : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.project_manager.data !== null
                                    ? `${_data.attributes.project_manager.data
                                      .attributes.first_name
                                    } ${_data.attributes.project_manager.data
                                      .attributes.last_name
                                    }`
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.status}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={
                                    classes.tableData
                                  }
                                >
                                  {_data.attributes.estimated_end_date !== null
                                    ? _data.attributes.estimated_end_date
                                    : "NA"}
                                </Typography>
                              </TableCell>



                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        )
                      })}

                    </Table>
                  </TableContainer>
                </> : <p>No data Found</p>}
              {/* table ends */}
            </>}

        </Grid>

        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >

          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="projectFilter" value={this.state.projectFilterValue} onChange={this.handleFilterChange2} >
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name0" control={<Radio color="primary" />} label="Name (asc)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name1" control={<Radio color="primary" />} label="Name (desc)" />
                <FormControlLabel value="created_at1" control={<Radio color="primary" />} label="Created At (latest)" />
                <FormControlLabel value="created_at0" control={<Radio color="primary" />} label="Created At (oldest)" />
                <FormControlLabel value="updated_at1" control={<Radio color="primary" />} label="Updated At (latest)" />
                <FormControlLabel value="updated_at0" control={<Radio color="primary" />} label="Updated At (oldest)" />

              </RadioGroup>
            </Grid>
          </Grid>

        </Menu>

        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
        >
          <DialogTitle id="customized-dialog-title">
            {/* {t("projects.newProjectTitle")} */}
            New Project
            <span style={{ position: "absolute", right: "10px" ,fontFamily : primaryFont }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",
              projectDescription: "",
              title: "",
              //shareTheProjectWithOthers:this.state.sharedProject,
              // includeAPersonalMessageWithYourInvitation: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              // projectDescription: Yup.string().required(
              //   "projectDescription is required"
              // ),
              title: Yup.string().required(
                "title is required"
              ),

              // includeAPersonalMessageWithYourInvitation: Yup.string().required(
              //   "Include A Personal MessageWith Your Invitation is required"
              // ),
            })}
            onSubmit={(values) => {
              //console.log(values);

              //console.log("pro", this.state, "pro");
              this.callCreateProjectApi(values, this.state.sharedProject);
              this.setState(
                {
                  sharedProjectError: this.state.sharedProject ? false : true,
                },
                () => {
                  //console.log(this.state.sharedProjectError, "error");
                }
              );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit} style={{fontFamily : primaryFont}}>
                  <DialogContent dividers>
                    <TextField
                      name="title"
                      type="text"
                      size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      fullWidth
                      // label={t("projects.projectDescription")}
                      label="Project Title"
                      value={values.title}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.title &&
                          errors.title ? (
                          // <Trans>CompanyProfile.companyNameRequired</Trans>
                          <Trans>Project title is required</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.title && errors.title
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />


                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.accountsData}
                      style={{ minWidth: "550px" }}

                      getOptionLabel={(option: any) => option?.attributes?.first_name ? option?.attributes?.first_name + " " + option?.attributes?.last_name : ''}

                      onInputChange={(e, value) => {
                        this.callGetAccountListApi(value)

                      }}
                      // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                      onChange={(e, value) => {
                        //console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          sharedProjectError: value.length > 0 ? false : true

                        }, () => {
                          //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            className={classes.projectChips}
                            label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.attributes?.photo_blob_signed_id_url} /> </span>
                              <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.attributes?.first_name}</span>
                            </Typography>}
                            {...getTagProps({ index })}

                            style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                          />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          label={t("teams.addMembers")}
                          name="addMembers"


                          helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />

                    <TextField
                      name="projectDescription"
                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      size="small"
                      className={classes.confirmInput}
                      fullWidth
                      label="Project Description"
                      // label={t(
                      //   "projects.ProjectDescription"
                      // )}
                      value={values.projectDescription}
                      variant="outlined"

                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.createProject")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>
        {
          localStorage.getItem("projectType") == "Deleted Projects" ? "" :
            (

              <Menu
                id="simple-menu"
                anchorEl={this.state.setOpen3}
                keepMounted
                open={Boolean(this.state.setOpen3)}
                onClose={() => {
                  this.setState({ setOpen3: null })
                }}
              >

                <MenuItem
                  // data-my-value={project}
                  onClick={(e) => {

                    this.unArchiveProject();
                  }}
                  style={{ fontSize: "12px" , fontFamily : primaryFont}}
                  className={classes.dropDown}
                  
                >
                  Unarchive
                </MenuItem>
              </Menu>
            )
        }
      </div>




      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(ProjectsTypes))
// Customizable Area Start

// Customizable Area End