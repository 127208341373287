import React, { Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ReactS3Uploader from "react-s3-uploader";
// import InputAdornment from '@material-ui/icons/InputAdornment';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CardMedia from '@material-ui/core/CardMedia';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import '../assets/accordion.css';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { Formik, Field } from "formik";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from 'react-draft-wysiwyg';
import TextField from "@material-ui/core/TextField";
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import '../assets/card.css'
import * as Yup from "yup";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
// Customizable Area End
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MultilevelApprovalController, {
  Props,
  configJSON
} from "./MultilevelApprovalController";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from "@material-ui/core/Card";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Autocomplete } from "formik-material-ui-lab";
import { imgFileTemplate } from "./assets";

import '../../../components/src/styles/styles.css'
// import '.././../../components/src/styles/styles.css'
import { AnyNsRecord } from "dns";
import SendIcon from '@material-ui/icons/Send';
import { toast } from 'react-toastify';

const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const logo = require("../../../components/src/volkswagen.svg")
const defaultUser = require("../../../components/src/defaultuser.png")
const downIcon = require('../../../components/src/down.png')
const plusButton = require("../../../components/src/plus-top-bar.png");
const filterButton = require("../../../components/src/filter.png");
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isNull } from "util";
const imageIcon = require("../../../components/src/svgFiles/image.svg")
const CancelIcon = require("../../ProjectPortfolio/assets/close_white.png");

const data = [
  {
    id: 1,
    name: "BMW"
  }
]
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const top100Films = [
  { title: 'Lars', year: 1994, image: require('../../../components/src/view-user-image.png') },
  { title: 'Abbe', year: 1972, image: require('../../../components/src/view-user-image.png') },
  { title: 'Alexander', year: 1974, image: require('../../../components/src/view-user-image.png') },
  { title: 'Baltasar', year: 2008, image: require('../../../components/src/view-user-image.png') },
  { title: 'Barduwulf', year: 1957, image: require('../../../components/src/view-user-image.png') },
  { title: "Barnim", year: 1993, image: require('../../../components/src/view-user-image.png') },
  { title: 'Pulp Fiction', year: 1994, image: require('../../../components/src/view-user-image.png') },
  { title: 'Frederick', year: 2003, image: require('../../../components/src/view-user-image.png') },
  { title: 'Garit', year: 1966, image: require('../../../components/src/view-user-image.png') },
  { title: 'Godfrey', year: 1999, image: require('../../../components/src/volkswagen.svg') },

];
const PrettoSlider = withStyles({
  root: {
    color: '#1a76b0',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: 0,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const styles = {
  root: {

  },
  parentInput: {
    "& input": {
      border: "none",
      outline: "none"

    },

  },
  button: {

    backgroundColor: "white",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  activeButton: {
    backgroundColor: "white",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  quoteButton: {
    backgroundColor: "#e4e8f0",
    marginTop: "20%",
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  selectInput: {
    marginBottom: "20px",
  },
  progress: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  TaskViewTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#000000",
    padding: "20px 20px"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
    },
    '&::after': {
      borderBottom: 'none'

    },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    margin: '0px 6px'
  },
  accordianStyle: {
    boxShadow: "none",
    border: "none",
    marginTop: "30px"
  },

  actionCard: {
    backgroundColor: "#e4e8f0",
    display: 'flex',
    flexDirection: 'row',
    position: "absolute",
    bottom: "2px",
    width: "93%"
  },
  //   avatar:{
  // marginLeft:"40px"
  //   },
  heading: {
    fontSize: "14px"
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px"
  },


  title: {
    color: "#282828",
    fontSize: "16px"
  },
  dropDown: {
    fontSize: "16px"

  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  quoteRequest: {
    display: "flex",
    // gap:"5px",

  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    marginLeft: "10px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 33px',
    height: '33px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center',
    padding: "2px"
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
    // padding:"0px"
  },
  status: {
    backgroundColor: "#f5f6fa",
    // paddingTop: "10px",
    padding: "3px",
    margin: "15px"

  },
  dialogPaper: {
    maxWidth: "486px!important" as any
  },
  actions: {
    padding: "15px!important" as any,
    justifyContent: "flex-start!important" as any,
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  quoteInput: {
    display: "block",
    fontSize: "2em",
    marginBlockStart: "0.67em",
    marginBlockEnd: "0.67em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    fontWeight: "bold"
  },
  tab: {
    minWidth: 10,
    textTransform: "none",

  },
  projectDocuments: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px"
  },
  projectDocument: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px",
  },
  fileName: {
    fontSize: "14px",
    margin: "8px"
  },
  fileSize: {
    fontSize: "10px",
    margin: "8px"
  },
  date: {
    fontSize: "12px",
    marginLeft: "190px"
  },
  members: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginLeft: "20px"
    // position:"relative"
  },
  setAssetInFrame: {
    maxHeight: "100%",
    width: "auto",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%"
  },
  attachmentShowContent: {
    backgroundColor: "rgb(0 0 0 / 78%)",
    padding: 0,
    overflowY: "hidden",
    position: "relative"
  }
} as any
class MultilevelApprovalRequest extends MultilevelApprovalController {
  fileInput: any;
  fileInput1: any
  fileInput2: any;
  fileInput3: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.fileInput = React.createRef();
    this.fileInput1 = React.createRef();
    this.fileInput2 = React.createRef();
    this.fileInput3 = React.createRef();
  }
  inputFileChanged = (e: any) => {
    console.log("File1", e.target.files[0])
    this.getSignedUrl(e.target.files[0]);
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
  };

  handleClickProjects = (event: any) => {
    console.log(event, "h")
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleChange = (event: any, value: any) => {
    this.setState({
      value: value,
    });
  };
  formatDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
    var dueDate = this.formatDate()
    return (
      //Merge Engine DefaultContainer

      <div style={{ marginLeft: "15px" }}>

        <Grid container spacing={0} >
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
            onClick={() => this.setState({ loading: false })}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item xs={4}>
            <div style={{ marginTop: "-14px" }}>
              <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
                <Link href="/Home/HomePage" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                  Home
                </Link>
                <Link href="/Home/ApprovalRequest" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                  Approval Requests
                </Link>
                <Link href="/Home/ProjectList" style={{ color: "#737373", fontSize: "14px", fontFamily: SecondaryFont }}>
                  {this.state.ProjectNameByID}
                </Link>
              </Breadcrumbs>

              <span style={{ marginLeft: "0!important" }}> <p style={{ fontSize: "32px", marginLeft: "10px", marginRight: "0px", marginTop: "0px", marginBottom: "0px" }}>{this.state.ProjectNameByID}
                <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} onClick={() =>
                  this.handleClickProjectPropertiesIcon()
                }></InfoOutlinedIcon>
              </p></span>
            </div>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>

            <Button
              variant="contained"
              style={{ color: secondaryColorToBeChanged, marginBottom: "10px", textTransform: "none", fontFamily: SecondaryFont }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter1: event.target })}
            >
              {
                this.state.projectTaskFilterValue == 'title0' ? 'Name (A-Z)' : this.state.projectTaskFilterValue == 'title1' ? 'Name (Z-A)' :
                  this.state.projectTaskFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.projectTaskFilterValue == 'created_at1' ? 'Created At (latest)' :
                    this.state.projectTaskFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At (latest)'
              }
            </Button>


            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              startAdornment={
                <InputAdornment position="start" >
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={this.handleProjectSearchChange}
              value={this.state.searchProjectName}
            />

          </Grid>
          {this.state.list &&
            (
              <>
                <div style={{ marginLeft: 'auto', marginRight: '1%' }}>

                </div>

                <Grid item xs={12}>
                  <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.listHeader}>  {this.state.permissionName === 'client' ? 'Quote requested' : 'Waiting for quotes'} <img src={downIcon} aria-controls="panel1a-content"
                        id="panel1a-header" /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>

                        {

                          this.state.setPendingQuote.length > 0 ? this.state.setPendingQuote?.map((item: any) => {
                            return (
                              <div
                                onClick={() => {
                                  item?.attributes?.ultimate_state == "Quote Requested" ?
                                    this.handleClickOpenSendQuoteModal(item) : this.handleClickOpenEditQuoteModal(item)
                                }}
                              >
                                <div style={{ width: "99%", borderRadius: "8px", borderLeft: `6px solid ${secondaryColorToBeChanged}`, borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px", cursor: "pointer" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={6}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', height: '30px' }}>
                                          <p style={{ width: 'auto', }}>{item?.attributes?.title}</p>
                                          <p style={{ marginLeft: "30px", background: '#F4F6FC 0% 0% no-repeat padding-box', padding: '6px', borderRadius: '8px' }}>{item.attributes.project_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px" }}>{item.attributes.description}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div className={classes.quoteRequest}>
                                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginTop: "11%" }}>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                              <p><ChatOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_comments_count}</p>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                              <p> <AttachFileOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_files_count}</p>

                                          </div>

                                          <span style={{ marginTop: "4%", marginLeft: "8%" }}></span>
                                          {(item?.attributes?.ultimate_state == "Pending Quote Approval" || item?.attributes?.ultimate_state === 'Quote Approved') &&
                                            <span style={{ marginTop: "4%", marginLeft: "8%" }}>
                                              <p style={{ fontSize: "20px", marginBottom: "0px" }}>
                                                €{item.attributes.quotation.data ? item.attributes.quotation.data.attributes.price :
                                                  item.attributes.price_auto_generated && item.attributes.price_auto_generated}</p>
                                              <p style={{ fontSize: "10px", marginTop: "0px" }}>
                                                {item.attributes?.price_auto_generated == null ? t("ApprovalRequest.quoteSent") : t("ApprovalRequest.autoGenerated")}</p>
                                            </span>
                                          }

                                          <span style={{ position: "absolute", right: "5%" }}>
                                            {/* {this.state.permissions === 'approver' ?
                                            <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                              Send Quote
                                            </Button>
                                            : */}
                                            <>
                                              {item?.attributes?.ultimate_state == "Quote Requested" &&
                                                (this.state.permissions == 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                                  {t("ApprovalRequest.quoteRequested")}
                                                </Button>
                                                : item?.attributes?.ultimate_state == "Quote Requested" &&
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#173E59", color: "#ffffff" }} onClick={() => { this.handleClickOpenSendQuoteModal(item) }}>
                                                  {t("ApprovalRequest.sendQuote")}
                                                </Button>
                                              }
                                              {item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                (this.state.permissions === 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                item?.attributes?.ultimate_state === 'Quote Approved' ?
                                                  <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    Approved
                                                  </Button> :
                                                  <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged, }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    {t("ApprovalRequest.approveQuote")}
                                                  </Button>
                                                : (this.state.permissions === 'editor' || this.state.permissions == "brand_manager") && this.state.permissionName !== "client" ?
                                                  item?.attributes?.ultimate_state === 'Quote Approved' ?
                                                    <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                      Approved
                                                    </Button>
                                                    : item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                    <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                      {t("ApprovalRequest.editQuote")}
                                                    </Button>
                                                  :
                                                  null
                                                // item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                // <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                //   {t("ApprovalRequest.editQuote")}
                                                // </Button>
                                              }
                                            </>
                                            {/* } */}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                            :
                            (this.state.loading == true && <b><p style={{ textAlign: "center", margin: "100px 0px 0px 15px" }}>
                              {/* Woohoo! You've no pending tasks in your approval requests */}
                            </p></b>)
                        }
                      </Grid>



                    </AccordionDetails>

                  </Accordion>

                </Grid>

                <Grid item xs={12}>
                  <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.listHeader}>{this.state.permissionName === 'client' ? 'waiting for approval' : 'Quote sent'}  <img src={downIcon} aria-controls="panel1a-content"
                        id="panel1a-header" /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>

                        {

                          this.state.setQuoteSent.length > 0 ? this.state.setQuoteSent?.map((item: any) => {
                            return (
                              <div
                                onClick={() => {
                                  item?.attributes?.ultimate_state == "Quote Requested" ?
                                    this.handleClickOpenSendQuoteModal(item) : this.handleClickOpenEditQuoteModal(item)
                                }}
                              >
                                <div style={{ width: "99%", borderRadius: "8px", borderLeft: `6px solid ${secondaryColorToBeChanged}`, borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px", cursor: "pointer" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={6}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', height: '30px' }}>
                                          <p style={{ width: 'auto', }}>{item?.attributes?.title}</p>
                                          <p style={{ marginLeft: "30px", background: '#F4F6FC 0% 0% no-repeat padding-box', padding: '6px', borderRadius: '8px' }}>{item.attributes.project_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px" }}>{item.attributes.description}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div className={classes.quoteRequest}>
                                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginTop: "11%" }}>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                              <p><ChatOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_comments_count}</p>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                              <p> <AttachFileOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_files_count}</p>

                                          </div>

                                          <span style={{ marginTop: "4%", marginLeft: "8%" }}></span>
                                          {(item?.attributes?.ultimate_state == "Pending Quote Approval" || item?.attributes?.ultimate_state === 'Quote Approved') &&
                                            <span style={{ marginTop: "4%", marginLeft: "8%" }}>
                                              <p style={{ fontSize: "20px", marginBottom: "0px" }}>
                                                €{item.attributes.quotation.data ? this.convertData(item.attributes.quotation.data.attributes.price) :
                                                  item.attributes.price_auto_generated && item.attributes.price_auto_generated}</p>
                                              <p style={{ fontSize: "10px", marginTop: "0px" }}>
                                                {item.attributes?.price_auto_generated == null ? t("ApprovalRequest.quoteSent") : t("ApprovalRequest.autoGenerated")}</p>
                                            </span>
                                          }

                                          <span style={{ position: "absolute", right: "5%" }}>
                                            {/* {this.state.permissions === 'approver' ?
                                            <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                              Send Quote
                                            </Button>
                                            : */}
                                            <>
                                              {item?.attributes?.ultimate_state == "Quote Requested" &&
                                                (this.state.permissions == 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                                  {t("ApprovalRequest.quoteRequested")}
                                                </Button>
                                                : item?.attributes?.ultimate_state == "Quote Requested" &&
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#173E59", color: "#ffffff" }} onClick={() => { this.handleClickOpenSendQuoteModal(item) }}>
                                                  {t("ApprovalRequest.sendQuote")}
                                                </Button>
                                              }
                                              {item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                (this.state.permissions === 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                item?.attributes?.ultimate_state === 'Quote Approved' ?
                                                  <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    Approved
                                                  </Button> :
                                                  <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged, }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    {t("ApprovalRequest.approveQuote")}
                                                  </Button>
                                                : ((this.state.permissions === 'editor' || this.state.permissions == "brand_manager") && this.state.permissionName !== "client") || this.state.permissionName == "superadmin" ?
                                                  item?.attributes?.ultimate_state === 'Quote Approved' ?
                                                    <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                      Approved
                                                    </Button>
                                                    : item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                    <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                      {t("ApprovalRequest.editQuote")}
                                                    </Button>
                                                  :
                                                  null
                                                // item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                // <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                //   {t("ApprovalRequest.editQuote")}
                                                // </Button>
                                              }
                                            </>
                                            {/* } */}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                            :
                            (this.state.loading == true && <b><p style={{ textAlign: "center", margin: "100px 0px 0px 15px" }}>
                              {/* Woohoo! You've no pending tasks in your approval requests */}
                            </p></b>)
                        }
                      </Grid>



                    </AccordionDetails>

                  </Accordion>

                </Grid>
                <Grid item xs={12}>
                  <Accordion style={{ width: "100%", marginTop: "30px" }} className={classes.accordianStyle} defaultExpanded>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.listHeader}>Approved Quotes <img src={downIcon} aria-controls="panel1a-content"
                        id="panel1a-header" /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>

                        {

                          this.state.setApprovedQuote.length > 0 ? this.state.setApprovedQuote?.map((item: any) => {
                            return (
                              <div
                                onClick={() => {
                                  item?.attributes?.ultimate_state == "Quote Requested" ?
                                    this.handleClickOpenSendQuoteModal(item) : this.handleClickOpenEditQuoteModal(item)
                                }}
                              >
                                <div style={{ width: "99%", borderRadius: "8px", borderLeft: `6px solid ${secondaryColorToBeChanged}`, borderRight: "1px solid #e4e8f0", borderTop: "1px solid #e4e8f0", borderBottom: "1px solid #e4e8f0", marginTop: "20px", cursor: "pointer" }}>
                                  <div style={{ margin: "20px" }}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={6}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', height: '30px' }}>
                                          <p style={{ width: 'auto', }}>{item?.attributes?.title}</p>
                                          <p style={{ marginLeft: "30px", background: '#F4F6FC 0% 0% no-repeat padding-box', padding: '6px', borderRadius: '8px' }}>{item.attributes.project_name}</p>
                                        </div>
                                        <div>
                                          <p style={{ fontSize: "12px" }}>{item.attributes.description}</p>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div className={classes.quoteRequest}>
                                          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginTop: "11%" }}>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                              <p><ChatOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_comments_count}</p>

                                            <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                              <p> <AttachFileOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                            </div>
                                            <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_files_count}</p>

                                          </div>

                                          <span style={{ marginTop: "4%", marginLeft: "8%" }}></span>
                                          {(item?.attributes?.ultimate_state) &&
                                            <span style={{ marginTop: "4%", marginLeft: "8%" }}>
                                              <p style={{ fontSize: "20px", marginBottom: "0px" }}>
                                                €{item.attributes.quotation.data ? this.convertData(item.attributes.quotation.data.attributes.price) :
                                                  item.attributes.price_auto_generated && item.attributes.price_auto_generated}</p>
                                              <p style={{ fontSize: "10px", marginTop: "0px" }}>
                                                {item.attributes?.price_auto_generated == null ? t("ApprovalRequest.quoteSent") : t("ApprovalRequest.autoGenerated")}</p>
                                            </span>
                                          }

                                          <span style={{ position: "absolute", right: "5%" }}>
                                            {/* {this.state.permissions === 'approver' ?
                                            <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                              Send Quote
                                            </Button>
                                            : */}
                                            <>
                                              {item?.attributes?.ultimate_state == "Quote Requested" &&
                                                (this.state.permissions == 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} disabled={true}>
                                                  {t("ApprovalRequest.quoteRequested")}
                                                </Button>
                                                : item?.attributes?.ultimate_state == "Quote Requested" &&
                                                <Button className={classes.quoteButton} style={{ backgroundColor: "#173E59", color: "#ffffff" }} onClick={() => { this.handleClickOpenSendQuoteModal(item) }}>
                                                  {t("ApprovalRequest.sendQuote")}
                                                </Button>
                                              }
                                              {item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                (this.state.permissions === 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                                                (item?.attributes?.ultimate_state) ?
                                                  <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    Approved
                                                  </Button> :
                                                  <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged, }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                    {t("ApprovalRequest.approveQuote")}
                                                  </Button>
                                                : ((this.state.permissions === 'editor' || this.state.permissions == "brand_manager") && this.state.permissionName !== "client") || this.state.permissionName == "superadmin" ?
                                                  item?.attributes?.ultimate_state ?
                                                    <Button className={classes.quoteButton} disabled={true} style={{ backgroundColor: "#E4E8F0", color: "#94979B" }} onClick={() => { this.handleClickOpenEditQuoteModal(item); }}>
                                                      Approved
                                                    </Button>
                                                    : item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                    <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                      {t("ApprovalRequest.editQuote")}
                                                    </Button>
                                                  :
                                                  null
                                                // item?.attributes?.ultimate_state !== "Quote Requested" &&
                                                // <Button className={classes.quoteButton} style={{ border: `1px solid ${secondaryColorToBeChanged}`, color: secondaryColorToBeChanged }} onClick={() => { this.handleClickOpenEditQuoteModal(item) }}>
                                                //   {t("ApprovalRequest.editQuote")}
                                                // </Button>
                                              }
                                            </>
                                            {/* } */}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                            :
                            (this.state.loading == true && <b><p style={{ textAlign: "center", margin: "100px 0px 0px 15px" }}>
                              {/* Woohoo! You've no pending tasks in your approval requests */}
                            </p></b>)
                        }
                      </Grid>



                    </AccordionDetails>

                  </Accordion>

                </Grid>


              </>
            )

          }

        </Grid>

        {/* Task view Send Quote */}
        {this.state.isCurrentSendQuoteSelected && this.state.currentTasks ? (
          <Dialog
            open={this.state.setQuoteOpen}
            onClose={this.handleCloseTaskModal}
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.TaskViewTitle}>
              <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID: {this.state.currentTasks?.attributes?.order_number}</p>
              <p style={{ fontSize: "14px" }}>Project: {this.state.currentTasks?.attributes?.project_name}</p>
              <span>
                <CloseIcon onClick={this.handleCloseTaskModal} />
              </span>
            </div>

            {/* </DialogTitle> */}
            <Divider />
            <Backdrop
              className={classes.backdrop}
              open={this.state.loading}
              onClick={() => this.setState({ loading: false })}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent style={{ overflow: "unset" }}>
              <DialogContentText id="alert-dialog-description">
                {Object.keys(this.state.currentTasks).length !== 0 ?
                  <>
                    <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                      <Grid item xs={8} style={{ backgroundColor: "#FAFAFC" }}>
                        <div>
                          <div style={{ margin: "20px" }}>
                            <p style={{ color: secondaryColorToBeChanged, fontSize: "20px" }}>{this.state.currentTasks?.attributes?.title}</p>
                            {/* <p style={{ fontSize: "14px", color: "#282828" }}>{this.state.currentTasks?.attributes?.title}</p> */}
                            <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginBottom: "0px" }}>Blockers</p>
                            {this.state.blockersDatas.length !== 0 ?
                              this.state.blockersDatas?.map((items: any) => (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox color="primary" name="is_completed"
                                        checked={items?.attributes?.is_completed}
                                        value={items?.attributes?.is_completed}
                                        onChange={(e) => { this.handleChangeSendQuoteBlockerSelection(items, e.target.checked) }}
                                      />}
                                    className={items.attributes?.is_completed && classes.checkdecoration}

                                    label={items.attributes?.description}
                                  // label="Secondary"
                                  />
                                </>
                              ))
                              : ""
                            }
                            {/* checkbox */}
                            <div style={{ position: "relative" }}>
                              <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton} onClick={this.createNewTaskBlockerModelInSendQuote} /></span>
                              <span><p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px" }} >Blocker reason (e.g. task # or @user</p> </span>
                            </div>

                            <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginTop: "30px", marginBottom: "0px" }}>Subtasks</p>
                            <div>
                              {this.state.subTasksDataInSendQuote.length !== 0 ? this.state.subTasksDataInSendQuote.map((values: any, index: any) => (
                                <FormControlLabel
                                  control={<Checkbox color="primary" name="is_completed"
                                    checked={values.attributes?.is_completed}
                                    value={values.attributes?.is_completed}
                                    onChange={(e) => { this.callUpdateSendQuoteSubTaskApi(values, e.target.checked) }}
                                  />}
                                  label={values.attributes?.title}
                                // label="Secondary"
                                />
                              ))
                                : ""
                              }

                            </div>


                            <div style={{ position: "relative" }}>
                              <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton} onClick={this.openSubTaskInSendQuote} /></span>
                              <span>
                                <p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px", marginBottom: "30px" }} >Add new subtask</p> </span>
                            </div>
                            <p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Attachments</p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                                  return (
                                    <React.Fragment>
                                      <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                        <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true }) }}>
                                          <span>
                                            <Card style={{ width: '73px' }}>
                                              <CardMedia
                                                component="img"
                                                alt="template"
                                                style={{ width: 50, height: 60, padding: '5px 10px', cursor: "pointer" }}
                                                height="15"
                                                width="20"
                                                image={item?.attributes?.name.includes("mp4") ? imgFileTemplate : item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                title={item.attributes.name}
                                                className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                              />
                                            </Card>
                                          </span>
                                        </span>
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid>
                                    </React.Fragment>
                                  )
                                }
                              }
                              )}
                            </div>
                            <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px", marginBottom: "33px", cursor: 'pointer' }}
                              // onClick={() => {
                              //   localStorage.setItem("task", "0")
                              //   this.fileInput1.click()
                              // }}
                              onClick={() => {
                                localStorage.setItem("task", "0")
                                // this.fileInput1.click()
                                this.getMediaAssets()
                                this.handleUploadOpenDialog()
                              }}
                            >
                              <ReactS3Uploader
                                type="file"
                                id="upload"
                                name="pic"
                                getSignedUrl={this.getSignedUrl}
                                uploadRequestHeaders={{
                                  'x-amz-acl': 'public-read'
                                }}
                                s3path="/uploads/"
                                contentDisposition="auto"
                                accept="**"
                                capture={true}
                                inputRef={cmp => this.fileInput1 = cmp}
                                onChange={(event: any) =>
                                  this.inputFileChanged(event)
                                }
                                style={{
                                  visibility: "hidden",
                                  position: "absolute",
                                  alignContent: "center", backgroundColor: 'white',
                                  color: '#419bf9',
                                  width: "280px", padding: 20,
                                  fontSize: 16, fontWeight: 'bold',
                                  borderWidth: 1,
                                  borderColor: '#419bf9',
                                  borderRadius: 10,
                                  borderStyle: 'dashed',
                                  fontVariant: "initial",
                                  textTransform: 'none',
                                  marginBottom: "20px"
                                }}
                              />
                              <p style={{ color: " #1a76b0", marginLeft: "42%" }}>+</p>

                            </div>
                            {this.state.currentTaskBriefingDatas?.slice(0).map((briefedValues: any, index: any) => {
                              console.log(briefedValues)
                              console.log(briefedValues.attributes.value_type)

                              return (
                                <div style={{ marginTop: "40px", marginLeft: "0px" }}>

                                  {briefedValues.attributes.value_type == "file" ?
                                    (
                                      <>
                                        <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                          <>
                                            {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                              briefedValues.attributes.briefed_value_files.data.length > 0 &&
                                              briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                                return (
                                                  <Fragment>
                                                    <p style={{ fontSize: '14px', marginTop: "0px" }}>{file.attributes.value}</p>
                                                    {/* <a href={file.attributes.file_blob_signed_id_url} target="_blank" style={{ marginRight: "10px", marginBottom: "30px" }}> */}
                                                    {/* <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}> */}
                                                    <span onClick={(e) => { this.setState({ fileId: file?.id, file_blob_signed_id_url: file?.attributes?.file_blob_signed_id_url, fileImage: file?.attributes?.name, setOpenImageDialog: true }) }}>
                                                      <Card style={{ width: '73px', }}>
                                                        <CardMedia
                                                          component="img"
                                                          alt="template"
                                                          style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                          height="15"
                                                          width="20"
                                                          image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                        />
                                                      </Card>
                                                    </span>
                                                    {/* </a> */}
                                                  </Fragment>
                                                )
                                              })
                                            }
                                          </>
                                        </div>
                                      </>
                                    )
                                    :
                                    briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image" ?
                                      (
                                        <>
                                          <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                            <>
                                              {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                                briefedValues.attributes.briefed_value_files.data.length > 0 &&
                                                briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                                  return (
                                                    <Fragment>
                                                      <span onClick={(e) => { this.setState({ fileId: file?.id, file_blob_signed_id_url: file?.attributes?.file_blob_signed_id_url, fileImage: "Uploaded Image", setOpenImageDialog: true }) }}>
                                                        <Card style={{ width: '73px', marginRight: "10px", marginBottom: "30px" }}>
                                                          <CardMedia
                                                            component="img"
                                                            alt="template"
                                                            style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                            height="15"
                                                            width="20"
                                                            image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                            // title={item.attributes.name}
                                                            className={classes.templateHover}
                                                          />
                                                        </Card>
                                                      </span>
                                                    </Fragment>
                                                  )
                                                })
                                              }
                                            </>
                                          </div>
                                        </>
                                      )
                                      // (
                                      //   <>
                                      //     {briefedValues.attributes.briefed_value_files.data !== null && briefedValues.attributes.briefed_value_files.data.length !== 0 ?
                                      //     briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url &&
                                      //       <Fragment>
                                      //         <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                      //         <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                      //         <a href={briefedValues.attributes.file_blob_signed_id_url} target="_blank">
                                      //           {/* <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}> */}
                                      //           {/* <span>
                                      //             <Card style={{ width: '73px' }}>
                                      //               <CardMedia
                                      //                 component="img"
                                      //                 alt="template"
                                      //                 style={{ width: 50, height: 60, padding: '5px 10px' }}
                                      //                 height="15"
                                      //                 width="20"
                                      //                 image={briefedValues.attributes.file_blob_signed_id_url ? briefedValues.attributes.file_blob_signed_id_url : imgFileTemplate}
                                      //               />
                                      //             </Card>
                                      //           </span> */}
                                      //         </a>
                                      //         <span>
                                      //           <Card style={{ width: '73px' }}>
                                      //             <CardMedia
                                      //               component="img"
                                      //               alt="template"
                                      //               style={{ width: 50, height: 60, padding: '5px 10px', cursor:"pointer" }}
                                      //               height="15"
                                      //               width="20"
                                      //               image={briefedValues.attributes ? briefedValues.attributes.briefed_value_files ?briefedValues.attributes.briefed_value_files.data ? briefedValues.attributes.briefed_value_files.data[0] ? briefedValues.attributes.briefed_value_files.data[0].attributes ? briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url : imgFileTemplate: imgFileTemplate: imgFileTemplate : imgFileTemplate : imgFileTemplate}
                                      //               // title={item.attributes.name}
                                      //               className={classes.templateHover}
                                      //               onClick={(e:any) =>this.handleFileMediaClick(briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url)}
                                      //             />
                                      //           </Card>
                                      //         </span>
                                      //       </Fragment>
                                      //       :""
                                      //     }
                                      //   </>
                                      // )
                                      // :
                                      // briefedValues.attributes.value_type === "image" ?
                                      //   (
                                      //     <>
                                      //       {briefedValues.attributes.briefed_value_files.data !== null && briefedValues.attributes.briefed_value_files.data.length !== 0 ?

                                      //       briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url &&
                                      //         <Fragment>
                                      //           <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                      //           <span>
                                      //             <Card style={{ width: '73px' }}>
                                      //               <CardMedia
                                      //                 component="img"
                                      //                 alt="template"
                                      //                 style={{ width: 50, height: 60, padding: '5px 10px' }}
                                      //                 height="15"
                                      //                 width="20"
                                      //               image={briefedValues.attributes ? briefedValues.attributes.briefed_value_files ?briefedValues.attributes.briefed_value_files.data ? briefedValues.attributes.briefed_value_files.data[0] ? briefedValues.attributes.briefed_value_files.data[0].attributes ? briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url : imgFileTemplate: imgFileTemplate: imgFileTemplate : imgFileTemplate : imgFileTemplate}
                                      //                 // title={item.attributes.name}
                                      //                 className={classes.templateHover}
                                      //                  onClick={(e:any) =>this.handleFileMediaClick(briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url)}
                                      //               />
                                      //             </Card>
                                      //           </span>
                                      //         </Fragment>
                                      //         :""
                                      //       }
                                      //     </>
                                      //   )
                                      :
                                      briefedValues.attributes.value_type == "date_time" ?
                                        (
                                          <>
                                            {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                              <Fragment>
                                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                                <p style={{ fontSize: '14px', marginTop: "0px" }}>{(briefedValues.attributes.value).replace("T", ", ")}</p>
                                              </Fragment>
                                            }
                                          </>
                                        )
                                        :
                                        (briefedValues.attributes.value_type == "short_text" ||

                                          briefedValues.attributes.value_type == "email" ||
                                          briefedValues.attributes.value_type == "multiple_selection" ||
                                          briefedValues.attributes.value_type == "single_selection" ||
                                          briefedValues.attributes.value_type == "drop_down_select" ||
                                          briefedValues.attributes.value_type == "comment_box"
                                        ) ?
                                          (
                                            <>
                                              {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                                <Fragment>
                                                  <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                                  <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                                </Fragment>
                                              }
                                            </>
                                          )
                                          :
                                          briefedValues.attributes.value_type == 'long_text' ?
                                            (
                                              <div className={classes.paragraphTemp1}>
                                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                                <p dangerouslySetInnerHTML={{ __html: briefedValues?.attributes?.value }}></p>
                                              </div>

                                            ) :

                                            briefedValues.attributes.value_type == "page_separator" ?
                                              "" :
                                              null
                                  }
                                </div>
                              )
                            })}
                          </div>
                          <Accordion className={classes.accordianStyle} defaultExpanded>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>{t("ApprovalRequest.activity")} <img src={downIcon} /></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item xs={12}>

                                <Editor
                                  editorState={this.state.editorState}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  placeholder="Add Comment"
                                  //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                                  wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                                  editorStyle={{ padding: 10, minHeight: 100, background: "#ffffff", }}
                                  toolbarStyle={{ height: 50, fontSize: 18, marginBottom: "0px", background: "#f4f6fc" }}
                                  onEditorStateChange={this.onEditorStateChange}
                                  mention={{
                                    separator: ' ',
                                    trigger: '@',
                                    suggestions: this.state.selectedProjectsMentionsAccountsData.length > 0 ? this.state.selectedProjectsMentionsAccountsData : "No Suggestion"
                                  }}
                                  hashtag={{}}

                                  toolbarCustomButtons={[
                                    <Button
                                      size="small"
                                      onClick={(e) => {
                                        this.state.convertedCommentData?.blocks?.length > 0 ?
                                          this.createCommentInSendQuoteTask(this.state.convertedCommentData) : toast.error("Comment is required");
                                      }}
                                      style={{ color: 'white', backgroundColor: '#173e59', position: 'absolute', right: '70px', padding: "5px 15px 5px 15px" }}
                                      // startIcon={<SendIcon />}
                                      type="submit"
                                      endIcon={<SendIcon />}
                                    >
                                      Send
                                    </Button>
                                    // <button
                                    //   style={{
                                    //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "293px",
                                    //   }}
                                    //   type="submit"

                                    //   onClick={(e) => {
                                    //     this.state.convertedCommentData?.blocks?.length > 0 ?
                                    //       this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                    //   }}
                                    // >
                                    //   <SendIcon />

                                    // </button>

                                  ]}
                                  toolbar={{
                                    options: ["inline"],
                                    inline: {
                                      inDropdown: false,
                                      className: "test",
                                      component: undefined,
                                      dropdownClassName: undefined,
                                      options: ["bold", "italic"],
                                      // bold: { className: "test", style: { height:80 } },
                                      // italic: { className: undefined },
                                    },
                                    list: {
                                      inDropdown: false,
                                      className: "test",
                                      component: undefined,
                                      dropdownClassName: undefined,
                                      // options: ['unordered', 'ordered'],
                                      unordered: { className: undefined },
                                      ordered: { className: undefined },
                                      // indent: { icon: indent, className: undefined },
                                      // outdent: { icon: outdent, className: undefined },
                                    },
                                    // link: {
                                    //   inDropdown: false,
                                    //   className: undefined,
                                    //   component: undefined,
                                    //   popupClassName: undefined,
                                    //   dropdownClassName: undefined,
                                    //   showOpenOptionOnHover: true,
                                    //   defaultTargetOption: '_self',
                                    //   options: ['link'],
                                    //   // link: { icon: link, className: undefined },
                                    //   // unlink: { icon: unlink, className: undefined },
                                    //   linkCallback: undefined
                                    // },
                                    // emoji: {
                                    //   className: undefined,
                                    //   component: undefined,
                                    //   popupClassName: undefined,
                                    //   emojis: [
                                    //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                    //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                                    //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                    //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                    //     '✅', '❎', '',
                                    //   ],
                                    // },

                                    // image: {
                                    //   //icon:,
                                    //   urlEnabled: false,
                                    //   uploadEnabled: true,
                                    //   uploadCallback: this.getSignedUrlTaskFileComment,
                                    //   previewImage: true,
                                    //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    // },

                                  }}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {!this.state.showActivity ?
                          <Fragment>
                            {this.state.taskActivityData?.map((item: any) => {
                              if (item?.attributes?.comment) {
                                return (<>
                                  <div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                      <Avatar src={item?.attributes?.task_comment?.data?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                      <div style={{ marginLeft: "15px" }}>
                                        <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>{item.attributes?.account?.data?.attributes?.first_name} said</p>
                                        <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.updated_at}</p>
                                      </div>
                                    </div>

                                    {item?.attributes?.task_comment?.data?.attributes?.file_blob_signed_id_url !== null && (
                                      <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 25, height: 40, padding: '15px 24px' }}
                                            height="15"
                                            width="20"
                                            image={imgFileTemplate}
                                            title={item.attributes.name}
                                            className={(this.state.fileTaskName?.id == item?.attributes?.task_comment?.data?.id) ? classes.templateHover : "white"}
                                          />
                                        </Card>
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.task_comment.data.attributes.name}
                                        </Typography>
                                      </Grid>
                                    )}
                                    <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                      <p style={{ margin: "0px", lineBreak: "anywhere" }} dangerouslySetInnerHTML={{ __html: item.attributes.task_comment.data?.attributes?.description }} />
                                      {localStorage.getItem("userId") == item.attributes.task_comment.data.attributes?.account_id ? <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }} onClick={() => { this.openSpecificTaskComment(item.attributes.task_comment.data.id) }}>Edit</p> : null}
                                    </div>
                                  </div>
                                </>
                                )
                              } else {
                                return (
                                  <ListItem
                                    alignItems="flex-start"
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar alt="Remy Sharp" src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <Typography variant="body2" style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#282828',
                                          fontStretch: 'normal',
                                          fontStyle: 'normal',
                                          lineHeight: 'normal',
                                          letterSpacing: '0.1px',
                                          marginBottom: '10px'
                                        }}>
                                       {item?.attributes?.account?.data?.attributes?.is_deleted ?"Deleted User":  `${item?.attributes?.account?.data?.attributes?.first_name}  ${item?.attributes?.account?.data?.attributes?.last_name}`}
                                        </Typography>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <Typography variant="body2" style={{
                                            fontSize: '14px',
                                            color: '#282828',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: '0.1px',
                                            margin: '10px 0px'
                                          }}>
                                            <p style={{ margin: "0px", fontFamily: primaryFont }} dangerouslySetInnerHTML={{ __html: item?.attributes?.activity }} />
                                          </Typography>
                                          {item.attributes.updated_at}
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>

                                )
                              }
                            })}
                            {this.state.totalActivityPageCount > 1 && this.state.totalActivityPageCount > this.state.currentPage &&
                              <Button variant="contained" className={classes.button} style={{
                                alignContent: "center", backgroundColor: 'white',
                                color: '#419bf9', width: "100%", padding: 5, fontSize: 16, fontWeight: 'bold',
                                borderWidth: 1,
                                borderColor: '#419bf9',
                                borderRadius: 10,
                                fontVariant: "initial",
                                textTransform: 'none',
                                marginLeft: "15px"
                              }} color="primary" onClick={this.getTaskActivityListPerPageApi}>
                                View more
                              </Button>
                            }
                          </Fragment>
                          :
                          <Fragment>

                          </Fragment>
                        }
                      </Grid>
                      <Grid item xs={4}>

                        <div style={{ marginLeft: "20px", marginTop: "20px" }} className={classes.parentInput} >

                          {this.state.currentTasks.attributes.ultimate_state == "Quote Requested" &&
                            (this.state.permissions == 'approver' || this.state.permissions === 'brand_manager')
                            && this.state.permissionName == "client" ?
                            <Button
                              style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                              type="submit"
                              fullWidth
                              variant="contained"
                            >
                              Quote Requested
                            </Button>
                            : this.state.currentTasks.attributes.ultimate_state == "Quote Approved" ? "" : (
                              <>
                                {
                                  this.state.isEditing ?
                                    <div >
                                      <input type='text' onChange={this.handleInputChangeInSendQuote} onBlur={() => this.setState({ isEditing: false })} defaultValue={0} className={classes.quoteInput} />
                                    </div>
                                    : <h1 onDoubleClick={() =>
                                      this.setState({
                                        isEditing: true
                                      })
                                    }>
                                      {
                                        this.state.quotePrice == "" ?
                                          "€" + 0
                                          :
                                          "€" + this.state.quotePrice
                                      }
                                    </h1>
                                }
                                <Button

                                  style={{ backgroundColor: "white", color: colorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  onClick={() => { this.createTheQuotationPrice(this.state.currentTasks.id, this.state.quotePrice) }}
                                >
                                  {t("ApprovalRequest.sendQuote")}
                                </Button>
                              </>
                            )}
                          <Divider style={{ marginBottom: "20px" }} />
                          {
                            this.state.currentTasks ? Object.keys(this.state.currentTasks).length !== 0 ?
                              <>

                                <Formik
                                  initialValues={{
                                    taskType: "",
                                    status: "",
                                    dueDate: "",
                                    requester: "",
                                    assignee: "",
                                    reviewer: "",
                                  }}
                                  validateOnBlur={false}
                                  validationSchema={Yup.object().shape({
                                    taskType: Yup.string().required(
                                      "taskType is required"
                                    ),
                                    status: Yup.string().required(
                                      "status is required"
                                    ),
                                    dueDate: Yup.string().required(
                                      "dueDate is required"
                                    ),
                                    requester: Yup.string().required(
                                      "Requester is required"
                                    ),
                                    assignee: Yup.string().required(
                                      "assignee is required"
                                    ),

                                    reviewer: Yup.string().required(
                                      "reviewer is required"
                                    ),
                                  })}
                                  onSubmit={(values) => {
                                    console.log(values);
                                  }}
                                  render={({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    isValid,
                                    handleBlur,
                                    setFieldTouched,
                                    isSubmitting,
                                    handleSubmit,
                                  }) => (
                                    <form onSubmit={handleSubmit}>

                                      <TextField
                                        disabled
                                        id="taskType"
                                        name="taskType"
                                        select
                                        size="small"
                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                          }
                                        }}
                                        fullWidth
                                        label={t("assetType.assetType")}
                                        value={this.state.currentTasks?.attributes?.asset_type_name}
                                        className={classes.selectInput}
                                        onChange={handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        variant="outlined"
                                      >
                                        <option>{this.state.currentTasks?.attributes?.asset_type_name}</option>

                                      </TextField>
                                      <TextField
                                        disabled
                                        name="status"
                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                          }
                                        }}
                                        select
                                        SelectProps={{
                                          native: true,
                                        }}
                                        size="small"
                                        fullWidth
                                        label={t("ApprovalRequest.status")}
                                        value={this.state.currentTasks?.attributes?.ultimate_state}
                                        className={classes.selectInput}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option > {this.state.currentTasks?.attributes.ultimate_state}</option>
                                      </TextField>


                                      {/* <TextField
                              focused
                              disabled={this.state.permissionName == "client" ? true : false}
                              type="date"
                              name="Calender"
                              InputProps={{ classes: { root: classes.inputRoot } }}

                              // defaultValue={Date.now()}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              size="small"
                              fullWidth

                              variant="outlined"
                              label="Calender"
                              className={classes.selectInput}

                              value={this.state.currentTasks?this.state.currentTasks.attributes?this.state.currentTasks.attributes.estimated_end_date !== null ? this.state.currentTasks.attributes.estimated_end_date :"" :"" :"" }
                              onChange={(e) => {
                                this.handleDateChangeInSendQuote(e);
                              }}
                            /> */}


                                      <TextField
                                        name="someDate"

                                        type="date"
                                        defaultValue={Date.now()}
                                        disabled={this.state.permissionName == "client" ? true : false}
                                        InputProps={{ classes: { root: classes.inputRoot }, inputProps: { min: dueDate }  }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                          }, shrink: true
                                        }}
                                        fullWidth

                                        variant="outlined"
                                        label="Due Date"
                                        className={classes.selectInput}
                                        value={this.state.currentTasks ? this.state.currentTasks.attributes ? this.state.currentTasks.attributes.estimated_end_date !== null ? this.state.currentTasks.attributes.estimated_end_date : "" : "" : ""}
                                        onChange={(e) => {
                                          this.handleDateChangeInSendQuote(e);
                                        }}
                                      />





                                      <TextField
                                        disabled
                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                          }
                                        }}
                                        id="Requestor"
                                        name="Requestor"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        size="small"
                                        className={classes.selectInput}
                                        fullWidth
                                        label="Requestor"
                                        value={this.state.currentTasks?.attributes?.requestor?.data?.attributes?.first_name}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                      >
                                      </TextField>
                                      <Autocomplete
                                        id="fixed-tags-demo"
                                        className={classes.selectInput}
                                        options={this.state.accountsData}
                                        getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                                        inputValue={this.state.taskReviewerName}
                                        onInputChange={(event, newInputValue) => {
                                          this.setState({
                                            taskReviewerName: newInputValue
                                          })
                                        }}
                                        value={this.state.currentTask?.attributes?.reviewer?.data || ""}
                                        onChange={(e, value) => {
                                          this.handleReviewerChange(e, value);
                                        }}
                                        style={{ width: 280 }}
                                        renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                                      />
                                      {/* {this.state.permissions === 'editor' ? */}

                                      <Autocomplete
                                        id="fixed-tags-demo"
                                        className={classes.selectInput}
                                        options={this.state.assigneeAccountsData}
                                        getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                                        inputValue={this.state.taskAssigneeName}
                                        onInputChange={(event, newInputValue) => {
                                          this.setState({
                                            taskAssigneeName: newInputValue
                                          })
                                        }}
                                        value={this.state.currentTask?.attributes?.assignee?.data || ""}
                                        disabled={this.state.permissionName == "client" ? true : false}
                                        onChange={(e, value) => {
                                          this.handleAssigneeChange(e, value);
                                        }}
                                        style={{ width: 280 }}
                                        renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                                      />

                                      <div style={{ display: "flex" }}>
                                        <TextField
                                          id="Assignee"
                                          name="job_number"
                                          disabled={!((this.state.permissionName == "albertbauer" && this.state.permissions == 'editor') || this.state.permissionName == 'superadmin')}
                                          
                                          InputProps={{ classes: { root: classes.inputRoot } }}
                                          InputLabelProps={{
                                            classes: {
                                              root: classes.labelRoot,
                                              focused: classes.labelFocused
                                            }
                                          }}
                                          fullWidth
                                          label="Job Number"
                                          value={this.state.job_number}
                                          onChange={this.handleJobNumberChange}
                                          className={classes.selectInput}
                                          variant="outlined"
                                        ></TextField>
                                      </div>

                                      <p style={{ fontSize: "16px" }}>{t("ApprovalRequest.taskProgress")}</p>
                                      <div className={classes.progress}>
                                        <span> <p style={{ fontSize: "25px" }}>{this.state.currentTasks.attributes.progress} %</p> </span>

                                        <span style={{ marginLeft: "10px" }}>   <p style={{ fontSize: "16px" }}>{t("ApprovalRequest.completed")}</p></span>
                                      </div>

                                      <div style={{ marginBottom: "20px" }}>
                                        <PrettoSlider
                                          valueLabelDisplay="auto"
                                          aria-label="pretto slider"
                                          defaultValue={this.state.currentTasks?.attributes.progress}
                                          onChange={this.progressPercentageInSendQuote}
                                          disabled={this.state.permissionName == "client" ? true : false}
                                        />

                                      </div>
                                      <Divider />
                                      <div style={{ margin: "20px" }}>
                                        <div style={{ marginBottom: "20px" }}>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.created")}</p></b>
                                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTasks.attributes.created_at}</p>
                                        </div>
                                        <div style={{ marginBottom: "20px" }}>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.updated")}</p></b>
                                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTasks.attributes.updated_at}</p>
                                        </div>
                                        <div>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.taskId")}</p></b>
                                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTasks.attributes?.order_number}</p>
                                        </div>
                                        {this.state.currentTasks.attributes.ultimate_state == "Quote Approved" ?
                                          <>
                                            <Divider />
                                            <div>
                                              <p style={{ fontSize: "16px", marginBottom: "0px" }} >Approved Quote</p>
                                              {/* <p style={{ fontSize: "25px", marginTop: "0px" }} >€{this.state.currentTasks.attributes?.quotation?.data?.attributes?.price}</p> */}
                                            </div>
                                            <Divider />
                                          </>
                                          : ""}

                                      </div>
                                    </form>
                                  )}
                                />
                              </>

                              : "" : ""
                          }
                        </div>

                      </Grid>
                    </Grid>
                  </>

                  : ""}
              </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>
        ) : undefined}



        {/* Task view Edit Quote */}
        {this.state.isCurrentTaskSelected && this.state.currentTask ? (
          <Dialog
            open={this.state.setOpen2}
            onClose={this.handleCloseTaskModal}
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.TaskViewTitle}>
              <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID: {this.state.currentTask.attributes?.order_number}</p>
              <p style={{ fontSize: "14px" }}>Project: {this.state.currentTask.attributes.project_name}</p>
              <span>
                <CloseIcon onClick={this.handleCloseTaskModal} />
              </span>
            </div>

            {/* </DialogTitle> */}
            <Divider />
            <Backdrop
              className={classes.backdrop}
              open={this.state.loading}
              onClick={() => this.setState({ loading: false })}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent style={{ overflow: "unset" }}>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                  <Grid item xs={8} style={{ backgroundColor: "#FAFAFC" }}>
                    <div>
                      <div style={{ margin: "20px" }}>
                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px" }}>{this.state.currentTask.attributes.title}</p>
                        <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }} >Blockers</p></b>
                        {this.state.blockersData.length !== 0 ? this.state.blockersData.map((values: any, index: any) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox color="primary" name="is_completed"
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.handleChangeBlockerSelection(values, e.target.checked) }}
                                />}
                              className={values.attributes?.is_completed && classes.checkdecoration}

                              label={values.attributes?.description}
                            // label="Secondary"
                            />

                          </>
                        )) : ""}
                        {/* checkbox */}
                        <div style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton} onClick={this.handleClickCreateNewTaskBlockerModel} /></span>
                          <span><p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px" }}>Blocker reason (e.g. task # or @user</p> </span>
                        </div>

                        
                        <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>Subtasks</p></b>
                        <div>
                          {this.state.subTasksData.length !== 0 ? this.state.subTasksData.map((values: any, index: any) => (
                            <>
                              <FormControlLabel
                                control={<Checkbox color="primary" name="is_completed"
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.callUpdateSubTaskApi(values, e.target.checked) }}
                                />}
                                label={values.attributes?.title}
                              // label="Secondary"
                              />

                            </>
                          )) : ""}

                        </div>


                        <div style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton} onClick={this.handleClickCreateNewSubTakModel} /></span>
                          <span>
                            <p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px", marginBottom: "30px" }} >Add new subtask</p> </span>
                        </div>
                        <b><p style={{ fontSize: "16px", marginTop: "-10px", marginBottom: "12px", color: "#282828" }}>Attachments</p></b>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                            if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                              return (
                                <React.Fragment>
                                  <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                    <span onClick={(e) => {
                                      this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name, setOpenImageDialog: true }
                                        //  () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }
                                      )
                                    }}>
                                      <Card style={{ width: '73px' }}>
                                        <CardMedia
                                          component="img"
                                          alt="template"
                                          style={{ width: 50, height: 60, padding: '5px 10px' }}
                                          height="15"
                                          width="20"
                                          onClick={() => this.handleAttachmentClick(item.attributes.file_blob_signed_url)}
                                          image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                          title={item.attributes.name}
                                          className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                        />
                                      </Card>
                                    </span>
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              )
                            }
                          }
                          )}
                        </div>
                        <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px", marginBottom: "-10px", cursor: "pointer" }}
                          // onClick={() => {
                          //   localStorage.setItem("task", "0")
                          //   this.fileInput1.click()
                          // }}
                          onClick={() => {
                            localStorage.setItem("task", "0")
                            // this.fileInput1.click()
                            this.getMediaAssets()
                            this.handleUploadOpenDialog()
                          }}
                        >
                          <p style={{ color: " #1a76b0", marginLeft: "42%" }}>+</p>
                        </div>
                        {this.state.currentTaskBriefingData?.length > 0 &&
                            <b><p style={{ fontSize: "16px", marginTop: "24px", marginBottom: "17px", color: "#282828" }}>BRIEFING SUMMARY:</p></b>
                          }
                        {this.state.currentTaskBriefingData?.slice(0).map((briefedValues: any, index: any) => (
                          <div style={{ marginTop: "-10px", marginLeft: "0px" }}>

                            {briefedValues.attributes.value_type == "file" ?

                              (
                                <>
                                  <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                    <>
                                      {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                        briefedValues.attributes.briefed_value_files.data.length > 0 &&
                                        briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                          return (
                                            <Fragment>
                                              <p style={{ fontSize: '14px', marginTop: "0px" }}>{file.attributes.value}</p>
                                              <a href={file.attributes.file_blob_signed_id_url} target="_blank" style={{ marginRight: "10px", marginBottom: "30px" }}>
                                                {/* <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}> */}
                                                <span>
                                                  <Card style={{ width: '73px', }}>
                                                    <CardMedia
                                                      component="img"
                                                      alt="template"
                                                      style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                      height="15"
                                                      width="20"
                                                      image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                    />
                                                  </Card>
                                                </span>
                                              </a>
                                            </Fragment>
                                          )
                                        })
                                      }
                                    </>
                                  </div>
                                </>
                              )
                              :
                              briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image" ?
                                (
                                  <>
                                    <b><p style={{ fontSize: "16px", marginBottom: "17px" }}>{briefedValues.attributes.label}</p></b>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                      <>
                                        {briefedValues.attributes.briefed_value_files && briefedValues.attributes.briefed_value_files.data &&
                                          briefedValues.attributes.briefed_value_files.data.length > 0 &&
                                          briefedValues.attributes.briefed_value_files.data.map((file: any) => {
                                            return (
                                              <Fragment>
                                                <span onClick={(e) => { this.setState({ fileId: file?.id, file_blob_signed_id_url: file?.attributes?.file_blob_signed_id_url, fileImage: "Uploaded Image", setOpenImageDialog: true }) }}>
                                                  <Card style={{ width: '73px', marginRight: "10px", marginBottom: "30px" }}>
                                                    <CardMedia
                                                      component="img"
                                                      alt="template"
                                                      style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                      height="15"
                                                      width="20"
                                                      image={file.attributes.file_blob_signed_id_url ? file.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                      // title={item.attributes.name}
                                                      className={classes.templateHover}
                                                    />
                                                  </Card>
                                                </span>
                                              </Fragment>
                                            )
                                          })
                                        }
                                      </>
                                    </div>
                                  </>
                                )
                                // (
                                //   <>
                                //     {briefedValues.attributes.briefed_value_files &&
                                //       <Fragment>
                                //         <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                //         <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                //         <a href={briefedValues.attributes.data ? briefedValues.attributes.briefed_value_files.data.length !== 0 ? briefedValues.attributes.briefed_value_files.data[0].attributes ? briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url :"#" :'#' :"#" } target="_blank">
                                //           {/* <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}> */}
                                //           <span>
                                //             <Card style={{ width: '73px' }}>
                                //               <CardMedia
                                //                 component="img"
                                //                 alt="template"
                                //                 style={{ width: 50, height: 60, padding: '5px 10px', cursor:"pointer" }}
                                //                 height="15"
                                //                 width="20"
                                //                 onClick={() => this.handleAttachmentClick(briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url)}
                                //                 image={briefedValues.attributes.briefed_value_files?briefedValues.attributes.briefed_value_files.data ? briefedValues.attributes.briefed_value_files.data.length !== 0 ? briefedValues.attributes.briefed_value_files.data[0].attributes ? briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url :imgFileTemplate :imgFileTemplate :imgFileTemplate:imgFileTemplate }
                                //               />
                                //             </Card>
                                //           </span>
                                //         </a>
                                //       </Fragment>
                                //     }
                                //   </>
                                // )
                                // :
                                // briefedValues.attributes.value_type === "image" ?
                                //   (
                                //     <>
                                //       {briefedValues.attributes.briefed_value_files &&
                                //         <Fragment>
                                //           <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                //           <span>
                                //             <Card style={{ width: '73px' }}>
                                //               <CardMedia
                                //                 component="img"
                                //                 alt="template"
                                //                 style={{ width: 50, height: 60, padding: '5px 10px',cursor:"pointer" }}
                                //                 height="15"
                                //                 width="20"
                                //                  image={briefedValues.attributes.briefed_value_files  ?briefedValues.attributes.briefed_value_files.data ? briefedValues.attributes.briefed_value_files.data.length !== 0 ? briefedValues.attributes.briefed_value_files.data[0].attributes ? briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url :imgFileTemplate :imgFileTemplate :imgFileTemplate :imgFileTemplate }
                                //                 // title={item.attributes.name}
                                //                  onClick={(e:any) =>this.handleFileMediaClick(briefedValues.attributes.briefed_value_files.data[0].attributes.file_blob_signed_id_url)}
                                //                 className={classes.templateHover}
                                //               />
                                //             </Card>
                                //           </span>
                                //         </Fragment>
                                //       }
                                //     </>
                                //   )
                                :
                                briefedValues.attributes.value_type == "date_time" ?
                                  (
                                    <>
                                      {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                        <Fragment>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                          <p style={{ fontSize: '14px', marginTop: "0px" }}>{(briefedValues.attributes.value).replace("T", ", ")}</p>
                                        </Fragment>
                                      }
                                    </>
                                  )
                                  :
                                  (briefedValues.attributes.value_type == "short_text" ||

                                    briefedValues.attributes.value_type == "email" ||
                                    briefedValues.attributes.value_type == "multiple_selection" ||
                                    briefedValues.attributes.value_type == "single_selection" ||
                                    briefedValues.attributes.value_type == "drop_down_select" ||
                                    briefedValues.attributes.value_type == "comment_box"
                                  ) ?
                                    (
                                      <>
                                        {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                          <Fragment>
                                            <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                            <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                          </Fragment>
                                        }
                                      </>
                                    )
                                    :
                                    briefedValues.attributes.value_type == 'long_text' ?
                                      (
                                        <div className={classes.paragraphTemp1}>
                                          <b><p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p></b>
                                          <p dangerouslySetInnerHTML={{ __html: briefedValues?.attributes?.value }}></p>
                                        </div>

                                      )
                                      :
                                      briefedValues.attributes.value_type == "page_separator" ?
                                        "" :
                                        null
                            }
                          </div>
                        ))}
                      </div>
                      <Accordion className={classes.accordianStyle} defaultExpanded>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>{t("ApprovalRequest.activity")} <img src={downIcon} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>

                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              //  className = {this.state.editorState.length > 0 ? "Editor--hide-placeholder .public-DraftEditorPlaceholder-root" : null}
                              wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                              editorStyle={{ padding: 10, minHeight: 100, background: "#ffffff", }}
                              toolbarStyle={{ height: 50, fontSize: 18, marginBottom: "0px", background: "#f4f6fc" }}
                              onEditorStateChange={this.onEditorStateChange}
                              mention={{
                                separator: ' ',
                                trigger: '@',
                                suggestions: this.state.selectedProjectsMentionsAccountsData.length > 0 ? this.state.selectedProjectsMentionsAccountsData : "No Suggestion"
                              }}
                              hashtag={{}}

                              toolbarCustomButtons={[
                                <Button
                                  size="small"
                                  onClick={(e) => {
                                    this.state.convertedCommentData?.blocks?.length > 0 ?
                                      this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                  }}
                                  style={{ color: 'white', backgroundColor: '#173e59', position: 'absolute', right: '70px', padding: "5px 15px 5px 15px" }}
                                  // startIcon={<SendIcon />}
                                  type="submit"
                                  endIcon={<SendIcon />}
                                >
                                  Send
                                </Button>
                                // <button
                                //   style={{
                                //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "293px",
                                //   }}
                                //   type="submit"

                                //   onClick={(e) => {
                                //     this.state.convertedCommentData?.blocks?.length > 0 ?
                                //       this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                //   }}
                                // >
                                //   <SendIcon />

                                // </button>

                              ]}
                              toolbar={{
                                options: ["inline", "link"],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  // options: ['unordered', 'ordered'],
                                  unordered: { className: undefined },
                                  ordered: { className: undefined },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                                link: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  dropdownClassName: undefined,
                                  showOpenOptionOnHover: true,
                                  defaultTargetOption: '_self',
                                  options: ['link'],
                                  // link: { icon: link, className: undefined },
                                  // unlink: { icon: unlink, className: undefined },
                                  linkCallback: undefined
                                },
                                // emoji: {
                                //   className: undefined,
                                //   component: undefined,
                                //   popupClassName: undefined,
                                //   emojis: [
                                //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                                //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                                //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                                //     '✅', '❎', '',
                                //   ],
                                // },

                                // image: {
                                //   //icon:,
                                //   urlEnabled: false,
                                //   uploadEnabled: true,
                                //   uploadCallback: this.getSignedUrlTaskFileComment,
                                //   previewImage: true,
                                //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                // },

                              }}
                            />

                            {/* <Button
                              style={{
                                backgroundColor: colorToBeChanged,
                                color: "white",
                                marginRight: "15px",
                              }}
                              type="submit"
                              variant="contained"
                              onClick={(e) => {
                                this.state.convertedCommentData?.blocks.map((values: any) => {
                                  this.setState({ commentDataText: values.text }, () => {
                                    this.callCreateTaskCommentListApi(this.state.commentDataText)
                                  })
                                })
                              }}
                            >Comment
                            </Button> */}

                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {!this.state.showActivity ?
                      <Fragment>
                        {this.state.taskActivityData?.map((item: any) => {
                          if (item?.attributes?.comment) {
                            return (<>
                              <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                  <Avatar src={item?.attributes?.task_comment?.data?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                  <div style={{ marginLeft: "15px" }}>
                                    <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>{item.attributes?.account?.data?.attributes?.first_name} said</p>
                                    <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.updated_at}</p>
                                  </div>
                                </div>

                                {item?.attributes?.task_comment?.data?.attributes?.file_blob_signed_id_url !== null && (
                                  <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                    <Card style={{ width: '73px' }}>
                                      <CardMedia
                                        component="img"
                                        alt="template"
                                        style={{ width: 25, height: 40, padding: '15px 24px' }}
                                        height="15"
                                        width="20"
                                        image={imgFileTemplate}
                                        title={item.attributes.name}
                                        className={(this.state.fileTaskName?.id == item?.attributes?.task_comment?.data?.id) ? classes.templateHover : "white"}
                                      />
                                    </Card>
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.task_comment.data.attributes.name}
                                    </Typography>
                                  </Grid>
                                )}
                                <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                  <p style={{ margin: "0px", lineBreak: "anywhere" }} dangerouslySetInnerHTML={{ __html: item.attributes.task_comment.data?.attributes?.description }} />
                                  {localStorage.getItem("userId") == item.attributes.task_comment.data.attributes?.account_id ? <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }} onClick={() => { this.openSpecificTaskComment(item.attributes.task_comment.data.id) }}>Edit</p> : null}
                                </div>
                              </div>
                            </>
                            )
                          } else {
                            return (
                              <ListItem
                                alignItems="flex-start"
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar alt="Remy Sharp" src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography variant="body2" style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                      color: '#282828',
                                      fontStretch: 'normal',
                                      fontStyle: 'normal',
                                      lineHeight: 'normal',
                                      letterSpacing: '0.1px',
                                      marginBottom: '10px'
                                    }}>
                                     {item?.attributes?.account?.data?.attributes?.is_deleted ?"Deleted User":  `${item?.attributes?.account?.data?.attributes?.first_name}  ${item?.attributes?.account?.data?.attributes?.last_name}`}
                                    </Typography>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Typography variant="body2" style={{
                                        fontSize: '14px',
                                        color: '#282828',
                                        fontStretch: 'normal',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        letterSpacing: '0.1px',
                                        margin: '10px 0px'
                                      }}>
                                        <p style={{ margin: "0px", fontFamily: primaryFont }} dangerouslySetInnerHTML={{ __html: item?.attributes?.activity }} />
                                      </Typography>
                                      {item.attributes.updated_at}
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>

                            )
                          }
                        })}
                        {this.state.totalActivityPageCount > 1 && this.state.totalActivityPageCount > this.state.currentPage &&
                          <Button variant="contained" className={classes.button} style={{
                            alignContent: "center", backgroundColor: 'white',
                            color: '#419bf9', width: "100%", padding: 5, fontSize: 16, fontWeight: 'bold',
                            borderWidth: 1,
                            borderColor: '#419bf9',
                            borderRadius: 10,
                            fontVariant: "initial",
                            textTransform: 'none'
                          }} color="primary" onClick={this.getTaskActivityListPerPageApi}>
                            View more
                          </Button>
                        }
                      </Fragment>
                      :
                      <Fragment>

                      </Fragment>
                    }
                  </Grid>
                  <Grid item xs={4}>

                    <div style={{ marginLeft: "20px", marginTop: "20px" }} className={classes.parentInput} >
                      {(this.state.permissions === 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                        <h2>
                          {this.state.originalQuote !== this.state.quotePrice ? "€" + this.state.quotePrice
                            :
                            "€" + this.state.currentTask?.attributes?.quotation?.data && this.state.currentTask?.attributes?.quotation?.data?.attributes?.price
                          }
                        </h2>
                        :
                        this.state.isEditing ?

                          <div >
                            <input type='text' onChange={this.handleInputChange} onBlur={() => this.setState({ isEditing: false })} defaultValue={this.state.currentTask?.attributes?.quotation?.data && this.state.currentTask?.attributes?.quotation?.data?.attributes?.price} className={classes.quoteInput} />
                          </div>
                          : <h1 onDoubleClick={() =>
                            this.setState({
                              isEditing: true
                            })

                          }>
                            {this.state.originalQuote !== this.state.quotePrice ? "€" + this.state.quotePrice
                              :
                              "€" + this.state.currentTask?.attributes?.quotation.data && this.state.currentTask?.attributes?.quotation?.data?.attributes?.price
                            }
                          </h1>
                      }
                      {(this.state.permissions === 'approver' || this.state.permissions === 'brand_manager') && this.state.permissionName == "client" ?
                        this.state.currentTask.attributes.ultimate_state === 'Quote Approved' || this.state.currentTask.attributes.ultimate_state === 'Done' || this.state.currentTask.attributes.ultimate_state === 'Inprogress' || this.state.currentTask.attributes.ultimate_state === 'Feedback' ?
                          <Button
                            style={{ backgroundColor: "#E4E8F0", color: "#94979B", border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => { this.taskApproveQuote(this.state.currentTask) }}
                            disabled={true}
                          >
                            Approved
                          </Button>
                          :
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => { this.taskApproveQuote(this.state.currentTask) }}
                          >
                            {t("ApprovalRequest.approveQuote")}
                          </Button>
                        : ((this.state.permissions === 'editor' || this.state.permissions == "brand_manager") && this.state.permissionName !== "client") || this.state.permissionName == "superadmin" ?
                          this.state.currentTask.attributes.ultimate_state === 'Quote Approved' || this.state.currentTask.attributes.ultimate_state === 'Done' || this.state.currentTask.attributes.ultimate_state === 'Inprogress' || this.state.currentTask.attributes.ultimate_state === 'Feedback' ?
                            <Button
                              style={{ backgroundColor: "#E4E8F0", color: "#94979B", border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              onClick={() => { this.taskApproveQuote(this.state.currentTask) }}
                              disabled={true}
                            >
                              Approved
                            </Button>
                            : <Button

                              style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              onClick={() => { this.updateQuotationPrice(this.state.currentTask.attributes.quotation.data.id) }}
                            >
                              {t("ApprovalRequest.editQuote")}
                            </Button>

                          :
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px", boxShadow: "none" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => { this.updateQuotationPrice(this.state.currentTask.attributes.quotation.data.id) }}
                          >
                            {t("ApprovalRequest.sendQuote")}
                          </Button>
                      }

                      <Divider style={{ marginBottom: "20px" }} />
                      <Formik
                        initialValues={{
                          taskType: "",
                          status: "",
                          dueDate: "",
                          requester: "",
                          assignee: "",
                          reviewer: "",
                        }}
                        validateOnBlur={false}
                        validationSchema={Yup.object().shape({
                          taskType: Yup.string().required(
                            "taskType is required"
                          ),
                          status: Yup.string().required(
                            "status is required"
                          ),
                          dueDate: Yup.string().required(
                            "dueDate is required"
                          ),
                          requester: Yup.string().required(
                            "Requester is required"
                          ),
                          assignee: Yup.string().required(
                            "assignee is required"
                          ),

                          reviewer: Yup.string().required(
                            "reviewer is required"
                          ),
                        })}
                        onSubmit={(values) => {
                          console.log(values);
                        }}
                        render={({
                          values,
                          errors,
                          touched,
                          handleChange,
                          isValid,
                          handleBlur,
                          setFieldTouched,
                          isSubmitting,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>

                            <TextField
                              disabled
                              id="taskType"
                              name="taskType"
                              select
                              size="small"
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              fullWidth
                              label={t("assetType.assetType")}
                              value={this.state.currentTask?.attributes?.asset_type_name}
                              className={classes.selectInput}
                              onChange={handleChange}
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>{this.state.currentTask?.attributes?.asset_type_name}</option>

                            </TextField>
                            <TextField
                              disabled
                              name="status"
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              select
                              SelectProps={{
                                native: true,
                              }}
                              size="small"
                              fullWidth
                              label={t("ApprovalRequest.status")}
                              value={this.state.currentTask?.attributes?.ultimate_state}
                              className={classes.selectInput}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option > {this.state.currentTask?.attributes?.ultimate_state}</option>
                            </TextField>
                            <TextField
                              type="date"
                              InputProps={{ classes: { root: classes.inputRoot }, inputProps: { min: dueDate }  }}
                              label="Due Date"
                              defaultValue={Date.now()}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }, shrink: true
                              }}
                              size="small"
                              fullWidth
                              disabled={this.state.permissionName == "client" ? true : false}
                              variant="outlined"
                              className={classes.selectInput}

                              value={this.state.currentTask?.attributes.estimated_end_date}
                              onChange={(e) => {
                                this.handleDateChange(e);
                              }}
                            />
                            <TextField
                              disabled
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              name="requester"
                              select
                              SelectProps={{
                                native: true,
                              }}
                              size="small"
                              className={classes.selectInput}
                              fullWidth
                              label={t("ApprovalRequest.requester")}
                              value={this.state.currentTask?.attributes.requestor.data.attributes.first_name}
                              variant="outlined"
                              onBlur={handleBlur}
                            >
                              <option>{this.state.currentTask?.attributes.requestor.data.attributes.first_name}</option>
                            </TextField>
                            <Autocomplete
                              id="fixed-tags-demo"
                              className={classes.selectInput}
                              options={this.state.accountsData}
                              getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                              inputValue={this.state.taskReviewerName}
                              onInputChange={(event, newInputValue) => {
                                this.setState({
                                  taskReviewerName: newInputValue
                                })
                              }}
                              value={this.state.currentTask?.attributes?.reviewer?.data || ""}
                              onChange={(e, value) => {
                                this.handleReviewerChange(e, value);
                              }}
                              style={{ width: 280 }}
                              renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                            />
                            {/* {this.state.permissions === 'editor' ? */}

                            <Autocomplete
                              id="fixed-tags-demo"
                              className={classes.selectInput}
                              options={this.state.assigneeAccountsData}
                              getOptionLabel={(option: any) => option?.attributes?.first_name || ""}
                              inputValue={this.state.taskAssigneeName}
                              onInputChange={(event, newInputValue) => {
                                this.setState({
                                  taskAssigneeName: newInputValue
                                })
                              }}
                              value={this.state.currentTask?.attributes?.assignee?.data || ""}
                              disabled={this.state.permissionName == "client" ? true : false}
                              onChange={(e, value) => {
                                this.handleAssigneeChange(e, value);
                              }}
                              style={{ width: 280 }}
                              renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                            />
                            {/* <Autocomplete
                              id="fixed-tags-demo"
                              className={classes.selectInput}
                              options={this.state.accountsData}
                              getOptionLabel={(option: any) => option.attributes.first_name}
                              onChange={(e, value) => {
                                console.clear();
                                console.log("Data Account Selected", value);
                                var currentTask = this.state.currentTask
                                currentTask.attributes.reviewer.data = value
                                this.setState({
                                  currentTask: currentTask,
                                }, () => {
                                  console.log(this.state.currentTask,)

                                })
                                this.callUpdateTaskApi();

                              }}
                              style={{ width: 280 }}
                              renderInput={(params) => <TextField {...params} label={t("ApprovalRequest.reviewer")} variant="outlined" />}
                            />
                            {this.state.permissions === 'editor' ?
                              <Autocomplete
                                id="fixed-tags-demo"
                                className={classes.selectInput}
                                options={this.state.accountsData}
                                getOptionLabel={(option: any) => option.attributes.first_name}
                                onChange={(e, value) => {
                                  console.log(e, value);
                                  var currentTask = this.state.currentTask
                                  currentTask.attributes.assignee.data = value
                                  this.setState({
                                    currentTask: currentTask,
                                    // sharedProjectError:value.length>0?false:true

                                  }, () => {
                                    console.log("Current Task Dataaa", this.state.currentTask,)

                                  })
                                  this.callUpdateTaskApi();

                                }}
                                style={{ width: 280 }}
                                renderInput={(params) => <TextField {...params} label={("ApprovalRequest.assignee")} variant="outlined" />}
                              />
                              :
                              ""
                            } */}
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="Assignee"
                                name="job_number"
                                disabled={!((this.state.permissionName == "albertbauer" && this.state.permissions == 'editor') || this.state.permissionName == 'superadmin')}
                               
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                  }
                                }}
                                fullWidth
                                label="Job Number"
                                value={this.state.job_number}
                                onChange={this.handleJobNumberChange}
                                className={classes.selectInput}
                                variant="outlined"
                              ></TextField>
                            </div>

                            <p style={{ fontSize: "16px" }}>{t("ApprovalRequest.taskProgress")}</p>
                            <div className={classes.progress}>
                              <span> <p style={{ fontSize: "25px" }}>{this.state.currentTask?.attributes.progress} %</p> </span>

                              <span style={{ marginLeft: "10px" }}>   <p style={{ fontSize: "16px" }}>{t("ApprovalRequest.completed")}</p></span>
                            </div>

                            <div style={{ marginBottom: "20px" }}>
                              <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask?.attributes.progress} onChange={this.progressPercentage} />

                            </div>
                            <Divider />
                            <div style={{ margin: "20px" }}>
                              <div style={{ marginBottom: "20px" }}>
                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.created")}</p></b>
                                <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask?.attributes.created_at}</p>
                              </div>
                              <div style={{ marginBottom: "20px" }}>
                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.updated")}</p></b>
                                <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask?.attributes.updated_at}</p>
                              </div>
                              <div>
                                <b><p style={{ fontSize: "16px", marginBottom: "0px" }} >{t("ApprovalRequest.taskId")}</p></b>
                                <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask?.attributes?.order_number}</p>
                              </div>


                              {/* <div>
                                <p style={{ fontSize: "16px" }} >{t("ApprovalRequest.finalFilesSignOff")}</p>
                              </div>
                              <div>
                                <div>
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                      if (item?.attributes?.source_file == false && item?.attributes?.final_file == true) {
                                        return (
                                          <React.Fragment>
                                            <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
    
                                              <Card style={{ width: '73px' }} onClick={() => { this.setState({ deleteFileId: item?.id }) }}>

                                                {this.state.permissions == "editor" && (
                                                  <>
                                                    <ExpandMoreIcon style={{ marginLeft: "45px", cursor: "pointer" }} onClick={this.openMenuFilePopUp} />
                                                    <Menu
                                                      id="long-menu"
                                                      anchorEl={this.state.setAnchorEl}
                                                      keepMounted
                                                      open={Boolean(this.state.setAnchorEl)}
                                                      onClose={this.closeMenu}
                                                      PaperProps={{
                                                        style: {
                                                          // maxHeight: ITEM_HEIGHT * 4.5,
                                                          width: '',
                                                        },
                                                      }}
                                                    >
                                                      <MenuItem>
                                                        <RemoveCircleOutlineIcon
                                                          style={{
                                                            fontSize: "20px",
                                                            marginRight: "10px",
                                                            color: "black",
                                                          }}
                                                        />{" "}
                                                        <span onClick={() => { this.deleteTaskFile(this.state.deleteFileId) }}> Delete</span>
                                                      </MenuItem>
                                                      <MenuItem >
                                                        <PublishOutlinedIcon
                                                          style={{
                                                            fontSize: "20px",
                                                            marginRight: "10px",
                                                            color: "black",
                                                            marginBottom: "-5px"
                                                          }}
                                                        />
                                                        <span onClick={() => {
                                                          localStorage.setItem("task", "1")
                                                          this.fileInput2.click()
                                                        }}>
                                                          <ReactS3Uploader
                                                            type="file"
                                                            id="upload"
                                                            name="pic"
                                                            getSignedUrl={this.getSignedUrl}
                                                            uploadRequestHeaders={{
                                                              'x-amz-acl': 'public-read'
                                                            }}
                                                            s3path="/uploads/"
                                                            contentDisposition="auto"
                                                            accept="**"
                                                            capture={true}
                                                            inputRef={cmp => this.fileInput2 = cmp}
                                                            onChange={(event: any) =>
                                                              this.inputFileChanged(event)
                                                            }
                                                            style={{
                                                              visibility: "hidden",
                                                              position: "absolute",
                                                              alignContent: "center", backgroundColor: 'white',
                                                              color: '#419bf9',
                                                              width: "280px", padding: 20,
                                                              fontSize: 16, fontWeight: 'bold',
                                                              borderWidth: 1,
                                                              borderColor: '#419bf9',
                                                              borderRadius: 10,
                                                              borderStyle: 'dashed',
                                                              fontVariant: "initial",
                                                              textTransform: 'none',
                                                              marginBottom: "20px"
                                                            }}
                                                          />
                                                          <p>Upload New Version</p></span>
                                                      </MenuItem>
                                                    </Menu>
                                                  </>
                                                )}

                                                <CardMedia
                                                  component="img"
                                                  alt="template"
                                                  style={{ width: 50, height: 60, padding: '5px 10px' }}
                                                  height="15"
                                                  width="20"
                                                  image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                                  title={item.attributes.name}
                                                  className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                                />
                                                
                                              </Card>
                                              
                                              <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                                {item.attributes.name}
                                              </Typography>
                                            </Grid>
                                          </React.Fragment>
                                        )
                                      }
                                    })}
                                  </div>
                                  {(this.state.permissions === 'editor' || this.state.permissions === 'approver') && (
                                    <>
                                      <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                        onClick={() => {
                                          localStorage.setItem("task", "1")
                                          this.fileInput2.click()
                                        }}
                                      >
                                        <ReactS3Uploader
                                          type="file"
                                          id="upload"
                                          name="pic"
                                          // className={uploaderClassName}
                                          getSignedUrl={this.getSignedUrl}
                                          //accept="image/*"
                                          // onProgress={onProgress}
                                          // onError={onError}
                                          // onFinish={onFinish}
                                          uploadRequestHeaders={{
                                            'x-amz-acl': 'public-read'
                                          }}
                                          s3path="/uploads/"
                                          contentDisposition="auto"
                                          accept="**"
                                          // multiple
                                          capture={true}
                                          //  ref={this.fileInput}
                                          inputRef={cmp => this.fileInput2 = cmp}
                                          onChange={(event: any) =>
                                            this.inputFileChanged(event)
                                          }
                                          style={{
                                            visibility: "hidden",
                                            position: "absolute",
                                            // width: "280px",
                                            alignContent: "center", backgroundColor: 'white',
                                            color: '#419bf9',
                                            width: "280px", padding: 20,
                                            fontSize: 16, fontWeight: 'bold',
                                            borderWidth: 1,
                                            borderColor: '#419bf9',
                                            borderRadius: 10,
                                            borderStyle: 'dashed',
                                            fontVariant: "initial",
                                            textTransform: 'none',
                                            marginBottom: "20px"
                                          }}
                                        />
                                        <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                      </div>
                                    </>
                                  )}
                                </div>
                              </div> */}
                              {/* <div>
                                <p style={{ fontSize: "16px" }}>{t("ApprovalRequest.sourceFiles")}</p>
                              </div>
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.state.fileTaskName && this.state.fileTaskName?.map((item: any) => {
                                  if (item?.attributes?.source_file == true && item?.attributes?.final_file == false) {
                                    return (
                                      <React.Fragment>
                                        <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                          
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px' }}
                                              height="15"
                                              width="20"
                                              image={item.attributes.file_blob_signed_id_url ? item.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              title={item.attributes.name}
                                              className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                            />
                                           
                                          </Card>
                                          
                                          <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                            {item.attributes.name}
                                          </Typography>
                                        </Grid>
                                      </React.Fragment>
                                    )
                                  }
                                })}
                                {(this.state.permissions === 'editor' || this.state.permissions === 'approver') && (
                                  <>
                                    <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                      onClick={() => {
                                        localStorage.setItem("task", "2")

                                        this.fileInput3.click()
                                      }}
                                    >
                                      <ReactS3Uploader
                                        type="file"
                                        id="upload"
                                        name="pic"
                                        // className={uploaderClassName}
                                        getSignedUrl={this.getSignedUrl}
                                        //accept="image/*"
                                        // onProgress={onProgress}
                                        // onError={onError}
                                        // onFinish={onFinish}
                                        uploadRequestHeaders={{
                                          'x-amz-acl': 'public-read'
                                        }}
                                        s3path="/uploads/"
                                        contentDisposition="auto"
                                        accept="**"
                                        // multiple
                                        capture={true}
                                        //  ref={this.fileInput}
                                        inputRef={cmp => this.fileInput3 = cmp}
                                        onChange={(event: any) =>
                                          this.inputFileChanged(event)
                                        }
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          // width: "280px",
                                          alignContent: "center", backgroundColor: 'white',
                                          color: '#419bf9',
                                          width: "280px", padding: 20,
                                          fontSize: 16, fontWeight: 'bold',
                                          borderWidth: 1,
                                          borderColor: '#419bf9',
                                          borderRadius: 10,
                                          borderStyle: 'dashed',
                                          fontVariant: "initial",
                                          textTransform: 'none',
                                          marginBottom: "20px"
                                        }}
                                      />
                                      <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                    </div>
                                  </>
                                )} 
                              </div> */}




                            </div>
                          </form>
                        )}
                      />
                    </div>

                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>
        ) : undefined}

        {/* Modal Dialogues */}
        <Dialog
          open={this.state.setOpen}
          onClose={this.handleCloseModal}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle>
            <Typography variant="h5" >
              You are about to officially start the project - are you sure?
            </Typography>
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={() => { this.handleCloseModal(); this.startProject(this.state.projectId) }} style={{ color: "white", backgroundColor: colorToBeChanged, marginRight: "15px" }} variant="contained">
              YES PLEASE!
            </Button>
            <Button onClick={this.handleCloseModal} style={{ color: colorToBeChanged, border: "1px solid colorToBeChanged" }} variant="outlined">
              NO, I NEED MORE TIME?
            </Button>
          </DialogActions>
        </Dialog>
        {/* Create Blocker In send quote */}
        <div >
          <Dialog maxWidth={false} open={this.state.openTaskBlockerInSendQuote} onClose={this.taskBlockersModelCloseInSendQuote} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>
              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Blocker</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.taskBlockersModelCloseInSendQuote}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  description: "",


                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required(
                    "description is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.createTaskBlockerInSendQuote(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primary'
                              }} fullWidth label="Description"
                              className={classes.confirmInput}
                              name="description"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="description" variant="outlined"
                              helperText={
                                touched.description &&
                                  errors.description ? (<>
                                    description is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>



                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE BLOCKER
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>
        <div >
          <Dialog maxWidth={false} open={this.state.openTaskBlockerCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Blocker</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewTaskBlockerModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  description: "",


                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required(
                    "description is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateTaskBlockerApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primary'
                              }} fullWidth label="Description"
                              className={classes.confirmInput}
                              name="description"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="description" variant="outlined"
                              helperText={
                                touched.description &&
                                  errors.description ? (<>
                                    description is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>



                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE BLOCKER
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>


        {/* end create status popup */}
        {/* Subtask Modal in send quote */}
        <div >
          <Dialog maxWidth={false} open={this.state.openSubTaskCreateModelInSendQuote} onClose={this.openSubTaskInCancelSendQuote} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Subtasks</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.openSubTaskInCancelSendQuote}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  title: ""


                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  title: Yup.string().required(
                    "title is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.createSubTaskInSendQuote(values, this.state.accountId)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic"
                              multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primory'
                              }} fullWidth label="Title"
                              className={classes.confirmInput}
                              name="title"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Title" variant="outlined"
                              helperText={
                                touched.title &&
                                  errors.title ? (<>
                                    title is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.title && errors.title
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>
                          <Grid item xs={6}>
                            {/* <FormControl variant="outlined" style={{
                              marginLeft: 0,
                              minWidth: "100%",
                            }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'gray', fontSize: 16 }}>Users</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.accountId}
                                onChange={this.handleChangeUsers}
                                label="Users"
                              >

                                {this.state.accountsData.length > 0 && this.state.accountsData.map((item: any) => (

                                  <MenuItem value={item.id} style={{ color: 'black', fontSize: 18 }}  >{item.attributes.first_name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Grid>
                          <Grid item xs={12}>
                            {/* <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primory'
                              }} fullWidth label="description"
                              className={classes.confirmInput}
                              name="description"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Enter description" variant="outlined"
                              helperText={
                                touched.description &&
                                  errors.description ? (<>
                                    description is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} /> */}
                          </Grid>


                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE SUB TASK
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={this.state.setOpenImageDialog}
            maxWidth="lg"
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move', backgroundColor: "rgb(0 0 0 / 78%)", padding: "5px 24px 24px" }} id="draggable-dialog-title">
              <Typography style={{ display: "flex", color: "#ffffff", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>
                  {this.state.fileImage}
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    {this.state.currentTask?.attributes?.brand_name} / {this.state.currentTask?.attributes?.project_name}
                  </Typography>
                </div>
                <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                  <Typography onClick={() => this.setState({ setOpenImageDialog: false })}>
                    <img src={CancelIcon} />
                  </Typography>
                </div>
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.attachmentShowContent}>
              {this.state.fileImage && this.state.fileImage.includes(".mp4") ?
                <video className={classes.setAssetInFrame} controls autoPlay>
                  <source src={this.state.file_blob_signed_id_url} type="video/mp4" />
                  <source src={this.state.file_blob_signed_id_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
                :
                <img src={this.state.file_blob_signed_id_url} className={classes.setAssetInFrame} />
              }
            </DialogContent>
          </Dialog>
        </div>

        {/* Create subtask popup */}
        <div >
          <Dialog maxWidth={false} open={this.state.openSubTaskCreateModel} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Subtasks</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewSubTakModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  title: "",



                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  title: Yup.string().required(
                    "title is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateSubTaskApi(values)
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>


                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primory'
                              }} fullWidth label="Title"
                              className={classes.confirmInput}
                              name="title"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Enter Title" variant="outlined"
                              helperText={
                                touched.title &&
                                  errors.title ? (<>
                                    Title is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.title && errors.title
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>


                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE SUB TASK
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>

          {/* Project properties popup */}
          <Dialog onClose={this.handleCloseModalProject} aria-labelledby="customized-dialog-title" open={this.state.openProjectProperties} >
            <div style={{ width: "600px", height: "570px", padding: "40px", fontFamily: primaryFont }}>
              <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px", padding: "0px 0px", marginBottom: "40px", }}>
                {/* {t("HomePage.photoProduction")} */}
                <div style={{ position: "relative" }}>
                  {/* {t("individualCompanyHomeScreen.photoProduction")} */}


                  <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }} >
                    <CloseIcon onClick={this.handleCloseModalProject} />

                  </span>
                </div>


              </DialogTitle>


              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                variant="fullWidth"
                scrollButtons="off"
                // indicatorColor="primary"
                aria-label="full width tabs example"
                textColor="primary"
                classes={{ indicator: classes.indicator }}
                centered
              >
                <Tab
                  label={t("individualCompanyHomeScreen.projectOverview")}
                  className={classes.tab}
                  style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}

                />
                <Tab
                  label={t("individualCompanyHomeScreen.projectDocuments")}
                  className={classes.tab}
                  style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                />
                <Tab
                  label={t("individualCompanyHomeScreen.members")}
                  className={classes.tab}
                  style={{ textTransform: "none", fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                />

              </Tabs>


              {this.state.value === 0 &&
                (

                  <Formik
                    initialValues={{
                      // email: "",
                      // animal: "",
                      // companyName: "",
                      projectTitle: this.state.selectedProjectData?.attributes?.name,
                      projectDescription: this.state.selectedProjectData?.attributes?.description,
                      //shareTheProjectWithOthers:this.state.sharedProject,
                      //   includeAPersonalMessageWithYourInvitation: ''

                    }}

                    validateOnBlur={false}
                    validationSchema={Yup.object().shape({
                      // projectDescription: Yup.string()
                      //   .required("projectDescription is required"),


                      // includeAPersonalMessageWithYourInvitation: Yup.string()
                      //   .required("Include A Personal MessageWith Your Invitation is required"),

                    })}
                    onSubmit={(values) => {
                      //console.log(values);
                      this.callUpdateProjectPropertiesApi(values, this.state.sharedProject);
                      // this.setState({
                      //   sharedProjectError: this.state.sharedProject ? true : false
                      // }, () => {
                      //   console.log(this.state.sharedProjectError, "error")
                      // })
                      // this.goToConfirmationAfterPasswordChange(values);
                      // this.props.nextStep(values);
                    }}

                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      isValid,
                      setFieldValue,
                      handleBlur,
                      setFieldTouched,
                      isSubmitting,
                      handleSubmit,
                    }) => {
                      //console.log(values, "shdfsdiuf", this.state.sharedProject);

                      return (

                        <form onSubmit={handleSubmit}>
                          <DialogContent >
                            <TextField
                              name="projectTitle"
                              type="text"
                              size="small"
                              InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              fullWidth
                              // label={t("projects.projectDescription")}
                              label="Project Title"
                              value={values.projectTitle}
                              className={classes.confirmInput}
                              variant="outlined"

                              onChange={handleChange("projectTitle")}
                              onBlur={handleBlur}
                            />

                            {/* <Autocomplete
                            inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.first_name}
                            id="fixed-tags-demo"
                            className={classes.selectInput}
                            options={this.state.projectPropertiesAccountsData}
                            getOptionLabel={(option: any) => option?.attributes?.first_name ? option?.attributes?.first_name : ''}
                            onChange={(e, value) => {
                              this.callGetProjectPropertiesAccountListApi(value)
                            }}
                            style={{ minWidth: "550px", marginTop: "40px" }}
                            renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                          /> */}
                            <Autocomplete
                              multiple
                              id="fixed-tags-demo"
                              options={this.state.brandTeamsData}
                              style={{ minWidth: "550px" }}
                              getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}

                              onInputChange={(e, value) => {
                                this.callGetProjectPropertiesAccountListApi(value)

                              }}
                              renderOption={(option: any) => (
                                option?.photo_blob_signed_id_url?.length >= 0 ?
                                  <React.Fragment>
                                    <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                                      <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                                      <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                                    }</span>
                                    {option.first_name} {option.last_name}
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    {option?.name}
                                  </React.Fragment>
                              )}
                              // defaultValue={[this.state.accountsData.length!== 0 && this.state.accountsData.length[0]]}
                              onChange={(e, value) => {
                                //console.log(e, value);
                                this.setState({
                                  sharedProject: value,
                                  // sharedProjectError: value.length > 0 ? false : true

                                }, () => {
                                  //console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                                })
                                // this.callGetProjectPropertiesAccountListApi(value)
                              }}

                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  option?.photo_blob_signed_id_url?.length >= 0 ?
                                    <Chip
                                      className={classes.projectChips}
                                      label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                        <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                        <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                                      </Typography>}
                                      {...getTagProps({ index })}

                                      style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                    />
                                    :
                                    <Chip
                                      className={classes.projectChips}
                                      label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                        {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                        <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                                      </Typography>}
                                      {...getTagProps({ index })}

                                      style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                                    />
                                ))
                              }
                              // renderTags={(value, getTagProps) =>
                              //   value.map((option, index) => (
                              //     <Chip
                              //       className={classes.projectChips}
                              //       label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              //         <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.attributes?.photo_blob_signed_id_url} /> </span>
                              //         <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.attributes?.first_name}</span>
                              //       </Typography>}
                              //       {...getTagProps({ index })}

                              //       style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                              //     />
                              //   ))
                              // }

                              renderInput={(params): any => (
                                <TextField
                                  {...params}
                                  label={t("teams.addMembers")}
                                  name="addMembers"


                                  // helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                                  // error={
                                  //   this.state.sharedProjectError
                                  // }

                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  className={classes.confirmInput}
                                  variant="outlined"

                                />
                              )}
                            />




                            <TextField
                              name="projectDescription"

                              multiline
                              rows={5}
                              // InputProps={{ classes: { root: classes.inputRoot } }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              style={{ marginTop: "40px", }}
                              size="small"
                              className={classes.confirmInput}
                              fullWidth
                              // label={t("projects.Includeapersonalmessagewithyourinvitation")}
                              label="Project Description"
                              value={values.projectDescription}
                              variant="outlined"

                              onChange={handleChange("projectDescription")}
                              onBlur={handleBlur}
                            />





                          </DialogContent>
                          <DialogActions style={{ justifyContent: "flex-start" }}>
                            {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                            <Button
                              style={{
                                backgroundColor: colorToBeChanged,
                                color: "white",
                                marginLeft: "15px",
                                width: "100px",
                                height: "48px",
                                marginTop: "15px"
                              }}
                              type="submit"
                              variant="contained"
                            // onClick={()=>
                            //   {this.handleUpdateDuplicateProjectProperties(values.projectTitle,values.projectDescription)}}
                            >Update
                              {/* {t("projects.done")} */}
                            </Button>
                            {/* <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button> */}
                          </DialogActions>
                        </form>


                      )
                    }}
                  />

                )
              }
              {
                this.state.value === 1 &&
                (
                  <div style={{ marginBottom: "2rem", minWidth: "600px" }} >
                    <div className={classes.projectDocuments} >
                      <p style={{ fontSize: "12px" }}>Document Details</p>
                      <p style={{ marginLeft: "60%", fontSize: "12px" }}>Date Details</p>

                    </div>
                    {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.map((media: any) => (
                      <div className={classes.projectDocument} style={{}} >
                        <span style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                          <img src={imageIcon} style={{ height: "14px", width: "18px", padding: "15px" }} />
                          <div>
                            <Link
                              //  href= "/Home/MediaAssets/:brandId/:mediaAssetId/:AssetId"
                              href={`/Home/MediaAssets/${this.state.projectMediaAssetsDetail[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.projectMediaAssetsDetail[0]?.attributes?.name + '/' + media?.attributes?.name + '/' + media.id}
                              // href = {`/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                              underline="none"
                            >

                              <p className={classes.fileName} style={{ color: secondaryColorToBeChanged }}>{media.attributes?.name}</p>
                            </Link>
                            <p className={classes.fileSize}>{media.attributes?.size}</p>
                          </div>
                        </span>
                        <span>
                          <p className={classes.date}>{media.attributes?.updated_at}</p>
                        </span>
                      </div >
                    ))}

                    {this.state.selectedProjectsMediaAssetData && this.state.selectedProjectsMediaAssetData.length > 0 ? <Button
                      className={classes.button}
                      style={{
                        backgroundColor: "#173e59",
                        color: "white",
                        bottom: "-300px",
                        right: "-390px"
                      }}
                      //type="submit"
                      variant="contained"
                      //onClick={this.handleCloseModal}
                      onClick={(e: any) => (window.location.href = `/Home/MediaAssets/${this.state.selectedProjectsMediaAssetData[0]?.attributes?.brand?.data?.attributes?.name}` + '/' + this.state.selectedProjectsMediaAssetData[0].attributes.name + '/' + this.state.selectedProjectsMediaAssetData[0].id)}
                    >
                      Go to Assets folder
                    </Button> :
                      null
                    }

                  </div>

                )
              }
              {
                this.state.value === 2 &&
                (
                  <div style={{ minWidth: "600px", padding: "15px" }}>

                    <p style={{ fontSize: "12px", marginLeft: "28px" }}>
                      Members Details
                    </p>
                    {this.state.selectedProjectsAccountsData && this.state.selectedProjectsAccountsData.map((account: any) => (
                      <div className={classes.members} style={{ marginLeft: "0px" }} >
                        <Avatar alt="Remy Sharp" src={account?.attributes?.photo_blob_signed_id_url} />
                        <div style={{ marginLeft: "10px" }}>
                          <span >
                            <p style={{ fontSize: "14px", textAlign: 'left', margin: "0" }}>{account?.attributes?.first_name}</p>
                          </span>
                          <span >
                            <p style={{ fontSize: "10px" }}> {account?.attributes?.email}</p>
                          </span>
                        </div>
                        <Button
                          className={classes.button}
                          style={{ backgroundColor: "#e4e8f0", color: "#282828", position: "absolute", right: "37px", width: "107px", height: "48px", }}
                          type="submit"
                          variant="contained"
                          onClick={(e) => {
                            this.callDeleteAccountProjectsApi(account.id, this.state.selectedProjectData.id)
                          }}
                        >Remove
                          {/* {t("projects.remove")} */}
                        </Button>
                      </div>
                    ))}

                  </div>
                )
              }
            </div>
          </Dialog>



        </div>

        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter1}
          // keepMounted
          open={Boolean(this.state.anchorElFilter1)}
          onClose={() => {
            this.handleFilterClose2();
          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",
          }}
        >
          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="projectFilter" value={this.state.projectTaskFilterValue} onChange={this.handleFilterChange2} >
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="title0" control={<Radio color="primary" />} label="Name (A-Z)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="title1" control={<Radio color="primary" />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio color="primary" />} label="Created At (latest)" />
                <FormControlLabel value="created_at0" control={<Radio color="primary" />} label="Created At (oldest)" />
                <FormControlLabel value="updated_at1" control={<Radio color="primary" />} label="Updated At (latest)" />
                <FormControlLabel value="updated_at0" control={<Radio color="primary" />} label="Updated At (oldest)" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Menu>


        <Dialog
          open={this.state.openTaskCommentPopUp}
          onClose={this.cancelSpecificTaskComment}
          aria-labelledby="customized-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {"Task Comment"}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.cancelSpecificTaskComment} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Editor
                editorState={this.state.editComment}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                placeholder={this.state.placeholder}
                onFocus={() => this.setState({ placeholder: '' })}
                onBlur={() => this.setState({ placeholder: 'Add Comment' })}
                wrapperStyle={{ height: 200, width: 500, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                editorStyle={{ padding: 10, minHeight: 100 }}
                toolbarStyle={{ height: 50, fontSize: 18, background: "#f5f6fa", marginBottom: "0px", }}
                onEditorStateChange={this.onEditorStateCommentTaskSpecific}
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: this.state.selectedProjectsMentionsAccountsData
                }}
                hashtag={{
                  separator: ' ',
                  trigger: '#',
                }}
                toolbarCustomButtons={[
                  <Button
                    size="small"
                    onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                    style={{ color: 'white', backgroundColor: colorToBeChanged, position: 'absolute', right: '70px', padding: "5px 15px 5px 15px" }}
                    // startIcon={<SendIcon />}
                    type="submit"
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                  // <button
                  //   style={{
                  //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "200px",
                  //   }}
                  //   type="submit"
                  //   onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                  // ><SendIcon />

                  // </button>

                ]}
                toolbar={{
                  options: ["inline", "link"],
                  inline: {
                    inDropdown: false,
                    className: "test",
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic"],
                  },
                  list: {
                    inDropdown: false,
                    className: "test",
                    component: undefined,
                    dropdownClassName: undefined,
                    // options: ['unordered', 'ordered'],
                    unordered: { className: undefined },
                    ordered: { className: undefined },
                  },
                  link: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    dropdownClassName: undefined,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_self',
                    options: ['link'],
                    linkCallback: undefined
                  },
                  // emoji: {
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   emojis: [
                  //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                  //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                  //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  //     '✅', '❎', '',
                  //   ],
                  // },

                  // image: {
                  //   //icon:,
                  //   urlEnabled: false,
                  //   uploadEnabled: true,
                  //   uploadCallback: this.getSignedUrlTaskFileComment,
                  //   previewImage: false,
                  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  // },
                }}
              />
            </Grid>
          </DialogContent>
        </Dialog>


        {/* File View Dialog */}

        <Dialog
          open={this.state.openFileDialog}
          onClose={this.handleFileDialog}
          aria-labelledby="customized-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {"File"}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleFileDialog} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <img src={this.state.selectedFile} alt="file" style={{ width: "auto", height: "auto", maxWidth: "800px", maxHeight: "800px" }} />
            </Grid>
          </DialogContent>
        </Dialog>



        {/* upload Dialog */}
        <Dialog
          onClose={this.handleUploadCloseDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.uploadDialog}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            Upload File
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleUploadCloseDialog} />
            </span>
            {/* <IconButton onClick={this.handleUploadCloseDialog} >
                  <CloseIcon />
                </IconButton> */}
            <Box display="flex" alignItems="center">
              {/* <Box flexGrow={1}> */}
              {/* <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#1a76b0",
                    opacity: 1,
                    letterSpacing: "0px",
                  }}
                >
                  Upload File
                </Typography> */}
              {/* </Box> */}
              {/* <Box>
                <IconButton onClick={this.handleUploadCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </Box> */}
            </Box>
            <Tabs
              value={this.state.dialogTabValue}
              onChange={this.handleUploadTabChange}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label="Media Asset Libraries" value="MediaAssest" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
              <Tab label="Upload" value="Upload" className={classes.tab}
                style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }} />
            </Tabs>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent dividers>
            <Grid>
              {this.state.dialogTabValue === "MediaAssest" ? (
                <AccordionDetails>
                  <Box width="100%" display="flex" flexDirection="column">
                    {/* <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                       border="solid 1px #e4e8f0"
                    > */}

                    {this.state.mediaState > 0 ?
                      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px", marginBottom: "10px" }}>

                        {


                          <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 0 })}>
                            <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                              Brands
                            </Link>
                          </Typography>

                        }
                        {
                          this.state.mediaState >= 1 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 1 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.brandSelected}
                              </Link>
                            </Typography>
                            : null
                        }

                        {
                          this.state.mediaState >= 2 ?
                            <Typography style={{ fontSize: "12px" }} onClick={() => this.setState({ mediaState: 2 })}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>

                                {this.state.mediaAssetSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }
                        {
                          this.state.mediaState >= 3 ?
                            <Typography style={{ fontSize: "12px" }}>
                              <Link style={{ color: secondaryColorToBeChanged, fontSize: "14px", cursor: "pointer", fontFamily: SecondaryFont }}>
                                {this.state.folderSelected}
                              </Link>
                            </Typography>
                            :
                            null
                        }



                      </Breadcrumbs>
                      :
                      null

                    }

                    <Box
                      width="100%"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                    // border="solid 1px #e4e8f0"
                    >
                      {this.state.mediaState == 0 && this.state.assetData.length > 0 && this.state.assetData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 50%'
                          }}>
                            <Box
                              width="calc(100% - 20px)"
                              borderRadius="8px"
                              border="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                // this.setState({mediaState : 1}, ()=> this.loadBrandMediaFolderandFiles(item?.id))
                                this.setState({ brandSelected: item?.attributes?.name }, () => this.loadBrandMediaFolderandFiles(item?.id))
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"



                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      {/* <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "} */}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 1 && this.state.brandMediaAssets.length > 0 && this.state.brandMediaAssets.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 50%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              borderRadius="8px"
                              border="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {


                                this.setState({ mediaAssetSelected: item?.attributes?.name }, () => {
                                  this.getfilesAndFolder(item?.id)

                                })
                              }

                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"


                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                      {this.state.mediaState == 2 && this.state.projectFoldersAssetsData.length > 0 && this.state.projectFoldersAssetsData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 50%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              borderRadius="8px"
                              border="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                localStorage.setItem("grid", "0")
                                this.setState({ folderSelected: item?.attributes?.name }, () => {
                                  this.getMediaFolderAssetFiles(item?.id)
                                })
                              }
                              }
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"

                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}



                      {this.state.mediaState == 2 && this.state.projectFilesAssetsData.length > 0 && this.state.projectFilesAssetsData.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 50%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              borderRadius="8px"
                              border="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"
                                  onClick={() => {
                                    // localStorage.setItem("grid", "0")
                                    //  this.setState({mediaState : 3}, ()=> {
                                    //    this.loadProjectMediaFolderandFiles(item?.id)
                                    //   this.getMediaAsset(item?.id)
                                    //   this.getMediaAssetFiles(item?.id)
                                    //   })

                                  }

                                  }

                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      {/* <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "} */}
                                      <Checkbox
                                        checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                        //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                        onChange={() => this.checkBoxChange(item)}
                                        color="primary"
                                      // sx={{
                                      //   color: secondaryColorToBeChanged,
                                      //   '&.Mui-checked': {
                                      //     color: secondaryColorToBeChanged,
                                      //   },
                                      // }}
                                      />
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}




                      {this.state.mediaState == 3 && this.state.mediaFolderAssetdFiles.length > 0 && this.state.mediaFolderAssetdFiles.map((item: any, index: any) => {
                        return (
                          <div style={{
                            flex: '0 0 50%'
                          }}>

                            <Box
                              width="calc(100% - 20px)"
                              borderRadius="8px"
                              border="1px solid rgb(195 195 195)"
                              margin="0 20px 20px 0"
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="60%"
                                  onClick={() => {


                                  }

                                  }

                                >
                                  <Link
                                    // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                    style={{
                                      color: secondaryColorToBeChanged,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Checkbox
                                        checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                        //checked={this.state.uploadingFilesId.includes(parseInt(item?.id))}
                                        onChange={() => this.checkBoxChange(item)}
                                        color="primary"
                                      // sx={{
                                      //   color: secondaryColorToBeChanged,
                                      //   '&.Mui-checked': {
                                      //     color: secondaryColorToBeChanged,
                                      //   },
                                      // }}
                                      />
                                      {/* <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "} */}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                      })}


                    </Box>
                  </Box>

                </AccordionDetails>
              ) : this.state.dialogTabValue === "Upload" ? (
                <Grid style={{ display: "flex", justifyContent: "center" }}>




                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    style={{
                      alignContent: "center",
                      backgroundColor: "white",
                      color: secondaryColorToBeChanged,
                      width: "280px",
                      padding: 20,
                      fontSize: 16,
                      fontWeight: "bold",
                      borderWidth: 1,
                      borderColor: secondaryColorToBeChanged,
                      borderRadius: 10,
                      borderStyle: "dashed",
                      fontVariant: "initial",
                      textTransform: "none",
                      marginBottom: "20px",
                      height: "70px",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    color="primary"
                    onClick={() => {
                      this.fileInput2.click()
                    }
                    }
                  // type ="file"
                  >
                    <ReactS3Uploader
                      type="file"
                      id="upload"
                      name="pic"
                      // className={uploaderClassName}
                      getSignedUrl={this.getSignedUrl}
                      //accept="image/*"
                      // onProgress={onProgress}
                      // onError={onError}
                      // onFinish={onFinish}
                      uploadRequestHeaders={{
                        'x-amz-acl': 'public-read'
                      }}
                      multiple
                      s3path="/uploads/"
                      contentDisposition="auto"
                      accept="**"
                      // multiple
                      capture={true}
                      //  ref={this.fileInput}
                      inputRef={cmp => this.fileInput2 = cmp}
                      onChange={(event: any) =>
                        this.inputFileChanged(event)
                      }
                      style={{
                        visibility: "hidden",
                        position: "absolute",
                        // width: "280px",
                        alignContent: "center",
                        backgroundColor: "white",
                        color: secondaryColorToBeChanged,
                        width: "280px",
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                        borderWidth: 1,
                        borderColor: secondaryColorToBeChanged,
                        borderRadius: 10,
                        borderStyle: "dashed",
                        fontVariant: "initial",
                        textTransform: "none",
                        marginBottom: "20px",
                      }}
                    />
                    + Upload
                  </Button>


                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContent>
          {this.state.dialogTabValue === "MediaAssest" ?
            <DialogActions
              style={{
                justifyContent: "flex-start",
                marginLeft: "25px",
                padding: "12px",
              }}
            >
              <Button variant="contained"
                // color= {secondaryColorToBeChanged} 
                style={{
                  background: this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  //  background : this.state.uploadingFilesId.length > 0 ? secondaryColorToBeChanged : null,
                  // color : this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                  color: this.state.uploadingFilesId.length > 0 ? "white" : "darkgrey"
                }}
                onClick={() => {
                  this.uploadFiles()
                }}
                disabled={this.state.uploadingFilesId.length > 0 ? false : true}
              >
                Insert
              </Button>
              <Button variant="contained" onClick={this.handleUploadCloseDialog}>Cancel</Button>
            </DialogActions>
            :
            null
          }

        </Dialog>






      </div>
      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(MultilevelApprovalRequest))
// Customizable Area Start

// Customizable Area End
