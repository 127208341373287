import React from "react";
// Customizable Area Start
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import MediaAssetsController, {
  Props,
  configJSON,
} from "./MediaAssetsController";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";

import { withTranslation, Trans } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfOutlined"
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
// import Link from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Svg from "../../../components/src/svgComponents/Svg";
import SearchIcon from "@material-ui/icons/Search";
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import ImageOutlined from "@material-ui/icons/ImageOutlined";
import { Divider } from "react-native-elements";
// import Board from 'react-trello'
import ReactS3Uploader from "react-s3-uploader"
import _ from "lodash";
const defaultUser = require("../../../components/src/defaultuser.png")
// or
// import { InputAdornment } from '@material-ui/core';
import Input from "@material-ui/core/Input";
import { imgFileTemplate } from "../../ProjectPortfolio/src/assets";
import { toast } from "react-toastify";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import { Document, Page } from 'react-pdf';

const downIcon = require("../../../components/src/down.png");
const logo = require("../../../components/src/volkswagen.svg");
// const inviteLogo = require('../../../components/src/svgFiles/group-6.svg')
// const duplicateLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
// const csvExportLogo = require("../../../components/src/svgFiles/group-8.svg");
// const archieveLogo = require("../../../components/src/svgFiles/group-14.svg")
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg");
const csvExportLogo = require("../../../components/src/svgFiles/group-6.svg");
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const filterButton = require("../../../components/src/filter.png");

const top100Films = [
  {
    title: "The Shawshank Redemption",
    year: 1994,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  {
    title: "Fight Club",
    year: 1999,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
  { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "The Lord of the Rings: The Two Towers", year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const styles = {
  root: {
    marginLeft: "40px",
  },
  button: {
    marginLeft: "43px",
    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center",
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
  },
  buttonGroup1: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  inputSerachBox: {
    "&::before": {
      content: "none",
      borderBottom: "none",
    },
    "&:hover": {
      // borderBottom:'none'
    },
    "&::after": {
      borderBottom: "none",
    },
    border: "1px solid #e8edf4",
    background: "#ffffff",
    borderBottom: "1px solid #e8edf4",
    boxSizing: "border-box",
    borderRadius: "8px",
    outline: "none",
    // underline:"none"
  },

  accordianStyle: {
    boxShadow: "none",
  },
  actionCard: {
    backgroundColor: "#e4e8f0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    minHeight: "45px",
    justifyContent: "space-between",
    position : "absolute",
    bottom:0,
    right:0,
    left:0
  },
  //   avatar:{
  // marginLeft:"40px"
  //   },
  heading: {
    fontSize: "14px",
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    borderBottom: "0",
    marginLeft: "10px",
    height: "100%",
    position : "relative",
    minHeight:"300px"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
  },

  title: {
    color: "#282828",
    fontSize: "16px",
  },
  dropDown: {
    fontSize: "16px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  folderDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "20px"
  },
  folderHeaders: {
    fontSize: "16px"
  },
  noOfFiles: {
    fontSize: "16px",
    marginLeft: "20px"
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  tab: {
    "&:p": {
      fontSize: "16px",
      textTransform: "none"
    },
    textTransform: "none"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  assetTitle :{
    display: "-webkit-box",
    maxWidth: "200px",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  pdfPageNavigationPrevButton: {
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#1a76b0",
    marginRight: "10px",
    marginLeft: "auto"
  },
  pdfPageNavigationNextButton: {
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#1a76b0",
    marginRight: "10px",
  },
  pdfPageNavigation: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
} as any;

interface FilmOptionType {
  title: string;
  year: number;
}

class MediaAssets extends MediaAssetsController {
  constructor(props: Props) {
    super(props);


    // Customizable Area Start
    // Customizable Area End
  }
  textArea: any
  handleClickProjects = (event: any) => {
    console.log(event, "h");
    this.setState({
      anchorE2: event.currentTarget,
    });
  };

  // Customizable Area Start
  // Customizable Area End
  handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleClickOpenModal1 = () => {
    this.setState({
      openLink: true,
    });
  };
  handleCloseModal1 = () => {
    this.setState({
      openLink: false,
    });
  };
  navigateToProjectPortfolio = () => {
    console.log(this.props, "prop");
    // window.location.href='/Home/Projectportfolio'
    this.props.history.push("/Home/Projectportfolio");
  };

  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({ openLink: false })

  }


  render() {
    const { t, classes, match } = this.props;
    console.log(this.props, "prop");

    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any


    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    const brandId: string = match.params.brandId || "N/A";
    console.log(match, "matc")
    const mediaAssetId: string = match.params.mediaAssetId || "N/A";
    const assetId: string = match.params.AssetId

    return (
      <div className={classes.root} style={{ fontFamily: primaryFont }}>
        
        <Grid container spacing={0}>
          <Backdrop
            className={classes.backdrop}
            open={this.state.uploading}
          // onClick={() => this.setState({uploading:false})}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/Home/HomePage"
                style={{
                  color: secondaryColorToBeChanged,
                  fontSize: "14px",
                  textDecoration: "none",
                  fontFamily: SecondaryFont
                }}
              >
                Home
              </Link>
             { (this.state.permissionName == "albertbauer" || this.state.permissionName == 'superadmin') && <Link
                to="/home/MediaAssets"
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily: SecondaryFont,
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                Media Asset Library
              </Link>}
              { (this.state.permissionName == "albertbauer" || this.state.permissionName == 'superadmin') && localStorage.getItem("brandGroupSelected") &&
               <Link
                to="/Home/MediaAssetsbrandGroup"
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily: SecondaryFont,
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                Brand Group
              </Link>}

              <Link
                to={"/home/MediaAssets/" + localStorage.getItem("brandasset")}
                style={{
                  color: secondaryColorToBeChanged,
                  fontSize: "14px",
                  textDecoration: "none",
                  fontFamily: SecondaryFont
                }}
              >
                {brandId}
              </Link>

              <Typography style={{ fontSize: "12px", fontFamily: SecondaryFont }}>
                {mediaAssetId}
              </Typography>
            </Breadcrumbs>

            <span>
              <p style={{ fontSize: "32px", margin: "0px 0px 0px 10px", fontFamily: SecondaryFont }}>
                {mediaAssetId}{" "}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Media Asset Library</Typography>
                      <p>Here you can see the media asset library</p>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                  </InfoOutlinedIcon>
                </HtmlTooltip>
              </p>
            </span>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              style={{
                color: secondaryColorToBeChanged,
                marginBottom: "10px",
                textTransform: "none",
                fontFamily : SecondaryFont
              }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter: event.target })}
            >
              Filters
            </Button> */}

            <ButtonGroup
              aria-label="outlined primary button group"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <Button
                // disabled={true}
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                className={
                  this.state.grid ? classes.activeButton : classes.button
                }
                onClick={(e) => {
                  this.changeToGridView(e);
                }}
                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={
                  <AppsOutlinedIcon
                    style={{ color: secondaryColorToBeChanged }}
                  />
                }
              >
                {this.state.grid && <>Grid</>}
              </Button>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                className={
                  this.state.list
                    ? classes.activeButtonList
                    : classes.buttonGroup
                }
                onClick={(e) => {
                  this.changeToListView(e);
                }}
                endIcon={
                  <ListOutlinedIcon
                    style={{ color: secondaryColorToBeChanged }}
                  />
                }
              >
                {this.state.list && (
                  <>
                    <span> List</span>
                  </>
                )}
              </Button>
            </ButtonGroup>

            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(e) => this.setState({ search: e.target.value }, () => {
                console.log("search ,", this.state.search);
                this.searchAssets()
              })}
            />

            <Button
              hidden={true}

              variant="contained"
              className={classes.button}
              size="medium"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: colorToBeChanged,
                color: "white",
                marginBottom: "10px",
              }}
              onClick={this.handleClickOpenModal}
            >
              Create Folder

            </Button>
          </Grid>

          {this.state.grid && (
            <Grid item xs={12}>
              <div style={{ marginTop: "10px" }}>
                <div>
                  
                  <Button
                    variant="contained"
                    style={{
                      color: secondaryColorToBeChanged, float: "right",
                      zIndex: 1,
                      marginRight: "13px", textTransform: "none", left: "-30px", fontFamily: primaryFont, marginBottom: "20px",
                    }}
                    className={classes.button}
                    startIcon={
                      <img src={filterButton} aria-controls="panel1a-content"
                        id="panel1a-header" />
                    }
                    endIcon={
                      <ExpandMoreIcon style={{ color: "black" }} />
                    }
                    onClick={(event) => this.setState({ anchorElFilter: event.target })}
                  >
                    {
                      this.state.brandFilterValue == 'name0' ? 'Name (A-Z)' : this.state.brandFilterValue == 'name1' ? 'Name (Z-A)' :
                        this.state.brandFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.brandFilterValue == 'created_at1' ? 'Created At' :
                          this.state.brandFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                    }
                  </Button>
                  {/* <Button
                            variant="contained"
                            style={{ color: secondaryColorToBeChanged ,float: "right",
                            zIndex: 1,
                            marginRight: "17px" , textTransform: "none" , left: "-30px" , fontFamily : primaryFont }}
                            className={classes.button}
                            startIcon={
                              <img src={filterButton} aria-controls="panel1a-content"
                               id="panel1a-header" />
                            }
                            endIcon={
                              <ExpandMoreIcon style={{ color: "black" }} />
                            }
                            onClick={()=>{
                              if(this.state.sortOrder == "asc"){
                                this.setState({sortOrder : 'desc'},()=> {
                                  this.getAssetsFolderByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                                  this.getAssetsFileByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                                }
                                // this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                                )
                              }
                              else if(this.state.sortOrder == "desc"){
                                this.setState({sortOrder : 'asc'},()=> {
                                  this.getAssetsFolderByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                                  this.getAssetsFileByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                                }
                                // this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)                          
                                )
                              }
                            }
                            }
                          >
                            Name
                          </Button> */}
                </div>
                <Accordion className={classes.accordianStyle} defaultExpanded>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      width: "100%",
                      display: " block !important",
                      padding: "0px 20px"
                    }}
                  >
                    <div style={{
                      display: "flex", flexDirection: "row", width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}>
                      <Typography className={classes.heading}>
                        Assets<img src={downIcon} />
                      </Typography>
                      <Typography
                        style={{ position: "absolute", right: "-1385px" }}
                      >

                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                   {/* {alert('uyrdsd')} */}
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        {this.state.assetData.length > 0 && this.state.assetData.map((item: any, index: any) => {
                          return (
                            <Grid item xs={3} style={{ marginBottom: "20px" }}>
                             
                              <Card className={classes.cardStyle}
                                style={{
                                  cursor: "pointer"
                                }}
                                onClick={() => this.props.history.push(`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/' + item.id)}
                              >
                                <CardContent style={{ minHeight: "165px" }}>
                                  <Typography
                                    className={classes.title}
                                    gutterBottom
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => localStorage.setItem("grid", "1")}
                                    >
                                      {/* <Link
                                        //  to="/Home/MediaAssets/bmw/others"
                                        to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}

                                      style={{
                                         color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                      > */}
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <FolderOpenIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                        />{" "}
                                        <p
                                          style={{
                                            marginTop: "6px",
                                            marginLeft: "7px",
                                          }}
                                        className = {classes.assetTitle}
                                        >
                                          
                                          {item?.attributes?.name}
                                        </p>
                                      </span>
                                      {/* </Link> */}
                                      <IconButton
                                        aria-label="settings"
                                        style={{
                                          color: "black",
                                          marginTop: "-17px",
                                        }}
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          e.persist()
                                          this.setState({ selectedAsset: item, fileFlag: false, anchorEl: e.currentTarget })

                                        }}
                                      >
                                        <MoreVertIcon
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"

                                        />
                                      </IconButton>
                                    </div>
                                  </Typography>
                                  <Box display="flex" flexWrap="wrap">
                                    {/* {Array.from(Array(5).keys()).map((key) => (
                                      <Box
                                        key={key}
                                        bgcolor="#f9f9fc"
                                        height="47px"
                                        width="68px"
                                        m="5px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius="4px"
                                      >
                                        <FileCopyOutlinedIcon />
                                      </Box>
                                    ))} */}
                                  </Box>
                                </CardContent>
                                <CardActions className={classes.actionCard}>
                                  <p style={{ fontSize: "10px",}}>
                                    {item?.attributes?.updated_at} 
                                    <br/>
                                    {t("ApprovalRequest.lastModified")}
                                  </p>
                          

                                  {item?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                    <AvatarGroup max={3}
                                      // className={classes.avatar}
                                      style={{ marginTop: "10px", marginLeft: "20px" }}
                                    >
                                      <Avatar alt="Remy Sharp"
                                        //  style={{ height: "30px", width: "30px" }}
                                        src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                      {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={defaultUser} />
                                      ))}
                                    </AvatarGroup>
                                    :
                                    (
                                      <>
                                        <AvatarGroup max={3}
                                          //  className={classes.avatar}
                                          style={{ marginTop: "10px", marginLeft: "131px" }}
                                        >
                                          {_.times(item.attributes.media_asset_accounts_count, () => (
                                            <Avatar alt="Remy Sharp"
                                              //  style={{ height: "30px", width: "30px" }}
                                              src={defaultUser} />
                                          ))}
                                        </AvatarGroup>
                                      </>

                                    )
                                  }
                                </CardActions>
                              </Card>
                            </Grid>
                          )
                        }
                        )}
                        {
                          this.state.assetFileData.length > 0 && this.state.assetFileData.map((item: any, index: any) => {
                            return (
                              <Grid item xs={3} style={{ marginBottom: "20px" }} >
                                <Card className={classes.cardStyle}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <CardContent style={{ minHeight: "165px" }}>
                                    <Typography
                                      className={classes.title}
                                      gutterBottom
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            // display: "flex",
                                            // flexDirection: "row",
                                          }}
                                        >
                                          {/* <ImageOutlined
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                          />{" "} */}
                                          {
                                            item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg' ?
                                              <p
                                                style={{
                                                  marginTop: "6px",
                                                  marginLeft: "7px",
                                                }}
                                                className = {classes.assetTitle}

                                              >
                                                {item?.attributes?.name}
                                              </p> :
                                              <p
                                                style={{
                                                  marginTop: "6px",
                                                  marginLeft: "7px",
                                                  cursor: 'pointer'
                                                }}
                                                className = {classes.assetTitle}
                                              // onClick={()=> this.imageView(item?.attributes?.file_blob_signed_id_url)}
                                              >
                                                {item?.attributes?.name}
                                              </p>

                                          }
                                        </span>
                                        <IconButton
                                          aria-label="settings"
                                          style={{
                                            color: "black",
                                            marginTop: "-17px",
                                          }}
                                          onClick={(e: any) => {
                                            e.persist()
                                            this.setState({ selectedAsset: item, fileFlag: true, anchorEl: e.currentTarget })
                                            //  this.handleClickMenu(e);
                                          }}
                                        >
                                          <MoreVertIcon
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                            }}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"

                                          />
                                        </IconButton>
                                      </div>
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap">
                                      {/* {Array.from(Array(5).keys()).map((key) => (
                                      <Box
                                        key={key}
                                        bgcolor="#f9f9fc"
                                        height="47px"
                                        width="68px"
                                        m="5px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius="4px"
                                      >
                                        <FileCopyOutlinedIcon />
                                      </Box>
                                    ))} */}
                                      {
                                        (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                          item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                          item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                          <img src={item?.attributes?.file_blob_signed_id_url} style={{ height: "auto", width: "125px", cursor: 'pointer' }}
                                            onClick={() => this.imageView(item.attributes.name, item?.attributes?.file_blob_signed_id_url)} /> :
                                          (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                            < PictureAsPdfRounded style={{ height: "114px", width: "125px" }} onClick={() => this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)} /> :
                                            item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'svg' ?
                                              <svg>
                                                <use href={item?.attributes?.file_blob_signed_id_url} />
                                              </svg>
                                              :
                                              <FileCopyOutlinedIcon style={{ height: "114px", width: "125px" }} />

                                      }

                                    </Box>
                                  </CardContent>
                                  <CardActions className={classes.actionCard} style={{height:"20px"}}>
                                    <p style={{ fontSize: "10px", display: "inline-block" }}>
                                      {" "}
                                      {item?.attributes?.updated_at}
                                       <br/>
                                      {t("ApprovalRequest.lastModified")}
                                    </p>

                                    {item?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{ marginTop: "10px", marginLeft: "131px" }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                            //  className={classes.avatar}
                                            style={{ marginTop: "10px", marginLeft: "131px" }}
                                          >
                                            {_.times(item.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    }
                                    {/* <AvatarGroup
                                    max={4}
                                    className={classes.avatar}
                                    style={{ marginLeft: "30%" }}
                                  >
                                    <Avatar
                                      alt="Remy Sharp"
                                      style={{ height: "30px", width: "30px" }}
                                      src="https://picsum.photos/id/237/200/300"
                                    />
                                    <Avatar
                                      alt="Travis Howard"
                                      style={{ height: "30px", width: "30px" }}
                                      src="https://picsum.photos/id/237/200/300"
                                    />
                                    <Avatar
                                      alt="Cindy Baker"
                                      style={{ height: "30px", width: "30px" }}
                                      src="https://picsum.photos/id/237/200/300 "
                                    />
                                    <Avatar
                                      alt="Trevor Henderson"
                                      style={{ height: "30px", width: "30px" }}
                                      src="https://picsum.photos/id/237/200/300 "
                                    />
                                  </AvatarGroup> */}
                                  </CardActions>
                                </Card>
                              </Grid>
                            )


                          })


                        }
                        {/* <Grid item xs={3}>
                          <Card className={classes.cardStyle}>
                            <CardContent>
                              <Typography
                                className={classes.title}
                                gutterBottom
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "}
                                    <p
                                      style={{
                                        marginTop: "6px",
                                        marginLeft: "7px",
                                      }}
                                    >
                                      Styleguide
                                    </p>
                                  </span>
                                  <IconButton
                                    aria-label="settings"
                                    style={{
                                      color: "black",
                                      marginTop: "-17px",
                                    }}
                                  >
                                    <MoreVertIcon
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(e: any) => {
                                        this.handleClickMenu(e);
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              </Typography>
                              <Box display="flex" flexWrap="wrap">
                                {Array.from(Array(5).keys()).map((key) => (
                                  <Box
                                    key={key}
                                    bgcolor="#f9f9fc"
                                    height="47px"
                                    width="68px"
                                    m="5px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="4px"
                                  >
                                    <FileCopyOutlinedIcon />
                                  </Box>
                                ))}
                              </Box>
                            </CardContent>
                            <CardActions className={classes.actionCard}>
                              <p style={{ fontSize: "10px" }}>
                                {" "}
                                29 Sep 2020 last modified
                              </p>

                              <AvatarGroup
                                max={4}
                                className={classes.avatar}
                                style={{ marginLeft: "30%" }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Travis Howard"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Cindy Baker"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                                <Avatar
                                  alt="Trevor Henderson"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                              </AvatarGroup>
                            </CardActions>
                          </Card>
                        </Grid>
                        <Grid item xs={3}>
                          <Card className={classes.cardStyle}>
                            <CardContent>
                              <Typography
                                className={classes.title}
                                gutterBottom
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "}
                                    <p
                                      style={{
                                        marginTop: "6px",
                                        marginLeft: "7px",
                                      }}
                                    >
                                      Templates
                                    </p>
                                  </span>
                                  <IconButton
                                    aria-label="settings"
                                    style={{
                                      color: "black",
                                      marginTop: "-17px",
                                    }}
                                  >
                                    <MoreVertIcon
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(e: any) => {
                                        this.handleClickMenu(e);
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              </Typography>
                              <Box display="flex" flexWrap="wrap">
                                {Array.from(Array(5).keys()).map((key) => (
                                  <Box
                                    key={key}
                                    bgcolor="#f9f9fc"
                                    height="47px"
                                    width="68px"
                                    m="5px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="4px"
                                  >
                                    <FileCopyOutlinedIcon />
                                  </Box>
                                ))}
                              </Box>
                            </CardContent>
                            <CardActions className={classes.actionCard}>
                              <p style={{ fontSize: "10px" }}>
                                {" "}
                                29 Sep 2020 last modified
                              </p>

                              <AvatarGroup
                                max={4}
                                className={classes.avatar}
                                style={{ marginLeft: "30%" }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Travis Howard"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Cindy Baker"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                                <Avatar
                                  alt="Trevor Henderson"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                              </AvatarGroup>
                            </CardActions>
                          </Card>
                        </Grid>
                        <Grid item xs={3}>
                          <Card className={classes.cardStyle}>
                            <CardContent>
                              <Typography
                                className={classes.title}
                                gutterBottom
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FolderOpenIcon
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                    />{" "}
                                    <p
                                      style={{
                                        marginTop: "6px",
                                        marginLeft: "7px",
                                      }}
                                    >
                                      Other
                                    </p>
                                  </span>
                                  <IconButton
                                    aria-label="settings"
                                    style={{
                                      color: "black",
                                      marginTop: "-17px",
                                    }}
                                  >
                                    <MoreVertIcon
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(e: any) => {
                                        this.handleClickMenu(e);
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={() => {
                                      this.handleClose("projectProperties");
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Folder Properties
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Invite Team Members
                                    </MenuItem>{" "}
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Duplicate
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Export as .CSV
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Archive
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.handleClose();
                                      }}
                                      className={classes.dropDown}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Notification Settings
                                    </MenuItem>
                                  </Menu>
                                </div>
                              </Typography>
                              <Box display="flex" flexWrap="wrap">
                                {Array.from(Array(5).keys()).map((key) => (
                                  <Box
                                    key={key}
                                    bgcolor="#f9f9fc"
                                    height="47px"
                                    width="68px"
                                    m="5px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="4px"
                                  >
                                    <FileCopyOutlinedIcon />
                                  </Box>
                                ))}
                              </Box>
                            </CardContent>
                            <CardActions className={classes.actionCard}>
                              <p style={{ fontSize: "10px" }}>
                                {" "}
                                29 Sep 2020 last modified
                              </p>

                              <AvatarGroup
                                max={4}
                                className={classes.avatar}
                                style={{ marginLeft: "30%" }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Travis Howard"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Cindy Baker"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                                <Avatar
                                  alt="Trevor Henderson"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                              </AvatarGroup>
                            </CardActions>
                          </Card>
                        </Grid>
                      */}
                        <Button variant="contained" className={classes.button} style={{
                          alignContent: "center", backgroundColor: 'white',
                          color: secondaryColorToBeChanged, width: "280px", padding: 20, fontSize: 16, fontWeight: 'bold',
                          borderWidth: 1,
                          borderColor: secondaryColorToBeChanged,
                          borderRadius: 10,
                          borderStyle: 'dashed',
                          fontVariant: "initial",
                          textTransform: 'none',
                          marginBottom: "20px",
                          height: "70px",
                          marginTop: "90px",
                          cursor: 'pointer'
                        }} color="primary"
                          onClick={() => this.fileInput.click()}
                        //type ="file"
                        >
                          <ReactS3Uploader
                            type="file"
                            id="upload"
                            name="pic"
                            // className={uploaderClassName}
                            getSignedUrl={this.getSignedUrl}
                            //accept="image/*"
                            // onProgress={onProgress}
                            // onError={onError}
                            // onFinish={onFinish}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read'
                            }}
                            s3path="/uploads/"
                            contentDisposition="auto"
                            accept="**"
                            multiple
                            capture={true}
                            //  ref={this.fileInput}
                            inputRef={cmp => this.fileInput = cmp}
                            onChange={(event: any) =>
                              this.inputFileChanged(event)
                            }
                            style={{
                              visibility: "hidden",
                              position: "absolute",
                              // width: "280px",
                              alignContent: "center", backgroundColor: 'white',
                              color: secondaryColorToBeChanged,
                              width: "280px", padding: 20,
                              fontSize: 16, fontWeight: 'bold',
                              borderWidth: 1,
                              borderColor: secondaryColorToBeChanged,
                              borderRadius: 10,
                              borderStyle: 'dashed',
                              fontVariant: "initial",
                              textTransform: 'none',
                              marginBottom: "20px"
                            }}
                          />
                          + {t("AdminConsole.uploadNewAsset")}

                        </Button>
                      </Grid>

                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          )}
          {this.state.list && (
            <div style={{
              width: "100%",
              marginTop: "20px",
              marginLeft: "10px",
            }}>
              <div>
                <Button
                  variant="contained"
                  style={{
                    color: secondaryColorToBeChanged, float: "right", marginRight: "17px", marginBottom: "20px",
                    zIndex: 1,
                    textTransform: "none", left: "-34px", fontFamily: primaryFont
                  }}
                  className={classes.button}
                  startIcon={
                    <img src={filterButton} aria-controls="panel1a-content"
                      id="panel1a-header" />
                  }
                  endIcon={
                    <ExpandMoreIcon style={{ color: "black" }} />
                  }
                  onClick={() => {
                    if (this.state.sortOrder == "asc") {
                      this.setState({ sortOrder: 'desc' }, () => {
                        this.getAssetsFolderByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                        this.getAssetsFileByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                      }
                        // this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)


                      )
                    }
                    else if (this.state.sortOrder == "desc") {
                      this.setState({ sortOrder: 'asc' }, () => {
                        this.getAssetsFolderByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                        this.getAssetsFileByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`)
                      }
                        // this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`) 



                      )
                    }

                  }
                  }
                >
                  Name
                </Button>
              </div>
              <>
                <Accordion
                  style={{
                    width: "100%",
                    //  marginTop: "30px"
                  }}
                  className={classes.accordianStyle}
                  defaultExpanded
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.listHeader}>
                      Assets{" "}
                      <img
                        src={downIcon}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box
                        width="100%"
                        borderRadius="8px"
                        border="solid 1px #e4e8f0"
                      >
                        {this.state.assetData.length > 0 && this.state.assetData.map((item: any, index: any) => {
                          return (
                            <div>
                              {/* <Link
                                to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                  style={{
                                    color: secondaryColorToBeChanged,
                                    textDecoration: "none",
                                  }}
                                > */}
                              <Box
                                width="100%"
                                borderRadius="8px"
                                border="solid 1px #e4e8f0"
                                style={{
                                  cursor: "pointer"
                                }}
                                onClick={() => this.props.history.push(`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/' + item.id)}
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"
                                    onClick={() => localStorage.setItem("grid", "0")}

                                  >

                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "}
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                      >
                                        {item?.attributes?.name}
                                      </Typography>

                                    </Box>
                                    {/* </Link> */}
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        marginLeft: "12px",
                                        fontSize: "12px !important",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "0.12px",
                                        color: "#282828",
                                      }}
                                    >
                                      {/* March 25, 2019 6:21 PM, Modified by Matea */}
                                      {item.attributes.updated_at}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        marginLeft: "12px",
                                        fontSize: "12px !important",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "0.12px",
                                        color: "#282828",
                                      }}
                                    >
                                      {item.attributes.size}
                                    </Typography>
                                  </Box>
                                  <Box width="10%"
                                    display="flex"
                                    justifyContent="flex-end">
                                    {/* <AvatarGroup
                                max={4}
                                className={classes.avatar}
                              // style={{ marginLeft: "30%" }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Travis Howard"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Cindy Baker"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                                <Avatar
                                  alt="Trevor Henderson"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                              </AvatarGroup> */}
                                    {item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                            //  className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            {_.times(item.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    }
                                  </Box>
                                  <Box>
                                    <IconButton
                                      aria-label="settings"
                                      style={{
                                        color: "black",
                                      }}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.persist()
                                        this.setState({ selectedAsset: item, fileFlag: false, anchorEl: e.currentTarget })
                                        //  this.handleClickMenu(e);
                                      }}
                                    >
                                      <MoreVertIcon
                                        style={{
                                          color: "black",
                                          fontSize: "16px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"

                                      />
                                    </IconButton>

                                  </Box>
                                </Box>
                              </Box>
                              {/* </Link> */}
                            </div>
                          )


                        })}
                        {this.state.assetFileData.length != 0 && this.state.assetFileData.map((item: any, index: any) => {
                          return (
                            <div>
                              <Box
                                width="100%"
                                borderRadius="8px"
                                border="solid 1px #e4e8f0"
                                style={{
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  if (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                    item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                    item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') {
                                    this.imageView(item.attributes.name, item?.attributes?.file_blob_signed_id_url)
                                  }
                                  else if(item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf'){
                                     this.pdfView(item.attributes.name, item.attributes.file_blob_signed_id_url)
                                  }

                                }}
                              >
                                <Box
                                  height="71.5px"
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  pl="30px"
                                  pr="30px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width="60%"

                                  >
                                    {/* <ImageOutlined
                                style={{
                                  width: "30px",
                                  height: "30px",
                                }}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                              />{" "} */}
                                    {
                                      (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                        //         <ImageOutlined
                                        //   style={{
                                        //     width: "30px",
                                        //     height: "30px",
                                        //   }}
                                        //   aria-controls="simple-menu"
                                        //   aria-haspopup="true"
                                        // />
                                        <Svg color={colorToBeChanged} keyword="MediaAssetLibrarySVg" />
                                        :
                                        (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                          <Svg color={colorToBeChanged} keyword="MediaAssetTypePDF" /> :
                                          <Svg color={colorToBeChanged} keyword="MediaAssetFile" />

                                    }
                                    {
                                      item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg' ?
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                            cursor: 'pointer'
                                          }}
                                        // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                        >
                                          {item.attributes.name}
                                        </Typography> :
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                            cursor: 'pointer'
                                          }}
                                        // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                        >
                                          {item.attributes.name}
                                        </Typography>

                                    }
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        marginLeft: "12px",
                                        fontSize: "12px !important",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "0.12px",
                                        color: "#282828",
                                      }}
                                    >
                                      {/* March 25, 2019 6:21 PM, Modified by Matea */}
                                      {item.attributes.updated_at}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        marginLeft: "12px",
                                        fontSize: "12px !important",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "0.12px",
                                        color: "#282828",
                                      }}
                                    >
                                      {item.attributes.size}
                                    </Typography>
                                  </Box>
                                  <Box width="10%"
                                    display="flex"
                                    justifyContent="flex-end"
                                  >
                                    {item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                            //  className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            {_.times(item.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    }
                                    {/* <AvatarGroup
                                max={4}
                                className={classes.avatar}
                              // style={{ marginLeft: "30%" }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Travis Howard"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300"
                                />
                                <Avatar
                                  alt="Cindy Baker"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                                <Avatar
                                  alt="Trevor Henderson"
                                  style={{ height: "30px", width: "30px" }}
                                  src="https://picsum.photos/id/237/200/300 "
                                />
                              </AvatarGroup> */}
                                  </Box>
                                  <Box>
                                    <IconButton
                                      aria-label="settings"
                                      style={{
                                        color: "black",
                                      }}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.persist()
                                        this.setState({ selectedAsset: item, fileFlag: true, anchorEl: e.currentTarget })
                                        // this.handleClickMenu(e)
                                      }}
                                    >
                                      <MoreVertIcon
                                        style={{
                                          color: "black",
                                          fontSize: "16px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"

                                      />
                                    </IconButton>

                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          )


                        })}


                        {/* <Box
                        border="solid 0.5px #e1e8f0"
                        style={{ opacity: 0.76 }}
                      />
                      <Box
                        height="71.5px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        pl="30px"
                        pr="30px"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          width="40%"
                        >
                          <ImageOutlinedIcon
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                          />{" "}
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "16px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            Folder Name
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "12px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            March 25, 2019 6:21 PM, Modified by Matea
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "12px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            509 KB
                          </Typography>
                        </Box>
                        <Box>
                          <AvatarGroup
                            max={4}
                            className={classes.avatar}
                          // style={{ marginLeft: "30%" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300"
                            />
                            <Avatar
                              alt="Travis Howard"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300"
                            />
                            <Avatar
                              alt="Cindy Baker"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300 "
                            />
                            <Avatar
                              alt="Trevor Henderson"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300 "
                            />
                          </AvatarGroup>
                        </Box>
                        <Box>
                          <IconButton
                            aria-label="settings"
                            style={{
                              color: "black",
                            }}
                          >
                            <MoreVertIcon
                              style={{
                                color: "black",
                                fontSize: "16px",
                              }}
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e: any) => {
                                this.handleClickMenu(e);
                              }}
                            />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => {
                              this.handleClose("projectProperties");
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Folder Properties
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Invite Team Members
                            </MenuItem>{" "}
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Duplicate
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Export as .CSV
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Archive
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Notification Settings
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                      <Box
                        border="solid 0.5px #e1e8f0"
                        style={{ opacity: 0.76 }}
                      />
                      <Box
                        height="71.5px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        pl="30px"
                        pr="30px"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          width="40%"
                        >
                          <ImageOutlinedIcon
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                          />{" "}
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "16px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            Folder Name
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "12px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            March 25, 2019 6:21 PM, Modified by Matea
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              marginLeft: "12px",
                              fontSize: "12px !important",
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.12px",
                              color: "#282828",
                            }}
                          >
                            509 KB
                          </Typography>
                        </Box>
                        <Box>
                          <AvatarGroup
                            max={4}
                            className={classes.avatar}
                          // style={{ marginLeft: "30%" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300"
                            />
                            <Avatar
                              alt="Travis Howard"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300"
                            />
                            <Avatar
                              alt="Cindy Baker"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300 "
                            />
                            <Avatar
                              alt="Trevor Henderson"
                              style={{ height: "30px", width: "30px" }}
                              src="https://picsum.photos/id/237/200/300 "
                            />
                          </AvatarGroup>
                        </Box>
                        <Box>
                          <IconButton
                            aria-label="settings"
                            style={{
                              color: "black",
                            }}
                          >
                            <MoreVertIcon
                              style={{
                                color: "black",
                                fontSize: "16px",
                              }}
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e: any) => {
                                this.handleClickMenu(e);
                              }}
                            />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => {
                              this.handleClose("projectProperties");
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Folder Properties
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Invite Team Members
                            </MenuItem>{" "}
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Duplicate
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Export as .CSV
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Archive
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleClose();
                              }}
                              className={classes.dropDown}
                              style={{ fontSize: "12px" }}
                            >
                              Notification Settings
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    </Box> */}
                        <Box
                          height="48px"
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="8px"
                          border={"dashed 1px " + secondaryColorToBeChanged}

                          mt="11px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.fileInput.click()}
                        >
                          {/* <input
                          style={{
                            position: "absolute",
                            margin: 0,
                            padding: 0,
                            width: "100%",
                            height: "48px",
                            outline: "none",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          type="file"
                          accept="image/*"
                        /> */}

                          <Typography
                            style={{
                              fontWeight: 500,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.1px",
                              color: secondaryColorToBeChanged,
                            }}

                          >
                            <ReactS3Uploader
                              type="file"
                              id="upload"
                              name="pic"
                              // className={uploaderClassName}
                              getSignedUrl={this.getSignedUrl}
                              //accept="image/*"
                              // onProgress={onProgress}
                              // onError={onError}
                              // onFinish={onFinish}
                              uploadRequestHeaders={{
                                'x-amz-acl': 'public-read'
                              }}
                              s3path="/uploads/"
                              contentDisposition="auto"
                              accept="**"
                              capture={true}
                              // ref={this.fileInput}
                              inputRef={cmp => this.fileInput = cmp}
                              onChange={(event: any) =>
                                this.inputFileChanged(event)
                              }
                              multiple
                              style={{
                                visibility: "hidden",
                                position: "absolute",
                              }}
                            />
                            + {t("AdminConsole.uploadNewAsset")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                  </AccordionDetails>
                </Accordion>
              </>
            </div>
          )

          }
        </Grid>
        {/* Modal Dialogues */}
        <Dialog
          onClose={this.handleCloseModalTeam}
          aria-labelledby="customized-dialog-title"
          open={this.state.inviteTeamMembersDialog}
        >
          <DialogTitle id="customized-dialog-title">
            {/* {t("projects.newProjectTitle")} */}
            Invite Team Members
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModalTeam} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{


            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({

            })}
            onSubmit={(values) => {
              console.log(values);

              console.log("pro", this.state, "pro");
              //this.callUpdateProjectsApi(this.state.sharedProject);
              this.setState(
                {
                  sharedProjectError: this.state.sharedProject ? false : true,
                },
                () => {
                  console.log(this.state.sharedProjectError, "error");
                }
              );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>

                    {/* <ReactS3Uploader
  // className={uploaderClassName}
  getSignedUrl={this.getSignedUrl}
  accept="image/*"
  // onProgress={onProgress}
  // onError={onError}
  // onFinish={onFinish}
  uploadRequestHeaders={{
    'x-amz-acl': 'public-read'
  }}
  s3path="/uploads/"
  contentDisposition="auto"
/> */}

                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.accountsData}
                      style={{ minWidth: "550px" }}
                      getOptionLabel={(option: any) => option.attributes.email}
                      // defaultValue={[this.state.accountData.length>0 &&this.state.initialAccountData]}
                      onChange={(e, value) => {

                        console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          sharedProjectError: value.length > 0 ? false : true

                        }, () => {
                          console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index) => {
                          console.log(option, "option")
                          console.log(value, "option2")
                          // console.log(getTagProps,"tagprops")

                          let { email, photo_blob_signed_id_url } = option.attributes
                          return <Chip
                            className={classes.projectChips}
                            label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              <span>
                                {photo_blob_signed_id_url &&
                                  <img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={photo_blob_signed_id_url} />

                                }
                              </span>
                              <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{email}</span>
                            </Typography>}
                            {...getTagProps({ index })}


                            style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                          />

                        }


                        )
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}

                          label={t("teams.addMembers")}
                          name="addMembers"


                          helperText={this.state.sharedProjectError ? "Members is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />


                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                      }}
                      type="submit"
                      variant="contained"
                    >Invite
                      {/* {t("projects.inviteTeamMembers")} */}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModalTeam}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>


        <Dialog
          onClose={() => this.setState({ openImage: false })}
          aria-labelledby="customized-dialog-title"
          open={this.state.openImage}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
            {/* {t("projects.newProjectTitle")} */}
            {/* Shareable Link */}
            {this.state.openImageURL?.name}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={() => this.setState({ openImage: false })} />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* <p> {this.state.selectedAsset?.attributes?.file_blob_signed_id_url} </p> */}
            {/* <textarea
              ref={(textarea) => this.textArea = textarea}
              value={this.state.selectedAsset?.attributes?.file_blob_signed_id_url}
              style={{ height: "125px", width: "750px" }}
            /> */}
            <img src={this.state.openImageURL?.url} style={{ maxHeight: "75vh", maxWidth: "100%" }} />
          </DialogContent>
          {/* <DialogActions style={{ justifyContent: "flex-start" }}>
           <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> 
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="button"
              variant="contained"
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy to Clipboard
                    </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseModal1}
            >
              {t("projects.cancel")}
            </Button>
          </DialogActions>  */}

        </Dialog>


        <Dialog
          onClose={() => this.setState({ openPdf: false })}
          aria-labelledby="customized-dialog-title"
          open={this.state.openPdf}
        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
            {this.state.openPdfURL?.name}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={() => this.setState({ openPdf: false, pageNumber : 1 })} />
            </span>
          </DialogTitle>
          <DialogContent>
            <div>
              <Document
                file={this.state.openPdfURL?.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.pageNumber} />
              </Document>
              {/* <p>Page {this.state.pageNumber} of {this.state.numPages}</p> */}
                    <nav className={classes.pdfPageNavigation}>
                          <p>
                            Page {this.state.pageNumber} of {this.state.numPages}
                          </p>
                        {this.state.numPages > 1 &&  <button
                            className={classes.pdfPageNavigationPrevButton}
                            style={this.state.pageNumber > 1 ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                            disabled={this.state.pageNumber > 1 ? false : true}
                            onClick={this.goToPrevPage}
                          >
                            Prev Page
                          </button>}
                         {this.state.numPages > 1 && <button
                            className={classes.pdfPageNavigationNextButton}
                            style={this.state.numPages && this.state.numPages > this.state.pageNumber ? { cursor: "pointer", opacity: 1 } : { opacity: 0.5 }}
                            disabled={this.state.numPages && this.state.numPages > this.state.pageNumber ? false : true}
                            onClick={this.goToNextPage}
                          >
                            Next Page
                          </button>}
                        </nav>
            </div>
          </DialogContent>
        </Dialog>


        <Dialog
          onClose={this.handleCloseModal1}
          aria-labelledby="customized-dialog-title"
          open={this.state.openLink}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px', color: secondaryColorToBeChanged }}>
            {/* {t("projects.newProjectTitle")} */}
            Shareable Link
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal1} />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* <p> {this.state.selectedAsset?.attributes?.file_blob_signed_id_url} </p> */}
            <textarea
              ref={(textarea) => this.textArea = textarea}
              value={this.state.selectedAsset?.attributes?.file_blob_signed_id_url}
              style={{ height: "125px", width: "750px" }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="button"
              variant="contained"
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy to Clipboard
            </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseModal1}
            >
              {t("projects.cancel")}
            </Button>
          </DialogActions>

        </Dialog>


        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
            {/* {t("projects.newProjectTitle")} */}
            Create New Folder
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: "",

              }}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .matches(/^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gmi, "folder name should not be start and end with special charecters")
                  .required(
                    "folder name is required"
                  ),


              })}
              onSubmit={(values) => {
                console.log(values);
                this.createFolder(values)
                // this.goToConfirmationAfterPasswordChange(values);
                // this.props.nextStep(values);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                isValid,
                setFieldValue,
                handleBlur,
                setFieldTouched,
                isSubmitting,
                handleSubmit,
              }) => {


                return (
                  <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                      <TextField
                        name="name"
                        type="text"
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        fullWidth
                        label="Name"
                        value={values.name}
                        className={classes.confirmInput}
                        variant="outlined"
                        helperText={
                          values.name.length == 0 && touched.name ? (
                            "folder name is required"
                          ) :
                            touched.name &&
                              errors.name ? (
                              "folder name should not be start and end with special charecters"

                            )
                              : (
                                <>
                                </>
                                // "folder name is required"
                              )
                        }
                        error={
                          touched.name && errors.name
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />


                    </DialogContent>
                    <DialogActions style={{ justifyContent: "flex-start" }}>
                      {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                      <Button
                        style={{
                          backgroundColor: colorToBeChanged,
                          color: "white",
                          marginLeft: "15px",
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Create Folder
                      </Button>
                      <Button
                        className={classes.button}
                        style={{ backgroundColor: "white", color: "black" }}
                        type="button"
                        variant="contained"
                        onClick={this.handleCloseModal}
                      >
                        {t("projects.cancel")}
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            />
          </DialogContent>
        </Dialog>











        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose("projectProperties");
          }}
        >
          {this.state.fileFlag ? <MenuItem
            onClick={() => {
              this.handleClose("assetsProperties");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
          >
            Asset Properties
          </MenuItem>
            :
            <MenuItem
              onClick={() => {
                this.handleClose("assetsProperties");
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              Folder Properties
            </MenuItem>
          }
          {/* <MenuItem
            onClick={() => {
              this.handleClose("inviteTeamMember");;
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Invite Team Members
                            </MenuItem>{" "}
          <MenuItem
            onClick={() => {
              this.handleClose();
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Duplicate
                            </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleClose();
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Share
                            </MenuItem> */}
          {this.state.fileFlag &&
            <MenuItem
              onClick={() => {
                this.handleClose("sharableLink");
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              Get Shareable Link
            </MenuItem>
          }
          {this.state.fileFlag && <MenuItem
            onClick={() => {
              this.handleClose("download");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
          >
            Download
          </MenuItem>
          }
           {(this.state.selectedAsset?.attributes?.name == 'Invoices') &&
            
            <MenuItem
              onClick={() => {
                this.handleClose("delete");
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              Delete
            </MenuItem>


          }
          {(this.state.selectedAsset?.attributes?.name != 'Invoices' && this.state.permissionName == 'superadmin') &&
            <MenuItem
              onClick={() => {
                this.handleClose("delete");
              }}
              className={classes.dropDown}
              style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
            >
              Delete
            </MenuItem>


          }


        </Menu>
        <Dialog onClose={() => this.setState({ setFolder: false })} aria-labelledby="customized-dialog-title" open={this.state.setFolder} >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px" }}>
            {/* {t("HomePage.photoProduction")} */}
            <div style={{ position: "relative", color: secondaryColorToBeChanged }}>
              {/* {t("teams.overviewTeam")} */}
              {this.state.fileFlag ? <>Asset Properties</> : <>Folder Properties</>}
              <span style={{ position: "absolute", right: "10px" }} >
                <CloseIcon onClick={() => this.setState({ setFolder: false })} />

              </span>
            </div>


          </DialogTitle>


          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="fullWidth"
            scrollButtons="off"
            indicatorColor="primary"
            aria-label="full width tabs example"
            textColor="primary"
            centered
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              label={t("teams.overview")}
              // label="Folder Properties"
              className={classes.tab}
              style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }}
            />

            <Tab
              // label={t("individualCompanyHomeScreen.members")}
              label=""
              className={classes.tab}
            />

          </Tabs>


          {this.state.value === 0 &&
            (
              <>
                <DialogContent style={{ minWidth: "700px" }}>
                  <DialogContentText id="alert-dialog-description">

                    {this.state.fileFlag ? <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        File name :
                      </li>
                      <span className={classes.noOfFiles} >
                        {this.state.selectedAsset?.attributes?.name}
                      </span>
                    </div> :
                      <div className={classes.folderDiv}>
                        <li className={classes.folderHeaders}>
                          Folder name :
                        </li>
                        <span className={classes.noOfFiles} >
                          {this.state.selectedAsset?.attributes?.name}
                        </span>
                      </div>
                    }

                    {!this.state.fileFlag && <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Number of files :
                      </li>
                      <span className={classes.noOfFiles} >
                        {this.state.selectedAsset?.attributes?.number_of_files}
                      </span>
                    </div>}

                    {/* <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of Folders :
                </li>
                <span className={classes.noOfFiles}>
                   {this.state.noOfFolders} 
                  </span>
              </div> */}
                    <div className={classes.folderDiv}>
                      <li className={classes.folderHeaders}>
                        Size:
                      </li>
                      <span className={classes.noOfFiles}>
                        {this.state.selectedAsset?.attributes?.size}
                      </span>
                    </div>


                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "white", color: "black" }}
                    type="button"
                    variant="contained"
                    onClick={() => this.setState({ setFolder: false })}
                  >
                    close
                  </Button>
                </DialogActions>
              </>
            )
          }

        </Dialog>
        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",  
          }}
        >

          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.brandFilterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{ marginLeft: "5px !important"}} value="name0" control={<Radio />} label="Name (A-Z)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name1" control={<Radio />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio />} label="Created At" />
                {/* <FormControlLabel value="created_at0" control={<Radio />} label="Created At (oldest)"/> */}
                <FormControlLabel value="updated_at1" control={<Radio />} label="Updated At" />
                {/* <FormControlLabel value="updated_at0" control={<Radio />} label="Updated At (oldest)" /> */}

              </RadioGroup>
            </Grid>



          </Grid>
        </Menu>
        {/* <Dialog
          open={this.state.setFolder}
          onClose={()=> this.setState({setFolder :false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle style={{
            fontFamily: "Work Sans !important",
            fontSize: "20px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.15px",
            color: "#282828",
            marginLeft: "20px"

          }} id="alert-dialog-title">{"Asset Properties"}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={()=> this.setState({setFolder :false})} />
            </span></DialogTitle>
          <Divider />
          <DialogContent style={{ minWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">

            { this.state.fileFlag ? <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  File name :
                </li>
                <span className={classes.noOfFiles} >
                {this.state.selectedAsset?.attributes?.name}
                  </span>
              </div> : 
              <div className={classes.folderDiv}>
              <li className={classes.folderHeaders}>
                Folder name :
              </li>
              <span className={classes.noOfFiles} >
              {this.state.selectedAsset?.attributes?.name}
                </span>
            </div>
              }

              { !this.state.fileFlag && <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of files :
                </li>
                <span className={classes.noOfFiles} >
                {this.state.selectedAsset?.attributes?.number_of_files}
                  </span>
              </div>}

              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of Folders :
                </li>
                <span className={classes.noOfFiles}>
                   {this.state.noOfFolders} 
                  </span>
              </div>
              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Size:
                </li>
                <span className={classes.noOfFiles}>
                {this.state.selectedAsset?.attributes?.size}
                  </span>
              </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={()=> this.setState({setFolder :false})}
            >
              close
                    </Button>
          </DialogActions>

        </Dialog> */}
        {/* { this.state.uploading ? <CircularProgress /> : null} */}



        <Dialog
          open={this.state.deleteMediaDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className= {classes.deleteProjectDialog}
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteMediaDialog: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.uploading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
              {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"
                   onClick={(e) => this.deleteAsset()}
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteMediaDialog: false }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

      </div>

    );
  }
}
export default withStyles(styles)(withTranslation()(MediaAssets));
// Customizable Area Start

// Customizable Area End
