import React from "react";
// Customizable Area Start
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { withTranslation, Trans } from "react-i18next";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Backdrop from "@material-ui/core/Backdrop";
import Menu from "@material-ui/core/Menu";
import '../../../components/src/styles/styles.css'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../../components/src/styles/styles.css'
import _, { values } from "lodash";
import DeleteIcon from '@material-ui/icons/Delete';

import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//const downIcon = require('../../../components/src/down.png')
// import Button from '@material-ui/core/Button';
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
// Customizable Area End
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import TeamBuilderController, {
  Props,
  configJSON
} from "./TeamBuilderController";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import { Card, CardMedia } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { borderRadius } from "react-select/src/theme";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import MenuItem from "@material-ui/core/MenuItem";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import * as Yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import { userInfo } from "os";
import { AnyAaaaRecord } from "dns";

const fileIcon = require("../../ProjectPortfolio/assets//ic_file.png")
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const deleteLogo = require('../../../components/src/svgFiles/group-9.svg')
const recentLogo = require('../../../components/src/svgFiles/recent.svg')
const imageIcon = require("../../../components/src/svgFiles/image.svg")
const userImage = require("../../../components/src/view-user-image.png")
const downIcon = require('../../../components/src/down.png')
const sideIcon = require('../../../components/src/side.png')
const defaultUser = require("../../../components/src/defaultuser.png")
const accounts = [
  {
    id: 1,
    company: "Ojas"
  },
  {
    id: 2,
    company: "techm"
  },
  {
    id: 3,
    company: "tcs"
  }
]
const top100Films = [
  { title: 'Neethu', year: 1994, image: require('../../../components/src/volkswagen.svg'), id: 3 },
  { title: 'Mokhan', year: 1972, id: 12 },

];
const teamsImage = [
  {
    "name": "Georgiana Zboncak",
    "id": 1,
    "email": "Abdul.Crona@gmail.com",
    "url": "https://via.placeholder.com/600/92c952",
    "thumbnailUrl": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFSIaWmheGOvq6XehKTN4oNE7h3djPDCqfTdRofuhyqq8YP3MXW1rENPPQ9B9JivSNGfc&usqp=CAU"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 2,
    "email": "Abdul.Crona@gmail.com",
    "title": "reprehenderit est deserunt velit ipsam",
    "url": "https://via.placeholder.com/600/771796",
    "thumbnailUrl": "https://media.gettyimages.com/photos/rupert-grint-attends-servant-panel-during-new-york-comic-con-at-on-picture-id1178879457?s=612x612"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 3,
    "email": "Abdul.Crona@gmail.com",
    "title": "officia porro iure quia iusto qui ipsa ut modi",
    "url": "https://via.placeholder.com/600/24f355",
    "thumbnailUrl": "https://i.ytimg.com/vi/LoyELZTDgn4/hqdefault.jpg"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 4,
    "email": "Abdul.Crona@gmail.com",
    "title": "culpa odio esse rerum omnis laboriosam voluptate repudiandae",
    "url": "https://via.placeholder.com/600/d32776",
    "thumbnailUrl": "https://www.biography.com/.image/t_share/MTE4MDAzNDEwNzkxOTI1MjYy/scarlett-johansson-13671719-1-402.jpg"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 5,
    "email": "Abdul.Crona@gmail.com",
    "title": "natus nisi omnis corporis facere molestiae rerum in",
    "url": "https://via.placeholder.com/600/f66b97",
    "thumbnailUrl": "https://wallpapercave.com/wp/wp2047767.jpg"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 6,
    "email": "Abdul.Crona@gmail.com",
    "title": "accusamus ea aliquid et amet sequi nemo",
    "url": "https://via.placeholder.com/600/56a8c2",
    "thumbnailUrl": "https://upload.wikimedia.org/wikipedia/en/e/e3/Gibbs300px.PNG"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 7,
    "email": "Abdul.Crona@gmail.com",
    "title": "officia delectus consequatur vero aut veniam explicabo molestias",
    "url": "https://via.placeholder.com/600/b0f7cc",
    "thumbnailUrl": "https://i.ytimg.com/vi/LoyELZTDgn4/hqdefault.jpg"
  },
  {
    "name": "Georgiana Zboncak",
    "id": 8,
    "email": "Abdul.Crona@gmail.com",
    "title": "aut porro officiis laborum odit ea laudantium corporis",
    "url": "https://via.placeholder.com/600/54176f",
    "thumbnailUrl": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHd0WA5YveM4Fz69P4576EQ8vhMWsrbY58jA&usqp=CAU"
  },


]
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Html2Tooltip = withStyles((theme) => ({
  tooltip: {
    padding: "24px 13px 30px 28px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);

const styles = {
  rootTeams: {
    marginLeft: "50px"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },

    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
    "@media only screen and (max-width:1920px)": {
      '&': {


        left: "22% !important"
      }
    },
    "@media only screen and (max-width:1440px)": {
      '&': {


        left: "15% !important"
      },

    },
    "@media only screen and (max-width:1366px)": {
      '&': {


        left: "14% !important"
      }
    }
  }
  ,
  sample: {
    width: `calc(100% - 120px)`
  },


  inputSerachBox2: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },

    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
    //  "@media only screen and (max-width:1920px)" :{
    //     '&':{


    //       left:"22% !important"
    //     }
    //  }
  },
  heading: {
    // position:"absolute",
    left: "0px",

  },
  teamMembers: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    alignItems: "center",
    position: "relative",
    paddingRight: "30px"
  },
  modalUserName: {
    // marginTop: "-10px",
    // marginLeft: "13px",
  },
  modalUserEmail: {
    // marginTop: "10px",
    // marginLeft: "-83px",
    color: "#666666"

  },
  dropDown: {
    fontSize: "12px"
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    // height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  accordionStyle: {
    boxShadow: "none"
  },
  circle: {
    marginRight: "5px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardRoot: {
    marginBottom: "20px",
    boxShadow: "none",
    border: "1px solid #e4e8f0",
    borderRadius: "8px"
  },
  activeTeam: {
    marginBottom: "20px",
    boxShadow: "none",
    border: "1px solid #1a76b0",
    borderRadius: "8px"
  },
  '&$disabled': {
    cursor: "not-allowed"
  },
  innerDiv: {
    height: "47px",
    width: "65px",
    backgroundColor: "#f9f9fc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  CardContent: {
    display: "flex",
    flexDirection: "row",
    position: "relative"
  },
  avatar: {
    marginLeft: "20px",
    marginTop: "10px"
  },
  parentCardDiv: {
    "&:last-child": {
      padding: 0
    }
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "125px",
    width: "100%",
    border: "2px dashed #1a76b0",
    '& span': {
      color: "#1a76b0"
    }
  },
  tab: {
    textTransform: "none"
  },
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },

  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    width: "72px",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  highlightText: {
    padding: "5px 5px 5px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
    zIndex: "9999"
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },

} as any
class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true
    })
  }
  handleCloseModal = () => {
    this.setState({
      setOpen: false
    })
  }

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 13) {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString());
      window.location.reload();
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
    this.forceUpdate();
  }

  handleSkipOnboarding = () => {
    localStorage.setItem("onBoardingStep", "20")
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t, classes } = this.props
    console.log(this.props, "prop");

    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    let roles: any
    let permissionRole: any
    let brandId: any
    const permissions = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (permissions) {
      roles = permissions?.role?.data?.attributes?.name,
        brandId = permissions?.brand_id,
        permissionRole = permissions?.role?.data?.attributes?.permission

    }
    let specificBrand: any
    if (brandId) {

      specificBrand = this.state.brandData.filter(function (x: any) {
        return brandId == x.id
      })
      console.log(specificBrand, "spe")

    }
    return (
      //Merge Engine DefaultContainer
      <div className={classes.rootTeams} style={{ fontFamily: SecondaryFont }}>
        <div>
          <Breadcrumbs aria-label="breadcrumb" >
            <Link href="/Home/HomePage"
              //  onClick={this.handleClick} 
              style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
              Home
            </Link>
            <Typography style={{ fontSize: "12px" }}>Teams</Typography>
          </Breadcrumbs>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span>
            <p style={{ fontSize: "32px", marginRight: "10px", marginTop: "0px", marginBottom: "40px", fontFamily: SecondaryFont }}>
              Teams
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Teams</Typography>
                    <p>Here you can see all the Teams.</p>
                  </React.Fragment>
                }
                placement="right"
              >
                <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373", marginLeft: "10px" }} >
                </InfoOutlinedIcon>
              </HtmlTooltip>
            </p>
          </span>

          {
            permissionRole == "viewer" ?
              <>
              </>
              :
              <>
                <Button
                  variant="contained"
                  className={classes.button}
                  size="medium"
                  onClick={this.handleClickOpenModal}
                  style={{ position: "absolute", right: "10px", backgroundColor: colorToBeChanged, color: "white", marginBottom: "10px" }}
                >
                  {t("teams.createNewTeam")}
                </Button>
              </>
          }
          <Input
            id="input-with-icon-adornment"
            className={classes.inputSerachBox}
            placeholder="Search Team Name"
            onChange={(e) => {
              this.setState({ search: e.target.value }, () => {
                if (this.state.search.length > 0) {
                  console.log("search ,", this.state.search);
                  this.searchTeam()
                }
                else {
                  this.componentDidMount2()
                }
              })
            }}
            startAdornment={
              <InputAdornment position="start" >
                <SearchIcon />
              </InputAdornment>
            }
            style={{
              position: 'absolute', left: '21%', top: "216px"
              //  top:'10.7%'
            }}

          />
        </div>
        <div>
          {
            this.state.search == null || this.state.search?.length == 0 ?
              <Grid container spacing={0}>
                <Grid item xs={5} style={{ marginLeft: '-10px' }}>
                  <div>

                    <p style={{ marginLeft: '14px' }}>{t("teams.teams")}</p>




                    <div style={{ marginRight: "50px" }}>
                      {this.state.brandData != null ? this.state.brandData.map((item: any, index: any) => {
                        console.log(this.state.itemValue, "i")
                        return (
                          <>
                            <Accordion className={classes.accordionStyle}
                              key={item.id}
                              expanded={this.state.expendbrand === item.id}
                              onChange={this.handleChangeAcc(item.id)}
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={e => {
                                  this.getTeams(item.id)
                                  this.getUser(item.id)
                                }}
                              >
                                <Typography className={classes.heading} style={{ padding: "15px 0px", fontWeight: "bold" }}>{item?.attributes?.name}
                                  {
                                    this.state.expendbrand === item.id ?
                                      <img src={downIcon} style={{ marginLeft: "7px" }} aria-controls="panel1a-content"
                                        id="panel1a-header" /> :

                                      <img src={sideIcon} style={{ marginLeft: "7px" }} aria-controls="panel1a-content"
                                        id="panel1a-header" />
                                  }
                                </Typography>
                              </AccordionSummary>
                              <>
                                {this.state.teamsData.length > 0 ? this.state.teamsData.map((element: any, id: any) => {
                                  return (
                                    <div style={{ marginLeft: '4%', cursor: 'pointer' }}>
                                      <Card className={this.state.selectedIndex === element.id ? classes.activeTeam : classes.cardRoot}
                                        onClick={e => {
                                          e.preventDefault()
                                        }}
                                      >
                                        <CardContent
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleChangeTeams(event, element.id)
                                            this.selctedTeamValue(element?.id)
                                          }}
                                        >
                                          <div className={classes.CardContent}>
                                            <div className={classes.innerDiv} style={{ height: "47px", width: "65px", backgroundColor: "#f9f9fc", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <p style={{ color: "black" }}><img src={fileIcon} style={{ height: "20px", width: "20px" }} /></p>
                                            </div>
                                            <div className={classes.sample}>

                                              <p style={{ marginLeft: "10px" }} onClick={e => { this.selctedTeamValue(element?.id) }}>{element?.attributes?.name}</p>
                                            </div>
                                            <div style={{ width: "120px" }}>
                                              {element?.attributes?.first_account?.data?.attributes?.photo_blob_signed_id_url != null ?
                                                <AvatarGroup max={2}
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <Avatar alt="Remy Sharp"
                                                    src={element?.attributes?.first_account?.data?.attributes?.photo_blob_signed_id_url} />
                                                  {_.times(element?.attributes?.total_accounts - 1, () => (
                                                    <Avatar alt="Remy Sharp"
                                                      src={defaultUser} />
                                                  ))}

                                                </AvatarGroup>
                                                :
                                                (
                                                  <>
                                                    <AvatarGroup max={2}
                                                      style={{ marginTop: "10px" }}
                                                    >
                                                      {_.times(element?.attributes?.total_accounts, () => (
                                                        <Avatar alt="Remy Sharp"
                                                          src={defaultUser} />
                                                      ))}
                                                    </AvatarGroup>
                                                  </>
                                                )
                                              }
                                            </div>
                                            {this.state.selectedIndex == element.id ?
                                              <div style={{ position: "absolute", right: "0px", marginTop: "15px" }}>
                                                <IconButton aria-label="settings" style={{ color: "black" }}>
                                                  <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e: any) => {
                                                      e.stopPropagation();
                                                      this.handleClickMenu(e);
                                                    }}
                                                  />
                                                </IconButton>
                                              </div>
                                              :
                                              null
                                            }
                                          </div>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  )
                                })
                                  :
                                  <p>No Teams Found</p>
                                }
                              </>
                            </Accordion>
                          </>
                        )
                      })
                        :
                        <p>No Data Found</p>
                      }
                    </div>
                  </div>
                </Grid>
                {this.state.teamsData.length > 0 && this.state.expendbrand !== false ?
                  <Grid item xs={7}>
                    {
                      <>
                        <div>
                          {this.state.getById?.attributes?.description ? <p style={{ fontWeight: "bold" }} >Team Description</p> : null}
                          <p style={{ fontSize: "14px" }}>{this.state.getById?.attributes?.description ? this.state.getById.attributes.description : null} </p>
                          <p style={{ fontWeight: "bold" }}> {t("teams.members")} </p>
                          <Input
                            id="input-with-icon-adornment"
                            className={classes.inputSerachBox2}
                            placeholder="Search Team Member"
                            onChange={(e) => {
                              this.setState({ searchMember: e.target.value }, () => {

                                console.log("search ,", this.state.searchMember);
                                this.accountTeam(this.state.getById?.id)

                                // else {
                                //   this.componentDidMount2()
                                // }
                              })
                            }}
                            startAdornment={
                              <InputAdornment position="start" >
                                <SearchIcon />
                              </InputAdornment>
                            }
                            style={{ float: "right", top: "-40px", cursor: "not-allowed" }}
                          />
                        </div>
                        <div>
                          <Grid container spacing={0}>
                            {
                              (this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'brand_manager' || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver') ?
                                <Html2Tooltip
                                  arrow
                                  placement="left"
                                  open={localStorage.getItem("onBoardingStep") == "13" ? true : false}
                                  title={
                                    <React.Fragment>
                                      <div>
                                        {/* <img src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "13px", width: "13px", borderRadius: "50%" }} /> */}
                                        <span className={classes.popupTitle}>Add New Member</span>
                                        <span className={classes.popupCancelIcon}>
                                          <CloseIcon onClick={this.handleSkipOnboarding} />
                                        </span>
                                      </div>
                                      <div className={classes.popupText}>
                                        {t("onBoarding.accountTextOne")}<br />
                                        {t("onBoarding.accountTextTwo")}
                                      </div>
                                      <div>
                                        <Button
                                          className={classes.popupSkipButton}
                                          onClick={this.handleSkipOnboarding}
                                          style={colorToBeChanged}
                                        >
                                          SKIP
                                        </Button>
                                        {/* <Button
                                      className={classes.popupButton}
                                      onClick={this.handlePrevOnboarding}
                                    >
                                      PREV
                                    </Button> */}
                                        <Button
                                          className={classes.popupButton}
                                          onClick={this.handleNextOnboarding}
                                          style={{ background: colorToBeChanged }}
                                        >
                                          NEXT
                                        </Button>
                                      </div>
                                    </React.Fragment>
                                  }
                                >
                                  <Grid item xs={2} style={{ marginRight: '2%', cursor: 'pointer' }} className={localStorage.getItem("onBoardingStep") == "13" && classes.highlightText}>
                                    <div className={classes.addButton} onClick={e => { this.handleOpenTeamUpdate(this.state.getById) }}>
                                      <span>+</span>
                                    </div>
                                    <p style={{ fontSize: "14px", color: secondaryColorToBeChanged, textAlign: 'center', cursor: 'pointer' }}>{t("AdminConsole.addNewMember")} </p>
                                  </Grid>
                                </Html2Tooltip>

                                :
                                null
                            }
                            {this.state.imagedata?.accounts?.data?.length ? this.state.imagedata.accounts.data.map((x: any) => {
                              return (
                                <>
                                  <Grid item xs={2} className={classes.parentCardDiv} style={{ marginRight: '2%' }}>
                                    <Card style={{ boxShadow: "none", padding: "0px", cursor: 'pointer' }} onClick={e => { this.setUsers(x) }}>
                                      <CardContent style={{ padding: "0px" }}  >
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <CardMedia
                                            component="img"
                                            height="125"
                                            image={x.attributes.photo_blob_signed_id_url && x.attributes.photo_blob_signed_id_url.length > 0 ? x.attributes.photo_blob_signed_id_url : defaultUser}
                                            alt="user-display"
                                          />
                                          {/* <img src={x.attributes.photo_blob_signed_id_url && x.attributes.photo_blob_signed_id_url.length > 0 ? x.attributes.photo_blob_signed_id_url : defaultUser} style={{ height: "125px", width: "100%" }} /> */}
                                        </div>

                                      </CardContent>
                                    </Card>
                                    {x.attributes.first_name &&
                                      <>
                                        <p style={{ fontSize: '14px', display: "flex", justifyContent: "center" }}> {x.attributes.first_name} {x.attributes.last_name ? x.attributes.last_name : null} </p>
                                      </>
                                    }
                                    <p style={{ fontSize: "10px", display: "flex", justifyContent: "center", color: "#939393", lineBreak: "anywhere" }}>{x.attributes.email}</p>
                                  </Grid>
                                </>
                              )
                            })
                              :
                              <>
                                <p>No Data Found</p>
                              </>
                            }
                          </Grid>
                        </div>
                      </>
                    }
                  </Grid>
                  : null
                }
              </Grid>
              :
              <Grid container spacing={0} >
                <Grid item xs={5} >
                  <div>
                    <p style={{}}>Teams</p>
                    {/* card */}
                    <div style={{ marginRight: "50px" }}>
                      <>
                        <div style={{ marginTop: "25px", cursor: 'pointer' }}>
                          {this.state.teamsData.length > 0 ? this.state.teamsData.map((element: any, id: any) => {
                            return (
                              <>
                                <Card className={this.state.selectedIndex === element.id ? classes.activeTeam : classes.cardRoot}>
                                  <CardContent
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      this.handleChangeTeams(event, element.id)
                                      this.selctedTeamValue(element?.id)
                                    }}
                                  >
                                    <div className={classes.CardContent}>
                                      <div className={classes.innerDiv} style={{ height: "47px", width: "65px", backgroundColor: "#f9f9fc", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <p style={{ color: "black" }}>  <img src={fileIcon} style={{ height: "20px", width: "20px" }} /></p>
                                      </div>
                                      <p style={{ marginLeft: "10px" }} onClick={e => { this.selctedTeamValue(element?.id) }}>{element?.attributes?.name}</p>
                                      <div style={{ position: "absolute", right: "50px" }}>
                                        {element?.attributes?.first_account?.data?.attributes?.photo_blob_signed_id_url != null ?
                                          <AvatarGroup max={2}
                                            style={{ marginTop: "10px" }}
                                          >
                                            <Avatar alt="Remy Sharp"
                                              src={element?.attributes?.first_account?.data?.attributes?.photo_blob_signed_id_url} />
                                            {_.times(element?.attributes?.total_accounts - 1, () => (
                                              <Avatar alt="Remy Sharp"
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                          :
                                          (
                                            <>
                                              <AvatarGroup max={2}
                                                style={{ marginTop: "10px" }}
                                              >
                                                {_.times(element.attributes.total_accounts, () => (
                                                  <Avatar alt="Remy Sharp"
                                                    src={defaultUser} />
                                                ))}
                                              </AvatarGroup>
                                            </>
                                          )
                                        }
                                      </div>
                                      {this.state.selectedIndex == element.id ?
                                        <div style={{ position: "absolute", right: "5px", marginTop: "2px" }}>
                                          <IconButton aria-label="settings" style={{ color: "black", bottom: "-10px" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              onClick={(e: any) => {
                                                e.stopPropagation();
                                                this.handleClickMenu(e);
                                              }}
                                            />
                                          </IconButton>
                                        </div>
                                        :
                                        null
                                      }
                                    </div>
                                  </CardContent>
                                </Card>
                              </>
                            )
                          })
                            :
                            <p>No Teams Found</p>
                          }
                        </div>
                        {/* </Accordion> */}
                      </>
                    </div>
                  </div>
                </Grid>
                {this.state.teamsData.length > 0 && this.state.expendbrand !== false ?
                  <Grid item xs={7}>
                    {
                      <>
                        <div>
                          {this.state.getById?.attributes?.description ? <p style={{ fontWeight: "bold" }} >Team Description</p> : null}
                          <p style={{ fontSize: "14px" }}>{this.state.getById?.attributes?.description ? this.state.getById.attributes.description : null} </p>
                          <p style={{ fontWeight: "bold" }}>Members</p>
                          <Input
                            id="input-with-icon-adornment"
                            className={classes.inputSerachBox2}
                            placeholder="Search Team Member"
                            onChange={(e) => {
                              this.setState({ searchMember: e.target.value }, () => {

                                console.log("search ,", this.state.searchMember);
                                this.accountTeam(this.state.getById?.id)

                                // else {
                                //   this.componentDidMount2()
                                // }
                              })
                            }}
                            startAdornment={
                              <InputAdornment position="start" >
                                <SearchIcon />
                              </InputAdornment>
                            }
                            style={{ float: "right", top: "-40px", cursor: "not-allowed" }}
                          />
                        </div>
                        <div>
                          <Grid container spacing={0}>
                            {
                              (this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver') ?
                                <Html2Tooltip
                                  arrow
                                  placement="left"
                                  open={localStorage.getItem("onBoardingStep") == "13" ? true : false}
                                  title={
                                    <React.Fragment>
                                      <div>
                                        {/* <img src={userInfo.photo_blob_signed_id_url && userInfo.photo_blob_signed_id_url.length > 0 ? userInfo.photo_blob_signed_id_url : userImage} style={{ height: "13px", width: "13px", borderRadius: "50%" }} /> */}
                                        <span className={classes.popupTitle}>Add New Member</span>
                                        <span className={classes.popupCancelIcon}>
                                          <CloseIcon onClick={this.handleSkipOnboarding} />
                                        </span>
                                      </div>
                                      <div className={classes.popupText}>
                                        {t("onBoarding.accountTextOne")}<br />
                                        {t("onBoarding.accountTextTwo")}
                                      </div>
                                      <div>
                                        <Button
                                          className={classes.popupSkipButton}
                                          onClick={this.handleSkipOnboarding}
                                        >
                                          SKIP
                                        </Button>
                                        {/* <Button
                                      className={classes.popupButton}
                                      onClick={this.handlePrevOnboarding}
                                    >
                                      PREV
                                    </Button> */}
                                        <Button
                                          className={classes.popupButton}
                                          onClick={this.handleNextOnboarding}
                                        >
                                          NEXT
                                        </Button>
                                      </div>
                                    </React.Fragment>
                                  }
                                >
                                  <Grid item xs={2} style={{ marginRight: '2%', cursor: 'pointer' }} className={localStorage.getItem("onBoardingStep") == "13" && classes.highlightText}>
                                    <div className={classes.addButton} onClick={e => { this.handleOpenTeamUpdate(this.state.getById) }}>
                                      <span>+</span>
                                    </div>
                                    <p style={{ fontSize: "14px", color: secondaryColorToBeChanged, textAlign: 'center', cursor: 'pointer' }}>{t("AdminConsole.addNewMember")} </p>
                                  </Grid>
                                </Html2Tooltip>

                                :
                                null
                            }
                            {this.state.imagedata?.accounts?.data?.length ? this.state.imagedata.accounts.data.map((x: any) => {
                              return (
                                <>
                                  <Grid item xs={2} className={classes.parentCardDiv} style={{ marginRight: '2%' }}>
                                    <Card style={{ boxShadow: "none", padding: "0px", cursor: 'pointer' }} onClick={e => { this.setUsers(x) }}>
                                      <CardContent style={{ padding: "0px" }}  >
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                          <CardMedia
                                            component="img"
                                            height="125"
                                            image={x.attributes.photo_blob_signed_id_url && x.attributes.photo_blob_signed_id_url.length > 0 ? x.attributes.photo_blob_signed_id_url : defaultUser}
                                            alt="user-display"
                                          />
                                          {/* <img src={x.attributes.photo_blob_signed_id_url && x.attributes.photo_blob_signed_id_url.length > 0 ? x.attributes.photo_blob_signed_id_url : defaultUser} style={{ height: "125px", width: "100%" }} /> */}
                                        </div>
                                      </CardContent>
                                    </Card>
                                    {x.attributes.first_name &&
                                      <>
                                        <p style={{ fontSize: '14px', display: "flex", justifyContent: "center" }}> {x.attributes.first_name} {x.attributes.last_name ? x.attributes.last_name : null} </p>
                                      </>
                                    }
                                    <p style={{ fontSize: "10px", display: "flex", justifyContent: "center", color: "#939393" }}>{x.attributes.email}</p>
                                  </Grid>
                                </>
                              )
                            })
                              :
                              <>
                                <p>No Data Found</p>
                              </>
                            }
                          </Grid>
                        </div>
                      </>
                    }
                  </Grid>
                  : null
                }
              </Grid>
          }
        </div>
        {/* createTeamModal */}
        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title" >
            {t("teams.createTeam")}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}  >
              <CloseIcon onClick={this.handleCloseModal} />

            </span>


          </DialogTitle>


          <Formik


            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",

              selectBrand: roles == 'client' ? specificBrand[0]?.id : '',
              //  selectBrand1 : "",
              teamName: "",
              // addMembers:"",
              description: ""
              // shareTheProjectWithOthers:this.state.sharedProject,


            }}

            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              selectBrand: roles != "client" ? Yup.string()
                .required("selectBrand is required") : Yup.string(),

              teamName: Yup.string().required("teamName is required")
              // .test('len', 'Must be less then 40 characters', values => values?.teamName?.length < 40)
              ,
              // addMembers: Yup.string()
              // .required("addMembers is required"),


            })}

            onSubmit={(values) => {
              console.log(values);

              // console.log(this.state.sharedProject, "pro")
              //console.log(this.state.initialAccountData, "init")
              // if(this.state.sharedProject.length<0 || Object.keys(this.state.initialAccountData).length <0){
              //   this.setState({
              //     sharedProjectError:this.state.sharedProject?true:false
              //   },()=>{
              //     console.log(this.state.sharedProjectError,"error")
              //   })
              // }
              let value = {}
              let account = []
              account.push(this.state.sharedProject.length > 0 ? this.state.sharedProject : [])

              value = { values, account }
              console.log(value, "bj")
              this.teamCreation(value)


              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}

            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {


              // this.teamCreation(value)
              // this.teamCreation(value)
              return (

                <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont, marginBottom: "35px" }}>
                  <DialogContent dividers>
                    {/* <TextField
                                  name="selectBrand"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  select
                                  fullWidth
                                  label={t("teams.selectBrand")}
                                  value={values.selectBrand}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  helperText={
                                    touched.selectBrand && errors.selectBrand
                                      ? (
                                        <Trans>
                                          CompanyProfile.companyNameRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.selectBrand && errors.selectBrand
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {accounts.map((item:any,index:any)=>{
                                    return(
                                      <>
                                      <option aria-label="None" value="" />
                                      <option value={item.id} >{item.company}</option>
                                      </>
                                    )
                                  })

                                  }
                                
                                  </TextField> */}
                    {roles !== "client" ?

                      <TextField
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        name="selectBrand"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        //size="small"
                        className={classes.confirmInput}
                        fullWidth
                        label={t("teams.selectBrand")}
                        value={values.selectBrand ? values.selectBrand : ''}
                        //defaultValue =""
                        variant="outlined"
                        //  disabled
                        helperText={
                          touched.selectBrand && errors.selectBrand
                            ? (
                              <Trans>
                                teams.brandIsRequired
                              </Trans>
                            )
                            : ""
                        }
                        error={
                          touched.selectBrand && errors.selectBrand
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >

                        <>
                          <option aria-label="None" />
                          {
                            this.state.brandData.length > 0 ? this.state.brandData.map((brandData: any) => {
                              return (
                                <>

                                  <option value={brandData.id} >{brandData.attributes.name}</option>
                                </>
                              )
                            })

                              :
                              <p>No Data Found</p>
                          }

                        </>

                      </TextField>
                      :
                      <>
                        <TextField
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          name="selectBrand1"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          //size="small"
                          className={classes.confirmInput}
                          fullWidth
                          label={t("teams.selectBrand")}
                          disabled
                          value={values.selectBrand}
                          variant="outlined"
                        // helperText={
                        //   touched.selectBrand && errors.selectBrand
                        //     ? (
                        //       <Trans>
                        //         teams.brandIsRequired
                        //       </Trans>
                        //     )
                        //     : ""
                        // }
                        // error={
                        //   touched.selectBrand && errors.selectBrand
                        //     ? true
                        //     : false
                        // }
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        >

                          <>
                            {
                              specificBrand.length > 0 ? specificBrand.map((brandData: any) => {
                                return (
                                  <>
                                    {/* <option aria-label="None" /> */}
                                    <option value={brandData.id} >{brandData.attributes.name}</option>
                                  </>
                                )
                              })

                                :
                                <p>No Data Found</p>
                            }

                          </>

                        </TextField>

                      </>

                    }

                    <TextField
                      name="teamName"
                      type="text"
                      //size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}


                      fullWidth
                      label={t("teams.teamName")}
                      value={values.teamName}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.teamName && errors.teamName
                          ?
                          values.teamName.length >= 40 ?
                            (
                              <Trans>
                                {/* teams.teamIsRequired */}
                                Team name must be less then 40 characters
                                {/* {errors.teamName} */}
                              </Trans>
                            )
                            :
                            values.teamName.length == 0 ?
                              (
                                <Trans>
                                  teams.teamIsRequired
                                </Trans>
                              )
                              :
                              ""
                          : ""
                      }
                      error={
                        touched.teamName && errors.teamName
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />



                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.accountData}
                      style={{ minWidth: "550px" }}
                      getOptionLabel={(option: any) => option.attributes?.first_name ? option.attributes.first_name : option.attributes.email}
                      renderOption={(option: any) => (
                        <React.Fragment>
                          <span style={{ marginRight: "5px" }}>{option.attributes.photo_blob_signed_id_url ?
                            <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option.attributes.photo_blob_signed_id_url} /> :
                            <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                          }</span>
                          {option.attributes.first_name} {option.attributes.last_name}
                        </React.Fragment>
                      )}
                      disableClearable
                      // defaultValue={[this.state.accountData.length>0 &&this.state.initialAccountData]}
                      onChange={(e, value) => {

                        console.log(e, value);
                        this.setState({
                          sharedProject: value,
                          sharedProjectError: value.length >= 0 ? false : true

                        }, () => {
                          console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index) => {
                          console.log(option, "option")
                          console.log(value, "option2")
                          // console.log(getTagProps,"tagprops")

                          let { first_name, last_name, photo_blob_signed_id_url } = option.attributes
                          return <Chip
                            className={classes.projectChips}
                            label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              <span>
                                {photo_blob_signed_id_url ?
                                  <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={photo_blob_signed_id_url} /> :
                                  <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />

                                }
                              </span>
                              <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{first_name} {last_name}</span>
                            </Typography>}
                            {...getTagProps({ index })}


                            style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                          />

                        }


                        )
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}

                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}


                          helperText={this.state.sharedProjectError ? "Members is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />
                    {/* <TextField
                                  id="outlined-multiline-static"
                                  label={t("projects.shareTheProjectWithOthers")}
                                  name="shareTheProjectWithOthers"
                                  fullWidth
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  value={values.shareTheProjectWithOthers}
                                  variant="outlined"
                                  className={classes.confirmInput}
                                  helperText={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? (
                                        <Trans>
                                          CompanyProfile.billingEmailRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                /> */}


                    <TextField
                      name="description"

                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}
                      //size="small"
                      className={classes.confirmInput}
                      fullWidth
                      label={t("teams.descriptions")}
                      placeholder={t("teams.descriptionsPlaceholder")}
                      value={values.description}
                      variant="outlined"
                      helperText={
                        touched.description && errors.description
                          ? (
                            <Trans>
                              teams.descriptionIsREquired
                            </Trans>
                          )
                          : ""
                      }
                      error={
                        touched.description && errors.description ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />





                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button

                      style={{ backgroundColor: colorToBeChanged, color: "white", marginLeft: "15px" }}
                      type="submit"
                      variant="contained"
                    >
                      {t("teams.createTeamButton")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="button"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>


              )
            }}
          />


        </Dialog>

        {/* infoModal */}

        <Dialog
          onClose={this.handleCloseModalInfo}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen1}
          style={{ fontFamily: primaryFont, marginBottom: "35px" }}
        >
          <DialogTitle id="customized-dialog-title" >
            {t("teams.overviewTeam")}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}  >
              <CloseIcon onClick={this.handleCloseModalInfo} />
            </span>
          </DialogTitle>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="fullWidth"
            scrollButtons="off"
            indicatorColor="primary"
            aria-label="full width tabs example"
            textColor="primary"
            centered
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              label={t("teams.overview")}
              className={classes.tab}
              style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
            />

            <Tab
              label={t("individualCompanyHomeScreen.members")}
              className={classes.tab}
              style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
            />

          </Tabs>


          {this.state.value === 0 &&
            (

              <Formik
                initialValues={{
                  // email: "",
                  // animal: "",
                  // companyName: "",
                  selectBrand: this.state.getById?.attributes?.brand?.data?.attributes?.name,
                  teamName: this.state.getById?.attributes?.name,
                  // addMembers:"",
                  description: this.state.getById?.attributes?.description ? this.state.getById.attributes.description : "",
                  // shareTheProjectWithOthers:this.state.sharedProject,


                }}
                enableReinitialize
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  selectBrand: Yup.string()
                    .required("selectBrand is required"),

                  teamName: Yup.string()
                    .required("teamName is required"),
                  // addMembers: Yup.string()
                  // .required("addMembers is required"),

                })}
                onSubmit={(values) => {
                  console.log(values, "overview field");
                  console.log(this.state.sharedProject, "pro")
                  let value = {}
                  let account = []
                  account.push(this.state.sharedProject)

                  value = { values, account }
                  console.log(value, "bj")
                  this.updateTeam(value)
                  // this.updateTeamOverviewDetail(values)



                  // this.setState({
                  //   sharedProjectError:this.state.sharedProject?true:false
                  // },()=>{
                  //   console.log(this.state.sharedProjectError,"error")
                  // })
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}

                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {
                  console.log(values, "shdfsdiuf", this.state.sharedProject);

                  return (

                    <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                      <DialogContent dividers>
                        <TextField
                          name="selectBrand"
                          type="text"
                          //size="small"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          disabled
                          fullWidth
                          label={t("teams.selectBrand")}
                          value={values.selectBrand}
                          className={classes.confirmInput}
                          variant="outlined"
                          helperText={
                            touched.selectBrand && errors.selectBrand
                              ? (
                                <Trans>
                                  CompanyProfile.companyNameRequired
                                </Trans>
                              )
                              : ""
                          }
                          error={
                            touched.selectBrand && errors.selectBrand
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <TextField
                          name="teamName"
                          type="text"
                          //size="small"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          fullWidth
                          label={t("teams.teamName")}
                          value={values.teamName}
                          className={classes.confirmInput}
                          variant="outlined"
                          disabled={localStorage.getItem("permissions") == 'viewer' ? true : false}
                          helperText={
                            touched.teamName && errors.teamName
                              ? (
                                <Trans>
                                  teams.teamIsRequired
                                </Trans>
                              )
                              : ""
                          }
                          error={
                            touched.teamName && errors.teamName
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />



                        <Autocomplete
                          multiple
                          id="fixed-tags-demo"
                          options={this.state.accountData}
                          getOptionLabel={(option: any) => option.attributes?.first_name ? option.attributes.first_name : option.attributes.email}
                          renderOption={(option: any) => (
                            <React.Fragment>
                              <span style={{ marginRight: "5px" }}>{option.attributes.photo_blob_signed_id_url ?
                                <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option.attributes.photo_blob_signed_id_url} /> :
                                <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                              }</span>
                              {option.attributes.first_name} {option.attributes.last_name}
                            </React.Fragment>
                          )}
                          style={{ minWidth: "550px" }}
                          disableClearable
                          disabled={localStorage.getItem("permissions") == 'viewer' ? true : false}
                          // getOptionLabel={option => option.title}
                          // defaultValue={[top100Films[0]]}
                          onChange={(e, value) => {
                            console.log(e, value);
                            this.setState({
                              sharedProject: value,
                              sharedProjectError: false

                            }, () => {
                              console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                            })
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option: any, index) => {
                              console.log(option, "option")
                              console.log(value, "option2")
                              // console.log(getTagProps,"tagprops")

                              let { first_name, last_name, photo_blob_signed_id_url } = option.attributes
                              return <Chip
                                className={classes.projectChips}
                                label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                  <span>
                                    {photo_blob_signed_id_url ?
                                      <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={photo_blob_signed_id_url} /> :
                                      <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />

                                    }
                                  </span>
                                  <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{first_name} {last_name}</span>
                                </Typography>}
                                {...getTagProps({ index })}


                                style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                              />

                            }


                            )
                          }

                          renderInput={(params): any => (
                            <TextField
                              {...params}
                              label={t("teams.addMembers")}
                              name="addMembers"
                              placeholder={t("teams.addMembersPlaceholder")}

                              helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                              error={
                                this.state.sharedProjectError
                              }

                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused
                                }
                              }}
                              className={classes.confirmInput}
                              variant="outlined"

                            />
                          )}
                        />


                        <TextField
                          name="description"

                          multiline
                          rows={4}

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          //size="small"
                          className={classes.confirmInput}
                          fullWidth
                          label={t("teams.descriptions")}
                          placeholder={t("teams.descriptionsPlaceholder")}
                          value={values.description}
                          variant="outlined"
                          disabled={localStorage.getItem("permissions") == 'viewer' ? true : false}
                          helperText={
                            touched.description && errors.description
                              ? (
                                <Trans>
                                  CompanyProfile.vatIdRequired
                                </Trans>
                              )
                              : ""
                          }
                          error={
                            touched.description && errors.description ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />





                      </DialogContent>
                      <DialogActions style={{ justifyContent: "flex-start" }}>
                        {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
Save changes
</Button> */}
                        {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == "brand_manager") ? <Button

                          style={{ backgroundColor: colorToBeChanged, color: "white", marginLeft: "15px" }}
                          type="submit"
                          variant="contained"
                        >
                          {t("teams.updateTeam")}
                        </Button> : null}
                        <Button
                          className={classes.button}
                          style={{ backgroundColor: "white", color: "black" }}
                          type="button"
                          variant="contained"
                          onClick={this.handleCloseModalInfo}
                        >
                          {t("projects.cancel")}
                        </Button>
                      </DialogActions>
                    </form>


                  )
                }}
              />

            )
          }

          {
            this.state.value === 1 &&
            (
              <div style={{ minWidth: "600px", fontFamily: primaryFont }}>

                <p style={{ fontSize: "12px", marginLeft: "30px" }}>
                  {t("teams.members")}
                </p>
                <div style={{ margin: "20px" }}>
                  {this.state.imagedata?.accounts?.data?.length ? this.state.imagedata?.accounts?.data.map((item: any) => {
                    return (
                      <div className={classes.teamMembers}>
                        <Avatar alt="Remy Sharp" src={item.attributes.photo_blob_signed_id_url && item.attributes.photo_blob_signed_id_url.length > 0 ? item.attributes.photo_blob_signed_id_url : defaultUser} />
                        <div style={{ marginLeft: "15px" }}>
                          <span className={classes.modalUserName} style={{ marginBottom: '15px' }}>
                            <p style={{ fontSize: "14px", margin: 0, padding: '5px', color: '#282828' }}>{item.attributes.first_name} {item.attributes.last_name ? item.attributes.last_name : null}</p>
                          </span>

                          <span className={classes.modalUserEmail}>
                            <p style={{ fontSize: "10px", margin: 0, padding: '5px', color: '#666' }} >{item.attributes.email}</p>
                          </span>
                        </div>


                        {/* <Button size="small" variant="contained" color="secondary"> */}
                        <span style={{ marginLeft: "15px", position: "absolute", right: 0 }}>
                          {

                            (this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager') ?
                              <Button size="small" color={secondaryColorToBeChanged} style={{ backgroundColor: "#f4f6fc" }} onClick={() => this.deleteTeamMember(item?.id)} startIcon={<DeleteIcon color="error" />}>
                                Remove
                              </Button>
                              : null
                          }
                        </span>

                        {/* </Button> */}

                      </div>


                    )
                  })
                    :
                    <p>No Data Found</p>

                  }
                </div>
              </div>
            )
          }
        </Dialog>

        {/* updateTeam */}

        <Dialog
          onClose={this.handleCloseTeamUpdate}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen2}
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title" >
            Update Team
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}  >
              <CloseIcon onClick={this.handleCloseTeamUpdate} />

            </span>

          </DialogTitle>


          <Formik
            initialValues={{

              teamName: this.state.teamName,
              // addMembers:"",

              // shareTheProjectWithOthers:this.state.sharedProject,


            }}

            validateOnBlur={false}
            validationSchema={Yup.object().shape({


              teamName: Yup.string()
                .required("Teamname is required"),


            })}
            enableReinitialize
            onSubmit={(values) => {
              console.log(values);

              console.log(this.state.sharedProject, "pro")
              // if(this.state.sharedProject == null){
              //   this.setState({
              //     sharedProjectError:this.state.sharedProject?true:false
              //   },()=>{
              //     console.log(this.state.sharedProjectError,"error")
              //   })
              // }


              let value = {}
              let account = []
              account.push(this.state.sharedProject)

              value = { values, account }
              console.log(value, "bj")
              this.updateTeam(value)


              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}

            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {


              // this.teamCreation(value)
              // this.teamCreation(value)
              return (

                <form onSubmit={handleSubmit} style={{ marginBottom: "35px" }}>
                  <DialogContent dividers>
                    {/* <TextField
                                  name="selectBrand"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  select
                                  fullWidth
                                  label={t("teams.selectBrand")}
                                  value={values.selectBrand}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  helperText={
                                    touched.selectBrand && errors.selectBrand
                                      ? (
                                        <Trans>
                                          CompanyProfile.companyNameRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.selectBrand && errors.selectBrand
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {accounts.map((item:any,index:any)=>{
                                    return(
                                      <>
                                      <option aria-label="None" value="" />
                                      <option value={item.id} >{item.company}</option>
                                      </>
                                    )
                                  })

                                  }
                                
                                  </TextField> */}

                    <TextField
                      name="teamName"
                      type="text"
                      //size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}


                      fullWidth
                      label={t("teams.teamName")}
                      value={values.teamName}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.teamName && errors.teamName
                          ? (
                            <Trans>
                              teams.teamIsRequired
                            </Trans>
                          )
                          : ""
                      }
                      error={
                        touched.teamName && errors.teamName
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <Autocomplete
                      multiple
                      // value={this.state.selectedUser}
                      id="fixed-tags-demo"
                      options={this.state.accountData}

                      getOptionLabel={(option: any) => option.attributes?.first_name ? option.attributes.first_name : option.attributes.email}
                      renderOption={(option: any) => (
                        <React.Fragment>

                          <span style={{ marginRight: "5px" }}>{option.attributes.photo_blob_signed_id_url ?
                            <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option.attributes.photo_blob_signed_id_url} /> :
                            <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                          }
                          </span>
                          {option.attributes.first_name} {option.attributes.last_name}
                        </React.Fragment>
                      )}
                      style={{ minWidth: "550px" }}
                      disableClearable
                      // getOptionSelected={(option :any, value : any) =>value?.attributes?.email === option?.attributes?.email}
                      // defaultValue={[this.state.accountData.length>0 &&this.state.initialAccountData]}
                      onChange={(e, value) => {

                        console.log("valueauto", e, value);
                        this.setState({
                          selectedUser: value,
                          sharedProject: value,
                          sharedProjectError: value.length >= 0 ? false : true,


                        }, () => {
                          console.log(this.state.sharedProject, "sh", this.state.sharedProjectError)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index) => {
                          console.log(option, "option")
                          console.log(value, "option2")
                          // console.log(getTagProps,"tagprops")

                          let { first_name, last_name, photo_blob_signed_id_url } = option.attributes
                          return <Chip
                            className={classes.projectChips}
                            label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              <span>
                                {photo_blob_signed_id_url ?
                                  <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={photo_blob_signed_id_url} /> :
                                  <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />

                                }
                              </span>
                              <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{first_name} {last_name}</span>
                            </Typography>}
                            {...getTagProps({ index })}


                            style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                          />

                        }


                        )
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}

                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}


                          helperText={this.state.sharedProjectError ? "Members is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />





                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    {

                      (this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == "brand_manager") ?
                        <Button

                          style={{ backgroundColor: colorToBeChanged, color: "white", marginLeft: "15px" }}
                          type="submit"
                          variant="contained"
                        >
                          {t("teams.upDateButton")}
                        </Button> :
                        null
                    }

                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="button"
                      variant="contained"
                      onClick={this.handleCloseTeamUpdate}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>


              )
            }}
          />


        </Dialog>

        {/* teamMembersDetails
      
      */}

        <Dialog
          open={this.state.setOpen3}
          onClose={this.handleCloseUserInfo}
          aria-labelledby="responsive-dialog-title"
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="responsive-dialog-title" style={{
            textAlign: "center",
            color: secondaryColorToBeChanged
          }}>{"Member Info"}</DialogTitle>
          <DialogContent style={{ minWidth: "400px" }}>
            <DialogContentText>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>First Name</p>
                {this.state.userInfo ?

                  <p>{this.state.userInfo.attributes?.first_name}</p> :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Last Name</p>
                {this.state.userInfo ?
                  <p>{this.state.userInfo.attributes?.last_name}</p>

                  :
                  <p>No Data</p>

                }


              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Email</p>
                {this.state.userInfo ?
                  <p>{this.state.userInfo.attributes?.email}</p> :

                  <p>No Data</p>

                }
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                <p>Designation</p>
                {this.state.userInfo ?
                  <p>{this.state.userInfo.attributes?.designation}</p> :

                  <p>No Data</p>

                }
              </div>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // autoFocus
              style={{
                color: "white",
                backgroundColor: secondaryColorToBeChanged
              }}
              onClick={this.handleCloseUserInfo}>
              close
            </Button>

          </DialogActions>
        </Dialog>
        <Menu

          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose();
          }}
        >

          <MenuItem
            onClick={(e) => {
              this.handleClose("overview");
            }}
            style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
            className={classes.dropDown}
          >
            <div className={classes.circle}>
              <AccessTimeIcon style={{ color: "black", fontSize: "13px" }} />

            </div>
            {t("teams.overview")}
          </MenuItem>

          {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager') ? <MenuItem
            onClick={(e) => {
              this.handleClose("delete");
            }}
            style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
            className={classes.dropDown}
          >
            <div className={classes.circle}>
              <img src={deleteLogo} />

            </div>
            {t("teams.deleteTeam")}
          </MenuItem> : null
          }
          {(this.state.permissionName === "superadmin" || localStorage.getItem("permissions") == 'editor' || localStorage.getItem("permissions") == 'approver' || localStorage.getItem("permissions") == 'brand_manager') ?
            <MenuItem
              onClick={() => {
                this.handleClose("duplicateTeam");
              }}
              style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
              className={classes.dropDown}
            >
              <div className={classes.circle}>
                <img src={duplicateLogo} />

              </div>
              {t("teams.duplicateTeam")}
            </MenuItem> : null
          }

        </Menu>


        <Dialog
          open={this.state.deleteTeamDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className= {classes.deleteProjectDialog}
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon onClick={() => { this.setState({ deleteTeamDialog: false }) }} />
            </span>
          </DialogTitle>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
          ></Backdrop>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
              {t("projects.deletePara")} <br />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "unset" }}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: colorToBeChanged, color: "white", border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  fullWidth
                  variant="contained"           
                  onClick={(e) => this.deleteTeam()}                 
                >
                  {t("projects.yes")}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  style={{ backgroundColor: "white", color: colorToBeChanged, border: `1px solid ${colorToBeChanged}`, marginBottom: "20px" }}
                  variant="contained"
                  fullWidth
                  onClick={() => { this.setState({ deleteTeamDialog: false }) }}
                >
                  {t("projects.close")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>


      </div>

      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(TeamBuilder))
// Customizable Area Start

// Customizable Area End