import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import { Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { forgetBackgroundImage, harvestLogoImage, rightHarvestLogoImage, signUpBackgroundImage } from "./assets";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { withTranslation, Trans } from "react-i18next";

const styles = {
  root: {
    backgroundColor: "red",
  },
  inputPostion: {
    margin: "0",
    position: "absolute",
    top: "35%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
  signUpLogo: {
    height: "100%",
    width: "25%",
    position: "absolute",
    top: "0",
    bottom: "0",
  },
  button: {
    backgroundColor: "rgb(23, 62, 89)",
    color: "white",
    marginTop: "10px",
  },
  PageHeader: {
    // fontFamily: "Inter-Regular",
    color: "#173e59",
    fontSize: "24px",
    marginBottom: "16px",
  },
  PageText: {
    fontFamily: "inter",
    color: "#173e59",
    fontSize: "13px",
    marginBottom: "16px",
  },
  emailError: {
    fontSize: "10px",
    color: "red",
  },
  inputHeight: {
    height: "70px",
    width: "50%",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  signUp: {
    textAlign: "end",
  },
  signUpLink: {
    color: "#173e59",
    cursor: "pointer",
  },
  textError: {
    border: "1px solid red",
  },
  confirmInput: {
    marginTop: "20px",
  },
  view_image_left: {
    maxHeight: "100vh"
  }
} as const;

class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { t, classes } = this.props;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#173e59";
    }
    return (
      <div style={{ overflowY: 'hidden', maxHeight: '100vh' }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {/* <div className={classes.backGroundImage}>
              <img src={signUpBackgroundImage} className={classes.signUpLogo} />
            </div> */}
            <img src={forgetBackgroundImage.default} className={classes.view_image_left} />
          </Grid>
          <div className={classes.images}>
            <img src={harvestLogoImage.default} style={{
              maxWidth: "173px",
              height: "auto",
              position: "absolute",
              left: "100px",
              top: "62px"
            }} />
            <Grid item xs={12} sm={6}>
              <form className={classes.inputPostion}>
                <div>
                  <p
                    className={classes.PageHeader}
                    style={{ color: colorToBeChanged }}
                  >
                    {t("NewPasswordController.setPassword")}
                  </p>
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} className={classes.inputHeight}>
                        <Formik
                          initialValues={{
                            // email: "",
                            // animal: "",
                            password: "",

                            // confirmPassword:'',
                            confirmPassword: "",
                          }}
                          validateOnBlur={false}
                          validationSchema={Yup.object().shape({
                            // email: Yup.string()
                            //   .email("Invalid email address")
                            //   .required("Please enter an email address"),
                            password: Yup.string()
                              .matches(
                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
                                "Your password must contains atleast 8 or more characters with a mix of letters, numbers & special characters"
                              )
                              .required("Password is required "),
                            confirmPassword: Yup.string()
                              .required("Reenter password to confirm")
                              .test(
                                "password-match",
                                "Password must match",
                                function (value) {
                                  console.log(value, "value");
                                  return this.parent.password === value;
                                }
                              ),
                          })}
                          onSubmit={(values) => {
                            console.log(values);
                            this.goToConfirmationAfterPasswordChange(values);
                            // this.props.nextStep(values);
                          }}
                          render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            isValid,
                            handleBlur,
                            setFieldTouched,
                            isSubmitting,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <TextField
                                name="password"
                                type="password"
                                size="small"
                                fullWidth
                                label={t("NewPasswordController.password")}
                                value={values.password}
                                variant="outlined"
                                helperText={
                                  // errors.confirmPassword && touched.confirmPassword
                                  //     ? errors.confirmPassword
                                  //     : 'Password must have 8 characters'

                                  // errors.password && touched.password ? (
                                  //   <Trans>
                                  //     NewPasswordController.mustHavePassword
                                  //   </Trans>
                                  // ) : (
                                  //   <Trans>
                                  //     NewPasswordController.passwordIsRequired
                                  //   </Trans>
                                  // )
                                  <span style={{ color: "red" }}>
                                    {errors.password}
                                  </span>
                                }
                                // error={
                                //   touched.confirmPassword && errors.confirmPassword
                                //     ? true
                                //     : false
                                // }
                                error={
                                  errors.password && touched.password
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextField
                                name="confirmPassword"
                                type="password"
                                size="small"
                                fullWidth
                                label={t("NewPasswordController.confirmPassword")}
                                value={values.confirmPassword}
                                variant="outlined"
                                className={classes.confirmInput}
                                helperText={
                                  // errors.confirmPassword &&
                                  //   touched.confirmPassword ? (
                                  //   errors.confirmPassword
                                  // ) : (
                                  //   <Trans>
                                  //     NewPasswordController.reEnterPassword
                                  //   </Trans>
                                  // )
                                  <span style={{ color: "red" }}>
                                    {errors.confirmPassword}
                                  </span>
                                }
                                error={
                                  errors.confirmPassword &&
                                    touched.confirmPassword
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* <Field
      name="animal"
      value={values.animal}
      variant="outlined"
    /> */}

                              {/* <button type="submit">Submit</button> */}

                              <Button
                                className={classes.button}
                                style={{ backgroundColor: colorToBeChanged }}
                                fullWidth
                                type="submit"
                                variant="contained"
                              >
                                {t("NewPasswordController.setPassword")}
                              </Button>
                            </form>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} />
                  </Grid>

                  <Grid item xs={10}>
                    {/* <Button className={classes.button} fullWidth type="submit" variant="contained" >
                  Log in
  </Button> */}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <img src={rightHarvestLogoImage.default} style={{
              maxWidth: "298px",
              height: "auto",
              margin: 0,
              position: "absolute",
              bottom: 0,
              right: 0
            }} />
          </div>
        </Grid>
      </div>
    );
  }
  async componentDidMount() {
    // Customizable Area Start
    this.tokenHandler()
    // Customizable Area End
  }
}
export default withStyles(styles)(withTranslation()(NewPassword));
// Customizable Area Start
// Customizable Area End
