import React, { createRef } from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropzoneArea } from "material-ui-dropzone";
import InputColor from "react-input-color";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Camera from "react-html5-camera-photo";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from "@material-ui/icons/Close";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
// import HomeScreen from './HomeScreen'
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
// card imports

import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { red } from "@material-ui/core/colors";
import Toolbar from "@material-ui/core/Toolbar";
import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import { withTranslation, Trans } from "react-i18next";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BulkUploading from '../../blocks/BulkUploading/src/BulkUploadingController';
import * as SparkMD5 from 'spark-md5';
import ReactS3Uploader from "react-s3-uploader"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const leftViewBg = require("./view-bg.png")
const RightViewBg = require("./view-right-image.png")

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();
const downIcon = require("./down.png");
const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});
const userImage = require("./view-user-image@3x.png");
const designHarvestLogo = require("./design-harvest-logo-icon.png");
const brandLogo = require("./default-logo.png");

const styles = {
  root: {
    flexGrow: 1,
  },
  dropZone: {
    minHeight: "0px",
    height: "70px",
    "& dropzoneIcon": {
      display: "none",
    },
  },
  MuiDropzoneAreaText: {
    height: "30px !important",
  },
  sideNav: {
    height: "100vh",
    backgroundColor: "#f5f6fa",
    zIndex: "9999",
    position: "fixed",
    width: "100%",
  },
  content: {
    backgroundColor: "#ffff",
    position: "relative",
    left: "20%",
  },
  topLogo: {
    marginTop: "40px",
    marginLeft: "40px",
  },
  userName: {
    color: "#282828",
    fontSize: "22px",
  },
  userDesignation: {
    color: "#737373",
    fontSize: "14px",
    margin: "0",
  },
  logos: {
    position: "fixed",
    bottom: "40px",
    left: "20px",
  },
  footerText: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  foottext: {
    color: "#5b6b96",
    // fontFamily:"WorkSans-Regular",
    fontSize: "10px",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "999",
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    paddingBottom: "30px",
  },
  username: {
    fontSize: "32px",
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "40px",
  },
  gridSpacing: {
    marginLeft: "40px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  confirmInputStreet: {
    marginTop: "15px",
    marginBottom: "20px",
  },
  companyEmail: {
    marginTop: "15px",

  },
  selectInput: {
    marginBottom: "20px",
  },
  selectInputLanguage: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    // backgroundColor:"#173e59",
    // backgroundColor:dynamicColor,
    // backgroundColor:`${dynamicColor}`,
    color: "white",
  },
  button2: {
    // marginTop: "20px",
    // backgroundColor:"#173e59",
    // backgroundColor:dynamicColor,
    // backgroundColor:`${dynamicColor}`,
    color: "white",
  },
  chips: {
    backgroundColor: "#f4f6fc",
  },
  fileNameDisplay: {
    "& div": {
      height: "30px",
      backgroundColor: "#f4f6fc",
      width: "auto",
      display: "flex",
      flexDirection:"row",      
     
      margin: "10px 0px",
      padding: "7px",
     
    }

  },

  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  tab: {
    textTransform: "none"
  },
  dropDown: {
    fontSize: "12px",
    color: "#1a76b0",
  },
  leftViewBg: {

    position: "absolute",
    right: "0",
    bottom: "0",

  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  api_closebtn : {

  },
  one:{
    flexBasis:"0%",
    textAlign:"right",
  },
  two:{
    textAlign:"left",
    flexBasis:"85%"
  },
 
  three:{
    
    flexBasis:"10%",
    textAlign:"right",
  },
} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  isTrue: false;
  files: [];
  dynamicColor: any;
  t?: any;
  i18?: any;
  // Customizable Area End
}

// Customizable Area Start
interface S {
  value: number;
  isTrue: false;
  files: any[];
  dynamicColor: "";
  messageError: any,
  companyDetail: any;
  userInfo: any;
  billingInfo: any;
  ThemeInfo: any;
  anchorEl: null;
  imgs: any;
  fileToRawBinary: any
  resp: any,
  setOpen: any,
  colorPlatteIndex: any;
  primaryColorIndex: any;
  secondaryColorIndex: any;
  primaryColorMainIndex: any;
  secondaryColorMainIndex: any;
  colorPlatte:
  {
    name: any,
    selected: any
    primaryColor: any,
    secondaryColor: any
  }[],
  selectedColor: any
  uploading: any
  optionFontFiles: any
  flag : any
}

interface SS { }

const defaultPrimaryColor = '#173e59';
const defaultSecondaryColor = "#1a76b0";

var imageUrl : any = '';

class CompanyProfile extends BlockComponent<Props, S, SS> {
  static instance: CompanyProfile;
  updateCompanyApiCallId: any;
  getCompanyDetailId: any;
  getCompanyBillingDetailId: any;
  updateCompanyBillingDetailId: any;
  getBrandThemeDetailId: any
  fontUploadApiCallId: any
  fontDeleteApiCallId :any
  fileInput: any;
  getSignedURLForFileUploadId: any;
  getSignedURLForFileUploadId2: any
  userProfileApiCallId: any
  updateCompanyThemeInfoId: any
  primaryColorPicker: any;
  secondaryColorPicker: any;
  deleteCompanyThemeInfoId: any;
  constructor(props: Props) {
    super(props);
    console.log(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    this.receive = this.receive.bind(this);
    this.fileInput = React.createRef();
    this.primaryColorPicker = React.createRef();
    this.secondaryColorPicker = React.createRef();
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      value: 0,
      colorPlatteIndex: 0,
      primaryColorMainIndex: 0,
      primaryColorIndex: 0,
      colorPlatte: [
        {
          name: '',
          selected: false,
          primaryColor: defaultPrimaryColor,
          secondaryColor: defaultSecondaryColor,
        }]
      ,
      secondaryColorMainIndex: 0,
      secondaryColorIndex: 0,
      isTrue: false,
      messageError: "",
      files: [],
      dynamicColor: "",
      companyDetail: null,
      billingInfo: null,
      ThemeInfo: null,
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      anchorEl: null,
      imgs: "",
      fileToRawBinary: null,
      resp: null,
      setOpen: null,
      selectedColor: 0,
      uploading: false,
      optionFontFiles: [],
      flag : 0
    };

    CompanyProfile.instance = this;
  }

  _setColor = (value: any) => {
    if (!value) {
      return {
        color: "red",
      };
    } else {
      return {
        color: value.hex,
      };
    }
  };
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };

  goToHomeScreen = () => {
    this.props.navigation.navigate('HomePage')
  }

  logOut = () => {
    console.log("item2");
    let language: any = localStorage.getItem("i18nextLng");
    localStorage.clear();
    localStorage.setItem("i18nextLng", language);
    this.props.navigation.navigate("EmailAccountLoginBlock");


  }
  uploadImage(responseJson: any) {
    this.setState({ uploading: true })

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };
    // const data = {
    //   // type: "email_account",
    //   attributes: values
    // };

    const httpBody = {
      "logo_blob_signed_id": responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
      // "logo_blob_signed_id": responseJson.data[0]?.signed_id
    };

    // var datas = new FormData()
    // datas.append("photo_blob_signed_id" , responseJson?.data[0]?.signed_id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
      // datas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }
  onChangeFont = (value: any) => {
    console.log("value23", value?.currentTarget?.name, value?.currentTarget?.value);
    if (value?.currentTarget?.name == 'primaryFont') {
      var key = localStorage.getItem("key") ? JSON.parse(localStorage.getItem("key") || `{}`) : {}
      key.font = value?.currentTarget?.value
      localStorage.setItem("key", JSON.stringify(key))
    }
    else if (value?.currentTarget?.name == 'secondaryFont') {

      var key = localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`) : {}
      key.font = value?.currentTarget?.value
      localStorage.setItem("secondarykey", JSON.stringify(key))

    }


  }
  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  getSignedUrl = (file: any) => {
    console.log("File", file)
    this.setState({ fileToRawBinary: file, uploading: true })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    });
  }

  getSignedUrlForCompanyProfilePhoto = (file: any) => {
    console.log("File", file);
    var reader = new FileReader();
    var self = this;
    reader.onload = function (e: any) {
      imageUrl = e.target.result;
      self.forceUpdate();
    }
    reader.readAsDataURL(file);
    this.setState({ fileToRawBinary: file });
  }

  getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }



  async componentDidMount() {

    this.getComapanyDetails();
    this.getBillingDetails();
    this.getBrandThemeDetails();


  }

  //  componentDidUpdate=()=>{
  //    this.getComapanyDetails();
  //   }

  handleChangeColorName = (e: any, index: any) => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr[index].name = e?.target?.value
    // console.log("targrt", e?.target?.value);
    this.setState({ colorPlatte: colorPlatteArr }, () => {
      console.log("colorname", this.state.colorPlatte);

    })

  }
  getBrandThemeDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandThemeDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations?brand_id=${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }
  getBillingDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyBillingDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_billing_details?brand_id=${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = (item?: any) => {
    if (item == 'removeImage') {
      this.setState({flag : 1}, ()=> this.uploadImage(item))
      
    }
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
      imgs: "",
    });
  };
  // handleTakePhoto = (dataUri: any) => {
  //   // Do stuff with the photo...
  //   console.log("takePhoto");
  // };

  inputFileChanged = (e: any) => {
    // console.log(this.fileInput.current.files[0]);

    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
    //  this.setState(
    //    {
    //      imgs: URL.createObjectURL(e.target.files[0]),
    //    },
    //    () => {
    //      // this.addAccountProfile(value, "image");
    //      console.log("imgs", (e.target));

    //    }
    //  );

    // this.addAccountProfile(this.state.imgs,"image")
  };

  getComapanyDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyDetailId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   bodyFormData
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // colorChangeSeondary = (color: any) => {
  //   this.setState({
  //     secondaryColor: color,
  //   });
  //   localStorage.removeItem("secondarykey");

  //   if (color) {
  //     // localStorage.setItem("secondaryColor",JSON.parse(color))
  //     localStorage.setItem("secondarykey", JSON.stringify(color));
  //   }
  // };
  fileChange = (files: any) => {
    this.setState({
      files: files,
    });
    // this.getSignedUrl(files)
  };
  checkBoxChange = (e: any) => {
    console.log(e, "e");
    this.setState({ isTrue: e.target.checked });
  };
  printName(data: any) {
    return data.map((item: any) => {
      if (item != null && item != "") {
        return <p style={{ textAlign: "center" }}>{item.name}</p>;
      }
    });
  }
  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async receive(from: string, message: Message) {
    console.log("receive function");
    console.log(message, "message");

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getCompanyDetailId) {
      this.setState({ companyDetail: message.properties?.RestAPIResponceSuccessMessage?.data?.attributes , uploading : false })
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId2) {

      if (this.state.value === 2) {
        this.fontFileUpload(this.state.resp)
      }
      else if (this.state.value === 0) {
        this.uploadImage(this.state.resp)
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyApiCallId) {
      this.setState({ companyDetail: message.properties?.RestAPIResponceSuccessMessage?.data?.attributes })
      this.setState({uploading : false})
      imageUrl = ""
      toast.success("Saved successfully")
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getCompanyBillingDetailId) {
      this.setState({ billingInfo: message.properties?.RestAPIResponceSuccessMessage?.brand_billings?.data[0] })
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyBillingDetailId) {
      this.setState({ billingInfo: message.properties?.RestAPIResponceSuccessMessage?.data })
      this.setState({uploading:false})
      toast.success("Saved successfully")
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.updateCompanyThemeInfoId) {
      this.setState({ ThemeInfo: message.properties?.RestAPIResponceSuccessMessage?.data })
      this.setState({uploading : false})
      toast.success("Saved successfully")
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteCompanyThemeInfoId) {
      this.setState({ ThemeInfo: message.properties?.RestAPIResponceSuccessMessage?.data })
      this.setState({ uploading : false })
      toast.success("Updated successfully")
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandThemeDetailId) {
      const theme_info = message.properties?.RestAPIResponceSuccessMessage?.brand_themes?.data[0];
      this.setState({ ThemeInfo: theme_info });
      const colorArr = [];

      const secondaryColorArr = [];
      for (const key in this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete) {
        if (Object.prototype.hasOwnProperty.call(this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete, key)) {
          const element = this.state.ThemeInfo?.attributes?.primary_colours?.color_pallete[key];
          if (element.selected == true) {
            this.setState({
              selectedColor: parseInt(key)
            })
          }
          colorArr.push({
            name: element?.name,
            selected: element?.selected,
            primaryColor: element.primaryColor,
            secondaryColor: element.secondaryColor

          });
        }
      }

      if (colorArr.length > 0) this.setState({ colorPlatte: colorArr });
      let fontFiles = [];
      let optionFontFiles = [];
      fontFiles.push(this.state.ThemeInfo?.attributes?.font_file_name_1 ? this.state.ThemeInfo?.attributes?.font_file_name_1 : null);
      fontFiles.push(this.state.ThemeInfo?.attributes?.font_file_name_2 ? this.state.ThemeInfo?.attributes?.font_file_name_2 : null);
      fontFiles = fontFiles.filter((ele: any) => ele != null)
      optionFontFiles = fontFiles.filter((font, index, self) => {
        return self.indexOf(font) === index;
      })
      this.setState({ files: fontFiles, optionFontFiles: optionFontFiles }, () => console.log("filesss", this.state.files, this.state.optionFontFiles))
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ resp: responseJson }, () => {
              this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
            })



            // toast.success("Project create successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      // if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {

      //   this.setState({
      //     projectsOfStatusOnGoingData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
      //   }, () => {
      //   })

      // }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.userProfileApiCallId) {
      if (message.properties?.RestAPIResponceSuccessMessage?.data) {
        // localStorage.removeItem("userInfo")
        // localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.data?.attributes))
        this.getComapanyDetails();
        if(this.state.flag == 1){
          toast.success("Saved successfully")
        }
        
        if (message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.logo_blob_signed_id_url?.length > 0) {
          localStorage.setItem("brandLogo", message?.properties?.RestAPIResponceSuccessMessage?.data?.attributes?.logo_blob_signed_id_url)
        }
        else {
          localStorage.removeItem("brandLogo")
        }

        //toast.success("Saved successfully")
        // this.setState({ userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}') })
        // if (this.state.userInfo?.language == 'English') {
        //   this.handleLanguage('en')
        // }
        // else if (this.state.userInfo?.language == 'German') {
        //   this.handleLanguage('de')
        // }

        // console.log("this.state.userInfo image", this.state.userInfo);
        this.setState({  flag : 0 })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.fontUploadApiCallId) {
      if (message.properties?.RestAPIResponceSuccessMessage?.data) {
        // localStorage.removeItem("userInfo")
        // localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.data?.attributes))
        this.getBrandThemeDetails();
        this.setState({ uploading: false })
        toast.success("Font file uploaded successfully")
        // this.setState({ userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}') })
        // if (this.state.userInfo?.language == 'English') {
        //   this.handleLanguage('en')
        // }
        // else if (this.state.userInfo?.language == 'German') {
        //   this.handleLanguage('de')
        // }

        // console.log("this.state.userInfo image", this.state.userInfo);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.fontDeleteApiCallId) {
      if (message.properties?.RestAPIResponceSuccessMessage?.data) {
          this.getBrandThemeDetails();
        this.setState({ uploading: false })
        toast.success("Font file deleted successfully")
  
      }
    }

    if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
      var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
      errorMessage.forEach((element: any) => {
        this.setState({
          messageError: element.name
        })

      });
      console.log(this.state.messageError, "error")
      toast.error(this.state.messageError)

    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            // category list



            //search live


            //recent search

          }
        }
      }
    }


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }



  }

  handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedColor: parseInt((event.target as HTMLInputElement).value)
    }, () => {
      console.log("selectedColor", this.state.selectedColor, typeof this.state.selectedColor);
      let key = JSON.parse(localStorage.getItem("key") || `{}`)
      key.hex = this.state.colorPlatte[this.state.selectedColor].primaryColor
      localStorage.setItem("key", JSON.stringify(key));
      let secondarykey = JSON.parse(localStorage.getItem("secondarykey") || `{}`)
      secondarykey.hex = this.state.colorPlatte[this.state.selectedColor].secondaryColor
      localStorage.setItem("secondarykey", JSON.stringify(secondarykey));
      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
      for (let i = 0; i < colorPlatteArr.length; i++) {
        if (i == this.state.selectedColor) {
          colorPlatteArr[this.state.selectedColor].selected = true
        }
        else {
          colorPlatteArr[i].selected = false
        }
      }
      this.setState({ colorPlatte: colorPlatteArr }, () => {
        console.log("final color", this.state.colorPlatte);

      })
    })
  }
  deleteFontFile=(file:any, index : any)=>{
    console.log("delete", file, index);
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };
    var httpBody;
    if (this.state.ThemeInfo?.attributes?.font_file_name_1?.length > 0 && index == 0) {
      httpBody = {
        // "photo_blob_signed_id" : responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
        "font_file_1_blob_signed_id": null,
        "font_file_name_1": null
      };
    }
    else  {
      httpBody = {
        // "photo_blob_signed_id" : responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
        "font_file_2_blob_signed_id": null,
        "font_file_name_2": null
      };
    }

    const data = {
      // type: "email_account",
      "data": httpBody
    };

    // var datas = new FormData()
    // datas.append("photo_blob_signed_id" , responseJson?.data[0]?.signed_id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fontDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
      // datas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }
  fontFileUpload(responseJson: any) {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };
    var httpBody;
    if ( this.state.ThemeInfo?.attributes?.font_file_1_blob_signed_id_url == null|| this.state.ThemeInfo?.attributes?.font_file_1_blob_signed_id_url?.length == 0 ) {
      httpBody = {
        // "photo_blob_signed_id" : responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
        "font_file_1_blob_signed_id": responseJson.data[0]?.signed_id,
        "font_file_name_1": this.state.fileToRawBinary?.name
      };
    }
    else {
      httpBody = {
        // "photo_blob_signed_id" : responseJson != "removeImage" ? responseJson.data[0]?.signed_id : null
        "font_file_2_blob_signed_id": responseJson.data[0]?.signed_id,
        "font_file_name_2": this.state.fileToRawBinary?.name
      };
    }

    const data = {
      // type: "email_account",
      "data": httpBody
    };

    // var datas = new FormData()
    // datas.append("photo_blob_signed_id" , responseJson?.data[0]?.signed_id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fontUploadApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
      // datas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  updateThemeInfo = (values: any) => {
    this.setState({uploading : true})
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    // var primary;
    // var secondary;
    // if (this.state.colorPlatte[0].primaryColor?.length > 0) {
    //   primary = {
    //     "color_1": this.state.colorPlatte[0].primaryColor[0]?.hex ? this.state.colorPlatte[0].primaryColor[0].hex : null,
    //   }
    // }
    // if (this.state.colorPlatte[0].secondaryColor?.length > 0) {
    //   secondary = {
    //     "color_1": this.state.colorPlatte[0].secondaryColor[0]?.hex ? this.state.colorPlatte[0].secondaryColor[0].hex : null,
    //   }
    // }

    // this.state.primaryColor?.length > 0 && this.state.primaryColor.length.map((col: any , index : any)=> primary) 
    var bodyFormDataUpdate = {
      "primary_font": values.primaryFont,
      "secondary_font": values.secondaryFont,
      "primary_colours": {
        "color_pallete": this.state.colorPlatte,
      },
      "selected_primary_colour": this.state.colorPlatte[this.state.selectedColor].primaryColor,
      "selected_secondary_colour": this.state.colorPlatte[this.state.selectedColor].secondaryColor


      // 'tax_id': values.taxID,
      // 'vat_id': values.vATID
    }

    var bodyFormDataAdd = {
      "brand_id": this.state.userInfo.brand_id,
      "primary_font": values.primaryFont,
      "secondary_font": values.secondaryFont,
      "primary_colours": {
        "color_pallete": this.state.colorPlatte,
      },
      "selected_primary_colour": this.state.colorPlatte[this.state.selectedColor].primaryColor,
      "selected_secondary_colour": this.state.colorPlatte[this.state.selectedColor].secondaryColor
      // 'tax_id': values.taxID,
      // 'vat_id': values.vATID
    }
    var body;
    this.state.ThemeInfo ? body = bodyFormDataUpdate : body = bodyFormDataAdd
    //  this.state.billingInfo ?  Object.assign({ "brand_id": this.state.userInfo.brand_id }, bodyFormData) : null;
    // bodyFormData = this.state.userInfo?.brand_id
    // values.billingEmail ? bodyFormData.append('billing_email', values.billingEmail) : null;
    // values.contactFirstName ? bodyFormData.append('first_name', values.contactFirstName) : null;
    // values.contactLastName ? bodyFormData.append('last_name', values.contactLastName) : null;
    // values.taxID ? bodyFormData.append('tax_id', values.taxID) : null;
    // values.vATID ? bodyFormData.append('vat_id', values.vATID) : null;
    // values.street ? bodyFormData.append('street', values.street) : null;
    // values.postCode ? bodyFormData.append('postcode', values.postCode) : null;
    // values.city ? bodyFormData.append('city', values.city) : null;
    // values.country ? bodyFormData.append('country', values.country) : null;
    // console.log(bodyFormData,"data")
    console.log("bodyFormData", body);

    const httpBody = {
      data: body,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyThemeInfoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.ThemeInfo ?
        `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo.id}`
        :
        `bx_block_categories_sub_categories/brand_theme_informations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // bodyFormData
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.ThemeInfo ?
        "PUT"
        :
        "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  handleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    this.setState({ setOpen: false })
    console.log("takePhoto", dataUri);
    var type = dataUri.slice(5, 14);
    console.log("type", type);

    // var file = this.dataURLtoFile("dataUri",this.state.userInfo?.first_name);
    this.urltoFile(dataUri, this.state.userInfo?.first_name, type).then((file) => { console.log(file); this.getSignedUrlForCompanyProfilePhoto(file) });
    // console.log("camera file",file);

  };

  urltoFile = (url: any, filename: any, mimeType: any) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  updateCompanyBillingDetails = (values: any) => {
    this.setState({uploading : true})
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    // if(value === 'image'){
    //   const data = new FormData();
    //   const imagedata = values;
    //   const date =
    //   data.append('photo', imagedata);
    // }
    // var bodyFormData = new FormData();
    // if(values.companyName){
    //   this.addCompanyProfile(values);
    // }
    var bodyFormDataUpdate = {
      "billing_email": values.billingEmail,
      'first_name': values.contactFirstName,
      'last_name': values.contactLastName,
      'tax_id': values.taxID,
      'vat_id': values.vATID
    }

    var bodyFormDataAdd = {
      "brand_id": this.state.userInfo.brand_id,
      "billing_email": values.billingEmail,
      'first_name': values.contactFirstName,
      'last_name': values.contactLastName,
      'tax_id': values.taxID,
      'vat_id': values.vATID
    }
    var body;
    this.state.billingInfo ? body = bodyFormDataUpdate : body = bodyFormDataAdd
    const httpBody = {
      data: body,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyBillingDetailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.billingInfo ?
        `bx_block_categories_sub_categories/brand_billing_details/${this.state.billingInfo.id}`
        :
        `bx_block_categories_sub_categories/brand_billing_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // bodyFormData
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.billingInfo ?
        "PUT"
        :
        "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addCompanyProfile = (values: any, value?: any) => {
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token")
    };
    // if(value === 'image'){
    //   const data = new FormData();
    //   const imagedata = values;
    //   const date =
    //   data.append('photo', imagedata);
    // }
    var bodyFormData = new FormData();
    values.companyName ? bodyFormData.append('name', values.companyName) : null;
    values.companyURL ? bodyFormData.append('website_url', values.companyURL) : null;
    values.companyEmail ? bodyFormData.append('email_domain', values.companyEmail) : null;
    values.companySize ? bodyFormData.append('company_size', values.companySize) : null;
    values.phoneNumber ? bodyFormData.append('phone_number', values.phoneNumber) : null;
    values.industry ? bodyFormData.append('industry', values.industry) : null;
    values.street ? bodyFormData.append('street', values.street) : null;
    values.postCode ? bodyFormData.append('postcode', values.postCode) : null;
    values.city ? bodyFormData.append('city', values.city) : null;
    values.country ? bodyFormData.append('country', values.country) : null;
    // console.log(bodyFormData,"data")
    const httpBody = {
      data: bodyFormData
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands/${this.state.userInfo?.brand_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    if(this.state.value === 0 && imageUrl.length > 0){
      if(this.state.fileToRawBinary && this.state.fileToRawBinary !== {}){
        let file = this.state.fileToRawBinary;
        this.setState({uploading : true})
        this.computeChecksumMd5(file).then((md5) => {
          this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
        });
      }
    }
    
    return true;
  };

  logoHandleChange = (event: any, value: any) => {
    // console.log(event.target.value);

    // setValue(newValue);
    console.log(value);
    // this.setState({
    //   value: value,
    // });
    // this.statvalue=value
  };
  logoHandleClick = (event: any) => {
    // this.setState({
    //   anchorEl: event.currentTarget,
    // });
  };

  logoHandleClose = (item?: any) => {
    console.log(item, "acount");
    // this.setState({
    //   anchorEl: null,
    //   imgs: "",
    // });
  };
  ; pgpHandleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    console.log("takePhoto");
  };

  triggerColorPicker = (mainColorPlatteIndex: number, index = 0) => {
    console.log("click !!" , mainColorPlatteIndex);
    
    this.setState({ colorPlatteIndex: mainColorPlatteIndex });
    this.setState({ primaryColorIndex: index });
    this.primaryColorPicker.current.click();
  }
  triggerSecondaryColorPicker = (mainColorPlatteIndex: number, index = 0) => {
    this.setState({ colorPlatteIndex: mainColorPlatteIndex });
    this.setState({ secondaryColorIndex: index });
    this.secondaryColorPicker.current.click();
  }

  primaryColorChange = (e: any) => {
    console.log("color", e?.target?.value);
    const value = e?.target?.value;
    if (value) {
      const colorPlatteIndex = this.state.colorPlatteIndex;
      // const primaryColorIndex = this.state.primaryColorIndex;
      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;

      colorPlatteArr[colorPlatteIndex].primaryColor = value;
      this.setState(
        {
          colorPlatte: colorPlatteArr
        }, () => {
          console.log("abcs", this.state.colorPlatte);
          // if (primaryColorIndex == 0) {
          let key = JSON.parse(localStorage.getItem("key") || `{}`)
          key.hex = value
          localStorage.setItem("key", JSON.stringify(key));
          // }

          // localStorage.setItem("primaryColor",JSON.parse(color))

        }
      );
    };
  }

  secondaryColorChange = (e: any) => {
    console.log("color", e?.target?.value);
    const value = e?.target?.value;
    if (value) {
      const colorPlatteIndex = this.state.colorPlatteIndex;
      const secondaryColorIndex = this.state.secondaryColorIndex;
      let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;

      colorPlatteArr[colorPlatteIndex].secondaryColor = value;
      // colorPlatteArr[colorPlatteIndex].secondaryColor[secondaryColorIndex].selected = true;
      // for (let i = 0; i < colorPlatteArr.length; i++) {
      //   var flag = false;
      //   for(let k =0 ; k<colorPlatteArr[i].primaryColor.length ; k++ ){
      //     if(colorPlatteArr[i].primaryColor[k].selected == true){
      //       flag = true;
      //       break;
      //     }
      //   }
      //   if(flag == false){
      //     colorPlatteArr[i].primaryColor[0].selected = true 
      //   }
      //   for (let j = 0; j < colorPlatteArr[i].primaryColor.length; j++) {
      //     if (i == colorPlatteIndex && flag) {

      //       //colorPlatteArr[i].primaryColor[0].selected = true 
      //       colorPlatteArr[i].secondaryColor[0].selected = true

      //     }
      //     else {
      //       colorPlatteArr[i].primaryColor[j].selected = false
      //     }
      //   }




      //   for (let j = 0; j < colorPlatteArr[i].secondaryColor.length; j++) {
      //     if (i == colorPlatteIndex && j == secondaryColorIndex) {
      //       colorPlatteArr[i].secondaryColor[j].selected = true
      //       colorPlatteArr[i].primaryColor[0].selected = true

      //     }
      //     else {
      //       colorPlatteArr[i].secondaryColor[j].selected = false
      //     }
      //   }

      // }
      // for(let i =0 ; i<colorPlatteArr[colorPlatteIndex].secondaryColor.length ; i++) {
      //   if(i == secondaryColorIndex){
      //     colorPlatteArr[colorPlatteIndex].secondaryColor[i].selected = true 
      //       }
      //       else {
      //         colorPlatteArr[colorPlatteIndex].secondaryColor[i].selected = false 
      //       }
      // }
      console.log(colorPlatteArr);

      this.setState(
        {
          colorPlatte: colorPlatteArr
        }, () => {
          let key = JSON.parse(localStorage.getItem("secondarykey") || `{}`)
          key.hex = value
          localStorage.setItem("secondarykey", JSON.stringify(key));
          // if (secondaryColorIndex == 0) {
          //   localStorage.setItem("key", JSON.stringify({
          //     hex: value,
          //   }));
          // }
          // localStorage.setItem("primaryColor",JSON.parse(color))

        }
      );
    };
  }

  incrementPrimaryColor = (mainColorPlatteIndex: number) => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr[mainColorPlatteIndex].primaryColor.push({
      hex: defaultPrimaryColor,
      selected: false
    });
    this.setState(
      {
        colorPlatte: colorPlatteArr
      }
    );
  }

  incrementSecondaryColor = (mainColorPlatteIndex: number) => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr[mainColorPlatteIndex].secondaryColor.push({
      hex: defaultSecondaryColor,
      selected: false
    });
    this.setState(
      {
        colorPlatte: colorPlatteArr
      }
    );
  }

  incrementColorPallete = () => {
    let colorPlatteArr: S['colorPlatte'] = this.state.colorPlatte;
    colorPlatteArr.push({
      name: "",
      selected: false,
      primaryColor: defaultPrimaryColor,
      secondaryColor: defaultSecondaryColor,
    })
    this.setState(
      {
        colorPlatte: colorPlatteArr
      }
    )

  }

  deleteColorPallet = (id : any) => {
    let existingColorPlatte = this.state.colorPlatte;
    console.log("colorPlatteIndexcolorPlatteIndex",existingColorPlatte)
    existingColorPlatte.splice(id, 1)
    this.setState({
      colorPlatteIndex: 0,
      colorPlatte: existingColorPlatte,
      uploading : true
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    var body = {
      "primary_colours": {
        "color_pallete": existingColorPlatte,
      },
    }
    
    const httpBody = {
      data: body,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCompanyThemeInfoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brand_theme_informations/${this.state.ThemeInfo.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  render() {
    const { t, navigation, classes } = this.props;
    const _this = this;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;


    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      <div style={{ overflowY: "auto", width: "100%", height: "100%", fontFamily: primaryFont }}>
        {/* <Backdrop
          className={classes.backdrop}
         
          open={this.state.uploading}
          onClick={() => this.setState({ uploading: false })}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <Grid container spacing={0}>


          <Grid item xs={2} className={classes.sideNav}>
          <Backdrop
          className={classes.backdrop}
         
          open={this.state.uploading}
          onClick={() => this.setState({ uploading: false })}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className={classes.topLogo}>
              <img style={{
                width: "120px", height: "120px", margin: "0 91px 36px 0",
                //  borderRadius: "45%" ,
                objectFit: "scale-down"
              }} src={this.state.companyDetail?.logo_blob_signed_id_url ? this.state.companyDetail?.logo_blob_signed_id_url : brandLogo} />
              <p className={classes.userName}>{this.state.companyDetail?.name ? this.state.companyDetail.name : ""}</p>
              <p className={classes.userDesignation}>
                {t("CompanyProfile.agency")}
              </p>
            </div>

            <div className={classes.logos}>
              <img src={designHarvestLogo} />
              <p className={classes.footerText}>© {new Date().getFullYear()} DESIGN HARVEST</p>
              <p
                className={classes.foottext}
                style={{ cursor: "pointer"  ,color : secondaryColorToBeChanged }}
                onClick={() => {
                  window.location.replace('/terms-and-conditions');
                }}
              >
                {t("CompanyProfile.termOfService")}
              </p>
              <p
                className={classes.foottext}
                style={{ cursor: "pointer"  ,color : secondaryColorToBeChanged}}
                onClick={() => {
                  window.location.replace('/privacy-policy');
                }}
              >{t("CompanyProfile.privacy")}</p>
              <p className={classes.foottext} onClick={this.logOut} style={{ cursor: "pointer", fontFamily: primaryFont  ,color : secondaryColorToBeChanged}}>{t("CompanyProfile.logout")}</p>
            </div>
            <img src={leftViewBg} className={classes.leftViewBg} />
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <AppBar position="static" className={classes.appBar}>
                  <Toolbar variant="dense">
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                    >
                      <div className={classes.plusButtonIcon} />
                    </IconButton>

                    <Typography variant="h6" color="inherit" />
                  </Toolbar>

                  <Grid container spacing={0} className={classes.nav_main}>
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={designHarvestLogo} />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ color: secondaryColorToBeChanged, cursor: "pointer", fontFamily: primaryFont }}
                            // onClick={this.goToHomeScreen}
                            onClick={(e) => window.location.href = '/Home/HomePage'}
                          >
                            {
                              `${t(
                                "CompanyProfile.backTo"
                              )} Design Harvest`}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AppBar>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={10} className={classes.content}>
                {/* <Grid container spacing={0}>
<Grid xs={10}>

</Grid>
<Grid xs={2}>

</Grid>
              </Grid> */}

                <p className={classes.username}>
                  {t("CompanyProfile.companyProfile")}
                </p>

                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  classes={{ indicator: classes.indicator }}
                  aria-label="scrollable force tabs example"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={t("CompanyProfile.general")}
                    className={classes.tab}
                    style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }}
                  />
                  <Tab
                    label={t("CompanyProfile.billingDetails")}
                    className={classes.tab}
                    style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }}
                  />
                  <Tab
                    label={t("CompanyProfile.brandInformation")}
                    className={classes.tab}
                    style={{ color: secondaryColorToBeChanged, fontFamily: SecondaryFont }}
                  />
                </Tabs>
                <div className={classes.gridSpacing}>
                  {this.state.value === 0 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Formik
                            initialValues={{
                              // email: "",
                              // animal: "",
                              companyName: this.state.companyDetail?.name ? this.state.companyDetail.name : "",
                              companySize: this.state.companyDetail?.company_size ? this.state.companyDetail.company_size : "",
                              industry: this.state.companyDetail?.industry ? this.state.companyDetail.industry : "",
                              companyEmail: this.state.companyDetail?.email_domain ? this.state.companyDetail.email_domain : '',
                              companyURL: this.state.companyDetail?.website_url ? this.state.companyDetail.website_url : '',
                              phoneNumber : this.state.companyDetail?.phone_number ? this.state.companyDetail.phone_number : '',
                              street: this.state.companyDetail?.street ? this.state.companyDetail.street : '',
                              postCode: this.state.companyDetail?.postcode ? this.state.companyDetail.postcode : '',
                              city: this.state.companyDetail?.city ? this.state.companyDetail.city : '',
                              country: this.state.companyDetail?.country ? this.state.companyDetail.country : '',
                            }}
                            enableReinitialize
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                              companyName: Yup.string()
                                .required("company name is required"),
                              companySize: Yup.string(),
                              industry: Yup.string(),
                              companyEmail: Yup.string()
                                .required("company domain is required"),
                              companyURL: Yup.string()
                                .required("company URL is required"),
                                phoneNumber : Yup.string(),
                              street: Yup.string(),
                              postCode: Yup.string(),
                              city: Yup.string(),
                              country: Yup.string(),
                            })}
                            onSubmit={(values) => {
                              console.log(values);
                              this.setState({uploading : true})
                              // this.goToConfirmationAfterPasswordChange(values);
                              // this.props.nextStep(values);
                              this.addCompanyProfile(values)
                            }}
                            render={({
                              values,
                              errors,
                              touched,
                              handleChange,
                              isValid,
                              handleBlur,
                              setFieldTouched,
                              isSubmitting,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                                <TextField
                                  name="companyName"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  fullWidth
                                  label={t("CompanyProfile.companyName")}
                                  value={values.companyName}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  helperText={
                                    touched.companyName &&
                                      errors.companyName ? (
                                      <Trans>
                                        CompanyProfile.companyNameRequired
                                      </Trans>

                                    ) : (
                                      ""
                                    )

                                  }
                                  error={
                                    touched.companyName && errors.companyName
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="companySize"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}

                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t("CompanyProfile.companySize")}
                                      value={values.companySize}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        errors.companySize &&
                                          touched.companySize ? (
                                          <Trans>
                                            CompanyProfile.companyRequired
                                          </Trans>
                                        ) : (
                                          ""
                                        )
                                      }
                                      error={
                                        touched.companySize &&
                                          errors.companySize
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >

                                    </TextField>
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      name="industry"
                                      select
                                      SelectProps={{
                                        native: true,
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      label={t("CompanyProfile.industry")}
                                      value={values.industry}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        touched.industry && errors.industry ? (
                                          <Trans>
                                            CompanyProfile.industryRequired
                                          </Trans>
                                        ) : (
                                          ""
                                        )
                                      }
                                      error={
                                        touched.industry && errors.industry
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option aria-label="None" value="" />
                                      {/* <option value="luxurygoods">Luxury goods</option>
                                      <option value="goods">goods</option> */}
                                      <option value="Services">Services</option>
                                      <option value="Entertainment / Gastro / Tourism / Culture">Entertainment / Gastro / Tourism / Culture</option>
                                      <option value="Real Estate / Construction / Architecture">Real Estate / Construction / Architecture</option>
                                      <option value="Industry">Industry</option>
                                      <option value="Industrial machinery / mechanical engineering / electronics / medical technology">Industrial machinery / mechanical engineering / electronics / medical technology</option>
                                      <option value="LEH / FMCG / Retail">LEH / FMCG / Retail</option>
                                      <option value="Luxury / Fashion">Luxury / Fashion</option>
                                      <option value="Software / IT / Digital">Software / IT / Digital</option>
                                      <option value="Advertising / Marketing / Agencies / Publishing">Advertising / Marketing / Agencies / Publishing</option>
                                    </TextField>
                                  </Grid>
                                </Grid>
                                <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label={t("CompanyProfile.companyEmailDomain")}
                                  name="companyEmail"
                                  fullWidth
                                  value={values.companyEmail}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  className={classes.companyEmail}
                                  size="small"
                                  helperText={
                                    touched.companyEmail && errors.companyEmail ? (
                                      <Trans>
                                        CompanyProfile.companyEmailDomainRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyEmail && errors.companyEmail
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label={t("CompanyProfile.companyUrl")}
                                  name="companyURL"
                                  fullWidth
                                  value={values.companyURL}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  className={classes.confirmInputStreet}
                                  size="small"
                                  helperText={
                                    touched.companyURL && errors.companyURL ? (
                                      <Trans>
                                        CompanyProfile.companyUrlRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.companyURL && errors.companyURL
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  label="Phone Number"
                                  name="phoneNumber"
                                  fullWidth
                                  value={values.phoneNumber}
                                  variant="outlined"
                                  className={classes.confirmInputStreet}
                                  size="small"
                                  helperText={
                                    touched.phoneNumber && errors.phoneNumber ? (
                                      <Trans>
                                        CompanyProfile.phoneNumberRequired
                                      </Trans>
                                    ) : (
                                      ""
                                    )
                                  }
                                  error={
                                    touched.phoneNumber && errors.phoneNumber
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <p style={{ fontSize: "20px" }}>   {t("CompanyProfile.address")}</p>
                                <TextField
                                  name="street"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  type="text"
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.street")}
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  value={values.street}
                                  variant="outlined"
                                  helperText={
                                    touched.street && errors.street
                                      ? (
                                        <Trans>
                                          CompanyProfile.streetRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.street && errors.street
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="postCode"
                                      type="text"
                                      size="small"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t("CompanyProfile.postCode")}
                                      value={values.postCode}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        errors.postCode && touched.postCode
                                          ? (
                                            <Trans>
                                              CompanyProfile.postCodeRequied
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.postCode && errors.postCode
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      name="city"
                                      type="text"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      label={t("CompanyProfile.city")}
                                      value={values.city}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        touched.city && errors.city
                                          ? (
                                            <Trans>
                                              CompanyProfile.cityRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.city && errors.city
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                </Grid>

                                <TextField
                                  name="country"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.country")}
                                  value={values.country}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  helperText={
                                    touched.country && errors.country
                                      ? (
                                        <Trans>
                                          CompanyProfile.countryRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.country && errors.country
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                  <Button
                                    className={classes.button}
                                    style={{ backgroundColor: colorToBeChanged }}
                                    type="submit"
                                    variant="contained"

                                  >
                                    {t("CompanyProfile.saveChanges")}
                                  </Button>
                                  :
                                  null

                                }
                              </form>
                            )}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          {/* <div style={{ marginLeft: "40%" }}>
                            <img src={brandLogo} />
                            <p style={{ fontSize: "18px" }}>{t("CompanyProfile.myAvatar")}</p>
                            <p style={{ fontSize: "14px" }}>
                              {t("CompanyProfile.myAvatarPargraph")}
                            </p>
                          </div> */}
                          <Grid container spacing={0}>
                            <Grid item xs={8}>
                              <div style={{ marginLeft: "40%" }}>
                                <Grid container spacing={0}>
                                  <Grid item xs={6}>
                                    {/* <img src={userImage} style={{width:"100%"}} /> */}
                                    <img
                                      src={imageUrl && imageUrl !== "" ? imageUrl : this.state.companyDetail?.logo_blob_signed_id_url ? this.state.companyDetail?.logo_blob_signed_id_url : brandLogo}
                                      alt="img"
                                      style={{
                                        width: "96px", height: "96px", borderRadius: "50%", border: "16px solid #f8f9fd", objectFit: "scale-down",
                                        // objectPosition: "left"
                                      }}
                                    />
                                  </Grid>
                                  {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                    <Grid item xs={6}>
                                      {/* <PanoramaFishEyeSharpIcon  style={{fontSize:"30px",marginTop:"35%",marginLeft:"-2px",color:"#d2daf8"}}>
                                 <ExpandMoreIcon style={{fontSize:"20px",color:"red"}}/>
                                 </PanoramaFishEyeSharpIcon> */}
                                      <ArrowDropDownCircleIcon
                                        style={{
                                          fontSize: "30px",
                                          marginTop: "59%",
                                          marginLeft: "6px",
                                          color: "#d2daf8",
                                          // width: "24px",
                                          // height: "24px",
                                          // margin: "62px 23px 78px 49px",
                                          // padding: "7px 6px 6px",
                                          // border: "solid 1px #d2daf8",
                                          // backgroundColor: "#ffffff"
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                          this.handleClick(e);
                                        }}
                                      />
                                      <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={() => {
                                          this.handleClose();
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) => {
                                            this.handleClose("uploadImage");
                                          }}
                                          style={{ alignItems: "center", color: secondaryColorToBeChanged, fontFamily: primaryFont }}
                                          className={classes.dropDown}
                                        >
                                          <span style={{ marginLeft: "-3px" }}>
                                            <label
                                              htmlFor="upload"
                                              className="label_tags"
                                            >

                                              {/* <input
                                                type="file"
                                                id="upload"
                                                name="pic"
                                                accept="image/jpg, image/jpeg, image/png"
                                                capture={true}
                                                ref={this.fileInput}
                                                onChange={(event) =>
                                                  this.inputFileChanged(event)
                                                }
                                                style={{
                                                  visibility: "hidden",
                                                  position: "absolute",
                                                }}
                                              /> */}
                                              <ReactS3Uploader
                                                type="file"
                                                id="upload"
                                                name="pic"
                                                // className={uploaderClassName}
                                                getSignedUrl={this.getSignedUrlForCompanyProfilePhoto}
                                                //accept="image/*"
                                                // onProgress={onProgress}
                                                // onError={onError}
                                                // onFinish={onFinish}
                                                uploadRequestHeaders={{
                                                  'x-amz-acl': 'public-read'
                                                }}
                                                s3path="/uploads/"
                                                contentDisposition="auto"
                                                accept="image/jpg, image/jpeg, image/png"
                                                capture={true}
                                                ref={this.fileInput}
                                                onChange={(event: any) =>
                                                  this.inputFileChanged(event)
                                                }
                                                style={{
                                                  visibility: "hidden",
                                                  position: "absolute",
                                                }}
                                              />
                                              <span>
                                                <PublishOutlinedIcon
                                                  style={{
                                                    fontSize: "20px",
                                                    marginRight: "10px",
                                                    color: colorToBeChanged,
                                                    marginBottom: "-5px"
                                                  }}
                                                />
                                              </span>

                                              <span>{t("AccountProfile.uploadImg")} </span>
                                            </label>
                                          </span>

                                        </MenuItem>

                                        <MenuItem
                                          onClick={(e) => {
                                            this.handleClose("removeImage");
                                          }}
                                          className={classes.dropDown}
                                          style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
                                        >
                                          <RemoveCircleOutlineIcon
                                            style={{
                                              fontSize: "20px",
                                              marginRight: "10px",
                                              color: colorToBeChanged,
                                            }}
                                          />{" "}
                                          {t("AccountProfile.removeImg")}
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() => {
                                            this.setState({ setOpen: true });
                                          }}
                                          className={classes.dropDown}
                                          style={{ color: secondaryColorToBeChanged, fontFamily: primaryFont }}
                                        >
                                          {" "}
                                          <CameraAltOutlinedIcon
                                            style={{
                                              fontSize: "20px",
                                              marginRight: "10px",
                                              color: colorToBeChanged,
                                            }}
                                          />
                                          {/* <Camera
                                     onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                                   /> */}
                                          {t("AccountProfile.takePicture")}
                                        </MenuItem>
                                      </Menu>
                                    </Grid>


                                    :
                                    null

                                  }
                                </Grid>

                                <p style={{ fontSize: "18px" }}>
                                  {t("CompanyProfile.myAvatar")}
                                </p>
                                <p style={{ fontSize: "14px" }}>
                                  {" "}
                                  {t("CompanyProfile.myAvatarPargraph")}
                                </p>
                              </div>

                              {/* <Camera
      onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
    /> */}
                            </Grid>
                            <Grid item xs={4} />
                          </Grid>

                        </Grid>
                      </Grid>
                    </>
                  )}
                  {this.state.value === 1 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Formik

                            initialValues={{
                              // email: "",
                              // animal: "",
                              companyName: this.state.companyDetail?.name ? this.state.companyDetail.name : "",
                              billingEmail: this.state.billingInfo?.attributes?.billing_email ? this.state.billingInfo?.attributes.billing_email : "",
                              contactFirstName: this.state.billingInfo?.attributes?.first_name ? this.state.billingInfo?.attributes.first_name : "",
                              contactLastName: this.state.billingInfo?.attributes?.last_name ? this.state.billingInfo?.attributes.last_name : "",
                              vATID: this.state.billingInfo?.attributes?.vat_id ? this.state.billingInfo?.attributes.vat_id : "",
                              taxID: this.state.billingInfo?.attributes?.tax_id ? this.state.billingInfo?.attributes.tax_id : "",
                            }}
                            enableReinitialize
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                              companyName: Yup.string()
                                .required("Company Name is required"),
                              billingEmail: Yup.string().email('Invalid billing email').required('billing email is required'),

                              contactFirstName: Yup.string().required("contact first name is required"),
                              contactLastName: Yup.string().required("contact last name is required"),
                              vATID: Yup.string(),
                              taxID: Yup.string(),
                            })}
                            onSubmit={(values) => {
                              console.log(values);
                              // this.goToConfirmationAfterPasswordChange(values);
                              // this.props.nextStep(values);
                              this.updateCompanyBillingDetails(values)
                            }}

                            render={({
                              values,
                              errors,
                              touched,
                              handleChange,
                              isValid,
                              handleBlur,
                              setFieldTouched,
                              isSubmitting,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                                <TextField
                                  name="companyName"
                                  type="text"
                                  size="small"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  disabled
                                  fullWidth
                                  label={t("CompanyProfile.companyName")}
                                  value={values.companyName}
                                  className={classes.confirmInput}
                                  variant="outlined"
                                  helperText={
                                    touched.companyName && errors.companyName
                                      ? (
                                        <Trans>
                                          CompanyProfile.companyNameRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.companyName && errors.companyName
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  label={t("CompanyProfile.billingEmail")}
                                  name="billingEmail"
                                  fullWidth
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  value={values.billingEmail}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  className={classes.confirmInput}
                                  helperText={
                                    touched.billingEmail && errors.billingEmail
                                      ? (
                                        <Trans>
                                          {/* CompanyProfile.billingEmailRequired */}
                                          {errors.billingEmail}
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.billingEmail && errors.billingEmail
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Grid container>
                                  <Grid xs={6}>
                                    <TextField
                                      name="contactFirstName"
                                      type="text"
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      size="small"
                                      className={classes.confirmInput}
                                      style={{ width: "95%" }}
                                      label={t(
                                        "CompanyProfile.contactFirstName"
                                      )}
                                      value={values.contactFirstName}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        errors.contactFirstName &&
                                          touched.contactFirstName
                                          ? (
                                            <Trans>
                                              CompanyProfile.contactFirstNameRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.contactFirstName &&
                                          errors.contactFirstName
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>

                                  <Grid xs={6}>
                                    <TextField
                                      name="contactLastName"
                                      type="text"
                                      size="small"
                                      className={classes.confirmInput}
                                      fullWidth
                                      InputProps={{ classes: { root: classes.inputRoot } }}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      label={t(
                                        "CompanyProfile.contactLastName"
                                      )}
                                      value={values.contactLastName}
                                      variant="outlined"
                                      disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                      helperText={
                                        touched.contactLastName &&
                                          errors.contactLastName
                                          ? (
                                            <Trans>
                                              CompanyProfile.contactLastNameRequired
                                            </Trans>
                                          )
                                          : ""
                                      }
                                      error={
                                        touched.contactLastName &&
                                          errors.contactLastName
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                </Grid>

                                <TextField
                                  name="vATID"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.vatId")}
                                  value={values.vATID}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  helperText={
                                    touched.vATID && errors.vATID
                                      ? (
                                        <Trans>
                                          CompanyProfile.vatIdRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.vATID && errors.vATID ? true : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  name="taxID"
                                  type="text"
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  size="small"
                                  className={classes.confirmInput}
                                  fullWidth
                                  label={t("CompanyProfile.taxId")}
                                  value={values.taxID}
                                  variant="outlined"
                                  disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                  helperText={
                                    touched.taxID && errors.taxID
                                      ? (
                                        <Trans>
                                          CompanyProfile.taxIdRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.taxID && errors.taxID ? true : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                  <Button
                                    className={classes.button}
                                    style={{ backgroundColor: colorToBeChanged }}
                                    type="submit"
                                    variant="contained"

                                  >
                                    {t("CompanyProfile.saveChanges")}
                                  </Button>
                                  :
                                  null

                                }
                              </form>
                            )}
                          />
                        </Grid>
                        {/* <img src={RightViewBg} style={{
                          maxWidth: "298px",
                          height: "auto",
                          margin: 0,
                          position: "absolute",
                          bottom: 0,
                          right: 0
                        }} /> */}
                        <Grid item xs={6} />
                      </Grid>
                    </>
                  )}
                  {this.state.value === 2 && (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <div style={{ paddingBottom: "50px", fontFamily: primaryFont }}>
                            <Formik
                              initialValues={{
                                // email: "",
                                // animal: "",
                                // nameColorPalatte: this.state.ThemeInfo?.attributes?.colour_palette_name ? this.state.ThemeInfo.attributes.colour_palette_name : "",
                                primaryFont: this.state.ThemeInfo?.attributes?.primary_font ? this.state.ThemeInfo.attributes.primary_font : "",
                                secondaryFont: this.state.ThemeInfo?.attributes?.secondary_font ? this.state.ThemeInfo.attributes.secondary_font : "",
                              }}
                              validateOnBlur={false}
                              validationSchema={Yup.object().shape({
                                // nameColorPalatte: Yup.string()
                                //   .required("Name Color Palatte is required"),
                                primaryFont: Yup.string(),

                                secondaryFont: Yup.string(),
                              })}
                              onSubmit={(values) => {
                                console.log(values);
                                // this.goToConfirmationAfterPasswordChange(values);
                                // this.props.nextStep(values);
                                this.updateThemeInfo(values)
                              }}

                              render={({
                                values,
                                errors,
                                touched,
                                handleChange,
                                isValid,
                                handleBlur,
                                setFieldTouched,
                                isSubmitting,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  {/* ================================================================== */}
                                  <RadioGroup value={this.state.selectedColor} onChange={(e) => this.handleChangeRadio(e)}>
                                    {this.state.colorPlatte?.length > 0 && this.state.colorPlatte.map((mainColorPlatte, mainColorPlatteIndex: number) =>
                                      <div key={mainColorPlatteIndex}>
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "15px"
                                        }}>
                                          <Radio value={mainColorPlatteIndex}
                                            color="primary"
                                            disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                          />
                                          <TextField
                                            name="nameColorPalatte"
                                            type="text"
                                            size="small"
                                            InputProps={{ classes: { root: classes.inputRoot } }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.labelRoot,
                                                focused: classes.labelFocused
                                              }
                                            }}
                                            style={{ marginTop: "0px" }}
                                            value={mainColorPlatte.name}
                                            disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                            fullWidth
                                            label={t(
                                              "CompanyProfile.nameOfColorPalatte"
                                            )}
                                            className={classes.confirmInput}
                                            variant="outlined"
                                            // helperText={
                                            //   touched.nameColorPalatte &&
                                            //     errors.nameColorPalatte
                                            //     ? (
                                            //       <Trans>
                                            //         CompanyProfile.nameOfColorPalatteRequired
                                            //       </Trans>
                                            //     )
                                            //     : ""
                                            // }
                                            // error={
                                            //   touched.nameColorPalatte &&
                                            //     errors.nameColorPalatte
                                            //     ? true
                                            //     : false
                                            // }
                                            onChange={(e) => this.handleChangeColorName(e, mainColorPlatteIndex)}
                                            onBlur={handleBlur}
                                          />
                                          <span 
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { this.deleteColorPallet(mainColorPlatteIndex) }}
                                          >
                                            <DeleteForeverIcon />
                                          </span>
                                        </div>

                                        <div style={{ marginTop: "40px", paddingLeft: "34px" }}>
                                          <p style={{ fontSize: "20px" }}>{t("CompanyProfile.colors")}</p>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              color: "rgba(0, 0, 0, 0.6)",

                                            }}
                                          >
                                            {t("CompanyProfile.addPrimaryColor")}
                                          </p>
                                          <div>
                                            <Grid container spacing={1}>


                                              <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "center" }}>
                                                {this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ?
                                                  <Box
                                                    p={2}
                                                    style={{
                                                      backgroundColor: "#efefef",
                                                      paddingLeft: "34px"

                                                    }}
                                                    onClick={() => this.triggerColorPicker(mainColorPlatteIndex)}
                                                  >
                                                    {mainColorPlatte?.primaryColor}
                                                  </Box>
                                                  :
                                                  <Box
                                                    p={2}
                                                    style={{
                                                      backgroundColor: "#efefef",
                                                      paddingLeft: "34px"

                                                    }}
                                                   onClick={() => this.triggerColorPicker(mainColorPlatteIndex)}
                                                  >
                                                    {mainColorPlatte?.primaryColor}
                                                  </Box>


                                                }

                                                <Box
                                                  p={2}
                                                  style={{
                                                    backgroundColor: mainColorPlatte?.primaryColor,
                                                    paddingLeft: "34px",
                                                    width: "25%",
                                                    marginLeft: "16px",
                                                    height: "8px",

                                                  }}

                                                >

                                                </Box>

                                              </Grid>

                                              {/* {mainColorPlatte.primaryColor?.length < 5 && <Button variant="contained" className={classes.button2} style={{
                                              alignContent: "center", backgroundColor: 'white',
                                              color: '#419bf9',
                                              //  width: "280px",
                                              padding: 20, fontSize: 16, fontWeight: 'bold',
                                              borderWidth: 1,
                                              borderColor: '#419bf9',
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              // marginBottom: "20px",
                                              height: "44px",
                                              marginLeft: "12px",
                                              marginTop: "7px"
                                              // marginTop: "90px"
                                            }} color="primary"
                                              onClick={(e) => this.incrementPrimaryColor(mainColorPlatteIndex)}
                                            > +
                                        </Button>} */}

                                            </Grid>
                                          </div>

                                        </div>
                                        <input
                                          style={{
                                             display: "none",
                                            opacity: 0
                                          }}
                                          ref={this.primaryColorPicker}
                                          type="color"
                                          value ={this.state.colorPlatte[this.state.colorPlatteIndex].primaryColor ? this.state.colorPlatte[this.state.colorPlatteIndex].primaryColor : defaultPrimaryColor}
                                          // defaultValue={this.state.colorPlatte[this.state.colorPlatteIndex].primaryColor ? this.state.colorPlatte[this.state.colorPlatteIndex].primaryColor : defaultPrimaryColor}
                                          onChange={this.primaryColorChange}
                                        />
                                        <div style={{ paddingLeft: "34px" }}>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              color: "rgba(0, 0, 0, 0.6)",
                                            }}
                                          >
                                            {t("CompanyProfile.addSecondaryColor")}
                                          </p>
                                        </div>
                                        <Grid container spacing={1} style={{ paddingLeft: "34px" }}>

                                          <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "center" }}>
                                            {this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ?
                                              <Box
                                                p={2}
                                                style={{
                                                  backgroundColor: "#efefef",
                                                  paddingLeft: "34px"
                                                }}
                                                onClick={() => this.triggerSecondaryColorPicker(mainColorPlatteIndex)}
                                              >
                                                {mainColorPlatte?.secondaryColor}
                                              </Box> :
                                              <Box
                                                p={2}
                                                style={{
                                                  backgroundColor: "#efefef",
                                                  paddingLeft: "34px"
                                                }}
                                              onClick={() => this.triggerSecondaryColorPicker(mainColorPlatteIndex)}
                                              >
                                                {mainColorPlatte?.secondaryColor}
                                              </Box>
                                            }

                                            <Box
                                              p={2}
                                              style={{
                                                backgroundColor: mainColorPlatte?.secondaryColor,
                                                paddingLeft: "34px",
                                                width: "25%",
                                                marginLeft: "16px",
                                                height: "8px",

                                              }}

                                            >

                                            </Box>
                                          </Grid>

                                          {/* {mainColorPlatte.secondaryColor?.length < 5 && <Button variant="contained" className={classes.button2} style={{
                                          alignContent: "center", backgroundColor: 'white',
                                          color: '#419bf9',
                                          //  width: "280px",
                                          padding: 20, fontSize: 16, fontWeight: 'bold',
                                          borderWidth: 1,
                                          borderColor: '#419bf9',
                                          borderRadius: 10,
                                          borderStyle: 'dashed',
                                          fontVariant: "initial",
                                          textTransform: 'none',
                                          // marginBottom: "20px",
                                          height: "44px",
                                          marginLeft: "12px",
                                          marginTop: "7px"
                                          // marginTop: "90px"
                                        }} color="primary"
                                          onClick={() => this.incrementSecondaryColor(mainColorPlatteIndex)}
                                        > +
                                        </Button>} */}

                                        </Grid>

                                        <div style={{
                                          // marginLeft: "50%",
                                          marginTop: "17px",

                                        }}>
                                          {/* <div style={{height:"40px",width:"50px",borderRadius:"8px",backgroundColor:"",border:"2px dotted #1a76b0"}}  >

     </div> */}

                                          {/* <InputColor
                                      initialValue={this.state.ThemeInfo?.attributes?.selected_secondary_colour ? this.state.ThemeInfo.attributes.selected_secondary_colour : "#1a76b0"}
                                      // onChange={this.state.color}
                                      // color={ this.state.color }
                                      onChange={this.colorChangeSeondary}
                                      placement="right"

                                    /> */}
                                          {/* <p style={{ color: secondaryColorToBeChanged }}>Choose color to change secondary colors</p> */}
                                          {/* <div style={{ marginLeft: "170px" }}>
                                      <InputColor
                                        initialValue={this.state.ThemeInfo?.attributes?.selected_secondary_colour ? this.state.ThemeInfo.attributes.selected_secondary_colour : "#1a76b0"}
                                        // onChange={this.state.color}
                                        // color={ this.state.color }
                                        // style={{marginLeft: "174px"}}
                                        onChange={this.colorChangeSeondary}
                                        placement="right"

                                      />
                                    </div> */}
                                          <input
                                            // style={{display: "none"}}
                                            style={{
                                               display: "none",
                                              opacity: 0
                                            }}
                                            ref={this.secondaryColorPicker}
                                            type="color"
                                            
                                            value ={this.state.colorPlatte[this.state.colorPlatteIndex].secondaryColor ? this.state.colorPlatte[this.state.colorPlatteIndex].secondaryColor : defaultSecondaryColor}
                                            // defaultValue={this.state.colorPlatte[this.state.colorPlatteIndex].secondaryColor ? this.state.colorPlatte[this.state.colorPlatteIndex].secondaryColor : defaultSecondaryColor}
                                            onChange={this.secondaryColorChange}
                                          />



                                        </div>
                                      </div>)


                                    }
                                  </RadioGroup>
                                  {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager" && this.state.colorPlatte.length < 5) ?
                                    <Button variant="contained" className={classes.button2} style={{
                                      alignContent: "center", backgroundColor: 'white',
                                      color: colorToBeChanged,
                                      //  width: "280px",
                                      padding: 20, fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: colorToBeChanged,
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      // marginBottom: "20px",
                                      // height: "44px",
                                      // marginLeft: "12px",
                                      marginTop: "7px",
                                      // marginTop: "90px"
                                      width: "100%"

                                    }} color="primary"
                                      onClick={this.incrementColorPallete}
                                    > + {t("CompanyProfile.addColorPalette")}
                                    </Button> : null
                                  }


                                  {/* =============================================================== */}
                                  <div style={{
                                    marginTop: "40px",
                                    borderTop: "2px solid #efefef"
                                  }}>
                                    <p style={{ fontSize: "20px" }}> {t("CompanyProfile.fonts")}</p>
                                  </div>

                                  <TextField
                                    name="primaryFont"
                                    type="text"
                                    size="small"
                                    select
                                    SelectProps={{
                                      native: true,
                                    }}
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}

                                    value={values.primaryFont}
                                    fullWidth
                                    label={t("CompanyProfile.primaryFonts")}
                                    className={classes.confirmInput}
                                    variant="outlined"
                                    disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                    helperText={
                                      touched.primaryFont && errors.primaryFont
                                        ? (
                                          <Trans>
                                            CompanyProfile.primaryFontsRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.primaryFont && errors.primaryFont
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleChange(e)
                                      this.onChangeFont(e)

                                    }}
                                    onBlur={handleBlur}
                                  >
                                    <option aria-label="None" />
                                    <option value="Arial">Arial</option>
                                    <option value="Times New Roman">Times New Roman</option>
                                    <option value="Times">Times</option>
                                    <option value="Courier New">Courier New</option>
                                    <option value="Courier">Courier</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Verdana">Verdana</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Palatino">Palatino</option>
                                    <option value="Garamond">Garamond</option>
                                    <option value="Bookman">Bookman</option>
                                    <option value="Tahoma">Tahoma</option>
                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                    <option value="Arial Black">Arial Black</option>
                                    <option value="Impact">Impact</option>
                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                    {this.state.optionFontFiles?.length > 0 && this.state.optionFontFiles.map((font: any, index: any) => {
                                      return (<option key={index} value={font?.split(".")[0]}>{font?.split(".")[0]}</option>)
                                    })
                                    }
                                  </TextField>

                                  <TextField
                                    name="secondaryFont"
                                    type="text"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                      }
                                    }}
                                    size="small"
                                    value={values.secondaryFont}
                                    fullWidth
                                    label={t("CompanyProfile.secondaryFonts")}
                                    className={classes.confirmInput}
                                    variant="outlined"
                                    disabled={this.state.userInfo?.role?.data?.attributes?.permission == "brand_manager" ? false : true}
                                    select
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText={
                                      touched.secondaryFont &&
                                        errors.secondaryFont
                                        ? (
                                          <Trans>
                                            CompanyProfile.secondaryRequired
                                          </Trans>
                                        )
                                        : ""
                                    }
                                    error={
                                      touched.secondaryFont &&
                                        errors.secondaryFont
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleChange(e)
                                      this.onChangeFont(e)

                                    }}
                                    onBlur={handleBlur}
                                  >
                                    <option aria-label="None" />
                                    <option value="Arial">Arial</option>
                                    <option value="Times New Roman">Times New Roman</option>
                                    <option value="Times">Times</option>
                                    <option value="Courier New">Courier New</option>
                                    <option value="Courier">Courier</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Verdana">Verdana</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Palatino">Palatino</option>
                                    <option value="Garamond">Garamond</option>
                                    <option value="Bookman">Bookman</option>
                                    <option value="Tahoma">Tahoma</option>
                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                    <option value="Arial Black">Arial Black</option>
                                    <option value="Impact">Impact</option>
                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                    {this.state.optionFontFiles?.length > 0 && this.state.optionFontFiles.map((font: any, index: any) => {
                                      return (<option key={index} value={font?.split(".")[0]}>{font?.split(".")[0]}</option>)
                                    })
                                    }
                                  </TextField>

                                  {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                    <>

                                      <div style={{ marginTop: "40px" }}>
                                        <div className={classes.fileNameDisplay}>
                                          {this.state.files.map((item, index) => {
                                            if (item != null && item != '')
                                              return <div key={index}> 
                                                <div className={classes.one} style={{textAlign:"right"}}><AttachFileIcon fontSize="small" style={{ transform: "rotate(45deg)", marginRight: "14px" }} /></div>
                                                <div className={classes.two}><p style={{marginTop:"-2px"}} >{item}</p></div>
                                                <div className={classes.three}><CloseIcon className="api_closebtn" onClick={()=> this.deleteFontFile(item, index)} /></div>
                                                </div>
                                          })}
                                        </div>
                                        {/* {this.printName(this.state.files)} */}
                                        {/* {this.state.files.map(item=>{
   return<p style={{textAlign:'center'}}>{item.name}</p>
 })} */}
                                        {/* </div> */}


                                      </div>
                                      {this.state.files.length < 2 ?
                                        <>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.isTrue}
                                                onChange={this.checkBoxChange}
                                                color="primary"
                                              />
                                            }
                                            label={t("CompanyProfile.agreeCheck")}
                                          />
                                          <div>
                                            {/* <DropzoneArea
                                        dropzoneText={t("CompanyProfile.uploadCustomFonts")}
                                        dropzoneClass={classes.dropZone}
                                        onChange={this.fileChange}
                                        showAlerts = {false}
                                        onClick={()=>this.fileInput.click()}
                                      /> */}
                                            <Button variant="contained" className={classes.button2} style={{
                                              alignContent: "center",
                                              backgroundColor: "white" ,
                                              color: this.state.isTrue ? colorToBeChanged : "#919191",
                                              //  width: "280px",
                                              padding: 20, fontSize: 16, fontWeight: 'bold',
                                              borderWidth: 1,
                                              borderColor: this.state.isTrue ? colorToBeChanged : "#919191",
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              // marginBottom: "20px",
                                              // height: "44px",
                                              // marginLeft: "12px",
                                              marginTop: "7px",
                                              // marginTop: "90px"
                                              width: "100%"

                                            }} color="primary"
                                            disabled = {!this.state.isTrue}
                                              // onClick={this.incrementColorPallete}
                                              onClick={() => this.fileInput.click()}
                                            >
                                              <ReactS3Uploader
                                                type="file"
                                                id="upload"
                                                name="pic"
                                                // className={uploaderClassName}
                                                getSignedUrl={this.getSignedUrl}
                                                //accept="**"
                                                // onProgress={onProgress}
                                                // onError={onError}
                                                // onFinish={onFinish}
                                                uploadRequestHeaders={{
                                                  'x-amz-acl': 'public-read'
                                                }}
                                                s3path="/uploads/"
                                                contentDisposition="auto"
                                                accept=" .otf, .ttf,.fnt"
                                                capture={true}
                                                // ref={this.fileInput}
                                                inputRef={cmp => this.fileInput = cmp}
                                                //  onChange={(event: any) =>
                                                //    this.inputFileChanged(event)
                                                //  }
                                                style={{
                                                  visibility: "hidden",
                                                  position: "absolute",
                                                  color: colorToBeChanged,
                                                }}
                                              />
                                             + {t("CompanyProfile.uploadCustomFonts")}
                                            </Button>

                                          </div>
                                        </> : null}


                                    </> : null
                                  }



                                  {(this.state.userInfo?.role?.data?.attributes?.permission === "brand_manager") ?
                                    <Button
                                      className={classes.button}
                                      style={{ backgroundColor: colorToBeChanged }}
                                      type="submit"
                                      variant="contained"

                                    >
                                      {t("CompanyProfile.saveChanges")}
                                    </Button>
                                    :
                                    null

                                  }
                                </form>
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>

              <Grid xs={2} />
            </Grid>
            <img src={RightViewBg.default} style={this.state.value !== 2 ? {
              maxWidth: "298px",
              height: "auto",
              margin: 0,
              position: "absolute",
              bottom: 0,
              right: 0
            } :
              {
                maxWidth: "298px",
                height: "auto",
                margin: 0,
                position: "absolute",
                bottom: "0",
                right: 0
              }
            }
            />
          </Grid>
        </Grid >
        <Dialog
          open={this.state.setOpen}
          onClose={() => this.setState({ setOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Capture Photo</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Camera
                onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleCloseModal} color="primary">
            Disagree
          </Button> */}
            <Button onClick={() => this.setState({ setOpen: false })} color="primary" autoFocus>
              cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}
// Customizable Area End

// Customizable Area Start

export default withStyles(styles)(withTranslation()(CompanyProfile));
