import React from "react";
import Button from "@material-ui/core/Button";
import { signUpBackgroundImage } from "./assets";
import { imgPasswordVisible } from "./assets";
import { imgPasswordInVisible } from "./assets";
import { harvestLogoImage, harvestLogo2xImage, harvestLogo3xImage, ukLogo, germanLogo } from './assets'

// Customizable Area Start
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'
import Visibility from "@material-ui/icons/Visibility"

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CustomCheckBox from "../../../components/src/CustomCheckBox";
// Customizable Area End
import { Link } from "@material-ui/core";

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { withFormik } from "formik";
import Yup from "yup";
import { withTranslation, Trans } from "react-i18next";
import { Tooltip } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined'


const styles = {
  root: {
    backgroundColor: "red",
    overflow: "hidden",
  },
  inputPostion: {
    margin: "0",
    position: "absolute",
    top: "30%",
    left: "50%",
    transorm: "translate(-50%, -50%)",
  },
  signUpLogo: {
    height: "100%",
    width: "25%",
    position: "fixed",
    top: "0",
    bottom: "0",
  },
  labelRoot: {
    fontSize: 15,
    color: "#282828",
    "&$labelFocused": {
      color: "#282828"
    }
  },
  button: {
    // backgroundColor: "rgb(23, 62, 89)",
    // backgroundColor:`${colorToBeChanged}`,
    color: "white",
  },
  PageHeader: {
    // fontFamily: "inter",
    color: "#173e59",
    fontSize: "24px",
    marginBottom: "20px",
  },
  emailError: {
    fontSize: "10px",
    color: "red",
  },
  inputHeight: {
    height: "auto",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  signUp: {
    textAlign: "end",
  },
  signUpLink: {
    color: "#173e59",
    cursor: "pointer",
  },
  textError: {
    border: "1px solid red",
  },
  passwordVisible: {
    height: "25px",
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
  inputRoot: {
    // fontSize: 56
    // height: 56
  },
  image_logo: {
    width: "174px",
    height: "91px",
    objectFit: "contain"
  },
  logo: {
    position: "relative",
    zIndex: 1,
    padding: "55px 30px"
  }
} as any;
var langSelected = localStorage.getItem("i18nextLng");
class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleLanguage(lang: string) {
    // console.log(this.props.i18n, lang)
    langSelected = lang
    this.props.i18n.changeLanguage(lang);
    this.langaugeChangeHandler(this.props.i18n.language)
     console.log("lang", this.props.i18n)
  }

  render() {
    const { t, classes } = this.props;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#173e59";
    }
    console.log("ocalStorage.getItemi18nextLng",localStorage.getItem("i18nextLng"));

    return (
      <div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "10px",
            zIndex: 10000,
          }}
        >
          <Button
            onClick={() => this.handleLanguage("en")}
            style={{ color:localStorage.getItem("i18nextLng") == 'en' ? 'white' : "black", backgroundColor: localStorage.getItem("i18nextLng") == 'en' ? '#173e59' : 'transparent' }}
            startIcon={<img src={ukLogo} style={{width: '18px', height: '18px'}}/>}
          >
            English 
          </Button>
          <Button
            onClick={() => this.handleLanguage("de")}
            style={{ color: localStorage.getItem("i18nextLng") == 'de' ? 'white' : "black" , backgroundColor: localStorage.getItem("i18nextLng") == 'de' ? '#173e59' : 'transparent' }}
            startIcon={<img src={germanLogo} style={{width: '18px', height: '18px'}}/>}
          >
            German
          </Button>
        </div>

      <Grid container>

        <Grid item xs={12} sm={6}>
          <img src={signUpBackgroundImage.default} className={classes.signUpLogo} />
          <div className={classes.logo}>
            <img src={harvestLogoImage}
              className={classes.image_logo}
            />
          </div>
        </Grid>
        {/* <Button variant="outlined" color="primary" onClick={(e)=>this.handleToggle(e)}>
        Show backdrop
      </Button> */}
        <Backdrop
          className={classes.backdrop}
          open={this.state.open}
          onClick={(e) => this.handleCloseLoader()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={6}>
          <form className={classes.inputPostion}>
            <div>
              <p
                className={classes.PageHeader}
                style={{ color: colorToBeChanged }}
              >
                {t("EmailAccountLoginController.signIn")}
              </p>
            </div>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.inputHeight}>
                  {!this.state.emailError && !this.state.emailErrorStatus ? (
                    <TextField
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      label={t("EmailAccountLoginController.email")}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}
                      id="outlined-error-helper-text"
                      name="email"
                      //size="small"
                      variant="outlined"
                      onChange={(e) => this.txtInputEmail(e)}
                    />
                  ) : (
                    <TextField
                      error
                      fullWidth
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      label={t("EmailAccountLoginController.email")}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}
                      id="outlined-error-helper-text"
                      helperText={
                        this.state.emailError ? (
                          <Trans>
                            EmailAccountLoginController.emailRequired
                          </Trans>
                        ) : this.state.emailErrorStatus ? (
                          <Trans>
                            EmailAccountLoginController.emailNotMatch
                          </Trans>
                        ) : null
                      }
                      name="email"
                      //size="small"
                      variant="outlined"
                      onChange={(e) => this.txtInputEmail(e)}
                    />
                  )}
                  {/* {this.emailError && <p className={classes.emailError}>Email is required</p>} */}
                </Grid>
                <Grid item xs={12} className={classes.inputHeight}>
                  {this.state.passwordError ? (
                    <TextField
                      fullWidth

                      error

                      label={t("EmailAccountLoginController.password")}
                      name="password"
                      //size="small"
                      type={this.state.showPassword ? "text" : "password"}
                      variant="outlined"

                      InputProps={{
                        endAdornment: this.state.showPassword ? (
                          // <img
                          //   src={imgPasswordInVisible}
                          //   onClick={(e) => this.passwordVisibility(e)}
                          // />
                          <Visibility fontSize="small" onClick={(e) => this.passwordVisibility(e)} style={{ color: "#9e9e9e" }} />
                        ) : (
                          // <button
                          //   src={imgPasswordInVisible}
                          //   onClick={(e) => this.passwordVisibility(e)}
                          // />
                          // <IconButton onClick={(e) => this.passwordVisibility(e)}>
                          <VisibilityOffOutlined fontSize="small" onClick={(e) => this.passwordVisibility(e)} style={{ color: "#9e9e9e" }} />

                        ),
                        classes: { root: classes.inputRoot }

                      }}
                      id="outlined-error-helper-text"
                      helperText={
                        this.state.passwordError && (
                          <Trans>
                            EmailAccountLoginController.passwordIsRequired
                          </Trans>
                        )
                      }
                      onChange={(e) => this.txtInputEmail(e)}
                    />

                  ) : (
                    <TextField
                      fullWidth
                      label={t("EmailAccountLoginController.password")}
                      name="password"
                      //size="small"
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused
                        }
                      }}
                      type={this.state.showPassword ? "text" : "password"}
                      variant="outlined"

                      InputProps={{
                        endAdornment: this.state.showPassword ? (
                          // <img
                          //   src={imgPasswordVisible}
                          //   className={classes.passwordVisible}
                          //   onClick={(e) => this.passwordVisibility(e)}
                          // />

                          <Visibility fontSize="small" onClick={(e) => this.passwordVisibility(e)} style={{ color: "#9e9e9e" }} />
                        ) : (
                          // <img
                          //   src={imgPasswordInVisible}
                          //   className={classes.passwordVisible}
                          //   onClick={(e) => this.passwordVisibility(e)}
                          // />
                          // <IconButton onClick={(e) => this.passwordVisibility(e)}>
                          <VisibilityOffOutlined fontSize="small" onClick={(e) => this.passwordVisibility(e)} style={{ color: "#9e9e9e" }} />

                        ),
                        classes: { root: classes.inputRoot }
                      }}
                      id="outlined-error-helper-text"
                      helperText={
                        this.state.passwordError && (
                          <Trans>
                            EmailAccountLoginController.passwordIsRequired
                          </Trans>
                        )
                      }
                      onChange={(e) => this.txtInputEmail(e)}
                    />   


                  )}
                  
                  {/* {this.passwordError && <p className={classes.emailError}>Password is required</p>} */}
                </Grid>

              </Grid>

              {/* <ErrorOutline /> */}
              <p>
                {" "}
                <Link
                  className={classes.forgotPassword}
                  style={{ color: colorToBeChanged }}
                  onClick={this.goToForgotPassword}
                >
                  {t("EmailAccountLoginController.forgetPassword")}
                </Link>
              </p>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Button
                className={classes.button}
                style={{ backgroundColor: colorToBeChanged }}
                fullWidth
                type="button"
                variant="contained"
                onClick={(e) => this.doEmailLogIn(e)}
              >

                {!this.state.loader ? <>{t("EmailAccountLoginController.signInButton")}</> : <img src="https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/giphy.gif" style={{ height: "15px", width: "15px" }} alt="loading" />}
              </Button>

              <p className={classes.signUp}>
                {t("EmailAccountLoginController.donNotHaveAccount")}
                <Link
                  className={classes.signUpLink}
                  style={{ color: colorToBeChanged }}
                  onClick={this.goToEmailAccountRegistration}
                >
                  {t("EmailAccountLoginController.signUp")}
                </Link>
              </p>
              {/* <p className={classes.signUp}>
                activate Your account here
                <Button
                  className={classes.signUpLink}
                  style={{ color: colorToBeChanged }}
                  onClick={this.resendMail}
                >
                  Activate
                </Button>
              </p> */}
            </Grid>

          </form>
        </Grid>
      </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(EmailAccountLoginBlock));

{
  /* <form className={classes.inputPostion}>
<Grid container spacing={3}>

<Grid item xs={12} sm={6}>
  <Paper className={classes.paper}>xs=12 sm=6</Paper>
</Grid>
<Grid item xs={12} sm={6}>
  <Paper className={classes.paper}>xs=12 sm=6</Paper>
</Grid>
  <Grid item xs={12}>
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <TextField fullWidth label="Email" name="email" size="small" variant="outlined" onChange={(e) => this.txtInputEmail(e)}/>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Password"
          name="password"
          size="small"
          type="password"
          variant="outlined"
          onChange={(e) => this.txtInputEmail(e)}
        />
      </Grid>
    </Grid>
  </Grid>
  <Grid item xs={12}>
    <Button color="secondary" fullWidth type="submit" variant="contained" onClick={e=>this.doEmailLogIn(e)}>
      Log in
    </Button>
  </Grid>
</Grid>
</form> */
}
