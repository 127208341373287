
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormatListNumberedRtlOutlined } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInterval } from "timers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  projectFilterValue: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  grid: boolean;
  list: boolean;
  anchorEl: any;
  anchorE2: any;
  setOpen2: any;
  sharedProjectError: any;
  sharedProject: any;
  percentage: any,
  quotePrice: any;
  isEditing: any;
  next_page: boolean;
  total_pages: number;
  brandsData: any;
  brandUpdatedCompleteDate: any;
  setPage: number;
  brandDeleteId: number;
  selectedBrand: any;
  selectedBrandId: any;
  selectData: any;
  isSearch: boolean;
  tableBrandData: any;
  anchorElFilter: any;
  searchBrandName: string;
  brandFilterValue: string;
  setOpen: boolean;
  accountsData: any;
  permissions: any;
  permissionName: any;
  selectedBrandForProjectCreate: any;
  selectedUsersForProjectCreate: any;
  projectDataFromDropDown: any;
  setOpen3: any,
  projectArchieveId: any,
  projectsOfStatusDraftData: any;
  loading: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectsTypesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End
  projectIndexApiCallIdType121:any;
  getBrandListingId: any;
  apiCallToBrandDeleteApi: any;
  getProjectByBrandId: any;
  getBrandListingByFiltersId: any;
  projectCreateApiCallId: any;
  callGetAccountListApiId: any;



  callGetProjectsAchiveListApiId: any;
  activeProjectID: any;
  projectArchiveApi: any;
  archiveProjectID: any;
  deleteProjectID: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      projectsOfStatusDraftData: [],
      projectDataFromDropDown: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      grid: true,
      list: false,
      projectFilterValue: null,
      anchorEl: null,
      anchorE2: null,
      setOpen2: false,
      sharedProjectError: false,
      sharedProject: "",
      percentage: 74,
      quotePrice: 64,
      isEditing: false,
      next_page: false,
      total_pages: 1,
      brandsData: [],
      brandUpdatedCompleteDate: [],
      setPage: 1,
      brandDeleteId: 1,
      selectedBrand: "",
      selectedBrandId: "",
      selectData: {},
      isSearch: false,
      tableBrandData: [],
      anchorElFilter: null,
      searchBrandName: "",
      brandFilterValue: "",
      setOpen: false,
      accountsData: {},
      permissions: '',
      permissionName: "",
      selectedBrandForProjectCreate: "",
      selectedUsersForProjectCreate: "",
      setOpen3: null,
      projectArchieveId: null,
      loading: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  handleInputChange = (e?: any) => {

    this.setState({
      quotePrice: e.target.value,

    })
  }
  handleClickAway = () => {
    console.log("clickaway")
    this.setState({
      isEditing: false
    })
  }
  handleFilterChange2 = (event: any) => {
    var order = event.target.value.slice(-1)
    var item = event.target.value.substr(0, event.target.value.length - 1)
    this.setState({
      isSearch: true,
      projectFilterValue: event.target.value,
      anchorElFilter:null
      // , isSearch: true
    })
    let path = "" as string;
    if (order == "0") {
      path = `sort_by=${item}&sort_order=asc`
    }
    else if (order == "1") {
      path = `sort_by=${item}&sort_order=desc`
    }
    if (localStorage.getItem("projectType") == "Archive Projects") {
      path += '&is_archived=true';
    } else if (localStorage.getItem("projectType") == "Deleted Projects") {
      path += '&deleted_at=true';
    } 
    this.callGetProjectsListApiType121(path)
  }
  callGetProjectsListApiType121(path: any) {
    const userDetails = localStorage.getItem("userInfo")
    var brand_id = localStorage.getItem("selectedBrandID");
    if (userDetails && brand_id === null) {
      brand_id = JSON.parse(userDetails).brand_id;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectIndexApiCallIdType121 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAPiEndPoint + `?page=${this.state.setPage}&per_page=${100}&brand_id=${brand_id}` + `&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }

  handleClickMenu3 = (event: any, projectId: any) => {
    this.setState({
      setOpen3: event.currentTarget,
      projectArchieveId: projectId
    });
    //console.log(this.state.taskActivityData);

  };
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson === undefined || !responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            console.log("responseJsonforbrands", responseJson)
            this.setState({ brandsData: responseJson.brands?.data })
            this.setState({ next_page: responseJson.next_page })
            this.setState({ total_pages: responseJson.total_pages })
            console.log("Responses of brands", this.state.brandsData);
            let DMYString = ""
            let brandUpdatedDate: any = [];
            this.state.brandsData?.map((items: any) => {
              let modifiedDate: any = items.attributes.updated_at;
              console.log("Data Date0", modifiedDate)

              var part: any = modifiedDate.split(" ");
              console.log("Data Date1", part)

              // let dataDate: any = new Date(modifiedDate)
              // console.log("Data Date",dataDate)
              // let dd = dataDate.getDate();
              // let mm = dataDate.getMonth();
              // let yy = dataDate.getFullYear();
              // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              DMYString = part[0] + " " + part[1] + " " + part[2];
              brandUpdatedDate.push({ id: items.id, DMY: DMYString })
            })
            this.setState({ brandUpdatedCompleteDate: brandUpdatedDate })
            console.log("Dateee", this.state.brandUpdatedCompleteDate)
            //search live
            //recent search
          }
        }
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectIndexApiCallIdType121) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          console.clear();
          console.log(responseJson)
          this.setState({ tableBrandData: responseJson.projects.data })
        } else {
          console.log("else")
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectsAchiveListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log(responseJson);
            this.setState({ projectDataFromDropDown: responseJson?.projects?.data })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectArchiveApi) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project unarchived successfully");
            if (localStorage.getItem("projectType") == "Active Projects") {
              this.activeProjects();
            } else if (localStorage.getItem("projectType") == "Archive Projects") {
              this.archiveProject();
            } else if (localStorage.getItem("projectType") == "Deleted Projects") {
              this.deleteProject();
            }
          }
        }
      } else if (responseJson.errors) {
        if (responseJson?.errors?.[0]?.brand) {
          toast.error(responseJson?.errors?.[0]?.brand);
        } else {
          toast.error(errorResponse.errors[0].projects);
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToBrandDeleteApi) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson === undefined || !responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list
            toast.success("Brand is deleted Sucessfully");
            //search live
            //recent search
          }
        }
      }
      else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectCreateApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //  this.callGetProjectsListApiType1();
            //this.callGetProjectsListApiTypeForAB();
            toast.success("Project created successfully");
            this.getBrandListing();
          }
        }
      } else if (responseJson.errors) {
        if (errorResponse.errors[0].name) {
          toast.error(`Project` + " " + errorResponse.errors[0].name);
        } else {
          toast.error(errorResponse.errors[0].account);
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              accountsData: responseJson.accounts.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProjectByBrandId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          console.log("responseJsoninbrands", responseJson)
          localStorage.setItem("projectsDataSelected", JSON.stringify(responseJson?.projects?.data))
          this.setData(this.state.selectData);

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFiltersId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          console.clear();
          console.log(responseJson)
          this.setState({ tableBrandData: responseJson.projects.data })


        } else {
          console.log("else")
        }

      }
    } if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.activeProjectID) {
   
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({
            loading: false,
            projectDataFromDropDown: responseJson?.projects?.data,
          })
        }
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.archiveProjectID) {
    
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({
            loading: false,
            projectDataFromDropDown: responseJson?.projects?.data
          })
        }
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteProjectID) {
    
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({
            loading: false,
            projectDataFromDropDown: responseJson?.projects?.data
          })
        }
      }
    }
    // Customizable Area Start
    // Customizable Area End


    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }

    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  progressPercentage = (e?: any, value?: any) => {
    console.log(e, value, "value")
    this.setState({
      percentage: value
    })
  }
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false
    })
    this.getBrandListing();
  }
  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  unArchiveProject = () => {
    this.setState({ setOpen3: null })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      "is_archived": false
    };
    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectArchiveApi = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects` + `/${this.state.projectArchieveId}`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);


  }
  setData(selectedBrand?: any) {

    console.log("selectedBrand", selectedBrand);
    this.setState({
      selectedBrandId: selectedBrand.id,
      selectedBrand: selectedBrand,
    });
    localStorage.setItem("selectedBrandID", selectedBrand.id)
    localStorage.setItem("selectedBrand", selectedBrand.id);
    localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0].id : null);
    localStorage.setItem("selectedProject", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0].id : null);
    localStorage.setItem("selectedProjectName", selectedBrand.attributes.projects.length > 0 ? selectedBrand.attributes.projects[0]?.name : null);

    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    //this.props.history.push("/Home/Projects")
    //navigator.navigate("/Home/Projectportfolio")
    if (selectedBrand.attributes.projects.length === 0) {
      toast("Please create a project")
    } else {
      window.location.href = '/Home/Projects'

    }

  }

  setData2(selectedBrand?: any) {

    //console.log("selectedBrand",selectedBrand);
    //   this.setState({
    //    selectedBrandId: selectedBrand.id,
    //    selectedBrand: selectedBrand,
    //  });
    localStorage.setItem("selectedBrandID", selectedBrand?.attributes?.brand_id)
    localStorage.setItem("selectedBrand", selectedBrand?.attributes?.brand_id);
    // localStorage.setItem("BrandNameIcon", selectedBrand?.attributes?.name.match(/\b(\w)/g).join(''))
    localStorage.setItem("selectedBrandName", selectedBrand?.attributes?.brand?.data?.attributes?.name);
    localStorage.setItem("selectedBrandImage", selectedBrand?.attributes?.brand?.data?.attributes?.logo_blob_signed_id_url);
    localStorage.setItem("selectedProjectId", selectedBrand?.id);
    localStorage.setItem("selectedProject", selectedBrand?.id);
    localStorage.setItem("selectedProjectName", selectedBrand.attributes?.name);


    // window.location.href = '/Home/Projectportfolio'
    const sideNavData = {
      sideNavData:
        []
    }
    let da = { sideNavData: sideNavData, key: "projects" }
    localStorage.setItem("navData", JSON.stringify(da))
    //this.props.history.push("/Home/Projects")
    //navigator.navigate("/Home/Projectportfolio")

    window.location.href = '/Home/Projectportfolio'



  }



  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true
    })
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false
    })
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null
    })
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  }
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true
    })
  }
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };
  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ setPage: value }, () => { this.getBrandListing() });
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  getBrandListing = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandListingAPiEndPoint + `&page=${this.state.setPage}&per_page=${20}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //account  index api
  callGetAccountListApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }


  getProjectByBrandID = () => {
    const header = {
      "Content-Type": configJSON.callToProjectShowByBrandContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectByBrandId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToProjectShowByBrandApiEndPoint + this.state.selectedBrandId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToProjectShowByBrandMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //project create api
  callCreateProjectApi = (values: any, users: any) => {

    this.setState({
      setOpen: false
    })

    const selected = [];
    if (users) {
      for (let i = 0; i < users.length; i++) {
        selected.push(users[i].id)
      }
      console.log("selected usersss id", selected)
    }
    var selectedUsers = [];
    selectedUsers = selected.map((i: any) => Number(i));
    console.log("selected usersss idsss", selectedUsers)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const data = {
      name: values.title,
      description: values.projectDescription,
      account_ids: selectedUsers,
      brand_id: parseInt(brand_id),
    };

    const httpBody = {
      data: data
    };


    const requestCreateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectCreateApiCallId = requestCreateProjectMessage.messageId;
    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectCreateAPiEndPoint
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateProjectMessage.id, requestCreateProjectMessage);



  }

  deleteBrand(id: number) {

    //Call update API
    const header = {
      "Content-Type": configJSON.contentTypeApiDeleteBrand,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToBrandDeleteApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandDeleteApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletionBrandApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  activeProjects = () => {
   
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activeProjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=1&&per_page=100`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  archiveProject = () => {
  
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveProjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=1&per_page=500&is_archived=true&brand_id=${localStorage.getItem("selectedBrand")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteProject = () => {
   
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=1&per_page=100&deleted_at=true&brand_id=${localStorage.getItem("selectedBrand")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    this.loadUserDetailsAndRoles();
    this.setState({ loading: true }, () => {
      if (localStorage.getItem("projectType") == "Active Projects") {
        this.activeProjects();
      } else if (localStorage.getItem("projectType") == "Archive Projects") {
        this.archiveProject();
      } else if (localStorage.getItem("projectType") == "Deleted Projects") {
        this.deleteProject();
      }
    });
    // this.getBrandListing();
    // this.loadUserDetailsAndRoles();
    // this.callGetAccountListApi('');
    // let projectDataFromDropDown = JSON.parse(localStorage.getItem("projectDataFromDropDown") || "");
    // this.setState({ projectDataFromDropDown: projectDataFromDropDown })



    //  this.getBrandListing();
    // Customizable Area Start
    // Customizable Area End
  }


  //projects Achive get api
  callGetProjectsAchiveListApi() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callGetProjectsAchiveListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/projects?" + `page=${1}&per_page=${500}&is_archived=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchChange = (event: any) => {
    console.log(event.target.value)


    if (event.target.value !== "") {
      this.setState({ isSearch: true, searchBrandName: event.target.value })
      if (this.state.brandFilterValue !== "") {
        this.getProjectsByFilter(`name=${event.target.value}&Sort_by=${this.state.brandFilterValue}`)
      } else {
        this.getProjectsByFilter(`name=${event.target.value}`)
      }
    } else {
      this.setState({ isSearch: false, searchBrandName: "" })
    }
  }


  getProjectsByFilter = (path: any) => {
    this.setState({ tableBrandData: [] })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects?page=${this.state.setPage}&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }

  handleSortByName = () => {
    this.setState({ isSearch: true })
    let path = "" as string;
    if (this.state.searchBrandName !== "") {
      path = `name=${this.state.searchBrandName}&Sort_by=name`;
    } else {
      path = `Sort_by=name`;
    }

    this.getProjectsByFilter(path)
  }

  handleFilterChange = (event: any) => {
    console.log(event.target.value)
    this.setState({ brandFilterValue: event.target.value, isSearch: true })
    let path = "" as string;
    if (this.state.searchBrandName !== "") {
      path = `name=${this.state.searchBrandName}&Sort_by=${event.target.value}`;
    } else {
      path = `Sort_by=${event.target.value}`
    }
    this.getProjectsByFilter(path)
  }

  handleCloseLoader = () => {
    this.setState({
      loading: false
    })
  };

  // Customizable Area Start
  // Customizable Area End

}