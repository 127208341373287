import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from 'react-toastify';
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any;
  match?: any;
  // id2List:any
  // id2List:{ droppable: string; droppable2: string; }
  // Customizable Area Start
  // Customizable Area End
}
// export interface id2List{ droppable: string; droppable2: string; };
// const getItems = (count:any) =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));
// const foo =
//     'lorem ipsum dolor sit amet consectutrtpoyu sklhfdlkfh dklfh lkdhflhdflkh dlkfhdlkhflkd fldhflh';
// const getItems = (count:any, offset = 0) =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         id: `item-${k + offset}`,
//         content: `item ${k + offset}${k + offset >= 10 ? foo : ''}`
//     }));

const data = [
  {
    id: "car",
    content: "car",
  },
  {
    id: "elelphant",
    content: "elelphant",
  },
  {
    id: "ball",
    content: "ball",
  },
  {
    id: "cat",
    content: "cat",
  },
  {
    id: "dog",
    content: "dog",
  },
];
let filesArray: any = [];

const itemsData = [
  {
    id: "BMW",
    content: "BMW",
  },
  {
    id: "Volkswagon",
    content: "Volkswagon",
  },
  {
    id: "Mercedes",
    content: "Mercedes",
  },
  {
    id: "Suzuki",
    content: "Suzuki",
  },
  {
    id: "Hundai",
    content: "Hundai",
  },
];

const getItems = (count: any, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));
interface FilmOptionType {
  title: string;
  year: number;
}
interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  items: any;
  // selected: any;
  // approval: any;
  todo: any;
  anchorEl: any;
  grid: boolean;
  list: boolean;
  setOpen: boolean;
  sharedProject: any;
  sharedProjectError: any;
  value: any;
  setOpen1: any;
  setOpen2: any;
  CGI: any;
  anchorE2: any;
  percentage: any;
  openTaskCreateModel: boolean;
  openAddBriefingModel: boolean;
  openAddRequestQuoteModel: boolean;
  category: string;
  assetType: string;
  setNotification: boolean;
  setFolder: boolean;
  setCurrentPage: any;
  pageNumber: any,
  perPage: any;
  assetData: any;
  count: any;
  mediaAssetID: any;
  brandId: any
  accountImages: any;
  files: any;
  noOfFiles: any;
  noOfFolders: any;
  type: any;
  size: any;
  internalFilesData: any,
  selectedBrand: any,
  brandDetail: any,
  selectedFolder: any
  search: any
  anchorElFilter: any
  brandFilterValue: any
  searchBrandName: any
  isSearch: any
  sortOrder: any
  searchedData: any
  openImage: any
  openImageURL : any
  selectedAsset : any
  fileFlag: boolean
  openLink : boolean,
  // setFolder : boolean
  totalPages:number;
  setPage:number;
  loading: boolean;
  permissions: any
  permissionName : any
  deleteMediaDialog : any
  // Customizable Area End
}
const initialBoardData = {
  items: {
    "item-1": { id: "item-1", content: "Content of item 1." },
    "item-2": { id: "item-2", content: "Content of item 2." },
    "item-3": { id: "item-3", content: "Content of item 3." },
    "item-4": { id: "item-4", content: "Content of item 4." },
    "item-5": { id: "item-5", content: "Content of item 5." },
    "item-6": { id: "item-6", content: "Content of item 6." },
    "item-7": { id: "item-7", content: "Content of item 7." },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Column 1",
      itemsIds: [
        "item-1",
        "item-2",
        "item-3",
        "item-4",
        "item-5",
        "item-6",
        "item-7",
      ],
    },
    "column-2": {
      id: "column-2",
      title: "Column 2",
      itemsIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "Column 3",
      itemsIds: [],
    },
    "column-4": {
      id: "column-4",
      title: "Column 4",
      itemsIds: [],
    },
  },
  columnsOrder: ["column-1", "column-2", "column-3", "column-4"],
};
interface SS {
  id: any;
  items: any;
  selected: any;
  todo: any;
}

export default class BrandAssetsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssetCategoryApiCallId: string = ""
  getAssetCategorySearchApiCallId: string = ""
  addFolderAPI: string = ""
  getNotificationSettingsApiCallId: string = ""
  getBrandCallId: string = ""
  getBrandListingByFiltersId: string = ""
  deleteAssetApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),



    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      items: data,
      todo: itemsData,
      sharedProject: "",
      sharedProjectError: false,
      // selected: getItems(5, 10),
      // approval: getItems(5),
      percentage: 74,
      anchorEl: null,
      grid: localStorage.getItem("grid") == '1' ? true : false,
      list: localStorage.getItem("grid") != '1' ? true : false,
      setOpen: false,
      value: 0,
      setOpen1: false,
      setOpen2: false,
      CGI: false,
      anchorE2: null,
      openTaskCreateModel: false,
      openAddBriefingModel: false,
      openAddRequestQuoteModel: false,
      category: "",
      assetType: "",
      setNotification: false,
      setFolder: false,
      setCurrentPage: '',
      pageNumber: 1,
      perPage: 10,
      assetData: [],
      count: '',
      brandId: 1,
      mediaAssetID: 1,
      accountImages: [],
      files: [],
      noOfFiles: '',
      noOfFolders: '',
      type: '',
      size: '',
      internalFilesData: '',
      selectedBrand: null,
      brandDetail: null,
      selectedFolder: null,
      search: "",
      anchorElFilter: null,
      brandFilterValue: 'name0',
      searchBrandName: null,
      isSearch: null,
      sortOrder : "desc",
      searchedData: [],
      openImageURL : null,
      openImage : false,
      selectedAsset : null,
      fileFlag : false,
      openLink : false,
      // setFolder : false,
      totalPages:0,
      setPage:1,
      loading: false,
      permissions: null,
      permissionName : null,
      deleteMediaDialog : null
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  imageView(name: any, url: any) {
    this.setState({ openImageURL: { "name": name, "url": url } }, () => {
      this.setState({ openImage: true })
    })
  }

  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }
  handlePageChange = (e?: any) => {
    this.setState({
      pageNumber: e.target.innerText
    }, () => {
      console.log(this.state.setCurrentPage, "h")
      this.getMediaAssets(this.state.pageNumber)
    })

  }
  handleCloseModal1 = () => {
    this.setState({
      openLink: false,
    });
  };
  getBrandsByFilters = (path?: any) => {
    // this.setState({tableBrandData:[]})
    this.setState({loading : true})
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     path ? `bx_block_categories_sub_categories/media_assets?page=${this.state.setPage}&per_page=${10}` + `&brand_id=${this.state.selectedBrand}&${path}` : 
     `bx_block_categories_sub_categories/media_assets?page=${this.state.setPage}&per_page=${10}` + `&brand_id=${this.state.selectedBrand}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFilterChange = (event: any) => {
    console.log(event.target.value)
    var order = event.target.value.slice(-1)
    var item  = event.target.value.substr(0,event.target.value.length -1)
    this.setState({ brandFilterValue:event.target.value, isSearch: true, anchorElFilter: null, loading: true })
    let path = "" as string;
    if(order == "0"){
      path = `sort_by=${item}&sort_order=asc`
    }
    else if(order == "1"){
      path = `sort_by=${item}&sort_order=desc`
    }
    
    // if (this.state.searchBrandName !== "") {
    //   path = `brand_name=${this.state.searchBrandName}&Sort_by=${event.target.value}`;
    // } else {
    //   path = `Sort_by=${event.target.value}`
    // }
    this.getBrandsByFilters(path)
  }

  async componentDidMount() {
    this.loadUserDetailsAndRoles()
    console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    console.log("props", this.props);

    const assetId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.setState({ selectedBrand: assetId }, () => {
      console.log("brandAssettsCon");
      this.getBrand()
      // this.getMediaAssets();
      this.getBrandsByFilters("sort_by=name&sort_order=asc");
    })

    // this.getAssets()
    // this.getNoticationSettings()
    // this.getFolders()
  }

  searchAssets() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var userId = JSON.parse(userDetails).brand_id
      console.log(userId, "userId")

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategorySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.AssetCategoryAPIEndPoint

      // `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
       //`bx_block_categories_sub_categories/media_assets/search_media_assets?page=1&brand_id=${this.state.selectedBrand}&name=${this.state.search}`
      // `/bx_block_categories_sub_categories/media_assets?per_page=50&brand_id=${this.state.selectedBrand}&sort_by=name`
    `bx_block_categories_sub_categories/media_assets/search_media_assets?page=1&per_page=500&name=${this.state.search}&brand_id=${this.state.selectedBrand}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getBrand = () => {
    const header = {

      token: localStorage.getItem("token")

    };

    localStorage.setItem("brandasset", this.state.selectedBrand)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/brands/${this.state.selectedBrand}`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }
  getMediaAssets = (pageNumber?: any) => {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/media_assets?page=${this.state.setPage}&per_page=${this.state.perPage}&brand_id=${this.state.selectedBrand}`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAssets = () => {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/media_asset_accounts?type=media_asset&id=${this.state.mediaAssetID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getFolders = (value?: any) => {
    const header = {

      token: localStorage.getItem("token")

    };
    const assetId = value
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      // `bx_block_categories_sub_categories/media_asset_accounts?type=brand&id=${this.state.mediaAssetID}`
      `bx_block_categories_sub_categories/media_asset_folders?page=${this.state.pageNumber}&per_page=${this.state.perPage}&media_asset_id=${value}`
    );
    requestMessage.addData(
      'assetId',
      assetId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null,
    });
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false,
    });
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen1: true,
    });
  };
  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true,
    });
  };
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen1: false,
    });
  };
  setData() {
    console.log("hello");
    const sideNavData = {
      sideNavData: [
        {
          name: "photoproduction",
        },
        {
          name: "CGI",
        },
      ],
    };
    localStorage.setItem("navData", JSON.stringify(sideNavData));
    // this.props.history.push("/Home/Projectportfolio")
    // navigator.navigate("/Home/Projectportfolio")
    window.location.href = "/Home/Projectportfolio";
  }
  progressPercentage = (e?: any, value?: any) => {
    console.log(e, value, "value");
    this.setState({
      percentage: value,
    });
  };
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true,
    });
  }
  navigateToProjects(e?: any) {
    this.props.navigation.navigate("home/projectportfolio");
  }
  deleteAsset() {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAssetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2
    `bx_block_categories_sub_categories/media_assets/${this.state.selectedFolder?.id}`
        // `bx_block_categories_sub_categories/media_asset_folders/${this.state.selectedAsset?.id}`
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
    });
    // if (item == "inviteTeamMember") {
    //   console.log("true");
    //   this.setState({
    //     // anchorEl: null,
    //     inviteTeamMembersDialog: true,
    //     //inviteteammembersId:item
    //   });
    // }
    if (item == "folderProperties") {
      this.setState({
        // anchorEl: null,
        setFolder: true,
        //inviteteammembersId:item
      });
    }
    if (item == 'download') {
      console.log("this.selectedAsset", this.state.selectedAsset);

      this.download(this.state.selectedAsset)
    }
    if (item == 'sharableLink') {
      this.setState({ openLink: true })
    }
    if(item == 'delete'){
      this.setState({deleteMediaDialog : true})
    }

    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  download(e: any) {
    console.log("file for ", e);
    fetch(e?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url.split("?")[0], {
      method: "GET",
      // headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", e?.attributes?.name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleClickCreateNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: true,
    });
  };
  handleOpenFolderProperties = () => {
    this.setState({
      setFolder: true
    })
  }
  handleClickCancelNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
    });
  };

  handleClickStartBriefing = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddBriefingModel: true,
    });
  };

  handleClickStopBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickChangeBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickSaveBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };

  handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ category: event.target.value as string });
  };

  handleChangeAssetType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ assetType: event.target.value as string });
  };

  handleClickStartRequestQuote = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddRequestQuoteModel: true,
    });
  };

  handleClickStopRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickChangeRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  setMediaAssetID = (data?: any) => {
    console.log(data, "gg")

    this.getFolders(data)


  }
  handleClickSaveRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };

  getfilesDetails = (value?: any) => {
    value?.forEach((element: any) => {
      element?.internalFiles?.map((item: any) => {
        this.setState({
          noOfFiles: item?.attributes?.number_of_files,
          noOfFolders: item?.attributes?.number_of_folders,
          size: item?.attributes?.size,
          type: item?.type
        })
      })



    })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log(message, "mes")
    runEngine.debugLog("Message Recived", message);

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
        let totalPagesCount = this.state.totalPages;
        this.setState({
          assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
          count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
          totalPages:message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : totalPagesCount,

        }, () => {
          console.log(this.state.assetData)
          // this.state.assetData.forEach(async (element: any) => {
          //   var value;
          //   value = element.id
          // this.setMediaAssetID(value)
          // })
        })
        // this.state.assetData.forEach((element:any) => {
        //   this.setState({
        //     mediaAssetID:element.id
        //   },()=>{
        //     this.getFolders()
        //   })
        // });
      }
    }
     if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteAssetApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.message) {
        this.setState({deleteMediaDialog : false})
          toast.success("Folder deleted successfully.")
          this.getMediaAssets()
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingByFiltersId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          console.log(responseJson)
          //  this.setState({tableBrandData:responseJson.brands.data})
          if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data && message?.properties?.RestAPIResponceSuccessMessage?.total_pages) {
            this.setState({
              assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
              count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
              loading: false
    
            }, () => {
              console.log(this.state.assetData)
              // this.state.assetData.forEach(async (element: any) => {
              //   var value;
              //   value = element.id
              // this.setMediaAssetID(value)
              // })
            })
            // this.state.assetData.forEach((element:any) => {
            //   this.setState({
            //     mediaAssetID:element.id
            //   },()=>{
            //     this.getFolders()
            //   })
            // });
          }
          else {
            this.setState({ loading: false,
              assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
             })
          }
        

        } else {
          this.setState({ loading: false,
           })
          console.log("else")
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategorySearchApiCallId) {
      if(message?.properties?.RestAPIResponceSuccessMessage?.data){
        this.setState({searchedData :  message?.properties?.RestAPIResponceSuccessMessage?.data})
        console.log("assetData123", this.state.searchedData);
        //console.log("searching data" , message?.properties?.RestAPIResponceSuccessMessage?.data ); 
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.addFolderAPI) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.data) {
       
        toast.success("Folder created successfully !")
        this.setState({ setOpen: false })
        this.getMediaAssets()





      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        console.log("brandssss");
        this.setState({ brandDetail: message.properties.RestAPIResponceSuccessMessage.data })



      }
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders) {
      filesArray.push(message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders.data)
      this.setState({
        files: filesArray
        // files: [...this.state.files, message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders.data]
      }, async () => {

        console.log(this.state.files, filesArray, this.state.assetData, "files")
        await this.state.assetData?.map((x: any, id: any) => {
          let len = this.state.files.length
          console.log(len, id);
          if (len)
            x.internalFiles = this.state.files[id + 1]
          return x;
        })


        this.getfilesDetails(this.state.assetData)
        console.log('====================================');
        console.log(this.state.assetData);

      })
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.accounts) {
      this.setState({
        accountImages: message?.properties?.RestAPIResponceSuccessMessage?.accounts.data
      }, () => {
        console.log(this.state.accountImages, "images")
      })
    }

    if (message?.properties?.RestAPIResponceSuccessMessage?.errors) {
      var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
      let error = Object.keys(errorMessage[0])
      let errorMsg = errorMessage[0][error[0]]
      console.log(error, "error", errorMessage[0][error[0]])


      toast.error(errorMsg)
      this.handleCloseModal()
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.data) {



      //toast.success("Folder Created Successfully")
      // this.getMediaAssets()
      // this.getAssets()
      // this.handleCloseModal()
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // createFolder = (values?: any, e?: any) => {
  //   console.log(values, "values")

  //   const header = {
  //     "Content-Type": configJSON.categoryApiContentType,
  //     token: localStorage.getItem("token")
  //   };

  //   console.log("header")

  //   const data = {
  //     name: values.name,
  //     media_asset_id: 1
  //   };

  //   const httpBody = {
  //     data: data
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   console.log("ddd")

  //   this.addFolderAPI = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.addFolderAPIEndPoint
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.httpPostType
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);



  //   return true;
  // }

  getNoticationSettings() {
    const userId = localStorage.getItem("userId")
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationSettingsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_notification_settings/project_notifications?account_id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  createFolder = (values?: any, e?: any) => {
    console.log(values, "values")
    const brandDetails = localStorage.getItem("userInfo")


    //var brandId = JSON.parse(brandDetails).brand_id
    var brandId = this.state.brandDetail?.id


    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token")
    };

    console.log("header")

    const data = {
      name: values.name,
      "visibility": true,
      "brand_id": brandId
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("ddd")

    this.addFolderAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.addFolderAPIEndPoint
      `bx_block_categories_sub_categories/media_assets`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);



    return true;
  }



  // Customizable Area Start


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handlePagination = (event:any,value:number) => {
this.setState({setPage:value}, () => {
  // this.getMediaAssets();
  this.getBrandsByFilters("sort_by=name&sort_order=asc");
})
  }



  // Customizable Area End
}
