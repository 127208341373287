import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Select
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, Redirect } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withTranslation, Trans } from "react-i18next";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

const styles = {
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  brandNameData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
    marginLeft: "20px",
  },
  tableDataLogo: {
    border: "1px solid white",
    paddingLeft: "44px",
    paddingTop: "18px",
    paddingBottom: "8px",
    backgroundColor: "#1E3E59",
    borderRadius: "26px",
  },
  dialogPaper: {
    width: "528px",
    height: "440px",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  inputRoot: {
    width: "449px",
    height: "40px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
  inputError: {
    width: "449px",
    height: "40px",
    border: "1px solid red",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  dialogOpen: any;
  newBrandRedirect: any;
  messageError: string;
  brandsList: any;
  brandName: any;
  brandEmailDomain: any;
  brandDescription: any;
  isBrandNameValid: any;
  isBrandEmailDomainValid: any;
  isBrandDescriptionValid: any;
  nextPage: boolean;
  totalCount: number;
  totalPages: number;
  setPage: number;
  isSearch: boolean;
  searchValue: any;
  searchData: any;
  path: string;
  selectedBrand: any;
  isBrandDisabled: boolean;
  userRequests: any;
  brandList: any
}

interface SS {
  id: any;
}

class UserRequests extends BlockComponent<Props, S, SS> {
  getUserRequestAPICallID: string;
  approveUserRequestAPICallID: string
  deleteUserRequestAPICallID: string
  getBrandsApiCallId: string
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: "",
      dialogOpen: false,
      newBrandRedirect: false,
      messageError: "",
      brandsList: [],
      brandName: "",
      brandEmailDomain: "",
      brandDescription: "",
      isBrandNameValid: true,
      isBrandEmailDomainValid: true,
      isBrandDescriptionValid: true,
      nextPage: false,
      totalCount: 0,
      totalPages: 0,
      setPage: 1,
      isSearch: false,
      searchValue: "",
      searchData: [],
      path: "",
      selectedBrand: null,
      isBrandDisabled: false,
      userRequests: [],
      brandList: []
    };
  }

  async componentDidMount() {
    await this.getUserRequest();
    await this.getBrandList();
  }

  getBrandList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/brands?page=1&per_page=2000`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserRequest = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserRequestAPICallID = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_creation_requests?page=${this.state.setPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  approveUserRequest = async (userId: any, brandId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approveUserRequestAPICallID = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const body = {
      "data": {
        "id": userId,
        "brand_id": brandId
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_creation_requests/approve_account`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteUserRequest = (userId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteUserRequestAPICallID = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_creation_requests/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleBrandChange = (event: any, id: any) => {
    console.log("handleBrandChange", event.target.value, id);
    let users = this.state.userRequests;
    const userIndex = this.state.userRequests?.findIndex((user: any) => user.id == id);
    if (userIndex > -1) {
      users[userIndex].attributes.assigned_brand_id = +event.target.value
    }
    this.setState({ userRequests: users })
  }






  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getUserRequestAPICallID) {
        console.clear();
        console.log("userRequest", responseJson);
        let total_page = this.state.totalPages;
        // let users = responseJson?.accounts?.data?.map((user: any) => {

        //   return {
        //     ...user,
        //     selectedBrand: null
        //   }


        // })
        this.setState({
          userRequests: responseJson?.accounts?.data,
          totalPages: responseJson?.total_pages ? responseJson?.total_pages : total_page
        })

      }
      else if (apiRequestCallId === this.approveUserRequestAPICallID) {
        console.clear();
        console.log("approveUserRequest", responseJson);
        toast.success("User Account Approve successfully.");
        this.getUserRequest()

      }
      else if (apiRequestCallId === this.deleteUserRequestAPICallID) {
        console.clear();
        console.log("approveUserRequest", responseJson);
        toast.success("User Account deleted successfully.");
        this.getUserRequest()

      }
      else if (apiRequestCallId === this.getBrandsApiCallId) {
        console.clear();
        if (responseJson.brands) {
          if (responseJson.brands.data) {
            if (responseJson.brands.data !== null) {
              let tempArr = [] as any;
              responseJson.brands.data.map((_data: any, idx: any) => {
                tempArr.push({
                  id: _data.id,
                  name: _data.attributes.name,
                });
              });
              this.setState({
                brandList: tempArr,
              });
            }
          }
        }
      }
    }
  }

  handleIconClick = (event: any, selectedBrand: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedBrand: selectedBrand
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
      selectedBrand: null
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      brandName: "",
      brandDescription: "",
      brandEmailDomain: "",
    });
  };
  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };
  handleBrandNameChange = (event: any) => {
    this.setState({
      isBrandNameValid: true,
      brandName: event.target.value,
    });
  };
  handleBrandDomainChange = (event: any) => {
    this.setState({
      isBrandEmailDomainValid: true,
      brandEmailDomain: event.target.value,
    });
  };
  handleBrandDescriptionChange = (event: any) => {
    this.setState({
      isBrandDescriptionValid: true,
      brandDescription: event.target.value,
    });
  };

  handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    this.setState({ setPage: value }, () => {
      this.getUserRequest()
    });
  };

  fetchMoreData = () => {
    console.clear();
    console.log("Page count", this.state.setPage);
    this.setState({ setPage: this.state.setPage + 1 }, () => {
      //   this.getBrandsByFilter(this.state.path, this.state.setPage);
    });
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  handleSearchClick = () => {
    this.setState({ searchData: [], setPage: 1 });
    this.setState({ isSearch: true });
    let path = `brand_name=${this.state.searchValue}`;
    this.setState({ path: path });
    // this.getBrandsByFilter(path, this.state.setPage);
    this.setState({ searchValue: "" });
  };



  render() {
    const { t } = this.props;
    return (
      <>
        <Grid container style={{ paddingLeft: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Link
                to="/AdminConsole"
                style={{
                  color: "#1a76b0",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.dashboard")}
              </Link>

              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#1a76b0",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {t("AdminConsole.AccountCreationRequests")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                {" "}
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {t("AdminConsole.AccountCreationRequests")}
                </Typography>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: "50px" }}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item
                    style={{ marginRight: "20px" }}
                  >
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder="Search for Users"
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                {/* <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={this.exportList}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("AdminConsole.export")}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder={t("AdminConsole.searchForBrands")}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                      onClick={this.handleSearchClick}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "152px",
                        height: "32px",
                      }}
                      onClick={() => {
                        this.handleDialogOpen();
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                          fontSize: "12px",
                        }}
                      >
                        {t("AdminConsole.createNewBrand")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          {!this.state.isSearch ? (
            <>
              <Grid item xs={12}>
                <TableContainer style={{ background: "#F5F6FA" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "20%",
                            paddingLeft: "50px",
                          }}
                        >
                          {/* {t("AdminConsole.brandName")} */}
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "20%",
                          }}
                        >
                          {/* {t("AdminConsole.Email")} */}
                          Email
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "10%",
                          }}
                        >
                          {/* {t("AdminConsole.Email")} */}
                          Brand Selected
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "15%",
                          }}
                        >
                          {/* {t("AdminConsole.Email")} */}
                          Address
                        </TableCell>
                        

                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "15%",
                          }}
                        >
                          {/* {t("AdminConsole.Email")} */}
                          Assign Brand
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontFamily: "Work Sans",
                            color: "#9EABB5",
                            textTransform: "uppercase",
                            opacity: 1,
                            borderBottom: "0px",
                            width: "30%",
                          }}
                        >
                          {/* {t('AdminConsole.')} */}
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.userRequests?.map((_data: any) => (
                      <>
                        <TableBody
                          style={{ background: "white" }}
                          key={_data.id}
                        // onClick={(e) =>
                        //   (window.location.href = `/AdminConsole/brand/${
                        //     _data.id
                        //   }`)
                        // }
                        >
                          <TableCell
                            style={{
                              padding: "35px",
                              border: "0px",
                              borderLeft: "20px solid #1E3E59",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container direction="row">
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="body2"
                                      className={
                                        this.props.classes.brandNameData
                                      }
                                    >
                                      {_data?.attributes?.first_name} {_data?.attributes?.last_name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data?.attributes?.email}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data?.attributes?.company_name}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              {_data?.attributes?.city}, {_data?.attributes?.country} {_data?.attributes?.zip_code} 
                            </Typography>
                          </TableCell>
                          <TableCell style={{ border: "0px" }}>
                            <Typography
                              variant="body2"
                              className={this.props.classes.tableData}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Age"
                                value={_data?.attributes?.assigned_brand_id}
                                disableUnderline={true}
                                style={{
                                  border: "1px solid #9EABB593",
                                  width: "152px",
                                  height: "32px",
                                  fontSize: "12px",
                                  paddingLeft: "5px",
                                }}
                                onChange={(e) =>
                                  this.handleBrandChange(e, _data.id)
                                }
                              >
                                {this.state.brandList.map((brand: any) =>
                                  <MenuItem
                                    value={brand.id}
                                    key={brand.id}
                                    onClick={(e) =>
                                      e.stopPropagation()
                                    }
                                  >
                                    {brand.name}
                                  </MenuItem>
                                )}

                              </Select>
                            </Typography>
                          </TableCell>
                          {_data?.attributes?.status != 'Approved' ?
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >

                                <Button
                                  style={{
                                    backgroundColor: "#173e59",
                                    border: "1px solid #173e59",
                                    width: "88px",
                                    height: "32px",
                                  }}
                                  disabled={_data?.attributes?.assigned_brand_id ==null || _data?.attributes?.assigned_brand_id ==''}
                                  onClick={() => this.approveUserRequest(_data?.id, _data?.attributes?.assigned_brand_id)}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Work Sans",
                                      letterSpacing: 0,
                                      color: "#FFFFFF",
                                      textTransform: "uppercase",
                                      opacity: 1,
                                      fontSize: "12px",
                                    }}
                                  >
                                    Add User
                                  </Typography>
                                </Button>


                                <Button
                                  style={{
                                    backgroundColor: "#173e59",
                                    border: "1px solid #173e59",
                                    width: "88px",
                                    height: "32px",
                                    marginLeft: "10px"
                                  }}
                                  onClick={() => this.deleteUserRequest(_data?.id)}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Work Sans",
                                      letterSpacing: 0,
                                      color: "#FFFFFF",
                                      textTransform: "uppercase",
                                      opacity: 1,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {/* {t("AdminConsole.createNewBrand")} */}
                                    Reject
                                  </Typography>
                                </Button>

                              </Typography>
                            </TableCell> :
                            <TableCell style={{ border: "0px" }}>
                              <Typography
                                variant="body2"
                                className={this.props.classes.tableData}
                              >

                                <Button
                                  style={{
                                    backgroundColor: "#173e59",
                                    border: "1px solid #173e59",
                                    width: "88px",
                                    height: "32px",
                                  }}
                                  disabled
                                  onClick={() => this.approveUserRequest(_data?.id, _data?.attributes?.selectedBrand)}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Work Sans",
                                      letterSpacing: 0,
                                      color: "#FFFFFF",
                                      textTransform: "uppercase",
                                      opacity: 1,
                                      fontSize: "12px",
                                    }}
                                  >
                                    Approved
                                  </Typography>
                                </Button>

                              </Typography>
                            </TableCell>

                          }

                        </TableBody>
                        <Grid container>
                          <Grid item xs={12} style={{ height: "25px" }} />
                        </Grid>
                      </>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} />
                  {this.state.totalPages > 1 ?
                    <>
                      <Grid item xs={3}>
                        <Pagination
                          count={this.state.totalPages}
                          variant="outlined"
                          color="primary"
                          page={this.state.setPage}
                          onChange={this.handlePaginationChange}
                        />
                      </Grid>
                    </> : ""}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      style={{ left: "60px" }}
                      onClick={() =>
                        this.setState({ isSearch: false, searchValue: "" })
                      }
                    >
                      Back to Previous Screen
                    </Button>
                  </Grid>
                </Grid>
                <InfiniteScroll
                  dataLength={this.state.searchData.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.nextPage}
                  loader={this.state.totalPages ? "" : <h4>Loading...</h4>}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                              paddingLeft: "50px",
                            }}
                          >
                            {t("AdminConsole.brandName")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.users")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.phone")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t("AdminConsole.email")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontFamily: "Work Sans",
                              color: "#9EABB5",
                              textTransform: "uppercase",
                              opacity: 1,
                              borderBottom: "0px",
                              width: "auto",
                            }}
                          >
                            {t('AdminConsole.address')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.searchData.map((_data: any) => {
                        return (
                          <>
                            <TableBody
                              style={{
                                background: "#E4E8F0",
                              }}
                              onClick={(e) =>
                              (window.location.href = `/AdminConsole/brand/${_data.id
                                }`)
                              }
                            >
                              <TableCell
                                style={{
                                  padding: "35px",
                                  border: "0px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={this.props.classes.tableData}
                                    >
                                      {_data.attributes.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.total_users}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.phone_number !== null
                                    ? _data.attributes.phone_number
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.email !== null
                                    ? _data.attributes.email
                                    : "NA"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "0px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={this.props.classes.tableData}
                                >
                                  {_data.attributes.address !== null
                                    ? _data.attributes.address
                                    : "NA"}
                                </Typography>
                              </TableCell>
                            </TableBody>

                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  height: "25px",
                                }}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </InfiniteScroll>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogOpen}
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={this.props.classes.dialogTitleRoot}
          >
            <span className={this.props.classes.dialogTitle}>
              {t("AdminConsole.createNewBrand")}
            </span>
            <IconButton>
              <CloseIcon onClick={this.handleDialogClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  classes={
                    !this.state.isBrandNameValid
                      ? { root: this.props.classes.inputError }
                      : { root: this.props.classes.inputRoot }
                  }
                  disableUnderline={true}
                  placeholder=""
                  value={this.state.brandName}
                  onChange={this.handleBrandNameChange}
                />
                {!this.state.isBrandNameValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Email Domain
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  classes={
                    !this.state.isBrandEmailDomainValid
                      ? { root: this.props.classes.inputError }
                      : { root: this.props.classes.inputRoot }
                  }
                  disableUnderline={true}
                  placeholder="domainname.com"
                  value={this.state.brandEmailDomain}
                  onChange={this.handleBrandDomainChange}
                />
                {!this.state.isBrandEmailDomainValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "40px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  style={
                    !this.state.isBrandDescriptionValid
                      ? {
                        width: "449px",
                        minHeight: "120px",
                        border: "1px solid red",
                        borderRadius: "2px",
                        paddingLeft: "15px",
                      }
                      : {
                        width: "449px",
                        minHeight: "120px",
                        border: "1px solid #9EABB5",
                        borderRadius: "2px",
                        paddingLeft: "15px",
                      }
                  }
                  disableUnderline={true}
                  placeholder=""
                  multiline={true}
                  value={this.state.brandDescription}
                  onChange={this.handleBrandDescriptionChange}
                />
                {!this.state.isBrandDescriptionValid ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    Required
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: "44px", paddingBottom: "30px" }}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "88px",
                height: "40px",
                borderRadius: "2px",
              }}
              onClick={this.handleDialogClose}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                }}
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "144px",
                height: "40px",
                borderRadius: "2px",
                background: "#1E3E59",
                marginLeft: "15px",
              }}
            //   onClick={this.createNewBrand}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                  color: "#FFFFFF",
                }}
              >
                Create Brand
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(UserRequests));
