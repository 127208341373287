import React, { Component } from 'react'
import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell, } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";



// Customizable Area Start
  

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t?: any;
    i18n?: any;
    location?: any;
    history?: any
}

interface S {
  taskData: any,
  allBrandData: any,
  allProjectData: any,
  searchtype:any
}

export default class SearchResults extends Component<Props, S> {
    constructor(props: Props) {
        super(props);
       
       this.state= {
        taskData: [],
        allBrandData: [],
        allProjectData: [],
        searchtype:""
         }
        }
    
  componentDidMount(){
    const searchAllData = localStorage.getItem("mydata")
    const x = localStorage.getItem("searchtype")
    if(searchAllData){
      let myData= JSON.parse(searchAllData)
      // console.log("delooo", myData);
      
      this.setState({taskData: myData,searchtype:x})
    }
    const searchBrandData = localStorage.getItem("myBrandData")
   
    if(searchBrandData){
      let branddata = JSON.parse(searchBrandData)
      this.setState({allBrandData: branddata, searchtype: x})
    }
   

  }      
  
  
  render() {
    
    console.log("jskkkkmmmmm....",this.state.taskData);
    const { classes } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
     

    return (
      <div style={{ marginLeft: "25px", fontFamily: primaryFont , marginRight: "25px"}}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/Homepage" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                
                Home
              </Link>
 
                <Link href="/Home/SearchResults" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                  
                   SearchResults
                </Link>
              
              
        </Breadcrumbs>
           <p style={{ color: "black", fontSize: "32px", fontFamily: primaryFont, marginTop: 0, marginLeft: "12px" }}>
               <strong>Search Results</strong> 
              </p>
           <div style={{marginTop: "20px", marginLeft: "12px"}}> 
          {this.state.taskData.length !== 0 && this.state.searchtype !=="brands" &&
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Brand Name
                      </TableCell>
                      {/* {this.state.searchtype!=="task" &&  */}
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Project Name
                      </TableCell>
                        {/* } */}
                      {this.state.searchtype!=="project" && 
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                          paddingLeft: "50px",
                        }}
                      >
                        Task Name
                      </TableCell>
                      }
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        End Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Updated At
                      </TableCell>


                    </TableRow>
                  </TableHead>

                  {this.state.taskData.map((_data: any,index:number) => {

                    return (
                      <>
                        <TableBody
                          style={{
                            background: "#E4E8F0",
                            cursor: 'pointer'
                          }}

                          // onClick={e => this.handleClickOpenTaskModal(_data)}
                          onClick={(e) => {
                          
                            
                            // alert("jao mt ");
                           
                            localStorage.setItem('selectedProjectId', _data.id);
                            localStorage.setItem('selectedBrand', _data?.attributes?.brand_id);
                            localStorage.setItem('retry', "yes");
              
                            if(_data?.attributes?.project_id){
                              // alert('project_id if condition')
                              // localStorage.setItem("searchtype","project")
                              window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${_data.id}&projectId=${_data?.attributes?.project_id}&brandId=${_data?.attributes?.brand_id}`
                              
                            }else{
                              // alert('task id else condition')
                              // localStorage.setItem("searchtype","task")
                              window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${_data.id}&projectId=${_data?.id}&brandId=${_data?.attributes?.brand_id}`

                            }
                           
                            
                                                        
                           
                          }}
                        >
                  
                            <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {this.state.searchtype=="task" && _data.attributes.brand_name}
                              {this.state.searchtype=="project" && _data.attributes?.brand?.data?.attributes?.name}
                              {/* {this.state.searchtype=="task" && _data.attributes?.brand?.data?.attributes?.name} */}
                            </Typography>
                          </TableCell>
                          {/* {this.state.searchtype!=="task" &&  */}
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.project_name || _data.attributes.name }
                            </Typography>
                          </TableCell>
                       {/* } */}
                          {this.state.searchtype!=="project" && 
                          <TableCell
                            style={{
                              padding: "35px",
                              border: "0px",

                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  style={{marginLeft: "15px"}}
                                >
                                  {_data.attributes.title}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        }
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.created_at}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.estimated_end_date}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.ultimate_state || _data.attributes.status }
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.updated_at}
                            </Typography>
                          </TableCell>
                           
                            



                        </TableBody>

                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              height: "25px",
                            }}
                          />
                        </Grid>
                      </>
                    )


                  })}







                </Table>
              </TableContainer>

              }
                  </div>
      
        <div style={{marginTop: "20px", marginLeft: "12px"}}>

        { this.state.allBrandData.length !== 0 && this.state.searchtype =="brands" &&
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Brand Name
                      </TableCell>
        
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        End Date
                      </TableCell>
                      {/* <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Status
                      </TableCell> */}
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "auto",
                        }}
                      >
                        Updated At
                      </TableCell>


                    </TableRow>
                  </TableHead>

                  {this.state.allBrandData.map((_data: any) => {

                    return (
                      <>
                        <TableBody
                          style={{
                            background: "#E4E8F0",
                            cursor: 'pointer'
                          }}

                         
                          onClick={(e) => {
                          
                            console.log("searchtype",this.state.searchtype)
                            if(this.state.searchtype === 'brands'){
                              localStorage.setItem("myBrandData",JSON.stringify(this.state.allBrandData))

                              window.location.href = `/Home/Brands?navigate&brandId=${_data.id}`
                              return
                            } 
                          }}
                        >
                  
                            <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              
                              {_data?.attributes?.name}
                              
                            </Typography>
                          </TableCell>
                          
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.created_at}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.estimated_end_date}
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.ultimate_state}
                            </Typography>
                          </TableCell> */}
                          <TableCell
                            style={{
                              border: "0px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              // className={
                              //   classes.tableData
                              // }
                            >
                              {_data.attributes.updated_at}
                            </Typography>
                          </TableCell>
                           
                            



                        </TableBody>

                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              height: "25px",
                            }}
                          />
                        </Grid>
                      </>
                    )


                  })}

                 </Table>
              </TableContainer>

              }
        </div>
              {this.state.taskData.length ==0 && this.state.allBrandData.length==0 && 
              <p style={{marginTop: "20px", marginLeft: "12px"}}>No Data Found</p> }
      </div>
      
    )
  }
}
// Customizable Area End

