Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.addFolderAPIEndPoint = "bx_block_categories_sub_categories/media_asset_folders";
exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.AssetCategoryAPIEndPoint = "bx_block_categories_sub_categories/media_assets"


exports.REACT_APP_API_stage = "https://abordersystem-37196-ruby.b37196.stage.us-east-1.aws.svc.builder.ai";
exports.REACT_APP_API_prod = "https://abordersystem-37196-ruby.b37196.prod.eu-central-1.aws.svc.builder.ai";


// Customizable Area End