import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Button from "@material-ui/core/Button";
// Customizable Area Start
import { Link } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
// Customizable Area End
import { signUpBackgroundImage } from "./assets";
import {
  harvestLogoImage,
  harvestLogo2xImage,
  harvestLogo3xImage,
  ukLogo,
  germanLogo,
} from "./assets";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/Visibility";
import { Tooltip, IconButton } from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const styles = {
  root: {
    backgroundColor: "red",
  },
  inputRoot: {
    // fontSize: 56
    // height: 56
  },
  inputPostion: {
    margin: "0",
    position: "absolute",
    top: "20%",
    left: "50%",
    transorm: "translate(-50%, -50%)",
  },
  signUpLogo: {
    height: "100%",
    width: "25%",
    position: "fixed",
    top: "0",
    bottom: "0",
  },
  label: {
    height: "20px",
  },
  button: {
    backgroundColor: "rgb(23, 62, 89)",
    color: "white",
    marginTop: "15px",
  },
  PageHeader: {
    // fontFamily: "inter",
    color: "#173e59",
    fontSize: "24px",
    marginBottom: "20px",
  },
  emailError: {
    fontSize: "10px",
    color: "red",
  },
  inputHeight: {
    height: "auto",
  },
  forgotPassword: {
    cursor: "pointer",
  },

  signInLink: {
    color: "#173e59",
    cursor: "pointer",
  },
  signUpLink: {
    textAlign: "end",
  },
  labelRoot: {
    fontSize: 15,
    color: "#282828",
    "&$labelFocused": {
      color: "#282828",
    },
  },
  image_logo: {
    width: "174px",
    height: "91px",
    objectFit: "contain",
  },
  logo: {
    position: "relative",
    zIndex: 1,
    padding: "55px 30px",
  },
} as const;
var langSelected = localStorage.getItem("i18nextLng");
// var labgColorNotSelected = "transperent"
class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
/* istanbul ignore next */
  handleLanguage(lang: string) {
    // console.log(this.props.i18n, lang)
    langSelected = lang;
    this.props.i18n.changeLanguage(lang);
    this.langaugeChangeHandler(this.props.i18n.language);
    console.log("lang", this.props.i18n);
  }

  render() {
    const { t, classes } = this.props;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    /* istanbul ignore else */
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#173e59";
    }
/* istanbul ignore next */
    return (
      <div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "10px",
            zIndex: 10000,
          }}
        >
          <Button
            onClick={() => this.handleLanguage("en")}
            style={{
              color:
                localStorage.getItem("i18nextLng") == "en" ? "white" : "black",
              backgroundColor:
                localStorage.getItem("i18nextLng") == "en"
                  ? "#173e59"
                  : "transparent",
            }}
            startIcon={
              <img src={ukLogo} style={{ width: "18px", height: "18px" }} />
            }
          >
            English
          </Button>
          <Button
            onClick={() => this.handleLanguage("de")}
            style={{
              color:
                localStorage.getItem("i18nextLng") == "de" ? "white" : "black",
              backgroundColor:
                localStorage.getItem("i18nextLng") == "de"
                  ? "#173e59"
                  : "transparent",
            }}
            startIcon={
              <img src={germanLogo} style={{ width: "18px", height: "18px" }} />
            }
          >
            German
          </Button>
        </div>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.backGroundImage}>
              <img
                src={signUpBackgroundImage.default}
                className={classes.signUpLogo}
              />
              <div className={classes.logo}>
                <img src={harvestLogoImage} className={classes.image_logo} />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <form className={classes.inputPostion}>
              <div>
                <p
                  className={classes.PageHeader}
                  style={{ color: colorToBeChanged }}
                >
                  {t("EmailAccountRegistrationController.signUp")}
                </p>
              </div>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      {!this.state.firstNameError ? (
                        <TextField
                          fullWidth
                          label={t(
                            "EmailAccountRegistrationController.firstName"
                          )}
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          name="firstName"
                          size="small"
                          id="outlined-error-helper-text"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.firstName"
                          )}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error
                          name="firstName"
                          size="small"
                          id="outlined-error-helper-text"
                          variant="outlined"
                          helperText={
                            this.state.firstNameError ? (
                              <Trans>
                                EmailAccountRegistrationController.firstRequired
                              </Trans>
                            ) : null
                          }
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {!this.state.lastNameError ? (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.lastName"
                          )}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          name="lastName"
                          size="small"
                          type="text"
                          className={classes.inputHeight}
                          variant="outlined"
                          id="outlined-error-helper-text"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.lastName"
                          )}
                          name="lastName"
                          error
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          size="small"
                          type="text"
                          variant="outlined"
                          id="outlined-error-helper-text"
                          helperText={
                            this.state.lastNameError ? (
                              <Trans>
                                EmailAccountRegistrationController.lastRequired
                              </Trans>
                            ) : null
                          }
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      {!this.state.companyNameError ? (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.companyName"
                          )}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          name="companyName"
                          id="outlined-error-helper-text"
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.companyName"
                          )}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error
                          name="companyName"
                          id="outlined-error-helper-text"
                          helperText={
                            this.state.companyNameError ? (
                              <Trans>
                                EmailAccountRegistrationController.companyNameIsRequired
                              </Trans>
                            ) : null
                          }
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                    </Grid>

                    <Grid item xs={10}>
                      {!this.state.emailError &&
                      !this.state.emailStatusError ? (
                        <TextField
                          fullWidth
                          label={t(
                            "EmailAccountRegistrationController.workEmail"
                          )}
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          className={classes.inputHeight}
                          id="outlined-error-helper-text"
                          name="email"
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.workEmail"
                          )}
                          className={classes.inputHeight}
                          error
                          id="outlined-error-helper-text"
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          helperText={
                            this.state.emailError ? (
                              <Trans>
                                EmailAccountRegistrationController.emailRequired
                              </Trans>
                            ) : this.state.emailStatusError ? (
                              "Invalid email"
                            ) : null
                          }
                          name="email"
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                      <Tooltip
                        title={
                          localStorage.getItem("i18nextLng") == "de"
                            ? "Nur Geschäftsemail-Adressen sind für die Nutzung von Design Harvest zugelassen."
                            : "Only Business Email adresses are allowed for the use of Design Harvest."
                        }
                        style={{ position: "absolute" }}
                      >
                        <IconButton>
                          <InfoOutlined style={{ color: "#9e9e9e" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} style={{ position: "relative" }}>
                      {!this.state.passwordError &&
                      !this.state.passwordStatusError ? (
                        <TextField
                          fullWidth
                          InputProps={{
                            endAdornment: this.state.showPassword ? (
                              // <img
                              //   src={imgPasswordInVisible}
                              //   onClick={(e) => this.passwordVisibility(e)}
                              // />
                              <Visibility
                                fontSize="small"
                                onClick={(e) => this.passwordVisibility(e)}
                                style={{ color: "#9e9e9e" }}
                              />
                            ) : (
                              // <img
                              //   src={imgPasswordInVisible}
                              //   onClick={(e) => this.passwordVisibility(e)}
                              // />
                              <VisibilityOffOutlined
                                fontSize="small"
                                onClick={(e) => this.passwordVisibility(e)}
                                style={{ color: "#9e9e9e" }}
                              />
                            ),

                            classes: { root: classes.inputRoot },
                          }}
                          label={t(
                            "EmailAccountRegistrationController.password"
                          )}
                          id="outlined-error-helper-text"
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          name="password"
                          type={this.state.showPassword ? "text" : "password"}
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label={t(
                            "EmailAccountRegistrationController.password"
                          )}
                          InputProps={{
                            endAdornment: this.state.showPassword ? (
                              // <img
                              //   src={imgPasswordVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.passwordVisibility(e)}
                              // />
                              <Visibility
                                fontSize="small"
                                onClick={(e) => this.passwordVisibility(e)}
                                style={{ color: "#9e9e9e" }}
                              />
                            ) : (
                              // <img
                              //   src={imgPasswordInVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.passwordVisibility(e)}
                              // />
                              <VisibilityOffOutlined
                                fontSize="small"
                                onClick={(e) => this.passwordVisibility(e)}
                                style={{ color: "#9e9e9e" }}
                              />
                            ),

                            classes: { root: classes.inputRoot },
                          }}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error
                          name="password"
                          id="outlined-error-helper-text"
                          helperText={
                            this.state.passwordError ? (
                              <Trans>
                                EmailAccountRegistrationController.passwordIsRequired
                              </Trans>
                            ) : this.state.passwordStatusError ? (
                              "Password length or special character is not matching"
                            ) : null
                          }
                          type={this.state.showPassword ? "text" : "password"}
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                      <Tooltip
                        title={
                          localStorage.getItem("i18nextLng") == "de"
                            ? "Ihr Passwort muss mindestens 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Sonderzeichen enthalten"
                            : "Your password must contains atleast 8 or more characters with a mix of letters, numbers & special characters"
                        }
                        style={{ position: "absolute", top: "10px" }}
                      >
                        <IconButton>
                          <InfoOutlined style={{ color: "#9e9e9e" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10}>
                      {!this.state.reTypePasswordError &&
                      !this.state.reTypePasswordStatusError ? (
                        <TextField
                          fullWidth
                          //InputLabelProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.confirmPassword"
                          )}
                          //className={classes.inputHeight}

                          InputProps={{
                            endAdornment: this.state.showConfirmPassword ? (
                              // <img
                              //   src={imgPasswordVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.passwordVisibility(e)}
                              // />
                              <Visibility
                                fontSize="small"
                                onClick={(e) =>
                                  this.confirmPasswordVisibility(e)
                                }
                                style={{ color: "#9e9e9e" }}
                              />
                            ) : (
                              // <img
                              //   src={imgPasswordInVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.confirmPasswordVisibility(e)}
                              // />
                              <VisibilityOffOutlined
                                fontSize="small"
                                onClick={(e) =>
                                  this.confirmPasswordVisibility(e)
                                }
                                style={{ color: "#9e9e9e" }}
                              />
                            ),
                            classes: {
                              root: classes.inputRoot,

                              // focused: classes.labelFocused
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          id="outlined-error-helper-text"
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          //InputLabelProps={{ classes: { root: classes.inputRoot } }}
                          label={t(
                            "EmailAccountRegistrationController.confirmPassword"
                          )}
                          InputProps={{
                            endAdornment: this.state.showConfirmPassword ? (
                              // <img
                              //   src={imgPasswordVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.confirmPasswordVisibility(e)}
                              // />
                              <Visibility
                                fontSize="small"
                                onClick={(e) =>
                                  this.confirmPasswordVisibility(e)
                                }
                                style={{ color: "#9e9e9e" }}
                              />
                            ) : (
                              // <img
                              //   src={imgPasswordInVisible}
                              //   className={classes.passwordVisible}
                              //   onClick={(e) => this.confirmPasswordVisibility(e)}
                              // />
                              <VisibilityOffOutlined
                                fontSize="small"
                                onClick={(e) =>
                                  this.confirmPasswordVisibility(e)
                                }
                                style={{ color: "#9e9e9e" }}
                              />
                            ),
                            classes: {
                              root: classes.inputRoot,
                              // focused: classes.labelFocused
                            },
                          }}
                          //style = {{height : "56px"}}
                          className={classes.inputHeight}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error
                          id="outlined-error-helper-text"
                          helperText={
                            this.state.reTypePasswordError ? (
                              <Trans>
                                EmailAccountRegistrationController.confirmPasswordRequired
                              </Trans>
                            ) : this.state.reTypePasswordStatusError ? (
                              <Trans>
                                EmailAccountRegistrationController.confirmPaswordNotmatch
                              </Trans>
                            ) : null
                          }
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          size="small"
                          variant="outlined"
                          onChange={(e) => this.txtInput(e)}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={10} style={{ marginTop: "10px" }}>
                    <p
                      className={classes.signUpLink}
                      style={{ textAlign: "start", fontSize: "13px" }}
                    >
                      By clicking sign up, you agree to our
                      <Link
                        className={classes.signUpLink}
                        style={{ color: colorToBeChanged }}
                        onClick={() =>
                          window.open(
                            "/terms-and-conditions?flag=signup",
                            "_blank"
                          )
                        }
                      >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {" Terms of Service "}
                        </span>
                      </Link>
                      <span>and you have read our </span>
                      <Link
                        className={classes.signUpLink}
                        style={{ color: colorToBeChanged }}
                        onClick={() =>
                          window.open("/privacy-policy?flag=signup", "_blank")
                        }
                      >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {" Privacy Policy "}
                        </span>
                        .
                      </Link>
                    </p>
                    {/* <Checkbox
                    color="primary"
                    name="acceptTermsAndConditions"
                    style={{paddingLeft: '0px', paddingBottom: '0px'}}
                    inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.acceptTermsAndConditions} onChange={(e) => { this.showTermsAndConditionsPopup(e) }} /> {'Accept Terms & Conditions'} */}
                  </Grid>

                  <Grid item xs={10}>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: colorToBeChanged }}
                      fullWidth
                      type="submit"
                      variant="contained"
                      onClick={(e) => this.createAccount(e)}
                    >
                      {t("EmailAccountRegistrationController.signUp")}
                    </Button>
                    <p className={classes.signUpLink}>
                      {t("EmailAccountRegistrationController.alreadyAccount")}
                      <Link
                        className={classes.signInLink}
                        onClick={this.goToSignIn}
                        style={{ color: colorToBeChanged }}
                      >
                        {t("EmailAccountRegistrationController.SignIn")}
                      </Link>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("permissions") == "superadmin") {
        window.location.assign("/AdminConsole");
      } else {
        window.location.assign("/Home/HomePage");
      }
    }
    // this.getValidations();
    // Customizable Area End
  }
}
export default withStyles(styles)(withTranslation()(EmailAccountRegistration));
