import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ToastContainer, toast } from 'react-toastify';

// Customizable Area End

export const configJSON = require("./config");
import 'react-toastify/dist/ReactToastify.css';
// Customizable Are

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  navigationScreenType:any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  sharedProject: any;
  sharedProjectError: any;
  setOpen1: any;
  setOpen: any;
  setOpen2: any;
  setOpen3: any;
  anchorEl: any;
  value: any,
  selectedIndex: any
  activeStatus: any
  teamsData: any
  pageNumber: any
  perPage: any
  accountId: any
  brandData: any
  getById: any,
  teamName: any,
  email: any,
  teamId: any,
  imagedata: any
  accountData: [],
  initialAccountData: []
  expanded: boolean
  userInfo: any
  itemValue: any
  specificBrandId: any,
  selectedUser: any,
  selectedUserId: any,
  flag : any,
  hover : any
  expendbrand : any
  search : any
  searchMember : any
  permissionName: any;
  deleteTeamDialog: any;
  loading:any
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTeamApiCallId: string = ""
  getAllBrandsApiCallId: string = ""
  addTeamAPI: string = ""
  getAllTeamsById: string = ""
  updateTeamAPI: string = ""
  updateTeamMemberId: string = ""
  deleteTeamMemberId : string = ""
  getAccountTeam: string = ""
  getAllUsersApiCallId: string = ""
  deleteTeamId: string = ""
  duplicateTeamId:string= ""
  getAllBrandsSearchApiCallId :string= ""

  accountValue: any = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

    ];

    this.state = {
      navigationScreenType:"",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      sharedProject: "",
      sharedProjectError: false,
      setOpen1: false,
      setOpen2: false,
      setOpen3: false,
      setOpen: false,
      anchorEl: null,
      value: 0,
      selectedIndex: 0,
      activeStatus: '',
      teamsData: [],
      pageNumber: 1,
      perPage: 2,
      accountId: [],
      brandData: [],
      getById: [],
      teamName: '',
      email: JSON.parse(localStorage.getItem('userInfo') || '{}')?.email,
      teamId: '',
      imagedata: [],
      accountData: [],
      initialAccountData: [],
      expanded: false,
      userInfo: {},
      itemValue: 0,
      specificBrandId: [],
      selectedUser: [],
      selectedUserId: [],
      flag : 0,
      hover : 0,
      expendbrand : null,
      search: null,
      searchMember : '',
      permissionName: JSON.parse(localStorage.getItem('userInfo') || '{}')?.role?.data?.attributes?.name,
      deleteTeamDialog : false,
      loading : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  handleClickOpenModalInfo = () => {
    this.setState({
      setOpen1: true
    })
  }
  handleCloseModalInfo = () => {
    this.setState({
      setOpen1: false
    },()=>{
      if(this.state.navigationScreenType == "TeamsView"){
          window.location.href = "/Home/Teams";
      }
    })
  }
  handleOpenTeamUpdate = (value?: any) => {
    console.log(value, "teamId")
    this.setState({
      setOpen2: true,
      teamName: value.attributes?.name,
      teamId: value.id
    })
  }
  setUsers = (value?: any) => {
    this.setState({
      userInfo: value
    }, () => {
      this.state.userInfo, "user"
    })
    this.handleOpenUserInfo(value)
  }

  handleOpenUserInfo = (value?: any) => {
    console.log(value, "value")
    this.setState({

      setOpen3: true,

    })
  }
  setUser = (value?: any) => {
    this.setState({
      userInfo: value,
    }, () => {
      this.state.userInfo, "user"
    })
  }
  handleCloseUserInfo = () => {
    this.setState({
      setOpen3: false
    })
  }
  handleCloseTeamUpdate = () => {
    this.setState({
      setOpen2: false
    })
  }
  handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = (item?: any) => {
    this.setState({
      anchorEl: null,
    });
    if (item == "overview") {
       this.handleClickOpenModalInfo()
      
    }
    if (item == "delete") {
      // this.props.navigation.navigate("CompanyProfile");
      this.setState({deleteTeamDialog : true})
      // this.deleteTeam()

    }
    if (item == "duplicateTeam") {
      // this.props.navigation.navigate("CompanyProfile");
      this.duplicateTeam()
    }

  };
  updateTeamOverviewDetail(values :any){
    console.log(values, "update fn");
    // this.state.sharedProject.length > 0 ? 
    

  }

  searchTeam(){
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo) {
      var brandId = JSON.parse(userInfo).brand_id
    }

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllBrandsSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_team_builder/teams?page=1&per_page=50&name=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteTeamMember(memberId : any){
    console.log("memberId",memberId)
    console.log("team member team" , this.state.selectedIndex);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const data = {
      // name: value?.values?.teamName,
      // email: this.state.email,
      "account_ids": [parseInt(memberId)],
      "team_id": this.state.selectedIndex,
      //  "message":"You have been invited to join team "

    };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("ddd")

    this.deleteTeamMemberId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.addFolderAPIEndPoint
      `bx_block_team_builder/account_teams/delete_team_members`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true

    
  }
  deleteTeam(){
    this.setState({loading : true})
    console.log("delete clicked" , this.state.selectedIndex);
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTeamId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_team_builder/teams/${this.state.selectedIndex}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }
  duplicateTeam(){
    console.log("duplicate clicked" , this.state.selectedIndex);
    const header = {

      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType

    };
    const data = {
      "team_id" : this.state.selectedIndex
    }
    const httpBody = {
      data: data
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.duplicateTeamId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_team_builder/teams/team_duplication`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  selctedTeamValue(value?: any) {
    this.selectedTeam(value)
    this.accountTeam(value)
  }
  selectedTeam(value?: any) {
    console.log(value, "values")

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_team_builder/teams/${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  handleChangeTeams = (event: any, index: any, str?: any) => {
    console.log(index, "activeStatus")
    this.setState({
      selectedIndex: index,
      activeStatus: str
    })
  }
  async componentDidMount() {
    let value : any = localStorage.getItem("onBoardingStep");
    let valueData : any = localStorage.getItem("toShowOnboarding");
    if(parseInt(value) == 13){
      if(this.state.permissionName === "superadmin" || 
      localStorage.getItem("permissions") == 'brand_manager' || localStorage.getItem("permissions") == 'editor' || 
      localStorage.getItem("permissions") == 'approver'){
        localStorage.setItem("toShowOnboarding", "true");
        if(valueData == "false") window.location.reload();
      }else{
        localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString());
      }
    }
    this.setState({flag : 1})
    this.getAllBrands()
    var str = window.location.href;
    var arr = str.split("?")[1];
    if (arr != undefined && arr.length > 1) {
      var subArr = arr.split("&");
      console.clear();
      console.log("URL ", subArr[1]?.split("=")[1])
      if (subArr[0] == 'navigate=teams_view') {
        this.setState({navigationScreenType:"TeamsView"},()=>{
          this.selectedTeam(subArr[1]?.split("=")[1])
          this.accountTeam(subArr[1]?.split("=")[1])
        })
      }
    }
  }

  async componentDidMount2() {
    this.setState({flag : 1})
    this.getAllBrands()
    // this.getTeams()
    
   
    // this.setState({selectedIndex : this.state.teamsData[0]?.id})
    // await this.accountTeam(this.state.teamsData[0]?.id)
  }
  accordianhandleChange = (id: any) => (event: any, isExpanded: any) => {

    this.setState({
      expanded: isExpanded ? id : false
    })
  }
  getTeams = (value?: any) => {
    console.log("getTeams")
    this.setState({
      teamsData: [],
      itemValue: value
    })
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo) {
      var brandId = JSON.parse(userInfo).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTeamsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_team_builder/teams?per_page=50&brand_id=${value ? value : brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  accountTeam(value?: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountTeam = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      this.state.searchMember.length > 0 ?  `bx_block_team_builder/account_teams?team_id=${value}&user=${this.state.searchMember}` :
      `bx_block_team_builder/account_teams?team_id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  teamCreation(value?: any) {
    console.log(value)
    if (value.account) {
      console.log(value.account, "ghg")
      console.log(value)
      value.account[0].map((item: any) => {

        this.accountValue.push(parseInt(item.id))
      })
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };


    const data = {
      name: value.values.teamName,
      brand_id: value.values.selectBrand ? value.values.selectBrand : JSON.parse(localStorage.getItem('userInfo') || '{}')?.brand_id,
      account_ids: this.accountValue,
      description: value.values.description
    };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("ddd")

    this.addTeamAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.addFolderAPIEndPoint
      `bx_block_team_builder/teams`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }
  getAllBrands() {
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo) {
      var brandId = JSON.parse(userInfo).brand_id
    }

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllBrandsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_categories_sub_categories/brands/brand_team_listing?page=1&per_page=1500`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getUser(value?: any) {

    const header = {

      token: localStorage.getItem("token")

    };

    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    var brandId
    if (value) {
      brandId = value 
     
    }
    else {
      brandId =  userInfo.brand_id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log("value" , value , "brandID" , brandId ,);
    
    this.getAllUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `account_block/accounts?per_page=100&brand_id=${brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getBrandById(value?: any) {
    value.forEach(async (element: any) => {
      var value;
      value = element.id
      this.getTeams(value)
      // this.accountTeam(value)


    })



  }

  updateTeamMember = (account?: any) => {
    console.log("updaye mene", account);
    if( account?.account[0].length > 0){
      let userID = account?.account[0].length > 0 && account?.account[0].map((el: any) => {
        return parseInt(el.id)
      })
      // userID = userID.filter((el:any)=> el != this.ima )
      // let userId = []
      // for( let i= 0; i< account?.account[0].length ; i++){
      //   console.log("account?.account[i].id",account?.account[i].id);
  
      //   userId.push(account?.account[0][i].id)
      // }
      // console.log("userID",userID);
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      };
  
      const data = {
        // name: value?.values?.teamName,
        // email: this.state.email,
        "account_ids": userID,
        "team_id": this.state.selectedIndex,
        //  "message":"You have been invited to join team "
  
      };
  
  
      const httpBody = {
        data: data
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      console.log("ddd")
  
      this.updateTeamMemberId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        // configJSON.addFolderAPIEndPoint
        `bx_block_team_builder/account_teams`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true
    }
   


  }
handleChangeAcc = (panel: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({expendbrand: isExpanded ? panel : false});
  };

  updateTeam(value?: any) {
    this.updateTeamMember(value)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };

    const data = {
      name: value?.values?.teamName,
      email: this.state.email,
      description: value.values?.description ? value.values.description : null


    };


    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("ddd")

    this.updateTeamAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.addFolderAPIEndPoint
      `bx_block_team_builder/teams/${this.state.selectedIndex}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.update
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log(from, "from")
    runEngine.debugLog("Message Recived", message);
    console.log(message, "message")

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.getAllBrandsSearchApiCallId){
        if (message?.properties?.RestAPIResponceSuccessMessage?.teams) {
          this.setState({
            teamsData: message?.properties?.RestAPIResponceSuccessMessage?.teams?.data,
            // itemValue: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data[0]?.id
          }, () => {
            // console.log(this.state.brandData, this.state.itemValue)

            // this.getBrandById(this.state.brandData)

          //  if(this.state.itemValue){
          //    this.getTeams(this.state.itemValue)
          //    this.getUser(this.state.itemValue)
          //    this.setState({expendbrand : this.state.itemValue  })

          // }
        })

        }
      }
      if (apiRequestCallId === this.getAllBrandsApiCallId) {
        if (message?.properties?.RestAPIResponceSuccessMessage?.brands) {
          this.setState({
            brandData: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data,
            itemValue: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data[0]?.id
          }, () => {
            // console.log(this.state.brandData, this.state.itemValue)

            // this.getBrandById(this.state.brandData)

           if(this.state.itemValue){
             this.getTeams(this.state.itemValue)
             if(localStorage.getItem("permissions") == 'editor'){
              this.getUser(JSON.parse(localStorage.getItem('userInfo') || '{}')?.brand_id)
             }
             else {
              this.getUser(this.state.itemValue)
             }
             
             this.setState({expendbrand : this.state.itemValue  })

          }})

        }
      }
      if (apiRequestCallId == this.updateTeamMemberId) {
        console.log(message?.properties?.RestAPIResponceSuccessMessage, "updateAPIResponse updaye ,member")
        // toast.success("Team Member Updated Successfully")
      }
      if (apiRequestCallId === this.updateTeamAPI) {
        console.log(message?.properties?.RestAPIResponceSuccessMessage, "updateAPIResponse")
        if (message?.properties?.RestAPIResponceSuccessMessage?.data) {
          toast.success("Team Updated Successfully")
          this.getTeams(this.state.itemValue)
           
          this.handleCloseTeamUpdate()
          this.setState({setOpen1 : false})

        }
        

        this.selectedTeam(this.state.selectedIndex)
        this.accountTeam(this.state.selectedIndex)
      }
      if (apiRequestCallId === this.getAccountTeam) {
        if (message?.properties?.RestAPIResponceSuccessMessage?.accounts) {
          this.setState({
            imagedata: message?.properties?.RestAPIResponceSuccessMessage?.accounts,
            // sharedProject : message?.properties?.RestAPIResponceSuccessMessage?.accounts
            selectedUser: message?.properties?.RestAPIResponceSuccessMessage?.accounts?.accounts?.data,
          }, () => {
            console.log(this.state.imagedata, "imageDatra")
            console.log(this.state.selectedUser, "share selectedUser");

          })

        }
      }
      if (apiRequestCallId === this.addTeamAPI) {
        if (message?.properties?.RestAPIResponceSuccessMessage?.data) {
          toast.success("Team Added Successfully")
          this.accountValue = []
          this.getAllBrands()
          this.setState({
            setOpen: false
          })

        }
        else {
          toast.error("Team name must be less then 50 charecters")
        }
      }

      if (apiRequestCallId === this.getTeamApiCallId) {
        if (message?.properties?.RestAPIResponceSuccessMessage?.data) {
          console.log(message?.properties?.RestAPIResponceSuccessMessage?.data, "teamData")

          this.setState({
            getById: message?.properties?.RestAPIResponceSuccessMessage?.data
          }, () => {
           // this.handleClickOpenModalInfo();
            console.log(this.state.getById, "getById")
          })
        }
      }
      if (apiRequestCallId === this.getAllUsersApiCallId) {
        this.setState({
          accountData: message?.properties?.RestAPIResponceSuccessMessage?.accounts.data,
          initialAccountData: message?.properties?.RestAPIResponceSuccessMessage?.accounts.data[0]
        }, () => {
          console.log(this.state.accountData, "accountData")


        })
      }
      if(apiRequestCallId === this.deleteTeamId){
        if(message?.properties?.RestAPIResponceSuccessMessage){
          this.setState({deleteTeamDialog : false , loading : false})
          toast.success("Team deleted successfully")
          this.getAllBrands()
          
        }
        else {
          console.log("deleted team2",message?.properties?.RestAPIResponceSuccessMessage);
        }
        
      }

 if(apiRequestCallId === this.duplicateTeamId){
  if(message?.properties?.RestAPIResponceSuccessMessage){
    console.log("duplicate team",message?.properties?.RestAPIResponceSuccessMessage);
    toast.success("duplicate team created successfully")
    this.getAllBrands()
    
  }
  else {
    console.log("duplicate team2",message?.properties?.RestAPIResponceSuccessMessage);
  }

 }
 if(apiRequestCallId === this.deleteTeamMemberId){
  if(message?.properties?.RestAPIResponceSuccessMessage){
    console.log("delete member team",message?.properties?.RestAPIResponceSuccessMessage);
    toast.success("Member deleted successfully")
    this.accountTeam(this.state.selectedIndex)
    this.getTeams(this.state.itemValue)

  }

 }

      if (apiRequestCallId === this.getAllTeamsById) {
        if (message?.properties?.RestAPIResponceSuccessMessage?.teams) {

          // let teamData = []
          // teamData.push(...message?.properties?.RestAPIResponceSuccessMessage?.teams.data)
          // let arr = []
          // arr.push(...teamData)
          // console.log(arr)
          this.setState({
            teamsData: message?.properties?.RestAPIResponceSuccessMessage?.teams?.data
          }
            , 
            () => {
              if(this.state.flag && this.state.navigationScreenType == ""){
                if(this.state.teamsData[0]?.id != null && this.state.teamsData[0]?.id ){
                  this.setState({getById:this.state.teamsData[0]  , selectedIndex :this.state.teamsData[0]?.id } , ()=> console.log(this.state.getById))
                  this.accountTeam(this.state.teamsData[0]?.id)
                  this.selectedTeam(this.state.teamsData[0]?.id)

                }
                this.setState({flag : 0 })
              }

              // this.state.teamsData.map((x:any)=>{
              //   this.accountTeam(x.id)

              // })
            }
          )



        }
      }


    }





    if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
      var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors;
      if (errorMessage.length > 0) {
        let error = Object.keys(errorMessage[0])
        let errorMsg = errorMessage[0][error[0]]
        console.log(error, "error", errorMessage[0][error[0]])
  
        toast.error(errorMsg)
      }
    }

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }




    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area End
}