import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Breadcrumbs,
  AppBar,
  Tab,
  Tabs,
  Input,
  InputAdornment,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Select,
  Menu,
  MenuItem,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useParams } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation, Trans } from "react-i18next";
export const configJSON = require("./config");

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  templateData: any;
  templateName: any;
  status: any;
  assetCategoryName: any;
  assetTypeName: any;
  createdBy: any;
  lastModifiedBy: any;
  templateId: any;
  brandData: any;
  dialogOpen: any;
  brandTemplatePrice: any;
  brandTemplateStatus: any;
  selectedBrandTemplateId: any;
  anchorEl: any;
  templatePrice: any;
  isTemplatePriceEdit: boolean;
  tempTemplatePrice: string;
  totalBrandPages: number;
  setBrandPage: number;
  assetTypeId: string;
}

interface SS {
  id: any;
}

const styles = {
  templateHeadings: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1a76b0",
  },
  templateHeadingsData: {
    fontSize: "12px",
    fontFamily: "Work Sans",
    letterSpacing: "0px",
    opacity: 1,
    fontWeight: 400,
    marginLeft: "10px",
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  brandNameData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
    marginLeft: "20px",
  },
  chipLabel: {
    marginBottom: "5px",
  },
  dialogPaper: {
    width: "528px",
    height: "auto",
  },
  dialogTitleRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#1a76b0",
    fontSize: "16px",
    fontFamily: "Work Sans",
    letterSpacing: 0,
    opacity: 1,
  },
  inputRoot: {
    width: "100%",
    height: "32px",
    border: "1px solid #9EABB5",
    borderRadius: "2px",
    opacity: 1,
    paddingLeft: "15px",
  },
} as any;

class TemplateDetails extends BlockComponent<Props, S, SS> {
  getTemplateApiCallId: any;
  getBrandApiCallId: any;
  updateBrandApiCallId: any;
  updateTemplateApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      templateData: {},
      templateName: "",
      status: "",
      assetCategoryName: "",
      assetTypeName: "",
      createdBy: "",
      lastModifiedBy: "",
      templateId: "",
      brandData: [],
      dialogOpen: false,
      brandTemplatePrice: "",
      brandTemplateStatus: "",
      selectedBrandTemplateId: "",
      anchorEl: null,
      templatePrice: "",
      isTemplatePriceEdit: false,
      tempTemplatePrice: "",
      totalBrandPages: 0,
      setBrandPage: 1,
      assetTypeId: "",
    };
  }

  async componentDidMount() {
    var str = window.location.href;
    var templateId = str.split("/")[5];
    this.setState({ templateId: templateId });
    await this.getTemplateData(templateId);
    await this.getBrandData(templateId);
  }

  getTemplateData = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getBrandData = (id: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/brand_templates?template_id=${id}&page=${
        this.state.setBrandPage
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getTemplateApiCallId) {
        console.log(responseJson);

        if (responseJson.data) {
          if (responseJson.data.attributes) {
            this.setState(
              {
                templateId: responseJson.data.id,
                templateName: responseJson.data.attributes.name,
                assetCategoryName: responseJson.data.attributes.asset_category
                  ? responseJson.data.attributes.asset_category.data
                    ? responseJson.data.attributes.asset_category.data
                        .attributes
                      ? responseJson.data.attributes.asset_category.data
                          .attributes.name
                      : ""
                    : ""
                  : "",
                assetTypeName: responseJson.data.attributes.asset_type
                  ? responseJson.data.attributes.asset_type.data
                    ? responseJson.data.attributes.asset_type.data.attributes
                      ? responseJson.data.attributes.asset_type.data.attributes
                          .name
                      : ""
                    : ""
                  : "",
                createdBy: responseJson.data.attributes.creator
                  ? responseJson.data.attributes.creator.data
                    ? responseJson.data.attributes.creator.data !== null
                      ? responseJson.data.attributes.creator.data.attributes
                        ? `${
                            responseJson.data.attributes.creator.data.attributes
                              .first_name
                          } ${
                            responseJson.data.attributes.creator.data.attributes
                              .last_name
                          }`
                        : "NA"
                      : "NA"
                    : "NA"
                  : "Na",
                lastModifiedBy: responseJson.data.attributes.modifier
                  ? responseJson.data.attributes.modifier.data
                    ? responseJson.data.attributes.modifier.data !== null
                      ? responseJson.data.attributes.modifier.data.attributes
                        ? `${
                            responseJson.data.attributes.modifier.data
                              .attributes.first_name
                          } ${
                            responseJson.data.attributes.modifier.data
                              .attributes.last_name
                          }`
                        : "NA"
                      : "NA"
                    : "NA"
                  : "Na",
                status: responseJson.data.attributes.status,
                templatePrice:
                  responseJson.data.attributes.price !== null
                    ? responseJson.data.attributes.price
                    : "0",
                assetTypeId: responseJson.data.attributes.asset_type_id,
              },
              () =>
                this.setState({
                  tempTemplatePrice: this.state.templatePrice,
                })
            );
          }
        }
      } else if (apiRequestCallId === this.getBrandApiCallId) {
        if (responseJson.brand_templates) {
          if (responseJson.brand_templates.data) {
            if (
              responseJson.brand_templates.data !== null ||
              responseJson.brand_templates.data.length !== 0
            ) {
              let tempArr = [] as any;
              let response = responseJson.brand_templates.data;

              response.map((_data: any) => {
                tempArr.push({
                  brandId: _data.attributes.brand_id,
                  brandName: _data.attributes.brand.data.attributes.name,
                  status: _data.attributes.status,
                  price: _data.attributes.price,
                  lastModifiedAt: _data.attributes.updated_at,
                  brandTemplateId: _data.id,
                });
              });
              let totalBrandPages = this.state.totalBrandPages;
              this.setState({
                brandData: tempArr,
                totalBrandPages: responseJson.total_pages
                  ? responseJson.total_pages
                  : totalBrandPages,
              });
            }
          }
        }
      } else if (apiRequestCallId === this.updateBrandApiCallId) {
        if (responseJson.data) {
          toast.success("Status changed successfully");
          this.getBrandData(this.state.templateId);
        }
      } else if (apiRequestCallId === this.updateTemplateApiCallId) {
        if (responseJson.data) {
          this.setState({ isTemplatePriceEdit: false });
          toast.success("Template status/price changed successfully");
          this.getTemplateData(this.state.templateId);
        }
      }
    }
  }

  updateBrand = (body: any, id: any) => {
    const httpBody = {
      data: body,
    };
    console.log(httpBody);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateBrandApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/brand_templates/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateTemplate = (body: any) => {
    const httpBody = {
      data: body,
    };
    console.log(httpBody);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTemplateApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/templates/${this.state.templateId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBrandStatusChange = (event: any, id: any) => {
    this.updateBrand({ status: event.target.value }, id);
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleBrandTemplatePriceChange = (event: any) => {
    this.setState({ brandTemplatePrice: event.target.value });
  };

  handleDialogStatusChange = (event: any) => {
    this.setState({ brandTemplateStatus: event.target.value });
  };

  openEditDialog = (id: any, idx: any) => {
    this.setState({ selectedBrandTemplateId: id });
    let brands = this.state.brandData;
    this.setState({
      brandTemplatePrice: brands[idx].price,
      brandTemplateStatus: brands[idx].status,
      dialogOpen: true,
    });
  };

  updateDialogBrandDetails = () => {
    if (
      this.state.brandTemplatePrice !== "" &&
      this.state.brandTemplateStatus !== ""
    ) {
      this.updateBrand(
        {
          price: this.state.brandTemplatePrice,
          status: this.state.brandTemplateStatus,
        },
        this.state.selectedBrandTemplateId
      );
      this.setState({ dialogOpen: false });
    }
  };

  openMenu = (event: any) => {
    this.setState({ anchorEl: event.target });
  };
  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  exportList = () => {
    var url = `${
      currentUrl
    }/bx_block_data_import_export_csv/brand_templates_exports?template_id=${
      this.state.templateId
    }&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "file.csv");
    anchor.click();
  };

  toggleTemplatePriceEdit = () => {
    this.setState({
      isTemplatePriceEdit: !this.state.isTemplatePriceEdit,
      tempTemplatePrice: this.state.templatePrice,
    });
  };

  handleTemplatePriceChange = (event: any) => {
    let numberRegex = /^[0-9]*$/;
    if (numberRegex.test(event.target.value)) {
      this.setState({ tempTemplatePrice: event.target.value });
    }
  };

  handleTemplatePriceUpdate = () => {
    if (this.state.tempTemplatePrice !== "") {
      this.updateTemplate({
        price: this.state.tempTemplatePrice,
        name: this.state.templateName,
      });
    }
  };

  handleBrandPagination = (event: any, value: number) => {
    this.setState(
      {
        setBrandPage: value,
      },
      () => {
        this.getBrandData(this.state.templateId);
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <Grid
          container
          style={{ paddingLeft: "10px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Dashboard
              </Typography>

              <Link
                to="/AdminConsole/TemplateManagement"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                Template Management
              </Link>
              <Typography
                variant="body2"
                style={{
                  color: "#1a76b0",
                  fontSize: "12px",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  fontFamily: "Work Sans",
                }}
              >
                {this.state.templateName}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    color: "#1a76b0",
                    textTransform: "uppercase",
                    opacity: 1,
                    fontSize: "20px",
                  }}
                >
                  {this.state.templateName}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "104px",
                        height: "32px",
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                        onClick={this.exportList}
                      >
                        Export
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Input
                      id="input-with-icon-adornment"
                      style={{
                        border: "1px solid #e8edf4",
                        background: "#ffffff",
                        borderBottom: "1px solid #e8edf4",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      disableUnderline={true}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "8px" }}
                        >
                          <SearchIcon
                            style={{
                              height: "35px",
                              width: "20px",
                              opacity: 0.4,
                            }}
                          />
                        </InputAdornment>
                      }
                      placeholder="search for user"
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#173e59",
                        border: "1px solid #173e59",
                        width: "88px",
                        height: "32px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Work Sans",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          opacity: 1,
                        }}
                      >
                        Search
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "20px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  borderTop: "1px solid #F1F1F1",
                  borderBottom: "1px solid #F1F1F1",
                  padding: "20px 0 20px 0",
                }}
              >
                <Grid container direction="row">
                  <Grid item xs={1}>
                    <FolderIcon
                      style={{
                        color: "#1a76b0",
                        height: "60px",
                        width: "60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          Template:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {!this.state.isTemplatePriceEdit ? (
                          <Typography
                            variant="body2"
                            className={classes.templateHeadingsData}
                          >
                            {" "}
                            {this.state.templateName}
                          </Typography>
                        ) : (
                          <Input
                            id="input-with-icon-template-name"
                            style={{
                              width: "100%",
                              height: "4px",
                              fontSize: "10px",
                            }}
                            disableUnderline={true}
                            placeholder={t("AdminConsole.templateName")}
                            value={this.state.templateName}
                            onChange={(event) => {
                              this.setState({
                                templateName: event.target.value,
                              });
                            }}
                            // onBlur={(ev) => this.updateTemplate({ name: this.state.templateName })}
                          />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.status")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Chip
                          style={{
                            height: "23px",
                            width: "80px",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #1a76b0",
                            marginLeft: "10px",
                          }}
                          classes={{
                            label: classes.chipLabel,
                          }}
                          onClick={(e) => this.openMenu(e)}
                          label={
                            <span
                              style={{
                                fontFamily: "Work Sans",
                                fontSize: "10px",
                              }}
                            >
                              {this.state.status}
                            </span>
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.price")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container direction="row">
                          {!this.state.isTemplatePriceEdit ? (
                            <>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body2"
                                  className={classes.templateHeadingsData}
                                >
                                  {" "}
                                  $ {this.state.templatePrice}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={this.toggleTemplatePriceEdit}
                                >
                                  Edit
                                </Typography>
                              </Grid>{" "}
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    (window.location.href =
                                      "/NewTemplate?briefingId=" +
                                      this.state.templateId +
                                      "&type=templatedetails")
                                  }
                                >
                                  Edit Template
                                </Typography>
                              </Grid>{" "}
                            </>
                          ) : (
                            <>
                              <Grid item xs={6}>
                                <Input
                                  id="input-with-icon-adornment"
                                  style={{
                                    width: "100%",
                                    height: "4px",
                                    fontSize: "10px",
                                  }}
                                  disableUnderline={true}
                                  placeholder={t("AdminConsole.price")}
                                  value={this.state.tempTemplatePrice}
                                  onChange={this.handleTemplatePriceChange}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={this.toggleTemplatePriceEdit}
                                >
                                  {t("AdminConsole.cancel")}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={this.handleTemplatePriceUpdate}
                                >
                                  Update
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={3}
                        style={{ padding: "0px", paddingTop: "7px" }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          Asset category:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.assetCategoryName}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          Asset type:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.assetTypeName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.createdBy")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.createdBy}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadings}
                        >
                          {t("AdminConsole.lastModifiedBy")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="body2"
                          className={classes.templateHeadingsData}
                        >
                          {" "}
                          {this.state.lastModifiedBy}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  color: "#1a76b0",
                }}
              >
                {t("AdminConsole.priceSettings")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableContainer
                style={{
                  background: "#F5F6FA",
                  paddingLeft: "20px",
                  paddingRight: "10px",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "30%",
                          paddingLeft: "50px",
                        }}
                      >
                        {t("AdminConsole.brandName")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "25%",
                        }}
                      >
                        {t("AdminConsole.status")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "20%",
                        }}
                      >
                        {t("AdminConsole.price")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontFamily: "Work Sans",
                          color: "#9EABB5",
                          textTransform: "uppercase",
                          opacity: 1,
                          borderBottom: "0px",
                          width: "25%",
                        }}
                      >
                        Last Modified at
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {this.state.brandData.map((_data: any, idx: any) => (
                    <>
                      <TableBody style={{ background: "white" }} key={_data.id}>
                        <TableCell
                          style={{
                            padding: "35px",
                            border: "0px",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.brandNameData}
                              >
                                {_data.brandName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell style={{ border: "0px" }}>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Age"
                            value={_data.status}
                            disableUnderline={true}
                            style={{
                              border: "1px solid #9EABB593",
                              width: "152px",
                              height: "32px",
                              fontSize: "12px",
                              paddingLeft: "5px",
                            }}
                            onChange={(e) =>
                              this.handleBrandStatusChange(
                                e,
                                _data.brandTemplateId
                              )
                            }
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell style={{ border: "0px" }}>
                          <Typography
                            variant="body2"
                            className={classes.tableData}
                          >
                            $ {_data.price}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: "0px" }}>
                          <Typography
                            variant="body2"
                            className={classes.tableData}
                          >
                            {_data.lastModifiedAt}
                          </Typography>
                        </TableCell>

                        <TableCell style={{ border: "0px" }}>
                          <IconButton
                            onClick={(e) =>
                              this.openEditDialog(_data.brandTemplateId, idx)
                            }
                          >
                            <MoreVertIcon />
                            <span style={{ fontSize: "14px" }}>EDIT</span>
                          </IconButton>
                        </TableCell>
                      </TableBody>
                      <Grid container>
                        <Grid item xs={12} style={{ height: "25px" }} />
                      </Grid>
                    </>
                  ))}
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Pagination
                    count={this.state.totalBrandPages}
                    variant="outlined"
                    color="primary"
                    page={this.state.setBrandPage}
                    onChange={this.handleBrandPagination}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          onClose={this.handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogOpen}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle
            id="simple-dialog-title"
            disableTypography
            // classes={{ root: this.props.classes.dialogTitle }}
            className={classes.dialogTitleRoot}
          >
            <span className={classes.dialogTitle}>Update Brand Template</span>
            <IconButton>
              <CloseIcon onClick={this.handleDialogClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  {t("AdminConsole.price")}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  id="input-with-icon-adornment"
                  disableUnderline={true}
                  classes={{ root: classes.inputRoot }}
                  placeholder=""
                  value={this.state.brandTemplatePrice}
                  onChange={this.handleBrandTemplatePriceChange}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "30px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    color: "#9EABB5",
                    letterSpacing: 0,
                  }}
                >
                  {t("AdminConsole.status")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Age"
                  value={this.state.brandTemplateStatus}
                  disableUnderline={true}
                  style={{
                    border: "1px solid #9EABB593",
                    width: "100%",
                    height: "32px",
                    fontSize: "12px",
                    paddingLeft: "5px",
                  }}
                  onChange={this.handleDialogStatusChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: "44px", paddingBottom: "30px" }}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "88px",
                height: "40px",
                borderRadius: "2px",
              }}
              onClick={this.handleDialogClose}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                }}
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #1E3E59",
                width: "144px",
                height: "40px",
                borderRadius: "2px",
                background: "#1E3E59",
                marginLeft: "15px",
              }}
              onClick={this.updateDialogBrandDetails}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "Work Sans",
                  fontSize: "13px",
                  color: "#FFFFFF",
                }}
              >
                Update
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.closeMenu}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={() => this.updateTemplate({ status: "Active" })}
          >
            Active
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={() => this.updateTemplate({ status: "Inactive" })}
          >
            Inactive
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(TemplateDetails));
