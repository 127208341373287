Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.REACT_APP_API_stage = "https://abordersystem-37196-ruby.b37196.stage.us-east-1.aws.svc.builder.ai";
exports.REACT_APP_API_prod = "https://abordersystem-37196-ruby.b37196.prod.eu-central-1.aws.svc.builder.ai";

//project index api
exports.projectAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectAPiMethod = "GET";
exports.projectApiContentType = "application/json";

//project create api
exports.projectCreateAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectCreateAPiMethod = "POST";
exports.projectCreateApiContentType = "application/json";

//project show api
exports.projectShowAPiEndPoint = "bx_block_project_portfolio/projects/";
exports.projectShowAPiMethod = "GET";
exports.projectShowApiContentType = "application/json";

//project update api
exports.projectUpdateAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectUpdateAPiMethod = "PUT";
exports.projectUpdateApiContentType = "application/json";

//project delete api
exports.projectDeleteAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectDeleteAPiMethod = "DELETE";
exports.projectDeleteApiContentType = "application/json";

//account project index api
exports.accountProjectAPiEndPoint = "bx_block_project_portfolio/account_projects";
exports.accountProjectAPiMethod = "GET";
exports.accountProjectApiContentType = "application/json";

//account project create api
exports.accountProjectCreateAPiEndPoint = "bx_block_project_portfolio/account_projects";
exports.accountProjectCreateAPiMethod = "POST";
exports.accountProjectCreateApiContentType = "application/json";

//account project delete api
exports.accountProjectDeleteAPiEndPoint = "bx_block_project_portfolio/account_projects/delete_project_members";
exports.accountProjectDeleteAPiMethod = "DELETE";
exports.accountProjectDeleteApiContentType = "application/json";

//task index api
exports.taskAPiEndPoint = "bx_block_project_portfolio/tasks";
exports.taskAPiMethod = "GET";
exports.taskApiContentType = "application/json";

//task create api
exports.taskCreateAPiEndPoint = "bx_block_project_portfolio/tasks";
exports.taskCreateAPiMethod = "POST";
exports.taskCreateApiContentType = "application/json";

//task show api
exports.taskShowAPiEndPoint = "bx_block_project_portfolio/tasks/";
exports.taskShowAPiMethod = "GET";
exports.taskShowApiContentType = "application/json";

//task update api
exports.taskUpdateAPiEndPoint = "bx_block_project_portfolio/tasks/";
exports.taskUpdateAPiMethod = "PUT";
exports.taskUpdateApiContentType = "application/json";

//task delete api
exports.taskDeleteAPiEndPoint = "bx_block_project_portfolio/tasks/";
exports.taskDeleteAPiMethod = "DELETE";
exports.taskDeleteApiContentType = "application/json";

//task template index api
exports.taskTemplateAPiEndPoint = "bx_block_project_portfolio/task_templates";
exports.taskTemplateAPiMethod = "GET";
exports.taskTempleApiContentType = "application/json";

//task template create api
exports.taskTemplateCreateAPiEndPoint = "bx_block_project_portfolio/task_templates";
exports.taskTemplateCreateAPiMethod = "POST";
exports.taskTemplateCreateApiContentType = "application/json";

//briefing create api
exports.briefingCreateAPiEndPoint = "bx_block_project_portfolio/task_briefings";
exports.briefingCreateAPiMethod = "POST";
exports.briefingCreateApiContentType = "application/json";

//briefing update api
exports.briefingUpdateAPiEndPoint = "bx_block_project_portfolio/briefed_values/update_all_briefed_values";
exports.briefingUpdateAPiMethod = "POST";
exports.briefingUpdateApiContentType = "application/json";

//briefing show api
exports.briefingShowAPiEndPoint = "bx_block_project_portfolio/task_briefings";
exports.briefingShowAPiMethod = "GET";
exports.briefingShowApiContentType = "application/json";

//briefing delete api
exports.briefingDeleteAPiEndPoint = "bx_block_project_portfolio/task_briefings";
exports.briefingDeleteAPiMethod = "DEL";
exports.briefingDeleteApiContentType = "application/json";

exports.briefingUpdateStatusAPiEndPoint = "bx_block_project_portfolio/task_briefings";
exports.briefingUpdateStatusAPiMethod = "PUT";
exports.briefingUpdateStatusApiContentType = "application/json";

//Briefed Values  update api
exports.briefedValuesUpdateAPiEndPoint = "bx_block_project_portfolio/briefed_values";
exports.briefedValuesUpdateAPiMethod = "PUT";
exports.briefedValuesUpdateApiContentType = "application/json";

//Briefed Values  show api
exports.briefedValuesShowAPiEndPoint = "bx_block_project_portfolio/briefed_values";
exports.briefedValuesShowAPiMethod = "GET";
exports.briefedValuesShowApiContentType = "application/json";

//task quotation update api
exports.taskQuotationUpdateAPiEndPoint = "bx_block_project_portfolio/task_quotations";
exports.taskQuotationUpdateAPiMethod = "PUT";
exports.taskQuotationUpdateApiContentType = "application/json";


//task quotation create api
exports.taskQuotationCreateAPiEndPoint = "bx_block_project_portfolio/task_quotations";
exports.taskQuotationCreateAPiMethod = "POST";
exports.taskQuotationCreateApiContentType = "application/json";

//task quotation show api
exports.taskQuotationShowAPiEndPoint = "bx_block_project_portfolio/task_quotations";
exports.taskQuotationShowAPiMethod = "GET";
exports.taskQuotationShowApiContentType = "application/json";

//request quotation create api
exports.requestQuotationCreateAPiEndPoint = "bx_block_project_portfolio/task_quotations/request_quote";
exports.requestQuotationCreateAPiMethod = "POST";
exports.requestQuotationCreateApiContentType = "application/json";

//status index api
exports.statusAPiEndPoint = "bx_block_customised_order_status/task_statuses";
exports.statusAPiMethod = "GET";
exports.statusApiContentType = "application/json";

//status create api
exports.statusCreateAPiEndPoint = "bx_block_customised_order_status/task_statuses";
exports.statusCreateAPiMethod = "POST";
exports.statusCreateApiContentType = "application/json";


//status show api
exports.statusShowAPiEndPoint = "bx_block_customised_order_status/task_statuses";
exports.statusShowAPiMethod = "GET";
exports.statusShowApiContentType = "application/json";

//status update api
exports.statusUpdateAPiEndPoint = "bx_block_customised_order_status/task_statuses";
exports.statusUpdateAPiMethod = "PUT";
exports.statusUpdateApiContentType = "application/json";

//status delete api
exports.statusDeleteAPiEndPoint = "bx_block_customised_order_status/task_statuses";
exports.statusDeleteAPiMethod = "DEL";
exports.statusDeleteApiContentType = "application/json";

//task  comment index api
exports.taskCommentAPiEndPoint = "bx_block_comments/task_comments";
exports.taskCommentAPiMethod = "GET";
exports.taskCommentApiContentType = "application/json";

//task comment create api
exports.taskCommentCreateAPiEndPoint = "bx_block_comments/task_comments";
exports.taskCommentCreateAPiMethod = "POST";
exports.taskCommentCreateApiContentType = "application/json";

//task comment show api
exports.taskCommentShowAPiEndPoint = "bx_block_comments/task_comments";
exports.taskCommentShowAPiMethod = "GET";
exports.taskCommentShowApiContentType = "application/json";

//task comment show specific api
exports.taskCommentSpecificCommentAPiEndPoint = "bx_block_comments/task_comments/";
exports.taskCommentSpecificCommentAPiMethod = "GET";
exports.taskCommentSpecificCommentApiContentType = "application/json";


//task comment update api
exports.taskCommentUpdateAPiEndPoint = "bx_block_comments/task_comments/";
exports.taskCommentUpdateAPiMethod = "PUT";
exports.taskCommentUpdateApiContentType = "application/json";

//task comment delete api
exports.taskCommentDeleteAPiEndPoint = "bx_block_comments/task_comments";
exports.taskCommentDeleteAPiMethod = "DEL";
exports.taskCommentDeleteApiContentType = "application/json";

//task file upload-create api
exports.taskFileUploadCreateAPiEndPoint = "bx_block_upload_media/task_files";
exports.taskFileUploadCreateAPiMethod = "POST";
exports.taskFileUploadCreateApiContentType = "application/json";

//task file list api
exports.taskFileListAPiEndPoint = "bx_block_upload_media/task_files";
exports.taskFileListAPiMethod = "GET";
exports.taskFileListApiContentType = "application/json";

//task file show api
exports.taskFileShowAPiEndPoint = "bx_block_upload_media/task_files";
exports.taskFileShowAPiMethod = "GET";
exports.taskFileShowApiContentType = "application/json";

//task file bulk uploads-create api
exports.taskFileBulkUploadsCreateAPiEndPoint = "bx_block_bulk_uploading/task_bulk_uploads";
exports.taskFileBulkUploadsCreateAPiMethod = "POST";
exports.taskFileBulkUploadsCreateApiContentType = "application/json";

//task file annotation index api
exports.taskFileAnnotationAPiEndPoint = "bx_block_annotations/task_file_annotations";
exports.taskFileAnnotationAPiMethod = "GET";
exports.taskFileAnnotationApiContentType = "application/json";

//task file annotation create api
exports.taskFileAnnotationCreateAPiEndPoint = "bx_block_annotations/task_file_annotations";
exports.taskFileAnnotationCreateAPiMethod = "POST";
exports.taskFileAnnotationCreateApiContentType = "application/json";

//task file annotation show api
exports.taskFileAnnotationShowAPiEndPoint = "bx_block_annotations/task_file_annotations";
exports.taskFileAnnotationShowAPiMethod = "GET";
exports.taskFileAnnotationShowApiContentType = "application/json";

//task file annotation update api
exports.taskFileAnnotationUpdateAPiEndPoint = "bx_block_annotations/task_file_annotations";
exports.taskFileAnnotationUpdateAPiMethod = "PUT";
exports.taskFileAnnotationUpdateApiContentType = "application/json";

//task file annotation destroy api
exports.taskFileAnnotationDeleteAPiEndPoint = "bx_block_annotations/task_file_annotations";
exports.taskFileAnnotationDeleteAPiMethod = "DEL";
exports.taskFileAnnotationDeleteApiContentType = "application/json";


//task file annotation comments index api
exports.taskFileAnnotationCommentsAPiEndPoint = "bx_block_annotations/task_file_annotation_comments";
exports.taskFileAnnotationCommentsAPiMethod = "GET";
exports.taskFileAnnotationCommentsApiContentType = "application/json";

//task file annotation comments create api
exports.taskFileAnnotationCommentsCreateAPiEndPoint = "bx_block_annotations/task_file_annotation_comments";
exports.taskFileAnnotationCommentsCreateAPiMethod = "POST";
exports.taskFileAnnotationCommentsCreateApiContentType = "application/json";

//task file annotation comments show api
exports.taskFileAnnotationCommentsShowAPiEndPoint = "bx_block_annotations/task_file_annotation_comments";
exports.taskFileAnnotationCommentsShowAPiMethod = "GET";
exports.taskFileAnnotationCommentsShowApiContentType = "application/json";

//task file annotation comments update api
exports.taskFileAnnotationCommentsUpdateAPiEndPoint = "bx_block_annotations/task_file_annotation_comments";
exports.taskFileAnnotationCommentsUpdateAPiMethod = "PUT";
exports.taskFileAnnotationCommentsUpdateApiContentType = "application/json";

//task file annotation comments destroy api
exports.taskFileAnnotationCommentsDeleteAPiEndPoint = "bx_block_annotations/task_file_annotation_comments";
exports.taskFileAnnotationCommentsDeleteAPiMethod = "DEL";
exports.taskFileAnnotationCommentsDeleteApiContentType = "application/json";

//project duplication create api
exports.projectDuplicationCreateAPiEndPoint = "bx_block_project_portfolio/projects/project_duplication";
exports.projectDuplicationCreateAPiMethod = "POST";
exports.projectDuplicationCreateApiContentType = "application/json";

//project exports index api
exports.projectExportsAPiEndPoint = "bx_block_data_import_export_csv/project_exports";
exports.projectExportsAPiMethod = "GET";
exports.projectExportsApiContentType = "application/json";

//project notification settings index api
exports.projectNotificationSettingsAPiEndPoint = "bx_block_notification_settings/project_notifications";
exports.projectNotificationSettingsAPiMethod = "GET";
exports.projectNotificationSettingsApiContentType = "application/json";

//project notification settings create api
exports.projectNotificationSettingsCreateAPiEndPoint = "bx_block_notification_settings/project_notifications";
exports.projectNotificationSetttingsCreateAPiMethod = "POST";
exports.projectNotificationSettingsCreateApiContentType = "application/json";

//project notification settings update api
exports.projectNotificationSettingsUpdateAPiEndPoint = "bx_block_notification_settings/project_notifications";
exports.projectNotificationSetttingsUpdateAPiMethod = "PUT";
exports.projectNotificationSettingsUpdateApiContentType = "application/json";

//project notification settings show api
exports.projectNotificationSettingsShowAPiEndPoint = "bx_block_notification_settings/project_notifications";
exports.projectNotificationSetttingsShowAPiMethod = "GET";
exports.projectNotificationSettingsShowApiContentType = "application/json";

//asset category index api
exports.assetCategoryAPiEndPoint = "bx_block_categories_sub_categories/asset_categories";
exports.assetCategoryAPiMethod = "GET";
exports.assetCategoryApiContentType = "application/json";

//asset type index api
exports.assetTypeAPiEndPoint = "bx_block_categories_sub_categories/asset_types";
exports.assetTypeAPiMethod = "GET";
exports.assetTypeApiContentType = "application/json";

//template index api
exports.templateAPiEndPoint = "bx_block_custom_form/brand_templates";
exports.templateAPiMethod = "GET";
exports.templateApiContentType = "application/json";


//account index api
exports.accountAPiEndPoint = "account_block/accounts";
exports.accountAPiMethod = "GET";
exports.accountApiContentType = "application/json";

//account show api
exports.accountShowAPiEndPoint = "bx_block_project_portfolio/account_projects?project_id";
exports.accountShowAPiMethod = "GET";
exports.accountShowApiContentType = "application/json";

//account update api
exports.accountUpdateAPiEndPoint = "account_block/accounts";
exports.accountUpdateAPiMethod = "PUT";
exports.accountUpdateApiContentType = "application/json";

//account delete api
exports.accountDeleteAPiEndPoint = "account_block/accounts";
exports.accountDeleteAPiMethod = "DEL";
exports.accountDeleteApiContentType = "application/json";

//state_transitions create api
exports.state_transitionsAPiEndPoint = "bx_block_projecttasktracking/state_transitions/";
exports.state_transitionsAPiMethod = "PUT";
exports.state_transitionsApiContentType = "application/json";

//subtask create api
exports.subtaskCreateAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskCreateAPiMethod = "POST";
exports.subtaskCreateApiContentType = "application/json";

//subtask index api
exports.subtaskAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskAPiMethod = "GET";
exports.subtaskApiContentType = "application/json";

//subtask show api
exports.subtaskShowAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskShowAPiMethod = "GET";
exports.subtaskShowApiContentType = "application/json";

//subtask update api
exports.subtaskUpdateAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskUpdateAPiMethod = "PUT";
exports.subtaskUpdateApiContentType = "application/json";

//subtask delete api
exports.subtaskDeleteAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskDeleteAPiMethod = "DEL";
exports.subtaskDeleteApiContentType = "application/json";

//Blockers create api
exports.blockersCreateAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersCreateAPiMethod = "POST";
exports.blockersCreateApiContentType = "application/json";

//Blockers index api
exports.blockersAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersAPiMethod = "GET";
exports.blockersApiContentType = "application/json";

//Blockers show api
exports.blockersShowAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersShowAPiMethod = "GET";
exports.blockersShowApiContentType = "application/json";

//Blockers update api
exports.blockersUpdateAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersUpdateAPiMethod = "PUT";
exports.blockersUpdateApiContentType = "application/json";

//Blockers delete api
exports.blockersDeleteAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersDeleteAPiMethod = "DEL";
exports.blockersDeleteApiContentType = "application/json";

//task_activity_logs index api
exports.taskActivityLogAPiEndPoint = "bx_block_project_portfolio/task_activity_logs";
exports.taskActivityLogAPiMethod = "GET";
exports.taskActivityLogApiContentType = "application/json";

// Customizable Area Start
// Get Brand Listing  
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.brandListingAPiEndPoint = "bx_block_categories_sub_categories/brands/brand_project_listing?per_page=100";

exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectPortfolio";
exports.labelBodyText = "ProjectPortfolio Body";

// Brand Delete
exports.contentTypeApiDeleteBrand = "application/json";
exports.brandDeleteApiEndPoint = "bx_block_categories_sub_categories/brands/";
exports.deletionBrandApiMethod = "DELETE";

exports.btnExampleTitle = "CLICK ME";


// Get Approved Quote Api call config
exports.getQuoteApprovedContentType = "application/json";
exports.getQuoteApprovedApiMethodType = "GET";
exports.getQuoteApprovedApiEndPoint = "bx_block_project_portfolio/tasks?page=1&quotation_status_type=quote_approved";

// Get Open Quote Api 
exports.getQuoteOpenContentType = "application/json";
exports.getQuoteOpenApiMethodType = "GET";
exports.getQuoteOpenApiEndPoint = "bx_block_project_portfolio/tasks?page=1&quotation_status_type=draft";

// Get Pending Quote Api 
exports.getQuotePendingContentType = "application/json";
exports.getQuotePendingApiMethodType = "GET";
exports.getQuotePendingApiEndPoint = "bx_block_project_portfolio/tasks?page=1&quotation_status_type=quote_requested_or_pending";

exports.getTemplateFormFieldsContentType = "application/json";
exports.getTemplateFormFieldsMethodType = "GET";
exports.getTemplateFormFieldsEndPoint = "bx_block_custom_form/template_form_fields?template_id=";

exports.getTemplateFormConditionsContentType = "application/json";
exports.getTemplateFormConditionsMethodType = "GET";
exports.getTemplateFormConditionsEndPoint = "bx_block_custom_form/template_form_conditions?template_id=";

// Get Presigned url
exports.callToPreSignedURLContentType = "application/json",
exports.callToPreSignedURLMethodType = "POST",
exports.callToPreSignedURLApiEndPoint ="bx_block_upload_media/s3_uploads/get_presigned_url"


// Project Detail by brand
exports.callToProjectShowByBrandContentType = "application/json",
exports.callToProjectShowByBrandMethodType = "GET",
exports.callToProjectShowByBrandApiEndPoint ="bx_block_project_portfolio/projects?brand_id="

// task File uploading config
exports.taskBulkUploadContentType = "application/json",
exports.taskBulkUploadContentTypeMethodType = "POST",
exports.taskBulkUploadContentTypeApiEndPoint ="bx_block_bulk_uploading/task_bulk_uploads"

// Get Task file list
exports.getTaskFileGetContentType = "application/json",
exports.getTaskFileGetMethodType = "GET",
exports.getTaskFileGetApiEndPoint ="bx_block_upload_media/task_files?page=1&per_page=1000&task_id="

// Get Task file show config
exports.getTaskFileSingleContentType = "application/json",
exports.getTaskFileSingleMethodType = "GET",
exports.getTaskFileSingleApiEndPoint ="bx_block_upload_media/task_files/"


// Create file annotation
exports.taskFileAnnotationContentType = "application/json",
exports.taskFileAnnotationMethodType = "POST",
exports.taskFileAnnotationApiEndPoint ="bx_block_annotations/task_file_annotations"

// Get All Annotation in task file 
exports.getTaskFileAnnotationContentType = "application/json",
exports.getTaskFileAnnotationMethodType = "GET",
exports.getTaskFileAnnotationApiEndPoint ="bx_block_annotations/task_file_annotations?file_type=task_file&per_page=1000&task_file_id="
exports.getTaskVersionFileAnnotationApiEndPoint ="bx_block_annotations/task_file_annotations?file_type=task_file_version&per_page=1000&task_file_id="

//  Create Annotation Comment

exports.createAnnotationCommentContentType = "application/json",
exports.createAnnotationCommentMethodType = "POST",
exports.createAnnotationCommentApiEndPoint ="bx_block_annotations/task_file_annotation_comments"

//  Get All comments with coordinates
exports.getAllCommentsContentType = "application/json",
exports.getAllCommentMethodType = "GET",
exports.getAllCommentApiEndPoint ="bx_block_annotations/task_file_annotation_comments?per_page=2000"

// Get Specific Comment Annotation's
exports.getSpecificAnnotationCommentContentType = "application/json",
exports.getSpecificAnnotationCommentMethodType = "GET",
exports.getSpecificAnnotationCommentApiEndPoint ="bx_block_annotations/task_file_annotation_comments/"

// Delete Annotation Comment API's
exports.deleteAnnotationCommentContentType = "application/json",
exports.deleteAnnotationCommentMethodType = "DELETE",
exports.deleteAnnotationCommentApiEndPoint ="bx_block_annotations/task_file_annotation_comments/"

// Delete Annotation coordinates file api
exports.deleteAnnotationContentType = "application/json",
exports.deleteAnnotationMethodType = "DELETE",
exports.deleteAnnotationApiEndPoint ="bx_block_annotations/task_file_annotations/"

//project Active index api
exports.projectActiveAPiEndPoint = "bx_block_categories_sub_categories/brands/brand_project_listing?";
exports.projectActiveAPiMethod = "GET";
exports.projectActiveApiContentType = "application/json";

//project Achive index api
exports.projectAchiveAPiEndPoint = "bx_block_categories_sub_categories/brands/brand_project_listing?";
exports.projectAchiveAPiMethod = "GET";
exports.projectAchiveApiContentType = "application/json";

//project Delete index api
exports.projectDeleteAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectDeleteAPiMethod = "DELETE";
exports.projectDeleteApiContentType = "application/json";

//request quotation for project create api
exports.requestQuotationForProjectCreateAPiEndPoint = "bx_block_project_portfolio/projects/project_tasks_request_quote";
exports.requestQuotationForProjectCreateAPiMethod = "POST";
exports.requestQuotationForProjectCreateApiContentType = "application/json"; 

//Get different version for same asset
exports.getAllVesrionsOfAssetContentType = "application/json";
exports.getAllVesrionsOfAssetMethodType = "GET";
exports.getAllVesrionsOfAssetEndPoint = "bx_block_upload_media/task_file_versions?page=1&per_page=50&task_file_id=";

//Delete Task file
exports.taskFileDeleteAPiEndPoint = "bx_block_upload_media/task_files/";
exports.taskFileDeleteAPiMethod = "DELETE";
exports.taskFileDeleteApiContentType = "application/json"; 

exports.taskFileUpdateAPiEndPoint = "bx_block_upload_media/task_files/";
exports.taskFileUpdateAPiMethod = "PUT";
exports.taskFileUpdateApiContentType = "application/json"; 

// Customizable Area End