import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Breadcrumbs,
  AppBar,
  Tab,
  Tabs,
  Input,
  InputAdornment,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  DialogActions,
  TextField
} from "@material-ui/core";
// import Link from '@material-ui/core/Link';
import MuiListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import { Link } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation, Trans } from "react-i18next";
import ReactS3Uploader from "react-s3-uploader";
import * as SparkMD5 from 'spark-md5';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  classes: any;
  t?: any;
  i18n?: any;
}
interface S {
  anchorEl: any;
  anchorE2: any;
  tabValue: any;
  categoryList: any;
  categoryInformationHeader: any;
  categoryInformation: any;
  selectedCategoryObj: any;
  selectedFaqObj: any;
  selectedCategory: any;
  selectedCategoryId: any;
  faqList: any;
  showNewCategory: any;
  newCategoryName: string;
  selectedToEdit: string;
  openEditDialog: boolean;
  searchKeyword: string;
  keywordValue: string;
  listOfKeywords: any;
  contactId: any;
  contactEmail: any;
  contactPhoneNumber: any;
  contactUpdateFlag: any;
  loading: any;
  fileName: any;
  fileToRawBinary: any;
  resp: any;
  newCategoryNameGerman: any;
  tabValueLanguage: any;
  selectedCategoryGermanId: any;
  answerValue: any;
  anchorEl2: any;
  selectedCategoryObjGerman: any;
  categoryDialog: boolean;
  categoryFileData: any;
  faqSelected: any
  editFaq: any
  editFaqQuestion: any;
  editFaqAnswer: any
  imageUrl: any;
  isEdit: any;
  editMode : any;
  selectedCategoryGerman:any;
  selectedCategoryLogo:any;
}

interface SS {
  id: any;
}

const styles = {
  AppBarRoot: {
    background: "#FFFFFF",
    color: "#1a76b0",
    boxShadow: "none",
    marginLeft: "10px",
  },
  indicator: {
    backgroundColor: "#1a76b0",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
} as any;

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#1a76b0",
      color: "white",
      opacity: 1,
    },
    "&:hover": {
      backgroundColor: "#1a76b0",
      color: "white",
    },
    borderRadius: "4px",
    background: "white",
    height: "56px",
    marginBottom: "10px",
    color: "#5b6b96",
  } as const,
  selected: {},
})(MuiListItem);

const QuestionAndAnswerSchema = Yup.object().shape({
  question: Yup.string().required(''),
  answer: Yup.string().required(''),
});

class CustomerSupport extends BlockComponent<Props, S, SS> {
  createCategoryApiCallId: any;
  editCategoryApiCallId: any;
  updateCategoryApiCallId: any;
  getCategoriesApiCallId: any;
  deleteCategoryApiCallId: any;
  getFaqQuestionsAndAnswersApiCallId: any;
  filterFaqApiCallId: any;
  addQuestionAndAnswerApiCallId: any;
  updateQuestionAndAnswerApiCallId: any;
  deleteQuestionAndAnswerApiCallId: any;
  createSearchApiCallId: any;
  createFaqContactApiCallId: any;
  updateFaqContactApiCallId: any;
  getFaqContactApiCallId: any;
  getSignedURLForFileUploadId: any;
  getSignedURLForFileUploadId2: any;
  fileInput: any;
  callToBulkFileUpload: any;
  fileInput2: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    this.fileInput = React.createRef();
    this.fileInput2 = React.createRef();
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      anchorEl: null,
      anchorE2: null,
      tabValue: 0,
      categoryList: [],
      categoryInformation: "",
      categoryInformationHeader: "",
      selectedCategoryObj: null,
      selectedFaqObj: null,
      selectedCategory: "",
      selectedCategoryId: 0,
      faqList: [],
      showNewCategory: false,
      newCategoryName: "",
      selectedToEdit: "",
      openEditDialog: false,
      searchKeyword: "",
      keywordValue: "",
      listOfKeywords: [],
      contactId: 0,
      contactEmail: '',
      contactPhoneNumber: '',
      contactUpdateFlag: 'SAVE',
      loading: false,
      fileName: "",
      fileToRawBinary: {},
      resp: null,
      newCategoryNameGerman: '',
      tabValueLanguage: localStorage.getItem("i18nextLng") == "en" ? 0 : 1,
      selectedCategoryGermanId: 0,
      answerValue: '',
      anchorEl2: null,
      selectedCategoryObjGerman: null,
      categoryDialog: false,
      categoryFileData: null,
      faqSelected: null,
      editFaq: null,
      editFaqQuestion: null,
      editFaqAnswer: null,
      imageUrl: "",
      isEdit: false,
      editMode : false,
      selectedCategoryGerman:"",
      selectedCategoryLogo:""
    };
  }
  async componentDidMount() {
    await this.getCategories();
  }

  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoriesApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_categories?per_page=50");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getFaqContact();
    return true;
  };

  handleCategoryClick = (category: any) => {
    console.log(category);
    this.setState({ loading: true })
    if ((category?.attributes?.language == "English" && this.state.tabValueLanguage == 0) ||
      (category?.attributes?.language == "German" && this.state.tabValueLanguage == 1)) {
      this.setState({
        categoryInformation: category.attributes.information,
        categoryInformationHeader: category.attributes.information_header,
        selectedCategory: category.attributes.name,
        selectedCategoryId: category.id,
        selectedCategoryObj: category
      }, () => {
        this.getFaqQuestionAndAnswers();
      });
    } else if (category?.attributes?.language == "English" && this.state.tabValueLanguage == 1) {
      let data = this.state.categoryList.filter((res: any) => parseInt(category?.attributes?.german_faq_category?.data?.id) == parseInt(res?.id));
      this.setState({
        categoryInformation: data[0].attributes.information,
        categoryInformationHeader:
          data[0].attributes.information_header,
        selectedCategory: data[0].attributes.name,
        selectedCategoryId: data[0].id,
        selectedCategoryObj: data[0]
      }, () => {
        this.getFaqQuestionAndAnswers();
      });
    } else if (category?.attributes?.language == "German" && this.state.tabValueLanguage == 0) {
      let data = this.state.categoryList.filter((res: any) => parseInt(category.id) == parseInt(res?.attributes?.german_faq_category?.data?.id));
      this.setState({
        categoryInformation: data[0].attributes.information,
        categoryInformationHeader:
          data[0].attributes.information_header,
        selectedCategory: data[0].attributes.name,
        selectedCategoryId: data[0].id,
        selectedCategoryObj: data[0]
      }, () => {
        this.getFaqQuestionAndAnswers();
      });
    }
  }

  addCategoryClick = () => {
    const header = { "Content-Type": "application/json", token: localStorage.getItem("token") };
    const data = {
      name: this.state.newCategoryName, information: '', information_header: '', file_blob_signed_id: this.state.categoryFileData.data[0].signed_id,
      german_category: { name: this.state.newCategoryNameGerman, information: '', information_header: '' }
    };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFaqQuestionAndAnswers = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFaqQuestionsAndAnswersApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers?per_page=1000&faq_category_id=" + this.state.selectedCategoryId);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addQuestionAndAnswer = (values: any) => {
    console.log(this.state.selectedCategoryId);
    console.log(values);
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = {
      question: values.question,
      answer: this.state.answerValue,
      "faq_question_answer_files_attributes": [
        {
          "name": this.state.fileToRawBinary?.name,
          "file_blob_signed_id": this.state.resp?.data[0]?.signed_id,
          "file_type": this.state.fileToRawBinary?.type
        }
      ],

      faq_category_id: parseInt(this.state.selectedCategoryId)
    };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addQuestionAndAnswerApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateQuestionAndAnswer = () => {
    console.log("this.state.faq", this.state.editFaqQuestion, this.state.editFaqAnswer, this.state.editFaqAnswer + this.state.imageUrl);
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = { question: this.state.editFaqQuestion, answer: this.state.editFaqAnswer + " " + this.state.imageUrl, faq_category_id: parseInt(this.state.selectedCategoryId) };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateQuestionAndAnswerApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers/" + parseInt(this.state.selectedFaqObj?.id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteForQuestionAndAnswers = () => {
    if (this.state.selectedFaqObj?.id !== "") {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteQuestionAndAnswerApiCallId = requestMessage.messageId;
      const header = { token: localStorage.getItem("token") };
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_help_centre/faq_question_answers/${this.state.selectedFaqObj?.id}`);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  addFaqContact = (values: any) => {
    console.log(values);
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = { email: values.email, phone_number: values.phone_number };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createFaqContactApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_contacts");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateFaqContact = (values: any) => {
    console.log(values);
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = { email: values.email, phone_number: values.phone_number };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateFaqContactApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_contacts/" + parseInt(this.state.contactId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFaqContact = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFaqContactApiCallId = requestMessage.messageId;
    const header = { token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_contacts");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  newCategoryFlagHelper = () => {
    // this.setState((preState) => ({
    //   showNewCategory: !preState.showNewCategory,
    //   newCategoryName: '',
    //   newCategoryNameGerman: ''
    // }));
    this.setState({
      categoryDialog: true,
      newCategoryName: "",
      newCategoryNameGerman: ""
    })
  }

  handleNewCategoryChange = (event: any) => {
    if (event.target.name == "newCategoryName") {
      this.setState({
        newCategoryName: event.target.value
      });
    }
    if (event.target.name == "newCategoryNameGerman") {
      this.setState({
        newCategoryNameGerman: event.target.value
      });
    }
  };

  handleIconClick = (event: any, id: any, name: any, type: any, data: any) => {
    console.log(data);
    console.log(type)
    this.setState({
      anchorEl: event.currentTarget,
      selectedCategoryId: id,
      selectedCategory: name,
      selectedCategoryGerman:type.attributes.german_faq_category.data.attributes.name,
      selectedCategoryLogo:type.attributes.file_blob_signed_id_url,
      selectedToEdit: type,
      isEdit: true,
    });
  };

  handleIconClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleQuestionAndAnswersIconClick = (event: any, obj: any) => {
    this.setState({
      anchorE2: event.currentTarget,
      selectedFaqObj: obj,
      editMode : true
    });
  };

  handleQuestionAndAnswersIconClose = () => {
    this.setState({
      anchorE2: null
    });
  };

  handleEditClick = () => {
    this.setState({
      openEditDialog: true,
    });
  };

  handleEditName = (event: any) => {
    console.log(event.target.value);
    this.setState({ selectedCategory: event.target.value });
  };
  handleEditNameGerman = (event: any) => {
    console.log(event.target.value);
    this.setState({ selectedCategoryGerman: event.target.value });
  };

  handleEditButtonClick = () => {
    
      this.editCustomerCategory();
    
  };

  editCustomerCategory = () => {
    const httpBody = {
      data: {
        name: this.state.selectedCategory,
       file_blob_signed_id: this.state.categoryFileData.data[0].signed_id,
        german_category:{
          name : this.state.selectedCategoryGerman
        }
      },
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editCategoryApiCallId = requestMessage.messageId;
    const header = { "Content-Type": "application/json", token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_help_centre/faq_categories/${this.state.selectedCategoryId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateCustomerCategory = () => {
    const httpBody = {
      data: {
        information: this.state.selectedCategoryObj.attributes.information,
        information_header: this.state.selectedCategoryObj.attributes.information_header
      },
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCategoryApiCallId = requestMessage.messageId;
    const header = { "Content-Type": "application/json", token: localStorage.getItem("token") };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_help_centre/faq_categories/${this.state.selectedCategoryId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseEditDialog = () => {
    this.setState({
      openEditDialog: false,
      selectedToEdit: "",
      anchorEl: null,
    });
  };

  handleCategoryDelete = () => {
    if (this.state.selectedCategoryId !== "") {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteCategoryApiCallId = requestMessage.messageId;
      const header = { token: localStorage.getItem("token") };
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_help_centre/faq_categories/${this.state.selectedCategoryId}`);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  getFaqSearch = () => {

    if (this.state.searchKeyword != "") {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.filterFaqApiCallId = requestMessage.messageId;
      const header = { token: localStorage.getItem("token") };
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_question_answers?faq_search=" + this.state.searchKeyword);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.getFaqQuestionAndAnswers();
    }
  };

  addSearchKeywords = () => {

    console.log(this.state.keywordValue);
    this.setState({
      listOfKeywords: [...this.state.listOfKeywords, this.state.keywordValue]
    }, () => {
      this.setState({ keywordValue: '' });
    });
  }

  createSearchKeyword = (faq_id: any) => {
    const header = { token: localStorage.getItem("token"), 'Content-Type': 'application/json' };
    const data = { faq_question_answer_id: faq_id, keywords: this.state.listOfKeywords };
    const httpBody = { data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_help_centre/faq_search_keywords");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.createCategoryApiCallId) {
        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {

          this.newCategoryFlagHelper();
          toast.success("Added Category successfully");
          this.getCategories();
          this.setState({ categoryDialog: !this.state.categoryDialog })

        } else {
          toast.error("Category Saving failed");
        }
      } else if (apiRequestCallId === this.updateCategoryApiCallId) {
        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {
          // toast.success("Updated Category Details successfully");
        } else {
          // toast.error("Updated Category Details failed");
        }
      } else if (apiRequestCallId === this.editCategoryApiCallId) {
        if (responseJson?.data) {
          toast.success("Category updated successfully");
          this.getCategories();
        } else {
          toast.error("Category updation failed");
        }
        this.handleCloseEditDialog();
      } else if (apiRequestCallId === this.getCategoriesApiCallId) {
        try{
        if (responseJson.faq_categories) {
          if (responseJson.faq_categories.data) {
            if (responseJson.faq_categories.data.length !== 0) {
              this.setState({
                categoryList: responseJson.faq_categories.data,
              });
              responseJson.faq_categories.data.map((_data: any, idx: any) => {
                if (idx === 0) {
                  if ((_data?.attributes?.language == "English" && localStorage.getItem("i18nextLng") == "en") ||
                    (_data?.attributes?.language == "German" && localStorage.getItem("i18nextLng") == "de")) {
                    this.setState({
                      categoryInformation: _data.attributes.information,
                      categoryInformationHeader:
                        _data.attributes.information_header,
                      selectedCategory: _data.attributes.name,
                      selectedCategoryId: _data.id,
                      selectedCategoryObj: _data
                    });
                  } else if (_data?.attributes?.language == "English" && localStorage.getItem("i18nextLng") == "de") {
                    let data = responseJson.faq_categories.data.filter((res: any) => parseInt(_data?.attributes?.german_faq_category?.data?.id) == parseInt(res?.id));
                    this.setState({
                      categoryInformation: data[0].attributes.information,
                      categoryInformationHeader:
                        data[0].attributes.information_header,
                      selectedCategory: data[0].attributes.name,
                      selectedCategoryId: data[0].id,
                      selectedCategoryObj: data[0]
                    });
                  } else if (_data?.attributes?.language == "German" && localStorage.getItem("i18nextLng") == "en") {
                    if(responseJson.faq_categories && responseJson.faq_categories.length !== 0){
                      console.log(")(#$UOIHFJHKDHFKJDHKF")
                      console.log(responseJson.faq_categories)
                    let data = responseJson.faq_categories.filter((res: any) => parseInt(_data.id) == parseInt(res?.attributes?.german_faq_category?.data?.id));
                   
                    this.setState({
                      categoryInformation: data[0].attributes.information,
                      categoryInformationHeader:
                        data[0].attributes.information_header,
                      selectedCategory: data[0].attributes.name,
                      selectedCategoryId: data[0].id,
                      selectedCategoryObj: data[0]
                    });
                  }
                   } 
                  this.getFaqQuestionAndAnswers();
                }
              });
            }
          }
        }
      }catch(err:any){
        console.log(err)
      }
      } else if (apiRequestCallId === this.deleteCategoryApiCallId) {
        if (responseJson.message) {
          if (responseJson?.message?.toUpperCase() === "Record successfully deleted".toUpperCase()) {
            toast.success(responseJson?.message);
            this.setState({
              anchorEl: "",
              selectedCategoryId: "",
            });
            this.getCategories();
          } else {
            toast.error("Category deletion failed");
          }
        } else {
          toast.error("Category deletion failed");
        }
      } else if (apiRequestCallId === this.getFaqQuestionsAndAnswersApiCallId) {

        let apiData = responseJson.faq_question_answers;
        let tempArr = [] as any;
        if (responseJson?.faq_question_answers?.data) {
          apiData.data.map((_data: any) => {
            let data = _data;
            data.isEdit = false;
            tempArr.push(data)
            data = {};
          })
          responseJson.faq_question_answers.data.map((_data: any, index1: any) => {
            let splitData = _data.attributes.answer.split(" ");
            console.log("testttttttttttt", splitData)
            splitData.map((a: any, index: any) => {
              if (a.includes("http://") || a.includes("https://")) {
                splitData[index] = `<a href=${a}>` + a + "</a>"
              }
            })
            responseJson.faq_question_answers.data[index1].attributes.answer = splitData.join(" ");
          })
          this.setState({
            loading: false,
            faqList: responseJson?.faq_question_answers?.data
          });
        } else {
          toast.error("Fetching faq list having issue");
        }
      } else if (apiRequestCallId === this.filterFaqApiCallId) {

        console.log(responseJson.faq_question_answers);
        if (responseJson?.faq_question_answers?.data) {
          this.setState({
            faqList: responseJson?.faq_question_answers?.data
          });
        } else {
          toast.error("Fetching faq list having issue");
        }
      } else if (apiRequestCallId === this.addQuestionAndAnswerApiCallId) {
        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {
          toast.success("Added faq successfully");
          this.setState({
            answerValue: ""
          });
          if (this.state.listOfKeywords.length !== 0) {
            this.createSearchKeyword(responseJson?.data?.id);
          }
          this.getFaqQuestionAndAnswers();
        } else {
          toast.error("Saving faq having issue");
        }
      } else if (apiRequestCallId === this.updateQuestionAndAnswerApiCallId) {

        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {
          toast.success("Updated faq successfully");
          this.setState({ editFaqQuestion: "", editFaqAnswer: '', editFaq: false, anchorE2: null })
          this.getFaqQuestionAndAnswers();
        } else {
          toast.error("Updating faq having issue");
        }
      } else if (apiRequestCallId === this.deleteQuestionAndAnswerApiCallId) {

        if (responseJson.message) {
          if (responseJson?.message?.toUpperCase() === "Record successfully deleted".toUpperCase()) {
            toast.success("Record successfully deleted");
            this.setState({
              anchorE2: "",
              selectedFaqObj: null
            });
            this.getFaqQuestionAndAnswers();
          } else {
            toast.error("Deletion failed");
          }
        } else {
          toast.error("Deletion failed");
        }
      } else if (apiRequestCallId === this.createSearchApiCallId) {

        console.log(responseJson);
        if (responseJson?.data) {
          toast.success("Added Search Keyword successfully");
        } else {
          toast.error("Having issue while saving search keyword");
        }
        this.setState({
          keywordValue: "",
          listOfKeywords: []
        });
      } else if (apiRequestCallId === this.createFaqContactApiCallId) {

        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {
          toast.success("Added Contact Successfully");
        } else {
          if (responseJson?.errors?.[0]?.email)
            toast.error(responseJson?.errors?.[0]?.email);
          else
            toast.error("Contact Info saving failed");
        }
      } else if (apiRequestCallId === this.updateFaqContactApiCallId) {

        console.log(responseJson);
        if (responseJson?.data?.id != undefined && responseJson?.data?.id != null && responseJson?.data?.id != "") {
          toast.success("Updated Contact Successfully");
        } else {
          if (responseJson?.errors?.[0]?.email)
            toast.error(responseJson?.errors?.[0]?.email);
          else
            toast.error("Contact Info updating failed");
        }
        this.getFaqContact();
      } else if (apiRequestCallId === this.getFaqContactApiCallId) {

        console.log(responseJson?.faq_contacts?.data?.[0]);
        if (responseJson?.faq_contacts?.data?.length > 0) {
          this.setState({
            contactId: responseJson?.faq_contacts?.data?.[0].id,
            contactEmail: responseJson?.faq_contacts?.data?.[0]?.attributes.email,
            contactPhoneNumber: responseJson?.faq_contacts?.data?.[0]?.attributes.phone_number,
            contactUpdateFlag: 'UPDATE'
          });
        }
      }
      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          if (apiRequestCallId != null) {
            /// search live
            if (apiRequestCallId && responseJson) {
              localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
              this.setState({ resp: responseJson }, () => {
                this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
              })
            }
          }
        } else if (responseJson.errors) {
          toast.error(errorResponse.errors[0].projects);
        } else if (responseJson.error) {
          toast.error(errorResponse.error);
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId2) {
        if (this.state.categoryDialog || this.state.openEditDialog ) {
          console.log("category Dialog ------------------")
          console.log(this.state.resp)
          this.setState({ categoryFileData: this.state.resp }, () => {
            this.setState({ loading: false })
            toast.success("file uploaded successfully")
          })

        } else {
          this.taskFileUploadBulk(this.state.resp)
        }
      }
      if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToBulkFileUpload) {
        var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          if (apiRequestCallId != null) {
            /// search live
            if (apiRequestCallId && responseJson) {
              if (responseJson?.data[0]?.attributes?.file_blob_signed_id_url) {
                if(this.state.editFaq){
                  this.setState({
                    // answerValue: this.state.answerValue + " " + responseJson?.data[0]?.attributes?.file_blob_signed_id_url + " ",
                    editFaqAnswer : this.state.editFaqAnswer +  " " + responseJson?.data[0]?.attributes?.file_blob_signed_id_url + " ",
                    fileName: "",
                    fileToRawBinary: {}
                  })
                }
                else{
                  this.setState({
                    answerValue: this.state.answerValue + " " + responseJson?.data[0]?.attributes?.file_blob_signed_id_url + " ",
                    // editFaqAnswer : this.state.editFaqAnswer +  " " + responseJson?.data[0]?.attributes?.file_blob_signed_id_url + " ",
                    fileName: "",
                    fileToRawBinary: {},
                  });
  
                }
                this.fileInput = React.createRef();
              }
              this.setState({ loading: false })
              toast.success("file uploaded successfully")
            }
          }
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
  }
  handleChange = (event: any, value: any) => {
    this.setState({ tabValue: value });
  };

  handleChangeLanguage = (event: any, value: any) => {
    let _data;
    if (this.state.tabValueLanguage == 0) {
      let data = this.state.categoryList.findIndex((a: any) => parseInt(a.id) == parseInt(this.state.selectedCategoryId))
      _data = this.state.categoryList.filter((res: any) =>
        parseInt(this.state.categoryList[data]?.attributes?.german_faq_category?.data?.id) == parseInt(res?.id));
    }
    if (this.state.tabValueLanguage == 1) {
      _data = this.state.categoryList.filter((res: any) =>
        parseInt(this.state.selectedCategoryId) == parseInt(res?.attributes?.german_faq_category?.data?.id));
    }
    if (_data.length > 0) {
      this.setState({
        categoryInformation: _data[0].attributes.information,
        categoryInformationHeader:
          _data[0].attributes.information_header,
        selectedCategory: _data[0].attributes.name,
        selectedCategoryId: _data[0].id,
        selectedCategoryObj: _data[0],
        tabValueLanguage: value,
        loading: true
      }, () => {
        this.getFaqQuestionAndAnswers();
      });
    } else {
      toast.error("There is no similar category for selected language.");
    }
  };

  getSignedUrl = (file: any) => {
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    });
  }

  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  inputFileChanged = (e: any) => {
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
  };
  inputFileChanged1 = (e: any) => {
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
  };

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  taskFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    var data = {
      data: {
        files_attributes: [
          {
            name: this.state.fileName,
            file_blob_signed_id: responseJson.data[0]?.signed_id,
            file_type: this.state.fileToRawBinary?.type
          }
        ],
        faq_category_id: this.state.selectedCategoryId
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_help_centre/faq_question_answer_files"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleQuestionIconClick = (event: any, faqSelected: any) => {
    this.setState({ faqSelected: faqSelected?.id, anchorE2: event.currentTarget })
  }
  handleQuestionIconClose = () => {
    this.setState({ anchorEl2: null })
  }
  handleQuestionEdit = (e: any) => {
    console.log(this.state.selectedFaqObj)
    let index1 = this.state.selectedFaqObj?.attributes?.answer.indexOf(">");
    let index2 = this.state.selectedFaqObj?.attributes?.answer.lastIndexOf("<");
    let index3 = this.state.selectedFaqObj?.attributes?.answer.indexOf("<");
    let ans,url;
  if(index1>= 0 && index2 >= 0 ){
    url = this.state.selectedFaqObj?.attributes?.answer.slice(index1 + 1, index2)
  }
  else {
   url = "" 
  }

    if(index3 >= 0){
      ans = this.state.selectedFaqObj?.attributes?.answer.substr(0, index3)
    }
   else {
    ans = this.state.selectedFaqObj?.attributes?.answer 
   }

    console.log("urls1", url, ans);
    // var reg =  RegExp("/<[a][(.+?)]>(.+?)</[a]>/g")

    // url = reg.exec(url)

    //   var result = url.match(`/<[tag][^>]*>(.+?)</[tag]>/g`).map(function(val :any){
    //     return val.replace(/<\/?b>/g,'');
    //  });
    console.log("urls2", url);


    this.setState({ imageUrl: url })
    // splitData.map((a: any, index: any) => {
    //   if (a.includes("http://") || a.includes("https://")) {
    //     
    //     splitData.splice(index, 1);

    //   }
    // })
    // let ans = splitData.join(" ")
    this.setState({
      editFaq: true,
      editFaqQuestion: this.state.selectedFaqObj?.attributes?.question,
      editFaqAnswer: ans
    })
    // let faqData = this.state.faqList;
    // faqData[idx].isEdit = true;
    // this.setState({ faqList: faqData })
  }

  handleCategoryDialog = () => {
    this.setState({ categoryDialog: false })
  }
  handleEnglishNameChange = (event: any) => {
    this.setState({
      newCategoryName: event.target.value
    })
  }
  handleGermanNameChange = (event: any) => {
    this.setState({
      newCategoryNameGerman: event.target.value
    })
  }

  handleEditFAQDialog = () => {
     this.setState({ editFaq: false })
  }
  handleFaqQuestionChange = (event: any) => {
    this.setState({
      editFaqQuestion: event.target.value
    })
  }
  handleFaqAnswerChange = (event: any) => {
    this.setState({
      editFaqAnswer: event.target.value
    })
  }


  render() {
    const { t } = this.props;
    return (
      <Grid
        container
        style={{ paddingLeft: "10px", width: "100%" }}
        spacing={2}
      >
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "10px" }}>
            <Link
              to="/AdminConsole"
              style={{
                color: "#1a76b0",
                fontSize: "12px",
                textDecoration: "none",
                textTransform: "uppercase",
                fontFamily: "Work Sans",
              }}
            >
              {t("AdminConsole.dashboard")}
            </Link>

            <Typography
              variant="body2"
              style={{
                fontSize: "12px",
                color: "#1a76b0",
                textTransform: "uppercase",
                fontFamily: "Work Sans",
              }}
            >
              {t("AdminConsole.customerSupport")}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "10px" }}>
          <Typography
            variant="body2"
            style={{
              fontFamily: "Work Sans",
              color: "#1a76b0",
              textTransform: "uppercase",
              opacity: 1,
              fontSize: "20px",
            }}
          >
            {t("AdminConsole.customerSupport")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppBar position="static" className={this.props.classes.AppBarRoot}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleChange}
              classes={{
                indicator: this.props.classes.indicator,
              }}
            >
              <Tab
                label={
                  <span
                    style={{
                      color: "#1a76b0",
                      fontFamily: "Work Sans",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("AdminConsole.faqs")}
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    style={{
                      color: "#1a76b0",
                      fontFamily: "Work Sans",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("AdminConsole.contactInformation")}
                  </span>
                }
              />
            </Tabs>
          </AppBar>
          {this.state.tabValue === 0 ? (
            <Grid container style={{ marginTop: "20px", marginLeft: "10px" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Input
                    style={{
                      width: "395px",
                      height: "40px",
                      border: "1px solid #1A1A1A",
                      borderRadius: "4px",
                      opacity: 0.4,
                    }}
                    disableUnderline={true}
                    type="text"
                    placeholder="ask a question"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    value={this.state.searchKeyword}
                    onChange={(event) => this.setState({ searchKeyword: event.target.value })}
                  />
                  <Button
                    style={{
                      backgroundColor: "#173e59",
                      border: "1px solid #173e59",
                      width: "134px",
                      height: "40px",
                      marginLeft: "17px",
                    }}
                    variant="contained"
                    onClick={this.getFaqSearch}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "13px",
                        fontFamily: "Work Sans",
                        opacity: 1,
                        textTransform: "uppercase",
                      }}
                    >
                      Search
                    </Typography>
                  </Button>
                  <Tabs
                    value={this.state.tabValueLanguage}
                    onChange={this.handleChangeLanguage}
                    style={{ marginLeft: "25px" }}
                    classes={{
                      indicator: this.props.classes.indicator,
                    }}
                  >
                    <Tab
                      label={
                        <span
                          style={{
                            color: "#1a76b0",
                            fontFamily: "Work Sans",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          English
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          style={{
                            color: "#1a76b0",
                            fontFamily: "Work Sans",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          German
                        </span>
                      }
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "Work Sans",
                    color: "#0E0E0E",
                    opacity: 1,
                    textTransform: "capitalize",
                  }}
                >
                  {t("AdminConsole.categories")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={3}>
                    <List>
                      {this.state.categoryList.length !== 0
                        ? this.state.categoryList.map((_data: any) => {
                          if ((_data.attributes.language == "English" && this.state.tabValueLanguage == 0) ||
                            (_data.attributes.language == "German" && this.state.tabValueLanguage == 1)) {
                            return (
                              <ListItem
                                button
                                key={_data.id}
                                selected={
                                  (this.state.selectedCategory ===
                                    _data.attributes.name) && this.state.searchKeyword == ""
                                    ? true
                                    : false
                                }
                                onClick={() => this.handleCategoryClick(_data)}
                                style={{ border: '1px solid lightgrey' }}
                              >
                                <ListItemIcon>
                                  <FiberManualRecordIcon
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      color: "#9EABB5",
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={_data.attributes.name} />
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="comments"
                                    onClick={(e) =>
                                      this.handleIconClick(
                                        e,
                                        _data.id,
                                        _data.attributes.name,
                                        _data,
                                        "Customer Category"
                                      )
                                    }
                                  >
                                    <MoreVertIcon style={{}} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          }
                        })
                        : ""}
                    </List>
                    {!this.state.showNewCategory ? (
                      <Button
                        variant="outlined"
                        style={{ border: "1px dashed #1A1A1A", width: '99%', padding: '5%' }}
                        onClick={() => this.newCategoryFlagHelper()}
                      >
                        <AddIcon
                          style={{
                            color: "#0E0E0E",
                            marginRight: "5px",
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: "#0E0E0E",
                            textTransform: "uppercase",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          NEW CATEGORY
                        </Typography>
                      </Button>
                    ) : (
                      <Card>
                        <CardContent>
                          <Input
                            type="text"
                            name={'newCategoryName'}
                            placeholder="Category Name in Emglish"
                            disableUnderline={true}
                            onChange={this.handleNewCategoryChange}
                            value={this.state.newCategoryName}
                          />
                          <Input
                            type="text"
                            name={'newCategoryNameGerman'}
                            style={{ paddingTop: "20px" }}
                            placeholder="Category Name in German"
                            disableUnderline={true}
                            onChange={this.handleNewCategoryChange}
                            value={this.state.newCategoryNameGerman}
                          />
                        </CardContent>
                        <CardActions
                          disableSpacing
                          style={{ float: "right" }}
                        >
                          <Button
                            size="small"
                            onClick={this.newCategoryFlagHelper}
                          >
                            {t("AdminConsole.cancel")}
                          </Button>
                          <Button
                            size="small"
                            onClick={this.addCategoryClick}
                            disabled={this.state.newCategoryName !== "" && this.state.newCategoryNameGerman !== "" ? false : true}
                          >
                            {t("AdminConsole.save")}
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <ArrowForwardIosIcon style={{ marginTop: "18px" }} />
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container>
                      <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            color: "#9EABB5",
                          }}
                        >
                          {t("AdminConsole.categoryInformation")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Card style={{ height: "auto", padding: "20px" }}>
                          <CardContent style={{ paddingTop: "0px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Input
                                  placeholder="Information Header"
                                  disableUnderline={true}
                                  value={this.state?.selectedCategoryObj?.attributes?.information_header}
                                  onChange={(event) => {
                                    const updateCategoryList = this.state.categoryList.map((t: any) => {
                                      let category = t;
                                      if (category.id === this.state.selectedCategoryObj?.id) {
                                        category = {
                                          ...this.state.selectedCategoryObj,
                                          attributes: {
                                            ...this.state.selectedCategoryObj.attributes,
                                            information_header: event.target.value,
                                          }
                                        };
                                      }
                                      return category;
                                    });
                                    this.setState({
                                      categoryList: updateCategoryList,
                                      selectedCategoryObj: {
                                        ...this.state.selectedCategoryObj,
                                        attributes: {
                                          ...this.state.selectedCategoryObj.attributes,
                                          information_header: event.target.value,
                                        }
                                      }
                                    }, () => {
                                    });
                                  }}
                                  onBlur={this.updateCustomerCategory}
                                  style={{
                                    fontFamily: "Work Sans",
                                    fontSize: "13px",
                                    color: "#1A1A1A",
                                    opacity: 1,
                                    letterSpacing: "0px",
                                    width: '100%'
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  border: "1px solid #9EABB5",
                                  opacity: 0.4,
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                placeholder="Information"
                                disableUnderline={true}
                                value={this.state?.selectedCategoryObj?.attributes?.information}
                                onChange={(event) => {
                                  const updateCategoryList = this.state.categoryList.map((t: any) => {
                                    let category = t;
                                    if (category.id === this.state.selectedCategoryObj?.id) {
                                      category = {
                                        ...this.state.selectedCategoryObj,
                                        attributes: {
                                          ...this.state.selectedCategoryObj.attributes,
                                          information: event.target.value,
                                        }
                                      };
                                    }
                                    return category;
                                  });
                                  this.setState({
                                    categoryList: updateCategoryList,
                                    selectedCategoryObj: {
                                      ...this.state.selectedCategoryObj,
                                      attributes: {
                                        ...this.state.selectedCategoryObj.attributes,
                                        information: event.target.value,
                                      }
                                    }
                                  }, () => {
                                  });
                                }}
                                onBlur={this.updateCustomerCategory}
                                style={{
                                  fontFamily: "Work Sans",
                                  fontSize: "13px",
                                  color: "#1A1A1A",
                                  opacity: 1,
                                  letterSpacing: "0px",
                                  width: '100%'
                                }}
                              />
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "35px" }}>
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            color: "#9EABB5",
                          }}
                        >
                          {t("AdminConsole.questions")}
                        </Typography>
                      </Grid>
                      {this.state.faqList.length !== 0 ? this.state.faqList.map((_data: any, idx: any) =>
                      (
                        <Grid item xs={12} key={_data.id} style={{ marginBottom: '10px' }}>
                          <Card style={{ height: "auto", padding: "20px" }}>
                            <CardContent style={{ paddingTop: "0px" }}>
                              <Grid container>
                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                  <IconButton aria-label="delete" size="small"
                                    onClick={(e) =>
                                      this.handleQuestionAndAnswersIconClick(
                                        e, _data

                                      )
                                    }
                                  >
                                    <MoreVertIcon fontSize="inherit" />
                                  </IconButton>

                                </Grid>
                                <Grid item xs={12}>
                                  {/* <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Work Sans",
                                      fontSize: "13px",
                                      color: "#1A1A1A",
                                      opacity: 1,
                                      letterSpacing: "0px",
                                    }}
                                  >
                                    {_data.attributes.question}
                                  </Typography> */}
                                  {_data.isEdit ?

                                    <>
                                      <Input
                                        disableUnderline={true}
                                        value={_data?.attributes?.question}
                                        onChange={(event) => {
                                          let updateFaqList = [...this.state.faqList];
                                          updateFaqList = updateFaqList.map((t: any) => {
                                            if (t?.id == _data?.id) {
                                              t = {
                                                ...t,
                                                attributes: {
                                                  ...t.attributes,
                                                  question: event.target.value
                                                }
                                              }
                                              this.setState({
                                                selectedFaqObj: { ...t }
                                              })
                                            }
                                            return t;
                                          });
                                          this.setState({
                                            faqList: updateFaqList
                                          }, () => {
                                          });
                                        }}
                                        onBlur={this.updateQuestionAndAnswer}
                                        style={{
                                          fontFamily: "Work Sans",
                                          fontSize: "13px",
                                          color: "#1A1A1A",
                                          opacity: 1,
                                          letterSpacing: "0px",
                                          width: '100%'
                                        }}
                                      />
                                    </>
                                    :
                                    <>
                                      <Typography variant='body2'>
                                        {_data.attributes.question}
                                      </Typography>

                                    </>
                                  }
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: "1px solid #9EABB5",
                                    opacity: 0.4,
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {/* <Typography
                                  variant="body2"
                                  style={{
                                    color: "#3F4A50",
                                    fontFamily: "Work Sans",
                                    fontSize: "12px",
                                    opacity: 1,
                                    letterSpacing: "0px",
                                  }}
                                >
                                  {_data.attributes.answer}
                                </Typography> */}
                                {_data.isEdit ?
                                  <>
                                    <Input
                                      disableUnderline={true}
                                      value={_data?.attributes?.answer}
                                      onChange={(event) => {
                                        let updateFaqList = [...this.state.faqList];
                                        updateFaqList = updateFaqList.map((t: any) => {
                                          if (t?.id == _data?.id) {
                                            t = {
                                              ...t,
                                              attributes: {
                                                ...t.attributes,
                                                answer: event.target.value
                                              }
                                            }
                                            this.setState({
                                              selectedFaqObj: { ...t }
                                            })
                                          }
                                          return t;
                                        });
                                        this.setState({
                                          faqList: updateFaqList
                                        }, () => {
                                        });
                                      }}
                                      onBlur={this.updateQuestionAndAnswer}
                                      style={{
                                        fontFamily: "Work Sans",
                                        fontSize: "13px",
                                        color: "#1A1A1A",
                                        opacity: 1,
                                        letterSpacing: "0px",
                                        width: '100%'
                                      }}
                                    />
                                  </>
                                  :
                                  <>
                                    <Typography
                                      variant="body2"
                                      dangerouslySetInnerHTML={{
                                        __html: _data.attributes.answer
                                        // +
                                        // _data.attributes.faq_question_answer_files?.data.length > 0 ? _data.attributes.faq_question_answer_files?.data[0]?.attributes?.file_blob_signed_id_url : null  

                                      }}
                                    >

                                    </Typography>
                                    {/* <Link 
                                      to={_data.attributes.faq_question_answer_files?.data.length > 0 ? _data.attributes.faq_question_answer_files?.data[0]?.attributes?.file_blob_signed_id_url : null}>
                                        {
                                           _data.attributes.faq_question_answer_files?.data.length > 0 ? _data.attributes.faq_question_answer_files?.data[0]?.attributes?.file_blob_signed_id_url : null} </Link>
                                     */}
                                  </>
                                }
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      )) : (<></>)
                      }
                      <Grid item xs={12} style={{ marginTop: "15px" }}>
                        <Card style={{ height: "auto", padding: "20px" }}>
                          <CardContent style={{ paddingTop: "0px" }}>
                            <Formik
                              initialValues={{ question: '', answer: '', keyword: '' }}
                              enableReinitialize
                              validateOnBlur={false}
                              validationSchema={Yup.object().shape({
                                question: Yup.string()
                                  .required(""),
                                answer: Yup.string()
                                  .required("")
                              })}
                              onSubmit={(values, { resetForm }) => {
                                this.addQuestionAndAnswer(values);
                                resetForm({ values: { question: '', answer: '', keyword: '' } })
                              }}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                isValid,
                                setFieldValue,
                                handleBlur,
                                setFieldTouched,
                                isSubmitting,
                                handleSubmit,
                                /* and other goodies */
                              }) => {
                                return (
                                  <Form translate="yes" onSubmit={handleSubmit}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Field
                                          name="question"
                                          type="text"
                                          placeholder={t("AdminConsole.addNewQuestion")}
                                          style={{ border: 'none', outline: 'none', padding: '1%', width: '100%' }}
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.question && errors.question ? (
                                          <div className="error">{errors.question}</div>
                                        ) : null}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          border: "1px solid #9EABB5",
                                          opacity: 0.4,
                                          marginTop: "10px",
                                          marginBottom: "15px",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Field
                                          name="answer"
                                          type="text"
                                          placeholder={t("AdminConsole.writeAnswerHere")}
                                          style={{ border: 'none', outline: 'none', padding: '1%', width: '100%' }}
                                          value={this.state.answerValue}
                                          onChange={(event: any) => this.setState({ answerValue: event.target.value })}
                                        />
                                        <Button
                                          variant="outlined"
                                          onClick={(event: any) => {
                                            event.target.value = null
                                            this.fileInput.click()
                                          }}
                                        >
                                          <ReactS3Uploader
                                            type="file"
                                            id="upload"
                                            name="pic"
                                            getSignedUrl={this.getSignedUrl}
                                            uploadRequestHeaders={{
                                              'x-amz-acl': 'public-read'
                                            }}
                                            s3path="/uploads/"
                                            contentDisposition="auto"
                                            accept="**"
                                            capture={true}
                                            inputRef={cmp => this.fileInput = cmp}
                                            onChange={(event: any) =>
                                              this.inputFileChanged(event)
                                            }
                                            style={{
                                              visibility: "hidden",
                                              position: "absolute",
                                              alignContent: "center", backgroundColor: 'white',
                                              color: '#419bf9',
                                              width: "280px", padding: 20,
                                              fontSize: 16, fontWeight: 'bold',
                                              borderWidth: 1,
                                              borderColor: '#419bf9',
                                              borderRadius: 10,
                                              borderStyle: 'dashed',
                                              fontVariant: "initial",
                                              textTransform: 'none',
                                              marginBottom: "20px"
                                            }}
                                          />
                                          Upload
                                        </Button>
                                      </div>
                                      {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                      {touched.answer && errors.answer ? (
                                        <div className="error">{errors.answer}</div>
                                      ) : null}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        border: "1px solid #9EABB5",
                                        opacity: 0.4,
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                      }}
                                    />
                                    <Grid item xs={12}>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Field
                                          name="keyword"
                                          type="text"
                                          placeholder={t("AdminConsole.provideSearchKeyword")}
                                          style={{ border: 'none', outline: 'none', padding: '1%', width: '70%' }}
                                          value={this.state.keywordValue}
                                          onChange={(event: any) => this.setState({ keywordValue: event.target.value })}
                                        />
                                        <Button variant="outlined" onClick={() => this.addSearchKeywords()}>{t("AdminConsole.addKeywords")}</Button>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        border: "1px solid #9EABB5",
                                        opacity: 0.4,
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                      }}
                                    />
                                    {this.state.listOfKeywords.length > 0 && this.state.listOfKeywords.map((_data: any, index: number) => (
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="body2"
                                          style={{
                                            color: "#3F4A50",
                                            fontFamily: "Work Sans",
                                            fontSize: "12px",
                                            opacity: 1,
                                            letterSpacing: "0px",
                                          }}
                                        >
                                          {_data}
                                        </Typography>
                                      </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                      <Button
                                        variant="outlined"
                                        style={{ border: "1px dashed #1A1A1A", marginTop: '10px' }}
                                        type="submit"
                                      >
                                        {/* <AddIcon
                                          style={{
                                            color: "#0E0E0E",
                                            marginRight: "5px",
                                          }}
                                        /> */}
                                        <Typography
                                          variant="body2"
                                          style={{
                                            color: "#0E0E0E",
                                            textTransform: "uppercase",
                                            fontFamily: "Inter",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {t("AdminConsole.submit")}
                                        </Typography>
                                      </Button>
                                    </Grid>
                                  </Form>
                                )
                              }}
                            </Formik>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "40px", marginLeft: "170px" }}
              >
                <Formik
                  initialValues={{ email: this.state.contactEmail, phone_number: this.state.contactPhoneNumber }}
                  enableReinitialize
                  validateOnBlur={false}
                  validationSchema={
                    Yup.object().shape({
                      email: Yup.string()
                        .required('Email is required'),
                      phone_number: Yup
                        .string()
                        .required('Phone number is required')
                    })}
                  onSubmit={(values) => {
                    if (this.state.contactUpdateFlag == 'SAVE')
                      this.addFaqContact(values);
                    else
                      this.updateFaqContact(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    isValid,
                    setFieldValue,
                    handleBlur,
                    setFieldTouched,
                    isSubmitting,
                    handleSubmit,
                    /* and other goodies */
                  }) => {
                    return (
                      <Form translate="yes" onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Work Sans",
                              color: "#1a76b0",
                              letterSpacing: "0px",
                              opacity: 1,
                              marginBottom: "8px",
                            }}
                          >
                            {t("AdminConsole.customerSupportEmail")}
                          </Typography>
                          <Field
                            name="email"
                            type="email"
                            placeholder='Support@email.com'
                            style={{
                              border: "1px solid #e8edf4",
                              width: "320px",
                              height: "48px",
                              paddingLeft: "8px",
                              borderRadius: "2px",
                            }}
                          />
                          {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                          {touched.email && errors.email ? (
                            <div className="error">{errors.email}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Work Sans",
                              color: "#1a76b0",
                              letterSpacing: "0px",
                              opacity: 1,
                              marginBottom: "8px",
                            }}
                          >
                            {t("AdminConsole.customerSupportPhoneNumber")}
                          </Typography>
                          <Field
                            name="phone_number"
                            type="number"
                            placeholder='9999999999'
                            style={{
                              border: "1px solid #e8edf4",
                              width: "320px",
                              height: "48px",
                              paddingLeft: "8px",
                              borderRadius: "2px",
                            }}
                          />
                          {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                          {touched.phone_number && errors.phone_number ? (
                            <div className="error">{errors.phone_number}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <Button
                            style={{
                              border: "1px solid #173e59",
                              background: "#173e59",
                              width: "332px",
                              height: "48px",
                            }}
                            variant="outlined"
                            type="submit"
                          >
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "16px",
                                fontFamily: "Work Sans",
                                color: "#FFFFFF",
                                textTransform: "uppercase",
                                opacity: 1,
                                letterSpacing: "0px",
                              }}
                            >
                              Save Changes
                            </Typography>
                          </Button>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Grid>
            </>
          )}
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleIconClose}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleEditClick}
          >
            Edit
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleCategoryDelete}
          >
            {t("AdminConsole.delete")}
          </MenuItem>
        </Menu>
        <Menu
          id="simple-menu-one"
          anchorEl={this.state.anchorE2}
          open={Boolean(this.state.anchorE2)}
          onClose={this.handleQuestionAndAnswersIconClose}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleQuestionEdit}
          >
            {t("AdminConsole.edit")}
          </MenuItem>

          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
            onClick={this.handleDeleteForQuestionAndAnswers}
          >
            {t("AdminConsole.delete")}
          </MenuItem>
        </Menu>
        {/* Edit dialog starts */}
        <Dialog
          open={this.state.openEditDialog}
          onClose={this.handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              fontFamily: "Work Sans !important",
              fontSize: "12px",
              fontWeight: 500,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0px",
              color: "#1a76b0",
              opacity: 1,
              textTransform: "uppercase",
            }}
            id="alert-dialog-title"
          >
            {/* {`Edit ${this.state.selectedToEdit}`} */}
            Edit Category
          </DialogTitle>
          <DialogContent
            style={{
              width: "600px",
              height: "auto",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="input-with-icon-adornment"
                    variant="outlined"
                    placeholder=""
                    fullWidth
                    value={this.state.selectedCategory}
                    onChange={this.handleEditName}
                   
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="input-with-icon-adornment"
                   variant="outlined"
                    fullWidth
                    placeholder=""
                    value={this.state.selectedCategoryGerman}
                    onChange={this.handleEditNameGerman}
                    // style={{
                    //   width: "449px",
                    //   height: "40px",
                    //   border: "1px solid #9EABB5",
                    //   borderRadius: "2px",
                    //   opacity: 1,
                    //   paddingLeft: "15px",
                    // }}
                  />
                </Grid>
                <Grid item xs={12}>
                     <Button
                  variant="outlined"
                  onClick={(event: any) => {
                    event.target.value = null
                    this.fileInput2.click()
                  }}
                >
                  <ReactS3Uploader
                    type="file"
                    id="upload"
                    name="pic"
                    getSignedUrl={this.getSignedUrl}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'public-read'
                    }}
                    s3path="/uploads/"
                    contentDisposition="auto"
                    accept="**"
                    capture={true}
                    inputRef={cmp => this.fileInput2 = cmp}
                    onChange={(event: any) =>
                      this.inputFileChanged1(event)
                    }
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      alignContent: "center", backgroundColor: 'white',
                      color: '#419bf9',
                      width: "280px", padding: 20,
                      fontSize: 16, fontWeight: 'bold',
                      borderWidth: 1,
                      borderColor: '#419bf9',
                      borderRadius: 10,
                      borderStyle: 'dashed',
                      fontVariant: "initial",
                      textTransform: 'none',
                      marginBottom: "20px"
                    }}
                  />
                  Upload New Logo
                </Button>
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                  
                    <img style={{width:"150px", height:"150px", marginLeft:"10px"}} src={this.state.selectedCategoryLogo !== null  || this.state.selectedCategoryLogo !== "" ? this.state.selectedCategoryLogo :"#" } alt="name"/>
                   
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button color="primary" onClick={this.handleCloseEditDialog}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
              >
                {t("AdminConsole.cancel")}
              </Typography>
            </Button>
            <Button>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Work Sans",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                  opacity: 1,
                  color: "#1a76b0",
                }}
                variant="body2"
                onClick={this.handleEditButtonClick}
              >
                Edit
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit dialog ends */}

        {/* Category Dialog Start */}
        <Dialog
          onClose={this.handleCategoryDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.categoryDialog}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            New Category
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleCategoryDialog} />
            </span>
          </DialogTitle>
          <DialogContent dividers >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField variant="outlined" label="Enter Name in English" fullWidth onChange={this.handleEnglishNameChange} value={this.state.newCategoryName} />
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" label="Enter Name in German" fullWidth onChange={this.handleGermanNameChange} value={this.state.newCategoryNameGerman} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={(event: any) => {
                    event.target.value = null
                    this.fileInput2.click()
                  }}
                >
                  <ReactS3Uploader
                    type="file"
                    id="upload"
                    name="pic"
                    getSignedUrl={this.getSignedUrl}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'public-read'
                    }}
                    s3path="/uploads/"
                    contentDisposition="auto"
                    accept="**"
                    capture={true}
                    inputRef={cmp => this.fileInput2 = cmp}
                    onChange={(event: any) =>
                      this.inputFileChanged1(event)
                    }
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      alignContent: "center", backgroundColor: 'white',
                      color: '#419bf9',
                      width: "280px", padding: 20,
                      fontSize: 16, fontWeight: 'bold',
                      borderWidth: 1,
                      borderColor: '#419bf9',
                      borderRadius: 10,
                      borderStyle: 'dashed',
                      fontVariant: "initial",
                      textTransform: 'none',
                      marginBottom: "20px"
                    }}
                  />
                  Upload Logo
                </Button>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button variant="contained" disableElevation color="primary" onClick={this.addCategoryClick}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>
        {/* Category Dialog End */}




        {/* edit FAQ Dialog Start */}
        <Dialog
          onClose={this.handleEditFAQDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.editFaq}
          fullWidth
          style={{
            width: "800px",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <DialogTitle
            id="simple-dialog-title"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            Edit FAQ
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleEditFAQDialog} />
            </span>
          </DialogTitle>
          <DialogContent dividers >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField variant="outlined"
                  // label="Enter Name in English" 
                  fullWidth
                  onChange={this.handleFaqQuestionChange}
                  value={this.state.editFaqQuestion} />
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined"
                  // label="Enter Name in German" 
                  fullWidth
                  onChange={this.handleFaqAnswerChange}
                  value={this.state.editFaqAnswer} />
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={(event: any) => {
                    event.target.value = null
                    this.fileInput2.click()
                  }}
                >
                  <ReactS3Uploader
                    type="file"
                    id="upload"
                    name="pic"
                    getSignedUrl={this.getSignedUrl}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'public-read'
                    }}
                    s3path="/uploads/"
                    contentDisposition="auto"
                    accept="**"
                    capture={true}
                    inputRef={cmp => this.fileInput2 = cmp}
                    onChange={(event: any) =>
                      this.inputFileChanged1(event)
                    }
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      alignContent: "center", backgroundColor: 'white',
                      color: '#419bf9',
                      width: "280px", padding: 20,
                      fontSize: 16, fontWeight: 'bold',
                      borderWidth: 1,
                      borderColor: '#419bf9',
                      borderRadius: 10,
                      borderStyle: 'dashed',
                      fontVariant: "initial",
                      textTransform: 'none',
                      marginBottom: "20px"
                    }}
                  />
                  Upload Logo
                </Button>
              </Grid>
               */}
              <Grid item xs={12}
                style={{ textAlign: "center" }}
              >
                {/* <Button variant="contained" disableElevation color="primary"
                  // onClick={this.updateQuestionAndAnswer}
                  style={{
                    marginRight: "14px"
                  }}
                >
                  Upload
                </Button> */}

                <Button
                  style={{
                    marginRight: "14px"
                  }}
                  variant="contained" disableElevation color="primary"
                  onClick={(event: any) => {
                    event.target.value = null
                    this.fileInput.click()
                  }}
                >
                  <ReactS3Uploader
                    type="file"
                    id="upload"
                    name="pic"
                    getSignedUrl={this.getSignedUrl}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'public-read'
                    }}
                    s3path="/uploads/"
                    contentDisposition="auto"
                    accept="**"
                    capture={true}
                    inputRef={cmp => this.fileInput = cmp}
                    onChange={(event: any) =>
                      this.inputFileChanged(event)
                    }
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      alignContent: "center", backgroundColor: 'white',
                      color: '#419bf9',
                      width: "280px", padding: 20,
                      fontSize: 16, fontWeight: 'bold',
                      borderWidth: 1,
                      borderColor: '#419bf9',
                      borderRadius: 10,
                      borderStyle: 'dashed',
                      fontVariant: "initial",
                      textTransform: 'none',
                      marginBottom: "20px"
                    }}
                  />
                  Upload
                </Button>




                <Button variant="contained" disableElevation color="primary" onClick={this.updateQuestionAndAnswer}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>


        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl2}
          open={Boolean(this.state.anchorEl2)}
          onClose={this.handleQuestionIconClose}
        >
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
          // onClick={(e) => this.handleQuestionEdit(_data, idx)}
          >
            Edit
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Work Sans",
              fontSize: "12px",
              color: "#0E0E0E",
              textTransform: "capitalize",
              letterSpacing: 0,
            }}
          // onClick={(e) =>
          //   this.handleQuestionAndAnswersIconClick(
          //     e,
          //     _data
          //   )
          // }
          >
            Delete
          </MenuItem>

        </Menu>

      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomerSupport));
