import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react"
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import BulkUploading from '../../BulkUploading/src/BulkUploadingController';
import * as SparkMD5 from 'spark-md5';
import { ToastContainer, toast } from 'react-toastify';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any;
  match?: any;
  // id2List:any
  // id2List:{ droppable: string; droppable2: string; }
  // Customizable Area Start
  // Customizable Area End
}

// export interface id2List{ droppable: string; droppable2: string; };
// const getItems = (count:any) =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));
// const foo =
//     'lorem ipsum dolor sit amet consectutrtpoyu sklhfdlkfh dklfh lkdhflhdflkh dlkfhdlkhflkd fldhflh';
// const getItems = (count:any, offset = 0) =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         id: `item-${k + offset}`,
//         content: `item ${k + offset}${k + offset >= 10 ? foo : ''}`
//     }));

const data = [
  {
    id: "car",
    content: "car",
  },
  {
    id: "elelphant",
    content: "elelphant",
  },
  {
    id: "ball",
    content: "ball",
  },
  {
    id: "cat",
    content: "cat",
  },
  {
    id: "dog",
    content: "dog",
  },
];

const itemsData = [
  {
    id: "BMW",
    content: "BMW",
  },
  {
    id: "Volkswagon",
    content: "Volkswagon",
  },
  {
    id: "Mercedes",
    content: "Mercedes",
  },
  {
    id: "Suzuki",
    content: "Suzuki",
  },
  {
    id: "Hundai",
    content: "Hundai",
  },
];

const getItems = (count: any, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));
interface FilmOptionType {
  title: string;
  year: number;
}
interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  items: any;
  selected: any;
  approval: any;
  todo: any;
  anchorEl: any;
  grid: boolean;
  list: boolean;
  setOpen: boolean;
  sharedProject: any;
  sharedProjectError: any;
  value: any;
  setOpen1: any;
  setOpen2: any;
  CGI: any;
  anchorE2: any;
  percentage: any;
  openTaskCreateModel: boolean;
  openAddBriefingModel: boolean;
  openAddRequestQuoteModel: boolean;
  category: string;
  assetType: string;
  assetData: any;
  assetFileData: any
  imageData: any
  folders: any;
  selectedBrand: any;
  fileToRawBinary: any;
  inviteTeamMembersDialog: any
  accountsData: any
  selectedAsset: any
  openLink: boolean
  setFolder: boolean
  uploading: any;
  openImage: any
  openImageURL: any
  anchorElFilter: any
  brandFilterValue: any
  searchBrandName: any
  isSearch: any
  sortOrder: any
  signedIdAndURLs: any,
  countUpload: any,
  countSigned: any,
  uploadFile: any,
  search: any,
  searchedData: any
  fileFlag: any
  uploadingSignedObject: any
  countForFile: any
  deleteMediaDialog: any
  permissions: any
  permissionName : any
  openPdf: any
  openPdfURL: any;
  numPages: any;
  pageNumber: any;

  // Customizable Area End
}
const initialBoardData = {
  items: {
    "item-1": { id: "item-1", content: "Content of item 1." },
    "item-2": { id: "item-2", content: "Content of item 2." },
    "item-3": { id: "item-3", content: "Content of item 3." },
    "item-4": { id: "item-4", content: "Content of item 4." },
    "item-5": { id: "item-5", content: "Content of item 5." },
    "item-6": { id: "item-6", content: "Content of item 6." },
    "item-7": { id: "item-7", content: "Content of item 7." },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Column 1",
      itemsIds: [
        "item-1",
        "item-2",
        "item-3",
        "item-4",
        "item-5",
        "item-6",
        "item-7",
      ],
    },
    "column-2": {
      id: "column-2",
      title: "Column 2",
      itemsIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "Column 3",
      itemsIds: [],
    },
    "column-4": {
      id: "column-4",
      title: "Column 4",
      itemsIds: [],
    },
  },
  columnsOrder: ["column-1", "column-2", "column-3", "column-4"],
};
interface SS {
  id: any;
  items: any;
  selected: any;
  todo: any;
}

const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

export default class MediaAssetsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssetCategorysApiCallId: string = ""
  getAssetCategoryApiCallId: string = ""
  getAssetCategoryFileApiCallId: string = ""
  getAssetCategoryFolderApiCallId: string = ""
  getMediaAssetAccountApiCallId: string = ""
  addFolderAPI: string = ""
  getSignedURLForFileUploadId: Array<any> = []
  getSignedURLForFileUploadId2: Array<any> = []
  fileInput: any
  userProfileApiCallId: Array<any> = []
  getAllUsersApiCallId: string = ""
  getBrandListingByFiltersId: string = ""

  getAssetCategorySearchApiCallId: string = ""

  deleteAssetApiCallId: string = ""
  array: Array<any> = []
  arratMD5: Array<any> = []
  counter = 0
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess),
    getName(MessageEnum.RestAPIResponceMessage),

    ];
    this.fileInput = React.createRef();

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      items: data,
      todo: itemsData,
      sharedProject: "",
      sharedProjectError: false,
      selected: getItems(5, 10),
      approval: getItems(5),
      percentage: 74,
      anchorEl: null,
      grid: localStorage.getItem("grid") == '1' ? true : false,
      list: localStorage.getItem("grid") != '1' ? true : false,
      setOpen: false,
      value: 0,
      setOpen1: false,
      setOpen2: false,
      CGI: false,
      anchorE2: null,
      openTaskCreateModel: false,
      openAddBriefingModel: false,
      openAddRequestQuoteModel: false,
      category: "",
      assetType: "",
      assetData: [],
      assetFileData: [],
      imageData: [],
      folders: [],
      selectedBrand: null,
      fileToRawBinary: {},
      inviteTeamMembersDialog: false,
      accountsData: [],
      selectedAsset: null,
      openLink: false,
      setFolder: false,
      uploading: false,
      openImage: false,
      openImageURL: null,
      anchorElFilter: null,
      brandFilterValue: 'name0',
      searchBrandName: null,
      isSearch: null,
      sortOrder: "desc",
      signedIdAndURLs: [],
      countUpload: 0,
      countSigned: 0,
      uploadFile: [],
      search: '',
      searchedData: [],
      fileFlag: false,
      uploadingSignedObject: null,
      countForFile: 0,
      deleteMediaDialog: false,
      permissionName : null,
      permissions : null,
      openPdf: false,
      openPdfURL: null,
      numPages: null,
      pageNumber: 1,

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("userInfo")

    if (userDetailsBeforeParse) {
      const userDetails = JSON.parse(userDetailsBeforeParse)
      localStorage.setItem('permissions', userDetails?.role?.data?.attributes?.permission)
      this.setState({
        permissions: userDetails?.role?.data?.attributes?.permission,
        permissionName: userDetails?.role?.data?.attributes?.name
      })
    }

  }

  searchAssets() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var userId = JSON.parse(userDetails).brand_id
      console.log(userId, "userId")

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategorySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.AssetCategoryAPIEndPoint
      // `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
      // `bx_block_categories_sub_categories/brands/brand_media_asset_listing?per_page=50&brand_name=${this.state.search}`
      `bx_block_categories_sub_categories/media_assets/search_media_assets?page=1&per_page=500&name=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  getMediaAsset() {
    console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    const assetId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.setState({ selectedBrand: assetId })

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // `bx_block_categories_sub_categories/media_assets?page=1&brand_id=${assetId}&per_page=50`

      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=30&media_asset_id=${assetId}`

      // `bx_block_categories_sub_categories/media_asset_folders?page=${1}&per_page=${10}&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null
    })
  }

  handleFilterChange = (event: any) => {
    console.log(event.target.value)
    var order = event.target.value.slice(-1)
    var item = event.target.value.substr(0, event.target.value.length - 1)
    this.setState({ brandFilterValue: event.target.value, isSearch: true, anchorElFilter: null })
    let path = "" as string;
    if (order == "0") {
      path = `sort_by=${item}&sort_order=asc`
    }
    else if (order == "1") {
      path = `sort_by=${item}&sort_order=desc`
    }
    // if (this.state.searchBrandName !== "") {
    //   path = `brand_name=${this.state.searchBrandName}&Sort_by=${event.target.value}`;
    // } else {
    //   path = `Sort_by=${event.target.value}`
    // }
    this.getBrandsByFilters(path)
  }
  getBrandsByFilters = (path: any) => {
    // this.setState({tableBrandData:[]})
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingByFiltersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=40&media_asset_folder_id=${this.state.selectedBrand}&${path}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getMediaAssetFiles(path?: any) {
    this.setState({ uploading: true })
    console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    const assetId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.setState({ selectedBrand: assetId })
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),



      path == null || path?.length == 0 ? `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1500&media_asset_folder_id=${assetId}`
        : `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1500&media_asset_folder_id=${assetId}&name=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async componentDidMount() {
    this.loadUserDetailsAndRoles()
    //this.getMediaAsset()
    this.nameSetting()
    this.getMediaAssetFiles()
    //this.getUser()
  }

  nameSetting = () => {
    // console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    const mediaName1: any = this.props.match.params.mediaAssetId;

    //  console.log("params12" , mediaName1[6]);
    // const header = {

    //   token: localStorage.getItem("token")

    // };

    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.getAssetCategoryApiCallId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   // configJSON.AssetCategoryAPIEndPoint

    //   `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.httpGetType
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);
    // return true;


  }
  handleClickMenu = (event: any) => {
    //console.log("filesss", ele);

    this.setState({
      anchorEl: event.currentTarget,

    });
  };
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null,
    });
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false,
    });
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen1: true,
    });
  };
  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true,
    });
  };
  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen1: false,
    });
  };
  setData() {
    console.log("hello");
    const sideNavData = {
      sideNavData: [
        {
          name: "photoproduction",
        },
        {
          name: "CGI",
        },
      ],
    };
    localStorage.setItem("navData", JSON.stringify(sideNavData));
    // this.props.history.push("/Home/Projectportfolio")
    // navigator.navigate("/Home/Projectportfolio")
    window.location.href = "/Home/Projectportfolio";
  }
  progressPercentage = (e?: any, value?: any) => {
    console.log(e, value, "value");
    this.setState({
      percentage: value,
    });
  };
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true,
    });
  }
  navigateToProjects(e?: any) {
    this.props.navigation.navigate("home/projectportfolio");
  }
  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
    });
    if (item == "inviteTeamMember") {
      console.log("true");
      this.setState({
        // anchorEl: null,
        inviteTeamMembersDialog: true,
        //inviteteammembersId:item
      });
    }
    if (item == "assetsProperties") {
      this.setState({
        // anchorEl: null,
        setFolder: true,
        //inviteteammembersId:item
      });
    }
    if (item == 'download') {
      console.log("this.selectedAsset", this.state.selectedAsset);

      this.download(this.state.selectedAsset)
    }
    if (item == 'sharableLink') {
      this.setState({ openLink: true })
    }

    if (item == 'delete') {
      this.setState({ deleteMediaDialog: true })
    }

    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleClickCreateNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: true,
    });
  };

  handleClickCancelNewTaskModel = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
    });
  };

  handleClickStartBriefing = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddBriefingModel: true,
    });
  };

  handleClickStopBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleClickChangeBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };
  handleCloseModalTeam = () => {
    this.setState({
      inviteTeamMembersDialog: false,
    });
  };
  handleClickSaveBriefing = (e?: any) => {
    this.setState({
      openAddBriefingModel: false,
    });
  };

  handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ category: event.target.value as string });
  };

  handleChangeAssetType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ assetType: event.target.value as string });
  };

  handleClickStartRequestQuote = (e?: any) => {
    this.setState({
      openTaskCreateModel: false,
      openAddRequestQuoteModel: true,
    });
  };

  handleClickStopRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickChangeRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handleClickSaveRequestQuote = (e?: any) => {
    this.setState({
      openAddRequestQuoteModel: false,
    });
  };
  handlePageChange = () => {

  }


  imageView(name: any, url: any) {
    this.setState({ openImageURL: { "name": name, "url": url } }, () => {
      this.setState({ openImage: true })
    })
  }
  pdfView(name: any, url: any) {
    this.setState({ openPdfURL: { "name": name, "url": url } }, () => {
      this.setState({ openPdf: true })
    })
  }

  goToPrevPage = () => {  
    this.setState({
      pageNumber: this.state.pageNumber - 1
    });
  }

  goToNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    });
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPages
    })
  }
  getAssetCategory = () => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var userId = JSON.parse(userDetails).brand_id
      console.log(userId, "userId")

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.AssetCategoryAPIEndPoint

      `bx_block_categories_sub_categories/media_assets?page=${2}&per_page=${1}&brand_id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  getImages(data?: any) {
    console.log(data, "gg")
    data.forEach(async (element: any) => {
      var value;
      value = element.id
      this.getMediaAssetImages(value)


    })
  }
  getUser(value?: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `account_block/accounts?per_page=50`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getMediaAssetImages(value?: any) {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaAssetAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      // `bx_block_categories_sub_categories/media_asset_accounts?type=brand&id=${this.state.mediaAssetID}`
      `bx_block_categories_sub_categories/media_asset_accounts?type=media_asset&id=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteAsset() {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAssetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // bx_block_team_builder/teams?page=1&per_page=2&brand_id=2

      `bx_block_categories_sub_categories/media_asset_files/${this.state.selectedAsset?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    console.log(message, "me")
    if (this.getSignedURLForFileUploadId2.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      // await this.uploadImage(this.state.uploadingSignedObject?.signed, this.state.uploadingSignedObject?.arryElement)
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.deleteAssetApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.message) {
        this.setState({ deleteMediaDialog: false })
        toast.success("File deleted successfully.")
        this.getMediaAssetFiles()
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategorySearchApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.data) {
        this.setState({ searchedData: message?.properties?.RestAPIResponceSuccessMessage?.data })
        console.log("assetData123", this.state.searchedData);
        //console.log("searching data" , message?.properties?.RestAPIResponceSuccessMessage?.data );


      }
    }
    if (this.getSignedURLForFileUploadId.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      // console.log("i am count", this.state.signedIdAndURLs);
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            this.setState(prevState => {
              return {
                ...prevState,
                signedIdAndURLs: [...prevState.signedIdAndURLs, responseJson]
              }
            }, async () => {
              // var blob = new Blob([this.array[this.state.signedIdAndURLs?.length-1]], { type: 'text/plain' });
              // let headers : string[] = Object.values(responseJson?.data[0].headers);
              // const header = {
              //   "Content-Type": headers[0],
              //   "Content-MD5": headers[1],
              // };
              // let head = new Headers();
              // head.append("Content-Type", headers[0])
              // head.append("Content-MD5", headers[1])
              // await fetch(responseJson?.data[0]?.url, {
              //   headers : header,
              //    method : "PUT",
              //    body : blob
              //  })
              if (this.state.signedIdAndURLs.length == this.state.countForFile) {
                console.log("signedIdAndURLs12", this.state.signedIdAndURLs);
                this.signedIdHandler()
              }
            })

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      // if (message?.properties?.RestAPIResponceSuccessMessage?.projects?.data) {

      //   this.setState({
      //     projectsOfStatusOnGoingData: message?.properties?.RestAPIResponceSuccessMessage?.projects?.data,
      //   }, () => {
      //   })

      // }
    }
    if (this.userProfileApiCallId.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      console.log("message.properties", message.properties)
      if (message.properties) {
        this.counter = this.counter + 1
        
           
        if(this.counter == this.state.countForFile){
        this.setState({ uploading: false, uploadFile: [], signedIdAndURLs: [], countUpload: 0, countSigned: 0, countForFile: 0 })
        toast.success("files uploaded successfully")
        this.array = []
        this.counter = 0
        // this.setState({})
        // this.getAssetCategory()
        this.getMediaAssetFiles()
      }
    }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllUsersApiCallId) {
      this.setState({
        accountsData: message?.properties?.RestAPIResponceSuccessMessage?.accounts.data,
        // initialAccountData: message?.properties?.RestAPIResponceSuccessMessage?.accounts.data[0]
      }, () => {
        console.log(this.state.accountsData, "accountData")


      })
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFolderApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data) {
        this.setState({
          assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFileApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          assetFileData: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          uploading: false
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,


        })
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getBrandListingByFiltersId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          assetFileData: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
        }, () => {
          // console.log("assetddat m", this.state.assetFileData)
        })
      }
    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.addFolderAPI) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        console.log("folder cretars");
        this.getMediaAsset()

      }
    }
    if (message?.properties?.RestAPIResponceSuccessMessage?.accounts?.data) {
      this.setState({
        imageData: [...this.state.imageData, message?.properties?.RestAPIResponceSuccessMessage?.accounts?.data]



      }, async () => {

        await this.state.assetData.map((item: any, id: any) => {
          let len = this.state.imageData.length
          if (len)
            item.internalFiles = this.state.imageData[id]
          return item;
        })
        console.log(this.state.assetData, "data")

        // this.setMediaAssetID(this.state.assetData)
      })

    }
    // if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
    //   this.setState({
    //     assetData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,



    //   }, () => {
    //     console.log(this.state.assetData)
    //     this.getImages(this.state.assetData)
    //     // this.setMediaAssetID(this.state.assetData)
    //   })

    // }



    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  async signedIdHandler() {


    console.log("signedIdHandler called");

    if (this.arratMD5.length > 0) {

      await Promise.all(this.array.map(async (file: any, index: any) => {
        await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index2: any) => {
          if (this.arratMD5[index] == signed?.data[0].headers["Content-MD5"]) {
            var blob = new Blob([this.array[index]], { type: 'text/plain' });
            let headers: string[] = Object.values(signed?.data[0].headers);
            const header = {
              "Content-Type": headers[0],
              "Content-MD5": headers[1],
            };
            let head = new Headers();
            head.append("Content-Type", headers[0])
            head.append("Content-MD5", headers[1])
            await fetch(signed?.data[0]?.url, {
              headers: header,
              method: "PUT",
              body: blob
            })
          }

        }))
      }))
      // for(let i=0; i < this.state.signedIdAndURLs.length ; i++){
      //   await this.bulkUploading(this.state.signedIdAndURLs[i]?.data[0], this.array[i])
      // }

      //   await Promise.all(this.state.signedIdAndURLs.map(async (signed: any, index: any) => {

      //     var blob = new Blob([this.array[index]], { type: 'text/plain' });
      //     let headers : string[] = Object.values(signed?.data[0].headers);
      //     const header = {
      //       "Content-Type": headers[0],
      //       "Content-MD5": headers[1],
      //     };
      //     let head = new Headers();
      //     head.append("Content-Type", headers[0])
      //     head.append("Content-MD5", headers[1])  
      //     await fetch(signed?.data[0]?.url, {
      //      headers : header,
      //       method : "PUT",
      //       body : blob
      //     })

      //     // await this.bulkUploading(signed?.data[0], this.array[index])

      // }))
      await this.uploadImage(this.state.signedIdAndURLs, this.array)

    }
  }
  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers: string[] = Object.values(data.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };
    let head = new Headers();
    head.append("Content-Type", headers[0])
    head.append("Content-MD5", headers[1])
    await fetch(data?.url, {
      headers: header,
      method: "PUT",
      body: blob
    })


    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.getSignedURLForFileUploadId2.push(requestMessage.messageId);
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   data?.url
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   blob
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   "PUT"
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  download(e: any) {
    console.log("file for ", e);
    // if (this.props.match.params.AssetId == "Approval Files" ||
    //   this.props.match.params.AssetId == 'Source Files' ||
    //   this.props.match.params.AssetId == 'Task Files') {
    //   this.setState({
    //     uploading: true
    //   })
    var url = `${currentUrl
      }/bx_block_categories_sub_categories/media_asset_files/file_downloads?media_asset_file_id=${e?.id}&token=${localStorage.getItem("token")}`;
    var anchor = document.createElement("a");
    //console.log("Url", url)
    anchor.setAttribute("href", url);
    // anchor.setAttribute("download", url);
    anchor.click();
    // this.setState({
    //   uploading: false
    // })

    // }
    // else {
    // fetch(e?.attributes?.file_blob_signed_id_url, {
    //   method: "GET",
    //   // headers: {}
    // })
    //   .then(response => {
    //     response.arrayBuffer().then(function (buffer) {
    //       const url = window.URL.createObjectURL(new Blob([buffer]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", e?.attributes?.name); //or any other extension
    //       document.body.appendChild(link);
    //       link.click();
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    // }

  }

  async uploadImage(signedIdAndURLs: any, fileArray?: any) {

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('token'),
    };
    const httpBody = {
      "media_asset_folder_id": parseInt(this.state.selectedBrand),
      "data": {
        "media_asset_files_attributes":  [{
            "name": fileArray?.name,
            "file_blob_signed_id": signedIdAndURLs.data[0]?.signed_id,
            "make_private": false,
            "make_public": false
          }]
        
      }
    }

    // var datas = new FormData()
    // datas.append("photo_blob_signed_id" , responseJson?.data[0]?.signed_id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileApiCallId.push(requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_files`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
      // datas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  inputFileChanged = (e: any) => {
    // console.log(this.fileInput.current.files[0]);

    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
    //  this.setState(
    //    {
    //      imgs: URL.createObjectURL(e.target.files[0]),
    //    },
    //    () => {
    //      // this.addAccountProfile(value, "image");
    //      console.log("imgs", (e.target));

    //    }
    //  );

    // this.addAccountProfile(this.state.imgs,"image")
  };

  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  getSignedUrl = async (file: any) => {
    this.setState({ uploading: true })
    console.log("File", file)
    this.array.push(file)
    this.setState(prevState => {
      {
        return {
          ...prevState,
          countForFile: prevState.countForFile + 1
        }
      }
    })
    console.log("arrays", this.array);
    this.setState({ fileToRawBinary: file })
    const md5 = await this.computeChecksumMd5(file);
    this.arratMD5.push(md5)

    await this.getSignedURLForFileUpload(file,file.size, md5, file.name, file.type)

    //this.bulkUpload()


    // if(file){
    //   console.log("File upload2 " , this.state.uploadFile);
    //   this.setState({uploadFile : [...this.state.uploadFile, file]} , ()=>{
    //   console.log("File upload" , this.state.uploadFile)
    //   })
    // }


    // console.log("File upload" , );
    //  , ()=>{
    //   console.log("File upload" , this.state.uploadFile);  
    // }
    //   this.setState({ fileToRawBinary: file})
    //  await this.computeChecksumMd5(file).then( (md5) => {
    //      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    //   });
  }


  async getSignedURLForFileUpload(file : any,fileSize: any, checkSum: any, fileName: any, contentType: any) {

    let header = new Headers()   
    let token= localStorage.getItem("token") ? localStorage.getItem("token") : ""
    header.append("Content-Type", "application/json")
    header.append("token", `${token}`) 
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    fetch(`${currentUrl}` + "/bx_block_upload_media/s3_uploads/get_presigned_url", {
      method: 'POST', 
      // headers: {
      //   'Content-Type': 'application/json'
      //   // 'Content-Type': 'application/x-www-form-urlencoded',
      // },
      headers : header,
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', 
      body: JSON.stringify(httpBody) // body data type must match "Content-Type" header
    })
    .then(response => response.json())
    .then(async(data) =>{

         var blob = new Blob([file], { type: 'text/plain' });
          let headers : string[] = Object.values(data?.data[0].headers);
          const header = {
            "Content-Type": headers[0],
            "Content-MD5": headers[1],
          };
          let head = new Headers();
          head.append("Content-Type", headers[0])
          head.append("Content-MD5", headers[1])  
          await fetch(data?.data[0]?.url, {
           headers : header,
            method : "PUT",
            body : blob
          })
          await this.uploadImage(data,file)
    }
      )
    .catch(err=> console.log(err))



    // const header = {
    //   "Content-Type": "application/json",
    //   token: localStorage.getItem("token")
    // };
    // const httpBody = {
    //   byte_size: fileSize,
    //   checksum: checkSum,
    //   filename: fileName,
    //   content_type: contentType
    // };
    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.getSignedURLForFileUploadId.push(requestMessage.messageId);
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   "bx_block_upload_media/s3_uploads/get_presigned_url"
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   "POST"
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);
  }







  createFolder = (values?: any, e?: any) => {
    console.log(values, "values")
    // const brandDetails = localStorage.getItem("userInfo")

    // if (brandDetails) {
    //   var brandId = JSON.parse(brandDetails).brand_id

    // }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token")
    };

    console.log("header")

    const data = {
      "name": values?.name,
      "make_public": true,
      "make_private": false,
      "media_asset_id": this.state.selectedBrand
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("ddd")

    this.addFolderAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.addFolderAPIEndPoint
      //`bx_block_categories_sub_categories/media_assets`
      `bx_block_categories_sub_categories/media_asset_folders`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);



    return true;
  }
  // Customizable Area End
}
