import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
} from "react-native";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { signUpBackgroundImage } from "./assets";
import { harvestLogoImage, harvestLogo2xImage, harvestLogo3xImage, rightHarvestLogoImage, forgetBackgroundImage, ukLogo, germanLogo } from './assets'
import { withTranslation, Trans } from "react-i18next";

//Customizable Area End
const styles = {
  root: {
    // backgroundColor: "red",
    overflowY: "hidden"
  },

  inputPostion: {
    margin: "0",
    position: "absolute",
    top: "30%",
    left: "45%",
    transorm: "translate(-50%, -50%)",
  },
  signUpLogo: {
    height: "100%",
    width: "25%",
    position: "absolute",
    top: "0",
    bottom: "0",
  },
  view_image_left: {
    maxHeight: "100vh"
  },

  button: {
    backgroundColor: "rgb(23, 62, 89)",
    color: "white",
  },
  PageHeader: {
    // fontFamily: "Inter",
    color: "#173e59",
    fontSize: "24px",
    marginBottom: "16px",
  },
  PageText: {
    // fontFamily: 'WorkSans-Regular',
    fontSize: '16px',
    color: '#282828',
    marginBottom: "16px",
  },
  emailError: {
    fontSize: "10px",
    color: "red",
  },
  inputHeight: {
    height: "70px",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  signUp: {
    textAlign: "end",
  },
  signUpLink: {
    color: "#173e59",
    cursor: "pointer",
  },
  textError: {
    border: "1px solid red",
  },
  image_logo: {
    width: "174px",
    height: "91px",
    objectFit: "contain"
  },
  logo: {
    position: "relative",
    zIndex: 1,
    padding: "55px 82px"
  },
  images: {

  }
} as const;

var langSelected = localStorage.getItem("i18nextLng");

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  handleLanguage(lang: string) {
    // console.log(this.props.i18n, lang)
    langSelected = lang
    this.props.i18n.changeLanguage(lang);
    this.langaugeChangeHandler(this.props.i18n.language)
    console.log("lang", this.props.i18n)
  }

  render() {
    const { t, classes } = this.props;
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
    } else {
      colorToBeChanged = "#173e59";
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
    } else {
      secondaryColorToBeChanged = "#173e59";
    }

    return (
      <div style={{ overflowY: 'hidden', maxHeight: '100vh' }}>
        <Grid container>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "10px",
            zIndex: 10000,
          }}
        >
          <Button
            onClick={() => this.handleLanguage("en")}
            style={{ color:localStorage.getItem("i18nextLng") == 'en' ? 'white' : "black", backgroundColor: localStorage.getItem("i18nextLng") == 'en' ? '#173e59' : 'transparent' }}
            startIcon={<img src={ukLogo} style={{width: '18px', height: '18px'}}/>}
          >
            English
          </Button>
          <Button
            onClick={() => this.handleLanguage("de")}
            style={{ color: localStorage.getItem("i18nextLng") == 'de' ? 'white' : "black" , backgroundColor: localStorage.getItem("i18nextLng") == 'de' ? '#173e59' : 'transparent' }}
            startIcon={<img src={germanLogo} style={{width: '18px', height: '18px'}}/>}
          >
            German
          </Button>
        </div>
          <Grid item xs={6}>
            <img src={forgetBackgroundImage.default} className={classes.view_image_left} />
            {/* <div className={classes.logo}>
              <img src={harvestLogoImage}
                className={classes.image_logo}
              />
            </div> */}
          </Grid>
          <div className={classes.images}>
            <img src={harvestLogoImage.default} style={{
              maxWidth: "173px",
              height: "auto",
              position: "absolute",
              left: "100px",
              top: "62px",
              cursor: 'pointer'
            }} onClick={() => window.location.href = '/EmailAccountLogin' } />
            <Grid item xs={6}>
              <form className={classes.inputPostion}>
                <div>
                  <p
                    className={classes.PageHeader}
                    style={{ color: colorToBeChanged }}
                  >
                    {t("ForgotPasswordController.forgotPassword")}
                  </p>
                </div>

                <div>
                  <p className={classes.PageText}>
                    {t("ForgotPasswordController.emailVerificationMessage")}
                  </p>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.inputHeight}>
                    {/* <TextField fullWidth label="Email" id="outlined-error-helper-text" name="email" size="small" variant="outlined"
                      onChange={e=>this.textInput(e)}
                        />  */}

                    {!this.state.emailError && !this.state.emailErrorStatus ? (
                      <TextField
                        fullWidth
                        label={t("ForgotPasswordController.email")}
                        id="outlined-error-helper-text"
                        name="email"
                        size="small"
                        variant="outlined"
                        onChange={(e) => this.textInput(e)}
                      />
                    ) : (
                      <TextField
                        error
                        fullWidth
                        label={t("ForgotPasswordController.email")}
                        id="outlined-error-helper-text"
                        helperText={
                          this.state.emailError
                            ? (
                              <Trans>ForgotPasswordController.emailRequired</Trans>
                            )
                            : this.state.emailErrorStatus
                              ? (
                                <Trans>ForgotPasswordController.invaildEmail</Trans>
                              )
                              : null
                        }
                        name="email"
                        size="small"
                        variant="outlined"
                        onChange={(e) => this.textInput(e)}
                      />
                    )}
                    {/* {this.emailError && <p className={classes.emailError}>Email is required</p>} */}
                  </Grid>

                  <Grid item xs={12}>
                    {/* <TextField fullWidth label="Email" id="outlined-error-helper-text" name="email" size="small" variant="outlined" />  */}
                    {/* {this.emailError && <p className={classes.emailError}>Email is required</p>} */}
                    <Button
                      className={classes.button}
                      fullWidth
                      type="submit"
                      style={{ backgroundColor: colorToBeChanged }}
                      variant="contained"
                      onClick={(e) =>
                        this.goToOtpAfterEmailValidation(
                          { accountType: "", email: "" },
                          e
                        )
                      }
                    >
                      {t("ForgotPasswordController.sendVerificationCode")}
                    </Button>
                    <p className={classes.signUp}>
                      {/* {t("EmailAccountLoginController.donNotHaveAccount")} */}
                      <Link
                        className={classes.signUpLink}
                        style={{ color: colorToBeChanged }}
                        onClick={() => this.props.navigation.navigate('EmailAccountLoginBlock')}
                      >
                        {t("EmailAccountLoginController.signInButton")}
                        {/* Sign In */}
                      </Link>
                    </p>
                  </Grid>

                  <Grid item xs={10}>
                    {/* <Button className={classes.button} fullWidth type="submit" variant="contained" >
                    Log in
    </Button> */}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <img src={rightHarvestLogoImage} style={{
              maxWidth: "298px",
              height: "auto",
              margin: 0,
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: -1
            }} />
          </div>

        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(ForgotPassword));
// Customizable Area Start
