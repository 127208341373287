import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  MenuItem,
  Box,
  Select,
  Menu,
  IconButton,
} from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
const designHarvestLogo = require("./design-harvest-logo-icon.png");

const styles = {};
interface Props {
  navigation: any;
}
interface S {
  flag: any;
}
interface SS { }

class PrivacyPolicy extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      flag: 'home'
    };
  }

  async componentDidMount() {
    var str = window.location.href;
    var flag = str.split("?")[1];
    if (flag?.split("=")[1]) {
      this.setState({
        flag: flag?.split("=")[1]
      })
    }
  }

  goBack = () => {
    this.props.navigation.navigate('HomePage')

  }

  render() {
    let colorToBeChanged: any;
    let primaryFont: any;
    let SecondaryFont: any

    let secondaryColorToBeChanged: any;
    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    // console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }
    return (
      <>
        <Grid container style={{fontFamily : primaryFont}}>
          <main className="wrap">

            <Grid container spacing={0}>
              <Grid item xs={9} />
              <Grid item xs={3}>
                {
                  this.state.flag != 'signup' &&
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src={designHarvestLogo} />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p
                        style={{
                          color: secondaryColorToBeChanged,
                          fontFamily : primaryFont,
                          cursor: "pointer",
                        }}
                        onClick={this.goBack}
                      >
                        Back To Design Harvest
                      </p>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
            <section className="container" style={{margin: '0px 3%'}}>
              <div className="container__heading">
                <h2>POLICIES</h2>
              </div>
              <div className="container__content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer nec odio. Praesent libero. Sed cursus ante dapibus
                  diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.
                  Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                  augue semper porta. Mauris massa. Vestibulum lacinia arcu eget
                  nulla. className aptent taciti sociosqu ad litora torquent per
                  conubia nostra, per inceptos himenaeos. Curabitur sodales
                  ligula in libero. Sed dignissim lacinia nunc.{" "}
                </p>
                <p>
                  Curabitur tortor. Pellentesque nibh. Aenean quam. In
                  scelerisque sem at dolor. Maecenas mattis. Sed convallis
                  tristique sem. Proin ut ligula vel nunc egestas porttitor.
                  Morbi lectus risus, iaculis vel, suscipit quis, luctus non,
                  massa. Fusce ac turpis quis ligula lacinia aliquet. Mauris
                  ipsum. Nulla metus metus, ullamcorper vel, tincidunt sed,
                  euismod in, nibh. Quisque volutpat condimentum velit.{" "}
                </p>
                <p>
                  className aptent taciti sociosqu ad litora torquent per
                  conubia nostra, per inceptos himenaeos. Nam nec ante. Sed
                  lacinia, urna non tincidunt mattis, tortor neque adipiscing
                  diam, a cursus ipsum ante quis turpis. Nulla facilisi. Ut
                  fringilla. Suspendisse potenti. Nunc feugiat mi a tellus
                  consequat imperdiet. Vestibulum sapien. Proin quam. Etiam
                  ultrices. Suspendisse in justo eu magna luctus suscipit.{" "}
                </p>
                <p>
                  Sed lectus. Integer euismod lacus luctus magna. Quisque
                  cursus, metus vitae pharetra auctor, sem massa mattis sem, at
                  interdum magna augue eget diam. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia Curae;
                  Morbi lacinia molestie dui. Praesent blandit dolor. Sed non
                  quam. In vel mi sit amet augue congue elementum. Morbi in
                  ipsum sit amet pede facilisis laoreet. Donec lacus nunc,
                  viverra nec, blandit vel, egestas et, augue. Vestibulum
                  tincidunt malesuada tellus. Ut ultrices ultrices enim.
                  Curabitur sit amet mauris.{" "}
                </p>
                <p>
                  Morbi in dui quis est pulvinar ullamcorper. Nulla facilisi.
                  Integer lacinia sollicitudin massa. Cras metus. Sed aliquet
                  risus a tortor. Integer id quam. Morbi mi. Quisque nisl felis,
                  venenatis tristique, dignissim in, ultrices sit amet, augue.
                  Proin sodales libero eget ante. Nulla quam. Aenean laoreet.{" "}
                </p>
              </div>
              <div className="container__nav">
                <small>
                  By clicking 'Accept' you are agreeing to our terms and
                  conditions.
                </small>
                {/* <a className="button" href="#">
                  Accept
                </a> */}
              </div>
            </section>
          </main>
        </Grid>
      </>
    );
  }
}

export default PrivacyPolicy;
