import React, { Fragment } from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EditorState, convertToRaw, ContentState, convertFromHTML, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
const plusButton = require("./plus-top-bar.png");
import * as SparkMD5 from 'spark-md5';
import Slider from '@material-ui/core/Slider';
import { imgFileTemplate } from "./Assets";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReactS3Uploader from "react-s3-uploader";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './styles/scrollbar.css'
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
export const configJSON = require("./config");
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
// import HomeScreen from './HomeScreen'
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
// card imports

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { red } from "@material-ui/core/colors";
import { withTranslation, Trans } from "react-i18next";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { date } from "yup";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Tooltip from '@material-ui/core/Tooltip';
import _ from "lodash";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';
// import './styles/styles.css'
var now = new Date();
var hour = now.toUTCString();
var minutes = now.getMinutes();
var time = now.toTimeString();


// var hour = now.getUTCHours() + 2;

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();
const downIcon = require("./down.png");
// const logo = require("../../../components/src/volkswagen.svg")
const logo = require('../../components/src/volkswagen.svg')
const userImage = require("./defaultuser.png");
import SendIcon from '@material-ui/icons/Send';

const CancelIcon = require("./close_white.png");
const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});

const PrettoSlider = withStyles({
  root: {
    color: '#1a76b0',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    top: "30px",
    left: "-30px",
    padding: "24px 13px 30px 28px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);

const styles = {
  name: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    // margin: "10px"
    marginTop: "10px",

    // boxShadow: "none",
    // background:" linearGradient(to bottom, black 50%, white 50%)"
    // background: '-webkit-linear-gradient(top, white 80%, #e4e8f0 20%);'
  },
  filterButton: {

  },
  accordianStyle: {
    boxShadow: "none",
  },
  fileName: {
    fontSize: "14px",
    margin: "8px"
  },
  banners: {
    marginTop: "30px",
    marginLeft: "50px",
    backgroundColor: '#f5f6fa'
  },
  labelTextForBriefingFields: {
    width: "484px",
    height: "19px",
    margin: "10px 13px 4px 1px",
    fontFamily: "WorkSans",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineSeight: "normal",
    letterSpacing: "normal",
    color: "#282828",
  },
  TaskViewTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: 'center',
    alignItems: "center",
    color: "#000000",
    padding: "20px 20px",
  },
  // tab: {
  //   minWidth: 10,
  // },
  date: {
    fontSize: "12px",
    marginLeft: "190px"
  },
  progress: {
    display: "flex",
    flexDirection: "row"
  },
  fileSize: {
    fontSize: "10px",
    margin: "8px"
  },
  projectDocument: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px",
  },
  selectInput: {
    marginBottom: "20px",
  },
  projectDocuments: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px"
  },
  members: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginLeft: "20px"
    // position:"relative"
  },
  modalUserName: {
    marginTop: "-10px",
    marginLeft: "13px",
  },
  modalUserEmail: {
    marginTop: "10px",
    marginLeft: "-83px",
    color: "#666666"

  },
  dialogueTitle: {
    height: "30px"
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  confirmInput: {
    marginTop: "15px",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  listHeader: {
    display: 'flex'
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  checkdecoration: {
    textDecoration: 'line-through'
  },
  circle: {
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circleList: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  countCircle: {
    background: '#ffd4d4',
    borderRadius: '50%',
    marginLeft: "70%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  listAvatar: {
    height: '25px',
    width: "25px",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    color: "black"
  },
  qaFeedback: {
    background: '#c8e7fa',
    borderRadius: '50%',
    marginLeft: "50%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  inProgress: {
    background: '#f6f0ba',
    borderRadius: '50%',
    marginLeft: "55%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  //   ''.MuiAvatar-root':{
  // height:"30px !important",
  // width:"30px !important"
  //   },

  doneCards: {
    background: '#c7f9c5',
    borderRadius: '50%',
    // marginLeft: "70%",
    marginTop: "5%",
    width: ' 24px',
    height: '24px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: "15px"
  },

  inputSearchBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    //     avatar:{
    // display:"inline-flex !important"
    //     },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  root: {
    minWidth: 75,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 8,
  },
  dlgpaper: {
    minWidth: "800px"
  },
  templateHover: {
    backgroundColor: "rgb(52, 63, 86)",
  },
  scrollbarSize: {
    '&::-webkit-scrollbar': {
      width: "5px!important"
    },
    '&::-webkit-scrollbar-track': {
      background: "#f1f1f1"
    },
    '&::-webkit-scrollbar-thumb': {
      background: "#888"
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: "#555"
    }
  },
  activitySpacing: {
    // marginLeft:"20px",
    width: "100%",
  },
  activityCardRoot: {
    width: "100%",
    backgroundColor: "#e4e8f0",
    height: "auto",
  },
  allActivityCard: {
    boxShadow: "none",

  },
  tab: {
    minWidth: 10,
    textTransform: "none",

  },
  activitiesCicle: {
    background: 'white',
    borderRadius: '50%',
    border: "1px solid #e4e8f0",
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  activitiesCalenderRow: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "start",

  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  footer: {
    verticalAlign: "bottom",
    position: "absolute",
    bottom: "40px",
  },
  main_header: {
    position: "relative",
    height: "100%",
    overflowY: "auto",
    overflowx: "hidden",
  },
  footer1: {
    bottom: "20px",
    position: "fixed",
    //  height: '100px',
    width: "30%",
    "& textarea": {
      border: "none",
      outline: "none",
      width: "100%"
    }
    // left: "15%"
  },
  welcomeQuote: {
    // fontFamily:'WorkSans-Regular',
    fontSize: "14px",
    color: "#282828",
  },
  welcomeMessage: {
    // fontFamily:"WorkSans-Regular",
    fontSize: "14px",
  },
  message: {
    height: "auto",
  },
  quickAccess: {
    // fontFamly: "WorkSans-Medium",
    fontSize: "12px",
  },
  notificationSpacing: {
    width: "100%",
  },
  cardRoot: {
    backgroundColor: "#e4e8f0",
    width: "300px",
  },
  notificationCardRoot: {
    backgroundColor: "#e4e8f0",
    //height: "auto",
    height: "81vh",
    overflow: "hidden",
    minWidth: '350px'
  },
  innerCardRoot: {
    width: "auto",
    height: "auto",
    marginTop: "20px",
    borderRadius: "8px"
  },
  secondInnerCardRoot: {
    width: "auto",
    height: "auto",
    marginTop: "20px",
    backgroundColor: "#e4e8f0",
    boxShadow: "none",
  },
  thirdInnerCardRoot: {
    width: "auto",
    height: "auto",
    marginTop: "20px",
    backgroundColor: "#e4e8f0",
    boxShadow: "none",
  },
  allNotificationCard: {
    boxShadow: "none",

  },
  notifications: {
    fontFamly: "WorkSans-Medium",
    fontSize: "12px",
  },
  firstCard: {
    height: "272px",
    width: "265px",
    borderRadius: "8px",
    marginBottom: "10px",
    cursor: "pointer",
    position: "relative",
    overflowX: "hidden"
  },

  accordion: {
    boxShadow: "none",
  },
  quickAccessHeight: {
    height: "100vh",
  },
  notificationCicle: {
    background: 'white',
    borderRadius: '50%',
    border: "1px solid #e4e8f0",
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  notificationCalenderRow: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "start",

  },
  heading: {
    fontSize: "14px",
    // fontFamily: "WorkSans",

    fontWeight: " 500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: " #282828",
    display: "flex",
    alignItems: "center",
    ".MuiAccordionDetails-root": {
      "p": {
        width: "100%"
      }
    }
  },
  indicator: {
    backgroundColor: localStorage.getItem("secondarykey") ? JSON.parse(localStorage.getItem("secondarykey") || `{}`).hex : "#173e59"
  },
  addScrollBarHome: {
    width: "100%"
  },
  notificationCardHover: {
    '&:hover': {
      backgroundColor: "#fff",
      boxShadow: "0 3px 3px rgba(0,0,0,0.2)"
    }
  },
  // notificationCardRoot: {
  //   '&::-webkit-scrollbar': {
  //     width: "5px!important"
  //   },
  //   '&::-webkit-scrollbar-track': {
  //     background: "#f1f1f1"
  //   },
  //   '&::-webkit-scrollbar-thumb': {
  //     background: "#888"
  //   },
  //   '&::-webkit-scrollbar-thumb:hover': {
  //     background: "#555"
  //   }
  // }
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },

  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    width: "72px",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  highlightText: {
    padding: "5px 5px 5px 0px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
    zIndex: "9999"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  addScrollBarFirstHome: {
    width: "100%"
  },
  addScrollBarNotificationHome: {
    height: "100% !important"
  },
  addScrollBarNotificationHome2: {
    height: "100%"
  }

} as any;

interface Props {
  navigation: any;
  id: string;
  classes: any;
  value: 0;
  selectedIndex: 1;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {
  value: number;
  messageError: any;
  isEditing: any;
  isEditiingAuthor: any;
  messageQuote: any
  userName: any;
  pageNo: any;
  perPage: any;
  taskData: any;
  dueToday: any;
  userId: any;
  TaskRequestor: any;
  notificationData: any;
  teamBased: any;
  approvalNotification: any;
  toDo: any;
  author: any;
  notificationArrow: any;
  quickAccessArrow: any;
  dueTodayAccess: any;
  setOpen2: any;
  currentTaskBriefingData: any;
  isCurrentTaskSelected: any;
  currentTask: any;
  fileName: any;
  permissions: any;
  fileToRawBinary: any;
  editorState: any;
  fileTaskName: any;
  accountsData: any;
  percentage: any;
  convertedCommentData: any;
  taskStatusesData: any;
  getTaskCommentsData: any;
  selectedProjectsMentionsAccountsData: any;
  subTasksData: any,
  blockersData: any,
  columnOrderKanban: any,
  columnsKanban: any,
  dynamicStatus: any,
  dynamicMessages: any,
  unstartedTasksData: any,
  projectNameSelectedShow: any,
  projectDescriptionSelectedShow: any,
  openTasksData: any,
  dueDate: any;
  resp: any;
  openTaskBlockerCreateModel: any;
  openSubTaskCreateModel: any;
  setOpen: any;
  currentTasks: any;
  currentTaskBriefingDatas: any;
  isCurrentTaskSelecteds: any;
  isComingFromBriefingForm: boolean;
  openTaskCommentPopUp: any;
  editComment: any,
  commentId: any,
  convertedCommentData2: any,
  initialValue: any,
  specificTaskComment: any;
  setOpenImageDialog: boolean;
  fileId: any,
  file_blob_signed_id_url: string,
  fileImage: any;
  date: any,
  showlabel: boolean,
  loading: boolean,
  blink: any
}

interface SS { }



const initialData = {
  tasks: {
    'task-1': { id: 'task-1', content: 'Take out the garbage' },
    'task-2': { id: 'task-2', content: 'Watch my favorite show' },
    'task-3': { id: 'task-3', content: 'Charge my phone' },
    'task-4': { id: 'task-4', content: 'Cook dinner' },
  },
  columns: {
    'UnStarted': {
      id: 'UnStarted',
      title: 'UnStarted',
      // taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
      tasks: [],

    },
    'Open': {
      id: 'Open',
      title: 'Open',
      // taskIds: [],
      tasks: []
    },
  },
  // Facilitate reordering of the columns
  columnOrder: [{
    id: 'UnStarted',
    title: 'UnStarted',
    messageId: '',

  },
  {
    id: 'Open',
    title: 'Open',
    messageId: '',

  },],

};

class HomePage extends BlockComponent<Props, S, SS> {
  static instance: HomePage;
  callUpdateTaskBlockerApiID: any;
  callUpdateSubTaskApiID: any;
  callCreateSubTaskApiID: any;
  getSignedURLForFileUploadId2: any;
  callCreateTaskBlockerApiID: any;
  subtaskAPiEndPointID: any;
  blockersAPiEndPointID: any;
  callCreateTaskCommentListApiID: any;
  callShowTaskCommentApiId: any;
  callToBulkFileUpload: any;
  openTaskListApiID: any;
  getTaskFileId: any;
  updateTaskAPIId: any;
  unstartedTaskListApiID: any;
  callGetAccountListApiId: any;
  createAccountApiCallId: any;
  callStateTransitionsAPIId: any;
  callGetStatusListApiId: any;
  getSignedURLForFileUploadId: any;
  apiSendQuoteCallId: string = "";
  apiAuthorCallId: string = "";
  createWelcomeApiCallId: string = "";
  getQucikId: string = "";
  dueToday: string = "";
  notificationApiCallId: string = "";
  dueDateApiCallId: string = "";


  statusUpdateToRequestQuoteId: string = "";
  callUpdateTaskQuotationApiID: string = "";
  callShowTaskApiId: string = "";

  getTaskFileSingleId: string = "";
  callUpdateTaskCommentID: string = "";
  showTaskCommentSpecificId: string = "";
  fileInput: any
  fileInput1: any
  fileInput2: any
  fileInput3: any
  constructor(props: Props) {
    super(props);
    this.fileInput = React.createRef();
    this.fileInput1 = React.createRef();
    this.fileInput2 = React.createRef();
    this.fileInput3 = React.createRef();
    //console.log(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ]
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      isCurrentTaskSelecteds: false,
      openSubTaskCreateModel: false,
      openTaskBlockerCreateModel: false,
      resp: null,
      dueDate: null,
      openTasksData: [],
      unstartedTasksData: [],
      projectNameSelectedShow: "",
      projectDescriptionSelectedShow: "",
      dynamicMessages: {},
      dynamicStatus: {},
      value: 0,
      messageError: "",
      isEditing: false,
      isEditiingAuthor: false,
      messageQuote: "",
      userName: '',
      pageNo: 1,
      perPage: 100,
      taskData: [],
      dueToday: [],
      notificationData: [],
      userId: '',
      TaskRequestor: '',
      teamBased: [],
      approvalNotification: [],
      toDo: [],
      author: null,
      notificationArrow: true,
      quickAccessArrow: true,
      dueTodayAccess: true,
      setOpen2: false,
      currentTaskBriefingData: [],
      isCurrentTaskSelected: false,
      currentTask: {},
      fileName: "",
      permissions: "",
      fileToRawBinary: {},
      editorState: EditorState.createEmpty(),
      fileTaskName: [],
      accountsData: [],
      percentage: 0,
      convertedCommentData: {},
      taskStatusesData: [],
      getTaskCommentsData: [],
      selectedProjectsMentionsAccountsData: [],
      subTasksData: [],
      blockersData: [],
      columnOrderKanban: initialData.columnOrder,
      columnsKanban: {},
      setOpen: false,
      currentTasks: {},
      currentTaskBriefingDatas: [],
      isComingFromBriefingForm: false,
      openTaskCommentPopUp: false,
      editComment: EditorState.createEmpty(),
      commentId: "",
      convertedCommentData2: {},
      initialValue: EditorState.createEmpty(),
      specificTaskComment: "",
      setOpenImageDialog: false,
      fileId: 1,
      file_blob_signed_id_url: "",
      fileImage: "",
      date: new Date(),
      showlabel: true,
      loading: false,
      blink: null
    };

    HomePage.instance = this;
  }

  inputFileChanged = (e: any) => {
    // console.log(this.fileInput.current.files[0]);
    //console.log("File1", e.target.files[0])
    this.getSignedUrl(e.target.files[0]);
    let value = {
      photo: URL.createObjectURL(e.target.files[0]),
    };
    //console.log("File2", value)
    //  this.setState(
    //    {
    //      imgs: URL.createObjectURL(e.target.files[0]),
    //    },
    //    () => {
    //      // this.addAccountProfile(value, "image");
    //      console.log("imgs", (e.target));

    //    }
    //  );

    // this.addAccountProfile(this.state.imgs,"image")
  };
  onEditorStateChange = (editorState?: any) => {

    //console.log("editorState", editorState);

    this.setState({
      editorState: editorState
    });
    this.setState({
      convertedCommentData: convertToRaw(this.state.editorState.getCurrentContent())
    });
    //console.log("convertedData", this.state.convertedCommentData);
  }
  handleChangeBlockerSelection = (task?: any, value?: any) => {

    // this.setState({
    //   is_completedBlocker: event.target.checked as boolean,
    //   taskBlockerId: event.target.value

    // });
    //console.log("locker checkbox", task, value);
    this.callUpdateTaskBlockerApi(task.id, value);
    //console.log("locker", task.id, value);

  };
  handleReviewerChange = (event: any, value: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.reviewer_id = value.id,
      this.setState({
        currentTask: currentTask,
      });
    this.callUpdateTaskApi(currentTask);


  };
  handleClickCancelNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: false,
    });

  };
  handleClickOpenTaskModal = (task: any) => {
    //console.log("task", task);
    this.callGetSubTaskListApi(task.id),
      this.callGetTaskBlockerListApi(task.id),
      this.callShowTaskCommentApi(task.id)

    this.setState({
      setOpen2: true,
      //  openAddBriefingModel: false,
      isCurrentTaskSelected: true,
      currentTask: task,
      currentTaskBriefingData: task?.attributes?.briefing?.data?.attributes?.briefed_values?.data,

    },
      () => {
        this.getListTaskFileIndex();
        //console.log("Current Task", this.state.currentTask) 
      }
    )

  }
  handleOpenDueDayTaskModal = (task: any) => {
    //console.log("task", task);
    this.callGetSubTaskListApi(task.id),
      this.callGetTaskBlockerListApi(task.id),
      this.callShowTaskCommentApi(task.id)

    this.setState({
      setOpen: true,
      isCurrentTaskSelecteds: true,
      currentTask: task,
      currentTaskBriefingData: task.attributes?.briefing?.data?.attributes?.briefed_values?.data,

    },
      () => {
        this.getListTaskFileIndex();
        //console.log("Current Task", this.state.currentTask) 
      }
    )
  }

  handleCloseTaskModal = () => {
    this.setState({
      setOpen2: false,
      isCurrentTaskSelected: true,
      setOpen: false,
      isCurrentTaskSelecteds: false,
    })
  }
  handleDateChange = (event: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.estimated_end_date = event.target.value,
      this.setState({
        dueDate: event.target.value as string,
        currentTask: currentTask,
      }, () => {
        this.callUpdateTaskApi(currentTask);
      });


  };

  convertItemtitle = (d: any) => {
    if (d?.length > 22) {
      let tword = d.substring(0, 22) + "..."
      return tword
    } else {
      return d
    }
  }

  convertItemCategory = (d: any) => {
    if (d?.length > 24) {
      let tword = d.substring(0, 24) + "..."
      return tword
    } else {
      return d
    }
  }

  cancelSpecificTaskComment = () => {
    this.setState({ openTaskCommentPopUp: false })
  }

  taskFileShow = (taskfileId: any) => {
    const header = {
      "Content-Type": configJSON.getTaskFileSingleContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileSingleId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileSingleApiEndPoint + taskfileId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileSingleMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleInputChange = (e?: any) => {
    //console.log("handleInputChange", e.target.value);


    this.setState({
      messageQuote: e.target.value,

    })
  }
  handleAuthorInputChange = (e?: any) => {
    //console.log("handleInputChange", e.target.value);


    this.setState({
      author: e.target.value,

    })
    console.log(this.state.author)
  }

  progressPercentage = (e?: any, value?: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.progress = value
    this.setState({
      percentage: value,
      currentTask: currentTask
    })
    this.callUpdateTaskApi(currentTask);

  }
  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }
  getSignedUrl = (file: any) => {
    //console.log("File", file)
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    this.computeChecksumMd5(file).then((md5) => {
      this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    });
  }



  //task  quotation update api
  callUpdateTaskQuotationApi(taskQuotationId?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      action: "approve_quote"
    };

    const httpBody = {
      data: data
    };

    const requestUpdateTaskQuotationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskQuotationApiID = requestUpdateTaskQuotationMessage.messageId;
    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskQuotationUpdateAPiEndPoint + `/${taskQuotationId}`
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskQuotationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskQuotationUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskQuotationMessage.id, requestUpdateTaskQuotationMessage);



  }


  statusUpdateToRequestQuote = (taskId: any) => {
    const header = {
      "Content-Type": configJSON.briefingUpdateStatusApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: {
        action: "submit_briefing",
        request_quote: true
      }
    };

    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statusUpdateToRequestQuoteId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.briefingUpdateStatusAPiEndPoint + '/' + taskId
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.briefingUpdateStatusAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);
  }

  openSpecificTaskComment = (commentId: any) => {
    this.setState({ openTaskCommentPopUp: true, commentId: commentId }, () => { this.showTaskCommentSpecific(commentId) })
  }


  showTaskCommentSpecific(commentID: any) {
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTaskCommentSpecificId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentSpecificCommentAPiEndPoint + commentID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentSpecificCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task comment update api
  callUpdateTaskCommentApi(convertedCommentData: any) {
    var comment = draftToHtml(convertToRaw(this.state.editComment.getCurrentContent()))
    let ArrayObjects: any = [];
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.taskCommentUpdateApiContentType,
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,

    }
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callUpdateTaskCommentID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentUpdateAPiEndPoint + `/${this.state.commentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  //status index api
  callGetStatusListApi() {

    const userDetails = localStorage.getItem("userInfo")
    const selectedProjectId: any = localStorage.getItem("selectedProjectId")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetStatusListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_customised_order_status/task_statuses" + `?project_id=${selectedProjectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //state transition create api
  callStateTransitionsAPI(action?: any, taskId?: any) {


    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const attrs = {
      "action": action
    };

    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callStateTransitionsAPIId = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_projecttasktracking/state_transitions/"
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  //fie upload API api
  getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/s3_uploads/get_presigned_url"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  handleChange = (event: any, value: any) => {
    // setValue(newValue);
    //console.log(value);
    this.setState({
      value: value,
    });
    // this.statvalue=value
  };
  handleClickCreateNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: true,
    });

  };
  sendMessageQuote = () => {
    this.setState({
      isEditing: false
    })
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };


    const data = {
      text: this.state.messageQuote,
      author: this.state.author
    };

    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendQuoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landing_page/messages/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  sendauthor = () => {
    this.setState({
      isEditiingAuthor: false
    })
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };


    const data = {
      text: this.state.messageQuote,
      author: this.state.author
    };

    const httpBody = {
      data: data
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendQuoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landing_page/messages/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getBasedNotifications(value?: any) {

    let teamBased = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "team"

    })
    //console.log(teamBased, "teamBased")
    this.setState({
      teamBased: teamBased
    })
    let approval = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "approval"
    })
    this.setState({
      approvalNotification: approval
    })
    let toDo = value.filter(function (e?: any) {
      return e.attributes?.target_user_entity == "todo"
    })
    this.setState({
      toDo: toDo
    })
  }
  getUserName() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      this.setState({
        userName: JSON.parse(userDetails).first_name
      })


    }
  }
  loadUserDetailsAndRoles() {
    const userDetailsBeforeParse = localStorage.getItem("permissions")
    if (userDetailsBeforeParse) {
      this.setState({ permissions: userDetailsBeforeParse });
    }
  }
  async componentDidMount() {
    this.loadUserDetailsAndRoles()
    this.getWelcomeMessage()
    this.getUserName()
    this.getQuickAccess()
    this.getDueToday()
    this.getMessageIndex()
    this.getNotifications()
    this.callGetAccountListApi('')
    setInterval(() => {
      this.setState({
        date: new Date()
      })
      if (this.state.blink == " ") {

        this.setState({
          blink: ":"
        })
      }
      else {
        this.setState({
          blink: " "
        })
      }

    }, 1000)



    // this.send(new Message(getName(MessageEnum.RestAPINotificationMessage)))
    //  this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }


  getMessageIndex() {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createWelcomeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landing_page/messages?page=${this.state.pageNo}&per_page=${this.state.perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getNotifications() {


    var userId = localStorage.getItem("userId")

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/alert_notifications?target_user_id=${userId}&page=1&per_page=60 `
      // `bx_block_notifications/alert_notifications?target_user_id=19`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getWelcomeMessage() {
    const userDetails = localStorage.getItem("userId")
    if (userDetails) {
      this.setState({
        userId: userDetails
      })


    }
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createWelcomeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landing_page/messages/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getQuickAccess() {


    var userId = localStorage.getItem("userId")

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQucikId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks?page=${this.state.pageNo}&per_page=${5}&user_id=${userId}&ongoing_type=ongoing`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setQuotes = (value?: any) => {
    //console.log(Array.isArray(value), "value")
    if (Array.isArray(value) == true) {
      value?.map((item: any, index: any) => {
        this.setState({
          messageQuote: item.attributes?.text,
          author: item.attributes?.author
        })
      })
    } else {
      this.setState({
        messageQuote: value.attributes?.text,
        author: value.attributes?.author
      })
    }

  }

  getTaskRequestor(value?: any) {
    //console.log(value[0], "requestor")
    value.map((item: any) => {
      let firstName = item.attributes.requestor.data.attributes.first_name
      let lastName = item.attributes.requestor.data.attributes.last_name
      let userName = firstName.charAt(0) + lastName.charAt(0)
      this.setState({
        TaskRequestor: userName
      })
    })
  }
  getDueToday() {
    const userDetails = localStorage.getItem("userId")
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var date = dd + '/' + mm + '/' + yyyy;


    if (userDetails) {
      var userId = userDetails
      //console.log(userId, "userId")

    }
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dueDateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks?page=${this.state.pageNo}&user_id=${userDetails}&estimated_end_date=${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //task index api

  callGetUnstartedTaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    //console.log("brandhID", userDetails)

    if (this.state.permissions !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer")) {
      //console.log("brandhID", userDetails)

      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id
        //console.log("brandhID", userDetails)

      }
    } else {
      var brand_id: any = localStorage.getItem("selectedBrandID");
      //console.log("brandhID", userDetails)

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unstartedTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/sub_tasks/" + `?page=${1}&per_page=${16}&status_type=unstarted&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  //account  index api
  callGetAccountListApi(nameofUser: any) {

    const userDetails: any = localStorage.getItem("userInfo")
    var brand_id: any = JSON.parse(userDetails).brand_id;
    // if (this.state.permissionName !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer")) {

    //   if (userDetails) {
    //     var brand_id: any = JSON.parse(userDetails).brand_id
    //     console.log("brandhID",userDetails)

    //   }
    // } 
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetAccountListApiId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
    //   //first_name=&last_name&email&username&brand_id`
    // );

    if (nameofUser === '') {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "account_block/accounts" + `?page=${1}&per_page=${20}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "account_block/accounts" + `?page=${1}&per_page=${21}`
          //first_name=&last_name&email&username&brand_id`
        );
      }

    } else {
      if (brand_id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "account_block/accounts" + `?page=${1}&&user=${nameofUser}&per_page=${22}&&brand_id=${brand_id}`
          //first_name=&last_name&email&username&brand_id`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "account_block/accounts" + `?page=${1}&&user=${nameofUser}&per_page=${23}`
          //first_name=&last_name&email&username&brand_id`
        );
      }

    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  callGetOpenTaskListApi(projectID?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (this.state.permissions !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer")) {
      if (userDetails) {
        var brand_id: any = JSON.parse(userDetails).brand_id

      }
    } else {
      var brand_id: any = localStorage.getItem("selectedBrandID");

    }


    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openTaskListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks" + `?page=${1}&per_page=${16}&ultimate_state=Open&brand_id=${brand_id}&project_id=${projectID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  callGetOngoingTaskListApi(projectID?: any, status?: any) {
    if (status.id !== "UnStarted" && status.id !== "Open") {
      const userDetails = localStorage.getItem("userInfo")
      if (this.state.permissions !== "albertbauer" && (this.state.permissions === "approver" || this.state.permissions === "viewer")) {
        if (userDetails) {
          var brand_id: any = JSON.parse(userDetails).brand_id

        }
      } else {
        //console.log("localStorage.getItem", localStorage.getItem("selectedBrandID"))
        var brand_id: any = localStorage.getItem("selectedBrandID");

      }
      const header = {
        token: localStorage.getItem("token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      status.messageId = requestMessage.messageId
      var dynamicMessages = this.state.dynamicMessages;
      dynamicMessages[status.id] = status
      this.setState({
        dynamicMessages: dynamicMessages

      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_project_portfolio/tasks" + `?page=${1}&per_page=${16}&ultimate_state=${status.id}&brand_id=${brand_id}&project_id=${projectID}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      // requestMessage.addData(
      //   getName(MessageEnum.RestAPIRequestBodyMessage),
      //   JSON.stringify(httpBody)
      // );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


    }


  }

  //task update api
  callUpdateTaskApi(currenttask: any) {
    //console.log("currenttask", currenttask, this.state.currentTask)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: currenttask.attributes,
    };
    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskAPIId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/tasks/" + `${currenttask.id}`
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);



  }


  onEditorStateCommentTaskSpecific = (editorState?: any) => {
    let contentState = convertFromHTML('<p>Hello</p>');
    const content = ContentState.createFromBlockArray(
      contentState.contentBlocks,
      contentState.entityMap
    );
    this.setState({
      initialValue: EditorState.createWithContent(content),
      editComment: editorState
    });
    this.setState({
      convertedCommentData2: convertToRaw(this.state.editComment.getCurrentContent())
    });
  }

  getListTaskFileIndex = () => {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_upload_media/task_files?task_id=" + this.state.currentTask.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  taskFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    var data;
    if (localStorage.getItem("task") == "2") {
      data = {
        task_id: parseInt(this.state.currentTask.id),
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: false,
              source_file: true,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "1") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: true,
              source_file: false,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }
    else if (localStorage.getItem("task") == "0") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: [
            {
              name: this.state.fileToRawBinary?.name,
              status: this.state.currentTask.attributes.status,
              final_file: false,
              source_file: false,
              file_blob_signed_id: responseJson.data[0]?.signed_id
            }
          ]
        }
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading/task_bulk_uploads"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  //task comment create api
  //task comment create api
  callCreateTaskCommentListApi(convertedCommentData?: any) {

    //console.log(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log(this.state.editorState.getCurrentContent().getPlainText());
    var comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    let ArrayObjects: any = [];
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,
      // file_blob_signed_id: this.state.presignedData?.signed_id,
      textual_description: this.state.editorState.getCurrentContent().getPlainText()
    }



    const httpBody = {
      data: data
    };

    const requestCreateTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateTaskCommentListApiID = requestCreateTaskCommentMessage.messageId;
    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentCreateAPiEndPoint
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskCommentMessage.id, requestCreateTaskCommentMessage);

  }
  //sub task  show api
  callShowTaskCommentApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskCommentApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentShowAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentShowAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }


  //task show api
  callShowTaskApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskApiId = requestShowTaskMessage.messageId;
    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskShowAPiEndPoint + `${taskId}`
    );

    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskShowAPiMethod
    );

    runEngine.sendMessage(requestShowTaskMessage.id, requestShowTaskMessage);



  }


  //task Blocker index api
  callGetTaskBlockerListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockersAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/task_blockers" + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //sub task  index api
  callGetSubTaskListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subtaskAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/sub_tasks" + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //task blocker create api
  callCreateTaskBlockerApi(values?: any) {
    this.setState({
      openTaskBlockerCreateModel: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };

    const attrs = {
      // email: this.state.email,
      // password: this.state.password
      "task_id": this.state.currentTask.id,

      "description": values.description,

    };
    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callCreateTaskBlockerApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/task_blockers/"
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }
  //task  blocker update api
  callUpdateTaskBlockerApi(taskBlockerId?: any, value?: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };
    const requestUpdateTaskBlockerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskBlockerApiID = requestUpdateTaskBlockerMessage.messageId;
    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/task_blockers/" + `${taskBlockerId}`
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestUpdateTaskBlockerMessage.id, requestUpdateTaskBlockerMessage);



  }
  handleClickCreateNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: true,
    });

  };
  handleClickCancelNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: false,
    });

  };
  //sub task create api
  callCreateSubTaskApi(values?: any) {
    this.setState({
      openSubTaskCreateModel: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }


    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTask.id,
      "title": values.title,
    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateSubTaskApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/sub_tasks"
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }
  //task  subtask update api
  callUpdateSubTaskApi(subTaskId?: any, value?: any) {
    //console.log(value);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };

    const requestUpdateSubTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSubTaskApiID = requestUpdateSubTaskMessage.messageId;
    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/sub_tasks/" + `${subTaskId.id}`
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestUpdateSubTaskMessage.id, requestUpdateSubTaskMessage);



  }
  async receive(from: string, message: Message) {
    //console.log("receive function");
    //console.log(message, "message");
    if (message.properties?.RestAPIResponceSuccessMessage?.data != null) {
      var messages = message.properties?.RestAPIResponceSuccessMessage?.data
      this.setQuotes(messages)
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.showTaskCommentSpecificId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ specificTaskComment: responseJson?.data?.attributes.description }, () => {
              const html = this.state.specificTaskComment
              const contentBlock = htmlToDraft(html);
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editComment: editorState })
              }
            });
            //console.log("Dtaa from the response", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskCommentID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.clear();
            toast.success("Task Comment Updated Successfully");
            this.cancelSpecificTaskComment();
            this.callShowTaskCommentApi(this.state.currentTask.id);
            //console.log("Comment Updated", responseJson)

          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(responseJson.data.id);
            this.callGetTaskBlockerListApi(responseJson.data.id);
            this.setState({
              isCurrentTaskSelected: true,
              currentTask: responseJson.data,
              currentTaskBriefingData: responseJson.data.attributes.briefing.data.attributes.briefed_values.data

            }, () => {
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.statusUpdateToRequestQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.callGetStatusListApi();
          this.callShowTaskApi(responseJson.data.attributes.task_id);
          this.setState({ isComingFromBriefingForm: false });
        } else {
          //console.log("else")
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskQuotationApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Quote Approved successfully");
            // if (this.state.selectedProjectStatus === "New" || this.state.selectedProjectStatus === "Quote Approved"
            //   || this.state.selectedProjectStatus === "Briefing Completed" || this.state.selectedProjectStatus === "Pending Quote Approval") {
            // this.getApprovedQuote()
            // this.getOpenQuote()
            // this.getPendingQuote()

            // } else {

            this.callGetStatusListApi();
            // }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(this.state.currentTask.id)
            toast.success("Sub task updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.callGetSubTaskListApi(this.state.currentTask.id),
            toast.success("Subtask created  successfully");
          this.setState({
            subTasksData: responseJson.sub_tasks?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetTaskBlockerListApi(this.state.currentTask.id)
            toast.success("Blocker updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.callGetTaskBlockerListApi(this.state.currentTask.id)
          toast.success("Blocker created  successfully");
          this.setState({
            blockersData: responseJson.task_blockers?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.subtaskAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.setState({
            subTasksData: responseJson.sub_tasks.data,

          })
        }
      }
      else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.blockersAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            blockersData: responseJson.task_blockers?.data,

          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskCommentApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("Response From the task comment  function", responseJson)
            this.setState({ getTaskCommentsData: responseJson.task_comments.data })

          }
          //console.log(this.state.getTaskCommentsData);
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskCommentListApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("rreess", responseJson)
            toast.success("Commented successfully");
            this.setState({ editorState: EditorState.createEmpty() }, () => {
              this.callShowTaskCommentApi(responseJson?.data?.attributes?.task_id)

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToBulkFileUpload) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("file uploaded successfully")
            this.setState({ fileTaskName: responseJson?.data })
            this.getListTaskFileIndex()
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            this.setState({ resp: responseJson }, () => {
              this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSignedURLForFileUploadId2) {
      if (localStorage.getItem("task") == "2") {
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "1") {
        this.taskFileUploadBulk(this.state.resp);
      }
      else if (localStorage.getItem("task") == "0") {
        this.taskFileUploadBulk(this.state.resp);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ fileTaskName: responseJson?.task_files?.data })
            // console.clear()
            //console.log("File Data Bulk Upload", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.updateTaskAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Task Data updated successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.openTaskListApiID) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data) {

        var columns = this.state.columnsKanban
        columns["Open"].tasks = message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data
        this.setState({
          columnsKanban: columns,
          openTasksData: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
        }, () => {
        })
        //console.log("vopenTasksData", this.state.openTasksData);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              accountsData: responseJson?.accounts?.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.unstartedTaskListApiID) {
      //console.log("messagemessage", message)
      if (message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data) {
        var columns = this.state.columnsKanban
        columns["UnStarted"].tasks = message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data
        this.setState({
          columnsKanban: columns,
          unstartedTasksData: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
          projectNameSelectedShow: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data[0]?.attributes?.project_name,
          projectDescriptionSelectedShow: message?.properties?.RestAPIResponceSuccessMessage?.tasks?.data[0]?.attributes?.project_desciption,
        }, () => {
        })
        //console.log("unstartedTasksData", this.state.unstartedTasksData);
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetStatusListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            var columnOrder: any = this.state.columnOrderKanban
            var dynamicStatus: any = []
            var columns: any = this.state.columnsKanban
            var dataTest: any = [
              {
                id: "1",
                attributes: {
                  status: "UnStarted",
                  action: "unstarted"

                }
              },
              {
                id: "2",
                attributes: {
                  status: "Open",
                  action: "open"
                }
              },
              ...responseJson.statuses.data,
            ]

            this.setState({
              taskStatusesData: dataTest,
            }, () => {
              dataTest.map((item: any) => {

                dynamicStatus.push({ "id": item.attributes.status, "action": item.attributes.action, "title": item.attributes.status, "messageId": '' })
                columns[item.attributes.status] = {
                  id: item.id,
                  title: item.attributes.status,
                  action: item.attributes.action,
                  tasks: [],
                  messageId: ''
                }
              })
            })
            this.setState(function (state, props) {
              return {
                dynamicStatus: columns,
                columnOrderKanban: dynamicStatus,
                columnsKanban: columns,
                dynamicMessages: columns,
              };
            });
            const selectedProjectId: any = localStorage.getItem("selectedProjectId")
            if (selectedProjectId !== null) {
              this.callGetUnstartedTaskListApi(selectedProjectId);
              this.callGetAccountListApi('');
              this.callGetOpenTaskListApi(selectedProjectId);
              dynamicStatus.map((column: any) => {
                this.callGetOngoingTaskListApi(selectedProjectId, column)
              })

            }

          }

        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callStateTransitionsAPIId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("State changed successfully");
            this.callGetStatusListApi();
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].status);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getQucikId) {
      if (message.properties?.RestAPIResponceSuccessMessage?.tasks?.data != null) {
        //console.log(message.properties?.RestAPIResponceSuccessMessage?.tasks?.data, "check")
        this.setState({
          taskData: message.properties?.RestAPIResponceSuccessMessage?.tasks?.data,
        }, () => {
          //console.log(this.state.taskData, "taskData")
          this.getTaskRequestor(this.state.taskData)
        })
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.dueDateApiCallId)
      if (message.properties?.RestAPIResponceSuccessMessage?.tasks?.data != null) {

        this.setState({
          dueToday: message.properties?.RestAPIResponceSuccessMessage?.tasks?.data
        }, () => {
          //console.log(this.state.dueToday, "dueToday")
        })
      }

    if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
      var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
      // errorMessage.forEach((element: any) => {
      //   this.setState({
      //     messageError: element.message
      //   })

      // });
      //console.log(this.state.messageError, "error")
      toast.error(this.state.messageError)

    }
    if (message.properties?.RestAPIResponceSuccessMessage?.alert_notifications) {
      this.setState({
        notificationData: message.properties?.RestAPIResponceSuccessMessage?.alert_notifications?.data
      }, () => {
        this.getBasedNotifications(this.state.notificationData)
        //console.log(this.state.notificationData, "notificationData")
      })

    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            // category list



            //search live


            //recent search

          }
        }
      }
    }


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }



  }
  convertTZ(date: any, tzString: any) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  handleNextOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    if (parseInt(value) == 4) {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      localStorage.setItem("toShowOnboarding", "false");
      window.location.reload();
    } else {
      localStorage.setItem("onBoardingStep", (parseInt(value) + 1).toString())
      this.forceUpdate();
    }
  }

  handlePrevOnboarding = () => {
    let value: any = localStorage.getItem("onBoardingStep");
    localStorage.setItem("onBoardingStep", (parseInt(value) - 1).toString())
    this.forceUpdate();
  }

  handleSkipOnboarding = () => {
    localStorage.setItem("onBoardingStep", "5")
    localStorage.setItem("toShowOnboarding", "false");
    window.location.reload();
  }

  componentDidUpdate() {

  }

  render() {
    const { t, navigation, classes } = this.props;
    const _this = this;
    let usersRole: any;

    const userDetail = JSON.parse(localStorage.getItem('userInfo') || '{}');
    let colorToBeChanged: any;

    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    //console.log(localStorage.getItem("key"), "key");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }




    // time
    var dt = this.state.date;
    userDetail?.timezone && userDetail?.timezone.length ? dt = this.convertTZ(dt, userDetail?.timezone) : null
    var h = dt.getHours(), mm = dt.getMinutes();
    var mm2 = mm.toString();
    var mmm = mm.toString();
    var h1 = h.toString();
    if (mm < 10) {
      mmm = ('0' + mm2).slice(-2)
      //console.log("mm", mmm);

    }
    if (h < 10) {
      h1 = ('0' + h1).slice(-2);
    }
    var thistime;
    var tempo = this.state.blink ? this.state.blink : ' '
    if (userDetail?.language == 'German') {
      thistime = h + ':' + mmm + ' Uhr';
    }


    else {
      thistime = (h >= 12) ? (h == 12) ? (h + ":" + mmm + ' pm') : h - 12 < 10 ? (`0` + `${h - 12}` + ":" + mmm + ' pm') : (h + ":" + mmm + ' pm') : (h1 + ":" + mmm + ' am');
    }




    var dayStatus;

    if (h < 12) {
      dayStatus = `${t("HomeScreen.HomePage.goodMorning")}`
    }
    else if (h >= 12 && h < 16) {
      dayStatus = `${t("HomeScreen.HomePage.goodAfernoon")}`
    }
    else if (h >= 16 && h < 24) {
      dayStatus = `${t("HomeScreen.HomePage.goodEvening")}`
    }
    // user

    //  while(1){
    //   setTimeout(() => {
    //     this.setState({date: new Date()})
    //   }, 50000);
    //  }


    return (
      <div style={{ fontFamily: primaryFont }}>
        <Backdrop
          className={classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={0}>
          <Grid item xs={5} className={classes.message}>
            <Grid container spacing={0}>
              <Grid item xs={1} />

              <Grid item xs={9}>
                {/* <div className={classes.footer}> */}
                <div className={classes.footer1}>
                  <p className={classes.welcomeMessage} style={{ marginBottom: "-24px" }}>
                    {dayStatus}
                    {/* {dayStatus}, */}
                    {this.state.userName}!
                  </p>
                  <h1 style={{ fontSize: "56px" }}>{thistime}</h1>

                  {(userDetail?.role?.data?.attributes?.permission == "editor" && userDetail?.role?.data?.attributes?.name == "albertbauer")
                    || userDetail?.role?.data?.attributes?.name == "superadmin"
                    || (userDetail?.role?.data?.attributes?.permission == "brand_manager" && userDetail?.role?.data?.attributes?.name == "albertbauer") ?
                    <>
                      {
                        this.state.isEditing ?
                          <div >
                            <textarea onChange={this.handleInputChange} onBlur={this.sendMessageQuote} defaultValue={this.state.messageQuote} className={classes.quoteInput} />
                          </div>
                          : <p className={classes.welcomeQuote} style={{ cursor: 'pointer' }} onDoubleClick={() =>
                            this.setState({
                              isEditing: true
                            })

                          }>   "{this.state.messageQuote}"</p>
                      }
                    </>
                    :
                    <>


                      <p className={classes.welcomeQuote} style={{ marginTop: "-25px" }}>   "{this.state.messageQuote}"</p>

                    </>

                  }
                  {(userDetail?.role?.data?.attributes?.permission == "editor" && userDetail?.role?.data?.attributes?.name == "albertbauer")
                    || userDetail?.role?.data?.attributes?.name == "superadmin"
                    || (userDetail?.role?.data?.attributes?.permission == "brand_manager" && userDetail?.role?.data?.attributes?.name == "albertbauer") ?
                    <>
                      {
                        this.state.isEditiingAuthor ?
                          <div >
                            <input onChange={this.handleAuthorInputChange} onBlur={this.sendauthor} defaultValue={this.state.author} style={{ border: 'none', outline: 'none', width: '100%' }} />
                          </div>
                          : <p style={{ cursor: 'pointer' }} onDoubleClick={() =>
                            this.setState({
                              isEditiingAuthor: true
                            })

                          }>   -{this.state.author}</p>
                      }
                    </>
                    :
                    <>


                      <p> -{this.state.author}</p>

                    </>

                  }

                </div>
              </Grid>

              <Grid item xs={1} />
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.accordian3} >
            {/* {this.state.taskData && this.state.taskData.length > 0 ?  */}
            <div>
              <HtmlTooltip
                arrow
                placement="left"
                open={localStorage.getItem("onBoardingStep") == "4" ? true : false}
                title={
                  <React.Fragment>
                    <div>

                      <span className={classes.popupTitle}>{t("onBoarding.quickAccess")}</span>
                      <span className={classes.popupCancelIcon}>
                        <CloseIcon onClick={this.handleSkipOnboarding} />
                      </span>
                    </div>
                    <div className={classes.popupText}>
                      {t("onBoarding.quickAccessText")}
                    </div>
                    <div>
                      <Button
                        className={classes.popupSkipButton}
                        onClick={this.handleSkipOnboarding}
                        color={colorToBeChanged}
                      >
                        SKIP
                      </Button>
                      {/* <Button
                                      className={classes.popupButton}
                                      onClick={this.handlePrevOnboarding}
                                    >
                                      PREV
                                    </Button> */}
                      <Button
                        className={classes.popupButton}
                        onClick={this.handleNextOnboarding}
                        style={{ background: colorToBeChanged }}
                      >
                        NEXT
                      </Button>
                    </div>
                  </React.Fragment>
                }
              >

                <div></div>
              </HtmlTooltip>
            </div>
            <Accordion className={classes.accordion} style={{ overflow: 'auto' }} defaultExpanded
              expanded={this.state.quickAccessArrow === true} onChange={(e) => this.setState(preState => ({ quickAccessArrow: !preState.quickAccessArrow }))}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading + ' ' + (localStorage.getItem("onBoardingStep") == "4" && classes.highlightText)}>
                  <div style={{ fontFamily: primaryFont }}>
                    {t("HomeScreen.HomePage.quickAccess")}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {this.state.quickAccessArrow === true ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block!important" }}>
                {this.state.taskData.length > 0 ?

                  <Typography className={classes.addScrollBarFirstHome} component="div" style={{ fontFamily: primaryFont }}>

                    <Card className={classes.cardRoot} style={{ maxWidth: "300px" }}>
                      <CardContent className="addScrollBarNotificationHome" style={{ padding: "15px", maxHeight: "57vh", overflowY: "auto" }}>
                        {
                          this.state.taskData.map((item: any, index: any) => {
                            return (

                              <>

                                <Card key={index} className={classes.firstCard} onClick={() => {
                                  //console.log("Task ID", item.id); 
                                  //localStorage.setItem("selectedProjectId", item?.attributes?.project_id)
                                  //this.handleClickOpenTaskModal(item)

                                  console.log("Task ID", item.id);
                                  if (item.id != null) {
                                    if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                      localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                    }
                                    else {
                                      localStorage.removeItem("brandGroupSelected")
                                    }
                                    (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item.id}&projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}`)
                                  }
                                }}>

                                  <CardContent >
                                    <div>

                                      <Typography className={classes.title} style={{ marginBottom: "10px" }} color="textSecondary" gutterBottom component="div">

                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                          {console.log("defghujkkkll....", item?.attributes)
                                          }
                                          <span> <p style={{ fontSize: "10px", color: "black" }}>{item?.attributes?.order_number}</p></span>
                                          <Avatar alt="Remy Sharp" src={item?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url} />


                                          {/* <Avatar className={classes.purple} style={{ fontSize: "12px", marginLeft: "50%", height: '30px', width: "30px", marginTop: "5px" }}><img src={item?.attributes?.assignee?.data?.attributes?.photo} /></Avatar> */}
                                          {/* <Avatar alt="Remy Sharp" src={item?.attributes?.assignee?.data?.attributes?.photo} /> */}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "10px" }}>
                                          <p style={{ color: "black", paddingRight: "9px", marginBottom: "10px", fontSize: "16px" }}>
                                            <strong style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                              {item?.attributes?.title}</strong></p>
                                          <span style={{ marginTop: "10px" }}>
                                            {/* <img src={logo} style={{ height: "45px", width: "45px" }} /> */}
                                            {/* <img src={item.attributes.brand_logo} style={{ height: "63px", width: "63px" }} /> */}
                                          </span>
                                          {/* <span style={{ color: "black", paddingRight: "9px", marginBottom: "10px" }}>
                                          
                                            <strong>{item?.attributes?.title}</strong> 
                                          </span> */}
                                          <span style={{
                                            color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(224,255,255)", borderRadius: "20px", marginBottom: "5px", width: "max-content", padding: "4px 10px",
                                          }}>
                                            <strong style={{ maxWidth: "201px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                              {item?.attributes?.asset_category_name}</strong>
                                          </span>
                                          <span style={{
                                            color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(175,238,238)", borderRadius: "20px", width: "max-content", padding: "4px 10px",
                                          }}>
                                            <strong style={{ maxWidth: "201px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                              {item?.attributes?.asset_type_name} </strong>
                                          </span>
                                        </div>
                                      </Typography>
                                    </div>
                                    <div >

                                    </div>
                                  </CardContent>


                                  <CardActions style={{ backgroundColor: "#e4e8f0", position: "absolute", bottom: 0, left: 0, right: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "-3%" }}>
                                      <div className={classes.circle} >
                                        <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                      </div>

                                      {item?.attributes?.estimated_end_date && item?.attributes?.estimated_end_date !== "" ?
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }} >{(item?.attributes?.estimated_end_date).split("-").reverse().join("-")} </p>
                                        :
                                        <p style={{ fontSize: "12px", marginLeft: "5px" }} >Add DueDate</p>
                                      }
                                      <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                        <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                      </div>

                                      <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_comments_count}</p>
                                      <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                        <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>

                                      </div>
                                      <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item?.attributes?.task_files_count}</p>
                                    </div>
                                  </CardActions>





                                  <CardActions />


                                </Card>


                              </>
                            )
                          })
                        }

                      </CardContent>
                      <CardActions />
                    </Card>

                  </Typography>


                  : null

                }


              </AccordionDetails>
            </Accordion>

            {/* : null */}

            {/* } */}
            <Accordion className={classes.accordion} style={{ overflow: 'auto' }} defaultExpanded
              expanded={this.state.dueTodayAccess === true} onChange={(e) => this.setState(preState => ({ dueTodayAccess: !preState.dueTodayAccess }))}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="panel1a-content in"
              >
                <Typography className={classes.heading + ' ' + (localStorage.getItem("onBoardingStep") == "4" && classes.highlightText)}>
                  <div style={{ fontFamily: primaryFont }}>
                    {t("HomeScreen.HomePage.dueToday")}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {this.state.dueTodayAccess === true ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block!important" }}>

                {this.state.dueToday.length > 0 ?

                  <Typography className={classes.addScrollBarFirstHome} component="div"
                  // style={{ width: "100%" }}
                  >
                    <Card className={classes.cardRoot} style={{ maxWidth: "300px" }} >
                      <CardContent className="addScrollBarNotificationHome" style={{ padding: "15px", maxHeight: "57vh", overflowY: "auto", }}>
                        {this.state.dueToday && this.state.dueToday.map((item: any, index: any) => {
                          return (
                            <>

                              <Card key={index} className={classes.firstCard} onClick={() => {
                                //console.log("Task ID", item.id); 
                                //this.handleOpenDueDayTaskModal(item)

                                console.log("Task ID", item.id);
                                if (item.id != null) {
                                  if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                    localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                  }
                                  else {
                                    localStorage.removeItem("brandGroupSelected")
                                  }
                                  (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item.id}&projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}`)
                                }
                              }}>

                                <CardContent>
                                  <div>

                                    <Typography className={classes.title} style={{ marginBottom: "10px" }} color="textSecondary" gutterBottom>

                                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span> <p style={{ fontSize: "10px", color: "black" }}>{item.attributes.project_name}</p></span>


                                        {/* <Avatar className={classes.purple} style={{ fontSize: "12px", marginLeft: "50%", height: '30px', width: "30px", marginTop: "5px" }}><img src={item?.attributes?.assignee?.data?.attributes?.photo} /></Avatar> */}
                                        <Avatar alt="Remy Sharp" src={item?.attributes?.assignee?.data?.attributes?.photo_blob_signed_id_url} />
                                      </div>
                                      <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                                        <p style={{ color: "black", paddingRight: "9px", marginBottom: "10px", fontSize: "16px" }}>
                                          <strong style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                            {item?.attributes?.title}</strong> </p>
                                        <span style={{ marginTop: "10px" }}>
                                          {/* <img src={logo} style={{ height: "45px", width: "45px" }} /> */}
                                          {/* <img src={item.attributes.brand_logo} style={{ height: "63px", width: "63px" }} /> */}

                                        </span>
                                        {/* <span style={{ color: "rgb(105,105,105)", fontSize: "12px", background:"rgb(224,255,255)" , borderRadius: "20px", marginBottom: "5px",width: "max-content",padding:"4px 10px", 
                                           }}>
                                         <strong>{item?.attributes?.title}</strong> 
                                         </span> */}
                                        <span style={{
                                          color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(224,255,255)", borderRadius: "20px", marginBottom: "5px", width: "max-content", padding: "4px 10px",
                                        }}>
                                          <strong style={{ maxWidth: "201px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                            {item?.attributes?.asset_category_name}</strong>
                                        </span>
                                        <span style={{
                                          color: "rgb(105,105,105)", fontSize: "12px", background: "rgb(175,238,238)", borderRadius: "20px", width: "max-content", padding: "4px 10px",
                                        }}>
                                          <strong style={{ maxWidth: "201px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" }}>
                                            {item?.attributes?.asset_type_name} </strong>
                                        </span>
                                      </div>
                                    </Typography>
                                  </div>
                                  <div >

                                  </div>
                                </CardContent>


                                <CardActions style={{ backgroundColor: "#e4e8f0", position: "absolute", bottom: 0, left: 0, right: 0 }}>
                                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className={classes.circle} >
                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                    </div>

                                    {item?.attributes?.estimated_end_date && item?.attributes?.estimated_end_date !== "" ?
                                      <p style={{ fontSize: "12px", marginLeft: "5px" }} >{(item?.attributes?.estimated_end_date).split("-").reverse().join("-")} </p>
                                      :
                                      <p style={{ fontSize: "12px", marginLeft: "5px" }} >Add DueDate</p>
                                    }
                                    <div className={classes.circle} style={{ marginLeft: "12px" }}>
                                      <span><ChatOutlinedIcon style={{ fontSize: "12px" }} /></span>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_comments_count}</p>
                                    <div className={classes.circle} style={{ marginLeft: "12px" }} >
                                      <span> <AttachFileOutlinedIcon style={{ fontSize: "13px" }} /></span>

                                    </div>
                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>{item.attributes.task_files_count}</p>
                                  </div>
                                </CardActions>





                                <CardActions />


                              </Card>

                            </>
                          )
                        })

                        }




                      </CardContent>
                      <CardActions />
                    </Card>
                  </Typography>


                  : null

                }

              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={4} className={classes.notificationSpacing} style={{ marginTop: '1%', paddingLeft: '10px' }}>
            <Accordion
              defaultExpanded
              // alwaysOpened
              style={{ overflow: 'auto' }}
              className={classes.accordion}
              expanded={this.state.notificationArrow === true} onChange={(e) => this.setState(preState => ({ notificationArrow: !preState.notificationArrow }))}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ width: "100%" }}
              >
                <Typography className={classes.heading} component="div">
                  <div style={{ fontFamily: primaryFont }}>
                    {t("HomeScreen.HomePage.notification")}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {this.state.notificationArrow === true ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block!important" }}>
                <Typography
                  // className="addScrollBarHome" 
                  style={{ width: "100%" }} component="div">
                  <Card className={classes.notificationCardRoot}>
                    <CardContent className="addScrollBarNotificationHome" style={{ height: "auto !important", maxHeight: "100% !important" }}>
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        //variant="scrollable"
                        //scrollButtons="on"
                        classes={{ indicator: classes.indicator }}
                        // indicatorColor="primary"
                        aria-label="scrollable force tabs example"
                        textColor="primary"
                      //centered
                      >
                        <Tab
                          label={t("HomeScreen.HomePage.all")}
                          className={classes.tab}

                          style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                        />
                        <Tab
                          label={t("HomeScreen.HomePage.teamBased")}
                          className={classes.tab}
                          style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                        />
                        <Tab
                          label={t("HomeScreen.HomePage.toDos")}
                          className={classes.tab}
                          style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                        />
                        <Tab
                          label={t("HomeScreen.HomePage.approvals")}
                          className={classes.tab}
                          style={{ fontFamily: primaryFont, color: secondaryColorToBeChanged }}
                        />
                      </Tabs>

                      <Card className={classes.allNotificationCard}>
                        <CardContent style={{ backgroundColor: "#e4e8f0" }}>
                          {this.state.value === 0 && (
                            <>
                              {this.state.notificationData ? this.state.notificationData.map((item: any, index: any) => {
                                if (item?.attributes?.link_entity == "Task") {
                                  return
                                } else {

                                  {
                                    console.log("debbbbbb........", item?.attributes)
                                  }
                                  return (
                                    <>
                                      <div key={index} style={{
                                        marginBottom: "20px",
                                        //  backgroundColor: "white",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        cursor: 'pointer'
                                      }}
                                        className={classes.notificationCardHover}
                                        onClick={() => {

                                          console.log("Task ID", item.id);
                                          if (item?.attributes?.task_id != null && item?.attributes?.project_id != null && this.state.permissions !== "viewer") {
                                            if (_.some(['Quote', 'quote'], (el) => _.includes(item?.attributes?.acted_user_message, el)) || _.some(['Quote', 'quote'], (el) => _.includes(item?.attributes?.target_user_message, el))) {
                                              if (_.some(['Approved', 'approved'], (el) => _.includes(item?.attributes?.acted_user_message, el)) || _.some(['Approved', 'approved'], (el) => _.includes(item?.attributes?.target_user_message, el))) {
                                                if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                                  localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                                }
                                                else {
                                                  localStorage.removeItem("brandGroupSelected")
                                                }
                                                (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item?.attributes?.task_id}&projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}`)
                                              }
                                              else {
                                                (window.location.href = `/Home/ProjectList?projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}&taskId=${item?.attributes?.task_id}`)
                                              }
                                            } else if (item?.attributes?.is_project_deleted === true || item?.attributes?.is_task_deleted === true) {
                                              toast.error("The Project/task has been deleted")
                                            }
                                            // else if (item?.attributes?.is_task_deleted=== true) {
                                            //   toast.error("The Project/task has been deleted")
                                            // }
                                            else if (item?.attributes?.task_id != null) {
                                              if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                                localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                              }
                                              else {
                                                localStorage.removeItem("brandGroupSelected")
                                              }
                                              (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item?.attributes?.task_id}&projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}`)
                                            }
                                          }
                                          else if (item?.attributes?.team_id != null) {
                                            (window.location.href = `/Home/Teams?navigate=teams_view&teamId=${item?.attributes?.team_id}`)
                                          } else if (item?.attributes?.task_id != null) {
                                            if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                              localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                            }
                                            else {
                                              localStorage.removeItem("brandGroupSelected")
                                            }
                                            (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item?.attributes?.task_id}&projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}`)
                                          } else if (item?.attributes?.project_id != null && item?.attributes?.is_project_deleted === false) {
                                            (window.location.href = `/Home/Projectportfolio?navigate=project_view&projectId=${item?.attributes?.project_id}`)
                                          }
                                          else if (item?.attributes?.project_id != null && item?.attributes?.is_project_deleted === true) {
                                            // (window.location.href = `/Home/Projectportfolio?navigate=project_view&projectId=${item?.attributes?.project_id}`)
                                            toast.error("The Project/task has been deleted")
                                          }
                                        }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center", fontFamily: primaryFont }}>
                                          <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                          {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                            {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                            <br />
                                            {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                          </span>}






                                          <p style={{ margin: 0 }}>
                                            {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"

                                            />
                                          </IconButton> */}
                                          </p>
                                        </div>
                                        {/* <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px",marginBottom:"10px",textAlign:"center",maxWidth:"100px"}} >
                                          {item?.attributes?.target_user_entity}

                                        </div> */}


                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                          <p style={{ fontSize: "14px", margin: "0" }}>
                                            {item?.attributes?.acted_user_message}
                                          </p>

                                        </div>



                                        <div className={classes.notificationCalenderRow}>
                                          <div className={classes.notificationCicle}  >
                                            <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />


                                          </div>
                                          <p style={{ fontSize: "10px", fontFamily: primaryFont }}>{item?.attributes?.updated_at}</p>
                                        </div>


                                      </div>
                                    </>
                                  )
                                }
                              })

                                :
                                <p>NO Data Found</p>

                              }
                            </>





                          )}
                          {this.state.value === 1 &&
                            <>
                              {this.state.teamBased != null ? this.state.teamBased.map((item: any, index: any) => {
                                 if (item?.attributes?.link_entity == "Task") {
                                  return
                                } else {
                                return (
                                  <>
                                    <div key={index} style={{
                                      marginBottom: "20px",
                                      // backgroundColor: "white",
                                      padding: "10px",
                                      borderRadius: "8px",
                                      cursor: 'pointer',
                                      fontFamily: primaryFont
                                    }} className={classes.notificationCardHover}
                                      onClick={() => {

                                        console.log("Task ID", item.id);
                                        if (item?.attributes?.team_id != null) {
                                          (window.location.href = `/Home/Teams?navigate=teams_view&teamId=${item?.attributes?.team_id}`)
                                        }
                                      }}
                                    >
                                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                        <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />

                                        {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                          {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                          <br />
                                          {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                        </span>}




                                        <p style={{ margin: 0 }}>
                                          {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"

                                            />
                                          </IconButton> */}
                                        </p>
                                      </div>
                                      {/* <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px",marginBottom:"10px",textAlign:"center",maxWidth:"100px"}} >
                                          {item?.attributes?.target_user_entity}

                                        </div> */}


                                      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <p style={{ fontSize: "14px", margin: "0" }}>
                                          {item.attributes.acted_user_message}
                                        </p>

                                      </div>



                                      <div className={classes.notificationCalenderRow}>
                                        <div className={classes.notificationCicle}  >
                                          <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />


                                        </div>
                                        <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                      </div>


                                    </div>
                                  </>
                                )}
                              })
                                :
                                <>
                                  <p>No Team Notifications</p>
                                </>

                              }
                            </>






                          }


                          {this.state.value === 2 &&


                            <>
                              {this.state.toDo != null ? this.state.toDo.map((item: any, index: any) => {
                                 if (item?.attributes?.link_entity == "Task") {
                                  return
                                } else {
                                return (
                                  <>
                                    <div key={index} style={{
                                      marginBottom: "20px",
                                      //backgroundColor: "white",
                                      padding: "10px",
                                      fontFamily: primaryFont,
                                      borderRadius: "8px", cursor: 'pointer'
                                    }} className={classes.notificationCardHover}
                                      onClick={() => {

                                        console.log("Task ID", item.id);
                                        if (item?.attributes?.task_id != null) {
                                          if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                            localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                          }
                                          else {
                                            localStorage.removeItem("brandGroupSelected")
                                          }
                                          (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item?.attributes?.task_id}&projectId=${item?.attributes?.project_id}`)
                                        }
                                      }}
                                    >
                                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                        <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />

                                        {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                          {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                          <br />
                                          {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                        </span>}


                                        <p style={{ margin: 0 }}>
                                          {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"

                                            />
                                          </IconButton> */}
                                        </p>
                                      </div>
                                      {/* <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px",marginBottom:"10px",textAlign:"center",maxWidth:"100px"}} >
                                          {item?.attributes?.target_user_entity}

                                        </div> */}


                                      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                        <p style={{ fontSize: "14px", margin: "0" }}>
                                          {item.attributes.acted_user_message}
                                        </p>

                                      </div>



                                      <div className={classes.notificationCalenderRow}>
                                        <div className={classes.notificationCicle}  >
                                          <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />


                                        </div>
                                        <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                      </div>


                                    </div>
                                  </>
                                )}
                              })
                                :
                                <>
                                  <p>No Team Notifications</p>
                                </>

                              }
                            </>








                          }
                          {this.state.value === 3 &&

                            <>
                              {this.state.approvalNotification != null ? this.state.approvalNotification.map((item: any, index: any) => {
                                if (item?.attributes?.link_entity == "Task") {
                                  return
                                } else {
                                  return (
                                    <>
                                      <div key={index} style={{
                                        marginBottom: "20px",
                                        //backgroundColor: "white",
                                        padding: "10px",
                                        fontFamily: primaryFont,
                                        borderRadius: "8px", cursor: 'pointer'
                                      }} className={classes.notificationCardHover}
                                        onClick={() => {

                                          console.log("Task ID", item.id);
                                          if (item?.attributes?.task_id != null && this.state.permissions !== "viewer") {
                                            if (_.some(['Quote', 'quote'], (el) => _.includes(item?.attributes?.acted_user_message, el)) || _.some(['Quote', 'quote'], (el) => _.includes(item?.attributes?.target_user_message, el))) {
                                              (window.location.href = `/Home/ProjectList?projectId=${item?.attributes?.project_id}&brandId=${item?.attributes?.brand_id}&taskId=${item?.attributes?.task_id}`)
                                            } else {
                                              if (item?.attributes?.brand?.data?.attributes?.brand_group_id) {
                                                localStorage.setItem("brandGroupSelected", item?.attributes?.brand?.data?.attributes?.brand_group_id)
                                              }
                                              else {
                                                localStorage.removeItem("brandGroupSelected")
                                              }
                                              (window.location.href = `/Home/Projectportfolio?navigate=task_view&taskId=${item?.attributes?.task_id}&projectId=${item?.attributes?.project_id}`)
                                            }
                                          }
                                        }}
                                      >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "10px", alignItems: "center" }}>
                                          <Avatar src={item?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                          {item?.attributes?.account?.data?.attributes?.is_deleted ? <span style={{ marginLeft: "15px" }}>Deleted User</span> : <span style={{ marginLeft: "15px" }}>
                                            {item?.attributes?.account?.data?.attributes?.first_name} {item?.attributes?.account?.data?.attributes?.last_name}
                                            <br />
                                            {item?.attributes?.account?.data?.attributes?.designation ? item?.attributes?.account?.data?.attributes?.designation : null}
                                          </span>}


                                          <p style={{ margin: 0 }}>
                                            {/* <IconButton aria-label="settings" style={{ color: "black" }}>
                                            <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="simple-menu"
                                              aria-haspopup="true"

                                            />
                                          </IconButton> */}
                                          </p>
                                        </div>
                                        {/* <div style={{ backgroundColor: "#f3f5f9", padding: "5px", height: "15px",marginBottom:"10px",textAlign:"center",maxWidth:"100px"}} >
                                          {item?.attributes?.target_user_entity}

                                        </div> */}


                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                          <p style={{ fontSize: "14px", margin: "0" }}>
                                            {item.attributes.acted_user_message}
                                          </p>

                                        </div>



                                        <div className={classes.notificationCalenderRow}>
                                          <div className={classes.notificationCicle}  >
                                            <CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} />


                                          </div>
                                          <p style={{ fontSize: "10px" }}>{item.attributes.updated_at}</p>
                                        </div>


                                      </div>
                                    </>
                                  )
                                }
                              })
                                :
                                <>
                                  <p>No Team Notifications</p>
                                </>

                              }
                            </>







                          }
                        </CardContent>
                        {/* <CardActions /> */}
                      </Card>

                    </CardContent>
                    <CardActions />
                  </Card>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* <div id="hubspot-conversations-inline-parent">
          <iframe id="hubspot-conversations-inline-iframe" />
        </div> */}


        {/* Quick Access*/}
        {this.state.isCurrentTaskSelected && this.state.currentTask ? (

          <Dialog
            open={this.state.setOpen2}
            onClose={this.handleCloseTaskModal}
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title" className={classes.dialogueTitle}> */}
            <div className={classes.TaskViewTitle}>
              <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
              <p style={{ fontSize: "14px" }}>Project: {this.state.currentTask?.attributes?.project_name}</p>
              <span>
                <CloseIcon onClick={this.handleCloseTaskModal} />
              </span>
            </div>

            {/* </DialogTitle> */}
            <Divider />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                  <Grid item xs={8} style={{ backgroundColor: "#f5f6fa" }} >
                    <div style={{ backgroundColor: "#f5f6fa" }}>
                      <div style={{ margin: "20px" }}>
                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px" }}>{this.state.currentTask?.attributes?.title}</p>
                        <p style={{ fontSize: "14px", color: "#282828" }}>{this.state.currentTask?.attributes?.title}</p>

                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginBottom: "0px" }} >Blockers</p>

                        {/* checkbox */}
                        <div> {this.state.blockersData?.length > 0 && this.state.blockersData.map((values: any, index: any) => (
                          <>
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox color="primary" name="is_completed"
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.handleChangeBlockerSelection(values, e.target.checked) }}
                                />}
                              className={values.attributes?.is_completed && classes.checkdecoration}

                              label={values.attributes?.description}
                            // label="Secondary"
                            />

                          </>
                        ))}


                        </div>
                        {this.state.permissions === "albertbauer" || (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}><img
                              onClick={this.handleClickCreateNewTaskBlockerModel}
                              src={plusButton} /></span>
                            <span><p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px" }} >Blocker reason (e.g. task # or @user</p> </span>
                          </div>
                        ) : undefined

                        }

                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginTop: "30px", marginBottom: "0px" }}>Subtasks</p>

                        <div>
                          {this.state.subTasksData?.length > 0 && this.state.subTasksData.map((values: any, index: any) => (
                            <>
                              <FormControlLabel
                                key={index}
                                control={<Checkbox color="primary" name="is_completed"
                                  // onChange={this.handleChangeBlockerSelection(value,values)}
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.callUpdateSubTaskApi(values, e.target.checked) }}
                                />}
                                label={values.attributes?.title}
                              // label="Secondary"
                              />

                            </>
                          ))}

                        </div>

                        {this.state.permissions === "albertbauer" || (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton}
                              onClick={this.handleClickCreateNewSubTakModel}
                            /></span>
                            <span>
                              <p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px", marginBottom: "30px" }} >Add new subtask</p> </span>
                          </div>) : undefined}
                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginTop: "30px", marginBottom: "0px" }}>Attachment</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => {
                            if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                              return (
                                <React.Fragment key={index}>
                                  <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                    {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                    <Card style={{ width: '73px' }}>
                                      <CardMedia
                                        component="img"
                                        alt="template"
                                        style={{ width: 25, height: 40, padding: '15px 24px' }}
                                        height="15"
                                        width="20"
                                        image={imgFileTemplate}
                                        title={item.attributes.name}
                                        className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                      />
                                    </Card>
                                    {/* </span> */}
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              )
                            }
                          }
                          )}
                        </div>
                        <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                          onClick={() => {
                            localStorage.setItem("task", "0")
                            this.fileInput1.click()
                          }}
                        >
                          <ReactS3Uploader
                            type="file"
                            id="upload"
                            name="pic"
                            getSignedUrl={this.getSignedUrl}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read'
                            }}
                            s3path="/uploads/"
                            contentDisposition="auto"
                            accept="**"
                            capture={true}
                            inputRef={cmp => this.fileInput1 = cmp}
                            onChange={(event: any) =>
                              this.inputFileChanged(event)
                            }
                            style={{
                              visibility: "hidden",
                              position: "absolute",
                              alignContent: "center", backgroundColor: 'white',
                              color: '#419bf9',
                              width: "280px", padding: 20,
                              fontSize: 16, fontWeight: 'bold',
                              borderWidth: 1,
                              borderColor: '#419bf9',
                              borderRadius: 10,
                              borderStyle: 'dashed',
                              fontVariant: "initial",
                              textTransform: 'none',
                              marginBottom: "20px"
                            }}
                          />
                          <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                        </div>






                      </div>

                      {/* <div className={classes.banners}>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Headline</p>
              <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque.</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Subline</p>
              <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iacul elit et libero.</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>Target URL</p>
              <p style={{ marginTop: "0px" }}>www.exampledomain.com</p>
            </div>
          </div> */}
                      {this.state.currentTaskBriefingData?.length > 0 && this.state.currentTaskBriefingData.slice(0).map((briefedValues: any, index: any) => (
                        <div style={{ marginTop: "40px", marginLeft: "20px" }}>

                          {briefedValues.attributes.value_type == "file" ?
                            (
                              <>
                                {briefedValues.attributes.file_blob_signed_id_url &&
                                  <Fragment>
                                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                    <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                    <span onClick={(e) => { this.setState({ file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, setOpenImageDialog: true }) }}>
                                      <Card style={{ width: '73px' }}>
                                        <CardMedia
                                          component="img"
                                          alt="template"
                                          style={{ width: 50, height: 60, padding: '5px 10px' }}
                                          height="15"
                                          width="20"
                                          image={briefedValues.attributes.file_blob_signed_id_url ? briefedValues.attributes.file_blob_signed_id_url : imgFileTemplate}
                                        />
                                      </Card>
                                    </span>
                                  </Fragment>
                                }
                              </>
                            )
                            :
                            briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image" ?
                              (
                                <>
                                  {briefedValues.attributes.file_blob_signed_id_url &&
                                    <Fragment>
                                      <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                      <span onClick={(e) => { this.setState({ fileId: briefedValues?.id, file_blob_signed_id_url: briefedValues?.attributes?.file_blob_signed_id_url, fileImage: "Uploaded Image", setOpenImageDialog: true }) }}>
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 50, height: 60, padding: '5px 10px' }}
                                            height="15"
                                            width="20"
                                            image={briefedValues.attributes.file_blob_signed_id_url ? briefedValues.attributes.file_blob_signed_id_url : imgFileTemplate}
                                            // title={item.attributes.name}
                                            className={classes.templateHover}
                                          />
                                        </Card>
                                      </span>
                                    </Fragment>
                                  }
                                </>
                              )
                              :
                              (briefedValues.attributes.value_type == "short_text" ||
                                briefedValues.attributes.value_type == "long_text" ||
                                briefedValues.attributes.value_type == "email" ||
                                briefedValues.attributes.value_type == "multiple_selection" ||
                                briefedValues.attributes.value_type == "single_selection" ||
                                briefedValues.attributes.value_type == "drop_down_select" ||
                                briefedValues.attributes.value_type == "slider" ||
                                briefedValues.attributes.value_type == "comment_box"
                              ) ?
                                (
                                  <>
                                    {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                      <Fragment>
                                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                        <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                      </Fragment>
                                    }
                                  </>
                                )
                                :
                                briefedValues.attributes.value_type == "date_time" ?
                                  (
                                    <>
                                      {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                        <Fragment>
                                          <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                          <p style={{ fontSize: '14px', marginTop: "0px" }}>{(briefedValues.attributes.value).replace("T", ", ")}</p>
                                        </Fragment>
                                      }
                                    </>
                                  )
                                  :
                                  (briefedValues.attributes.value_type == "short_text" ||
                                    briefedValues.attributes.value_type == "long_text" ||
                                    briefedValues.attributes.value_type == "email" ||
                                    briefedValues.attributes.value_type == "multiple_selection" ||
                                    briefedValues.attributes.value_type == "single_selection" ||
                                    briefedValues.attributes.value_type == "drop_down_select" ||
                                    briefedValues.attributes.value_type == "comment_box"
                                  ) ?
                                    (
                                      <>
                                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                        <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                      </>
                                    )
                                    :
                                    briefedValues.attributes.value_type == "page_separator" ?
                                      "" :
                                      null
                          }
                        </div>
                      ))}

                      {/* <div style={{ marginTop: "40px", marginLeft: "20px" }}>
            <p style={{ fontSize: "16px", marginBottom: "0px" }}>Do you have any other instructions?</p>
            <p style={{ fontSize: '14px', marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue elit ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue eli et libero.)
</p>
          </div> */}

                      <Accordion className={classes.accordianStyle} defaultExpanded>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>Activity <img src={downIcon} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>

                            {/* <Paper component="form" className={classes.root} style={{height:'200px',border:'1px solid black',margin:15}} > */}


                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                              editorStyle={{ padding: 10, minHeight: 100 }}
                              toolbarStyle={{ height: 50, fontSize: 18 }}
                              onEditorStateChange={this.onEditorStateChange}
                              mention={{
                                separator: ' ',
                                trigger: '@',
                                suggestions: this.state.selectedProjectsMentionsAccountsData
                                //  [
                                //   { text: 'APPLE', value: 'apple', url: 'apple' },
                                //   { text: 'BANANA', value: 'banana', url: 'banana' },
                                //   { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                //   { text: 'DURIAN', value: 'durian', url: 'durian' },
                                //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                //   { text: 'FIG', value: 'fig', url: 'fig' },
                                //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                // ],
                              }}
                              hashtag={{
                                separator: ' ',
                                trigger: '#',
                              }}
                              // toolbarCustomButtons={[
                              //   <Button
                              //     style={{
                              //       backgroundColor: colorToBeChanged,
                              //       color: "white",
                              //       marginRight: "0px",
                              //     }}
                              //     type="submit"
                              //     variant="contained"
                              //     onClick={() => { this.callCreateTaskCommentListApi(this.state.convertedCommentData) }}
                              //   >Comment
                              //     {t("projects.saveChanges")}
                              //   </Button>

                              // ]}
                              toolbarCustomButtons={[
                                <Button
                                  size="small"
                                  onClick={(e) => {
                                    this.state.convertedCommentData?.blocks?.length > 0 ?
                                      this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                  }}
                                  style={{ color: 'white', backgroundColor: '#173e59', position: 'absolute', right: '70px' }}
                                  startIcon={<SendIcon />}
                                  type="submit"
                                >
                                  Send
                                </Button>
                                // <button
                                //   style={{
                                //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "293px",
                                //   }}
                                //   type="submit"

                                //   onClick={(e) => {
                                //     this.state.convertedCommentData?.blocks?.length > 0 ?
                                //       this.callCreateTaskCommentListApi(this.state.convertedCommentData) : toast.error("Comment is required");
                                //   }}
                                // >
                                //   <SendIcon />

                                // </button>

                              ]}

                              toolbar={{
                                options: ["inline"],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  // options: ['unordered', 'ordered'],
                                  unordered: { className: undefined },
                                  ordered: { className: undefined },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                                // link: {
                                //   inDropdown: false,
                                //   className: undefined,
                                //   component: undefined,
                                //   popupClassName: undefined,
                                //   dropdownClassName: undefined,
                                //   showOpenOptionOnHover: true,
                                //   defaultTargetOption: '_self',
                                //   options: ['link'],
                                // link: { icon: link, className: undefined },
                                // unlink: { icon: unlink, className: undefined },
                                //   linkCallback: undefined
                                // },


                                // image: {
                                //   //icon:,
                                //   className: undefined,
                                //   component: undefined,
                                //   popupClassName: undefined,
                                //   urlEnabled: true,
                                //   uploadEnabled: true,
                                //   alignmentEnabled: true,
                                //   uploadCallback: undefined,
                                //   previewImage: false,
                                //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                //   alt: { present: false, mandatory: false },
                                //   defaultSize: {
                                //     height: 'auto',
                                //     width: 'auto',
                                //   },
                                // },

                              }}
                            />

                            {this.state.getTaskCommentsData.map((item: any) => {
                              return (<>
                                <div>
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "16px", }}>
                                    <Avatar src={item.attributes?.account?.data?.attributes?.photo_blob_signed_id_url}></Avatar>
                                    <div style={{ marginLeft: "15px" }}>
                                      <p style={{ fontSize: "16px", margin: "3px 3px 3px 0", fontWeight: "bold", }}>{item.attributes?.account?.data?.attributes?.first_name}</p>
                                      <p style={{ fontSize: "10px", margin: "0px" }}>{item.attributes?.account?.data?.attributes?.created_at}</p>
                                    </div>
                                  </div>

                                  {item.attributes?.file_blob_signed_id_url !== null && (
                                    <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px", margin: "14px 27px 0 80px", }}>
                                      <span
                                      // onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url, fileImage: item?.attributes?.name }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}
                                      >
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 25, height: 40, padding: '15px 24px' }}
                                            height="15"
                                            width="20"
                                            image={imgFileTemplate}
                                            title={item.attributes.name}
                                            className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                          />
                                        </Card>
                                      </span>
                                      <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                        {item.attributes.name}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <div style={{ margin: "14px 27px 0 80px", padding: "17px 16px 19px 20px", background: "#efefef", borderRadius: "7px", color: "#282828", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                    <p style={{ margin: "0px" }} dangerouslySetInnerHTML={{ __html: item.attributes?.description }} />
                                    <p style={{ cursor: "pointer", fontSize: "12px", margin: "0px" }} onClick={() => { this.openSpecificTaskComment(item.id) }}>Edit</p>
                                  </div>
                                </div>
                              </>
                              )
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>


                    </div>

                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ marginLeft: "20px", marginTop: "20px" }}>

                      {(this.state.currentTask.attributes?.quotation?.data?.attributes?.price && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && !this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated &&
                        this.state.currentTask.attributes?.quotation?.data?.attributes?.status === "Pending Quote Approval" &&
                        (this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <>
                          <p style={{ fontSize: "16px" }}></p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={(e) => {
                              this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >

                            {t("projects.selfApprove")}
                          </Button>
                        </>
                      ) : (this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <>
                          <p style={{ fontSize: "16px" }}>Auto Generated</p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              var currentTask = this.state.currentTask;
                              currentTask.attributes.quotation.data.attributes.status = "Quote Approved"
                              this.setState({
                                currentTask: currentTask
                              })
                              this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >
                            {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" ? (
                              <span>{t("projects.selfApprove")}</span>
                            ) : (
                              <span>Approved</span>
                            )}
                          </Button>
                        </>
                      ) : undefined))}

                      {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions !== "albertbauer" && this.state.permissions === "approver") ? (
                        <div>
                          {(this.state.currentTask.attributes?.quote_requested === false) ? (
                            <>
                              <Button

                                style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                // onClick={() => { this.callCreateRequestQuotationApi(this.state.currentTask?.id) }}
                                onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.attributes?.briefing?.data?.id) }}
                              >
                                {t("projects.requestQuote")}
                              </Button>
                              <Divider style={{ marginBottom: "20px" }} />
                            </>
                          ) : (
                            <>
                              {this.state.currentTask.attributes.price_auto_generated !== null && (
                                <>
                                  <p style={{ fontSize: "16px" }}>Auto Generated</p>
                                  <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                                </>
                              )}
                              {/* <Button
                            disabled
                            style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={e => { this.statusUpdateToRequestQuote(this.state.currentTask?.id) }}
                          >
                            Quote Requested
                          </Button> */}
                              {/* <Divider style={{ marginBottom: "20px" }} /> */}
                            </>
                          )}

                        </div>
                      ) : (
                        <div>
                          {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                            <>
                              <p>Approved quote - €{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>

                              <Divider style={{ marginBottom: "20px" }} />
                            </>
                          ) : undefined}
                        </div>



                      )}
                      <TextField
                        id="taskType"
                        disabled
                        name="taskType"
                        select
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        // label={t("TaskView.taskType")}
                        label="Asset Type"
                        value={this.state.currentTask.attributes.asset_type_name}
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      >
                        <option>{this.state.currentTask.attributes.asset_type_name}</option>

                      </TextField>
                      {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <TextField
                          name="status"
                          disabled
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)
                                this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state}
                          className={classes.selectInput}
                          variant="outlined"


                        > <MenuItem aria-label="None" value="started" />
                          <MenuItem key="New" value="New"> New</MenuItem>
                          <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>

                          <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>                        {/* <MenuItem key="Open" value="Open">Open</MenuItem> */}
                          {this.state.taskStatusesData?.map((option: any) => (

                            <MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>

                          ))}


                        </TextField>

                      ) : (
                        <TextField
                          name="status"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)


                                // this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state || ''}
                          className={classes.selectInput}
                          variant="outlined"
                        > <MenuItem aria-label="None" value="started" />
                          <MenuItem key="New" value="New"> New</MenuItem>
                          <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>

                          <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>
                          {this.state.taskStatusesData?.map((option: any) => (

                            <MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>

                          ))}


                        </TextField>

                      )
                      }
                      <TextField
                        focused
                        id="date"
                        name="Due Date"
                        type="date"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        size="small"
                        fullWidth
                        label="Due Date"

                        variant="outlined"
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}
                        value={this.state.currentTask.attributes.estimated_end_date}
                        onChange={(e) => {
                          this.handleDateChange(e);
                        }}
                      />
                      <TextField
                        id="Requestor"
                        name="Requestor"
                        disabled
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        label="Requestor"
                        value={this.state.currentTask?.attributes?.requestor?.data?.attributes?.first_name}
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      ></TextField>
                      <Autocomplete
                        id="fixed-tags-demo"
                        className={classes.selectInput}
                        options={this.state.accountsData}
                        inputValue={this.state.currentTask?.attributes?.reviewer?.data?.attributes?.first_name}
                        getOptionLabel={(option: any) => option.attributes.first_name}
                        onChange={(e: any, value: any) => {
                          this.handleReviewerChange(e, value);
                        }}
                        style={{ width: 280 }}
                        renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                      />
                      {this.state.permissions === 'editor' ?

                        <Autocomplete
                          inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.first_name}
                          id="fixed-tags-demo"
                          className={classes.selectInput}
                          options={this.state.accountsData}
                          getOptionLabel={(option: any) => option.attributes.first_name}
                          onChange={(e: any, value: any) => {
                            // this.handleAssigneeChange(e, value);
                          }}
                          style={{ width: 280 }}
                          renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                        />
                        : undefined}
                      <p style={{ fontSize: "16px" }}>Task Progress</p>
                      <div className={classes.progress}>
                        <span> <p style={{ fontSize: "16px" }}>{this.state.currentTask.attributes.progress} %</p> </span>

                        <span style={{ marginTop: "10px", marginLeft: "10px" }}>   <p style={{ fontSize: "10px" }}>completed</p></span>
                      </div>
                      {(this.state.permissions === "approver" || this.state.permissions === "editor") ? (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      ) : (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider disabled valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      )}

                      <Divider />
                      <div style={{ margin: "20px" }}>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Created</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.created_at}</p>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Updated</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.updated_at}</p>
                        </div>
                        <div>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Task ID</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes?.order_number}</p>
                        </div>
                        {this.state.permissions === 'editor' ?
                          <>
                            <div>
                              <p style={{ fontSize: "16px" }} >Final Files for Sign off</p>
                            </div>
                            <div>
                              <div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                        {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 25, height: 40, padding: '15px 24px' }}
                                            height="15"
                                            width="20"
                                            image={imgFileTemplate}
                                            title={item.attributes.name}
                                            className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                          />
                                          {/* </CardActionArea> */}
                                        </Card>
                                        {/* </span> */}
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                                </div>
                                <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                  onClick={() => {
                                    localStorage.setItem("task", "1")
                                    this.fileInput2.click()
                                  }}
                                >
                                  <ReactS3Uploader
                                    type="file"
                                    id="upload"
                                    name="pic"
                                    getSignedUrl={this.getSignedUrl}
                                    uploadRequestHeaders={{
                                      'x-amz-acl': 'public-read'
                                    }}
                                    s3path="/uploads/"
                                    contentDisposition="auto"
                                    accept="**"
                                    // multiple
                                    capture={true}
                                    inputRef={cmp => this.fileInput2 = cmp}
                                    onChange={(event: any) =>
                                      this.inputFileChanged(event)
                                    }
                                    style={{
                                      visibility: "hidden",
                                      position: "absolute",
                                      alignContent: "center", backgroundColor: 'white',
                                      color: '#419bf9',
                                      width: "280px", padding: 20,
                                      fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: '#419bf9',
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginBottom: "20px"
                                    }}
                                  />
                                  <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                </div>
                              </div>
                            </div>
                            <div>
                              <p style={{ fontSize: "16px" }}>Source Files</p>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                    {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                    <Card style={{ width: '73px' }}>
                                      <CardMedia
                                        component="img"
                                        alt="template"
                                        style={{ width: 25, height: 40, padding: '15px 24px' }}
                                        height="15"
                                        width="20"
                                        image={imgFileTemplate}
                                        title={item.attributes.name}
                                        className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                      />
                                      {/* </CardActionArea> */}
                                    </Card>
                                    {/* </span> */}
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </div>
                            <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                              onClick={() => {
                                localStorage.setItem("task", "2")

                                this.fileInput3.click()
                              }}
                            >
                              <ReactS3Uploader
                                type="file"
                                id="upload"
                                name="pic"
                                getSignedUrl={this.getSignedUrl}
                                uploadRequestHeaders={{
                                  'x-amz-acl': 'public-read'
                                }}
                                s3path="/uploads/"
                                contentDisposition="auto"
                                accept="**"
                                // multiple
                                capture={true}
                                //  ref={this.fileInput}
                                inputRef={cmp => this.fileInput3 = cmp}
                                onChange={(event: any) =>
                                  this.inputFileChanged(event)
                                }
                                style={{
                                  visibility: "hidden",
                                  position: "absolute",
                                  // width: "280px",
                                  alignContent: "center", backgroundColor: 'white',
                                  color: '#419bf9',
                                  width: "280px", padding: 20,
                                  fontSize: 16, fontWeight: 'bold',
                                  borderWidth: 1,
                                  borderColor: '#419bf9',
                                  borderRadius: 10,
                                  borderStyle: 'dashed',
                                  fontVariant: "initial",
                                  textTransform: 'none',
                                  marginBottom: "20px"
                                }}
                              />
                              <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                            </div>
                          </>
                          : ""}
                      </div>

                    </div>

                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>
        ) : undefined}

        {/* Briefing model part 2*/}
        {this.state.isCurrentTaskSelecteds && this.state.currentTask ? (

          <Dialog
            open={this.state.setOpen}
            onClose={this.handleCloseTaskModal}
            // maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title" className={classes.dialogueTitle}> */}
            <div className={classes.TaskViewTitle}>
              <p style={{ fontSize: "16px", marginLeft: "24px" }}>ID:{this.state.currentTask?.attributes?.order_number}</p>
              <p style={{ fontSize: "14px" }}>Project: {this.state.currentTask?.attributes?.project_name}</p>
              <span>
                <CloseIcon onClick={this.handleCloseTaskModal} />
              </span>
            </div>

            {/* </DialogTitle> */}
            <Divider />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={0} style={{ minWidth: "600px", maxWidth: "900px" }}>
                  <Grid item xs={8} style={{ backgroundColor: "#f5f6fa" }} >
                    <div style={{ backgroundColor: "#f5f6fa" }}>
                      <div style={{ margin: "20px" }}>
                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px" }}>{this.state.currentTask?.attributes?.title}</p>
                        <p style={{ fontSize: "14px", color: "#282828" }}>{this.state.currentTask?.attributes?.title}</p>

                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginBottom: "0px" }} >Blockers</p>

                        {/* checkbox */}
                        <div> {this.state.blockersData?.length > 0 && this.state.blockersData.map((values: any, index: any) => (
                          <>
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox color="primary" name="is_completed"
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.handleChangeBlockerSelection(values, e.target.checked) }}
                                />}
                              className={values.attributes?.is_completed && classes.checkdecoration}

                              label={values.attributes?.description}
                            // label="Secondary"
                            />

                          </>
                        ))}


                        </div>
                        {this.state.permissions === "albertbauer" || (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}><img
                              onClick={this.handleClickCreateNewTaskBlockerModel}
                              src={plusButton} /></span>
                            <span><p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px" }} >Blocker reason (e.g. task # or @user</p> </span>
                          </div>
                        ) : undefined

                        }

                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginTop: "30px", marginBottom: "0px" }}>Subtasks</p>

                        <div>
                          {this.state.subTasksData?.length > 0 && this.state.subTasksData.map((values: any, index: any) => (
                            <>
                              <FormControlLabel
                                key={index}
                                control={<Checkbox color="primary" name="is_completed"
                                  // onChange={this.handleChangeBlockerSelection(value,values)}
                                  checked={values.attributes?.is_completed}
                                  value={values.attributes?.is_completed}
                                  onChange={(e) => { this.callUpdateSubTaskApi(values, e.target.checked) }}
                                />}
                                label={values.attributes?.title}
                              // label="Secondary"
                              />

                            </>
                          ))}

                        </div>

                        {this.state.permissions === "albertbauer" || (this.state.permissions === "editor" || this.state.permissions === "viewer") ? (
                          <div style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "-4px" }}><img src={plusButton}
                              onClick={this.handleClickCreateNewSubTakModel}
                            /></span>
                            <span>
                              <p style={{ color: secondaryColorToBeChanged, fontSize: "14px", paddingLeft: "32px", marginBottom: "30px" }} >Add new subtask</p> </span>
                          </div>) : undefined}
                        <p style={{ color: secondaryColorToBeChanged, fontSize: "20px", marginTop: "30px", marginBottom: "0px" }}>Attachment</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => {
                            if (item?.attributes?.source_file == false && item?.attributes?.final_file == false) {
                              return (
                                <React.Fragment key={index}>
                                  <Grid item xs={2} style={{ marginBottom: "15px", marginTop: "15px" }}>
                                    {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                    <Card style={{ width: '73px' }}>
                                      <CardMedia
                                        component="img"
                                        alt="template"
                                        style={{ width: 25, height: 40, padding: '15px 24px' }}
                                        height="15"
                                        width="20"
                                        image={imgFileTemplate}
                                        title={item.attributes.name}
                                        className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                      />
                                    </Card>
                                    {/* </span> */}
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              )
                            }
                          }
                          )}
                        </div>
                        <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                          onClick={() => {
                            localStorage.setItem("task", "0")
                            this.fileInput1.click()
                          }}
                        >
                          <ReactS3Uploader
                            type="file"
                            id="upload"
                            name="pic"
                            getSignedUrl={this.getSignedUrl}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read'
                            }}
                            s3path="/uploads/"
                            contentDisposition="auto"
                            accept="**"
                            capture={true}
                            inputRef={cmp => this.fileInput1 = cmp}
                            onChange={(event: any) =>
                              this.inputFileChanged(event)
                            }
                            style={{
                              visibility: "hidden",
                              position: "absolute",
                              alignContent: "center", backgroundColor: 'white',
                              color: '#419bf9',
                              width: "280px", padding: 20,
                              fontSize: 16, fontWeight: 'bold',
                              borderWidth: 1,
                              borderColor: '#419bf9',
                              borderRadius: 10,
                              borderStyle: 'dashed',
                              fontVariant: "initial",
                              textTransform: 'none',
                              marginBottom: "20px"
                            }}
                          />
                          <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                        </div>






                      </div>

                      {/* <div className={classes.banners}>
  <div style={{ marginTop: "20px" }}>
    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Headline</p>
    <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque.</p>
  </div>
  <div style={{ marginTop: "20px" }}>
    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Subline</p>
    <p style={{ marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iacul elit et libero.</p>
  </div>
  <div style={{ marginTop: "20px" }}>
    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Target URL</p>
    <p style={{ marginTop: "0px" }}>www.exampledomain.com</p>
  </div>
</div> */}
                      {this.state.currentTaskBriefingData?.length > 0 && this.state.currentTaskBriefingData.slice(0).map((briefedValues: any, index: any) => (
                        <div style={{ marginTop: "40px", marginLeft: "20px" }}>

                          {briefedValues.attributes.value_type == "slider" ?
                            (
                              <>
                                <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                {/* <CustomSlider
                                  style={{
                                    // width: "100%",
                                    marginLeft: "-10px",
                                    marginRight: "18px",

                                    // marginTop: "18px",
                                  }}
                                  disabled
                                  defaultValue={briefedValues.attributes.value ? briefedValues.attributes.value : 0}
                                  // getAriaValueText={valuetext}
                                  aria-labelledby="discrete-slider-always"
                                  //step={10}
                                  marks={[
                                    {
                                      value: briefedValues.attributes.detailed_values?.min ? parseInt(briefedValues.attributes.detailed_values?.min) : 0,
                                      label: briefedValues.attributes.detailed_values?.min ? briefedValues.attributes.detailed_values?.min : '0',
                                    },
                                    {
                                      value: briefedValues.attributes.detailed_values?.max ? parseInt(briefedValues.attributes.detailed_values?.max) : 100,
                                      label: briefedValues.attributes.detailed_values?.max ? briefedValues.attributes.detailed_values?.max : '100',
                                    },

                                  ]}
                                  min={briefedValues.attributes.detailed_values?.min ? parseInt(briefedValues.attributes.detailed_values?.min) : 0}
                                  max={briefedValues.attributes.detailed_values?.max ? parseInt(briefedValues.attributes.detailed_values?.max) : 100}
                                  valueLabelDisplay="on"
                                //ValueLabelComponent={StyledValueLabel}
                                /> */}
                              </>)
                            :
                            briefedValues.attributes.value_type == "date_time" ?
                              (
                                <>
                                  <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                  <p style={{ fontSize: '14px', marginTop: "0px" }}>{(briefedValues.attributes.value).replace("T", ", ")}</p>
                                </>
                              )
                              :
                              briefedValues.attributes.value_type === "file" || briefedValues.attributes.value_type === "image" ?
                                (
                                  <>
                                    {briefedValues.attributes.file_blob_signed_id_url &&
                                      <React.Fragment>
                                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                        <span>
                                          <Card style={{ width: '73px' }}>
                                            <CardMedia
                                              component="img"
                                              alt="template"
                                              style={{ width: 50, height: 60, padding: '5px 10px' }}
                                              height="15"
                                              width="20"
                                              image={briefedValues.attributes.file_blob_signed_id_url ? briefedValues.attributes.file_blob_signed_id_url : imgFileTemplate}
                                              // title={item.attributes.name}
                                              className={classes.templateHover}
                                            />
                                          </Card>
                                        </span>
                                      </React.Fragment>
                                    }
                                  </>
                                )
                                :
                                (briefedValues.attributes.value_type == "short_text" ||
                                  briefedValues.attributes.value_type == "long_text" ||
                                  briefedValues.attributes.value_type == "email" ||
                                  briefedValues.attributes.value_type == "multiple_selection" ||
                                  briefedValues.attributes.value_type == "single_selection" ||
                                  briefedValues.attributes.value_type == "drop_down_select" ||
                                  briefedValues.attributes.value_type == "slider"
                                ) ?
                                  (
                                    <>
                                      {briefedValues.attributes.value && briefedValues.attributes.value !== "" &&
                                        <React.Fragment>
                                          <p style={{ fontSize: "16px", marginBottom: "0px" }}>{briefedValues.attributes.label}</p>
                                          <p style={{ fontSize: '14px', marginTop: "0px" }}>{briefedValues.attributes.value}</p>
                                        </React.Fragment>
                                      }
                                    </>
                                  )
                                  :
                                  briefedValues.attributes.value_type == "page_separator" ?
                                    "" :
                                    null
                          }
                        </div>
                      ))}

                      {/* <div style={{ marginTop: "40px", marginLeft: "20px" }}>
  <p style={{ fontSize: "16px", marginBottom: "0px" }}>Do you have any other instructions?</p>
  <p style={{ fontSize: '14px', marginTop: "0px" }}>ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue elit ipsum in suscipit pharetra, mi odio aliquet neque, non iaculis augue eli et libero.)
</p>
</div> */}

                      <Accordion className={classes.accordianStyle} defaultExpanded>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>Activity <img src={downIcon} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12}>

                            {/* <Paper component="form" className={classes.root} style={{height:'200px',border:'1px solid black',margin:15}} > */}


                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Add Comment"
                              wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                              editorStyle={{ padding: 10, minHeight: 100 }}
                              toolbarStyle={{ height: 50, fontSize: 18 }}
                              onEditorStateChange={this.onEditorStateChange}
                              mention={{
                                separator: ' ',
                                trigger: '@',
                                suggestions: this.state.selectedProjectsMentionsAccountsData
                                //  [
                                //   { text: 'APPLE', value: 'apple', url: 'apple' },
                                //   { text: 'BANANA', value: 'banana', url: 'banana' },
                                //   { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                //   { text: 'DURIAN', value: 'durian', url: 'durian' },
                                //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                //   { text: 'FIG', value: 'fig', url: 'fig' },
                                //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                // ],
                              }}
                              hashtag={{
                                separator: ' ',
                                trigger: '#',
                              }}
                              toolbarCustomButtons={[
                                <Button
                                  style={{
                                    backgroundColor: colorToBeChanged,
                                    color: "white",
                                    marginRight: "0px",
                                  }}
                                  type="submit"
                                  variant="contained"
                                  onClick={() => { this.callCreateTaskCommentListApi(this.state.convertedCommentData) }}
                                >Comment
                                  {/* {t("projects.saveChanges")} */}
                                </Button>

                              ]}
                              toolbar={{
                                options: ["inline", "list", "link", "image"],
                                inline: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["bold", "italic"],
                                  // bold: { className: "test", style: { height:80 } },
                                  // italic: { className: undefined },
                                },
                                list: {
                                  inDropdown: false,
                                  className: "test",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ['unordered', 'ordered'],
                                  unordered: { className: undefined },
                                  ordered: { className: undefined },
                                  // indent: { icon: indent, className: undefined },
                                  // outdent: { icon: outdent, className: undefined },
                                },
                                link: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  dropdownClassName: undefined,
                                  showOpenOptionOnHover: true,
                                  defaultTargetOption: '_self',
                                  options: ['link'],
                                  // link: { icon: link, className: undefined },
                                  // unlink: { icon: unlink, className: undefined },
                                  linkCallback: undefined
                                },


                                image: {
                                  //icon:,
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  urlEnabled: true,
                                  uploadEnabled: true,
                                  alignmentEnabled: true,
                                  uploadCallback: undefined,
                                  previewImage: false,
                                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                  alt: { present: false, mandatory: false },
                                  defaultSize: {
                                    height: 'auto',
                                    width: 'auto',
                                  },
                                },

                              }}
                            />

                            <div>
                              {this.state.getTaskCommentsData?.map((values: any, index: any) => {
                                return (
                                  <div key={index}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <Avatar alt="authar Image" src={values?.attributes?.account?.data?.attributes?.photo_blob_signed_id_url} />
                                      <p style={{ marginLeft: "10px" }}>{values?.attributes?.account?.data?.attributes?.first_name}
                                        <br />
                                        {values?.attributes?.created_at}
                                      </p>
                                    </div>
                                    <div style={{ marginLeft: "45px" }} dangerouslySetInnerHTML={{ __html: values.attributes?.description }} />
                                  </div>
                                )
                              })}
                            </div>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>


                    </div>

                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ marginLeft: "20px", marginTop: "20px" }}>

                      {(this.state.currentTask.attributes?.quotation?.data?.attributes?.price && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && !this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated &&
                        this.state.currentTask.attributes?.quotation?.data?.attributes?.status === "Pending Quote Approval" &&
                        (this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <>
                          <p style={{ fontSize: "16px" }}></p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={(e) => {
                              // this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >

                            {t("projects.selfApprove")}
                          </Button>
                        </>
                      ) : (this.state.currentTask.attributes?.quotation?.data?.attributes?.auto_generated && this.state.currentTask.attributes?.quote_requested === false && this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <>
                          <p style={{ fontSize: "16px" }}>Auto Generated</p>
                          <p style={{ fontSize: "16px" }}>€{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>
                          <Button

                            style={{ backgroundColor: "white", color: secondaryColorToBeChanged, border: "1px solid #1a76b0", marginBottom: "20px" }}
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              var currentTask = this.state.currentTask;
                              currentTask.attributes.quotation.data.attributes.status = "Quote Approved"
                              this.setState({
                                currentTask: currentTask
                              })
                              // this.callUpdateTaskQuotationApi(this.state.currentTask?.attributes?.quotation?.data?.id);
                            }}
                          >
                            {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" ? (
                              <span>{t("projects.selfApprove")}</span>
                            ) : (
                              <span>Approved</span>
                            )}
                          </Button>
                        </>
                      ) : undefined))}

                      {this.state.currentTask.attributes?.quotation?.data?.attributes?.status !== "Quote Approved" && (this.state.permissions !== "albertbauer" && this.state.permissions === "approver") ? (
                        <div>
                          {(this.state.currentTask.attributes?.quote_requested === false) ? (
                            <>
                              <Button

                                style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                              // onClick={() => { this.callCreateRequestQuotationApi(this.state.currentTask?.id) }}
                              >
                                {t("projects.requestQuote")}
                              </Button>
                              <Divider style={{ marginBottom: "20px" }} />
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                style={{ backgroundColor: colorToBeChanged, color: "white", marginBottom: "20px" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                              // onClick={() => { this.callCreateRequestQuotationApi(this.state.currentTask?.id) }}
                              >
                                Quote Requested
                              </Button>
                              <Divider style={{ marginBottom: "20px" }} />
                            </>
                          )}

                        </div>
                      ) : (
                        <div>
                          {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                            <>
                              <p>Approved quote - €{this.state.currentTask.attributes?.quotation?.data?.attributes?.price}</p>

                              <Divider style={{ marginBottom: "20px" }} />
                            </>
                          ) : undefined}
                        </div>



                      )}
                      <TextField
                        id="taskType"
                        disabled
                        name="taskType"
                        select
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        // label={t("TaskView.taskType")}
                        label="Asset Type"
                        value={this.state.currentTask.attributes.asset_type_name}
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      >
                        <option>{this.state.currentTask.attributes.asset_type_name}</option>

                      </TextField>
                      {(this.state.permissions === "approver" || this.state.permissions === "viewer") ? (
                        <TextField
                          name="status"
                          disabled
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)
                                this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state}
                          className={classes.selectInput}
                          variant="outlined"


                        > <MenuItem aria-label="None" value="started" />
                          <MenuItem key="New" value="New"> New</MenuItem>
                          <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>

                          <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>                        {/* <MenuItem key="Open" value="Open">Open</MenuItem> */}
                          {this.state.taskStatusesData?.map((option: any) => (

                            <MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>

                          ))}


                        </TextField>

                      ) : (
                        <TextField
                          name="status"
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          select
                          onChange={(e) => {
                            //console.log("target", e)
                            var action: string = ""
                            this.state.taskStatusesData.map((item: any) => {
                              if (item.attributes.status === e.target.value) {
                                action = item.attributes.action
                                //console.log("action", action)


                                // this.callStateTransitionsAPI(action, this.state.currentTask.id)

                              }
                            })

                          }}
                          size="small"
                          fullWidth
                          label={t("TaskView.status")}
                          value={this.state.currentTask.attributes.ultimate_state || ''}
                          className={classes.selectInput}
                          variant="outlined"
                        > <MenuItem aria-label="None" value="started" />
                          <MenuItem key="New" value="New"> New</MenuItem>
                          <MenuItem key="Quote Approved" value="Quote Approved"> Quote Approved</MenuItem>
                          <MenuItem key="Pending Quote Approval" value="Pending Quote Approval"> Pending Quote Approval</MenuItem>

                          <MenuItem key="Briefing Completed" value="Briefing Completed"> Briefing Completed</MenuItem>
                          {this.state.taskStatusesData?.map((option: any) => (

                            <MenuItem key={option.attributes.status} value={option.attributes.status} > {option.attributes.status} </MenuItem>

                          ))}


                        </TextField>

                      )
                      }
                      <TextField
                        focused
                        id="date"
                        name="Due Date"
                        type="date"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        size="small"
                        fullWidth
                        label="Due Date"

                        variant="outlined"
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}
                        value={this.state.currentTask.attributes.estimated_end_date}
                        onChange={(e) => {
                          this.handleDateChange(e);
                        }}
                      />
                      <TextField
                        id="Requestor"
                        name="Requestor"
                        disabled
                        size="small"
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused
                          }
                        }}
                        fullWidth
                        label="Requestor"
                        value={this.state.currentTask?.attributes?.requestor?.data?.attributes?.first_name}
                        className={classes.selectInput}
                        SelectProps={{
                          native: true,
                        }}

                        variant="outlined"
                      ></TextField>
                      <Autocomplete
                        id="fixed-tags-demo"
                        className={classes.selectInput}
                        options={this.state.accountsData}
                        inputValue={this.state.currentTask?.attributes?.reviewer?.data?.attributes?.first_name}
                        getOptionLabel={(option: any) => option.attributes.first_name}
                        onChange={(e: any, value: any) => {
                          this.handleReviewerChange(e, value);
                        }}
                        style={{ width: 280 }}
                        renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
                      />
                      {this.state.permissions === 'editor' ?

                        <Autocomplete
                          inputValue={this.state.currentTask?.attributes?.assignee?.data?.attributes?.first_name}
                          id="fixed-tags-demo"
                          className={classes.selectInput}
                          options={this.state.accountsData}
                          getOptionLabel={(option: any) => option.attributes.first_name}
                          onChange={(e: any, value: any) => {
                            // this.handleAssigneeChange(e, value);
                          }}
                          style={{ width: 280 }}
                          renderInput={(params) => <TextField {...params} label="Assignee" variant="outlined" />}
                        />
                        : undefined}
                      <p style={{ fontSize: "16px" }}>Task Progress</p>
                      <div className={classes.progress}>
                        <span> <p style={{ fontSize: "16px" }}>{this.state.currentTask.attributes.progress} %</p> </span>

                        <span style={{ marginTop: "10px", marginLeft: "10px" }}>   <p style={{ fontSize: "10px" }}>completed</p></span>
                      </div>
                      {(this.state.permissions === "approver" || this.state.permissions === "editor") ? (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      ) : (
                        <div style={{ marginBottom: "20px" }}>
                          <PrettoSlider disabled valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={this.state.currentTask.attributes.progress} onChange={this.progressPercentage} />

                        </div>
                      )}

                      <Divider />
                      <div style={{ margin: "20px" }}>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Created</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.created_at}</p>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Updated</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes.updated_at}</p>
                        </div>
                        <div>
                          <p style={{ fontSize: "16px", marginBottom: "0px" }} >Task ID</p>
                          <p style={{ fontSize: "14px", marginTop: "0px" }} >{this.state.currentTask.attributes?.order_number}</p>
                        </div>
                        {this.state.permissions === 'editor' ?
                          <>
                            <div>
                              <p style={{ fontSize: "16px" }} >Final Files for Sign off</p>
                            </div>
                            <div>
                              <div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                        {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                        <Card style={{ width: '73px' }}>
                                          <CardMedia
                                            component="img"
                                            alt="template"
                                            style={{ width: 25, height: 40, padding: '15px 24px' }}
                                            height="15"
                                            width="20"
                                            image={imgFileTemplate}
                                            title={item.attributes.name}
                                            className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                          />
                                          {/* </CardActionArea> */}
                                        </Card>
                                        {/* </span> */}
                                        <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                          {item.attributes.name}
                                        </Typography>
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                                </div>
                                <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                                  onClick={() => {
                                    localStorage.setItem("task", "1")
                                    this.fileInput2.click()
                                  }}
                                >
                                  <ReactS3Uploader
                                    type="file"
                                    id="upload"
                                    name="pic"
                                    getSignedUrl={this.getSignedUrl}
                                    uploadRequestHeaders={{
                                      'x-amz-acl': 'public-read'
                                    }}
                                    s3path="/uploads/"
                                    contentDisposition="auto"
                                    accept="**"
                                    // multiple
                                    capture={true}
                                    inputRef={cmp => this.fileInput2 = cmp}
                                    onChange={(event: any) =>
                                      this.inputFileChanged(event)
                                    }
                                    style={{
                                      visibility: "hidden",
                                      position: "absolute",
                                      alignContent: "center", backgroundColor: 'white',
                                      color: '#419bf9',
                                      width: "280px", padding: 20,
                                      fontSize: 16, fontWeight: 'bold',
                                      borderWidth: 1,
                                      borderColor: '#419bf9',
                                      borderRadius: 10,
                                      borderStyle: 'dashed',
                                      fontVariant: "initial",
                                      textTransform: 'none',
                                      marginBottom: "20px"
                                    }}
                                  />
                                  <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                                </div>
                              </div>
                            </div>
                            <div>
                              <p style={{ fontSize: "16px" }}>Source Files</p>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {this.state.fileTaskName && this.state.fileTaskName?.map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={2} style={{ marginRight: "40px", marginBottom: "15px" }}>
                                    {/* <span onClick={(e) => { this.setState({ fileId: item?.id, file_blob_signed_id_url: item?.attributes?.file_blob_signed_id_url }, () => { this.taskFileShow(this.state.fileId), this.openModalAnnotate(); }) }}> */}
                                    <Card style={{ width: '73px' }}>
                                      <CardMedia
                                        component="img"
                                        alt="template"
                                        style={{ width: 25, height: 40, padding: '15px 24px' }}
                                        height="15"
                                        width="20"
                                        image={imgFileTemplate}
                                        title={item.attributes.name}
                                        className={(this.state.fileTaskName?.id == item?.id) ? classes.templateHover : "white"}
                                      />
                                      {/* </CardActionArea> */}
                                    </Card>
                                    {/* </span> */}
                                    <Typography color="textSecondary" style={{ marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '79px' }}>
                                      {item.attributes.name}
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </div>
                            <div style={{ height: "45px", width: '60px', border: "1px dotted #1a76b0", borderRadius: "8px" }}
                              onClick={() => {
                                localStorage.setItem("task", "2")

                                this.fileInput3.click()
                              }}
                            >
                              <ReactS3Uploader
                                type="file"
                                id="upload"
                                name="pic"
                                getSignedUrl={this.getSignedUrl}
                                uploadRequestHeaders={{
                                  'x-amz-acl': 'public-read'
                                }}
                                s3path="/uploads/"
                                contentDisposition="auto"
                                accept="**"
                                // multiple
                                capture={true}
                                //  ref={this.fileInput}
                                inputRef={cmp => this.fileInput3 = cmp}
                                onChange={(event: any) =>
                                  this.inputFileChanged(event)
                                }
                                style={{
                                  visibility: "hidden",
                                  position: "absolute",
                                  // width: "280px",
                                  alignContent: "center", backgroundColor: 'white',
                                  color: '#419bf9',
                                  width: "280px", padding: 20,
                                  fontSize: 16, fontWeight: 'bold',
                                  borderWidth: 1,
                                  borderColor: '#419bf9',
                                  borderRadius: 10,
                                  borderStyle: 'dashed',
                                  fontVariant: "initial",
                                  textTransform: 'none',
                                  marginBottom: "20px"
                                }}
                              />
                              <p style={{ color: " #1a76b0", marginLeft: "50%" }}>+</p>

                            </div>
                          </>
                          : ""}
                      </div>

                    </div>

                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>
        ) : undefined}
        {/* create task blocker popup */}
        <div >
          <Dialog maxWidth={false} open={this.state.openTaskBlockerCreateModel} onClose={this.handleClickCancelNewTaskBlockerModel} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Blocker</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewTaskBlockerModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  description: "",


                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required(
                    "description is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateTaskBlockerApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primary'
                              }} fullWidth label="Description"
                              className={classes.confirmInput}
                              name="description"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="description" variant="outlined"
                              helperText={
                                touched.description &&
                                  errors.description ? (<>
                                    description is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>



                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          {/* <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }} onClick={this.handleClickStartBriefing}>
                      START BRIEFING
                      </Button> */}
                          <Button variant="contained" type="submit" style={{
                            backgroundColor: colorToBeChanged,
                            color: "white",
                            marginLeft: "15px",
                          }}>
                            SAVE BLOCKER
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>
        </div>
        <div >
          <Dialog maxWidth={false} open={this.state.openSubTaskCreateModel} onClose={this.handleClickCancelNewSubTakModel} aria-labelledby="form-dialog-title">
            <div style={{ width: 800 }}>

              <DialogTitle id="form-dialog-title" style={{
                fontSize: '16px', fontWeight: 'bolder'
              }}>
                <span style={{ fontWeight: 'bold' }}>Add Subtasks</span>
                <IconButton style={{
                  position: 'absolute',
                  right: 10,

                }} onClick={this.handleClickCancelNewSubTakModel}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Formik
                initialValues={{
                  title: "",



                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  title: Yup.string().required(
                    "title is required"
                  ),


                })}
                onSubmit={(values) => {
                  this.callCreateSubTaskApi(values)
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => {


                  return (
                    <form onSubmit={handleSubmit}>

                      <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container spacing={3}>


                          <Grid item xs={12}>
                            <TextField id="outlined-basic" multiline
                              rows={4} style={{
                                marginBottom: 10,
                                marginTop: 3,
                                color: 'primory'
                              }} fullWidth label="Title"
                              className={classes.confirmInput}
                              name="title"
                              InputLabelProps={{ style: { borderColor: 'black', color: '' } }}
                              InputProps={{ style: { borderColor: 'black' } }}
                              placeholder="Enter Title" variant="outlined"
                              helperText={
                                touched.title &&
                                  errors.title ? (<>
                                    Title is required
                                  </>
                                ) : (
                                  ""
                                )
                              }
                              error={
                                touched.title && errors.title
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </Grid>


                        </Grid>

                      </DialogContent>

                      <DialogActions>

                        <Grid container item >
                          <Button variant="contained" type="submit" style={{ backgroundColor: '#343f56', color: "#FFF", borderRadius: 10, marginBottom: "18px", margin: 15, fontFamily: "Roboto", padding: 15, fontWeight: 'initial' }}>
                            SAVE SUB TASK
                          </Button>
                        </Grid>


                      </DialogActions>

                    </form>
                  );
                }}
              />
            </div>
          </Dialog>

          <Dialog
            open={this.state.openTaskCommentPopUp}
            onClose={this.cancelSpecificTaskComment}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Task Comment"}
            </DialogTitle>
            <DialogContent>
              <Grid item xs={12}>
                <Editor
                  editorState={this.state.editComment}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Add Comment"
                  wrapperStyle={{ height: 200, border: '1px solid black', margin: 15, display: 'flex', flexDirection: 'column-reverse' }}
                  editorStyle={{ padding: 10, minHeight: 100 }}
                  toolbarStyle={{ height: 50, fontSize: 18, background: "#f5f6fa", marginBottom: "0px", }}
                  onEditorStateChange={this.onEditorStateCommentTaskSpecific}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: this.state.selectedProjectsMentionsAccountsData
                  }}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }}
                  toolbarCustomButtons={[
                    <Button
                      size="small"
                      onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                      style={{ color: 'white', backgroundColor: '#173e59', position: 'absolute', right: '70px' }}
                      startIcon={<SendIcon />}
                      type="submit"
                    >
                      Send
                    </Button>
                    // <button
                    //   style={{
                    //     transform: "rotate(-45deg)", border: "none", outline: "none", background: "none", marginLeft: "200px",
                    //   }}
                    //   type="submit"
                    //   onClick={(e: any) => { this.callUpdateTaskCommentApi(this.state.convertedCommentData2) }}
                    // ><SendIcon />

                    // </button>

                  ]}
                  toolbar={{
                    options: ["inline"],
                    inline: {
                      inDropdown: false,
                      className: "test",
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic"],
                    },
                    list: {
                      inDropdown: false,
                      className: "test",
                      component: undefined,
                      dropdownClassName: undefined,
                      // options: ['unordered', 'ordered'],
                      unordered: { className: undefined },
                      ordered: { className: undefined },
                    },
                    // link: {
                    //   inDropdown: false,
                    //   className: undefined,
                    //   component: undefined,
                    //   popupClassName: undefined,
                    //   dropdownClassName: undefined,
                    //   showOpenOptionOnHover: true,
                    //   defaultTargetOption: '_self',
                    //   options: ['link'],
                    //   linkCallback: undefined
                    // },
                    // emoji: {
                    //   className: undefined,
                    //   component: undefined,
                    //   popupClassName: undefined,
                    //   emojis: [
                    //     '', '', '', '', '', '', '', '', '', '珞', '樂', '', '', '', '', '邏',
                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                    //     '⛷', '', '', '', '', '', '⛹', '', '', '', '', '', '', '', '', '',
                    //     '', '', '落', '', '', '', '', '✊', '', '', '', '', '', '', '', '',
                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                    //     '', '', '', '⏰', '', '', '', '⭐', '', '', '', '', '⛄', '', '', '',
                    //     '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                    //     '✅', '❎', '',
                    //   ],
                    // },

                    // image: {
                    //   //icon:,
                    //   urlEnabled: false,
                    //   uploadEnabled: true,
                    //   uploadCallback: this.getSignedUrlTaskFileComment,
                    //   previewImage: false,
                    //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    // },
                  }}
                />
              </Grid>
            </DialogContent>
          </Dialog>

          <div>
            <Dialog
              open={this.state.setOpenImageDialog}
              maxWidth="lg"
              onClose={() => this.setState({ setOpenImageDialog: false })}
              aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle style={{ cursor: 'move', backgroundColor: "rgb(0 0 0 / 78%)", padding: "5px 24px 24px" }} id="draggable-dialog-title">
                <Typography style={{ display: "flex", color: "#ffffff", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography>
                    {this.state.fileImage}
                  </Typography>
                  <div style={{ textAlign: "center" }}>
                    <Typography>
                      {this.state.currentTask?.attributes?.brand_name} / {this.state.currentTask?.attributes?.project_name}
                    </Typography>
                  </div>
                  <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                    <Typography onClick={() => this.setState({ setOpenImageDialog: false })}>
                      <img src={CancelIcon} />
                    </Typography>
                  </div>
                </Typography>
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "rgb(0 0 0 / 78%)", padding: 0, overflow: "hidden" }}>
                <img src={this.state.file_blob_signed_id_url} style={{ width: "100%", height: "100%" }} />
              </DialogContent>
            </Dialog>
          </div>


        </div>
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start

export default withStyles(styles)(withTranslation()(HomePage));
