Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.brandListingAPiEndPoint = "/bx_block_categories_sub_categories/brands/brand_project_listing";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultilevelApproval";
exports.labelBodyText = "MultilevelApproval Body";
exports.REACT_APP_API_stage = "https://abordersystem-37196-ruby.b37196.stage.us-east-1.aws.svc.builder.ai";
exports.REACT_APP_API_prod = "https://abordersystem-37196-ruby.b37196.prod.eu-central-1.aws.svc.builder.ai";

exports.btnExampleTitle = "CLICK ME";

// Get Approved Quote Api call config
exports.getQuoteApprovedContentType = "application/json";
exports.getQuoteApprovedApiMethodType = "GET";
exports.getQuoteApprovedApiEndPoint = "bx_block_project_portfolio/tasks?page=1&approval_status_type=quote_approved";

// Get Open Quote Api 
exports.getQuoteOpenContentType = "application/json";
exports.getQuoteOpenApiMethodType = "GET";
exports.getQuoteOpenApiEndPoint = "bx_block_project_portfolio/tasks?page=1&approval_status_type=draft";

// Get Pending Quote Api 
exports.getQuotePendingContentType = "application/json";
exports.getQuotePendingApiMethodType = "GET";
exports.getQuotePendingApiEndPoint = "bx_block_project_portfolio/tasks?page=1&approval_status_type=waiting_for_quote";

// Get waiting Quote Api 
exports.getQuotePendingContentType = "application/json";
exports.getQuotePendingApiMethodType = "GET";
exports.getQuoteSentApiEndPoint = "bx_block_project_portfolio/tasks?page=1&approval_status_type=quote_sent";

// Get waiting Quote Api 
exports.getQuotePendingContentType = "application/json";
exports.getQuotePendingApiMethodType = "GET";
exports.getWaitingForApprovalApiEndPoint = "bx_block_project_portfolio/tasks?page=1&approval_status_type=pending_quote_approval";

// Delete Project From The Draft 
exports.contentTypeApiProjectDelete = "application/json";
exports.deletionProjectApiMethod = "DELETE";
exports.deletionProjectApiEndPoint = "bx_block_project_portfolio/projects/";

// Duplicate the project 
exports.contentTypeApiProjectDuplicate = "application/json";
exports.duplicateProjectApiMethod = "POST";
exports.duplicateProjectApiEndPoint = "bx_block_project_portfolio/projects/project_duplication";

// Project Request Quote 
exports.contentTypeApiProjectRequestQuote = "application/json";
exports.projectRequestQuoteApiMethod = "POST";
exports.projectRequestQuoteApiEndPoint = "bx_block_project_portfolio/task_quotations/request_quote";

// Project Archive 
exports.contentTypeApiProjectArchive = "application/json";
exports.projectArchiveApiMethod = "PUT";
exports.projectArchiveApiEndPoint = "bx_block_project_portfolio/projects/";

//All Accounts Data 
exports.contentTypeApiAllAccountData = "application/json";
exports.allAccountDataApiMethod = "GET";
exports.allAccountDataApiEndPoint = "account_block/accounts";

// Invite the project
exports.contentTypeApiInviteProject = "application/json";
exports.inviteProjectApiMethod = "POST";
exports.inviteProjectApiEndPoint = "bx_block_project_portfolio/projects";

// Get Project Details By ID
exports.contentTypeApiProjectDetails = "application/json";
exports.projectDetailsApiMethod = "GET";
exports.projectDetailsApiEndPoint = "bx_block_project_portfolio/projects/";

// Start Project Config
exports.contentTypeApiStartProject = "application/json";
exports.startProjectApiMethod = "PUT";
exports.startProjectApiEndPoint = "bx_block_project_portfolio/projects/";

// Edit Quotation Config
exports.contentTypeApiEditQuote = "application/json";
exports.editQuoteApiMethod = "PUT";
exports.editQuoteApiEndPoint = "bx_block_project_portfolio/task_quotations/";

// Create Quotation Config
exports.contentTypeApiCreateQuotation = "application/json";
exports.createQuotationApiMethod = "POST";
exports.createQuotationApiEndPoint = "bx_block_project_portfolio/task_quotations";


//task  approve quote api
exports.taskApproveQuoteAPiEndPoint = "bx_block_project_portfolio/task_quotations/";
exports.taskApproveQuoteAPiMethod = "PUT";
exports.taskApproveQuoteApiContentType = "application/json";

//task update api
exports.taskUpdateAPiEndPoint = "bx_block_project_portfolio/tasks/";
exports.taskUpdateAPiMethod = "PUT";
exports.taskUpdateApiContentType = "application/json";

//task comment create api
exports.taskCommentCreateAPiEndPoint = "bx_block_comments/task_comments";
exports.taskCommentCreateAPiMethod = "POST";
exports.taskCommentCreateApiContentType = "application/json";

//task comment index api
exports.getTaskCommentCreateAPiEndPoint = "bx_block_comments/task_comments";
exports.getTaskCommentCreateAPiMethod = "GET";
exports.getTaskCommentCreateApiContentType = "application/json";

//Blockers create api
exports.blockersCreateAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersCreateAPiMethod = "POST";
exports.blockersCreateApiContentType = "application/json";


//Blockers index api
exports.blockersAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersAPiMethod = "GET";
exports.blockersApiContentType = "application/json";

//subtask create api
exports.subtaskCreateAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskCreateAPiMethod = "POST";
exports.subtaskCreateApiContentType = "application/json";

//Get subtask create api
exports.getSubtaskCreateAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.getSubtaskCreateAPiMethod = "GET";
exports.getSubtaskCreateApiContentType = "application/json";

// Get Presigned url
exports.callToPreSignedURLContentType = "application/json",
exports.callToPreSignedURLMethodType = "POST",
exports.callToPreSignedURLApiEndPoint ="bx_block_upload_media/s3_uploads/get_presigned_url"

// task File uploading config
exports.taskBulkUploadContentType = "application/json",
exports.taskBulkUploadContentTypeMethodType = "POST",
exports.taskBulkUploadContentTypeApiEndPoint ="bx_block_bulk_uploading/task_bulk_uploads"

// Get Task file list
exports.getTaskFileGetContentType = "application/json",
exports.getTaskFileGetMethodType = "GET",
exports.getTaskFileGetApiEndPoint ="bx_block_upload_media/task_files?page=1&per_page=1500&task_id="

//subtask index api
exports.subtaskAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskAPiMethod = "GET";
exports.subtaskApiContentType = "application/json";

//subtask update api
exports.subtaskUpdateAPiEndPoint = "bx_block_project_portfolio/sub_tasks/";
exports.subtaskUpdateAPiMethod = "PUT";
exports.subtaskUpdateApiContentType = "application/json";

//Blockers update api
exports.blockersUpdateAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersUpdateAPiMethod = "PUT";
exports.blockersUpdateApiContentType = "application/json";

//Send Quote Blockers update api
exports.blockersSendQuoteUpdateAPiEndPoint = "bx_block_project_portfolio/task_blockers/";
exports.blockersSendQuoteUpdateAPiMethod = "PUT";
exports.blockersSendQuoteUpdateApiContentType = "application/json";
//Delete Task file
exports.taskFileDeleteAPiEndPoint = "bx_block_upload_media/task_files/";
exports.taskFileDeleteAPiMethod = "DELETE";
exports.taskFileDeleteApiContentType = "application/json"; 

//account index api
exports.accountAPiEndPoint = "account_block/accounts";
exports.accountAPiMethod = "GET";
exports.accountApiContentType = "application/json";

//account project delete api
exports.accountProjectDeleteAPiEndPoint = "bx_block_project_portfolio/account_projects/delete_project_members";
exports.accountProjectDeleteAPiMethod = "DELETE";
exports.accountProjectDeleteApiContentType = "application/json";

//project update api
exports.projectUpdateAPiEndPoint = "bx_block_project_portfolio/projects";
exports.projectUpdateAPiMethod = "PUT";
exports.projectUpdateApiContentType = "application/json";



//task comment show specific api
exports.taskCommentSpecificCommentAPiEndPoint = "bx_block_comments/task_comments/";
exports.taskCommentSpecificCommentAPiMethod = "GET";
exports.taskCommentSpecificCommentApiContentType = "application/json";



//task comment update api
exports.taskCommentUpdateAPiEndPoint = "bx_block_comments/task_comments/";
exports.taskCommentUpdateAPiMethod = "PUT";
exports.taskCommentUpdateApiContentType = "application/json";

//task_activity_logs index api
exports.taskActivityLogAPiEndPoint = "bx_block_project_portfolio/task_activity_logs";
exports.taskActivityLogAPiMethod = "GET";
exports.taskActivityLogApiContentType = "application/json";

// Customizable Area End