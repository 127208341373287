import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from 'react-toastify';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Navigation } from "@material-ui/icons";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  firstNameError: boolean;
  lastName: string;
  lastNameError: boolean;
  email: string;
  emailError: boolean;
  emailStatusError: boolean;
  password: string;
  passwordError: boolean;
  passwordStatusError: boolean;
  otpAuthToken: string;
  reTypePassword: string;
  reTypePasswordError: boolean;
  reTypePasswordStatusError: boolean;
  companyName: string;
  companyNameError: boolean;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  acceptTermsAndConditions: boolean;
  messageError: any,
  showPassword: boolean,
  showConfirmPassword : boolean

  language :any,
  flag : boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
/* istanbul ignore next */
export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  accountActivation: string = "";
  accountActivationmailId : string = "";
  accountActivationmailId2 : string = "";


  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    console.log(props, "props")
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      companyName: '',
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      emailStatusError: false,
      companyNameError: false,
      passwordError: false,
      passwordStatusError: false,
      reTypePasswordError: false,
      reTypePasswordStatusError: false,
      messageError: "",
      showPassword: false,
      showConfirmPassword : false,
      language : localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : 'en',
      flag : false,
      acceptTermsAndConditions: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    console.log(message, "message")
    console.log(message.properties?.RestAPIResponceSuccessMessage?.errors, "Error")
    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.createAccountApiCallId){
      if (message.properties?.RestAPIResponceSuccessMessage?.data != null) {
        toast.success(this.props?.t("EmailAccountRegistrationController.accountCreatedSuccess"))
        localStorage.setItem("userEmail", this.state.email)
        this.props.navigation.navigate('EmailVerfication')
        // localStorage.setItem( "userInfo",JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.account?.data?.attributes))
  
        // localStorage.setItem("token",JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.meta?.token))
      }
  
      if (message.properties?.RestAPIResponceSuccessMessage?.errors) {
        var errorMessage = message.properties?.RestAPIResponceSuccessMessage.errors
        let error = Object.keys(errorMessage[0])
        let errorMsg = errorMessage[0][error[0]]
         console.log(error, "error", errorMsg)
        // console.log(this.state.messageError, "error")
        toast.error(errorMsg)
  
  
      }
    }
   
    else if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage))== this.accountActivation){

      this.setState({flag : true})
      toast.success(this.props?.t("EmailAccountRegistrationController.signUpSuccess"))
      //this.props?.t("EmailAccountLoginController.signInSuccess")
      localStorage.removeItem("userEmail")
      // this.props.navigation.navigate('EmailAccountLoginBlock')
    }
    else if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage))== this.accountActivationmailId){
      if(message.properties?.RestAPIResponceSuccessMessage?.message == "Account not found")
      {
        toast.error("please create your account!")
        this.props.navigation.navigate("EmailAccountRegistration")
      }
      else {
        toast.success("Activation mail sent Successfully!")
      }
     
    }
    else if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage))== this.accountActivationmailId2){
      // toast.success("Activation mail sent Successfully!")
      toast.success(this.props?.t("EmailAccountActivationController.emailActivationMail"))
    }

    // passwordVisibility = (e?: any) => {
    //   this.setState({
    //     showPassword: !this.state.showPassword
    //   })
    // }

    // Customizable Area Start
    // if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //   const apiRequestCallId = message.getData(
    //     getName(MessageEnum.RestAPIResponceDataMessage)
    //   );

    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );

    //   var errorReponse = message.getData(
    //     getName(MessageEnum.RestAPIResponceErrorMessage)
    //   );

    //   if (apiRequestCallId && responseJson) {
    //     if (apiRequestCallId === this.validationApiCallId) {
    //       this.arrayholder = responseJson.data;

    //       if (this.arrayholder && this.arrayholder.length !== 0) {
    //         let regexData = this.arrayholder[0];

    //         if (regexData.password_validation_regexp) {
    //           this.passwordReg = new RegExp(
    //             regexData.password_validation_regexp
    //           );
    //         }

    //         if (regexData.password_validation_rules) {
    //           this.setState({
    //             passwordHelperText: regexData.password_validation_rules
    //           });
    //         }

    //         if (regexData.email_validation_regexp) {
    //           this.emailReg = new RegExp(regexData.email_validation_regexp);
    //         }
    //       }
    //     } else if (apiRequestCallId === this.createAccountApiCallId) {
    //       if (!responseJson.errors) {
    //     //  this.props.navigation.navigate('EmailVerfication')
    //         // const msg: Message = new Message(
    //         //   getName(MessageEnum.AccoutResgistrationSuccess)
    //         // );


    //         // msg.addData(
    //         //   getName(MessageEnum.NavigationPropsMessage),
    //         //   this.props
    //         // );

    //         // this.send(msg);
    //       } else {
    //         //Check Error Response
    //         this.parseApiErrorResponse(responseJson);
    //       }

    //       this.parseApiCatchErrorResponse(errorReponse);
    //     }
    //   }
    // }

    // if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
    //   const otpAuthTkn = message.getData(
    //     getName(MessageEnum.AuthTokenDataMessage)
    //   );
    //   if (otpAuthTkn && otpAuthTkn.length > 0) {
    //     this.setState({ otpAuthToken: otpAuthTkn });
    //     runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
    //     runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    //   }
    // }

    // if (getName(MessageEnum.CountryCodeMessage) === message.id) {
    //   var selectedCode = message.getData(
    //     getName(MessageEnum.CountyCodeDataMessage)
    //   );

    //   if (selectedCode !== undefined) {
    //     this.setState({
    //       countryCodeSelected:
    //         selectedCode.indexOf("+") > 0
    //           ? selectedCode.split("+")[1]
    //           : selectedCode
    //     });
    //   }
    // }
    // Customizable Area End
  }

  activateAccountHandler = ()=>{
    var pageURL = window.location.href;
    console.log(pageURL,pageURL.lastIndexOf('='));
    
    var lastURLSegment = pageURL.substring(pageURL.lastIndexOf('=') + 1);
    console.log("lastURLSegment",lastURLSegment );
    
    var str = lastURLSegment
    window.location.toString().includes("franky")
    if (window.location.toString().includes('?')) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token": lastURLSegment
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.accountActivation = requestMessage.messageId;
      var emailVerification = configJSON.activateComfirmAPiEndPoint + '?' + 'token=' + lastURLSegment
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        emailVerification
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.activateComfirmAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }   
  }
  langaugeChangeHandler = (lang : string)=>{
    this.setState({language : lang})
    // console.log("lang2", this.state.language);

  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToSignIn = () => {
    console.log(this.props)
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  txtInput(e: any) {
    if (e.target.name === "firstName") {
      if (e.target.value === '') {
        this.setState({
          firstNameError: true,
          // lastNameError:false,
          // emailError:false,
          // emailStatusError:false,
          // companyNameError:false,
          // passwordStatusError:false,
          // passwordError:false,
          // reTypePasswordStatusError:false,
          // reTypePasswordError:false
        })
      }
      else {
        this.setState({
          firstNameError: false
        })
      }
      this.setState({
        firstName: e.target.value
      })

    }
    if (e.target.name === "lastName") {
      if (e.target.value === '') {
        this.setState({
          // firstNameError:false,
          lastNameError: true,
          // emailError:false,
          // emailStatusError:false,
          // companyNameError:false,
          // passwordStatusError:false,
          // passwordError:false,
          // reTypePasswordStatusError:false,
          // reTypePasswordError:false
        })
      }
      else {
        this.setState({
          lastNameError: false
        })


      }
      this.setState({
        lastName: e.target.value
      })
    }
    if (e.target.name === "email") {
      if (e.target.value == '') {
        this.setState({
          // firstNameError:false,
          // lastNameError:false,
          emailError: true,
          emailStatusError: false,
          // companyNameError:false,
          // passwordStatusError:false,
          // passwordError:false,
          // reTypePasswordStatusError:false,
          // reTypePasswordError:false
        })
      }
      if (e.target.value.length > 0) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {

          this.setState({
            // firstNameError:false,
            // lastNameError:false,
            emailError: false,
            emailStatusError: true,
            // companyNameError:false,
            // passwordStatusError:false,
            // passwordError:false,
            // reTypePasswordStatusError:false,
            // reTypePasswordError:false
          })



        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (pattern.test(e.target.value) && e.target.value !== "") {
          this.setState({
            emailError: false,
            emailStatusError: false,
          })

        }
      }


      this.setState({
        email: e.target.value
      })
    }
    if (e.target.name === "password") {
      var patternPassword = new RegExp(/^(?=.*[A-Z])(?=.*[#!@$&*?<>',\[\]}{=\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$/);
      if (e.target.value === '') {
        this.setState({
          passwordError: true,
          passwordStatusError: false
        })
      }

      if (e.target.value.length > 0) {
        

        if (!patternPassword.test(e.target.value)) {
          this.setState({

            passwordError: false,
            passwordStatusError: true
          })
        }
        if (patternPassword.test(e.target.value)) {
          this.setState({

            passwordStatusError: false
          })
        }

      }
      this.setState({
        password: e.target.value
      })
    }
    if (e.target.name === "confirmPassword") {
      if (e.target.value === '') {
        this.setState({
          reTypePasswordError: true,
          reTypePasswordStatusError: false
        })
      }

      if (e.target.value !== '' && e.target.value != this.state.password) {
        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: true
        })
      }
      if (e.target.value != '' && e.target.value === this.state.password) {

        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: false
        })
      }
      this.setState({
        reTypePassword: e.target.value
      })
    }
    if (e.target.name === "companyName") {
      if (e.target.value === '') {
        this.setState({
          companyNameError: true
        })
      }
      else {
        this.setState({
          companyNameError: false
        })
      }
      this.setState({
        companyName: e.target.value
      })
    }
  }
  createAccount(e?: any) {
    e.preventDefault()

    if (this.state.firstName === '') {
      this.setState({
        firstNameError: true
      })
    }
    if (this.state.lastName === '') {
      this.setState({
        lastNameError: true
      })
    }
    if (this.state.email === '') {
      this.setState({
        emailError: true
      })
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (pattern.test(this.state.email) && this.state.email !== "") {
      this.setState({
        emailError: false,
        emailStatusError: false,
      })

    }
    if (this.state.companyName === '') {
      this.setState({
        companyNameError: true
      })
    }

    var patternPassword = new RegExp(/^(?=.*[A-Z])(?=.*[#!@$&*?<>',\[\]}{=\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$/);
    if (this.state.password === '') {
      this.setState({
        passwordError: true,
        passwordStatusError: false
      })
    }
    
    else if (!patternPassword.test(this.state.password)) {
      this.setState({

        passwordError: false,
        passwordStatusError: true
      })
    }
    // else if (this.state.password.length < 8 && patternPassword.test(this.state.password)) {
    //   this.setState({
    //     passwordStatusError: false,
    //     passwordError: false,
    //   })
    // }
    else if (this.state.password.length >= 8){
      this.setState({
        passwordStatusError: false,
        passwordError: false,
      })
    }
    if (this.state.reTypePassword === '') {
      this.setState({
        reTypePasswordError: true
      })
    }
    if (this.state.reTypePassword !== '' && this.state.reTypePassword != this.state.password) {
      this.setState({
        reTypePasswordStatusError: true
      })
    }

    // if (
    //   this.isStringNullOrBlank(this.state.firstName) ||
    //   this.isStringNullOrBlank(this.state.lastName) ||
    //   this.isStringNullOrBlank(this.state.email) ||
    //   this.isStringNullOrBlank(this.state.password) ||
    //   this.isStringNullOrBlank(this.state.reTypePassword) ||
    //   this.isStringNullOrBlank(this.state.companyName)

    // ) {
    //   this.showAlert(
    //     configJSON.errorTitle,
    //     configJSON.errorAllFieldsAreMandatory
    //   );
    //   return false;
    // }

    // var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
    //   this.state.countryCodeSelected,
    //   this.state.phone
    // );

    // if (phoneNumberError) {
    //   this.showAlert(configJSON.errorTitle, phoneNumberError);
    //   return false;
    // }

    // if (!this.isValidEmail(this.state.email)) {
    //   this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
    //   return false;
    // }

    // if (!this.passwordReg.test(this.state.password)) {
    //   this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
    //   return false;
    // }

    // if (this.state.password !== this.state.reTypePassword) {
    //   this.showAlert(
    //     configJSON.errorTitle,
    //     configJSON.errorBothPasswordsNotSame
    //   );
    //   return false;
    // }
    // if(!this.state.emailError && !this.state.emailStatusError && !this.state.firstNameError && !this.state.lastNameError && !this.state.companyNameError && this.state.passwordError && !this.state.passwordStatusError && !this.state.reTypePasswordError && !this.state.reTypePasswordStatusError){
    if (this.state.firstName != "" && this.state.lastName && this.state.email != "" && this.state.emailStatusError === false && this.state.companyName !== '' && this.state.password !== '' && this.state.passwordStatusError === false && this.state.reTypePassword !== '' && this.state.reTypePasswordStatusError === false) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };

      const attrs = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        company_name: this.state.companyName,
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.reTypePassword
        // full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
      };
      const data = {
        type: "email_account",
        attributes: attrs
      };

      const httpBody = {
        data: data,
        token: this.state.otpAuthToken,
        language: localStorage.getItem("i18nextLng") == 'en' ? 'English' : 'German'
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountsAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    // }

  }

  passwordVisibility = (e?: any) => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  confirmPasswordVisibility = (e: any) => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }
  resendMail2(e:any) {
    e.preventDefault()

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,

    };

    const attrs = {

      email: localStorage.getItem("userEmail"),

    };
    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      language: localStorage.getItem("i18nextLng") == 'en' ? 'English' : 'German'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountActivationmailId2 = requestMessage.messageId;
   
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAPiMethod
    );




    runEngine.sendMessage(requestMessage.id, requestMessage);





  }

  resendMail() {
    // e.preventDefault()

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,

    };

    const attrs = {

      email: localStorage.getItem("userEmail"),

    };
    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      language: localStorage.getItem("i18nextLng") == 'en' ? 'English' : 'German'
    };

    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountActivationmailId = requestMessage1.messageId;
    console.log(this.accountActivationmailId,"accountActivationmailId");
    
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateAPiEndPoint
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAPiMethod
    );




    runEngine.sendMessage(requestMessage1.id, requestMessage1);





  }
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  showTermsAndConditionsPopup = (event: any) => {
    this.setState((preState) => ({
      acceptTermsAndConditions: !preState.acceptTermsAndConditions
    }))
  }

  // Customizable Area End
}
