import React from "react";
// Customizable Area Start
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Chip from "@material-ui/core/Chip";

import CategoriessubcategoriesController, {
  Props,
  configJSON,
} from "./CategoriessubcategoriesController";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation, Trans } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
//import { Link } from "react-router-dom";
import PictureAsPdfRounded from "@material-ui/icons/PictureAsPdfOutlined"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import SearchIcon from "@material-ui/icons/Search";
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";

// import Board from 'react-trello'
import _ from "lodash";
const defaultUser = require("../../../components/src/defaultuser.png")
// or
// import { InputAdornment } from '@material-ui/core';
import Input from "@material-ui/core/Input";
const downIcon = require("../../../components/src/down.png");
const logo = require("../../../components/src/volkswagen.svg");
// const inviteLogo = require('../../../components/src/svgFiles/group-6.svg')
// const duplicateLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
// const csvExportLogo = require("../../../components/src/svgFiles/group-8.svg");
// const archieveLogo = require("../../../components/src/svgFiles/group-14.svg")
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg");
const csvExportLogo = require("../../../components/src/svgFiles/group-6.svg");
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
import Svg from "../../../components/src/svgComponents/Svg";
const filterButton = require("../../../components/src/filter.png");
const top100Films = [
  {
    title: "The Shawshank Redemption",
    year: 1994,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  {
    title: "Fight Club",
    year: 1999,
    image: require("../../../components/src/volkswagen.svg"),
  },
  { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
  { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "The Lord of the Rings: The Two Towers", year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const styles = {
  root: {
    marginLeft: "50px",
  },
  button: {
    marginLeft: "43px",
    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center",
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
  },
  buttonGroup1: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
  },
  circle: {
    marginLeft: "20px",
    marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: "white",
    borderRadius: "50%",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important",
  },
  inputSerachBox: {
    "&::before": {
      content: "none",
      borderBottom: "none",
    },
    "&:hover": {
      // borderBottom:'none'
    },
    "&::after": {
      borderBottom: "none",
    },
    border: "1px solid #e8edf4",
    background: "#ffffff",
    borderBottom: "1px solid #e8edf4",
    boxSizing: "border-box",
    borderRadius: "8px",
    outline: "none",
    // underline:"none"
  },

  accordianStyle: {
    boxShadow: "none",
  },
  actionCard: {
    backgroundColor: "#e4e8f0",
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    marginTop: "-40px"
  },
  //   avatar:{
  // marginLeft:"40px"
  //   },
  heading: {
    fontSize: "14px",
  },
  cardStyle: {
    // borderRadius: "8px",
    // border: "1px solid #e4e8f0",
    // marginLeft: "10px",
    border: "1px solid #e4e8f0",
    cursor: "pointer",
    height: "15vw",
    display: "flex",
    borderRadius: "8px",
    flexDirection: "column",
    justifyContent: " space-between",
    marginBottom: "20px",
    marginRight: "20px"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
  },

  title: {
    color: "#282828",
    fontSize: "16px",
  },
  dropDown: {
    fontSize: "16px",
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },
  folderDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "20px"
  },
  folderHeaders: {
    fontSize: "16px"
  },
  noOfFiles: {
    fontSize: "16px",
    marginLeft: "20px"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  backdrop2: {
    zIndex: 999,
    color: "#fff",
  },

  tab :{
    textTransform : "none"
  },
  test123 : {
    width: "100%"
  }
} as any;

interface FilmOptionType {
  title: string;
  year: number;
}

class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  textArea: any

  handleClickProjects = (event: any) => {
    console.log(event, "h");
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({ openLink: false })

  }
  // Customizable Area Start
  // Customizable Area End
  handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  handleClickOpenModal = () => {
    this.setState({
      setOpen: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };
  navigateToProjectPortfolio = () => {
    console.log(this.props, "prop");
    // window.location.href='/Home/Projectportfolio'
    this.props.history.push("/Home/Projectportfolio");
  };
  render() {
    const { t, classes } = this.props;
    console.log(this.props, "prop");

    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");
    
    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      <div className={classes.root} style={{fontFamily : primaryFont}}>
        <Grid container spacing={0}>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
            // onClick={() => this.setState({ loading: false })}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "2px" }}>
              <Link
                href="/Home/HomePage"
                style={{
                  color: secondaryColorToBeChanged,
                  fontFamily : SecondaryFont,
                  fontSize: "14px",
                  // textDecoration: "none",
                }}
              >
                Home
              </Link>

              <Typography style={{ fontSize: "12px" ,  fontFamily : SecondaryFont }}>
                Media Asset Library
              </Typography>
            </Breadcrumbs>

            <span>
              <p style={{ fontSize: "32px", margin: "0px 0px 0px 2px" ,  fontFamily : SecondaryFont, }}>
                Media Asset Library{" "}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Media Asset Library</Typography>
                      <p>Here you can see the media asset library</p>
                      {/* here you can see the media asset library */}
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} >

                  </InfoOutlinedIcon>
                </HtmlTooltip>
              </p>
            </span>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              style={{
                color: secondaryColorToBeChanged,
                fontFamily : SecondaryFont,
                marginBottom: "10px",
                textTransform: "none"
              }}
              className={classes.button}
              startIcon={<ImportExportIcon style={{ color: "black" }} />}
              onClick={(event) => this.setState({ anchorElFilter: event.target })}
            >
              Filters
            </Button> */}

            <ButtonGroup
              aria-label="outlined primary button group"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged , textTransform: "none" ,  fontFamily : SecondaryFont}}
                className={
                  this.state.grid ? classes.activeButton : classes.button
                }
                onClick={(e) => {
                  this.changeToGridView(e);
                }}
                // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                startIcon={
                  <AppsOutlinedIcon
                    style={{ color: secondaryColorToBeChanged }}
                  />
                }
              >
                {this.state.grid && <>Grid</>}
              </Button>
              <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged , textTransform: "none" ,  fontFamily : SecondaryFont }}
                className={
                  this.state.list
                    ? classes.activeButtonList
                    : classes.buttonGroup
                }
                onClick={(e) => {
                  this.changeToListView(e);
                }}
                // disabled={true}
                endIcon={
                  <ListOutlinedIcon
                    style={{ color: secondaryColorToBeChanged , fontFamily : SecondaryFont, }}
                  />
                }
              >
                {this.state.list && (
                  <>
                    <span > List</span>
                  </>
                )}
              </Button>
            </ButtonGroup>

            <Input
              id="input-with-icon-adornment"
              className={classes.inputSerachBox}
              onChange={(e) => {
                this.setState({ search: e.target.value }, () => {
                  console.log("search ,", this.state.search);
                  this.searchAssets()
                })
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              disabled={true}
              variant="contained"
              className={classes.button}
              size="medium"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: colorToBeChanged,
                color: "white",
                marginBottom: "10px",
                opacity: 0,
              }}
              onClick={this.handleClickOpenModal}
            >
              Create Project
            </Button>
          </Grid>

          {this.state.grid && (
            <Grid item xs={12}>

              <div style={{ marginTop: "10px" }}>
             {  this.state.search.length == 0 ?
             <div>
               <Button
                variant="contained"
                style={{
                  color: secondaryColorToBeChanged, float: "right", marginRight:"53px",marginBottom:"20px",
                  zIndex: 1 , textTransform: "none" ,  fontFamily : SecondaryFont
                }}
                className={classes.button}
                startIcon={
                  <img src={filterButton} aria-controls="panel1a-content"
                   id="panel1a-header" />
                }
                endIcon={
                  <ExpandMoreIcon style={{ color: "black" }} />
                }
                onClick={(event) => this.setState({ anchorElFilter: event.target })}
              >
                {
                  this.state.brandFilterValue == 'name0' ? 'Name (A-Z)' : this.state.brandFilterValue == 'name1' ? 'Name (Z-A)' :
                  this.state.brandFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.brandFilterValue == 'created_at1' ? 'Created At' :
                  this.state.brandFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                }
              </Button>
                {/* <Button
                  variant="contained"
                  style={{
                    color: secondaryColorToBeChanged, float: "right", marginRight: "37px",
                    zIndex: 1 , textTransform: "none" ,  fontFamily : SecondaryFont,
                  }}
                  className={classes.button}
                  startIcon={
                    <img src={filterButton} aria-controls="panel1a-content"
                     id="panel1a-header" />
                  }
                  endIcon={
                    <ExpandMoreIcon style={{ color: "black" }} />
                  }
                  onClick={() => {
                    if (this.state.sortOrder == "asc") {
                      this.setState({ sortOrder: 'desc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                    }
                    else if (this.state.sortOrder == "desc") {
                      this.setState({ sortOrder: 'asc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                    }

                  }
                  }
                >
                  Name
                        </Button> */}
              </div>
            :
            null  
            }
                {
                  this.state.search.length == 0 ?
                    <Accordion className={classes.accordianStyle} defaultExpanded>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          width: "100%",
                          display: "block !important",
                          padding: "0px 20px",
                          //position: "inherit !important"
                        }}
                      >
                        <div style={{
                          display: "flex", flexDirection: "row",
                           width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}>
                          <Typography className={classes.heading}>
                            Brands <img src={downIcon} />
                          </Typography>
                          <Typography
                            style={{ position: "absolute", right: "-1385px" }}
                          >

                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.test123}>
                        <Grid item xs={12} >
                          <Grid container spacing={0} 
                          >
                            {this.state.assetData && this.state.assetData.map((element: any, index: any) => {
                              return (
                                <>
                                  <Grid item xs={3}>
                                    <Card className={classes.cardStyle}>
                                      <CardContent>
                                        <Typography
                                          className={classes.title}
                                          gutterBottom
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            onClick={() =>{ 
                                              localStorage.setItem("grid", "1")
                                              localStorage.removeItem('brandGroupSelected');
                                            }}
                                          >
                                            <Link
                                              // to="/Home/MediaAssets/bmw"
                                              component={RouterLink}
                                              to={
                                                `/Home/MediaAssets` + '/' + element.id
                                              }
                                           style={{
                                                textDecoration: "none",
                                                color: secondaryColorToBeChanged,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                              {element?.attributes?.logo_blob_signed_id_url ?
                                              <img src={element?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} 
                                                    // onClick={(e) => { this.handleClickProjects(e); }}
                                                     />
                                                    :
                                              <Avatar style={{ width: "35px", height: "35px" }} 
                                                    // onClick={(e) => { this.handleClickProjects(e); }}
                                                    >{element.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                  }
                                                <>
                                                </>

                                                {" "}
                                                <p
                                                  style={{
                                                    marginTop: "4px",
                                                    marginLeft: "7px",
                                                    marginBottom: "3px",
                                                    display: "inline",
                                                    //backgroundColor: "red"
                                                    fontSize: "14px"
                                                  }}
                                                >
                                                  {element?.attributes?.name}
                                                </p>
                                              </span>
                                            </Link>
                                            {/* <IconButton
                                         aria-label="settings"
                                         style={{
                                           color: "black",
                                           marginTop: "-17px",
                                         }}
                                       >
                                         <MoreVertIcon
                                           style={{
                                             color: "black",
                                             fontSize: "16px",
                                           }}
                                           aria-controls="simple-menu"
                                           aria-haspopup="true"
                                           // onClick={(e: any) => {
                                           //   this.handleClickMenu(e);
                                           // }}
                                         />
                                       </IconButton> */}
                                            
                                          </div>
                                        </Typography>
                                        <p>
                                          <Link
                                            component={RouterLink}
                                            to={`/Home/MediaAssets` + '/' + element.id}
                                            // to='/Home/MediaAssets/bmw'
                                            style={{
                                              fontSize: "12px",
                                              color: secondaryColorToBeChanged,
                                              textDecoration: "none",
                                            }}
                                          >
                                            {element?.attributes?.media_assets?.length ? element?.attributes?.media_assets.map((ele: any) => <p> {ele.name}</p>) : null}
                                          </Link>
                                        </p>
                                     </CardContent>
                                      <CardActions className={classes.actionCard} style={{height:"30px"}}>
                                        <p style={{ fontSize: "10px" }}>
                                          {" "}
                                          {element?.attributes?.updated_at} 
                                          <br />
                                          <span> last modified </span> 
                                        </p>
                                        {/* <AvatarGroup
                                     max={4}
                                     className={classes.avatar}
                                     style={{ marginLeft: "30%" }}
                                   >
                                     <Avatar
                                       alt="Remy Sharp"
                                       style={{ height: "30px", width: "30px" }}
                                       src="https://picsum.photos/id/237/200/300"
                                     />

                                   </AvatarGroup> */}
                                   
                                        {/* {element?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && element?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                          <AvatarGroup max={3}
                                            // className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            <Avatar alt="Remy Sharp"
                                              //  style={{ height: "30px", width: "30px" }}
                                              src={element.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                            {_.times(element?.attributes?.media_asset_accounts_count - 1, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                          :
                                          (
                                            <>
                                              <AvatarGroup max={3}
                                                //  className={classes.avatar}
                                                style={{ marginTop: "10px" }}
                                              >
                                                {_.times(element.attributes.media_asset_accounts_count, () => (
                                                  <Avatar alt="Remy Sharp"
                                                    //  style={{ height: "30px", width: "30px" }}
                                                    src={defaultUser} />
                                                ))}
                                              </AvatarGroup>
                                            </>

                                          )
                                        } */}

                                      </CardActions>
                                    </Card>
                                  </Grid>

                                </>
                              )
                            })


                            }
                            {this.state.brandGroupList && this.state.brandGroupList.map((element: any, index: any) => {
                              return (
                                <>
                                  <Grid item xs={3}>
                                    <Card className={classes.cardStyle}>
                                      <CardContent>
                                        <Typography
                                          className={classes.title}
                                          gutterBottom
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            onClick={() => {
                                              localStorage.setItem("grid", "1")
                                              localStorage.setItem("brandGroupSelected", element?.id)
                                            }}
                                          >
                                            <Link
                                              // to="/Home/MediaAssets/bmw"
                                              component={RouterLink}
                                              to={
                                                `/Home/MediaAssetsbrandGroup`
                                              }
                                           style={{
                                                textDecoration: "none",
                                                color: secondaryColorToBeChanged,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                              {element?.attributes?.logo_blob_signed_id_url ?
                                              <img src={element?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} 
                                                    // onClick={(e) => { this.handleClickProjects(e); }}
                                                     />
                                                    :
                                              <Avatar style={{ width: "35px", height: "35px" }} 
                                                    // onClick={(e) => { this.handleClickProjects(e); }}
                                                    >{element.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                  }
                                                <>
                                                </>

                                                {" "}
                                                <p
                                                  style={{
                                                    marginTop: "4px",
                                                    marginLeft: "7px",
                                                    marginBottom: "3px",
                                                    display: "inline",
                                                    //backgroundColor: "red"
                                                    fontSize: "14px"
                                                  }}
                                                >
                                                  {element?.attributes?.name}
                                                </p>
                                              </span>
                                            </Link>
                                            {/* <IconButton
                                         aria-label="settings"
                                         style={{
                                           color: "black",
                                           marginTop: "-17px",
                                         }}
                                       >
                                         <MoreVertIcon
                                           style={{
                                             color: "black",
                                             fontSize: "16px",
                                           }}
                                           aria-controls="simple-menu"
                                           aria-haspopup="true"
                                           // onClick={(e: any) => {
                                           //   this.handleClickMenu(e);
                                           // }}
                                         />
                                       </IconButton> */}
                                            
                                          </div>
                                        </Typography>
                                        {/* <p>
                                          <Link
                                            component={RouterLink}
                                            to={`/Home/MediaAssets` + '/' + element.id}
                                            // to='/Home/MediaAssets/bmw'
                                            style={{
                                              fontSize: "12px",
                                              color: secondaryColorToBeChanged,
                                              textDecoration: "none",
                                            }}
                                          >
                                            {element?.attributes?.media_assets?.length ? element?.attributes?.media_assets.map((ele: any) => <p> {ele.name}</p>) : null}
                                          </Link>
                                        </p> */}
                                     </CardContent>
                                      <CardActions className={classes.actionCard} style={{height:"30px"}}>
                                        {/* <p style={{ fontSize: "10px" }}>
                                          {" "}
                                          {element?.attributes?.updated_at} 
                                          <br />
                                          <span> last modified </span> 
                                        </p> */}
                                        {/* <AvatarGroup
                                     max={4}
                                     className={classes.avatar}
                                     style={{ marginLeft: "30%" }}
                                   >
                                     <Avatar
                                       alt="Remy Sharp"
                                       style={{ height: "30px", width: "30px" }}
                                       src="https://picsum.photos/id/237/200/300"
                                     />

                                   </AvatarGroup> */}
                                   
                                        {/* {element?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && element?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                          <AvatarGroup max={3}
                                            // className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            <Avatar alt="Remy Sharp"
                                              //  style={{ height: "30px", width: "30px" }}
                                              src={element.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                            {_.times(element?.attributes?.media_asset_accounts_count - 1, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                          :
                                          (
                                            <>
                                              <AvatarGroup max={3}
                                                //  className={classes.avatar}
                                                style={{ marginTop: "10px" }}
                                              >
                                                {_.times(element.attributes.media_asset_accounts_count, () => (
                                                  <Avatar alt="Remy Sharp"
                                                    //  style={{ height: "30px", width: "30px" }}
                                                    src={defaultUser} />
                                                ))}
                                              </AvatarGroup>
                                            </>

                                          )
                                        } */}

                                      </CardActions>
                                    </Card>
                                  </Grid>

                                </>
                              )
                            })

                            }



                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    :
                    <Grid item xs={12}>
                      <Backdrop
                  className={classes.backdrop2}
                  open={this.state.loading2}
                 // onClick={() => this.setState({ loading: false })}
                  >
                  <CircularProgress color="inherit" />
                     </Backdrop>
                      <Grid container spacing={0}>
                        {this.state.search.length > 0 && this.state.searchedData.length > 0 && this.state.searchedData.map((item: any, index: any) => {

                          if(item?.attributes?.media_asset_type != 'media_asset_folder'){
                            return (
                              <Grid item xs={3} style={{ marginBottom: "20px" }}>
                                <Card className={classes.cardStyle} >
                                  <CardContent style={{ minHeight: "165px" }}>
                                    <Typography
                                      className={classes.title}
                                      gutterBottom
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                        onClick={() => localStorage.setItem("grid", "1")}
                                      >
                                        {
                                          item?.attributes?.media_asset_type == 'media_asset' ?
                                            <Link
                                              //  to="/Home/MediaAssets/bmw/others"
                                              // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                              to={`/Home/MediaAssets/${item?.attributes?.brand_name}` + '/' + item.attributes?.name + '/' + item.attributes?.media_id}
                                              component={RouterLink}
                                              style={{
                                                color: secondaryColorToBeChanged,
                                                textDecoration: "none",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <FolderOpenIcon
                                                  style={{
                                                    width: "30px",
                                                    height: "30px",
                                                  }}
                                                  aria-controls="simple-menu"
                                                  aria-haspopup="true"
                                                />{" "}
                                                <p
                                                  style={{
                                                    marginTop: "6px",
                                                    marginLeft: "7px",
                                                  }}
                                                >
                                                  {item?.attributes?.name}
                                                </p>
                                              </span>
                                            </Link>
                                            :
  
                                            item?.attributes?.media_asset_type == 'media_asset_folder' ?
                                              <Link
                                                //  to="/Home/MediaAssets/bmw/others"
                                                // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                                to={`/Home/MediaAssets` + '/' + item?.attributes?.brand_name + '/' + item?.attributes?.asset?.data?.attributes?.media_asset_id + '/' + item.attributes.name + '/' + item.attributes.media_id}
                                                style={{
                                                  color: secondaryColorToBeChanged,
                                                  textDecoration: "none",
                                                }}
                                                component={RouterLink}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <FolderOpenIcon
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                  />{" "}
                                                  <p
                                                    style={{
                                                      marginTop: "6px",
                                                      marginLeft: "7px",
                                                    }}
                                                  >
                                                    {item?.attributes?.name}
                                                  </p>
                                                </span>
                                              </Link>
                                              :
                                              //   <Link
                                              //   //  to="/Home/MediaAssets/bmw/others"
                                              //   // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                              //   to="/Home/MediaAssets/bmw/others"
                                              //   style={{
                                              //     color: secondaryColorToBeChanged,
                                              //     textDecoration: "none",
                                              //   }}
                                              //   component={RouterLink}
                                              // >
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                {/* <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />{" "} */}
                                                <p
                                                  style={{
                                                    marginTop: "6px",
                                                    marginLeft: "7px",
                                                  }}
                                                >
                                                  {item?.attributes?.name}
                                                </p>
                                              </span>
  
                                        }
  
  
                                        {/* <Link
                                      //  to="/Home/MediaAssets/bmw/others"
                                      // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
  
                                      style={{
                                        color: secondaryColorToBeChanged,
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <FolderOpenIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                        />{" "}
                                        <p
                                          style={{
                                            marginTop: "6px",
                                            marginLeft: "7px",
                                          }}
                                        >
                                          {item?.attributes?.name}
                                        </p>
                                      </span>
                                    </Link> */}
                                    { 
                                    item?.attributes?.media_asset_type === 'media_asset_file' ?
                                    <IconButton
                        aria-label="settings"
                        style={{
                          color: "black",
                          marginTop: "-17px",
                        }}
                      >
                        <MoreVertIcon
                          style={{
                            color: "black",
                            fontSize: "16px",
                          }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e: any) => {
                            this.setState({ selectedAsset: item , fileFlag : true })
                            this.handleClickMenu(e);
                          }}
                        />
                      </IconButton> 
                      :
                       null
                       }
                                      </div>
                                    </Typography>
                                    {
                                      item?.attributes?.media_asset_type == 'media_asset_file' ?
                                        <Box display="flex" flexWrap="wrap">
                                          {/* {Array.from(Array(5).keys()).map((key) => (
                                 <Box
                                   key={key}
                                   bgcolor="#f9f9fc"
                                   height="47px"
                                   width="68px"
                                   m="5px"
                                   display="flex"
                                   justifyContent="center"
                                   alignItems="center"
                                   borderRadius="4px"
                                 >
                                   <FileCopyOutlinedIcon />
                                 </Box>
                               ))} */}
                                          {
                                            (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                              item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                              <img src={item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url} style={{ height: "114px", width: "125px" }}
                                                onClick={() => this.imageView(item.attributes.name, item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url)} /> :
                                              (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                                < PictureAsPdfRounded style={{ height: "114px", width: "125px" }} /> :
                                                <FileCopyOutlinedIcon style={{ height: "114px", width: "125px" }} />
  
                                          }
  
                                        </Box>
                                        :
                                        <Box display="flex" flexWrap="wrap">
  
                                        </Box>
                                    }
  
                                  </CardContent>
                                  <CardActions className={classes.actionCard}>
                                    <p style={{ fontSize: "10px" }}>
                                      {" "}
                                      {item?.attributes?.updated_at} last modified
                                 </p>
                                    {item?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && item?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{ marginTop: "10px", marginLeft: "20px" }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                          //  style={{ height: "30px", width: "30px" }}
                                          src={item.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(item?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                            //  style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                            //  className={classes.avatar}
                                            style={{ marginTop: "10px", marginLeft: "131px" }}
                                          >
                                            {_.times(item.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                //  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>
  
                                      )
                                    }
                                  </CardActions>
                                </Card>
                              </Grid>
  
  
  
  
                            )
                          }
                        



                        }


                        )
                        }
                      </Grid>
                    </Grid>

                }

              </div>
            </Grid>
          )}
          {this.state.list && (
            <div style={{
              width: "100%",
              marginTop: "20px"
            }}>
           {  this.state.search.length == 0 ?
             <div>
               <Button
                variant="contained"
                style={{
                  color: secondaryColorToBeChanged, float: "right", marginRight: "37px",
                  zIndex: 1 , textTransform: "none" ,  fontFamily : SecondaryFont,
                }}
                className={classes.button}
                startIcon={
                  <img src={filterButton} aria-controls="panel1a-content"
                   id="panel1a-header" />
                }
                endIcon={
                  <ExpandMoreIcon style={{ color: "black" }} />
                }
                onClick={(event) => this.setState({ anchorElFilter: event.target })}
              >
                {
                  this.state.brandFilterValue == 'name0' ? 'Name (A-Z)' : this.state.brandFilterValue == 'name1' ? 'Name (Z-A)' :
                  this.state.brandFilterValue == 'created_at0' ? 'Created At (oldest)' : this.state.brandFilterValue == 'created_at1' ? 'Created At' :
                  this.state.brandFilterValue == 'updated_at0' ? 'Updated At (oldest)' : 'Updated At'
                }
              </Button>
                {/* <Button
                  variant="contained"
                  style={{
                    color: secondaryColorToBeChanged, float: "right", marginRight: "37px",
                    zIndex: 1 , textTransform: "none" ,  fontFamily : SecondaryFont,
                  }}
                  className={classes.button}
                  startIcon={
                    <img src={filterButton} aria-controls="panel1a-content"
                     id="panel1a-header" />
                  }
                  endIcon={
                    <ExpandMoreIcon style={{ color: "black" }} />
                  }
                  onClick={() => {
                    if (this.state.sortOrder == "asc") {
                      this.setState({ sortOrder: 'desc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                    }
                    else if (this.state.sortOrder == "desc") {
                      this.setState({ sortOrder: 'asc' }, () => this.getBrandsByFilters(`sort_by=name&sort_order=${this.state.sortOrder}`))
                    }

                  }
                  }
                >
                  Name
                        </Button> */}
              </div>
            :
            null  
            }
              <>
                {
                  this.state.search.length == 0 ?
                    <Accordion
                      style={{ width: "100%" }}
                      className={classes.accordianStyle}
                      defaultExpanded
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.listHeader}>
                          Brands{" "}
                          <img
                            src={downIcon}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          />
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12}>
                          {this.state.assetData?.length > 0 && this.state.assetData.map((element: any) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  borderLeft: `6px solid ${secondaryColorToBeChanged}`,
                                  borderRight: "1px solid #e4e8f0",
                                  borderTop: "1px solid #e4e8f0",
                                  borderBottom: "1px solid #e4e8f0",
                                  marginTop: "20px",
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  console.log("clicked here");
                                  
                                  localStorage.setItem("grid", "0")
                                  localStorage.removeItem('brandGroupSelected');
                                  this.props.history.push(`/Home/MediaAssets` + '/' + element.id)
                                }
                                }

                              >
                                <div style={{ margin: "20px" }}>
                                  <div style={{
                                    display: "flex", flexDirection: "row", justifyContent: "space-between",
                                    marginRight: "63px"
                                  }}>
                                    <p 
                                    // onClick={() => {
                                    //   localStorage.setItem("grid", "0")
                                    //   this.props.history.push(`/Home/MediaAssets` + '/' + element.id)
                                    // }
                                    // }
                                      style={{ "cursor": "pointer" }}>{element?.attributes?.name}</p>
                                    {/* {element?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && element?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{
                                          marginTop: "10px",
                                          marginLeft: "131px"
                                        }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                            style={{ height: "30px", width: "30px" }}
                                          src={element.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(element?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                              style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                              className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            {_.times(element.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    } */}
                                    {/* <p style={{ marginLeft: "30px" }}>Photo Production</p> */}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p style={{ fontSize: "12px" }}>
                                      {element?.attributes?.media_assets?.length ? element?.attributes?.media_assets.map((ele: any) => <span style={{ marginLeft: "15px" }}>{ele.name}</span>) : null}
                                    </p>

                                    <div className={classes.circle}>
                                      <p>
                                        <CalendarTodayOutlinedIcon
                                          style={{ fontSize: "12px" }}
                                        />
                                      </p>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                      {element?.attributes?.updated_at}
                                    </p>




                                    {/* <AvatarGroup
                              max={4}
                              className={classes.avatar}
                            // style={{ marginLeft: "30%" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300"
                              />
                              <Avatar
                                alt="Travis Howard"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300"
                              />
                              <Avatar
                                alt="Cindy Baker"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300 "
                              />
                              <Avatar
                                alt="Trevor Henderson"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300 "
                              />
                            </AvatarGroup> */}
                                    {/* <div
                        className={classes.circle}
                        style={{ marginLeft: "12px" }}
                      >
                        <span>
                          <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                        </span>
                      </div> */}

                                    {/* <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                        3
                      </p>
                      <div
                        className={classes.circle}
                        style={{ marginLeft: "12px" }}
                      >
                        <span>
                          {" "}
                          <AttachFileOutlinedIcon
                            style={{ fontSize: "13px" }}
                          />
                        </span>
                      </div> */}
                                    {/* <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                        3
                      </p> */}
                                    {/* <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {this.state.brandGroupList?.length > 0 && this.state.brandGroupList.map((element: any) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  borderLeft: `6px solid ${secondaryColorToBeChanged}`,
                                  borderRight: "1px solid #e4e8f0",
                                  borderTop: "1px solid #e4e8f0",
                                  borderBottom: "1px solid #e4e8f0",
                                  marginTop: "20px",
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  localStorage.setItem("grid", "1")
                                  localStorage.setItem("brandGroupSelected", element?.id)
                                  this.props.history.push(`/Home/MediaAssetsbrandGroup`)
                                }
                                }

                              >
                                <div style={{ margin: "20px" }}>
                                  <div style={{
                                    display: "flex", flexDirection: "row", justifyContent: "space-between",
                                    marginRight: "63px"
                                  }}>
                                    <p 
                                    // onClick={() => {
                                    //   localStorage.setItem("grid", "0")
                                    //   this.props.history.push(`/Home/MediaAssets` + '/' + element.id)
                                    // }
                                    // }
                                      style={{ "cursor": "pointer" }}>{element?.attributes?.name}</p>
                                    {/* {element?.attributes?.media_asset_accounts?.data?.[0]?.attributes?.photo_blob_signed_id_url != null && element?.attributes?.media_asset_accounts?.data[0]?.attributes?.photo_blob_signed_id_url.length > 0 ?
                                      <AvatarGroup max={3}
                                        // className={classes.avatar}
                                        style={{
                                          marginTop: "10px",
                                          marginLeft: "131px"
                                        }}
                                      >
                                        <Avatar alt="Remy Sharp"
                                            style={{ height: "30px", width: "30px" }}
                                          src={element.attributes?.media_asset_accounts.data[0]?.attributes?.photo_blob_signed_id_url} />
                                        {_.times(element?.attributes?.media_asset_accounts_count, () => (
                                          <Avatar alt="Remy Sharp"
                                              style={{ height: "30px", width: "30px" }}
                                            src={defaultUser} />
                                        ))}
                                      </AvatarGroup>
                                      :
                                      (
                                        <>
                                          <AvatarGroup max={3}
                                              className={classes.avatar}
                                            style={{ marginTop: "10px" }}
                                          >
                                            {_.times(element.attributes.media_asset_accounts_count, () => (
                                              <Avatar alt="Remy Sharp"
                                                  style={{ height: "30px", width: "30px" }}
                                                src={defaultUser} />
                                            ))}
                                          </AvatarGroup>
                                        </>

                                      )
                                    } */}
                                    {/* <p style={{ marginLeft: "30px" }}>Photo Production</p> */}
                                  </div>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    {/* <p style={{ fontSize: "12px" }}>
                                      {element?.attributes?.media_assets?.length ? element?.attributes?.media_assets.map((ele: any) => <span style={{ marginLeft: "15px" }}>{ele.name}</span>) : null}
                                    </p>

                                    <div className={classes.circle}>
                                      <p>
                                        <CalendarTodayOutlinedIcon
                                          style={{ fontSize: "12px" }}
                                        />
                                      </p>
                                    </div>

                                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                                      {element?.attributes?.updated_at}
                                    </p> */}




                                    {/* <AvatarGroup
                              max={4}
                              className={classes.avatar}
                            // style={{ marginLeft: "30%" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300"
                              />
                              <Avatar
                                alt="Travis Howard"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300"
                              />
                              <Avatar
                                alt="Cindy Baker"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300 "
                              />
                              <Avatar
                                alt="Trevor Henderson"
                                style={{ height: "30px", width: "30px" }}
                                src="https://picsum.photos/id/237/200/300 "
                              />
                            </AvatarGroup> */}
                                    {/* <div
                        className={classes.circle}
                        style={{ marginLeft: "12px" }}
                      >
                        <span>
                          <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                        </span>
                      </div> */}

                                    {/* <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                        3
                      </p>
                      <div
                        className={classes.circle}
                        style={{ marginLeft: "12px" }}
                      >
                        <span>
                          {" "}
                          <AttachFileOutlinedIcon
                            style={{ fontSize: "13px" }}
                          />
                        </span>
                      </div> */}
                                    {/* <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                        3
                      </p> */}
                                    {/* <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        </Grid>
                      </AccordionDetails>
                      {/* <AccordionDetails>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "95%",
                      borderRadius: "8px",
                      borderLeft: "6px solid #1a76b0",
                      borderRight: "1px solid #e4e8f0",
                      borderTop: "1px solid #e4e8f0",
                      borderBottom: "1px solid #e4e8f0",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ margin: "20px" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p>Magazine Ad _ Nov_001</p>
                        <p style={{ marginLeft: "30px" }}>Photo Production</p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p style={{ fontSize: "12px" }}>
                          Lorem Ipsum dolor sit amet, consectetur adipsicing
                          elite. Got it? Neither did we. In the publishing and
                          design industries
                        </p>
                        <div className={classes.circle}>
                          <p>
                            <CalendarTodayOutlinedIcon
                              style={{ fontSize: "12px" }}
                            />
                          </p>
                        </div>

                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                          29 Sep 2020{" "}
                        </p>
                        <div
                          className={classes.circle}
                          style={{ marginLeft: "12px" }}
                        >
                          <span>
                            <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                          </span>
                        </div>

                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                          3
                        </p>
                        <div
                          className={classes.circle}
                          style={{ marginLeft: "12px" }}
                        >
                          <span>
                            {" "}
                            <AttachFileOutlinedIcon
                              style={{ fontSize: "13px" }}
                            />
                          </span>
                        </div>
                        <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                          3
                        </p>
                        <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </AccordionDetails> */}

                    </Accordion>


                    :

                    <Box width="100%" display="flex" flexDirection="column">
                      {/* <Box
                      width="100%"
                      borderRadius="8px"
                      border="solid 1px #e4e8f0"
                    > */}

                      {this.state.search.length != 0 && this.state.searchedData.map((item: any, index: any) => {
                        if(item?.attributes?.media_asset_type != 'media_asset_folder'){
 return (
                          <div>
                            <Box
                              width="100%"
                              borderRadius="8px"
                              border="solid 1px #e4e8f0"
                            >
                              <Box
                                height="71.5px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                pl="30px"
                                pr="30px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  width="40%"

                                >
                                  {/* <ImageOutlined
                                style={{
                                  width: "30px",
                                  height: "30px",
                                }}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                              />{" "} */}

                                  {
                                    item?.attributes?.media_asset_type == 'media_asset' ?


                                      <FolderOpenIcon
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                      />

                                      :
                                      (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                        item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg') ?
                                        //         <ImageOutlined
                                        //   style={{
                                        //     width: "30px",
                                        //     height: "30px",
                                        //   }}
                                        //   aria-controls="simple-menu"
                                        //   aria-haspopup="true"
                                        // />
                                        <Svg
                                          color={

                                            colorToBeChanged
                                          }
                                          keyword="MediaAssetLibrarySVg"
                                        />
                                        :
                                        (item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'pdf') ?
                                          <PictureAsPdfRounded style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true" />
                                          :
                                          item?.attributes?.media_asset_type == 'media_asset_folder' ?


                                            <FolderOpenIcon
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              aria-controls="simple-menu"
                                              aria-haspopup="true"
                                            />

                                            :
                                            <FileCopyOutlinedIcon style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                              aria-controls="simple-menu"
                                              aria-haspopup="true" />

                                  }

                                  {
                                    item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'png' ||
                                      item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'jpg' ||
                                      item?.attributes?.name.substr(item?.attributes?.name.length - 3, item?.attributes?.name.length - 1) == 'peg' ?
                                      <Typography
                                        style={{
                                          marginLeft: "12px",
                                          fontSize: "16px !important",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "0.12px",
                                          color: "#282828",
                                        }}
                                        onClick={() => this.imageView(item.attributes.name, item?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url)}
                                      >
                                        {item.attributes.name}
                                      </Typography> 
                                      :
                                      item?.attributes?.media_asset_type == 'media_asset' ?
                                      <Link
                                      to={`/Home/MediaAssets/${item?.attributes?.brand_name}` + '/' + item.attributes?.name + '/' + item.attributes?.media_id}
                                      //to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                        style={{
                                          color: secondaryColorToBeChanged,
                                          textDecoration: "none",
                                        }}
                                        component= {RouterLink}
                                      >
                                        <Typography
                                          style={{
                                            marginLeft: "12px",
                                            fontSize: "16px !important",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.12px",
                                            color: "#282828",
                                          }}
                                        // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                        >
                                          {item.attributes.name}
                                        </Typography>
                                        </Link>
                                        :
                                        item?.attributes?.media_asset_type == 'media_asset_folder' ?
                                        <Link
                                        // to={`/Home/MediaAssets` + '/' + brandId + '/' + mediaAssetId + '/' + item.attributes.name + '/'  + item.id}
                                        to={`/Home/MediaAssets` + '/' + item?.attributes?.brand_name + '/' + item?.attributes?.asset?.data?.attributes?.media_asset_id + '/' + item.attributes.name + '/' + item.attributes.media_id}  
                                        style={{
                                            color: secondaryColorToBeChanged,
                                            textDecoration: "none",
                                          }}
                                          component= {RouterLink}
                                        >
                                          <Typography
                                            style={{
                                              marginLeft: "12px",
                                              fontSize: "16px !important",
                                              fontWeight: 500,
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "0.12px",
                                              color: "#282828",
                                            }}
                                          // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                          >
                                            {item.attributes.name}
                                          </Typography>
                                          </Link>
                                          :
                                          <Typography
                                            style={{
                                              marginLeft: "12px",
                                              fontSize: "16px !important",
                                              fontWeight: 500,
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "0.12px",
                                              color: "#282828",
                                            }}
                                          // onClick={()=> this.imageView(item.attributes.name ,item?.attributes?.file_blob_signed_id_url)}
                                          >
                                            {item.attributes.name}
                                          </Typography>

                                  }
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      marginLeft: "12px",
                                      fontSize: "12px !important",
                                      fontWeight: 500,
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "normal",
                                      letterSpacing: "0.12px",
                                      color: "#282828",
                                    }}
                                  >
                                    {/* March 25, 2019 6:21 PM, Modified by Matea */}
                                    {item.attributes.updated_at}
                                  </Typography>
                                </Box>
                                <Box>
                                  {/* <Typography
                                style={{
                                  marginLeft: "12px",
                                  fontSize: "12px !important",
                                  fontWeight: 500,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "0.12px",
                                  color: "#282828",
                                }}
                              >
                                {item.attributes.size}
                              </Typography> */}
                                </Box>
                                <Box>

                                </Box>
                                <Box>
                                 { item?.attributes?.media_asset_type === 'media_asset_file'? <IconButton
                                aria-label="settings"
                                style={{
                                  color: "black",
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(e: any) => {
                                   this.setState({ selectedAsset: item , fileFlag : true })
                                    this.handleClickMenu(e);
                                  }}
                                />
                              </IconButton> : null
                              }

                                </Box>
                              </Box>
                            </Box>
                          </div>
                        )
                        }
                       


                      })}



                    </Box>




                }

                {/* 
              <Accordion
                style={{ width: "100%" }}
                className={classes.accordianStyle}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.listHeader}>
                    Open{" "}
                    <img
                      src={downIcon}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <div
                      style={{
                        width: "95%",
                        borderRadius: "8px",
                        borderLeft: "6px solid #1a76b0",
                        borderRight: "1px solid #e4e8f0",
                        borderTop: "1px solid #e4e8f0",
                        borderBottom: "1px solid #e4e8f0",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p>Magazine Ad _ Nov_001</p>
                          <p style={{ marginLeft: "30px" }}>Photo Production</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p style={{ fontSize: "12px" }}>
                            Lorem Ipsum dolor sit amet, consectetur adipsicing
                            elite. Got it? Neither did we. In the publishing and
                            design industries
                          </p>
                          <div className={classes.circle}>
                            <p>
                              <CalendarTodayOutlinedIcon
                                style={{ fontSize: "12px" }}
                              />
                            </p>
                          </div>

                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            29 Sep 2020{" "}
                          </p>
                          <div
                            className={classes.circle}
                            style={{ marginLeft: "12px" }}
                          >
                            <span>
                              <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                            </span>
                          </div>

                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            3
                          </p>
                          <div
                            className={classes.circle}
                            style={{ marginLeft: "12px" }}
                          >
                            <span>
                              {" "}
                              <AttachFileOutlinedIcon
                                style={{ fontSize: "13px" }}
                              />
                            </span>
                          </div>
                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            3
                          </p>
                          <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </AccordionDetails>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <div
                      style={{
                        width: "95%",
                        borderRadius: "8px",
                        borderLeft: "6px solid #1a76b0",
                        borderRight: "1px solid #e4e8f0",
                        borderTop: "1px solid #e4e8f0",
                        borderBottom: "1px solid #e4e8f0",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p>Magazine Ad _ Nov_001</p>
                          <p style={{ marginLeft: "30px" }}>Photo Production</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p style={{ fontSize: "12px" }}>
                            Lorem Ipsum dolor sit amet, consectetur adipsicing
                            elite. Got it? Neither did we. In the publishing and
                            design industries
                          </p>
                          <p style={{ marginLeft: "30px", fontSize: "12px" }}><CalendarTodayOutlinedIcon style={{ fontSize: "10px", marginRight: "5px" }} /> 29 Sep 2020  <ChatOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} />3 <AttachFileOutlinedIcon style={{ fontSize: "10px", marginLeft: "5px" }} /> 3</p>
                          <div className={classes.circle}>
                            <p>
                              <CalendarTodayOutlinedIcon
                                style={{ fontSize: "12px" }}
                              />
                            </p>
                          </div>

                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            29 Sep 2020{" "}
                          </p>
                          <div
                            className={classes.circle}
                            style={{ marginLeft: "12px" }}
                          >
                            <span>
                              <ChatOutlinedIcon style={{ fontSize: "12px" }} />
                            </span>
                          </div>

                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            3
                          </p>
                          <div
                            className={classes.circle}
                            style={{ marginLeft: "12px" }}
                          >
                            <span>
                              {" "}
                              <AttachFileOutlinedIcon
                                style={{ fontSize: "13px" }}
                              />
                            </span>
                          </div>
                          <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                            3
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </AccordionDetails>
              </Accordion>
             */}
              </>
            </div>
          )}
        </Grid>
        {/* Modal Dialogues */}
        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
        >
          <DialogTitle id="customized-dialog-title">
            {t("projects.newProjectTitle")}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",
              projectDescription: "",
              // shareTheProjectWithOthers:this.state.sharedProject,
              includeAPersonalMessageWithYourInvitation: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              projectDescription: Yup.string().required(
                "projectDescription is required"
              ),

              includeAPersonalMessageWithYourInvitation: Yup.string().required(
                "Include A Personal MessageWith Your Invitation is required"
              ),
            })}
            onSubmit={(values) => {
              console.log(values);

              console.log(this.state.sharedProject, "pro");

              this.setState(
                {
                  sharedProjectError: this.state.sharedProject ? true : false,
                },
                () => {
                  console.log(this.state.sharedProjectError, "error");
                }
              );
              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {
              console.log(values, "shdfsdiuf", this.state.sharedProject);

              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <TextField
                      name="projectDescription"
                      type="text"
                      size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      fullWidth
                      label={t("projects.projectDescription")}
                      value={values.projectDescription}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.projectDescription &&
                          errors.projectDescription ? (
                          <Trans>CompanyProfile.companyNameRequired</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.projectDescription && errors.projectDescription
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  style={{minWidth:"550px"}}
                                  options={top100Films}
                                  getOptionLabel={(option:FilmOptionType) => option.title}
                                  defaultValue={[top100Films[13]]}
                                  fullWidth={true}
                                  filterSelectedOptions
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                  name="shareTheProjectWithOthers"
                                  value={values.shareTheProjectWithOthers}

                                  helperText={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? (
                                        <Trans>
                                          CompanyProfile.billingEmailRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? true
                                      : false
                                  }

                                      InputLabelProps={{
                                        classes: {
                                          root: classes.labelRoot,
                                          focused: classes.labelFocused
                                        }
                                      }}
                                      label={t("projects.shareTheProjectWithOthers")}
                                      placeholder="Favorites"
                                      margin="normal"
                                      fullWidth
                                    /> */}

                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={top100Films}
                      style={{ minWidth: "550px" }}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[top100Films[0]]}
                      onChange={(e, value) => {
                        console.log(e, value);
                        this.setState(
                          {
                            sharedProject: value,
                            sharedProjectError: value.length > 0 ? false : true,
                          },
                          () => {
                            console.log(
                              this.state.sharedProject,
                              "sh",
                              this.state.sharedProjectError
                            );
                          }
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            className={classes.projectChips}
                            label={
                              <Typography
                                style={{
                                  whiteSpace: "normal",
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      marginTop: "10px",
                                    }}
                                    src={option.image}
                                  />{" "}
                                </span>
                                <span
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {option.title}
                                </span>
                              </Typography>
                            }
                            {...getTagProps({ index })}
                            style={{
                              height: "44px",
                              borderRadius: "8px",
                              backgroundColor: "#f4f6fc",
                              opacity: "none",
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Share the project with others"
                          name="shareTheProjectWithOthers"
                          helperText={
                            this.state.sharedProjectError
                              ? "Share the project with others is required"
                              : ""
                          }
                          error={this.state.sharedProjectError}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          className={classes.confirmInput}
                          variant="outlined"
                        />
                      )}
                    />
                    {/* <TextField
                                  id="outlined-multiline-static"
                                  label={t("projects.shareTheProjectWithOthers")}
                                  name="shareTheProjectWithOthers"
                                  fullWidth
                                  InputProps={{ classes: { root: classes.inputRoot } }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused
                                    }
                                  }}
                                  value={values.shareTheProjectWithOthers}
                                  variant="outlined"
                                  className={classes.confirmInput}
                                  helperText={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? (
                                        <Trans>
                                          CompanyProfile.billingEmailRequired
                                        </Trans>
                                      )
                                      : ""
                                  }
                                  error={
                                    touched.shareTheProjectWithOthers && errors.shareTheProjectWithOthers
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                /> */}

                    <TextField
                      name="includeAPersonalMessageWithYourInvitation"
                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      size="small"
                      className={classes.confirmInput}
                      fullWidth
                      label={t(
                        "projects.Includeapersonalmessagewithyourinvitation"
                      )}
                      value={values.includeAPersonalMessageWithYourInvitation}
                      variant="outlined"
                      helperText={
                        touched.includeAPersonalMessageWithYourInvitation &&
                          errors.includeAPersonalMessageWithYourInvitation ? (
                          <Trans>CompanyProfile.vatIdRequired</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.includeAPersonalMessageWithYourInvitation &&
                          errors.includeAPersonalMessageWithYourInvitation
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.createProject")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black" }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>
        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >

          <Grid container style={{ width: "200px", paddingLeft: "15px" }}>
            <Grid item xs={12}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.brandFilterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{ marginLeft: "5px !important"}} value="name0" control={<Radio />} label="Name (A-Z)" />
                <FormControlLabel style={{ marginLeft: "5px !important" }} value="name1" control={<Radio />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio />} label="Created At" />
                {/* <FormControlLabel value="created_at0" control={<Radio />} label="Created At (oldest)" /> */}
                <FormControlLabel value="updated_at1" control={<Radio />} label="Updated At" />
                {/* <FormControlLabel value="updated_at0" control={<Radio />} label="Updated At (oldest)" /> */}

              </RadioGroup>
            </Grid>



          </Grid>
        </Menu>
        <Dialog
          onClose={() => this.setState({ openImage: false })}
          aria-labelledby="customized-dialog-title"
          open={this.state.openImage}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>

            {this.state.openImageURL?.name}
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={() => this.setState({ openImage: false })} />
            </span>
          </DialogTitle>
          <DialogContent>

            <img src={this.state.openImageURL?.url} style={{ maxHeight: "75vh", maxWidth: "100%" }} />
          </DialogContent>


        </Dialog>

        <Dialog
          onClose={this.handleCloseModal1}
          aria-labelledby="customized-dialog-title"
          open={this.state.openLink}

        >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: '700px', marginLeft: '10px' }}>
            {/* {t("projects.newProjectTitle")} */}
           Shareable Link
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseModal1} />
            </span>
          </DialogTitle>
          <DialogContent>
            {/* <p> {this.state.selectedAsset?.attributes?.file_blob_signed_id_url} </p> */}
            <textarea
              ref={(textarea) => this.textArea = textarea}
              value={this.state.selectedAsset?.attributes?.asset?.data?.attributes?.file_blob_signed_id_url}
              style={{ height: "125px", width: "750px" }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
            <Button
              style={{
                backgroundColor: colorToBeChanged,
                color: "white",
                marginLeft: "15px",
              }}
              type="button"
              variant="contained"
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy to Clipboard
                    </Button>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={this.handleCloseModal1}
            >
              {t("projects.cancel")}
            </Button>
          </DialogActions>

        </Dialog>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose("projectProperties");
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleClose("assetsProperties");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Asset Properties
                            </MenuItem>
          {/* <MenuItem
            onClick={() => {
              this.handleClose("inviteTeamMember");;
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Invite Team Members
                            </MenuItem>{" "}
          <MenuItem
            onClick={() => {
              this.handleClose();
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Duplicate
                            </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleClose();
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Share
                            </MenuItem> */}
           { this.state.fileFlag &&
             <MenuItem
            onClick={() => {
              this.handleClose("sharableLink");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Get Shareable Link
                            </MenuItem>
                            }
         { this.state.fileFlag && <MenuItem
            onClick={() => {
              this.handleClose("download");
            }}
            className={classes.dropDown}
            style={{ fontSize: "12px" }}
          >
            Download
                            </MenuItem>
  }
        </Menu>

        <Dialog onClose={()=> this.setState({setFolder :false})} aria-labelledby="customized-dialog-title" open={this.state.setFolder} >
          <DialogTitle id="customized-dialog-title" style={{ minWidth: "500px" }}>
            {/* {t("HomePage.photoProduction")} */}
            <div style={{ position: "relative" }}>
              {/* {t("teams.overviewTeam")} */}
              {this.state.fileFlag ? <>Asset Properties</> : <>Folder Properties</> }
              <span style={{ position: "absolute", right: "10px" }} >
                <CloseIcon onClick={()=> this.setState({setFolder :false})} />

              </span>
            </div>


          </DialogTitle>


          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="fullWidth"
            scrollButtons="off"
            indicatorColor="primary"
            aria-label="full width tabs example"
            textColor="primary"
            centered
          >
            <Tab
               label={t("teams.overview")}
              // label="Folder Properties"
              className={classes.tab}
              
            />
          
            <Tab
              // label={t("individualCompanyHomeScreen.members")}
              label=""
              className={classes.tab}
            />

          </Tabs>


          {this.state.value === 0 &&
            (
              <>
              <DialogContent style={{ minWidth: "700px" }}>
            <DialogContentText id="alert-dialog-description">

            { this.state.fileFlag ? <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  File name :
                </li>
                <span className={classes.noOfFiles} >
                {this.state.selectedAsset?.attributes?.name}
                  </span>
              </div> : 
              <div className={classes.folderDiv}>
              <li className={classes.folderHeaders}>
                Folder name :
              </li>
              <span className={classes.noOfFiles} >
              {this.state.selectedAsset?.attributes?.name}
                </span>
            </div>
              }

              { !this.state.fileFlag && <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of files :
                </li>
                <span className={classes.noOfFiles} >
                {this.state.selectedAsset?.attributes?.number_of_files}
                  </span>
              </div>}

              {/* <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Number of Folders :
                </li>
                <span className={classes.noOfFiles}>
                   {this.state.noOfFolders} 
                  </span>
              </div> */}
              <div className={classes.folderDiv}>
                <li className={classes.folderHeaders}>
                  Size:
                </li>
                <span className={classes.noOfFiles}>
                {this.state.selectedAsset?.attributes?.asset?.data?.attributes?.size}
                  </span>
              </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              style={{ backgroundColor: "white", color: "black" }}
              type="button"
              variant="contained"
              onClick={()=> this.setState({setFolder :false})}
            >
              close
                    </Button>
          </DialogActions>
</>
            )
          }
          
        </Dialog>



        {/* <Dialog
        open={this.state.setOpen}
        onClose={this.handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseModal} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleCloseModal} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}
      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(Categoriessubcategories));
// Customizable Area Start

// Customizable Area End
