import CompanyProfile from './CompanyProfile.web'
import { BlockComponent } from "../../framework/src/BlockComponent";

interface Props {
    navigation: any;
    id: string;
    classes: any;
    isTrue: false;
    files: [],
    primaryColor:'',
    secondaryColor:''
    // Customizable Area End
  }
interface S {
    value: number;
    color:any;
    isTrue: false;
    files: [];
    primaryColor:any;
    secondaryColor:any;

  
  }
  
  interface SS { }
class theme extends  BlockComponent<Props, S, SS>  { 
    static instance: theme;
    constructor(props: Props) {
            super(props)

            // this.state = {
            //     primaryColor:'',
            //     secondaryColor:''
            // }
    }
  

}