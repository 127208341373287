import React, { Fragment } from "react";
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  HashRouter,
  Link,
  Redirect
} from "react-router-dom";
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Badge,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Drawer,
  Divider,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from "@material-ui/icons/Search";
import MuiListItem from "@material-ui/core/ListItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const logoutIcon = require("../assets/Group 6@2x.png");

import "../../../components/src/styles/styles.css";

import AdminConsoleController, { Props } from "./AdminConsoleController";
import Projects from "./Projects.web";
import Users from "./Users.web";
import Brands from "./Brands.web";
import TemplateManagement from "./TemplateManagement.web";
import AssetCategories from "./AssetCategories.web";
import CustomerSupport from "./CustomerSupport.web";
import TemplateDetails from "./TemplateDetails.web";
import ProjectDetails from "./ProjectDetails.web";
import UserDetails from "./UserDetails.web";
import BrandDetails from "./BrandDetails.web";
import UserRequest from "./UserRequest";

var logo = require("../assets/image_logo_dark.png");

import { withTranslation, Trans } from "react-i18next";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#1a76b0",

      color: "white",
      opacity: 1,
    },
    "&:hover": {
      backgroundColor: "#1a76b0",
      color: "white",
    },
    borderRadius: "10px",
    marginBottom: "10px",
    color: "#5b6b96",
  } as const,
  selected: {},
})(MuiListItem);

const styles = {
  root: {
    flexGrow: 1,
    position: "relative",
    height: "100%",
  },
  Icons: {
    color: "#1a76b0",
  },
  fileName: {
    fontSize: "14px",
    marginTop: "-12px",
    marginLeft: "17px",
  },
  date: {
    fontSize: "12px",
    marginLeft: "190px",
  },
  fileSize: {
    fontSize: "10px",
    marginLeft: "-84px",
    marginTop: "10px",
  },

  inputRoot: {
    // fontSize: 56
    height: 56,
  },
  labelRoot: {
    fontSize: 15,
    color: "black",
  },

  active: {
    //  "& text1":{
    // color:"white"
    //  },
    "& Icons": {
      color: "#1a76b0",
    },
    "& p": {
      // backgroundColor: "red",
      color: "#ffffff",
      textDecorationLine: "none",
    },
    "& img": {
      color: "#ffffff",
    },
    backgroundColor: "#1a76b0",
    borderRadius: "10px",
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  confirmInput: {
    marginTop: "15px",
  },
  sideNav: {
    backgroundColor: "#f5f6fa",
    boxShadow: "#f5f6fa",
    borderRight: " 1px solid #e4e8f0",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    padding: "10px",
    width: "100%",
    zIndex: "999",
    "& a": {
      color: "#1a76b0 !important",
    },
  },
  designHarvest: {
    marginTop: "50px",
  },
  Board: {
    marginTop: "30px",
  },
  PersonalBoard: {
    marginTop: "10px",
  },
  designHarvestText: {
    Color: "#173e59",
    fontSize: "15px",
  },
  recentBoard: {
    color: "black",
    // fontFamily:"WorkSans-Regular",
    fontSize: "12px",
  },
  recent1: {
    marginTop: "5px",
    width: "218px",
    borderRadius: "20px",
  },
  started: {
    marginTop: "20px",
    width: "218px",
    borderRadius: "20px",
  },
  recent: {
    paddingTop: "5px",
    marginBottom: "5px",
    width: "218px",
    borderRadius: "20px",
  },
  mediaAssetLogo: {
    marginTop: "20px",
    width: "218px",
    borderRadius: "20px",
  },
  teamsLogoimage: {
    marginTop: "20px",
    width: "218px",
    borderRadius: "20px",
  },
  approvalRequestImage: {
    marginTop: "20px",
    width: "218px",
    borderRadius: "20px",
  },
  text: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  text1: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  favouriteText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  recentText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  Mediatext: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  teamsText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  approvalText: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "13px",
  },
  foottext: {
    // fontFamily: "WorkSans-Medium",
    color: "#5b6b96",
    fontSize: "11px",
  },

  header: {
    marginTop: "40px",
  },
  designHarvestLogo: {
    height: "80px",
    width: "155px",
  },
  routingPages: {
    backgroundColor: "#ffff",
    position: "relative",
    left: "16.2%",
  },
  navArea: {
    marginBottom: "60px",
  },
  plusButtonIcon: {
    textAlign: "end",
  },
  nav_main: {
    backgroundColor: "#ffff",
    position: "fixed",
    right: "0",
    paddingTop: "20px",
    zIndex: "99",
  },
  // root1: {
  //   backgroundColor: 'red'
  // },
  listItemText: {
    fontSize: "0.8em",
  },
  Icon: {
    color: "blue",
  },
  iconActive: {
    color: "white",
  },
  fadeIcon: {
    color: "#1a76b0",
  },

  MenuListItems: {
    // zIndex:"1"
  },
  root1: {
    "&:selected": {
      backgroundColor: "red",
      color: "white",
    },
  },
  selected: {},
} as any;

class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({ selectedPath: window.location.pathname.toLowerCase()});
  }

  langaugeChangeHandler = (lang : string)=>{
    this.setState({language : lang})
  }

  render() {
    const { id, navigation, classes, t } = this.props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    console.log(this.props);
    const currentPathName = window.location.pathname.toLowerCase();
    

    return (
      <>
        <div className={classes.root}>
          <BrowserRouter>
            <Grid container spacing={0}>
              <Grid item xs={2} className={classes.sideNav}>
                <div className={classes.root1}>
                  <Grid container spacing={0}>
                    <Grid item xs={1} />
                    <Grid item xs={11} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={2} />
                    <Grid item xs={10} className={classes.designHarvest}>
                      <img src={logo.default} className={classes.designHarvestLogo} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={1} />
                    <Grid item xs={11} className={classes.Board} />
                  </Grid>

                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={1} />
                      <Grid item xs={11} className={classes.PersonalBoard}>
                        <p className={classes.recentBoard} />
                      </Grid>
                    </Grid>

                    <List>
                      <Link
                        to="/AdminConsole/Users"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <ListItem
                          onClick={() =>
                            this.setState({
                              selectedPath: "/AdminConsole/Users".toLowerCase(),
                            })
                          }
                          button
                          selected={
                            "/AdminConsole/Users".toLowerCase() ===
                            this.state.selectedPath
                          }
                          disabled={
                            "/AdminConsole/Users".toLowerCase() ===
                            this.state.selectedPath
                          }
                          key="Users"
                          classes={{
                            root: classes.listRoot,
                            selected: classes.selected,
                          }}
                        >
                          <span
                            style={{
                              border: "1px solid #707880",
                              height: "16px",
                              width: "16px",
                              backgroundColor: this.state.selectedPath == "/AdminConsole/Users".toLowerCase() ? "white" : "#707880",
                              borderRadius: "17px",
                              marginRight: "16px"
                            }}
                          />
                          <ListItemText primary={t("AdminConsole.users")} />
                        </ListItem>
                      </Link>
                      <Link
                        to="/AdminConsole/Projects"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <ListItem
                          onClick={() =>
                            this.setState({
                              selectedPath: "/AdminConsole/Projects".toLowerCase(),
                            })
                          }
                          button
                          selected={
                            "/AdminConsole/Projects".toLowerCase() ===
                            this.state.selectedPath
                          }
                          disabled={
                            "/AdminConsole/Projects".toLowerCase() ===
                            this.state.selectedPath
                          }
                          key="Projects"
                        >
                          <span
                            style={{
                              border: "1px solid #707880",
                              height: "16px",
                              width: "16px",
                              backgroundColor: this.state.selectedPath == "/AdminConsole/Projects".toLowerCase() ? "white" : "#707880",
                              borderRadius: "17px",
                              marginRight: "16px"
                            }}
                          />
                          <ListItemText primary={t("AdminConsole.projects")} />
                        </ListItem>
                      </Link>
                      {userInfo?.role?.data?.attributes?.name === 'superadmin' &&
                        <Fragment>
                          <Link
                            to="/AdminConsole/Brands"
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <ListItem
                              onClick={() =>
                                this.setState({
                                  selectedPath: "/AdminConsole/Brands".toLowerCase(),
                                })
                              }
                              button
                              selected={
                                "/AdminConsole/Brands".toLowerCase() ===
                                this.state.selectedPath
                              }
                              disabled={
                                "/AdminConsole/Brands".toLowerCase() ===
                                this.state.selectedPath
                              }
                              key="Brands"
                            >
                              <span
                                style={{
                                  border: "1px solid #707880",
                                  height: "16px",
                                  width: "16px",
                                  backgroundColor: this.state.selectedPath == "/AdminConsole/Brands".toLowerCase() ? "white" : "#707880",
                                  borderRadius: "17px",
                                  marginRight: "16px"
                                }}
                              />
                              <ListItemText primary={t("AdminConsole.brands")} />
                            </ListItem>
                          </Link>
                          <Link
                            to={"/AdminConsole/AssetCategories"}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <ListItem
                              onClick={() =>
                                this.setState({
                                  selectedPath: "/AdminConsole/AssetCategories".toLowerCase(),
                                })
                              }
                              button
                              selected={
                                "/AdminConsole/AssetCategories".toLowerCase() ===
                                this.state.selectedPath
                              }
                              disabled={
                                "/AdminConsole/AssetCategories".toLowerCase() ===
                                this.state.selectedPath
                              }
                              key="Asset Categories"
                            >
                              <span
                                style={{
                                  border: "1px solid #707880",
                                  height: "16px",
                                  width: "16px",
                                  backgroundColor: this.state.selectedPath == "/AdminConsole/AssetCategories".toLowerCase() ? "white" : "#707880",
                                  borderRadius: "17px",
                                  marginRight: "16px"
                                }}
                              />
                              <ListItemText primary={t("AdminConsole.assetCategories")} />
                            </ListItem>
                          </Link>
                          <Link
                            to="/AdminConsole/TemplateManagement"
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <ListItem
                              onClick={() =>
                                this.setState({
                                  selectedPath: "/AdminConsole/TemplateManagement".toLowerCase(),
                                })
                              }
                              button
                              selected={
                                "/AdminConsole/TemplateManagement".toLowerCase() ===
                                this.state.selectedPath
                              }
                              disabled={
                                "/AdminConsole/TemplateManagement".toLowerCase() ===
                                this.state.selectedPath
                              }
                              key="Template Management"
                            >
                              <span
                                style={{
                                  border: "1px solid #707880",
                                  height: "16px",
                                  width: "16px",
                                  backgroundColor: this.state.selectedPath == "/AdminConsole/TemplateManagement".toLowerCase() ? "white" : "#707880",
                                  borderRadius: "17px",
                                  marginRight: "16px"
                                }}
                              />
                              <ListItemText primary={t("AdminConsole.templateManagement")} />
                            </ListItem>
                          </Link>

                          <Link
                            to="/AdminConsole/CustomerSupport"
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <ListItem
                              onClick={() =>
                                this.setState({
                                  selectedPath: "/AdminConsole/CustomerSupport".toLowerCase(),
                                })
                              }
                              button
                              selected={
                                "/AdminConsole/CustomerSupport".toLowerCase() ===
                                this.state.selectedPath
                              }
                              disabled={
                                "/AdminConsole/CustomerSupport".toLowerCase() ===
                                this.state.selectedPath
                              }
                              key="Customer Support"
                            >
                              <span
                                style={{
                                  border: "1px solid #707880",
                                  height: "16px",
                                  width: "16px",
                                  backgroundColor: this.state.selectedPath == "/AdminConsole/CustomerSupport".toLowerCase() ? "white" : "#707880",
                                  borderRadius: "17px",
                                  marginRight: "16px"
                                }}
                              />
                              <ListItemText primary={t("AdminConsole.customerSupport")} />
                            </ListItem>
                          </Link>
                        </Fragment>
                      }

{userInfo?.role?.data?.attributes?.name === 'superadmin' &&
                        <Fragment>
                          <Link
                            to="/AdminConsole/UserRequests"
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <ListItem
                              onClick={() =>
                                this.setState({
                                  selectedPath: "/AdminConsole/UserRequests".toLowerCase(),
                                })
                              }
                              button
                              selected={
                                "/AdminConsole/UserRequests".toLowerCase() ===
                                this.state.selectedPath
                              }
                              disabled={
                                "/AdminConsole/UserRequests".toLowerCase() ===
                                this.state.selectedPath
                              }
                              key="Brands"
                            >
                              <span
                                style={{
                                  border: "1px solid #707880",
                                  height: "16px",
                                  width: "16px",
                                  backgroundColor: this.state.selectedPath == "/AdminConsole/UserRequests".toLowerCase() ? "white" : "#707880",
                                  borderRadius: "17px",
                                  marginRight: "16px"
                                }}
                              />
                              <ListItemText primary={t("AdminConsole.AccountCreationRequests")} />
                            </ListItem>
                          </Link>
                        </Fragment>
                      }


                    </List>
                  </>
                </div>
              </Grid>
              <Grid item xs={10} className={classes.routingPages}>
                <Grid container spacing={0} className={classes.navArea}>
                  <Grid item xs={12}>
                    <AppBar position="static" className={classes.appBar}>
                      <Toolbar variant="dense">
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "Work Sans",
                            letterSpacing: "0",
                            color: "#f0f0f0",
                            opacity: 0,
                            fontSize: "18px",
                          }}
                        >
                          Logo
                        </Typography>

                        <Grid
                          container
                          spacing={0}
                          className={classes.nav_main}
                        >
                          <Grid item xs={2} />
                          <Grid item xs={4} />
                          <Grid item xs={3} />
                          <Grid item xs={3}>
                            <Grid container spacing={0} justify="space-evenly">
                              <Grid
                                item
                                xs={2}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <SearchIcon style={{ color: "#282828" }} /> */}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <Badge badgeContent={11} color="secondary">
                                  <NotificationsIcon
                                    style={{ color: "#282828" }}
                                  />
                                </Badge> */}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <PersonOutlineOutlinedIcon
                                  style={{ color: "#282828" }}
                                /> */}
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  marginRight:"-15px"
                                }}
                              >
                                <Grid
                                  container
                                  spacing={0}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Grid item xs={12}>
                                    {/* {userInfo?.role?.data?.attributes?.name === 'superadmin' ?
                                      <p
                                        style={{
                                          color: "black",
                                          fontSize: "11px",
                                          margin: "0",
                                        }}
                                      >
                                        Super Admin
                                      </p>
                                    : */}
                                      <p
                                        style={{
                                          color: "black",
                                          fontSize: "11px",
                                          margin: "0",
                                          display:"flex",
                                          justifyContent:"flex-end"
                                        }}
                                      >
                                        {userInfo?.first_name} {userInfo?.last_name}
                                      </p>
                                     {/* } */}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  onClick={(e) => {
                                    this.handleClick(e);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ExpandMoreOutlinedIcon
                                    style={{
                                      color: "black",
                                      marginTop:"12px",
                                      marginRight:"10px"
                                    }}
                                  />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  style={{
                                    marginTop: "50px",
                                    borderRadius: "8px",
                                    boxShadow:
                                      "0 3px 7px 0 rgba(26, 118, 176, 0.05)",
                                  }}
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={() => {
                                    this.handleClose();
                                  }}
                                >
                                  <Grid item style={{}} xs={12}>
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      wrap="nowrap"
                                    >
                                      <Grid item xs={12}>
                                        <MenuItem
                                          style={{
                                            fontFamily: "Work Sans",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.09px",
                                            color: "#1a76b0",
                                          }}
                                          onClick={this.logOut}
                                        >
                                          <span
                                            style={{
                                              border: "1px solid #e4e8f0",
                                              borderRadius: "10px",
                                              textAlign: "center",
                                              height: "20px",
                                              width: "20px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            <img
                                              src={logoutIcon}
                                              style={{
                                                height: "12px",
                                                width: "12px",
                                                padding: "4px",
                                              }}
                                            />{" "}
                                          </span>
                                          Logout
                                        </MenuItem>
                                        <MenuItem
                                          style={{
                                            fontFamily: "Work Sans",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "0.09px",
                                            color: "#1a76b0",
                                          }}
                                          onClick={(e) => {
                                            let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
                                            let lang = userInfo?.language;
                                            if(lang && lang?.length > 0){
                                              if(lang == "German") {
                                                lang = "de"
                                              }
                                              else {
                                                lang = "en"
                                              }
                                              this.props.i18n.changeLanguage(lang);
                                              this.langaugeChangeHandler(this.props.i18n.language)
                                            }
                                            else {
                                              lang = "en"
                                              this.props.i18n.changeLanguage(lang);
                                              this.langaugeChangeHandler(this.props.i18n.language)
                                  
                                            }
                                            window.location.href = `/Home/HomePage`
                                          }
                                          }
                                        >
                                          <span
                                            style={{
                                              border: "1px solid #e4e8f0",
                                              borderRadius: "10px",
                                              textAlign: "center",
                                              height: "20px",
                                              width: "20px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            <img
                                              src={logoutIcon}
                                              style={{
                                                height: "12px",
                                                width: "12px",
                                                padding: "4px",
                                              }}
                                            />{" "}
                                          </span>
                                          Goto Design Harvest
                                        </MenuItem>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Menu>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Toolbar>
                    </AppBar>
                  </Grid>
                </Grid>
                <Switch>
                  <Route exact path="/AdminConsole/Users">
                    <Users updateSelectionState={this.updateSelectionState} />
                  </Route>
                  <Route exact path="/AdminConsole/Projects">
                    <Projects />
                  </Route>
                  <Route exact path="/AdminConsole/Brands">
                    <Brands />
                  </Route>
                  <Route exact path="/AdminConsole/TemplateManagement">
                    <TemplateManagement />
                  </Route>
                  <Route exact path="/AdminConsole/AssetCategories">
                    <AssetCategories />
                  </Route>
                  <Route exact path="/AdminConsole/CustomerSupport">
                    <CustomerSupport />
                  </Route>
                  <Route exact path="/AdminConsole/template/:id">
                    <TemplateDetails />
                  </Route>
                  <Route exact path="/AdminConsole/project/:id">
                    <ProjectDetails />
                  </Route>
                  <Route exact path="/AdminConsole/user/:id">
                    <UserDetails />
                  </Route>
                  <Route exact path="/AdminConsole/brand/:id">
                    <BrandDetails />
                  </Route>
                  <Route exact path="/AdminConsole/UserRequests">
                    <UserRequest />
                  </Route>
                  <Redirect path="/AdminConsole" to="/AdminConsole/Users?redirect=true"  />
                </Switch>
              </Grid>
            </Grid>
          </BrowserRouter>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminConsole));
