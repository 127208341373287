import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { EditorState, convertToRaw, ContentState, convertFromHTML, } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import * as SparkMD5 from 'spark-md5';
import { debug } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  // Customizable Area Start
  // Customizable Area End
}
const currentUrl: any = window.location.href.includes("prod") ? configJSON.REACT_APP_API_prod : configJSON.REACT_APP_API_stage;

interface S {
  loading: any;
  deleteFileId: any;
  fileName: any;
  selectedProjectsMentionsAccountsData: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  grid: boolean;
  list: boolean;
  anchorEl: any;
  anchorEl1: any;
  anchorE2: any;
  anchorEl2: any;
  setOpen2: any;
  sharedProjectError: boolean;
  sharedProject: any;
  percentage: any;
  quotePrice: any;
  isEditing: any;
  setApprovedQuote: any;
  approvedQuoteActualDate: any;
  setDraftQuote: any;
  openQuoteActualDate: any;
  setPendingQuote: any;
  pendingQuoteActualDate: any;
  setQuoteSent: any;
  QuoteSentActualDate: any;
  projectDeleteId: number;
  duplicateProjectId: number;
  taskId: number;
  projectId: number;
  setOpen: boolean;
  projectDesc: string;
  accountsData: any;
  brandId: number;
  projectName: string;
  ProjectNameByID: string;
  projectDescByID: string;
  permissions: any;
  isCurrentTaskSelected: boolean;
  currentTask: any;
  currentTaskBriefingData: any,
  originalQuote: any;
  dueDate: any;
  editorState: any;
  convertedCommentData: any;
  openTaskBlockerCreateModel: any;
  blockersData: any,
  openSubTaskCreateModel: any;
  subTasksData: any;
  accountId: string;
  commentDataText: string;
  commentsGetData: any;
  setQuoteOpen: any;
  currentTaskBriefingDatas: any;
  currentTasks: any;
  isCurrentSendQuoteSelected: any;
  openTaskBlockerInSendQuote: any;
  blockersDatas: any;
  openSubTaskCreateModelInSendQuote: any;
  subTasksDataInSendQuote: any;
  commentsGetDataInSendQuote: any;
  draftsArrowFlag: any;
  waitingArrowFlag: any;
  onGoingArrowFlag: any;
  fileToRawBinary: any;
  resp: any;
  fileTaskName: any;
  openProjectProperties: any;
  openProjectPropertiesID: any;
  selectedProjectData: any;
  selectedProjectsAccountsData: any;
  selectedProjectsMediaAssetData: any;
  value: any;
  setAnchorEl: any;
  projectPropertiesAccountsData: any;
  taskAssigneeName: any;
  taskReviewerName: any;
  assigneeAccountsData: any;
  permissionName: any;
  anchorElFilter1: any;
  projectTaskFilterValue: any;
  projectFilteredPath: any;
  searchProjectName: any;
  brandTeamsData: any;
  userInfo: any;
  clientCounterVal: any;
  mediaAssetId: any;
  projectMediaAssetsDetail: any;
  file_blob_signed_id_url: any;
  fileImage: any;
  setOpenImageDialog: boolean;
  fileId: any;
  flagDraft: any
  openTaskCommentPopUp: any
  commentId: any
  specificTaskComment: any
  editComment: any
  placeholder: string,
  convertedCommentData2: any,
  initialValue: any,
  selectedFile: any;
  openFileDialog: any;
  taskActivityData: any,
  showActivity: any,
  totalActivityPageCount: any,
  currentPage: any
  uploadDialog: any
  mediaState: any
  dialogTabValue: any;
  brandSelected: any
  mediaAssetSelected: any
  folderSelected: any;
  assetData: any
  brandMediaAssets: any
  projectFoldersAssetsData: any
  projectFilesAssetsData: any
  mediaFolderAssetdFiles: any
  uploadingFilesId: any
  uploadingFilesName: any
  signedIdAndURLs3: any;
  countUpload2: any;
  countUpload: any;
  job_number : any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
var timer : any;

export default class MultilevelApprovalController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  callUpdateProjectPropertiesApiId: any;
  callDeleteAccountProjectsApiId: any;
  callGetProjectPropertiesAccountListApiId: any;
  deleteTaskFileFromTheTaskViewID: any;
  callUpdateTaskBlockerApiID: any;
  getTaskFileEditId: any;
  subtaskSendQuoteAPiEndPointID: any;
  callUpdateSendQuoteSubTaskApiID: any;
  callUpdateSendTaskBlockerApiID: any;
  subtaskAPiEndPointID: any;
  callUpdateSubTaskApiID: any;
  getTaskFileId: any;
  callToBulkFileUpload: any;
  getSignedURLForFileUploadId2: Array<any> = [];
  callShowAccountforProjectMentionsApiId: any;
  getSignedURLForFileUploadId: Array<any> = [];
  getApprovedQuoteId: any;
  getOpenQuoteId: any;
  getPendingQuoteId: any;
  apiCallToProjectDeleteApi: any;
  apiCallToProjectDuplicateApi: any;
  apiCallToPrjectRequestQuoteId: any;
  apiCallToArchiveProjectMessageId: any;
  apiCallToAllAccountsDataId: any;
  apiCallToInviteProjectId: any;
  apiCallToGetProjectDetailsID: any;
  apiCallTostartProjectID: any;
  apiCallToeditQuoteID: any;
  apiCallToApproveQuoteID: any;
  projectApiCallId: any;
  callCreateTaskBlockerApiID: any;
  blockersAPiEndPointID: any;
  callCreateSubTaskApiID: any;
  getSubTaskApiCallID: any;
  subTaskData: any;
  callCreateTaskCommentListID: any;
  getAllTaskCommentsID: any;
  createTaskBlockerInSendQuoteID: any;
  getTakBlockerInSendQuoteID: any;
  createSubTaskInSendQuoteID: any;
  getAllSubTaskInSendQuoteID: any;
  createCommentInSendQuoteTaskID: any;
  getAllCommentsInSendQuoteID: any;
  createTheQuotationPriceID: any;
  callShowMediaforProjectApiId: any
  projectShowApiCallId: any
  callShowAccountforProjectApiId: any;
  getUserAccountsApiCallId: any;


  projectTeamUpdateCallId: any
  getBrandTeamId: any;
  getBrandListingId: any;

  mediaFolderAPIId: any


  showTaskCommentSpecificId: any
  callUpdateTaskCommentID: any;
  callShowTaskActivityApiId: any;
  getMoreTaskActivityApiId: any;
  // Customizable Area End


  getAssetCategoryApiCallId: any
  getMediaAssetsApiCallId: any
  getAssetCategoryFolderApiCallId: any
  getAssetCategoryFileApiCallId: any
  getMediaFolderAssetFilesApiCallId: any
  uploadFilesAPIId: any


  getQuoteSentId : any

  array3: Array<any> = []
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      projectPropertiesAccountsData: [],
      setAnchorEl: null,
      deleteFileId: "",
      resp: null,
      fileToRawBinary: {},
      fileName: "",
      selectedProjectsMentionsAccountsData: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      grid: false,
      list: true,
      anchorEl: null,
      anchorEl1: null,
      anchorE2: null,
      anchorEl2: null,
      setOpen2: false,
      sharedProjectError: false,
      sharedProject: [],
      percentage: 74,
      quotePrice: "",
      isEditing: false,
      setApprovedQuote: [],
      approvedQuoteActualDate: [],
      setDraftQuote: [],
      openQuoteActualDate: [],
      setPendingQuote: [],
      pendingQuoteActualDate: [],
      setQuoteSent: [],
     QuoteSentActualDate: [],
      projectDeleteId: 1,
      duplicateProjectId: 1,
      taskId: 1,
      projectId: 1,
      setOpen: false,
      projectDesc: "",
      accountsData: [],
      brandId: 1,
      projectName: "",
      ProjectNameByID: "No Project Name found",
      projectDescByID: "no description found",
      permissions: "",
      isCurrentTaskSelected: false,
      currentTask: {},
      currentTaskBriefingData: [],
      originalQuote: "",
      dueDate: null,
      editorState: EditorState.createEmpty(),
      convertedCommentData: {},
      openTaskBlockerCreateModel: false,
      blockersData: [],
      openSubTaskCreateModel: false,
      subTasksData: [],
      accountId: '',
      commentDataText: '',
      commentsGetData: [],
      setQuoteOpen: false,
      currentTaskBriefingDatas: [],
      currentTasks: {},
      isCurrentSendQuoteSelected: false,
      openTaskBlockerInSendQuote: false,
      blockersDatas: [],
      openSubTaskCreateModelInSendQuote: false,
      subTasksDataInSendQuote: [],
      commentsGetDataInSendQuote: [],
      draftsArrowFlag: true,
      waitingArrowFlag: true,
      onGoingArrowFlag: true,
      fileTaskName: [],

      openProjectProperties: false,
      openProjectPropertiesID: null,
      selectedProjectData: null,

      selectedProjectsAccountsData: null,
      selectedProjectsMediaAssetData: null,
      value: 0,
      taskAssigneeName: "",
      taskReviewerName: "",
      assigneeAccountsData: [],
      permissionName: "",
      anchorElFilter1: null,
      projectTaskFilterValue: 'title0',
      projectFilteredPath: 'sort_by=title&sort_order=asc',
      searchProjectName: '',
      brandTeamsData: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      clientCounterVal: 1,
      mediaAssetId: null,
      projectMediaAssetsDetail: null,
      flagDraft: false,
      file_blob_signed_id_url: "",
      fileImage: "",
      setOpenImageDialog: false,
      fileId: "",
      openTaskCommentPopUp: false,
      commentId: null,
      specificTaskComment: null,
      editComment: null,
      placeholder: "Add Comment",
      convertedCommentData2: {},
      initialValue: EditorState.createEmpty(),
      selectedFile: "",
      openFileDialog: false,
      taskActivityData: [],
      showActivity: false,
      totalActivityPageCount: 1,
      currentPage: 1,
      uploadDialog: false,
      mediaState: 0,
      dialogTabValue: "MediaAssest",
      brandSelected: null,
      mediaAssetSelected: null,
      folderSelected: null,
      assetData: [],
      brandMediaAssets: [],
      projectFoldersAssetsData: [],
      projectFilesAssetsData: [],
      mediaFolderAssetdFiles: [],
      uploadingFilesId: [],
      uploadingFilesName: [],
      signedIdAndURLs3: [],
      countUpload2: 0,
      countUpload: 0,
      job_number : null

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  handleInputChange = (e?: any) => {
    this.setState({
      originalQuote: this.state.currentTask.attributes.quotation.data.attributes.price,
      quotePrice: e.target.value,
    })
  }
  handleInputChangeInSendQuote = (e?: any) => {
    this.setState({
      quotePrice: e.target.value,
    })
  }
  convertData = (d: any) => {
    let flot = String(d).split('.')
    let kk=flot[0].split('')
    let value = String(d).split('')
    if (flot.length > 1) {
      if (kk.length === 4) {
        kk.splice(1, 0, ",")
      } else if (kk.length === 5) { 
        kk.splice(2, 0, ",")
      } else if (kk.length === 6) {  
        kk.splice(1, 0, ",")
        kk.splice(4, 0, ",")
      } else if (kk.length === 7){ 
        kk.splice(2, 0, ",") 
        kk.splice(5, 0, ",")
      } else if (kk.length === 8){ 
        kk.splice(1, 0, ",")
        kk.splice(4, 0, ",")
        kk.splice(7, 0, ",")
      }
    } else {
      if (value.length === 4) {
        value.splice(1, 0, ",")
      } else if (value.length === 5) {
        value.splice(2, 0, ",")
      } else if (value.length === 6) {
        value.splice(1, 0, ",")
        value.splice(4, 0, ",")
      } else if (value.length === 7){ 
        value.splice(2, 0, ",") 
        value.splice(5, 0, ",")
      } else if (value.length === 8){   
        value.splice(1, 0, ",")
        value.splice(4, 0, ",")
        value.splice(7, 0, ",")
      }
    }
    if (flot.length > 1) {
      return kk.join('')+"."+flot[1]
    } else {
      return value.join('')
    }
  }


  handleClickAway = () => {
    console.log("clickaway")
    this.setState({
      isEditing: false
    })
  }
  handleCloseModalProject = () => {
    this.setState({
      openProjectProperties: false,
    });
  }

  handleAssigneeChange = (event: any, value: any) => {

    if (value != null) {
      var currentTask = this.state.currentTask
      currentTask.attributes.assigned_to = value?.id,
        currentTask.attributes.assignee.data = value,
        this.setState({
          currentTask: currentTask,
          taskAssigneeName: value.attributes.first_name || ""
        });
      this.callUpdateTaskApi();
    }
  };

  getBrandTeams = (brand_id?: any) => {
    // debugger;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandTeamId = requestMessage.messageId;
    var brandID;
    if (brand_id) {
      brandID = brand_id
    }
    else {
      const userDetails = localStorage.getItem("selectedBrand")
      brandID = userDetails
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/project_sharing_lists?brand_id=${brandID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleReviewerChange = (event: any, value: any) => {

    if (value != null) {
      var currentTask = this.state.currentTask
      currentTask.attributes.reviewer_id = value?.id,
        currentTask.attributes.reviewer.data = value,
        this.setState({
          currentTask: currentTask,
          taskReviewerName: value?.attributes?.first_name || ""
        });
      this.callUpdateTaskApi();
    }
  };

  handleClickOpenEditQuoteModal = (task: any) => {
    console.log("testttttttttttttttttttgcghvghvghvg")
    this.setState({
      setOpen2: true,
      isCurrentTaskSelected: true,
      currentTask: task,
      currentTaskBriefingData: task.attributes.briefing.data && task.attributes.briefing.data.attributes.briefed_values.data,
      taskAssigneeName: task?.attributes?.assignee?.data == null ? "" : task?.attributes?.assignee?.data?.attributes?.first_name,
      taskReviewerName: task?.attributes?.reviewer?.data == null ? "" : task?.attributes?.reviewer?.data?.attributes?.first_name
    }, () => { this.getListTaskFileIndexEdit(); this.callGetTaskBlockerListApi(this.state.currentTask.id), this.getSubtaskInTask(this.state.currentTask.id), this.getTaskActivityListApi(this.state.currentTask.id) });
    console.log("Tasks Data", this.state.currentTask)
    console.log("Tasks Current Brifing", this.state.currentTaskBriefingData)
  };
  handleChangeBlockerSelection = (task?: any, value?: any) => {
    console.clear();
    console.log(value, task)
    this.callUpdateTaskBlockerApi(task.id, value);
  };
  handleChangeSendQuoteBlockerSelection = (task?: any, value?: any) => {
    console.clear();
    console.log(value, task)
    this.callUpdateSendQuoteTaskBlockerApi(task.id, value);
  };
  handleClickOpenSendQuoteModal = (task: any) => {
    this.setState({
      setQuoteOpen: true,
      isCurrentSendQuoteSelected: true,
      currentTasks: task,
      currentTask: task,
      currentTaskBriefingDatas: task.attributes.briefing.data.attributes.briefed_values.data
    }, () => {
      this.getListTaskFileIndex(this.state.currentTasks.id);
      this.getTakBlockerInSendQuote(this.state.currentTasks.id);
      this.getAllSubTaskInSendQuote(this.state.currentTasks.id);
      this.getTaskActivityListApi(this.state.currentTasks.id);
      console.log("Data from the send TAsk", this.state.currentTasks)
      console.log("Brifing of the Task ", this.state.currentTaskBriefingDatas)
    })
  }
  handleDateChangeInSendQuote = (event: React.ChangeEvent<{ value: unknown }>) => {
    var currentTask = this.state.currentTasks
    currentTask.attributes.estimated_end_date = event.target.value,
      this.setState({
        dueDate: event.target.value as string,
        currentTasks: currentTask,
      });
    this.updateTheTaskInSendQuote();
  };

  uploadFiles = () => {
    this.setState({ loading: true })


    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    var httpBody;
    if (localStorage.getItem("task") == "0") {
      httpBody = {
        data: {
          "task_id": parseInt(this.state.currentTask.id),
          "media_asset_file_ids": this.state.uploadingFilesId,
          "final_file": false,
          "source_file": false,
        }
      };
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFilesAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/tasks/task_file_clone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkBoxChange = (assetIds: any) => {
    console.log("assetIds", assetIds);

    const assetId = parseInt(assetIds?.id);
    if (this.state.uploadingFilesId.includes(assetId)) {
      var temp = this.state.uploadingFilesId;
      var tempFile = this.state.uploadingFilesName;
      const index = temp.indexOf(assetId);
      if (index > -1) {
        temp.splice(index, 1);
        tempFile.splice(index, 1);
      }
      this.setState({
        uploadingFilesId: temp,
        // uploadingFilesId2 : temp , 
        uploadingFilesName: tempFile
      }, () => console.log("uploadingFilesIda", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))

    }
    else {
      var temp = this.state.uploadingFilesId;
      var tempFile = this.state.uploadingFilesName;
      temp.push(assetId)
      tempFile.push({ name: assetIds?.attributes?.name })
      this.setState({
        uploadingFilesId: temp,
        // uploadingFilesId2 : temp,
        uploadingFilesName: tempFile
      }, () => console.log("uploadingFilesIdb", this.state.uploadingFilesId.includes(assetId), this.state.uploadingFilesId))
    }
    console.log("uploadingFilesId", this.state.uploadingFilesId);





  }






  handleUploadTabChange = (event: any, value: any) => {
    this.setState({ dialogTabValue: value });
  };

  handleUploadOpenDialog = () => {
    this.setState({
      uploadDialog: true,
    });
  };
  handleUploadCloseDialog = () => {
    this.setState({
      // uploadingFilesId: [],
      // uploadingFilesId1: [],
      // uploadingFilesId2: [],
      // uploadingFilesName: [],
      // uploadedImages : []
      uploadDialog: false,
      mediaState: 0
    });
  };

  getMediaAssets = () => {
    console.log("++++")
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/brands/brand_media_asset_listing?page=1&per_page=150`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  loadBrandMediaFolderandFiles = (brandId: any) => {
    this.setState({ loading: true })

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaAssetsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories_sub_categories/media_assets?page=1&per_page=1000&brand_id=${brandId}`
      // `bx_block_categories_sub_categories/media_assets?page=${this.state.pageNumber}&per_page=${this.state.perPage}&brand_id=${this.state.brandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getfilesAndFolder(id: any) {
    this.setState({ loading: true })
    await this.getMediaAsset(id)
    await this.getMediaAssetFiles(id)
    // this.setState({loading : false})
  }

  async getMediaAssetFiles(assetId: any) {

    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      // `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=3&media_asset_id=${assetId}`

      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1000&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  async getMediaAsset(assetId: any) {
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssetCategoryFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // `bx_block_categories_sub_categories/media_assets?page=1&brand_id=${assetId}&per_page=50`

      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=1000&media_asset_id=${assetId}`

      // `bx_block_categories_sub_categories/media_asset_folders?page=${1}&per_page=${10}&media_asset_id=${assetId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getMediaFolderAssetFiles(assetId: any) {
    this.setState({ loading: true })
    const header = {

      token: localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMediaFolderAssetFilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_files?page=1&per_page=1000&media_asset_folder_id=${assetId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  async signedIdHandler() {
    if (this.state.signedIdAndURLs3.length > 0) {
      if (this.state.signedIdAndURLs3.length > 0) {
        await Promise.all(this.state.signedIdAndURLs3.map(async (signed: any, index: any) => {
          await this.bulkUploading(signed?.data[0], this.array3[index]).catch(e => console.log(e));
        })
        )
      }
    }

  }


  handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.estimated_end_date = event.target.value,
      this.setState({
        dueDate: event.target.value as string,
        currentTask: currentTask,
      });
    this.callUpdateTaskApi();


  };
  progressPercentageInSendQuote = (e?: any, value?: any) => {
    var currentTask = this.state.currentTasks
    currentTask.attributes.progress = value
    this.setState({
      percentage: value,
      currentTasks: currentTask
    })
    this.updateTheTaskInSendQuote();

  }
  progressPercentage = (e?: any, value?: any) => {
    var currentTask = this.state.currentTask
    currentTask.attributes.progress = value
    this.setState({
      percentage: value,
      currentTask: currentTask
    })
    this.callUpdateTaskApi();

  }
  callShowMediaforProjectApi(projectId: any) {

    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowMediaforProjectApiId = requestShowAccountMessage.messageId;
    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "bx_block_categories_sub_categories/media_asset_folders/" + `${projectId}`
      "bx_block_categories_sub_categories/media_assets?page=1&per_page=50&media_type=BxBlockProjectPortfolio::Project&media_id=" + `${projectId}`
    );

    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestShowAccountMessage.id, requestShowAccountMessage);



  }

  callShowProjectsApi(projectID: any) {
    console.log("callShowProjectsApi");

    const userDetails = localStorage.getItem("userInfo")

    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectShowApiCallId = requestShowProjectMessage.messageId;
    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_project_portfolio/projects/` + `${projectID}`
    );

    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestShowProjectMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestShowProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // 
      "GET"
    );

    runEngine.sendMessage(requestShowProjectMessage.id, requestShowProjectMessage);



  }
  callShowAccountforProjectApi(projectId: any) {
    console.log("callShowAccountforProjectApi");
    const header = {

      token: localStorage.getItem("token")

    };


    const requestShowAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowAccountforProjectApiId = requestShowAccountMessage.messageId;
    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_project_portfolio/account_projects?project_id" + `=${projectId}&per_page=28`
    );

    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestShowAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestShowAccountMessage.id, requestShowAccountMessage);



  }

  handleClickProjectPropertiesIcon = () => {
    console.log("menu", localStorage.getItem("selectedProject"));
    const item: any = localStorage.getItem("selectedProjectId")
    this.callShowProjectsApi(item)
    this.callShowAccountforProjectApi(item)
    this.callShowMediaforProjectApi(item)

    console.log("truetrue", item);
    this.setState({
      // anchorEl: null,
      // openProjectProperties: true,
      openProjectPropertiesID: item,
    });
  }


  onEditorStateChange = (editorState?: any) => {
    this.setState({
      editorState: editorState
    }, () => { console.log("Editor State", this.state.editorState) });

    this.setState({
      convertedCommentData: convertToRaw(this.state.editorState.getCurrentContent())
    }, () => { console.log("comment data", this.state.convertedCommentData) })
  }



  handleClickCreateNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: true,
    });

  };
  createNewTaskBlockerModelInSendQuote = (e?: any) => {
    this.setState({
      openTaskBlockerInSendQuote: true,
    });

  };
  taskBlockersModelCloseInSendQuote = (e?: any) => {
    this.setState({
      openTaskBlockerInSendQuote: false,
    });

  };

  handleClickCancelNewTaskBlockerModel = (e?: any) => {
    this.setState({
      openTaskBlockerCreateModel: false,
    });

  };
  handleClickCreateNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: true,
    });

  };
  handleClickCancelNewSubTakModel = (e?: any) => {
    this.setState({
      openSubTaskCreateModel: false,
    });

  };
  openSubTaskInSendQuote = (e?: any) => {
    this.setState({
      openSubTaskCreateModelInSendQuote: true,
    });

  };
  openSubTaskInCancelSendQuote = (e?: any) => {
    this.setState({
      openSubTaskCreateModelInSendQuote: false,
    });

  };
  handleChangeUsers = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ accountId: event.target.value as string });

  };
  openMenuFilePopUp = (event: any) => {
    // console.log("EVent Details", event)
    this.setState({ setAnchorEl: event.currentTarget });
  }
  deleteTaskFile = (deleteID: any) => {
    this.setState({ setAnchorEl: null }, () => { console.log("Icon from the setAnchor", deleteID); this.deleteTaskFileFromTheTaskView(deleteID) })
  }
  closeMenu = () => {
    this.setState({ setAnchorEl: null });
  }

  getUserAccounts = () => {
    const brand_id: any = localStorage.getItem("selectedBrandID");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountsApiCallId = requestMessage.messageId;
    const header = {
      // "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    //&brand_id=${brand_id}&role_id=${roles}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?page=${1}&per_page=${50}&ab_users=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskCommentID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // console.clear();
            toast.success("Task Comment Updated Successfully");
            this.cancelSpecificTaskComment();
            this.getTaskActivityListApi(this.state.currentTask.id)
            this.getAllCommentsInSendQuote(this.state.currentTask.id)
            // this.callShowTaskCommentApi(this.state.currentTask.id);
            // this.getTaskActivityListApi(this.state.currentTask.id)
            //console.log("Comment Updated", responseJson)

          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.uploadFilesAPIId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {
          this.getListTaskFileIndex(this.state.currentTask?.id)
          this.setState({ loading: false, uploadingFilesId: [], uploadDialog: false })
          toast.success("Files Uploaded successfully!")

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.brands?.data) {

        this.setState({
          assetData: message?.properties?.RestAPIResponceSuccessMessage?.brands?.data,
          loading: false

        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaAssetsApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data) {
        //let totalPagesCount = this.state.totalPages;
        this.setState({
          brandMediaAssets: message?.properties?.RestAPIResponceSuccessMessage?.media_assets?.data,
          loading: false,
          //count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,
          //totalPages:message?.properties?.RestAPIResponceSuccessMessage?.total_pages ? message?.properties?.RestAPIResponceSuccessMessage?.total_pages : totalPagesCount,
          mediaState: 1
        })

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFolderApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data) {
        this.setState({
          projectFoldersAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_assets_folders?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,


        })

      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getAssetCategoryFileApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          projectFilesAssetsData: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          // count: message?.properties?.RestAPIResponceSuccessMessage?.total_pages,

          mediaState: 2,
          loading: false
        })

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) == this.getMediaFolderAssetFilesApiCallId) {
      if (message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data) {
        this.setState({
          mediaFolderAssetdFiles: message?.properties?.RestAPIResponceSuccessMessage?.media_asset_files?.data,
          mediaState: 3,
          loading: false

        })

      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowTaskActivityApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            taskActivityData: responseJson.task_activities?.data,
            totalActivityPageCount: responseJson.total_pages
          })

        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getMoreTaskActivityApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            taskActivityData: this.state.taskActivityData.concat(responseJson.task_activities?.data),
          })

        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.showTaskCommentSpecificId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ specificTaskComment: responseJson?.data?.attributes.description }, () => {
              const html = this.state.specificTaskComment
              const contentBlock = htmlToDraft(html);
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editComment: editorState })
              }
            });
            //console.log("Dtaa from the response", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }


    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.mediaFolderAPIId) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {

        if (apiRequestCallId && responseJson) {

          this.setState({
            selectedProjectsMediaAssetData: responseJson.media_assets_folders.data,

          })

        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }

      }

    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandTeamId) {
      if (message?.properties?.RestAPIResponceSuccessMessage) {
        this.setState({
          brandTeamsData: message?.properties?.RestAPIResponceSuccessMessage
        }
          , () => {
            console.log(this.state.brandTeamsData, "teamsDara")
          }
        )
      }
    }
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectTeamUpdateCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            const selectedProjectId = localStorage.getItem("selectedProjectId")
            if (selectedProjectId) {
              this.callShowAccountforProjectApi(selectedProjectId)
            }
            toast.success("project team members successfully invited");
          }
        }
      } else if (responseJson.errors) {
        toast.error(`Account ` + errorResponse.errors[0].account_ids);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getUserAccountsApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson);
      if (responseJson?.accounts?.data?.length > 0) {
        this.setState({
          assigneeAccountsData: responseJson?.accounts?.data
        })
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getApprovedQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // category list

          this.setState({ setApprovedQuote: responseJson.tasks?.data })
          console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let ApprovedUpdatedDate: any = [];
          this.state.setApprovedQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            ApprovedUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ approvedQuoteActualDate: ApprovedUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getOpenQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          // category list
          console.log("Open Quote", responseJson);
          this.setState({ setDraftQuote: responseJson.tasks?.data }, () => {
            if (this.state.setDraftQuote?.length == 0) {
              this.setState({ flagDraft: true })
            }
          })
          // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let OpenUpdatedDate: any = [];
          this.state.setDraftQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            OpenUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ openQuoteActualDate: OpenUpdatedDate })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getPendingQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // debugger;
          // category list

          console.log("Pending Quote", responseJson);
          this.setState({ setPendingQuote: responseJson.tasks?.data })
          // // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let pendingUpdatedDate: any = [];
          this.state.setPendingQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            pendingUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ pendingQuoteActualDate: pendingUpdatedDate, loading: false })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    } 
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getQuoteSentId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // debugger;
          // category list
          this.setState({ setQuoteSent: responseJson.tasks?.data })
          // // console.log("Data Quote Approved", responseJson.tasks?.data)
          let DMYString = ""
          let pendingUpdatedDate: any = [];
          this.state.setPendingQuote?.map((items: any) => {
            let modifiedDate: any = items.attributes.updated_at;
            var part: any = modifiedDate.split(" ");
            DMYString = part[0] + " " + part[1] + " " + part[2];
            pendingUpdatedDate.push({ id: items.id, DMY: DMYString })
          })
          this.setState({ QuoteSentActualDate: pendingUpdatedDate, loading: false })
        } else {
          this.parseApiCatchErrorResponse(errorResponse);
        }
        //search live
        //recent search
      }
    }
    
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToProjectDeleteApi) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && responseJson.message) {
        if (apiRequestCallId && responseJson) {
          // category list
          toast.success("Project is deleted Sucessfully");
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          //search live
          //recent search
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToProjectDuplicateApi) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          // category list
          toast.success("Project is duplicated Sucessfully");
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          //search live
          //recent search
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else if (responseJson.errors) {
        toast.error(`Project ` + errorResponse.errors[0].project);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToPrjectRequestQuoteId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error && responseJson.message) {
        if (apiRequestCallId && responseJson) {
          // category list
          toast.success(responseJson.message);
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          //search live
          //recent search
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToArchiveProjectMessageId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          // category list
          toast.success("Project archive is done!");
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          //search live
          //recent search
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToAllAccountsDataId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          // category list
          // console.log("Accounts All", responseJson);
          this.setState({ accountsData: responseJson?.accounts?.data.filter((item: any) => item?.attributes?.role?.data?.attributes?.name?.toUpperCase() == "client".toUpperCase()) }, () => {
            console.log("Accounts All2", this.state.accountsData)
          })
          // toast.success("Project archive is done!");
          // this.getApprovedQuote();
          // this.getOpenQuote();
          // this.getPendingQuote();
          //search live
          //recent search
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToInviteProjectId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          console.log("Data After Execute Invite", responseJson);
          toast.success("Project Invite is sent successfully!");
        }
      } else if (responseJson.errors) {
        toast.error(`This Project ` + errorResponse.errors[0].name);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToGetProjectDetailsID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.setState({ ProjectNameByID: responseJson?.data?.attributes?.name, projectDescByID: responseJson?.data?.attributes?.description })
          // console.log("Hi Sahib Data",responseJson)
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallTostartProjectID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          console.log("Project Start", responseJson)
          toast.success("Project is started successfully!");
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createTheQuotationPriceID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          toast.success("Quotation is created successfully");
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToeditQuoteID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          toast.success("Quotation is updated successfully");
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiCallToApproveQuoteID) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      /// search live
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId && responseJson) {
          this.getApprovedQuote();
          this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()
          this.setState({
            setOpen2: false
          })
          toast.success("Quote is approved successfully");
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Task Data updated successfully");
            this.getTaskActivityListApi(this.state.currentTask.id);
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.projectShowApiCallId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log("projectProject", responseJson)
      if (responseJson) {

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.log("projectProject", responseJson)
            this.setState({
              selectedProjectData: message?.properties?.RestAPIResponceSuccessMessage?.data,
            }, () => {
              this.setState({ openProjectProperties: true })
            })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowAccountforProjectApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.log(responseJson);
            var selectedProjectsMentionsAccountsData: any = this.state.selectedProjectsMentionsAccountsData.length > 0 ? this.state.selectedProjectsMentionsAccountsData : [];
            responseJson.accounts?.accounts?.data.map((item: any) => {
              console.log(item)
              var objectValues: any = {}
              objectValues.text = item.attributes.first_name,
                objectValues.value = item.attributes.first_name,

                selectedProjectsMentionsAccountsData.push(objectValues)
            }
            )
            this.setState({
              selectedProjectsMentionsAccountsData: selectedProjectsMentionsAccountsData,

            })
            this.setState({
              selectedProjectsAccountsData: responseJson.accounts?.accounts?.data,

            })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowMediaforProjectApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {

            this.setState({ mediaAssetId: responseJson.media_assets.data[0].id }, () => this.getMediaFolders(this.state.mediaAssetId))
            this.setState({
              projectMediaAssetsDetail: responseJson.media_assets.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.callGetTaskBlockerListApi(this.state.currentTask.id)
        } else if (errorResponse.errors) {
          toast.error(errorResponse.errors[0].title);
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createTaskBlockerInSendQuoteID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.getTakBlockerInSendQuote(this.state.currentTasks.id)
        } else if (errorResponse.errors) {
          toast.error(errorResponse.errors[0].title);
        }
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTakBlockerInSendQuoteID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({ blockersDatas: responseJson.task_blockers?.data })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].title);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }

    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.blockersAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({ blockersData: responseJson.task_blockers?.data, blockersDatas: responseJson.task_blockers?.data })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].title);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createSubTaskInSendQuoteID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // this.callGetSubTaskListApi(this.state.currentTask.id)
          this.getAllSubTaskInSendQuote(this.state.currentTasks.id);
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].title);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          // this.callGetSubTaskListApi(this.state.currentTask.id)
          this.getSubtaskInTask(this.state.currentTask.id);
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].title);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllSubTaskInSendQuoteID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({ subTasksDataInSendQuote: responseJson?.sub_tasks?.data })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSubTaskApiCallID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({ subTasksData: responseJson?.sub_tasks?.data })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createCommentInSendQuoteTaskID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          toast.success("Task Comment is created successfully!")
          this.setState({ editorState: EditorState.createEmpty(), loading: false }, () => {
            this.getAllCommentsInSendQuote(this.state.currentTasks.id);
          })
        }
      } else if (responseJson.errors) {
        this.setState({
          loading: false
        });
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        this.setState({
          loading: false
        });
        toast.error(errorResponse.error);
      } else {
        this.setState({
          loading: false
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callCreateTaskCommentListID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            //console.log("rreess", responseJson)
            toast.success("Commented successfully");
            this.setState(
              {
                convertedCommentData: {},
                editorState: EditorState.createEmpty(),
                loading: false
              }, () => {
                this.getTaskActivityListApi(this.state.currentTask.id);

              })
            //console.log("this.state.editorState", this.state.editorState);
          }
        }
      } else if (responseJson.errors) {
        this.setState({ loading: false });
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        this.setState({ loading: false });
        toast.error(errorResponse.error);
      } else {
        this.setState({ loading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllCommentsInSendQuoteID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({ commentsGetDataInSendQuote: responseJson.task_comments.data });
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getAllTaskCommentsID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.setState({
            commentsGetData: responseJson.task_comments.data,
            loading: false
          });
        }
      } else if (responseJson.errors) {
        this.setState({ loading: false });
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        this.setState({ loading: false });
        toast.error(errorResponse.error);
      } else {
        this.setState({ loading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callShowAccountforProjectMentionsApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          console.log(responseJson.accounts?.data);
          var selectedProjectsMentionsAccountsData: any = [];
          responseJson?.accounts?.accounts?.data?.map((item: any) => {
            //console.log(selectedProjectsMentionsAccountsData2)
            var objectValues: any = {}
            objectValues.text = item.attributes.first_name,
              objectValues.value = item.attributes.first_name,
              objectValues.url = item.id,
              selectedProjectsMentionsAccountsData.push(objectValues)
          }
          )
          this.setState({
            selectedProjectsMentionsAccountsData: selectedProjectsMentionsAccountsData,
          })
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    }
    if (this.getSignedURLForFileUploadId.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {



            localStorage.setItem("presignedData", JSON.stringify(responseJson.data[0]))
            // this.setState({ resp: responseJson }, () => {
            //   this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
            // })


            if (localStorage.getItem("task") == '0') {
              this.setState({ signedIdAndURLs3: [...this.state.signedIdAndURLs3, responseJson], fileName: this.array3 }, () => {
                console.log("Dtaassss", this.state.signedIdAndURLs3, this.array3.length);
                if (this.state.signedIdAndURLs3.length == this.array3.length) {
                  this.signedIdHandler()
                }
              })
            }
            else {
              // console.log("right");

              this.setState({ resp: responseJson }, () => {
                this.bulkUploading(responseJson.data[0], this.state.fileToRawBinary);
              })
            }




          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    else if (this.getSignedURLForFileUploadId2.includes(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      if (localStorage.getItem("task") == "0" && this.array3.length > 0) {
        this.setState({ countUpload2: this.state.countUpload2 + 1 }, () => {
          if (this.state.countUpload2 == this.state.signedIdAndURLs3?.length) {
            this.setState({ loading: false, countUpload2: 0 })
            // this.taskFileUploadBulk(this.state.resp);
            this.taskFileUploadBulk(this.state.signedIdAndURLs3);
          }
        })

      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callToBulkFileUpload) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (localStorage.getItem("task") == '0') {
            this.setState({ countUpload: this.state.countUpload + 1 }, () => {
              if (this.state.countUpload == this.state.signedIdAndURLs3.length) {
                this.setState({ loading: false, countUpload: 0, uploadDialog: false, signedIdAndURLs3: [] })
                toast.success("files uploaded successfully")
                this.getTaskActivityListApi(this.state.currentTask.id);
                this.getListTaskFileIndex(this.state.currentTask.id)
                if (this.state.currentTask?.id) {
                  this.getTaskActivityListApi(this.state.currentTask.id);
                  this.getListTaskFileIndex(this.state.currentTask.id)
                }
                this.array3 = []
              }
            })
          }
          else if (apiRequestCallId && responseJson) {
            this.setState({ uploadDialog: false })
            toast.success("file uploaded successfully")
            this.setState({
              // fileTaskName: responseJson?.data,
              setAnchorEl: null,
              // setSourceFileAnchorEl: null,
              // uploadVersion: null
            })
            this.array3 = []
            this.getListTaskFileIndex(this.state.currentTask.id)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ fileTaskName: responseJson?.task_files?.data })
            console.log("File Data Bulk Upload", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getTaskFileEditId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({ fileTaskName: responseJson?.task_files?.data })
            console.log("File Data Bulk Upload", responseJson)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSubTaskListApi(this.state.currentTask.id)
            toast.success("Sub task updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.subtaskAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.setState({
            subTasksData: responseJson.sub_tasks.data,

          })
        }
      }
      else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.callGetTaskBlockerListApi(this.state.currentTask.id);
          toast.success("Blocker updated  successfully");
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }

      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSendTaskBlockerApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {
          this.callGetTaskBlockerListApi(this.state.currentTasks.id)
          toast.success("Blocker updated  successfully");
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      }

      else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateSendQuoteSubTaskApiID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.callGetSendQuoteSubTaskListApi(this.state.currentTasks.id)
            toast.success("Sub task updated  successfully");
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.subtaskSendQuoteAPiEndPointID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId != null) {
        /// search live
        if (apiRequestCallId && responseJson) {

          this.setState({
            subTasksDataInSendQuote: responseJson.sub_tasks.data

          })
        }
      }
      else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.deleteTaskFileFromTheTaskViewID) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("Task file deleted successfully")
            this.getListTaskFileIndex(this.state.currentTask.id)
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callGetProjectPropertiesAccountListApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            this.setState({
              projectPropertiesAccountsData: responseJson?.accounts?.data,

            })
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0]);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }

    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callDeleteAccountProjectsApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            console.log(responseJson)
            toast.success("Member Removed successfully");
            // this.callGetProjectsListApiType1();
            // this.callGetProjectsListApiType2();
            // this.callGetProjectsListApiType3();
            // this.callGetProjectsListApiType4();
            const item: any = localStorage.getItem("selectedProjectId")
            this.callShowAccountforProjectApi(item)
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].projects);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.callUpdateProjectPropertiesApiId) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            toast.success("project updated successfully");

          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].role);
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getBrandListingId) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!responseJson.errors) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (apiRequestCallId != null) {
          /// search live
          if (apiRequestCallId && responseJson) {
            // category list

            if (this.state.userInfo?.role?.data?.attributes?.name == 'client' && responseJson.brands?.data?.length == 1) {
              localStorage.setItem("BrandNameIcon", responseJson.brands?.data[0]?.attributes?.name.match(/\b(\w)/g).join(''))
              localStorage.setItem("selectedBrand", responseJson.brands?.data[0]?.id);
              localStorage.setItem("selectedBrandName", responseJson.brands?.data[0]?.attributes?.name);
              localStorage.setItem("selectedBrandImage", responseJson.brands?.data[0]?.attributes?.logo_blob_signed_id_url);
              // localStorage.setItem("selectedProjectId", responseJson.brands?.data[0]?.attributes.projects.length > 0 ? responseJson.brands?.data[0]?.attributes.projects[0].id : null);
              const sideNavData = { sideNavData: [] }
              let da = { sideNavData: sideNavData, key: "approvalRequests" }
              localStorage.setItem("navData", JSON.stringify(da))
              this.setState({
                projectId: responseJson.brands?.data[0]?.attributes.projects.length > 0 ? responseJson.brands?.data[0]?.attributes.projects[0].id : null
              }, () => {
                this.getPendingQuote();
                this.getQuoteSent()
                this.getProjectDetailById();
                if (localStorage.getItem('clientCounterVal') == '1') {
                  localStorage.setItem('clientCounterVal', '0');
                  window.location.reload();
                }
                //window.location.href = '/Home/ProjectList';
              })
            }
          }
        }
      } else if (responseJson.errors) {
        toast.error(errorResponse.errors[0].brand);
        //  console.log("Fetch Data Brand",errorResponse.errors[0].brand)
      } else if (responseJson.error) {
        toast.error(errorResponse.error);
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area Start
    // Customizable Area End

  }

  //project update api
  callUpdateProjectsApi(users?: any) {
    const selectedProjectData: any = this.state.selectedProjectData
    const userDetails = localStorage.getItem("userInfo")
    // if (value) {
    //   const selected = [];
    //   for (let i = 0; i < value.length; i++) {
    //     selected.push(value[i].id)
    //   }
    //   var selectedUsers = [];
    //   selectedUsers = selected.map((i: any) => Number(i));
    // }

    // if (userDetails) {
    //   var brand_id = JSON.parse(userDetails).brand_id

    // }


    if (users) {
      const selectedAccount = [];
      const selectedTeam = []

      for (let i = 0; i < users.length; i++) {
        if (users[i].entity == "Account") {
          selectedAccount.push(users[i].id)
        }
        else if (users[i].entity == "Team") {
          selectedTeam.push(users[i].id)
        }

      }
      var selectedUsers = [];
      selectedUsers = selectedAccount.map((i: any) => Number(i));
      var teams = [];
      teams = selectedTeam.map((i: any) => Number(i));
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectId:this.state.projectId
      // email: this.state.email,
      // password: this.state.password
      "account_ids": selectedUsers,
      "project_id": selectedProjectData.id,
      "team_ids": teams,
      "message": "You have been invited to view project"
    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTeamUpdateCallId = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //configJSON.projectUpdateAPiEndPoint + `/${selectedProjectData.id}`
      `bx_block_project_portfolio/account_projects`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      //configJSON.projectUpdateAPiMethod
      "POST"
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);



  }


  //project update api
  callUpdateProjectPropertiesApi(values?: any, users?: any) {
    console.log("project overview", values, users);
    if (users?.length > 0) {
      this.callUpdateProjectsApi(users)
    }

    // this.setState({
    //   openProjectProperties: false,
    // });


    const selectedProjectData: any = this.state.selectedProjectData
    const userDetails = localStorage.getItem("userInfo")
    // if (users) {
    //   const selectedAccount = [];
    //   const selectedTeam = []

    //   for (let i = 0; i < users.length; i++) {
    //     if (users[i].entity == "Account") {
    //       selectedAccount.push(users[i].id)
    //     }
    //     else if (users[i].entity == "Team") {
    //       selectedTeam.push(users[i].id)
    //     }

    //   }
    //   var selectedUsers = [];
    //   selectedUsers = selectedAccount.map((i: any) => Number(i));
    //   var teams = [];
    //   teams = selectedTeam.map((i: any) => Number(i));
    // }
    //console.log("selectedUsers", selectedUsers);
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    const attrs = {
      //projectId:this.state.projectId,
      // email: this.state.email,
      // password: this.state.password
      // account_ids:projectTitle selectedUsers,
      // team_ids: teams,
      "name": values?.projectTitle,
      "description": values?.projectDescription,

    };

    // const data = {
    //  // type: "email_account",
    //   attributes: attrs
    // };

    const httpBody = {
      data: attrs
    };
    const requestUpdateProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateProjectPropertiesApiId = requestUpdateProjectMessage.messageId;
    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateAPiEndPoint + `/${selectedProjectData.id}`
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateProjectMessage.id, requestUpdateProjectMessage);



  }
  //account project delete api
  callDeleteAccountProjectsApi(accountID?: any, selectedProjectIdForDelete?: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    // const attrs = {
    //   // email: this.state.email,
    //   // password: this.state.password
    // };

    const data = {
      "project_id": selectedProjectIdForDelete,
      "account_ids": [accountID]
    };

    const httpBody = {
      data: data
    };

    const requestDeleteAccountProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callDeleteAccountProjectsApiId = requestDeleteAccountProjectMessage.messageId;
    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountProjectDeleteAPiEndPoint
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestDeleteAccountProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountProjectDeleteAPiMethod
    );

    runEngine.sendMessage(requestDeleteAccountProjectMessage.id, requestDeleteAccountProjectMessage);



  }
  //account  index api
  callGetProjectPropertiesAccountListApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callGetProjectPropertiesAccountListApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteTaskFileFromTheTaskView = (deleteID: any) => {
    const header = {
      "Content-Type": configJSON.taskFileDeleteApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskFileFromTheTaskViewID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFileDeleteAPiEndPoint + deleteID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskFileDeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callUpdateSendQuoteTaskBlockerApi(taskBlockerId?: any, value?: any) {
    console.log();

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };
    const requestUpdateTaskBlockerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSendTaskBlockerApiID = requestUpdateTaskBlockerMessage.messageId;
    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersSendQuoteUpdateAPiEndPoint + `/${taskBlockerId}`
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersSendQuoteUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskBlockerMessage.id, requestUpdateTaskBlockerMessage);



  }
  //task  blocker update api
  callUpdateTaskBlockerApi(taskBlockerId?: any, value?: any) {
    console.log();

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };
    console.log("value", value)
    const requestUpdateTaskBlockerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateTaskBlockerApiID = requestUpdateTaskBlockerMessage.messageId;
    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersUpdateAPiEndPoint + `/${taskBlockerId}`
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskBlockerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskBlockerMessage.id, requestUpdateTaskBlockerMessage);



  }
  //sub task  index api
  callGetSendQuoteSubTaskListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subtaskSendQuoteAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //sub task  index api
  callGetSubTaskListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subtaskAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //task  subtask update api
  callUpdateSendQuoteSubTaskApi(subTaskId?: any, value?: any) {
    console.log(value);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };

    const requestUpdateSubTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSendQuoteSubTaskApiID = requestUpdateSubTaskMessage.messageId;
    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskUpdateAPiEndPoint + `/${subTaskId.id}`
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateSubTaskMessage.id, requestUpdateSubTaskMessage);
  }
  //task  subtask update api
  callUpdateSubTaskApi(subTaskId?: any, value?: any) {
    console.log(value);
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };


    const data = {
      // type: "email_account",
      is_completed: value
    };

    const httpBody = {
      data: data
    };

    const requestUpdateSubTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callUpdateSubTaskApiID = requestUpdateSubTaskMessage.messageId;
    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskUpdateAPiEndPoint + `/${subTaskId.id}`
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateSubTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateSubTaskMessage.id, requestUpdateSubTaskMessage);
  }
  getListTaskFileIndexEdit = () => {
    const header = {
      "Content-Type": configJSON.getTaskFileGetContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileEditId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskFileGetApiEndPoint + this.state.currentTask.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getListTaskFileIndex = (taskId?: any) => {

    const header = {
      "Content-Type": configJSON.getTaskFileGetContentType,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      taskId ? configJSON.getTaskFileGetApiEndPoint + taskId : configJSON.getTaskFileGetApiEndPoint + this.state.currentTasks.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskFileGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  taskFileUploadBulk(responseJson: any) {

    const header = {
      "Content-Type": configJSON.taskBulkUploadContentType,
      token: localStorage.getItem("token")
    };
    var data;
    if (localStorage.getItem("task") == "0") {
      data = {
        task_id: this.state.currentTask.id,
        data: {
          task_files_attributes: this.array3?.length > 0 && this.array3.map((file: any, index: any) => {
            return {
              "name": file?.name,
              // "file_type": file?.type,
              "final_file": false,
              "source_file": false,
              "file_blob_signed_id": this.state.signedIdAndURLs3 && this.state.signedIdAndURLs3[index]?.data[0]?.signed_id ? this.state.signedIdAndURLs3[index]?.data[0]?.signed_id : ''
            }
          })

        }
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callToBulkFileUpload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskBulkUploadContentTypeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskBulkUploadContentTypeMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  bulkUploading = async (data: any, file: any) => {
    var blob = new Blob([file], { type: 'text/plain' });
    let headers = Object.values(data?.headers);
    const header = {
      "Content-Type": headers[0],
      "Content-MD5": headers[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignedURLForFileUploadId2.push(requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data?.url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      blob
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: any): void {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      processChunk(0);
    });
  }

  getSignedUrl = async (file: any) => {
    console.log("File", file)
    console.log("File", file)
    this.array3.push(file)
    this.setState({ fileName: file.name })
    this.setState({ fileToRawBinary: file })
    const md5 = await this.computeChecksumMd5(file);
    console.log('computeChecksumMd5', md5);

    await this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    // this.computeChecksumMd5(file).then((md5) => {
    //   this.getSignedURLForFileUpload(file.size, md5, file.name, file.type)
    // });
  }

  //fie upload API api
  async getSignedURLForFileUpload(fileSize: any, checkSum: any, fileName: any, contentType: any) {
    this.setState({ loading: true })
    this.handleUploadCloseDialog();
    const header = {
      "Content-Type": configJSON.callToPreSignedURLContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      byte_size: fileSize,
      checksum: checkSum,
      filename: fileName,
      content_type: contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // this.getSignedURLForFileUploadId = requestMessage.messageId;
    this.getSignedURLForFileUploadId.push(requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.callToPreSignedURLApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callToPreSignedURLMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  // progressPercentage = (e?: any, value?: any) => {
  //   console.log(e, value, "value")
  //   this.setState({
  //     percentage: value
  //   })
  // }
  changeToGridView(e?: any) {
    this.setState({
      grid: true,
      list: false
    }, () => { this.getApprovedQuote(); this.getOpenQuote(); this.getPendingQuote();  this.getQuoteSent() })
  }
  handleClose = (item?: any) => {
    console.log(item, "acount");
    this.setState({
      anchorEl: null,
    });
    if (item == "account") {
      this.props.navigation.navigate("AccountProfile");
    }
    if (item == "companyProfile") {
      this.props.navigation.navigate("CompanyProfile");
    }
  };
  handleClickMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  setData() {
    console.log("hello")
    const sideNavData = {
      sideNavData:
        [
          {
            name: "annualEvent"
          },
          {
            name: "quaterlyEvent"
          }


        ]
    }
    let da = { sideNavData: sideNavData, key: "approvalRequests" }
    localStorage.setItem("navData", JSON.stringify(da))
    // this.props.history.push("/Home/Projectportfolio")
    // navigator.navigate("/Home/Projectportfolio")
    window.location.href = '/Home/Approve'



  }
  handleClickOpenTaskModal = () => {
    this.setState({
      setOpen2: true
    })
  }
  //task comment update api
  callUpdateTaskCommentApi(convertedCommentData: any) {

    var comment = draftToHtml(convertToRaw(this.state.editComment.getCurrentContent()))
    let ArrayObjects: any = [];
    // Object.values(convertedCommentData.entityMap).forEach((val: any) => {
    //   let data: any = {
    //     [val.data.text]: parseInt(val.data.url)
    //   }
    //   ArrayObjects.push(data);
    // });
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      if (val?.type == 'LINK') {

      }
      else {
        let data: any = {
          [val.data.text]: parseInt(val.data.url)
        }
        ArrayObjects.push(data);
      }
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.taskCommentUpdateApiContentType,
      token: localStorage.getItem("token")

    };
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,

    }
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callUpdateTaskCommentID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentUpdateAPiEndPoint + `${this.state.commentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentUpdateAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  onEditorStateCommentTaskSpecific = (editorState?: any) => {
    let contentState = convertFromHTML('<p>Hello</p>');
    const content = ContentState.createFromBlockArray(
      contentState.contentBlocks,
      contentState.entityMap
    );
    this.setState({
      initialValue: EditorState.createWithContent(content),
      editComment: editorState
    });
    this.setState({
      convertedCommentData2: convertToRaw(this.state.editComment.getCurrentContent())
    });
  }

  cancelSpecificTaskComment = () => {
    this.setState({ openTaskCommentPopUp: false })
  }

  showTaskCommentSpecific(commentID: any) {
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTaskCommentSpecificId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentSpecificCommentAPiEndPoint + commentID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentSpecificCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  openSpecificTaskComment = (commentId: any) => {
    this.setState({ openTaskCommentPopUp: true, commentId: commentId }, () => { this.showTaskCommentSpecific(commentId) })
  }

  handleCloseTaskModal = () => {
    this.getApprovedQuote();
    this.getOpenQuote();
    this.getPendingQuote();
    this.getQuoteSent()
    this.getProjectDetailById();
    this.setState({
      setOpen2: false,
      setQuoteOpen: false,
      taskAssigneeName: "",
      taskReviewerName: ""
    })
  }
  handleCloseProjects = (item?: any) => {
    this.setState({
      anchorE2: null
    })
    if (item == "activeProjects") {
      // this.props.navigation.navigate("AccountProfile");
    }
    if (item == "archievedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
    if (item == "deletedProjects") {
      // this.props.navigation.navigate("CompanyProfile");
    }
  }
  changeToListView(e?: any) {
    this.setState({
      grid: false,
      list: true
    })
  }
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  //task Blocker index api
  getTakBlockerInSendQuote(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.blockersApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTakBlockerInSendQuoteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  //task Blocker index api
  callGetTaskBlockerListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.blockersApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockersAPiEndPointID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  getAllSubTaskInSendQuote = (taskId: any) => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getSubtaskCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubTaskInSendQuoteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubtaskCreateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubtaskCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  getSubtaskInTask = (taskId: any) => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getSubtaskCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubTaskApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubtaskCreateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubtaskCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }
  //task blocker in send quote create api
  createTaskBlockerInSendQuote(values?: any) {
    this.setState({
      openTaskBlockerInSendQuote: false
    });
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }

    const header = {
      "Content-Type": configJSON.blockersCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTasks.id,
      "description": values.description,

    };
    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTaskBlockerInSendQuoteID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }

  //task blocker create api
  callCreateTaskBlockerApi(values?: any) {
    this.setState({
      openTaskBlockerCreateModel: false,
    });
    console.log("Data Values", values)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }

    const header = {
      "Content-Type": configJSON.blockersCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTask.id,
      "description": values.description,

    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callCreateTaskBlockerApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockersCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockersCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);



  }
  // Create Subtask In send quote 
  createSubTaskInSendQuote = (values?: any, userId?: any) => {

    this.setState({
      openSubTaskCreateModelInSendQuote: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }


    const header = {
      "Content-Type": configJSON.subtaskCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTasks.id,
      "title": values.title,

    };
    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubTaskInSendQuoteID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);
  }


  //sub task create api
  callCreateSubTaskApi = (values?: any, userId?: any) => {

    this.setState({
      openSubTaskCreateModel: false,
    });

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id


    }


    const header = {
      "Content-Type": configJSON.subtaskCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const attrs = {
      "task_id": this.state.currentTask.id,
      "title": values.title
    };



    const httpBody = {
      data: attrs
    };
    const requestCreateStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateSubTaskApiID = requestCreateStatusMessage.messageId;
    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subtaskCreateAPiEndPoint
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subtaskCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateStatusMessage.id, requestCreateStatusMessage);
  }
  //account  index api
  callShowAccountforProjectMentionsApi(nameofUser: any) {

    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {

      token: localStorage.getItem("token")

    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowAccountforProjectMentionsApiId = requestMessage.messageId;
    if (nameofUser === '') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_project_portfolio/account_projects?project_id=${localStorage.getItem("selectedProjectId")}`
        //first_name=&last_name&email&username&brand_id`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountAPiEndPoint + `?page=${1}&&user=${nameofUser}&per_page=${20}`
        //first_name=&last_name&email&username&brand_id`
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //task comment create api
  createCommentInSendQuoteTask(convertedCommentData?: any) {

    this.setState({
      loading: true
    });
    //console.log(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log(this.state.editorState.getCurrentContent().getPlainText());
    var comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    let ArrayObjects: any = [];
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    console.log("Expected Results", referencesData)
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.taskCommentCreateApiContentType,
      token: localStorage.getItem("token")
    };
    const data = {
      task_id: this.state.currentTasks.id,
      references: referencesData,
      description: comment,
      textual_description: this.state.editorState.getCurrentContent().getPlainText()
    }
    const httpBody = {
      data: data
    };

    const requestCreateTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentInSendQuoteTaskID = requestCreateTaskCommentMessage.messageId;
    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentCreateAPiEndPoint
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskCommentMessage.id, requestCreateTaskCommentMessage);
  }


  getMediaFolders = (mediaID: any) => {
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mediaFolderAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories_sub_categories/media_asset_folders?page=1&per_page=30&media_asset_id=${mediaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  //task comment create api
  callCreateTaskCommentListApi(convertedCommentData?: any) {
    console.log(this.state.editorState.getCurrentContent().getPlainText())
    if (this.state.editorState.getCurrentContent().getPlainText() == "" || this.state.editorState.getCurrentContent().getPlainText()?.trim() == "") {
      toast.error("Comment is required");
      return;
    }

    this.setState({
      loading: true
    });
    //console.log(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log(this.state.editorState.getCurrentContent().getPlainText());
    var comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    const userDetails = localStorage.getItem("userInfo")
    let ArrayObjects: any = [];
    Object.values(convertedCommentData.entityMap).forEach((val: any) => {
      let data: any = {
        [val.data.text]: parseInt(val.data.url)
      }
      ArrayObjects.push(data);
    });
    let referencesData: any = Object.assign({}, ...ArrayObjects);
    //console.log("Expected Results", referencesData)
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")

    };
    console.log("this.state.currentTask", this.state.currentTask)
    const data = {
      task_id: this.state.currentTask.id,
      references: referencesData,
      description: comment,
      // file_blob_signed_id: this.state.presignedData?.signed_id,
      textual_description: this.state.editorState.getCurrentContent().getPlainText()
    }



    const httpBody = {
      data: data
    };

    const requestCreateTaskCommentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCreateTaskCommentListID = requestCreateTaskCommentMessage.messageId;
    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCommentCreateAPiEndPoint
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestCreateTaskCommentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestCreateTaskCommentMessage.id, requestCreateTaskCommentMessage);



  }
  getAllCommentsInSendQuote = (taskId: any) => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTaskCommentCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCommentsInSendQuoteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskCommentCreateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllTaskComments = (taskId: any) => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {
      "Content-Type": configJSON.getTaskCommentCreateApiContentType,
      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTaskCommentsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskCommentCreateAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskCommentCreateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Update Task APi
  updateTheTaskInSendQuote() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.taskUpdateApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: this.state.currentTasks.attributes,
    };
    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskUpdateAPiEndPoint + `${this.state.currentTasks.id}`
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);



  }

  // Update Task APi
  callUpdateTaskApi() {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.taskUpdateApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {
      data: this.state.currentTask?.attributes,
    };
    const requestUpdateTaskMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectApiCallId = requestUpdateTaskMessage.messageId;
    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskUpdateAPiEndPoint + `${this.state.currentTask?.id}`
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestUpdateTaskMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskUpdateAPiMethod
    );

    runEngine.sendMessage(requestUpdateTaskMessage.id, requestUpdateTaskMessage);



  }


  // Get Approved Quote
  getApprovedQuote = () => {
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = this.state.projectId;
    const header = {
      "Content-Type": configJSON.getQuoteApprovedContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApprovedQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteApprovedApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}&page=1&per_page=1000`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteApprovedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Open/New Quote
  getOpenQuote = () => {
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = this.state.projectId;
    const header = {
      "Content-Type": configJSON.getQuoteOpenContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOpenQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteOpenApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuoteOpenApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get Pending Quote
  getPendingQuote = () => {

    // debugger;
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = this.state.projectId;
    const header = {
      "Content-Type": configJSON.getQuotePendingContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingQuoteId = requestMessage.messageId;
    if (this.state.searchProjectName != "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getQuotePendingApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}&title=${this.state.searchProjectName}` + `&${this.state.projectFilteredPath}` + '&page=1&per_page=1000'
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getQuotePendingApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}` + `&${this.state.projectFilteredPath}` + '&page=1&per_page=1000'
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuotePendingApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getQuoteSent =()=>{
    var brand_id = JSON.parse(localStorage.getItem("selectedBrand") || "");
    var project_id = this.state.projectId;
    const header = {
      "Content-Type": configJSON.getQuotePendingContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuoteSentId = requestMessage.messageId;
    if (this.state.searchProjectName != "") {
      if(this.state.permissionName === 'client'){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getQuoteSentApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}&title=${this.state.searchProjectName}` + `&${this.state.projectFilteredPath}` + '&page=1&per_page=1000'
        );

      }
      else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getQuoteSentApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}&title=${this.state.searchProjectName}` + `&${this.state.projectFilteredPath}` + '&page=1&per_page=1000'
        );

      }
      

    } else {
      if(this.state.permissionName === 'client'){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getQuoteSentApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}` + `&${this.state.projectFilteredPath}` +'&page=1&per_page=1000'
        );
      }
      else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getQuoteSentApiEndPoint + `&brand_id=${brand_id}&project_id=${project_id}` + `&${this.state.projectFilteredPath}` + '&page=1&per_page=1000'
        );

      }


     
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuotePendingApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Project Delete 
  draftProjectDeleteById = (id: any) => {
    // console.log("Project  Delete ID",id)

    const header = {
      "Content-Type": configJSON.contentTypeApiProjectDelete,
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToProjectDeleteApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletionProjectApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletionProjectApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  duplicateProjectById = (projectIds: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiProjectDuplicate,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { project_id: projectIds } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToProjectDuplicateApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateProjectApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.duplicateProjectApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //  Request Quote By Task ID 
  requestQuoteByTaskId = (taskById: number) => {
    // console.log("Task ID", taskById)
    const header = {
      "Content-Type": configJSON.contentTypeApiProjectRequestQuote,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { task_id: taskById } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToPrjectRequestQuoteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectRequestQuoteApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectRequestQuoteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  archiveTheProject = (id: number) => {
    // console.log("Archive Project ID", id);
    const header = {
      "Content-Type": configJSON.contentTypeApiProjectArchive,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { is_archived: true } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToArchiveProjectMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectArchiveApiEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectArchiveApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  allAccountsData = () => {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAllAccountData,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToAllAccountsDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allAccountDataApiEndPoint + `?page=${1}&per_page=${20}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allAccountDataApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  inviteTheProjectToTeam = (values: any, brandID: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiInviteProject,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        brand_id: brandID,
        name: values.projectName,
        description: values.projectDescription,
        account_ids: values.account_ids,
        message: values.includeAPersonalMessageWithYourInvitation
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToInviteProjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteProjectApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.inviteProjectApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProjectDetailById = async() => {
    var project_id = this.state.projectId;
    console.log("this.state.projectId", this.state.projectId)
    const header = {
      "Content-Type": configJSON.contentTypeApiProjectDetails,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToGetProjectDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDetailsApiEndPoint + project_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  startProject = (startProjectID: any) => {
    console.log("Start Project ID", startProjectID);
    const header = {
      "Content-Type": configJSON.contentTypeApiStartProject,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { commence_work: true } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallTostartProjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.startProjectApiEndPoint + startProjectID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.startProjectApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateQuotationPrice = (quotationID: any) => {
    console.clear();
    console.log("Quotation Price is", this.state.quotePrice)
    console.log("Quotation ID", quotationID)
    const header = {
      "Content-Type": configJSON.contentTypeApiEditQuote,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { price: parseFloat(this.state.quotePrice), action: "edit_quote" } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToeditQuoteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editQuoteApiEndPoint + quotationID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editQuoteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createTheQuotationPrice = (taskId: any, quotationId: any) => {
    this.setState({
      setQuoteOpen: false,
      isCurrentSendQuoteSelected: false
    })
    const header = {
      "Content-Type": configJSON.contentTypeApiCreateQuotation,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { task_id: parseInt(taskId), price: parseFloat(quotationId) } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTheQuotationPriceID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createQuotationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createQuotationApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  taskApproveQuote = (quotationID: any) => {
    console.log("Quotation ID", quotationID?.attributes?.quotation?.data?.id)
    const header = {
      "Content-Type": configJSON.taskApproveQuoteApiContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = { data: { action: "approve_quote" } };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallToApproveQuoteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskApproveQuoteAPiEndPoint + quotationID?.attributes?.quotation?.data?.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskApproveQuoteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandListing = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandListingAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // Customizable Area Start
  async componentDidMount() {
    var str = window.location.href;
    var arr = str.split("?")[1];
    var arr2;
    if (arr != undefined && arr.length > 1) {
      arr2 = arr.split("&");
      localStorage.setItem("selectedProjectId", arr2[0].split("=")[1]);
      localStorage.setItem("selectedBrand", arr2[1].split("=")[1]);
      localStorage.setItem("selectedBrandID", arr2[1].split("=")[1]);
      const header = new Headers();
      var taskData;
      header.append("token", `${localStorage.getItem("token")}`)
       fetch(`${currentUrl}` + `/bx_block_project_portfolio/tasks/${(arr2[2].split("=")[1])}`,{
         headers: header
       }).then(res=>  res.json())
          .then(data=>{
         console.log("taskData",data?.data);
         taskData = data?.data;
         arr2 = arr.split("&");
         if (arr2[3]?.split("=")[0] == "sendQuote" && arr2[3]?.split("=")[1] == "1") {
          console.log("arr2[2]",arr2[2]);
          
           this.handleClickOpenSendQuoteModal(taskData)
    
        }
        if (arr2[3]?.split("=")[0] == "approveQuote" && arr2[3]?.split("=")[1] == "1") {
    
          this.handleClickOpenEditQuoteModal(taskData)
        }
  
       })
     
      
      
    }
    if (this.state.userInfo?.role?.data?.attributes?.name == 'client') {
      this.getBrandListing();
    }
    let userDetailsBeforeParseTemp: any = localStorage.getItem("userInfo");
    let userDetailsTemp = JSON.parse(userDetailsBeforeParseTemp);
    let permissionNameTemp = userDetailsTemp?.role?.data?.attributes?.name
    this.setState({ loading: true, permissions: localStorage.getItem('permissions'), permissionName: permissionNameTemp })

    const selectedProjectId = JSON.parse(localStorage.getItem("selectedProjectId") || "");
    if (selectedProjectId) {
      this.setState({ projectId: selectedProjectId }, () => {
        this.getProjectDetailById();
        this.getPendingQuote();
        this.getApprovedQuote();
        // this.getOpenQuote();
        this.getQuoteSent()
         
      });
    }

   

    this.allAccountsData();
    this.getBrandTeams();
    this.getUserAccounts();
    this.callGetProjectPropertiesAccountListApi('');
    this.callShowAccountforProjectMentionsApi('');
   
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.projectId !== JSON.parse(localStorage.getItem("selectedProjectId") || "")) {
      const selectedProjectId = JSON.parse(localStorage.getItem("selectedProjectId") || "");
      if (selectedProjectId) {
        this.setState({ loading: true, projectId: selectedProjectId }, () => {
          this.getApprovedQuote();
          // this.getOpenQuote();
          this.getPendingQuote();
          this.getQuoteSent()

          this.getProjectDetailById();
        });
      }
    }
  }

  handleFilterClose2 = () => {
    this.setState({ anchorElFilter1: null })
  }

  handleFilterChange2 = (event: any) => {
    var order = event.target.value.slice(-1)
    var item = event.target.value.substr(0, event.target.value.length - 1)
    this.setState({
      // isSearch: true,
      projectTaskFilterValue: event.target.value,
      anchorElFilter1: null
      // , isSearch: true
    })
    let path = "" as string;
    if (order == "0") {
      path = `sort_by=${item}&sort_order=asc`
    }
    else if (order == "1") {
      path = `sort_by=${item}&sort_order=desc`
    }

    this.setState({
      projectFilteredPath: path
    }, () => {
      this.getPendingQuote();
      this.getQuoteSent()
    })
  }

  handleProjectSearchChange = (event: any) => {

    console.log(event.target.value)
    if (event.target.value !== "") {
      this.setState({ searchProjectName: event.target.value }, () => {
        this.getPendingQuote()
        this.getQuoteSent()
      })
    } else {
      this.setState({ searchProjectName: "" }, () => {

        this.getPendingQuote()
        this.getQuoteSent()
      });
    }
  }

  handleFileMediaClick = (url: any) => {
    console.log(url)
    this.setState({ selectedFile: url }, () => {
      this.handleFileDialog();
    })
  }

  handleFileDialog = () => {
    this.setState({ openFileDialog: !this.state.openFileDialog })
  }

  handleJobNumberChange = (event : any) => {
    if (event.target.value != null) {    
      var currentTask = this.state.currentTask
      currentTask.attributes.job_number = event.target.value,
        currentTask.attributes.job_number = event.target.value,
        this.setState({
          currentTask: currentTask,
          job_number: event.target.value || ""
        });

      if(timer){
        clearTimeout(timer)
      }
      timer = setTimeout(()=>{
        this.callUpdateTaskApi();
      },2000)     
    }
  }

  getTaskActivityListPerPageApi = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    }, () => {
      const userDetails = localStorage.getItem("userInfo")
      if (userDetails) {
        var brand_id = JSON.parse(userDetails).brand_id

      }
      const header = {

        token: localStorage.getItem("token")

      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getMoreTaskActivityApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.taskActivityLogAPiEndPoint + `?task_id=${this.state.currentTask.id}&&page=${this.state.currentPage}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.taskActivityLogAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  getTaskActivityListApi(taskId: any) {
    const userDetails = localStorage.getItem("userInfo")
    if (userDetails) {
      var brand_id = JSON.parse(userDetails).brand_id

    }
    const header = {

      token: localStorage.getItem("token")

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callShowTaskActivityApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskActivityLogAPiEndPoint + `?task_id=${taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.taskActivityLogAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAttachmentClick = (file: any) => {
    if (file) {
      if (file !== null || file !== undefined) {
        window.open(file, "_blank")
      }
    }

  }
  // Customizable Area End

}
