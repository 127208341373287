// App.js - WEB
import React, { Component } from 'react';
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  Link
} from 'react-router-dom';

import { View } from 'react-native';
// import firebase from 'firebase'
import { connect } from 'react-firebase';
import { withRouter } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen.web';
import HomePage from '../../components/src/HomePage.web';

import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import GSuiteIntegration from '../../blocks/GSuiteIntegration/src/GSuiteIntegration';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole.web';
import Annotations from '../../blocks/Annotations/src/Annotations';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Chat from '../../blocks/Chat/src/Chat';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Notifications from '../../blocks/Notifications/src/Notifications';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Translation from '../../blocks/Translation/src/Translation';
import LandingPage from '../../blocks/LandingPage/src/LandingPage';
import HubspotIntegration from '../../blocks/HubspotIntegration/src/HubspotIntegration';
import Download from '../../blocks/Download/src/Download';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import PersonalEmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/PersonalEmailAccountRegistration.web"
import HelpCentre from '../../blocks/HelpCentre/src/HelpCentre';
import Comments from '../../blocks/Comments/src/Comments';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Documentation from '../../blocks/Documentation/src/Documentation';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import UploadMedia from '../../blocks/UploadMedia/src/UploadMedia';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder.web';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import CustomiseInterface from '../../blocks/CustomiseInterface/src/CustomiseInterface';
import ServicespecificSettingsadmin from '../../blocks/ServicespecificSettingsadmin/src/ServicespecificSettingsadmin';
import ImportPhotoshopDocumentspsd from '../../blocks/ImportPhotoshopDocumentspsd/src/ImportPhotoshopDocumentspsd';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import EmailVerfication from '../../blocks/email-account-registration/src/EmailVerfication.web';
import EmailConfirmation from '../../blocks/email-account-registration/src/EmailConfirmation.web';
import EmailAccountActivation from '../../blocks/email-account-registration/src/EmailAccountActivation.web';
import BrandAssets from '../../blocks/categoriessubcategories/src/BrandAssets';
import Brands from '../../blocks/ProjectPortfolio/src/Brands.web';
import MediaAssets from '../../blocks/categoriessubcategories/src/MediaAssets';
import ProjectPortfolio from '../../blocks/ProjectPortfolio/src/ProjectPortfolio.web';
import ProjectsTypes from '../../blocks/ProjectPortfolio/src/ProjectsTypes.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import MultilevelApproval from '../../blocks/MultilevelApproval/src/MultilevelApproval';
import AccountProfileWeb from '../../components/src/AccountProfile.web';
import HelpWeb from '../../components/src/Help.web';
import CompanyProfile from '../../components/src/CompanyProfile.web';
import { lightTheme } from '../../components/src/theme';
import Trello from '../../components/src/draganddrop/Trello.web';
import indexTrello from '../../components/src/draganddrop/indexTrello';
import ProjectsWeb from '../../blocks/ProjectPortfolio/src/Projects.web';
import ProjectPortfolioWeb from '../../blocks/ProjectPortfolio/src/ProjectPortfolio.web';
import HomePageWeb from '../../components/src/HomePage.web';
import SearchResults from '../../components/src/SearchResults.web'
import NewTemplates from '../../blocks/AdminConsole/src/NewTemplates.web';
import NewBrand from '../../blocks/AdminConsole/src/NewBrand.web';
import Users from '../../blocks/AdminConsole/src/Users.web';
import TermsAndConditions from '../../components/src/TermsAndConditions.web';
import PrivacyPolicy from '../../components/src/PrivacyPolicy.web';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
const HUBSPOTID = '6141202';

const routeMap = {
  GSuiteIntegration: {
    component: GSuiteIntegration,
    path: '/GSuiteIntegration'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  NewTemplates: {
    component: NewTemplates,
    path: '/NewTemplate'
  },
  Annotations: {
    component: Annotations,
    path: '/Annotations'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLogin'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Translation: {
    component: Translation,
    path: '/Translation'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  HubspotIntegration: {
    component: HubspotIntegration,
    path: '/HubspotIntegration'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  PersonalEmailAccountRegistrationWeb : {
    component : PersonalEmailAccountRegistrationWeb,
    path: '/customRegistration',
    exact : true
  },
  HelpCentre: {
    component: HelpWeb,
    path: '/HelpCentre'
  },
  Comments: {
    component: Comments,
    path: '/Comments'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  UploadMedia: {
    component: UploadMedia,
    path: '/UploadMedia'
  },
  // TeamBuilder:{
  //  component:TeamBuilder,
  // path:"/TeamBuilder"},
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/ProjecttaskTracking'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  CustomiseInterface: {
    component: CustomiseInterface,
    path: '/CustomiseInterface'
  },
  ServicespecificSettingsadmin: {
    component: ServicespecificSettingsadmin,
    path: '/ServicespecificSettingsadmin'
  },
  ImportPhotoshopDocumentspsd: {
    component: ImportPhotoshopDocumentspsd,
    path: '/ImportPhotoshopDocumentspsd'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  EmailVerfication: {
    component: EmailVerfication,
    path: '/EmailVerfication'
  },
  EmailConfirmation: {
    component: EmailConfirmation,
    path: '/EmailConfirmation'
  },
  EmailAccountActivation: {
    component: EmailAccountActivation,
    path: '/EmailAccountActivation'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: '/MultilevelApproval'
  },

  Home: {
    component: HomeScreen,
    path: '/Home'
    // exact: true
  },

  ProjectsWeb: {
    component: ProjectsWeb,
    path: '/Home/Project',
    exact: true
  },
  // Brands: {
  //   component: Brands,
  //   path: '/Home/Brands',
  //   exact: true
  // },
  // BrandAssets : {
  //   component: BrandAssets,
  //   path: '/Home/BrandAssets/:brandId',
  //   // exact: true
  // },
  // MediaAssets : {
  //   component: MediaAssets,
  //   path: '/Home/MediaAssets/:brandId',
  // },

  AccountProfile: {
    component: AccountProfileWeb,
    path: '/AccountProfile',
    exact: true
  },
  CompanyProfile: {
    component: CompanyProfile,
    path: '/CompanyProfile',
    exact: true
  },
  HomePage: {
    component: HomePageWeb,
    path: 'Home/HomePage',
    exact: true
  },
  
  // SearchResults: {
  //   component: SearchResults,
  //   path: '/Home/SearchResults',
  //   exact: true
  // },
  //    CGI:{
  // component:ProjectPortfolio,
  //   path:"/Home/Projects/Projectportfolio",
  //   exact:true

  //    },

  Trello: {
    component: Trello,
    path: '/TrelloBoard',
    exact: true
  },
  indexTrello: {
    component: indexTrello,
    path: '/Trello',
    exact: true
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  NewBrand: {
    component: NewBrand,
    path: '/NewBrand'
  },
  TERMSANDCONDITIONS: {
    component: TermsAndConditions,
    path: '/terms-and-conditions'
  },
  PRIVACY: {
    component: PrivacyPolicy,
    path: '/privacy-policy'
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
  }

  componentDidMount() {
    if(localStorage.getItem("lastLoginDate")){
      if(localStorage.getItem("lastLoginDate") == new Date().getDate()){
        if (localStorage.getItem("token") || (window.location.href).includes("NewPassword") ||
         (window.location.href).includes("terms-and-conditions")
        || (window.location.href).includes("privacy-policy") ||  (window.location.href).includes("customRegistration")) {
        } else {
          this.props.history.push({
            pathname: `/EmailAccountLogin`
          });
        }
      }
      else{
        localStorage.clear()
        this.props.history.push({
          pathname: `/EmailAccountLogin`
        });
      }
    }
    else{
      localStorage.setItem("lastLoginDate", new Date().getDate())
      if((window.location.href).includes("EmailAccountActivation")) {

      }
      else {
        this.props.history.push({
          pathname: `/EmailAccountLogin`
        });
      }
    }

    
    let script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${HUBSPOTID}.js`;
    script.async = true;
    document.body.appendChild(script);
    // window.hsConversationsSettings = {
    //   loadImmediately: false
    // };
    if(localStorage.getItem("hubSpotIdentificationToken") !== null &&
    localStorage.getItem("hubSpotIdentificationToken") !== "" ){
      window.hsConversationsSettings = {
        identificationEmail: JSON.parse(localStorage.getItem("userInfo")).email,
        identificationToken: localStorage.getItem("hubSpotIdentificationToken")
      };
      if (window.HubSpotConversations) {
        console.log('The api is ready already');
      } else {
        window.hsConversationsOnReady = [
          () => {
            console.log(`HubSpot Conversations API:${window.HubSpotConversations}`)
            window.HubSpotConversations.widget.load();
          },
        ];
      }
    }
    if(window.screen.availWidth < 1024){
      this.setState({
        showPopup: true
      });
    }
  }
  closePopUp = () => {
    this.setState({ showPopup: false });
  }
  render() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    let role = userInfo?.role?.data?.attributes?.name;

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      //  <EmailAccountLoginBlock/>
      // <EmailAccountRegistration/>
      <>
      {localStorage.getItem("toShowOnboarding") == "true"  &&  role !== 'superadmin' &&
        <div className="disableBackgroundScreen"></div>
      }
      <div>
        {WebRoutesGenerator({ routeMap })}
        <ToastContainer position="bottom-right" />
        <Dialog
          open={this.state.showPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ textAlign: "right", paddingBottom: 0 }}>
            <span>
              <CloseIcon style={{ cursor: "pointer" }} onClick={this.closePopUp} />
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontSize: "18px" }}>
                <b>Design Harvest is not yet optimized for mobile devices.<br/>
                Please use your desktop computer to access Design Harvest for the time being.</b>
           
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      </>
    );
  }
}

export default withRouter(App);
