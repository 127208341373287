
import React, { Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
// import InputAdornment from '@material-ui/icons/InputAdornment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
const defaultUser = require("../../../components/src/defaultuser.png")
import _ from "lodash";
// import '.././../../components/src/styles/styles.css'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
// Customizable Area Start
// Customizable Area End
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { TableContainer, TableHead, TableRow, TableBody, Table, TableCell, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText } from '@material-ui/core'
import BrandsController, {
  Props,
  configJSON
} from "./BrandsController";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from "@material-ui/core/Card";


import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";

import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

import "../../../components/src/styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import '../assets/styles.css'
const inviteLogo = require("../../../components/src/svgFiles/group-8.svg");
const duplicateLogo = require("../../../components/src/svgFiles/group-14.svg")
const csvExportLogo = require('../../../components/src/svgFiles/group-6.svg')
const archieveLogo = require("../../../components/src/svgFiles/group-5.svg");
const notificationLogo = require("../../../components/src/svgFiles/group-8 (1).svg");
const logo = require("../../../components/src/volkswagen.svg")
const downIcon = require('../../../components/src/down.png');
const filterButton = require("../../../components/src/filter.png");

import Backdrop from "@material-ui/core/Backdrop";

import CircularProgress from "@material-ui/core/CircularProgress";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    padding: "24px 13px 30px 28px",
    borderRadius: "8px",
    backgroundColor: "#f5f5f9",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tooltip);


const data = [
  {
    id: 1,
    name: "BMW"
  },
  {
    id: 2,
    name: "BMW"
  },
  {
    id: 3,
    name: "BMW"
  },
  {
    id: 4,
    name: "BMW"
  }

]


const styles = {
  root: {

  },
  button: {

    backgroundColor: "white",
    // border: "1px solid #e8edf4",
    borderRight: "1px solid #e8edf4",
    borderBottom: "1px solid #e8edf4",
    borderTop: "1px solid #e8edf4",
    borderLeft: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px"
  },
  activeButton: {
    backgroundColor: "white",
    // border: "1px solid #1a76b0",
    borderRight: "1px solid #1a76b0 !important",
    borderBottom: "1px solid #1a76b0",
    borderTop: "1px solid #1a76b0",
    borderLeft: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px"
  },
  buttonGroup: {
    backgroundColor: "#e8edf4",
    border: "1px solid #e8edf4",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  activeButtonList: {
    backgroundColor: "#e8edf4",
    border: "1px solid #1a76b0",
    boxShadow: "none",
    borderRadius: "8px",
    marginLeft: "0px !important"
  },
  inputSerachBox: {
    '&::before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:hover': {
      // borderBottom:'none'
    },
    '&::after': {
      borderBottom: 'none'

    },
    avatar: {
      '&:MuiAvatar-root': {
        width: '30px !important',
        height: "30px !important"
      }
    },
    //     '.MuiAvatar-root .MuiAvatar-circle .MuiAvatarGroup-avatar .MuiAvatar-colorDefault':{
    // width:'30px !important',
    // height:"30px !important"
    //     },
    border: '1px solid #e8edf4',
    background: '#ffffff',
    borderBottom: '1px solid #e8edf4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    outline: "none",
    // underline:"none"
  },
  accordianStyle: {
    boxShadow: "none",
    border: "none",
    marginTop: "30px"
  },

  actionCard: {
    backgroundColor: "#e4e8f0",
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: "14px"
  },
  cardStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  title: {
    color: "#282828",
    fontSize: "16px"
  },
  dropDown: {
    fontSize: "16px"
  },
  confirmInput: {
    marginTop: "15px",
  },
  inputRoot: {
    // fontSize: 56
    height: 56
  },
  labelRoot: {
    fontSize: 15,
    color: "black",

  },
  dropDowncircle: {
    // marginTop: "7px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    marginLeft: "20px",
    border: "1px solid #e4e8f0",
    background: 'white',
    borderRadius: '50%',
    width: ' 25px',
    height: '25px',
    display: 'flex', /* or inline-flex */
    alignItems: 'center',
    justifyContent: 'center'
  },
  projectChips: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    justifyItems: "center"
  },
  cardFirstStyle: {
    borderRadius: "8px",
    border: "1px solid #e4e8f0",
    marginLeft: "0px",
    // padding:"0px"
  },
  tableData: {
    fontFamily: "Work Sans",
    fontSize: "12px",
    color: "#1A1A1A",
    opacity: "100%",
    textTransform: "capitalize",
    letterSpacing: 0,
  },
  accordionSummaryRoot: {
    position: "inherit !important"
  },
  popupIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    backgroundColor: "#282828",
  },

  popupTitle: {
    width: "55px",
    height: "19px",
    margin: "5px 29px 16px 14px",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.12px",
    color: "#282828",
  },

  popupCancelIcon: {
    position: "absolute",
    right: "15px",
    top: "25px"
  },

  popupText: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.1px",
    color: "#282828",
  },

  popupSkipButton: {
    minWidth: "72px",
    width: "auto",
    height: "40px",
    margin: "10px 23px -5px 0px",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#173e59",
  },

  popupButton: {
    color: "#ffffff",
    width: "72px",
    height: "32px",
    margin: "13px 10px 0",
    padding: "8px 8px 8px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    backgroundColor: "#173e59",
  },
  highlightText: {
    padding: "5px 5px 5px 10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px 0 rgba(46, 91, 255, 0.04)",
    border: "solid 1px #e8edf4",
    backgroundColor: "#f5f5f9",
    zIndex: "9999"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  brandTitle: {
    width: "100px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  dot: {
    float: "left",
    marginLeft: "-12px !important",
    marginTop: "-12px !important",
  },
  listProjects: {
    width: "150px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  listDate: {
    fontSize: "12px",
    margin: "8px 0px 0px 5px"
  },
  listDot: {
    float: "left",
    marginLeft: "-15px !important",
    position: "relative",
    marginTop: "19px"
  }
} as any
class Brands extends BrandsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t } = this.props
    let colorToBeChanged: any;
    let secondaryColorToBeChanged: any;

    let primaryFont: any;
    let SecondaryFont: any

    const storageColor = localStorage.getItem("key");
    const storageSecondary = localStorage.getItem("secondarykey");

    if (storageColor) {
      colorToBeChanged = JSON.parse(storageColor).hex;
      primaryFont = JSON.parse(storageColor).font;
    } else {
      colorToBeChanged = "#173e59";
      primaryFont = "Work Sans"
    }
    if (storageSecondary) {
      secondaryColorToBeChanged = JSON.parse(storageSecondary).hex;
      SecondaryFont = JSON.parse(storageSecondary).font;
    } else {
      secondaryColorToBeChanged = "#173e59";
      SecondaryFont = "Work Sans"
    }

    return (
      <div style={{ marginLeft: "45px", fontFamily: primaryFont }}>
        <Backdrop
          className={classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={0} >
          <Grid item xs={4}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "12px" }}>
              <Link href="/Home/HomePage" style={{ color: secondaryColorToBeChanged, fontSize: "14px", fontFamily: SecondaryFont }}>
                {t("HomeScreen.home")}
              </Link>
              <Link href="/Home/Brands" style={{ color: '#737373', fontSize: "14px", fontFamily: SecondaryFont }}>
                {t("HomeScreen.brand")}
              </Link>
            </Breadcrumbs>

            <div style={{ marginLeft: "10px", display: "flex" }}>
              <HtmlTooltip
                arrow
                placement="right"
                open={localStorage.getItem("onBoardingStep") == "10" ? true : false}
                title={
                  <React.Fragment>
                    <div>
                      <span className={classes.popupTitle}> {t("AdminConsole.brands")}</span>
                      <span className={classes.popupCancelIcon}>
                        <CloseIcon onClick={this.handleSkipOnboarding} />
                      </span>
                    </div>
                    <div className={classes.popupText}>
                      {t("onBoarding.brandText")}
                    </div>
                    <div>
                      <Button
                        className={classes.popupSkipButton}
                        onClick={this.handleSkipOnboarding}
                        color={colorToBeChanged}
                      >
                        SKIP
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handlePrevOnboarding}
                        style={{ background: colorToBeChanged }}
                      >
                        PREV
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handleNextOnboarding}
                        style={{ background: colorToBeChanged }}
                      >
                        NEXT
                      </Button>
                    </div>
                  </React.Fragment>
                }
              >
                <p
                  style={{ fontSize: "32px", margin: "0", fontFamily: SecondaryFont }}
                  className={localStorage.getItem("onBoardingStep") == "10" && classes.highlightText}
                >
                  {t("HomeScreen.brand")}
                </p>
              </HtmlTooltip>
              {localStorage.getItem("toShowOnboarding") === "true" ?
                (
                  <HtmlTooltip
                    arrow
                    placement="right"
                    open={localStorage.getItem("onBoardingStep") == "5" ? true : false}
                    title={
                      <React.Fragment>
                        <div>
                          <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373" }} />
                          <span className={classes.popupTitle}>Information</span>
                          <span className={classes.popupCancelIcon}>
                            <CloseIcon onClick={this.handleSkipOnboarding} />
                          </span>
                        </div>
                        <div className={classes.popupText}>
                          {t("onBoarding.informationText")}
                        </div>
                        <div>
                          <Button
                            className={classes.popupSkipButton}
                            onClick={this.handleSkipOnboarding}
                            color={colorToBeChanged}
                          >
                            SKIP
                          </Button>
                          <Button
                            className={classes.popupButton}
                            onClick={this.handleNextOnboarding}
                            style={{ background: colorToBeChanged }}
                          >
                            NEXT
                          </Button>
                        </div>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      style={{ height: "20px", width: "20px", color: "#737373", marginTop: "7px" }}
                      className={localStorage.getItem("onBoardingStep") == "5" && classes.highlightText}>
                    </InfoOutlinedIcon>
                  </HtmlTooltip>
                )
                :
                (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{t("projects.infoBrand")}</Typography>
                        {/* <p>Projects grouped brands.</p> */}
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <InfoOutlinedIcon style={{ height: "20px", width: "20px", color: "#737373", marginTop: "7px" }} >

                    </InfoOutlinedIcon>
                  </HtmlTooltip>

                )}
            </div>









          </Grid>
          <Grid item xs={8} style={{ textAlign: "right" }}>
            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "6" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <ImportExportIcon style={{ color: "black" }} />
                    <span className={classes.popupTitle}>Filters</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.filterText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                      color={colorToBeChanged}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >

              <span></span>
              {/* <Button
                variant="contained"
                style={{ color: secondaryColorToBeChanged, marginBottom: "10px", textTransform: "none", fontFamily: SecondaryFont }}
                className={classes.button + ' ' + (localStorage.getItem("onBoardingStep") == "6" && classes.highlightText)}
                startIcon={<ImportExportIcon style={{ color: "black" }} />}
                onClick={(event) => this.setState({ anchorElFilter: event.target })}

              >
                Filters
              </Button> */}
            </HtmlTooltip>

            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "7" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <AppsOutlinedIcon />
                    <span className={classes.popupTitle}>{t("HomeScreen.grid")}</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.gridText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                      color={colorToBeChanged}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >
              <ButtonGroup
                aria-label="outlined primary button group"
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={localStorage.getItem("onBoardingStep") == "7" && classes.highlightText}
              >
                <Button
                  variant="contained"
                  style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                  className={this.state.grid ? classes.activeButton : classes.button}
                  onClick={e => { this.changeToGridView(e) }}

                  // startIcon={<ImportExportIcon style={{ color: "black" }} />}
                  startIcon={<AppsOutlinedIcon />}
                >
                  {this.state.grid &&
                    <>{t("HomeScreen.grid")}</>
                  }
                </Button>
                <Button
                  variant="contained"
                  style={{ color: secondaryColorToBeChanged, textTransform: "none", fontFamily: SecondaryFont }}
                  className={this.state.list ? classes.activeButtonList : classes.buttonGroup}
                  onClick={e => { this.changeToListView(e); this.setState({ isSearch: false, searchBrandName: "" }) }}
                  endIcon={<ListOutlinedIcon />}

                >
                  <span >  {this.state.list &&
                    <>{t("HomeScreen.list")}</>
                  }</span>
                </Button>

              </ButtonGroup>
            </HtmlTooltip>

            <HtmlTooltip
              arrow
              placement="left"
              open={localStorage.getItem("onBoardingStep") == "8" ? true : false}
              title={
                <React.Fragment>
                  <div>
                    <SearchIcon />
                    <span className={classes.popupTitle}>Search</span>
                    <span className={classes.popupCancelIcon}>
                      <CloseIcon onClick={this.handleSkipOnboarding} />
                    </span>
                  </div>
                  <div className={classes.popupText}>
                    {t("onBoarding.searchText")}
                  </div>
                  <div>
                    <Button
                      className={classes.popupSkipButton}
                      onClick={this.handleSkipOnboarding}
                      color={colorToBeChanged}
                    >
                      SKIP
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handlePrevOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      PREV
                    </Button>
                    <Button
                      className={classes.popupButton}
                      onClick={this.handleNextOnboarding}
                      style={{ background: colorToBeChanged }}
                    >
                      NEXT
                    </Button>
                  </div>
                </React.Fragment>
              }
            >

              <Input
                id="input-with-icon-adornment"
                className={classes.inputSerachBox + ' ' + (localStorage.getItem("onBoardingStep") == "8" && classes.highlightText)}
                startAdornment={
                  <InputAdornment position="start" >
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={this.handleSearchChange}
                value={this.state.searchBrandName}
                placeholder="search"
              />
            </HtmlTooltip>

            {this.state.permissions !== "viewer" && (
              <HtmlTooltip
                arrow
                placement="left"
                open={localStorage.getItem("onBoardingStep") == "9" ? true : false}
                title={
                  <React.Fragment>
                    <div>
                      <AppsOutlinedIcon />
                      <span className={classes.popupTitle}>{t("onBoarding.createProject")}</span>
                      <span className={classes.popupCancelIcon}>
                        <CloseIcon onClick={this.handleSkipOnboarding} />
                      </span>
                    </div>
                    <div className={classes.popupText}>
                      {t("onBoarding.createProjectText")}
                    </div>
                    <div>
                      <Button
                        className={classes.popupSkipButton}
                        onClick={this.handleSkipOnboarding}
                        color={colorToBeChanged}
                      >
                        SKIP
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handlePrevOnboarding}
                        style={{ background: colorToBeChanged }}
                      >
                        PREV
                      </Button>
                      <Button
                        className={classes.popupButton}
                        onClick={this.handleNextOnboarding}
                        style={{ background: colorToBeChanged }}
                      >
                        NEXT
                      </Button>
                    </div>
                  </React.Fragment>
                }
              >
                <Button
                  variant="contained"
                  className={classes.button + ' ' + (localStorage.getItem("onBoardingStep") == "9" && classes.highlightText)}
                  size="medium"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    backgroundColor: colorToBeChanged,
                    color: "white",
                    marginBottom: "10px",
                  }}
                  onClick={this.handleClickOpenModal}
                >
                  {t("projects.createProject")}
                </Button>
              </HtmlTooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: "10px" }}>
              <Typography style={{ textAlign: "right", right: "25px" }}>
                <div>
                  <Button
                    variant="contained"
                    style={{ color: secondaryColorToBeChanged, textTransform: 'none', fontFamily: SecondaryFont }}
                    className={classes.button}
                    startIcon={
                      <img src={filterButton} aria-controls="panel1a-content"
                        id="panel1a-header" />
                    }
                    endIcon={
                      <ExpandMoreIcon style={{ color: "black" }} />
                    }
                    onClick={(event) => this.setState({ anchorElFilter: event.target })}
                  >
                    {this.state.brandFilterValue == 'name0' ? 'Name (A-Z)'
                      : this.state.brandFilterValue == "name1" ? 'Name (Z-A)'
                        : this.state.brandFilterValue == 'created_at1' ? 'Created At' : 'Updated At'}
                  </Button>
                </div>
              </Typography>
            </div>
          </Grid>
          {this.state.brandsData && this.state.brandsData.length > 0 ?
            <>
              {this.state.grid &&

                (
                  <>
                    <Grid item xs={12}>
                      <div style={{ marginTop: "10px" }}>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <Grid container spacing={0} alignItems="stretch">
                            {this.state.brandsData?.map((item: any, index: any) => {
                              if (!(localStorage.getItem("projectType") == "Archive Projects" || localStorage.getItem("projectType") == "Deleted Projects") || !localStorage.getItem("projectType")) {
                                return (
                                  <Grid item xs={3} key={index}>

                                    <Box m={1}>
                                      <Card className={classes.cardStyle} onClick={(e: any) => {
                                        this.setState({ selectData: item, selectedBrandId: item.id }, () => {
                                          localStorage.removeItem('brandGroupSelected');
                                          this.getProjectByBrandID()
                                        })
                                      }}>
                                        <CardContent style={{ paddingBottom: "16px" }}  >
                                          {this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? <div style={{
                                            height: 8,
                                            width: 8,
                                            backgroundColor: "red",
                                            borderRadius: "50%",
                                            // marginTop: "5px",
                                            marginLeft: "5px"
                                          }}
                                            className={classes.dot}
                                          ></div> : null}
                                          <a>
                                            <Typography className={classes.title} gutterBottom component="div">
                                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                  {item?.attributes?.logo_blob_signed_id_url ?
                                                    <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                    :
                                                    <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                  }
                                                  <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "300px" }} >{item?.attributes?.name}</p> </span>
                                                {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                    <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="listing_branding"
                                                      aria-haspopup="true"
                                                      onClick={(e: any) => { this.handleClickMenu(e); this.setState({ brandDeleteId: parseInt(item.id) }) }}
                                                    />
  
  
                                                  </IconButton> */}


                                              </div>


                                            </Typography>
                                            {/* Active projects */}
                                            {!(localStorage.getItem("projectType") == "Archive Projects" || localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.projects?.map((projectName: any, index: any) => (
                                              <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  this.setProjectData(projectName, item)
                                                }}>
                                                {projectName?.name}
                                              </p>
                                            )))
                                            }

                                            {/* Archive projects */}
                                            {(localStorage.getItem("projectType") == "Archive Projects") && (item?.attributes?.archived_projects?.map((projectName: any, index: any) => (
                                              <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                              // onClick={(e) => {
                                              //   e.stopPropagation()
                                              //   // this.setProjectData(projectName, item)
                                              // }}
                                              >
                                                {projectName?.name}
                                              </p>
                                            )))
                                            }
                                            {/* Deleted projects */}
                                            {(localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.deleted_projects?.map((projectName: any, index: any) => (
                                              <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                              // onClick={(e) => {
                                              //   e.stopPropagation()
                                              //   // this.setProjectData(projectName, item)
                                              // }}
                                              >
                                                {projectName?.name}
                                              </p>
                                            )))
                                            }




                                            {/* <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p> */}
                                          </a>

                                        </CardContent>

                                        {/* </NavLink> */}
                                        {/* </a> */}


                                        <CardActions className={classes.actionCard} >
                                          <p style={{ fontSize: "10px", display: "inline-block", width: "80px" }}>
                                            {" "}
                                            {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                                              item.id == val.id && (
                                                val.DMY + " " + t("ApprovalRequest.lastModified")
                                              )
                                            ))}
                                          </p>
                                        </CardActions>
                                      </Card>
                                    </Box>
                                  </Grid>
                                )
                              }
                              else if (localStorage.getItem("projectType") == "Archive Projects") {
                                if (item?.attributes?.archived_projects?.length) {
                                  return (
                                    <Grid item xs={3} key={index}>

                                      <Box m={1}>
                                        <Card className={classes.cardStyle} onClick={(e: any) => {
                                          this.setState({ selectData: item, selectedBrandId: item.id }, () => {
                                            localStorage.removeItem('brandGroupSelected');
                                            this.getProjectByBrandID()
                                          })
                                        }}>
                                          <CardContent style={{ paddingBottom: "16px" }}  >
                                            {this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? <div style={{
                                              height: 8,
                                              width: 8,
                                              backgroundColor: "red",
                                              borderRadius: "50%",
                                              // marginTop: "5px",
                                              marginLeft: "5px"
                                            }}
                                              className={classes.dot}
                                            ></div> : null}
                                            <a>
                                              <Typography className={classes.title} gutterBottom component="div">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                  <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                    {item?.attributes?.logo_blob_signed_id_url ?
                                                      <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                      :
                                                      <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                    }
                                                    <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "300px" }} >{item?.attributes?.name}</p> </span>
                                                  {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                      <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="listing_branding"
                                                        aria-haspopup="true"
                                                        onClick={(e: any) => { this.handleClickMenu(e); this.setState({ brandDeleteId: parseInt(item.id) }) }}
                                                      />
    
    
                                                    </IconButton> */}


                                                </div>


                                              </Typography>
                                              {/* Active projects */}
                                              {/* { !(localStorage.getItem("projectType") == "Archive Projects" || localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setProjectData(projectName, item)
                                                  }}>
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              } */}

                                              {/* Archive projects */}
                                              {(localStorage.getItem("projectType") == "Archive Projects") && (item?.attributes?.archived_projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                // onClick={(e) => {
                                                //   e.stopPropagation()
                                                //   // this.setProjectData(projectName, item)
                                                // }}
                                                >
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              }
                                              {/* Deleted projects */}
                                              {(localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.deleted_projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                // onClick={(e) => {
                                                //   e.stopPropagation()
                                                //   // this.setProjectData(projectName, item)
                                                // }}
                                                >
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              }




                                              {/* <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p> */}
                                            </a>

                                          </CardContent>

                                          {/* </NavLink> */}
                                          {/* </a> */}


                                          <CardActions className={classes.actionCard} >
                                            <p style={{ fontSize: "10px", display: "inline-block", width: "80px" }}>
                                              {" "}
                                              {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                                                item.id == val.id && (
                                                  val.DMY + " " + t("ApprovalRequest.lastModified")
                                                )
                                              ))}
                                            </p>
                                          </CardActions>
                                        </Card>
                                      </Box>
                                    </Grid>
                                  )
                                }

                              }
                              else if (localStorage.getItem("projectType") == "Deleted Projects") {
                                if (item?.attributes?.deleted_projects?.length) {
                                  return (
                                    <Grid item xs={3} key={index}>

                                      <Box m={1}>
                                        <Card className={classes.cardStyle} onClick={(e: any) => {
                                          this.setState({ selectData: item, selectedBrandId: item.id }, () => {
                                            localStorage.removeItem('brandGroupSelected');
                                            this.getProjectByBrandID()
                                          })
                                        }}>
                                          <CardContent style={{ paddingBottom: "16px" }}  >
                                            {this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? <div style={{
                                              height: 8,
                                              width: 8,
                                              backgroundColor: "red",
                                              borderRadius: "50%",
                                              // marginTop: "5px",
                                              marginLeft: "5px"
                                            }}
                                              className={classes.dot}
                                            ></div> : null}
                                            <a>
                                              <Typography className={classes.title} gutterBottom component="div">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                  <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                    {item?.attributes?.logo_blob_signed_id_url ?
                                                      <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                                      :
                                                      <Avatar style={{ width: "35px", height: "35px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                    }
                                                    <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "300px" }} >{item?.attributes?.name}</p> </span>
                                                  {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                      <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="listing_branding"
                                                        aria-haspopup="true"
                                                        onClick={(e: any) => { this.handleClickMenu(e); this.setState({ brandDeleteId: parseInt(item.id) }) }}
                                                      />
    
    
                                                    </IconButton> */}


                                                </div>


                                              </Typography>
                                              {/* Active projects */}
                                              {/* { !(localStorage.getItem("projectType") == "Archive Projects" || localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setProjectData(projectName, item)
                                                  }}>
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              } */}

                                              {/* Archive projects */}
                                              {(localStorage.getItem("projectType") == "Archive Projects") && (item?.attributes?.archived_projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                // onClick={(e) => {
                                                //   e.stopPropagation()
                                                //   // this.setProjectData(projectName, item)
                                                // }}
                                                >
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              }
                                              {/* Deleted projects */}
                                              {(localStorage.getItem("projectType") == "Deleted Projects") && (item?.attributes?.deleted_projects?.map((projectName: any, index: any) => (
                                                <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                                // onClick={(e) => {
                                                //   e.stopPropagation()
                                                //   // this.setProjectData(projectName, item)
                                                // }}
                                                >
                                                  {projectName?.name}
                                                </p>
                                              )))
                                              }




                                              {/* <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p> */}
                                            </a>

                                          </CardContent>

                                          {/* </NavLink> */}
                                          {/* </a> */}


                                          <CardActions className={classes.actionCard} >
                                            <p style={{ fontSize: "10px", display: "inline-block", width: "80px" }}>
                                              {" "}
                                              {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                                                item.id == val.id && (
                                                  val.DMY + " " + t("ApprovalRequest.lastModified")
                                                )
                                              ))}
                                            </p>
                                          </CardActions>
                                        </Card>
                                      </Box>
                                    </Grid>
                                  )
                                }

                              }

                            })
                            }
                            {this.state.brandGroupList?.map((item: any, index: any) => {
                              return (
                                <Grid item xs={3} key={index}>

                                  <Box m={1}>
                                    <Card className={classes.cardStyle} onClick={(e: any) => {
                                      this.setState({
                                        selectData: item,
                                        //  selectedBrandId: item.id
                                      }, () => { this.getBrandGroupsById() })
                                    }}>
                                      <CardContent style={{ paddingBottom: "16px" }}  >
                                        {/* {this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? <div style={{
                                          height: 8,
                                          width: 8,
                                          backgroundColor: "red",
                                          borderRadius: "50%",
                                          // marginTop: "5px",
                                          marginLeft: "5px"
                                        }}
                                          className={classes.dot}
                                        ></div> : null} */}
                                        <a>
                                          <Typography className={classes.title} gutterBottom component="div">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <span style={{ fontSize: "16px", display: "flex", flexDirection: "row" }}>
                                                {item?.attributes?.logo_blob_signed_id_url ?
                                                  <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px" }}

                                                    onClick={(e) => { this.handleClickProjects(e); }}
                                                  />
                                                  :
                                                  <Avatar style={{ width: "35px", height: "35px" }}
                                                    onClick={(e) => { this.handleClickProjects(e); }}
                                                  >{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                                }
                                                <p style={{ marginTop: "6px", marginLeft: "7px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "300px" }} >{item?.attributes?.name}</p> </span>
                                              {/* <IconButton aria-label="settings" style={{ color: "black", marginTop: "-17px" }}>
                                                  <MoreVertIcon style={{ color: "black", fontSize: "16px" }} aria-controls="listing_branding"
                                                    aria-haspopup="true"
                                                    onClick={(e: any) => { this.handleClickMenu(e); this.setState({ brandDeleteId: parseInt(item.id) }) }}
                                                  />


                                                </IconButton> */}


                                            </div>


                                          </Typography>
                                          {/* 
                                          {item?.attributes?.projects?.map((projectName: any, index: any) => (
                                            <p key={index} className={classes.brandTitle} style={{ fontSize: "12px", color: secondaryColorToBeChanged }}
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                this.setProjectData(projectName, item)
                                              }}>
                                              {projectName?.name}
                                            </p>
                                          ))
                                          } */}


                                          {/* <p style={{ fontSize: "12px", color: secondaryColorToBeChanged }}>Quarterly Event</p> */}
                                        </a>

                                      </CardContent>

                                      {/* </NavLink> */}
                                      {/* </a> */}


                                      <CardActions className={classes.actionCard} >
                                        <p style={{ fontSize: "10px", display: "inline-block", width: "80px" }}>
                                          {" "}
                                          {this.state.brandUpdatedCompleteDate?.map((val: any) => (
                                            item.id == val.id && (
                                              val.DMY + " " + t("ApprovalRequest.lastModified")
                                            )
                                          ))}
                                        </p>
                                      </CardActions>
                                    </Card>
                                  </Box>
                                </Grid>
                              )
                            })
                            }
                          </Grid>
                          {/* <Grid item xs={12}>
                            <Grid container direction="row">
                              <Grid item xs={9} />
                              <Grid item xs={3}>
                                <Pagination
                                  count={this.state.total_pages}
                                  variant="outlined"
                                  color="primary"
                                  page={this.state.setPage}
                                  onChange={this.handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid> */}


                          <Grid container spacing={0} alignItems="stretch">
                            <Grid item xs={12}>

                            </Grid>
                          </Grid>

                        </Grid>




                      </div></Grid>
                  </>
                )

              }

              {this.state.list &&
                (
                  <>
                    <Grid item xs={12}>
                      {

                        this.state.brandsData?.map((item: any, index: any) => {
                          return (
                            <div style={{
                              width: "95%",
                              borderRadius: "8px",
                              borderLeft: `6px solid ${secondaryColorToBeChanged}`,
                              borderRight: "1px solid #e4e8f0",
                              borderTop: "1px solid #e4e8f0",
                              borderBottom: "1px solid #e4e8f0",
                              marginTop: "20px",
                              cursor: 'pointer'
                            }}
                              onClick={(e: any) => { this.setState({ selectData: item, selectedBrandId: item.id }, () => { this.getProjectByBrandID() }) }}
                            >
                              <div style={{ margin: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <div style={{ display: "flex" }}>
                                    <Fragment>

                                      <div className={classes.listDot} style={{
                                        height: 8,
                                        width: 8,
                                        backgroundColor: this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? "red" : "white",
                                        borderRadius: "50%",
                                        // marginTop: "5px",
                                        marginLeft: "5px"
                                      }} />

                                      {item?.attributes?.logo_blob_signed_id_url ?
                                        <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px", marginLeft: "10px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                        :
                                        <Avatar style={{ width: "35px", height: "35px", marginLeft: "10px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                      }
                                      <p style={{ marginTop: "10px", marginLeft: "20px" }}>{item.attributes.name}</p>
                                    </Fragment>
                                  </div>

                                  <div style={{ display: "flex" }}>
                                    <div className={classes.circle} >
                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                    </div>
                                    {this.state.brandUpdatedCompleteDate?.map((val: any, i: any) => {
                                      if (index == i) {
                                        return (
                                          <p className={classes.listDate}>
                                            {item.id == val.id && (
                                              val.DMY + " " + t("ApprovalRequest.lastModified")
                                            )}
                                          </p>
                                        )
                                      }
                                    })}
                                  </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>

                                  {/* Active projects */}
                                  {!(localStorage.getItem("projectType") == "Archive Projects" || localStorage.getItem("projectType") == "Deleted Projects") && (item.attributes?.projects.map((project: any) => (
                                    <p className={classes.listProjects} style={{ fontSize: "12px", paddingLeft: "25px" }}>{project.name}</p>
                                  )))}

                                  {/* Archive projects */}
                                  {(localStorage.getItem("projectType") == "Archive Projects") && (item.attributes?.archived_projects.map((project: any) => (
                                    <p className={classes.listProjects} style={{ fontSize: "12px", paddingLeft: "25px" }}>{project.name}</p>
                                  )))}

                                  {/* Deleted projects */}
                                  {(localStorage.getItem("projectType") == "Deleted Projects") && (item.attributes?.deleted_projects.map((project: any) => (
                                    <p className={classes.listProjects} style={{ fontSize: "12px", paddingLeft: "25px" }}>{project.name}</p>
                                  )))}

                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                      {

                        this.state.brandGroupList?.map((item: any, index: any) => {
                          return (
                            <div style={{
                              width: "95%",
                              borderRadius: "8px",
                              borderLeft: `6px solid ${secondaryColorToBeChanged}`,
                              borderRight: "1px solid #e4e8f0",
                              borderTop: "1px solid #e4e8f0",
                              borderBottom: "1px solid #e4e8f0",
                              marginTop: "20px",
                              cursor: 'pointer'
                            }}
                              onClick={(e: any) => {
                                this.setState({
                                  selectData: item,
                                  // selectedBrandId: item.id
                                }, () => { this.getBrandGroupsById() })
                              }}
                            >
                              <div style={{ margin: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <div style={{ display: "flex" }}>
                                    <Fragment>

                                      {/* <div className = {classes.listDot} style={{
                    height: 8,
                    width: 8,
                    backgroundColor: this.state.notify?.brand_ids?.includes(parseInt(item.id)) ? "red" : "white",
                    borderRadius: "50%",
                    // marginTop: "5px",
                    marginLeft: "5px"
                  }} /> */}

                                      {item?.attributes?.logo_blob_signed_id_url ?
                                        <img src={item?.attributes?.logo_blob_signed_id_url} style={{ width: "35px", height: "35px", marginLeft: "10px" }} onClick={(e) => { this.handleClickProjects(e); }} />
                                        :
                                        <Avatar style={{ width: "35px", height: "35px", marginLeft: "10px" }} onClick={(e) => { this.handleClickProjects(e); }}>{item.attributes.name.match(/\b(\w)/g).join('')}</Avatar>
                                      }
                                      <p style={{ marginTop: "10px", marginLeft: "20px" }}>{item.attributes.name}</p>
                                    </Fragment>
                                  </div>

                                  <div style={{ display: "flex" }}>
                                    <div className={classes.circle} >
                                      <p><CalendarTodayOutlinedIcon style={{ fontSize: "12px" }} /></p>
                                    </div>
                                    {/* {this.state.brandUpdatedCompleteDate?.map((val: any, i: any) => {
              if (index == i) {
                return (
                  <p className={classes.listDate}>
                    {item.id == val.id && (
                      val.DMY + " " + t("ApprovalRequest.lastModified")
                    )}
                  </p>
                )
              }
            })} */}
                                  </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  {/* {item.attributes?.projects.map((project: any) => (
            <p className={classes.listProjects} style={{ fontSize: "12px", paddingLeft: "25px" }}>{project?.name}</p>
          ))}
          {item?.attributes && item?.attributes?.projects && item?.attributes?.projects?.length == 0 &&
            <p></p>
          } */}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Grid>
                  </>
                )
              }
            </>
            : this.state.loading == false && this.state.brandsData.length <= 0 && this.state.brandGroupList.length <= 0 &&
            <p>No Brands</p>
          }








        </Grid>

        <Menu
          id="listing_branding"
          anchorEl={this.state.anchorElFilter}
          // keepMounted
          open={Boolean(this.state.anchorElFilter && this.state.isSearch === false)}
          onClose={() => {
            this.handleFilterClose();

          }}
          style={{
            marginTop: "100px", marginLeft: "-30px", borderRadius: "8px", boxShadow: "0 3px 7px 0 rgba(26, 118, 176, 0.05)",

          }}
        >

          <Grid container style={{ width: "auto", paddingLeft: "15px" }}>
            {/* <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <List style={{width:"100%"}}> */}
            {/* <ListItem button>
                      <ListItemText primary="name" />
                    </ListItem> */}
            {/* <ListItem button>
                      <ListItemText primary="name" />
                    </ListItem> */}
            {/* </List>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={8}>
              <RadioGroup aria-label="filter" name="brandFilter" value={this.state.brandFilterValue} onChange={this.handleFilterChange} >
                <FormControlLabel style={{
                  marginLeft: "5px !important",
                  width: "125px"
                }} value="name0" control={<Radio />} label="Name (A-Z)" />
                <FormControlLabel style={{
                  marginLeft: "5px !important",
                  width: "125px"
                }} value="name1" control={<Radio />} label="Name (Z-A)" />
                <FormControlLabel value="created_at1" control={<Radio />} style={{ width: "112px" }} label="Created At" />
                <FormControlLabel value="updated_at1" control={<Radio />} style={{ width: "112px" }} label="Updated At" />

              </RadioGroup>
            </Grid>



          </Grid>
        </Menu>

        <Dialog
          onClose={this.handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
          style={{ fontFamily: primaryFont }}
        >
          <DialogTitle id="customized-dialog-title">
            {t("projects.newProject")}
            {/* New Project */}
            <span style={{ position: "absolute", right: "10px", cursor: 'pointer' }}>
              <CloseIcon onClick={this.handleCloseModal} />
            </span>
          </DialogTitle>

          <Formik
            initialValues={{
              // email: "",
              // animal: "",
              // companyName: "",
              projectDescription: "",
              title: "",
              //shareTheProjectWithOthers:this.state.sharedProject,
              // includeAPersonalMessageWithYourInvitation: "",
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              // projectDescription: Yup.string().required(
              //   "projectDescription is required"
              // ),
              title: Yup.string().required(
                "title is required"
              ),

              // includeAPersonalMessageWithYourInvitation: Yup.string().required(
              //   "Include A Personal MessageWith Your Invitation is required"
              // ),
            })}
            onSubmit={(values) => {
              console.log("Project Brand ID", this.state.selectedBrandForProjectCreate);

              console.log("propro", values);
              this.callCreateProjectApi(values, this.state.selectedBrandForProjectCreate, this.state.selectedUsersForProjectCreate);


              // this.goToConfirmationAfterPasswordChange(values);
              // this.props.nextStep(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              setFieldValue,
              handleBlur,
              setFieldTouched,
              isSubmitting,
              handleSubmit,
            }) => {

              return (
                <form onSubmit={handleSubmit} style={{ fontFamily: primaryFont }}>
                  <DialogContent>
                    <Autocomplete
                      id="fixed-tags-demo"
                      options={this.state.brandsData}
                      style={{ minWidth: "550px" }}
                      getOptionLabel={(option: any) => option?.attributes?.name}
                      defaultValue={[this.state.brandsData.length !== 0 && this.state.brandsData.length[0]]}
                      onChange={(e, value) => {
                        this.setState({
                          selectedBrandForProjectCreate: value
                        }, () => {
                          this.getBrandTeams(this.state.selectedBrandForProjectCreate)
                          this.templeteListingApi(this.state.selectedBrandForProjectCreate)
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            className={classes.projectChips}
                            label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                              <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.attributes?.logo_blob_signed_id_url} /> </span>
                              <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.attributes?.name}</span>
                            </Typography>}
                            {...getTagProps({ index })}

                            style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                          />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          label={t("teams.selectBrand")}
                          // label="Select Brand"
                          name="selectbrand"


                          helperText={this.state.sharedProjectError ? "Brand is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />
                    <TextField
                      name="title"
                      type="text"
                      size="small"
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      fullWidth
                      label={t("projects.newProjectTitle")}
                      // label="Project Title"
                      value={values.title}
                      className={classes.confirmInput}
                      variant="outlined"
                      helperText={
                        touched.title &&
                          errors.title ? (
                          // <Trans>CompanyProfile.companyNameRequired</Trans>
                          <Trans>Project title is required</Trans>
                        ) : (
                          ""
                        )
                      }
                      error={
                        touched.title && errors.title
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />


                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={this.state.brandTeamsData}
                      style={{ minWidth: "550px" }}
                      getOptionLabel={(option: any) => option?.first_name ? option?.first_name + " " + option?.last_name : option?.name ? option.name : ''}
                      renderOption={(option: any) => (
                        option?.photo_blob_signed_id_url?.length >= 0 ?
                          <React.Fragment>
                            <span style={{ marginRight: "5px" }}>{option?.photo_blob_signed_id_url?.length > 0 ?
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={option?.photo_blob_signed_id_url} /> :
                              <img style={{ height: "30px", width: "30px", marginTop: "10px", borderRadius: "50%" }} src={defaultUser} />
                            }</span>
                            {option.first_name} {option.last_name}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {option?.name}
                          </React.Fragment>
                      )}
                      onInputChange={(e, value) => {
                        this.callGetAccountListApi(value)

                      }}
                      onChange={(e, value) => {
                        this.setState({
                          selectedUsersForProjectCreate: value
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          option?.photo_blob_signed_id_url?.length >= 0 ?
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span>
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.first_name + option?.last_name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                            :
                            <Chip
                              className={classes.projectChips}
                              label={<Typography style={{ whiteSpace: 'normal', display: 'flex' }}>
                                {/* <span><img style={{ height: "30px", width: "30px", marginTop: "10px" }} src={option?.photo_blob_signed_id_url} /> </span> */}
                                <span style={{ marginTop: "15px", marginLeft: "5px", fontSize: "12px" }}>{option?.name}</span>
                              </Typography>}
                              {...getTagProps({ index })}

                              style={{ height: "44px", borderRadius: "8px", backgroundColor: "#f4f6fc", opacity: "none" }}
                            />
                        ))
                      }

                      renderInput={(params): any => (
                        <TextField
                          {...params}
                          // label={t("teams.addMembers")}
                          label={t("teams.addMembers")}
                          name="addMembers"
                          placeholder={t("teams.addMembersPlaceholder")}

                          helperText={this.state.sharedProjectError ? "Share the project with others is required" : ""}


                          error={
                            this.state.sharedProjectError
                          }

                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused
                            }
                          }}
                          className={classes.confirmInput}
                          variant="outlined"

                        />
                      )}
                    />
                    {this.state.permissionName != 'client' && <Grid item xs={12} style={{
                      marginTop: "15px"
                    }}>
                      <FormControl variant="outlined" style={{
                        marginLeft: 0,
                        minWidth: "100%",
                      }}>
                        <InputLabel id="demo-simple-select-outlined-label" style={{ color: 'black', fontSize: 16, background: "#fff" }}>Template</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.tempeleteChoosen}
                          fullWidth
                          onChange={(e) => {
                            // this.setCategory(item)
                            this.setState({ tempeleteChoosen: e.target.value })
                          }}
                          label="Template"
                          name="template"
                        >

                          {this.state.tempeleteListing.length > 0 && this.state.tempeleteListing.map((item: any, index: any) => (

                            <MenuItem key={index} value={item.id} style={{ color: 'black', fontSize: 18 }}
                            //  onClick={(e) => {
                            //   // this.setCategory(item)
                            //   this.handleChangeBrand(item.id)
                            // }} 
                            >{item.attributes.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>}

                    <TextField
                      name="projectDescription"
                      multiline
                      rows={4}
                      // InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      size="small"
                      className={classes.confirmInput}
                      fullWidth
                      // label="Project Description"
                      label={t("projects.projectDescription")}
                      value={values.projectDescription}
                      variant="outlined"

                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "flex-start", marginBottom: "40px" }}>
                    {/* <Button autoFocus onClick={this.handleCloseModal} color="primary">
              Save changes
          </Button> */}
                    <Button
                      style={{
                        backgroundColor: colorToBeChanged,
                        color: "white",
                        marginLeft: "15px",
                        marginTop: "11px"
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("projects.createProject")}
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ backgroundColor: "white", color: "black", marginLeft: "36px", marginBottom: "-12px" }}
                      type="submit"
                      variant="contained"
                      onClick={this.handleCloseModal}
                    >
                      {t("projects.cancel")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </Dialog>
      </div>




      //Merge Engine End DefaultContainer
    );
  }
}
export default withStyles(styles)(withTranslation()(Brands))
// Customizable Area Start

// Customizable Area End